import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  autocomplete: {
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& button': {
        width: '100%',
      },
    },
  },
}))
