import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'

interface MatchReportReturnToMatchButtonProps {
  matchId: string
  className?: string
}

export const MatchReportReturnToMatchButton: React.FC<MatchReportReturnToMatchButtonProps> = ({
  matchId,
  className,
}) => {
  const pathToMatchLobby = `${Path.Match}/${matchId}`

  return (
    <NxButton
      className={className}
      href={pathToMatchLobby}
      label="Return to Match"
      variant="secondary"
    />
  )
}
