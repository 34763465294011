import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const ClosedEye = createSvgIcon(
  <svg
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49981 12.5303C8.20931 12.5303 8.85731 12.453 9.44831 12.3195L8.13056 11.0018C7.92581 11.0175 7.71806 11.0303 7.49981 11.0303C3.48656 11.0303 1.93181 8.14575 1.55531 7.28025C1.70831 6.92775 2.06081 6.2445 2.68631 5.5575L1.63781 4.509C0.484312 5.75925 0.0470625 7.0185 0.0388125 7.04325C-0.0129375 7.197 -0.0129375 7.36425 0.0388125 7.518C0.0545625 7.5675 1.77506 12.5303 7.49981 12.5303ZM7.49981 2.03025C6.12206 2.03025 4.99031 2.32725 4.04681 2.766L1.28006 0L0.219562 1.0605L13.7196 14.5605L14.7801 13.5L12.2908 11.0108C14.2513 9.5475 14.9511 7.5495 14.9616 7.518C15.0133 7.36425 15.0133 7.197 14.9616 7.04325C14.9451 6.993 13.2246 2.03025 7.49981 2.03025ZM11.2288 9.94875L9.51881 8.23875C9.66131 7.94625 9.74981 7.6245 9.74981 7.28025C9.74981 6.0495 8.73056 5.03025 7.49981 5.03025C7.15556 5.03025 6.83381 5.11875 6.54206 5.262L5.18606 3.906C5.84681 3.675 6.61031 3.53025 7.49981 3.53025C11.5131 3.53025 13.0678 6.41475 13.4443 7.28025C13.2178 7.79925 12.5698 9.03675 11.2288 9.94875Z"
      fill="#2F3B43"
    />
  </svg>,
  'ClosedEye',
  { viewBox: '0 0 15 15' }
)
