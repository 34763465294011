export enum EsportSlug {
  LeagueOfLegends = 'league-of-legends',
  RocketLeague = 'rocket-league',
  Smite = 'smite',
  Fortnite = 'fortnite',
  FortniteSolos = 'fortnite-solos',
  Overwatch = 'overwatch',
  Hearthstone = 'hearthstone',
  Madden21PS4 = 'madden-21-ps4',
  Madden = 'madden-21-solos-ps4',
  Madden21XboxOne = 'madden-21-xbox-one',
  Madden21SolosXboxOne = 'madden-21-solos-xbox-one',
  Fifa21PS4 = 'fifa-21-ps4',
  Fifa21SolosPS4 = 'fifa-21-solos-ps4',
  Fifa21XboxOne = 'fifa-21-xbox-one',
  Fifa21SolosXboxOne = 'fifa-21-solos-xbox-one',
  SmashBrosUltimate = 'super-smash-bros-ultimate',
  BlackOpsColdWar = 'black-ops-cold-war',
  Splatoon = 'splatoon-2',
  MarioKart = 'mario-kart',
  NBA2K = 'nba2k',
  Valorant = 'valorant',
  FC = 'ea-sports-fc',
  Chess = 'chess',
  Chess2 = 'chess-2',
  Chess3 = 'chess-3',
  ChessKids = 'chess-kids',
  SuperSmashBrosUltimateSolos = 'super-smash-bros-ultimate-solos',
  SuperSmashBrosUltimateCrew = 'super-smash-bros-ultimate-crew',
  RocketleagueDuos = 'rocket-league-duos',
  StreetFighter = 'street-fighter',
  Esport01 = 'esport-01',
  Esport02 = 'esport-02',
  Minecraft = 'minecraft',
  Fortnite2 = 'fortnite-2',
  CollegeFootball = 'college-football',
  ARAM = 'aram',
  RocketLeagueGMA = 'rocket-league-gma',
}
