import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { StandingsByEsport } from '../../standings/StandingsByEsport'
import { PhaseFilterType } from '../LeagueDetails.types'

interface Props {
  esportSlug: EsportSlug | undefined
  phase: PhaseFilterType | undefined
  seasonId: string
}

export const LeagueStandings: React.FC<Props> = ({
  esportSlug,
  phase,
  seasonId,
}) => {
  if (!phase) {
    return null
  }

  return (
    <Esport slug={esportSlug}>
      <StandingsByEsport phase={phase} seasonId={seasonId} />
    </Esport>
  )
}
