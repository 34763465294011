import { yupResolver } from '@hookform/resolvers'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'

import {
  Maybe,
  refetchGetMySchoolDetailsQuery,
  useGetMySchoolDetailsQuery,
  useUpdateSchoolProfileMutation,
} from '@plvs/graphql'
import { AvatarCircle } from '@plvs/rally/components/avatar'
import { Box } from '@plvs/respawn/features/layout'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import { useWithSinglePendingRequest } from '@plvs/rally/libs/request-utils/useWithSinglePendingRequest'
import { formErrorToString } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { equals, pick } from 'ramda'
import React, { FunctionComponent, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { NxTypography, NxTextInput } from '@playvs-inc/nexus-components'
import { Lock } from '@playvs-inc/nexus-icons'

import SetSchoolAvatarImage from '../SetSchoolAvatarImage'

export const SchoolBasicInfoSchema = yup.object().shape({
  address: yup.string().required('Address is required'),
  mascotName: yup.string(),
})

interface SchoolBasicInfoInput {
  mascotName: string
  address: Maybe<string>
}
const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: theme.spacing(3, 3, 1),
  },
  inputContainer: {
    maxWidth: '350px',
    paddingBottom: `${theme.spacing(0.5)}px !important`,
    paddingTop: `0px !important`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

/**
 * This is the panel in the school settings page that handles the basic info
 * such as address and mascot name.
 */
export const SchoolBasicInfoFormPanel: FunctionComponent = () => {
  // Queries
  // Grab school data from cache.
  const { data } = useGetMySchoolDetailsQuery()
  const { enqueueSnackbar } = useSnackbar()

  // computed props
  const schoolId = data?.me?.school?.id ?? ''
  const logoUrl = data?.me?.school?.logoUrl ?? ''
  const schoolName = data?.me?.school?.name ?? ''
  const mascotName = data?.me?.school?.mascotName ?? ''
  const address = data?.me?.school?.address ?? null

  // Hooks
  const formRef = useRef<HTMLFormElement>(null)
  const withSinglePendingRequest = useWithSinglePendingRequest()
  const withSaveNotification = useWithSaveNotification()
  const classes = useStyles()

  const {
    errors,
    register,
    reset,
    handleSubmit,
    getValues,
    setError,
  } = useForm<SchoolBasicInfoInput>({
    resolver: yupResolver(SchoolBasicInfoSchema),
  })

  // Mutations
  const [mutateSchoolInfo] = useUpdateSchoolProfileMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  // Side effects

  // update populated values when new values received by refetch
  React.useEffect(
    () =>
      reset({
        mascotName,
        address,
      }),
    [mascotName, address]
  )

  // Form handlers
  const onSubmit = useCallback(
    handleSubmit((input: SchoolBasicInfoInput): void => {
      withSinglePendingRequest(async () => {
        await withSaveNotification(async () => {
          await mutateSchoolInfo({
            variables: {
              schoolId,
              input,
            },
          })
        })
      })
    }),
    [
      handleSubmit,
      withSinglePendingRequest,
      withSaveNotification,
      mutateSchoolInfo,
    ]
  )

  const onBlur = useCallback(async (): Promise<void> => {
    const values = getValues()
    const oldValues = pick(['address', 'mascotName'], data?.me?.school)
    const newValues = pick(['address', 'mascotName'], getValues())

    const hasChanges = !equals(oldValues, newValues)

    if (!hasChanges) {
      return
    }

    try {
      await SchoolBasicInfoSchema.validate(values)
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const firstError = err.errors[0]
        enqueueSnackbar(firstError, {
          variant: 'error',
          transitionDuration: {
            appear: 5000,
          },
        })

        setError(err.path as keyof SchoolBasicInfoInput, {
          message: firstError,
          type: err.type,
        })
        return
      }
      throw err // otherwise throw and let sentry error capture
    }

    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }, [getValues, data, enqueueSnackbar, setError])

  return (
    <Box>
      <form ref={formRef} noValidate onBlur={onBlur} onSubmit={onSubmit}>
        <Card>
          <CardContent className={classes.titleContainer}>
            <Box mb={3}>
              <NxTypography data-testid="school-info-title" variant="h4">
                School Info
              </NxTypography>
            </Box>
            <Box alignItems="center" display="flex" mb={4} mt={2}>
              <AvatarCircle avatarUrl={logoUrl} size="large" />
              <Box ml={2}>
                <SetSchoolAvatarImage schoolId={schoolId} />
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  data-testid="school-name"
                  defaultValue={schoolName}
                  disabled
                  endAdornment={<Lock />}
                  fullWidth
                  label="School Name"
                  name="name"
                  tabIndex={0}
                />
              </Grid>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  data-testid="SchoolBasicInfoFormPanel__MascotInput"
                  defaultValue={mascotName}
                  fullWidth
                  helperText={formErrorToString(errors.mascotName)}
                  label="Mascot Name"
                  name="mascotName"
                  variant={errors.mascotName ? 'error' : 'default'}
                />
              </Grid>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  data-testid="address-input"
                  defaultValue={address ?? ''}
                  fullWidth
                  helperText={formErrorToString(errors.address)}
                  label="Address"
                  name="address"
                  variant={errors.address ? 'error' : 'default'}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Box>
  )
}
