import React, { useEffect, useState } from 'react'
import { ResourceType } from '@plvs/graphql'
import { AvatarCircleProps } from '@plvs/rally/components/avatar'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

export const MyAvatarCircle = (
  props: Omit<AvatarCircleProps, 'avatarUrl' | 'size'> & {
    size: NxAvatarSize
  }
): React.ReactElement => {
  const { size } = props
  const { userId } = useUserIdentityFn()
  const { resourceImages, getResourceImageOfSize } = useResourceImageProvider()
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>()

  useEffect(() => {
    const resourceImage = getResourceImageOfSize({
      resource: {
        id: userId,
        type: ResourceType.User,
      },
      size,
    })
    const url = resourceImage?.url
    if (url !== avatarUrl) {
      setAvatarUrl(url)
    }
  }, [resourceImages])
  return (
    <NxUserAvatar avatarUrl={avatarUrl} hashId={userId || ''} size={size} />
  )
}
