import React from 'react'
import { noop } from 'ramda-adjunct'

import {
  Trashcan,
  Promotetoteamcaptain,
  Demoteteamcaptain,
  Substitute,
} from '@playvs-inc/nexus-icons'
import {
  DemoteTeamCaptainMenuItem,
  PromoteToTeamCaptainMenuItem,
} from '@plvs/respawn/features/manage-teams/ManageTeamCaptains'
import { canRemoveTeamMembers } from '@plvs/utils'
import { RemovePlayerFromTeam } from '@plvs/respawn/features/manage-teams/RemovePlayerFromTeam'
import { RepositionPlayersInTeam } from '@plvs/respawn/features/manage-teams/RespositionPlayersInTeam'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { RosterCardMenuRowItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { ContentContextPermissions } from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RowMenuProps } from '../../RosterProvider.types'
import { useRosterContext } from '../../RosterProvider'
import { ActionItemProps, RosterRowActions } from './RosterRowActions'

export const RosterRowFilledMenu: React.FC<RowMenuProps> = ({
  getPermissions,
  hasUnlimitedPass,
  member,
  members,
  metaseason,
  onMutationSuccess,
  rosterCardStarterRowActions,
  teamId,
  userId,
  isAdmin = false,
}) => {
  // Although we want to migrate away from child components calling context directly,
  // accessing the below props allows us to type RowMenuProps and share that type between
  // multiple roster menu components.
  const { teamCaptain, league, isRosterActivelyLocked, enrolledSeason } =
    useRosterContext()

  const { getUsername } = useEsportContext()

  const canRemoveMembers = canRemoveTeamMembers(
    isRosterActivelyLocked,
    enrolledSeason,
    isAdmin
  )
  const flattenedMember = {
    id: member.user?.id || '',
    name: member.user?.name || '',
    position: member.position?.index || 0,
    seasonPasses: member?.user?.seasonPasses ?? undefined,
  }
  const memberRoles =
    members?.find(({ user }) => user && user?.id === flattenedMember.id)?.user
      ?.roles ?? []
  const currentSeasonPass = member?.user?.seasonPasses?.find(
    (seasonPass) =>
      metaseason?.id &&
      seasonPass.metaseasonId === metaseason?.id &&
      league?.id === seasonPass?.leagueId &&
      !!seasonPass.consumedAt
  )
  const hasUsedPass = !!currentSeasonPass?.consumedAt
  const isBenchPlayer = flattenedMember.position === undefined

  const isSelf = flattenedMember?.id === userId

  const allowedActions = getPermissions(
    memberRoles,
    canRemoveMembers
      ? ContentContextPermissions.ROSTER_ITEMS_MENU
      : ContentContextPermissions.ROSTER_ITEMS_MENU_DISABLED_REMOVAL,
    isSelf
  ) as RosterCardMenuRowItems[]

  const filteredAllowedActions = rosterCardStarterRowActions?.length
    ? allowedActions.filter((action) =>
        rosterCardStarterRowActions.includes(action)
      )
    : allowedActions

  const rosterRowFilledMenuMap: Record<
    RosterCardMenuRowItems,
    ActionItemProps
  > = {
    [RosterCardMenuRowItems.RemoveFromTeam]: {
      label: 'Remove Player',
      icon: <Trashcan />,
      dialogProps: {
        children: (
          <RemovePlayerFromTeam
            action="remove"
            member={flattenedMember}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Remove Player',
    },
    [RosterCardMenuRowItems.LeaveTeam]: {
      label: 'Leave Team',
      icon: <Trashcan />,
      dialogProps: {
        children: (
          <RemovePlayerFromTeam
            action="leave"
            member={flattenedMember}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Leave Team',
    },
    [RosterCardMenuRowItems.PromoteToTeamCaptain]: {
      label: 'Promote to Captain',
      icon: <Promotetoteamcaptain />,
      dialogProps: {
        children: (
          <PromoteToTeamCaptainMenuItem
            currentTeamCaptainName={teamCaptain?.name ?? ''}
            member={flattenedMember}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Promote to Captain',
    },
    [RosterCardMenuRowItems.RemoveTeamCaptain]: {
      label: 'Remove Team Captain',
      icon: <Demoteteamcaptain />,
      dialogProps: {
        children: (
          <DemoteTeamCaptainMenuItem
            currentTeamCaptainName={teamCaptain?.name ?? ''}
            member={flattenedMember}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Remove Team Captain',
    },
    [RosterCardMenuRowItems.RepositionPlayer]: {
      label: 'Change Position',
      icon: <Substitute />,
      dialogProps: {
        children: (
          <RepositionPlayersInTeam
            getUsername={getUsername}
            hasUnlimitedPass={hasUnlimitedPass}
            hasUsedPass={hasUsedPass}
            isBench={isBenchPlayer}
            leagueId={league?.id}
            metaseasonId={metaseason?.id}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            player={flattenedMember}
            teamId={teamId}
          />
        ),
      },
      key: 'Change Position',
    },
    [RosterCardMenuRowItems.DisabledRemoveFromTeam]: {
      label: `Unable to remove player, roster is locked`,
      icon: <Trashcan />,
      key: 'Disabled Remove Player',
      disabled: true,
    },
    [RosterCardMenuRowItems.DisabledLeaveTeam]: {
      label: `Can’t leave team, roster is locked`,
      icon: <Trashcan />,
      key: 'Disabled Leave Team',
      disabled: true,
    },
  }

  const menuItems = filteredAllowedActions.map((rosterMenuItem) => {
    return rosterRowFilledMenuMap[rosterMenuItem]
  })

  return <RosterRowActions actionItemProps={menuItems} />
}
