import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  esportCardSkeleton: {
    borderRadius: '12px',
    height: '160px',
    width: '160px',
  },
  createTeamSelect: {
    backgroundColor: theme.palette.ColorBackgroundAlt,
    border: `1px solid ${theme.palette.ColorBackgroundAlt}`,
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
    borderRadius: '4px',
  },
  createTeamButton: {
    '&.MuiButton-contained.Mui-disabled': {
      opacity: 0.5,
      color: theme.palette.common.white,
      backgroundColor:
        theme.palette.ColorBackgroundAccentAlt || theme.palette.primary.main,
    },
  },
}))
