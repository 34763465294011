import { formatDistanceToNow, subMinutes, isBefore, subYears } from 'date-fns'
import React from 'react'

import { Tooltip } from '@plvs/rally/components/tooltip'
import { useTheme } from '@material-ui/core'

export const OnlineBadge: React.FC<{ lastSeen: string | null }> = ({
  lastSeen,
}) => {
  const theme = useTheme()

  const lastSeenDate = new Date(lastSeen || 0)
  const isInvalidDate = isBefore(lastSeenDate, subYears(new Date(), 1))
  const isOnline = isBefore(subMinutes(new Date(), 5), lastSeenDate)
  const lastSeenCopy = isInvalidDate
    ? 'Offline'
    : `Last seen ${formatDistanceToNow(lastSeenDate, {
        addSuffix: true,
        includeSeconds: false,
      })}`
  const title = isOnline ? 'Online' : lastSeenCopy

  return (
    <Tooltip
      style={{
        bottom: 0,
        position: 'absolute',
        height: 20,
        right: 0,
        width: 20,
      }}
      title={title}
    >
      <div
        style={{
          backgroundColor: isOnline
            ? theme.palette.ColorIconSuccess
            : theme.palette.ColorIconDisabled,
          border: `2px solid ${theme.palette.ColorBackgroundBase}`,
          borderRadius: '50%',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      />
    </Tooltip>
  )
}
