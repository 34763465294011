import React, { useState } from 'react'
import { NxTypography, NxButton, NxModal } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box, FlexSpacer } from '@plvs/respawn/features/layout'

import { GetLocalReferralSchoolsQuery } from '@plvs/graphql/generated'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '../../coach/coachLeadGen/CoachLeadComponent'

type LocalSchoolReferral = NonNullable<
  NonNullable<
    NonNullable<GetLocalReferralSchoolsQuery['getSchoolsES']>['schools']
  >
>[0]

export const useStyles = makeStyles({
  form: {
    marginBottom: 0,
  },
})

interface Props {
  school: LocalSchoolReferral
}

export const SchoolReferralRow: React.FC<Props> = ({ school }) => {
  const classes = useStyles()

  const [open, setOpen] = useState<boolean>(false)
  const handleModalClose = (): void => setOpen(false)
  const handleModalOpen = (): void => setOpen(true)

  return (
    <>
      <Box key={school.id} alignItems="center" display="flex" mb={1}>
        <NxTypography variant="body3">{school?.name}</NxTypography>
        <FlexSpacer />
        <NxButton label="Refer" onClick={handleModalOpen} variant="secondary" />
      </Box>
      <NxModal
        onClose={handleModalClose}
        open={open}
        showTopRightClose
        subtitle="Refer a coach at another school to get them started on PlayVS."
        title="Refer a School Near You"
      >
        <CoachLeadComponent
          className={classes.form}
          gridSpacing={1}
          hideModalPadding
          isBorderless
          isFaculty
          isModalView
          onBackButtonClick={handleModalClose}
          school={
            {
              id: school.id,
              name: school.name,
              state: school.state,
            } as SchoolPropType
          }
          setAvailableSchool
          showBackButton
          source="student-dashboard-coach-lead"
        />
      </NxModal>
    </>
  )
}
