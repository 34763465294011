import dayjs from 'dayjs'
import { flatten, innerJoin, map, range } from 'ramda'
import { Season } from '@plvs/graphql'
import { ApolloCache } from '@apollo/client'
import { getDateWithCorrectTimeZone } from '@plvs/utils'
import type {
  EnrolledPlayerOnTeam,
  OptionalWeeksCache,
  SelectionObj,
  FinalSelectionsToCreate,
  TeamEnrollmentUpdate,
  TeamWithEnrolledSeason,
  OptionalSlotExclusionRangeData,
  EnrollmentTeam as EnrollmentTeamType,
} from './types'
import { EnrollmentStep } from './enrollmentDetailsData'

export const NO_BREAK_WEEK_HASH = 'none'

export enum UnableToEnrollTeamReason {
  TeamAlreadyEnrolled = 'teamAlreadyEnrolled',
  BeforeRegistrationStart = 'beforeRegistrationStart',
  AfterRegistrationEnd = 'afterRegistrationEnd',
  TeamStartersBelowMinimum = 'teamStartersBelowMinimum',
  TeamMembersAboveMaximum = 'teamMembersAboveMaximum',
  TeamMembersBelowFormat = 'teamMembersBelowFormat',
  TeamMembersThatCannotBeEnrolled = 'teamMembersThatCannotBeEnrolled',
}

export enum UnableToEnrollTeamsReason {
  NoTeams = 'noTeams',
  NoTeamsReadyToEnroll = 'NoTeamsReadyToEnroll',
}

// NOTE:
//
// The logic in this function is intended to mirror the checks in the backend
//  TeamEnrollmentServices.addTeamToLeague function. Ideally, we would write
//  these checks in a single place, but this will require some refactoring that
//  we don't have time for, at the moment.

/**
 * TODO: Created a V2 version of getTeamMembersThatCannotBeEnrolled b/c in the process
 * of updating the original getTeamMembersThatCannotBeEnrolled, I noticed it was
 * used in serveral places. So instead of risking breaking things elsewhere.
 * A story has been created to address this tech debt PRO-3933
 *
 * @param enrolledPlayersOnTeam
 * @param leagueId
 * @param metaseasonId
 * @param team
 */
export const getTeamMembersThatCannotBeEnrolledV2 = <
  T extends {
    id: string
  }
>({
  enrolledPlayersOnTeam,
  leagueId,
  metaseasonId,
  team,
}: {
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  leagueId: string
  metaseasonId: string
  team:
    | {
        id: string
        members: T[] | null
      }
    | null
    | undefined
}): {
  teamMembersThatCannotBeEnrolled: T[]
} => {
  if (!team) return { teamMembersThatCannotBeEnrolled: [] }

  const rejectedPlayersInLeague = enrolledPlayersOnTeam.filter(
    (player) =>
      player.leagueId === leagueId &&
      player.metaseasonId === metaseasonId &&
      player.teamId !== team.id
  )

  const teamMembersEnrolledInTheSameLeagueOnAnotherTeam = innerJoin(
    (member, enrolledPlayer) => member.id === enrolledPlayer.userId,
    team.members ?? [],
    rejectedPlayersInLeague
  )

  const teamMembersThatCannotBeEnrolled = [
    ...teamMembersEnrolledInTheSameLeagueOnAnotherTeam,
  ]

  return { teamMembersThatCannotBeEnrolled }
}

type EnrollmentTeam<T> = {
  id: string
  members: T[] | null
  name: string | null
  enrolledSeasons: { metaseasonId: string }[] | null
}

export const getTeamIsUnenrollable = ({
  season,
}: {
  season: Pick<Season, 'id' | 'teamDeregistrationEndsAt'>
}): boolean => {
  // First, assume that the team has already been enrolled.

  // Specifically check `!isAfter` because deregistrationEndsAt could be null
  //  and we want to return true if that's the case!
  return !dayjs().isAfter(season.teamDeregistrationEndsAt)
}

export type GetPlayerCanEnrollTeamsReturn<T> =
  | {
      case: 1
      reason: UnableToEnrollTeamsReason.NoTeams
    }
  | {
      case: 2
      team: EnrollmentTeam<T>
      teamMembersThatCannotBeEnrolled: T[] | undefined
      reason: UnableToEnrollTeamReason | null
    }
  | {
      case: 3
      reason: UnableToEnrollTeamsReason.NoTeamsReadyToEnroll
    }
  | {
      case: 4
      team: EnrollmentTeam<T>
    }
  | {
      case: 5
      teams: EnrollmentTeam<T>[]
    }

export const getFormattedWeekInterval = ({
  weekNumber,
  startsAt,
  endsAt,
}: {
  weekNumber: number
  startsAt: string
  endsAt: string
}): string => {
  const selectedMomentStartsAt = startsAt
    ? getDateWithCorrectTimeZone(startsAt).format('MMM Do')
    : 'TBD'

  const selectedMomentEndsAt = endsAt
    ? getDateWithCorrectTimeZone(endsAt)
        .subtract(1, 'millisecond')
        .format('MMM Do')
    : 'TBD'

  const formattedSelectedWeek = `${selectedMomentStartsAt} - ${selectedMomentEndsAt}`

  return `Week ${weekNumber}: ${formattedSelectedWeek}`
}

export const NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION = {
  id: '',
  isAvailable: true,
  startsAt: '',
  endsAt: '',
  hash: NO_BREAK_WEEK_HASH,
  title: 'No Break Week',
  formattedSelection: 'No Break Week',
  isEditable: true,
}

export function getOptionalSeasonWeeksMap(
  seasonSlotExclusionRangeData: OptionalSlotExclusionRangeData[]
): OptionalWeeksCache {
  const seasonWeeksMap: OptionalWeeksCache = {}
  seasonSlotExclusionRangeData.forEach(
    ({ resourceId, resourceType, configuration, selections, seasonId }) => {
      if (seasonId && !seasonWeeksMap[seasonId]) {
        seasonWeeksMap[seasonId] = {
          resourceType,
          resourceId,
          seasonId,
          selections: !selections.length
            ? []
            : range(0, configuration.maxSlotExclusions).map((i) => {
                const confirmedSelection = selections[i]
                let initializedSelection = confirmedSelection
                  ? {
                      ...confirmedSelection,
                      formattedSelection: configuration.suggestions.find(
                        (suggestion) =>
                          suggestion.hash === confirmedSelection?.hash
                      )?.title,
                    }
                  : null
                if (!initializedSelection) {
                  initializedSelection = NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION
                }
                return {
                  confirmedSelection: initializedSelection,
                  selection: initializedSelection,
                }
              }),
        }
      }
    }
  )
  return seasonWeeksMap
}

export const getFinalSlotExclusionsSelections = ({
  cache,
  teamUpdates,
  seasonEnrollmentCount,
}: {
  cache: OptionalWeeksCache
  teamUpdates?: Array<TeamEnrollmentUpdate>
  seasonEnrollmentCount?: Record<string, number>
}): {
  slotExclusionsToUpdate: Array<SelectionObj>
  slotExclusionsToCreate: Array<FinalSelectionsToCreate>
  slotExclusionsToDelete: Array<string>
  flattenedSlotExclusionSelections: Array<SelectionObj>
} => {
  const mappedCacheValues = Object.values(cache).map((value) => ({
    selections: value.selections.map((selection) => ({
      ...selection,
      resourceId: value.resourceId,
      resourceType: value.resourceType,
      seasonId: value.seasonId,
    })),
  }))
  const flattenedSelections = flatten(
    map(
      (selection) => selection,
      mappedCacheValues.map((value) => value.selections)
    )
  )

  const slotExclusionsToUpdate = flattenedSelections.filter(
    ({ selection, confirmedSelection }) =>
      selection &&
      confirmedSelection &&
      confirmedSelection.id &&
      selection.hash !== confirmedSelection.hash
  )

  const slotExclusionsToCreate = flattenedSelections.filter(
    ({ selection, confirmedSelection }) =>
      selection &&
      (!confirmedSelection || confirmedSelection.hash === NO_BREAK_WEEK_HASH)
  )

  const slotExclusionsToDelete = flattenedSelections
    .filter(
      ({ selection, confirmedSelection }) =>
        selection &&
        confirmedSelection &&
        confirmedSelection.hash !== NO_BREAK_WEEK_HASH &&
        selection.hash === NO_BREAK_WEEK_HASH
    )
    .map((s) => s.confirmedSelection?.id ?? '')

  if (seasonEnrollmentCount && teamUpdates) {
    const seasonIdsToRemove = new Set(
      teamUpdates
        .filter((update) => update.toSeasonId !== update.fromSeasonId)
        .map((update) => update.fromSeasonId)
    )
    Object.values(cache)
      .filter(
        (cache) =>
          cache.seasonId &&
          seasonIdsToRemove.has(cache.seasonId) &&
          seasonEnrollmentCount[cache.seasonId] === 0
      )
      .forEach((cache) =>
        cache.selections.forEach((sel) => {
          if (sel.confirmedSelection?.id) {
            slotExclusionsToDelete.push(sel.confirmedSelection?.id)
          }
        })
      )
  }

  return {
    slotExclusionsToUpdate,
    slotExclusionsToCreate,
    slotExclusionsToDelete,
    flattenedSlotExclusionSelections: flattenedSelections,
  }
}

/**
 * Evicts billing related cache to enrollment changes will purge the
 * billing summary cache.
 */
// Not feisable to unit test apollo cache.
/* istanbul ignore next */
export const evictBillingCache = (cache: ApolloCache<unknown>): void => {
  cache.evict({
    fieldName: 'getBillingPeriodSummaryForDateRange',
  })
  cache.evict({
    fieldName: 'getSchoolSeasonEnrollmentSummary',
  })
}

export const getStep = (pathname: string): EnrollmentStep => {
  if (pathname.match(/teams/)) {
    return EnrollmentStep.SelectTeam
  }

  if (pathname.match(/select/)) {
    return EnrollmentStep.SelectPlan
  }

  return EnrollmentStep.EnrollmentConfirmation
}

export const getPl = (step: EnrollmentStep, isMobile: boolean): number => {
  if (
    step === EnrollmentStep.SelectPlan ||
    (isMobile && step === EnrollmentStep.SelectTeam)
  ) {
    return 2
  }

  if (step === EnrollmentStep.EnrollmentConfirmation) {
    return 0
  }

  return 8
}

export const getPr = (step: EnrollmentStep, isMobile: boolean): number => {
  switch (step) {
    case EnrollmentStep.SelectTeam:
      return isMobile ? 2 : 51
    case EnrollmentStep.SelectPlan:
    case EnrollmentStep.EnrollmentConfirmation:
      return 0
    default:
      return 8
  }
}

export interface SlotWindowUpdateData {
  id: string
  attributes: {
    startsAt: string
    endsAt: string
  }
}

export interface SlotWindowDeleteData {
  id: string
}

export const getEnrolledTeams = (
  metaseasonId: string,
  // The actual type got pretty ugly due to the amount of subfield selections
  // globbed with team.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teams: EnrollmentTeamType[]
): { leagueId: string; teamId: string }[] => {
  const teamsEnrolledInMetaseason = teams.filter(
    (team): boolean =>
      team?.enrolledSeasons?.some(
        (season) => season.metaseason?.id === metaseasonId
      ) ?? false
  )

  const teamLeagueEntries = teamsEnrolledInMetaseason.reduce(
    (acc: { teamId: string; leagueId: string }[], team) => {
      team.leagues?.forEach((teamLeague: { id: string }) => {
        acc.push({
          teamId: team.id,
          leagueId: teamLeague.id,
        })
      })
      return acc
    },
    []
  )

  return teamLeagueEntries
}

export function isTeamEnrolledInMetaseason(
  metaseasonId: string | null,
  team?: TeamWithEnrolledSeason | null
): boolean {
  if (!team?.enrolledSeasons?.length) {
    return false
  }
  return team?.enrolledSeasons?.some(
    (season) => season.metaseasonId === metaseasonId
  )
}
