import React from 'react'
import { AppPage, PublicPage } from '@plvs/rally/pages/page'
import { LeagueDetails } from '@plvs/rally/features/explore/LeagueDetails'
import { Navigate, useLocation } from 'react-router-dom'
import { AllMetaseasons } from '@plvs/rally/containers/filter/league/AllMetaseasons'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Path } from '@plvs/const'

export const LeagueDetailsPage: React.FC = () => {
  const location = useLocation()
  const isPublic = !location.pathname.includes('/app')

  const { userId, loading } = useUserIdentityFn()

  // if logged in already, redirect users back to /app url
  if (isPublic && userId && !loading) {
    const prependApp = `${Path.App}${location.pathname}`

    return <Navigate to={prependApp} />
  }

  return (
    <AllMetaseasons>
      {isPublic ? (
        <PublicPage
          description="Public Leage Page"
          hasDrawer
          title="Competitions"
        >
          <LeagueDetails isPublic={isPublic} />
        </PublicPage>
      ) : (
        <AppPage title="Competitions">
          <LeagueDetails isPublic={isPublic} />
        </AppPage>
      )}
    </AllMetaseasons>
  )
}
