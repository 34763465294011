export enum SponsorshipImageName {
  BannerLogo = 'bannerLogo',
  DarkModeBannerLogo = 'darkModeBannerLogo',
  MobileBannerLogo = 'mobileBannerLogo',
  DarkModeMobileBannerLogo = 'darkModeMobileBannerLogo',
  DarkModeMobileRightSideLogo = 'darkModeMobileRightSideLogo',
  DarkModeMobileSectionLogo = 'darkModeMobileSectionLogo',
  DarkModeMobileWidgetLogo = 'darkModeMobileWidgetLogo',
  DarkModeRightSideLogo = 'darkModeRightSideLogo',
  DarkModeSectionLogo = 'darkModeSectionLogo',
  DarkModeWidgetLogo = 'darkModeWidgetLogo',
  MobileRightSideLogo = 'mobileRightSideLogo',
  MobileSectionLogo = 'mobileSectionLogo',
  MobileWidgetLogo = 'mobileWidgetLogo',
  RightSideLogo = 'rightSideLogo',
  SectionLogo = 'sectionLogo',
  WidgetLogo = 'widgetLogo',
  Banner = 'banner',
  DarkModeBanner = 'darkModeBanner',
  MobileBanner = 'mobileBanner',
  DarkModeMobileBanner = 'darkModeMobileBanner',
}

export enum SponsorshipComponentName {
  DashboardBanner = 'dashboardBanner',
  MatchLobbyBanner = 'matchLobbyBanner',
  MatchLobbyLogo = 'matchLobbyLogo',
}
