import { yupResolver } from '@hookform/resolvers'
import { Box, Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import {
  useGetMySchoolDetailsQuery,
  useUpdateSchoolDetailMutation,
  refetchGetMySchoolDetailsQuery,
} from '@plvs/graphql/generated'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import { useWithSinglePendingRequest } from '@plvs/rally/libs/request-utils/useWithSinglePendingRequest'
import {
  assert,
  formErrorToString,
  isValidPhoneNumber,
  recordDiffRight,
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPhoneNumber,
} from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { equals, pick } from 'ramda'
import React, { FunctionComponent, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { NxTypography, NxTextInput } from '@playvs-inc/nexus-components'

interface ItContactFields {
  fname: string
  lname: string
  email: string
  phone: string
}

const ItContactSchema = yup.object().shape({
  email: yupEmail,
  fname: yupFirstName,
  lname: yupLastName,
  phone: yupPhoneNumber.test({
    name: 'phone',
    message: 'Please enter a valid phone number',
    /* istanbul ignore next */
    test: isValidPhoneNumber,
  }),
})

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: `${theme.spacing(3, 3, 2)} !important`,
  },
  inputContainer: {
    maxWidth: '350px',
    paddingBottom: `${theme.spacing(0.5)}px !important`,
    paddingTop: `0px !important`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export const ItContactInfoFormPanel: FunctionComponent = () => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()
  const withSaveNotification = useWithSaveNotification()
  const withSinglePendingRequest = useWithSinglePendingRequest()
  const classes = useStyles()

  const formRef = useRef<HTMLFormElement>(null)

  // Queries
  const { data } = useGetMySchoolDetailsQuery()

  const { errors, handleSubmit, register, setError, getValues } = useForm<
    ItContactFields
  >({
    defaultValues: {
      fname: data?.me?.school?.detail?.it?.suppStaff?.fname ?? '',
      lname: data?.me?.school?.detail?.it?.suppStaff?.lname ?? '',
      email: data?.me?.school?.detail?.it?.suppStaff?.email ?? '',
      phone: data?.me?.school?.detail?.it?.suppStaff?.phone ?? '',
    },
    resolver: yupResolver<ItContactFields>(ItContactSchema),
  })

  // Mutations
  const [mutateSchoolDetails] = useUpdateSchoolDetailMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  // Form handlers

  const onBlur = useCallback(async (): Promise<void> => {
    const values = getValues()
    const oldValues = pick(
      ['fname', 'lname', 'email', 'phone'],
      data?.me?.school?.detail?.it?.suppStaff ?? {}
    )

    const hasChanges = !equals(oldValues, values)

    if (!hasChanges) {
      return
    }

    try {
      await ItContactSchema.validate(values)
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const firstError = err.errors[0]
        enqueueSnackbar(firstError, {
          variant: 'error',
          transitionDuration: {
            appear: 5000,
          },
        })

        setError(err.path as keyof ItContactFields, {
          message: firstError,
          type: err.type,
        })
        return
      }
      throw err // otherwise throw and let sentry error capture
    }

    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }, [getValues, enqueueSnackbar, setError, data])

  const onSubmit = useCallback(
    handleSubmit(
      async (values: ItContactFields): Promise<void> => {
        const oldValues = pick(
          ['fname', 'lname', 'email', 'phone'],
          data?.me?.school?.detail?.it?.suppStaff ?? {}
        )

        const changedValues = recordDiffRight(
          oldValues,
          (values as unknown) as Record<string, unknown>
        )

        if (Object.keys(changedValues).length < 1) {
          return
        }
        withSinglePendingRequest(
          withSaveNotification(
            async (): Promise<void> => {
              const schoolId = data?.me?.school?.id
              assert(schoolId)
              await mutateSchoolDetails({
                variables: {
                  schoolId,
                  detail: {
                    it: {
                      suppStaff: {
                        ...changedValues,
                      },
                    },
                  },
                },
              })
            }
          )
        )
      }
    ),
    [
      withSaveNotification,
      useWithSinglePendingRequest,
      mutateSchoolDetails,
      data,
    ]
  )

  return (
    <form ref={formRef} noValidate onBlur={onBlur} onSubmit={onSubmit}>
      <Card>
        <CardContent className={classes.cardContainer}>
          <Box mb={2}>
            <NxTypography variant="h4">IT Contact Info</NxTypography>
          </Box>
          <Grid container spacing={2}>
            <Grid className={classes.inputContainer} item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                fullWidth
                helperText={formErrorToString(errors?.fname)}
                label="First Name"
                name="fname"
                variant={errors.fname ? 'error' : 'default'}
              />
            </Grid>
            <Grid className={classes.inputContainer} item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                fullWidth
                helperText={formErrorToString(errors?.lname)}
                label="Last Name"
                name="lname"
                variant={errors.lname ? 'error' : 'default'}
              />
            </Grid>
            <Grid className={classes.inputContainer} item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                data-cy="ITEmail"
                fullWidth
                helperText={formErrorToString(errors?.email)}
                label="Email"
                name="email"
                type="email"
                variant={errors.email ? 'error' : 'default'}
              />
            </Grid>
            <Grid className={classes.inputContainer} item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                data-cy="ITPhone"
                fullWidth
                helperText={formErrorToString(errors?.phone)}
                label="Phone Number"
                name="phone"
                variant={errors.phone ? 'error' : 'default'}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}
