import React from 'react'
import {
  CompetitionGroup,
  Esport,
  EsportRating,
  Maybe,
  School,
  Season,
  Team,
  TeamEnrollment,
} from '@plvs/graphql'
import { sortBy } from 'ramda'
import dayjs from 'dayjs'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { Box } from '@material-ui/core'
import { assert } from '@plvs/utils'
import { Path, Param } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import TeamCard from './TeamCard'
import { MinimalLeague, MinimalTeam } from './TeamCard/TeamCard.types'
import { TeamCardLoading } from './TeamCard/TeamCard'

export const EnrolledTeamCards: React.FC<{
  baseTeams?: Array<
    Pick<Team, 'id' | 'name' | 'competitionGroup' | 'esportId'> & {
      esport: Pick<Esport, 'slug'>
      school: Maybe<Pick<School, 'logoUrl'>>
    }
  >
  teamEnrollments?: TeamEnrollment[]
  loading: boolean
  userId: string
  isCoach: boolean
}> = ({ teamEnrollments, loading, userId, isCoach, baseTeams }) => {
  const navigate = useNavigate()
  const { metaseason, promotedMetaseason } = useSchoolLeagueInfoContext()

  return !loading ? (
    <Box display="flex">
      {teamEnrollments?.map((enrollment) => {
        const team = enrollment?.team
        const isOwnerOfTeam = team?.owners?.some((owner) => owner.id === userId)

        // Latest enrollable season uses the most recent season with an active registration
        // deadline.
        const registerableSeasonByRegistrationDeadline = sortBy(
          (x: Season) => dayjs(x.teamRegistrationEndsAt).valueOf(),
          enrollment?.enrollableSeasons as Season[]
        )
        const today = dayjs()
        const filteredSeasonsByRegistrationDeadline = registerableSeasonByRegistrationDeadline
          .filter((x) => !!x.teamRegistrationEndsAt && !!x.registrationStartsAt)
          .filter((x) => today.isBefore(dayjs(x.teamRegistrationEndsAt)))
          .filter((x) => today.isAfter(dayjs(x.registrationStartsAt)))

        // Use the last registerable season as the basis for the
        // days left to register and enrollment button state.
        const lastRegisterableSeason =
          filteredSeasonsByRegistrationDeadline[
            filteredSeasonsByRegistrationDeadline.length - 1
          ]
        const lastEnrolledSeason =
          enrollment?.enrolledSeasons[enrollment.enrolledSeasons.length - 1]

        const targetEnrollmentSeason =
          lastRegisterableSeason ?? lastEnrolledSeason

        const suggestedRegistrationEndsAt =
          lastRegisterableSeason?.suggestedRegistrationEndsAt ??
          lastEnrolledSeason?.suggestedRegistrationEndsAt

        const isYouthLeagueTeam =
          team?.competitionGroup === CompetitionGroup.Youth

        const teamEnrolledMetaseasonIds = (
          enrollment?.team?.enrolledSeasons ?? []
        ).map((s) => s?.metaseason?.id)

        let isEnrolled = isYouthLeagueTeam
          ? !!enrollment?.enrolledSeasons[0]
          : undefined
        if (typeof isEnrolled === 'undefined') {
          if (promotedMetaseason) {
            isEnrolled = teamEnrolledMetaseasonIds.includes(
              promotedMetaseason?.id
            )
          } else {
            isEnrolled = teamEnrolledMetaseasonIds.includes(
              targetEnrollmentSeason?.metaseasonId ?? ''
            )
          }
        }

        const esportSlug = team?.esport?.slug ?? ''
        const esportRating = team?.esport?.rating || EsportRating.General
        const teamLogoUrl = team?.school?.logoUrl ?? ''

        const daysTillEnrollmentDeadline = dayjs(suggestedRegistrationEndsAt)
          .startOf('day')
          .diff(dayjs().startOf('day'), 'days')
        const daysLeftToEnroll =
          isEnrolled || daysTillEnrollmentDeadline <= 0
            ? undefined
            : daysTillEnrollmentDeadline

        const rankLabel =
          enrollment?.activePhaseRanks[0]?.rank?.toString() ?? 'TBD'
        const hasCompleteRoster =
          team?.esport?.details &&
          team?.roster?.numPlayers &&
          team.roster.numPlayers >= team?.esport?.details?.teamMinStarterSize

        let ctaState: 'fill-team' | 'enroll' | 'disabled'
        let ctaLabel: string
        if (!isOwnerOfTeam && !isCoach) {
          // does not have rights
          ctaState = 'disabled'
          ctaLabel = ''
        } else if (!isEnrolled) {
          ctaState = 'enroll'
          ctaLabel = 'Enroll'
        } else if (isEnrolled && !hasCompleteRoster) {
          ctaState = 'fill-team'
          ctaLabel = 'Fill Roster'
        } else {
          // Is already enrolled and has full roster nothing left to do.
          ctaState = 'disabled'
          ctaLabel = ''
        }

        const league = enrollment?.enrolledSeasons[0]?.league

        // CTA on Click
        return (
          <TeamCard
            key={team?.id}
            ctaEnabled={ctaState !== 'disabled'}
            ctaLabel={ctaState !== 'disabled' ? ctaLabel : ''}
            ctaOnClick={(): void => {
              switch (ctaState) {
                case 'fill-team':
                  navigate({
                    pathname: Path.ManageTeams,
                    search: `?${Param.Esport}=${esportSlug}`,
                  })
                  break
                case 'enroll':
                  assert(promotedMetaseason?.id || metaseason?.id)
                  navigate(
                    `${Path.Enrollment}/${
                      promotedMetaseason?.id ?? metaseason?.id
                    }`
                  )
                  break
                default:
                  break
              }
            }}
            daysLeftToEnroll={daysLeftToEnroll}
            esportSlug={esportSlug}
            isCoach={isCoach}
            isEnrolled={isEnrolled}
            league={league as MinimalLeague}
            rankLabel={rankLabel}
            rating={esportRating}
            team={team as MinimalTeam}
            teamLogoUrl={teamLogoUrl}
          />
        )
      })}
      {baseTeams
        ?.filter(
          (team) =>
            !teamEnrollments?.some(
              (enrolledTeam) => enrolledTeam.team.id === team.id
            )
        )
        ?.map((team) => {
          return (
            <TeamCard
              key={team.id}
              ctaEnabled={false}
              ctaLabel=""
              esportSlug={team.esport.slug}
              isCoach={isCoach}
              isEnrolled
              rankLabel="TBD"
              team={team as MinimalTeam}
              teamLogoUrl={team?.school?.logoUrl ?? ''}
            />
          )
        })}
    </Box>
  ) : (
    <>
      {[1, 2, 3].map((t) => (
        <TeamCardLoading key={t} />
      ))}
    </>
  )
}
