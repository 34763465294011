import React from 'react'
import { useQuery } from '@apollo/client'

import {
  MyRolesQuery,
  MyRolesDocument,
  UserRoleName,
  ResourceType,
} from '@plvs/graphql'

import {
  LeaderboardFilter,
  useLeaderboardFilter,
} from '@plvs/rally/components/filter'
import { hasRoleForResourceType } from '@plvs/utils'

export function useMyLeaderboardFilter(): {
  leaderboardFilter: React.ReactElement
  selectedLeaderboardFilter: LeaderboardFilter
} {
  const { data, loading } = useQuery<MyRolesQuery>(MyRolesDocument)

  const { leaderboardFilter, selectedLeaderboardFilter } = useLeaderboardFilter(
    {
      hasTeams: hasRoleForResourceType(
        data?.me?.roles ?? [],
        UserRoleName.Player,
        ResourceType.Team
      ),
      loading,
    }
  )

  return {
    leaderboardFilter,
    selectedLeaderboardFilter,
  }
}
