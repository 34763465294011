import React from 'react'

import { NxAlert, NxTypography } from '@playvs-inc/nexus-components'

import { BoxProps } from '@plvs/respawn/features/layout'

export enum BannerType {
  Error = 'error',
  Warning = 'warn',
  Success = 'success',
}

interface BannerProps extends BoxProps {
  title: string
  subtitle?: string | React.ReactElement
  type: BannerType
}

export const Banner: React.FC<BannerProps> = ({
  title,
  subtitle,
  type,
  children,
  ...props
}) => {
  return (
    <NxAlert
      actions={children as React.ReactElement}
      canClose
      content={
        typeof subtitle === 'string' ? (
          <NxTypography
            colorToken="ColorTextAlt2"
            data-testid="Banner_Subtitle"
            variant="body1"
          >
            {subtitle}
          </NxTypography>
        ) : (
          subtitle || ''
        )
      }
      title={
        <NxTypography data-testid="Banner_Title" variant="h4">
          {title}
        </NxTypography>
      }
      variant={type ?? 'success'}
      {...props}
    />
  )
}
