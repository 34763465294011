import { useContext } from 'react'
import {
  UseSchoolLeagueInfoReturn,
  LeagueFilterContext,
} from '@plvs/respawn/features/filters/types'
import { leagueContext } from './context'

export const useSchoolLeagueInfoContext = (): UseSchoolLeagueInfoReturn => {
  const {
    competitionModel,
    defaultPhases,
    esportSlug,
    metaseason,
    promotedMetaseason,
    league,
    leagues,
    loading,
    phase,
    phases,
    season,
    metaseasons,
    team,
    setTeam,
    teams,
    setMetaseason,
    setLeague,
    initialMetaseasonSelected,
  } = useContext(leagueContext)
  return {
    competitionModel,
    defaultPhases,
    esportSlug,
    metaseason,
    promotedMetaseason,
    league,
    leagues,
    loading,
    phase,
    phases,
    season,
    team,
    setTeam,
    teams,
    metaseasons,
    setMetaseason,
    setLeague,
    initialMetaseasonSelected,
  }
}

export const useLeagueConfig = (): LeagueFilterContext =>
  useContext(leagueContext)
