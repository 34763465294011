import { InputLabel, makeStyles, FormHelperText } from '@material-ui/core'
import { Attachment as AttachmentIcon } from '@material-ui/icons'
import { includes } from 'ramda'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

import { MBToBytesMap } from '@plvs/const'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  AttachScreenshotAPI,
  UploadMutationHook,
  acceptableFileTypes,
  addAttachment,
  getAssetFromUploadMutationResult,
  ScreenshotSizeLimit,
} from './attachHelpers'

const useStyles = makeStyles((theme) => ({
  attachment: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  icon: {
    marginLeft: theme.spacing(-0.25),
    marginRight: theme.spacing(1),
  },
  label: {
    alignItems: 'center',
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderRadius: 8,
    color: theme.palette.ColorTextAlt,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    padding: theme.spacing(0.975, 2),
    '& .Mui-disabled': {
      cursor: 'default',
    },
  },
  helperText: {
    marginLeft: theme.spacing(1.75),
  },
}))

type AttachScreenshotButtonProps = AttachScreenshotAPI & {
  screenshotSizeLimit: ScreenshotSizeLimit
  uploadAssetMutation: UploadMutationHook
  formErrorString?: string
}

export const AttachScreenshotButton: React.FC<AttachScreenshotButtonProps> = ({
  attachments,
  screenshotSizeLimit,
  setAttachments,
  uploadAssetMutation,
  formErrorString,
}) => {
  const inputRef = React.useRef<HTMLInputElement>()
  const [error, setError] = React.useState<Error>()
  const classes = useStyles()
  const [mutate, { loading }] = uploadAssetMutation()

  const onChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    try {
      const file = event?.target?.files?.[0]
      if (file) {
        const { name, size, type } = file

        if (size > MBToBytesMap[screenshotSizeLimit]) {
          throw new Error(`File size must be less than ${screenshotSizeLimit}`)
        }
        if (includes(type, acceptableFileTypes)) {
          const result = await mutate({
            awaitRefetchQueries: true,
            variables: { file },
          })

          const asset = getAssetFromUploadMutationResult(result)

          if (asset)
            addAttachment({
              attachments,
              attachmentToAdd: { ...asset, fileName: name },
              setAttachments,
            })
        } else {
          throw new Error('File must be jpeg or png.')
        }
      } else {
        // Users hit this else block when they hit the Cancel button in the
        //  system file picker dialog. We don't need to do anything here.
      }
    } catch (err: any) {
      setError(err)
    }

    // Resets input[type='file'] to prevent issue where onChange isn't triggered
    // when re-attaching a removed screenshot
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className={classes.attachment}>
      <div>
        <InputLabel // eslint-disable-line jsx-a11y/label-has-associated-control
          // A fake button that forwards clicks to the Input inside.
          className={classes.label}
          disabled={loading}
          error={!!error}
        >
          <WaitTillLoaded
            loading={loading}
            loadingSpinnerProps={{ size: 'medium' }}
          >
            <AttachmentIcon className={classes.icon} />
          </WaitTillLoaded>

          <NxTypography variant="button">
            {loading ? 'Uploading' : 'Attach Screenshot'}
          </NxTypography>
          <input
            // @ts-ignore HTMLInputElement is a valid ref type
            ref={inputRef}
            // Handles opening of the system file picker dialog.
            accept={acceptableFileTypes.join(',')}
            disabled={loading}
            onChange={onChange}
            style={{ display: 'none' }}
            type="file"
          />
        </InputLabel>
        {error && (
          <FormHelperText className={classes.helperText} error={!!error}>
            {error.message}
          </FormHelperText>
        )}
        {formErrorString && (
          <FormHelperText
            className={classes.helperText}
            error={!!formErrorString}
          >
            {formErrorString}
          </FormHelperText>
        )}
      </div>
    </div>
  )
}
