import React from 'react'
import { TextField, useTheme, Box } from '@material-ui/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { NxButton } from '@playvs-inc/nexus-components'

import {
  NativeSelectField,
  Attachment,
  AttachScreenshot,
} from '@plvs/rally/components/form'
import {
  CreateMatchDisputeRequestDocument,
  CreateMatchDisputeRequestMutation,
  CreateMatchDisputeRequestMutationVariables,
  CoachSettleMatchInfoQuery,
  MatchDisputeReason,
  useDeleteMatchDisputeRequestAssetMutation,
  useUploadMatchDisputeRequestAssetMutation,
} from '@plvs/graphql'
import { Path } from '@plvs/const'
import { useDefaultForm } from '@plvs/rally/core/apollo'
import { yupNote } from '@plvs/utils'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { getRefetchMatchLobbyQueries } from '@plvs/rally/containers/match'
import { MatchReportReturnToMatchButton } from './MatchReportReturnToMatchButton'
import { MatchReportErrorBanner } from './MatchReportErrorBanner'

type CoachSettleMatch = NonNullable<CoachSettleMatchInfoQuery['match']>

type DisputeFormInput = {
  attachments: Attachment[]
  notes: string
  reason: MatchDisputeReason
}

export const printReason: Record<MatchDisputeReason, string> = {
  [MatchDisputeReason.Incorrect]: 'Incorrect scoring',
  [MatchDisputeReason.Unplayed]: 'This match wasn‘t played',
  [MatchDisputeReason.Completed]: 'This match was completed',
  [MatchDisputeReason.Other]: 'Other',
}

const reasonValues = Object.values(MatchDisputeReason)

const reasonOptions = reasonValues.map((value) => (
  <option key={value} value={value}>
    {printReason[value]}
  </option>
))

const validationSchema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        fileName: yup.string(),
        id: yup.string(),
        signedUrl: yup.string(),
      })
    )
    .required('Screenshot is required.'),
  notes: yupNote,
  reason: yup.mixed().oneOf(reasonValues, 'Please select a reason.'),
})

export const MatchDisputeForm: React.FC<{
  match: CoachSettleMatch
  myTeamId: string
  success: boolean
  setSuccess(success: boolean): void
}> = ({ match, myTeamId, success, setSuccess }) => {
  const theme = useTheme()
  const {
    error,
    formErrorStrings,
    onSubmit,
    register,
    setValue,
    unregister,
    watch,
  } = useDefaultForm<
    DisputeFormInput,
    CreateMatchDisputeRequestMutation,
    CreateMatchDisputeRequestMutationVariables
  >({
    MutationDocument: CreateMatchDisputeRequestDocument,
    onSuccess: () => {
      setSuccess(true)
    },
    refetchQueries: [...getRefetchMatchLobbyQueries(match.id)],
    variables: ({ notes, reason, attachments }) => {
      return {
        input: {
          notes,
          reason,
          matchId: match.id,
          teamId: myTeamId,
          evidence: attachments?.map(({ id }) => ({ assetId: id })),
        },
      }
    },
    useFormProps: {
      defaultValues: {},
      resolver: yupResolver<DisputeFormInput>(validationSchema),
    },
  })

  const attachmentFormName = 'attachments'
  const attachments = watch(attachmentFormName)
  const setAttachments = (value: Attachment[]): void => {
    setValue(attachmentFormName, value)
  }

  const pathToMatchMissionControl = `${Path.Match}/${match.id}/mission-control`

  return (
    <div>
      {error && <MatchReportErrorBanner subtitle={error.message} />}
      <>
        {success ? (
          <div style={{ marginBottom: 24 }}>
            <Banner
              data-cy="gameResultsSubmittedBanner"
              subtitle="The opposing team will be notified."
              title="Game results submitted"
              type={BannerType.Success}
            >
              <MatchReportReturnToMatchButton matchId={match.id} />
            </Banner>
          </div>
        ) : (
          <form noValidate onSubmit={onSubmit} style={{ maxWidth: 400 }}>
            <div>
              <NativeSelectField
                error={!!formErrorStrings.reason}
                fullWidth
                helperText={formErrorStrings.reason}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Reason for dispute"
                name="reason"
              >
                <option
                  aria-label="Select a reason"
                  placeholder="Select a reason"
                  value=""
                />
                {reasonOptions}
              </NativeSelectField>
            </div>
            <Box mt={3}>
              <TextField
                error={!!formErrorStrings.notes}
                fullWidth
                helperText={formErrorStrings.notes}
                inputRef={register}
                label="Tell us what happened"
                multiline
                name="notes"
                rows="6"
                type="search"
                variant="filled"
              />
            </Box>
            <Box mt={3}>
              <AttachScreenshot
                attachments={attachments}
                deleteAssetMutation={useDeleteMatchDisputeRequestAssetMutation}
                formErrorString={formErrorStrings.attachments}
                name={attachmentFormName}
                register={register}
                setAttachments={setAttachments}
                unregister={unregister}
                uploadAssetMutation={useUploadMatchDisputeRequestAssetMutation}
              />
            </Box>
            <Box
              alignItems="center"
              display="flex"
              gridGap={theme.spacing(2)}
              mt={3}
            >
              <NxButton
                data-cy="submitDisputeButton"
                label="Submit Dispute"
                type="submit"
                variant="primary"
              />
              <NxButton
                href={pathToMatchMissionControl}
                label="Cancel"
                variant="secondary"
              />
            </Box>
          </form>
        )}
      </>
    </div>
  )
}
