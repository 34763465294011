import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import {
  ReplaceTeamCoachDocument,
  ReplaceTeamCoachMutation,
  ReplaceTeamCoachMutationVariables,
  useGetSchoolCoachesBySchoolIdQuery,
} from '@plvs/graphql'
import { useAutoskipQuery } from '@plvs/utils'
import { makeStyles } from '@material-ui/core/styles'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxAlert, NxModal } from '@playvs-inc/nexus-components'
import { useDefaultMutation } from '../apollo/useDefaultMutation'
import { TeamCoach } from '../roster/roster.types'
import { RosterRowGroup } from '../roster/modal/RosterRowGroup'

const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginBottom: theme.spacing(1),
  },
  label: {
    color: theme.palette.ColorTextBase,
  },
  content: {
    '& .MuiDialogContent-root': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
    maxHeight: '796px',
    overflow: 'auto',
  },
}))

export const filterOutCurrentCoaches = (
  allCoaches: TeamCoach[],
  currentCoaches: TeamCoach[]
): TeamCoach[] => {
  const coachSet = new Set(currentCoaches.map(({ id }) => id))
  return allCoaches.filter(({ id }) => !coachSet.has(id))
}

export interface SwapCoachModalProps {
  onClose(): void
  coach: TeamCoach
  onMutationSuccess(): void
  teamId: string
  schoolId: string
  allTeamCoaches: TeamCoach[]
}

export const SwapCoachModal: React.FC<SwapCoachModalProps> = ({
  onClose,
  coach,
  onMutationSuccess,
  teamId,
  schoolId,
  allTeamCoaches,
}) => {
  const classes = useStyles()
  const [coachSelected, setCoachSelected] = useState<{
    id: string
  }>({ id: '' })
  const [replaceTeamCoach, { errorString, loading: replaceTeamCoachLoading }] =
    useDefaultMutation<
      { id: string },
      ReplaceTeamCoachMutation,
      ReplaceTeamCoachMutationVariables
    >({
      MutationDocument: ReplaceTeamCoachDocument,
      onSuccess: () => {
        onClose()
        onMutationSuccess()
      },
      snackError: 'Failed to replace coach.',
      snackSuccess: 'Coach replaced!',
      variables: (newMember) => ({
        teamId,
        existingCoachUserId: coach.id,
        newCoachUserId: newMember.id,
      }),
    })
  const { data, loading: loadingCoaches } = useAutoskipQuery(
    useGetSchoolCoachesBySchoolIdQuery,
    {
      variables: {
        schoolId,
      },
    }
  )

  const availableCoaches = filterOutCurrentCoaches(
    data?.school?.coaches || [],
    allTeamCoaches
  ).map((ac) => ({
    title: ac.name ?? '',
    avatarSrc: ac.avatarUrl ?? '',
    id: ac.id ?? '',
    subtitle: ac.email ?? '',
  }))

  const onChange = (id: string): void => {
    if (coachSelected.id === id) {
      setCoachSelected({ id: '' })
    } else {
      setCoachSelected({ id })
    }
  }

  const noCoaches = !availableCoaches.length

  return (
    <NxModal
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            data-testid="SwapCoachModal_Button"
            disabled={replaceTeamCoachLoading}
            onClick={async (): Promise<void> => {
              await replaceTeamCoach({ id: coachSelected.id })
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </>
      }
      className={classes.content}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open
      subtitle={`Who would you like to swap ${
        coach?.name ?? 'this coach'
      } with?`}
      title="Change Position"
    >
      <WaitTillLoaded
        loading={loadingCoaches}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {errorString && (
          <Box py={2}>
            <NxAlert
              canClose
              content={errorString}
              title="Unable to change positions"
              variant="error"
            />
          </Box>
        )}
        {noCoaches ? (
          <NxAlert
            canClose
            content="There are no other coaches at your school."
            title="Unable to Replace Coach"
            variant="error"
          />
        ) : (
          <RosterRowGroup
            onChange={onChange}
            rowEntries={availableCoaches}
            selectedId={coachSelected.id}
          />
        )}
      </WaitTillLoaded>
    </NxModal>
  )
}
