import React from 'react'
import { Box, useTheme } from '@material-ui/core'

import { Google } from '@playvs-inc/nexus-icons'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'

type OAuthProps = {
  isEnabled: boolean
  shouldHide: boolean
  isBusy: boolean
  onOAuth: () => void
}

export const OAuth: React.FC<OAuthProps> = ({
  isEnabled,
  shouldHide,
  isBusy,
  onOAuth,
}) => {
  const theme = useTheme()

  if (!isEnabled || shouldHide) {
    return null
  }

  return (
    <>
      <Box height={theme.spacing(5.5)}>
        <NxIconButton
          data-testid="google-oauth"
          disabled={isBusy}
          fullWidth
          icon={<Google />}
          label="Sign In With Google"
          onClick={onOAuth}
          size="large"
          variant="secondary"
        />
      </Box>
      <Box mb={2} mt={2}>
        <NxTypography variant="subtitle2">OR</NxTypography>
      </Box>
    </>
  )
}
