import React, { useState, useEffect } from 'react'
import { isNil } from 'ramda'
import { Box, TextField, useTheme } from '@material-ui/core'

import {
  NxTypography,
  NxButton,
  NxFirebobMessage,
  NxSelectableSingle,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import {
  MatchSettleRequestFragment,
  MatchSettleRequestStatus,
  useAdminSaveGameResultLiveMutation,
  useFetchMatchSettleRequestQuery,
  useMatchSettleRequestSubmittedSubscription,
  useMatchSettleRequestUpdatedSubscription,
  useSaveGameResultLiveMutation,
} from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchAssistantContext } from '../../useMatchAssistant'
import { useStyles } from './Reporting.styles'
import {
  FirebobMessage,
  getInvalidScores,
  submitGameResults,
  TeamSide,
  updateStateOnMatchSettleRequestChange,
} from './Reporting.helpers'

import { MatchAssistantFooter } from '../../MatchAssistantFooter'
import { TriggerMatchAssistantAlertsButton } from '../../../dynamic/buttons/TriggerMatchAssistantAlertsButton'
import { useMatchAssistantStepAlertsContext } from '../../hooks/useMatchAssistantStepAlerts'

export const TeamReporting: React.FC = () => {
  const theme = useTheme()
  const { userId } = useUserIdentityFn()

  const {
    currentStep,
    refetchCurrentGameAssistantStepByMatchId,
    homeTeam,
    awayTeam,
    homeTeamId,
    awayTeamId,
    match,
    teamId: myTeamId = '',
    setStepIndex,
    setSubmittedUserIdForNPS,
    stepIndex,
    gameMode,
    selectedStage,
    isInCheckpoint,
    hasScores,
  } = useMatchAssistantContext()
  const classes = useStyles({ hasCharacterAsset: false })
  /// ///////
  // State //
  /// ///////
  const [selectedTeamSide, setSelectedTeamSide] = useState<TeamSide | -1>(-1)
  const [gameOrdinalState] = useState(currentStep?.step?.gameOrdinal)
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)
  const [firebobMessage, setFirebobMessage] = useState<FirebobMessage>(
    FirebobMessage.Hmm
  )
  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)
  const [homeScore, setHomeScore] = useState<string>()
  const [awayScore, setAwayScore] = useState<string>()

  /// /////////
  // QUERIES //
  /// /////////
  const { data: incomingMatchSettleRequest } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId: match?.id,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !(match?.id || userId),
    fetchPolicy: 'network-only',
  })

  /// ///////////
  // MUTATIONS //
  /// ///////////
  const [saveGameResultLive] = useSaveGameResultLiveMutation()
  const [adminSaveGameResultLive] = useAdminSaveGameResultLiveMutation()

  const saveGameResult = isInCheckpoint
    ? adminSaveGameResultLive
    : saveGameResultLive

  /// ///////////////
  // SUBSCRIPTIONS //
  /// ///////////////
  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId: match?.id ?? '',
      },
    },
    skip: !match?.id,
  })

  useMatchSettleRequestSubmittedSubscription({
    variables: {
      input: {
        matchSettleRequestId: matchSettleRequest?.id ?? '',
      },
    },
    skip: !matchSettleRequest?.id,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]

    if (returnedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: returnedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled: false,
        gameAssistantStepHasBeenUpdated: false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(returnedMatchSettleRequest)
    } else {
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest, stepIndex])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest

    if (updatedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: updatedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.matchHasBeenAutoSettled ?? false,
        gameAssistantStepHasBeenUpdated:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.gameAssistantStepHasBeenUpdated ?? false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
    }
  }, [matchSettleRequestUpdated, stepIndex])

  const { getStepAlerts, openStepAlert } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  useEffect(() => {
    if (
      gameOrdinalState &&
      currentStep?.step?.gameOrdinal !== gameOrdinalState
    ) {
      setStepIndex(0)
    }
  }, [gameOrdinalState, currentStep?.step?.gameOrdinal])

  const { invalidScores, errorMessage } = getInvalidScores(
    hasScores,
    Number(homeScore),
    Number(awayScore)
  )
  const derivedFirebobMessage =
    (invalidScores && errorMessage) || firebobMessage
  const isNextDisabled = selectedTeamSide === -1 || invalidScores

  useEffect(
    function updateSelectionOnScoreChange(): void {
      if (!hasScores) {
        return
      }

      if (!invalidScores) {
        setSelectedTeamSide(
          Number(homeScore) > Number(awayScore)
            ? TeamSide.homeTeam
            : TeamSide.awayTeam
        )
      } else {
        setSelectedTeamSide(-1)
      }
    },
    [hasScores, homeScore, awayScore]
  )

  return (
    <Box className={classes.container}>
      <NxTypography variant="h1">
        Who won Game {currentStep?.step?.gameOrdinal}?
      </NxTypography>

      {gameMode && (
        <Box textAlign="center">
          <NxTypography colorToken="ColorTextAlt2" variant="overline">
            {gameMode}
          </NxTypography>
          <NxTypography variant="h4">
            {selectedStage?.selectionOption?.displayName}
          </NxTypography>
        </Box>
      )}

      <NxSelectableSingle
        height={`${theme.spacing(11)}px`}
        hideCheckmark
        onChange={(_, newValue): void => {
          if (hasScores) {
            return
          }
          setSelectedTeamSide(newValue)
        }}
        outerContainerClassName={classes.outerContainer}
        selectableContainerClassName={classes.selectableContainer}
        value={selectedTeamSide}
        width="100%"
      >
        <Box key="home-team" className={classes.selectable}>
          <Box className={classes.playerGradient} />
          <NxUserCluster
            avatarHashId={homeTeam?.id ?? ''}
            avatarUrl={homeTeam?.avatarUrl ?? undefined}
            className={classes.userCluster}
            compact
            subtitles={[{ title: homeTeam?.school?.name }]}
            title={homeTeam?.name ?? '??'}
          />

          {hasScores && (
            <Box display="flex" flex={1} justifyContent="flex-end">
              <TextField
                className={classes.scoreInput}
                inputProps={{ min: 0 }}
                label="Score"
                name="homeScore"
                onChange={(evnt): void => {
                  setHomeScore(evnt.target.value)
                }}
                type="number"
                value={homeScore}
                variant="outlined"
              />
            </Box>
          )}
        </Box>

        <Box key="away-team" className={classes.selectable}>
          <Box className={classes.opponentGradient} />
          <NxUserCluster
            avatarHashId={awayTeam?.id ?? ''}
            avatarUrl={awayTeam?.avatarUrl ?? undefined}
            className={classes.userCluster}
            compact
            subtitles={[{ title: awayTeam?.school?.name }]}
            title={awayTeam?.name ?? '??'}
          />

          {hasScores && (
            <Box display="flex" flex={1} justifyContent="flex-end">
              <TextField
                className={classes.scoreInput}
                inputProps={{ min: 0 }}
                label="Score"
                name="awayScore"
                onChange={(evnt): void => {
                  setAwayScore(evnt.target.value)
                }}
                type="number"
                value={awayScore}
                variant="outlined"
              />
            </Box>
          )}
        </Box>
      </NxSelectableSingle>

      <MatchAssistantFooter>
        <NxFirebobMessage message={derivedFirebobMessage} />

        <Box
          display="flex"
          gridGap={theme.spacing(2)}
          justifyContent="space-between"
          width="100%"
        >
          <TriggerMatchAssistantAlertsButton
            disabled={stepAlertsData.actionLoading}
            label="Change Roster"
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label={
              firebobMessage === FirebobMessage.RejectedSubmission
                ? 'Resubmit'
                : 'Submit'
            }
            loading={
              loadingMatchSettleRequest ||
              firebobMessage === FirebobMessage.WaitingOnOpp
            }
            onClick={async (): Promise<void> => {
              setSubmittedUserIdForNPS(userId)
              if (!isNil(selectedTeamSide) && currentStep && match) {
                await submitGameResults({
                  selectedTeamSide,
                  homeTeamId,
                  awayTeamId,
                  userId,
                  matchId: match?.id ?? '',
                  seriesId: currentStep?.step?.seriesId || undefined,
                  seriesOrdinal: currentStep?.step?.seriesOrdinal || undefined,
                  gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
                  myTeamId,
                  saveGameResultLive: saveGameResult,
                  setIsLoadingMatchSettleRequest,
                  homeTeamIds: homeTeam?.starters?.map(({ id }) => id) || [],
                  awayTeamIds: awayTeam?.starters?.map(({ id }) => id) || [],
                  isAdmin: isInCheckpoint,
                  homeScore: Number(homeScore),
                  awayScore: Number(awayScore),
                })
              }
            }}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </Box>
  )
}
