import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const Dollar = createSvgIcon(
  <>
    <svg
      fill="none"
      height="35"
      viewBox="0 0 50 35"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.5 0H0V2.5V16.4286V22.5V32.5V35H2.5H12.5H47.5H50V32.5V12.5V2.5V0H47.5H37.5H2.5ZM35.0316 5H5V16.4286V20.0316C10.3187 20.4362 14.5638 24.6813 14.9684 30H45V14.9684C39.6813 14.5638 35.4362 10.3187 35.0316 5ZM45 9.94093C42.4453 9.5755 40.4245 7.55474 40.0591 5H45V9.94093ZM5 30V25.0591C7.55474 25.4245 9.5755 27.4453 9.94093 30H5ZM25 25C29.1421 25 32.5 21.6421 32.5 17.5C32.5 13.3579 29.1421 10 25 10C20.8579 10 17.5 13.3579 17.5 17.5C17.5 21.6421 20.8579 25 25 25Z"
        fill="#2AAA2B"
        fillRule="evenodd"
      />
    </svg>
  </>,
  'dollar-bill-icon',
  { viewBox: '0 0 50 35' }
)
