import { useState } from 'react'

type SkipMatchAssistantReturn = {
  skipMatchAssistant(): Promise<void>
  hasSkippedMatchAssistant: Record<string, boolean>
}

export function useSkipMatchAssistant(
  matchId: string = ''
): SkipMatchAssistantReturn {
  const [hasSkippedMatchAssistant, setHasSkippedMatchAssistant] = useState<
    Record<string, boolean>
  >({
    [matchId]: false,
  })

  const skipMatchAssistant = async (): Promise<void> => {
    setHasSkippedMatchAssistant({
      ...hasSkippedMatchAssistant,
      [matchId]: true,
    })
  }

  return { hasSkippedMatchAssistant, skipMatchAssistant }
}
