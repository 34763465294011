import React from 'react'
import clsx from 'clsx'
import Handbook from '@plvs/rally/assets/coachResources/handbook.png'
import HandbookMobile from '@plvs/rally/assets/coachResources/handbook-mobile.jpg'
import EnrollmentInstructions from '@plvs/rally/assets/coachResources/enrollment-instructions.jpg'
import { Grid, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { EsportSlug } from '@plvs/graphql/types'
import { Box } from '@plvs/respawn/features/layout/Box'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { IntercomArticleMappings } from '@plvs/const'
import { PLAYVS_HELP_ARTICLES_BASE_URL } from '@plvs/utils'
import { Linkout } from '@playvs-inc/nexus-icons'
import { RulebookImg } from '../rulebook/RulebookImg'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  link: {
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: 91,
    [theme.breakpoints.down('xs')]: {
      borderRadius: theme.shape.borderRadius * 2,
      height: 130,
    },
  },
  resourceText: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
}))

interface Props {
  className?: string
  esportSlug: EsportSlug
  isPublic: boolean
  rulebookUrl: string | undefined | null
}

export const ResourcesCard: React.FC<Props> = ({
  className,
  esportSlug,
  isPublic,
  rulebookUrl,
}) => {
  const isXs = useBreakpointXs()

  const classes = useStyles()

  const handleResourceClick = (articleUrl: string): void => {
    window.open(articleUrl, '_blank', 'noreferrer')
  }

  return (
    <div className={clsx(classes.root, className)}>
      <NxTypography className={classes.title} variant="h3">
        Resources
      </NxTypography>
      <NxTypography className={classes.subtitle} variant="body3">
        Advice and answers from the PlayVS Team.
      </NxTypography>
      <Grid container spacing={3}>
        {rulebookUrl && (
          <Grid item xs={6}>
            <RulebookImg
              esportSlug={esportSlug}
              imgClassName={classes.img}
              isPublic={isPublic}
              rulebookUrl={rulebookUrl}
              titleClassName={classes.resourceText}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Box
            className={classes.link}
            display="flex"
            onClick={(): void =>
              handleResourceClick(
                `${PLAYVS_HELP_ARTICLES_BASE_URL}${IntercomArticleMappings.coachHandbook}`
              )
            }
          >
            <img
              alt="handbook"
              className={classes.img}
              src={isXs ? HandbookMobile : Handbook}
            />
          </Box>
          <Box mt={2}>
            <NxTypography className={classes.resourceText} variant="body3">
              PlayVS Coach Handbook <Linkout color="inherit" height={15} />
            </NxTypography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.link}
            display="flex"
            onClick={(): void =>
              handleResourceClick(
                `${PLAYVS_HELP_ARTICLES_BASE_URL}${IntercomArticleMappings.highSchoolEnrollmentSteps}`
              )
            }
          >
            <img
              alt="enrollment"
              className={classes.img}
              src={EnrollmentInstructions}
            />
          </Box>
          <Box mt={2}>
            <NxTypography
              className={classes.resourceText}
              component="span"
              variant="body3"
            >
              Enrollment Instructions <Linkout color="inherit" height={15} />
            </NxTypography>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
