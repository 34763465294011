import React from 'react'
import { Box, Avatar, useTheme } from '@material-ui/core'
import { isEmpty } from 'ramda'

import { NxEmote, FB, NxTypography } from '@playvs-inc/nexus-components'
import { Stage } from '@playvs-inc/nexus-icons'

import { SmashUltimateStatsPlayerFragment } from '@plvs/graphql'
import { StatsRateFragment } from '@plvs/graphql/generated'
import { useRosterContext } from '../../RosterProvider'
import { StatsAvatarWrapper } from './RosterRowFilledStats.helpers'
import { useStyles } from './RosterRowFilledStats.styles'

export interface RosterRowFilledStatsSmashProps {
  memberId: string
  smashStatsByPlayer?: Record<string, SmashUltimateStatsPlayerFragment>
  reverseStats?: boolean
}

export const RosterRowFilledStatsSmash: React.FC<
  RosterRowFilledStatsSmashProps
> = ({
  memberId,
  smashStatsByPlayer: passedSmashStatsByPlayer,
  reverseStats = false,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const { smashStatsByPlayer } = useRosterContext()

  const smashStats =
    passedSmashStatsByPlayer?.[memberId] || smashStatsByPlayer[memberId]
  const showSmashStats = smashStats && !isEmpty(smashStats)

  const smashStatsAvatar = (
    { name, played, winRate }: StatsRateFragment,
    options?: { avatar?: React.ReactElement; title?: string }
  ): React.ReactNode => {
    const showEasterEgg = winRate >= 80 && played > 3
    return (
      <StatsAvatarWrapper
        key={name}
        tooltipTitle={
          <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
            <Box>
              {options?.title && <Box>{options.title}</Box>}
              <Box>{name}</Box>
              <Box>
                {played} {played > 1 ? 'Games' : 'Game'}
              </Box>
              <Box>{winRate}% Win Rate</Box>
            </Box>

            {showEasterEgg && (
              <NxEmote
                emoteKey={FB.Shock}
                height={30}
                shouldAnimate
                width={30}
              />
            )}
          </Box>
        }
      >
        {options?.avatar ? (
          // Wrapper makes tooltip work
          <Box>{options.avatar}</Box>
        ) : (
          <Avatar className={styles.smallStatsAvatar}>
            <NxTypography variant="body4">{name[0]}</NxTypography>
          </Avatar>
        )}
      </StatsAvatarWrapper>
    )
  }

  const statsRowReversed = (
    <>
      {smashStats?.mostPlayedCharacters?.map(
        (player) => player && smashStatsAvatar(player)
      )}{' '}
      {smashStats?.mostPlayedStages[0] &&
        smashStatsAvatar(smashStats.mostPlayedStages[0], {
          avatar: <Stage className={styles.stageIcon} />,
          title: 'Most Played Stage',
        })}
    </>
  )

  return showSmashStats ? (
    <Box
      alignItems="center"
      alignSelf="flex-start"
      display="flex"
      gridGap={theme.spacing(0.5)}
      justifyContent={reverseStats ? 'flex-end' : undefined}
    >
      {reverseStats ? (
        statsRowReversed
      ) : (
        <>
          {smashStats?.mostPlayedStages[0] &&
            smashStatsAvatar(smashStats.mostPlayedStages[0], {
              avatar: <Stage className={styles.stageIcon} />,
              title: 'Most Played Stage',
            })}
          {smashStats?.mostPlayedCharacters?.map(
            (player) => player && smashStatsAvatar(player)
          )}
        </>
      )}
    </Box>
  ) : (
    <></>
  )
}
