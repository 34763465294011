import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { cleanGraphQLError } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { Tooltip } from '@plvs/rally/components/tooltip'
import {
  LoadingSpinner,
  NxTextLinkExternal,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings } from '@plvs/const'
import { useGetHelpLinksQuery } from '@plvs/graphql/generated'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { DisabledEnrollmentCTAStatus } from '@plvs/respawn/containers/enrollment/types'

interface EnrollmentFooterProps {
  buttonTitle?: string
  subtitle?: string
  numberOfTeams: number
  passesNeeded: number | string
  onClick?: () => void
  disableButton?: DisabledEnrollmentCTAStatus
  error?: Error
  loading?: boolean
  isUnlimited: boolean
}

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    boxShadow: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'inline',
    '&>div>p': {
      display: 'inline',
      fontSize: `${theme.typography.body3.fontSize} !important`,
    },
    '& div': {
      display: 'inline',
    },
  },
  termsContainer: {
    textAlign: 'center',
  },
  externalLink: {
    textTransform: 'none',
    display: 'inline-flex',
  },
}))

export const EnrollmentFooter: React.FC<EnrollmentFooterProps> = ({
  buttonTitle,
  subtitle,
  numberOfTeams,
  passesNeeded,
  onClick,
  disableButton,
  error,
  loading,
  isUnlimited,
}) => {
  const flags = useFlags()
  const { data } = useGetHelpLinksQuery()
  const pricingFaqLink =
    data?.getLinks?.find((link) => link.slug === 'pricing-faqs')?.url ??
    IntercomArticleMappings.pricingFaq
  const classes = useStyles()

  return (
    <Box
      className={classes.footerContainer}
      display="flex"
      flex={0}
      flexDirection="column"
      justifyContent="flex-end"
      px={3}
    >
      {error ? (
        <Box pt={2}>
          <Banner
            subtitle={cleanGraphQLError(error.message)}
            title="Unable to enroll"
            type={BannerType.Error}
          />
        </Box>
      ) : null}
      {numberOfTeams > 0 && (
        <Box>
          <Box display="flex" justifyContent="space-between" pt={2}>
            <NxTypography variant="body1">Enrollment Summary</NxTypography>
            <NxTypography
              className={classes.bold}
              data-testid="enrollment-summary"
              variant="body1"
            >
              {numberOfTeams} Teams · {passesNeeded} Players
            </NxTypography>
          </Box>
          {!flags.freeCompetition && (
            <Box display="flex" justifyContent="space-between" pt={2}>
              <NxTypography variant="body1">
                Total Estimated Player Passes
              </NxTypography>
              <NxTypography
                className={classes.bold}
                data-testid="number-of-passes"
                variant="body1"
              >
                {isUnlimited ? 'Unlimited' : passesNeeded}
              </NxTypography>
            </Box>
          )}
        </Box>
      )}
      <Box pb={flags.freeCompetition ? 2 : 0} pt={3}>
        <Tooltip title={disableButton?.message ?? ''}>
          <Button
            color="primary"
            data-testid="submit-button"
            disabled={disableButton?.value || loading}
            fullWidth
            onClick={onClick}
            size="large"
            variant="contained"
          >
            {loading ? <LoadingSpinner size="small" /> : buttonTitle}
          </Button>
        </Tooltip>
        {flags.enrollmentPageTosAndPrivacyPolicyLinks && (
          <Box mt={2} textAlign="center">
            <NxTypography
              className={classes.termsContainer}
              component="span"
              variant="body1"
            >
              By clicking this button, you agree to our latest{' '}
              <NxTextLinkExternal
                className={classes.externalLink}
                display="inline"
                href="https://playvs.com/terms"
                label=" Terms of Use"
                rel="noopener"
                target="_blank"
                variant="body2"
              />{' '}
              and{' '}
              <NxTextLinkExternal
                className={classes.externalLink}
                display="inline"
                href="https://playvs.com/privacy"
                label=" Privacy Policy."
                rel="noopener"
                target="_blank"
                variant="body2"
              />
            </NxTypography>
          </Box>
        )}
      </Box>

      {!flags.freeCompetition && (
        <Box py={2} textAlign="center">
          <NxTypography data-testid="subtitle-text" variant="body3">
            {subtitle} For more information, visit our{' '}
            <NxTextLinkExternal
              className={classes.inline}
              data-testid="EnrollmentFooter__PricingFaqLink"
              label="Pricing FAQ."
              labelVariant="body3"
              onClick={(): void => {
                showIntercomArticle(pricingFaqLink)
              }}
            />
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
