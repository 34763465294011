import { format } from 'date-fns'
import React from 'react'

import { User } from '@plvs/graphql'

export type UserCreatedAtProps = Pick<User, 'id' | 'createdAt'>

export const UserCreatedAt: React.FC<UserCreatedAtProps> = ({ createdAt }) => (
  <>{createdAt ? format(new Date(createdAt), 'MM-dd-y') : ''}</>
)
