import React, { useState } from 'react'
import { userRolesByResourceToSingularString } from '@plvs/utils'
import { TeamCluster } from '@plvs/rally/components/cluster'
import {
  ScrimmageRequestQuery,
  Team,
  useJoinScrimmageMutation,
  refetchScrimmageRequestQuery,
  ResourceType,
} from '@plvs/graphql'
import {
  NxSelect,
  NxSelectOption,
  NxButton,
  NxTypography,
  NxModal,
  NxContactCluster,
} from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'

type JoinScrimmageStepAProps = {
  scrimmageRequest?: ScrimmageRequestQuery['scrimmageRequest']
  formattedDatetime: string
  esportName: string
  myAvailableTeams: Pick<Team, 'id' | 'name'>[]
  onClose(): void
  loading: boolean
  open: boolean
  setSuccessStep(): void
  scrimmageId: string
  toggleRefresh(): void
}

/*
 * Join Scrimmage dialog first displayed to user when
 * attempting to join a scrimmage either via Join button
 * or link access.
 */
export const JoinScrimmageStepA = ({
  esportName,
  formattedDatetime,
  myAvailableTeams,
  onClose,
  setSuccessStep,
  scrimmageRequest,
  loading,
  open,
  scrimmageId,
  toggleRefresh,
}: JoinScrimmageStepAProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [opposingTeamId, setOpposingTeamId] = useState('')

  const [
    joinScrimmage,
    { loading: joiningScrimmage },
  ] = useJoinScrimmageMutation()

  const {
    getResourceImageOfSize,
    updateResources,
    loading: imageLoading,
  } = useResourceImageProvider()

  const bestOf = scrimmageRequest?.bestOf ?? '???'

  const requestingTeamName = scrimmageRequest?.requestingTeam?.name ?? 'Unknown'
  const requestingTeamSchoolName =
    scrimmageRequest?.requestingTeam?.school?.name ?? 'Unknown'
  updateResources({
    resources: [
      {
        id: scrimmageRequest?.requestingTeam?.school?.id,
        type: ResourceType.Organization,
      },
    ],
  })
  const requestingTeamSchoolLogoUrl =
    getResourceImageOfSize({
      resource: {
        id: scrimmageRequest?.requestingTeam?.school?.id,
        type: ResourceType.Organization,
      },
      size: NxAvatarSize.MD,
    })?.url ?? 'Unknown'

  const requestingUserName = scrimmageRequest?.requestedBy?.name ?? 'Unknown'
  const requestingUserTitle = userRolesByResourceToSingularString(
    scrimmageRequest?.requestedBy?.roles || []
  )

  const onSubmit = async (): Promise<void> => {
    if (opposingTeamId && scrimmageId) {
      try {
        await joinScrimmage({
          awaitRefetchQueries: true,
          refetchQueries: [refetchScrimmageRequestQuery({ id: scrimmageId })],
          variables: {
            input: {
              id: scrimmageId,
              opposingTeamId,
            },
          },
        })

        setSuccessStep()
        toggleRefresh()
      } catch (e) {
        enqueueSnackbar('Unable to join scrimmage', {
          variant: 'error',
        })
      }
    }
  }

  const selectionOfTeams = (
    <NxSelect
      disabled={!myAvailableTeams.length}
      fullWidth
      label="Team"
      name="opposingTeamId"
      onChange={(e): void => {
        setOpposingTeamId(e.target.value as string)
      }}
    >
      {myAvailableTeams.map((team) => {
        const label = team.name

        return (
          <NxSelectOption key={team.id} value={team.id}>
            {label}
          </NxSelectOption>
        )
      })}
    </NxSelect>
  )

  const actions = (
    <>
      <NxButton label="Cancel" onClick={onClose} variant="text" />
      <NxButton
        disabled={joiningScrimmage || !opposingTeamId}
        label="Join"
        onClick={onSubmit}
        type="submit"
        variant="primary"
      />
    </>
  )

  return (
    <NxModal
      actions={actions}
      onClose={onClose}
      open={open}
      showTopRightClose
      size="small"
      title="Join Scrimmage"
    >
      <WaitTillLoaded loading={loading || imageLoading} showSpinnerWhileLoading>
        <Box pb={2}>
          <NxTypography variant="button">Scrimmage Requested By:</NxTypography>
        </Box>
        <TeamCluster
          avatarUrl={requestingTeamSchoolLogoUrl}
          subtitle={requestingTeamSchoolName}
          title={requestingTeamName}
        />
        <Box py={2}>
          <Box pb={2}>
            <NxTypography variant="button">Scrimmage Start Time:</NxTypography>
          </Box>
          <NxTypography variant="body1">{formattedDatetime}</NxTypography>
          <NxTypography variant="body1">
            {esportName}, Best of {bestOf}
          </NxTypography>
        </Box>
        <Box pb={2}>
          <NxTypography variant="button">
            Scrimmage Point of Contact:
          </NxTypography>
        </Box>
        <NxContactCluster
          avatarHashId={requestingUserName}
          avatarUrl={scrimmageRequest?.requestedBy?.avatarUrl ?? ''}
          email={scrimmageRequest?.requestedBy?.email ?? ''}
          name={requestingUserName}
          phone={scrimmageRequest?.requestedBy?.phone ?? ''}
          subHeader={requestingUserTitle}
        />
        <Box pt={2}>
          <NxTypography variant="h4">Choose a New Scrimmage Team</NxTypography>
          <Box pt={1}>{selectionOfTeams}</Box>
        </Box>
      </WaitTillLoaded>
    </NxModal>
  )
}
