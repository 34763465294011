import { provinceAbbr } from '@plvs/const'
import { CountryCode } from '@plvs/utils'

export const getCountryFromState = (value: string): CountryCode => {
  const provinces = [...provinceAbbr] as Array<string>
  if (provinces.includes(value)) {
    return CountryCode.Canada
  }
  return CountryCode.UnitedStates
}
