import React from 'react'

import { User } from '@plvs/graphql'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { TableColumn, DEFAULT_WIDTH } from '../Table'
import * as Cell from '../cell'
import { UserCluster as UserClusterComponent } from '../../cluster'

const userClusterBase = {
  minWidth: 185,
}

export const PhasePlayerCluster: TableColumn<any> = {
  accessor: 'player.name',
  Cell: ({ player }: { player: any }) => {
    const { id, avatarUrl, name, username } = player
    return (
      <UserClusterComponent
        avatarSize={NxAvatarSize.SM}
        avatarUrl={avatarUrl}
        id={id}
        subtitle={username || 'Not Connected'}
        title={name}
      />
    )
  },
  Header: 'Player',
  minWidth: 185,
}

export const UserCluster: TableColumn<Cell.UserClusterProps> = {
  ...userClusterBase,
  accessor: 'name',
  Cell: Cell.UserCluster,
  Header: 'Player',
}

export const CoachCluster: TableColumn<Cell.UserClusterProps> = {
  minWidth: 120,
  accessor: 'name',
  Cell: Cell.UserCluster,
  Header: 'Coach',
}

export const PlayerCluster: TableColumn<Cell.PlayerClusterProps> = {
  ...userClusterBase,
  Cell: Cell.PlayerCluster,
  Header: 'Player',
}

export const MobileUserCluster: TableColumn<Cell.MobileUserClusterProps> = {
  minWidth: 120,
  accessor: 'name',
  Cell: Cell.MobileUserCluster,
  Header: '',
}

export const UserEmail: TableColumn<Pick<User, 'id' | 'email'>> = {
  accessor: 'email',
  minWidth: 200,
  Header: 'Email Address',
}

export const UserEmailCopiable: TableColumn<Cell.UserEmailCopiableProps> = {
  accessor: 'email',
  minWidth: 200,
  Header: 'Email Address',
  Cell: Cell.UserEmailCopiable,
}

export const SchoolEmailCopiable: TableColumn<Cell.UserEmailCopiableProps> = {
  accessor: 'email',
  minWidth: 200,
  Header: 'School Email',
  Cell: Cell.UserEmailCopiable,
}

export const UserGradYear: TableColumn<Pick<User, 'id' | 'gradYear'>> = {
  accessor: 'gradYear',
  Header: 'Class',
}

export const UserJoinDate: TableColumn<Cell.UserCreatedAtProps> = {
  accessor: 'createdAt',
  minWidth: 125,
  Cell: Cell.UserCreatedAt,
  Header: 'Joined',
}

export const UserStatus: TableColumn<Cell.UserStatusProps> = {
  width: 125,
  accessor: 'schoolRoleStatus',
  Header: 'Status',
  Cell: Cell.UserStatus,
}

export const UserVerifiedPlayerMore: TableColumn<Cell.UserVerifiedPlayerMoreProps> = {
  id: 'more',
  width: 72,
  Header: '',
  Cell: Cell.UserVerifiedPlayerMore,
}

export const UserDeniedPlayerMore: TableColumn<Cell.UserDeniedPlayerMoreProps> = {
  id: 'more',
  width: 72,
  Header: '',
  Cell: Cell.UserDeniedPlayerMore,
}

export const UserArchivedPlayerMore: TableColumn<Cell.UserArchivedPlayerMoreProps> = {
  id: 'more',
  width: 72,
  Header: '',
  Cell: Cell.UserArchivedPlayerMore,
}

export const UserVerifiedCoachMore: TableColumn<Cell.UserVerifiedCoachMoreProps> = {
  id: 'more',
  width: 72,
  Header: '',
  Cell: Cell.UserVerifiedCoachMore,
}

export const UserDeniedCoachMore: TableColumn<Cell.UserDeniedCoachMoreProps> = {
  id: 'more',
  width: 72,
  Header: '',
  Cell: Cell.UserDeniedCoachMore,
}

export const UserEsports: TableColumn<unknown> = {
  id: 'esportPreferences',
  Header: '',
  // Accessor not defined, the child component will grab the props directly
  // assuming row is a User type with esportInterests field selected.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Cell: Cell.UserEsports as any,
  sortMethod: (next, prev) =>
    (next?.length ?? 0) > (prev?.length ?? 0) ? 1 : -1,
  width: 135,
}

export const Position = (rankingInfoCopy: string): TableColumn<any> => ({
  accessor: 'position',
  Header: 'Pos',
  HeaderTooltip: rankingInfoCopy,
  width: DEFAULT_WIDTH,
})
