import { makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Adduser } from '@playvs-inc/nexus-icons'
import { NxIconButton, NxTooltip } from '@playvs-inc/nexus-components'
import { colors } from '@playvs-inc/nexus-core'

import { User } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'
import { RosterTeamCoaches } from '../RosterProvider.types'

const useStyles = makeStyles((theme) => ({
  outer: {
    padding: theme.spacing(2),
  },
  icon: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
  },
  inner: {
    border: '1px dashed',
    height: '6rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    width: '100%',
    textTransform: 'none',
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: colors.OpBlack[13],
      color: colors.Grey[8],
    },
  },
}))

export type RosterListAddItemDialogProps = {
  teamId: string
  onMutationSuccess?(): Promise<void>
  onClose(): void
  isDialogOpen: boolean
  users?: User[] | RosterTeamCoaches | null
  schoolId: string
  metaseasonId?: string
  defaultSearchTerm?: string
}

type RosterListAddItemProps = {
  title: string
  DialogElement: React.FC<RosterListAddItemDialogProps>
  disabled?: boolean
  disabledTooltipTitle?: string
  teamId: string
  onMutationSuccess?(): Promise<void>
  users?: User[] | RosterTeamCoaches | null
  schoolId: string
}

export const RosterListAddItem: React.FC<RosterListAddItemProps> = ({
  DialogElement,
  title,
  disabled,
  disabledTooltipTitle,
  teamId,
  onMutationSuccess,
  users,
  schoolId,
}) => {
  const styles = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const onCloseHandler = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  return (
    <>
      <Box className={styles.outer}>
        <NxTooltip
          data-testid="RosterListAddItem_Tooltip"
          title={disabled ? disabledTooltipTitle ?? 'disabled' : 'disabled'}
        >
          <Box>
            <NxIconButton
              className={styles.inner}
              data-testid="RosterListAddItem_Button"
              disabled={disabled}
              icon={<Adduser className={styles.icon} />}
              label={title}
              onClick={(): void => {
                setOpen(true)
              }}
              variant="secondary"
            />
          </Box>
        </NxTooltip>
      </Box>
      <DialogElement
        isDialogOpen={open}
        onClose={onCloseHandler}
        onMutationSuccess={onMutationSuccess}
        schoolId={schoolId}
        teamId={teamId}
        users={users}
      />
    </>
  )
}
