import React, { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxButton,
  NxSelectOption,
  NxTextInput,
  NxTypography,
} from '@playvs-inc/nexus-components'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useSnackbar } from 'notistack'
import {
  refetchGetChildUserQuery,
  refetchGetUserChildrenQuery,
  useGetChildUserQuery,
  useSetChildAccountSettingsMutation,
} from '@plvs/graphql'
import {
  formErrorToString,
  getGradYearOptions,
  yupFirstNameRequired,
  yupGradYearRequired,
  yupLastNameRequired,
} from '@plvs/utils'
import { NxSelectController } from '@plvs/rally/components/select/NxSelectController'
import { BirthdaySelectController } from '@plvs/rally/components/onboard/BirthdaySelectController'
import { getDate, getMonth, getYear } from 'date-fns'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { UserCluster } from '@plvs/rally/components/cluster'
import { SetUserAvatar } from '@plvs/respawn/features/account/SetUserAvatar'

const useStyles = makeStyles((theme) => ({
  inputTitles: {
    marginBottom: theme.spacing(3),
  },
  nameInputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  inputFieldContainer: {
    maxWidth: '466px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}))

const EditChildInfoFormSchema = yup.object().shape({
  day: yup.string().required(),
  firstName: yupFirstNameRequired,
  gradYear: yupGradYearRequired,
  lastName: yupLastNameRequired,
  month: yup.string().required(),
  year: yup.string().required(),
})

interface EditChildInfoFormInput {
  firstName: string
  lastName: string
  gradYear: number
  month: string
  day: string
  year: string
}

export const ChildEditInfoForm: React.FC<{
  userId: string
}> = ({ userId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [
    setChildAccountSettings,
    setChildAccountSettingsResult,
  ] = useSetChildAccountSettingsMutation({
    refetchQueries: [
      refetchGetUserChildrenQuery(),
      refetchGetChildUserQuery({ id: userId }),
    ],
  })
  const { data, loading } = useGetChildUserQuery({ variables: { id: userId } })

  const dateOfBirthDefaultValues = useMemo(() => {
    const parsedDate = data?.user?.dateOfBirth
      ? new Date(data?.user?.dateOfBirth)
      : null
    return parsedDate
      ? {
          year: getYear(parsedDate),
          // Note: months from dayjs are 0 indexed, our select options are 1 indexed
          month: getMonth(parsedDate) + 1,
          day: getDate(parsedDate),
        }
      : undefined
  }, [data])

  const { control, register, getValues, handleSubmit, errors } = useForm<
    EditChildInfoFormInput
  >({
    resolver: yupResolver<EditChildInfoFormInput>(EditChildInfoFormSchema),
  })

  const gradYearOptions = useMemo(() => getGradYearOptions(), [])

  const formDisabled = loading || setChildAccountSettingsResult.loading

  const submitDisabled = formDisabled

  function calculateDateOfBirth({
    day,
    month,
    year,
  }: Partial<EditChildInfoFormInput>): string {
    return `${month}-${day}-${year}`
  }

  const submit: React.FormEventHandler<HTMLFormElement> = async (): Promise<
    void
  > => {
    const { firstName, lastName, gradYear, day, month, year } = getValues()
    const dateOfBirth = calculateDateOfBirth({ day, month, year })
    try {
      await setChildAccountSettings({
        variables: {
          userId,
          input: {
            firstName,
            lastName,
            dateOfBirth,
            gradYear: +gradYear,
            schoolId: data?.user?.school?.id,
          },
        },
      })
      enqueueSnackbar('Child info has been successfully updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (e: any) {
      enqueueSnackbar('Something went wrong please contact support.', {
        variant: 'error',
        autoHideDuration: 2000,
      })
    }
  }

  return (
    <Box>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <form onSubmit={handleSubmit(submit)}>
          <NxTypography className={classes.inputTitles} variant="h2">
            Edit Child Information
          </NxTypography>

          <Box className={classes.inputFieldContainer}>
            <Box className={classes.inputRow}>
              <UserCluster
                avatarSize={NxAvatarSize.LG}
                avatarUrl={data?.user?.avatarUrl}
                id={data?.user?.id}
                title={undefined}
              />
              <Box ml={2}>
                <SetUserAvatar userId={userId} />
              </Box>
            </Box>

            <Box className={classes.nameInputRow}>
              <NxTextInput
                ref={register}
                data-testid="first-name"
                defaultValue={data?.user?.firstName ?? undefined}
                fullWidth
                helperText={formErrorToString(errors.firstName)}
                label="First Name"
                name="firstName"
                style={{ height: '56px' }}
                variant={errors.firstName ? 'error' : 'default'}
              />
              <NxTextInput
                ref={register}
                data-testid="last-name"
                defaultValue={data?.user?.lastName ?? undefined}
                fullWidth
                helperText={formErrorToString(errors.lastName)}
                label="Last Name"
                name="lastName"
                style={{ height: '56px' }}
                variant={errors.lastName ? 'error' : 'default'}
              />
            </Box>
            <Box className={classes.inputRow}>
              <BirthdaySelectController
                control={control}
                defaultValues={dateOfBirthDefaultValues}
                selectClassName={classes.select}
                showBirthInLabels
              />
            </Box>
            <Box className={classes.inputRow}>
              <NxSelectController
                className={classes.select}
                control={control}
                data-testid="gradYear"
                defaultValue={data?.user?.gradYear?.toString()}
                fullWidth
                label="Graduation Year"
                name="gradYear"
                required
              >
                {gradYearOptions.map((year) => (
                  <NxSelectOption key={year} value={year}>
                    {year}
                  </NxSelectOption>
                ))}
              </NxSelectController>
            </Box>
            <Box className={classes.inputRow}>
              <NxButton
                disabled={submitDisabled}
                fullWidth
                label="Save"
                type="submit"
                variant="primary"
              />
            </Box>
          </Box>
        </form>
      </WaitTillLoaded>
    </Box>
  )
}
