import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import NasefLight from '@plvs/rally/assets/nasef/NasefLight.png'
import NasefDark from '@plvs/rally/assets/nasef/NasefDark.png'
import { useImageLoader } from '@plvs/respawn/features/avatar'

interface AuthLogoProps {
  isNasef?: boolean
}

export const AuthLogo = ({ isNasef }: AuthLogoProps): React.ReactElement => {
  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const NASEFMode = isDarkMode ? NasefDark : NasefLight
  const { imageSrc } = useImageLoader({
    src: NASEFMode,
  })

  return isNasef ? (
    <Box display="flex" justifyContent="center">
      <img
        alt="nasef-logo"
        data-testid={`nasef-logo-${theme.palette.type}`}
        height={64}
        src={imageSrc}
        width={188}
      />
    </Box>
  ) : (
    <Box
      alignItems="center"
      display="flex"
      justifyContent={['center', 'unset']}
      marginLeft={[-0.875, 4]}
      minHeight={48}
      mt={4}
    >
      <Box mt={1.4}>
        <img
          alt="playvs-logo"
          data-testid="playvs-logo"
          height={48}
          src="https://s3.amazonaws.com/assets.playvs.com/PlayVS/ScholasticPlayVSLogo.webp"
          width={157}
        />
      </Box>
    </Box>
  )
}
