import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { MetaseasonFilter } from '@plvs/rally/components/filter/MetaseasonFilter'
import { sortByMaybeStartsAt } from '@plvs/utils'
import { PhaseFilter } from '@plvs/rally/components/filter/PhaseFilter'
import { makeStyles } from '@material-ui/core'

import { SlotFilter } from '@plvs/rally/components/filter/SlotFilter'
import { head } from 'ramda'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { StringParam, useQueryParams } from 'use-query-params'
import { SeasonStatus } from '@plvs/graphql/generated'
import {
  LeagueDetailsTab,
  PhaseFilterType,
  SlotFilterType,
} from './LeagueDetails.types'
import { getCurrentSlot } from './ExploreHelpers'

export const useStyles = makeStyles((theme) => ({
  metaseasonFilter: {
    marginRight: theme.spacing(2),
  },
  slotFilter: {
    marginLeft: theme.spacing(2),
  },
}))

const FALL_2022_CUTOFF_DATE = new Date('2022-08-30 00:00:00+00')

interface Props {
  leagueId: string
  phase: PhaseFilterType | undefined
  phases: PhaseFilterType[]
  setPhase: Dispatch<SetStateAction<PhaseFilterType | undefined>>
  slotFilter: SlotFilterType | undefined
  setSlotFilter: Dispatch<SetStateAction<SlotFilterType | undefined>>
  tab: LeagueDetailsTab
  team: SelectedTeam | null
}

export const LeagueDetailsFilters: React.FC<Props> = ({
  leagueId,
  phase,
  phases,
  setPhase,
  slotFilter,
  setSlotFilter,
  tab,
  team,
}) => {
  const classes = useStyles()

  const [queryFilters, setQueryFilters] = useQueryParams({
    queryMetaseasonId: StringParam,
    queryPhaseId: StringParam,
    querySlotId: StringParam,
  })
  const { queryMetaseasonId, queryPhaseId, querySlotId } = queryFilters
  const {
    setMetaseason,
    metaseason,
    metaseasons,
  } = useSchoolLeagueInfoContext()
  const handleSetMetaseason = (metaseasonId: string): void => {
    setMetaseason(metaseasonId)
    setQueryFilters({ ...queryFilters, queryMetaseasonId: metaseasonId })
  }
  if (queryMetaseasonId && metaseason?.id !== queryMetaseasonId) {
    handleSetMetaseason(queryMetaseasonId)
  }

  const filterMetaseasons = metaseasons.filter((m) => {
    if (
      !m.seasons?.some(
        (s) => s.leagueId === leagueId && s.status === SeasonStatus.Active
      )
    ) {
      return false
    }
    const startsAtDate = m?.startsAt ? new Date(m.startsAt) : undefined
    return startsAtDate
      ? startsAtDate?.getTime() > FALL_2022_CUTOFF_DATE.getTime()
      : false
  })

  const handleSetPhase = (phaseId: string): void => {
    const newPhase = phases.find((p) => p.id === phaseId)
    setPhase(newPhase)
    setQueryFilters({ ...queryFilters, queryPhaseId: newPhase?.id })
  }

  const showPhaseFilter =
    tab === LeagueDetailsTab.Standings || tab === LeagueDetailsTab.Matches

  if (queryPhaseId && phase?.id !== queryPhaseId) {
    handleSetPhase(queryPhaseId)
  }

  const slots = phase?.slots ?? []

  const filterSlots = sortByMaybeStartsAt(slots).map((slot, index) => ({
    ...slot,
    name: `Week ${index + 1}`,
  }))
  const handleSetSlot = (slotId: string): void => {
    const newSlot = filterSlots.find((s) => s.id === slotId)
    setSlotFilter(newSlot)
    setQueryFilters({ ...queryFilters, querySlotId: newSlot?.id })
  }
  useEffect(() => {
    if (slots.length > 0) {
      const currentSlot =
        filterSlots.find((x) => x.id === querySlotId) ||
        filterSlots.find(getCurrentSlot) ||
        head(filterSlots)

      if (currentSlot) {
        setSlotFilter(currentSlot)
        setQueryFilters({ ...queryFilters, querySlotId: currentSlot?.id })
      }
    }
  }, [slots])

  return (
    <>
      <MetaseasonFilter
        className={classes.metaseasonFilter}
        metaseason={metaseason}
        metaseasons={filterMetaseasons}
        setMetaseason={handleSetMetaseason}
      />
      {showPhaseFilter && (
        <PhaseFilter phase={phase} phases={phases} setPhase={handleSetPhase} />
      )}
      {tab === LeagueDetailsTab.Matches && (
        <SlotFilter
          className={classes.slotFilter}
          disabled={!!team}
          setSlot={handleSetSlot}
          slot={slotFilter}
          slots={filterSlots}
        />
      )}
    </>
  )
}
