import React, { useEffect, useState } from 'react'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { Grid, makeStyles, useTheme } from '@material-ui/core'

import { HeroGutter } from '@plvs/rally/components/hero'
import { ProfileLeagueMatchHistoryRow } from '@plvs/rally/features/profile/match-history/ProfileLeagueMatchHistoryRow'
import {
  MatchStatus,
  useGetPlayerMatchHistoryQuery,
  useGetLatestMetaseasonWithMatchQuery,
} from '@plvs/graphql'
import {
  NxButton,
  NxTypography,
  NxEmptyState,
} from '@playvs-inc/nexus-components'

import {
  StadiumDarkLargeSecondary,
  StadiumLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { useNonLoggedInUserProviderContext } from '@plvs/rally/features/profile/NonLoggedInUserProvider'
import { NonLoggedInUserFilters } from '@plvs/rally/features/profile/NonLoggedInUserFilters'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

const DEFAULT_VIEW_SIZE = 8

export const useStyles = makeStyles((theme) => ({
  matchRowContainer: {
    marginBottom: theme.spacing(1),
  },
}))

const matchStatus = Object.values(MatchStatus).filter(
  (status) =>
    !new Set([
      MatchStatus.Open,
      MatchStatus.Live,
      MatchStatus.Quarantined,
      MatchStatus.Rescheduled,
      MatchStatus.Cancelled,
      MatchStatus.Scheduled,
    ]).has(status)
)

export const MatchHistory: React.FC<{ userId: string }> = ({ userId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const {
    data: latestMetaseasonData,
    loading: latestMetaseasonLoading,
  } = useGetLatestMetaseasonWithMatchQuery({
    variables: {
      playerId: userId,
    },
  })

  const {
    metaseason,
    loading,
    setMetaseasonId,
  } = useNonLoggedInUserProviderContext()

  const latestMetaseasonId =
    latestMetaseasonData?.getLatestMatchPlayedByPlayerId?.slot?.phase?.season
      ?.metaseasonId ?? ''

  useEffect(() => {
    if (latestMetaseasonId && !loading) {
      setMetaseasonId(latestMetaseasonId)
    }
  }, [latestMetaseasonId, loading])

  const [viewAll, setViewAll] = useState<boolean>(false)
  const metaseasonId = metaseason?.id
  const { data, loading: loadingMatches } = useGetPlayerMatchHistoryQuery({
    variables: {
      playerId: userId,
      metaseasonId: metaseasonId || '',
      statuses: matchStatus,
    },
    skip: !metaseasonId,
  })
  const allMatches = data?.findPlayerMetaseasonMatches || []
  let matches = allMatches
  if (!viewAll && allMatches.length > DEFAULT_VIEW_SIZE) {
    matches = allMatches.slice(0, DEFAULT_VIEW_SIZE)
  }

  const spot = isDarkMode ? (
    <StadiumDarkLargeSecondary height={200} width={200} />
  ) : (
    <StadiumLightLargeSecondary height={200} width={200} />
  )

  return (
    <Box>
      <HeroGutter mb={3}>
        <NxTypography variant="h3">Match History</NxTypography>
      </HeroGutter>
      <HeroGutter mb={[2, 3]}>
        <Grid container direction="row" justifyContent="space-between">
          <NonLoggedInUserFilters />
          {allMatches.length > DEFAULT_VIEW_SIZE && (
            <NxButton
              label={viewAll ? 'View Less' : 'View All'}
              onClick={(): void => {
                setViewAll(!viewAll)
              }}
              variant="text"
            />
          )}
        </Grid>
      </HeroGutter>
      <PageContentGutter style={{ marginTop: 0 }}>
        <WaitTillLoaded
          loading={loading || loadingMatches || latestMetaseasonLoading}
          showSpinnerWhileLoading
        >
          <>
            {matches.length ? (
              matches.map((match) => {
                return (
                  <ProfileLeagueMatchHistoryRow
                    key={match.id}
                    className={classes.matchRowContainer}
                    // @ts-ignore
                    match={match}
                  />
                )
              })
            ) : (
              <NxEmptyState
                spot={spot}
                subtitle="Try adjusting filter to find your match history."
                title="No Match History Found"
              />
            )}
          </>
        </WaitTillLoaded>
      </PageContentGutter>
    </Box>
  )
}
