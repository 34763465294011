import React from 'react'
import { PageContentGutter } from '@plvs/respawn/features/layout'
import AccountSettingsFormWrapper from '@plvs/rally/features/account/AccountSettingsFormWrapper'
import { withTransaction } from '@elastic/apm-rum-react'
import { AccountRenderControllerProvider } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { AppPage } from '../page'

const AccountPage: React.FC = () => {
  return (
    <AppPage title="Account">
      <PageContentGutter>
        <AccountRenderControllerProvider>
          <AccountSettingsFormWrapper />
        </AccountRenderControllerProvider>
      </PageContentGutter>
    </AppPage>
  )
}

export default withTransaction('AccountPage', 'component')(AccountPage)
