import { concat, path } from 'ramda'
import { isFunction, noop } from 'ramda-adjunct'

import { env } from '@plvs/env'
import { objFromKeys } from '@plvs/utils'

export const makeSafeObjectOfFunctions = (
  pathToObject: string[],
  expectedKeys: string[]
): Record<string, any> =>
  env.IS_BROWSER
    ? // if we're in the browser, recreate the pre-existing object on window, but with a check make sure the function exists before calling it
      objFromKeys(
        (key: string): (() => void) =>
          (...args: any[]): void => {
            const fn = path(concat(pathToObject, [key]), window)
            return isFunction(fn) && fn(...args)
          },
        expectedKeys
      )
    : // if we're on the server, do not attempt to reference the window object; turn off analytics entirely by rendering all methods noop
      objFromKeys(noop, expectedKeys)

export const makeSafeFunction = (
  pathToFunction: string[]
): ((...args: any[]) => void) =>
  env.IS_BROWSER
    ? (...args: any[]): void => {
        const fn = path(pathToFunction, window)
        return isFunction(fn) && fn(...args)
      }
    : noop
