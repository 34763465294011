import React from 'react'
import { useGetMySchoolTeamsQuery } from '@plvs/graphql'

import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { TeamsListGroup } from './TeamsListGroup'

interface Props {
  isPublicPage: boolean
  schoolId: string
  metaseasonId: string | undefined
}

export const TeamsList: React.FC<Props> = ({
  isPublicPage,
  schoolId,
  metaseasonId = '',
}) => {
  const { data, loading } = useGetMySchoolTeamsQuery({
    variables: {
      id: schoolId,
      metaseasonId,
    },
    skip: !schoolId || !metaseasonId,
  })

  const school = data?.school

  const teams = school?.teams?.filter((team) => !team?.isHidden) ?? []

  return (
    <Box mb={3}>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        {teams.length > 0 && (
          <TeamsListGroup
            isPublicPage={isPublicPage}
            teams={teams}
            title="Teams"
          />
        )}
      </WaitTillLoaded>
    </Box>
  )
}
