import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'
import React from 'react'
import { Icon, Icons } from '@plvs/rally/components/icon'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  callout: {
    backgroundColor:
      theme.palette.ColorBackgroundBase || theme.palette.background.paper,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  calloutIconCircle: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: '50%',
    height: theme.spacing(6),
    width: theme.spacing(6),
    backgroundColor: Colors.SkyBlue94,
    borderRadius: theme.spacing(3),
    transform: 'translate(-50%, -50%)',
  },
  calloutIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: theme.spacing(3),
    width: theme.spacing(3),
    margin: 'auto auto auto auto',
    color: Colors.Blue75,
  },
}))

export const Callout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.callout} mt={4} p={2}>
      <div className={classes.calloutIconCircle}>
        <Icon className={classes.calloutIcon} icon={Icons.megaphone} />
      </div>
      <Box mt={1} p={1}>
        <NxTypography align="center" color="textSecondary">
          {children}
        </NxTypography>
      </Box>
    </Box>
  )
}
