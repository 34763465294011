import { Box } from '@material-ui/core'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { ConversationsProviderMetadata } from '@plvs/rally/containers/chat/Providers.types'
import { getMatchIdFromCoachChat } from '../../../chat/utils'
import { useStyles } from '../AppDrawerToggle.styles'
import { ChatPreviewCard } from './ChatPreviewCard'

interface ChatPreviewSectionProps {
  isMobile: boolean
  title: string
  conversations: ConversationsProviderMetadata[]
}
export const ChatPreviewSection: React.FC<ChatPreviewSectionProps> = ({
  isMobile,
  title,
  conversations,
}) => {
  const classes = useStyles({ isMobile })

  return (
    <Box p={2}>
      <NxTypography className={classes.drawerSectionTitle} variant="h4">
        {title}
      </NxTypography>
      {conversations.map((convo) => {
        const { uniqueName } = convo.conversation
        let matchId = uniqueName ?? ''
        if (uniqueName?.includes('coach')) {
          matchId = getMatchIdFromCoachChat({ uniqueName })
        }
        return (
          <ChatPreviewCard
            key={uniqueName ?? undefined}
            conversation={convo}
            isMobile={isMobile}
            matchId={matchId}
          />
        )
      })}
    </Box>
  )
}
