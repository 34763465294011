import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Maybe, MatchResult } from '@plvs/graphql/generated'

export type MatchNameProps = {
  match?: {
    matchResults: Maybe<Array<MatchResult>>
  }
}

const useStyles = makeStyles(() => ({
  overflowText: {
    whiteSpace: 'break-spaces',
  },
}))

export const MatchName: React.FC<MatchNameProps> = ({
  match,
}): React.ReactElement => {
  const classes = useStyles()
  const team1 = match?.matchResults?.[0]?.team?.name
  const team2 = match?.matchResults?.[1]?.team?.name
  return (
    <NxTypography
      className={classes.overflowText}
      variant="body1"
    >{`${team1} VS ${team2}`}</NxTypography>
  )
}
