import React, { useState, useEffect, useMemo } from 'react'
import { equals, without, uniqBy } from 'ramda'
import { EsportPreferenceModalContent } from '@plvs/rally/components/overlay/managePlayers'
import {
  refetchGetUserEsportInterestsForSchoolQuery,
  useGetMySchoolDetailsQuery,
  useGetMySchoolEsportsQuery,
  useSetUserEsportInterestsMutation,
} from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { cleanGraphQLError } from '@plvs/utils'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { NxButton, NxModal } from '@playvs-inc/nexus-components'
import { Box, makeStyles } from '@material-ui/core'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { createPreferences } from './modalHelpers'

const useStyles = makeStyles({
  contentClassName: {
    margin: 0,
  },
})

interface EsportEntry {
  id: string
  slug: string
}
export interface Props {
  userId: string
  closeModal(): void
  isModalOpen: boolean
  esportInterests: EsportEntry[]
}
export const EsportPreferenceModal: React.FC<Props> = ({
  userId,
  closeModal,
  isModalOpen,
  esportInterests,
}) => {
  const classes = useStyles()

  const [initialInterests, setInitialInterests] = useState<string[]>([])

  const [checkedEsportItems, setCheckedEsportItems] = useState<string[]>([])

  const [hasInitialized, setHasInitialized] = useState<boolean>(false)

  const [error, setError] = useState<Error | null>(null)

  const allEsportsData = useAllEsportAdapters()

  const {
    data: schoolData,
    loading: schoolDataLoading,
  } = useGetMySchoolDetailsQuery()
  const { data, loading } = useGetMySchoolEsportsQuery({
    variables: { schoolId: schoolData?.me?.school?.id ?? '' },
    skip: !schoolData?.me?.school?.id || schoolDataLoading,
  })

  const [mutation, { loading: isMutating }] = useSetUserEsportInterestsMutation(
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchGetUserEsportInterestsForSchoolQuery({
          id: userId,
        }),
      ],
    }
  )

  const myEsportInterests = esportInterests.map(({ id }) => id)

  const hasEsportInterests = !!esportInterests.length

  // Create a cached copy of available esports that is available
  // to the school based on their LeagueScope
  const availableEsports = useMemo(() => {
    const schoolLeagues = data?.getLeaguesBySchoolId ?? []
    return schoolLeagues.map((league) => {
      const esportAdapter =
        allEsportsData.find(
          (esport) => esport?.slug === league?.esport?.slug
        ) ?? null
      return {
        id: league?.esport?.id,
        name: league?.esport?.name,
        slug: league?.esport?.slug,
        isChecked: false,
        avatarLogoUrl: esportAdapter?.avatarLogoUrl ?? '',
        gameType: esportAdapter?.gameType ?? '',
      }
    })
  }, [data, allEsportsData])

  // Generate a list of entries that include selectable esports for
  // this player and whether or not they have them already selected.
  const esportPreferences = useMemo(() => {
    const eligibleEsports = uniqBy(
      (eligibleEsport) => eligibleEsport.slug,
      availableEsports
    )

    return createPreferences(eligibleEsports, checkedEsportItems)
  }, [checkedEsportItems, availableEsports])

  useEffect(() => {
    const hasInterests = !!myEsportInterests.length
    const shouldInitialize = !loading && hasInterests && !hasInitialized

    if (shouldInitialize) {
      const userEsportInterestState = esportPreferences
        .filter((preference) => {
          return myEsportInterests.includes(preference.id)
        })
        .map(({ id }) => id)
      setHasInitialized(true)
      setInitialInterests(userEsportInterestState)
      setCheckedEsportItems(userEsportInterestState)
    }
  }, [
    myEsportInterests,
    esportPreferences,
    initialInterests,
    checkedEsportItems,
    hasInitialized,
  ])

  const onClose = (): void => {
    setCheckedEsportItems([])
    closeModal()
  }

  const hasChanged = !equals(initialInterests, checkedEsportItems)

  const onChange = (id: string): void => {
    if (checkedEsportItems.includes(id)) {
      setCheckedEsportItems(without([id], checkedEsportItems))
    } else {
      setCheckedEsportItems([...checkedEsportItems, id])
    }
  }

  const onClick = async (): Promise<void> => {
    if (hasChanged && userId) {
      try {
        const preferenceSet = await mutation({
          variables: {
            input: {
              userId,
              esportIds: checkedEsportItems,
            },
          },
        })
        const result = preferenceSet?.data?.setUserEsportInterests?.success
        if (!result) {
          throw new Error('Unable to save esport preferences')
        }
        closeModal()
      } catch (e: unknown) {
        setError(e as Error)
      }
    }
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <NxModal
      actions={
        <NxButton
          color="primary"
          disabled={(hasEsportInterests && !hasChanged) || isMutating}
          label={hasEsportInterests ? 'Save Changes' : 'Done'}
          onClick={onClick}
          variant="primary"
        />
      }
      contentClassName={classes.contentClassName}
      onClose={onClose}
      open={isModalOpen}
      subtitle="You can select multiple esport preferences for each player - this will
      help us create a better experience for you."
      title="Esport Preferences (Optional)"
    >
      <WaitTillLoaded
        loading={loading}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {errorMessage ? (
          <Box mb={2} mt={-1}>
            <Banner
              subtitle={errorMessage}
              title="Unable to assign esport preference for user"
              type={BannerType.Error}
            />
          </Box>
        ) : null}
        {esportPreferences.length ? (
          <EsportPreferenceModalContent
            entries={esportPreferences}
            onChange={onChange}
          />
        ) : null}
      </WaitTillLoaded>
    </NxModal>
  )
}
