import React from 'react'
import createAnAccount from '@plvs/rally/assets/backgrounds/create-an-account.png'
import parentQRCode from '@plvs/rally/assets/enrollment/parent-signup-qr.png'
import { makeStyles, Grid, Box, Hidden } from '@material-ui/core'
import {
  NxButton,
  NxTextLink,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'

import { PublicPage } from './page'
import { SignUpBenefits } from '../features/login/SignUpBenefits'
import { LoginSideBanner } from '../features/login/LoginSideBanner'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  title: {
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 600,
    height: '100%',
  },

  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginLeft: theme.spacing(-0.875),
    },
  },
  content: {
    height: 'calc(100vh - 240px)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '135px',
    [theme.breakpoints.down('sm')]: {
      height: 584,
      paddingTop: theme.spacing(9),
    },
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'max-content',
      padding: theme.spacing(5, 0, 2),
    },
  },
  playvsLogo: {
    marginTop: theme.spacing(0.6),
  },
  signUpLink: {
    textTransform: 'lowercase',
  },
  supportLink: {
    marginTop: theme.spacing(2),
  },
}))

export const ParentSignUpRequestPage: React.FC = () => {
  const { RegularFirebob, PlayVsLogo } = NxMiscAssets

  const classes = useStyles()

  const sideBar = (
    <LoginSideBanner height="100%" src={createAnAccount}>
      <Box className={classes.benefitsContainer}>
        <SignUpBenefits />
      </Box>
    </LoginSideBanner>
  )

  const navigateToMarketing = (): void => {
    window.location.href = 'https://playvs.com/'
  }

  const parentSignupLink = `${window.location.origin}/signup-parent`

  return (
    <PublicPage description="Request Parents to Sign up" title="Parent Signup">
      <Box display="flex" flex={1} flexDirection="column">
        <Grid className={classes.gridContainer} container>
          <Hidden smDown>
            <Grid item md={6}>
              {sideBar}
            </Grid>
          </Hidden>
          <Grid className={classes.formContainer} item md={6} xs={12}>
            <Box className={classes.logoContainer} minHeight={48} mt={4}>
              <RegularFirebob height={48} width={48} />
              <PlayVsLogo
                className={classes.playvsLogo}
                height={30}
                width={85}
              />
            </Box>
            <Box className={classes.content}>
              <NxTypography className={classes.title} variant="display">
                Almost there!
              </NxTypography>

              <Box maxWidth={404} my={3}>
                <NxTypography
                  className={classes.title}
                  data-cy="parental-request-message"
                  data-testid="parental-request-message"
                  variant="subtitle1"
                >
                  You need a parent to create your account.
                </NxTypography>
              </Box>

              <Box mb={1}>
                <NxTypography variant="body2">
                  Share this link to invite your parent to sign up:
                </NxTypography>
              </Box>
              <NxTextLink
                className={classes.signUpLink}
                href={parentSignupLink}
                label={parentSignupLink}
                labelVariant="body1"
              />

              <Box my={2}>
                <NxTypography variant="body2">OR</NxTypography>
              </Box>

              <NxTypography variant="body2">
                Have your parent scan this QR code to sign up:
              </NxTypography>

              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={1}
                width={404}
              >
                <img
                  alt="https://app.playvs.com/signup-parent"
                  data-cy="qr-code"
                  data-testid="qr-code"
                  height={120}
                  src={parentQRCode}
                  width={120}
                />
                <Box mt={3} />
                <NxButton
                  data-cy="back-to-homepage-button"
                  data-testid="back-to-homepage-button"
                  fullWidth
                  label="Back to Homepage"
                  onClick={navigateToMarketing}
                  variant="primary"
                />
                <NxTypography className={classes.supportLink} variant="body1">
                  Need help?{' '}
                  <NxTextLink
                    href="mailto:support@playvs.com"
                    label="Contact Support"
                  />
                </NxTypography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Hidden mdUp>{sideBar}</Hidden>
      </Box>
    </PublicPage>
  )
}
