import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel'
import { map } from 'ramda'
import React from 'react'

interface RadioOption {
  label: string
  value: string
}

interface Props {
  defaultValue?: string
  error?: boolean
  helperText?: React.ReactNode
  inputRef: React.Ref<any> | React.RefObject<any>
  InputLabelProps?: MuiInputLabelProps
  label?: string
  name: string
  options: RadioOption[]
}

export const RadioField = ({
  defaultValue,
  error,
  helperText,
  inputRef,
  InputLabelProps,
  label,
  name,
  options,
}: Props): React.ReactElement => {
  return (
    <FormControl error={error}>
      {label && <FormLabel {...InputLabelProps}>{label}</FormLabel>}
      <RadioGroup defaultValue={defaultValue}>
        {map(
          (option: RadioOption): React.ReactElement => (
            <FormControlLabel
              key={option.value}
              control={<Radio inputRef={inputRef} name={name} />}
              label={option.label}
              value={option.value}
            />
          ),
          options
        )}
      </RadioGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
