import { makeStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { cleanGraphQLError } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { refetchGetEventSeasonQuery, useLeaveTeamMutation } from '@plvs/graphql'
import {
  NxAlert,
  NxButton,
  NxModal,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { useSnackbar } from 'notistack'
import { ApolloError } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  disclaimerText: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    '&.MuiButton-root': {
      color: theme.palette.ColorTextAlt,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  reminder: {
    color: theme.palette.ColorTextAlt2,
    marginTop: theme.spacing(1),
  },
}))

interface UnenrollTeamProps {
  teamId: string
  seasonId: string
  onClose(): void
  onSuccess(): void
}

export const LeaveTeam: React.FC<UnenrollTeamProps> = ({
  teamId,
  seasonId,
  onClose,
  onSuccess,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState<ApolloError | null>(null)
  const theme = useTheme()
  const [leaveTeam] = useLeaveTeamMutation()

  const onClick = async (): Promise<void> => {
    try {
      const result = await leaveTeam({
        variables: {
          teamId,
        },
      })

      const success = result?.data?.leaveTeam?.success ?? false
      if (success) {
        onClose()
        onSuccess()
        refetchGetEventSeasonQuery({ seasonId, isSignedIn: true })
        enqueueSnackbar('You have left the team.', {
          variant: 'warning',
        })
      }
    } catch (error) {
      setError(error as ApolloError)
    }
  }

  const errorMessage = error?.message ? cleanGraphQLError(error.message) : null

  return (
    <NxModal
      actions={
        <Box display="flex" gridGap={theme.spacing(1)}>
          <NxButton
            className={classes.cancelButton}
            color="primary"
            data-testid="cancel"
            label="Cancel"
            onClick={onClose}
            variant="secondary"
          />
          <NxButton
            color="primary"
            data-testid="confirm"
            label="Confirm"
            onClick={onClick}
            variant="primary"
          />
        </Box>
      }
      maxWidth="md"
      onClose={onClose}
      open
      title="Leave Team"
      width={480}
    >
      {errorMessage && (
        <Box pb={3}>
          <NxAlert
            canClose
            content={
              <NxTypography
                colorToken="ColorTextAlt2"
                data-testid="Banner_Subtitle"
                variant="body1"
              >
                {errorMessage}
              </NxTypography>
            }
            title={
              <NxTypography data-testid="Banner_Title" variant="h4">
                Unable to leave team
              </NxTypography>
            }
            variant="error"
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        <NxTypography className={classes.disclaimerText} variant="body1">
          Are you sure you want to leave the team?
        </NxTypography>
      </Box>
    </NxModal>
  )
}
