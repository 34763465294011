import { Box, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React from 'react'
import {
  useDenyVerifiedPlayerMutation,
  useGraduatePlayerMutation,
  useTransferPlayerMutation,
} from '@plvs/graphql'
import { formErrorToString } from '@plvs/utils'
import * as analytics from '@plvs/respawn/features/analytics'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import { Moremenu } from '@playvs-inc/nexus-icons'
import { evictPlayersTableCache } from '@plvs/rally/features/coach/players/helpers'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 5,
  },
}))

export interface UserVerifiedPlayerMoreProps {
  id: string
  schoolId: string
  gradYear: number | null
}

export const UserVerifiedPlayerMore: React.FC<UserVerifiedPlayerMoreProps> = ({
  id,
  schoolId,
  gradYear,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const [
    denyVerifiedPlayer,
    { loading: denyingVerifiedPlayer },
  ] = useDenyVerifiedPlayerMutation({
    update: evictPlayersTableCache,
  })
  const [
    graduatePlayer,
    { loading: graduatingPlayer },
  ] = useGraduatePlayerMutation({
    update: evictPlayersTableCache,
  })
  const [
    transferPlayer,
    { loading: transferringPlayer },
  ] = useTransferPlayerMutation({
    update: evictPlayersTableCache,
  })

  const onDeny = async (): Promise<void> => {
    try {
      const deny = await denyVerifiedPlayer({
        variables: {
          userId: id,
          resourceId: schoolId,
        },
      })
      const success = deny?.data?.denyVerifiedPlayer?.success
      if (success) {
        analytics.deniedVerifiedPlayer({
          deniedUserId: id,
          schoolId,
          gradYear: gradYear ?? null,
        })
        enqueueSnackbar('Success! Player denied.', {
          variant: 'success',
        })
      } else throw new Error()
    } catch (e: any) {
      handleClose()
      enqueueSnackbar(`Could not deny player: ${formErrorToString(e)}`, {
        variant: 'error',
      })
    }
  }

  const onGraduate = async (): Promise<void> => {
    const input = { userId: id, resourceId: schoolId }
    try {
      const graduate = await graduatePlayer({
        variables: {
          input,
        },
      })
      const success = graduate?.data?.graduatePlayer?.success
      if (success) {
        analytics.graduateVerifiedPlayer({
          graduatedUserId: id,
          schoolId,
          gradYear: gradYear ?? null,
        })
        enqueueSnackbar('Success! Player graduated.', {
          variant: 'success',
        })
      } else throw new Error()
    } catch (e: any) {
      handleClose()
      enqueueSnackbar(`Could not graduate player: ${formErrorToString(e)}`, {
        variant: 'error',
      })
    }
  }

  const onTransfer = async (): Promise<void> => {
    const input = { userId: id, resourceId: schoolId }

    try {
      const deny = await transferPlayer({
        variables: {
          input,
        },
      })
      const success = deny?.data?.transferPlayer?.success
      if (success) {
        analytics.transferVerifiedPlayer({
          transferredUserId: id,
          schoolId,
          gradYear: gradYear ?? null,
        })
        enqueueSnackbar('Success! Player transferred.', {
          variant: 'success',
        })
      } else throw new Error()
    } catch (e: any) {
      handleClose()
      enqueueSnackbar(`Could not transfer player: ${formErrorToString(e)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      <NxIconButton
        aria-label="copy"
        className={classes.iconButton}
        data-cy="verified-player-more-menu"
        icon={<Moremenu />}
        label=""
        onClick={handleClick}
        variant="text"
      />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem
          data-cy="verified-player-more-deny-button"
          disabled={denyingVerifiedPlayer}
          onClick={onDeny}
        >
          <NxTypography variant="body1">Deny Player</NxTypography>
        </MenuItem>
        <MenuItem
          data-cy="verified-player-more-graduate-button"
          disabled={graduatingPlayer}
          onClick={onGraduate}
        >
          <NxTypography variant="body1">Player Graduated</NxTypography>
        </MenuItem>
        <MenuItem
          data-cy="verified-player-more-transfer-button"
          disabled={transferringPlayer}
          onClick={onTransfer}
        >
          <NxTypography variant="body1">Player Transferred</NxTypography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
