import React from 'react'
import { isNil } from 'ramda'
import {
  Tour as TourType,
  useGetTourQuery,
  useSetTourViewedMutation,
} from '@plvs/graphql/generated'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  productTourViewed,
  findMatchTourViewed,
  matchLobbyTourViewed,
} from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/rally/init/dayjs'

// eslint-disable-next-line import/no-unresolved
import Tour from 'shepherd.js/src/types/tour'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useProductTours } from './useProductTours'

interface Props {
  tour: Tour
  tourType: TourType
}

const segmentViewedMap = {
  [TourType.Product]: productTourViewed,
  [TourType.FindYourMatch]: findMatchTourViewed,
  [TourType.MatchLobby]: matchLobbyTourViewed,
}

export const ShepherdStarter: React.FC<Props> = ({ tour, tourType }) => {
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'), {
    noSsr: true,
  })

  const { userId } = useUserIdentityFn()

  const { data, loading } = useGetTourQuery({
    variables: { tour: tourType },
    fetchPolicy: 'network-only',
  })

  const [setTourViewed] = useSetTourViewedMutation()

  const { setInitiatedTimestamp } = useProductTours()

  const handleSeen = async (): Promise<void> => {
    await setTourViewed({ variables: { tour: tourType } })

    setInitiatedTimestamp(dayjs().toISOString())

    segmentViewedMap[tourType]({
      userId,
      initiatedTimestamp: dayjs().toISOString(),
      timeStamp: dayjs().toISOString(),
      stepNumber: 0,
      clickTarget: 'Conditional Trigger',
    })
  }

  const doesNotQualify = data?.getTour === null

  const hasSeenTour = !isNil(data?.getTour?.viewedAt)

  React.useEffect(() => {
    if (!loading && !hasSeenTour && !tour.isActive() && !doesNotQualify) {
      tour.on('start', handleSeen)
      tour.start()
    }

    if (!isDesktop && tour.isActive()) {
      tour.complete()
    }
  }, [isDesktop, hasSeenTour, loading, doesNotQualify])

  return null
}
