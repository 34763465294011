import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

interface StyleProps {
  hasUnreadNotifications?: boolean
}

export const useStyles = makeStyles((theme) => ({
  taskSection: {
    backgroundColor: theme.palette.ColorBackgroundAccent,
    boxShadow: 'none',
  },
  taskTitle: {
    fontSize: '1.13rem',
    color: theme.palette.common.white,
  },
  fetchMoreNotifications: {
    borderRadius: theme.shape.borderRadius,
  },
  unreadDot: {
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '0.5em',
    bottom: '50%',
    transform: 'translate(0, 50%)',
  },
  markAllRead: ({
    hasUnreadNotifications,
  }: StyleProps): CreateCSSProperties => ({
    color: hasUnreadNotifications
      ? theme.palette.ColorTextLink
      : theme.palette.ColorTextDisabled,
    cursor: hasUnreadNotifications ? 'pointer' : 'auto',
    pointerEvents: hasUnreadNotifications ? 'auto' : 'none',
    marginLeft: 'auto',
    position: 'absolute',
    top: 20,
    right: 16,
  }),
}))
