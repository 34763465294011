import { Box, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import {
  NxButton,
  NxTextLink,
  NxCardPaginationButtons,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'
import {
  useGetSchoolTeamIdsQuery,
  useGetMatchesQuery,
  useGetTeamSlotsWithQueueMatchesQuery,
  useGetTeamsForScheduleMatchesWithSlotsQuery,
  Tour,
} from '@plvs/graphql'
import {
  useBreakpointXs,
  useWindowDimensions,
} from '@plvs/respawn/features/layout'
import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import {
  buildAllPossibleQueueMatches,
  filterQueueMatches,
  getMatchesPrepped,
} from '@plvs/rally/features/match/schedule/matchesHelper'
import {
  SchedulePageMatch,
  SchedulePageQueueMatch,
} from '@plvs/rally/features/match/schedule/schedule'
import { Stadium } from '@playvs-inc/nexus-icons'
import { appendClasses, getNow, useAutoskipQuery } from '@plvs/utils'
import dayjs from 'dayjs'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { uniq } from 'ramda'
import { isSafari } from '@plvs/rally/core'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ShepherdStarter } from '@plvs/rally/init/shepherd/ShepherdStarter'
import { useProductTours } from '@plvs/rally/init/shepherd/useProductTours'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import CreateScrimmageExploreCard from './CreateScrimmageExploreCard'
import DashboardMatchCard from './DashboardMatchCard'
import { DashboardQueueMatchCard } from './DashboardMatchCard/DashboardQueueMatchCard'

const useStyles = makeStyles((theme) => ({
  matchesContainer: {
    overflow: 'hidden',
    position: 'relative',
    height: 'max-content',
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  schedule: {
    color: theme.palette.ColorTextLink || theme.palette.primary.main,
    cursor: 'pointer',
  },
  matches: ({
    isMobile,
    hasMatches,
  }: {
    isMobile: boolean
    hasMatches: boolean | undefined
  }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'max-content',
    minHeight: '15rem',
    width: !isMobile && !hasMatches ? 'calc(100% - 23px)' : '100%',
    padding: theme.spacing(3),
    scrollSnapType: 'x mandatory',
    scrollPadding: '0',
    overflow: isMobile ? 'auto' : 'hidden',
  }),
  noMatches: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'max-content',
    maxWidth: '32rem',
    alignItems: 'center',
  },
  scrimmageIcon: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    marginRight: isMobile ? '0' : theme.spacing(3),
    height: isMobile ? '0' : '100%',
    width: isMobile ? '0' : '100%',
  }),
  emptyContent: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: isMobile ? '0' : theme.spacing(3),
  }),
  emptyBody: {
    marginTop: theme.spacing(1),
    fontSize: '0.88rem',
    fontWeight: 400,
    paddingRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  scrollControlContainer: {
    display: 'flex',
    position: 'absolute',
    right: '1.5rem',
    top: '.3rem',
    width: '3.75rem',
    height: '1.5rem',
    justifyContent: 'space-between',
  },
  icon: {
    height: '1.5rem',
  },
  flippedIcon: {
    height: '1.5rem',
    transform: 'rotate(180deg)',
  },
}))

export const UpcomingMatches: React.FC<{
  isCoach: boolean
  isPendingVerification: boolean
}> = ({ isCoach, isPendingVerification }) => {
  const flags = useFlags()
  const { onboardingTour, findMatchTour } = useProductTours()
  const [visibleMatchesTimeWindow] = useState(
    getNow({ minuteDifference: -120 })
  )
  const [isCarouselNavVisible, setIsCarouselNavVisible] = useState(false)
  const [isNextAllowed, setIsNextAllowed] = useState(false)

  const { width } = useWindowDimensions()
  const { id: orgId } = useSelectedOrganizationFn()

  const { loadingTeams, schoolTeamIds } = useTeamsContext()

  const {
    data: selectedSchoolTeamIdsData,
    loading: loadingSchoolTeams,
  } = useAutoskipQuery(useGetSchoolTeamIdsQuery, {
    variables: {
      id: orgId,
    },
    skip: !orgId || isPendingVerification,
  })

  const myTeamIds = uniq([
    ...schoolTeamIds,
    ...((isCoach &&
      selectedSchoolTeamIdsData?.school?.teams?.map(({ id }) => id)) ||
      []),
  ])

  const hasTeams = myTeamIds.length !== 0

  const { data, loading: loadingMatches } = useGetMatchesQuery({
    variables: {
      isFortnite: false,
      limit: 1000,
      filters: {
        teamIds: myTeamIds,
        startsAt: {
          ...{ after: visibleMatchesTimeWindow },
        },
      },
    },
    skip: loadingSchoolTeams || loadingTeams || !hasTeams,
  })

  const {
    data: teamsData,
    loading: loadingTeamSchedules,
  } = useGetTeamsForScheduleMatchesWithSlotsQuery({
    variables: {
      teamIds: myTeamIds,
      minStartsAt: visibleMatchesTimeWindow,
    },
    skip: loadingSchoolTeams || loadingTeams || !hasTeams,
  })

  const allPossibleQueueMatchesAndSlots = teamsData
    ? buildAllPossibleQueueMatches(teamsData)
    : undefined
  const slotIds = allPossibleQueueMatchesAndSlots?.slotIds ?? []

  const {
    data: slotTeams,
    loading: queueLoading,
  } = useGetTeamSlotsWithQueueMatchesQuery({
    variables: {
      teamIds: myTeamIds,
      slotIds: Array.from(slotIds),
    },
    skip: loadingSchoolTeams || loadingTeams || !hasTeams,
  })

  const queueMatches: SchedulePageQueueMatch[] =
    allPossibleQueueMatchesAndSlots && slotTeams
      ? filterQueueMatches(allPossibleQueueMatchesAndSlots, slotTeams)
      : []

  const rawMatches = data?.getMatches?.matches ?? []
  const teamSlots = teamsData?.teamsByIds ?? []

  const matches =
    useMemo(() => {
      // @ts-ignore
      return getMatchesPrepped(rawMatches, teamSlots, myTeamIds)
    }, [rawMatches, teamSlots, myTeamIds]) ?? []

  const matchesContainerRef = useRef<HTMLDivElement>()
  const [currentMatchCardViewed, setCurrentMatchCardViewed] = useState(1)
  const isMobile = useBreakpointXs()

  const hasMatches =
    (matches && matches?.length > 0) ||
    (queueMatches && queueMatches.length > 0)

  const classes = useStyles({ isMobile, hasMatches })

  const matchesTitleText = hasMatches
    ? 'Your Match Line Up'
    : 'No Upcoming Matches'

  const findYourMatchTourEnabled =
    !!flags.matchDayOnboardingTour && !onboardingTour?.isActive() && isCoach

  const combinedArray = [...matches, ...queueMatches]
    .sort((a, b) => {
      const dateA = dayjs(a.scheduledStartsAt)
      const dateB = dayjs(b.scheduledStartsAt)
      return dateA > dateB ? 1 : -1
    })
    .slice(0, 10)

  const hasManyMatches = [...matches, ...queueMatches].length > 10

  // Also shows loading state if there are teams associated to the current user
  const emptyState = (
    <Box className={classes.emptyContainer}>
      <Stadium className={classes.scrimmageIcon} />
      {isCoach ? (
        <Box className={classes.emptyContent}>
          <NxTypography variant="h4">Scrimmage While You Wait</NxTypography>
          <NxTypography className={classes.emptyBody} variant="body1">
            While you are waiting for the next season match, schedule a
            scrimmage to practice your drills and scope out the competition
          </NxTypography>
          <NxButton
            className={classes.button}
            data-testid="scrimmage-button"
            href={Path.Scrimmage}
            label="View Scrimmages"
            variant="secondary"
          />
        </Box>
      ) : (
        <Box className={classes.emptyContent}>
          <NxTypography variant="h4">
            Your Upcoming Matches Will Display Here
          </NxTypography>
          <NxTypography className={classes.emptyBody} variant="body1">
            Enroll into available competitions or leagues on the Explore page to
            compete.
          </NxTypography>
          <NxButton
            className={classes.button}
            data-testid="scrimmage-button"
            href={Path.Explore}
            label="Explore Competitions"
            variant="secondary"
          />
        </Box>
      )}
    </Box>
  )

  const matchesToShow =
    !loadingMatches && !loadingTeamSchedules && !queueLoading ? (
      <>
        {combinedArray?.map((match, index) => {
          // we combined the two arrays so we could sort them properly
          // now we need to disassemble the array per entry
          // only normal scheduled matches have ids
          const isRegularMatch = Object.prototype.hasOwnProperty.call(
            match,
            'id'
          )
          if (isRegularMatch) {
            const matchAsRegularMatch = match as SchedulePageMatch
            return (
              <DashboardMatchCard
                key={matchAsRegularMatch?.id}
                isCoach={isCoach}
                isMobile={isMobile}
                match={matchAsRegularMatch}
              />
            )
          }

          const matchAsQueueMatch = match as SchedulePageQueueMatch
          return (
            <DashboardQueueMatchCard
              // eslint-disable-next-line react/no-array-index-key
              key={`match-queue-card-${index}`}
              match={matchAsQueueMatch}
            />
          )
        })}
        <CreateScrimmageExploreCard
          hasManyMatches={hasManyMatches}
          isCoach={isCoach}
          isMobile={isMobile}
        />
      </>
    ) : (
      <>
        {[1, 2, 3].map((m) => (
          <DashboardMatchCard key={m} isMobile={isMobile} match={undefined} />
        ))}
      </>
    )

  const onShowPreviousCard = (): void => {
    if (currentMatchCardViewed > 1) {
      setCurrentMatchCardViewed(currentMatchCardViewed - 1)
    }
  }
  const onShowNextCard = (): void => {
    if (isNextAllowed) {
      setCurrentMatchCardViewed(currentMatchCardViewed + 1)
    }
  }

  // Initial scroll next available task into focus.
  useLayoutEffect(() => {
    if (!matchesContainerRef.current || currentMatchCardViewed < 1) {
      return
    }

    const childTaskCards = matchesContainerRef.current?.querySelectorAll(
      'div.MatchCard'
    )

    const focusedTaskCard: Element | undefined =
      childTaskCards?.[currentMatchCardViewed - 1]

    if (!focusedTaskCard) {
      return
    }

    if (
      matchesContainerRef?.current?.scrollTo &&
      focusedTaskCard instanceof HTMLElement
    ) {
      matchesContainerRef.current.scrollTo({
        left: focusedTaskCard.offsetLeft,
        top: 0,
        behavior: isSafari ? undefined : 'smooth',
      })
    }
  }, [currentMatchCardViewed, matches])

  useLayoutEffect(() => {
    if (isMobile) {
      // Don't show pagination controls on mobile.
      setIsCarouselNavVisible(false)
      return
    }
    if (!matchesContainerRef.current) {
      return
    }

    const matchCardsDivs = matchesContainerRef.current?.querySelectorAll(
      'div.MatchCard'
    )

    // If no match cards don't show
    if (!matchCardsDivs || matchCardsDivs.length === 0) {
      return
    }

    setIsCarouselNavVisible(true)

    // No need to calculate if next is available if we only have 1 or fewer cards.
    if (matchCardsDivs.length < 1) {
      return
    }

    // Check if last card is fully visible within the visible part of the matches container.
    const matchContainerBoundingBox = matchesContainerRef.current.getBoundingClientRect()
    const lastMatchBoundingBox = matchCardsDivs[
      matchCardsDivs.length - 1
    ].getBoundingClientRect()
    if (lastMatchBoundingBox.right > matchContainerBoundingBox.right) {
      setIsNextAllowed(true)
    } else {
      setIsNextAllowed(false)
    }
  }, [currentMatchCardViewed, isMobile, width, matches])

  return (
    <>
      {findYourMatchTourEnabled && findMatchTour && (
        <ShepherdStarter tour={findMatchTour} tourType={Tour.FindYourMatch} />
      )}
      <Box className={classes.matchesContainer}>
        <Box data-cy="upcomingMatches" mb={1}>
          <NxTypography variant="h1">{matchesTitleText}</NxTypography>
        </Box>
        <Box>
          <NxTextLink
            className={classes.schedule}
            href={Path.Schedule}
            label="View Your Schedule"
            variant="body2"
          />
        </Box>

        <div className="shepherd-match-line-up">
          <Box
            className={appendClasses(
              classes.matches,
              hasMatches || loadingMatches ? '' : classes.noMatches
            )}
            data-testid="matches-container"
            mt={hasMatches || loadingMatches ? 0 : 2}
            // This hack is because the typings for Box is missing 'ref' prop.
            // but it actually exists.  https://github.com/mui-org/material-ui/issues/17010
            {...({ ref: matchesContainerRef } as Record<string, unknown>)}
          >
            {hasMatches || loadingMatches ? matchesToShow : emptyState}
            {hasMatches ? <Box pr={300} /> : null}
          </Box>
        </div>
        {isCarouselNavVisible && (
          <Box position="absolute" right="1em" top="1em">
            <NxCardPaginationButtons
              enableLeft={currentMatchCardViewed > 1}
              enableRight={isNextAllowed}
              onLeft={onShowPreviousCard}
              onRight={onShowNextCard}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
