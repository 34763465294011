import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

interface ReportAccordionItemStyleProps {
  isExpanded?: boolean
  isComplete?: boolean
}

const useStyles = makeStyles((theme) => ({
  accordionItem: ({
    isExpanded,
    isComplete,
  }: ReportAccordionItemStyleProps): CreateCSSProperties => ({
    borderBottom: `1px solid ${theme.palette.ColorBackgroundBase}`,
    borderLeft: `4px solid transparent`,
    borderLeftColor: 'transparent',

    // Unexpanded styles
    background: theme.palette.ColorBackgroundAlt,
    color: isComplete
      ? theme.palette.ColorTextBase
      : theme.palette.ColorTextDisabled,

    // Expanded styles
    ...(isExpanded
      ? {
          minHeight: 140,
          color: theme.palette.ColorTextBase,
          background: 'inherit',
          margin: 0,
          borderLeftColor: theme.palette.ColorBackgroundAccent,
        }
      : {}),
  }),
}))

interface ReportAccordionItemProps {
  isExpanded?: boolean
  isComplete?: boolean
  onClick(): void
  content: any
  expandedContent: any
}

export const ReportAccordionItem: React.FC<ReportAccordionItemProps> = ({
  isExpanded,
  isComplete,
  onClick,
  content,
  expandedContent,
}) => {
  const styles = useStyles({ isExpanded, isComplete })

  const handleKeyDown = (evnt: React.KeyboardEvent): void => {
    switch (evnt.key) {
      case 'Enter':
      case ' ':
        onClick()
        break
      default:
    }
  }

  return (
    <div
      className={styles.accordionItem}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      {isExpanded ? expandedContent : content}
    </div>
  )
}
