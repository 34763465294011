import React from 'react'

import { NxTooltip } from '@playvs-inc/nexus-components'

interface PlaybookProps {
  title?: string | null
  src?: string | null
}

export const Playbook: React.FC<PlaybookProps> = ({ title, src }) => {
  return src && title ? (
    <NxTooltip arrow placement="top" title={title}>
      <img alt={title} height={40} src={src} title={title} />
    </NxTooltip>
  ) : (
    <></>
  )
}
