import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { NxButton, NxIconButton, NxModal } from '@playvs-inc/nexus-components'
import { Regularcheck } from '@playvs-inc/nexus-icons'

import { TeamRivalFragment, useAcceptTeamRivalMutation } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useRosterContext } from '../../RosterProvider'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: `${theme.palette.ColorIconSuccess}!important`,
  },
}))

interface RosterRivalsAcceptButtonProps {
  rival: TeamRivalFragment
  onComplete?(): void
}

export const RosterRivalsAcceptButton: React.FC<
  RosterRivalsAcceptButtonProps
> = ({ rival, onComplete }) => {
  const styles = useStyles()
  const { teamId } = useRosterContext()

  const { enqueueSnackbar } = useSnackbar()
  const [acceptTeamRival, { loading: loadingAcceptTeamRival }] =
    useAcceptTeamRivalMutation()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (): void => {
    setIsOpen(true)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleAccept = async (): Promise<void> => {
    try {
      await acceptTeamRival({
        variables: {
          input: {
            acceptingTeamId: teamId,
            teamRivalId: rival.id,
          },
        },
      })
      onComplete?.()
      enqueueSnackbar('Rival accepted', { variant: 'success' })
      setIsOpen(false)
    } catch (err) {
      enqueueSnackbar(
        'There was an issue accepting this rival. Try again or reach out to Customer Experience.',
        {
          variant: 'error',
        }
      )
    }
  }

  return (
    <>
      <NxIconButton
        icon={<Regularcheck className={styles.icon} />}
        label=""
        onClick={handleOpen}
        variant="text"
      />

      {isOpen && (
        <NxModal
          actions={
            <>
              <NxButton label="Cancel" onClick={handleClose} variant="text" />

              <NxButton
                label="Yes, Approve"
                loading={loadingAcceptTeamRival}
                onClick={handleAccept}
                variant="primary"
              />
            </>
          }
          onClose={handleClose}
          open={isOpen}
          showTopRightClose
          subtitle={`You are about to approve ${rival.requestingTeam?.name}. You will not be able to remove them until next season.`}
          title="Approve Rival Invitation?"
        >
          <WaitTillLoaded
            loading={loadingAcceptTeamRival}
            loadingSpinnerProps={{ size: 'medium' }}
            showSpinnerWhileLoading
          />
        </NxModal>
      )}
    </>
  )
}
