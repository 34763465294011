import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import {
  useActiveGlobalChatConversationsVar,
  useGlobalUnreadMessagesVar,
} from '@plvs/client-data/hooks'
import { updateActiveGlobalWindows } from '@plvs/client-data/mutations'
import { updateActiveGlobalChatConversationsVar } from '@plvs/client-data/mutations/updateActiveGlobalChatConversationsVar'
import React, { useEffect, useState } from 'react'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  UserOnlineStatusProvider,
  useUserOnlineStatusContext,
} from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { GlobalChatWindow } from './GlobalChatWindow'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      paddingRight: 0,
    },
  },
}))
export const GlobalChat: React.FC = () => {
  const classes = useStyles()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const [conversationList, setConversationList] = useState<Array<string>>([])
  const {
    currentUserIdsMap,
    setCurrentUserIdsMap,
  } = useUserOnlineStatusContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()

  useEffect(() => {
    const newConversationList: Array<string> = []
    Object.keys(activeConversations).forEach((uniqueName) => {
      if (activeConversations[uniqueName]) {
        newConversationList.push(uniqueName)
      }
    })
    setConversationList(newConversationList)
    updateActiveGlobalWindows(newConversationList)
  }, [JSON.stringify(activeConversations)])

  const onClose = (input: string): void => {
    const newActiveConversations = { ...activeConversations }
    delete newActiveConversations[input]
    updateActiveGlobalChatConversationsVar(newActiveConversations)
    const hasAnyUnreadMessages = unreadMessagesByConversation[input] > 0
    analytics.globalChatWindowClosed({
      chatUniqueName: input,
      closedWithUnreadMessages: hasAnyUnreadMessages,
    })
    const newCurrentUserIdsMap = { ...currentUserIdsMap }
    delete newCurrentUserIdsMap[input]
    setCurrentUserIdsMap?.(newCurrentUserIdsMap)
  }

  return (
    <UserOnlineStatusProvider>
      <Box
        className={classes.footerContainer}
        data-cy="globalChatWindow"
        display="flex"
        height="0"
        justifyContent="flex-end"
      >
        {conversationList.map((uniqueName) => {
          return (
            <Box
              key={uniqueName}
              alignItems="flex-end"
              display="flex"
              px={isMobile ? undefined : 1}
            >
              <GlobalChatWindow
                activeConversations={activeConversations}
                onClose={(): void => {
                  onClose(uniqueName)
                }}
                uniqueName={uniqueName}
              />
            </Box>
          )
        })}
      </Box>
    </UserOnlineStatusProvider>
  )
}
