import React from 'react'

import { VersusBar } from '@plvs/rally/components/match'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { EsportTeamStatsKey } from '@plvs/respawn/features/esport/creator/types'
import { TeamGameStats, TeamMatchStats } from '@plvs/graphql'
import { StatsScope } from '@plvs/respawn/features/esport/adapters/constants'

interface VersusBar {
  accessor: EsportTeamStatsKey
  label: string
  format?: (value: number) => string | number
}

interface VersusBar {
  accessor: EsportTeamStatsKey
  label: string
  format?: (value: number) => string | number
}

export interface Props {
  teamAGameStats?: Pick<TeamGameStats, '__typename'> & {
    stats: unknown | null
  }
  teamAMatchStats?: Pick<TeamMatchStats, '__typename'> & {
    stats: unknown | null
  }

  teamBGameStats?: Pick<TeamGameStats, '__typename'> & {
    stats: unknown | null
  }
  teamBMatchStats?: Pick<TeamMatchStats, '__typename'> & {
    stats: unknown | null
  }
  type?: 'game' | 'match'
}

export const VersusBars: React.FC<Props> = ({
  teamAGameStats,
  teamAMatchStats,
  teamBGameStats,
  teamBMatchStats,
  type = 'game',
}) => {
  const scope = type === 'game' ? StatsScope.Game : StatsScope.Match
  const { getVersusBars } = useEsportContext()

  return (
    <div>
      {getVersusBars(scope).map(({ accessor, ...rest }: VersusBar) => {
        // @ts-ignore
        const teamAValue = (type === 'game' ? teamAGameStats : teamAMatchStats)
          ?.stats?.[accessor]
        // @ts-ignore
        const teamBValue = (type === 'game' ? teamBGameStats : teamBMatchStats)
          ?.stats?.[accessor]

        return (
          <VersusBar
            key={accessor}
            teamAValue={teamAValue}
            teamBValue={teamBValue}
            {...rest}
          />
        )
      })}
    </div>
  )
}
