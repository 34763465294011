import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { Avatar } from '@plvs/rally/components/avatar'
import PeopleIcon from '@plvs/rally/assets/icons/groups.svg'
import { Product } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  body1: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export interface PaymentPlanProps {
  id: string
  hasPaymentPlan: boolean
  metaseasonName: string
  productPurchased: Pick<Product, 'id' | 'name'> | undefined | null
}

export const PaymentPlan: React.FC<PaymentPlanProps> = ({
  hasPaymentPlan,
  metaseasonName,
  productPurchased,
}) => {
  const classes = useStyles()
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      pb={1}
      pt={4}
      px={4}
    >
      <Box display="flex" flex={1} flexDirection="column">
        {hasPaymentPlan ? (
          <Box
            key={productPurchased?.id}
            data-testid="PaymentPlan_PlanContainer"
            display="flex"
            flexDirection="column"
          >
            <Box alignItems="center" display="flex" flexDirection="row">
              {/* // TODO: remove default PeopleIcon to map product to correct icons */}
              <Avatar size="smedium" src={PeopleIcon} />
              <NxTypography variant="body2">
                {productPurchased?.name}
              </NxTypography>
              <NxTypography className={classes.body1} variant="body1">
                {metaseasonName}
              </NxTypography>
            </Box>
          </Box>
        ) : (
          <>
            <NxTypography variant="body2">No Payment Plan</NxTypography>
            <Box pt={1}>
              <NxTypography
                className={classes.body1}
                data-testid="PaymentPlan_NoPlanDisplay"
                variant="body1"
              >
                This school currently has no payment plan.
              </NxTypography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
