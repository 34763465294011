import { ErrorCode } from '@plvs/graphql/generated'

export const ErrorCodeMessageMap = {
  [ErrorCode.TeamExisted]: 'You are already a part of this team.',
  [ErrorCode.AgeRequirementNotMet]:
    'You do not meet the age requirement to join this team.',
  [ErrorCode.EnrollmentNotOpen]: 'This event is not open for enrollment.',
  [ErrorCode.EmailNotVerified]:
    'You must first verify your email to join or create a team and compete.',
  [ErrorCode.MaxNumberOfTeamsReached]:
    'You may not enroll in more teams at the same time, please leave a team first.',
  [ErrorCode.TeamNameExisted]: 'A team with this name already exists.',
}
