import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core'

import {
  GameStatus,
  MatchStatus,
  useGetEntryCodesQuery,
} from '@plvs/graphql/generated'

import { Duplicate, Regularcheck, Ban } from '@playvs-inc/nexus-icons'
import { Polling } from '@plvs/const'
import { NxIconButton } from '@playvs-inc/nexus-components'
import { useClipboard } from '@plvs/respawn/features/button'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.ColorTextAlt,
    marginRight: theme.spacing(2),
  },
  copyButtonLabel: {
    color: theme.palette.ColorTextBase,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  copyButtonIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      height: 20,
      width: 20,
    },
  },
}))

interface GameCodeCopyButtonProps {
  matchId: string
}

export const GameCodeCopyButton: React.FC<GameCodeCopyButtonProps> = ({
  matchId,
}) => {
  const classes = useStyles()

  const {
    data: matchData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useGetEntryCodesQuery({
    variables: { matchId },
    skip: !matchId,
    ssr: false,
  })

  const status = matchData?.match?.status
  const openGame = matchData?.match?.games?.find(
    (game) => game.status === GameStatus.Open || game.status === GameStatus.Live
  )
  const gameCode = openGame?.entryCode
  const errorGettingCode = error || (openGame && !gameCode)

  useEffect(() => {
    if (status === MatchStatus.Open || status === MatchStatus.Live) {
      startPolling(Polling.Normal)
    }
    return (): void => stopPolling()
  }, [status])

  const { copy, wasRecentlyCopied } = useClipboard(gameCode || '')

  let buttonLabel: string
  let icon: JSX.Element

  if (errorGettingCode || !gameCode) {
    buttonLabel = 'Game Code Unavailable'
    icon = <Ban />
  } else if (wasRecentlyCopied) {
    buttonLabel = 'Copied'
    icon = <Regularcheck className={classes.copyButtonIcon} />
  } else {
    buttonLabel = `Game ${openGame?.ordinalNumber} Code`
    icon = <Duplicate className={classes.copyButtonIcon} />
  }

  return (
    <NxIconButton
      disabled={loading || !!error || !gameCode || wasRecentlyCopied}
      icon={icon}
      id="game-code-copy"
      label={buttonLabel}
      loading={loading}
      onClick={copy}
      style={{ minWidth: '100px' }}
      variant={errorGettingCode ? 'error' : 'secondary'}
    />
  )
}
