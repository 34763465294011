import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import ReferCoach from '@plvs/rally/assets/league-details/refer-coach.png'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  useGetLocalReferralSchoolsQuery,
  useGetSchoolCoordinatesQuery,
} from '@plvs/graphql/generated'
import { CompetingSchoolsNear } from './CompetingSchoolsNear'
import { SchoolReferralRow } from './SchoolReferralRow'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 200,
  },
  text: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  schoolText: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  schoolId: string
}

export const ReferCard: React.FC<Props> = ({ schoolId }) => {
  const classes = useStyles()

  const {
    data: mySchoolData,
    loading: mySchoolDataLoading,
  } = useGetSchoolCoordinatesQuery({
    variables: { id: schoolId },
    skip: !schoolId,
  })

  const lat = mySchoolData?.school?.lat ?? 0
  const lon = mySchoolData?.school?.lon ?? 0

  const { data, loading } = useGetLocalReferralSchoolsQuery({
    variables: {
      limit: 10,
      filters: {
        geolocation: {
          miles: 50,
          lat,
          lon,
        },
        hasVerifiedCoach: false,
        deleted: false,
      },
    },
    skip: mySchoolDataLoading || !lat || !lon,
  })

  const schools = (data?.getSchoolsES?.schools ?? []).filter(
    (school) => school?.id !== schoolId
  )

  return (
    <WaitTillLoaded
      loading={mySchoolDataLoading || loading}
      showSpinnerWhileLoading
    >
      {schools.length > 0 && (
        <div className={classes.root}>
          <NxTypography className={classes.title} variant="h2">
            Refer a Coach
          </NxTypography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={0.5}
          >
            <img alt="refer-coach" className={classes.img} src={ReferCoach} />
            <NxTypography className={classes.text} variant="body1">
              Referring coaches at other schools helps grow the scholastic
              esports community and results in more competition!
            </NxTypography>
          </Box>
          {schools.length > 0 && (
            <section>
              <NxTypography className={classes.schoolText} variant="overline">
                school
              </NxTypography>
              {schools.map((school) => (
                <SchoolReferralRow key={school.id} school={school} />
              ))}
            </section>
          )}
          <section data-cy="competingSchoolsNearYou">
            <CompetingSchoolsNear lat={lat} lon={lon} schoolId={schoolId} />
          </section>
        </div>
      )}
    </WaitTillLoaded>
  )
}
