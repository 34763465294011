import React from 'react'
import { makeStyles, Card, Theme } from '@material-ui/core'
import { Colors, getRaisedHoverStyle } from '@plvs/rally/themes'
import { Box } from '@plvs/respawn/features/layout'
import { Checkbox } from '../checkbox/Checkbox'

interface Props {
  checked?: boolean
  checkedColor?: string
  backgroundColor?: string
  borderColor?: string
  inputRef?: React.Ref<any>
  name?: string
  maxWidth?: string
  onChange?(event?: React.ChangeEvent<HTMLInputElement>): void
  containerStyles?: any
  ariaLabelledBy?: string
  ariaDescribedBy?: string
  onClick?(): void
}

const useStyles = makeStyles<Theme, Props>({
  boxContainer: ({ containerStyles }: Props): any => ({
    position: 'relative',
    cursor: 'pointer',
    ...containerStyles,
    ...getRaisedHoverStyle(),
  }),
  card: (): any => ({
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 32px',
  }),
  outlineIcon: {
    color: Colors.Grey4,
    fontSize: 32,
  },
  inActiveText: {
    color: Colors.Grey3,
  },
})

export const SelectorContainer: React.FC<Props> = ({
  checked,
  checkedColor = Colors.Green,
  backgroundColor = 'rgba(0, 170, 111, 0.25)',
  borderColor = Colors.Grey4,
  inputRef,
  name,
  children,
  maxWidth,
  onChange,
  containerStyles,
  ariaDescribedBy,
  ariaLabelledBy,
  onClick,
}) => {
  const classes = useStyles({
    containerStyles,
  })

  return (
    <Box
      className={classes.boxContainer}
      maxWidth={maxWidth}
      onClick={onClick}
      width="100%"
    >
      <Card className={classes.card}>
        {children}
        <Checkbox
          ariaDescribedBy={ariaDescribedBy}
          ariaLabelledBy={ariaLabelledBy}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          checked={checked}
          checkedColor={checkedColor}
          inputRef={inputRef}
          name={name}
          onChange={onChange}
        />
      </Card>
    </Box>
  )
}
