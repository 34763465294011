import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const ClockCircle = createSvgIcon(
  <svg
    fill="none"
    height="56"
    viewBox="0 0 56 56"
    width="56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="28"
      cy="28"
      r="27"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M28 16C21.3726 16 16 21.3726 16 28C16 34.6274 21.3726 40 28 40C34.6274 40 40 34.6274 40 28C39.9928 21.3756 34.6244 16.0072 28 16ZM28 36.5C27.3096 36.5 26.75 35.9404 26.75 35.25C26.75 34.5596 27.3096 34 28 34C28.6904 34 29.25 34.5596 29.25 35.25C29.25 35.5815 29.1183 35.8995 28.8839 36.1339C28.6495 36.3683 28.3315 36.5 28 36.5ZM27.293 28.707C27.4805 28.8945 27.7348 28.9999 28 29H35C35.5523 29 36 28.5523 36 28C36 27.4477 35.5523 27 35 27H28.414L25.707 24.294C25.3134 23.9244 24.6975 23.934 24.3156 24.3156C23.9337 24.6973 23.9237 25.3132 24.293 25.707L27.293 28.707ZM22 28C22 28.6904 21.4404 29.25 20.75 29.25C20.0596 29.25 19.5 28.6904 19.5 28C19.5 27.3096 20.0596 26.75 20.75 26.75C21.4404 26.75 22 27.3096 22 28ZM29.25 20.75C29.25 20.0596 28.6904 19.5 28 19.5C27.3096 19.5 26.75 20.0596 26.75 20.75C26.75 21.4404 27.3096 22 28 22C28.6904 22 29.25 21.4404 29.25 20.75Z"
      fill="url(#paint1_linear)"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear"
        x1="28"
        x2="28"
        y1="0"
        y2="56"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FF530A" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear"
        x1="28"
        x2="28"
        y1="16"
        y2="40"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FF530A" />
      </linearGradient>
    </defs>
  </svg>,
  'ClockCircle',
  { viewBox: '0 0 56 56' }
)
