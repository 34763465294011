import { makeStyles } from '@material-ui/core'

export const useAnimationStyles = makeStyles({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg) scale(1.0)',
    },
    '7.5%': {
      transform: 'rotate(25deg) scale(1.25)',
    },
    '30%': {
      transform: 'rotate(-25deg) scale(1.25)',
    },
    '45%': {
      transform: 'rotate(15deg) scale(1.25)',
    },
    '58%': {
      transform: 'rotate(-10deg) scale(1.25)',
    },
    '70%': {
      transform: 'rotate(5deg) scale(1.25)',
    },
    '87.5%': {
      transform: 'rotate(-2deg) scale(1.25)',
    },
    '100%': {
      transform: 'rotate(0deg) scale(1.0)',
    },
  },
  '@keyframes badgePopup': {
    '0%': {
      scale: 0,
    },
    '75%': {
      scale: 1.15,
    },
    '100%': {
      scale: 1,
    },
  },
  rotateIcon: {
    animation: '$rotate 1250ms ease-in-out',
    animationDelay: '2s',
  },
  badgePopup: {
    scale: 0,
    animation: '$badgePopup 1000ms forwards',
    animationDelay: '4s',
  },
})
