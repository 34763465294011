import React from 'react'
import { useNavigate } from 'react-router-dom'

import { generatePath } from 'react-router'
import { useTheme } from '@material-ui/core'

import { Sendfeedback } from '@playvs-inc/nexus-icons'
import { NxIconButton, NxTooltip } from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { DisputeMatch, userCanDisputeMatchResults } from '@plvs/utils'

interface DisputeResultsProps {
  match: DisputeMatch
  canManageMatch: boolean
}

export const DisputeResultsButton: React.FC<DisputeResultsProps> = ({
  match,
  canManageMatch,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const onClickDisputeResults = (): void => {
    navigate({
      pathname: generatePath(Path.MatchDispute, { matchId: match?.id ?? '' }),
    })
  }

  const canUserDisputeMatchResults = userCanDisputeMatchResults({
    match,
    canManageMatch,
  })

  return (
    <>
      {canUserDisputeMatchResults.message ? (
        <NxTooltip arrow title={canUserDisputeMatchResults.message}>
          <div>
            <NxIconButton
              data-cy="disputeResultsButton"
              disabled={!canUserDisputeMatchResults.result}
              icon={<Sendfeedback color={theme.palette.ColorTextAlt} />}
              label="Dispute Results"
              onClick={onClickDisputeResults}
              variant="text"
            />
          </div>
        </NxTooltip>
      ) : (
        <NxIconButton
          data-cy="disputeResultsButton"
          disabled={!canUserDisputeMatchResults.result}
          icon={<Sendfeedback color={theme.palette.ColorTextAlt} />}
          label="Dispute Results"
          onClick={onClickDisputeResults}
          variant="text"
        />
      )}
    </>
  )
}
