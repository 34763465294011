import React from 'react'
import {
  LocalStorageKey,
  Path,
  VESL_SLUG,
  NASEF_SLUG,
  Param,
} from '@plvs/const'

import { useFlags } from 'launchdarkly-react-client-sdk'

import { CreateAccount } from '@plvs/rally/features/login/CreateAccount'
import { AuthRenderControllerProvider } from '@plvs/respawn/renderController'
import createAnAccount from '@plvs/rally/assets/backgrounds/create-an-account.png'
import { RegistrationFormContainer } from '@plvs/rally/features/auth/registration/RegistrationFormContainer'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { useNavigate } from 'react-router-dom'
import { PublicPage } from './page'
import { useQueryParams } from '../components/filter'
import { getSidebarContent } from './RegistrationPage.helpers'
import { AuthLogo } from '../features/login/AuthLogo'

export const RegistrationPage: React.FC = () => {
  const [params] = useQueryParams([Param.UTM, Param.inviteCode])
  const flags = useFlags()
  const navigate = useNavigate()

  const isUnderAgeStored = localStorage.getItem(LocalStorageKey.IsUnderage)
  if (isUnderAgeStored && JSON.parse(isUnderAgeStored) && flags.coppaSupport) {
    navigate(Path.ParentSignUpRequest)
  }

  const inviteCode = params.icd ?? ''
  const isNasefSignUp = flags.nasefUx && params.utm_source === NASEF_SLUG
  const isVeslSignup = flags.veslBranding && params.utm_source === VESL_SLUG

  const isMobile = useBreakpointSm()
  const defaultTitle = isMobile ? 'Join PlayVS' : 'Join the PlayVS Community'
  const sidebarContent = getSidebarContent(inviteCode, false, isVeslSignup)

  return (
    <PublicPage description="Join the PlayVS Community" title="Signup">
      <AuthRenderControllerProvider>
        <CreateAccount
          bannerImageSrc={createAnAccount}
          Logo={<AuthLogo isNasef={isNasefSignUp} />}
          logoPosition={isNasefSignUp ? 'center' : 'corner'}
          SidebarContent={sidebarContent}
          title={isNasefSignUp ? 'Start Your Esports Journey' : defaultTitle}
        >
          <RegistrationFormContainer
            inviteCode={inviteCode}
            isNasefSignUp={isNasefSignUp}
            isParentSignup={false}
          />
        </CreateAccount>
      </AuthRenderControllerProvider>
    </PublicPage>
  )
}
