import React, { useEffect, useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchCheckInMatch } from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import {
  makeStyles,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { ForfeitMatchDialog } from '@plvs/rally/features/match/cancel/ForfeitMatchDialog'
import {
  CancelMatchFirstStep,
  CancelMatchStep,
} from '@plvs/rally/containers/match'
import {
  MatchRescheduleRequestStatus,
  MatchRescheduleResponse,
  useGetCancelMatchQuery,
  useGetRescheduleMatchQuery,
} from '@plvs/graphql'

import { Forfeit, Reschedule } from '@playvs-inc/nexus-icons'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import { normalizeActionAndInfoMatch } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.helpers'
import {
  RescheduleMatchStep,
  canMatchBeRescheduled,
} from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { RescheduleMatchDialog } from '@plvs/respawn/features/rescheduleMatch/reschedule'

const useStyles = makeStyles({
  paddedButton: {
    padding: '10px 20px',
    marginRight: '20px',
  },
})

enum AcknowledgementOptions {
  Unselected = '',
  Yes = 'yes',
  No = 'no',
}

export const MatchCheckInAcknowledgementsStep: React.FC<{
  onMatchForfeit(): void
  onMatchReschedule(): void
  onMatchRescheduleRequestRespond(response: MatchRescheduleResponse): void
  onNext(): void
  match: MatchCheckInMatch
  teamId: string
  upsertingMatchCheckIn: boolean
}> = ({
  match,
  teamId,
  upsertingMatchCheckIn,
  onMatchForfeit,
  onMatchReschedule,
  onMatchRescheduleRequestRespond,
  onNext,
}) => {
  const { paddedButton } = useStyles()

  const { data: cancelData, loading: cancelLoading } = useGetCancelMatchQuery({
    variables: {
      matchId: match.id,
    },
  })

  const cancelMatch = normalizeActionAndInfoMatch(cancelData?.match)

  const {
    data: rescheduleData,
    loading: rescheduleLoading,
  } = useGetRescheduleMatchQuery({
    variables: { matchId: match.id },
    errorPolicy: 'all',
  })

  const team1SchoolId = rescheduleData?.match?.team1?.school?.id
  const team2SchoolId = rescheduleData?.match?.team2?.school?.id
  const isSameSchool = !!(
    team1SchoolId === team2SchoolId &&
    team2SchoolId &&
    team1SchoolId
  )

  const rescheduleMatch = normalizeActionAndInfoMatch(rescheduleData?.match)
  const { canReschedule } = rescheduleMatch
    ? canMatchBeRescheduled(rescheduleMatch)
    : { canReschedule: false }

  const [userHasAgreed, setUserHasAgreed] = useState<string>(
    AcknowledgementOptions.Unselected
  )

  const [cancelStep, setCancelStep] = React.useState<CancelMatchStep>(
    CancelMatchFirstStep
  )

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const openCancelDialog = (): void => {
    setCancelStep(CancelMatchFirstStep)
    setIsCancelDialogOpen(true)
  }
  const closeCancelDialog = (): void => setIsCancelDialogOpen(false)

  const [rescheduleStep, setRescheduleStep] = React.useState<
    RescheduleMatchStep
  >('requested-reason')
  const [isRescheduleDialogOpen, setIsRescheduleDialogOpen] = useState<boolean>(
    false
  )
  const openRescheduleDialog = (): void => {
    setRescheduleStep('requested-reason')
    setIsRescheduleDialogOpen(true)
  }
  const closeRescheduleDialog = (): void => setIsRescheduleDialogOpen(false)

  useEffect(() => {
    setUserHasAgreed('')
  }, [teamId])

  useEffect(() => {
    if (
      rescheduleData?.match?.matchRescheduleRequests?.find(
        ({ teamId: requestingTeamId, status }) => {
          return (
            status === MatchRescheduleRequestStatus.Pending &&
            requestingTeamId !== teamId
          )
        }
      )
    ) {
      setRescheduleStep('update-request')
      setIsRescheduleDialogOpen(true)
    }
  }, [rescheduleData])

  const esportSlug = match?.esport?.slug

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="400px"
    >
      <Box id="radio-buttons">
        <Box pb={3} pt={5}>
          <NxTypography variant="h3">
            Is your team attending this match?
          </NxTypography>
        </Box>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="userHasAgreed"
            name="userHasAgreed"
            onChange={({ target }): void => setUserHasAgreed(target.value)}
            value={userHasAgreed}
          >
            <FormControlLabel
              control={<Radio />}
              label="Yes"
              value={AcknowledgementOptions.Yes}
            />
            <FormControlLabel
              control={<Radio />}
              label="No"
              value={AcknowledgementOptions.No}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {userHasAgreed === AcknowledgementOptions.No && (
        <WaitTillLoaded
          loading={cancelLoading || rescheduleLoading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <Box id="forfeit-reschedule">
            <Box pb={3} pt={5}>
              <NxTypography variant="h3">
                Sorry to hear that! What would you like to do?
              </NxTypography>
            </Box>
            <Box display="flex" flexDirection="horizontal">
              <Button
                className={paddedButton}
                data-cy="forfeitThisMatchButton"
                onClick={openCancelDialog}
                variant="outlined"
              >
                <Box component="span" pr={1}>
                  <Forfeit />
                </Box>
                Forfeit This Match
              </Button>
              {canReschedule && (
                <Button
                  className={paddedButton}
                  data-cy="rescheduleThisMatchButton"
                  onClick={openRescheduleDialog}
                  variant="outlined"
                >
                  <Box component="span" pr={1}>
                    <Reschedule />
                  </Box>
                  Reschedule This Match
                </Button>
              )}
            </Box>
          </Box>
        </WaitTillLoaded>
      )}
      {cancelMatch && (
        <ForfeitMatchDialog
          closeDialog={closeCancelDialog}
          esportSlug={esportSlug}
          isDialogOpen={isCancelDialogOpen}
          match={cancelMatch}
          onForfeitSuccess={onMatchForfeit}
          setStep={setCancelStep}
          showRescheduleOption={false}
          step={cancelStep}
          teamId={teamId}
        />
      )}
      {rescheduleMatch && isRescheduleDialogOpen && (
        <RescheduleMatchDialog
          closeDialog={closeRescheduleDialog}
          esportSlug={match?.esport?.slug ?? null}
          isDialogOpen={isRescheduleDialogOpen}
          isReadOnly
          isSameSchool={isSameSchool}
          match={rescheduleMatch}
          onReschedule={onMatchReschedule}
          onRespond={onMatchRescheduleRequestRespond}
          setStep={setRescheduleStep}
          step={rescheduleStep}
          teamId={teamId}
        />
      )}
      <Box textAlign="right">
        <Button
          color="primary"
          data-cy="chekInStepButton"
          disabled={userHasAgreed !== AcknowledgementOptions.Yes}
          onClick={onNext}
          size="large"
          variant="contained"
        >
          {upsertingMatchCheckIn ? <LoadingSpinner size="small" /> : 'Check In'}
        </Button>
      </Box>
    </Box>
  )
}
