import { Callout } from '@plvs/rally/components/callout'
import { Checkbox } from '@plvs/rally/components/checkbox/Checkbox'
import { OnboardTextField } from '@plvs/rally/components/form/OnboardTextfield'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'

export type UserAccountDetailsInput = {
  otherEmail: string
  phone: string
}

export enum Checked {
  Personal = 'personal',
  Work = 'work',
  School = 'school',
}

export interface FieldProps {
  register: React.Ref<HTMLInputElement>
  errors?: DeepMap<UserAccountDetailsInput, FieldError>
  email: string | undefined | null
  isPersonal: boolean
  isWork: boolean
  setSelected(input: Checked): void
  selected: Checked | null
  personalEmailOptional: boolean
}

export const OnboardFacultyEmailConfirmation: React.FC<FieldProps> = ({
  errors,
  register,
  email,
  isPersonal,
  setSelected,
  isWork,
  selected,
  personalEmailOptional,
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box py={1}>
        <NxTypography variant="body1">
          Provide your cell phone number
        </NxTypography>
      </Box>
      <Box pb={3} pt={1}>
        <OnboardTextField
          defaultValue=""
          error={errors?.phone}
          label="Phone Number"
          name="phone"
          register={register}
          type="tel"
        />
      </Box>
      <Callout>
        Your phone number may also be used to assist you in setting up your
        esports program and to contact you on competition days if there is an
        issue.
      </Callout>
      {!personalEmailOptional && (
        <>
          <Box data-testid="secondary-email" mt={2} py={1}>
            <NxTypography variant="body1">
              Is {(email && <b>{email}</b>) || 'this'} your personal or work
              email address?
            </NxTypography>
          </Box>
          <Box alignItems="center" display="flex" flexDirection="row" py={1}>
            <Box pr={2}>
              <Checkbox
                ariaDescribedBy="personal-email"
                ariaLabelledBy="personal-email"
                checked={isPersonal}
                onChange={(): void => {
                  setSelected(Checked.Personal)
                }}
              />
            </Box>
            <NxTypography variant="body1">Personal</NxTypography>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            pb={4}
            pt={1}
          >
            <Box pr={2}>
              <Checkbox
                ariaDescribedBy="work-email"
                ariaLabelledBy="work-email"
                checked={isWork}
                onChange={(): void => {
                  setSelected(Checked.Work)
                }}
              />
            </Box>
            <NxTypography variant="body1">Work</NxTypography>
          </Box>
          {selected && (
            <>
              <Box pb={1}>
                <NxTypography variant="body1">
                  {selected === Checked.Personal
                    ? 'Provide Your Work Email Address'
                    : 'Provide Your Personal Email Address'}
                </NxTypography>
              </Box>
              <OnboardTextField
                defaultValue=""
                error={errors?.otherEmail}
                label={
                  selected === Checked.Personal
                    ? 'Work Email Address'
                    : 'Personal Email Address'
                }
                name="otherEmail"
                register={register}
              />
              <Box mt={1}>
                <NxTypography component="span" display="inline" variant="body4">
                  PlayVS will never sell or use your data in inappropriate ways.
                  To read more please review our&nbsp;
                  <NxTextLink
                    href="https://playvs.com/privacy"
                    label="Privacy Policy."
                    labelVariant="body4"
                    target="_blank"
                  />
                </NxTypography>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}
