import React from 'react'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { PlasmicRootProvider } from '@plasmicapp/loader-react'

import { Path } from '@plvs/const'
import { Analytics } from '@plvs/respawn/features/analytics'
import { AppNavigation } from '@plvs/rally/features/app'
import { NotificationProvider } from '@plvs/rally/features/notifications'
import { plasmic } from '@plvs/rally/init/plasmic/initPlasmic'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  updateAppLocationFn,
  updateProductTypeFn,
} from '@plvs/client-data/mutations'
import { Location, ProductType } from '@plvs/client-data/models'

import { Pages } from '@plvs/rally/pages'
import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { ProfileProvider } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { AppProvider } from './containers/app'
import { InitClient } from './containers/app/InitClient'
import { OrganizationsProvider } from './containers/organization/OrganizationsProvider'
import { TeamsProvider } from './containers/team/TeamsProvider'
import { ThemeProvider, ThemeProviderContext } from './features/theme'
import { ChatContainer } from './containers/chat/ChatContainer'
import { LaunchDarklyProvider } from './init/launchDarkly/LaunchDarklyProvider'
import { rallyEnv } from './env'
import { DefaultMaintenance } from './components/maintenance/DefaultMaintenance'
import { ProductToursProvider } from './init/shepherd/useProductTours'
import { ResourceImageProvider } from '../../respawn/src/features/resources/ResourceImageProvider'

const App = (): React.ReactElement => {
  updateProductTypeFn(ProductType.Scholastic)
  updateAppLocationFn(Location.Rally)
  const ldClient = useLDClient()

  // Removing LaunchDarkly errors from sentry
  ldClient?.on('error', () => {})

  const { userId, userName } = useUserIdentityFn()

  return (
    <LaunchDarklyProvider
      launchDarklyClientId={rallyEnv.LAUNCHDARKLY_CLIENTID}
      userId={userId}
      userName={userName}
    >
      <DefaultMaintenance>
        <ProductToursProvider>
          <InitClient />
          <Analytics />
          <CssBaseline />
          <NotificationProvider>
            <AppProvider>
              <ThemeProvider>
                {({
                  themeMode,
                  theme,
                }: ThemeProviderContext): React.ReactElement => {
                  return (
                    <PlasmicRootProvider
                      globalVariants={[{ name: 'Palette', value: themeMode }]}
                      loader={plasmic}
                    >
                      <MuiThemeProvider theme={theme}>
                        <FilterCacheProvider id={Path.App}>
                          <ProfileProvider>
                            <OrganizationsProvider>
                              <ResourceImageProvider>
                                <TeamsProvider>
                                  <ChatContainer>
                                    <AppNavigation />
                                    <Pages />
                                  </ChatContainer>
                                </TeamsProvider>
                              </ResourceImageProvider>
                            </OrganizationsProvider>
                          </ProfileProvider>
                        </FilterCacheProvider>
                      </MuiThemeProvider>
                    </PlasmicRootProvider>
                  )
                }}
              </ThemeProvider>
            </AppProvider>
          </NotificationProvider>
        </ProductToursProvider>
      </DefaultMaintenance>
    </LaunchDarklyProvider>
  )
}

export default App
