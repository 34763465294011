import {
  Hearthstone,
  Leagueoflegends,
  Madden2,
  Mariokart,
  Nba2K,
  Overwatch,
  Rocketleague,
  Smashbros,
  Splatoon3,
  Valorant,
  Chess,
} from '@playvs-inc/nexus-icons'
import { EsportSlug } from '@plvs/graphql/types'
import React from 'react'

export const buildEsportsInfoDictionary = (): any => {
  const esports: any = {}
  esports[EsportSlug.LeagueOfLegends] = {
    whatIs:
      'https://help.playvs.com/en/articles/4919292-what-is-league-of-legends',
    whatIsOtherLabel: 'Learn about Campus League Unlocked',
    whatIsOther:
      'https://help.playvs.com/en/articles/7879664-league-of-legends-campus-league-unlocked',
    rulebook:
      'https://help.playvs.com/en/articles/4919212-league-of-legends-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/2890444-league-of-legends-game-day-protocol',
    protocolOther:
      'https://help.playvs.com/en/articles/4919233-how-to-scout-my-league-of-legends-opponent',
    protocolOtherLabel: 'How to scout my opponent',
    name: 'League of Legends',
    icon: <Leagueoflegends height="40px" width="40px" />,
  }
  esports[EsportSlug.RocketLeague] = {
    whatIs: 'https://help.playvs.com/en/articles/4919293-what-is-rocket-league',
    whatIsOtherLabel: 'How to Download Rocket League',
    whatIsOther:
      'https://help.playvs.com/en/articles/4919282-how-to-download-rocket-league',
    rulebook:
      'https://help.playvs.com/en/articles/4919221-rocket-league-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/5301489-rocket-league-game-day-protocol',
    protocolOther:
      'https://help.playvs.com/en/articles/4919180-epic-linking-for-rocket-league',
    protocolOtherLabel: 'Link Epic Account to PlayVS',
    name: 'Rocket League',
    icon: <Rocketleague height="40px" width="40px" />,
  }
  esports[EsportSlug.RocketLeagueGMA] = {
    whatIs: 'https://help.playvs.com/en/articles/4919293-what-is-rocket-league',
    whatIsOtherLabel: 'How to Download Rocket League',
    whatIsOther:
      'https://help.playvs.com/en/articles/4919282-how-to-download-rocket-league',
    rulebook:
      'https://help.playvs.com/en/articles/4919221-rocket-league-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/5301489-rocket-league-game-day-protocol',
    protocolOther:
      'https://help.playvs.com/en/articles/4919180-epic-linking-for-rocket-league',
    protocolOtherLabel: 'Link Epic Account to PlayVS',
    name: 'Rocket League',
    icon: <Rocketleague height="40px" width="40px" />,
  }
  esports[EsportSlug.SmashBrosUltimate] = {
    whatIs:
      'https://help.playvs.com/en/articles/4919170-what-is-super-smash-bros-ultimate',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook:
      'https://help.playvs.com/en/articles/4919168-super-smash-bros-ultimate-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/4919137-super-smash-bros-ultimate-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Super Smash Bros.™ Ultimate',
    icon: <Smashbros height="40px" width="40px" />,
  }
  esports[EsportSlug.Splatoon] = {
    whatIs: 'https://help.playvs.com/en/articles/5517722-what-is-splatoon-2',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook: 'https://help.playvs.com/en/articles/5433354-splatoon-2-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/5433514-splatoon-2-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Splatoon™ 3',
    icon: <Splatoon3 height="40px" width="40px" />,
  }
  esports[EsportSlug.MarioKart] = {
    whatIs:
      'https://help.playvs.com/en/articles/5777110-what-is-mario-kart-8-deluxe',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook:
      'https://help.playvs.com/en/articles/5872507-mario-kart-8-deluxe-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/5873212-mario-kart-8-deluxe-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Super Mario Kart™ 8 Deluxe',
    icon: <Mariokart height="40px" width="40px" />,
  }
  esports[EsportSlug.Madden] = {
    whatIs: 'https://help.playvs.com/en/articles/4919173-what-is-madden-nfl-22',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook:
      'https://help.playvs.com/en/articles/4919167-madden-nfl-22-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/4919164-madden-nfl-22-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Madden NFL 25',
    icon: <Madden2 height="40px" width="40px" />,
  }
  esports[EsportSlug.NBA2K] = {
    whatIs: 'https://help.playvs.com/en/articles/6417963-what-is-nba-2k23',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook: 'https://help.playvs.com/en/articles/6417922-nba-2k23-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/6417987-nba-2k23-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'NBA® 2K25',
    icon: <Nba2K height="40px" width="40px" />,
  }
  esports[EsportSlug.Hearthstone] = {
    whatIs: 'https://help.playvs.com/en/articles/5860005-what-is-hearthstone',
    whatIsOtherLabel: 'Hearthstone Card Unlock Overview',
    whatIsOther:
      'https://help.playvs.com/en/articles/6377240-hearthstone-card-unlock-overview',
    rulebook:
      'https://help.playvs.com/en/articles/5860006-hearthstone-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/5860076-hearthstone-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Hearthstone',
    icon: <Hearthstone height="40px" width="40px" />,
  }
  esports[EsportSlug.Chess] = {
    whatIs: 'https://help.playvs.com/en/articles/9264910-what-is-chess',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook: 'https://help.playvs.com/en/articles/9264895-chess-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/9264903-chess-match-instructions',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Chess',
    icon: <Chess height="40px" width="40px" />,
  }
  esports[EsportSlug.Overwatch] = {
    whatIs: 'https://help.playvs.com/en/articles/4919296-what-is-overwatch',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook:
      'https://help.playvs.com/en/articles/5954709-overwatch-rulebook-youth-program',
    protocol:
      'https://help.playvs.com/en/articles/4919157-overwatch-gameday-protocol-youth-program',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'Overwatch 2',
    icon: <Overwatch height="40px" width="40px" />,
  }
  esports[EsportSlug.Valorant] = {
    whatIs: 'https://help.playvs.com/en/articles/8659612-what-is-valorant',
    whatIsOtherLabel: '',
    whatIsOther: '',
    rulebook: 'https://help.playvs.com/en/articles/8673214-valorant-rulebook',
    protocol:
      'https://help.playvs.com/en/articles/8673215-valorant-game-day-protocol',
    protocolOther: '',
    protocolOtherLabel: '',
    name: 'VALORANT',
    icon: <Valorant height="40px" width="40px" />,
  }
  return esports
}
