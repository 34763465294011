import React from 'react'

import { Box } from '@plvs/respawn/features/layout'

export const HeroGutter: React.FC<{
  children: React.ReactNode
  mb?: number | number[]
  mt?: number | number[]
  mx?: number | number[]
}> = ({ children, mb, mt, mx }) => (
  <Box mb={mb ?? 5} mt={mt ?? 2} mx={mx ?? 2}>
    {children}
  </Box>
)
