/* istanbul ignore file */
// Low value unit testing this component.

import { makeStyles, useTheme } from '@material-ui/core'
import { Path } from '@plvs/const'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { getPath } from '@plvs/utils'
import { useLocation } from 'react-router-dom'
import { PublicPage } from './page'

const useStyles = makeStyles((theme) => ({
  body: {
    margin: theme.spacing(2),
  },
}))

/**
 * This is a page to present when a user is not allowed due to
 * not qualifying.  For example, underaged.
 */
export const ForbiddenPage: React.FC = () => {
  const classes = useStyles()
  const { state, pathname } = useLocation()
  const path = getPath(pathname)
  const theme = useTheme()

  const title = state?.title || '403 Forbidden'

  const defaultMessage =
    path === Path.Banned
      ? 'Your access to PlayVS has been removed. Please contact support at support@playvs.com if you have any questions.'
      : 'Unfortunately due to certain criteria you are not eligible to use PlayVS'

  const message = state?.message || defaultMessage

  const subtext = state?.subtext || ''

  return (
    <PublicPage description="You are not allowed" title={title}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        margin="auto"
        paddingX={theme.spacing(2)}
      >
        <NxTypography
          align="center"
          className={classes.body}
          colorToken="ColorTextBase"
          data-testid="ForbiddenPage_Title"
          variant="h3"
        >
          {message}
        </NxTypography>
        {!!subtext && (
          <NxTypography align="center" data-testid="ForbiddenPage_Subtitle">
            {subtext}
          </NxTypography>
        )}
      </Box>
    </PublicPage>
  )
}
