import React, { useState } from 'react'
import { head } from 'ramda'
import {
  UserRole,
  ResourceType,
  useGetMyTeamsRallyAppDrawerQuery,
  GetMyIdentityQueryVariables,
  GetMyIdentityQuery,
  RoleStatus,
  Role,
  Maybe,
} from '@plvs/graphql'

import { assert, useAutoskipQuery, isMemberOfOrganization } from '@plvs/utils'
import { ApolloQueryResult } from '@apollo/client'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  getEntityIdsFromVerifiedRoles,
  getOnlyOrganizationProfiles,
  getUserEntityIdsFromRoles,
} from './utils'
import {
  AppDrawerTeam,
  ProfileType,
  RefetchAppDrawerTeamsFm,
  RefetchAppDrawerTeamsFnVariables,
} from './types'
import { FilterCacheContext } from '../FilterCacheProvider/FilterCacheProvider'

type ProfileContext = {
  organizationIds: string[]
  teamIds: string[]
  profiles: ProfileType[]
  roles: Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName' | 'userId'>[]
  userSchoolRoleStatus:
    | Maybe<
        (Pick<RoleStatus, 'status'> & {
          role: Pick<
            Role,
            'resourceType' | 'resourceId' | 'roleName' | 'userId'
          >
        })[]
      >
    | undefined
  selectedEntityId: string
  selectedEntityType: ResourceType | null | string
  setOrganization(id: string): void
  setTeam(id: string): void
  setEntity(resourceType: ResourceType | null | string, id?: string): void
  refetch?: (
    variables?: Partial<GetMyIdentityQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<GetMyIdentityQuery>> | any
  refetchAppDrawerTeams?:
    | ((
        input?: RefetchAppDrawerTeamsFnVariables
      ) => Promise<RefetchAppDrawerTeamsFm>)
    | undefined
  loading: boolean
  isMemberOfOrg: boolean
  isAssociatedWithTeam: (teamId: string) => boolean
  userId: string
  appDrawerTeams: AppDrawerTeam[]
}

export const ProfileContext = React.createContext<ProfileContext>({
  organizationIds: [],
  teamIds: [],
  roles: [],
  userSchoolRoleStatus: [],
  profiles: [],
  selectedEntityId: '',
  selectedEntityType: null,
  setOrganization: () => {},
  setTeam: () => {},
  setEntity: () => {},
  refetch: undefined,
  loading: false,
  isMemberOfOrg: false,
  isAssociatedWithTeam: () => false,
  userId: '',
  appDrawerTeams: [],
  refetchAppDrawerTeams: undefined,
})

export const useProfileContext = (): ProfileContext => {
  return React.useContext(ProfileContext)
}

interface ProfileProviderProps {
  setDefaultIdToOrganization?: boolean
  defaultEntityId?: string
  defaultEntityType?: ResourceType
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({
  children,
  setDefaultIdToOrganization = false,
  defaultEntityId = '',
  defaultEntityType = '',
}) => {
  // Hooks
  const { setEntityId, setEntityType, entityId, entityType } =
    React.useContext(FilterCacheContext)

  const [selectedEntityType, setSelectedEntityType] = React.useState<
    ResourceType | null | string
  >(defaultEntityType || entityType)
  const [selectedEntityId, setSelectedEntityId] = React.useState<string>(
    defaultEntityId || entityId
  )

  const [initHasRun, setInitHasRun] = useState<boolean>(false)

  const {
    userRoles: roles,
    userSchoolRoleStatus,
    userId,
    refetch,
    loading,
  } = useUserIdentityFn()

  const identityLoading = !!userId && loading

  // Logic

  const organizationIds = getEntityIdsFromVerifiedRoles(
    roles,
    ResourceType.Organization
  )

  const teamIds = getUserEntityIdsFromRoles(roles, ResourceType.Team)
  const selectedEntityArg = selectedEntityId
    ? { schoolId: selectedEntityId }
    : {}

  const {
    data: appDrawerTeams,
    loading: appDrawerTeamsLoading,
    refetch: refetchAppDrawerTeams,
  } = useAutoskipQuery(useGetMyTeamsRallyAppDrawerQuery, {
    variables: {
      filters: { teamIds, ...selectedEntityArg },
    },
    skip: teamIds.length === 0 || !userId || identityLoading,
  })

  // ONLY BRING BACK ORGANIZATION PROFILE FOR NOW. Use `getAllProfileEntities` if we want to turn on all team profiles
  const profiles = getOnlyOrganizationProfiles(userSchoolRoleStatus)

  // Handlers
  const setEntity = (
    entityTypeParam: ResourceType | null | '',
    entityIdParam: string
  ): void => {
    setSelectedEntityType(entityTypeParam)
    setSelectedEntityId(entityIdParam)
    // Set values in FilterCacheProvider to persist state of profile
    setEntityType(entityTypeParam)
    setEntityId(entityIdParam)
  }
  const setOrganization = (id: string): void =>
    setEntity(ResourceType.Organization, id)
  const setTeam = (id: string): void => setEntity(ResourceType.Team, id)

  // Initialization
  React.useEffect(() => {
    // Wait until loading is complete before init initial selected entity.
    if (identityLoading || appDrawerTeamsLoading) {
      return
    }

    if (organizationIds.length === 1 && selectedEntityType === '') {
      setOrganization(head(organizationIds) ?? '')
    } else if (!initHasRun && profiles.some((profile) => profile.approved)) {
      // If there is an approved profile, default to that instead only on init.
      const orgToSelect = head(organizationIds)
      assert(orgToSelect)
      setOrganization(orgToSelect)
    } else if (
      profiles.some(
        (profile) => profile.id === selectedEntityId && !profile.approved
      )
    ) {
      // Safety net to avoid having user default to non-approved profile
      setEntity(null, '')
    }

    setInitHasRun(true)

    // Note: profiles and organizationIds update together, adding it into dependencies
    // for 'correctness' so all dependencies are included
  }, [
    identityLoading,
    appDrawerTeamsLoading,
    profiles,
    organizationIds,
    setDefaultIdToOrganization,
    initHasRun,
  ])

  const isMemberOfOrg = isMemberOfOrganization(roles, organizationIds)

  const isAssociatedWithTeam = (teamId: string): boolean => {
    return (
      !identityLoading &&
      roles.some(
        (item) =>
          item.resourceId === teamId && item.resourceType === ResourceType.Team
      )
    )
  }

  const refetchAppDrawerTeamsHelper = async (
    input?: RefetchAppDrawerTeamsFnVariables
  ): Promise<RefetchAppDrawerTeamsFm> => {
    await refetch?.()
    let variables
    if (input) {
      const associationArg = input?.refetchAssociationId
        ? { schoolId: input?.refetchAssociationId }
        : selectedEntityArg
      variables = {
        filters: {
          teamIds: input?.refetchTeamIds,
          ...associationArg,
        },
      }
    }
    return refetchAppDrawerTeams?.(
      variables
    ) as unknown as RefetchAppDrawerTeamsFm
  }

  const value: ProfileContext = {
    setEntity,
    selectedEntityId,
    selectedEntityType,
    organizationIds,
    teamIds,
    setOrganization,
    setTeam,
    roles,
    userSchoolRoleStatus,
    profiles,
    refetch,
    loading: identityLoading || appDrawerTeamsLoading,
    isMemberOfOrg,
    isAssociatedWithTeam,
    userId: userId ?? '',
    appDrawerTeams: appDrawerTeams?.getTeams?.teams || [],
    refetchAppDrawerTeams: refetchAppDrawerTeamsHelper,
  }

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}
