import React from 'react'
import { useTheme } from '@material-ui/core'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  StandingDarkLargeSecondary,
  StandingLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { NxEmptyState, NxTypography } from '@playvs-inc/nexus-components'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { Table } from '@plvs/rally/components/table'
import { useGetPlayerGameStatsQuery } from '@plvs/graphql'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { getTableColumns } from '@plvs/rally/containers/table'

import { decoratePlayerRow } from './decorate-stats'

interface Props {
  gameId: string
  team1Id: string
}

interface EmptyStat {
  team: { name: string }
}

const stampEmptyStat = (): EmptyStat => ({
  team: {
    name: 'N/A',
  },
})

export const PlayerGameStats: React.FC<Props> = ({ gameId, team1Id }) => {
  const theme = useTheme()
  const {
    gamePlayerStatsColumns,
    getUsername,
    rankingInfoCopy,
  } = useEsportContext()
  const { data: statsData, loading, error } = useGetPlayerGameStatsQuery({
    variables: { gameId },
    skip: !gameId,
  })

  if (error) {
    throw error
  }

  const columns = getTableColumns(gamePlayerStatsColumns ?? [], rankingInfoCopy)

  const playerGameStats = statsData?.game?.playerGameStats
  if (!loading && (!playerGameStats || !playerGameStats.length)) {
    const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
    return (
      <NxEmptyState
        isStandalone
        spot={
          isDarkMode ? (
            <StandingDarkLargeSecondary height={200} width={200} />
          ) : (
            <StandingLightLargeSecondary height={200} width={200} />
          )
        }
        subtitle="Check back later."
        title="No stats found"
      />
    )
  }

  const team1Stats = playerGameStats
    ?.filter((stats) => stats.teamId === team1Id)
    .map(decoratePlayerRow({ getUsername }))
  const { team: team1 } = team1Stats?.[0] || stampEmptyStat()
  const team2Stats = playerGameStats
    ?.filter((stats) => stats.teamId !== team1Id)
    .map(decoratePlayerRow({ getUsername }))
  const { team: team2 } = team2Stats?.[0] || stampEmptyStat()

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        <Box pl={3} py={2}>
          <NxTypography variant="overline">{team1.name}</NxTypography>
        </Box>
        <Table columns={columns} data={team1Stats} />
        <Box borderColor={`1px solid ${theme.palette.BorderLight}`} my={3} />
        <Box pl={3} py={2}>
          <NxTypography variant="overline">{team2.name}</NxTypography>
        </Box>
        <Table columns={columns} data={team2Stats} />
      </>
    </WaitTillLoaded>
  )
}
