import { ResourceType } from '@plvs/graphql'
import { useReactiveVar } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import { UserProfileInterface, userProfileVar } from '../models/UserProfile'
import { useUserIdentityFn } from './useUserIdentityFn'
import { getUserEntityIdsFromRoles } from '../data/utils'

export const useUserProfileFn = (): UserProfileInterface => {
  const { userRoles, loading, error } = useUserIdentityFn()

  const teamIds = getUserEntityIdsFromRoles(userRoles, ResourceType.Team)
  const teamIdsDependency = JSON.stringify(teamIds)
  const setVar = useCallback(() => {
    userProfileVar({ teamIds, loading, error })
  }, [teamIdsDependency, loading, error])

  useEffect(() => {
    if (
      !loading &&
      JSON.stringify(userProfileVar().teamIds) !== teamIdsDependency
    ) {
      setVar()
    }
  }, [teamIdsDependency, loading, error])

  return useReactiveVar(userProfileVar)
}
