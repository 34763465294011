import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
} from '@playvs-inc/nexus-components'

import {
  GameAssistantSelectionOptionFragment,
  useGetCharacterSelectionOptionsByEsportIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import { sortByDisplayName, SelectionOption, FirebobMessage } from '@plvs/utils'
import { logger } from '@plvs/rally/logging'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { useStyles } from './CharacterSelect.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'

interface CharacterSelectProps {
  readonly?: boolean
  onNext?(): void
  buttonText?: string
}

export const CharacterSelect: React.FC<CharacterSelectProps> = ({
  readonly = false,
  onNext,
  buttonText,
}) => {
  const {
    selectionSide,
    isMyTurn,
    currentStep,
    homeCharacter,
    awayCharacter,
    currentPlayers,
    gameOrdinal,
    match,
    isMyTeamHome,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    esportSlug,
  } = useMatchAssistantContext()
  const { id: stepId = '' } = currentStep?.step ?? {}

  const {
    match: { canParticipate, canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const styles = useStyles({ readonly })

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const {
    data: characterData,
    loading: characterDataLoading,
  } = useGetCharacterSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id || readonly,
  })

  const [
    submitGameAssistantStep,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions = characterData?.characterSelectionOptionsByEsportId?.selectionOptions.find(
    (option) => option.gameOrdinal === gameOrdinal
  )
  const sortedSelectionOptions = sortByDisplayName(
    selectionOptions?.selectionOptions as GameAssistantSelectionOptionFragment[]
  )
  const [selectedCharacter, setSelectedCharacter] = useState<SelectionOption>()

  const isNextDisabled = readonly
    ? !onNext
    : !selectedCharacter || canSpectate || updateLoading

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    value: SelectionOption | null
  ): void => {
    setSelectedCharacter(value || undefined)
  }

  const handleNext = async (): Promise<void> => {
    onNext?.()

    if (isNextDisabled || readonly) {
      return
    }
    setActionsTakenInSteps({
      ...actionsTakenInSteps,
      [stepId]: new Date().toISOString(),
    })

    logger.debug('[CharacterSelect | handleNext]', selectedCharacter)

    try {
      await submitGameAssistantStep({
        variables: {
          input: {
            stepId,
            selectionOptions: selectedCharacter
              ? [{ selectionOptionId: selectedCharacter.id }]
              : [],
          },
        },
      })
      setHasSubmitted(true)
    } catch (err) {
      logger.error(err)
    }
  }

  const myTeamSelection = isMyTeamHome ? homeCharacter : awayCharacter
  const opponentTeamSelection = isMyTeamHome ? awayCharacter : homeCharacter

  let homePlayerSelection
  let awayPlayerSelection

  if (isMyTeamHome) {
    homePlayerSelection = selectedCharacter ?? homeCharacter?.selectionOption
    awayPlayerSelection = awayCharacter?.selectionOption
  } else {
    homePlayerSelection = homeCharacter?.selectionOption
    awayPlayerSelection = selectedCharacter ?? awayCharacter?.selectionOption
  }

  useEffect(
    function clearSelection() {
      setSelectedCharacter(undefined)
    },
    [myTeamSelection?.selectionOption?.displayName]
  )

  const isWaitingForOpponent =
    (canParticipate &&
      !readonly &&
      (!isMyTurn || (myTeamSelection && !opponentTeamSelection))) ||
    hasSubmitted
  const isReady = Boolean(homeCharacter && awayCharacter)
  const isSelectDisabled =
    !canParticipate ||
    updateLoading ||
    isWaitingForOpponent ||
    isReady ||
    readonly ||
    hasSubmitted

  let firebobMessage =
    isReady || readonly || isWaitingForOpponent
      ? FirebobMessage.StageStriking
      : `Pick Player ${selectionSide + 1}'s character`
  if (!canParticipate) {
    firebobMessage = FirebobMessage.Spectator
  }

  return (
    <WaitTillLoaded
      loading={characterDataLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {!readonly && (
        <Autocomplete
          className={styles.autocomplete}
          data-cy="character-select"
          defaultValue={myTeamSelection?.selectionOption}
          disabled={isSelectDisabled}
          getOptionLabel={(option): string => option.displayName}
          onChange={handleChange}
          options={sortedSelectionOptions}
          renderInput={(params): React.ReactElement => (
            <TextField
              {...params}
              label="Search Characters"
              variant="outlined"
            />
          )}
          renderOption={(params): React.ReactElement => {
            return (
              <NxTypography variant="body1">{params.displayName}</NxTypography>
            )
          }}
        />
      )}

      <SelectedCharacters
        awayPlayer={{
          id: currentPlayers[1]?.id,
          name: currentPlayers[1]?.name ?? '',
          avatarUrl: currentPlayers[1]?.avatarUrl ?? '',
          selection: awayPlayerSelection,
          useAvatar: !awayPlayerSelection?.assetUri,
        }}
        esportSlug={esportSlug}
        homePlayer={{
          id: currentPlayers[0]?.id,
          name: currentPlayers[0]?.name ?? '',
          avatarUrl: currentPlayers[0]?.avatarUrl ?? '',
          selection: homePlayerSelection,
          useAvatar: !homePlayerSelection?.assetUri,
        }}
      />

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {canParticipate && (
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label={buttonText ?? 'Confirm Your Character'}
            loading={isWaitingForOpponent || updateLoading}
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </WaitTillLoaded>
  )
}
