import React from 'react'
import { NxSelect, NxSelectProps } from '@playvs-inc/nexus-components'
import { Control, Controller } from 'react-hook-form'

interface Props extends NxSelectProps {
  control: Control
  'data-cy'?: string
  formControlClassName?: string
  name: string
}

/**
 * Nx Select component for use with react-hook-form
 */

export const NxSelectController: React.FC<Props> = ({
  'data-cy': dataCy,
  control,
  formControlClassName,
  defaultValue,
  name,
  ...selectProps
}) => {
  return (
    <Controller
      className={formControlClassName}
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(props): React.ReactElement => (
        <NxSelect data-cy={dataCy} {...selectProps} {...props} />
      )}
    />
  )
}
