import moment, { Moment } from 'moment-timezone'
import { isNil, reduce, without } from 'ramda'
import React from 'react'
import * as yup from 'yup'

import {
  CreatePhaseMutationVariables,
  CreateSlotMutationVariables,
  Esport,
  EsportSlug,
  GetLeagueSeasonQuery,
  GetLeagueSeasonsQuery,
  League,
  Maybe,
  Metaseason,
  Phase,
  PhaseFormat,
  PhaseStatus,
  PhaseType,
  Season,
  SeasonStatus,
  Slot,
  SlotStatus,
  CompetitionGroup,
  SeasonType,
  SlotLabel,
  VarsityAssociation,
  SeasonScheduleInput,
  SlotExclusionRangeConfiguration,
} from '@plvs/graphql'
import {
  isAssociatedToOrganization,
  yupNumber,
  formatIsElimination,
} from '@plvs/utils'
import {
  DEFAULT_TIMEZONE,
  MOMENT_DASHED_DATE,
  MOMENT_DASHED_DATE_AND_TIME,
} from '@plvs/const'
import {
  parseFinalSlotsByLabel,
  parseLoserSlotsByLabel,
  parseWinnerSlotsByLabel,
} from '@plvs/rally/features/standings/standingsHelpers'
import dayjs from '@plvs/rally/init/dayjs'

export const DEBUG = false
export const DIALOG_BASE_PROPS = {
  scroll: 'body',
  width: 480,
} as const

export type Mode = 'create' | 'edit'

export type CreateSeasonDialogProps = {
  dialogProps: {
    usedMetaseasons: Pick<Metaseason, 'id' | 'name' | 'competitionGroup'>[]
    unusedMetaseasons: Pick<Metaseason, 'id' | 'name' | 'competitionGroup'>[]
    league: Pick<League, 'id' | 'name' | 'timezone' | 'competitionGroup'>
    refetchSeasons(): void
  }
}

export type GetLeagueSeason = NonNullable<
  NonNullable<GetLeagueSeasonsQuery['league']>['allSeasons']
>[0]

export type GetLeaguePhases = NonNullable<
  NonNullable<GetLeagueSeasonQuery['season']>['allPhases']
>

export type GetLeaguePhase = GetLeaguePhases[0]

export type GetLeagueSlot = NonNullable<GetLeaguePhase['slots']>[0]

type PartiallyRequire<
  T extends Record<string, unknown>,
  P extends keyof T
> = Partial<Omit<T, P>> & Required<Pick<T, P>>

export const DATE_FORMAT = 'MMM. Do, YYYY'
export const DATE_AND_TIME_FORMAT = `${DATE_FORMAT} hh:mma z`
export const DATE_AND_TIME_FORMAT_NO_TZ = `${DATE_FORMAT} hh:mma`
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD'
export const DATE_AND_TIME_INPUT_FORMAT = 'YYYY-MM-DD hh:mma'
export const DATE_TIME_TIMEZONE_CONVERSION_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const BRACKET_DATE_TIME_FORMAT = `h:mm A z, MMM Do, YYYY`

export const LeagueContext = React.createContext<{
  loaded: boolean
  league: Pick<League, 'id' | 'name' | 'timezone'> & {
    competitionGroup: Maybe<CompetitionGroup>
    seasons: Maybe<
      Array<
        Pick<Season, 'id'> & {
          metaseason: Maybe<
            Pick<Metaseason, 'id' | 'name' | 'startsAt' | 'endsAt'>
          >
        }
      >
    >
    esport: Pick<Esport, 'id'> & {
      slug: Maybe<EsportSlug>
    }
    seasonByMetaseasonId: Maybe<
      Pick<Season, 'id' | 'numberOfTeams' | 'numberOfTeamsByStates'> & {
        numberOfTeamsByVarsityAssociations: Maybe<
          Array<{
            teamCount: number
            varsityAssociation: Maybe<
              Pick<VarsityAssociation, 'slug' | 'abbreviation' | 'name'>
            >
          }>
        >
      }
    >
  }
}>({
  loaded: false,
  league: {
    id: '',
    name: null,
    timezone: null,
    seasons: [],
    esport: { id: '', slug: null },
    competitionGroup: null,
    seasonByMetaseasonId: {
      id: '',
      numberOfTeams: 0,
      numberOfTeamsByStates: [],
      numberOfTeamsByVarsityAssociations: [],
    },
  },
})

export const PHASE_WIDTH = 280

export const createDateInFn = ({
  stripTime,
  timezone,
}: {
  stripTime?: boolean
  timezone: string | null
}): ((input: string) => string) => (input: string): string => {
  // instantiate in the timezone of the league (or UTC if none exists)
  const dateInLeagueTz = moment.tz(input, timezone ?? 'UTC')
  return stripTime
    ? // strip the date's timezone, so the date picker will assume the browser's
      dateInLeagueTz.format(DATE_INPUT_FORMAT)
    : dateInLeagueTz.toISOString()
}

/* 
- instantiate in provided timezone (or default to UTC) and then convert to UTC
- e.g. input: 12PM PT -> instantiate as 12PM ET if ET timezone -> output: converted to 4PM UTC (depending on daylight savings)
*/
interface CreateDateOutFnProps {
  timezone?: string
  endOfDayKeys?: readonly string[]
  startOfDayKeys?: readonly string[]
}

export const createDateOutFn = ({
  timezone = DEFAULT_TIMEZONE,
  endOfDayKeys = [],
  startOfDayKeys = [],
}: CreateDateOutFnProps): ((input: string, key?: string) => string) => (
  input: string,
  key?: string
): string => {
  // input must be in user's local timezone, so that a moment can be created in user's local timezone and be formatted (strip out timezone)
  const formattedInput = moment(input).format(
    DATE_TIME_TIMEZONE_CONVERSION_FORMAT
  )
  // instantiate in provided timezone
  const inputInTimezone = moment.tz(formattedInput, timezone)

  // if this is a key we want to set to the start/end of the day (i.e. phase start/end dates), mutate the date
  if (key) {
    if (startOfDayKeys?.includes(key)) {
      inputInTimezone.startOf('day')
    }

    if (endOfDayKeys?.includes(key)) {
      inputInTimezone.endOf('day')
    }
  }

  return inputInTimezone.toISOString()
}

// 12PM ET => 12PM PT
export const convertInputToLocalTimezoneFn = (
  input = moment().format(),
  inputTimezone = DEFAULT_TIMEZONE,
  localTimezone = DEFAULT_TIMEZONE
): string => {
  // 2021-10-08T17:00:00.000Z (5PM UTC / 12PM ET) => 2021-10-08T12:00:00-05:00 EST (12PM ET)
  const dateTimeInLeagueTz = moment
    .utc(input)
    .tz(inputTimezone ?? DEFAULT_TIMEZONE)

  // 2021-10-08T12:00:00-05:00 EST (12PM ET) => 2021-10-08 12:00:00 (12PM - stripped out timezone to use moment.tz)
  const formattedDateTimeInLeagueTz = dateTimeInLeagueTz.format(
    DATE_TIME_TIMEZONE_CONVERSION_FORMAT
  )

  // 2021-10-08 12:00:00 (12PM) => 2021-10-08T12:00:00-08:00 PST (12PM PT)
  const dateTimeInUserTz = moment
    .tz(formattedDateTimeInLeagueTz, localTimezone)
    .format()

  return dateTimeInUserTz
}

// Todo: can probably use convertInputToLocalTimezoneFn and deprecate this function
// 12PM ET => 12PM UTC
export const parseDateTimeInUTC = (
  input = moment().format(),
  leagueTz = DEFAULT_TIMEZONE
): string => {
  //  2021-10-08T17:00:00.000Z => 2021-10-08T17:00:00.000+00:00 (5PM UTC / 12PM ET)
  const inputInUTC = moment.utc(input)

  // 2021-10-08T17:00:00.000+00:00 (5PM UTC / 12PM ET) => 2021-10-08T12:00:00-05:00 ET (12PM ET)
  const inputInLeagueTz = inputInUTC.tz(leagueTz).format()

  // 2021-10-08T12:00:00-05:00 ET (12PM ET) => 2021-10-08T12:00:00+00:00 (12PM UTC)
  const instantiateInputInLeagueTzWithUTC = moment
    .utc(inputInLeagueTz, 'YYYY-MM-DDTHH:mm:ss')
    .format()

  return instantiateInputInLeagueTzWithUTC
}

// apply a transformation function to a particular set of object keys
export const fnObjKeys = <T extends Record<string, any>, U extends keyof T>({
  fn,
  keys,
  obj,
}: {
  fn(input: any, key?: string | number | symbol): any
  keys: readonly U[]
  obj: T
}): T =>
  reduce(
    (acc, key) => {
      const value = obj[key]
      return {
        ...acc,
        ...{ [key]: fn(value, key) },
      }
    },
    {} as T,
    keys
  )

// TODO:cleanup
export const datesIn = <T extends Record<string, any>, U extends keyof T>({
  dateKeys,
  dateTimeKeys,
  obj,
  timezone,
}: {
  dateKeys: readonly U[]
  dateTimeKeys: readonly U[]
  obj: T
  timezone: string | null
}): T => ({
  ...obj,
  ...fnObjKeys({
    fn: createDateInFn({
      stripTime: true,
      timezone: timezone ?? DEFAULT_TIMEZONE,
    }),
    keys: dateKeys,
    obj,
  }),
  ...fnObjKeys({
    fn: createDateInFn({
      timezone: timezone ?? DEFAULT_TIMEZONE,
    }),
    keys: dateTimeKeys,
    obj,
  }),
})

export const datesOut = <T extends Record<string, any>, U extends keyof T>({
  dateKeys,
  dateTimeKeys,
  obj,
  timezone,
  endOfDayKeys,
  startOfDayKeys,
}: {
  dateKeys: readonly U[]
  dateTimeKeys: readonly U[]
  obj: T
  timezone: string | null
  endOfDayKeys?: readonly string[]
  startOfDayKeys?: readonly string[]
}): T => ({
  ...obj,
  ...fnObjKeys({
    fn: createDateOutFn({
      timezone: timezone ?? DEFAULT_TIMEZONE,
      endOfDayKeys,
      startOfDayKeys,
    }),
    keys: dateTimeKeys,
    obj,
  }),
  ...fnObjKeys({
    fn: createDateOutFn({
      timezone: timezone ?? DEFAULT_TIMEZONE,
      endOfDayKeys,
      startOfDayKeys,
    }),
    keys: dateKeys,
    obj,
  }),
})

export const formatMoment = <T extends Record<string, any>, U extends keyof T>(
  dateTimeKeys: readonly U[],
  obj: T
): T => ({
  ...obj,
  ...fnObjKeys({
    fn: (dateTime: string | Moment) => {
      if (moment.isMoment(dateTime)) {
        return moment(dateTime).format()
      }
      return dateTime
    },
    keys: dateTimeKeys,
    obj,
  }),
})

export const convertInputToLocalTimezone = <
  T extends Record<string, any>,
  U extends keyof T
>(
  dateTimeKeys: readonly U[],
  obj: T,
  inputTz: string,
  localTz: string
): T => ({
  ...obj,
  ...fnObjKeys({
    fn: (dateTime: string | null) => {
      if (isNil(dateTime)) {
        return dateTime
      }
      return convertInputToLocalTimezoneFn(dateTime, inputTz, localTz)
    },
    keys: dateTimeKeys,
    obj,
  }),
})

export const seasonDateKeys = [] as const
export const seasonDateTimeKeys = [
  'endsAt',
  'rostersLockAt',
  'registrationStartsAt',
  'teamRegistrationEndsAt',
  'teamDeregistrationEndsAt',
  'playerRegistrationEndsAt',
  'playerDeregistrationEndsAt',
  'startsAt',
  'suggestedRegistrationEndsAt',
] as const

export const phaseDateKeys = ['endsAt', 'startsAt'] as const
export const phaseDateTimeKeys = ['endsAt', 'startsAt'] as const
export const phaseEndOfDayKeys = ['endsAt'] as const
export const phaseStartOfDayKeys = ['startsAt'] as const

export const slotDateKeys = [] as const
export const slotDateTimeKeys = ['scheduleMatchesAt', 'startsAt'] as const

export type SlotExclusionRangeConfigurationInput = Pick<
  SlotExclusionRangeConfiguration,
  'range' | 'maxSlotExclusions'
> & { id?: string }

export type CreateSeasonFormInput = Pick<
  SeasonScheduleInput,
  | 'type'
  | 'name'
  | 'slug'
  | 'metaseasonId'
  | 'registrationStartsAt'
  | 'teamRegistrationEndsAt'
  | 'teamDeregistrationEndsAt'
  | 'playerRegistrationEndsAt'
  | 'playerDeregistrationEndsAt'
  | 'suggestedRegistrationEndsAt'
  | 'startsAt'
  | 'endsAt'
  | 'rostersLockAt'
  | 'status'
  | 'areBreakWeekSlotExclusionWindowsEnabled'
  | 'seasonPlayoffTemplateId'
  | 'overridePlayoffGenerationDate'
  | 'overridePlayoffSeedingDate'
> & {
  slotExclusionRangeConfigurations: SlotExclusionRangeConfigurationInput[]
  areCustomBreakweeksRequired?: boolean
  eventDetails: string
  isHidden: boolean
  maxAge: number | null
  minAge: number | null
  maxTeamsAllowed: number | null
  prizeDetails: string
}

export type CreateSeasonFormDefaultValues = PartiallyRequire<
  CreateSeasonFormInput,
  'status'
>

export type CreateSeasonFormValuesProp = Pick<
  Season,
  keyof Omit<
    CreateSeasonFormInput,
    | 'slotExclusionRangeConfigurations'
    | 'areCustomBreakweeksRequired'
    | 'eventDetails'
    | 'isHidden'
    | 'maxAge'
    | 'minAge'
    | 'maxTeamsAllowed'
    | 'prizeDetails'
  >
> & {
  slotExclusionRangeConfigurations: SlotExclusionRangeConfigurationInput[]
} & Pick<
    CreateSeasonFormInput,
    | 'areCustomBreakweeksRequired'
    | 'eventDetails'
    | 'isHidden'
    | 'maxAge'
    | 'minAge'
    | 'maxTeamsAllowed'
    | 'prizeDetails'
  >

const REQUIRED = 'Required'

// for some reason, yup treats null as a separate case from undefined; if we
//  don't convert null (which is a possible case for the DatePicker component)
//  to undefined, we'll get an interal "unexpected null" error before we reach
//  our "required" error.
export const yupDate = yup
  .string()
  .ensure()
  .notOneOf(['Invalid date'], 'Invalid date')
export const yupDateRequired = yup
  .string()
  .ensure()
  .required(REQUIRED)
  .notOneOf(['Invalid date'], 'Invalid date')
const yupString = yup.string()
export const yupStringRequired = yupString.required(REQUIRED)
const yupStringNotRequired = yupString.notRequired().nullable()
const yupNumberRequired = yupNumber.required(REQUIRED)
export const yupNumberGreaterThanZero = yupNumberRequired?.min(
  1,
  'Must be greater than 0'
)

export const SeasonStatuses = Object.values(SeasonStatus)
export const SeasonTypes = Object.values(SeasonType)

export enum SeasonValidationErrors {
  EndsAt = 'Must be after season start date',
  PlayerDeregistrationEndsAt = 'Must be after official player and suggested registration end dates',
  PlayerRegistrationEndsAt = 'Must be after registration start date but before season end date',
  RegistrationStartsAt = 'Must be before season start date',
  RostersLockAt = 'Must be after team and player de-registration end dates and registration start date. Must be before season end date.',
  SuggestedRegistrationEndsAt = 'Must be before official registration end dates but after registration start date',
  TeamDeregistrationEndsAt = 'Must be after suggested registration end dates',
  TeamRegistrationEndsAt = 'Must be after registration start date but before season end date.',
  SlotExclusionConfigurationStartsAt = 'Must be a valid date that starts on a Monday',
  SlotExclusionConfigurationEndsAt = 'Must be a Monday and come after Break Week Starts at date',
}

export enum PhaseValidationErrors {
  startsAt = 'Must be after season start date',
  endsAt = 'Must be before season end date',
}

export const getSeasonValidationSchema = ({
  metaseasonStartsAt,
  metaseasonEndsAt,
  leagueCompetitionGroup,
}: {
  metaseasonStartsAt?: string
  metaseasonEndsAt?: string
  leagueCompetitionGroup?: Maybe<CompetitionGroup>
}): any =>
  yup.object().shape({
    areBreakWeekSlotExclusionWindowsEnabled: yup.boolean(),
    areCustomBreakweeksRequired: yup.boolean(),
    endsAt: yupDateRequired
      .test('after', SeasonValidationErrors.EndsAt, function (): boolean {
        const { endsAt, startsAt } = this.parent
        return (
          endsAt && startsAt && moment(endsAt).isSameOrAfter(startsAt, 'day')
        )
      })
      .test(
        'same or before metaseason end date',
        `End Date must be before Metaseason End Date: ${moment(
          metaseasonEndsAt
        ).format(MOMENT_DASHED_DATE_AND_TIME)}`,
        function (): boolean {
          const { endsAt } = this.parent

          if (!metaseasonEndsAt) {
            return true
          }

          return endsAt && moment(endsAt).isSameOrBefore(metaseasonEndsAt)
        }
      ),

    maxAge: yup
      .number()
      .nullable(true)
      .test(
        'maxAge',
        'Max. Age must be greater than 0',
        function greaterThanZero(): boolean {
          const { maxAge } = this.parent
          if (typeof maxAge === 'number') {
            return maxAge > 0
          }
          return true
        }
      )
      .test(
        'maxAge',
        'Max. Age must be greater than Min. Age',
        function greaterThanMin(): boolean {
          const { maxAge, minAge } = this.parent
          if (typeof maxAge === 'number' && typeof minAge === 'number') {
            return maxAge > minAge
          }
          return true
        }
      ),
    maxTeamsAllowed: yup
      .number()
      .nullable(true)
      .test(
        'maxTeamsAllowed',
        'Max. teams is required and must be greater than 0',
        function isMaxTeamsRequired(): boolean {
          const { maxTeamsAllowed } = this.parent
          if (leagueCompetitionGroup === CompetitionGroup.Stadium) {
            return typeof maxTeamsAllowed === 'number' && maxTeamsAllowed > 0
          }
          return true
        }
      ),
    metaseasonId: yupStringNotRequired,
    minAge: yup
      .number()
      .nullable(true)
      .test(
        'minAge',
        'Min. Age must be greater than 0',
        function greaterThanZero(): boolean {
          const { minAge } = this.parent
          if (typeof minAge === 'number') {
            return minAge > 0
          }
          return true
        }
      )
      .test(
        'minAge',
        'Min. Age must be less than Max. Age',
        function lessThanMaxAge(): boolean {
          const { maxAge, minAge } = this.parent
          if (typeof maxAge === 'number' && typeof minAge === 'number') {
            return minAge < maxAge
          }
          return true
        }
      ),
    name: yupStringRequired,
    playerDeregistrationEndsAt: yupDateRequired.test(
      'after',
      SeasonValidationErrors.PlayerDeregistrationEndsAt,
      function (): boolean {
        const {
          playerDeregistrationEndsAt,
          playerRegistrationEndsAt,
          suggestedRegistrationEndsAt,
        } = this.parent

        return (
          playerDeregistrationEndsAt &&
          playerRegistrationEndsAt &&
          suggestedRegistrationEndsAt &&
          moment(playerDeregistrationEndsAt).isSameOrAfter(
            playerRegistrationEndsAt,
            'day'
          ) &&
          moment(playerDeregistrationEndsAt).isSameOrAfter(
            suggestedRegistrationEndsAt,
            'day'
          )
        )
      }
    ),
    playerRegistrationEndsAt: yupDateRequired.test(
      'after',
      SeasonValidationErrors.PlayerRegistrationEndsAt,
      function (): boolean {
        const {
          playerRegistrationEndsAt,
          registrationStartsAt,
          endsAt,
        } = this.parent
        return (
          playerRegistrationEndsAt &&
          registrationStartsAt &&
          endsAt &&
          moment(playerRegistrationEndsAt).isSameOrAfter(
            registrationStartsAt,
            'day'
          ) &&
          moment(playerRegistrationEndsAt).isSameOrBefore(endsAt, 'day')
        )
      }
    ),
    registrationStartsAt: yupDateRequired.test(
      'before',
      SeasonValidationErrors.RegistrationStartsAt,
      function (): boolean {
        const { registrationStartsAt, startsAt } = this.parent
        return (
          registrationStartsAt &&
          startsAt &&
          moment(registrationStartsAt).isSameOrBefore(startsAt, 'day')
        )
      }
    ),
    rostersLockAt: yupDateRequired.test(
      'between',
      SeasonValidationErrors.RostersLockAt,
      function (): boolean {
        const {
          rostersLockAt,
          teamDeregistrationEndsAt,
          playerDeregistrationEndsAt,
          registrationStartsAt,
          endsAt,
        } = this.parent
        return (
          rostersLockAt &&
          teamDeregistrationEndsAt &&
          playerDeregistrationEndsAt &&
          registrationStartsAt &&
          endsAt &&
          moment(rostersLockAt).isSameOrAfter(
            teamDeregistrationEndsAt,
            'day'
          ) &&
          moment(rostersLockAt).isSameOrAfter(
            playerDeregistrationEndsAt,
            'day'
          ) &&
          moment(rostersLockAt).isSameOrAfter(registrationStartsAt, 'day') &&
          moment(rostersLockAt).isSameOrBefore(endsAt, 'day')
        )
      }
    ),
    slotExclusionRangeConfigurations: yup.array().of(
      yup.object().shape({
        id: yup.string().notRequired(),
        maxSlotExclusions: yupNumberRequired,
        range: yup.object().shape({
          endsAt: yupDateRequired.test(
            'validateSlotConfigurationEndsAt',
            SeasonValidationErrors.SlotExclusionConfigurationEndsAt,
            function (): boolean {
              const startDate = dayjs(this.parent.startsAt)
              const endDate = dayjs(this.parent.endsAt)
              return (
                endDate.isValid() &&
                endDate.day() === 1 &&
                endDate.isAfter(startDate)
              )
            }
          ),
          startsAt: yupDateRequired.test(
            'validateSlotConfigurationStartsAt',
            SeasonValidationErrors.SlotExclusionConfigurationStartsAt,
            function (): boolean {
              const day = dayjs(this.parent.startsAt)
              return day.isValid() && day.day() === 1
            }
          ),
        }),
      })
    ),
    slug: yupStringRequired,
    startsAt: yupDateRequired.test(
      'same or after metaseason start date',
      `Start Date must be after Metaseason Start Date: ${moment(
        metaseasonStartsAt
      ).format(MOMENT_DASHED_DATE_AND_TIME)}`,
      function (): boolean {
        const { startsAt } = this.parent

        if (!metaseasonStartsAt) {
          return true
        }

        return startsAt && moment(startsAt).isSameOrAfter(metaseasonStartsAt)
      }
    ),
    status: yup.mixed().oneOf(SeasonStatuses, REQUIRED),
    suggestedRegistrationEndsAt: yupDateRequired.test(
      'between',
      SeasonValidationErrors.SuggestedRegistrationEndsAt,
      function (): boolean {
        const {
          suggestedRegistrationEndsAt,
          teamRegistrationEndsAt,
          playerRegistrationEndsAt,
          registrationStartsAt,
        } = this.parent
        return (
          suggestedRegistrationEndsAt &&
          teamRegistrationEndsAt &&
          playerRegistrationEndsAt &&
          registrationStartsAt &&
          moment(suggestedRegistrationEndsAt).isSameOrBefore(
            teamRegistrationEndsAt,
            'day'
          ) &&
          moment(suggestedRegistrationEndsAt).isSameOrBefore(
            playerRegistrationEndsAt,
            'day'
          ) &&
          moment(suggestedRegistrationEndsAt).isSameOrAfter(
            registrationStartsAt,
            'day'
          )
        )
      }
    ),
    teamDeregistrationEndsAt: yupDateRequired.test(
      'after',
      SeasonValidationErrors.TeamDeregistrationEndsAt,
      function (): boolean {
        const {
          teamDeregistrationEndsAt,
          suggestedRegistrationEndsAt,
        } = this.parent
        return (
          teamDeregistrationEndsAt &&
          suggestedRegistrationEndsAt &&
          moment(teamDeregistrationEndsAt).isSameOrAfter(
            suggestedRegistrationEndsAt,
            'day'
          )
        )
      }
    ),
    teamRegistrationEndsAt: yupDateRequired.test(
      'between',
      SeasonValidationErrors.TeamRegistrationEndsAt,
      function (): boolean {
        const {
          teamRegistrationEndsAt,
          registrationStartsAt,
          endsAt,
        } = this.parent
        return (
          teamRegistrationEndsAt &&
          registrationStartsAt &&
          endsAt &&
          moment(teamRegistrationEndsAt).isSameOrAfter(
            registrationStartsAt,
            'day'
          ) &&
          moment(teamRegistrationEndsAt).isSameOrBefore(endsAt, 'day')
        )
      }
    ),
    type: yup.mixed().oneOf(SeasonTypes),
  })

export const PhaseFormats = Object.values(PhaseFormat)
export const FortnitePhaseFormats = [PhaseFormat.BattleRoyale]
export const NonFortnitePhaseFormats = without(
  FortnitePhaseFormats,
  PhaseFormats
)
export const PhaseStatuses = Object.values(PhaseStatus)
export const PhaseTypes = Object.values(PhaseType)

export type CreatePhaseFormInput = {
  shouldSendPlayoffNotification: number
  status: PhaseStatus
} & Omit<
  CreatePhaseMutationVariables['input'],
  | 'seasonId'
  | 'description'
  | 'mode'
  | 'survivalAdvancementCount'
  | 'survivalAdvancementPercentage'
  | 'minSlotParticipation'
  | 'maxSlotParticipation'
  | 'shouldSendPlayoffNotification'
  | 'seriesBestOf'
>

export type CreatePhaseFormDefaultValues = PartiallyRequire<
  CreatePhaseFormInput,
  'bestOf'
>

export type CreatePhaseFormValuesProp = Pick<Phase, keyof CreatePhaseFormInput>

type CreatePhaseValidationSchema = yup.ObjectSchema<
  yup.Shape<Record<string, unknown>, CreatePhaseFormInput>
>

const basePhaseValidationSchema = {
  bestOf: yupNumberGreaterThanZero,
  name: yupStringRequired,
  status: yup.mixed().oneOf(PhaseStatuses, REQUIRED),
  type: yup.mixed().oneOf(PhaseTypes, REQUIRED),
}

export const getPhaseValidationSchema = ({
  isSmash,
  seasonStartDate,
  seasonEndDate,
  disableSingleElimination,
}: {
  isSmash: boolean
  seasonStartDate?: string
  seasonEndDate?: string
  disableSingleElimination?: boolean
}): CreatePhaseValidationSchema =>
  yup.object().shape({
    ...basePhaseValidationSchema,
    bracketSize: yup.number().when('format', {
      is: formatIsElimination,
      then: yupNumberRequired,
    }),
    endsAt: yupDateRequired
      .test('after', 'Must be after start date', function (): boolean {
        const { endsAt, startsAt } = this.parent
        return endsAt && startsAt && moment(endsAt).isSameOrAfter(startsAt)
      })
      .test(
        'before',
        `${PhaseValidationErrors.endsAt}: ${moment(seasonEndDate).format(
          MOMENT_DASHED_DATE
        )}`,
        function (): boolean {
          const { endsAt } = this.parent
          if (endsAt && seasonEndDate) {
            return moment(endsAt).isSameOrBefore(moment(seasonEndDate))
          }
          return true
        }
      ),
    format: yup
      .mixed()
      .required()
      .oneOf(NonFortnitePhaseFormats)
      .test(
        'existing single elim phase',
        'A Single Elimination Phase already exists',
        function (): boolean {
          const { format } = this.parent
          if (
            format === PhaseFormat.SingleElimination &&
            disableSingleElimination
          ) {
            return false
          }

          return true
        }
      ),
    maxTeamsPerSchool: yup.number().when(['format', 'competitionGroup'], {
      is: (format, competitionGroup) =>
        formatIsElimination(format) &&
        isAssociatedToOrganization(competitionGroup),
      then: yupNumberGreaterThanZero,
    }),
    minutesBeforeMatchToLockInSeries: isSmash ? yupNumberRequired : yupNumber,
    shouldSendPlayoffNotification: yup.number(),
    startsAt: yupDateRequired.test(
      'after',
      `${PhaseValidationErrors.startsAt}: ${moment(seasonStartDate).format(
        MOMENT_DASHED_DATE
      )}`,
      function (): boolean {
        const { startsAt } = this.parent

        if (startsAt && seasonStartDate) {
          return moment(startsAt).isSameOrAfter(moment(seasonStartDate))
        }
        return true
      }
    ),
  })

export const getFortnitePhaseValidationSchema = ({
  seasonStartDate,
  seasonEndDate,
}: {
  seasonStartDate?: string
  seasonEndDate?: string
}): CreatePhaseValidationSchema =>
  yup.object().shape({
    ...basePhaseValidationSchema,
    endsAt: yupDateRequired
      .test('after', 'Must be after start date', function (): boolean {
        const { endsAt, startsAt } = this.parent
        return endsAt && startsAt && moment(endsAt).isSameOrAfter(startsAt)
      })
      .test(
        'before',
        `${PhaseValidationErrors.endsAt}: ${moment(seasonEndDate).format(
          MOMENT_DASHED_DATE
        )}`,
        function (): boolean {
          const { endsAt } = this.parent

          if (endsAt && seasonEndDate) {
            return moment(endsAt).isSameOrBefore(moment(seasonEndDate))
          }
          return true
        }
      ),
    format: yup.mixed().required().oneOf(FortnitePhaseFormats),
    fortniteEventId: yupString,
    shouldSendPlayoffNotification: yup.number(),
    startsAt: yupDateRequired.test(
      'after',
      `${PhaseValidationErrors.startsAt}: ${moment(seasonStartDate).format(
        MOMENT_DASHED_DATE
      )}`,
      function (): boolean {
        const { startsAt } = this.parent
        if (startsAt && seasonStartDate) {
          return moment(startsAt).isSameOrAfter(moment(seasonStartDate))
        }
        return true
      }
    ),
  })

export type CreateSlotFormInput = Omit<
  CreateSlotMutationVariables['input'],
  | 'ordinal'
  | 'phaseId'
  | 'rankingSourcePhaseId'
  | 'rankingType'
  | 'seriesBestOf'
>

export type CreateSlotFormDefaultValues = PartiallyRequire<
  CreateSlotFormInput,
  'bestOf' | 'status'
>

export type CreateSlotFormValuesProp = Pick<Slot, keyof CreateSlotFormInput>

type CreateSlotValidationSchema = yup.ObjectSchema<
  yup.Shape<Record<string, unknown>, CreateSlotFormInput>
>

export const SlotStatuses = Object.values(SlotStatus)

const baseSlotValidationSchema = {
  bestOf: yupNumberGreaterThanZero,
  name: yupString,
  scheduleMatchesAt: yupDate,
  startsAt: yupDateRequired,
  status: yup.mixed().oneOf(SlotStatuses, REQUIRED),
}

export const getBetweenPhaseDatesMessage = (
  start?: string,
  end?: string
): string =>
  `Must be between phase start & end dates: ${moment(start).format(
    MOMENT_DASHED_DATE
  )} - ${moment(end).format(MOMENT_DASHED_DATE)}`

export const getSlotValidationSchema = ({
  phaseEndsAt,
  phaseStartsAt,
}: {
  phaseEndsAt?: string
  phaseStartsAt?: string
}): CreateSlotValidationSchema =>
  yup.object().shape({
    ...baseSlotValidationSchema,
    startsAt: yupDateRequired.test(
      'is between phase startsAt & endsAt',
      getBetweenPhaseDatesMessage(phaseStartsAt, phaseEndsAt),
      function () {
        const { startsAt } = this.parent

        if (!phaseEndsAt || !phaseStartsAt) {
          return true
        }

        return moment(startsAt).isBetween(
          phaseStartsAt,
          phaseEndsAt,
          null,
          '[]'
        )
      }
    ),
  })

export const getFortniteSlotValidationSchema = (): CreateSlotValidationSchema =>
  yup.object().shape({
    ...baseSlotValidationSchema,
    fortniteEventWindowId: yupString.required(REQUIRED),
  })

export const DATE_PICKER_BASE_PROPS = {
  clearable: true,
  format: DATE_INPUT_FORMAT,
  fullWidth: true,
  inputVariant: 'outlined',
  placeholder: DATE_INPUT_FORMAT,
} as const

export const DATE_TIME_PICKER_BASE_PROPS = {
  clearable: true,
  format: DATE_AND_TIME_INPUT_FORMAT,
  fullWidth: true,
  inputVariant: 'outlined',
  placeholder: DATE_AND_TIME_INPUT_FORMAT,
} as const

// add round property (e.g. round 1) to double elim slot
export const assignSlotRound = (slots: GetLeagueSlot[]): void => {
  const winnerSlots = slots?.filter(parseWinnerSlotsByLabel) ?? []
  const loserSlots = slots?.filter(parseLoserSlotsByLabel) ?? []
  const finalSlots = slots?.filter(parseFinalSlotsByLabel) ?? []
  slots.forEach((slot) => {
    let round
    switch (slot?.label) {
      case SlotLabel.Winners:
        round = winnerSlots.findIndex((winnerSlot) => winnerSlot === slot) + 1
        break
      case SlotLabel.Losers:
        round = loserSlots.findIndex((loserSlot) => loserSlot === slot) + 1
        break
      case SlotLabel.Finals:
        round = finalSlots.findIndex((finalSlot) => finalSlot === slot) + 1
        break
      default:
        round = 0
        break
    }
    Object.assign(slot, { round })
  })
}
