import React from 'react'
import { useGetTeamMembersAtNowQuery } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import {
  EnrolledPlayerOnTeam,
  EnrollmentLeague,
  SeasonEnrollmentRangeData,
  TeamLeagueSelectFn,
} from '@plvs/respawn/containers/enrollment/types'
import { TeamRow } from '../../features/enrollment/Row/TeamRow'

interface EnrollmentTeamRowProps {
  enrolledLeague?: EnrollmentLeague
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  leagues: EnrollmentLeague[]
  metaseasonId: string
  onChange: TeamLeagueSelectFn
  teamId: string
  isAdmin: boolean
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  addEnrollmentSeasonIds: (seasonIds: string[]) => void
  removeEnrollmentSeasonId: (seasonId: string) => void
}

export const EnrollmentTeamRow: React.FC<EnrollmentTeamRowProps> = ({
  enrolledLeague,
  enrolledPlayersOnTeam,
  leagues,
  metaseasonId,
  onChange,
  teamId,
  isAdmin,
  seasonSlotExclusionRangeData,
  addEnrollmentSeasonIds,
  removeEnrollmentSeasonId,
}) => {
  const { promotedMetaseason } = useSchoolLeagueInfoContext()
  const { data, loading } = useGetTeamMembersAtNowQuery({
    skip: !teamId,
    variables: { teamId },
  })

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        {data?.team ? (
          <TeamRow
            key={teamId}
            addEnrollmentSeasonIds={addEnrollmentSeasonIds}
            enrolledLeague={enrolledLeague}
            enrolledPlayersOnTeam={enrolledPlayersOnTeam}
            isAdmin={isAdmin}
            leagues={leagues}
            metaseasonId={metaseasonId}
            onChange={onChange}
            promotedMetaseason={promotedMetaseason}
            removeEnrollmentSeasonId={removeEnrollmentSeasonId}
            seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
            team={data.team}
          />
        ) : null}
      </>
    </WaitTillLoaded>
  )
}
