import React from 'react'
import { Avatar, Box, useTheme } from '@material-ui/core'
import { isEmpty } from 'ramda'

import { Books } from '@playvs-inc/nexus-icons'

import { useRosterContext } from '../../RosterProvider'
import { StatsAvatarWrapper } from './RosterRowFilledStats.helpers'
import { useStyles } from './RosterRowFilledStats.styles'
import { StatsAvatar } from './StatsAvatar'

export interface MaddenStatsProps {
  memberId: string
}

export const RosterRowFilledStatsMadden: React.FC<MaddenStatsProps> = ({
  memberId,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const { statsByPlayer } = useRosterContext()
  const stats = statsByPlayer[memberId]
  const showStats = stats?.maddenStats && !isEmpty(stats.maddenStats)

  const playbooks = ({
    offensivePlaybook,
    defensivePlaybook,
  }: {
    offensivePlaybook?: string
    defensivePlaybook?: string
  }): React.ReactNode => {
    return (
      <StatsAvatarWrapper
        tooltipTitle={
          <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
            <Box>
              {offensivePlaybook && <Box>Offensive: {offensivePlaybook}</Box>}
              {defensivePlaybook && <Box>Defensive: {defensivePlaybook}</Box>}
            </Box>
          </Box>
        }
      >
        <Avatar className={styles.statsAvatar}>
          <Books />
        </Avatar>
      </StatsAvatarWrapper>
    )
  }

  const offensivePlaybook =
    stats?.maddenStats?.mostPlayedOffensivePlaybooks?.[0]?.name
  const defensivePlaybook =
    stats?.maddenStats?.mostPlayedDefensivePlaybooks?.[0]?.name

  return showStats ? (
    <Box mr={1}>
      <Box display="flex" gridGap={theme.spacing(0.5)}>
        {Boolean(offensivePlaybook ?? defensivePlaybook) &&
          playbooks({
            offensivePlaybook,
            defensivePlaybook,
          })}
        {stats?.maddenStats?.mostPlayedTeams?.map(
          (team) => team && <StatsAvatar {...team} />
        )}
      </Box>
    </Box>
  ) : (
    <></>
  )
}
