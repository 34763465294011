export enum TeamEnrollmentStatus {
  Enrolled = 'enrolled',
  NotEnrolled = 'notEnrolled',
}

export const getTeamEnrollmentStatus = ({
  isEnrolled,
}: {
  isEnrolled: boolean
}): TeamEnrollmentStatus =>
  isEnrolled ? TeamEnrollmentStatus.Enrolled : TeamEnrollmentStatus.NotEnrolled

export const getPillProps = (
  status: TeamEnrollmentStatus
): {
  label: string
  variant: 'warn' | 'info' | 'success'
} => {
  switch (status) {
    case TeamEnrollmentStatus.Enrolled:
      return { label: 'Enrolled', variant: 'success' }
    case TeamEnrollmentStatus.NotEnrolled:
      return { label: 'Not Enrolled', variant: 'warn' }
    default:
      return { label: '', variant: 'info' }
  }
}
