import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  seriesTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  ctaContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cta: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  lowerContentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))
