import React, { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import {
  RouteComponentProps,
  withRouter,
} from '@plvs/respawn/features/route/WithRouter'
import { withCookies } from 'react-cookie'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Param } from '@plvs/const'
import { cleanGraphQLError, CookieProps } from '@plvs/utils'
import { rallyEnv } from '@plvs/rally/env'

import {
  refetchGetSchoolProviderAccountsQuery,
  useUnlinkSchoolProviderAccountMutation,
  SchoolProvider,
} from '@plvs/graphql'

import ConnectionCard from '../connections/ConnectionCard'
import {
  createConnectAccount,
  SchoolAccountProviderComponentProps,
} from '../connections/utils'

interface SchoolConnectionProps
  extends SchoolAccountProviderComponentProps,
    RouteComponentProps {}

const SchoolConnection: React.FC<SchoolConnectionProps & CookieProps> = ({
  name: providerName,
  schoolProviderDetails,
  apolloError,
  cookies,
  title,
  subtitle,
}) => {
  // Data
  const schoolProviderId = schoolProviderDetails?.id ?? ''
  const schoolProviderDisplayName =
    schoolProviderDetails?.providerDisplayName ?? ''
  // Hooks
  const [success] = useQueryParam(Param.Success)
  const [authError] = useQueryParam(Param.AuthError)
  const [provider] = useQueryParam(Param.Provider)
  const [name, setName] = useState<string>(schoolProviderDisplayName)
  const [error, setError] = useState<Error | ApolloError | undefined>()
  // Mutations
  const [unlinkSchoolProvider] = useUnlinkSchoolProviderAccountMutation({
    onError: (err): void => setError(err),
  })

  useEffect(() => {
    if (schoolProviderDisplayName) setName(schoolProviderDisplayName)
  }, [schoolProviderDisplayName])
  useEffect(() => {
    if (apolloError && provider === providerName) {
      setError(apolloError)
    }
  }, [apolloError])
  useEffect(() => {
    if (provider === providerName) {
      if (success === 'false' && authError) {
        setError(new Error(authError))
      }
      if (success === 'false' && !authError) {
        setError(
          new Error(
            'Account could not be successfully connected, please try again or connect a different account.'
          )
        )
      }
    }
  }, [provider, success, authError])

  const disconnectAccount = async (): Promise<void> => {
    await unlinkSchoolProvider({
      awaitRefetchQueries: true,
      refetchQueries: [refetchGetSchoolProviderAccountsQuery()],
      variables: {
        providerId: schoolProviderId,
      },
    }).then(() => {
      setName('')
      setError(undefined)
    })
  }

  const getAuthorizeUri = (accountProvider: string | undefined): string => {
    switch (accountProvider) {
      case SchoolProvider.TwitchSchool:
        return rallyEnv.TWITCH_SCHOOL_AUTH_URI
      case SchoolProvider.DiscordSchool:
        return rallyEnv.DISCORD_SCHOOL_AUTH_URI
      case SchoolProvider.YoutubeSchool:
        return rallyEnv.YOUTUBE_SCHOOL_AUTH_URI
      default:
        return ''
    }
  }

  const connectAccount = async (): Promise<void> => {
    const uri = getAuthorizeUri(providerName)
    const connect = createConnectAccount(uri, cookies, setError)
    connect()
  }

  return (
    <>
      <ConnectionCard
        connectedText={name ? `School Connected to ${name}` : ''}
        errorText={
          error && error.message ? cleanGraphQLError(error.message) : ''
        }
        isConnected={!!name}
        onConnectClick={connectAccount}
        onUpdateClick={disconnectAccount}
        providerName={providerName}
        subtitle={name ? '' : subtitle}
        title={title}
      />
    </>
  )
}

export default withRouter(withCookies(SchoolConnection))
