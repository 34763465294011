// 3rd party vendor config code.  Ignore code coverage and type warnings.
/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { env } from '@plvs/env'
import { ValidationError } from 'yup'

// NOTE: Sentry types fails on init method :/
type SentryClient = any

interface SentryProviderProps {
  client: SentryClient | null
}

const shouldInit = !env.IS_NODE_ENV_DEVELOPMENT // || true

export const sentryContext = React.createContext<SentryProviderProps>({
  client: null,
})

const { Provider } = sentryContext
export const SentryProvider: React.FC<SentryProviderProps> = ({
  client,
  children,
}) => {
  return <Provider value={{ client }}>{children}</Provider>
}

const filteredErrors = [
  /Non-Error promise rejection captured with keys/gi,
  /Extension context invalidated/gi,
  /Benchmark is not defined/gi,
  /Cannot read property 'sendMessage'/gi,
]

const shouldFilterMessage = (message: string): boolean =>
  filteredErrors.some((err) => !!message.match(err))

export const initSentry = (
  Sentry: SentryClient,
  sentryDsn: string | undefined
): void => {
  if (!shouldInit) return

  // Config documentation:
  // https://docs.sentry.io/clients/javascript/config/

  Sentry.init({
    dsn: sentryDsn,
    environment: env.NODE_ENV,
    // NOTE: trying to filter out bad errors
    // https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-540553675
    beforeSend(event: any, hint: any) {
      // (try catch is only so you dont have to assert any null values in that long chain)
      const { message: eventMessage } =
        // eslint-disable-next-line no-underscore-dangle
        event?.extra?.__serialized__?.detail?.reason ?? {}
      const errorMessage = event?.exception?.values?.[0]?.value ?? ''

      const originalError = hint.originalException

      if (
        shouldFilterMessage(eventMessage || '') ||
        shouldFilterMessage(errorMessage || '') ||
        // Dev Note: See ID-943 - filter out Yup Validation errors.
        originalError instanceof ValidationError
      ) {
        // eslint-disable-next-line no-console
        console.warn(`Error filtered:`, eventMessage || errorMessage)
        return null
      }
      return event
    },
    // NOTE: Inserted example from Sentry docs below:
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Sentry example START
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Sentry example STOP

      // PlayVS specific configuration below:
      'twttr is not defined',
      'window.wootric is not a function',
      'Failed to fetch',
      'The request timed out',
      'NetworkError when attempting to fetch resource',
      'The network connection was lost',
      'The Internet connection appears to be offline',
      'Loading chunk 1 failed',
      'Loading chunk 2 failed',
      'Loading chunk 3 failed',
      'Loading chunk 4 failed',
      // See https://github.com/juggle/resize-observer/issues/103. This error is being handled properly to prevent
      // infinite resize loops that will crash the browser.
      'ResizeObserver loop completed with undelivered notifications',
      // See https://stackoverflow.com/a/50387233.
      'ResizeObserver loop limit exceeded',
      'TypeError: Load failed',
    ],
    denyUrls: [
      // Sentry example START
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Sentry example STOP

      // PlayVS specific configuration below:
      // Spam websites
      /faxir\.ru/i,
      /lavad\.ru/i,
      /breca\.ru/i,

      // filter out any ad blocked content from Segment
      /cdn\.segment\.com/i,

      // Intercom JS scripts
      /message-modern\..*\.js/i, // name of JS script that loads the iframe.
      /js\.intercomcdn\.com/i, // Intercom CDN where additional JS files are loaded.

      // Social media JS scripts/pixels
      /snapchat\.com/i, // Snapchat
      /sc-static\.net/i, // Shopify
      /analytics\.tiktok\.com/i, // TikTok
    ],
  })
}
