import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  NxTypography,
  NxTeamHistoryRecord,
  NxCardPaginationButtons,
} from '@playvs-inc/nexus-components'
import { Box, FlexSpacer } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { makeStyles } from '@material-ui/core'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { EsportRating, GetMySchoolTeamsQuery } from '@plvs/graphql/generated'
import { Path } from '@plvs/const'
import { Link } from '@plvs/rally/components/link/Link'

interface StyleProps {
  currentIndex: number
  teamsSize: number
}

const useStyles = makeStyles((theme) => ({
  pagination: ({ teamsSize }: StyleProps): CreateCSSProperties => ({
    display: teamsSize > 2 ? 'block' : 'none',
    [theme.breakpoints.down('xs')]: {
      display: teamsSize > 1 ? 'block' : 'none',
    },
  }),
  teamRowContainerOverflow: {
    overflowX: 'hidden',
  },
  teamRowContainer: {
    height: 314,
    width: '100%',
    display: 'flex',
  },
  teamContainer: ({ currentIndex }: StyleProps): CreateCSSProperties => ({
    marginRight: theme.spacing(2),
    transition: 'all 250ms linear',
    transform: `translateX(-${currentIndex * 100}%)`,
    minWidth: 260,
  }),
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.OverlayColorTextBase,
    },
  },
}))

interface Props {
  isPublicPage: boolean
  title: string
  teams: NonNullable<NonNullable<GetMySchoolTeamsQuery>['school']>['teams']
}

export const TeamsListGroup: React.FC<Props> = ({
  isPublicPage,
  title,
  teams,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want to make the component re-appear when it comes back in view
  })
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const { metaseason } = useSchoolLeagueInfoContext()

  const teamsSize = teams?.length ?? 0

  const classes = useStyles({ currentIndex, teamsSize })

  const handleNext = (): void => {
    if (currentIndex < teamsSize - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }
  const anyRestricted = teams?.some(
    (t) => t.esport.rating === EsportRating.Restricted
  )

  return (
    <>
      <Box alignItems="center" display="flex" mb={2}>
        <NxTypography variant="h4">{title}</NxTypography>
        <FlexSpacer />
        <div className={classes.pagination}>
          <NxCardPaginationButtons
            enableLeft={currentIndex > 0}
            enableRight={currentIndex < teamsSize - 1}
            onLeft={handlePrev}
            onRight={handleNext}
          />
        </div>
      </Box>
      {anyRestricted && (
        <Box mb={2}>
          <NxTypography variant="body3">
            Some esports titles and leagues are unaffiliated with the NFHS
            Network and certain state associations.
          </NxTypography>
        </Box>
      )}
      <Box className={classes.teamRowContainerOverflow} mb={3}>
        <Box ref={ref} className={classes.teamRowContainer}>
          {(teams || []).map((team) => {
            const seasonName = metaseason?.name ?? ''
            const leagueName =
              team?.leagues?.[0]?.displayName ||
              team?.leagues?.[0]?.name ||
              '- -'

            const hasRecord =
              team?.phaseResult?.matchesWon || team?.phaseResult?.matchesLost
            const recordMetaseasonName =
              team?.phaseResult?.phase?.season?.metaseason?.name
            const recordPhaseName = team?.phaseResult?.phase?.name
            const recordSeasonName = hasRecord
              ? `${recordMetaseasonName} ${recordPhaseName}`
              : seasonName

            const esportSlug = team?.esport?.slug

            return (
              inView && (
                <NxTeamHistoryRecord
                  key={team?.id}
                  className={classes.teamContainer}
                  teamHistoryRecord={{
                    seasonName,
                    leagueName,
                    teamName: (
                      <Link
                        className={classes.link}
                        to={`${isPublicPage ? '/team' : Path.Team}/${team?.id}`}
                      >
                        <NxTypography
                          colorToken="OverlayColorTextBase"
                          variant="h4"
                        >
                          {team?.name ?? ''}
                        </NxTypography>
                      </Link>
                    ),
                    recordSeasonName,
                    wins: team?.phaseResult?.matchesWon ?? 0,
                    losses: team?.phaseResult?.matchesLost ?? 0,
                    esportSlug,
                  }}
                />
              )
            )
          })}
        </Box>
      </Box>
    </>
  )
}
