import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'
import { useClipboard } from '@plvs/respawn/features/button'

type CopyButtonProps = {
  buttonTextBeforeCopying?: string
  buttonTextAfterCopying?: string
  textToCopy: string
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  buttonTextBeforeCopying = 'Copy',
  buttonTextAfterCopying = 'Copied!',
  textToCopy,
}) => {
  const { copy, wasRecentlyCopied } = useClipboard(textToCopy)

  const renderTitle = wasRecentlyCopied
    ? buttonTextAfterCopying
    : buttonTextBeforeCopying

  return (
    <NxButton
      disabled={wasRecentlyCopied}
      label={renderTitle}
      onClick={copy}
      variant="secondary"
    />
  )
}
