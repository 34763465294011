import React from 'react'
import clsx from 'clsx'
import { NxTypography, NxTooltip } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.OverlayColorOpBlack3,
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(1, 0, 1, 2),
    width: 98,
    height: 91,
  },
  stat: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  className?: string
  stat: number
  subtitle: string
}

const getTooltipText = (subtitle: string): string => {
  switch (subtitle) {
    case 'Matches':
      return 'Number of completed matches. Does not include forfeits.'
    case 'Seasons':
      return 'Number of seasons with a match played.'
    case 'Titles':
      return 'Number of esport titles with a match played.'
    default:
      return ''
  }
}

export const SnapshotBox: React.FC<Props> = ({ className, stat, subtitle }) => {
  const classes = useStyles()

  return (
    <NxTooltip arrow placement="bottom-start" title={getTooltipText(subtitle)}>
      <Box
        alignItems="flex-start"
        className={clsx(classes.root, className)}
        display="flex"
        flexDirection="column"
      >
        <NxTypography
          className={classes.stat}
          colorToken="OverlayColorTextBase"
          variant="display2"
        >
          {stat}
        </NxTypography>
        <NxTypography colorToken="OverlayColorTextAlt" variant="body3">
          {subtitle} <br /> Played
        </NxTypography>
      </Box>
    </NxTooltip>
  )
}
