import { useState, useCallback, useLayoutEffect } from 'react'

interface UseImageLoaderReturn {
  /**
   * If the browser has completed loading the external image
   * URL
   */
  isLoaded: boolean
  /**
   * If the browser encountered an error loading the
   * external image URL.
   */
  isError: boolean
}

/**
 * This hook will attempt to load the `src` parameter in a hidden
 * img tag not attached to the dom, then return `isLoaded=true`
 * when the image has finished loading.
 */
export const useImageLoader = (
  src: string | null | undefined
): UseImageLoaderReturn => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  const onError = useCallback(() => {
    setIsError(true)
  }, [])

  useLayoutEffect(() => {
    let img: HTMLImageElement | null
    if (src) {
      img = new Image()
      img.addEventListener('load', onLoad)
      img.addEventListener('error', onError)
      img.src = src || ''
    }
    return (): void => {
      if (img) {
        img.removeEventListener('load', onLoad)
      }
    }
  }, [src])

  return {
    isError,
    isLoaded,
  }
}
