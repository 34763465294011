import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { appendClasses } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'

export type BadgeProps = {
  backgroundColor: string
  color: string
  className?: string
  size?: 'small' | 'medium'
  style?: React.CSSProperties
  text: string
  textStyle?: React.CSSProperties
  textVariant?: 'overline' | 'body1' | 'body2' | 'body4'
}

const rootMap: Record<NonNullable<BadgeProps['size']>, React.CSSProperties> = {
  small: {
    lineHeight: '26px',
    padding: '0 8px',
  },
  medium: {
    lineHeight: '32px',
    padding: '0 12px',
  },
}

const typographyMap: Record<
  NonNullable<BadgeProps['size']>,
  React.CSSProperties
> = {
  small: {
    fontSize: 12,
  },
  medium: {
    fontSize: 16,
  },
}

const useStyles = makeStyles({
  badge: ({
    backgroundColor,
    color,
  }: {
    backgroundColor: string
    color: string
  }): CreateCSSProperties => ({
    backgroundColor,
    borderRadius: 8,
    color,
    display: 'flex',
    width: 'fit-content',
  }),
})

export const Badge: React.FC<BadgeProps> = ({
  backgroundColor,
  className,
  color,
  size = 'small',
  style = {},
  text,
  textStyle = {},
  textVariant,
}) => {
  const classes = useStyles({ backgroundColor, color })

  return (
    <span
      className={appendClasses(classes.badge, className)}
      style={{
        ...rootMap[size],
        ...style,
      }}
    >
      <NxTypography
        color="inherit"
        style={{ lineHeight: 'inherit', ...typographyMap[size], ...textStyle }}
        variant={textVariant || 'overline'}
      >
        {text}
      </NxTypography>
    </span>
  )
}
