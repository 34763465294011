/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-this-in-sfc */
import React from 'react'
import { renderToString } from 'react-dom/server'
import { ShepherdSlide } from '@plvs/rally/components/shepherd/ShepherdSlide'
import { NxEmote } from '@playvs-inc/nexus-components'
import Step from 'shepherd.js/src/types/step'
import { Path } from '@plvs/const'
import { NxSpot } from '@playvs-inc/nexus-spots'
import { matchLobbyTourClicked } from '@plvs/respawn/features/analytics'
import { pollForItem } from './shepherdUtils'
import dayjs from '../dayjs'

const CHAT_SUBTITLE = `This is the match chat section. Use this space to communicate with all the players and coaches of the opposing team. Additionally, you can utilize the "COACH" tab to have direct conversations with the opposing team's coach.`

export const createMatchLobbyTourSteps = ({
  userId,
}: {
  userId: string
}): Step.StepOptions[] => {
  const now = dayjs().toISOString()

  const steps: Step.StepOptions[] = [
    {
      id: 'match-lobby-intro',
      text: renderToString(
        <ShepherdSlide
          spot={
            <NxSpot
              domain="Stadium"
              height={132}
              size="large"
              variant="primary"
              width={132}
            />
          }
          subtitle="A dedicated Match Lobby is generated for each match. Take this tour to explore and familiarize yourself with its navigation."
          title="Welcome to the Match Lobby!"
        />
      ),
      classes: 'shepherd-modal large-slide',
      cancelIcon: {
        enabled: false,
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Maybe Later',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Maybe Later Button',
            })
            const element = document.getElementsByClassName(
              'shepherd-manage-match'
            )?.[0]
            if (element && element instanceof HTMLElement) {
              element.click()
            }

            return this.show('maybe-later')
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Take Tour',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Skip Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'chat',
      attachTo: { element: '.shepherd-chat', on: 'right-start' },
      canClickTarget: false,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal match-chat',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={1}
          subtitle={CHAT_SUBTITLE}
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Skip Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'match-assistant',
      attachTo: { element: '.shepherd-match-assistant', on: 'left-start' },
      canClickTarget: false,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal match-assistant',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={2}
          subtitle="This area is called the “Match Assistant”. The Match Assistant will walk you through submitting results for each game within a match. Simply follow the prompts in this section once the match starts."
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 2,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 2,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'teams-tab',
      attachTo: { element: '.shepherd-teams-tab', on: 'right-end' },
      canClickTarget: false,
      cancelIcon: {
        enabled: true,
      },
      scrollTo: true,
      scrollToHandler(): void {
        window.scrollTo(0, 0)
      },
      classes: 'shepherd-modal match-players-tab',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={3}
          subtitle="You can view the rosters of both teams in the “Teams” tab here."
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 3,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      modalOverlayOpeningPadding: 8,
    },
    {
      id: 'manage-match-button',
      attachTo: { element: '.shepherd-manage-match', on: 'left' },
      cancelIcon: {
        enabled: true,
      },
      scrollTo: true,
      scrollToHandler(): void {
        window.scrollTo(0, 0)
      },
      classes: 'shepherd-modal manage-match-button',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 10, right: -80, transform: 'rotate(150deg)' }}
          step={4}
          subtitle="Click here to open the “Manage Match” menu."
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 4,
              clickTarget: 'Back Button',
            })
            if (window.location.pathname.includes(Path.Manage)) {
              window.history.back()
            }
            return this.back()
          },
        },
      ],
      modalOverlayOpeningPadding: 8,
    },
    {
      id: 'manage-match-menu',
      cancelIcon: {
        enabled: true,
      },
      attachTo: { element: '.shepherd-manage-match-menu', on: 'left' },
      classes: 'shepherd-modal manage-match-menu',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={5}
          subtitle="Here you’ll find helpful links to rulebooks, your opponent’s team page, and the forfeit match option."
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 5,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 5,
              clickTarget: 'Next Button',
            })
            const element = document.getElementsByClassName(
              'shepherd-manage-match'
            )?.[0]
            if (element && element instanceof HTMLElement) {
              element.click()
            }

            return this.next()
          },
        },
      ],
      beforeShowPromise: pollForItem({
        selector: '.shepherd-manage-match-menu',
      }),
    },
    {
      id: 'intercom-button',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal intercom-button',
      attachTo: {
        element: '.intercom-launcher',
        on: 'right',
      },
      canClickTarget: false,
      text: renderToString(
        <ShepherdSlide
          showTopLeftHighlight
          step={6}
          subtitle="If you need live help during a match, you can reach our support team by clicking this button. You’ll get a response within minutes"
          totalSteps={7}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 6,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 6,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      modalOverlayOpeningPadding: 4,
    },
    {
      id: 'match-lobby-final-step',
      classes: 'shepherd-modal large-slide',
      text: renderToString(
        <ShepherdSlide
          spot={<NxEmote emoteKey="fbhighfive" height={112} width={112} />}
          subtitle="That’s it! Have fun and best of luck on your next match!"
          title="Good Luck on Your Match!"
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 7,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Done',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 7,
              clickTarget: 'Complete Button',
            })
            return this.complete()
          },
        },
      ],
    },
    {
      id: 'maybe-later',
      classes: 'shepherd-modal match-maybe-later',
      attachTo: { element: '.shepherd-match-lobby-tour', on: 'left' },
      text: renderToString(
        <ShepherdSlide
          rightArrowStyles={{ top: 20, right: -70 }}
          subtitle='To revisit the tour at any time, simply click on “Manage Match” and then select "Match Lobby Tour”.'
        />
      ),
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-primary-button',
          text: 'Got it',
          action(): void {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Got It Button',
            })
            return this.complete()
          },
        },
      ],
      beforeShowPromise: pollForItem({
        selector: '.shepherd-match-lobby-tour',
      }),
    },
  ]

  return steps
}
