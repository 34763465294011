import { DeleteTeamMutation, useDeleteTeamMutation } from '@plvs/graphql'
import { FetchResult } from '@apollo/client'
import { generateDeleteUpdateFn } from './helpers'

type Options = ReturnType<typeof useDeleteTeamMutation>[1]
type DeleteFn = (teamId: string) => Promise<FetchResult<DeleteTeamMutation>>

export const useDeleteTeamHook = (): [fn: DeleteFn, options: Options] => {
  const [deleteTeamMutation, opts] = useDeleteTeamMutation()
  const fn: DeleteFn = async (teamId: string) => {
    return deleteTeamMutation({
      variables: {
        teamId,
      },
      update: generateDeleteUpdateFn(teamId, 'Team'),
    })
  }
  return [fn, opts]
}
