import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { noop } from 'ramda-adjunct'

import { NxButton, NxHeaderCardAlertProps } from '@playvs-inc/nexus-components'

import {
  useRequestGameResetMutation,
  useRequestMatchAssistantBypassMutation,
} from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql/types'
import { MatchAssistantAlertType, StepType } from '@plvs/utils'
import { logger } from '@plvs/rally/logging'
import { useMatchAssistantContext } from '../useMatchAssistant'

type AlertProps = NxHeaderCardAlertProps & {
  actionLoading?: boolean
}
interface MatchAssistantStepAlertsProviderProps {
  matchId: string
  teamId: string
}

interface UseMatchAssistantStepAlertsReturn {
  getStepAlerts(step?: StepType, esportSlug?: EsportSlug): AlertProps
  openStepAlert(input: MatchAssistantAlertType): void
  renderStepAlert: boolean
}

export function useMatchAssistantStepAlerts({
  matchId,
  teamId,
}: MatchAssistantStepAlertsProviderProps): UseMatchAssistantStepAlertsReturn {
  const data = useMatchAssistantContext()
  const { enqueueSnackbar } = useSnackbar()
  const [showStepAlert, setShowStepAlert] = useState<
    Record<MatchAssistantAlertType, boolean>
  >({ reset: false, skip: false })
  const [renderStepAlert, setRenderStepAlert] = useState<boolean>(false)
  const [isLockStepForAlert, setIsLockStepForAlert] = useState(false)
  const [requestResetGameMutation, { loading }] = useRequestGameResetMutation()
  const [
    requestSkipAssistantMutation,
    { loading: skipAssistantLoading },
  ] = useRequestMatchAssistantBypassMutation()

  const openStepAlert = (input: MatchAssistantAlertType): void => {
    setShowStepAlert({ ...showStepAlert, [input]: true })
    setRenderStepAlert(true)
  }

  const closeStepAlert = (): void => {
    const resetAllStepAlerts = { reset: false, skip: false }
    setShowStepAlert({ ...resetAllStepAlerts })
    setIsLockStepForAlert(false)
    setRenderStepAlert(false)
  }

  const resetGame = async (): Promise<void> => {
    try {
      const reset = await requestResetGameMutation({
        variables: {
          input: {
            matchId,
            teamId,
          },
        },
      })

      if (reset.data?.requestGameReset.success) {
        setIsLockStepForAlert(true)
      }
    } catch (err) {
      logger.error('[stepAlertResetGame]', err)
      enqueueSnackbar('Unable to reset game, please reach out to support.', {
        variant: 'error',
      })
    }
  }
  const skipAssistant = async (): Promise<void> => {
    try {
      const skip = await requestSkipAssistantMutation({
        variables: {
          input: {
            matchId,
            teamId,
          },
        },
      })

      if (skip.data?.requestMatchAssistantBypass?.success) {
        setIsLockStepForAlert(true)
      }
    } catch (err) {
      logger.error('[stepAlertSkipAssistant]', err)
      enqueueSnackbar(
        'Unable to skip Match Assistant, please reach out to support.',
        {
          variant: 'error',
        }
      )
    }
  }

  // This useEffect makes sure that when the subscription is updated, we auto close the alert that is currently open.
  // This allows the frontend to "fake" a lock step without leaving the alert window open once the action completes.
  useEffect(() => {
    closeStepAlert()
  }, [data])

  const getStepAlerts = (): AlertProps => {
    if (showStepAlert.reset) {
      return {
        title: 'Reset Game / Change Roster',
        subtitle:
          "If teams need to change roster or reset the game because something went wrong, continue. Both teams will need to click 'Continue'.",
        actions: (
          <>
            <NxButton
              disabled={loading || isLockStepForAlert}
              label="Close"
              onClick={(): void => closeStepAlert()}
              variant="text"
            />
            <NxButton
              label="Reset Game"
              loading={loading || isLockStepForAlert}
              onClick={resetGame}
              variant="primary"
            />
          </>
        ),
        firebobMessage:
          loading || isLockStepForAlert
            ? 'Waiting for your opponent to reset the game.'
            : undefined,
        firebobSubtitle:
          loading || isLockStepForAlert ? 'Waiting on opponent' : undefined,
        onClose: (): void => closeStepAlert(),
        actionLoading: loading,
      }
    }
    if (showStepAlert.skip) {
      return {
        title: 'Skip Match Assistant',
        subtitle:
          'If the Match Assistant is having issues and you’d like to skip it to just enter match results, you can skip the match assistant.  A member from each team will need to request the skip.  Once skipped, no stats will be recorded from the match, teams can not bring the match assistant back, and players will no longer be able to enter results. A coach from either team will then be able to enter all match results themselves.',
        actions: (
          <>
            <NxButton
              disabled={skipAssistantLoading || isLockStepForAlert}
              label="Cancel"
              onClick={(): void => closeStepAlert()}
              variant="text"
            />
            <NxButton
              label="Skip Assistant"
              loading={skipAssistantLoading || isLockStepForAlert}
              onClick={skipAssistant}
              variant="primary"
            />
          </>
        ),
        firebobMessage:
          skipAssistantLoading || isLockStepForAlert
            ? 'Waiting for your opponent to reset the game.'
            : undefined,
        firebobSubtitle:
          skipAssistantLoading || isLockStepForAlert
            ? 'Waiting on opponent'
            : undefined,
        onClose: (): void => closeStepAlert(),
        actionLoading: skipAssistantLoading,
      }
    }
    return {
      title: '',
      subtitle: '',
    }
  }

  return {
    getStepAlerts,
    openStepAlert,
    renderStepAlert,
  }
}

const context = React.createContext<UseMatchAssistantStepAlertsReturn>({
  getStepAlerts: (): AlertProps => ({
    title: '',
    subtitle: '',
  }),
  openStepAlert: noop,
  renderStepAlert: false,
})
const { Provider } = context

export const MatchAssistantStepAlertsProvider: React.FC<MatchAssistantStepAlertsProviderProps> = ({
  matchId,
  teamId,
  children,
}) => {
  const values = useMatchAssistantStepAlerts({ matchId, teamId })
  return <Provider value={values}>{children}</Provider>
}

export const useMatchAssistantStepAlertsContext = (): UseMatchAssistantStepAlertsReturn => {
  return useContext(context)
}
