import React from 'react'
import { UserRequiredFieldsAtAppLaunch } from '@plvs/graphql/generated'
import { InitAppState } from '@plvs/rally/containers/app'
import { Navigate } from 'react-router-dom'
import { Path } from '@plvs/const'

interface Props {
  missingFields: string[]
  isParent: boolean | undefined
}

export const MissingFieldsRedirect: React.FC<Props> = ({
  missingFields,
  isParent,
}) => {
  if (missingFields.includes(UserRequiredFieldsAtAppLaunch.Name) && isParent) {
    return <Navigate to={`${Path.ParentSpawnPoint}/about`} />
  }

  return (
    <>
      <InitAppState />
      <Navigate to={Path.MoreDetails} />
    </>
  )
}
