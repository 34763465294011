import React from 'react'
import { Route } from 'react-router-dom'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { ChildDetailsPage } from '../ChildDetailsPage'
import { ChildPlatformConnectionsPage } from './ChildPlatformConnectionsPage'
import { ChildLoginEditPage } from '../ChildLoginEditPage'
import { ChildEditInfoPage } from '../ChildEditInfoPage'

export const ChildDetailsRoute: React.FC = () => {
  return (
    <ApmRoutes>
      <Route element={<ChildDetailsPage />} path="/" />
      <Route element={<ChildLoginEditPage />} path="login-settings" />
      <Route element={<ChildEditInfoPage />} path="edit-info" />
      <Route
        element={<ChildPlatformConnectionsPage />}
        path="platform-connections"
      />
    </ApmRoutes>
  )
}
