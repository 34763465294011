import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const PaymentBadge = createSvgIcon(
  <path
    d="M9.6 0H6.4V3.2472C7.5752 3.3768 8.6648 3.7888 9.6 4.4144V0ZM4.8 0H1.6V4.4144C2.5352 3.7888 3.6248 3.3768 4.8 3.2472V0ZM5.6 16C8.6928 16 11.2 13.4928 11.2 10.4C11.2 7.3072 8.6928 4.8 5.6 4.8C2.5072 4.8 0 7.3072 0 10.4C0 13.4928 2.5072 16 5.6 16ZM4.62 9.1848L5.6 7.2L6.58 9.1848L8.7704 9.5024L7.1856 11.048L7.56 13.2296L5.6 12.2L3.6408 13.2296L4.0152 11.048L2.4304 9.5024L4.62 9.1848Z"
    fill="#F78823"
  />,
  'PaymentBadge',
  { viewBox: '0 0 16 16' }
)
