import { Box, makeStyles, useTheme } from '@material-ui/core'
import {
  useGetSchoolPreviewQuery,
  useGetSchoolVarsityAssociationsQuery,
} from '@plvs/graphql/generated/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Avatar } from '@plvs/rally/components/avatar'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes/colors'
import React, { useEffect } from 'react'
import { pluralize } from '@plvs/utils'
import { CA_CIF_SLUG } from '@plvs/const'
import { SchoolId } from './OnboardSchoolSelect.types'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    height: 'calc(100vh - 150px)',
    minHeight: '65em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  schoolName: {
    marginTop: theme.spacing(1),
  },
  schoolLocation: {
    marginTop: theme.spacing(0.5),
  },
}))

export const SchoolPreview: React.FC<{
  schoolId: SchoolId
  onNumCoaches: (numCoaches: number) => void
  setIsCifSchool: (boolean) => void
}> = ({ schoolId, onNumCoaches, setIsCifSchool }) => {
  const theme = useTheme()
  const classes = useStyles()

  const {
    data: schoolData,
    loading: selectedSchoolLoading,
  } = useGetSchoolPreviewQuery({
    variables: {
      schoolId: (schoolId as unknown) as string,
    },
  })

  const {
    data: varsityData,
    loading: varsityLoading,
  } = useGetSchoolVarsityAssociationsQuery({
    variables: { id: (schoolId as unknown) as string },
    skip: !schoolId,
  })

  useEffect(() => {
    if (schoolData?.school?.coaches) {
      onNumCoaches(schoolData.school.coaches.length)
    }
  }, [schoolData])

  useEffect(() => {
    if (!varsityLoading) {
      const isCifSchool = (varsityData?.school?.varsityAssociations ?? []).some(
        (va) => va?.slug === CA_CIF_SLUG
      )
      setIsCifSchool(isCifSchool)
    }
  }, [varsityData, varsityLoading])

  return (
    <WaitTillLoaded
      loading={selectedSchoolLoading || varsityLoading}
      showSpinnerWhileLoading
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height={theme.spacing(25)}
        justifyItems="center"
        // Using negative margin to subtract spacing normally added by
        // Questionaire box.
        marginBottom="-2em"
        mt={4.5}
        textAlign="center"
        width="100%"
      >
        <Avatar
          backgroundColor={Colors.Grey4}
          data-testid="OnboardSchoolSelect.SchoolPreview__Avatar"
          size="large"
          src={schoolData?.school?.logoUrl}
        />
        <NxTypography
          align="center"
          className={classes.schoolName}
          data-testid="OnboardSchoolSelect.SchoolPreview__Name"
          variant="h3"
        >
          {schoolData?.school?.name || ''}
        </NxTypography>
        <Box mt={1}>
          <NxTypography
            align="center"
            className={classes.schoolLocation}
            data-testid="OnboardSchoolSelect.SchoolPreview__Address"
            variant="h4"
          >
            {schoolData?.school?.city || ''}
            {schoolData?.school?.city && <>,&nbsp;</>}
            {schoolData?.school?.state || ''}&nbsp;
            {schoolData?.school?.zip || ''}
          </NxTypography>
        </Box>
        {schoolData && (
          <Box pt={2}>
            <NxTypography
              align="center"
              data-testid="OnboardSchoolSelect.SchoolPreview__Stats"
              variant="body1"
            >
              {pluralize(
                schoolData?.school?.coaches?.length ?? 0,
                'coach',
                'coaches'
              )}
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {pluralize(
                schoolData.school?.playerCount ?? 0,
                'player',
                'players'
              )}
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {pluralize(
                schoolData.school?.teams?.length ?? 0,
                'team',
                'teams'
              )}
            </NxTypography>
          </Box>
        )}
      </Box>
    </WaitTillLoaded>
  )
}
