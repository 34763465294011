import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { OnboardTellUsMore } from './OnboardTellUsMore'
import { TellUsMoreMiddleSchool } from './TellUsMoreMiddleSchool'

export const OnboardTellUsMoreWrapper: React.FC = () => {
  const flags = useFlags()

  const hasMiddleSchoolSupport = flags.middleSchoolSupport

  if (hasMiddleSchoolSupport) {
    return <TellUsMoreMiddleSchool />
  }

  return <OnboardTellUsMore />
}
