import { PhaseResultData } from './VsRegularStandings'
import { calculatePlayvsScore } from '../../components/table/column/standings'
import dayjs from '../../init/dayjs'
import { FALL_2022_DATE } from './standingsHelpers'

export interface StandingsCsvData {
  position: string
  teamName: string
  schoolName: string
  matchRecord: string
  playVsScore: string
  gameRecord: string
  seriesRecord?: string
}

export const standingsCsvHeaders = [
  { label: 'Position', key: 'position' },
  { label: 'Team Name', key: 'teamName' },
  { label: 'School Name', key: 'schoolName' },
  { label: 'Match Record', key: 'matchRecord' },
  { label: 'PlayVS Score', key: 'playVsScore' },
  { label: 'Game Record', key: 'gameRecord' },
]

export const standingsSeriesCsvHeaders = [
  { label: 'Position', key: 'position' },
  { label: 'Team Name', key: 'teamName' },
  { label: 'School Name', key: 'schoolName' },
  { label: 'Match Record', key: 'matchRecord' },
  { label: 'PlayVS Score', key: 'playVsScore' },
  { label: 'Series Record', key: 'seriesRecord' },
  { label: 'Game Record', key: 'gameRecord' },
]

export function convertStandingsDataForCsv(
  data: Array<PhaseResultData>
): StandingsCsvData[] {
  return data.map((phaseResult) => {
    const { cumulativeScore } = phaseResult
    const { cumulativeScoreOfOpposition } = phaseResult
    const isBeforeFall2022 = dayjs(phaseResult.startsAt).isBefore(
      dayjs(FALL_2022_DATE)
    )
    const playVsScore = isBeforeFall2022
      ? '-'
      : calculatePlayvsScore({
          cumulativeScore,
          cumulativeScoreOfOpposition,
        })
    const result: StandingsCsvData = {
      position: `${phaseResult.position}`,
      teamName: phaseResult.team?.name || '',
      schoolName: phaseResult.team?.school?.name || '',
      matchRecord: `${phaseResult.matchesWon} - ${phaseResult.matchesLost}`,
      playVsScore: `${playVsScore}`,
      gameRecord: `${phaseResult.gamesWon} - ${phaseResult.gamesLost}`,
    }
    if (phaseResult.seriesWon !== 0 || phaseResult.seriesLost !== 0) {
      result.seriesRecord = `${phaseResult.seriesWon} - ${phaseResult.seriesLost}`
    }
    return result
  })
}
