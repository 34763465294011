import React, { useState } from 'react'
import { useSnackbar } from 'notistack'

import { Attach } from '@playvs-inc/nexus-icons'
import { NxIconButton, NxButton, NxModal } from '@playvs-inc/nexus-components'

import {
  MatchSettleRequestFragment,
  useSubmitMatchSettleRequestAssetsLiveMutation,
} from '@plvs/graphql/generated'
import { ScreenshotSizeLimit } from '@plvs/rally/components/form/attach/attachHelpers'
import { useTheme } from '@material-ui/core'
import { DmrMatch, LobbyTeam } from '@plvs/utils'
import { AttachSeries } from './AttachSeries'
import {
  mapRequestAttachmentsToSeries,
  mapSeriesAttachmentsToPayload,
  SeriesAttachments,
} from './AttachScreenshotsButton.helpers'
import { LobbySeries } from '../../../assistant/useMatchAssistant.types'

interface AttachScreenshotsButtonProps {
  match: DmrMatch
  series?: LobbySeries[]
  matchSettleRequest: MatchSettleRequestFragment | null
  screenshotSizeLimit?: ScreenshotSizeLimit
  setMatchSettleRequest(matchSettleRequest: MatchSettleRequestFragment): void
}

export const AttachScreenshotsButton: React.FC<AttachScreenshotsButtonProps> = ({
  match,
  setMatchSettleRequest,
  screenshotSizeLimit = '3MB',
  matchSettleRequest,
}) => {
  const theme = useTheme()
  const [
    submitMatchSettleRequestAssetLivesMutation,
  ] = useSubmitMatchSettleRequestAssetsLiveMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [showDialog, setShowDialog] = useState(false)
  const [attachments, setAttachments] = useState<SeriesAttachments>(
    mapRequestAttachmentsToSeries(matchSettleRequest)
  )

  const series = match?.series ?? []
  const seriesResults = matchSettleRequest?.results?.seriesResults || []

  const openDialog = (): void => {
    setShowDialog(true)
  }

  const closeDialog = (): void => {
    setShowDialog(false)
  }

  const onClickSubmitScreenshots = async (): Promise<void> => {
    if (attachments) {
      try {
        const { data } = await submitMatchSettleRequestAssetLivesMutation({
          variables: {
            input: {
              matchSettleRequestId: matchSettleRequest?.id || '',
              seriesAssets: mapSeriesAttachmentsToPayload(attachments),
            },
          },
        })

        if (data?.submitMatchSettleRequestAssetsLive.matchSettleRequest) {
          setMatchSettleRequest(
            data?.submitMatchSettleRequestAssetsLive.matchSettleRequest
          )
        }

        enqueueSnackbar(`Screenshots submitted`, {
          variant: 'success',
        })
        closeDialog()
      } catch (err: unknown) {
        enqueueSnackbar(`Screenshots failed to submit`, {
          variant: 'error',
        })
      }
    }
  }

  const getPlayerName = (
    team?: LobbyTeam | null
  ): string | null | undefined => {
    return team && team.roster && team.roster[0]?.name
  }

  return (
    <>
      <NxIconButton
        data-cy="attachScreenshotsButton"
        icon={<Attach color={theme.palette.ColorTextAlt} />}
        label="Attach Screenshots"
        onClick={openDialog}
        variant="secondary"
      />
      <NxModal
        actions={
          <>
            <NxButton label="Cancel" onClick={closeDialog} variant="text" />
            <NxButton
              label="Submit"
              onClick={onClickSubmitScreenshots}
              variant="primary"
            />
          </>
        }
        onClose={closeDialog}
        open={showDialog}
        showTopRightClose
        size="large"
        title="Attach Screenshots"
      >
        {seriesResults.map((seriesResult) => (
          <AttachSeries
            key={seriesResult.id}
            attachments={attachments}
            getPlayerName={getPlayerName}
            screenshotSizeLimit={screenshotSizeLimit}
            series={series?.[(seriesResult.ordinalNumber ?? 0) - 1]}
            seriesResult={seriesResult}
            setAttachments={setAttachments}
          />
        ))}
      </NxModal>
    </>
  )
}
