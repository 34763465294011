import { GameStatus, MatchStatus } from '@plvs/graphql'

import {
  CompetitorCellProps,
  ScoreboardRow,
} from '@playvs-inc/nexus-components'
import { LobbyMatch, LobbyTeam, MatchAssistantMatch } from '@plvs/utils'

export type LobbyMatchResult = {
  id?: string | null
  metaseasonId?: string | null
  team?: LobbyTeam | null
  teamId?: string
  teamSide?: number | null
}

export interface NxScoreboardDataProp {
  leftCompetitor: CompetitorCellProps
  rightCompetitor: CompetitorCellProps
  gameWins: boolean[]
}

export const mapNonSeriesData = (
  match: MatchAssistantMatch,
  homeTeamMatchResults: LobbyMatchResult | undefined | null,
  awayTeamMatchResults: LobbyMatchResult | undefined | null,
  isYouthProgram: boolean
): ScoreboardRow[] => {
  const homeTeamId = homeTeamMatchResults?.team?.id
  const homeTeamSchoolLogo = homeTeamMatchResults?.team?.school?.logoUrl
  const homeTeamSchoolName = homeTeamMatchResults?.team?.school?.name

  const awayTeamId = awayTeamMatchResults?.team?.id
  const awayTeamSchoolLogo = awayTeamMatchResults?.team?.school?.logoUrl
  const awayTeamSchoolName = awayTeamMatchResults?.team?.school?.name

  const validGames =
    match?.games?.filter(
      (game) =>
        (match.status === MatchStatus.Forfeited &&
          game.status === GameStatus.Forfeited) ||
        [
          GameStatus.Settled,
          GameStatus.Completed,
          GameStatus.Reviewed,
        ].includes(game.status)
    ) ?? []

  const homeGameWins = validGames.filter(
    (game) =>
      game?.gameResults?.find(
        (gameResult) => gameResult.teamId === homeTeamMatchResults?.team?.id
      )?.placing === 1
  )

  const settleRequest = match?.matchSettleRequests?.[0]

  const gameResults =
    settleRequest?.results?.seriesResults?.[0]?.gameResults ?? []
  const homeRequestWins = gameResults.filter(
    (result) => result.winningTeamId === homeTeamId
  )
  const awayRequestWins = gameResults?.filter(
    (result) => result.winningTeamId === awayTeamId
  )

  const awayGameWins = validGames.filter(
    (game) =>
      game?.gameResults?.find(
        (gameResult) => gameResult.teamId === awayTeamMatchResults?.team?.id
      )?.placing === 1
  )

  const leftSide = {
    avatarUrl:
      !isYouthProgram && homeTeamSchoolLogo ? homeTeamSchoolLogo : undefined,
    id: homeTeamId ?? '',
  }

  const rightSide = {
    avatarUrl:
      !isYouthProgram && awayTeamSchoolLogo ? awayTeamSchoolLogo : undefined,
    id: awayTeamId ?? '',
  }

  const results =
    settleRequest?.results?.seriesResults?.[0]?.gameResults?.map(
      (result, ndx) => {
        const homeResult =
          result.winningTeamId === homeTeamId
            ? result.winningTeamScore
            : result.losingTeamScore
        const awayResult =
          result.winningTeamId === homeTeamId
            ? result.losingTeamScore
            : result.winningTeamScore

        return {
          title: `Game ${ndx + 1}`,
          showScore: true,
          isScoreFinal: true,

          leftSide: {
            ...leftSide,
            score: homeResult || 0,
          },
          rightSide: {
            ...rightSide,
            score: awayResult || 0,
          },
        }
      }
    ) ?? []

  return [
    {
      title: 'Final',
      leftSide: {
        title: homeTeamMatchResults?.team?.name ?? '??',
        subtitle:
          !isYouthProgram && homeTeamSchoolName
            ? homeTeamSchoolName
            : undefined,
        avatarUrl:
          !isYouthProgram && homeTeamSchoolLogo
            ? homeTeamSchoolLogo
            : undefined,
        id: homeTeamMatchResults?.team?.id ?? '',
        score: homeGameWins.length || homeRequestWins.length,
      },
      rightSide: {
        title: awayTeamMatchResults?.team?.name ?? '??',
        subtitle:
          !isYouthProgram && awayTeamSchoolName
            ? awayTeamSchoolName
            : undefined,
        avatarUrl:
          !isYouthProgram && awayTeamSchoolLogo
            ? awayTeamSchoolLogo
            : undefined,
        id: awayTeamMatchResults?.team?.id ?? '',
        score: awayGameWins.length || awayRequestWins.length,
      },
    },
    ...results,
  ]
}

export const mapSeriesData = (
  match: MatchAssistantMatch,
  homeTeamId: string,
  awayTeamId: string
): ScoreboardRow[] => {
  return (
    match?.series?.reduce((acc, { id, team1, team2 }, ndx) => {
      const validGames = match?.games?.filter((game) => {
        const gameWasPlayed =
          game.status === GameStatus.Settled ||
          game.status === GameStatus.Completed
        const matchAndGameWasForfeited =
          match.status === MatchStatus.Forfeited &&
          game.status === GameStatus.Forfeited
        return (
          game.seriesId === id && (gameWasPlayed || matchAndGameWasForfeited)
        )
      })

      // Skip unnecessary series
      if (!validGames?.length) {
        return acc
      }

      const homeTeam = team1?.id === homeTeamId ? team1 : team2
      const awayTeam = team1?.id === awayTeamId ? team1 : team2

      const homeGameWins =
        validGames?.filter(
          (game) =>
            game?.gameResults?.find(
              (gameResult) => gameResult.teamId === homeTeamId
            )?.placing === 1
        ) ?? []

      const awayGameWins =
        validGames?.filter(
          (game) =>
            game?.gameResults?.find(
              (gameResult) => gameResult.teamId === awayTeamId
            )?.placing === 1
        ) ?? []

      acc.push({
        title: `Series ${ndx + 1}`,
        leftSide: {
          title: homeTeam?.roster?.[0]?.name ?? '??',
          subtitle: homeTeam?.roster?.[0]?.publisherAccountName ?? '',
          avatarUrl: homeTeam?.roster?.[0].avatarUrl ?? undefined,
          id: homeTeam?.roster?.[0].id ?? '',
          score: homeGameWins.length,
        },
        rightSide: {
          title: awayTeam?.roster?.[0]?.name ?? '??',
          subtitle: awayTeam?.roster?.[0]?.publisherAccountName ?? '',
          avatarUrl: awayTeam?.roster?.[0].avatarUrl ?? undefined,
          id: awayTeam?.roster?.[0].id ?? '',
          score: awayGameWins.length,
        },
      })

      return acc
    }, [] as ScoreboardRow[]) ?? []
  )
}

export const getNumberOfGames = (
  hasSeries: boolean,
  match: LobbyMatch,
  data: NxScoreboardDataProp[]
): number => {
  const bestOf: number | undefined | null = hasSeries
    ? match?.series?.[0]?.bestOf
    : match?.bestOf
  const defaultBestOf: number = hasSeries
    ? data.reduce(
        (maxGames, row) =>
          row.gameWins.length > maxGames ? row.gameWins.length : maxGames,
        0
      )
    : data[0].gameWins.length

  return bestOf ?? defaultBestOf
}
