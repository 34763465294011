import { Path } from '@plvs/const'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { NavigateFunction } from 'react-router'

export const handleChildAccountError = ({
  enqueueSnackbar,
  navigate,
  userId,
}: {
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
  navigate: NavigateFunction
  userId: string
}): void => {
  enqueueSnackbar(
    'Something went wrong, please try again or contact support.',
    {
      variant: 'error',
      autoHideDuration: 2000,
    }
  )

  // replace prevents user from hitting back button back to page
  navigate(`${Path.App}/child/${userId}`, { replace: true })
}
