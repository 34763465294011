import React from 'react'
import { makeStyles, Tooltip, useTheme } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

import { Box } from '@plvs/respawn/features/layout'
import { convertUSD } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  EARLY_BIRD_SEAT_PRICE,
  REGULAR_SEAT_PRICE,
} from '../EnrollmentPlanSelection/constants'
import { isEarlyBird22 } from '../EnrollmentPlanSelection/plan-cards/helpers'

interface EnrollmentCardProps {
  neededPasses: number
  isBoxShadow?: boolean
  seasonName: string
  isUnlimited: boolean
}

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.ColorIconBase,
    fontSize: 17,
    verticalAlign: ' text-bottom',
    marginLeft: theme.spacing(1),
  },
  discount: {
    color: theme.palette.ColorTextSuccess,
  },
  teamDetails: {
    textTransform: 'capitalize',
  },
  waivedText: {
    color: theme.palette.ColorTextAlt2,
  },
  passCounts: {
    marginBottom: '1rem',
  },
}))

export const EnrollmentPassTotals: React.FC<EnrollmentCardProps> = ({
  neededPasses,
  isBoxShadow,
  seasonName,
  isUnlimited,
}) => {
  const isEmptyState = neededPasses === 0
  const classes = useStyles({
    isBoxShadow: !isEmptyState ? isEmptyState : isBoxShadow,
  })
  const theme = useTheme()

  const SEAT_PRICE_CENTS = isEarlyBird22()
    ? EARLY_BIRD_SEAT_PRICE * 100
    : REGULAR_SEAT_PRICE * 100

  return (
    <Box
      boxShadow={
        isBoxShadow && !isEmptyState
          ? theme.mixins.boxShadow.elevation1
          : 'none'
      }
      px={3}
      py={3}
    >
      <NxTypography variant="h3">Season Pass Billing</NxTypography>
      {isEmptyState ? (
        <Box data-testid="empty-state" pt={3} textAlign="center">
          <NxTypography color="textSecondary">
            Select enrollable teams & substitute players to enroll for{' '}
            {seasonName}
          </NxTypography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          my={2}
        >
          <Box
            className={classes.passCounts}
            display="flex"
            justifyContent="space-between"
          >
            <NxTypography className={classes.teamDetails} variant="body1">
              Estimated Players Passes
            </NxTypography>
            <NxTypography data-testid="player-passes" variant="body1">
              {neededPasses}
            </NxTypography>
          </Box>
          <Box
            borderBottom={`1px solid ${theme.palette.BorderLight}`}
            className={classes.passCounts}
          />
          <Box
            className={classes.passCounts}
            display="flex"
            justifyContent="space-between"
          >
            <NxTypography className={classes.teamDetails} variant="body1">
              Subtotal
            </NxTypography>
            <NxTypography data-testid="subtotal" variant="body1">
              {neededPasses}
            </NxTypography>
          </Box>
          <Box
            borderBottom={`4px solid ${theme.palette.BorderLight}`}
            className={classes.passCounts}
          />
          <Box
            className={classes.passCounts}
            display="flex"
            justifyContent="space-between"
          >
            <NxTypography className={classes.teamDetails} variant="body1">
              Total Estimated Player Passes
              <Tooltip
                arrow
                placement="top"
                title={
                  isUnlimited
                    ? `Since you have the Unlimited plan, 
                you can add as many player passes as you'd like without incurring extra cost.`
                    : `Billable passes count towards your initial seat plan amount. Any additional passes will be charged ${convertUSD(
                        SEAT_PRICE_CENTS
                      )} per pass. Your estimated total is ${convertUSD(
                        SEAT_PRICE_CENTS * neededPasses
                      )}`
                }
              >
                <InfoOutlined className={classes.infoIcon} fontSize="small" />
              </Tooltip>
            </NxTypography>
            <NxTypography data-testid="billable-passes" variant="body1">
              {neededPasses}
            </NxTypography>
          </Box>
          <Box lineHeight={1} textAlign="center">
            <NxTypography className={classes.waivedText} variant="body3">
              An itemized breakdown of your costs will be displayed on your
              invoice
            </NxTypography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
