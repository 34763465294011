import React from 'react'
import { Path } from '@plvs/const'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { ManageTeamsEnrollmentCardContainer } from './CoachEnrollmentCardContainer'

export const EnrollmentCard: React.FC = () => {
  const {
    esportSlug,
    metaseason,
    loading: seasonLoading,
  } = useSchoolLeagueInfoContext()

  const selectedMetaseasonId = metaseason?.id ?? ''
  const selectedMetaseasonName = metaseason?.name ?? ''
  const selectedEsportSlug = esportSlug ?? null
  const title = `${selectedMetaseasonName} Leagues`
  const path = `${Path.Enrollment}/${selectedMetaseasonId}`

  return (
    <WaitTillLoaded
      loading={seasonLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        <ManageTeamsEnrollmentCardContainer
          esportSlug={selectedEsportSlug}
          metaseason={metaseason}
          path={path}
          title={title}
        />
      </>
    </WaitTillLoaded>
  )
}
