/* istanbul ignore file */
import { MutationUpdaterFn } from '@apollo/client/core'

export const generateDeleteUpdateFn = (
  id: string,
  typeName: string
): MutationUpdaterFn => {
  return (cache): void => {
    const normalizedId = cache.identify({ id, __typename: typeName })
    cache.evict({ id: normalizedId })
    cache.gc()
  }
}

export const generateCreateUpdateFn = (
  parentId: string,
  parentTypeName: string,
  fieldName: string,
  responseFieldName: string
): MutationUpdaterFn => {
  return (cache, response): void => {
    const normalizedId = cache.identify({
      id: parentId,
      __typename: parentTypeName,
    })
    cache.modify({
      id: normalizedId,
      fields: {
        [fieldName]: (prev): any[] => {
          return [...prev, response.data?.[responseFieldName]]
        },
      },
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mockUseForm = () =>
  jest.mock('react-hook-form', () => ({
    useForm: (): any => ({
      handleSubmit: (): any => jest.fn(),
      register: jest.fn(),
      control: {
        register: jest.fn(),
        unregister: jest.fn(),
        getFieldState: jest.fn(),
        _names: {
          array: new Set('test'),
          mount: new Set('test'),
          unMount: new Set('test'),
          watch: new Set('test'),
          focus: 'test',
          watchAll: false,
        },
        _subjects: {
          watch: jest.fn(),
          array: jest.fn(),
          state: jest.fn(),
        },
        _getWatch: jest.fn(),
        _formValues: ['test'],
        _defaultValues: ['test'],
      },
      getValues: (): any => {
        return []
      },
      setValue: () => jest.fn(),
      formState: () => jest.fn(),
      watch: () => jest.fn(),
      errors: { newPassword: null, confirmPassword: null },
    }),
    Controller: (): any => [],
    useSubscribe: (): any => ({
      r: { current: { subject: { subscribe: (): any => jest.fn() } } },
    }),
  }))
