import dayjs from 'dayjs'
import { sortBy, join, head } from 'ramda'
import { compact } from 'ramda-adjunct'
import dayjsUtc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(dayjsUtc)
dayjs.extend(tz)

// types

// conversion of ms to other format
type TimeFn = (x: number) => number

export enum RelativeTiming {
  Future = 'future',
  Present = 'present',
  Past = 'past',
}

// consts

const msToSeconds: TimeFn = (ms) => ms / 1000
const msToMinutes: TimeFn = (ms) => msToSeconds(ms) / 60
const msToHours: TimeFn = (ms) => msToMinutes(ms) / 60
const msToDays: TimeFn = (ms) => msToHours(ms) / 24

const secondsToMS: TimeFn = (s) => s * 1000
const minutesToMS: TimeFn = (m) => secondsToMS(m * 60)
const hourToMS: TimeFn = (h) => minutesToMS(h * 60)
const daysToMS: TimeFn = (d) => hourToMS(d * 24)

// utils

export const getTime = (date?: string | null): number =>
  date ? Date.parse(date) : new Date().getTime()

export const getTimeFromEndsAtObj = ({
  endsAt,
}: {
  endsAt?: string | null
}): number => getTime(endsAt)

export const getTimeFromStartsAtObj = ({
  startsAt,
}: {
  startsAt?: string | null
}): number => getTime(startsAt)

export const getTimeFromScheduledStartsAtObj = ({
  scheduledStartsAt,
}: {
  scheduledStartsAt?: string | null
}): number => getTime(scheduledStartsAt)

export function sortByMaybeEndsAt<T extends { endsAt?: string | null }>(
  list: readonly T[]
): T[] {
  return sortBy(getTimeFromEndsAtObj, list)
}

export function sortByStartsAt<T extends { startsAt: string }>(
  list: readonly T[]
): T[] {
  return sortBy(getTimeFromStartsAtObj, list)
}

export function sortByMaybeStartsAt<T extends { startsAt?: string | null }>(
  list: readonly T[]
): T[] {
  return sortBy(getTimeFromStartsAtObj, list)
}

export function sortByMaybeScheduledStartsAt<
  T extends { scheduledStartsAt?: string | null }
>(list: readonly T[]): T[] {
  return sortBy(getTimeFromScheduledStartsAtObj, list)
}

export function isFuture({ startsAt }: { startsAt?: string | null }): boolean {
  const today = getTime()
  return startsAt ? getTime(startsAt) > today : false
}

export function isCurrent({
  startsAt,
  endsAt,
}: {
  startsAt?: string | null
  endsAt?: string | null
}): boolean {
  const today = dayjs()
  return startsAt && endsAt
    ? today.isBetween(dayjs(startsAt), dayjs(endsAt))
    : false
}

export function isPast({ endsAt }: { endsAt?: string | null }): boolean {
  const today = getTime()
  return endsAt ? getTime(endsAt) < today : false
}

export function findFutureItemId(
  items: { id: string; startsAt?: string | null }[] | []
): string | undefined {
  const pastItem = items.find(isFuture)
  return pastItem?.id
}

export function findCurrentItemId(
  items: { id: string; startsAt?: string | null; endsAt?: string | null }[] | []
): string | undefined {
  const activeItem = items.find(isCurrent)
  return activeItem?.id
}

export function findPastItemId(
  items: { id: string; endsAt?: string | null }[] | []
): string | undefined {
  const pastItem = items.find(isPast)
  return pastItem?.id
}

export function findCurrentPastFutureItemId(
  items: { id: string; startsAt?: string | null; endsAt?: string | null }[] | []
): string | undefined {
  return (
    findCurrentItemId(items) || findPastItemId(items) || findFutureItemId(items)
  )
}

const pad = (n: number): string => `${n}`.padStart(2, '0')
export const formatDuration = ({
  hideSeconds,
  ms,
}: {
  hideSeconds?: boolean
  ms: number
}): string => {
  const days = Math.floor(msToDays(ms))
  const daysMS = daysToMS(days)
  const hours = Math.floor(msToHours(ms - daysMS))
  const hoursMS = hourToMS(hours)
  const minutes = Math.floor(msToMinutes(ms - daysMS - hoursMS))
  const minutesMS = minutesToMS(minutes)
  const seconds = Math.floor(msToSeconds(ms - daysMS - hoursMS - minutesMS))
  return join(
    ' ',
    compact([
      days ? `${pad(days)}d` : null,
      `${pad(hours)}h`,
      `${pad(minutes)}m`,
      days || hideSeconds ? null : `${pad(seconds)}s`,
    ])
  )
}

export const getNow = (args?: { minuteDifference?: number }): string => {
  const minuteDifference = args?.minuteDifference
  const now = new Date()

  if (minuteDifference) {
    now.setMinutes(now.getMinutes() + minuteDifference)
    now.setSeconds(0)
    now.setMilliseconds(0)
  }

  return now.toISOString()
}

export const sortStartsAtClosestToNow = <
  T extends { startsAt: string | null | undefined }
>({
  list,
}: {
  list: T[]
}): T | undefined => {
  const now = dayjs()
  const sortedList = list.sort((a, b) => {
    const aDate = dayjs(a.startsAt)
    const bDate = dayjs(b.startsAt)
    return aDate.diff(now, 'days') < bDate.diff(now, 'days') ? 1 : -1
  })
  return head(sortedList)
}

export const getDateWithCorrectTimeZone = (input: string): dayjs.Dayjs =>
  dayjs(input).tz(dayjs.tz.guess())

export const convertSecondsToMinutes = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)}`
}
