import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import React from 'react'
import { formatISO, isBefore } from 'date-fns'
import { toDate, format } from 'date-fns-tz'
import { MatchRescheduleRequestStatus } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { AccessTime } from '@material-ui/icons'
import { head } from 'ramda'
import { NxTypography } from '@playvs-inc/nexus-components'
import { RescheduleMatch } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'

const useStyles = makeStyles((theme) => ({
  item: {
    color: theme.palette.ColorTextBase,
    textDecoration: 'none',
  },

  renderOption: {
    color: theme.palette.ColorTextBase,
  },
}))

export const RescheduleUpdate: React.FC<{
  onChange(name: string): void
  match: RescheduleMatch
  acceptedDate: string | undefined
}> = ({ onChange, match, acceptedDate }) => {
  const request = head(
    (match?.matchRescheduleRequests ?? []).filter(
      (o) => o.status === MatchRescheduleRequestStatus.Pending
    )
  )
  const { item, renderOption } = useStyles()

  const renderOptions =
    request &&
    request.proposedTimes &&
    request.proposedTimes.map((datetime) => {
      const date = toDate(datetime)
      const label = format(date, "EEEE, MMM do, yyyy 'at' h:mma zz")
      const isChecked = formatISO(date) === acceptedDate
      const isBeforeNow = isBefore(date, new Date())
      return isBeforeNow ? (
        <Box key={datetime} className={renderOption} ml={2}>
          <Box display="flex" width="100%">
            <Box mr={1}>
              <AccessTime />
            </Box>
            <NxTypography
              color="inherit"
              component="span"
              style={{ textDecoration: 'line-through' }}
              variant="body1"
            >
              {label}
            </NxTypography>
          </Box>
        </Box>
      ) : (
        <Box key={datetime} display="flex" ml={2} width="100%">
          <FormControlLabel
            key={label}
            control={<Checkbox checked={isChecked} value="acceptedTime" />}
            label={
              <Box display="flex" flex={1} flexDirection="row">
                <NxTypography
                  className={item}
                  component="span"
                  data-cy="proposedReschedule"
                  variant="body1"
                >
                  {label}
                </NxTypography>
              </Box>
            }
            onChange={(): void =>
              onChange(isChecked ? '' : formatISO(toDate(datetime)))
            }
          />
        </Box>
      )
    })
  const isChecked = acceptedDate === 'counter'
  const otherOption = (
    <Box display="flex" ml={2} width="100%">
      <FormControlLabel
        control={<Checkbox checked={isChecked} value="acceptedTime" />}
        label={
          <Box display="flex" flex={1} flexDirection="row">
            <NxTypography
              className={item}
              component="span"
              data-cy="suggestAnotherSetOfTimes"
              variant="body1"
            >
              I&apos;d like to suggest another set of times
            </NxTypography>
          </Box>
        }
        onChange={(): void => onChange(isChecked ? '' : 'counter')}
      />
    </Box>
  )
  return (
    <>
      {renderOptions}
      {otherOption}
    </>
  )
}
