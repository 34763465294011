import { useGetDmrMatchQuery } from '@plvs/graphql/generated'
import { MatchReportCard } from '@plvs/rally/features/match/dynamic/matchReportCard/MatchReportCard'
import React from 'react'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

interface MatchReportCardWrapperProps {
  matchId: string
  myTeamId: string
  refetchCallback(): Promise<void>
  canManageMatch: boolean
}

export const MatchReportCardWrapper: React.FC<MatchReportCardWrapperProps> = ({
  matchId,
  myTeamId,
  refetchCallback,
  canManageMatch,
}) => {
  const { data, loading, error, refetch: refetchGetDmr } = useGetDmrMatchQuery({
    variables: {
      matchId,
    },
  })

  return (
    <WaitTillLoaded error={error} loading={loading} showSpinnerWhileLoading>
      {data && (
        <MatchReportCard
          canManageMatch={canManageMatch}
          isSeriesMatch={(data?.match?.seriesBestOf ?? 0) > 1}
          match={data?.match}
          matchAssistantEnabled={false}
          myTeamId={myTeamId}
          refetchCallback={(): void => {
            refetchCallback()
            refetchGetDmr()
          }}
        />
      )}
    </WaitTillLoaded>
  )
}

export default MatchReportCardWrapper
