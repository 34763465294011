import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  autocomplete: {
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
  },
}))
