import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { SchoolSearchResult } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  school: Pick<
    SchoolSearchResult,
    'schoolId' | 'name' | 'city' | 'state' | 'logoUrl'
  >
}

export const CoachSchoolLookUpOption: React.FC<Props> = ({ school }) => {
  const classes = useStyles()

  return (
    <Box alignItems="center" display="flex">
      <NxUserAvatar
        avatarUrl={school?.logoUrl}
        className={classes.avatar}
        hashId={school.schoolId}
        size={NxAvatarSize.XS}
      />
      <div>
        <NxTypography variant="body3">{school?.name}</NxTypography>
        <NxTypography variant="body5">
          {school?.city}, {school?.state}
        </NxTypography>
      </div>
    </Box>
  )
}
