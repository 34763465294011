import { head } from 'ramda'
import React from 'react'
import { UsStateCode } from '@plvs/graphql'
import { FilterButton } from './FilterButton'
import { Option } from './filterHelpers'
import { useOption } from './useOption'

export type FilterStateAbbr = { id: UsStateCode }

const allStates = Object.values(UsStateCode)

const defaultOption: Option<FilterStateAbbr> = {
  id: 'default',
  name: 'State',
  values: [],
}

const getStateAbbrevOptions: (
  stateAbbrevs: UsStateCode[]
) => Option<FilterStateAbbr>[] = (stateAbbrevs) =>
  stateAbbrevs.map((stateAbbrev) => ({
    id: stateAbbrev,
    name: stateAbbrev,
    values: [{ id: stateAbbrev }],
  }))

const allOptions = [defaultOption, ...getStateAbbrevOptions(allStates)]

export function useStateAbbr(): {
  selectedStateAbbr?: UsStateCode
  stateAbbrFilter: React.ReactElement
} {
  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption,
  })

  return {
    selectedStateAbbr: head(option.values)?.id,
    stateAbbrFilter: (
      <FilterButton<Option<FilterStateAbbr>>
        id="state-filter"
        option={option}
        options={options}
        setOption={setOption}
      />
    ),
  }
}
