import React, { useState } from 'react'
import { NxButton, NxTypography, NxModal } from '@playvs-inc/nexus-components'

import { useStyles } from './PostMatchSurveyOverlay.styles'

interface PostMatchSurveyOverlayProps {
  setShowPostMatchSurveySuccessStep(
    showPostMatchSurveySuccessStep: boolean
  ): void
}

export const PostMatchSurveySuccessStep: React.FC<PostMatchSurveyOverlayProps> = ({
  setShowPostMatchSurveySuccessStep,
}) => {
  const classes = useStyles()

  const [isDialogOpen, setIsDialogOpen] = useState(true)

  const closeDialog = (): void => {
    setShowPostMatchSurveySuccessStep(false)
    setIsDialogOpen(false)
  }

  return (
    <NxModal
      actions={<NxButton label="Close" onClick={closeDialog} variant="text" />}
      contentClassName={classes.contentClass}
      onClose={closeDialog}
      open={isDialogOpen}
      showTopRightClose
      size="large"
      title="We received your feedback!"
      titleClassName={classes.titleClass}
    >
      <NxTypography>
        Our support team will review your feedback and match. Please allow up to
        one week for a response. For immediate support, you may always reach out
        directly using the support button. Thank you!
      </NxTypography>
    </NxModal>
  )
}
