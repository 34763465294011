import React from 'react'

import { EmptyPage } from '@plvs/rally/components/empty'
import { PlayerPhaseTopPerformers } from '@plvs/rally/containers/match'
import { PlayerPhaseStats } from '@plvs/rally/containers/stats'
import { Phase } from '@plvs/graphql'
import { NoEntityId } from '@plvs/const'

interface Props {
  metaseasonId: string
  phase: Pick<Phase, 'id' | 'endsAt'>
  teamId: string
}

export const UserStatsWrapper = ({
  metaseasonId,
  phase,
  teamId,
}: Props): React.ReactElement => {
  const phaseId = phase.id

  if (!phaseId || !teamId || teamId === NoEntityId.Team) {
    return (
      <EmptyPage showIcon subtitle="No stats available, check back later!" />
    )
  }

  return (
    <>
      <PlayerPhaseTopPerformers
        metaseasonId={metaseasonId}
        phase={phase}
        teamId={teamId}
      />
      <div style={{ marginTop: 24 }} />
      <PlayerPhaseStats
        metaseasonId={metaseasonId}
        phase={phase}
        teamId={teamId}
      />
    </>
  )
}
