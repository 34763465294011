import React from 'react'

import {
  NxButton,
  NxModal,
  NxMatchDetailsCluster,
} from '@playvs-inc/nexus-components'

import { ScrimmageRequestQuery } from '@plvs/graphql/generated'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

interface JoinScrimmageStepCProps {
  requestingTeamName: string
  opposingTeamName: string
  loading: boolean
  open: boolean
  onClose(): void
  scrimmageRequest: ScrimmageRequestQuery['scrimmageRequest'] | undefined
}

export const JoinScrimmageStepC: React.FC<JoinScrimmageStepCProps> = ({
  requestingTeamName,
  opposingTeamName,
  scrimmageRequest,
  open,
  onClose,
  loading,
}) => {
  return (
    <NxModal
      actions={<NxButton label="Done" onClick={onClose} variant="primary" />}
      onClose={onClose}
      open={open}
      showTopRightClose
      size="small"
      title="Scrimmage Joined"
    >
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <NxMatchDetailsCluster
          bestOf={scrimmageRequest?.bestOf ?? undefined}
          esport={scrimmageRequest?.esport?.slug ?? ''}
          esportName={scrimmageRequest?.esport?.name ?? ''}
          showAvatar
          teamAName={requestingTeamName}
          teamBName={opposingTeamName}
          timestamp={scrimmageRequest?.startsAt ?? ''}
        />
      </WaitTillLoaded>
    </NxModal>
  )
}
