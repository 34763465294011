import React from 'react'
import { makeStyles, Radio, RadioProps } from '@material-ui/core'
import { appendClasses } from '@plvs/utils'

const useStyles = makeStyles((theme) => ({
  radio: {
    '& svg': {
      height: '20px',
      width: '20px',
    },
    '&.Mui-checked': {
      color: theme.palette.ColorTextBase,
      opacity: '85%',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      color: theme.palette.ColorTextBase,
      backgroundColor: 'transparent',
      opacity: '85%',
    },
    '&:disabled': {
      color: theme.palette.ColorTextBase,
      opacity: '10%',
    },
  },
}))

export const RadioButton: React.FC<RadioProps> = ({
  checked,
  onChange,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Radio
      checked={checked}
      className={appendClasses(classes.radio, className)}
      onChange={onChange}
      {...rest}
    />
  )
}
