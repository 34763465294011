import React from 'react'

import { Maybe, User, UserRoleStatusName } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'

export type UserStatusProps =
  | Maybe<Pick<User, 'id' | 'schoolRoleStatus'>>
  | undefined

export const UserStatus: React.FC<UserStatusProps> = ({ schoolRoleStatus }) => {
  const status = schoolRoleStatus?.find(
    (role) =>
      role.status === UserRoleStatusName.Graduated ||
      role.status === UserRoleStatusName.Transferred
  )?.status

  return (
    <NxTypography>
      {status && typeof status === 'string'
        ? `${status.charAt(0).toUpperCase()}${status.slice(1)}`
        : ''}
    </NxTypography>
  )
}
