import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const RegionalNationalWhite = createSvgIcon(
  <>
    <path
      d="M12 24C9.62663 24 7.30655 23.2962 5.33316 21.9776C3.35977 20.6591 1.8217 18.7849 0.913451 16.5922C0.00519943 14.3995 -0.232441 11.9867 0.230582 9.65892C0.693605 7.33115 1.83649 5.19295 3.51472 3.51472C5.19295 1.83649 7.33115 0.693605 9.65892 0.230582C11.9867 -0.232441 14.3995 0.00519943 16.5922 0.913451C18.7849 1.8217 20.6591 3.35977 21.9776 5.33316C23.2962 7.30655 24 9.62663 24 12C23.9962 15.1814 22.7307 18.2315 20.4811 20.4811C18.2315 22.7307 15.1814 23.9962 12 24ZM12 1.6864C9.96017 1.6864 7.96614 2.29129 6.27007 3.42456C4.57401 4.55783 3.25209 6.1686 2.47148 8.05316C1.69087 9.93772 1.48663 12.0114 1.88458 14.0121C2.28253 16.0127 3.26481 17.8504 4.70719 19.2928C6.14957 20.7352 7.98728 21.7175 9.98792 22.1154C11.9886 22.5134 14.0623 22.3091 15.9468 21.5285C17.8314 20.7479 19.4422 19.426 20.5754 17.7299C21.7087 16.0339 22.3136 14.0398 22.3136 12C22.3102 9.26571 21.2225 6.64437 19.2891 4.71093C17.3556 2.77749 14.7343 1.68979 12 1.6864Z"
      fill="white"
    />
    <path
      d="M5.3407 11.0209C5.17802 10.6459 5.04012 10.2605 4.9279 9.86734C4.90897 9.80955 4.89558 9.75008 4.8879 9.68975C4.8735 9.59855 4.9679 9.51695 5.0895 9.51695H6.0943C6.0735 9.31855 6.0527 9.11695 6.0351 8.90895C6.0351 8.82575 6.0207 8.74895 6.0143 8.66895H3.9999C3.8287 8.66895 3.6943 8.77935 3.7087 8.90895C3.72975 9.15557 3.76501 9.40078 3.8143 9.64335C4.1167 10.9233 4.6143 12.1473 5.6271 13.1633C6.06461 13.6095 6.59277 13.9565 7.1759 14.1809C7.0159 13.7921 6.8719 13.3649 6.7359 12.9009C6.0703 12.3921 5.6799 11.7201 5.3407 11.0209Z"
      fill="white"
    />
    <path
      d="M20.1904 8.66895H18.2849C18.2593 8.95055 18.2321 9.23215 18.1985 9.51695H19.0977C19.2209 9.51695 19.3153 9.59855 19.3009 9.68975C19.2924 9.74993 19.279 9.80933 19.2609 9.86735C19.1486 10.2605 19.0107 10.6459 18.8481 11.0209C18.5281 11.6737 18.1697 12.301 17.5825 12.7986C17.452 13.2554 17.2976 13.7051 17.1201 14.1458C17.6604 13.9205 18.15 13.5892 18.5601 13.1714C19.5729 12.1474 20.0688 10.9313 20.3712 9.65135C20.4219 9.40895 20.4577 9.1637 20.4784 8.91695C20.496 8.77935 20.3616 8.66895 20.1904 8.66895Z"
      fill="white"
    />
    <path
      d="M17.16 6.19332C16.5274 5.88479 15.8675 5.63572 15.1888 5.44932C12.4896 4.79972 9.69124 4.85092 7.13765 6.19332C6.74725 6.39972 6.57445 6.71972 6.60165 7.23652C6.63365 7.83172 6.68965 8.29732 6.73605 8.84932C6.96645 11.5789 7.78725 15.5245 10.096 17.0461C10.5072 17.3117 10.96 17.5037 11.4 17.7325C11.52 18.4125 11.0224 19.6525 9.42564 20.5613C11.1645 21.0933 13.0228 21.0933 14.7616 20.5613C13.024 19.8605 12.776 18.4253 12.8832 17.7309C13.0224 17.6605 13.1856 17.6029 13.3232 17.5421C14.1317 17.2081 14.8325 16.6579 15.3488 15.9517C17.1376 13.4941 17.4912 10.2557 17.6896 7.24132C17.7216 6.73572 17.5664 6.37412 17.16 6.19332ZM14.8576 10.8509C14.8595 11.3912 14.7011 11.9199 14.4023 12.37C14.1035 12.8202 13.6779 13.1715 13.1793 13.3796C12.6807 13.5876 12.1316 13.6431 11.6014 13.5388C11.0713 13.4346 10.584 13.1754 10.2013 12.794C9.81864 12.4127 9.55772 11.9263 9.45162 11.3966C9.34551 10.8668 9.39901 10.3175 9.60532 9.81818C9.81163 9.31885 10.1615 8.89198 10.6106 8.59162C11.0597 8.29126 11.5878 8.13092 12.128 8.13092V9.27012L14.8912 8.15012L13.6656 10.8509H14.8576Z"
      fill="white"
    />
  </>,
  'RegionalNational-White',
  { viewBox: '0 0 24 24' }
)
