import React from 'react'
import { uniqBy } from 'ramda'
import { EsportSlug, SchoolLeagueEnrollmentStatus } from '@plvs/graphql'
import { NxIconButton } from '@playvs-inc/nexus-components'
import { Menu } from '@material-ui/core'
import clsx from 'clsx'
import { Filters } from '@playvs-inc/nexus-icons'
import { useStyles } from '../styles'
import { FilterLeague, getLabel } from './leagueFilterHelpers'
import { LeagueFilterSubgroup } from './LeagueFilterSubgroup'

interface Props {
  league?: FilterLeague
  leagues: FilterLeague[]
  setLeague: (id: string) => void
  /* Use the league's esport name instead of display name */
  showEsportName?: boolean
  /* Use a league's name instead of display name */
  showLeagueName?: boolean
  /* Truncate the league name text up to 40chars */
  shouldTruncateName?: boolean
  showUniqueLeaguesByEsport?: boolean
  className?: string
}

const defaultLeague = {
  id: '',
  name: 'League',
  displayName: 'League',
  esport: {
    id: '',
    name: '',
    slug: EsportSlug.LeagueOfLegends,
  },
  enrollmentStatus: SchoolLeagueEnrollmentStatus.Available,
}

export const LeagueFilter: React.FC<Props> = ({
  league,
  leagues,
  setLeague,
  showEsportName = false,
  showLeagueName = false,
  shouldTruncateName = false,
  showUniqueLeaguesByEsport = false,
  className,
}) => {
  const selectedLeague = league ?? defaultLeague

  const filteredLeagues = showUniqueLeaguesByEsport
    ? uniqBy((leagueData) => leagueData?.esport?.slug, leagues)
    : leagues

  const enrolledLeagues = filteredLeagues
    .filter((l) => l?.enrollmentStatus === SchoolLeagueEnrollmentStatus.Active)
    .sort((a, b) => (a?.name).localeCompare(b?.name))

  const pastEnrolledLeagues = filteredLeagues
    .filter((l) => l?.enrollmentStatus === SchoolLeagueEnrollmentStatus?.Past)
    .sort((a, b) => (a?.name).localeCompare(b?.name))

  const availableLeagues = filteredLeagues
    .filter(
      (l) => l?.enrollmentStatus === SchoolLeagueEnrollmentStatus?.Available
    )
    .sort((a, b) => (a?.name).localeCompare(b?.name))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const classes = useStyles({ isMenuOpen: !!anchorEl })
  const disabled = leagues.length === 0

  const defaultLabel = getLabel({
    league: selectedLeague,
    showEsportName,
    shouldTruncateName,
    showLeagueName,
  })

  return (
    <>
      <NxIconButton
        className={clsx(classes.filterButton, className)}
        data-cy={defaultLabel}
        disabled={disabled}
        icon={<Filters />}
        label={defaultLabel}
        onClick={handleClick}
        variant="secondary"
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.menu}
        getContentAnchorEl={null}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {enrolledLeagues.length > 0 && (
          <LeagueFilterSubgroup
            handleClose={handleClose}
            leagues={enrolledLeagues}
            selectedLeague={selectedLeague}
            setLeague={setLeague}
            shouldTruncateName={shouldTruncateName}
            showEsportName={showEsportName}
            showLeagueName={showLeagueName}
            title="Enrolled Leagues"
          />
        )}
        {pastEnrolledLeagues.length > 0 && (
          <LeagueFilterSubgroup
            handleClose={handleClose}
            leagues={pastEnrolledLeagues}
            selectedLeague={selectedLeague}
            setLeague={setLeague}
            shouldTruncateName={shouldTruncateName}
            showEsportName={showEsportName}
            showLeagueName={showLeagueName}
            title="Past Enrolled Leagues"
          />
        )}
        {availableLeagues.length > 0 && (
          <LeagueFilterSubgroup
            handleClose={handleClose}
            leagues={availableLeagues}
            selectedLeague={selectedLeague}
            setLeague={setLeague}
            shouldTruncateName={shouldTruncateName}
            showEsportName={showEsportName}
            showLeagueName={showLeagueName}
            title="Available Leagues"
          />
        )}
      </Menu>
    </>
  )
}
