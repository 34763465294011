import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxButton,
  NxTooltip,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { School } from '@plvs/graphql/generated'
import { WarningOutlined } from '@playvs-inc/nexus-icons'
import { CopyIconButton } from '@plvs/respawn/features/button'
import { useNavigate } from 'react-router-dom'
import { ChildSettingsCard } from './ChildSettingsCard'

import { AccountSettingsPromptEditPasswordDialog } from '../AccountSettingsPromptEditPasswordDialog'

type ChildSchoolLoginProps = {
  userId: string
  childSchool: Pick<School, 'id' | 'name' | 'logoUrl' | 'city' | 'state'>
  childEmail?: string
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    minWidth: '81px',
    marginRight: theme.spacing(3),
  },
}))

export const ChildSchoolLoginSettings: React.FC<ChildSchoolLoginProps> = ({
  userId,
  childEmail,
  childSchool,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [showEnterPasswordPrompt, setShowEnterPasswordPrompt] = useState<
    boolean
  >()

  const childSchoolCluster = (
    <Box data-testid="child-school" display="flex" flexDirection="row">
      <NxUserCluster
        avatarHashId={childSchool.id}
        avatarUrl={childSchool.logoUrl ?? ''}
        subtitles={[{ title: `${childSchool.city}, ${childSchool.state}` }]}
        title={childSchool.name ?? ''}
      />

      <NxTooltip
        arrow
        placement="right"
        title="Contact support to link a different school to this account"
      >
        <Box ml={1} mt={1}>
          <WarningOutlined />
        </Box>
      </NxTooltip>
    </Box>
  )

  const emailCluster = (
    <Box
      alignItems="center"
      data-testid="child-email"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" flexDirection="row">
        <NxTypography className={classes.textTitle} variant="body1">
          School Email
        </NxTypography>
        <NxTypography variant="body1">{childEmail}</NxTypography>
      </Box>
      <CopyIconButton
        textToCopy={childEmail ?? ''}
        tooltipTextBeforeCopying="Copy Child Email"
      />
    </Box>
  )

  const PasswordCluster = (
    <Box data-testid="child-password" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Password
      </NxTypography>
      <NxTypography variant="body1">*************</NxTypography>
    </Box>
  )

  const showEditPasswordPrompt = (): void => {
    setShowEnterPasswordPrompt(true)
  }

  const titleCta = (
    <NxButton
      data-testid="edit-details-button"
      label="Edit"
      onClick={showEditPasswordPrompt}
      variant="secondary"
    />
  )

  const handleRequestAccountEditSuccess = (): void => {
    setShowEnterPasswordPrompt(false)

    navigate(`${Path.App}/child/${userId}/login-settings`)
  }

  return (
    <Box mt={3}>
      <ChildSettingsCard
        customContent={[
          { id: 'school', content: childSchoolCluster },
          { id: 'email', content: emailCluster },
          { id: 'password', content: PasswordCluster },
        ]}
        title="School & Login Information"
        titleCta={titleCta}
      />
      {showEnterPasswordPrompt && (
        <Box>
          <AccountSettingsPromptEditPasswordDialog
            onClose={(): void => {
              setShowEnterPasswordPrompt(false)
            }}
            onSuccess={handleRequestAccountEditSuccess}
          />
        </Box>
      )}
    </Box>
  )
}
