import { Box, makeStyles } from '@material-ui/core'
import { Location, ProductType } from '@plvs/client-data/models'
import React, { useEffect, useState } from 'react'
import {
  NxSelect,
  NxSelectOption,
  NxTextInput,
  NxButton,
} from '@playvs-inc/nexus-components'
import { rallyEnv } from '@plvs/rally/env'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useAppLocationFn, useProductTypeFn } from '@plvs/client-data/hooks'
import { useApolloProviderWrapperContext } from '@plvs/rally/ApolloProviderWrapper'
import { LocalStorageKey } from '@plvs/const'

const useStyles = makeStyles((theme) => ({
  select: {
    height: '54px',
    '& .MuiSelect-select, div': {
      display: 'flex',
      backgroundColor: theme.palette.ColorBackgroundBase,
      color: theme.palette.ColorTextAlt,
    },
    '& .MuiSelect-select:focus, div': {
      backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    },
  },
  submitButton: {
    height: theme.spacing(5.5),
    opacity: 1,
  },
}))

export const EnvironmentFormSchema = yup.object().shape({
  environment: yup.string().url().nullable(),
})

export const UpdateEnvironment: React.FC = () => {
  const productTypeVar = useProductTypeFn()
  const {
    changeProductAPI,
    defaultStagingDevelopURL,
  } = useApolloProviderWrapperContext()
  const [productType, setProductType] = useState<ProductType>(productTypeVar)
  const [environment, setEnvironment] = useState<string>(
    defaultStagingDevelopURL
  )
  const appLocation = useAppLocationFn()
  const isCheckpoint = appLocation === Location.Checkpoint
  const isProduction = rallyEnv.API_ENV === 'production'
  const isNotProduction = !isProduction
  const { errors, register } = useForm<{
    environment?: string
  }>({
    resolver: yupResolver<{ environment?: string }>(EnvironmentFormSchema),
  })

  const classes = useStyles()

  const updateProductType = (
    e: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const value = e.target.value as ProductType
    setProductType(value)
    changeProductAPI({ productTypeChangeArg: value })
  }

  const onEnvironmentUpdateClick = (e): void => {
    try {
      e.preventDefault()
      changeProductAPI({
        productTypeChangeArg: productType,
        environment,
      })
    } catch (error) {
      // This swallows any error related to an invalid environment.
      // The error is thrown in errorLink as a network type error.
    }
  }

  useEffect(() => {
    if (isCheckpoint || !isProduction) {
      localStorage.setItem(LocalStorageKey.ProductType, productType)
      localStorage.setItem(LocalStorageKey.Environment, environment)
    }
  }, [])

  return (
    <>
      {isCheckpoint && (
        <NxSelect
          className={classes.select}
          data-testid="productTypeSelect"
          defaultValue={productType}
          fullWidth
          onChange={updateProductType}
        >
          <NxSelectOption value={ProductType.Scholastic}>
            Scholastic
          </NxSelectOption>
          <NxSelectOption value={ProductType.Stadium}>Stadium</NxSelectOption>
        </NxSelect>
      )}

      {isNotProduction && (
        <form name="environment" noValidate>
          <Box mt={2}>
            <NxTextInput
              ref={register}
              data-testid="environment"
              defaultValue={environment}
              fullWidth
              helperText={errors.environment ? 'Please enter a url' : undefined}
              label="Environment"
              name="environment"
              onChange={(e): void => {
                setEnvironment(e.target.value)
              }}
              // Setting style inline because className overrides base style
              style={{ height: '54px' }}
              type="url"
              variant={errors.environment ? 'error' : 'default'}
            />
          </Box>
          <Box mt={2}>
            <NxButton
              className={classes.submitButton}
              data-testid="updateEnvironmentButton"
              fullWidth
              label="Update Environment"
              name="environment"
              onClick={onEnvironmentUpdateClick}
              size="large"
              variant="secondary"
            />
          </Box>
        </form>
      )}
    </>
  )
}
