import { SetMyAvatarImageMutation } from '@plvs/graphql/generated'

const MAX_FILE_SIZE_IN_BYTES = 1000000 // 1MB

export async function setMyAvatarImage(
  event: React.ChangeEvent<HTMLInputElement>,
  setMyAvatarImageMutation: (input: {
    variables: {
      file: any
    }
  }) => Promise<{ data?: SetMyAvatarImageMutation | null }>
): Promise<void> {
  const file = event?.target?.files?.[0]
  if (file) {
    const { type } = await file
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      throw new Error('File size must be less than 1MB')
    }
    if (type === 'image/jpeg' || type === 'image/png') {
      await setMyAvatarImageMutation({
        variables: { file },
      })
    } else {
      throw new Error('File must be jpeg or png')
    }
  } else {
    // User will get error if they hit cancel from file upload window. Do we want this?
    // throw new Error('No file selected')
  }
}
