import { Box } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { useAppLocationFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import React from 'react'

const rallyPageStyle = {
  zIndex: 1,
  width: '100%',
}

const checkpointPageStyle = {
  flexGrow: 1,
  flexShrink: 0,
}

export const fullHeightStyle = {
  display: 'flex',
  flexDirection: 'column',
}

export const FullHeightBox = ({ ...rest }: BoxProps): React.ReactElement => {
  const location = useAppLocationFn()
  const styles =
    location === Location.Rally || location === Location.Stadium
      ? rallyPageStyle
      : checkpointPageStyle

  return (
    <Box
      {...fullHeightStyle}
      {...styles}
      {...rest}
      bgcolor="ColorBackgroundBody"
    />
  )
}
