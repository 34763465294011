import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { NxEsportAvatar, NxTypography } from '@playvs-inc/nexus-components'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { Path } from '@plvs/const'

import { LiveIndicator } from '../matches/LiveIndicator'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    borderRadius: theme.shape.borderRadius * 1.5,
    background: 'inherit',
    textDecoration: 'none',
    color: theme.palette.ColorTextBase,
    width: '100%',
    '&:hover': {
      background:
        theme.palette.type === MUIThemeModeEnum.Dark
          ? theme.palette.ColorBackgroundAlt2
          : theme.palette.ColorBackgroundBase,
      fontWeight: 600,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  announcementInfo: {
    overflow: 'hidden',
    width: '100%',
  },
  liveText: {
    color: theme.palette.ColorTextError,
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export interface PracticeDayCardProps {
  isCoach?: boolean
  canView?: boolean
  isLive: boolean
  esportSlugs: string[]
}

export const PracticeDayCard: React.FC<PracticeDayCardProps> = ({
  canView,
  isCoach,
  isLive,
  esportSlugs,
}) => {
  const styles = useStyles()
  const subtitle = isCoach ? null : 'Practice Day - Grab a Coach to Queue'
  const [esportSlugIndex, setEsportSlugIndex] = useState<number>(0)

  useEffect(() => {
    const index = setInterval(() => {
      const nextIndex = esportSlugIndex + 1
      if (esportSlugs[nextIndex]) {
        setEsportSlugIndex(nextIndex)
      } else {
        setEsportSlugIndex(0)
      }
    }, 3000)

    return (): void => clearInterval(index)
  })

  const announcementBody = (
    <>
      <div className={styles.icon}>
        <NxEsportAvatar
          esport={esportSlugs[esportSlugIndex]}
          iconHeight={25}
          iconWidth={25}
        />
      </div>
      <div className={styles.announcementInfo}>
        <div className={styles.titleWrapper}>
          <NxTypography className={styles.title} variant="body3">
            Practice Day
          </NxTypography>
        </div>
        {isLive && isCoach && <LiveIndicator isLive />}
        {subtitle && (
          <NxTypography className={styles.subtitle} variant="body5">
            {subtitle}
          </NxTypography>
        )}
      </div>
    </>
  )

  return (
    <>
      {canView ? (
        <Link className={styles.wrapper} to={Path.PracticeDayQueue}>
          {announcementBody}
        </Link>
      ) : (
        <div className={styles.wrapper}>{announcementBody}</div>
      )}
    </>
  )
}
