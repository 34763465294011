import { CreateTeam } from '@plvs/rally/features/createTeam/CreateTeam'
import React from 'react'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Param } from '@plvs/const'
import { AppPage } from '../page'

export const CreateTeamPage: React.FC = () => {
  const [esport] = useQueryParam(Param.Esport)

  return (
    <AppPage title="Create Team">
      <CreateTeam esportId={esport || ''} />
    </AppPage>
  )
}
