import React from 'react'
import { Route } from 'react-router-dom'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { CompetitionsPage } from './CompetitionsPage'
import { ExploreLeaguesTablePage } from './ExploreLeaguesTablePage'
import { LeagueDetailsPage } from './LeagueDetailsPage'

export const CompetitionsRoute: React.FC = () => {
  return (
    <ApmRoutes>
      <Route
        element={<ExploreLeaguesTablePage />}
        path="leagues-table/:competitionGroup/:esportSlug"
      />
      <Route element={<LeagueDetailsPage />} path="leagues/:publicSlug/*" />
      <Route element={<CompetitionsPage />} path="/" />
    </ApmRoutes>
  )
}
