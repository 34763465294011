import { mapIndexed } from 'ramda-adjunct'
import React, { useEffect, useState } from 'react'
import { SizeMe } from 'react-sizeme'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { DEFAULT_COMPONENT_WIDTH } from '@plvs/const'
import { EsportSlug, SlotLabel } from '@plvs/graphql'

import { IconButton } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { appendClasses } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import {
  BracketResult,
  BracketType,
} from '@plvs/rally/features/standings/standingsHelpers'
import { NxTypography } from '@playvs-inc/nexus-components'
import { BracketSlot } from './BracketSlot'
import { useBracketStyles } from './Bracket.styles'

const getNumSlidesToShow = (width: number): number =>
  Math.floor(width / 300) || 1

export const Arrow: React.FC<{
  className?: string
  onClick?(): void
  prev?: boolean
}> = ({ className, onClick, prev }) => (
  <button
    aria-label={prev ? 'prev' : 'next'}
    className={className}
    onClick={onClick}
    type="button"
  >
    {prev ? <ChevronLeft /> : <ChevronRight />}
  </button>
)

export const getSlotTitle = (
  numSlots: number,
  slotIndex: number,
  slotLabel: SlotLabel | null,
  noWinnerSlot = false
): string => {
  // noWinnerSlot used for double elim where finals are not used only in single elim
  const label = slotLabel ? `${slotLabel} ` : ''
  if (slotIndex === numSlots && !noWinnerSlot) return 'Winner'

  if (slotIndex === numSlots - 1 && slotIndex !== 0 && !noWinnerSlot) {
    // prevents Finals Finals situation
    if (label === `${SlotLabel.Finals} `) {
      return SlotLabel.Finals
    }
    return `${label}Finals`
  }

  return `${label}Round ${slotIndex + 1}`
}

interface Props {
  bracketSlotResults: BracketResult[][]
  bracketType?: BracketType
  className?: string
  slots: { label?: string | null; startsAt?: string | null }[]
  slug: EsportSlug | null
  toMatch?(matchId: string): string
}

export const DEFAULT_NUM_TEAMS_TO_DISPLAY = 16

export const Bracket: React.FC<Props> = ({
  bracketSlotResults,
  bracketType = 'winners',
  className,
  slots,
  slug,
  toMatch,
}) => {
  // state
  const numTeamsFirstSlot = bracketSlotResults[0].length
  const shouldShowToggle = numTeamsFirstSlot > DEFAULT_NUM_TEAMS_TO_DISPLAY
  const [toggled, setToggled] = useState<boolean>(shouldShowToggle)
  const [currentSlotIndex, setCurrentSlotIndex] = useState<number>(0)

  // styles
  const classes = useBracketStyles({ toggled })

  useEffect(() => {
    // reset current slot index when changing esport
    if (currentSlotIndex > 0) {
      setCurrentSlotIndex(0)
    }
  }, [slug])

  const toggleText = `${toggled ? 'Show' : 'Hide'}  more ${bracketType} matches`

  const bracketSlotResultsLength = bracketSlotResults.length - 1

  return (
    <SizeMe>
      {({ size }): React.ReactElement => {
        const width = size.width || DEFAULT_COMPONENT_WIDTH
        return (
          <div className={appendClasses(classes.root, className)}>
            <Slider
              {...{
                adaptiveHeight: false,
                beforeChange: (_, newIndex): void =>
                  setCurrentSlotIndex(newIndex),
                infinite: false,
                nextArrow: <Arrow />,
                prevArrow: <Arrow prev />,
                slidesToShow: getNumSlidesToShow(width),
                slidesToScroll: 1,
              }}
            >
              {mapIndexed(
                (bracketResults, slotIndex) => (
                  <BracketSlot
                    key={`slot-${slotIndex}`}
                    bracketResults={bracketResults}
                    bracketType={bracketType}
                    slot={slots[slotIndex]}
                    slotIndex={slotIndex}
                    slotsLength={bracketSlotResultsLength}
                    title={getSlotTitle(
                      bracketSlotResultsLength,
                      slotIndex,
                      slots[slotIndex]?.label as SlotLabel | null,
                      true
                    )}
                    toMatch={toMatch}
                  />
                ),
                bracketSlotResults
              )}
            </Slider>
            {shouldShowToggle && (
              <Box alignItems="center" display="flex" px={5} py={4}>
                <NxTypography className={classes.showMoreText} variant="body1">
                  {toggleText}
                </NxTypography>
                <IconButton
                  className={classes.downArrowButton}
                  onClick={(): void => setToggled(!toggled)}
                  size="small"
                >
                  {toggled ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton>
              </Box>
            )}
          </div>
        )
      }}
    </SizeMe>
  )
}
