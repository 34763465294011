import React from 'react'
import { Box } from '@material-ui/core'
import {
  NxTypography,
  NxButton,
  NxTextLink,
  NxTooltip,
  NxFirebobMessage,
} from '@playvs-inc/nexus-components'

import { IntercomArticleMappings } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { useStyles } from '../../../MatchAssistant.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'

interface MatchAssistantIntroProps {
  onNext?(): void
}

export const MatchAssistantIntro: React.FC<MatchAssistantIntroProps> = ({
  onNext,
}) => {
  // This is not the correct pattern, rendering logic should be created by the render controller.
  // However, intercom is being hidden for v1, so this is a temporary solution.
  const productType = useProductTypeFn()
  const isStadium = productType === ProductType.Stadium
  const styles = useStyles()

  const { esportSlug, setShowMatchAssistantIntro } = useMatchAssistantContext()

  const { matchAssistantVideoSrc } = useGeneralEsportAdapter(esportSlug)

  const handleStart = (): void => {
    setShowMatchAssistantIntro(false)
    onNext?.()
  }

  const handleLearnMore = (): void => {
    showIntercomArticle(IntercomArticleMappings.matchAssistant)
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <NxTypography variant="display3">Match Assistant Guide</NxTypography>
      <Box pb={3} pt={1}>
        <NxTypography variant="body1">
          Take a moment and learn about the new Match Assistant. If you ever
          need to refer back to this guide, click “Need Help?” above.
        </NxTypography>
      </Box>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        frameBorder="0"
        height="315"
        src={matchAssistantVideoSrc}
        title="Match Assistant Tutorial"
        width="100%"
      />
      <MatchAssistantFooter>
        <NxFirebobMessage message="Learn about the new Match Assistant before starting" />
        {!onNext ? (
          <NxTooltip
            arrow
            placement="top"
            title="Wait for your match to start!"
          >
            <Box textAlign="center" width="100%">
              <NxButton
                className={styles.cta}
                disabled={!onNext}
                fullWidth
                label="I Understand"
                onClick={handleStart}
                variant="primary"
              />
            </Box>
          </NxTooltip>
        ) : (
          <NxButton
            className={styles.cta}
            disabled={!onNext}
            fullWidth
            label="I Understand"
            onClick={handleStart}
            variant="primary"
          />
        )}
        {isStadium ? null : (
          <NxTextLink
            label="What is Match Assistant?"
            onClick={handleLearnMore}
          />
        )}
      </MatchAssistantFooter>
    </Box>
  )
}
