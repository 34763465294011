import {
  ActiveGlobalConveresationsVarPayload,
  ActiveGlobalConversations,
  activeGlobalConversationsVar,
} from '../models/GlobalChatConversations'

export const updateActiveGlobalChatConversationsVar = (
  updatedActiveGlobalChatConversation: ActiveGlobalConversations
): ActiveGlobalConveresationsVarPayload => {
  return activeGlobalConversationsVar({
    activeConversations: updatedActiveGlobalChatConversation,
  })
}
