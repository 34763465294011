import { makeStyles } from '@material-ui/core'
import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { AvatarCircle } from '@plvs/rally/components/avatar'
import { GameStatsQuery } from '@plvs/graphql'
import { NxTooltip } from '@playvs-inc/nexus-components'

type TeamGameStats = NonNullable<
  NonNullable<GameStatsQuery['game']>['teamGameStats']
>[0]

type LeagueOfLegendsBan = NonNullable<
  NonNullable<
    NonNullable<TeamGameStats['leagueOfLegendsStats']>['detail']
  >['bans']
>[0]

const GUTTER = 8

const useStyles = makeStyles(() => ({
  squircle: {
    marginLeft: GUTTER,
    marginRight: GUTTER,
  },
}))

const LolBandanaItem: React.FC<LeagueOfLegendsBan> = ({ champion }) => {
  const classes = useStyles()
  const championId = champion?.id
  const championImage = champion?.image.src
  const name = champion?.name ?? ''

  return (
    <NxTooltip key={championId} arrow title={name}>
      <div>
        <AvatarCircle
          avatarUrl={championImage}
          className={classes.squircle}
          defaultIcon={<></>}
          shape="square"
          size="small"
        />
      </div>
    </NxTooltip>
  )
}

interface Props {
  opposing?: boolean
  teamGameStats?: TeamGameStats
}

export const LolBandana: React.FC<Props> = ({ opposing, teamGameStats }) => (
  <Box
    display="flex"
    flex={1}
    flexDirection={opposing ? 'row' : 'row-reverse'}
    marginLeft={opposing && -GUTTER}
    marginRight={opposing || -GUTTER}
  >
    {teamGameStats?.leagueOfLegendsStats?.detail?.bans?.map(LolBandanaItem)}
  </Box>
)
