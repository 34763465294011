import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { MatchRescheduleRequestReason, EsportSlug } from '@plvs/graphql'
import {
  NxMatchDetailsCluster,
  NxSelect,
  NxSelectOption,
  NxTextArea,
} from '@playvs-inc/nexus-components'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { RescheduleMatch } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'

export const RescheduleReasonStep: React.FC<{
  reason?: string
  reasonDetails: string | null
  setReasonDetails: any
  match: RescheduleMatch
  onChange(event: any): void
  esportSlug: EsportSlug | null
}> = ({
  reason,
  reasonDetails,
  setReasonDetails,
  match,
  onChange,
  esportSlug,
}) => {
  const matchTime = match.scheduledStartsAt ?? ''
  const team1Name = match.team1?.name ?? '???'
  const team2Name = match.team2?.name ?? '???'
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)

  const textareaReasonDetails =
    reason === MatchRescheduleRequestReason.Other ? (
      <NxTextArea
        fullWidth
        label="Message"
        name="reasonDetail"
        onChange={(e): void => {
          setReasonDetails(e.target.value)
        }}
        placeholder="Please provide at least 30 characters"
        rows={6}
        value={reasonDetails ?? ''}
        variant="default"
      />
    ) : null

  return (
    <>
      <NxMatchDetailsCluster
        bestOf={match?.bestOf ?? undefined}
        esport={esportSlug ?? ''}
        esportName={esportName}
        teamAName={team1Name}
        teamBName={team2Name}
        timestamp={matchTime}
      />
      <Box py={3} width="80%">
        <Box display="flex" width="100%">
          <NxSelect
            fullWidth
            label="Reason for reschedule"
            name="reason"
            onChange={onChange}
          >
            <NxSelectOption value={MatchRescheduleRequestReason.TechIssues}>
              Technology Issues
            </NxSelectOption>
            <NxSelectOption
              value={MatchRescheduleRequestReason.NotEnoughPlayers}
            >
              Not enough players
            </NxSelectOption>
            <NxSelectOption value={MatchRescheduleRequestReason.SchoolClosed}>
              School closure
            </NxSelectOption>
            <NxSelectOption value={MatchRescheduleRequestReason.PlatformIssues}>
              Platform issues
            </NxSelectOption>
            <NxSelectOption
              value={MatchRescheduleRequestReason.CovidSchoolClosure}
            >
              COVID-19 School Closure
            </NxSelectOption>
            <NxSelectOption value={MatchRescheduleRequestReason.Other}>
              Other
            </NxSelectOption>
          </NxSelect>
        </Box>
      </Box>
      <Box width="80%">{textareaReasonDetails}</Box>
    </>
  )
}
