import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'

interface TextGroupingInterface {
  label?: React.ReactNode
  value: React.ReactNode
}

export const TextGrouping: React.FC<TextGroupingInterface> = ({
  label,
  value,
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      whiteSpace="nowrap"
    >
      <Box data-cy="bestOf" flex={0}>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {label}
        </NxTypography>
      </Box>
      <Box flex={0} mx={2} style={{ opacity: 0.7 }}>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          |
        </NxTypography>
      </Box>
      <Box data-cy="matchLobbyDate" flex={0}>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {value}
        </NxTypography>
      </Box>
    </Box>
  )
}
