import React, { useState } from 'react'
import { groupBy, head } from 'ramda'
import { generatePath } from 'react-router'

import {
  NxTypography,
  NxButton,
  NxEsportAvatar,
  NxSelectableSingle,
} from '@playvs-inc/nexus-components'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  EsportSlug,
  GetCoachSchoolTeamsQueryResult,
  UserRole,
  useGetCoachSchoolTeamsQuery,
} from '@plvs/graphql'
import { useMatchImageLoader } from '@plvs/respawn/features/esport/creator/utils'
import { Box } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { isCurrentSeason, isCaptainForResource } from '@plvs/utils'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { QueuePageWrapper } from './QueuePageWrapper'

export type PracticeReadyTeams = NonNullable<
  NonNullable<
    NonNullable<GetCoachSchoolTeamsQueryResult['data']>['school']
  >['teams']
>[0]

enum Teams {
  Enrolled = 'A',
  NotEnrolled = 'B',
}

const groupByEnrolled = groupBy((team: PracticeReadyTeams) =>
  team?.enrolledSeasons?.some((season) => isCurrentSeason(season))
    ? Teams.Enrolled
    : Teams.NotEnrolled
)

export const sortEnrolledTeams = (
  teams: PracticeReadyTeams[]
): PracticeReadyTeams[] => {
  const groupedTeams: {
    [index: string]: PracticeReadyTeams[]
  } = groupByEnrolled(teams)

  const sortedGroups = [
    groupedTeams[Teams.Enrolled] || [],
    groupedTeams[Teams.NotEnrolled] || [],
  ].map((group) =>
    group
      .slice()
      .sort((teamA, teamB) =>
        (teamA?.name ?? '').localeCompare(teamB?.name ?? '')
      )
  )
  return sortedGroups.flat()
}

export function getTeamsForPracticeDay(
  teams: PracticeReadyTeams[],
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  isCaptainAtOrg: boolean
): PracticeReadyTeams[] {
  const viewableTeams = isCaptainAtOrg
    ? teams.filter((team) => isCaptainForResource(userRoles, [team.id]))
    : teams

  const practiceReadyTeams = viewableTeams.filter((team) => {
    const teamRosterFormat = head(team?.roster?.formats ?? [])
    const filledStarterRosterSize = (
      teamRosterFormat?.starters?.filter(
        (starter) => starter.player?.user.id
      ) ?? []
    ).length
    const teamFormat = teamRosterFormat?.teamSize ?? 0

    return filledStarterRosterSize >= teamFormat
  })

  return sortEnrolledTeams(practiceReadyTeams)
}

export const PracticeDayQueuePage: React.FC = () => {
  const [selectedTeam, setSelectedTeam] = useState<{
    teamId: string
    esportSlug: EsportSlug
  } | null>(null)
  const esportSlug = selectedTeam?.esportSlug ?? EsportSlug.RocketLeague

  const { orgId, isCaptainAtOrg, userRoles } = useUserIdentityFn()
  const coachOrgId = orgId ?? ''
  const { data, loading } = useGetCoachSchoolTeamsQuery({
    variables: {
      orgId: coachOrgId,
    },
    skip: !coachOrgId,
  })

  const teams = getTeamsForPracticeDay(
    data?.school?.teams ?? [],
    userRoles,
    isCaptainAtOrg
  )
  const hasTeams = Boolean(teams.length)
  const selectedValue = teams
    .map((team) => team.id)
    .indexOf(selectedTeam?.teamId ?? '')

  const { imageSrc } = useMatchImageLoader({
    esportSlug,
  })

  const generatedPath =
    selectedTeam?.teamId && selectedTeam.esportSlug
      ? generatePath(Path.MatchQueue, {
          teamId: selectedTeam.teamId,
          slug: selectedTeam.esportSlug,
        })
      : ''

  return (
    <QueuePageWrapper
      backgroundImageSrc={imageSrc}
      esportSlug={esportSlug}
      loading={loading}
      pageDataTestId="PracticeQueuePage_Page"
      pageTitle="Practice Queue"
    >
      <Box
        alignItems="center"
        display="flex"
        flex={0}
        flexDirection="column"
        mt={hasTeams ? '20vh' : '30vh'}
        width="100%"
      >
        <NxTypography colorToken="OverlayColorTextBase" variant="display">
          It&apos;s Practice Day
        </NxTypography>
        <Box pb={6} pt={2} textAlign="center">
          <NxTypography colorToken="OverlayColorTextBase" variant="h1">
            {hasTeams
              ? 'Which team would you like to queue as?'
              : 'A full roster is required to queue.'}
          </NxTypography>
        </Box>
        {hasTeams ? (
          <>
            <NxSelectableSingle
              justifyContent="center"
              onChange={(_evnt, newValue): void => {
                const selectedTeam = teams[newValue]
                setSelectedTeam({
                  teamId: selectedTeam.id,
                  esportSlug: selectedTeam.esport.slug,
                })
              }}
              padding={1}
              shouldUseOverlayColors
              value={selectedValue}
            >
              {teams.map((team) => {
                const esportSlug = team.esport.slug
                return (
                  <Box
                    key={team.id}
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <Box display="flex" flexDirection="row" pb={1}>
                      <NxEsportAvatar esport={esportSlug} />
                    </Box>
                    <NxTypography color="inherit" variant="button">
                      {team.name}
                    </NxTypography>
                  </Box>
                )
              })}
            </NxSelectableSingle>
            <Box mt="10vh" width="248px">
              <NxButton
                data-testid="PracticeQueuePage_StartQueueButton"
                disabled={!selectedTeam?.teamId}
                fullWidth
                label="Start Queue"
                onClick={(): void => {
                  window.open(generatedPath, '_blank', 'noopener')
                }}
                shouldUseOverlayColors
                variant="primary"
              />
            </Box>
            {selectedTeam?.esportSlug && (
              <EsrbRating
                bottom={40}
                esportSlug={selectedTeam.esportSlug}
                position="fixed"
                shouldUseLightText
              />
            )}
          </>
        ) : (
          <Box mb="10vh" width="248px">
            <NxButton
              data-testid="PracticeQueuePage_ManageTeamsButton"
              fullWidth
              href={Path.ManageTeams}
              label="Manage Teams"
              shouldUseOverlayColors
              variant="primary"
            />
          </Box>
        )}
      </Box>
    </QueuePageWrapper>
  )
}
