import { makeStyles, useTheme } from '@material-ui/core'
import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { NxIconProps } from '@playvs-inc/nexus-icons'

const useStyles = makeStyles((theme) => ({
  box: {
    alignItems: 'center',
    backgroundColor: theme.palette.ColorBackgroundAlt2,
    color: theme.palette.ColorTextBase,
    borderRadius: 4,
    display: 'flex',
    height: 38,
    justifyContent: 'center',
    width: 68,
    cursor: 'default',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

interface ObjectiveProps {
  description?: string
  Icon?: (input: NxIconProps) => React.ReactElement
  value: React.ReactNode
}

export const Objective: React.FC<ObjectiveProps> = ({
  description,
  Icon,
  value,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <NxTooltip arrow title={description ?? ''}>
        <div className={classes.iconBox}>
          {Icon && <Icon color={theme.palette.ColorIconAlt} />}
          <Box pl={0.5}>
            <NxTypography variant="body4">{value}</NxTypography>
          </Box>
        </div>
      </NxTooltip>
    </Box>
  )
}
