import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { RosterRow, RosterRowData } from './RosterRow'

export interface RosterRowGroupProps {
  rowEntries: RosterRowData[]
  onChange(input: string): void
  selectedId?: string
  py?: number
}
export const RosterRowGroup: React.FC<RosterRowGroupProps> = ({
  onChange,
  rowEntries,
  selectedId,
  py = 2,
}) => {
  return (
    <>
      {rowEntries.map(({ id, ...rest }) => (
        <Box key={id} py={py}>
          <RosterRow
            checked={selectedId === id}
            id={id}
            onClick={onChange}
            {...rest}
          />
        </Box>
      ))}
    </>
  )
}
