import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { noop } from 'ramda-adjunct'
import { Box } from '@material-ui/core'

import { NxBreadcrumbs } from '@playvs-inc/nexus-components'

import { Path, Param } from '@plvs/const'
import {
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import {
  useGetSchoolTeamsByIdQuery,
  RequestScrimmageInput,
  RequestScrimmageMutation,
  useRequestScrimmageMutation,
  useMySchoolDetailsQuery,
  Team,
  EsportSlug,
} from '@plvs/graphql'
import { cleanGraphQLError } from '@plvs/utils'
import { useQueryParams } from '@plvs/rally/components/filter'
import {
  CreateScrimmageStep,
  CreateScrimmageFormInput,
  getRefetchScrimmageQueries,
  ScrimmageOpponentType,
} from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { getScrimmageRatings } from '@plvs/respawn/features/esport/creator/esportStaticDetails'
import { CreateScrimmageStepA } from './CreateScrimmageStepA'
import { CreateScrimmageStepB } from './CreateScrimmageStepB'
import { CreateScrimmageStepC } from './CreateScrimmageStepC'
import { getTeamsForScrimmage } from './CreateScrimmage.helpers'

dayjs.extend(tz)

export const CreateScrimmage: React.FC = () => {
  const navigate = useNavigate()
  const [params] = useQueryParams([Param.Esport, Param.Team])
  const teamId = params[Param.Team] ?? ''
  const slug = params[Param.Esport] ?? undefined
  const [step, setStep] = React.useState<CreateScrimmageStep>(
    CreateScrimmageStep.Initialize
  )
  const [selectedOpponent, setSelectedOpponent] = useState<Team>()
  const [error, setError] = React.useState<Error>()
  const [isSuccess, setSuccess] = React.useState<boolean>(false)
  const [scrimmageId, setScrimmageId] = React.useState<string>('')

  const [formValues, setFormValues] = React.useState<CreateScrimmageFormInput>({
    opponentType: ScrimmageOpponentType.Public,
    requestingTeamId: teamId,
    esport: slug as EsportSlug,
    allowAutoSchedule: false,
    rating: getScrimmageRatings(slug as EsportSlug)[0],
  })

  const {
    data: schoolData,
    loading: schoolDataLoading,
  } = useMySchoolDetailsQuery()
  const schoolId = schoolData?.me?.school?.id ?? ''
  const schoolCompetitionGroup = schoolData?.me?.school?.competitionGroup
  const { data, loading: teamDataLoading } = useGetSchoolTeamsByIdQuery({
    variables: { id: schoolId },
    skip: !schoolId,
  })

  const { bestOf } = useGeneralEsportAdapter(formValues?.esport)
  const { userRoles } = useUserIdentityFn()

  const teams = getTeamsForScrimmage(
    data?.school?.teams || [],
    schoolId,
    userRoles
  )

  const handleSuccess = (successData: RequestScrimmageMutation): any => {
    setSuccess(true)
    setScrimmageId(successData?.requestScrimmage?.scrimmageRequest.id ?? '')
  }

  const [submit, { loading: isSubmitting }] = useRequestScrimmageMutation({
    onError: (err): void => {
      const errorMessageFormatted = err ? cleanGraphQLError(err.message) : ''
      return setError(
        new Error(
          errorMessageFormatted === 'NotFoundError'
            ? "This could be caused if you don't have any activated teams!"
            : errorMessageFormatted
        )
      )
    },
    onCompleted: (
      completedData: RequestScrimmageMutation
    ): React.ReactElement => {
      return handleSuccess(completedData)
    },
  })

  const resetForm = (): void => {
    setScrimmageId('')
    setStep(CreateScrimmageStep.Initialize)
  }

  const onUpdate = async (input?: CreateScrimmageFormInput): Promise<void> => {
    if (input) setFormValues({ ...formValues, ...input })
    setStep(
      step === CreateScrimmageStep.Initialize
        ? CreateScrimmageStep.RecommendedScrimmages
        : CreateScrimmageStep.ChooseOpponent
    )
  }

  const onSubmit = async (input: CreateScrimmageFormInput): Promise<void> => {
    const {
      requestingTeamId,
      time,
      esport,
      opponentType,
      allowAutoSchedule,
      rating,
    } = {
      ...formValues,
      ...input,
    }
    if (!esport || !requestingTeamId) {
      setError(new Error('Missing data'))
      setSuccess(false)
    } else {
      const scrimmageInput: RequestScrimmageInput = {
        rating,
        bestOf,
        opposingTeamId:
          opponentType === 'private' && selectedOpponent?.id
            ? selectedOpponent.id
            : null,
        requestingTeamId,
        startsAt: time ?? '',
        allowAutoSchedule,
      }
      try {
        await submit({
          awaitRefetchQueries: true,
          refetchQueries: getRefetchScrimmageQueries(schoolId),
          variables: {
            input: scrimmageInput,
          },
        })
      } catch (e: any) {
        setError(e)
        setSuccess(false)
      }
    }
  }

  const selectedTeam = formValues.requestingTeamId
    ? teams.find((team) => team.id === formValues.requestingTeamId)
    : null

  return (
    <WaitTillLoaded
      loading={schoolDataLoading || teamDataLoading}
      showSpinnerWhileLoading
    >
      <PageContentGutter>
        <Box my={2}>
          <NxBreadcrumbs
            breadcrumbs={[
              {
                label: 'Scrimmage',
                onClick: (): void => navigate(Path.Scrimmage),
              },
              { label: 'Create Scrimmage', onClick: noop },
            ]}
          />
        </Box>

        {step === CreateScrimmageStep.Initialize && (
          <CreateScrimmageStepA
            formValues={formValues}
            onSubmit={onUpdate}
            schoolCompetitionGroup={schoolCompetitionGroup}
            teams={teams}
          />
        )}
        {step === CreateScrimmageStep.RecommendedScrimmages && (
          <CreateScrimmageStepB
            formValues={formValues}
            isSubmitting={isSubmitting}
            onReset={resetForm}
            onSubmit={onUpdate}
            schoolCompetitionGroup={schoolCompetitionGroup ?? null}
            schoolId={schoolId}
            timezone={dayjs.tz.guess()}
          />
        )}
        {step === CreateScrimmageStep.ChooseOpponent && (
          <CreateScrimmageStepC
            bestOf={bestOf}
            formValues={formValues}
            isSubmitting={isSubmitting}
            onReset={resetForm}
            onSubmit={onSubmit}
            scrimmageId={scrimmageId}
            selectedOpponent={selectedOpponent}
            setError={setError}
            setSelectedOpponent={setSelectedOpponent}
            team={selectedTeam ?? null}
          />
        )}
        {error && (
          <Navigate
            to={`${Path.Scrimmage}/?error=${encodeURIComponent(error.message)}`}
          />
        )}
        {isSuccess && scrimmageId && (
          <Navigate to={`${Path.Scrimmage}/?scrimmage=${scrimmageId}`} />
        )}
      </PageContentGutter>
    </WaitTillLoaded>
  )
}
