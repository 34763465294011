import {
  Asset,
  DeleteMatchSettleRequestAssetMutationHookResult,
  DeleteMatchDisputeRequestAssetMutationHookResult,
  UploadMatchSettleRequestAssetMutationHookResult,
  UploadMatchDisputeRequestAssetMutationHookResult,
} from '@plvs/graphql'

export const acceptableFileTypes = ['image/jpeg', 'image/png'] as const

export type Attachment = Pick<Asset, 'id' | 'fileName' | 'signedUrl'>
export type SetAttachmentsFn = (value: Attachment[]) => void

export type AttachScreenshotAPI = {
  setAttachments: SetAttachmentsFn
  attachments?: Attachment[]
}

type UploadMutationHookResult =
  | UploadMatchSettleRequestAssetMutationHookResult
  | UploadMatchDisputeRequestAssetMutationHookResult

export type UploadMutationHook = () => UploadMutationHookResult

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

type UploadMutationResult = ThenArg<ReturnType<UploadMutationHookResult[0]>>

export const getAssetFromUploadMutationResult = (
  result: UploadMutationResult
): Attachment | undefined => {
  const { data } = result

  if (data) {
    if ('uploadMatchSettleRequestAsset' in data)
      return data.uploadMatchSettleRequestAsset
    if ('uploadMatchDisputeRequestAsset' in data)
      return data.uploadMatchDisputeRequestAsset
  }

  return undefined
}

type DeleteMutationHookResult =
  | DeleteMatchSettleRequestAssetMutationHookResult
  | DeleteMatchDisputeRequestAssetMutationHookResult

export type DeleteMutationHook = () => DeleteMutationHookResult

export const addAttachment = ({
  attachments = [],
  attachmentToAdd,
  setAttachments,
}: AttachScreenshotAPI & {
  attachmentToAdd: Attachment
}): void => {
  if (!attachments.find(({ id }) => id === attachmentToAdd.id))
    setAttachments([...attachments, attachmentToAdd])
}

export const removeAttachment = ({
  attachments = [],
  attachmentIdToRemove,
  setAttachments,
}: AttachScreenshotAPI & {
  attachmentIdToRemove: string
}): void => {
  setAttachments(attachments.filter(({ id }) => id !== attachmentIdToRemove))
}

export type ScreenshotSizeLimit = '1MB' | '2MB' | '3MB'
