import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core'
import { FormControlProps } from '@material-ui/core/FormControl'
import { SelectProps } from '@material-ui/core/Select'
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel'
import React from 'react'

type Props = {
  helperText?: React.ReactNode
  InputLabelProps?: MuiInputLabelProps
} & Pick<
  FormControlProps,
  'error' | 'disabled' | 'fullWidth' | 'variant' | 'className'
> &
  Pick<
    SelectProps,
    'autoFocus' | 'children' | 'inputRef' | 'label' | 'name' | 'onChange'
  >

export const NativeSelectField = ({
  error,
  helperText,
  InputLabelProps,
  label,
  fullWidth = false,
  variant = 'filled',
  disabled,
  ...rest
}: Props): React.ReactElement => (
  <FormControl
    disabled={disabled}
    error={error}
    fullWidth={fullWidth}
    variant={variant}
  >
    <InputLabel {...InputLabelProps}>{label}</InputLabel>
    <Select label={label} native {...rest} />
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
)
