import { ListItem, makeStyles } from '@material-ui/core'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'

export const useRosterRowStyles = makeStyles((theme) => ({
  listItem: {
    overflow: 'hidden',
    padding: theme.spacing(1.5, 3),
  },
  listContent: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    display: 'flex',
  },
  icon: {
    color: theme.palette.ColorIconBase,
  },
}))

interface RosterRowItemProps {
  end?: React.ReactNode
  content: React.ReactNode
  isLastNode: boolean
}

export const RosterRowItem: React.FC<RosterRowItemProps> = ({
  end,
  content,
  isLastNode = false,
}) => {
  const classes = useRosterRowStyles({ isLastNode })
  return (
    <ListItem className={classes.listItem} disableGutters>
      <Box className={classes.listContent}>
        <Box alignItems="center" flex={1} overflow="hidden">
          {content}
        </Box>
        <Box
          alignItems="center"
          data-cy="benchMoreItem"
          display="flex"
          justifyContent="flex-end"
          pr={end ? undefined : 4}
        >
          {end}
        </Box>
      </Box>
    </ListItem>
  )
}
