import React, { useEffect, useState } from 'react'
import { Paginator, Conversation } from '@twilio/conversations'
import { last } from 'ramda'
import { noop } from 'ramda-adjunct'

import { logger } from '@plvs/rally/logging'
import { ParticipantAttributesJSON } from '@plvs/rally/features/chat/utils'
import { ChatRole } from '@plvs/graphql/generated'
import { useChatClientContext } from './ChatClientProvider'
import {
  ChatConversationsContext,
  ConversationsProviderMetadata,
} from './Providers.types'

export const chatConversationsContext = React.createContext<
  ChatConversationsContext
>({
  usersActiveConversationsMap: {},
  setUsersActiveConversationsMap: noop,
  loading: false,
  setRefetchGetConversations: noop,
})

export const useChatConversationsContext = (): ChatConversationsContext => {
  return React.useContext(chatConversationsContext)
}

export const ChatConversationsProvider: React.FC = ({ children }) => {
  const { client, isReady } = useChatClientContext()
  const [refetchGetConversations, setRefetchGetConversations] = useState(false)
  const [buildUpData, setBuildUpData] = useState(true)
  const [
    usersActiveConversationsMap,
    setUsersActiveConversationsMap,
  ] = useState<Record<string, ConversationsProviderMetadata>>({})

  useEffect(
    function getConversations() {
      async function getConversationsAsync(): Promise<void> {
        let subscribedConversationsConstructor:
          | Paginator<Conversation>
          | undefined

        if (client && isReady) {
          try {
            subscribedConversationsConstructor = await client.getSubscribedConversations()
          } catch (err) {
            logger.debug('[ChatConversationsProvider]', err)
            setBuildUpData(false)
          }

          if (subscribedConversationsConstructor) {
            const conversations = subscribedConversationsConstructor.items
            const mappedConversations = conversations.map(
              async (conversation) => {
                // Messages
                const messages = await conversation.getMessages(10)

                const lastMessageContent = last(messages.items)

                const chatLastUpdated = lastMessageContent?.dateUpdated

                // Participants

                const convoParticipants = await conversation.getParticipants()

                const participantsWithoutAdmins = convoParticipants.filter(
                  (participant) => {
                    const {
                      chatRole: participantChatRole,
                    } = participant.attributes as ParticipantAttributesJSON
                    return participantChatRole !== ChatRole.Admin
                  }
                )

                return {
                  conversation,
                  messages,
                  lastMessage: lastMessageContent,
                  chatLastUpdated,
                  participants: participantsWithoutAdmins,
                  participantsCount: participantsWithoutAdmins.length,
                }
              }
            )
            Promise.all(mappedConversations)?.then((results) => {
              const chatConversationsMap = results.reduce((acc, curr) => {
                if (curr.conversation.uniqueName) {
                  acc[curr.conversation.uniqueName] = curr
                }
                return acc
              }, {})

              setUsersActiveConversationsMap(chatConversationsMap)

              setBuildUpData(false)

              setRefetchGetConversations(false)
            })
          }
        }
      }
      getConversationsAsync()
    },
    [Boolean(client), refetchGetConversations, isReady]
  )

  return (
    <chatConversationsContext.Provider
      value={{
        usersActiveConversationsMap,
        setUsersActiveConversationsMap,
        // This loading state is required for the initial render and hard refreshes of the chat component.
        loading: buildUpData,
        setRefetchGetConversations,
      }}
    >
      {children}
    </chatConversationsContext.Provider>
  )
}
