import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core'
import { Control, Controller } from 'react-hook-form'

interface Props {
  children: React.ReactNode
  className?: string
  control: Control
  defaultValue?: string | null
  disabled?: boolean
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  label?: string
  menuMaxHeight?: number
  name: string
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * Select component for use with react-hook-form
 */

export const SelectController: React.FC<Props> = ({
  children,
  className,
  control,
  defaultValue = '',
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  menuMaxHeight = 450,
  name,
  variant = 'outlined',
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(props): React.ReactElement => (
        <FormControl
          className={className}
          disabled={disabled}
          error={error}
          fullWidth={fullWidth}
          variant={variant}
        >
          <InputLabel>{label}</InputLabel>
          <Select
            {...props}
            label={label}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: menuMaxHeight,
              },
            }}
            variant={variant}
          >
            {children}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
