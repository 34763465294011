import React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@playvs-inc/nexus-icons'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'

import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings } from '@plvs/const'
import { PlayerAccountStatus } from '../RosterProvider.types'

export interface PlayerAccountStatusDetailsProps {
  acctStatus: PlayerAccountStatus | undefined
  providerName: string | undefined
}

interface ErrorSubtitleProps {
  title: string
  tooltip?: string
  dataTestId?: string
  tooltipDataTestId?: string
}

const useStyles = makeStyles((theme) => ({
  statusWrapper: {
    color: theme.palette.ColorTextError,
    display: 'flex',
    gridGap: theme.spacing(0.5),
  },
  statusText: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  icon: {
    cursor: 'pointer',
  },
}))

export const ErrorSubtitle: React.FC<ErrorSubtitleProps> = ({
  title,
  tooltip,
  dataTestId,
  tooltipDataTestId,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.statusWrapper} data-testid={dataTestId}>
      <NxTypography
        className={classes.statusText}
        colorToken="ColorTextAlt2"
        variant="body3"
      >
        {title}
      </NxTypography>
      {tooltip ? (
        <NxTooltip
          arrow
          data-testid={tooltipDataTestId}
          maxWidth={1000}
          placement="bottom-start"
          PopperProps={{ style: { marginTop: -14 } }}
          title={tooltip ?? ''}
        >
          <Box>
            <InfoOutlined
              color="ColorTextError"
              onClick={(): void =>
                showIntercomArticle(IntercomArticleMappings.connectionsGuide)
              }
            />
          </Box>
        </NxTooltip>
      ) : (
        <InfoOutlined
          className={classes.icon}
          color="ColorTextError"
          onClick={(): void =>
            showIntercomArticle(IntercomArticleMappings.connectionsGuide)
          }
        />
      )}
    </Box>
  )
}

export const playerAccountStatusDetails = ({
  acctStatus,
  providerName,
}: PlayerAccountStatusDetailsProps): React.ReactNode => {
  if (!acctStatus) return null

  if (!acctStatus.isAcctConnected) {
    return (
      <ErrorSubtitle title={`${providerName ?? ''} Account Not Connected`} />
    )
  }

  return null
}
