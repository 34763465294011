import { Avatar, makeStyles } from '@material-ui/core'
import { useGetScheduleConsultLinkQuery } from '@plvs/graphql/generated/graphql'
import { GetReady } from '@plvs/rally/assets/avatars'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes/colors'
import React, { useState } from 'react'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { getNow, hasEnrolledTeams } from '@plvs/utils'
import { useUserIdentityFn } from '@plvs/client-data/hooks/useUserIdentityFn'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '../OnboardingContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      paddingTop: theme.spacing(2),
    },
  },
  title: {
    textAlign: 'center',
  },
  verifiedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  p: {
    textAlign: 'center',
    color: Colors.Grey2,
    marginTop: theme.spacing(2),
  },
  continueButton: {
    padding: '12px',
    maxWidth: '400px',
  },
  avatar: {
    width: '120px',
    height: '120px',
    marginBottom: '3em',
  },
}))

interface OnboardSheerIdVerificationStatusProps {
  isVerified: boolean
  isStudent: boolean
  schoolHasCoach: boolean | unknown
}

export const OnboardSheerIdVerificationStatus: React.FC<OnboardSheerIdVerificationStatusProps> = ({
  isVerified,
  isStudent,
  schoolHasCoach,
}: OnboardSheerIdVerificationStatusProps) => {
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))
  const isMobile = useBreakpointSm()
  const navigate = useNavigate()
  const classes = useStyles({ isMobile })
  const { userId } = useUserIdentityFn()

  const { data: contextData } = useOnboardingContext()
  const { schoolId } = contextData

  const { data: scheduleData } = useGetScheduleConsultLinkQuery({
    variables: {
      schoolId: schoolId as string,
      userId,
      effectiveAt: visibleTeamsTimeWindow,
    },
    skip: !schoolId,
    // in case SF integration is down so we can at least safely fallback
    // to completing onboarding.
    errorPolicy: 'ignore',
  })

  // // https://playvs.atlassian.net/browse/GP-3545 Disabling until email is reworked
  // const [referCoachMutation] = useReferCoachEmailMutation()

  const hasEnrolled = hasEnrolledTeams({
    teamEnrollments: scheduleData?.findTeamEnrollmentsByUser?.teamEnrollments,
  })

  const scheduleLink = scheduleData?.school?.salesRep?.calendarLink
  const showNASEFUx = contextData?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const onContinue = async (): Promise<void> => {
    // https://playvs.atlassian.net/browse/GP-3545 Disabling until email is reworked
    // await referCoachMutation()

    if (scheduleLink && !hasEnrolled) {
      navigate(`${Path.SpawnPoint}/schedule-consult${showNASEFUx}`, {
        state: { scheduleLink },
      })
      return
    }

    if (isStudent && schoolHasCoach) {
      navigate(`${Path.SpawnPoint}/invite-player${showNASEFUx}`)
      return
    }

    if (isStudent && !schoolHasCoach) {
      navigate(`${Path.SpawnPoint}/invite-coach${showNASEFUx}`)
      return
    }

    navigate('finish')
  }

  const notificationTitle = isVerified
    ? `You're Verified!`
    : `Your Verification is Pending`

  return (
    <Box className={classes.container}>
      <Box mb={4}>
        <Box className={classes.verifiedContainer}>
          {isVerified && <Avatar className={classes.avatar} src={GetReady} />}
          <NxTypography className={classes.title} variant="h1">
            {notificationTitle}
          </NxTypography>
          <NxTypography className={classes.p} variant="subtitle1">
            Thank you for doing your part to keep PlayVS safe & secure
          </NxTypography>
        </Box>
      </Box>

      <NxButton
        className={classes.continueButton}
        data-testid="continueButton"
        fullWidth
        label="Continue"
        onClick={onContinue}
        variant="primary"
      />
    </Box>
  )
}
