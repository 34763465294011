import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import {
  OptionalWeeksCache,
  SeasonEnrollmentRangeData,
} from '@plvs/respawn/containers/enrollment/types'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import '@playvs-inc/nexus-theme'
import { groupBy } from 'ramda'
import { NO_BREAK_WEEK_HASH } from '@plvs/respawn/containers/enrollment/enrollmentHelpers'

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.ColorBackgroundAlt3,
    fontSize: 17,
    verticalAlign: ' text-bottom',
    marginLeft: 4,
  },
  cardContainer: ({ isBoxShadow }: StyleProps): Record<string, string> => ({
    boxShadow: isBoxShadow
      ? `0px 1px 0px ${theme.palette.BorderLight}`
      : 'none',
  }),
  leftHeader: {
    color: theme.palette.ColorTextAlt2,
    textTransform: 'uppercase',
  },
  title: {
    color: theme.palette.ColorTextBase,
    paddingBottom: theme.spacing(1.0),
  },
  selection: {
    color: theme.palette.ColorTextBase,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface EnrollmentSidebarTopContentProps {
  title: string
  detail: Record<string, string | number | boolean | undefined> | undefined
  isBoxShadow?: boolean
  isCollege: boolean
  showBreakWeekInSidebar: boolean
  showSeasonBreakWeekInSidebar: boolean
  optionalWeeksCache: OptionalWeeksCache
  optionalSeasonWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
}

interface StyleProps {
  isBoxShadow?: boolean
  upgradeable?: boolean
}

export const EnrollmentSidebarTopContent: React.FC<EnrollmentSidebarTopContentProps> = ({
  title,
  detail,
  isBoxShadow,
  isCollege,
  showBreakWeekInSidebar,
  showSeasonBreakWeekInSidebar,
  optionalSeasonWeeksCache,
  optionalWeeksCache,
  seasonSlotExclusionRangeData,
}) => {
  const flags = useFlags()
  const upgradeable = !!detail?.upgradeable || !detail?.name
  const classes = useStyles({
    isBoxShadow,
    upgradeable,
  })
  const seasonSlotExclusionRangeDataMapping = groupBy(
    (range) => range.seasonId,
    seasonSlotExclusionRangeData
  )

  return (
    <Box
      className={
        flags.freeCompetition && !showBreakWeekInSidebar
          ? ''
          : classes.cardContainer
      }
      px={3}
      py={3}
    >
      {!flags.freeCompetition && (
        <>
          <NxTypography variant="h3">{title}</NxTypography>
          <Box
            key={detail?.title as string}
            display="flex"
            justifyContent="space-between"
            my={2}
          >
            <Box data-testid="plan-name" display="flex" flexDirection="row">
              <Pill
                label={detail?.name ? `${detail?.name}` : 'No Plan Selected'}
                size="small"
                variant={upgradeable ? 'disabled' : 'brand'}
              />
            </Box>
          </Box>

          <Box>
            <NxTypography data-testid="EnrollmentSidebarTopContent_Typography">
              {!!detail &&
                `The ${detail.name} plan covers the ${
                  detail.yearName
                } season(s). ${
                  isCollege
                    ? ''
                    : 'Schedule a consultation to receive a custom quote.'
                }`}
            </NxTypography>
          </Box>
        </>
      )}

      {showBreakWeekInSidebar && (
        <Box
          data-testid="Break_Week_Container"
          display="flex"
          flexDirection="column"
          pt={flags.freeCompetition ? 0 : 3}
        >
          <NxTypography className={classes.title} variant="h3">
            General Break Week:
          </NxTypography>
          {Object.keys(optionalWeeksCache).map((key) => {
            return optionalWeeksCache[key].selections.map(({ selection }) => {
              const subtitle =
                selection?.hash === NO_BREAK_WEEK_HASH
                  ? 'By choosing “No Break Week”, your team(s) will continuously play throughout the season without a BYE week.'
                  : 'No matches will be scheduled for the school this week.'
              return (
                <>
                  <Box key={selection?.hash}>
                    <NxTypography
                      className={classes.selection}
                      id="selectedBreakWeek"
                      variant="body1"
                    >
                      {selection?.formattedSelection}
                    </NxTypography>
                  </Box>
                  <NxTypography className={classes.subtitle} variant="body4">
                    {subtitle}
                  </NxTypography>
                </>
              )
            })
          })}
        </Box>
      )}
      {showSeasonBreakWeekInSidebar && (
        <Box
          data-testid="Break_Week_Container"
          display="flex"
          flexDirection="column"
        >
          <NxTypography className={classes.title} variant="h3">
            Custom Break Week:
          </NxTypography>
          {Object.keys(optionalSeasonWeeksCache).map((key) => {
            const rangeData =
              seasonSlotExclusionRangeDataMapping[
                optionalSeasonWeeksCache[key].seasonId || ''
              ]

            return optionalSeasonWeeksCache[key].selections.map(
              ({ selection }) => {
                const subtitle =
                  selection?.hash === NO_BREAK_WEEK_HASH
                    ? 'By choosing “No Break Week”, your team(s) will continuously play throughout the season without a BYE week.'
                    : 'No matches will be scheduled for the school this week.'
                return (
                  <>
                    <Box key={selection?.hash}>
                      <NxTypography
                        className={classes.selection}
                        id="selectedBreakWeek"
                        variant="body1"
                      >
                        {`${rangeData?.[0]?.leagueName}: ${
                          selection?.formattedSelection?.split(': ')[1]
                        }`}
                      </NxTypography>
                    </Box>
                    <NxTypography className={classes.subtitle} variant="body4">
                      {subtitle}
                    </NxTypography>
                  </>
                )
              }
            )
          })}
        </Box>
      )}
    </Box>
  )
}
