import { range } from 'ramda'
import React from 'react'
import dayjs from 'dayjs'

import { NxSelectOption } from '@playvs-inc/nexus-components'

import { PlaceHolderOption } from './PlaceHolderOption'

export const DateOptions: React.FC = () => {
  const startTomorrow = dayjs().hour() > 21 // if after 9pm
  const nextTwoWeeks = range(startTomorrow ? 1 : 0, 15)

  return (
    <>
      <PlaceHolderOption text="Select a Day" />
      {nextTwoWeeks.map((day) => {
        const date = dayjs().add(day, 'days')
        const name = date.format('ddd MMM D')
        const value = date.format('YYYYMMDD')

        return (
          <option key={value} value={value}>
            {name}
          </option>
        )
      })}
    </>
  )
}

export function useDateOptions(): React.ReactElement[] {
  const startTomorrow = dayjs().hour() > 21 // if after 9pm
  const nextTwoWeeks = range(startTomorrow ? 1 : 0, 15)
  return [
    <NxSelectOption key="placeholder" value="">
      Select a day
    </NxSelectOption>,
    ...nextTwoWeeks.map((day) => {
      const date = dayjs().add(day, 'days')
      const name = date.format('ddd MMM D')
      const value = date.format('YYYYMMDD')

      return (
        <NxSelectOption key={value} value={value}>
          {name}
        </NxSelectOption>
      )
    }),
  ]
}
