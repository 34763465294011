/* istanbul ignore file */
import React from 'react'
import dayjs from '@plvs/rally/init/dayjs'
import { Box } from '@plvs/respawn/features/layout'
import { Link, makeStyles } from '@material-ui/core'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Colors } from '@plvs/rally/themes/colors'
import { Path } from '@plvs/const'
import { RouterLink } from '@plvs/respawn/features/route'
import { EsportRating } from '@plvs/graphql/generated'
import { NxTypography } from '@playvs-inc/nexus-components'
import { SchoolMatchMessage } from './helper'

const useStyles = makeStyles((theme) => ({
  ago: {
    display: 'flex',
    flex: '1',
    fontWeight: 400,
  },
  avatar: {
    margin: theme.spacing(2.5),
  },
  message: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 1, 0),
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
  },
  action: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    height: '100%',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    paddingBottom: theme.spacing(2),
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  win: {
    color: Colors.Green,
  },
  lose: {
    color: Colors.Red,
  },
  hyphen: {
    color: theme.palette.text.secondary,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  team1: {
    textAlign: 'right',
  },
  vs: {
    textAlign: 'center',
  },
}))

export const MessageMatch: React.FC<{
  message: SchoolMatchMessage
  esportRating: EsportRating
}> = React.memo(({ message, esportRating }) => {
  const classes = useStyles()
  const createdAt = message?.createdAt ? dayjs(message?.createdAt) : undefined
  const ago = createdAt ? createdAt?.fromNow() : ''

  return (
    <Box className={classes.message}>
      <Box mb={2}>
        <NxTypography className={classes.ago} variant="subtitle1">
          {ago}
        </NxTypography>
      </Box>
      <Box className={classes.info}>
        <Box display="flex" flexDirection="row" width="100%">
          <Box flex="48%" mr={1}>
            <NxTypography className={classes.team1} variant="body2">
              {message?.title?.split('vs')[0]}
            </NxTypography>
          </Box>
          <Box flex="2%">
            <NxTypography className={classes.vs} variant="body1">
              vs
            </NxTypography>
          </Box>
          <Box flex="48%" ml={1}>
            <NxTypography variant="body2">
              {message?.title?.split('vs')[1]}
            </NxTypography>
          </Box>
        </Box>

        <NxTypography variant="body4">
          {createdAt?.format('MMM D @ h:mma z')}{' '}
        </NxTypography>
        <Box className={classes.row}>
          <NxUserAvatar
            avatarUrl={
              esportRating === EsportRating.Restricted
                ? null
                : message.homeSchoolLogoUrl
            }
            hashId={message.homeTeamId ?? ''}
            size={NxAvatarSize.MD}
          />
          <Link
            className={classes.row}
            color="inherit"
            component={RouterLink}
            to={`${Path.Match}/${message?.matchId}`}
          >
            <Box className={classes.score} p={2}>
              <NxTypography variant="h4">
                {message.homeTeamGamesWon}
              </NxTypography>
              <NxTypography className={classes.win} variant="body2">
                W
              </NxTypography>
            </Box>
            <Box className={classes.score}>
              <NxTypography className={classes.hyphen} variant="h4">
                {' '}
                -{' '}
              </NxTypography>
              <NxTypography variant="body2">&nbsp;</NxTypography>
            </Box>
            <Box className={classes.score} p={2}>
              <NxTypography variant="h4">
                {message.homeTeamGamesLost}
              </NxTypography>
              <NxTypography className={classes.lose} variant="body2">
                L
              </NxTypography>
            </Box>
          </Link>
          <NxUserAvatar
            avatarUrl={
              esportRating === EsportRating.Restricted
                ? null
                : message.opposingSchoolLogoUrl
            }
            hashId={message.opposingTeamId ?? ''}
            size={NxAvatarSize.MD}
          />
        </Box>
      </Box>
    </Box>
  )
})
