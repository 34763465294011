import { UserContentQueryResult, ContentFeature } from '@plvs/graphql'

import { ApolloError } from '@apollo/client'
import { AppState } from './state'

export enum UserContentActionTypes {
  Complete = 'user-content-complete',
}

export interface UserContentCompleteAction {
  type: UserContentActionTypes.Complete
  payload: {
    features: Pick<ContentFeature, 'slug' | 'variant'>[] | null
    refetch: () => void
    error?: ApolloError
    loading: boolean
  }
}

export type UserContentActions = UserContentCompleteAction

export interface UserContentFetchedParams {
  data: UserContentQueryResult['data']
  refetch: () => void
  error?: ApolloError
  loading: boolean
}

export const userContentFetched = ({
  data,
  refetch,
  error,
  loading,
}: UserContentFetchedParams): UserContentCompleteAction => {
  const features = data?.me?.content?.features ?? null
  return {
    type: UserContentActionTypes.Complete,
    payload: {
      features,
      refetch,
      error,
      loading,
    },
  }
}

export const reduceUserContent = (
  state: AppState,
  { type, payload }: UserContentActions
): AppState => {
  switch (type) {
    case UserContentActionTypes.Complete:
      return {
        ...state,
        userContent: {
          ...state.userContent,
          ...payload,
        },
      }
    default:
      return state
  }
}
