import React from 'react'
import { Colors } from '@plvs/rally/themes/colors'
import { NxTypography } from '@playvs-inc/nexus-components'
import { SelectorContainer } from './SelectorContainer'

interface Props {
  title: string
  checked?: boolean
  inActive?: boolean
  checkedColor?: string
  backgroundColor?: string
  borderColor?: string
  inputRef?: React.Ref<any>
  name?: string
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  containerStyles?: any
  ariaDescribedBy?: string
  ariaLabelledBy?: string
  onClick?(): void
}

export const SelectorInput: React.FC<Props> = ({
  title,
  checked,
  inActive,
  checkedColor,
  borderColor = Colors.Grey4,
  inputRef,
  name,
  backgroundColor,
  onChange,
  containerStyles,
  ariaDescribedBy,
  ariaLabelledBy,
  onClick,
}) => {
  return (
    <SelectorContainer
      ariaDescribedBy={ariaDescribedBy}
      ariaLabelledBy={ariaLabelledBy}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      checked={checked}
      checkedColor={checkedColor}
      containerStyles={containerStyles}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      onClick={onClick}
    >
      <NxTypography
        colorToken={inActive ? 'ColorTextAlt2' : 'ColorTextBase'}
        style={{ paddingRight: 32 }}
        variant="subtitle1"
      >
        {title}
      </NxTypography>
    </SelectorContainer>
  )
}
