import React from 'react'
import { makeStyles } from '@material-ui/core'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Path, SessionStorageKey } from '@plvs/const'
import { useOnboardingContext } from '@plvs/rally/containers/onboard/v2/OnboardingContext'
import { useParams } from 'react-router-dom'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGetUserChildOnboardQuery } from '@plvs/graphql/generated'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  container: {
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderTopRightRadius: theme.shape.borderRadius * 2.5,
    borderTopLeftRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(2, 1),
    width: '100%',
  },
  row: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.divider.bottom,
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  lastRow: {
    borderBottomRightRadius: theme.shape.borderRadius * 2.5,
    borderBottomLeftRadius: theme.shape.borderRadius * 2.5,
  },
  button: {
    marginTop: theme.spacing(4),
  },
  esportInterestsText: {
    maxWidth: 300,
    textAlign: 'right',
  },
}))

export const OnboardChildReview: React.FC = () => {
  const classes = useStyles()

  const { data: onboardData } = useOnboardingContext()

  const params = useParams<{ childId: string | undefined }>()
  const { childId } = params

  const childIdToUse = (onboardData.childId as string | undefined) || childId

  const { data: childData, loading } = useGetUserChildOnboardQuery({
    variables: { id: childIdToUse },
    skip: !childIdToUse,
  })

  const handleContinue = (): void => {
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
    window.location.replace(Path.ParentDashboard)
  }

  const child = childData?.user

  const esportInterests = (child?.esportInterests ?? []).map(
    (esport) => esport?.name ?? ''
  )

  if (!child) {
    return null
  }

  return (
    <div className={classes.root}>
      <QuestionnaireCard
        childWidth={600}
        subtitle="Please share the following information with them to sign in to their PlayVS Account. You can edit this through the “Parent Dashboard” later."
        title="Child Account Created"
      >
        <WaitTillLoaded loading={loading}>
          <div className={classes.container}>
            <div className={classes.header}>
              <NxTypography variant="body2">
                Child Account Information
              </NxTypography>
            </div>
            <div className={classes.row}>
              <NxTypography variant="body2">Child Name</NxTypography>
              <NxTypography variant="body1">{child?.name}</NxTypography>
            </div>
            <div className={classes.row}>
              <NxTypography variant="body2">Child School Email</NxTypography>
              <NxTypography variant="body1">
                {child?.emails?.[0]?.email ?? ''}
              </NxTypography>
            </div>
            <div className={classes.row}>
              <NxTypography variant="body2">
                Graduation Year for Current School
              </NxTypography>
              <NxTypography variant="body1">{child?.gradYear}</NxTypography>
            </div>
            <div className={classes.row}>
              <NxTypography variant="body2">School State</NxTypography>
              <NxTypography variant="body1">
                {child?.school?.state}
              </NxTypography>
            </div>
            <div className={classes.row}>
              <NxTypography variant="body2">School</NxTypography>
              <NxTypography variant="body1">{child?.school?.name}</NxTypography>
            </div>
            <div className={`${classes.row} ${classes.lastRow}`}>
              <NxTypography variant="body2">Esports Interest</NxTypography>
              <NxTypography
                className={classes.esportInterestsText}
                variant="body1"
              >
                {esportInterests.length > 0 ? esportInterests.join(', ') : '-'}
              </NxTypography>
            </div>
          </div>
          <NxButton
            className={classes.button}
            fullWidth
            label="Continue To Parent dashboard"
            onClick={handleContinue}
            variant="primary"
          />
        </WaitTillLoaded>
      </QuestionnaireCard>
    </div>
  )
}
