import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import EnrollmentHeroImg from '@plvs/rally/assets/backgrounds/enrollment-sidebar-hero.svg'
import { Colors } from '@plvs/rally/themes/colors'
import { makeStyles } from '@material-ui/core'
import { Avatar } from '@plvs/rally/components/avatar/Avatar'
import { DisabledEnrollmentCTAStatus } from '@plvs/respawn/containers/enrollment/types'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { EnrollmentFooter } from './EnrollmentFooter'

interface EnrollmentSideBarProps {
  title: string
  children?: React.ReactNode
  avatarSrc?: string
  onButtonPress?: () => void
  footerButtonTitle?: string
  disableButton?: DisabledEnrollmentCTAStatus
  numberOfTeams: number
  error?: Error
  loading?: boolean
  isUnlimited: boolean
  passesNeeded: number
}

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: 396,
    minWidth: 396,
    position: 'fixed',
    right: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      margin: theme.spacing(0, 2, 2),
      position: 'static',
      paddingBottom: theme.spacing(2),
    },
  },
  titleContainer: {
    boxShadow: `0px 1px 0px ${theme.palette.ColorBackgroundAlt2}`,
  },
  avatarContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(-5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  avatar: {
    zIndex: 1,
  },
}))

export const EnrollmentSideBar: React.FC<EnrollmentSideBarProps> = ({
  title,
  children,
  avatarSrc,
  onButtonPress,
  footerButtonTitle,
  disableButton,
  numberOfTeams,
  error,
  loading,
  isUnlimited,
  passesNeeded,
}) => {
  const flags = useFlags()
  const isMobile = useBreakpointSm()
  const classes = useStyles()

  return (
    <Box className={classes.footerContainer}>
      <Box flex={0}>
        {!isMobile && (
          <Box height={131}>
            <MatchImageCard
              borderRadius="0"
              boxShadow="none"
              height="100%"
              src={EnrollmentHeroImg}
            />
          </Box>
        )}
        <Box className={classes.avatarContainer}>
          <Avatar
            backgroundColor={Colors.TeamBlue}
            className={classes.avatar}
            size="large"
            src={avatarSrc}
          />
        </Box>
        <Box className={classes.titleContainer} pb={4} pt={2} px={4}>
          <NxTypography align="center" variant="h2">
            {title}
          </NxTypography>
        </Box>
      </Box>
      <Box
        display="flex"
        flex={flags.freeCompetition ? 0 : 1}
        flexDirection="column"
        overflow={flags.freeCompetition ? 'visible' : 'auto'}
      >
        {children}
      </Box>
      <EnrollmentFooter
        buttonTitle={footerButtonTitle}
        disableButton={disableButton}
        error={error}
        isUnlimited={isUnlimited}
        loading={loading}
        numberOfTeams={numberOfTeams}
        onClick={onButtonPress}
        passesNeeded={passesNeeded}
        subtitle="Player passes are estimated based on your starting roster. If you replace a starting player with a bench player, an additional player pass will be charged when the bench player plays in a match."
      />
    </Box>
  )
}
