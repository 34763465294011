import { Box, makeStyles } from '@material-ui/core'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { Maybe, ResourceType } from '@plvs/graphql/generated/graphql'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { BoxShadow } from '@plvs/rally/themes'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useMemo } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate } from 'react-router-dom'
import { NotificationPill, PillVariants } from '../../NotificationPill'
import { openNotification } from './helpers'

dayjs.extend(relativeTime)

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      boxShadow: BoxShadow.elevation3,
    },
    transition: 'box-shadow 0.5s ease-in-out',
  },
  title: {
    fontWeight: 600,
  },
  logoContainer: {
    padding: '0.5px',
    height: '2.4rem !important',
    width: '2.4rem !important',
    backgroundColor: theme.palette.BorderLight,
    borderColor: theme.palette.BorderLight,
    borderRadius: '50%',
    transition: 'all 0.7s cubic-bezier(0.395, -0.570, 0.000, 1.285)',
    zIndex: 1,
    '&:not(:first-child)': {
      marginLeft: `-.5rem`,
    },
  },
  notificationBodyText: {
    lineHeight: '1.1rem',
    fontWeight: 400,
  },
  timeText: {
    alignSelf: 'center',
    color: theme.palette.ColorTextDisabled,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  statusContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
}))

/**
 * Similar to <SystemMessage/> but ensures a school is selected
 * prior to navigating to the intended target.
 */
export const SchoolSystemMessage: React.FC<{
  IconComponent: React.ReactElement
  message: string
  messageDates: Maybe<string[]>
  receivedAt?: Date
  pillVariant?: PillVariants
  pillText?: string
  url?: string
  helpAction?: () => void
}> = ({
  IconComponent,
  message,
  messageDates,
  pillVariant,
  pillText,
  receivedAt,
  url,
  helpAction,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { id: orgId } = useSelectedOrganizationFn()
  const { setEntity, selectedEntityId } = useProfileContext()

  const onBeforeClick = (): void => {
    if (selectedEntityId !== orgId) {
      setEntity(ResourceType.Organization, orgId)
    }
  }

  let timeText
  if (receivedAt) {
    const receivedDate = dayjs(receivedAt)

    timeText = receivedDate.format('MMM DD, YYYY')
    if (receivedDate.diff(dayjs(), 'hours') < 24) {
      timeText = receivedDate.fromNow()
    }
    timeText = timeText
      .toString()
      .replace('minute', 'min')
      .replace('second', 'sec')
  }
  const formattedMessage = useMemo(() => {
    let updatedContentMessage = message
    messageDates?.forEach((date) => {
      updatedContentMessage = updatedContentMessage.replace(
        `${date}`,
        `${dayjs(date).format('MMMM D @ h:mmA')}`
      )
    })
    return updatedContentMessage
  }, [message, messageDates])

  return (
    <Box
      className={classes.container}
      display="flex"
      onClick={(): void => {
        onBeforeClick()
        openNotification(navigate, url, helpAction)
      }}
      pb={2}
      pt={3}
      px={2}
    >
      <Box display="flex" mr={1.2}>
        {IconComponent}
      </Box>
      <Box>
        <NxTypography className={classes.notificationBodyText} variant="body1">
          {formattedMessage}
        </NxTypography>
        <Box className={classes.statusContainer} mt={1}>
          {pillVariant && pillText && (
            <NotificationPill pillText={pillText} variant={pillVariant} />
          )}
          {timeText && (
            <NxTypography className={classes.timeText} variant="overline">
              {timeText}
            </NxTypography>
          )}
        </Box>
      </Box>
    </Box>
  )
}
