import React, { useEffect } from 'react'
import { EsportSlug, UserRoleName } from '@plvs/graphql'
import { getCompetitionIntervalName } from '@plvs/utils'
import { makeStyles } from '@material-ui/core'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { LeagueFilters } from '@plvs/rally/containers/filter/league/LeagueFilters'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { Path } from '@plvs/const'
import { ReferButton } from '@plvs/rally/containers/onboard'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { useNavigate } from 'react-router-dom'
import { EnrollmentCard } from '../manageTeams/EnrollmentCard'
import { useOrganizationsContext } from '../organization/OrganizationsProvider'
import { CompetitionInterval } from '../match/CompetitionInterval'

const useStyles = makeStyles((theme) => ({
  teamButton: {
    marginLeft: 16,
    marginRight: 16,
  },
  title: {
    marginTop: theme.spacing(1),
  },
}))

export interface TeamsFilterBarProps {
  setEsportSlug(input: EsportSlug | undefined): void
}

export const TeamsFilterBar: React.FC<TeamsFilterBarProps> = ({
  setEsportSlug,
}) => {
  const flags = useFlags()
  const isMobile = useBreakpointSm()
  const { teamButton, title } = useStyles()
  const { metaseasons, competitionInterval } = useManageTeamsContext()
  const { esportSlug } = useSchoolLeagueInfoContext()
  const { competitionModels } = useOrganizationsContext()

  const showCompetitionModels = competitionModels.length > 1
  const navigate = useNavigate()
  const firstDay = competitionInterval?.firstDay
  const lastDay = competitionInterval?.lastDay

  useEffect(() => {
    setEsportSlug(esportSlug)
  }, [esportSlug])

  if (isMobile) {
    return (
      <Box display="flex" flex="1" flexDirection="column">
        <Box height="auto" pb={2} width="auto">
          {flags.activateFullBleedBanners && (
            <Box mb={1} mt={3}>
              <CompetitionInterval />
              <NxTypography className={title} variant="display2">
                Manage Teams
              </NxTypography>
              <NxTypography
                className={title}
                colorToken="ColorTextAlt2"
                variant="body2"
              >
                {firstDay && lastDay
                  ? getCompetitionIntervalName({
                      firstDay,
                      lastDay,
                    })
                  : ''}
              </NxTypography>
            </Box>
          )}
          <EnrollmentCard />
        </Box>
        <LeagueFilters
          metaseasonOptions={metaseasons}
          showMetaseasons={false}
          showTeams={false}
          spacing={2}
        />
      </Box>
    )
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        mt={showCompetitionModels ? 1 : -3}
      >
        <Box display="flex" flex="1" flexDirection="row">
          <Box display="flex" flex="1" flexDirection="row">
            <LeagueFilters
              metaseasonOptions={metaseasons}
              showMetaseasons={false}
              showTeams={false}
              spacing={2}
            />
            <NxButton
              className={teamButton}
              href={Path.CreateTeam}
              label="Create Team"
              onClick={(evt): void => {
                // If command click, allow the browser to just open this in a new window.
                if (!evt.metaKey) {
                  evt.preventDefault()
                  navigate(Path.CreateTeam)
                }
              }}
              variant="primary"
            />
            <Box maxWidth={150}>
              <ReferButton
                refer={UserRoleName.Student}
                source="my_school_page"
                variant="secondary"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
