import React, { useEffect, useRef } from 'react'
import { Box, useTheme } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import {
  useMarkUserNotificationOpenedMutation,
  UserNotification,
} from '@plvs/graphql/generated'
import {
  userNotificationMessageOpened,
  userNotificationMessageRead,
} from '@plvs/respawn/features/analytics'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useStyles as useNotificationStyles } from './NotificationDrawer.styles'
import { createNotification } from '../../notifications/notification-factory/notificationFactory'
import { useStyles } from '../AppDrawerToggle.styles'

const MarkNotificationRead: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  const hasAlreadyNotifiedRef = useRef<boolean>(false)

  const { userId } = useUserIdentityFn()
  useEffect(() => {
    if (notification?.id && !hasAlreadyNotifiedRef.current) {
      userNotificationMessageRead({
        timeStamp: new Date().toDateString(),
        userId: userId || '',
        userNotificationId: notification.id,
        userNotificationType: notification.type,
      })
      hasAlreadyNotifiedRef.current = true
    }
  }, [notification])
  return <></>
}

export const NotificationMessages: React.FC<{
  notifications: UserNotification[]
  title: string
  isMobile: boolean
  onNotificationClick?: () => void
}> = ({ notifications, title, isMobile, onNotificationClick }) => {
  const theme = useTheme()
  const classes = useNotificationStyles({ hasUnreadNotifications: false })

  const { cardContainer } = useStyles({ isMobile })
  const { userId } = useUserIdentityFn()

  const [markNotificationOpened] = useMarkUserNotificationOpenedMutation()

  const handleNotificationClick = (notification: UserNotification): void => {
    onNotificationClick?.()

    userNotificationMessageOpened({
      timeStamp: new Date().toDateString(),
      userId: userId || '',
      userNotificationId: notification.id,
      userNotificationType: notification.type,
      firstOpened: !notification.openedAt,
    })

    markNotificationOpened({
      variables: {
        notificationId: notification.id,
      },
    })
  }

  return (
    <Box p={2}>
      <Box alignItems="center" display="flex" mb={1}>
        <NxTypography variant="h4">{title}</NxTypography>
      </Box>

      {notifications.map((notification) => {
        return (
          <Box
            key={notification.id}
            className={cardContainer}
            data-testid="NotificationsDrawer__Notifications__NotificationEntry"
            mb={2}
            onClickCapture={(): void => {
              handleNotificationClick(notification)
            }}
            position="relative"
          >
            {createNotification(notification, theme.palette.type === 'dark')}
            {!notification.openedAt && (
              <FiberManualRecord
                className={classes.unreadDot}
                data-testid="NotificationsDrawer__Notifications__NotificationEntry__ReadIndicator"
                htmlColor={theme.palette.ColorIconLink}
              />
            )}
            <MarkNotificationRead notification={notification} />
          </Box>
        )
      })}
    </Box>
  )
}
