import { makeVar, ReactiveVar } from '@apollo/client'

export type GlobalUnreadMessageCount = Record<string, number>

export interface GlobalUnreadMessageCountPayload {
  unreadMessagesByConversation: GlobalUnreadMessageCount
  hasAnyUnreadMessages: boolean
}

export interface GlobalUnreadMessagesVarPayload {
  unreadMessagesByConversation: GlobalUnreadMessageCount
}

export const globalUnreadMessagesVar: ReactiveVar<GlobalUnreadMessagesVarPayload> = makeVar<
  GlobalUnreadMessagesVarPayload
>({
  unreadMessagesByConversation: {},
})

export type ActiveAndExpanded = {
  active: boolean
  expanded: boolean
}
export type ActiveGlobalConversations = Record<string, ActiveAndExpanded>

export interface ActiveGlobalConveresationsVarPayload {
  activeConversations: ActiveGlobalConversations
}

export const activeGlobalConversationsVar: ReactiveVar<ActiveGlobalConveresationsVarPayload> = makeVar<
  ActiveGlobalConveresationsVarPayload
>({
  activeConversations: {},
})

export const activeGlobalWindowsVar: ReactiveVar<Array<string>> = makeVar<
  Array<string>
>([])
