import React from 'react'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'

import {
  BracketResult,
  TAIL_WIDTH,
} from '@plvs/rally/features/standings/standingsHelpers'
import { EsportRating } from '@plvs/graphql/generated'
import { BracketResultPod } from './BracketResultPod'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  pod: {
    position: 'relative',
    width: '100%',
  },
  tail: {
    borderTop: `1px solid ${Colors.Grey12}`,
    content: '""',
    position: 'absolute',
    top: '50%',
    left: -20,
  },
})

export const BracketResultPairSingle: React.FC<{
  bracketResults: BracketResult[]
  isFirstSlot: boolean
  slotIndex: number
  slotsLength?: number
}> = ({ bracketResults, isFirstSlot, slotIndex, slotsLength }) => {
  const classes = useStyles()

  const { status, team, esportRating } = bracketResults[0]
  const isWinningBracket = slotIndex === slotsLength

  const tailStyle = useSpring({ width: isFirstSlot ? 0 : TAIL_WIDTH })

  return (
    <animated.div key={`slot-${slotIndex}-pair-0`} className={classes.root}>
      <div className={classes.pod}>
        <animated.div className={classes.tail} style={tailStyle} />
        <BracketResultPod
          key={`slot-${slotIndex}-team-${0}`}
          esportRating={esportRating || EsportRating.General}
          isWinningBracket={isWinningBracket}
          matchId={null}
          noOpponent
          status={status}
          team={team}
          top
        />
      </div>
    </animated.div>
  )
}
