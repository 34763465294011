import React from 'react'

import { AssignEsportForUserButton } from './AssignEsportForUserButton'

interface EsportEntry {
  id: string
  slug: string
}

export type UserEsportsProps = {
  userId: string
  hasSchool: boolean
  esportInterests: EsportEntry[]
}

export const UserEsports: React.FC = ({ ...playerProps }) => {
  // Using cast to any since I'm taking raw row data instead of
  // using an accessor for this table column.  This is done because
  // the data here is not flat single value that react-table normally
  // expects.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasSchool = !!(playerProps as any).school
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const esportInterests = (playerProps as any).esportInterests || []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userId = (playerProps as any).id

  return (
    <AssignEsportForUserButton
      esportInterests={esportInterests}
      hasSchool={hasSchool}
      userId={userId}
    />
  )
}
