import React from 'react'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { PlayerTopPerformers } from '@plvs/rally/components/match'
import { useGetTopPerformersStatsByGameIdQuery } from '@plvs/graphql/generated'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

interface GameTopPerformersProps {
  gameId: string
}

export const GameTopPerformers: React.FC<GameTopPerformersProps> = ({
  gameId,
}) => {
  const { data, loading } = useGetTopPerformersStatsByGameIdQuery({
    variables: { gameId },
    skip: !gameId,
  })

  const { topPerformerCategories } = useEsportContext()

  const playerGameStats = data?.game?.playerGameStats ?? []
  const playersWithGameStats = playerGameStats.filter(({ stats }) => stats)
  const categories = topPerformerCategories ?? []

  const tableData = playersWithGameStats.map(({ player, stats }) => {
    return {
      player: {
        avatarUrl: player?.avatarUrl ?? null,
        name: player?.name || 'N/A',
        id: player?.id ?? '',
      },
      stats,
    }
  })

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      <PlayerTopPerformers categories={categories} data={tableData} />
    </WaitTillLoaded>
  )
}
