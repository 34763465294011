import { Colors, alpha } from '../colors'

export const MuiCard = {
  root: {
    boxShadow: `0px 2px 4px ${alpha(Colors.Black, 0.04)}, 0px 2px 16px ${alpha(
      Colors.Black,
      0.02
    )};`,
  },
}
