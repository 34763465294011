import type { NodeEnv } from './types'
import { createSafeEnv, getApiEnv, IS_BROWSER } from './utils'

declare global {
  interface Window {
    // We can't claim for certain that `window.env` is type `Env`--nor would we
    //  want to!
    // Developers shouldn't rely on access to `window.env`; they should
    //  instead consume the `const env` exported by this package.
    env: Record<string, unknown>
  }
}

export interface Env {
  COOKIE_DOMAIN: string
  OAUTH_COOKIE_DOMAIN: string
  IS_BROWSER: boolean
  IS_NODE_ENV_PRODUCTION: boolean
  IS_NODE_ENV_DEVELOPMENT: boolean
  NODE_ENV: NodeEnv
  ENVIRONMENT_NAME: string
}

// Every app that adds it's own set env vars of should call `createSafeEnv`
//  like this package does.
const safeEnv = createSafeEnv<Env>({
  COOKIE_DOMAIN: '',
  OAUTH_COOKIE_DOMAIN: '',
  IS_BROWSER,
  IS_NODE_ENV_PRODUCTION: false,
  IS_NODE_ENV_DEVELOPMENT: true,
  NODE_ENV: 'development',
  ENVIRONMENT_NAME: 'local',
})

const NODE_ENV = safeEnv.NODE_ENV || 'development'

export const env: Env = {
  COOKIE_DOMAIN: safeEnv.COOKIE_DOMAIN || 'localhost',
  OAUTH_COOKIE_DOMAIN:
    safeEnv.OAUTH_COOKIE_DOMAIN ||
    (NODE_ENV === 'production' ? 'playvs.com' : 'staging.plvs.io'),
  IS_BROWSER,
  IS_NODE_ENV_PRODUCTION: NODE_ENV === 'production',
  IS_NODE_ENV_DEVELOPMENT: NODE_ENV === 'development',
  NODE_ENV,
  ENVIRONMENT_NAME: safeEnv.ENVIRONMENT_NAME || 'local',
}

// Note: this is to allow for mocking of environment variables in tests
export function getEnv(): Env {
  return env
}

export const DISABLE_IN_PRODUCTION = !env.IS_NODE_ENV_PRODUCTION

export const LOCAL_API_URL = 'http://localhost:4000'

export const LOCAL_WS_URL = 'ws://localhost:4000'

export const LOCAL_APM_SERVER_URL = 'http://localhost:8200'

export { NodeEnv, createSafeEnv, getApiEnv, IS_BROWSER }

export const contentSecurityPolicyDirectives = {
  defaultSrc: [
    "'self'",
    "'unsafe-inline'",
    'data:',
    'http://localhost:3003/',
    'http://localhost:3004/',
    'http://localhost:3007/',
    'http://localhost:4000',
    'https://api-iam.intercom.io/',
    'https://app.launchdarkly.com/',
    'https://clientstream.launchdarkly.com/',
    'https://events.launchdarkly.com/',
    'https://meetings.grooveapp.com/',
    'https://posthog.plasmic.app/',
    'https://codegen.plasmic.app/',
    'https://services.sheerid.com/',
    'https://sentry.io/',
    'https://api.segment.io',
    'https://cdn.segment.com/',
    'https://player.twitch.tv/',
    'https://www.youtube.com',
    'https://mcs.us1.twilio.com',
    'ws://localhost:3004/',
    'ws://localhost:3007/',
    'wss://tsock.us1.twilio.com/',
    'wss://nexus-websocket-a.intercom.io/',
  ],
  scriptSrc: [
    "'self'",
    "'unsafe-eval'",
    "'unsafe-inline'",
    'http://localhost:3004/',
    'http://localhost:3007/',
    'https://cdn.heapanalytics.com/',
    'https://js.intercomcdn.com/',
    'https://widget.intercom.io/',
    'https://clientstream.launchdarkly.com/',
    'https://posthog.plasmic.app/',
    'https://player.twitch.tv/',
    'https://www.youtube.com',
    'https://cdn.segment.com/',
    'https://cdn.cookielaw.org',
  ],
  imgSrc: [
    "'self'",
    'data:',
    'https:',
    'http://localhost:3003/',
    'http://localhost:3004/',
    'http://localhost:3007/',
    'https://s3.amazonaws.com/',
    'https://playvs-media.s3.amazonaws.com/',
    'https://playvs-onboarding.s3.amazonaws.com/',
    'https://site-assets.plasmic.app/',
    'https://static-cdn.jtvnw.net',
    'https://i.ytimg.com',
  ],
  frameSrc: ['youtube.com', 'www.youtube.com'],
  fontSrc: [
    "'self'",
    'data:',
    'http://localhost:3003/',
    'http://localhost:3004/',
    'http://localhost:3007/',
  ],
  styleSrc: ["'self'", "'unsafe-inline'", 'https://fonts.googleapis.com/'],
}
