import { makeStyles, useTheme } from '@material-ui/core'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import React from 'react'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { Tooltip } from '@plvs/rally/components/tooltip'

import * as analytics from '@plvs/respawn/features/analytics'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Regularcheck } from '@playvs-inc/nexus-icons'

interface ScreenProps {
  mobile: boolean
}

const useStyles = makeStyles((theme) => ({
  successContainter: ({ mobile }: ScreenProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: mobile ? 'center' : 'unset',
  }),
  ctaCompleted: {
    color: theme.palette.ColorTextSuccess,
  },
  button: {
    width: '100%',
    textTransform: 'none',
  },
  tooltip: ({ mobile }: ScreenProps): CreateCSSProperties => ({
    width: mobile ? '100%' : 'max-content',
  }),
}))

type TaskCtaButtonProps = {
  onClick: () => void
  buttonText: string
  completed: boolean
  task: { state: string; id: string; type: string }
  disabled?: boolean
  tooltipText?: string | Element | React.ReactElement
}

export const TaskCtaButton: React.FC<TaskCtaButtonProps> = ({
  completed,
  onClick,
  buttonText,
  disabled = false,
  tooltipText,
  task,
}) => {
  const { userId, orgId } = useUserIdentityFn()
  const mobile = useBreakpointXs()
  const classes = useStyles({ mobile })
  const theme = useTheme()

  const onTaskSelected = (): void => {
    onClick()
    analytics.gettingStartedTaskOpened({
      userId,
      userTaskId: task.id,
      userTaskType: task.type,
      schoolId: orgId ?? '',
    })
  }

  return !completed ? (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip
        className={classes.tooltip}
        placement="bottom"
        title={tooltipText ?? ''}
      >
        <NxButton
          className={classes.button}
          data-testid="TaskCtaButton__Button"
          disabled={disabled}
          label={buttonText}
          onClick={onTaskSelected}
          variant="secondary"
        />
      </Tooltip>
    </Box>
  ) : (
    <Box className={classes.successContainter}>
      <Box data-testid="TaskCtaButton__CompletedLabel">
        <Regularcheck
          color={theme.palette.ColorTextSuccess}
          height="20"
          width="20"
        />
      </Box>
      <Box>
        <NxTypography className={classes.ctaCompleted} variant="body4">
          Task has been completed
        </NxTypography>
      </Box>
    </Box>
  )
}
