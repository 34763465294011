/* istanbul ignore file */
// This takes an unusally large amount of low level mocking in order to test.
// At this point it would be more test code written against the specific
// implementation to be worth the effort/return from such a test.

import { ApolloLink } from '@apollo/client'
import { rallyEnv } from '@plvs/rally/env'
import { createUploadLink } from 'apollo-upload-client'

export const uploadLink = (jwt?: string, requestUrl?: string): ApolloLink =>
  (createUploadLink({
    headers: {
      'Apollo-Require-Preflight': 'true',
      ...(jwt ? { authorization: `Bearer ${jwt}` } : {}),
    },
    uri: requestUrl ? `${requestUrl}/graphql` : rallyEnv.GRAPHQL_URI,
    credentials: 'include',
  }) as unknown) as ApolloLink
