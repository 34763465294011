import { TableColumn } from '@plvs/rally/components/table/Table'
import {
  UserAccountConnected as UserAccountConnectedCell,
  UserAccountConnectedProps,
} from '../cell/UserAccountConnected'

export const UserAccountConnected: TableColumn<UserAccountConnectedProps> = {
  id: 'accountConnected',
  width: 162,
  Header: 'Esport Interest',
  Cell: UserAccountConnectedCell,
}
