import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  refetchGetNotificationCenterSummaryQuery,
  useGetIncompleteNotificationTasksQuery,
  useGetNotificationCenterQuery,
  useMarkAllUserNotificationsOpenedMutation,
  useMarkAllUserNotificationsReadMutation,
  UserNotification,
} from '@plvs/graphql/generated'
import { CarretDown, Notifications } from '@playvs-inc/nexus-icons'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { useScrolledIntoView } from '@plvs/rally/libs/interactions/useScrolledIntoView'
import { assert } from '@plvs/utils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import dayjs from '@plvs/rally/init/dayjs'
import { EmptyNotifications } from './EmptyNotifications'
import { LoadingSkeleton } from './LoadingSkeleton'
import { NotificationMessages } from './NotificationMessages'
import { NotificationTasks } from './NotificationTasks'
import { useStyles } from './NotificationDrawer.styles'
import { DrawerWrapper } from '../DrawerWrapper'

const DEFAULT_PAGE_SIZE = 20

export const NotificationDrawer: React.FC<{
  close?: () => void
  uncompletedTaskCount?: number | null | undefined
}> = ({ close, uncompletedTaskCount }) => {
  const {
    isSupported: scrollIntoViewSupported,
    isVisible: loadingVisible,
    ScrolledIntoViewTrigger,
  } = useScrolledIntoView()
  const isMobile = useBreakpointSm()

  const lastEndCursorRef = useRef<string>()

  const [markRead] = useMarkAllUserNotificationsReadMutation({
    refetchQueries: [refetchGetNotificationCenterSummaryQuery()],
  })

  const {
    data,
    fetchMore,
    loading,
    called,
    refetch,
  } = useGetNotificationCenterQuery({
    variables: {
      first: DEFAULT_PAGE_SIZE,
    },
    // Used to allow users to quickly see their notifications in the cache while
    // the request is in progress.  Generally this will be up to date as we have
    // a polling refetch in the <AppDrawerNotificationsToggle/> component.  This
    // is an extra fetch in case new notifications arrive when the user is opening
    // their drawer but after the last polling.
    fetchPolicy: 'cache-and-network',
    // This is to allow `loading` status to change back to loading when fetchMore()
    // is called during infinite scroll.
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: tasksData,
    loading: tasksLoading,
    called: tasksCalled,
  } = useGetIncompleteNotificationTasksQuery({
    variables: {
      first: 100, // TODO: optimize pagination for tasks https://playvs.atlassian.net/browse/GP-1876
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const [
    markAllUserNotificationsOpened,
  ] = useMarkAllUserNotificationsOpenedMutation()

  const handleMarkAllOpenedClick = async (): Promise<void> => {
    const result = await markAllUserNotificationsOpened()
    if (result?.data?.markAllUserNotificationsOpened?.success) {
      refetch()
    }
  }

  const pageInfo = data?.userNotifications.notifications?.pageInfo
  const firstLoadNotComplete = !called || (!data && loading)
  const firstTaskLoadNotComplete = !tasksCalled || (!tasksData && tasksLoading)

  const notifications: UserNotification[] =
    data?.userNotifications.notifications?.edges.map((e) => e.node) ?? []

  const hasNotifications = notifications?.length > 0

  const hasUnreadNotifications = notifications.some(
    (notification) => !notification.openedAt
  )

  const classes = useStyles({ hasUnreadNotifications })

  const todaysNotifications = notifications.filter((n) =>
    dayjs(n.createdAt).isAfter(dayjs().startOf('day'))
  )
  const olderNotifications = notifications.filter((n) =>
    dayjs(n.createdAt).isBefore(dayjs().startOf('day'))
  )

  const tasks: UserNotification[] =
    tasksData?.getIncompleteNotificationTasks?.edges?.map((e) => e.node) ?? []

  useEffect(() => {
    if ((data?.userNotifications.notifications?.edges.length ?? 0) > 0) {
      // Mark read using the most recent message as the cursor.
      assert(data?.userNotifications.notifications?.edges[0])
      markRead({
        variables: {
          notificationId: data.userNotifications.notifications.edges[0].node.id,
        },
      })
    }
  }, [data?.userNotifications.notifications?.edges])

  // Using Callback to prevent stale values if listener triggered
  // during mid render cycle.
  const handlePaginate = useCallback((): void => {
    if (
      pageInfo?.hasNext &&
      !!pageInfo?.endCursor &&
      lastEndCursorRef.current !== pageInfo?.endCursor
    ) {
      lastEndCursorRef.current = pageInfo?.endCursor
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      })
    }
  }, [loading, pageInfo])

  useEffect(() => {
    if (loading || !loadingVisible) {
      return
    }
    handlePaginate()
  }, [loadingVisible, loading])

  const [isTaskSectionAnimating, setIsTaskSectionAnimating] = useState(true)

  if (firstLoadNotComplete || firstTaskLoadNotComplete) {
    return <LoadingSkeleton />
  }

  return (
    <DrawerWrapper
      ContainerIcon={Notifications}
      containerTitle="Notifications"
      EmptyState={EmptyNotifications}
      isMobile={isMobile}
      onClose={close}
      renderEmptyState={!uncompletedTaskCount && !notifications?.length}
    >
      {/* Task Section */}
      {typeof uncompletedTaskCount === 'number' && uncompletedTaskCount > 0 && (
        <Box>
          <Accordion
            className={classes.taskSection}
            defaultExpanded
            onChange={(evt, isExpanded): void => {
              if (isExpanded) {
                setIsTaskSectionAnimating(true)
              } else {
                setIsTaskSectionAnimating(false)
              }
            }}
            square
          >
            <AccordionSummary
              aria-controls="task-section-content"
              expandIcon={<CarretDown color="#fff" fontSize="1.5rem" />}
              id="task-section-header"
            >
              <Box ml={isMobile ? 2 : 0}>
                <NxTypography className={classes.taskTitle} variant="h4">
                  Needs Attention ({uncompletedTaskCount || 0})
                </NxTypography>
              </Box>
            </AccordionSummary>
            <AccordionDetails id="task-section-content">
              <NotificationTasks
                isAnimating={isTaskSectionAnimating}
                isMobile={isMobile}
                onNotificationClick={close}
                tasks={tasks}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {/* Match Notifications */}
      <Box ml={isMobile ? 2 : 0} position="relative">
        {hasNotifications && (
          <NxTypography
            className={classes.markAllRead}
            onClick={handleMarkAllOpenedClick}
            variant="body3"
          >
            Mark all as read
          </NxTypography>
        )}
        {todaysNotifications.length > 0 && (
          <NotificationMessages
            isMobile={isMobile}
            notifications={todaysNotifications}
            onNotificationClick={close}
            title="Today"
          />
        )}
        {olderNotifications.length > 0 && (
          <NotificationMessages
            isMobile={isMobile}
            notifications={olderNotifications}
            onNotificationClick={close}
            title="Older"
          />
        )}
        {((scrollIntoViewSupported && pageInfo?.hasNext) || loading) && (
          <Box mx={2} pb={3}>
            {scrollIntoViewSupported && <ScrolledIntoViewTrigger />}
            <Skeleton
              className={classes.fetchMoreNotifications}
              component="div"
              height="5em"
              variant="rect"
              width="100%"
            />
          </Box>
        )}
        {!scrollIntoViewSupported && pageInfo?.hasNext && !loading && (
          <Button variant="text">View older notifications</Button>
        )}
      </Box>
    </DrawerWrapper>
  )
}
