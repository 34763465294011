import React from 'react'
import { Card, CardContent, makeStyles, useTheme } from '@material-ui/core'
import { Param, Path } from '@plvs/const'
import { EsportSlug } from '@plvs/graphql/types'
import { Box } from '@plvs/respawn/features/layout/Box'
import {
  CreateteamDarkLargePrimary,
  CreateteamLightLargePrimary,
} from '@playvs-inc/nexus-spots'
import { NxTypography, NxButton, NxTooltip } from '@playvs-inc/nexus-components'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  createTeamSpot: {
    marginRight: theme.spacing(3),
    width: 100,
  },
  spacingBottom: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  esportSlug: EsportSlug | undefined
  isCoachAtOrg: boolean
}

export const CreateTeamCard: React.FC<Props> = ({
  esportSlug,
  isCoachAtOrg,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const handleCreateTeamClick = (): void =>
    navigate(`${Path.CreateTeam}?${Param.Esport}=${esportSlug}`)

  const CreateTeamButton = (
    <NxButton
      color="primary"
      data-testid="LeagueDetails_CreateTeamButton"
      disabled={!isCoachAtOrg}
      label="Create a Team"
      onClick={handleCreateTeamClick}
      variant="secondary"
    />
  )

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Box alignItems="center" display="flex">
          {isDarkMode ? (
            <CreateteamDarkLargePrimary className={classes.createTeamSpot} />
          ) : (
            <CreateteamLightLargePrimary className={classes.createTeamSpot} />
          )}
          <Box display="flex" flexDirection="column">
            <NxTypography className={classes.spacingBottom} variant="h4">
              Create a Team
            </NxTypography>
            <NxTypography variant="body3">
              We&apos;ve got esports for everyone!
            </NxTypography>
            <NxTypography className={classes.spacingBottom} variant="body3">
              Keep building out your school teams.
            </NxTypography>
            {isCoachAtOrg ? (
              CreateTeamButton
            ) : (
              <NxTooltip arrow title="You must be a coach to create a team.">
                <div>{CreateTeamButton}</div>
              </NxTooltip>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
