import React, { useContext, useEffect } from 'react'
import { School, useIdentifyQuery } from '@plvs/graphql'
import { LocalStorageKey } from '@plvs/const'
import { isFacultyForOrganization } from '@plvs/utils'
import { useLocation, useNavigate } from 'react-router'
import * as analytics from './master'
import { sentryContext } from './sentry/initSentry'
import * as segment from './segment/segment'

// 1. create a listener for page views (note: this doesn't cover the initial page view)
// 2. identify user
// 3. group user (by school)
// 4. initialize intercom
// 5. identify user in sentry
// 6. set isUnderage if not already set

const Analytics: React.FC = () => {
  // TODO - GP-4086 - on or after 7/8/2024, remove the cookie fallback
  const location = useLocation()
  const navigate = useNavigate()
  const { client: Sentry } = useContext(sentryContext)
  const { data, loading } = useIdentifyQuery()

  // Redirect users in stadium if they are not authenticated for joining team
  if (
    !loading &&
    !data?.me &&
    location.search.includes('utm_source=event_team_creation') &&
    location.pathname.includes('/app/event')
  ) {
    navigate(`/signup${location.search}&next=${location.pathname}`)
  }

  // Ran only on mount
  // https://reactrouter.com/en/main/hooks/use-location
  useEffect(() => {
    // 1
    segment.page()
  }, [location])

  useEffect(() => {
    if (!data || !data.me || loading) return
    const { school, ...rest } = data.me
    // 2
    analytics.identify({ schoolId: school?.id, ...rest })

    // 3
    if (school) {
      analytics.group(school as School)
    }

    // 4
    if (isFacultyForOrganization(data.me.roles || [], [school?.id ?? ''])) {
      analytics.bootIntercom({ ...rest })
    }

    // 5
    const { id, roles } = rest
    const userRoles = (roles || []).map((userRole) => userRole.roleName)
    Sentry.setUser({ id, userRoles })

    // 6
    const isUnderage = data.me?.isUnderage ?? false
    localStorage.setItem(LocalStorageKey.IsUnderage, JSON.stringify(isUnderage))
  }, [data, loading])

  return null
}

export default Analytics
