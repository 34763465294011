import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { useGetRosterAcrossAllTeamsQuery } from '@plvs/graphql'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Coach, Player } from './helper'
import { CoachRow } from './CoachRow'
import { PlayerRow } from './PlayerRow'
import { RowsLoading } from './RowsLoading'

const useStyles = makeStyles({
  viewAll: {
    marginLeft: 'auto',
  },
})

const COACH_LIMIT = 2
const PLAYER_LIMIT = 5

export const SchoolRoster: React.FC<{
  schoolId: string
  isPublicPage: boolean
}> = ({ schoolId, isPublicPage }) => {
  const flags = useFlags()
  const isPlayerProfileEnabled = flags.playerProfile
  const classes = useStyles()

  const [numCoaches, setNumCoaches] = useState(COACH_LIMIT)
  const [numPlayers, setNumPlayers] = useState(PLAYER_LIMIT)

  const { data, loading } = useGetRosterAcrossAllTeamsQuery({
    variables: {
      id: schoolId,
      isPublic: isPublicPage,
    },
    skip: !schoolId,
  })

  const showCoachEmails = data?.school?.showCoachEmails ?? false

  const coaches: Coach[] = data?.school?.coaches ?? []
  const players: Player[] = data?.school?.players ?? []
  const coachSize = coaches.length
  const playersSize = players.length

  const displayCoaches = coaches.slice(0, numCoaches)
  const displayPlayers = players.slice(0, numPlayers)

  const hasClickedCoachViewAll = numCoaches === coachSize
  const hasClickedPlayerViewAll = numPlayers === playersSize

  const handleViewAll = (type: 'coach' | 'player'): void => {
    if (type === 'coach') {
      if (hasClickedCoachViewAll) {
        setNumCoaches(COACH_LIMIT)
      } else {
        setNumCoaches(coachSize)
      }
    } else if (type === 'player') {
      if (hasClickedPlayerViewAll) {
        setNumPlayers(PLAYER_LIMIT)
      } else {
        setNumPlayers(playersSize)
      }
    }
  }

  return (
    <MatchCard data-testid="SchoolRoster">
      <Box alignItems="center" display="flex" pb={2} pt={2}>
        <NxTypography variant="h4">
          Coaches {!loading && <>({coachSize})</>}
        </NxTypography>
        {coachSize > COACH_LIMIT && (
          <NxButton
            className={classes.viewAll}
            data-testid="view-all-coaches"
            label={hasClickedCoachViewAll ? 'View Less' : 'View All'}
            onClick={(): void => handleViewAll('coach')}
            variant="text"
          />
        )}
      </Box>
      <Box pb={3}>
        {loading ? (
          <RowsLoading numberOfRows={2} />
        ) : (
          <>
            {coachSize > 0 ? (
              <Box display="flex" flexDirection="column" gridGap={16}>
                {displayCoaches.map((coach) => (
                  <CoachRow
                    key={coach.id}
                    coach={coach}
                    showCoachEmails={showCoachEmails}
                  />
                ))}
              </Box>
            ) : (
              <NxTypography variant="body1">No coaches yet</NxTypography>
            )}
          </>
        )}
      </Box>
      <Box alignItems="center" display="flex" pb={2}>
        <NxTypography variant="h4">
          Players {!loading && <>({playersSize})</>}
        </NxTypography>
        {playersSize > PLAYER_LIMIT && (
          <NxButton
            className={classes.viewAll}
            data-testid="view-all-players"
            label={hasClickedPlayerViewAll ? 'View Less' : 'View All'}
            onClick={(): void => handleViewAll('player')}
            variant="text"
          />
        )}
      </Box>
      <Box pb={3}>
        {loading ? (
          <RowsLoading numberOfRows={5} />
        ) : (
          <>
            {playersSize > 0 ? (
              <Box
                data-testid="SchoolRoster_Players"
                display="flex"
                flexDirection="column"
                gridGap={16}
              >
                {displayPlayers.map((player) => (
                  <PlayerRow
                    key={player.id}
                    isPlayerProfileEnabled={isPlayerProfileEnabled}
                    player={player}
                  />
                ))}
              </Box>
            ) : (
              <NxTypography variant="body1">No players yet</NxTypography>
            )}
          </>
        )}
      </Box>
    </MatchCard>
  )
}
