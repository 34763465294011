// Since these are accessors that apply to any data type.
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import {
  formatDuration,
  getAccountProviderUsername,
  Percent,
  Comma,
  RoundToOneDecimalPlace,
} from '@plvs/utils'

import { Colors } from '@plvs/rally/themes'
import { UserCluster, TeamCluster } from '@plvs/rally/components/cluster'
import {
  FortniteTeamMatchStats,
  TeamMatchStats,
  Provider,
  PhaseFormat,
} from '@plvs/graphql'

import { MiniBadge } from '../../badge/miniBadge'
import { TableColumn } from '../Table'

import { Fire } from '../../icon'

const clutchBadge = (
  <MiniBadge
    backgroundStyle="linear-gradient(180deg, #B254FB 0%, #66239A 100%)"
    title={
      <>
        This team has qualified for the championship <br />
        by placing in a week’s top 3 teams.
      </>
    }
  >
    <Fire
      htmlColor={Colors.White}
      style={{
        height: 8,
        width: 8,
      }}
    />
  </MiniBadge>
)

const PlayVsCupBadge = (
  <MiniBadge
    backgroundStyle="linear-gradient(180deg, #FF5509 0%, #FF5509 100%)"
    title={
      <>
        This team is advancing to the next round of <br />
        this competition.
      </>
    }
  >
    <Fire
      htmlColor={Colors.White}
      style={{
        height: 8,
        width: 8,
      }}
    />
  </MiniBadge>
)

type FnTeamClusterProps = TeamMatchStats & {
  stats: Pick<FortniteTeamMatchStats, 'clutched' | 'rank'>
  format: string
  survivalRankingNumber: number
}

export const FnTeamCluster: TableColumn<FnTeamClusterProps> = {
  accessor: 'team.name',
  Cell: ({
    stats,
    team,
    format,
    survivalRankingNumber,
  }: FnTeamClusterProps): React.ReactElement => {
    const isSurvivalFormat = format === PhaseFormat.Survival
    const winnerBadge = isSurvivalFormat ? PlayVsCupBadge : clutchBadge
    const isClutched = isSurvivalFormat
      ? stats.rank <= survivalRankingNumber
      : stats.clutched

    return team ? (
      <div style={{ padding: '16px 0' }}>
        <TeamCluster
          avatarUrl={team.school?.logoUrl ?? ''}
          badge={isClutched ? winnerBadge : undefined}
          subtitle={team.school?.name ?? 'N/A'}
          title={team.name || 'N/A'}
        />
      </div>
    ) : (
      <>-</>
    )
  },
  Header: 'Team',
  id: 'team',
  minWidth: 360,
}

export const FnRosterPlayer: TableColumn<any> = {
  accessor: 'name',
  Cell: (member) => {
    const { avatarUrl, isCaptain, name, id } = member
    const username = getAccountProviderUsername(member, Provider.EpicGames)
    return (
      <UserCluster
        avatarUrl={avatarUrl}
        id={id}
        isCaptain={isCaptain}
        subtitle={username}
        title={name}
      />
    )
  },
  Header: 'Player',
  minWidth: 320,
}

export const FnTeam: TableColumn<any> = {
  id: 'team',
  Cell: ({ team }) => <>{team ? team.name : null}</>,
  Header: 'Team',
  minWidth: 200,
  sortMethod: (a: any | undefined, b: any | undefined): number =>
    (a?.team?.name ?? '') > (b?.team?.name ?? '') ? 1 : 0,
}

export const FnRank: TableColumn<any> = {
  accessor: 'stats.rank',
  Header: 'Pos',
  HeaderTooltip: 'Rank is determined by Points',
  style: { textAlign: 'center', width: '100%' },
  width: 50,
}

export const FnWin: TableColumn<any> = {
  accessor: 'stats.totalVictoryRoyales',
  Header: 'Wins',
  HeaderTooltip: 'Number of times placed 1st',
}

export const FnWinPercentage: TableColumn<any> = {
  accessor: ({ stats }) =>
    stats?.sessionsPlayed > 0
      ? stats?.totalVictoryRoyales / stats?.sessionsPlayed
      : 0,
  id: 'fnWinPercentage',
  format: Percent,
  Header: 'Win %',
  HeaderTooltip: 'Wins divided by Matches Played',
}

export const FnPoints: TableColumn<any> = {
  accessor: 'stats.totalPoints',
  format: Comma,
  Header: 'Pts',
  HeaderTooltip: 'Total Points',
}

export const FnAvgElims: TableColumn<any> = {
  accessor: 'stats.avgEliminationsPerSession',
  format: RoundToOneDecimalPlace,
  Header: 'Avg Elims',
  HeaderTooltip: 'Average Eliminations per Game',
}

export const FnEliminationPoints: TableColumn<any> = {
  accessor: 'stats.eliminationPoints',
  format: RoundToOneDecimalPlace,
  Header: 'K',
  HeaderTooltip: 'Total Elimination Points',
}

export const FnAvgPoints: TableColumn<any> = {
  accessor: 'stats.avgPointsPerSession',
  format: RoundToOneDecimalPlace,
  Header: 'Avg Pts',
  HeaderTooltip: 'Average Points per Game',
}

export const FnAvgPlace: TableColumn<any> = {
  accessor: 'stats.avgPlacementPerSession',
  format: RoundToOneDecimalPlace,
  Header: 'Avg Place',
  HeaderTooltip: 'Average Place per Game',
}

export const FnGamesPlayed: TableColumn<any> = {
  accessor: 'stats.sessionsPlayed',
  Header: 'Games',
  HeaderTooltip: 'Games Played',
}

export const FnTimePlayed: TableColumn<any> = {
  accessor: 'stats.totalSecondsSurvived',
  format: (s: number) => formatDuration({ hideSeconds: true, ms: s * 1000 }),
  Header: 'Play Time',
  HeaderTooltip: 'Total time survived',
}

export const FnState: TableColumn<any> = {
  accessor: 'team.school.state',
  Header: 'State',
}
