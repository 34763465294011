import React, { useEffect, useState } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

interface QueueTimerProps {
  variant: NxTypographyVariant
  countdownTime: Dayjs
  onCountdownComplete(): Promise<void>
  onTenSecondsLeft?(): Promise<void>
  slotLobbyQueueDate?: string
}

export const QueueTimer: React.FC<QueueTimerProps> = ({
  variant,
  countdownTime,
  onCountdownComplete,
  onTenSecondsLeft,
  slotLobbyQueueDate,
}) => {
  const calculateTimeLeft = (): plugin.Duration => {
    const now = dayjs()
    if (now >= countdownTime) {
      return dayjs.duration(0)
    }
    return dayjs.duration(countdownTime.diff(now))
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  const [countdownFired, setCountdownFired] = useState(false)
  const [tenSecondsLeftFired, setTenSecondsLeftFired] = useState(false)

  useEffect(() => {
    setCountdownFired(false)
    setTenSecondsLeftFired(false)
  }, [countdownTime])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timerInterval = setTimeout(() => {
      const nextTimeLeft = calculateTimeLeft()
      setTimeLeft(nextTimeLeft)
      if (nextTimeLeft.asSeconds() <= 0 && !countdownFired) {
        onCountdownComplete()
        setCountdownFired(true)
      }
      // function will return decimal seconds,
      // so check at 11 so display matches
      if (
        nextTimeLeft.asSeconds() <= 11 &&
        onTenSecondsLeft &&
        !tenSecondsLeftFired
      ) {
        onTenSecondsLeft()
        setTenSecondsLeftFired(true)
      }
    }, 500)
    return (): void => {
      clearTimeout(timerInterval)
    }
  }, [countdownTime, timeLeft])

  const displayTimeLeft = (): string => {
    let display = ''
    if (timeLeft.asHours() > 24 && slotLobbyQueueDate) {
      display = slotLobbyQueueDate
    } else {
      if (timeLeft.hours()) {
        display += `${Math.floor(timeLeft.asHours())}:`
      }
      let minutesFormat = 'm'
      if (timeLeft.minutes()) {
        if (timeLeft.hours() > 0) {
          minutesFormat = 'mm'
        }
        display += `${timeLeft.format(minutesFormat)}:`
      } else if (timeLeft.hours() > 0) {
        display += '00:'
      } else {
        display += '0:'
      }
      if (timeLeft.seconds()) {
        display += timeLeft.format('ss')
      } else {
        display += '00'
      }
    }
    return display
  }

  return (
    <NxTypography
      colorToken="OverlayColorTextBase"
      data-cy="queueTimer"
      variant={variant}
    >
      {displayTimeLeft()}
    </NxTypography>
  )
}
