import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import {
  CreateteamLightSmallPrimary,
  CreateteamDarkSmallPrimary,
} from '@playvs-inc/nexus-spots'
import dayjs from 'dayjs'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'

export const FillTeamRosterAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Fill Team Roster',

  createNotification(
    notification: UserNotification,
    isDark?: boolean
  ): React.ReactElement {
    return (
      <SystemMessage
        IconComponent={
          isDark ? (
            <CreateteamDarkSmallPrimary height="4rem" />
          ) : (
            <CreateteamLightSmallPrimary height="4rem" />
          )
        }
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        receivedAt={dayjs(notification.createdAt).toDate()}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
