import React from 'react'
import clsx from 'clsx'
import dayjs from '@plvs/rally/init/dayjs'
import tz from 'dayjs/plugin/timezone'
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { formatInTz } from '@plvs/utils'
import {
  NxTypography,
  NxEsportAvatar,
  NxTooltip,
} from '@playvs-inc/nexus-components'
import {
  ArenaDarkSmallPrimary,
  ArenaLightSmallPrimary,
  EnrollmentDarkSmallPrimary,
  EnrollmentLightSmallPrimary,
  HighAlertLightSmallPrimary,
  HighAlertDarkSmallPrimary,
  LeagueDarkSmallPrimary,
  LeagueLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { QuestionOutlined } from '@playvs-inc/nexus-icons'
import { LATE_ENROLLMENT_TOOLTIP } from '@plvs/rally/containers/manageTeams/EnrollmentModal/enrollmentModalHelpers'
import { EsportSlug } from '@plvs/graphql/types'

import { esportResources } from '@plvs/respawn/features/esport/creator/esportStaticDetails'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  dateTitle: {
    color: theme.palette.ColorTextAlt2,
  },
  icon: {
    marginRight: theme.spacing(3),
    width: 64,
    height: 64,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  iconButton: {
    padding: theme.spacing(0),
  },
  questionIcon: {
    marginLeft: theme.spacing(0.5),
  },
  gridItem: {
    marginBottom: theme.spacing(4),
  },
  resourceSection: {
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(3),
  },
  resourceText: {
    marginLeft: theme.spacing(3),
  },
  resourceLink: {
    textDecoration: 'none',
  },
}))

interface Props {
  className?: string
  esportName: string
  esportSlug: EsportSlug | undefined
  isPublic: boolean
  metaseasonName: string
  rostersLockAt: string | undefined | null
  seasonStartsAt: string | undefined | null
  seasonEndsAt: string | undefined | null
  suggestedRegistrationEndsAt: string | undefined | null
  teamRegistrationEndsAt: string | undefined | null
}

const DATE_FORMAT = 'LLL z'
const TBD = 'TBD'

export const KeyDatesCard: React.FC<Props> = ({
  className,
  esportName,
  esportSlug,
  isPublic,
  metaseasonName,
  rostersLockAt,
  seasonStartsAt,
  seasonEndsAt,
  suggestedRegistrationEndsAt,
  teamRegistrationEndsAt,
}) => {
  const classes = useStyles()

  const timezone = dayjs.tz.guess()

  const now = dayjs()

  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const formattedTeamRegistrationEndsAt = teamRegistrationEndsAt
    ? formatInTz({
        at: teamRegistrationEndsAt,
        format: DATE_FORMAT,
        timezone,
      })
    : TBD

  const formattedSuggestedRegistrationEndsAt = suggestedRegistrationEndsAt
    ? formatInTz({
        at: suggestedRegistrationEndsAt,
        format: DATE_FORMAT,
        timezone,
      })
    : TBD

  const formattedSeasonStartsAt = seasonStartsAt
    ? formatInTz({
        at: seasonStartsAt,
        format: DATE_FORMAT,
        timezone,
      })
    : TBD

  const formattedSeasonEndsAt = seasonEndsAt
    ? formatInTz({
        at: seasonEndsAt,
        format: DATE_FORMAT,
        timezone,
      })
    : TBD

  const formattedRostersLockAt = rostersLockAt
    ? formatInTz({
        at: rostersLockAt,
        format: DATE_FORMAT,
        timezone,
      })
    : TBD

  const isLateEnrollment = now.isAfter(dayjs(suggestedRegistrationEndsAt))

  const regDeadline = isLateEnrollment
    ? formattedTeamRegistrationEndsAt
    : formattedSuggestedRegistrationEndsAt

  const esportResourceLink =
    esportResources[esportSlug || EsportSlug.LeagueOfLegends]
  return (
    <Card className={className}>
      <CardContent className={classes.cardContent}>
        <NxTypography
          className={classes.title}
          data-cy="metaseasonName"
          variant="h3"
        >
          Key Dates for {metaseasonName}
        </NxTypography>
        <Grid alignItems="center" container direction="row">
          <Grid
            className={classes.gridItem}
            item
            sm={isPublic ? 6 : 12}
            xs={12}
          >
            <Box alignItems="center" display="flex">
              {isDarkMode ? (
                <EnrollmentDarkSmallPrimary className={classes.icon} />
              ) : (
                <EnrollmentLightSmallPrimary className={classes.icon} />
              )}
              <div>
                <Box alignItems="center" display="flex">
                  <NxTypography
                    className={classes.dateTitle}
                    variant="subtitle1"
                  >
                    {isLateEnrollment ? 'Late ' : ''} Enrollment Deadline
                  </NxTypography>
                  {isLateEnrollment && (
                    <NxTooltip
                      arrow
                      placement="top-start"
                      title={LATE_ENROLLMENT_TOOLTIP}
                    >
                      <IconButton className={classes.iconButton}>
                        <QuestionOutlined
                          className={classes.questionIcon}
                          color={theme.palette.ColorIconAlt2}
                        />
                      </IconButton>
                    </NxTooltip>
                  )}
                </Box>
                <NxTypography data-cy="enrollmentDeadline" variant="h4">
                  {regDeadline}
                </NxTypography>
              </div>
            </Box>
          </Grid>
          <Grid
            className={classes.gridItem}
            item
            sm={isPublic ? 6 : 12}
            xs={12}
          >
            <Box alignItems="center" display="flex">
              {isDarkMode ? (
                <ArenaDarkSmallPrimary className={classes.icon} />
              ) : (
                <ArenaLightSmallPrimary className={classes.icon} />
              )}
              <div>
                <NxTypography className={classes.dateTitle} variant="subtitle1">
                  Season Starts
                </NxTypography>
                <NxTypography data-cy="seasonStarts" variant="h4">
                  {formattedSeasonStartsAt}
                </NxTypography>
              </div>
            </Box>
          </Grid>
          <Grid
            className={classes.gridItem}
            item
            sm={isPublic ? 6 : 12}
            xs={12}
          >
            <Box alignItems="center" display="flex">
              {isDarkMode ? (
                <HighAlertDarkSmallPrimary className={classes.icon} />
              ) : (
                <HighAlertLightSmallPrimary className={classes.icon} />
              )}

              <div>
                <NxTypography className={classes.dateTitle} variant="subtitle1">
                  Roster Locks
                </NxTypography>
                <NxTypography data-cy="rosterLocks" variant="h4">
                  {formattedRostersLockAt}
                </NxTypography>
              </div>
            </Box>
          </Grid>
          <Grid
            className={clsx(isPublic && classes.gridItem)}
            item
            sm={isPublic ? 6 : 12}
            xs={12}
          >
            <Box alignItems="center" display="flex">
              {isDarkMode ? (
                <LeagueDarkSmallPrimary className={classes.icon} />
              ) : (
                <LeagueLightSmallPrimary className={classes.icon} />
              )}
              <div>
                <NxTypography className={classes.dateTitle} variant="subtitle1">
                  Season Ends
                </NxTypography>
                <NxTypography data-cy="seasonEnds" variant="h4">
                  {formattedSeasonEndsAt}
                </NxTypography>
              </div>
            </Box>
          </Grid>
        </Grid>
        {isPublic && (
          <Box
            alignItems="center"
            className={classes.resourceSection}
            data-testid="esport-resources"
            display="flex"
            justifyContent="center"
          >
            <NxEsportAvatar
              esport={esportSlug}
              iconHeight="32px"
              iconWidth="32px"
            />
            <div className={classes.resourceText}>
              <NxTypography variant="body2">New to {esportName}?</NxTypography>
              <NxTypography variant="body2">
                Explore this esport on our{' '}
                <a
                  className={classes.resourceLink}
                  href={esportResourceLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  <NxTypography
                    colorToken="ColorTextLink"
                    component="span"
                    variant="body2"
                  >
                    PlayVS Resources!
                  </NxTypography>
                </a>
              </NxTypography>
            </div>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
