import React, { useEffect, useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'

import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useStyles } from '@plvs/rally/pages/app/match/slotQueue'
import { useTheme } from '@material-ui/core'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { QueueTimer } from './QueueTimer'

dayjs.extend(duration)

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface SlotQueueWaitingRowProps {
  team?: Team | null
  countdownTime: Dayjs
  timeUntilQueueCloses: Dayjs
  countdownReachedOnce: boolean
  onCountdownComplete(): Promise<void>
  onTenSecondsLeft(): Promise<void>
  hasError: boolean
}

export const SlotQueueWaitingRow: React.FC<SlotQueueWaitingRowProps> = ({
  team,
  countdownTime,
  timeUntilQueueCloses,
  countdownReachedOnce,
  onCountdownComplete,
  onTenSecondsLeft,
  hasError,
}) => {
  const theme = useTheme()
  const mobile = useBreakpointSm()
  const [title, setTitle] = useState(
    hasError ? "There's been an issue" : 'Looking for a Team'
  )
  const {
    teamsContainer,
    mobileTeamNameContainer,
    mobileTeamContainer,
    mobileHeader,
    subtitleWrapper,
    schoolName,
    teamLabel,
  } = useStyles({ mobile })

  useEffect(() => {
    if (hasError) {
      setTitle("There's been an issue")
    }
  }, [title, hasError])

  const changeTitleOnTenSecondsLeft = async (): Promise<void> => {
    onTenSecondsLeft()
    setTitle('Finding Opponent...')
  }

  const changeTitleOnCountdown = async (): Promise<void> => {
    onCountdownComplete()
    setTitle('Still Searching...')
  }

  const now = dayjs()
  const durationUntilClose = dayjs.duration(timeUntilQueueCloses.diff(now))

  let subtitle: string | React.ReactElement = countdownReachedOnce
    ? 'We were not able to find an opponent, please wait while we try again. If no ' +
      `opponent is found within ${Math.ceil(
        durationUntilClose.asMinutes()
      )} minutes, your team ` +
      'will be awarded a Bye (win) for the week. Please remain on this page to stay in ' +
      'the queue; any team that leaves the queue could receive a forfeit for the week.'
    : 'Every 10 minutes, we will attempt to find an opponent for you. Any match ' +
      "found from this queue will count towards your season's standings. Please " +
      'remain on this page to stay in the queue; any team that leaves the queue ' +
      'could receive a forfeit for the week.'

  if (hasError) {
    subtitle = (
      <NxTypography className={subtitleWrapper} color="inherit" variant="body1">
        There has been an issue redirecting you to your match.{' '}
        <NxTextLink label="Click here" onClick={openIntercom} /> to reach out to
        support.
      </NxTypography>
    )
  }

  const isYouthProgram = team?.esport?.rating === EsportRating.Restricted

  return (
    <Box className={teamsContainer} data-testid="SlotQueueWaitingRow_Wrapper">
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flexDirection="column"
          >
            <NxTypography color="inherit" variant="h2">
              {title}
            </NxTypography>
            <QueueTimer
              countdownTime={countdownTime}
              onCountdownComplete={changeTitleOnCountdown}
              onTenSecondsLeft={changeTitleOnTenSecondsLeft}
              variant="h2"
            />
            <NxTypography
              className={subtitleWrapper}
              color="inherit"
              variant="subtitle1"
            >
              {subtitle}
            </NxTypography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                color={theme.palette.OverlayColorTextBase}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography color="inherit" variant="h1">
                  {team?.name}
                </NxTypography>
                {!isYouthProgram && (
                  <NxTypography
                    className={schoolName}
                    color="inherit"
                    variant="subtitle2"
                  >
                    {team?.school?.name}
                  </NxTypography>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxUserAvatar
              avatarUrl={team?.school?.logoUrl}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.XXL}
            />
          </Box>
          <NxTypography className={teamLabel} color="inherit" variant="h1">
            {team?.name}
          </NxTypography>
          {!isYouthProgram && (
            <NxTypography
              className={schoolName}
              color="inherit"
              variant="subtitle2"
            >
              {team?.school?.name}
            </NxTypography>
          )}
          <Box
            alignItems="center"
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            justifyContent="space-between"
            pt={2}
          >
            <NxTypography color="inherit" variant="h2">
              {title}
            </NxTypography>
            <NxTypography
              className={subtitleWrapper}
              color="inherit"
              variant="subtitle1"
            >
              {subtitle}
            </NxTypography>
            <QueueTimer
              countdownTime={countdownTime}
              onCountdownComplete={changeTitleOnCountdown}
              onTenSecondsLeft={changeTitleOnTenSecondsLeft}
              variant="display"
            />
          </Box>
        </>
      )}
    </Box>
  )
}
