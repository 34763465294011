import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'

import { Colors } from '@plvs/rally/themes'

import {
  BracketResult,
  BracketType,
  determineBracketResultStatus,
} from '@plvs/rally/features/standings/standingsHelpers'
import { MatchResult, MatchSlot, Maybe } from '@plvs/graphql/generated'
import { NxTypography } from '@playvs-inc/nexus-components'
import { BracketResultPairs } from '../BracketResultPairs'

const useStyles = makeStyles((theme) => ({
  // you can wrap a collection of classes.slot with classes.slots, if you don't
  //  want a slider
  slots: {
    display: 'flex',
    overflow: 'auto',
  },
  slot: {
    display: 'flex !important',
    flex: 1,
    flexDirection: 'column',
    minWidth: 1,
    outline: 'none',
  },
  slotHeader: {
    padding: '24px 28px',
    textAlign: 'center',
  },
  slotTitle: {
    textTransform: 'capitalize',
  },
  slotResults: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
}))

export const BracketSlot: React.FC<{
  bracketResults: BracketResult[]
  bracketType: BracketType
  slot: {
    startsAt?: string | null
    matchSlots?: Maybe<Array<MatchSlot>>
  }
  slotIndex: number
  title: string
  toMatch?(matchId: string): string
  slotsLength?: number
}> = ({
  bracketResults,
  bracketType,
  slot,
  slotIndex,
  title,
  toMatch,
  slotsLength,
}) => {
  const mappedBrackets = bracketResults.map((result) => {
    const match = slot?.matchSlots?.find((mSlot) => {
      return mSlot?.id === result.matchSlotId
    })?.match

    const resultForTeam = match?.matchResults?.find(
      (res: MatchResult) => res.teamId === result?.team?.id
    )

    return {
      ...result,
      matchId: match?.id ?? result.matchId ?? '',
      status: resultForTeam
        ? determineBracketResultStatus(resultForTeam?.placing ?? 0)
        : result.status,
    }
  })

  const classes = useStyles()
  const noResults = slotIndex === slotsLength ? 'Final' : 'TBD'
  return (
    <div key={`slot-${slotIndex}`} className={classes.slot}>
      <div className={classes.slotHeader}>
        <NxTypography className={classes.slotTitle} variant="h3">
          {title}
        </NxTypography>
        <div style={{ height: 4 }} />
        <NxTypography style={{ color: Colors.Grey2 }} variant="body1">
          {slot && slot.startsAt ? (
            format(new Date(slot.startsAt), 'MMMM do, yyy')
          ) : (
            <>{noResults}</>
          )}
        </NxTypography>
      </div>
      <div className={classes.slotResults}>
        <BracketResultPairs
          bracketResults={mappedBrackets}
          bracketType={bracketType}
          slotIndex={slotIndex}
          slotsLength={slotsLength}
          toMatch={toMatch}
        />
      </div>
    </div>
  )
}
