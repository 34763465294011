import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { rallyEnv } from '@plvs/rally/env'
import { MaintenanceErrorState } from './MaintenanceErrorState'

export const DefaultMaintenance: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const flags = useFlags()

  // Note: rallyEnv variable set to true should override LD value https://playvs.atlassian.net/browse/GP-4015
  if (rallyEnv.MAINTENANCE === 'true') {
    return <MaintenanceErrorState />
  }

  if (flags.maintenanceMode === true) {
    return <MaintenanceErrorState />
  }

  return <>{children}</>
}
