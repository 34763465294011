import React, { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import { ChatRole } from '@plvs/graphql'
import {
  useGlobalUnreadMessagesVar,
  useActiveGlobalChatConversationsVar,
  useActiveGlobalWindowsVar,
} from '@plvs/client-data/hooks'
import { updateGlobalUnreadMessages } from '@plvs/client-data/mutations'
import {
  getDerivedChatUniqueName,
  MAX_ACTIVE_CONVERSATIONS,
} from '@plvs/rally/features/chat/utils'
import { updateActiveGlobalChatConversationsVar } from '@plvs/client-data/mutations/updateActiveGlobalChatConversationsVar'
import { ChatUnreadMessage } from '@playvs-inc/nexus-icons'
import { useGetUnreadMessageCount } from '@plvs/rally/features/chat'
import * as analytics from '@plvs/respawn/features/analytics'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'

interface UnreadMessagesIndicatorProps {
  matchId: string
  chatRole?: ChatRole
}

const useStyles = makeStyles(() => ({
  chatUnreadMessagesIcon: {
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  box: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const UnreadMessagesIndicator: React.FC<UnreadMessagesIndicatorProps> = ({
  matchId,
  chatRole,
}) => {
  const styles = useStyles()
  const { usersActiveConversationsMap } = useChatConversationsContext()
  // useGlobalUnreadMessagesVar is only being imported because updating the var requires the current state.
  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const activeConversationsList = useActiveGlobalWindowsVar()
  const derivedMatchId = getDerivedChatUniqueName({ chatRole, matchId })

  const {
    unreadMessageCounts: unreadMessageCountObjAll,
    unreadMessageCount: unreadMessageCountAll,
  } = useGetUnreadMessageCount({
    chatUniqueName: matchId,
  })

  const {
    unreadMessageCounts: unreadMessageCountObjCoach,
    unreadMessageCount: unreadMessageCountCoach,
  } = useGetUnreadMessageCount({
    chatUniqueName: derivedMatchId,
  })

  const totalUnreadMessagesCount =
    (unreadMessageCountAll ?? 0) + (unreadMessageCountCoach ?? 0)
  const newUnreadMessagesState = {
    ...unreadMessageCountObjCoach,
    ...unreadMessageCountObjAll,
  }

  const shouldRenderUnreadNotifications = totalUnreadMessagesCount !== 0

  // This follows the same logic as the MatchChat tabs.
  const setConvoWithUnreadMessagesToActiveUniqueName =
    unreadMessageCountCoach > 0 && unreadMessageCountAll === 0
      ? derivedMatchId
      : matchId
  const subscribedConversation =
    usersActiveConversationsMap[setConvoWithUnreadMessagesToActiveUniqueName]

  // unreadMessagesByConversation is being updated here so that <AppDrawerChatMessagesToggle /> can determine
  // if any subscribed conversation has unread messages.
  useEffect(() => {
    updateGlobalUnreadMessages({
      ...unreadMessagesByConversation,
      ...newUnreadMessagesState,
    })
  }, [
    JSON.stringify(newUnreadMessagesState),
    JSON.stringify(unreadMessagesByConversation),
  ])

  const onClick = (): void => {
    if (
      activeConversationsList.length === MAX_ACTIVE_CONVERSATIONS &&
      // This handles the use case where a user clicks an already open chat conversation. This avoids only rendering three chat windows.
      !activeConversations[setConvoWithUnreadMessagesToActiveUniqueName]?.active
    ) {
      const newActiveConversations = { ...activeConversations }
      delete newActiveConversations[activeConversationsList[0]]
      updateActiveGlobalChatConversationsVar({
        ...newActiveConversations,
        [setConvoWithUnreadMessagesToActiveUniqueName]: {
          active: true,
          expanded: true,
        },
      })
    } else {
      updateActiveGlobalChatConversationsVar({
        ...activeConversations,
        [setConvoWithUnreadMessagesToActiveUniqueName]: {
          active: true,
          expanded: true,
        },
      })
    }
    analytics.leftNavUnreadNotificationIconClicked({
      chatOpened: true,
      chatUnqiueName: setConvoWithUnreadMessagesToActiveUniqueName,
    })

    analytics.userReadUnreadMessageViaGlobal({
      chatUniqueName: setConvoWithUnreadMessagesToActiveUniqueName,
      location: 'Left Nav Indicator',
      numberOfUnreadMessagesRead:
        setConvoWithUnreadMessagesToActiveUniqueName === matchId
          ? unreadMessageCountAll
          : unreadMessageCountCoach,
    })
    subscribedConversation?.conversation.setAllMessagesRead()
  }

  return !shouldRenderUnreadNotifications ? (
    <></>
  ) : (
    <Box className={styles.box} onClick={onClick}>
      <ChatUnreadMessage
        className={styles.chatUnreadMessagesIcon}
        count={totalUnreadMessagesCount}
      />
    </Box>
  )
}
