import { CompetitionGroup, CompetitionModel, UserRoleName } from '@plvs/graphql'

export type Rules = {
  menuItemName: MenuItems
  requirements: {
    competitionModel?: CompetitionModel
    competitionGroup: CompetitionGroup
    userRoleNames: UserRoleName[]
    memberRoleNames?: UserRoleName[]
    excludeMemberRoleNames?: UserRoleName[]
    includesSelf?: boolean
    excludeOtherMembers?: boolean
  }[]
}[]

export enum RosterCardEmptyRowMenuItems {
  AddPlayer = 'add player',
  JoinTeam = 'join team',
}

export enum RosterCardMenuItems {
  RenameTeam = 'rename team',
  UnenrollTeam = 'unenroll team',
  UpdateTeamAvatar = 'update team avatar',
  DeleteTeam = 'delete team',
  CopyTeamId = 'copy team id',
  LeaveTeam = 'leave team',
}

export enum RosterCardMenuRowItems {
  RemoveFromTeam = 'remove from team',
  LeaveTeam = 'leave team',
  PromoteToTeamCaptain = 'promote to team captain',
  RemoveTeamCaptain = 'remove team captain',
  RepositionPlayer = 'reposition player',
  DisabledRemoveFromTeam = 'disabled remove from team',
  DisabledLeaveTeam = 'disabled leave team',
}

export enum RosterCardMenuRowSubItems {
  RemoveFromTeam = 'remove from team',
  LeaveTeam = 'leave team',
  SwapSubToTeam = 'swap sub to team',
  RepositionPlayer = 'reposition player',
  PromoteToTeamCaptain = 'promote to team captain',
  RemoveTeamCaptain = 'remove team captain',
  DisabledRemoveFromTeam = 'disabled remove from team',
  DisabledLeaveTeam = 'disabled leave team',
  DisabledSwapSubToTeam = 'disabled swap sub to team',
}

export enum RosterCardMenuRowCoachItems {
  SwapCoach = 'swap coach',
  RemoveCoach = 'remove coach',
}

export type MenuItems =
  | RosterCardEmptyRowMenuItems
  | RosterCardMenuItems
  | RosterCardMenuRowItems
  | RosterCardMenuRowSubItems
  | RosterCardMenuRowCoachItems

export const rosterCardMenuItemRules: Rules = [
  {
    menuItemName: RosterCardMenuItems.RenameTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Player,
          UserRoleName.Captain,
        ],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Captain],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Captain],
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Fac,
        ],
      },
      {
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
      },
    ],
  },
  {
    menuItemName: RosterCardMenuItems.CopyTeamId,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Captain],
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [UserRoleName.Owner],
      },
    ],
  },
  {
    menuItemName: RosterCardMenuItems.UpdateTeamAvatar,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
      },
    ],
  },
  {
    menuItemName: RosterCardMenuItems.DeleteTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Fac],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Fac],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Fac,
          UserRoleName.Captain,
        ],
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [UserRoleName.Owner],
      },
      {
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
      },
    ],
  },
  {
    menuItemName: RosterCardMenuItems.LeaveTeam,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Owner, UserRoleName.Captain],
      },
    ],
  },
]

export const rosterCardMenuRowItemRules: Rules = [
  {
    menuItemName: RosterCardMenuRowItems.RemoveFromTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },

      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.LeaveTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        memberRoleNames: [],
        excludeOtherMembers: true,
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [
          UserRoleName.Player,
          UserRoleName.Member,
          UserRoleName.Captain,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        excludeOtherMembers: true,
        includesSelf: true,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.PromoteToTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.RemoveTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.RepositionPlayer,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: true,
      },
    ],
  },
]

export const rosterCardMenuRowItemDisabledRules: Rules = [
  {
    menuItemName: RosterCardMenuRowItems.DisabledRemoveFromTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },

      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.DisabledLeaveTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        memberRoleNames: [],
        excludeOtherMembers: true,
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [
          UserRoleName.Player,
          UserRoleName.Member,
          UserRoleName.Captain,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        excludeOtherMembers: true,
        includesSelf: true,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.PromoteToTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.RemoveTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowItems.RepositionPlayer,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
]

export const rosterCardMenuRowSubItemDisabledRules: Rules = [
  {
    menuItemName: RosterCardMenuRowSubItems.DisabledSwapSubToTeam,
    requirements: [
      {
        competitionGroup: CompetitionGroup.HighSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.MiddleSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.DisabledRemoveFromTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },

      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.DisabledLeaveTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        memberRoleNames: [],
        excludeOtherMembers: true,
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [
          UserRoleName.Player,
          UserRoleName.Member,
          UserRoleName.Captain,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        excludeOtherMembers: true,
        includesSelf: true,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.PromoteToTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.RemoveTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.RepositionPlayer,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Captain, UserRoleName.Player],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
]

export const rosterCardMenuRowSubRules: Rules = [
  {
    menuItemName: RosterCardMenuRowSubItems.LeaveTeam,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        memberRoleNames: [UserRoleName.Player, UserRoleName.Captain],
        includesSelf: true,
        excludeOtherMembers: true,
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [],
        includesSelf: false,
        excludeOtherMembers: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.RemoveFromTeam,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [UserRoleName.Admin],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Admin, UserRoleName.Coach],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.HighSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [UserRoleName.Admin, UserRoleName.Coach],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.MiddleSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [UserRoleName.Admin, UserRoleName.Coach],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.SwapSubToTeam,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.College,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.HighSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.MiddleSchool,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.RepositionPlayer,
    requirements: [
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.College,
        competitionModel: CompetitionModel.Rec,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: true,
      },
      {
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [
          UserRoleName.Player,
          UserRoleName.Captain,
          UserRoleName.Member,
        ],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.Stadium,
        userRoleNames: [UserRoleName.Owner],
        memberRoleNames: [UserRoleName.Player],
        includesSelf: true,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.PromoteToTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        memberRoleNames: [UserRoleName.Player],
        excludeMemberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowSubItems.RemoveTeamCaptain,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [UserRoleName.Coach, UserRoleName.Admin],
        memberRoleNames: [UserRoleName.Captain],
        includesSelf: false,
      },
    ],
  },
]

export const rosterCardMenuEmptyRowRules: Rules = [
  {
    menuItemName: RosterCardEmptyRowMenuItems.AddPlayer,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Player,
          UserRoleName.Admin,
        ],
      },
      {
        competitionGroup: CompetitionGroup.Youth,
        userRoleNames: [],
      },
    ],
  },
  {
    menuItemName: RosterCardEmptyRowMenuItems.JoinTeam,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [UserRoleName.Member],
      },
    ],
  },
]

export const rosterCardMenuItemCoachRules: Rules = [
  {
    menuItemName: RosterCardMenuRowCoachItems.SwapCoach,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
    ],
  },
]

export const rosterCardMenuItemMultipleCoachRules: Rules = [
  {
    menuItemName: RosterCardMenuRowCoachItems.RemoveCoach,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
    ],
  },
  {
    menuItemName: RosterCardMenuRowCoachItems.SwapCoach,
    requirements: [
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.College,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.HighSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
      {
        competitionModel: CompetitionModel.Rec,
        competitionGroup: CompetitionGroup.MiddleSchool,
        userRoleNames: [
          UserRoleName.Coach,
          UserRoleName.Captain,
          UserRoleName.Admin,
        ],
        includesSelf: true,
      },
    ],
  },
]
