import { Hidden as MuiHidden } from '@material-ui/core'
import { HiddenProps } from '@material-ui/core/Hidden'
import React from 'react'

import { IS_BROWSER } from '@plvs/utils'

interface Props extends Omit<HiddenProps, 'implementation'> {
  children: React.ReactNode
}

export const Hidden: React.FC<Props> = ({ ...rest }) => (
  <MuiHidden implementation={IS_BROWSER ? 'js' : 'css'} {...rest} />
)
