import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  NxButton,
  NxEmptyState,
  NxModal,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { useGetReferralSchoolsQuery } from '@plvs/graphql/generated'
import {
  SchoolDarkLargeSecondary,
  SchoolLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { GlobalSchoolBountyPlasmicComponent } from './GlobalSchoolBountyCalloutComponent'
import { CoachLeadComponent } from './CoachLeadComponent'
import { InviteSchoolCard } from './InviteSchoolCard'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  form: {
    marginBottom: 0,
  },
}))

export const GlobalSchoolLeadComponent: React.FC = () => {
  const theme = useTheme()
  const { isFacultyAtOrg } = useUserIdentityFn()
  const classes = useStyles()
  const { data } = useGetReferralSchoolsQuery({
    variables: {
      limit: 12,
    },
    errorPolicy: 'ignore',
  })

  const schoolReferrals = data?.getReferralSchools?.referralSchools
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const hasSchoolReferrals = schoolReferrals?.some(
    (referral) => referral?.school?.name
  )

  const [open, setOpen] = useState<boolean>(false)
  const handleModalClose = (): void => setOpen(false)
  const handleModalOpen = (): void => setOpen(true)

  return (
    <Box>
      {/* Header */}
      <Box>
        <GlobalSchoolBountyPlasmicComponent />
      </Box>

      {/* Content */}
      {hasSchoolReferrals ? (
        <>
          <Box mb={2}>
            <Grid container spacing={2}>
              {schoolReferrals?.map((schoolReferral) => {
                const location = `${schoolReferral?.school?.city}, ${schoolReferral?.school?.state}`
                return (
                  <InviteSchoolCard
                    key={schoolReferral?.school?.name ?? undefined}
                    competitionGroup={schoolReferral?.school?.competitionGroup}
                    hasBeenReferred={!!schoolReferral?.referredAt}
                    isFaculty={isFacultyAtOrg}
                    location={location}
                    phoneNumber={schoolReferral?.school?.phone ?? ''}
                    schoolId={schoolReferral?.schoolId ?? ''}
                    schoolName={schoolReferral?.school?.name ?? ''}
                    state={schoolReferral?.school?.state ?? ''}
                  />
                )
              })}
            </Grid>
          </Box>
          {/* Footer */}
          <Box alignItems="center" display="flex" flexDirection="column" mb={7}>
            <NxTypography variant="h4">
              Don&apos;t see a school you want to refer?
            </NxTypography>
            <NxButton
              className={classes.button}
              label="Refer Another School"
              onClick={handleModalOpen}
              variant="secondary"
            />
          </Box>
        </>
      ) : (
        <Box mb={6}>
          <NxEmptyState
            cta={
              <NxButton
                label="Refer Another School"
                onClick={handleModalOpen}
                variant="secondary"
              />
            }
            spot={
              isDarkMode ? (
                <SchoolDarkLargeSecondary height={100} width={100} />
              ) : (
                <SchoolLightLargeSecondary height={100} width={100} />
              )
            }
            subtitle="There are currently no schools to refer within your state"
            title="No Nearby Eligible Schools Found"
          />
        </Box>
      )}
      <NxModal
        onClose={handleModalClose}
        open={open}
        showTopRightClose
        subtitle="Refer a coach at another school to get them started on PlayVS."
        title="Refer a School Near You"
      >
        <CoachLeadComponent
          className={classes.form}
          gridSpacing={1}
          hideModalPadding
          isBorderless
          isFaculty={isFacultyAtOrg}
          isModalView
          onBackButtonClick={handleModalClose}
          school={{
            state: schoolReferrals?.[0]?.school?.state ?? '',
          }}
          setAvailableState
          showBackButton
          source={
            isFacultyAtOrg
              ? 'faculty-global-coach-lead'
              : 'student-global-coach-lead'
          }
        />
      </NxModal>
    </Box>
  )
}
