import { UserRoleName } from '@plvs/graphql/generated'
import { assert } from '@plvs/utils'
import { Path } from '@plvs/const'
import { NavigateFunction } from 'react-router'
import { SchoolName, SchoolId } from './OnboardSchoolSelect.types'
import { OnboardAdditionalContactController } from '../OnboardAdditionalContact.controller'

export const handlePostSchoolSelect = async ({
  navigate,
  controller,
  role,
  showNASEFUx,
  personalEmailOptional,
  assign,
  numCoaches,
  schoolEmail,
  selectedSchoolId,
  province,
  selectedSchoolName,
  isCifSchool,
}: {
  navigate: NavigateFunction
  controller: OnboardAdditionalContactController
  role: unknown
  showNASEFUx: string
  personalEmailOptional: boolean
  assign: (values: Record<string, unknown>) => void
  schoolEmail: string | undefined | null
  numCoaches: number
  selectedSchoolId: SchoolId | null
  province: string
  selectedSchoolName: SchoolName | null
  isCifSchool: unknown
}): Promise<void> => {
  assert(role === UserRoleName.Student || role === UserRoleName.Fac)

  // Safari allows submitting forms by pressing 'enter' which can lead to
  // user accidentally submitting by pressing enter on the autocomplete field.
  // We don't want to prevent default on the autocomplete field because
  // there is logic in material autocomplete UI to select partial matches
  // when user presses enter, that we want to keep.
  if (!selectedSchoolId) {
    return
  }

  assign({
    schoolId: selectedSchoolId,
    schoolHasCoach: !!numCoaches,
    schoolProvince: province,
    schoolName: selectedSchoolName,

    // Null out suggestedSchool in case user has previously manually entered
    // a school
    suggestedSchool: null,

    // Checking if the school selected is a NASEF school for next route change
    isNasefSignUp: isCifSchool,
  })

  if (role === UserRoleName.Fac) {
    navigate(`${Path.SpawnPoint}/faculty${showNASEFUx}`)
  } else if (role === UserRoleName.Student) {
    if (!personalEmailOptional) {
      navigate(`${Path.SpawnPoint}/student-contact${showNASEFUx}`)
    } else {
      assert(schoolEmail)
      assign({ schoolEmail })

      await controller.onboardStudent()

      if (numCoaches > 0) {
        navigate(`${Path.SpawnPoint}/student-invite-player${showNASEFUx}`)
        setImmediate(assign, { onboardingComplete: true })
      } else {
        navigate(`${Path.SpawnPoint}/student-invite-coach${showNASEFUx}`)
        setImmediate(assign, { onboardingComplete: true })
      }
    }
  }
}

export const handleStateSelect = ({
  value,
  province,
  setSelectedSchoolId,
  setProvince,
}: {
  value: string
  province: string
  setSelectedSchoolId: (value: React.SetStateAction<SchoolId | null>) => void
  setProvince: React.Dispatch<React.SetStateAction<string>>
}): void => {
  if (province !== value) {
    setSelectedSchoolId(null)
  }
  setProvince(value)
}
