import React from 'react'
import { EnrollmentRoute } from '@plvs/rally/containers/enrollment/EnrollmentRoute'
import { MySchoolLeagues } from '../../../containers/filter/league/MySchoolLeagues'
import { AppPage } from '../../page'

export const EnrollmentPage: React.FC = () => {
  return (
    <AppPage title="Enrollment">
      <MySchoolLeagues>
        <EnrollmentRoute />
      </MySchoolLeagues>
    </AppPage>
  )
}
