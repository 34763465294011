import React from 'react'
import { head } from 'ramda'

import {
  PlayerTopPerformers,
  TeamTopPerformers,
} from '@plvs/rally/components/match'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

import {
  CategoryInterface,
  PlayerStatRow,
  TeamStatRow,
} from '@plvs/rally/components/match/top-performers/TopPerformers'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'

const isTeamStatRow = (
  arr: PlayerStatRow[] | TeamStatRow[]
): arr is TeamStatRow[] => head(arr as TeamStatRow[])?.team !== undefined

export const PhaseTopPerformers: React.FC<{
  categories: CategoryInterface[]
  data: PlayerStatRow[] | TeamStatRow[]
  loading: boolean
}> = ({ categories, data, loading }) => (
  <MatchCard py={4} title="Top Performers">
    <WaitTillLoaded loading={loading}>
      {isTeamStatRow(data) ? (
        <TeamTopPerformers categories={categories} data={data} />
      ) : (
        <PlayerTopPerformers categories={categories} data={data} />
      )}
    </WaitTillLoaded>
  </MatchCard>
)
