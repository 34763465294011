import React from 'react'
import { Link } from '@material-ui/core'
import { EsportRating, GetAllLeagueTeamsQuery } from '@plvs/graphql'
import { TableColumn } from '@plvs/rally/components/table'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Path, StateAbbr, stateNames } from '@plvs/const'
import { RouterLink } from '@plvs/respawn/features/route'
import { Box } from '@plvs/respawn/features/layout'
import { Linkout } from '@playvs-inc/nexus-icons'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useLocation } from 'react-router'
import { useStyles } from './styles'

type TeamsResult = NonNullable<
  NonNullable<NonNullable<GetAllLeagueTeamsQuery>['getTeams']>['teams']
>[0]

export const teamNameColumn: TableColumn<TeamsResult> = {
  id: 'team',
  accessor: 'name',
  Header: 'Team ',
  minWidth: 200,
  Cell: ({ id, name, school, esport }): React.ReactElement => {
    const classes = useStyles()

    const location = useLocation()

    const isPublic = !location.pathname.includes('/app')

    const path = isPublic ? `${Path.PublicTeam}/${id}` : `${Path.Team}/${id}`

    return (
      <Box alignItems="center" display="flex">
        <NxUserAvatar
          avatarUrl={
            esport?.rating === EsportRating.Restricted
              ? undefined
              : school?.logoUrl
          }
          className={classes.avatar}
          hashId={id}
          size={NxAvatarSize.SM}
        />
        <Link color="inherit" component={RouterLink} to={path}>
          <NxTypography>{name}</NxTypography>
        </Link>
      </Box>
    )
  },
}

export const schoolColumn: TableColumn<TeamsResult> = {
  id: 'school',
  accessor: 'school.name',
  Header: 'School',
  minWidth: 200,
  Cell: ({ school }): React.ReactElement => (
    <NxTypography>{school?.name ?? ''}</NxTypography>
  ),
}

export const stateColumn: TableColumn<TeamsResult> = {
  id: 'state',
  accessor: 'state',
  Header: 'State',
  minWidth: 90,
  Cell: ({ state }): React.ReactElement => (
    <NxTypography>{state ? stateNames[state as StateAbbr] : ''}</NxTypography>
  ),
}

export const publicPageLinkColumn: TableColumn<TeamsResult> = {
  id: 'publicPageLink',
  Header: '',
  minWidth: 200,
  Cell: ({ school }): React.ReactElement => {
    const classes = useStyles()

    return (
      <Link
        className={classes.link}
        color="inherit"
        component={RouterLink}
        target="_blank"
        to={`/org/${school?.slug}`}
      >
        <NxTypography>School Public Page</NxTypography>
        <Linkout />
      </Link>
    )
  },
}

export const generalColumns = [
  teamNameColumn,
  schoolColumn,
  stateColumn,
  publicPageLinkColumn,
]

export const youthProgramColumns = [teamNameColumn, stateColumn]
