import React, { useEffect } from 'react'
import { flatten, uniq, uniqBy } from 'ramda'
import {
  NxTypography,
  NxEnrolledLeagueCard,
  NxTabs,
  NxTab,
} from '@playvs-inc/nexus-components'
import { Grid, makeStyles } from '@material-ui/core'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import { useGetTeamLeaguesByMetaseasonIdQuery } from '@plvs/graphql/generated'

import { RelativeTiming } from '@plvs/utils'

import { Path } from '@plvs/const'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useNavigate } from 'react-router'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
  leagueRow: {
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      overflow: 'auto',
      marginBottom: theme.spacing(2),
    },
  },
  leagueCard: {
    marginBottom: theme.spacing(-1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 302,
    },
  },
}))

export const MyEnrolledLeagues: React.FC = () => {
  const classes = useStyles()
  const { id: orgId } = useSelectedOrganizationFn()
  const navigate = useNavigate()

  // custom hooks
  const { loadingTeams, schoolTeamIds } = useTeamsContext()

  const {
    setMetaseason,
    setLeague,
    metaseasons,
    metaseason,
    loading: loadingSchoolLeagueInfoContext,
  } = useSchoolLeagueInfoContext()

  useEffect(() => {
    setLeague('')
    setMetaseason('')
  }, [])

  const metaseasonId = metaseason?.id ?? ''

  // queries
  const { data, loading } = useGetTeamLeaguesByMetaseasonIdQuery({
    skip:
      loadingTeams ||
      loadingSchoolLeagueInfoContext ||
      !schoolTeamIds.length ||
      !metaseasonId,
    variables: {
      ids: schoolTeamIds,
      metaseasonId,
    },
  })

  const teams =
    data?.teamsByIds?.filter((team) => team?.schoolId === orgId) ?? []

  const teamsMetaseasons = uniq(
    flatten(
      teams.map((team) => {
        const enrolledSeasons = team?.enrolledSeasons ?? []

        return enrolledSeasons?.map((es) => es?.metaseasonId)
      })
    )
  )

  const enrolledTeams = teams.filter((team) => {
    const enrolledSeasons = team?.enrolledSeasons ?? []
    return enrolledSeasons.some(
      (season) => season?.metaseasonId === metaseason?.id
    )
  })

  const enrolledLeagues = flatten(
    enrolledTeams.map((enrolledTeam) => enrolledTeam?.leagues ?? [])
  )

  const numberOfTeamsMap = enrolledLeagues.reduce((acc, league) => {
    const leagueId = league?.id ?? ''

    if (!acc[leagueId]) {
      acc[leagueId] = 1
    } else {
      acc[leagueId] += 1
    }

    return acc
  }, {} as Record<string, number>)

  const uniqueLeagues = uniqBy((league) => league?.id, enrolledLeagues)

  const metaseasonTabs = metaseasons.filter(
    (m) => m?.timing !== RelativeTiming.Past && teamsMetaseasons.includes(m?.id)
  )

  const handleTabChange = (_: any, newValue: any): void => {
    setMetaseason(newValue)
  }

  return (
    <WaitTillLoaded
      loading={loadingTeams || loadingSchoolLeagueInfoContext || loading}
      showSpinnerWhileLoading
    >
      {metaseasonTabs.length > 0 && (
        <>
          <NxTypography className={classes.title} variant="h3">
            Your Enrolled Leagues
          </NxTypography>
          <NxTypography
            className={classes.subtitle}
            colorToken="ColorTextAlt"
            variant="body1"
          >
            Quickly access the leagues your teams are currently enrolled in
          </NxTypography>
          <div className={classes.tabs}>
            <NxTabs onChange={handleTabChange} value={metaseason?.id}>
              {metaseasonTabs.map((mt) => (
                <NxTab key={mt?.id} label={mt?.name} value={mt?.id} />
              ))}
            </NxTabs>
          </div>
          <Grid className={classes.leagueRow} container spacing={3}>
            {uniqueLeagues.map((league) => {
              const numberOfTeams = numberOfTeamsMap[league.id]

              const hasMultipleTeams = numberOfTeams > 1

              return (
                <Grid key={league.id} item lg={4} md={6}>
                  <NxEnrolledLeagueCard
                    className={classes.leagueCard}
                    esport={league?.esport?.slug}
                    onClick={(): void =>
                      navigate(`${Path.Explore}/leagues/${league.publicSlug}`)
                    }
                    subtitle={`${numberOfTeams} Team${
                      hasMultipleTeams ? 's' : ''
                    }`}
                    title={league.displayName || league.name || ''}
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </WaitTillLoaded>
  )
}
