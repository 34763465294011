import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { CreateCSSProperties } from '@material-ui/styles'

import { Box } from '@plvs/respawn/features/layout'
import { useImageLoader } from '@plvs/respawn/features/avatar'

export interface MatchCardProps extends BoxProps {
  alt?: string
  showOverlay?: boolean
  src?: string
}

interface MatchCardStyleProps {
  isLoaded: boolean
  height?: string
}

const useStyles = makeStyles((theme) => ({
  imgStyle: {
    objectFit: 'cover',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  imgActor: ({ isLoaded }: MatchCardStyleProps): CreateCSSProperties => ({
    transition: 'all 1.2s ease-in-out',
    opacity: isLoaded ? 1 : 0,
    transform: `scale(${isLoaded ? 1.01 : 1})`,
    transformOrigin: 'center top',
  }),
  card: ({ height }: MatchCardStyleProps): CreateCSSProperties => ({
    height,
    willChange: 'transform',
    overflow: 'hidden',
    top: 0,
    left: 0,
    right: '50vw',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  }),
}))

export const LoginSideBanner: React.FC<MatchCardProps> = ({
  className,
  src,
  children,
  alt,
  ...otherProps
}) => {
  const { isLoaded, imageSrc } = useImageLoader({
    src,
  })

  const { card, imgStyle, imgActor } = useStyles({
    isLoaded,
  })

  return (
    <Box
      className={clsx(card, className)}
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      {...otherProps}
    >
      <Box
        bottom="0"
        className={imgActor}
        left="0"
        overflow="hidden"
        position="absolute"
        right="0"
        top="0"
        zIndex="1"
      >
        {isLoaded && imageSrc && (
          <img
            alt={alt ?? ''}
            className={imgStyle}
            data-testid="login-side-banner"
            src={imageSrc}
          />
        )}
      </Box>
      <Box
        display="flex"
        flex="1"
        justifyContent="center"
        position="relative"
        zIndex="3"
      >
        {children}
      </Box>
    </Box>
  )
}
