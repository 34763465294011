import React from 'react'
import {
  Box,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

import {
  useSetSchoolLogoMutation,
  refetchGetMySchoolDetailsQuery,
} from '@plvs/graphql'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'

export const MAX_FILE_SIZE_IN_BYTES = 1000000 // 1MB

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    border: `1px solid ${theme.palette.BorderDark}`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    position: 'relative',
    transform: 'none',
    '&:hover, &:active': {
      backgroundColor: theme.palette.ColorBackgroundAlt3,
      color: theme.palette.ColorTextBase,
    },
  },
}))

const SetSchoolAvatarImage: React.FC<{ schoolId: string }> = ({ schoolId }) => {
  const classes = useStyles()

  const [isBusy, setIsBusy] = React.useState(false)
  const [error, setError] = React.useState<Error>()

  const [mutate] = useSetSchoolLogoMutation()
  const { updateResourceImages } = useResourceImageProvider()
  const handleOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    try {
      const file = event?.target?.files?.[0]
      if (!file || file.size > MAX_FILE_SIZE_IN_BYTES) {
        throw new Error(
          file ? 'File size must be less than 1MB' : 'No file selected'
        )
      }
      const { type } = file
      if (type === 'image/jpeg' || type === 'image/png') {
        setIsBusy(true)
        await mutate({
          awaitRefetchQueries: true,
          refetchQueries: [refetchGetMySchoolDetailsQuery()],
          variables: { schoolId, file },
        })
        updateResourceImages()
      } else {
        throw new Error('File must be jpeg or png')
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <Box>
      <FormControl error={!!error}>
        <InputLabel className={classes.uploadButton}>
          <NxTypography variant="button">
            {isBusy ? 'Uploading...' : 'Upload School Logo'}
          </NxTypography>
          <Input
            disabled={isBusy}
            disableUnderline
            id="upload-school-logo"
            name="mySchoolLogo"
            onChange={handleOnChange}
            required
            style={{ display: 'none' }}
            type="file"
          />
        </InputLabel>
        <FormHelperText>{error && error.message}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default SetSchoolAvatarImage
