import { head } from 'ramda'
import React from 'react'
import { FilterButton } from './FilterButton'
import { Option } from './filterHelpers'
import { useOption } from './useOption'

export enum Weekday {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const weekdays = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
] as const

export const weekdayLetter: Record<Weekday, string> = {
  [Weekday.Monday]: 'M',
  [Weekday.Tuesday]: 'T',
  [Weekday.Wednesday]: 'W',
  [Weekday.Thursday]: 'R',
  [Weekday.Friday]: 'F',
  [Weekday.Saturday]: 'S',
  [Weekday.Sunday]: 'N',
}

export const weekdayName: Record<Weekday, string> = {
  [Weekday.Monday]: 'Monday',
  [Weekday.Tuesday]: 'Tuesday',
  [Weekday.Wednesday]: 'Wednesday',
  [Weekday.Thursday]: 'Thursday',
  [Weekday.Friday]: 'Friday',
  [Weekday.Saturday]: 'Saturday',
  [Weekday.Sunday]: 'Sunday',
}

export type FilterWeekday = { id: Weekday }

const defaultOption: Option<FilterWeekday> = {
  id: 'default',
  name: 'Weekday',
  values: [],
}

const weekdayOptions: Option<FilterWeekday>[] = weekdays.map((id) => ({
  id: `${id}`,
  name: weekdayName[id],
  values: [{ id }],
}))

const allOptions = [defaultOption, ...weekdayOptions]

export function useWeekday(): {
  selectedWeekday?: Weekday
  weekdayFilter: React.ReactElement
} {
  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption,
  })

  return {
    selectedWeekday: head(option.values)?.id,
    weekdayFilter: (
      <FilterButton<Option<FilterWeekday>>
        id="weekday-filter"
        option={option}
        options={options}
        setOption={setOption}
      />
    ),
  }
}
