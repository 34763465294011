import React, { useEffect } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useStyles } from '@plvs/rally/pages/app/match/slotQueue'
import { Dayjs } from 'dayjs'
import { pushMessage } from '@plvs/rally/pages/app/match/slotQueuePushHelper'
import { QueueTimer } from './QueueTimer'

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
}

interface SlotQueueOpponentRowProps {
  team?: Team | null
  opponentTeam?: Team | null
  countdownTime: Dayjs
  lobbyUrl: string
  onCountdownComplete(): Promise<void>
}

export const SlotQueueOpponentRow: React.FC<SlotQueueOpponentRowProps> = ({
  team,
  opponentTeam,
  countdownTime,
  lobbyUrl,
  onCountdownComplete,
}) => {
  const mobile = useBreakpointSm()
  const {
    teamsContainer,
    mobileTeamNameContainer,
    mobileTeamContainer,
    mobileHeader,
    teamLabel,
  } = useStyles({ mobile })

  const title = 'Opponent Found'

  useEffect(() => {
    if (opponentTeam) {
      const currUrl = window.location
      pushMessage('Match Ready!', {
        body: `Your match with ${opponentTeam.name} is ready!`,
        data: {
          url: `${currUrl.protocol}//${currUrl.host}${lobbyUrl}`,
        },
      })
    }
  }, [opponentTeam])

  return (
    <Box className={teamsContainer} data-testid="SlotQueueOpponentRow_Wrapper">
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            display="flex"
            flexDirection="column"
          >
            <NxTypography colorToken="OverlayColorTextBase" variant="h2">
              {title}
            </NxTypography>
            <QueueTimer
              countdownTime={countdownTime}
              onCountdownComplete={onCountdownComplete}
              variant="h2"
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography colorToken="OverlayColorTextBase" variant="h1">
                  {team?.name}
                </NxTypography>
                <NxTypography colorToken="OverlayColorTextBase" variant="body1">
                  {team?.school?.name}
                </NxTypography>
              </Box>
            </Box>
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={opponentTeam?.school?.logoUrl}
                hashId={opponentTeam?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography colorToken="OverlayColorTextBase" variant="h1">
                  {opponentTeam?.name}
                </NxTypography>
                <NxTypography colorToken="OverlayColorTextBase" variant="body1">
                  {opponentTeam?.school?.name}
                </NxTypography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box display="flex" flex="1" flexDirection="row" width="100%">
          <Box display="flex" flex="1">
            <Box display="flex" flex="1" flexDirection="column">
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.XXL}
              />
              <NxTypography
                className={teamLabel}
                colorToken="OverlayColorTextBase"
                variant="h1"
              >
                {team?.name}
              </NxTypography>
              <NxTypography colorToken="OverlayColorTextBase" variant="body1">
                {team?.school?.name}
              </NxTypography>
            </Box>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxTypography colorToken="OverlayColorTextBase" variant="h2">
              {title}
            </NxTypography>
            <QueueTimer
              countdownTime={countdownTime}
              onCountdownComplete={onCountdownComplete}
              variant="display"
            />
          </Box>
          <Box display="flex" flex="1">
            <Box display="flex" flex="1" flexDirection="column">
              <Box display="flex" justifyContent="flex-end">
                <NxUserAvatar
                  avatarUrl={opponentTeam?.school?.logoUrl}
                  hashId={opponentTeam?.id ?? ''}
                  size={NxAvatarSize.XXL}
                />
              </Box>
              <NxTypography
                align="right"
                className={teamLabel}
                colorToken="OverlayColorTextBase"
                variant="h1"
              >
                {opponentTeam?.name}
              </NxTypography>
              <NxTypography
                align="right"
                colorToken="OverlayColorTextBase"
                variant="body1"
              >
                {opponentTeam?.school?.name}
              </NxTypography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
