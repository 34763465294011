import { makeStyles } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { GetTeamMembersAtNowQuery } from '@plvs/graphql'
import {
  RelativeTiming,
  mapMetaseasonTimingToRelative,
  MinimalMetaseason,
  getStarters,
} from '@plvs/utils'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { TeamAvatarGroup } from '@plvs/rally/components/avatar/TeamAvatarGroup'
import { Tooltip } from '@plvs/rally/components/tooltip'
import { CreateCSSProperties } from '@material-ui/styles'
import {
  EnrolledPlayerOnTeam,
  EnrollmentLeague,
  EnrollmentSeason,
  SeasonEnrollmentRangeData,
  TeamLeagueSelectFn,
} from '@plvs/respawn/containers/enrollment/types'
import dayjs from 'dayjs'
import { NxTypography } from '@playvs-inc/nexus-components'
import { getTeamMembersThatCannotBeEnrolledV2 } from '@plvs/respawn/containers/enrollment/enrollmentHelpers'
import {
  getDisabled,
  getDisabledLeagueBcOfPlayersMsg,
  getDisabledPlayerRow,
} from './TeamRow.helpers'
import { RowContainerV2 } from './RowContainerV2'
import { getSelect, INVALID_LEAGUE_IDS } from './TeamRow.Select.helper'

interface TeamRowProps {
  enrolledLeague?: EnrollmentLeague
  leagues: EnrollmentLeague[]
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  metaseasonId: string
  promotedMetaseason?: MinimalMetaseason
  onChange: TeamLeagueSelectFn
  team: NonNullable<GetTeamMembersAtNowQuery['team']>
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  isAdmin: boolean
  addEnrollmentSeasonIds: (seasonIds: string[]) => void
  removeEnrollmentSeasonId: (seasonId: string) => void
}

type StyleProps = { disabled?: boolean; isMobile?: boolean }

const useStyles = makeStyles((theme) => ({
  headerTitle: ({ disabled }: StyleProps): CreateCSSProperties => ({
    color: disabled
      ? theme.palette.ColorTextDisabled
      : theme.palette.ColorTextBase,
    fontWeight: 'bold',
  }),
  summary: ({ disabled }: StyleProps): CreateCSSProperties => ({
    color: disabled
      ? theme.palette.ColorTextDisabled
      : theme.palette.ColorTextBase,
  }),
  headerContextTitle: {
    color: theme.palette.ColorTextInfo,
  },
  dot: ({ disabled }: StyleProps): CreateCSSProperties => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    color: disabled ? theme.palette.ColorTextDisabled : 'inherit',
  }),
  selectionContainer: {
    '& div': {
      backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    },
  },
}))

export const useSelectStyles = makeStyles((theme) => ({
  tooltip: {
    display: 'inline',
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.ColorTextBase,
  },
  displayOnlyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  element: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    width: isMobile ? '100%' : '40%',
  }),
  formHelperText: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '100%',
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingTop: 15,
    marginTop: 10,
    marginBottom: theme.spacing(1),
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: theme.palette.BorderMedium,
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  selectLabel: {
    top: '10px',
    left: '10px',
    zIndex: 1,
  },
}))

export const TeamRow: React.FC<TeamRowProps> = ({
  enrolledLeague,
  enrolledPlayersOnTeam,
  metaseasonId,
  onChange,
  team,
  leagues,
  promotedMetaseason,
  isAdmin,
  seasonSlotExclusionRangeData,
  addEnrollmentSeasonIds,
  removeEnrollmentSeasonId,
}) => {
  const isMobile = useBreakpointXs()
  const enrolledLeagueId = enrolledLeague?.id
  const [leagueId, setLeagueId] = useState<string>(
    enrolledLeagueId || 'default'
  )
  const [showLeagueNameOnly, setShowLeagueNameOnly] = useState<boolean>(true)
  const { getUsername } = useEsportContext()

  const members = team.members ?? []
  const teamName = team.name
  const teamFormat = team.esport.leagueDefaults.teamFormat ?? 0

  const leaguesMap = leagues.reduce<Record<string, EnrollmentLeague>>(
    (accum, league) => {
      return { ...accum, [league.id]: league }
    },
    {}
  )

  const teamAvatarGroupMembers = members.map((user) => ({
    ...user,
    username: getUsername(user),
  }))

  const numberOfBenchMembers = members.filter((member) => {
    return member.position >= teamFormat
  }).length

  const numberOfStarters =
    members.filter((member) => member.position < teamFormat).length ?? ''

  // Keeping teamFormat from league becaused it's used throughout the enrollment flow.
  // This will change when the enrollment flow is updated.
  const enrolledSeasons = team?.enrolledSeasons ?? []
  const isPromotedMetaseason = metaseasonId === promotedMetaseason?.id
  const notEnoughStarters =
    getStarters(members, {
      teamFormat,
    }).length < teamFormat

  const teamSummary = `Total ${members.length} Players (${numberOfStarters} roster players, ${numberOfBenchMembers} bench players)`

  const enrolledLeagueSeason:
    | EnrollmentSeason
    | undefined = enrolledLeague?.seasons?.find(
    (currentSeason: EnrollmentSeason) =>
      currentSeason.metaseasonId === metaseasonId
  )
  const enrolledLeagueEnrolledSeasonId = enrolledLeagueSeason?.id
  const enrolledLeagueIsPastTeamDeregistrationDate = dayjs().isAfter(
    enrolledLeagueSeason?.teamDeregistrationEndsAt
  )
  const enrolledLeaguePlayDate =
    enrolledLeagueSeason?.weeklySlotDateEstimate?.sample

  const disabledLeagues = useMemo(() => {
    return leagues.reduce<Record<string, string>>((accum, league) => {
      const {
        teamMembersThatCannotBeEnrolled,
      } = getTeamMembersThatCannotBeEnrolledV2({
        enrolledPlayersOnTeam,
        leagueId: league.id,
        metaseasonId,
        team,
      })
      if (teamMembersThatCannotBeEnrolled.length > 0) {
        return {
          ...accum,
          [league.id]: getDisabledLeagueBcOfPlayersMsg(
            teamMembersThatCannotBeEnrolled.map(({ name }) => name || '')
          ),
        }
      }
      return accum
    }, {})
  }, [leagues])

  const { disabled, disabledMessage } = getDisabled({
    hasNoMembers: members.length === 0,
    teamName,
    notEnoughStarters,
  })

  const classes = useStyles({ disabled })
  const selectStyles = useSelectStyles({ isMobile })

  const currentEnrolledSeason = enrolledSeasons
    .map(
      (enrolledSeason) =>
        enrolledSeason?.metaseason &&
        mapMetaseasonTimingToRelative(enrolledSeason.metaseason)
    )
    .find((ms) => ms?.timing === RelativeTiming.Present && !ms?.isPromoted)

  const isEnrolledInCurrentSeason = !!currentEnrolledSeason

  const {
    disabled: disabledPlayerRow,
    disabledMessage: disabledPlayerRowMessage,
  } = getDisabledPlayerRow({
    isPromotedMetaseason,
    isEnrolledInCurrentSeason,
    currentEnrolledSeason,
  })

  const flattenedLeagues = useMemo(() => {
    return leagues.map((league) => {
      const season: EnrollmentSeason | undefined = league.seasons?.find(
        (currentSeason: EnrollmentSeason) =>
          currentSeason.metaseasonId === metaseasonId
      )
      const isPastRegDate = dayjs().isAfter(season?.teamRegistrationEndsAt)
      const isPastDeRegDate = dayjs().isAfter(season?.teamDeregistrationEndsAt)
      return {
        id: league.id,
        displayName: league.displayName || league.name,
        playDate: season?.weeklySlotDateEstimate?.sample,
        isPastRegDate,
        isPastDeRegDate,
      }
    })
  }, [leagues])

  const isAllPastRegDate = !flattenedLeagues.some(
    ({ isPastRegDate }) => !isPastRegDate
  )

  const [toSeasonId, setToSeasonId] = useState<string | undefined>(undefined)
  const [showContextText, setShowContextText] = useState<boolean>(false)
  const seasonIdsWithCustomRangeConfigs = new Set(
    seasonSlotExclusionRangeData.map((range) => range.seasonId)
  )
  useMemo(() => {
    if (toSeasonId) {
      if (seasonIdsWithCustomRangeConfigs.has(toSeasonId)) {
        setShowContextText(true)
      } else {
        setShowContextText(false)
      }
    } else {
      // this happens when user selects the default option to take no action
      setShowContextText(false)
    }
  }, [toSeasonId, enrolledLeague])

  return (
    <RowContainerV2 disabled={disabled}>
      <Box>
        <Box display="flex" flexDirection="column">
          <NxTypography className={classes.headerTitle} variant="body2">
            {teamName}
          </NxTypography>
          <NxTypography className={classes.summary} variant="body3">
            {teamSummary}
          </NxTypography>
        </Box>
        {showContextText && (
          <Box alignItems="center" display="flex" flexDirection="row">
            <NxTypography
              className={classes.headerContextTitle}
              variant="body3"
            >
              This league has a custom break week. Please make sure you have
              selected a break week for this league.
            </NxTypography>
          </Box>
        )}
        <Box className={classes.selectionContainer}>
          {getSelect({
            disabledLeagues,
            isAdmin,
            enrolledLeague: enrolledLeague && {
              ...enrolledLeague,
              playDate: enrolledLeaguePlayDate,
            },
            flattenedLeagues,
            isDisabled: disabled,
            isAllRegDatePassed: isAllPastRegDate,
            isPastDeregistrationDate: enrolledLeagueIsPastTeamDeregistrationDate,
            isEnrolledLeaguePastDeRegDate: enrolledLeagueIsPastTeamDeregistrationDate,
            showLeagueNameOnly,
            disabledMessage,
            setShowLeagueNameOnly,
            selectedLeagueId: leagueId,
            styles: selectStyles,
            onChange: (e): void => {
              const selectedLeagueId = e.target.value as string
              const originalLeagueId = leagueId
              setLeagueId(selectedLeagueId)
              const shouldRemoveLeague = INVALID_LEAGUE_IDS.has(
                selectedLeagueId
              )

              const league = leaguesMap[selectedLeagueId]
              let season: EnrollmentSeason | undefined
              if (!shouldRemoveLeague) {
                season = league.seasons?.find(
                  (currentSeason: EnrollmentSeason) =>
                    currentSeason.metaseasonId === metaseasonId
                )
              }
              if (season?.id && season?.id !== toSeasonId) {
                addEnrollmentSeasonIds([season?.id])
              }
              if (toSeasonId && season?.id !== toSeasonId) {
                removeEnrollmentSeasonId(toSeasonId)
              }
              setToSeasonId(season?.id)
              onChange({
                fromLeagueId: originalLeagueId,
                toLeagueId: selectedLeagueId,
                fromSeasonId: enrolledLeagueEnrolledSeasonId,
                toSeasonId: shouldRemoveLeague ? undefined : season?.id || '',
                esportSlug: team.esport.slug,
                teamId: team.id,
                members: members.map(({ id, position }) => ({
                  id,
                  position,
                })),
                teamFormat,
              })
            },
          })}
        </Box>
        {!isMobile && (
          <Tooltip title={disabledPlayerRow ? disabledPlayerRowMessage : null}>
            <Box display="flex" ml={-0.5} mt={0.5}>
              <TeamAvatarGroup
                avatarSize="xsmall"
                disabled={disabledPlayerRow}
                members={teamAvatarGroupMembers}
                teamFormat={teamFormat}
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    </RowContainerV2>
  )
}
