// Note: <any> is used in this file because these are generic accessors/renderers
// that apply to a number of types coming back from a variety of queries.
/* eslint-disable @typescript-eslint/no-explicit-any */

import { makeStyles } from '@material-ui/core'
import {
  LeagueOfLegendsBan,
  LeagueOfLegendsItem,
  LeagueOfLegendsPlayerGameStats,
  LeagueOfLegendsPlayerMatchStats,
  LeagueOfLegendsPlayerPhaseStats,
} from '@plvs/graphql'
import { isNil } from 'ramda'
import React from 'react'
import {
  None,
  RoundToOneDecimalPlace,
  Comma,
  Km,
  Round,
  Percent,
} from '@plvs/utils'
import { NxTooltip } from '@playvs-inc/nexus-components'
import { AvatarCircle } from '../../avatar'
import { TableColumn } from '../Table'

const useStyles = makeStyles(() => ({
  champions: {
    marginRight: '8px',
  },
}))

export function StatColumn<
  S = Record<string, string | number | string[] | number[] | null | undefined>,
  T extends {
    stats: S | null | undefined
  } = {
    stats: S | null | undefined
  }
>(
  accessor: string,
  format: (value: S) => React.ReactNode,
  tableColumn: Omit<Partial<TableColumn<T>>, 'accessor'>
): TableColumn<any> {
  return {
    Cell: (props: any): React.ReactElement => {
      const { stats } = props
      const value = stats?.[accessor]
      return <>{!isNil(value) ? format(value) : '-'}</>
    },
    accessor: `stats.${accessor}`,
    Header: accessor,
    ...tableColumn,
  }
}

export const Champion = StatColumn<Partial<LeagueOfLegendsPlayerGameStats>>(
  'champion',
  None,
  {
    Cell: ({ stats }: { stats: any }) => {
      return (
        <>
          {stats && stats.champion && (
            <NxTooltip arrow title={stats.champion.name}>
              <div>
                <AvatarCircle
                  avatarUrl={stats.champion.image.src}
                  defaultIcon={<></>}
                  size="small"
                />
              </div>
            </NxTooltip>
          )}
        </>
      )
    },
    Header: 'Champion',
  }
)

export const Champions = StatColumn<Partial<LeagueOfLegendsPlayerMatchStats>>(
  'champions',
  None,
  {
    Cell: ({ stats }: { stats: any }) => {
      const classes = useStyles()
      return stats.isForfeit || stats.isSettled ? (
        <>
          {stats.isForfeit
            ? `Forfeit by ${stats.forfeitBy}`
            : `Settled by ${stats.settledBy}`}
        </>
      ) : (
        <>
          {(stats?.champions ?? []).map((champion: any, i: number) => {
            const image = champion?.image?.src ?? ''
            const { name, id } = champion

            return (
              <NxTooltip key={`${id}-${i.toString()}`} arrow title={name}>
                <div>
                  <AvatarCircle
                    avatarUrl={image}
                    className={classes.champions}
                    defaultIcon={<></>}
                    shape="square"
                    size="small"
                  />
                </div>
              </NxTooltip>
            )
          })}
        </>
      )
    },
    Header: 'Champions',
    width: 240,
  }
)

export const Bans = StatColumn<Partial<LeagueOfLegendsPlayerMatchStats>>(
  'bans',
  None,
  {
    Cell: ({ stats }: { stats: any }) => {
      const classes = useStyles()

      return (
        <>
          {(stats?.detail?.bans ?? []).map(
            (ban: LeagueOfLegendsBan, i: number) => {
              const id = ban?.champion?.id ?? ''
              const image = ban?.champion?.image?.src ?? ''
              const name = ban?.champion?.name ?? ''

              return (
                <NxTooltip key={`${id}-${i.toString()}`} arrow title={name}>
                  <div>
                    <AvatarCircle
                      avatarUrl={image}
                      className={classes.champions}
                      defaultIcon={<></>}
                      shape="circle"
                      size="small"
                    />
                  </div>
                </NxTooltip>
              )
            }
          )}
        </>
      )
    },
    Header: 'Bans',
    width: 240,
  }
)

export const Cs = StatColumn('creepScore', RoundToOneDecimalPlace, {
  Header: 'CS',
  HeaderTooltip: 'Creep Score',
})

export const Damage = StatColumn('championDamageDealt', Comma, {
  Header: 'DMG',
  HeaderTooltip: 'Champion Damage Dealt',
})

export const Gold = StatColumn('goldEarned', Km, {
  Header: 'Gold',
  HeaderTooltip: 'Gold Earned',
})

export const Gpm = StatColumn('goldPerMinute', Round, {
  Header: 'GPM',
  HeaderTooltip: 'Gold Per Minute',
})

export const GpmAvg = StatColumn('averageGoldPerMinute', Round, {
  Header: 'GPM',
  HeaderTooltip: 'Avg. Gold Per Minute',
})

export const Items = StatColumn<Partial<LeagueOfLegendsPlayerGameStats>>(
  'detail.items',
  None,
  {
    Cell: ({ stats }: { stats: any }) => {
      return (
        <>
          {(stats?.detail?.items ?? []).map(
            (item: LeagueOfLegendsItem, i: number) => {
              if (!item.id || item.id === '0') {
                return null
              }
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} style={{ marginRight: 4 }}>
                  <AvatarCircle
                    avatarUrl={item.image.src}
                    defaultIcon={<></>}
                    shape="square"
                    size="small"
                  />
                </div>
              )
            }
          )}
        </>
      )
    },
    Header: 'Items',
    minWidth: 240,
    sortable: false,
  }
)

export const Kda = StatColumn('eliminations', None, {
  id: 'kda',
  Cell: ({ stats }: { stats: any }) => (
    <>
      {stats
        ? `${stats.eliminations} / ${stats.respawns} / ${stats.assists}`
        : ''}
    </>
  ),
  Header: 'KDA',
  HeaderTooltip: 'Kills / Deaths / Assists',
  minWidth: 120,
})

export const Wards = StatColumn('wardsPlaced', None, {
  Header: 'Wards',
  HeaderTooltip: 'Wards Placed',
})

export const KdaAvg = StatColumn<Partial<LeagueOfLegendsPlayerPhaseStats>>(
  'eliminationRespawnRatio',
  RoundToOneDecimalPlace,
  {
    Header: 'KDA',
    HeaderTooltip: 'Avg. KDA',
    sortable: false,
  }
)

export const Eliminations = StatColumn<
  Partial<LeagueOfLegendsPlayerPhaseStats>
>('eliminations', Round, {
  Header: 'K',
  HeaderTooltip: 'Eliminations',
})

export const EliminationsAvg = StatColumn<
  Partial<LeagueOfLegendsPlayerPhaseStats>
>('averageEliminations', Round, {
  Header: 'K',
  HeaderTooltip: 'Avg. Eliminations',
})

export const Respawns = StatColumn<Partial<LeagueOfLegendsPlayerPhaseStats>>(
  'respawns',
  Round,
  {
    Header: 'D',
    HeaderTooltip: 'Respawns',
  }
)

export const RespawnsAvg = StatColumn<Partial<LeagueOfLegendsPlayerPhaseStats>>(
  'averageRespawns',
  Round,
  {
    Header: 'D',
    HeaderTooltip: 'Avg. Respawns',
  }
)

export const Assists = StatColumn<Partial<LeagueOfLegendsPlayerPhaseStats>>(
  'assists',
  None,
  {
    Header: 'A',
    HeaderTooltip: 'Assists',
  }
)

export const AssistsAvg = StatColumn<Partial<LeagueOfLegendsPlayerPhaseStats>>(
  'averageAssists',
  Round,
  {
    Header: 'A',
    HeaderTooltip: 'Avg. Assists',
  }
)

export const Kp = StatColumn('eliminationParticipation', Percent, {
  Header: 'KP',
  HeaderTooltip: 'Elimination Participation',
})

export const CsAvg = StatColumn('averageCreepScore', Round, {
  Header: 'CS',
  HeaderTooltip: 'Avg. Creep Score',
})

export const TowersDestroyedAvg = StatColumn('averageTowersDestroyed', Round, {
  Header: 'TK',
  HeaderTooltip: 'Avg. Towers Destroyed',
})

export const MostPickedChampion = StatColumn('mostPickedChampionId', None, {
  Cell: ({ stats }: { stats: any }) => (
    <>
      {stats && stats.mostPickedChampion && (
        <AvatarCircle
          avatarUrl={stats.mostPickedChampion.image.src}
          defaultIcon={<></>}
          size="small"
        />
      )}
    </>
  ),
  Header: 'MPC',
  HeaderTooltip: 'Most Picked Champion',
})
