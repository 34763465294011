import React, { useMemo } from 'react'
import { Navigate, Route, RouteProps, useParams } from 'react-router-dom'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useGetTeamIdsForMatchDisputeQuery, UserRoleName } from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { intersection } from 'ramda'
import { userRolesToUserRoleNames } from '@plvs/utils'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

export const RequiredRoleForMatchRoute: React.FC<
  {
    requireAnyOf: UserRoleName[]
    redirectTo?: Path
    routeElement?: JSX.Element
  } & RouteProps
> = ({ redirectTo, requireAnyOf, routeElement, ...rest }) => {
  const classes = useStyles()
  const { userRoles, loading: userRolesLoading } = useUserIdentityFn()
  const { matchId } = useParams<{ matchId: string }>() as { matchId: string }
  const { data, loading } = useGetTeamIdsForMatchDisputeQuery({
    variables: {
      matchId,
    },
    skip: !userRoles.length || !matchId,
  })

  const isLoading = userRolesLoading || loading

  const matchTeamAndSchoolIds = useMemo(
    () => [
      data?.match?.team1?.id ?? '',
      data?.match?.team2?.id ?? '',
      data?.match?.team1?.school?.id ?? '',
      data?.match?.team2?.school?.id ?? '',
    ],
    [
      data?.match?.team1?.id,
      data?.match?.team2?.id,
      data?.match?.team1?.school?.id,
      data?.match?.team2?.school?.id,
    ]
  )

  const userRolesResourceIdsForMatch = userRoles
    .map((role) => role.resourceId)
    .filter((id) => matchTeamAndSchoolIds.includes(id))

  const filteredUserRoles = userRoles.filter((role) =>
    userRolesResourceIdsForMatch.includes(role.resourceId)
  )

  const userRoleNames = userRolesToUserRoleNames(filteredUserRoles)
  const isAllowed = !!intersection(userRoleNames, requireAnyOf).length

  return (
    <WaitTillLoaded loading={isLoading}>
      {isAllowed ? (
        <div className={classes.root} data-testid="allowed">
          <Route {...rest} element={routeElement} />
        </div>
      ) : (
        <div data-testid="not-allowed">
          <Navigate data-testid="redirecting" to={redirectTo || Path.App} />
        </div>
      )}
    </WaitTillLoaded>
  )
}

export const RequiredRoleForMatchWrapper = ({
  children,
  requireAnyOf,
  redirectTo,
}: {
  children: JSX.Element
  requireAnyOf: UserRoleName[]
  redirectTo?: Path
}): JSX.Element => {
  const classes = useStyles()
  const { userRoles, loading: userRolesLoading } = useUserIdentityFn()
  const { matchId } = useParams<{ matchId: string }>() as { matchId: string }
  const { data, loading } = useGetTeamIdsForMatchDisputeQuery({
    variables: {
      matchId,
    },
    skip: !userRoles.length || !matchId,
  })

  const isLoading = userRolesLoading || loading

  const matchTeamAndSchoolIds = useMemo(
    () => [
      data?.match?.team1?.id ?? '',
      data?.match?.team2?.id ?? '',
      data?.match?.team1?.school?.id ?? '',
      data?.match?.team2?.school?.id ?? '',
    ],
    [
      data?.match?.team1?.id,
      data?.match?.team2?.id,
      data?.match?.team1?.school?.id,
      data?.match?.team2?.school?.id,
    ]
  )

  const userRolesResourceIdsForMatch = userRoles
    .map((role) => role.resourceId)
    .filter((id) => matchTeamAndSchoolIds.includes(id))

  const filteredUserRoles = userRoles.filter((role) =>
    userRolesResourceIdsForMatch.includes(role.resourceId)
  )

  const userRoleNames = userRolesToUserRoleNames(filteredUserRoles)
  const isAllowed = !!intersection(userRoleNames, requireAnyOf).length

  return (
    <WaitTillLoaded loading={isLoading}>
      {isAllowed ? (
        <div className={classes.root} data-testid="allowed">
          {children}
        </div>
      ) : (
        <div data-testid="not-allowed">
          <Navigate data-testid="redirecting" to={redirectTo || Path.App} />
        </div>
      )}
    </WaitTillLoaded>
  )
}
