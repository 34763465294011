import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'
import { Smallcheck, UnknownAvatar } from '@playvs-inc/nexus-icons'
import { BracketTeamMatchStatus } from '@plvs/graphql/generated'
import clsx from 'clsx'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

const useStyles = makeStyles((theme) => ({
  container: ({
    isWinner,
    isLoser,
    isMobile,
    esportColor,
    hovered,
  }: {
    isWinner: boolean
    isLoser: boolean
    isMobile: boolean
    esportColor: string
    hovered: boolean
  }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: hovered ? esportColor : theme.palette.ColorBackgroundAlt,
    padding: theme.spacing(1, 3, 1, 3),
    borderRadius: theme.shape.borderRadius,
    borderRight:
      isWinner || hovered
        ? `4px solid ${esportColor}`
        : `4px solid ${theme.palette.ColorBackgroundAlt}`,
    alignItems: 'center',
    minHeight: '54px',
    opacity: isLoser && !isMobile ? 0.5 : 1,
    cursor: 'pointer',
  }),
  text: ({ hovered }: { hovered: boolean }): CreateCSSProperties => ({
    color: hovered
      ? theme.palette.OverlayColorTextBase
      : theme.palette.ColorTextBase,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
  }),
  seed: {
    width: '20px',
    textAlign: 'center',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  avatar: {
    opacity: '1 !important',
  },
}))

export interface BracketTeamProps {
  onMouseEnter
  onMouseLeave
  party
  hovered
  esportColor
}

export function NxBracketTeam({
  onMouseEnter,
  onMouseLeave,
  party,
  hovered,
  esportColor,
}: BracketTeamProps): React.ReactElement {
  const isWinner = party.status === BracketTeamMatchStatus.Win
  const isLoser = party.status === BracketTeamMatchStatus.Loss && !hovered

  const isMobile = useBreakpointSm()
  const theme = useTheme()
  const classes = useStyles({
    isWinner,
    isLoser,
    isMobile,
    esportColor,
    hovered,
  })

  const handleLinkInNewTab = (link: string): void => {
    if (link) {
      window.open(link, '_blank', 'noreferrer')
      window?.focus()
    }
  }

  return (
    <Box
      className={classes.container}
      onClick={(): void => {
        handleLinkInNewTab(party.matchLink)
      }}
      onMouseEnter={(): void => {
        if (party.id) {
          onMouseEnter(party.id)
        }
      }}
      onMouseLeave={onMouseLeave}
    >
      <Box mr={2}>
        <NxTypography
          className={clsx(classes.seed, classes.text)}
          variant="body1"
        >
          {party.seed}
        </NxTypography>
      </Box>
      <Box mr={1}>
        {party.picture && !party.hideSchoolDetails ? (
          <NxUserAvatar
            avatarUrl={party.picture}
            hashId={party.teamId ?? ''}
            size={NxAvatarSize.XS}
          />
        ) : (
          <UnknownAvatar className={classes.icon} />
        )}
      </Box>
      <Box className={classes.nameContainer} flex={1}>
        <NxTypography
          className={classes.text}
          onClick={(): void => {
            if (party.id && !party.isDeleted) {
              handleLinkInNewTab(party.teamLink)
            }
          }}
          variant="body2"
        >
          {party.displayName ? party.displayName : 'TBD'}
        </NxTypography>
        {!party.schoolName || party.hideSchoolDetails || party.isDeleted ? (
          <></>
        ) : (
          <NxTypography
            className={classes.text}
            onClick={(): void => {
              if (party.schoolId) {
                handleLinkInNewTab(party.schoolLink)
              }
            }}
            variant="body4"
          >
            {party.schoolName}
          </NxTypography>
        )}
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row">
        {isWinner ? (
          <Smallcheck
            color={
              hovered
                ? theme.palette.OverlayColorIconBase
                : theme.palette.ColorIconSuccess
            }
            data-testid="BracketTeam_checkmark"
            height={24}
            width={24}
          />
        ) : (
          <></>
        )}
        <NxTypography className={classes.text} variant="body2">
          {party.score}
        </NxTypography>
      </Box>
    </Box>
  )
}
