import { InputAdornment, TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'

import { CopyIconButton } from '../button'

// https://github.com/mui/material-ui/issues/13794#issuecomment-476455816
// disableUnderline fix for TextField is to use StandardTextFieldProps

interface Props extends Omit<StandardTextFieldProps, 'variant'> {
  onClickCopy?(value: string): void
  value: string
  disableUnderline?: boolean
  className?: string
}

export const CopiableField: React.FC<Props> = ({
  onClickCopy,
  value,
  disableUnderline,
  className,
  ...rest
}) => (
  <TextField
    data-cy="referral-text"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <CopyIconButton
            onClickCopyAdditionalAction={onClickCopy}
            textToCopy={value}
          />
        </InputAdornment>
      ),
      disableUnderline,
      className,
    }}
    value={value}
    {...rest}
  />
)
