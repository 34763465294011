import { EsportSlug } from '@plvs/graphql/types'
import { useFavicon } from './useFavicon'

const faviconEsportMap = {
  [EsportSlug.Hearthstone]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_HS.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_HS_alert.png',
  },
  [EsportSlug.LeagueOfLegends]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_LoL.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_LoL_alert.png',
  },
  [EsportSlug.Madden]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Madden.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Madden_alert.png',
  },
  [EsportSlug.MarioKart]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Mk8.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Mk8_alert.png',
  },
  [EsportSlug.NBA2K]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_NBA.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_NBA_alert.png',
  },
  [EsportSlug.Overwatch]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_OW.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_OW_alert.png',
  },
  [EsportSlug.RocketLeague]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_RL.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_RL_alert.png',
  },
  [EsportSlug.RocketLeagueGMA]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_RL.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_RL_alert.png',
  },
  [EsportSlug.SmashBrosUltimate]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Smash.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Smash_alert.png',
  },
  [EsportSlug.Splatoon]: {
    default:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Splatoon3.png',
    attention:
      'https://s3.amazonaws.com/assets.playvs.com/favicons/Firebob_Splatoon3_alert.png',
  },
}

interface UseEsportFaviconProps {
  esportSlug: EsportSlug | null
}

/**
 * Changes the favicon to an esport-specific favicon
 * with conditional alert.
 *
 * @param esportSlug
 * @param hasUnreadMessage
 */
export function useEsportFavicon({ esportSlug }: UseEsportFaviconProps): void {
  let faviconSrc: string | undefined
  if (esportSlug) {
    const favicons = faviconEsportMap[esportSlug]
    faviconSrc = favicons?.default
  }

  useFavicon(faviconSrc)
}
