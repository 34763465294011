import { Box, FormControlLabel, makeStyles } from '@material-ui/core'
import React from 'react'
import dayjs from 'dayjs'
import { TOSUpdatedAt } from '@plvs/const'
import {
  useGetTosAcceptanceQuery,
  useAcceptTosMutation,
  refetchGetTosAcceptanceQuery,
} from '@plvs/graphql/generated/graphql'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import {
  NxTypography,
  NxCheckbox,
  NxModal,
  NxButton,
  NxTextLinkExternal,
} from '@playvs-inc/nexus-components'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

const useStyles = makeStyles(() => ({
  termsContainer: {
    textAlign: 'center',
  },
  externalLink: {
    textTransform: 'none',
    display: 'inline-flex',
  },
}))

export const TOSModal = (): React.ReactElement => {
  const [isAccepted, toggleAccepted] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [isOpen, toggleOpen] = React.useState(true)
  const { data, loading } = useGetTosAcceptanceQuery()
  const classes = useStyles()
  const tosAcceptDate = data?.me?.tosAcceptedAt
  const showTOS = dayjs().isAfter(new Date(TOSUpdatedAt))
  const hasAcceptedNewestTOS =
    !!tosAcceptDate &&
    dayjs(new Date(tosAcceptDate)).isAfter(new Date(TOSUpdatedAt))

  const { isUnderage, loading: identityLoading } = useUserIdentityFn()

  const [acceptMutation] = useAcceptTosMutation()

  const subtitle = (
    <>
      {error && (
        <Box mt={2}>
          <NxTypography color="error" variant="body1">
            {error}
          </NxTypography>
          <NxButton
            label="Contact Support"
            onClick={openIntercom}
            variant="secondary"
          />
        </Box>
      )}

      <Box mt={2}>
        <NxTypography
          className={classes.termsContainer}
          component="span"
          variant="body1"
        >
          In order to continue using PlayVS, you must agree to our latest{' '}
          <NxTextLinkExternal
            className={classes.externalLink}
            display="inline"
            href="https://playvs.com/terms"
            label=" Terms of Use"
            rel="noopener"
            target="_blank"
            variant="body2"
          />{' '}
          and{' '}
          <NxTextLinkExternal
            className={classes.externalLink}
            display="inline"
            href="https://playvs.com/privacy"
            label=" Privacy Policy"
            rel="noopener"
            target="_blank"
            variant="body2"
          />
          . Please review and then accept the Terms of Use and Privacy Policy.
        </NxTypography>
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <NxCheckbox
              checked={isAccepted}
              data-testid="tosCheckbox"
              name="tosCheckbox"
              onChange={(): void => toggleAccepted(!isAccepted)}
            />
          }
          label="I agree to the updated Terms of Use & Privacy Policy"
        />
      </Box>
    </>
  )

  if (
    !showTOS ||
    hasAcceptedNewestTOS ||
    loading ||
    identityLoading ||
    isUnderage
  ) {
    return <></>
  }

  const onAcceptTerms = async (): Promise<void> => {
    try {
      await acceptMutation({
        awaitRefetchQueries: true,
        refetchQueries: [refetchGetTosAcceptanceQuery()],
      })
      toggleOpen(false)
    } catch (err: any) {
      setError('Failed TOS acceptance')
    }
  }

  return (
    <>
      <NxModal
        actions={
          <Box display="flex">
            <NxButton
              data-testid="tosButton"
              disabled={!isAccepted}
              label="Continue"
              onClick={onAcceptTerms}
              variant="primary"
            />
          </Box>
        }
        onClose={(): void => {}}
        open={isOpen}
        showTopRightClose={false}
        size="small"
        subtitle={subtitle}
        title="Terms of Use & Privacy Policy"
      />
    </>
  )
}
