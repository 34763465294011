import { useRef } from 'react'

export type PendingActionDef = () => Promise<void>

type WithSinglePendingRequest = (action: PendingActionDef) => void

export const useWithSinglePendingRequest = (): WithSinglePendingRequest => {
  const currentActionRef = useRef<PendingActionDef>()
  const pendingActionRef = useRef<PendingActionDef>()

  const withSinglePendingRequest: (action: PendingActionDef) => void = async (
    action: PendingActionDef
  ) => {
    if (currentActionRef.current) {
      pendingActionRef.current = action
      return
    }

    try {
      currentActionRef.current = action
      await action()
    } catch (err: any) {
      // Swallow error. All error handling should be done inside the action.
    }

    currentActionRef.current = undefined

    if (pendingActionRef.current) {
      const pendingAction = pendingActionRef.current
      pendingActionRef.current = undefined

      setImmediate(() => withSinglePendingRequest(pendingAction))
    }
  }
  return withSinglePendingRequest
}
