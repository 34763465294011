import React, { useState } from 'react'
import { SingleChat } from '@playvs-inc/nexus-icons'
import { useGlobalUnreadMessagesVar } from '@plvs/client-data/hooks'
import * as analytics from '@plvs/respawn/features/analytics'
import { IconToggle, IconDescription } from '../IconToggle'

type DrawerComponent = React.FC<{
  close?(): void
}>

interface AppDrawerChatMessagesToggleProps {
  ChatMessageDrawer: DrawerComponent
  isInAppBar?: boolean
}
export const AppDrawerChatMessagesToggle: React.FC<AppDrawerChatMessagesToggleProps> = ({
  ChatMessageDrawer,
  isInAppBar,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()

  const hasAnyUnreadMessages = Object.values(unreadMessagesByConversation).some(
    (value) => value > 0
  )

  const onClose = (): void => setIsOpen(false)

  const onOpen = (): void => {
    setIsOpen(!isOpen)
    analytics.globalChatNotificationsDrawerOpened({
      openedWithUnreadMessages: hasAnyUnreadMessages,
    })
  }

  return (
    <IconToggle
      badgeCount={hasAnyUnreadMessages ? 1 : 0}
      childDrawer={<ChatMessageDrawer close={onClose} />}
      Icon={SingleChat}
      iconDescription={IconDescription.Chat}
      isInAppBar={isInAppBar}
      isOpen={isOpen}
      isRedDotDisplayed={hasAnyUnreadMessages}
      onClose={onClose}
      onOpen={onOpen}
    />
  )
}
