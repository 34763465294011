import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { RewindData, RewindAssets } from '../Rewind.helpers'
import { useStyles } from './IntroSlide.styles'

interface IntroSlideProps {
  isLoggedInUser: boolean
}

export const IntroSlide: React.FC<IntroSlideProps> = ({ isLoggedInUser }) => {
  const styles = useStyles()

  const randomJoystickIndex = useMemo(
    () => Math.floor(Math.random() * RewindAssets.Joysticks.length),
    []
  )

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      p={3}
      pb={2}
    >
      <Box>
        <img alt="PlayVS" src={RewindAssets.PlayVS} />
      </Box>

      <Box>
        <img
          alt="Decorative joystick"
          className={styles.joystick}
          src={RewindAssets.Joysticks[randomJoystickIndex]}
        />
      </Box>

      <Box className={styles.textContainer} pl={4} position="relative">
        <img
          alt="Decorative vertical scribble"
          className={styles.scribble}
          src={RewindAssets.Scribble}
        />

        <NxTypography
          className={styles.text}
          colorToken="OverlayColorTextBase"
          variant="display"
        >
          {isLoggedInUser ? 'Your' : RewindData.Year}
        </NxTypography>

        <NxTypography
          className={styles.text}
          colorToken="OverlayColorTextBase"
          variant="display"
        >
          PlayVS
        </NxTypography>

        <NxTypography
          className={styles.text}
          colorToken="OverlayColorTextBase"
          variant="display"
        >
          Rewind
        </NxTypography>

        <img
          alt="Decorative mark-up"
          className={styles.spark}
          src={RewindAssets.Blast}
        />
      </Box>

      <Box alignItems="flex-end" display="flex" justifyContent="space-between">
        <Box>
          <img
            alt="Decorative checker pattern"
            className={styles.checker}
            src={RewindAssets.Checker}
          />
        </Box>
      </Box>
    </Box>
  )
}
