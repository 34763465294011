import React from 'react'

import { NxTypography } from '@playvs-inc/nexus-components'

import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { ScrimmageBadge } from '@plvs/rally/components/badge'
import {
  MatchStatus,
  PhaseType,
  useGetMatchPhaseTypeAndScheduleStartsAtQuery,
} from '@plvs/graphql/generated'
import { MatchCountdown } from './MatchCountdown'
import { TextGrouping } from './TextGrouping'

export const MatchFaceoffHeader: React.FC<{
  countdown?: string
  liveLabel: string
  mobile?: boolean
  bestOf: number
  status: MatchStatus | null | undefined
  matchId: string
  refetchMatchStatus(): Promise<void>
}> = ({
  countdown,
  liveLabel,
  bestOf,
  mobile,
  status,
  matchId,
  refetchMatchStatus,
}) => {
  const countdownStyle = mobile ? { fontSize: 12 } : {}

  const { data, loading } = useGetMatchPhaseTypeAndScheduleStartsAtQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const scheduledStartsAt = data?.match?.scheduledStartsAt
  const isPreseason = data?.match?.slot?.phase?.type === PhaseType.Preseason
  const isScrimmage = data?.match?.isScrimmage

  return (
    <Box alignItems="center" display="flex" mb={mobile ? 1 : 3}>
      <Box display="flex">
        <TextGrouping label={`Best of ${bestOf}`} value="" />
      </Box>
      <Box alignItems="center" display="flex" flexWrap="wrap">
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          {countdown ? (
            <NxTypography
              colorToken="OverlayColorTextBase"
              style={countdownStyle}
              variant="subtitle2"
            >
              {countdown}
            </NxTypography>
          ) : (
            <MatchCountdown
              data-cy="countDown"
              date={scheduledStartsAt}
              liveLabel={liveLabel}
              mobile={mobile}
              refetchMatchStatus={refetchMatchStatus}
              status={status}
            />
          )}
          {mobile && isScrimmage && (
            <ScrimmageBadge isPreseason={isPreseason} size="small" />
          )}
        </WaitTillLoaded>
      </Box>
    </Box>
  )
}
