import React, { useRef, useState } from 'react'

import {
  NxTypography,
  NxButton,
  NxIconButton,
} from '@playvs-inc/nexus-components'

import {
  useDeleteMatchSettleRequestAssetMutation,
  useUploadMatchSettleRequestAssetMutation,
} from '@plvs/graphql/generated'
import {
  acceptableFileTypes,
  getAssetFromUploadMutationResult,
  ScreenshotSizeLimit,
} from '@plvs/rally/components/form/attach/attachHelpers'
import { MBToBytesMap } from '@plvs/const'
import { includes } from 'ramda'
import { makeStyles } from '@material-ui/core'
import { AvatarCircle } from '@plvs/rally/components/avatar'
import { Trashcan } from '@playvs-inc/nexus-icons'
import {
  addAttachment,
  removeAttachment,
  SeriesAttachments,
  SetAttachmentsFn,
} from './AttachScreenshotsButton.helpers'
import { Attachment } from '../../dynamic.types'

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },

  gameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1em',
    paddingBottom: theme.spacing(2),
  },

  screenshotWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },

  gameName: {
    color: theme.palette.ColorTextAlt2,
  },

  fileName: {
    color: theme.palette.ColorTextAlt2,
  },

  errorMessage: {
    color: theme.palette.ColorTextError,
  },
  emptyState: {
    color: theme.palette.ColorTextAlt2,
    paddingTop: theme.spacing(1),
  },
  trashCan: {
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
}))

interface AttachGameProps {
  gameOrdinal: number
  screenshotSizeLimit: ScreenshotSizeLimit
  attachments: SeriesAttachments
  setAttachments: SetAttachmentsFn
  seriesId: string
}

export const AttachGame: React.FC<AttachGameProps> = ({
  gameOrdinal,
  attachments,
  setAttachments,
  screenshotSizeLimit = '3MB',
  seriesId,
}) => {
  const styles = useStyles()
  const inputRef = useRef<HTMLInputElement>()
  const [error, setError] = useState<Error>()
  const [
    uploadMutation,
    { loading: isUploadLoading },
  ] = useUploadMatchSettleRequestAssetMutation()
  const [
    removeMutation,
    { loading: isDeleteLoading },
  ] = useDeleteMatchSettleRequestAssetMutation()

  const gameAttachments = (attachments[seriesId] &&
    attachments[seriesId].find(
      ({ ordinalNumber }) => ordinalNumber === gameOrdinal
    )) || { assets: [] as Attachment[] }
  const hasAttachment = Boolean(gameAttachments.assets.length)

  const onChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)

    try {
      const file = event?.target?.files?.[0]
      if (file) {
        const { size, type } = file

        if (size > MBToBytesMap[screenshotSizeLimit]) {
          throw new Error(`File size must be less than ${screenshotSizeLimit}`)
        }

        if (!includes(type, acceptableFileTypes)) {
          throw new Error('File must be jpeg or png.')
        }

        const result = await uploadMutation({
          awaitRefetchQueries: true,
          variables: { file },
        })

        const asset = getAssetFromUploadMutationResult(result)

        if (asset) {
          addAttachment({
            attachments,
            attachmentToAdd: asset,
            setAttachments,
            ordinalNumber: gameOrdinal,
            seriesId,
          })
        }
      }
    } catch (err: unknown) {
      setError(err as Error)
    }

    // Resets input[type='file'] to prevent issue where onChange isn't triggered
    // when re-attaching a removed screenshot
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  // input[type='file'] is notoriously difficult to style,
  // so pass our button's click event to trigger the file input.
  const onButtonClick = (): void => {
    inputRef?.current?.click()
  }

  const onRemoveClick = async (attachment: Attachment): Promise<void> => {
    try {
      await removeMutation({
        awaitRefetchQueries: true,
        variables: { assetId: attachment.id },
      })
      removeAttachment({
        attachments,
        attachmentToRemove: attachment,
        setAttachments,
        ordinalNumber: gameOrdinal,
        seriesId,
      })
    } catch (err: unknown) {
      setError(err as Error)
    }
  }

  return (
    <>
      <div className={styles.gameWrapper}>
        <NxTypography className={styles.gameName} variant="body2">
          Game {gameOrdinal}
        </NxTypography>
        <NxButton
          label="Add Screenshot"
          loading={isUploadLoading}
          onClick={onButtonClick}
          variant="secondary"
        />
        <input
          // @ts-ignore HTMLInputElement is a valid ref type
          ref={inputRef}
          accept={acceptableFileTypes.join(',')}
          className={styles.fileInput}
          onChange={onChange}
          type="file"
        />
      </div>

      {error && (
        <NxTypography className={styles.errorMessage}>
          {error.message}
        </NxTypography>
      )}

      {hasAttachment && (
        <div>
          {gameAttachments.assets.map((attachment: Attachment) => (
            <div key={attachment.id} className={styles.gameWrapper}>
              <div className={styles.screenshotWrapper}>
                {attachment.signedUrl && (
                  <AvatarCircle
                    avatarUrl={attachment.signedUrl}
                    shape="square"
                    size="medium"
                  />
                )}
                <div className={styles.fileName}>{attachment.fileName}</div>
              </div>
              <NxIconButton
                className={styles.trashCan}
                disabled={isDeleteLoading}
                icon={<Trashcan />}
                label=""
                onClick={(): Promise<void> => onRemoveClick(attachment)}
                variant="text"
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}
