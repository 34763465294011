import { head } from 'ramda'

import { MatchTeam } from '@plvs/graphql/generated'
import { LobbyTeam, MatchAssistantTeam } from '@plvs/utils'
import { MatchTeamForRenderController } from '@plvs/respawn/renderController/matchLobby/match/types/matchRenderController.types'
import { CurrentPlayersForMatchAssistant } from './MatchAssistant.types'
import { LobbySeries } from './useMatchAssistant.types'

interface MapSeriesToScoreboardRowArrayProps {
  homeTeamId?: string
  series: LobbySeries[]
}

export const mapSeriesToScoreboardRowArray = ({
  homeTeamId,
  series,
}: MapSeriesToScoreboardRowArrayProps): CurrentPlayersForMatchAssistant[] => {
  return (
    head(
      series?.map((currSeries) => {
        const seriesHomeTeam:
          | LobbyTeam
          | null
          | Partial<MatchTeam>
          | undefined =
          currSeries.team1?.id === homeTeamId
            ? currSeries.team1
            : currSeries.team2

        const seriesAwayTeam:
          | LobbyTeam
          | null
          | Partial<MatchTeam>
          | undefined =
          currSeries.team1?.id === homeTeamId
            ? currSeries.team2
            : currSeries.team1

        const homePlayer = seriesHomeTeam?.roster?.[0]
        const awayPlayer = seriesAwayTeam?.roster?.[0]

        return [
          {
            title: homePlayer?.name ?? '',
            subtitles: [{ title: seriesHomeTeam?.name ?? '' }],
            avatarUrl: homePlayer?.avatarUrl ?? undefined,
            id: homePlayer?.id ?? '',
            userProviderName: homePlayer?.publisherAccountName ?? '',
          },
          {
            title: awayPlayer?.name ?? '',
            subtitles: [{ title: seriesAwayTeam?.name ?? '' }],
            avatarUrl: awayPlayer?.avatarUrl ?? undefined,
            id: awayPlayer?.id ?? '',
            userProviderName: awayPlayer?.publisherAccountName ?? '',
          },
        ]
      })
    ) ?? []
  )
}

export function normalizeMatchAssistantTeam(
  team: MatchAssistantTeam | null | undefined
): MatchTeamForRenderController {
  return {
    id: team?.id ?? '',
    schoolId: team?.schoolId ?? '',
  }
}
