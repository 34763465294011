import React from 'react'
import {
  Checkbox as MuiCheckbox,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import CheckIcon from '@material-ui/icons/Check'

import { Box } from '@plvs/respawn/features/layout'

interface Props {
  checked?: boolean
  checkedColor?: string
  backgroundColor?: string
  borderColor?: string
  inputRef?: React.Ref<any>
  name?: string
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  onClick?(event: React.SyntheticEvent): void
  ariaDescribedBy?: string
  ariaLabelledBy?: string
  disabled?: boolean
  size?: 'small' | 'medium'
}

interface CSSProps extends Props {
  isSmall?: boolean
}

const SMALL_SIZE = 16
const MEDIUM_SIZE = 32

const useStyles = makeStyles((theme) => ({
  root: ({ disabled }: CSSProps): CreateCSSProperties => ({
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: disabled ? 'default' : 'pointer',
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  }),
  checkIcon: ({
    checkedColor,
    backgroundColor,
    isSmall,
  }: CSSProps): CreateCSSProperties => ({
    fontSize: isSmall ? SMALL_SIZE : MEDIUM_SIZE,
    color: checkedColor,
    backgroundColor,
    borderRadius: 4,
  }),
  outlineBox: ({ borderColor, isSmall }: CSSProps): CreateCSSProperties => ({
    width: isSmall ? SMALL_SIZE : MEDIUM_SIZE,
    height: isSmall ? SMALL_SIZE : MEDIUM_SIZE,
    color: theme.palette.BorderMedium,
    border: `2px solid ${borderColor}`,
    borderRadius: 4,
  }),
}))

export const Checkbox: React.FC<Props> = ({
  checked,
  checkedColor,
  backgroundColor = 'rgba(0, 170, 111, 0.25)',
  borderColor,
  inputRef,
  name,
  onChange,
  onClick,
  ariaDescribedBy,
  ariaLabelledBy,
  disabled = false,
  size = 'medium',
}) => {
  const isSmall = size === 'small'

  const theme = useTheme()

  const classes = useStyles({
    checkedColor: checkedColor ?? theme.palette.ColorIconSuccess,
    borderColor: borderColor ?? theme.palette.BorderLight,
    backgroundColor,
    disabled,
    isSmall,
  })

  return (
    <MuiCheckbox
      checked={checked}
      checkedIcon={<CheckIcon className={classes.checkIcon} />}
      className={classes.root}
      disabled={disabled}
      icon={<Box className={classes.outlineBox} />}
      inputProps={{
        'aria-labelledby': ariaLabelledBy,
        'aria-describedby': ariaDescribedBy,
        role: 'checkbox',
        id: name,
      }}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      onClick={onClick}
    />
  )
}
