// eslint-disable-next-line import/no-restricted-paths
import { EsportPreferenceModal } from '@plvs/rally/containers/managePlayers/EsportPreferenceModal'
import { NxIconButton } from '@playvs-inc/nexus-components'
import React from 'react'
import { Edit, AddteamHover } from '@playvs-inc/nexus-icons'

interface EsportEntry {
  id: string
  slug: string
}

type AssignEsportForUserButtonProps = {
  userId: string
  esportInterests: EsportEntry[]
  hasSchool: boolean
}

export const AssignEsportForUserButton: React.FC<AssignEsportForUserButtonProps> = ({
  userId,
  hasSchool,
  esportInterests,
}) => {
  const hasEsportInterests = !!esportInterests.length

  // dialog
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const openModal = (): void => setIsModalOpen(true)
  const closeModal = (): void => setIsModalOpen(false)

  return hasSchool ? (
    <>
      <NxIconButton
        data-cy="assign-esport-preference-button"
        icon={hasEsportInterests ? <Edit /> : <AddteamHover />}
        label={hasEsportInterests ? 'Edit' : 'Add'}
        onClick={openModal}
        variant="text"
      />
      {isModalOpen ? (
        <EsportPreferenceModal
          closeModal={closeModal}
          esportInterests={esportInterests}
          isModalOpen={isModalOpen}
          userId={userId}
        />
      ) : null}
    </>
  ) : (
    <>Player doesn‘t have a school</>
  )
}
