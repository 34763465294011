/* eslint-disable import/no-unresolved */
import { Box, makeStyles } from '@material-ui/core'
import {
  GetMyVerificationStatusQuery,
  refetchGetUserTasksQuery,
  TaskState,
  useDeleteUserTaskQueueMutation,
  VerificationStep,
} from '@plvs/graphql/generated'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import {
  NxCardPaginationButtons,
  NxTasksProgressCard,
} from '@playvs-inc/nexus-components'
import { isSafari } from '@plvs/rally/core'

import DownloadHandbookTaskCard from './DownloadHandbookTaskCard'
import EnrollTeamTaskCard from './EnrollTeamTaskCard'
import FillRosterTaskCard from './FillRosterTaskCard'
import PlayerAccountConnectionTaskCard from './PlayerAccountConnectionTaskCard'
import PlayerVerificationTaskCard from './PlayerVerificationTaskCard'
import ScholasticVerificationTaskCard from './ScholasticVerificationTaskCard'
import SchoolTeamCreationTaskCard from './SchoolTeamCreationTaskCard'
import PlayMatchTaskCard from './PlayMatchTaskCard'
import CompleteChecklistTaskCard from './CompleteChecklistTaskCard'

const TASK_CARD_WIDTH = 392

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '286px',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
  },
  mobileContainer: {
    display: 'flex',
    height: 'max-content',
    flexDirection: 'row',
    position: 'relative',
  },
  tasksContainer: {
    position: 'absolute',
    left: '270px',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 3,
    paddingBottom: '1em',
    height: 'calc(100% + 1em)',
    width: 'calc(100% - 242px)',
    overflow: 'hidden',
  },
  mobileTaskContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 3,
    overflow: 'auto',
    position: 'absolute',
    top: '170px',
    left: '0',
    scrollSnapType: 'x mandatory',
    scrollPadding: '0 4%',
  },
  scrollControlContainer: {
    display: 'flex',
    position: 'absolute',
    zIndex: 4,
    right: '0px',
    top: '-40px',
    width: '60px',
    height: '24px',
    justifyContent: 'space-between',
  },
  icon: {
    height: '24px',
  },
  flippedIcon: {
    height: '24px',
    transform: 'rotate(180deg)',
  },
})

export const TaskCards: React.FC<{
  tasks: { state: string; id: string; type: string }[]
  verificationStatus: GetMyVerificationStatusQuery | undefined
}> = ({ tasks, verificationStatus }) => {
  const isPendingVerification =
    verificationStatus?.me?.currentVerification?.step !==
      VerificationStep.DocUpload &&
    verificationStatus?.me?.currentVerification?.step !==
      VerificationStep.Success
  const defaultTaskView = tasks.findIndex(
    (task) =>
      (isPendingVerification && task.state === TaskState.Disabled) ||
      task.state === TaskState.Enabled
  )

  const [currentTaskToView, setCurrentTaskToView] = useState(
    (defaultTaskView > 0 && defaultTaskView + 1) || 1
  )
  const [isNextAllowed, setIsNextAllowed] = useState(true)
  const { id: orgId } = useSelectedOrganizationFn()
  const isMobile = useBreakpointXs()
  const classes = useStyles()
  const tasksContainerRef = useRef<HTMLDivElement>()

  const progress = {
    completed: tasks.filter((task) => task.state === 'completed').length,
    total: tasks.length,
  }

  const nextTaskIndex = tasks.findIndex(
    (task) => task.state === TaskState.Enabled
  )

  const taskCards = tasks.map((task, index) => {
    switch (task.type) {
      case 'verifySelf':
        return (
          <ScholasticVerificationTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            isPendingVerification={isPendingVerification}
            task={task}
          />
        )
      case 'viewHandbook':
        return (
          <DownloadHandbookTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            task={task}
          />
        )
      case 'createTeam':
        return (
          <SchoolTeamCreationTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            schoolId={orgId}
            task={task}
          />
        )
      case 'verifyPlayers':
        return (
          <PlayerVerificationTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            schoolId={orgId}
            task={task}
          />
        )
      case 'fillRoster':
        return (
          <FillRosterTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            task={task}
          />
        )
      case 'enrollTeam':
        return (
          <EnrollTeamTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            task={task}
          />
        )
      case 'linkAccounts':
        return (
          <PlayerAccountConnectionTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            schoolId={orgId}
            task={task}
          />
        )
      case 'completeChecklist':
        return (
          <CompleteChecklistTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            task={task}
          />
        )
      case 'playMatch':
        return (
          <PlayMatchTaskCard
            key={task.id}
            index={index + 1}
            isMobile={isMobile}
            task={task}
          />
        )
      default:
        return null
    }
  })

  // Initial scroll next available task into focus.

  useLayoutEffect(() => {
    if (!tasksContainerRef.current || currentTaskToView < 1) {
      return
    }

    const childTaskCards = tasksContainerRef.current?.querySelectorAll(
      'div.TaskCard'
    )

    const focusedTaskCard: Element | undefined =
      childTaskCards?.[currentTaskToView - 1]

    if (!focusedTaskCard) {
      return
    }

    if (isMobile && tasksContainerRef?.current?.scrollTo) {
      // shift over by size of the card x times (See TaskCard for dimensions)
      tasksContainerRef.current.scrollTo({
        left: 150 * currentTaskToView,
        top: 0,
        behavior: isSafari ? undefined : 'smooth',
      })
    } else if (
      tasksContainerRef?.current?.scrollTo &&
      focusedTaskCard instanceof HTMLElement
    ) {
      tasksContainerRef.current.scrollTo({
        left: focusedTaskCard.offsetLeft,
        top: 0,
        behavior: isSafari ? undefined : 'smooth',
      })
    }
  }, [nextTaskIndex, isMobile, currentTaskToView])

  useEffect(() => {
    const maxViewableCards = Math.floor(
      (tasksContainerRef?.current &&
        tasksContainerRef?.current?.clientWidth / TASK_CARD_WIDTH) ||
        0
    )

    if (currentTaskToView < progress.total + 1 - maxViewableCards) {
      setIsNextAllowed(true)
    } else {
      setIsNextAllowed(false)
    }
  }, [currentTaskToView])

  const onShowPreviousCard = (): void => {
    if (currentTaskToView > 1) {
      setCurrentTaskToView(currentTaskToView - 1)
    }
  }
  const onShowNextCard = (): void => {
    if (isNextAllowed && currentTaskToView !== progress.total) {
      setCurrentTaskToView(currentTaskToView + 1)
    }
  }
  const { userId } = useUserIdentityFn()
  const [deleteUserTask] = useDeleteUserTaskQueueMutation({
    refetchQueries: [
      { ...refetchGetUserTasksQuery(), variables: { id: userId } },
    ],
  })

  const handleCompleteUserTaskQueue = (): void => {
    deleteUserTask()
  }
  return (
    <Box
      className={isMobile ? classes.mobileContainer : classes.container}
      mb={7}
      mt={isMobile ? 0 : 7}
    >
      <NxTasksProgressCard
        onDonePressed={handleCompleteUserTaskQueue}
        progress={progress}
      />
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore Ref is a valid prop */}
      <Box
        data-testid="task-container"
        // This hack is because the typings for Box is missing 'ref' prop.
        // but it actually exists.  https://github.com/mui-org/material-ui/issues/17010
        {...{ ref: tasksContainerRef }}
        className={
          isMobile ? classes.mobileTaskContainer : classes.tasksContainer
        }
      >
        {taskCards}
        <Box mr={112} />
      </Box>
      {!isMobile && (
        <Box className={classes.scrollControlContainer}>
          <NxCardPaginationButtons
            enableLeft={currentTaskToView > 1}
            enableRight={isNextAllowed}
            onLeft={onShowPreviousCard}
            onRight={onShowNextCard}
          />
        </Box>
      )}
    </Box>
  )
}
