import React from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles, Paper } from '@material-ui/core'

import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  CompetitionGroup,
  EsportSlug,
  EsportRating,
  useFindLeaguePreviewsByEsportSchoolQuery,
  useGetEsportBySlugQuery,
  LeaguePreview,
  EsportRulebook,
  SeasonType,
} from '@plvs/graphql'
import { head } from 'ramda'
import {
  formatInTz,
  DateFormats,
  useAutoskipQuery,
  findNextCompSlot,
} from '@plvs/utils'
import {
  NxTypography,
  NxButton,
  NxEsportBanner,
  NxBreadcrumbs,
  useGetEsportsProps,
  PersonaImageVariant,
  OverlayPill,
} from '@playvs-inc/nexus-components'

import { noop } from 'ramda-adjunct'
import { LeagueDarkLargePrimary } from '@playvs-inc/nexus-spots'

import { useFlags } from 'launchdarkly-react-client-sdk'

import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import {
  exploreLeaguesHeroSubtitleMap,
  getHeroSubtitle,
  getSeasonEnrollmentStatus,
} from './ExploreHelpers'

import { CoachResources } from './CoachResources/CoachResources'
import { ExploreLeagueCard } from './ExploreLeagueCard'

dayjs.extend(tz)

export const useStyles = makeStyles((theme) => ({
  subtitleText: {
    marginBottom: theme.spacing(2.5),
    letterSpacing: '0.12em',
  },
  heroTitleText: {
    marginBottom: '24px',
    textTransform: 'capitalize',
  },
  exploreContainer: {
    cursor: 'pointer',
  },
  cta: {
    marginRight: '16px',
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  pageSubtitle: {
    color: theme.palette.ColorTextAlt2,
    marginBottom: theme.spacing(3),
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
  },
  leagueCard: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  noLeaguesContainer: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  goBackButton: {
    marginTop: theme.spacing(2),
  },
  nextIcon: {
    color: '#F7F8F8',
  },
  pill: {
    letterSpacing: '0.12em',
  },
  breadcrumbs: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}))

export const ExploreLeaguesTable: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const flags = useFlags()

  const { id: organizationId } = useSelectedOrganizationFn()
  const { competitionGroup, esportSlug } = useParams<{
    competitionGroup: CompetitionGroup
    esportSlug: EsportSlug
  }>()
  const { data: esportData, loading: esportLoading } = useAutoskipQuery(
    useGetEsportBySlugQuery,
    {
      variables: { slug: esportSlug as EsportSlug },
    }
  )

  const esport = esportData?.esport

  const {
    data: leaguePreviewsData,
    loading: leaguePreviewsDataLoading,
  } = useFindLeaguePreviewsByEsportSchoolQuery({
    variables: {
      esportId: esport?.id as string,
      schoolId: organizationId,
    },
    skip: !esport?.id,
  })

  const rulebook = leaguePreviewsData?.findLeaguePreviewsByEsportSchool
    .rulebook as EsportRulebook
  const leaguePreviews =
    (leaguePreviewsData?.findLeaguePreviewsByEsportSchool
      ?.leaguePreviews as LeaguePreview[]) ?? []

  const leaguesDetails = leaguePreviews.map((leaguePreview) => {
    const { league, nextSeason: season } = leaguePreview
    const numberOfEnrolledTeams = season?.numberOfTeams ?? 0
    const metaseasonName = season?.metaseason?.name ?? ''
    const phase = season?.phases ? season?.phases[0] : null
    const nextCompSlot = findNextCompSlot(phase)
    const competitionTime = nextCompSlot?.startsAt
    const formattedTime = competitionTime
      ? formatInTz({
          at: competitionTime,
          format: DateFormats.LeagueCompetitionTimeFormat,
          timezone: dayjs.tz.guess(),
        })
      : 'TBD'

    const competitionModel = league?.competitionModel
    const startingRangeFormat = 'MMM. D'
    const endingRangeFormat = 'MMM. D, YYYY'
    const seasonRange = `${formatInTz({
      at: season?.startsAt,
      format: startingRangeFormat,
      timezone: dayjs.tz.guess(),
    })} - ${formatInTz({
      at: season?.endsAt,
      format: endingRangeFormat,
      timezone: dayjs.tz.guess(),
    })}`

    return {
      id: league.id,
      competitionModel,
      name: league.displayName ?? league.name,
      status: getSeasonEnrollmentStatus({
        teamRegistrationEndsAt: season?.teamRegistrationEndsAt ?? undefined,
        registrationStartsAt: season?.registrationStartsAt,
      }),
      time: formattedTime,
      teamsCount: numberOfEnrolledTeams,
      leagueId: league.id,
      esportName: esport?.name ?? '',
      metaseasonName,
      rating: esport?.rating,
      rulebookUrl: rulebook?.url,
      publicSlug: league?.publicSlug ?? '',
      type: season?.type,
      seasonRange,
      metaseasonId: season?.metaseason?.id,
      sponsorshipImages: season?.sponsorshipImages,
    }
  })
  const esportName = esport?.name ?? ''
  const metaseasonName = head(leaguesDetails)?.metaseasonName
  const esportRating = esport?.rating
  const rulebookUrl = head(leaguesDetails)?.rulebookUrl

  const handleExploreClick = (): void => {
    navigate(`/app/explore`)
  }

  const { pills } = useGetEsportsProps(esportSlug as EsportSlug)

  const leagueTypes = leaguesDetails.filter((l) => l.type === SeasonType.Season)
  const tournamentTypes = leaguesDetails.filter(
    (l) => l.type === SeasonType.Tournament
  )

  return (
    <WaitTillLoaded loading={leaguePreviewsDataLoading || esportLoading}>
      {flags.activateFullBleedBanners && esportSlug ? (
        <HeroGutter mb={2} mt={0} mx={[1, 2]}>
          <NxEsportBanner
            additionalContent={
              <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gridGap="8px"
                mt={2}
              >
                {pills?.map((label) => {
                  return (
                    <OverlayPill
                      key={label}
                      className={classes.pill}
                      label={label}
                      size="small"
                      variant="disabled"
                    />
                  )
                })}
              </Box>
            }
            breadcrumbs={
              <NxBreadcrumbs
                breadcrumbs={[
                  {
                    label: 'Competitions',
                    onClick: handleExploreClick,
                  },
                  {
                    label: esportName || '',
                    onClick: noop,
                  },
                ]}
                className={classes.breadcrumbs}
                useOverlayColor
              />
            }
            esportSlug={esportSlug as EsportSlug}
            hasNoTopNavBar
            size="large"
            subtitle={exploreLeaguesHeroSubtitleMap[esportSlug as EsportSlug]}
            title={esportName}
            useOverlayImage
            variant={PersonaImageVariant.League}
          />
        </HeroGutter>
      ) : (
        <HeroGutter>
          <MatchImageCard esportSlug={esportSlug as EsportSlug}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              py={2}
              width="100%"
            >
              <Box padding="40px" paddingTop="20px">
                <Box paddingBottom="40px">
                  <NxBreadcrumbs
                    breadcrumbs={[
                      {
                        label: 'Competitions',
                        onClick: handleExploreClick,
                      },
                      {
                        label: esportName || '',
                        onClick: noop,
                      },
                    ]}
                    useOverlayColor
                  />
                </Box>
                <NxTypography
                  className={classes.heroTitleText}
                  colorToken="OverlayColorTextBase"
                  data-cy={esportName}
                  variant="display"
                >
                  {esportName}
                </NxTypography>
                <NxTypography
                  className={classes.subtitleText}
                  color="inherit"
                  variant="body1"
                >
                  {getHeroSubtitle({
                    competitionGroup: competitionGroup as CompetitionGroup,
                    rating: esportRating ?? EsportRating.General,
                    metaseasonName: metaseasonName ?? '',
                  })}
                </NxTypography>
              </Box>
            </Box>
          </MatchImageCard>
        </HeroGutter>
      )}

      <PageContentGutter>
        <Box display="flex" flexDirection={['column', 'row']}>
          <Box
            display="flex"
            flexDirection="column"
            mr={[0, 3]}
            width={['100%', 686]}
          >
            {leagueTypes.length > 0 && (
              <>
                <NxTypography className={classes.pageTitle} variant="h3">
                  Leagues
                </NxTypography>
                <NxTypography className={classes.pageSubtitle} variant="body3">
                  All available {esportName} Leagues in your region.
                </NxTypography>
                {leagueTypes.map((league) => (
                  <ExploreLeagueCard
                    key={league.id}
                    esportSlug={esportSlug as EsportSlug}
                    id={league.id}
                    metaseasonId={league.metaseasonId}
                    name={league.name}
                    publicSlug={league.publicSlug}
                    seasonRange={league.seasonRange}
                    sponsorshipImages={league.sponsorshipImages ?? []}
                    status={league.status}
                    teamsCount={league.teamsCount}
                    time={league.time}
                  />
                ))}
              </>
            )}
            {tournamentTypes.length > 0 && (
              <>
                <NxTypography className={classes.pageTitle} variant="h3">
                  Tournaments
                </NxTypography>
                <NxTypography className={classes.pageSubtitle} variant="body3">
                  Find every {esportName} tournament happening in your region.
                </NxTypography>
                {tournamentTypes.map((league) => (
                  <ExploreLeagueCard
                    key={league.id}
                    esportSlug={esportSlug as EsportSlug}
                    id={league.id}
                    metaseasonId={league.metaseasonId}
                    name={league.name}
                    publicSlug={league.publicSlug}
                    seasonRange={league.seasonRange}
                    sponsorshipImages={league.sponsorshipImages ?? []}
                    status={league.status}
                    teamsCount={league.teamsCount}
                    time={league.time}
                  />
                ))}
              </>
            )}
            {!leagueTypes.length && !tournamentTypes.length && (
              <Paper className={classes.noLeaguesContainer} elevation={1}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <LeagueDarkLargePrimary />
                  <NxTypography variant="h4">No Leagues Available</NxTypography>
                  <NxButton
                    className={classes.goBackButton}
                    label="Go Back to Explore"
                    onClick={handleExploreClick}
                    variant="primary"
                  />
                </Box>
              </Paper>
            )}
          </Box>
          <Box display="flex" flexDirection="column" width={['100%', 332]}>
            <CoachResources
              esportSlug={esportSlug as EsportSlug}
              rulebookUrl={rulebookUrl}
            />
          </Box>
        </Box>
        {esportSlug && (
          <EsrbRating esportSlug={esportSlug as EsportSlug} mt={5} />
        )}
      </PageContentGutter>
    </WaitTillLoaded>
  )
}
