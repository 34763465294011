import React from 'react'

import { Pill, PillProps } from '@playvs-inc/nexus-components'

interface ScrimmageBadgeProps {
  isPreseason?: boolean
  size?: PillProps['size']
  variant?: PillProps['variant']
}

export const ScrimmageBadge: React.FC<ScrimmageBadgeProps> = ({
  size = 'large',
  variant = 'brand',
  isPreseason = false,
}) => (
  <Pill
    label={isPreseason ? 'Preseason' : 'Scrimmage'}
    size={size}
    variant={variant}
  />
)
