import React, { CSSProperties } from 'react'
import { isNumber } from 'ramda-adjunct'
import { CellInfo } from 'react-table-v6'
import { FormatFn } from '../Table'

export const DefaultCell = ({
  defaultEmptyCellValue,
  format,
  style,
}: {
  defaultEmptyCellValue: string
  format?: FormatFn
  style?: Record<string, CSSProperties>
}) => (data: CellInfo): React.ReactElement => {
  const value = data?.value
  const formattedValue = format ? format(value) : value

  return (
    <span style={style}>
      {value || isNumber(value) ? formattedValue : defaultEmptyCellValue}
    </span>
  )
}
