import { createContext, useContext } from 'react'
import { noop } from 'ramda-adjunct'
import { MyLeaguesContext } from './myLeaguesTypes'

export const myLeaguesContext = createContext<MyLeaguesContext>({
  leagues: [],
  loading: true,
  error: null,
  setLeague: noop,
  setMetaseason: noop,
  seasons: [],
  phases: [],
  setPhase: noop,
  metaseasons: [],
})

export const { Provider } = myLeaguesContext

export const useMyLeagues = (): MyLeaguesContext => useContext(myLeaguesContext)
