import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { CreateCSSProperties } from '@material-ui/styles'

import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { NxTypography } from '@playvs-inc/nexus-components'
import { SchoolOutlined } from '@playvs-inc/nexus-icons'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useNavigate } from 'react-router-dom'

export interface UsernameDashboardComponentMinimalUser {
  id: string
  firstName: string
  avatarUrl?: string | null
  school?: {
    name: string
    slug: string
  } | null
}

const useStyles = makeStyles((theme) => ({
  container: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    margin: isMobile ? '16px 0' : '1em 0',
    width: '100%',
    overflow: 'hidden',
  }),
  textContainer: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    paddingLeft: isMobile ? '1em' : '2em',
    width: '*',
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: '1em',
    overflow: 'hidden',
  }),
  avatarSkeleton: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    width: isMobile ? '54px' : '8rem',
    height: isMobile ? '54px' : '8rem',
  }),
  userNameHeading: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    fontSize: isMobile ? '1.625em' : '3em',
    lineHeight: '108%',
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
    textOverflow: 'ellipsis',
    width: '100%',
    color: theme.palette.ColorTextAlt || theme.palette.text.secondary,
  }),
  schoolNameHeading: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '.18em',
    fontSize: isMobile ? '1em' : '1.625em',
    lineHeight: '108%',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    textOverflow: 'ellipsis',
    width: '100%',
    cursor: 'pointer',
  }),
  schoolIcon: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: isMobile ? 'none' : 'inherit',
    marginRight: '1rem',
    width: '1em',
    height: '1em',
  }),
}))

export const UsernameDashboardComponent: React.FC<{
  user?: UsernameDashboardComponentMinimalUser
}> = ({ user }) => {
  const navigate = useNavigate()
  const isMobile = useBreakpointXs()
  const classes = useStyles({ isMobile })

  const onNavigateToSchool = (): void => {
    navigate(`${Path.MySchoolActivity}`)
  }

  return (
    <Box
      className={classes.container}
      data-testid="UsernameDashboardComponent_Container"
      display="flex"
      flexDirection="row"
      overflow="hidden"
    >
      <Box alignItems="center" display="flex" justifyContent="center">
        {user ? (
          <NxUserAvatar
            avatarUrl={user.avatarUrl}
            hashId={user.id}
            size={isMobile ? NxAvatarSize.MD : NxAvatarSize.XXL}
          />
        ) : (
          <Skeleton className={classes.avatarSkeleton} variant="circle" />
        )}
      </Box>
      <Box
        alignItems="flex-start"
        className={classes.textContainer}
        data-testid="UsernameDashboardComponent_TextContainer"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {user?.firstName && (
          <NxTypography
            className={classes.userNameHeading}
            data-testid="UsernameDashboardComponent_user_name"
            noWrap
            variant="display2"
          >
            Hi {user.firstName}!
          </NxTypography>
        )}
        {!user && (
          <Skeleton
            className={classes.userNameHeading}
            variant="text"
            width="5em"
          />
        )}
        {user?.school?.name && (
          <NxTypography
            className={classes.schoolNameHeading}
            color="textSecondary"
            data-testid="UsernameDashboardComponent_school_name"
            noWrap
            onClick={onNavigateToSchool}
            variant="subtitle1"
          >
            <SchoolOutlined className={classes.schoolIcon} />
            {user.school.name}
          </NxTypography>
        )}
        {!user && (
          <Skeleton
            className={classes.schoolNameHeading}
            variant="text"
            width="12em"
          />
        )}
      </Box>
    </Box>
  )
}
