import { Avatar, Card, CardContent, useTheme, Box } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'
import { SizeMe } from 'react-sizeme'
import { DEFAULT_COMPONENT_WIDTH } from '@plvs/const'
import { NxTypography } from '@playvs-inc/nexus-components'

export const ALERT_DESKTOP_WIDTH = 480

export type AlertProps = {
  avatarSrc?: string
  backgroundColor?: string
  children?: React.ReactElement
  color?: string
  iconColor?: string
  IconComponent?: React.FC<SvgIconProps>
  IconProps?: SvgIconProps
  title?: string
  subtitle?: string | React.ReactElement
  endContent?: React.ReactNode
  subcontent?: React.ReactNode
  subtitleColor?: string
}

export const Alert: React.FC<AlertProps> = ({
  avatarSrc,
  backgroundColor,
  children,
  color,
  iconColor,
  IconComponent,
  IconProps = {},
  title,
  subtitle,
  endContent,
  subcontent,
  subtitleColor,
}) => {
  const theme = useTheme()

  const bgColor = backgroundColor ?? theme.palette.ColorBackgroundBase
  const textColor = color ?? theme.palette.ColorTextBase

  return (
    <SizeMe monitorHeight>
      {({ size }): React.ReactElement => {
        const mobile =
          (size?.width ?? DEFAULT_COMPONENT_WIDTH) < ALERT_DESKTOP_WIDTH

        return (
          <Card style={{ color: textColor, backgroundColor: bgColor }}>
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Box
                  display={mobile ? 'block' : 'flex'}
                  flexDirection="row"
                  justifyItems="center"
                  p={1}
                >
                  {children || (
                    <Box alignItems="center" display="flex" flexDirection="row">
                      {avatarSrc && (
                        <Avatar
                          src={avatarSrc}
                          style={{
                            height: 56,
                            marginRight: theme.spacing(3),
                            width: 56,
                          }}
                        />
                      )}
                      {IconComponent ? (
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          marginRight={2}
                          padding={1.5}
                          style={{
                            border: '2px solid',
                            borderRadius: '50%',
                            color: iconColor ?? 'inherit',
                            height: 56,
                            width: 56,
                          }}
                        >
                          {IconComponent && (
                            <IconComponent
                              style={{ height: 28, width: 28 }}
                              {...IconProps}
                            />
                          )}
                        </Box>
                      ) : null}
                      <Box flex={1}>
                        <NxTypography color="inherit" variant="h4">
                          {title}
                        </NxTypography>
                        {title && subtitle && <div style={{ height: 6 }} />}
                        <NxTypography
                          style={{ color: subtitleColor ?? 'inherit' }}
                          variant="body1"
                        >
                          {subtitle}
                        </NxTypography>
                      </Box>
                    </Box>
                  )}
                  {endContent && (
                    <Box alignItems="center" display="flex" ml="auto">
                      {endContent}
                    </Box>
                  )}
                </Box>
                {subcontent && <>{subcontent}</>}
              </Box>
            </CardContent>
          </Card>
        )
      }}
    </SizeMe>
  )
}
