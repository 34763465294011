import { useEffect } from 'react'
import {
  useGetAllEligibleMetaseasonsQuery,
  useGetUserAttrsQuery,
} from '@plvs/graphql'

import { createMetaseasonOptions } from '@plvs/rally/components/filter'
import { useAppDispatch, useAppState } from '../context'
import { userAttrsFetched } from '../user-attributes'
import { UserAttrsState } from '../state'

export const useUserAttrsContext = (): UserAttrsState => {
  const { userAttributes } = useAppState()

  return {
    ...userAttributes,
  }
}

export const GetUserAttrs = (): null => {
  const {
    data: metaseasonData,
    loading: metaseasonDataLoading,
  } = useGetAllEligibleMetaseasonsQuery()
  const allMetaseasons = metaseasonData?.eligibleMetaseasons ?? []
  const { defaultOption } = createMetaseasonOptions(allMetaseasons)
  const metaseasonId = defaultOption?.id || null
  const variables = { id: null, metaseasonId }

  const { data, error, loading, refetch } = useGetUserAttrsQuery({
    variables,
    skip: metaseasonDataLoading,
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data || error) {
      dispatch(
        userAttrsFetched({
          data,
          error,
          loading,
          refetch: () => refetch(variables),
        })
      )
    }
  }, [data, error, loading, refetch])

  return null
}
