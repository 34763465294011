import { makeVar, ReactiveVar } from '@apollo/client'

export enum Location {
  Rally = 'rally',
  Checkpoint = 'checkpoint',
  Stadium = 'stadium',
}

export const useAppLocationVar: ReactiveVar<Location> = makeVar<Location>(
  Location.Rally
)
