import React from 'react'
import clsx from 'clsx'
import dayjs from '@plvs/rally/init/dayjs'
import tz from 'dayjs/plugin/timezone'
import { GetAwardMatchesQuery } from '@plvs/graphql/generated'
import { Grid, makeStyles } from '@material-ui/core'
import { NxTypography, NxTooltip, NxAvatar } from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Box } from '@plvs/respawn/features/layout'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { SHORT_DOW_DATE_FORMAT_AND_HOUR_TZ_FORMAT } from '@plvs/const'
import { formatInTz } from '@plvs/utils'

import { truncate } from '../explore/LeagueMatches/leagueMatchesHelpers'
import { MatchResults } from '../explore/LeagueMatches/MatchResults'

dayjs.extend(tz)

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    width: '100%',
    padding: theme.spacing(3, 2),
    textAlign: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  timeSpacing: {
    marginBottom: theme.spacing(1),
  },
  teamSpacing: {
    marginBottom: theme.spacing(2),
  },
  team1: {
    textAlign: 'right',
  },
  team2: {
    textAlign: 'left',
  },
  playerAvatar: {
    marginLeft: -theme.spacing(1),
  },
  opaqueTeam: {
    opacity: '30%',
  },
}))

const TEAM_NAME_MAX_CHAR = 14

type MatchType = NonNullable<
  NonNullable<NonNullable<GetAwardMatchesQuery>['getMatches']>['matches']
>[0]

interface Props {
  className?: string
  match: MatchType
}

export const AwardMatchCard: React.FC<Props> = ({ className, match }) => {
  const classes = useStyles()

  const matchResults = match?.matchResults ?? []

  const side1 = matchResults?.[0]
  const side2 = matchResults?.[1]

  const hasValidResults = matchResults.length >= 2

  const team1Lost = hasValidResults && side1?.placing === 2
  const team2Lost = hasValidResults && side2?.placing === 2

  const { NexusIcon } = useGeneralEsportAdapter(match?.esport?.slug)

  const myTeam = (match?.matchResults ?? []).find(
    (result) => result?.team?.isMyTeam
  )

  const starters = myTeam?.team?.roster?.formats?.[0]?.starters ?? []

  const matchRoster = starters?.map((s) => s?.player?.user)

  return (
    <div className={clsx(classes.root, className)}>
      <NxTypography
        className={classes.timeSpacing}
        colorToken="ColorTextAlt2"
        variant="overline"
      >
        {formatInTz({
          at: match?.scheduledStartsAt,
          format: SHORT_DOW_DATE_FORMAT_AND_HOUR_TZ_FORMAT,
          timezone: dayjs.tz.guess(),
        })}
      </NxTypography>
      <Box alignItems="center" display="flex" justifyContent="center" mb={2}>
        {NexusIcon && <NexusIcon className={classes.icon} />}
        <NxTypography colorToken="ColorTextAlt" variant="body2">
          {match?.esport?.name ?? ''}
        </NxTypography>
      </Box>
      <Grid className={classes.teamSpacing} container>
        <Grid item xs={5}>
          <NxTypography className={classes.team1} variant="body2">
            {truncate(side1?.team?.name ?? 'N/A', TEAM_NAME_MAX_CHAR)}
          </NxTypography>
        </Grid>
        <Grid item xs={2}>
          <NxTypography variant="body3">vs</NxTypography>
        </Grid>
        <Grid item xs={5}>
          <NxTypography className={classes.team2} variant="body2">
            {truncate(side2?.team?.name ?? 'N/A', TEAM_NAME_MAX_CHAR)}
          </NxTypography>
        </Grid>
      </Grid>
      <Box alignItems="center" display="flex" justifyContent="center" mb={2}>
        <NxUserAvatar
          avatarUrl={side1?.team?.school?.logoUrl}
          className={clsx(team1Lost && classes.opaqueTeam)}
          hashId={side1?.team?.id ?? ''}
          size={NxAvatarSize.SM}
        />
        <Box alignItems="center" display="flex" justifyContent="center" m={1}>
          <MatchResults matchResults={match?.matchResults ?? []} />
        </Box>
        <NxUserAvatar
          avatarUrl={side2?.team?.school?.logoUrl}
          className={clsx(team2Lost && classes.opaqueTeam)}
          hashId={side2?.team?.id ?? ''}
          size={NxAvatarSize.SM}
        />
      </Box>
      <Box alignItems="center" display="flex" justifyContent="center" pl={1}>
        {matchRoster.map((player) => (
          <NxTooltip
            key={`${match.id}-${player?.id}`}
            arrow
            placement="right"
            title={player?.name ?? ''}
          >
            <div>
              <NxAvatar
                avatarHashId={player?.id ?? ''}
                avatarUrl={player?.avatarUrl ?? ''}
                className={classes.playerAvatar}
                height={32}
                width={32}
              />
            </div>
          </NxTooltip>
        ))}
      </Box>
    </div>
  )
}
