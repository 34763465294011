import React, { Suspense } from 'react'
import { ResourceType, useGetPublicSchoolQuery } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { Grid } from '@material-ui/core'
import {
  NxPlayVsBanner,
  NxSkeletonLoader,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Messages } from './Messages'
import { SchoolRoster } from './SchoolRoster'
import { CoachResources } from '../explore/CoachResources/CoachResources'
import { SchoolProviderAccount } from './SchoolProviderAccount'
import { TeamsList } from './TeamsList'
import { useResourceImageProvider } from '../../../../respawn/src/features/resources/ResourceImageProvider'

const HeroGutter = React.lazy(() =>
  import('@plvs/rally/components/hero').then((m) => ({ default: m.HeroGutter }))
)

export const SchoolOverview: React.FC<{
  schoolId: string
  isPublicPage: boolean
  hideHero?: boolean
}> = ({ schoolId, isPublicPage = true, hideHero = false }) => {
  const { data, error } = useGetPublicSchoolQuery({
    variables: {
      id: schoolId,
    },
    skip: !schoolId,
  })
  const {
    getResourceImageOfSize,
    updateResources,
    loading: imageLoading,
  } = useResourceImageProvider()
  updateResources({
    resources: [{ id: schoolId, type: ResourceType.Organization }],
  })
  const schoolLogoUrl = getResourceImageOfSize({
    resource: { id: schoolId, type: ResourceType.Organization },
    size: NxAvatarSize.LG,
  })?.url

  const school = data?.school
  const metaseasonId = data?.school?.getLatestEnrolledMetaseason?.id

  const location = school?.city
    ? `${school?.city}, ${school?.state}`
    : school?.state ?? ''

  const schoolProviderAccounts = (school?.schoolProviderAccounts ?? []).slice()
  const sortedSchoolProviderAccounts = schoolProviderAccounts.sort((a, b) => {
    return (b?.providerName ?? '').localeCompare(a?.providerName ?? '')
  })

  if (error) {
    return <></>
  }

  return school ? (
    <>
      {!hideHero && !imageLoading && (
        <Suspense fallback={<NxSkeletonLoader />}>
          <HeroGutter>
            <NxPlayVsBanner
              additionalContent={
                schoolProviderAccounts.length > 0 ? (
                  <Box alignItems="center" display="flex">
                    {sortedSchoolProviderAccounts.map((pa) => (
                      <SchoolProviderAccount key={pa.id} providerAccount={pa} />
                    ))}
                  </Box>
                ) : undefined
              }
              avatar={
                <NxUserAvatar
                  avatarUrl={schoolLogoUrl}
                  hashId={school?.id}
                  size={NxAvatarSize.LG}
                />
              }
              shouldUseDefaultBanner
              size="small"
              subtitle={location}
              title={school?.name ?? ''}
              variant={PersonaImageVariant.Org}
            />
          </HeroGutter>
        </Suspense>
      )}
      <Grid container spacing={3}>
        <Grid item sm={8} xs={12}>
          <TeamsList
            isPublicPage={isPublicPage}
            metaseasonId={metaseasonId}
            schoolId={schoolId}
          />
          <Messages school={school} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box mb={3}>
            <SchoolRoster isPublicPage={isPublicPage} schoolId={school?.id} />
          </Box>
          <Box mb={3}>
            <CoachResources />
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  )
}
