import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box, makeStyles } from '@material-ui/core'
import ScribbleLeft from '../../assets/shepherd/Scribble_LeftCurvedArrow.svg'
import ScribbleRight from '../../assets/shepherd/Scribble_RightCurvedArrow.svg'
import ScribbleRightHighlight from '../../assets/shepherd/Scribble_RightHighlight.svg'
import ScribbleLeftHighlight from '../../assets/shepherd/Scribble_LeftHighlight.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(1),
    padding: 0,
    textAlign: 'center',
    fontFamily: 'TT Commons',
  },
  subtitle: {
    marginBottom: `${theme.spacing(3)}px !important`,
    padding: 0,
    fontFamily: 'TT Commons',
  },
  steps: {
    marginBottom: `${theme.spacing(0.5)}px !important`,
    padding: 0,
  },
  arrow: {
    position: 'absolute',
  },
  topRightHighlight: {
    position: 'absolute',
    top: -62,
    right: -62,
  },
  topLeftHighlight: {
    position: 'absolute',
    top: -62,
    left: -62,
  },
  bottomLeftHighlight: {
    position: 'absolute',
    bottom: -138,
    left: -62,
    transform: 'rotate(-90deg)',
  },
  bottomRightHighlight: {
    position: 'absolute',
    bottom: -138,
    right: -62,
    transform: 'rotate(-270deg)',
  },
}))

interface Props {
  title?: string
  subtitle?: string
  step?: number
  totalSteps?: number
  leftArrowStyles?: React.CSSProperties
  rightArrowStyles?: React.CSSProperties
  showTopLeftHighlight?: boolean
  showTopRightHighlight?: boolean
  showBottomLeftHighlight?: boolean
  showBottomRightHighlight?: boolean
  spot?: React.ReactElement
}

export const ShepherdSlide: React.FC<Props> = ({
  title,
  subtitle,
  step,
  totalSteps,
  leftArrowStyles,
  rightArrowStyles,
  showTopLeftHighlight = false,
  showTopRightHighlight = false,
  showBottomLeftHighlight = false,
  showBottomRightHighlight = false,
  spot,
}) => {
  const classes = useStyles()

  const showStepProgress = Boolean(!!step && !!totalSteps)

  return (
    <Box className={classes.root}>
      {leftArrowStyles && (
        <img
          alt="left-arrow"
          className={classes.arrow}
          src={ScribbleLeft}
          style={leftArrowStyles}
        />
      )}
      {rightArrowStyles && (
        <img
          alt="right-arrow"
          className={classes.arrow}
          src={ScribbleRight}
          style={rightArrowStyles}
        />
      )}
      {showTopLeftHighlight && (
        <img
          alt="left-highlight"
          className={classes.topLeftHighlight}
          src={ScribbleLeftHighlight}
        />
      )}
      {showTopRightHighlight && (
        <img
          alt="right-highlight"
          className={classes.topRightHighlight}
          src={ScribbleRightHighlight}
        />
      )}
      {showBottomLeftHighlight && (
        <img
          alt="bottom-left-highlight"
          className={classes.bottomLeftHighlight}
          src={ScribbleLeftHighlight}
        />
      )}
      {showBottomRightHighlight && (
        <img
          alt="bottom-right-highlight"
          className={classes.bottomRightHighlight}
          src={ScribbleRightHighlight}
        />
      )}
      {showStepProgress && (
        <NxTypography className={classes.steps} variant="overline">
          {step}/{totalSteps}
        </NxTypography>
      )}
      {spot && (
        <Box display="flex" justifyContent="center" mb={2}>
          {spot}
        </Box>
      )}
      {title && (
        <NxTypography className={classes.title} variant="h2">
          {title}
        </NxTypography>
      )}
      {subtitle && (
        <NxTypography className={classes.subtitle} colorToken="ColorTextAlt2">
          {subtitle}
        </NxTypography>
      )}
    </Box>
  )
}
