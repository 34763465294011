import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'

interface SettleMatchButtonProps {
  onClickSettleMatch(): void
}

export const SettleMatchButton: React.FC<SettleMatchButtonProps> = ({
  onClickSettleMatch,
}) => {
  return (
    <>
      <NxButton
        data-cy="settleMatchButton"
        label="Settle Match"
        onClick={onClickSettleMatch}
        variant="primary"
      />
    </>
  )
}
