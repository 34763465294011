import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { CompetitionGroup } from '@plvs/graphql'
import { NxCheckbox, NxTypography } from '@playvs-inc/nexus-components'

interface SchoolPermissionsProps {
  allowPlayerLedTeams: boolean
  setAllowPlayerLedTeams(allowPlayerLedTeams: boolean): void
  allowCaptainEnrollment: boolean
  setAllowCaptainEnrollment(allowCaptainEnrollment: boolean): void
  showCoachEmails: boolean
  setShowCoachEmails(showCoachEmails: boolean): void
  competitionGroup?: string
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginLeft: theme.spacing(-1.25),
  },
  checkboxLabel: {
    cursor: 'pointer',
  },
}))

/**
 * Displays toggleable school permissions.  This component is used by both Rally
 * and Checkpoint.
 *
 * Note: This component purposely kept separate from 'SchoolPermissionsFormPanel'
 * since it is currently reused by Checkpoint.
 */
export const SchoolPermissions: React.FC<SchoolPermissionsProps> = ({
  allowPlayerLedTeams,
  setAllowPlayerLedTeams,
  allowCaptainEnrollment,
  setAllowCaptainEnrollment,
  showCoachEmails,
  setShowCoachEmails,
  competitionGroup,
}) => {
  const classes = useStyles()
  const isPlayerLedOptions = competitionGroup !== CompetitionGroup.HighSchool
  return (
    <>
      <Box display="flex" flexDirection="column">
        {isPlayerLedOptions && (
          <Box alignItems="center" display="flex">
            <NxCheckbox
              checked={allowPlayerLedTeams}
              className={classes.checkbox}
              data-cy="player-teams-checkbox"
              id="player-teams-checkbox"
              onChange={(): void =>
                setAllowPlayerLedTeams(!allowPlayerLedTeams)
              }
            />
            <NxTypography
              className={classes.checkboxLabel}
              onClick={(): void => setAllowPlayerLedTeams(!allowPlayerLedTeams)}
              variant="body1"
            >
              Allow player teams
            </NxTypography>
          </Box>
        )}
        {isPlayerLedOptions && (
          <Box alignItems="center" display="flex">
            <NxCheckbox
              checked={allowCaptainEnrollment}
              className={classes.checkbox}
              data-cy="captain-enrollment-checkbox"
              onChange={(): void =>
                setAllowCaptainEnrollment(!allowCaptainEnrollment)
              }
            />
            <NxTypography
              className={classes.checkboxLabel}
              onClick={(): void =>
                setAllowCaptainEnrollment(!allowCaptainEnrollment)
              }
              variant="body1"
            >
              Allow captain enrollment
            </NxTypography>
          </Box>
        )}
        <Box alignItems="center" display="flex">
          <NxCheckbox
            checked={showCoachEmails}
            className={classes.checkbox}
            data-cy="display-email-checkbox"
            onChange={(): void => setShowCoachEmails(!showCoachEmails)}
          />
          <NxTypography
            className={classes.checkboxLabel}
            onClick={(): void => setShowCoachEmails(!showCoachEmails)}
            variant="body1"
          >
            Display email address on School Page for ALL coaches
          </NxTypography>
        </Box>
      </Box>
    </>
  )
}
