import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Path } from '@plvs/const'
import {
  formErrorToString,
  yupMatchPassword,
  passwordRequirementsValidation,
  formMultiErrorResolver,
} from '@plvs/utils'
import { useFullFillPasswordResetMutation } from '@plvs/graphql'

import {
  NxTypography,
  NxButton,
  NxTextInput,
} from '@playvs-inc/nexus-components'
import { Hide, Unhide } from '@playvs-inc/nexus-icons'
import { useNavigate } from 'react-router-dom'

const ChangePasswordFormSchema = yup.object().shape({
  password: passwordRequirementsValidation,
  repeatPassword: yupMatchPassword,
})

const useStyles = makeStyles((theme) => ({
  form: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    maxWidth: theme.spacing(48),
    textAlign: 'center',
    width: '100%',
  },
  endAdornment: {
    marginRight: theme.spacing(1.375),
  },
  submitButton: {
    height: theme.spacing(5.5),
    maxWidth: theme.spacing(48),
  },
}))

interface Props {
  token: string
}

interface PasswordResetFormProps {
  password: string
  repeatPassword: string
}

export const PasswordResetForm = ({ token }: Props): React.ReactElement => {
  const { errors, handleSubmit, register } = useForm<PasswordResetFormProps>({
    resolver: formMultiErrorResolver<PasswordResetFormProps>(
      ChangePasswordFormSchema
    ),
  })
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()
  const [showPassword, setShowPassword] = React.useState(false)
  const [mutate] = useFullFillPasswordResetMutation()
  const navigate = useNavigate()
  const classes = useStyles()

  const ShowPasswordIcon = showPassword ? Unhide : Hide

  const onSubmit = async (input: PasswordResetFormProps): Promise<void> => {
    try {
      if (
        !token ||
        !input?.password ||
        input?.password !== input?.repeatPassword
      ) {
        throw new Error('Unable to verify submission, please try again.')
      }
      await mutate({
        variables: {
          resetCode: token,
          newPassword: input.password,
        },
      })
      setIsSuccess(true)
      setError(undefined)
    } catch (e: any) {
      setIsSuccess(false)
      setError(e)
    }
  }

  return (
    <>
      {error ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <NxTypography colorToken="ColorTextError" variant="body1">
            {error.message}
          </NxTypography>
        </Box>
      ) : null}
      {isSuccess ? (
        <Box display="flex" flexDirection="column" mt={3} textAlign="center">
          <NxTypography variant="body1">
            Your password has been changed!
          </NxTypography>
          <Box mt={2}>
            <NxButton
              className={classes.submitButton}
              data-cy="back-to-login"
              fullWidth
              label="Back to Login"
              onClick={(): void => {
                navigate(Path.Login)
              }}
              variant="primary"
            />
          </Box>
        </Box>
      ) : (
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box mt={3}>
            <NxTypography variant="body1">
              Password must be between 8-64 characters long.
            </NxTypography>
          </Box>
          <Box mt={2}>
            <Grid className={classes.inputContainer} container>
              <Grid item xs={12}>
                <NxTextInput
                  ref={register}
                  endAdornment={
                    <ShowPasswordIcon
                      className={classes.endAdornment}
                      onClick={(): void => setShowPassword(!showPassword)}
                    />
                  }
                  fullWidth
                  helperText={formErrorToString(errors.password)}
                  label="Password"
                  name="password"
                  // Setting style inline because className overrides base style
                  style={{ height: '54px' }}
                  type={showPassword ? 'text' : 'password'}
                  variant={errors.password ? 'error' : 'default'}
                />
              </Grid>
              <Grid item xs={12}>
                <NxTextInput
                  ref={register}
                  endAdornment={
                    <ShowPasswordIcon
                      className={classes.endAdornment}
                      onClick={(): void => setShowPassword(!showPassword)}
                    />
                  }
                  fullWidth
                  helperText={formErrorToString(errors.repeatPassword)}
                  label="Confirm New Password"
                  name="repeatPassword"
                  // Setting style inline because className overrides base style
                  style={{ height: '54px', marginTop: '4px' }}
                  type={showPassword ? 'text' : 'password'}
                  variant={errors.repeatPassword ? 'error' : 'default'}
                />
              </Grid>
            </Grid>
            <Box mt={0.5}>
              <NxButton
                className={classes.submitButton}
                fullWidth
                label="Update Password"
                type="submit"
                variant="primary"
              />
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}
