import { SeriesFormInput } from '@plvs/checkpoint/features/match/history/view/helpers'
import React from 'react'

import { UseFormMethods } from 'react-hook-form'
import {
  DeleteMutationHook,
  UploadMutationHook,
  ScreenshotSizeLimit,
  SetAttachmentsFn,
  Attachment,
} from './attachHelpers'
import { AttachScreenshotButton } from './AttachScreenshotButton'
import { RemoveAttachedScreenshot } from './RemoveAttachedScreenshot'

export const AttachScreenshot: React.FC<
  Partial<Pick<UseFormMethods<SeriesFormInput>, 'unregister' | 'register'>> & {
    attachments?: Attachment[]
    setAttachments: SetAttachmentsFn
    deleteAssetMutation: DeleteMutationHook
    uploadAssetMutation: UploadMutationHook
    // These properties could be made optional, if this component is ever used
    //  outside of the useForm/register context.
    name?: string
    screenshotSizeLimit?: ScreenshotSizeLimit
    formErrorString?: string
  }
> = ({
  attachments,
  deleteAssetMutation,
  name,
  register,
  setAttachments,
  screenshotSizeLimit = '3MB',
  unregister,
  uploadAssetMutation,
  formErrorString,
}) => {
  React.useEffect(() => {
    if (name && register) register(name)
    return (): void => {
      if (name && unregister) unregister(name)
    }
  }, [name, register, unregister])

  return (
    <>
      <AttachScreenshotButton
        attachments={attachments}
        formErrorString={formErrorString}
        screenshotSizeLimit={screenshotSizeLimit}
        setAttachments={setAttachments}
        uploadAssetMutation={uploadAssetMutation}
      />
      {attachments?.map((attachment) => (
        <RemoveAttachedScreenshot
          key={attachment.id}
          attachment={attachment}
          attachments={attachments}
          deleteAssetMutation={deleteAssetMutation}
          setAttachments={setAttachments}
        />
      ))}
    </>
  )
}
