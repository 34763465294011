import React, { useEffect } from 'react'
import { Box, Card, CardContent, Divider, makeStyles } from '@material-ui/core'
import { includes } from 'ramda'
import {
  useGetSupportedAccountProvidersQuery,
  useGetUserProviderAccountsQuery,
  useGetUserProviderAccountsFromIdQuery,
  UserRoleName,
  useUpdateRiotAccountMutation,
  Provider,
  refetchGetUserProviderAccountsQuery,
  useUpdateSpinAccountMutation,
} from '@plvs/graphql'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  useAccountRenderControllerContext,
  useAccountRenderControllerState,
} from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import {
  gameConnectionComponentMap,
  platformConnectionComponentMap,
} from './connections'
import { getSupportedProviders, isVesl } from './utils'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3, 3, 0),
  },
  divider: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    boxShadow: theme.mixins.divider.bottom,
    backgroundColor: 'transparent',
  },
}))

type AccountProviders = {
  userId?: string
  checkpoint?: boolean
}

type Connection = {
  name: string
  Component: typeof React.Component
}

export const AccountProviders: React.FC<AccountProviders> = ({
  userId,
  checkpoint = false,
}) => {
  const flags = useFlags()
  const classes = useStyles()

  const { data } = useGetSupportedAccountProvidersQuery()
  const [updateRiotAccount] = useUpdateRiotAccountMutation()
  const [updateSpinAccount] = useUpdateSpinAccountMutation()
  const product = useProductTypeFn()
  const {
    data: myProviderAccountsData,
    error: myProvidersApolloError,
    refetch: refetchUserProviderAccounts,
    loading,
  } = useGetUserProviderAccountsQuery({
    skip: !!userId,
    onCompleted: async (userPAData) => {
      const spinAccount = userPAData.me?.userProviderAccounts?.find(
        (account) => account.providerName === Provider.Spin
      )
      const shouldUpdateSpin = spinAccount && !spinAccount?.providerDisplayName

      if (shouldUpdateSpin) {
        await updateSpinAccount({
          awaitRefetchQueries: true,
          refetchQueries: [refetchGetUserProviderAccountsQuery()],
        })
      }
    },
  })

  const {
    data: userProviderAccountsData,
    error: userProvidersApolloError,
  } = useGetUserProviderAccountsFromIdQuery({
    variables: {
      userId: userId ?? '',
    },
    skip: !userId,
  })

  const queryUserId = userId ?? myProviderAccountsData?.me?.id ?? ''
  const providerAccounts =
    myProviderAccountsData?.me?.userProviderAccounts ??
    userProviderAccountsData?.user?.userProviderAccounts ??
    []
  const userRoles =
    myProviderAccountsData?.me?.roles ??
    userProviderAccountsData?.user?.roles ??
    []

  const apolloError = myProvidersApolloError || userProvidersApolloError
  const userRoleNames: UserRoleName[] = userRoles.map((role) => role.roleName)
  const isPlayerOrStudent: boolean =
    userRoleNames.includes(UserRoleName.Player) ||
    userRoleNames.includes(UserRoleName.Student)

  const isVeslVarsityAssociation =
    (myProviderAccountsData?.me?.school?.varsityAssociations ?? []).some(
      isVesl
    ) ||
    (userProviderAccountsData?.user?.school?.varsityAssociations ?? []).some(
      isVesl
    )
  const {
    providers: { getProvidersComponentsToRender },
  } = useAccountRenderControllerContext()
  const providersRenderController = getProvidersComponentsToRender({
    productType: product,
  })
  const {
    getAccountRenderControllerState,
    setAccountRenderControllerStateFn,
  } = useAccountRenderControllerState()

  useEffect(
    function updateRenderController() {
      const currentState = getAccountRenderControllerState()
      setAccountRenderControllerStateFn({
        providers: {
          ...currentState.providers,
          ...providersRenderController,
        },
      })
    },
    [
      ...Object.values(providersRenderController.shouldRender),
      ...Object.values(providersRenderController.isEnabled),
    ]
  )

  const {
    providers: { shouldRender },
  } = getAccountRenderControllerState()

  const actProvNames = getSupportedProviders({
    isPlayerOrStudent,
    flags,
    supportedProviders: data?.supportedProviders ?? [],
    isVeslVarsityAssociation,
  })

  const gameComponentMap = gameConnectionComponentMap.filter(
    (gameConnection): boolean => includes(gameConnection.name, actProvNames)
  )
  const platformComponentMap = platformConnectionComponentMap.filter(
    (platformConnection): boolean =>
      includes(platformConnection.name, actProvNames)
  )
  const mapConnectionToComponent = (
    { name, Component }: Connection,
    index: number,
    arr: Connection[]
  ): React.ReactElement => {
    if (!shouldRender[name]) {
      return <></>
    }

    return (
      <div key={name} data-cy={`account-connection-name-${name.toLowerCase()}`}>
        <Component
          key={name}
          apolloError={apolloError}
          checkpoint={checkpoint}
          disconnectProfile={(): void => {}}
          name={name}
          providerDetails={providerAccounts.find(
            (account) => account.providerName === name
          )}
          refetchUserProviderAccounts={refetchUserProviderAccounts}
          userId={queryUserId}
        />
        {index < arr.length - 1 && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
      </div>
    )
  }

  useEffect(() => {
    if (!loading) {
      const hasRiotAccount = myProviderAccountsData?.me?.userProviderAccounts?.some(
        (provider) => provider.providerName === Provider.Riot
      )

      if (hasRiotAccount && !checkpoint) {
        updateRiotAccount({
          awaitRefetchQueries: true,
          refetchQueries: [refetchGetUserProviderAccountsQuery()],
        })
      }
    }
  }, [loading])

  return (
    <>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box>
            <NxTypography variant="h1">Platform Connections</NxTypography>
          </Box>
          {gameComponentMap.map(mapConnectionToComponent)}
          <Divider className={classes.divider} variant="fullWidth" />

          {platformComponentMap.map(mapConnectionToComponent)}
        </CardContent>
      </Card>
    </>
  )
}
