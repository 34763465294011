export type HeroAnnouncement = {
  heroTitle: string
  img: string | undefined
  heroSubtitle?: string
  heroOverlineTitle: string
}

export const emptyAnnouncement: HeroAnnouncement = {
  heroTitle: 'Competitions',
  img: undefined,
  heroSubtitle:
    'Play the most popular titles and be crowned the next state or regional champion',
  heroOverlineTitle: '',
}
