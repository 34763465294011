import { LinearProgress, makeStyles } from '@material-ui/core'
import React from 'react'
import { identity } from 'ramda'
import { isNaN } from 'ramda-adjunct'

import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { StatRow } from './StatRow'
import { StatRowHeading } from './StatRowHeading'
import { Colors } from '../../../themes/colors'

export type Formatter = (stat: number) => string | number

interface VersusBarProps {
  teamAValue: number
  teamBValue: number
  label: string
  format?: (value: number) => string | number
  teamAColor?: string
  teamBColor?: string
}

const useStyles = makeStyles({
  barColorPrimary: {
    backgroundColor: ({
      teamAColor,
    }: {
      teamAColor?: string
      teamBColor?: string
    }): string => teamAColor || Colors.Grey1,
  },
  colorPrimary: {
    backgroundColor: ({
      teamBColor,
    }: {
      teamAColor?: string
      teamBColor?: string
    }): string => teamBColor || Colors.Grey6,
  },
  root: {
    height: 8,
    borderRadius: 4,
  },
  bar: {
    borderRadius: 4,
  },
})

const percent = (x: number, y: number): number => {
  const decimal = x / (x + y)
  return (isNaN(decimal) ? 0.5 : decimal) * 100
}

export const VersusBar: React.FC<VersusBarProps> = (props) => {
  const {
    teamAValue,
    teamBValue,
    format,
    label,
    teamAColor,
    teamBColor,
  } = props
  const myAmount = teamAValue || 0
  const opposingAmount = teamBValue || 0
  const classes = useStyles({ teamAColor, teamBColor })
  const formatFn = format || identity

  return (
    <StatRow flexDirection="column" marginTop={24}>
      <div style={{ paddingBottom: 8 }}>
        <StatRowHeading>{label}</StatRowHeading>
      </div>
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="center"
        width="100%"
      >
        <Box flex={0} minWidth={48}>
          <NxTypography variant="body1">{formatFn(teamAValue)}</NxTypography>
        </Box>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="center"
          padding="0 12px"
        >
          <LinearProgress
            classes={classes}
            value={percent(myAmount, opposingAmount)}
            variant="determinate"
          />
        </Box>
        <Box flex={0} minWidth={48} textAlign="right">
          <NxTypography variant="body1">{formatFn(teamBValue)}</NxTypography>
        </Box>
      </Box>
    </StatRow>
  )
}
