import React, { useEffect, useState } from 'react'
import { PlasmicComponent } from '@plasmicapp/loader-react'
import { Box, makeStyles } from '@material-ui/core'
import {
  ResourceType,
  School,
  useGenerateReferralLinkMutation,
  useGetMySchoolNameQuery,
  UserRoleName,
} from '@plvs/graphql'

import copy from 'clipboard-copy'
import { useSnackbar } from 'notistack'
import { useBreakpointSm, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { EmailReferralModal } from '@plvs/rally/containers/referral/EmailReferralModal'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '@plvs/rally/features/coach/coachLeadGen/CoachLeadComponent'
import { NxTypography } from '@playvs-inc/nexus-components'
import { GlobalReferralCoachLeadIncentives } from '@plvs/rally/features/coach/coachLeadGen/GlobalReferralCoachLeadIncentives'
import { getCountryFromState } from '@plvs/rally/components/onboard/helpers'
import { PlayerInviteComponent } from '@plvs/rally/features/player/playerInvite/PlayerInviteComponent'
import { GlobalReferralStudentCoachLeadIncentives } from '@plvs/rally/features/coach/coachLeadGen/GlobalReferralStudentCoachLeadIncentives'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { CountryCode } from '@plvs/utils'
import { GlobalSchoolLeadComponent } from '@plvs/rally/features/coach/coachLeadGen/GlobalSchoolLeadComponent'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles((theme) => ({
  coachLeadContainer: {
    color: theme.palette.ColorTextBase,
  },
  globalInviteContainer: {
    width: '100%',
    overflow: 'auto',
  },
  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  coachLeadTitle: {
    fontSize: '2rem',
  },
  playerInviteContainer: {
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius,
  },
}))

export const GlobalReferPage: React.FC = () => {
  const flags = useFlags()
  const { data: schoolData } = useGetMySchoolNameQuery()
  const { isFacultyAtOrg } = useUserIdentityFn()

  const [
    generateReferralLink,
    { data, loading },
  ] = useGenerateReferralLinkMutation()

  const [isReferEmailDialogOpen, setIsReferEmailDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const isMobile = useBreakpointSm()

  const link = data?.generateReferralLink ?? ''

  const school = schoolData?.me?.school

  const isUsersSchoolInUnitedStates =
    school &&
    getCountryFromState(school?.state ?? '') === CountryCode.UnitedStates

  useEffect(() => {
    generateReferralLink({
      variables: {
        resourceType: ResourceType.System,
        roleName: UserRoleName.User,
        source: 'global_referral',
        medium: 'share_link',
      },
    })
  }, [])

  const copyWithConfirmation = (): void => {
    copy(link)
      .then((): void => {
        enqueueSnackbar('Copied to clipboard', {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
      })
      .catch((): void => {
        enqueueSnackbar('Error copying to clipboard', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          persist: true,
        })
      })
  }

  const onOpenEmailModal = (): void => {
    setIsReferEmailDialogOpen(true)
  }

  const onCloseEmailModal = (): void => {
    setIsReferEmailDialogOpen(false)
  }

  const referralTitle = isFacultyAtOrg
    ? 'Refer your district or another school'
    : 'Refer coaches and earn prizes'

  const referralCopy =
    isUsersSchoolInUnitedStates && isFacultyAtOrg ? (
      <GlobalReferralCoachLeadIncentives />
    ) : (
      <GlobalReferralStudentCoachLeadIncentives />
    )

  if (isUsersSchoolInUnitedStates) {
    return (
      <>
        {flags.schoolBounties ? (
          <Box
            display="flex"
            flexDirection="column"
            mt={isMobile ? 0 : 7.5}
            mx={3}
          >
            <GlobalSchoolLeadComponent />
            <PlayerInviteComponent link={link} school={school as School} />
          </Box>
        ) : (
          <Box
            className={classes.coachLeadContainer}
            mx={3}
            my={isMobile ? 0 : 4.5}
          >
            <NxTypography className={classes.title} variant="h1">
              Help us grow the PlayVS community
            </NxTypography>
            <Box mt={4}>
              <CoachLeadComponent
                isFaculty={isFacultyAtOrg}
                school={school as SchoolPropType}
                setAvailableSchool={!isFacultyAtOrg}
                sideView={referralCopy}
                source={
                  isFacultyAtOrg
                    ? 'faculty-global-coach-lead'
                    : 'student-global-coach-lead'
                }
                title={
                  <Box>
                    <NxTypography
                      className={classes.coachLeadTitle}
                      variant="h1"
                    >
                      {referralTitle}
                    </NxTypography>
                  </Box>
                }
              />

              <PlayerInviteComponent link={link} school={school as School} />
            </Box>
          </Box>
        )}
      </>
    )
  }

  return (
    <WaitTillLoaded loading={loading || !link} showSpinnerWhileLoading>
      <Box
        className={classes.globalInviteContainer}
        pl={isMobile ? 0 : 5}
        pt={isMobile ? 0 : 5}
      >
        <PlasmicComponent
          component="ReferralPage"
          // forceOriginal here means we want the originally generated Plasmic
          // component, and not the substituted one (which would be this very component,
          // resulting in an infinite loop).
          componentProps={{
            // Pass in the referral link as a prop to the desktop and mobile
            // ReferralLinkBox components
            desktopReferral: {
              link,
              copyButton: {
                onClick: copyWithConfirmation,
              },
              smallCopyButton: { onClick: copyWithConfirmation },
              emailButton: {
                onClick: onOpenEmailModal,
              },
            },
            mobileReferral: {
              link,
              copyButton: {
                onClick: copyWithConfirmation,
              },
              smallCopyButton: { onClick: copyWithConfirmation },
            },
          }}
          forceOriginal
        />
        <EmailReferralModal
          invitedRole={UserRoleName.User}
          isOpen={isReferEmailDialogOpen}
          onCloseEmailModal={onCloseEmailModal}
          source="global_referral"
        />
      </Box>
    </WaitTillLoaded>
  )
}
