import React from 'react'

import { NxIconButton, NxTooltip } from '@playvs-inc/nexus-components'
import { Duplicate } from '@playvs-inc/nexus-icons'
import { Box, makeStyles } from '@material-ui/core'
import { useClipboard } from './useClipboard'

const DELAY_BEFORE_COPYING = 0
const DELAY_AFTER_COPYING = 1000

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 5,
  },
}))

export const CopyIconButton: React.FC<{
  className?: string
  textToCopy: string
  tooltipTextBeforeCopying?: string
  tooltipTextAfterCopying?: string
  onClickCopyAdditionalAction?: (value: string) => void
  variant?: string
  icon?: React.ReactElement
  label?: string
}> = ({
  className,
  textToCopy,
  tooltipTextBeforeCopying = 'Copy',
  tooltipTextAfterCopying = 'Copied!',
  onClickCopyAdditionalAction,
  variant = 'text',
  icon = <Duplicate />,
  label = '',
}) => {
  const classes = useStyles()
  const { copy, wasRecentlyCopied } = useClipboard(textToCopy)
  const text = wasRecentlyCopied
    ? tooltipTextAfterCopying || undefined
    : tooltipTextBeforeCopying

  const onClickCopy = (): void => {
    if (onClickCopyAdditionalAction) {
      onClickCopyAdditionalAction(textToCopy)
    }
    copy()
  }

  return (
    <NxTooltip
      arrow
      leaveDelay={
        wasRecentlyCopied ? DELAY_AFTER_COPYING : DELAY_BEFORE_COPYING
      }
      title={text ?? ''}
    >
      <Box>
        <NxIconButton
          aria-label="copy"
          className={className || classes.iconContainer}
          icon={icon}
          label={label}
          onClick={onClickCopy}
          // @ts-ignore
          variant={variant}
        />
      </Box>
    </NxTooltip>
  )
}
