import {
  InputLabel,
  makeStyles,
  FormHelperText,
  Select,
  SelectProps,
} from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { Box } from '@plvs/respawn/features/layout'
import React, { useRef, useState } from 'react'

export interface StyleProps {
  dirty?: boolean
}

const useStyles = makeStyles((theme) => ({
  onboardingSelectWrapper: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  onboardingSelectBackground: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  onboardingSelectLabel: ({ dirty }: StyleProps): CreateCSSProperties => ({
    paddingTop: dirty ? theme.spacing(1) : 0,
    paddingLeft: theme.spacing(2),
  }),
  onboardingSelect: ({ dirty }: StyleProps): CreateCSSProperties => ({
    '& select': {
      paddingTop: dirty ? theme.spacing(1) : theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: theme.palette.text.primary,
      height: '1.5em',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  }),
}))

type OnboardSelectFieldProps = Omit<
  SelectProps,
  'native' | 'fullWidth' | 'disableUnderline' | 'label' | 'name'
> & {
  error?: boolean
  helperText?: string
  name: string
  label?: string
  prompt: string
}

export const OnboardSelectField: React.FC<OnboardSelectFieldProps> = React.forwardRef(
  (props, forwardRef) => {
    const selectForwardRef = useRef<HTMLSelectElement>()

    const {
      onChange: changeHandler,
      error,
      name,
      label,
      className,
      children,
      value,
      prompt,
      defaultValue,
      helperText,
      ...remainingProps
    } = props

    const [dirty, setDirty] = useState(!!value)
    const classes = useStyles({ dirty })

    const labelId = `${name}-select-labelId`

    return (
      <Box className={classes.onboardingSelectWrapper}>
        <Box
          className={classes.onboardingSelectBackground}
          onFocus={(): void => selectForwardRef.current?.focus()}
        >
          {!!label && (
            <InputLabel
              className={classes.onboardingSelectLabel}
              htmlFor={labelId}
            >
              {dirty && label}
            </InputLabel>
          )}
          <Select
            ref={forwardRef}
            className={`
              ${classes.onboardingSelect} 
              ${className ?? ''}          
            `}
            data-testid="onboard-select-state"
            defaultValue={defaultValue}
            disableUnderline
            fullWidth
            inputProps={{
              id: labelId,
              placeholder: label,
              name,
            }}
            inputRef={selectForwardRef}
            labelId={labelId}
            native
            value={value}
            {...remainingProps}
            onChange={(e, child): void => {
              setDirty(true)
              if (changeHandler) {
                changeHandler(e, child)
              }
            }}
          >
            {/** Note: this will raise a console.error in dev.  This is intentional
             * to create a placeholder option that holds the prompt text.
             */}
            <option disabled value="">
              {prompt}
            </option>
            {children}
          </Select>
        </Box>
        {!!helperText && (
          <FormHelperText data-testid="helper-text" error={error}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    )
  }
)
