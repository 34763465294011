import { makeStyles, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'

export const useStyles = makeStyles((theme) => ({
  spot: {
    width: 200,
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(1),
    color: theme.palette.ColorTextAlt2,
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
    marginBottom: theme.spacing(7),
  },
}))

type Props = {
  spot: string
  spotCaption: string
  title: string
  subtitle: string
  spotDark?: string
}

export const EmptyPageWithSpot: React.FC<Props> = ({
  spot,
  spotDark,
  spotCaption,
  title,
  subtitle,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {/* TODO CHK-1192 Replace this with Nexus SVG icon when spot illustration is done */}
      <img
        alt={spotCaption}
        className={classes.spot}
        src={theme.palette.type === 'dark' ? spotDark : spot}
      />
      <NxTypography className={classes.header} variant="h2">
        {title}
      </NxTypography>
      <NxTypography className={classes.subtitle} variant="body1">
        {subtitle}
      </NxTypography>
    </Box>
  )
}
