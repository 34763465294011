let fbKey: string

// because updating user properties requires the pixel id
export const getKey = (): string => fbKey

export default (key: string): void => {
  /* eslint-disable */
  // @ts-ignore
  !(function (f, b, e, v, n, t, s) {
    // @ts-ignore
    if (f?.fbq) return
    // @ts-ignore
    n = f.fbq = function () {
      if (n) {
        // @ts-ignore
        n.callMethod
          ? // @ts-ignore
            n.callMethod.apply(n, arguments)
          : // @ts-ignore
            n.queue.push(arguments)
      }
    }

    if (!n) return
    // @ts-ignore
    if (!f?._fbq) f._fbq = n
    // @ts-ignore
    n.push = n
    // @ts-ignore
    n.loaded = !0
    // @ts-ignore
    n.version = '2.0'
    // @ts-ignore
    n.queue = []
    // @ts-ignore
    t = b.createElement(e)
    // @ts-ignore
    t.async = !0
    // @ts-ignore
    t.src = v
    // @ts-ignore
    s = b.getElementsByTagName(e)[0]
    // @ts-ignore
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  /* eslint-enable */

  fbKey = key
}
