import React, { useState } from 'react'
import { NotificationOverlay as NotificationOverlayPresentation } from '@plvs/rally/components/overlay/NotificationOverlay'
import * as analytics from '@plvs/respawn/features/analytics'
import { USER_NOTIFICATIONS } from '@plvs/const'
import { PostMatchSurveySuccessStep } from '@plvs/rally/components/overlay/PostMatchSurveyOverlay/PostMatchSurveySucessStep'
import { PostMatchSurveyOverlay } from './PostMatchSurveyOverlay'

import { useNotifications } from '../app'

export enum NotificationAction {
  Cancel = 'cancel',
  Primary = 'primary',
}

export const NotificationOverlay: React.FC<Record<
  string,
  unknown
>> = React.memo(() => {
  const { notifications, acknowledge } = useNotifications()
  const [
    showPostMatchSurveySuccessStep,
    setShowPostMatchSurveySuccessStep,
  ] = useState<boolean>(false)
  const modal = notifications.find(
    (notif) => notif.notification.type === 'modal'
  )
  const postMatchSurveyModal = notifications.find(
    (notif) =>
      notif.notification.type === 'modal' && notif.surveyMatchId !== null
  )

  const isFirstMatch =
    postMatchSurveyModal?.notification?.id ===
    USER_NOTIFICATIONS.firstMatchSurveyNotificationId
  const isExternalUrl = /^https?:\/\//.test(modal?.notification.href ?? '')

  if (showPostMatchSurveySuccessStep) {
    return (
      <PostMatchSurveySuccessStep
        setShowPostMatchSurveySuccessStep={setShowPostMatchSurveySuccessStep}
      />
    )
  }
  if (postMatchSurveyModal) {
    return (
      <PostMatchSurveyOverlay
        acknowledge={acknowledge}
        isFirstMatch={isFirstMatch}
        postMatchSurveyNotification={postMatchSurveyModal}
        setShowPostMatchSurveySuccessStep={setShowPostMatchSurveySuccessStep}
      />
    )
  }

  // NOTE: key is added to allow for consecutive modals
  return modal ? (
    <NotificationOverlayPresentation
      key={modal.id}
      description={modal.notification.description ?? ''}
      imageURL={modal.notification.imageUrl || undefined}
      isExternalUrl={isExternalUrl}
      link={modal.notification.href ?? ''}
      onCancelClick={(): void => {
        analytics.notificationService({
          name: modal.notification.name,
          action: NotificationAction.Cancel,
        })
        acknowledge(modal.id)
      }}
      onPrimaryClick={async (): Promise<void> => {
        analytics.notificationService({
          name: modal.notification.name,
          action: NotificationAction.Primary,
        })
        await acknowledge(modal.id)
      }}
      primaryCTA={modal.notification.buttonText ?? ''}
      title={modal.notification.title ?? ''}
    />
  ) : null
})
