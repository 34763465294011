import React, { useState, useEffect } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import {
  NotificationCategory,
  NotificationScope,
  refetchGetUserNotificationSettingsQuery,
  useGetMyAccountDetailsQuery,
  useGetUserNotificationSettingsQuery,
  useSetUserNotificationEnabledMutation,
  useSetUserNotificationIsPersonalMutation,
  useSetUserNotificationScopeMutation,
} from '@plvs/graphql'
import { NxToggle, NxTypography } from '@playvs-inc/nexus-components'
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  makeStyles,
  Radio,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { RouterLink } from '@plvs/respawn/features/route'
import { Path } from '@plvs/const'
import { SchoolOutlined, UserOutlined } from '@playvs-inc/nexus-icons'

import { cleanGraphQLError } from '@plvs/utils'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { CreateCSSProperties } from '@material-ui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAccountRenderControllerState } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'

interface MinimalUserEmail {
  id: string
  email: string
  isVerified: boolean
  isSchoolEmail?: boolean
  updatedAt: string
  activeUpdateRequest?: {
    newEmail: string
  }
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  link: {
    display: 'inline-block',
  },
  manageEmailsCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  missingSchoolEmail: {
    color: theme.palette.ColorTextDisabled,
  },
  subTitle: {
    fontWeight: 600,
    fontSize: '.80rem',
    textTransform: 'uppercase',
    letterSpacing: '0.11rem',
    marginBottom: theme.spacing(2),
  },
  emailText: {
    wordBreak: 'break-all',
  },
  cardTitle: {
    marginBottom: theme.spacing(3),
  },
  altText: {
    color: theme.palette.ColorTextAlt,
    display: 'inline-block',
  },
  teamsNotificationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '3.375rem',
    marginTop: theme.spacing(2),
  },
  emailSpacer: {
    marginBottom: theme.spacing(2),
  },
  emailSwitch: {
    marginRight: theme.spacing(1),
  },
  disabledSelection: {
    color: theme.palette.ColorTextDisabled,
  },
  emailsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  emailIcons: ({
    hasSchoolEmail,
  }: {
    hasSchoolEmail: boolean
  }): CreateCSSProperties => ({
    marginRight: '1.5rem',
    color: hasSchoolEmail
      ? theme.palette.ColorIconBase
      : theme.palette.ColorIconDisabled,
    width: 20,
    height: 20,
  }),
  missingSchoolEmailButton: {
    color: theme.palette.ColorTextAlt,
    marginLeft: '2rem',
    marginRight: '2rem',
    textTransform: 'capitalize',
  },
  missingSchoolEmailContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    boxShadow: theme.mixins.divider.bottom,
    backgroundColor: 'transparent',
  },
}))

export const NotificationSettings: React.FC = () => {
  const flags = useFlags()

  // Queries
  const {
    data: emailData,
    loading: emailDataLoading,
  } = useGetMyAccountDetailsQuery()
  const userId = emailData?.me?.id ?? ''
  const {
    data: notificationSettingsData,
    loading: notificationSettingsDataLoading,
  } = useGetUserNotificationSettingsQuery({
    variables: { input: { userId } },
    skip: !userId,
  })
  const { isParent, isFacultyAtOrg } = useUserIdentityFn()

  // Mutations
  const [
    setUserNotificationScopeMutation,
  ] = useSetUserNotificationScopeMutation()
  const [
    setUserNotificationEnabledMutation,
  ] = useSetUserNotificationEnabledMutation()
  const [
    setUserNotificationIsPeronalMutation,
  ] = useSetUserNotificationIsPersonalMutation()

  // State hooks
  const [error, setError] = React.useState<Error>()
  const [initialSettingsSet, setInitialSettingsSet] = useState<boolean>(false)
  const [selectedEsportNotification, setSelectedEsportNotification] = useState<
    string | null
  >(NotificationScope.PersonalTeams)
  const [esportNotificationChecked, setEsportNotificationChecked] = useState<
    boolean
  >(false)
  const [selectedEsportEmail, setSelectedEsportEmail] = useState<string | null>(
    null
  )
  const [promotionalEmailsChecked, setPromotionalEmailsChecked] = useState<
    boolean
  >(false)

  const [selectedPromotionalEmail, setSelectedPromotionalEmail] = useState<
    string | null
  >(null)

  const emails: MinimalUserEmail[] =
    (emailData?.me?.emails as MinimalUserEmail[]) ?? []
  const personalEmail = emails.find((email) => email.isSchoolEmail === false)
  const schoolEmail = emails.find((email) => !!email.isSchoolEmail)
  const mainEmail = schoolEmail?.email ?? personalEmail?.email
  const hasMultipleEmails = !flags.personalEmailOptional || emails.length > 1

  const classes = useStyles({ hasSchoolEmail: !!schoolEmail?.email })

  const handleOnChangeEsportNotification = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const scope = event.target.value as NotificationScope
    setSelectedEsportNotification(scope)

    try {
      setUserNotificationScopeMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserNotificationSettingsQuery({
            input: { userId },
          }),
        ],
        variables: {
          input: {
            userId,
            category: NotificationCategory.Esport,
            scope,
          },
        },
      })
    } catch (e: any) {
      setError(e)
    }
  }

  const handleEsportCheckChange = (): void => {
    try {
      setUserNotificationEnabledMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserNotificationSettingsQuery({
            input: { userId },
          }),
        ],
        variables: {
          input: {
            userId,
            category: NotificationCategory.Esport,
            enable: !esportNotificationChecked,
          },
        },
      })
    } catch (e: any) {
      setError(e)
    }

    setEsportNotificationChecked((currentValue) => !currentValue)
  }

  const handleOnChangeEsportEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isPersonal = event.target.value === personalEmail?.email
    setSelectedEsportEmail(event.target.value)

    try {
      setUserNotificationIsPeronalMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserNotificationSettingsQuery({
            input: { userId },
          }),
        ],
        variables: {
          input: {
            userId,
            category: NotificationCategory.Esport,
            isPersonalEmail: isPersonal,
          },
        },
      })
    } catch (e: any) {
      setError(e)
    }
  }

  const handlePromotionalEmailsCheckedChange = (): void => {
    try {
      setUserNotificationEnabledMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserNotificationSettingsQuery({
            input: { userId },
          }),
        ],
        variables: {
          input: {
            userId,
            category: NotificationCategory.Promotional,
            enable: !promotionalEmailsChecked,
          },
        },
      })
    } catch (e: any) {
      setError(e)
    }

    setPromotionalEmailsChecked((currentValue) => !currentValue)
  }

  const handleOnChangePromotionalEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isPersonal = event.target.value === personalEmail?.email
    setSelectedPromotionalEmail(event.target.value)

    try {
      setUserNotificationIsPeronalMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserNotificationSettingsQuery({
            input: { userId },
          }),
        ],
        variables: {
          input: {
            userId,
            category: NotificationCategory.Promotional,
            isPersonalEmail: isPersonal,
          },
        },
      })
    } catch (e: any) {
      setError(e)
    }
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  useEffect(() => {
    if (notificationSettingsData) {
      const esportSettings = notificationSettingsData?.getUserNotificationsSettings?.userNotificationSettings?.find(
        (setting) => setting?.category === NotificationCategory.Esport
      )
      const promotionalSettings = notificationSettingsData?.getUserNotificationsSettings?.userNotificationSettings?.find(
        (setting) => setting?.category === NotificationCategory.Promotional
      )

      setSelectedEsportEmail(esportSettings?.userEmail?.email ?? null)
      setEsportNotificationChecked(esportSettings?.enabled ?? false)
      setSelectedEsportNotification(esportSettings?.scope ?? null)

      setSelectedPromotionalEmail(promotionalSettings?.userEmail?.email ?? null)
      setPromotionalEmailsChecked(promotionalSettings?.enabled ?? false)
      setInitialSettingsSet(true)
    }
  }, [emailData, notificationSettingsData])

  const { getAccountRenderControllerState } = useAccountRenderControllerState()
  const {
    settings: { shouldRenderSchool },
  } = getAccountRenderControllerState()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {errorMessage && (
          <Box pb={3}>
            <Banner
              subtitle={errorMessage}
              title="Unable to save notification settings"
              type={BannerType.Error}
            />
          </Box>
        )}
        <Box mb={3}>
          <NxTypography variant="h2">Where to Notify You</NxTypography>
        </Box>
        {emailDataLoading ? (
          <Skeleton height="5rem" variant="rect" width="100%" />
        ) : (
          <Box mb={5}>
            <Grid className={classes.emailsContainer} container>
              {hasMultipleEmails && (
                <Grid className={classes.emailSpacer} item xs={12}>
                  <NxTypography className={classes.subTitle} variant="overline">
                    Personal Email
                  </NxTypography>
                  <NxTypography
                    className={classes.emailText}
                    data-testid="NotificationSettings_PersonalEmailTextField"
                  >
                    {personalEmail?.email}
                  </NxTypography>
                </Grid>
              )}
              <Grid className={classes.emailSpacer} item xs={12}>
                <NxTypography className={classes.subTitle} variant="overline">
                  {isParent || !shouldRenderSchool
                    ? 'Personal Email'
                    : 'School Email'}
                </NxTypography>
                <NxTypography
                  className={classes.emailText}
                  data-testid="NotificationSettings_SchoolEmailTextField"
                >
                  {mainEmail}
                </NxTypography>
              </Grid>
            </Grid>
            <Box className={classes.manageEmailsCopy} mb={3}>
              <NxTypography className={classes.altText}>
                To manage your emails, visit{' '}
                <Link
                  className={classes.link}
                  component={RouterLink}
                  to={Path.Settings}
                  underline="none"
                  variant="body1"
                >
                  Security &amp; Privacy
                </Link>
                .
              </NxTypography>
            </Box>
            <Divider className={classes.divider} light variant="fullWidth" />
          </Box>
        )}

        {/* Esport Notifications */}

        <Box display="flex" flexDirection="column" mb={3}>
          <NxTypography className={classes.cardTitle} variant="h2">
            Esport Notifications
          </NxTypography>
          <NxTypography className={classes.altText}>
            Includes reschedule requests, match status updates, verification
            updates, and more.
          </NxTypography>
        </Box>
        {notificationSettingsDataLoading || emailDataLoading ? (
          <Skeleton
            data-testid="notification-skeleton"
            height="5rem"
            variant="rect"
            width="100%"
          />
        ) : (
          <>
            <Box display="flex" flexDirection="column" mb={4}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={3}
              >
                <NxTypography className={classes.emailSwitch} variant="body2">
                  Allow Esport notification email
                </NxTypography>
                <Box alignItems="center" display="flex">
                  <NxTypography color="textSecondary" variant="body1">
                    {esportNotificationChecked ? 'Yes' : 'No'}
                  </NxTypography>
                  {initialSettingsSet && (
                    <Box mr={1.5} pl={1}>
                      <NxToggle
                        checked={esportNotificationChecked}
                        disabled={false}
                        name="esportNotificationChecked"
                        onChange={handleEsportCheckChange}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {isFacultyAtOrg && (
                <>
                  <NxTypography variant="body2">
                    Receive Notifications for
                  </NxTypography>
                  <Box className={classes.teamsNotificationContainer}>
                    <NxTypography>Your teams only</NxTypography>
                    <Radio
                      checked={
                        selectedEsportNotification ===
                        NotificationScope.PersonalTeams
                      }
                      inputProps={{
                        'aria-label': 'select personal notifications only',
                      }}
                      name="personal-notifications"
                      onChange={handleOnChangeEsportNotification}
                      value={NotificationScope.PersonalTeams}
                    />
                  </Box>
                  <Divider
                    className={classes.divider}
                    light
                    variant="fullWidth"
                  />
                  {shouldRenderSchool && (
                    <Box className={classes.teamsNotificationContainer}>
                      <NxTypography>All teams in your school</NxTypography>
                      <Radio
                        checked={
                          selectedEsportNotification ===
                          NotificationScope.AllSchoolTeams
                        }
                        inputProps={{
                          'aria-label': 'select all school notifications',
                        }}
                        name="all-school-notifications"
                        onChange={handleOnChangeEsportNotification}
                        value={NotificationScope.AllSchoolTeams}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* Esport Email preference email selection */}
            <Box display="flex" flexDirection="column" gridGap={8}>
              {esportNotificationChecked && hasMultipleEmails && (
                <>
                  <NxTypography className={classes.subTitle} variant="overline">
                    Where to be notified
                  </NxTypography>
                  <Box className={classes.teamsNotificationContainer}>
                    <Box alignItems="center" display="flex">
                      <UserOutlined className={classes.emailIcons} />
                      <NxTypography>Personal Email</NxTypography>
                    </Box>
                    <Radio
                      checked={selectedEsportEmail === personalEmail?.email}
                      inputProps={{
                        'aria-label': 'select personal email esports',
                      }}
                      name="personal-email-esports"
                      onChange={handleOnChangeEsportEmail}
                      value={personalEmail?.email}
                    />
                  </Box>

                  {shouldRenderSchool && (
                    <>
                      <Divider
                        className={classes.divider}
                        light
                        variant="fullWidth"
                      />

                      <Box
                        className={classes.teamsNotificationContainer}
                        mb={3}
                      >
                        <Box alignItems="center" display="flex">
                          <SchoolOutlined className={classes.emailIcons} />
                          <NxTypography
                            className={
                              schoolEmail?.email
                                ? ''
                                : classes.disabledSelection
                            }
                          >
                            School Email
                          </NxTypography>
                        </Box>
                        <Radio
                          checked={selectedEsportEmail === schoolEmail?.email}
                          disabled={!schoolEmail?.email}
                          inputProps={{
                            'aria-label': 'select school email esports',
                          }}
                          name="school-email-esports"
                          onChange={handleOnChangeEsportEmail}
                          value={schoolEmail?.email}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </>
        )}

        {/* PlayVS Promotions */}

        <Box display="flex" flexDirection="column" mb={2}>
          <NxTypography className={classes.cardTitle} variant="h2">
            PlayVS Promotions
          </NxTypography>
          <NxTypography className={classes.altText}>
            Receive news about new game titles, competitions, and more.
          </NxTypography>
        </Box>
        {notificationSettingsDataLoading ? (
          <Skeleton height="5rem" variant="rect" width="100%" />
        ) : (
          <Box display="flex" flexDirection="column">
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Box mb={1}>
                <NxTypography className={classes.emailSwitch} variant="body2">
                  Allow PlayVS promotion emails
                </NxTypography>
              </Box>

              <Box alignItems="center" display="flex">
                <NxTypography color="textSecondary" variant="body1">
                  {promotionalEmailsChecked ? 'Yes' : 'No'}
                </NxTypography>
                {initialSettingsSet && (
                  <Box mr={1.5} pl={1}>
                    <NxToggle
                      checked={promotionalEmailsChecked as boolean}
                      disabled={false}
                      name="promotionalEmailsChecked"
                      onChange={handlePromotionalEmailsCheckedChange}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {promotionalEmailsChecked && hasMultipleEmails && !isParent && (
              <>
                <NxTypography variant="overline">
                  Where to be notified
                </NxTypography>
                <Box className={classes.teamsNotificationContainer}>
                  <Box alignItems="center" display="flex">
                    <UserOutlined className={classes.emailIcons} />
                    <NxTypography>Personal Email</NxTypography>
                  </Box>
                  <Radio
                    checked={selectedPromotionalEmail === personalEmail?.email}
                    inputProps={{
                      'aria-label': 'select personal email',
                    }}
                    name="personal-email-promotional"
                    onChange={handleOnChangePromotionalEmail}
                    value={personalEmail?.email}
                  />
                </Box>
                {shouldRenderSchool && (
                  <>
                    <Divider
                      className={classes.divider}
                      light
                      variant="fullWidth"
                    />
                    <Box className={classes.teamsNotificationContainer}>
                      <Box alignItems="center" display="flex">
                        <SchoolOutlined className={classes.emailIcons} />
                        <NxTypography
                          className={
                            schoolEmail?.email ? '' : classes.disabledSelection
                          }
                        >
                          School Email
                        </NxTypography>
                      </Box>
                      <Radio
                        checked={
                          selectedPromotionalEmail === schoolEmail?.email
                        }
                        disabled={!schoolEmail?.email}
                        inputProps={{ 'aria-label': 'select school email' }}
                        name="school-email-promotional"
                        onChange={handleOnChangePromotionalEmail}
                        value={schoolEmail?.email}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
