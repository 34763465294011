import { Paper, makeStyles, Box } from '@material-ui/core'
import { flatten, map } from 'ramda'
import React from 'react'

import { useAutoskipQuery } from '@plvs/utils'
import { EsportSlug, useDoubleEliminationResultsQuery } from '@plvs/graphql'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'

import { Colors, theme } from '@plvs/rally/themes'
import StandingSecondaryLargeDark from '@plvs/rally/assets/icons/StandingSecondaryLargeDark.svg'
import StandingSecondaryLarge from '@plvs/rally/assets/icons/StandingSecondaryLarge.svg'
import { EmptyPageWithSpot } from '@plvs/rally/components/empty/EmptyPageWithSpot'
import {
  computeDoubleElimResults,
  computeIncompleteDoubleElimResults,
  parseLoserSlotsByLabel,
  parseDoubleElimWinnerSlotsByLabel,
  getLastDoubleElimSlot,
} from './standingsHelpers'
import { Bracket } from './Brackets'

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  winnerBracket: {
    marginLeft: theme.spacing(12),
    width: `calc(100% - ${theme.spacing(12)}px)`,
  },
  loserBracket: {
    marginLeft: theme.spacing(49.5),
    width: `calc(100% - ${theme.spacing(49.5)}px)`,
  },
  separator: {
    height: '1px',
    width: '100%',
    margin: 0,
    backgroundColor: Colors.Grey4,
    border: '1px',
  },
}))

interface Props {
  phaseId: string
  slug: EsportSlug | null
  toMatch?(matchId: string): string
}

export const VsDoubleElimStandings: React.FC<Props> = ({
  phaseId,
  slug,
  toMatch,
}) => {
  // queries
  const { data } = useAutoskipQuery(useDoubleEliminationResultsQuery, {
    variables: {
      phaseId,
    },
  })

  // computed values
  const phase = data?.phase
  const slots = phase?.slots ?? []

  // computer winner and loser brackets
  const winnerSlots = slots?.filter(parseDoubleElimWinnerSlotsByLabel) ?? []

  const mappedWinnerBracketSlotResults = map(
    ({ matchSlots }) =>
      flatten(
        map((matchSlot) => {
          const teamSlots = matchSlot?.teamSlots ?? []
          const isMatchCompleted = matchSlot?.match?.status
          const computeFunction = isMatchCompleted
            ? computeDoubleElimResults
            : computeIncompleteDoubleElimResults

          return map(computeFunction, teamSlots)
        }, matchSlots ?? [])
      ),
    winnerSlots
  )

  let doubleElimWinnerLastSlotIndex = 50
  if (winnerSlots.length > 2) {
    doubleElimWinnerLastSlotIndex = getLastDoubleElimSlot(winnerSlots)
  }

  // Extra slots can be created on the backend when calling slot mutation, this is to remove them from double elim view
  const winnerBracketSlotResults = mappedWinnerBracketSlotResults.slice(
    0,
    doubleElimWinnerLastSlotIndex + 1
  )

  const loserSlots = slots?.filter(parseLoserSlotsByLabel)

  const mappedLoserBracketSlotResults = map(
    ({ matchSlots }) =>
      flatten(
        map((matchSlot) => {
          const teamSlots = matchSlot?.teamSlots ?? []
          const isMatchCompleted = matchSlot?.match?.status
          const computeFunction = isMatchCompleted
            ? computeDoubleElimResults
            : computeIncompleteDoubleElimResults

          return map(computeFunction, teamSlots)
        }, matchSlots ?? [])
      ),
    loserSlots
  )

  let doubleElimLoserLastSlotIndex = 50
  if (loserSlots.length > 2) {
    doubleElimLoserLastSlotIndex = getLastDoubleElimSlot(loserSlots)
  }

  // styles
  const classes = useStyles()

  // Extra slots can be created on the backend when calling slot mutation, this is to remove them from double elim view
  const loserBracketSlotResults = mappedLoserBracketSlotResults.slice(
    0,
    doubleElimLoserLastSlotIndex + 1
  )

  // Only show if a phase has been set up
  const hasPhaseSetup = data ?? false

  return hasPhaseSetup ? (
    <Paper className={classes.paper}>
      <PenaltyBox>
        {winnerSlots.length && (
          <Box className={classes.winnerBracket}>
            <Bracket
              bracketSlotResults={winnerBracketSlotResults}
              slots={winnerSlots}
              slug={slug}
              toMatch={toMatch}
            />
          </Box>
        )}
        <hr className={classes.separator} />
        {loserSlots.length && (
          <Box className={classes.loserBracket}>
            <Bracket
              bracketSlotResults={loserBracketSlotResults}
              bracketType="losers"
              slots={loserSlots}
              slug={slug}
              toMatch={toMatch}
            />
          </Box>
        )}
      </PenaltyBox>
    </Paper>
  ) : (
    <EmptyPageWithSpot
      spot={StandingSecondaryLarge}
      spotCaption="No standings"
      spotDark={StandingSecondaryLargeDark}
      subtitle="An updated bracket will be available after the first round is played."
      title="Bracket not available"
    />
  )
}
