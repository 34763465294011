import React from 'react'
import {
  Box,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'

import { useSetMyAvatarImageMutation } from '@plvs/graphql'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { setMyAvatarImage } from './SetAvatarImageHelper'

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    border: `1px solid ${theme.palette.BorderMedium}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.ColorTextAlt || theme.palette.text.secondary,
    cursor: 'pointer',
    letterSpacing: theme.typography.button.letterSpacing,
    fontWeight: theme.typography.button.fontWeight,
    padding: theme.spacing(1.75),
    textAlign: 'center',
    width: '180px',
  },
  formControl: {
    '& p': {
      marginTop: theme.spacing(4.5),
    },
  },
}))

const SetMyAvatarImage = (): React.ReactElement => {
  const { label, formControl } = useStyles()
  const [isBusy, setIsBusy] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const { updateResourceImages } = useResourceImageProvider()
  const [mutate] = useSetMyAvatarImageMutation()
  const handleOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    setIsBusy(true)
    try {
      await setMyAvatarImage(event, mutate)
    } catch (err: unknown) {
      setError(err as Error)
    } finally {
      updateResourceImages()
      setIsBusy(false)
    }
  }

  return (
    <Box height={32} style={{ backgroundColor: 'none' }}>
      <FormControl className={formControl} error={!!error}>
        <InputLabel className={label} shrink>
          {isBusy ? 'Uploading...' : 'Upload Avatar'}
          <Input
            data-testid="upload-avatar-input"
            disabled={isBusy}
            disableUnderline
            id="upload-avatar"
            name="myAvatar"
            onChange={handleOnChange}
            required
            style={{ display: 'none' }}
            type="file"
          />
        </InputLabel>
        <Box mt={1}>
          <FormHelperText>{error && error.message}</FormHelperText>
        </Box>
      </FormControl>
    </Box>
  )
}

export default SetMyAvatarImage
