import React from 'react'
import { useTheme } from '@material-ui/core'
import { NxEmptyState, NxButton } from '@playvs-inc/nexus-components'

import {
  StadiumDarkLargeSecondary,
  StadiumLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { Path } from '@plvs/const'

interface ScrimmagesEmptyProps {
  subtitle?: string
}

export const ScrimmagesEmpty: React.FC<ScrimmagesEmptyProps> = ({
  subtitle,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.type

  return (
    <NxEmptyState
      cta={
        <NxButton
          href={Path.CreateScrimmage}
          label="Create a Scrimmage"
          variant="secondary"
        />
      }
      isStandalone
      spot={
        themeMode === MUIThemeModeEnum.Dark ? (
          <StadiumDarkLargeSecondary height={100} width={100} />
        ) : (
          <StadiumLightLargeSecondary height={100} width={100} />
        )
      }
      subtitle={subtitle}
      title="No Scrimmages"
    />
  )
}
