import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import { RewindData, RewindAssets } from '../Rewind.helpers'

const useStyles = makeStyles(() => ({
  image: {
    height: 'auto',
    width: 480,
    maxWidth: '90vw',
  },
}))

export const FinalSlide: React.FC = () => {
  const styles = useStyles()

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
    >
      <img
        alt={`Thank you! See you next ${RewindData.NextSeason}!`}
        className={styles.image}
        src={RewindAssets.Finale}
      />
    </Box>
  )
}
