import { Box, Card, CardContent, makeStyles } from '@material-ui/core'
import {
  SchoolProvider,
  useGetMySchoolDetailsQuery,
  useGetSchoolProviderAccountFromIdQuery,
} from '@plvs/graphql'
import React, { FunctionComponent } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import SchoolConnection from './SchoolConnection'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 3, 1),
  },
}))

export const SchoolConnectedAccountsPanel: FunctionComponent = () => {
  const classes = useStyles()
  // Grab school data from cache.
  const { data } = useGetMySchoolDetailsQuery()

  const flags = useFlags()
  const showYoutubeConnection = flags.youtubeAccountConnection

  const schoolId = data?.me?.school?.id ?? ''

  const {
    data: schoolProviderAccountsData,
  } = useGetSchoolProviderAccountFromIdQuery({
    variables: {
      schoolId,
    },
    skip: !schoolId,
  })
  const schoolProviderAccounts =
    schoolProviderAccountsData?.school?.schoolProviderAccounts ?? []

  return (
    <Card>
      <CardContent className={classes.container}>
        <Box>
          <NxTypography variant="h4">Connections</NxTypography>
        </Box>
        <Box mt={-1}>
          <SchoolConnection
            name={SchoolProvider.TwitchSchool}
            schoolId={schoolId}
            schoolProviderDetails={schoolProviderAccounts.find(
              (account) => account.providerName === SchoolProvider.TwitchSchool
            )}
            subtitle="Not Connected"
            title="School Twitch Account"
          />
        </Box>

        <Box mb={showYoutubeConnection ? 0 : -3}>
          <SchoolConnection
            name={SchoolProvider.DiscordSchool}
            schoolId={schoolId}
            schoolProviderDetails={schoolProviderAccounts.find(
              (account) => account.providerName === SchoolProvider.DiscordSchool
            )}
            subtitle="Not Connected"
            title="School Discord Account"
          />
        </Box>
        {showYoutubeConnection && (
          <Box mb={-3}>
            <SchoolConnection
              name={SchoolProvider.YoutubeSchool}
              schoolId={schoolId}
              schoolProviderDetails={schoolProviderAccounts.find(
                (account) =>
                  account.providerName === SchoolProvider.YoutubeSchool
              )}
              subtitle="Not Connected"
              title="School Youtube Account"
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
