import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  pageWrapper: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    width: mobile ? '100%' : '1042px',
    height: '100%',
  }),
  gameSubheader: {
    textTransform: 'uppercase',
    justifyContent: 'space-around',
    width: '40%',
    alignItems: 'stretch',
  },
  schoolName: {
    color: theme.palette.common.white,
  },
  barTextColor: {
    color: theme.palette.grey[600],
  },
  leaveButton: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  leaveButtonText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',
  },
  teamsContainer: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    alignItems: 'center',
    flexGrow: mobile ? 1 : 2,
    marginBottom: mobile ? '0' : '250px',
    width: '100%',
    flexDirection: 'column',
    marginTop: mobile ? theme.spacing(2) : theme.spacing(12),
  }),
  mobileTeamNameContainer: {
    marginLeft: theme.spacing(3),
  },
  mobileTeamContainer: {
    marginBottom: theme.spacing(2),
  },
  mobileHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  subtitleWrapper: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    textAlign: 'center',
    width: mobile ? '100%' : '664px',
  }),
  teamLabel: {
    marginTop: theme.spacing(2),
  },
  joinButtonContainer: {
    marginTop: theme.spacing(4),
    alignItems: 'center',
    '& button': {
      height: '36px',
      width: '211px',
    },
  },
  byeMatchButton: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
  },
  leaveQueueButton: {
    visibility: 'hidden',
  },
}))
