import { makeStyles } from '@material-ui/core'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import React from 'react'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { useScheduleConsultationEmailMutation } from '@plvs/graphql/generated'
import { useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '20em',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // Color to match the color used by Groove.  (Not a PlayVS theme color)
    backgroundColor: '#FAFAFB',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  continueButton: {
    padding: '12px',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: '400px',
  },
  skip: {
    padding: theme.spacing(3),
    fontWeight: 600,
    textTransform: 'capitalize',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  p: {
    textAlign: 'center',
    color: theme.palette.ColorTextAlt2,
    marginTop: theme.spacing(2),
  },
  iframe: {
    width: '100%',
    height: '70vh',
    border: 'none',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      height: 530,
    },
  },
}))

export const OnboardScheduleFacultyConsult: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [mutate] = useScheduleConsultationEmailMutation()
  const scheduleLink = ((location.state as unknown) as Record<string, unknown>)
    ?.scheduleLink as string

  const isMobile = useBreakpointSm()
  const classes = useStyles()

  const next = (): void => {
    navigate('finish')
  }

  const skip = (): void => {
    mutate()
    navigate('finish')
  }

  return (
    <Box className={classes.container} mt={2} px={2}>
      <NxTypography align="center" variant="h3">
        Schedule a consultation
      </NxTypography>

      <Box maxWidth={isMobile ? 544 : 400}>
        <NxTypography className={classes.p} variant="subtitle1">
          Schedule a consult to get expert advice on how to get set up based on
          your district and school.
        </NxTypography>
      </Box>
      <iframe
        className={classes.iframe}
        src={scheduleLink}
        title="Schedule a consultation"
      />
      <NxButton
        className={classes.continueButton}
        data-testid="OnboardScheduleFacultyConsult_ContinueButton"
        fullWidth
        label="continue"
        onClick={next}
        variant="primary"
      />
      <NxTypography
        className={classes.skip}
        data-testid="OnboardScheduleFacultyConsult_SkipLink"
        onClick={skip}
        variant="subtitle2"
      >
        Skip
      </NxTypography>
    </Box>
  )
}
