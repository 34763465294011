import { UserNotification } from '@plvs/graphql/generated/graphql'
import {
  NotVerifiedDarkSmallPrimary,
  NotVerifiedLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import React from 'react'
import { useTheme } from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'

const ReviewVerifiedCoachesAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  return (
    <SchoolSystemMessage
      IconComponent={
        isDarkMode ? (
          <NotVerifiedDarkSmallPrimary height={64} width={64} />
        ) : (
          <NotVerifiedLightSmallPrimary height={64} width={64} />
        )
      }
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const ReviewVerifiedCoachesAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Review Verified Coaches',

  createNotification(notification: UserNotification): React.ReactElement {
    return <ReviewVerifiedCoachesAdapterContainer notification={notification} />
  },
}
