/**
 * Checks if current Date is eligible for Early Enrollment Pricing 2022 discount.
 * Cut off is 7/31/2022 EOD
 */
export const isEarlyBird22 = (): boolean => {
  // This is the cut off using the user's local date/time.
  const earlyBirdCutOff = new Date('2022/08/01 00:00:00')
  const now = new Date()

  return now.valueOf() - earlyBirdCutOff.valueOf() < 0
}

/**
 * Checks if current Date is eligible for Spring Early Enrollment Pricing 2023 unlimited plan discount.
 * Cut off is 1/17/2023 EOD
 */
export const isEarlyBird23 = (): boolean => {
  // This is the cut off using the user's local date/time.
  const earlyBirdCutOff = new Date('2023/01/18 00:00:00')
  const now = new Date()

  return now.valueOf() - earlyBirdCutOff.valueOf() < 0
}

/**
 * Checks if current Date is eligible for Early Enrollment Pricing 2024 unlimited plan discount.
 * Cut off is 7/31/2023 EOD
 */
export const isEarlyBird24 = (): boolean => {
  // This is the cut off using the user's local date/time.
  const earlyBirdCutOff = new Date('2023/08/01 00:00:00')
  const now = new Date()

  return now.valueOf() - earlyBirdCutOff.valueOf() < 0
}
