import React from 'react'

import { Box } from '@plvs/respawn/features/layout'

export const MatchCardFooter: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box display="flex" flexDirection="row" gridGap="1em" px={[2, 3]} py={[2, 3]}>
    {children}
  </Box>
)
