/* eslint-disable no-restricted-imports */
import * as yup from 'yup'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  Cookie,
  LocalStorageKey,
  NASEF_QUERY_PARAM,
  Param,
  Path,
} from '@plvs/const'
import {
  SetCookieFn,
  yupEmailRequired,
  passwordRequirementsValidation,
  userRolesToUserRoleNames,
  yupNumber,
  safeSetCookie,
  IS_UNDERAGE_EVENT_NAME,
} from '@plvs/utils'
import dayjs from 'dayjs'
import { NavigateFunction } from 'react-router'

export const postLoginAndRegistration = ({
  setCookie,
  token,
  defaultPath,
  domain,
}: {
  setCookie: SetCookieFn
  token?: string
  defaultPath: string
  domain: string
}): void => {
  // save the JWT
  if (token) {
    safeSetCookie(setCookie, Cookie.Token, token, domain)
  }
  // redirect the user
  const query = new URLSearchParams(window.location.search)
  const next = query.get(Param.Next)
  if (next) {
    query.delete(Param.Next)
    const qs = query.toString()
    window.location.href = `${next}${qs ? `?${qs}` : ''}`
  } else {
    window.location.replace(defaultPath)
  }
}

export function postLogin({
  setCookie,
  token,
  domain,
  userData,
}: {
  setCookie: SetCookieFn
  token: string

  domain: string
  userData: { email: string; userId: string; isUnderage: boolean }
}): void {
  // analytics
  const { email, userId, isUnderage } = userData

  localStorage.setItem(LocalStorageKey.IsUnderage, JSON.stringify(isUnderage))

  analytics.login({
    email,
    userId,
    userRoles: [],
  })

  postLoginAndRegistration({
    defaultPath: Path.Root,
    setCookie,
    token,
    domain,
  })
}

export const postRegistration = (
  setCookie: SetCookieFn,
  onboardingBasePath: string,
  domain: string,
  userData: { email: string; userId: string; isUnderage?: boolean | null },
  referralToken?: string
): void => {
  const { email, userId, isUnderage = false } = userData
  // analytics
  localStorage.setItem(LocalStorageKey.IsUnderage, JSON.stringify(isUnderage))

  analytics.register({
    email,
    referralToken,
    userId,
    userRoles: userRolesToUserRoleNames([]),
  })

  postLoginAndRegistration({
    defaultPath: onboardingBasePath,
    setCookie,
    domain,
  })
}

export const LoginFormSchema = yup.object().shape({
  email: yupEmailRequired,
  password: yup.string().required('Enter a password.'),
})

export const ForgotPasswordFormSchema = yup.object().shape({
  email: yupEmailRequired,
})

export const SignUpFormSchema = yup.object().shape({
  email: yupEmailRequired,
  password: passwordRequirementsValidation,
})

export interface BirthdayInput {
  month: string
  day: string
  year: string
}

export const BirthdaySchema = yup.object().shape({
  day: yupNumber.required(),
  month: yupNumber.required(),
  year: yupNumber.required(),
})

const AGE_CUTOFF = 13

export const isUnderage = (birthday: dayjs.Dayjs): boolean => {
  const currentDate = dayjs().startOf('day')
  const age = currentDate.diff(birthday, 'year')

  return age < AGE_CUTOFF
}

export const PARENT_SIGNUP_TEXT =
  'Your child wants to join an esports team at their school. Before your child’s coach can add them to a team and start competing, we require a quick parent account creation and identity verification. Let’s get started!'

export const handleBirthdaySubmit = ({
  data,
  isParentSignup,
  setHasError,
  hasCoppaSupport,
  navigate,
  setBirthday,
  shouldRedirectToParentSignup,
}: {
  data: BirthdayInput
  isParentSignup: boolean
  setHasError: React.Dispatch<React.SetStateAction<boolean>>
  hasCoppaSupport: boolean
  navigate: NavigateFunction
  setBirthday: React.Dispatch<React.SetStateAction<dayjs.Dayjs | undefined>>
  shouldRedirectToParentSignup: boolean
}): void => {
  const date = dayjs(`${data.year}-${data.month}-${data.day}`, 'YYYY-M-D', true)

  if (!date.isValid()) {
    setHasError(true)
    return
  }

  if (isUnderage(date)) {
    localStorage.setItem(LocalStorageKey.IsUnderage, JSON.stringify(true))

    if (isParentSignup) {
      setHasError(true)
      return
    }

    if (hasCoppaSupport) {
      if (shouldRedirectToParentSignup) {
        navigate(Path.ParentSignUpRequest, { replace: true })
        return
      }
      window.dispatchEvent(new Event(IS_UNDERAGE_EVENT_NAME))
      return
    }

    setHasError(true)
  } else {
    setBirthday(date)
  }
}

export const getOnboardingBasePath = ({
  isNasefSignUp,
  isParentSignup,
}: {
  isNasefSignUp: boolean
  isParentSignup: boolean
}): string => {
  if (isParentSignup) {
    return Path.ParentSpawnPoint
  }

  return isNasefSignUp
    ? `${Path.SpawnPoint}${NASEF_QUERY_PARAM}`
    : Path.SpawnPoint
}

export const getEmailLabel = (
  isPersonalEmailOptional: boolean,
  isParentSignup: boolean,
  isStadiumSignup: boolean
): string => {
  if (isParentSignup || isStadiumSignup) {
    return 'Email'
  }

  return isPersonalEmailOptional ? 'School Email' : 'Email address'
}
