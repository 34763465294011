import { capitalize } from '@material-ui/core'
// eslint-disable-next-line import/no-restricted-paths
import { scrimmageRatings } from '@plvs/respawn/features/esport/creator/esportStaticDetails'
import { head } from 'ramda'
import React from 'react'
import { FilterButton } from './FilterButton'
import { Option } from './filterHelpers'
import { useOption } from './useOption'

export type FilterScrimmageRating = { id: string }
const allRatings = Object.values(scrimmageRatings).flat()

const defaultOption: Option<FilterScrimmageRating> = {
  id: 'default',
  name: 'Rating',
  values: [],
}

const getRatingOptions: (
  ratings: string[]
) => Option<FilterScrimmageRating>[] = (ratings) =>
  ratings.map((rating) => ({
    id: rating,
    name: capitalize(rating),
    values: [{ id: rating }],
  }))

const allOptions = [defaultOption, ...getRatingOptions(allRatings)]

export function useRating(): {
  selectedRating?: string
  ratingFilter: React.ReactElement
} {
  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption,
  })

  return {
    selectedRating: head(option.values)?.id,
    ratingFilter: (
      <FilterButton<Option<FilterScrimmageRating>>
        id="rating-filter"
        option={option}
        options={options}
        setOption={setOption}
      />
    ),
  }
}
