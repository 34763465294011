import { map } from 'ramda'
import React, { useEffect } from 'react'

import {
  Column,
  Table,
  TableColumn,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import {
  QuerySortOrder,
  ResourceType,
  User,
  UserEmail,
  UserRoleName,
  UserRoleStatusName,
  useMyCoachPlayersQuery,
} from '@plvs/graphql'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@plvs/const'
import { betterCompact } from '@plvs/utils'
import { getSchoolEmail, MinimalEmailEntry } from './helpers'

interface Props {
  filters: { name: string }
  filtersDebouncing: boolean
  setTotalCount: (count: number) => void
}

const columns = [
  Column.UserCluster,
  Column.SchoolEmailCopiable,
  Column.UserGradYear,
  Column.UserJoinDate,
  Column.UserStatus,
  Column.UserArchivedPlayerMore,
]

export const ArchivedPlayersTable: React.FC<Props> = ({
  filters,
  filtersDebouncing,
  setTotalCount,
}) => {
  const { page, getNumPages, setPage, offset } = usePagination({
    itemsPerPage: DEFAULT_PAGINATION_PAGE_LIMIT,
  })
  const { selectedEntityId } = useProfileContext()

  const { data, loading } = useMyCoachPlayersQuery({
    variables: {
      input: {
        filters: {
          name: filters.name,
          roles: {
            roleNames: [UserRoleName.Player],
            roleStatuses: [
              UserRoleStatusName.Graduated,
              UserRoleStatusName.Transferred,
            ],
          },
          schoolId: selectedEntityId,
        },
        options: {
          limit: DEFAULT_PAGINATION_PAGE_LIMIT,
          offset,
          orderby: [{ field: 'createdAt', order: QuerySortOrder.Asc }],
        },
      },
    },
    skip: !selectedEntityId || filtersDebouncing,
  })

  const players = data?.findUsers?.users ?? []
  const playerData = map(
    (player) => ({
      ...player,
      schoolId: selectedEntityId,
      email: getSchoolEmail(
        selectedEntityId,
        player.emails as MinimalEmailEntry[] | null
      ),
    }),
    players
  )
  // const playersToShow = betterCompact(playerData)

  const playersToShow = betterCompact(
    playerData.map((player) => ({
      ...player,
      schoolId: selectedEntityId,
      emails: player.emails as UserEmail[], // Cast the emails property to UserEmail[]
      schoolRoleStatus:
        player?.schoolRoleStatus?.map((status) => ({
          ...status,
          role: {
            ...status.role,
            deletedAt: null,
            id: '',
            isDirect: false,
            parentRoleId: '',
            resourceId: '',
            resourceType: ResourceType.Organization,
            status: UserRoleStatusName.Approved,
            userId: '',
          },
        })) ?? [],
    }))
  )

  const totalCount = data?.findUsers?.totalCount ?? 0
  const numPages = getNumPages(totalCount)

  const columnsToUse =
    numPages > 1
      ? columns.map(
          (column) => ({ ...column, sortable: false } as TableColumn<unknown>)
        )
      : columns

  useEffect(() => {
    setTotalCount(totalCount)
  }, [data])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <LoadingSpinner size="large" />
      </Box>
    )
  }

  return players.length > 0 ? (
    <Box data-cy="archived-players-table">
      <Table<
        Pick<
          User,
          | 'id'
          | 'avatarUrl'
          | 'createdAt'
          | 'email'
          | 'emails'
          | 'gradYear'
          | 'name'
          | 'schoolRoleStatus'
        > & { schoolId: string }
      >
        columns={columnsToUse}
        data={playersToShow}
      />

      <Box display="flex" my={2}>
        <TablePagination
          numPages={numPages}
          page={page}
          setPage={setPage}
          totalCount={players.length}
        />
      </Box>
    </Box>
  ) : (
    <Box mb={2} mx={3}>
      <NxTypography variant="body2">
        You do not have any archived players.
      </NxTypography>
    </Box>
  )
}
