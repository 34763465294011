import { TextField, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  cleanGraphQLError,
  formErrorToString,
  yupTeamNameValidation,
} from '@plvs/utils'
import { WaitTillLoaded, Box } from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxModal,
  NxAlert,
  NxTypography,
} from '@playvs-inc/nexus-components'
import {
  UpdateTeamInput,
  useUpdateTeamMutation,
  useGetTeamNameQuery,
} from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'
import { head } from 'ramda'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
  cancelButton: {
    '&.MuiButton-root': {
      color: theme.palette.ColorTextDisabled || theme.palette.text.hint,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const RenameTeamFormSchema = yup.object().shape({
  name: yupTeamNameValidation,
})

interface RenameTeamProps {
  onClose(): void
  id: string
}

export const RenameTeam: React.FC<RenameTeamProps> = ({ onClose, id }) => {
  const classes = useStyles()
  const [error, setError] = useState<Error>()
  const { enqueueSnackbar } = useSnackbar()
  const { data, loading } = useGetTeamNameQuery({
    variables: {
      id,
    },
    skip: !id,
  })
  const name = data?.team?.name

  const [mutate, { loading: isMutating }] = useUpdateTeamMutation()

  const { errors, handleSubmit, register } = useForm<UpdateTeamInput>({
    resolver: yupResolver<UpdateTeamInput>(RenameTeamFormSchema),
  })

  const onSubmit = async (input: UpdateTeamInput): Promise<void> => {
    const newName = input?.name
    setError(undefined)
    try {
      const result = await mutate({
        variables: {
          teamId: id,
          input: {
            name: newName,
          },
        },
      })

      const success = result?.data?.updateTeam
      if (success) {
        onClose()
        enqueueSnackbar('Success! Team name updated.', {
          variant: 'success',
        })
      } else {
        throw head(result?.errors ?? []) || new Error('Invalid response.')
      }
    } catch (e: any) {
      setError(e)
    }
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <NxModal
        onClose={(): void => {
          if (onClose) onClose()
        }}
        open
        subtitle="Choose a new team name. Be respectful."
        title={`Rename ${name ?? 'Team'}?`}
      >
        <form aria-label="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          {errorMessage && (
            <Box pb={3}>
              <NxAlert
                canClose
                content={
                  <NxTypography
                    colorToken="ColorTextAlt2"
                    data-testid="Banner_Subtitle"
                    variant="body1"
                  >
                    {errorMessage}
                  </NxTypography>
                }
                title={
                  <NxTypography data-testid="Banner_Title" variant="h4">
                    Unable to change team name
                  </NxTypography>
                }
                variant="error"
              />
            </Box>
          )}
          <TextField
            autoComplete="off"
            data-testid="name-textfield"
            error={!!errors?.name}
            fullWidth
            helperText={formErrorToString(errors?.name)}
            inputRef={register}
            label="New team name"
            name="name"
            variant="filled"
          />
          <Box
            alignItems="center"
            display="flex"
            gridColumnGap={8}
            justifyContent="flex-end"
            mt={4}
          >
            <NxButton
              className={classes.cancelButton}
              label="cancel"
              onClick={onClose}
              variant="text"
            />
            <NxButton
              data-testid="submit"
              disabled={isMutating}
              label="Rename Team"
              type="submit"
              variant="primary"
            />
          </Box>
        </form>
      </NxModal>
    </WaitTillLoaded>
  )
}
