import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CssTriangle } from '@plvs/rally/components/triangle/CssTriangle'
import { makeStyles, useTheme } from '@material-ui/core'
import { MatchResult } from '@plvs/graphql/generated'

export const useStyles = makeStyles((theme) => ({
  matchResultsText: {
    margin: theme.spacing(0, 1),
  },
}))

interface Props {
  matchResults: Pick<MatchResult, 'id' | 'gamesWon' | 'gamesLost' | 'placing'>[]
}

export const MatchResults: React.FC<Props> = ({ matchResults }) => {
  const theme = useTheme()

  const classes = useStyles()

  return (
    <>
      {matchResults[0]?.placing === 1 && (
        <CssTriangle color={theme.palette.ColorIconSuccess} direction="left" />
      )}
      <NxTypography className={classes.matchResultsText} variant="h4">
        {matchResults[0]?.gamesWon} - {matchResults[1]?.gamesWon}
      </NxTypography>
      {matchResults[1]?.placing === 1 && (
        <CssTriangle color={theme.palette.ColorIconSuccess} direction="right" />
      )}
    </>
  )
}
