import { makeStyles } from '@material-ui/core'
import React from 'react'
import { MatchStatus } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import { format } from 'date-fns'
import { Colors } from '../../../themes/colors'
import { CountDown } from './CountDown'
import dayjs from '../../../init/dayjs'

const useStyles = makeStyles({
  dot: {
    backgroundColor: Colors.Orange,
    borderRadius: 8,
    boxShadow: `0 0 10px ${Colors.Orange}`,
    height: 8,
    marginRight: ({ mobile }: { mobile?: boolean }): number => (mobile ? 4 : 8),
    width: 8,
  },
  line: {
    backgroundColor: Colors.Grey3,
    marginLeft: ({ mobile }: { mobile?: boolean }): number => (mobile ? 8 : 14),
    marginRight: ({ mobile }: { mobile?: boolean }): number =>
      mobile ? 8 : 14,
    opacity: 0.8,
    height: 16,
    width: 1,
  },
})

const Live: React.FC<{ label: string; mobile?: boolean }> = ({
  label,
  mobile,
}) => {
  const classes = useStyles({ mobile })

  return (
    <>
      <div className={classes.dot} />
      <NxTypography colorToken="OverlayColorTextBase" variant="body1">
        LIVE
      </NxTypography>
      {label && (
        <>
          <div className={classes.line} />
          <NxTypography colorToken="OverlayColorTextBase" variant="body1">
            {label}
          </NxTypography>
        </>
      )}
    </>
  )
}

export const getAfter = ({
  live,
  status,
  date,
}: {
  live: React.ReactElement
  status: MatchStatus | null | undefined
  date: string | null | undefined
}): React.ReactNode => {
  const statusPretty = status
    ? status.charAt(0).toUpperCase() + status.slice(1)
    : ''
  switch (status) {
    case MatchStatus.Completed:
      return `Completed on ${format(new Date(date ?? ''), 'MMM d, yyyy')}`
    case MatchStatus.Forfeited:
      return `Forfeited on ${format(new Date(date ?? ''), 'MMM d, yyyy')}`
    case MatchStatus.Live:
      return live
    case MatchStatus.Open:
      return 'Match Starting'
    case MatchStatus.Quarantined: {
      const matchDate = dayjs(date)
      if (
        (matchDate.day() === 5 &&
          dayjs().isAfter(matchDate.add(3, 'day').startOf('day'))) ||
        (matchDate.day() !== 5 && dayjs().isAfter(matchDate.add(24, 'h')))
      ) {
        return 'Pending Forfeit'
      }
      return statusPretty
    }
    case MatchStatus.Scheduled:
    default:
      return statusPretty
  }
}

export const MatchCountdown: React.FC<{
  date: string | null | undefined
  liveLabel: string
  mobile?: boolean
  status: MatchStatus | null | undefined
  refetchMatchStatus(): Promise<void>
}> = ({ date, liveLabel, mobile, status, refetchMatchStatus }) => {
  switch (status) {
    case MatchStatus.Scheduled:
    case MatchStatus.Open:
    case MatchStatus.Live:
    case MatchStatus.Completed:
    case MatchStatus.Quarantined:
    case MatchStatus.Forfeited:
      return (
        <CountDown
          after={getAfter({
            live: <Live label={liveLabel} mobile={mobile} />,
            status,
            date,
          })}
          date={date}
          mobile={mobile}
          refetchMatchStatus={refetchMatchStatus}
        />
      )
    default:
      return (
        <NxTypography
          colorToken="OverlayColorTextBase"
          data-cy="matchStatus"
          variant="body2"
        >
          {getAfter({
            live: <Live label={liveLabel} mobile={mobile} />,
            status,
            date,
          })}
        </NxTypography>
      )
  }
}
