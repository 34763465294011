import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { head } from 'ramda'

// MyLeagueFilters query is not performant. For now, eligible leagues has been removed
// from the Roster Card. This feature will be brought back once there is a performant endpoint to get eligible leagues
// for a team. Test and stories have been updated.
export interface TeamDetailsContentProps {
  enrolledLeagueNames: { displayName: string | null }[]
}

export const TeamLeaguesStatus: React.FC<TeamDetailsContentProps> = ({
  enrolledLeagueNames,
}) => {
  const leagueNames = enrolledLeagueNames
    .map(({ displayName }) => displayName)
    .filter((displayName) => displayName)

  return (
    <NxTypography data-testid="TeamLeaguesStatus_Text">
      {head(leagueNames)}
    </NxTypography>
  )
}
