import { Vesl } from '@playvs-inc/nexus-icons'
import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'
import { PillVariants } from '../../NotificationPill'

export const ConnectVeslAccountAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Connect VESL Account',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SystemMessage
        IconComponent={<Vesl height={64} width={64} />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        pillText="Account Connection"
        pillVariant={PillVariants.Warning}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
