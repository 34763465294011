import { Link, useTheme, makeStyles } from '@material-ui/core'
import { Path } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { RouterLink } from '@plvs/respawn/features/route'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  win: {
    color: theme.palette.ColorTextSuccess,
  },
  loss: {
    color: theme.palette.ColorTextError,
  },
  subtitle: {
    textOverflow: 'ellipsis',
  },
}))

/**
 * This is similar to GameCluster, but it has additional props of `isSettled`,
 * `isForfeit`, `forfeitBy`, and `settledBy`
 */
export const GameClusterWithGameStatus: React.FC<{
  gameId?: string
  matchId: string | null
  opponent?: string
  outcome?: string
  opposing?: boolean
  subtitle?: string | null
  title?: string | null
  isForfeit?: boolean
  isSettled?: boolean
  forfeitBy?: string
  settledBy?: string
  isGamelessType?: boolean
}> = ({
  gameId,
  matchId,
  opponent,
  outcome,
  opposing,
  subtitle,
  title,
  isForfeit,
  isSettled,
  forfeitBy,
  settledBy,
  isGamelessType = false,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  return (
    <Box px={0} py={0}>
      <Link
        key={gameId ?? matchId ?? ''}
        component={RouterLink}
        to={
          isGamelessType
            ? `${Path.Match}/${matchId}`
            : `${Path.Match}/${matchId}/game/${gameId}`
        }
        underline="none"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection={opposing ? 'row-reverse' : 'row'}
          justifyContent="flex-start"
          py={theme.spacing(0.25)}
          width="100%"
        >
          <Box marginRight={theme.spacing(2)}>
            <NxTypography colorToken="ColorTextBase" variant="h3">
              {title}
            </NxTypography>
            <NxTypography colorToken="ColorTextAlt" variant="subtitle2">
              {subtitle}
            </NxTypography>
          </Box>
          <Box
            alignItems="center"
            justifyContent="flex-start"
            marginRight={theme.spacing(2)}
            width={theme.spacing(1.5)}
          >
            <span className={outcome === 'L' ? styles.loss : styles.win}>
              {outcome}
            </span>
          </Box>
          <Box justifyContent="flex-start">
            <NxTypography
              className={styles.subtitle}
              colorToken="ColorTextAlt2"
              variant="h4"
            >
              {opponent && `vs ${opponent}`}
            </NxTypography>
          </Box>
        </Box>
      </Link>
      {(isForfeit || isSettled) && (
        <NxTypography colorToken="ColorTextAlt2" variant="body1">
          {isForfeit && `Forfeit by ${forfeitBy}`}
          {isSettled && `Settled by ${settledBy}`}
        </NxTypography>
      )}
    </Box>
  )
}
