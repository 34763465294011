import React from 'react'

import { NodeEnv } from '@plvs/env'
import { Tooltip } from '@plvs/rally/components/tooltip'
import { Box, Theme, useTheme } from '@material-ui/core'
import { useAppLocationFn, useProductTypeFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import { productTypeMapToDisplayString } from '@plvs/client-data/models/ProductType'
import { LocalStorageKey } from '@plvs/const'
import { Badge, BadgeProps } from './Badge'

const envToColors = (
  environment: NodeEnv,
  theme: Theme
): {
  backgroundColor: string
  color: string
} => {
  switch (environment) {
    case 'production':
      return {
        backgroundColor: theme.palette.ColorBackgroundError as string,
        color: theme.palette.ColorTextError as string,
      }
    case 'staging':
      return {
        backgroundColor: theme.palette.ColorBackgroundWarn as string,
        color: theme.palette.ColorTextWarn as string,
      }
    case 'development':
      return {
        backgroundColor: theme.palette.ColorBackgroundSuccess as string,
        color: theme.palette.ColorTextSuccess as string,
      }
    default:
      return {
        backgroundColor: theme.palette.ColorBackgroundError as string,
        color: theme.palette.ColorTextError as string,
      }
  }
}

export const ApiEnvBadge: React.FC<
  { apiEnv: NodeEnv } & Omit<BadgeProps, 'backgroundColor' | 'color' | 'text'>
> = ({ apiEnv, ...rest }) => {
  const theme = useTheme()
  const productType = useProductTypeFn()
  const location = useAppLocationFn()
  const isCheckpoint = location === Location.Checkpoint
  const assignedApiEnv = localStorage.getItem(LocalStorageKey.Environment)

  const productTypeString = productTypeMapToDisplayString[productType]
  return (
    <Tooltip
      title={
        apiEnv && assignedApiEnv
          ? `This application is using the ${apiEnv} API and pointing to ${assignedApiEnv} environment.`
          : 'Cannot determine which API is being used'
      }
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
        <Badge
          {...envToColors(apiEnv, theme)}
          style={{ margin: '0 8px' }}
          text={`${apiEnv} ${isCheckpoint ? productTypeString : ''}`}
          {...rest}
        />

        {assignedApiEnv ? (
          <Badge
            {...envToColors(apiEnv, theme)}
            style={{ margin: '0 8px' }}
            text={`API: ${assignedApiEnv}`}
            textVariant="body4"
            {...rest}
          />
        ) : null}
      </Box>
    </Tooltip>
  )
}
