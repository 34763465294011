import React from 'react'
import { Box, Hidden } from '@plvs/respawn/features/layout'
import {
  MatchCheckInMatch,
  MatchCheckInMatchStatus,
  MatchCheckInStatusMap,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { makeStyles, Button } from '@material-ui/core'

import { Link } from '@plvs/rally/components/link'
import { MatchCheckInSidebarItem } from '@plvs/rally/features/match/checkIns/MatchCheckInSidebarItem'
import { CaretLeft } from '@playvs-inc/nexus-icons'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'

dayjs.extend(tz)

export interface MatchCheckInSidebarProps {
  matches: MatchCheckInMatch[]
  loading: boolean
  selectedMatchId?: string
  onSelectMatch(matchId: string): void
  matchStatuses: MatchCheckInStatusMap
}

export interface MatchCheckInSidebarItemProps {
  selected: boolean
  match: MatchCheckInMatch
  matchStatus: MatchCheckInMatchStatus
  onSelectMatch(matchId: string): void
}

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100vh',
    overflow: 'auto',
    position: 'sticky',
    top: 0,
    paddingRight: '10px',
    paddingTop: '16px',
    width: '30em',
    maxWidth: '100%',
  },
  gutter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export const MatchCheckInSidebar = ({
  loading,
  matches,
  selectedMatchId,
  onSelectMatch,
  matchStatuses,
}: MatchCheckInSidebarProps): React.ReactElement => {
  const { gutter, container, content, loader } = useStyles()

  const date = dayjs().startOf('day')

  const matchesByDay: MatchCheckInMatch[][] = []

  matches?.forEach((match) => {
    const matchStartDate = dayjs(match.scheduledStartsAt).startOf('day')
    const daysUntil = matchStartDate.diff(date, 'days')
    matchesByDay[daysUntil] = matchesByDay[daysUntil]
      ? [...matchesByDay[daysUntil], match]
      : [match]
  })

  return (
    <Box className={container} pb={3}>
      <Box className={gutter} mb={1} paddingLeft="20px" paddingRight="20px">
        <Box className={content}>
          <Link to="/app/schedule/upcoming">
            <Button>
              <CaretLeft />
              Back to Schedule
            </Button>
          </Link>
          {loading && (
            <Hidden mdUp>
              <Box className={loader} pt={6}>
                <LoadingSpinner size="medium" />
              </Box>
            </Hidden>
          )}
          {matchesByDay.map((matchesForDay, dayIndex) => {
            let timesString
            if (dayIndex === 0) {
              timesString = 'Today'
            } else if (dayIndex === 1) {
              timesString = 'Tomorrow'
            } else {
              timesString = `in ${dayIndex} Days`
            }

            const matchString = matchesForDay.length === 1 ? 'Match' : 'Matches'
            return (
              !!matchesForDay && (
                <Box key={timesString} className="match-group">
                  <Box mb={1} mt={2} pl={2}>
                    <NxTypography
                      className="match-group-title"
                      data-testid="MatchCheckInSideBar_NxTypography"
                      variant="subtitle2"
                    >{`${matchesForDay.length} ${matchString} ${timesString}`}</NxTypography>
                  </Box>
                  {matchesForDay?.map((match) => (
                    <MatchCheckInSidebarItem
                      key={match.id}
                      match={match}
                      matchStatus={matchStatuses[match.id]}
                      onSelectMatch={onSelectMatch}
                      selected={match.id === selectedMatchId}
                    />
                  ))}
                </Box>
              )
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
