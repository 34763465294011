import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NxBreadcrumbs, NxTypography } from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { noop } from 'ramda-adjunct'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { School, useGetChildUserQuery } from '@plvs/graphql/generated'

import { UserCluster } from '@plvs/rally/components/cluster'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useNavigate } from 'react-router-dom'
import { ChildSchoolLoginSettings } from './childSettings/ChildSchoolLoginSettings'
import { ChildOtherSettings } from './childSettings/ChildOtherSettings'
import { ChildInfoSettings } from './childSettings/ChildInfoSettings'

export const useStyles = makeStyles((theme) => ({
  bannerContent: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
    },
  },
  bannerText: {
    color: theme.palette.OverlayColorTextBase,
  },
  settingsContainer: {
    margin: theme.spacing(3, 2, 0),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 0),
    },
  },
}))

const AccountChildSettings: React.FC<{ userId: string }> = ({ userId }) => {
  const navigate = useNavigate()
  const classes = useStyles()

  const { data, loading } = useGetChildUserQuery({ variables: { id: userId } })

  const child = data?.user
  const childName = child?.firstName
  const childEmail = child?.emails?.[0]?.email
  const childSchool = child?.school

  return (
    <Box>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <Box className={classes.settingsContainer}>
          <NxBreadcrumbs
            breadcrumbs={[
              {
                label: 'My Family Group',
                onClick: (): void => {
                  navigate(Path.ParentDashboard)
                },
              },
              {
                label: 'Settings for Family Group Member',
                onClick: noop,
              },
            ]}
          />
          <Box mt={3}>
            <NxTypography variant="h2">
              Settings for Family Group Member
            </NxTypography>

            <UserCluster
              avatarSize={NxAvatarSize.LG}
              avatarUrl={child?.avatarUrl}
              id={child?.id}
              subtitle="Child"
              title={child?.name}
            />

            <ChildInfoSettings
              dateOfBirth={child?.dateOfBirth}
              graduationYear={child?.gradYear}
              name={child?.name}
            />

            <ChildSchoolLoginSettings
              childEmail={childEmail}
              childSchool={childSchool as School}
              userId={userId}
            />

            <ChildOtherSettings childName={childName} userId={userId} />
          </Box>
        </Box>
      </WaitTillLoaded>
    </Box>
  )
}

export default AccountChildSettings
