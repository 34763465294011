import React from 'react'

import { Container } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { PublicPage } from './page'

export const ValuesPage: React.FC = () => (
  <PublicPage description="Our company values" title="Values">
    <Container maxWidth="sm">
      <Box my={4}>
        <NxTypography variant="h1">Company Values</NxTypography>
      </Box>
      <Box my={2}>
        <span aria-labelledby="playerExperienceFirst" role="img">
          🎮 <NxTypography variant="h3">Player Experience First</NxTypography>
        </span>
        <NxTypography variant="body1">
          We’re obsessed over player experience. Every decision we make is
          rooted in one purpose — making our player experience as good as
          possible.
        </NxTypography>
      </Box>
      <Box my={2}>
        <span aria-labelledby="teamwork" role="img">
          🤝 <NxTypography variant="h3">Teamwork</NxTypography>
        </span>
        <NxTypography variant="body1">
          Nobody can accomplish greatness alone. The name on the front of the
          jersey is more important than the one on the back. When a task or
          problem needs addressing, we don‘t say “That‘s not my problem.” we say
          “How can I help?”.
        </NxTypography>
      </Box>
      <Box my={2}>
        <span aria-labelledby="allIn" role="img">
          🎯 <NxTypography variant="h3">All-In</NxTypography>
        </span>
        <NxTypography variant="body1">
          If it’s worth doing, it’s worth trying. We dive into every project
          with this mindset. Delivering scaled amateur esports requires our full
          commitment. No half-steppin‘.
        </NxTypography>
      </Box>
      <Box my={2}>
        <span aria-labelledby="impossible" role="img">
          🧗🏽
          <NxTypography variant="h3">
            Impossible Is Our Favorite Kind Of Possible
          </NxTypography>
        </span>
        <NxTypography variant="body1">
          Achieving ambitious goals demands vision. And vision means refusing to
          accept impossibilities. We are breaking new ground on something that’s
          never been done before. Dream big.
        </NxTypography>
      </Box>
    </Container>
  </PublicPage>
)
