import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import { NxSkeletonLoader } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  skeleton: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    gridGap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& > span': {
      minWidth: 500,
      maxWidth: 600,
      paddingRight: theme.spacing(4),

      [theme.breakpoints.down('md')]: {
        maxWidth: 600,
        paddingRight: theme.spacing(4),
      },
    },
  },
}))

export const LobbySkeleton = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div data-testid="loading-skeleton">
      <NxSkeletonLoader height="300px" variant="rect" />
      <Box className={classes.skeleton}>
        <NxSkeletonLoader height="650px" variant="rect" />
        <NxSkeletonLoader height="650px" variant="rect" />
      </Box>
    </div>
  )
}
