import React from 'react'

import { Grid } from '@material-ui/core'

import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import { EsportSlug } from '@plvs/graphql/types'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useGetMatchRostersContainerDataQuery } from '@plvs/graphql/generated'
import { TeamStatsRosterHeader } from '@plvs/respawn/features/match-lobby/TeamStatsRosterHeader'

interface MatchRostersContainerProps {
  esportSlug: EsportSlug | null | undefined
  matchId: string
}

export const MatchRostersContainer: React.FC<MatchRostersContainerProps> = ({
  esportSlug,
  matchId,
}) => {
  const mobile = useBreakpointXs()

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const {
    homeTeam,
    awayTeam,
    enableScoutingToTeamsPage,
  } = getMatchLobbyRenderControllerState().lobby

  const { data, loading } = useGetMatchRostersContainerDataQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const match = data?.match
  const homeTeamFromQuery = match?.team1
  const awayTeamFromQuery = match?.team2

  return (
    <Grid container spacing={3}>
      <Grid data-cy={homeTeam.teamName} item xs={mobile ? 12 : 6}>
        <MatchCard px={0}>
          <RosterContainer
            CustomHeader={(): React.ReactElement => (
              <TeamStatsRosterHeader
                enableScoutingToTeamsPage={enableScoutingToTeamsPage}
                esportSlug={esportSlug}
                hasClinchedPlayoffs={
                  !!homeTeamFromQuery?.regularSeasonTeamPhaseStandings
                    ?.hasClinchedPlayoffs
                }
                isTeamDeleted={!!homeTeamFromQuery?.archivedAt}
                phaseId={match?.slot?.phase?.id}
                ranking={
                  homeTeamFromQuery?.regularSeasonTeamPhaseStandings?.ranking
                }
                teamId={homeTeam.id}
                teamName={homeTeam.teamName}
                winLossRecord={{
                  matchesWon:
                    homeTeamFromQuery?.regularSeasonTeamPhaseStandings
                      ?.winLossRecord?.matchesWon ?? 0,
                  matchesLost:
                    homeTeamFromQuery?.regularSeasonTeamPhaseStandings
                      ?.winLossRecord?.matchesLost ?? 0,
                }}
              />
            )}
            date={match?.scheduledStartsAt}
            isInMatchAssistantContainer
            overrideTeamRosterViewPermissions
            parentDataLoading={loading}
            rosterCardStarterRowActions={[
              RosterCardMenuRowItems.RepositionPlayer,
            ]}
            rosterCardSubRowActions={[
              RosterCardMenuRowSubItems.RepositionPlayer,
            ]}
            showAdditionalContainerActions={false}
            showCreateScrimmage={false}
            teamId={homeTeam.id}
          />
        </MatchCard>
      </Grid>
      <Grid data-cy={awayTeam.teamName} item xs={mobile ? 12 : 6}>
        <MatchCard px={0}>
          <RosterContainer
            CustomHeader={(): React.ReactElement => (
              <TeamStatsRosterHeader
                enableScoutingToTeamsPage={enableScoutingToTeamsPage}
                esportSlug={esportSlug}
                hasClinchedPlayoffs={
                  !!awayTeamFromQuery?.regularSeasonTeamPhaseStandings
                    ?.hasClinchedPlayoffs
                }
                isTeamDeleted={!!awayTeamFromQuery?.archivedAt}
                phaseId={match?.slot?.phase?.id}
                ranking={
                  awayTeamFromQuery?.regularSeasonTeamPhaseStandings?.ranking
                }
                teamId={awayTeam.id}
                teamName={awayTeam.teamName}
                winLossRecord={{
                  matchesWon:
                    awayTeamFromQuery?.regularSeasonTeamPhaseStandings
                      ?.winLossRecord?.matchesWon,
                  matchesLost:
                    awayTeamFromQuery?.regularSeasonTeamPhaseStandings
                      ?.winLossRecord?.matchesLost,
                }}
              />
            )}
            date={match?.scheduledStartsAt}
            hideDefaultHeader
            isInMatchAssistantContainer
            overrideTeamRosterViewPermissions
            parentDataLoading={loading}
            rosterCardStarterRowActions={[
              RosterCardMenuRowItems.RepositionPlayer,
            ]}
            rosterCardSubRowActions={[
              RosterCardMenuRowSubItems.RepositionPlayer,
            ]}
            showAdditionalContainerActions={false}
            showCreateScrimmage={false}
            teamId={awayTeam.id}
          />
        </MatchCard>
      </Grid>
    </Grid>
  )
}
