export const getSubtitle = ({
  isStudent,
  personalEmailOptional,
}: {
  isStudent: boolean | undefined
  personalEmailOptional: boolean
}): string => {
  if (isStudent) {
    return ''
  }

  return personalEmailOptional
    ? 'Add your cell phone number below to help us verify your identity.'
    : 'Add your cell phone number and work email address below to help us verify your identity.'
}
