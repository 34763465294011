import {
  CompetitionGroup,
  GenerateReferralLinkMutationFn,
  ResourceType,
  UserRoleName,
} from '@plvs/graphql/generated'
import highFive2020 from './hi-five-2020.png'

export const getCardProps = (
  userRoleName: UserRoleName.Student | UserRoleName.Fac,
  isFaculty: boolean
): {
  image?: string
  subtitle: string
  title: string
} => {
  if (userRoleName === UserRoleName.Student) {
    return isFaculty
      ? {
          subtitle:
            'Ready to level up the competition? Invite players to join your teams on PlayVS. Players under 13? Invite their parents to create an account first so they can compete.',
          title: 'Invite Players or Parents',
        }
      : {
          subtitle: `You can't win a championship alone. So, hit up some friends and classmates to join your school's esports team.`,
          title: 'Invite Teammates',
        }
  }
  return {
    image: highFive2020,
    subtitle: isFaculty
      ? 'Have someone in mind who would make a great coach? Use the link below to nominate a teacher, administrator or any other school employee to coach your esports team.'
      : 'Got a favorite teacher? Now‘s your chance to make ‘em your favorite coach. Use the link below to nominate a teacher, administrator, or any other school employee to coach your esports team.',
    title: isFaculty ? 'Invite a Coach' : 'Nominate a Coach',
  }
}

export const generateReferralLinks = async ({
  schoolId,
  competitionGroup,
  source,
  isFacultyAtOrg,
  setLinks,
  generateReferralLink,
}: {
  schoolId: string
  competitionGroup?: CompetitionGroup
  source: string
  isFacultyAtOrg: boolean
  setLinks: React.Dispatch<
    React.SetStateAction<{
      referralLinkPlayer: string | undefined
      referralLinkParent: string | undefined
    }>
  >
  generateReferralLink: GenerateReferralLinkMutationFn
}): Promise<void> => {
  const playerLink = await generateReferralLink({
    variables: {
      resourceId: schoolId,
      resourceType: ResourceType.System,
      // TODO: @Thien to address in separate ticket parameterizing 'source'
      source,
      medium: 'share_link',
      competitionGroup,
      roleName: UserRoleName.User,
    },
  })

  const referralPlayer = playerLink.data?.generateReferralLink

  let referralParent
  if (isFacultyAtOrg) {
    const parentLink = await generateReferralLink({
      variables: {
        resourceId: schoolId,
        resourceType: ResourceType.System,
        // TODO: @Thien to address in separate ticket parameterizing 'source'
        source,
        medium: 'share_link',
        competitionGroup,
        roleName: UserRoleName.Parent,
      },
    })

    referralParent = parentLink?.data?.generateReferralLink
  }

  setLinks({
    referralLinkParent: referralParent,
    referralLinkPlayer: referralPlayer,
  })
}
