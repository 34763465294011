import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { SelectedTeam } from './types'

const useStyles = makeStyles((theme) => ({
  schoolAvatar: {
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  team: SelectedTeam
}

export const TeamSearchOption: React.FC<Props> = ({ team }) => {
  const classes = useStyles()

  return (
    <Box alignItems="center" display="flex">
      <NxUserAvatar
        avatarUrl={team?.school?.logoUrl}
        className={classes.schoolAvatar}
        hashId={team?.id}
        size={NxAvatarSize.XS}
      />
      <div>
        <NxTypography variant="body3">{team?.name ?? ''}</NxTypography>
        <NxTypography variant="body5">{team?.school?.name ?? ''}</NxTypography>
      </div>
    </Box>
  )
}
