import React, { useState, useCallback, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Client as ConversationsClient,
  State as ClientState,
} from '@twilio/conversations'
import { logger } from '@plvs/rally/logging'
import { useAppLocationFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import { useChatAccessTokenProviderContext } from './ChatAccessTokenProvider'
import { ChatClientContext } from './Providers.types'
import { retry } from './Providers.helpers'

export const chatClientContext = React.createContext<ChatClientContext>({
  loading: false,
  isReady: false,
})

export const useChatClientContext = (): ChatClientContext => {
  return React.useContext(chatClientContext)
}

export const ChatClientProvider: React.FC = ({ children }) => {
  const location = useAppLocationFn()
  const isCheckpoint = location === Location.Checkpoint
  const {
    namespace,
    getAccessToken,
    loading,
    removeAccessTokenOnLogout,
  } = useChatAccessTokenProviderContext()
  const { enqueueSnackbar } = useSnackbar()
  const [client, setClient] = useState<ConversationsClient>()
  const [status, setStatus] = useState<ClientState>()
  const [chatClient, setChatClient] = useState<ChatClientContext>({
    loading,
    isReady: false,
  })
  const [canRetry, setCanRetry] = useState(true)

  const setStateFn = useCallback(() => {
    setChatClient({
      client,
      status,
      loading,
      isReady: status === 'initialized',
    })
  }, [Boolean(client), status, loading])

  useEffect(() => {
    if (!loading) {
      setStateFn()
    }
  }, [Boolean(client), status, loading])

  const getTokenAndConnect = async (): Promise<boolean> => {
    await getAccessToken?.()
    const scopedAccessToken = localStorage.getItem(namespace || '') ?? ''
    const convoClient = new ConversationsClient(scopedAccessToken)
    if (convoClient) {
      logger.debug('Successfully connected to Twilio')
      setCanRetry(false)
      if (isCheckpoint) {
        enqueueSnackbar('Successfully connected to Twilio', {
          variant: 'success',
        })
      }
    }
    // Make sure to setClient to undefined if the client is undefined to not block the useEffect
    setClient(convoClient)
    return Boolean(convoClient)
  }

  const withRetry = async (): Promise<void> => {
    try {
      await retry({
        executedPromise: getTokenAndConnect,
        maxRetries: 5,
        setCanRetry,
      })
    } catch (err) {
      logger.debug(err)
      enqueueSnackbar(err)
      setClient(undefined)
    }
  }

  const setupClientAsync = useCallback(
    async ({
      scopedAccessToken,
    }: {
      scopedAccessToken: string
    }): Promise<void> => {
      try {
        const conversationsClient = new ConversationsClient(scopedAccessToken)

        conversationsClient.on('stateChanged', (state) => {
          setStatus(state)
        })

        conversationsClient.on('connectionStateChanged', async (state) => {
          if (state === 'denied' && canRetry) {
            try {
              await withRetry()
            } catch (err) {
              logger.debug(err)
              enqueueSnackbar(err)
              setClient(undefined)
            }
          }
        })

        conversationsClient.on('tokenAboutToExpire', async () => {
          try {
            await getAccessToken?.()
            setClient(undefined)
          } catch (err) {
            logger.debug(err)
            setClient(undefined)
          }
        })

        setClient(conversationsClient)
      } catch (err) {
        logger.debug(err)
        setClient(undefined)
      }
    },
    [getAccessToken, Boolean(client), loading]
  )

  useEffect(() => {
    const scopedAccessToken = localStorage.getItem(namespace || '')
    if (scopedAccessToken && !client && !loading) {
      setupClientAsync({ scopedAccessToken })
    }
  }, [Boolean(client), loading, namespace])

  const tearDownClient = (): void => {
    removeAccessTokenOnLogout?.()
    client?.shutdown()
    setClient(undefined)
  }

  return (
    <chatClientContext.Provider value={{ ...chatClient, tearDownClient }}>
      {children}
    </chatClientContext.Provider>
  )
}
