import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { UserNameInput } from '@plvs/rally/components/onboard/types'
import { OnboardTextField } from '../../../../components/form/OnboardTextfield'

export interface OnboardNameFormProps {
  errors?: DeepMap<UserNameInput, FieldError>
  register: React.Ref<HTMLInputElement>
  values?: { firstName: string; lastName: string }
}

export const OnboardUserNameForm: React.FC<OnboardNameFormProps> = ({
  errors,
  register,
  values,
}) => {
  return (
    <>
      <Box
        data-testid="OnboardNameForm_FirstName"
        display="flex"
        flexDirection="column"
        mt={-4}
        pt={2}
        width="100%"
      >
        <Box width="100%">
          <OnboardTextField
            defaultValue=""
            error={errors?.firstName}
            label="First Name"
            name="firstName"
            register={register}
            value={values?.firstName}
          />
        </Box>
        <Box data-testid="OnboardNameForm_LastName" mt={2} pt={1} width="100%%">
          <OnboardTextField
            defaultValue=""
            error={errors?.lastName}
            label="Last Name"
            name="lastName"
            register={register}
            value={values?.lastName}
          />
        </Box>
      </Box>
    </>
  )
}
