import React from 'react'
import { ResourceType, useGetSchoolTeamIdsQuery } from '@plvs/graphql'

import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'

import ScheduleWrapper from './ScheduleWrapper'

interface ScheduleContainerProps {
  path: string
}

export const ScheduleContainer = ({
  path: basePath,
}: ScheduleContainerProps): React.ReactElement => {
  const { teamIds: userRolesTeamIds, loadingTeams } = useTeamsContext()
  const { metaseason } = useSchoolLeagueInfoContext()

  const {
    loading: selectedEntityLoading,
    roles,
    selectedEntityId,
  } = useProfileContext()

  const {
    data: selectedSchoolTeamIdsData,
    loading: loadingSchoolTeams,
  } = useGetSchoolTeamIdsQuery({
    variables: {
      id: selectedEntityId,
    },
    skip: !selectedEntityId || selectedEntityLoading,
  })

  const selectedSchoolTeamIds =
    selectedSchoolTeamIdsData?.school?.teams?.map(({ id }) => id) ?? []

  const userTeamIds = new Set(
    roles
      .map((role) =>
        role.resourceType === ResourceType.Team ? role.resourceId : null
      )
      .filter((id) => id)
  )
  const selectedSchoolUserTeamIds = selectedSchoolTeamIds.filter((id) =>
    userTeamIds.has(id)
  )

  const teamIds = selectedEntityId
    ? selectedSchoolUserTeamIds
    : userRolesTeamIds

  return (
    <ScheduleWrapper
      isLoading={Boolean(
        loadingSchoolTeams || loadingTeams || selectedEntityLoading
      )}
      metaseason={metaseason}
      path={basePath}
      teamIds={teamIds}
    />
  )
}
