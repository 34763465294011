import React from 'react'
// eslint-disable-next-line
import { Logout } from '@plvs/rally/features/login/Logout'
import { PublicPage } from './PublicPage'
import { AuthView } from '../components/AuthView'

export const LogoutPage: React.FC<{
  logo?: React.ReactElement
  bannerImageSrc?: string
  logoPosition?: 'corner' | 'center'
}> = ({ logo, bannerImageSrc, logoPosition }) => (
  <PublicPage description="Logout from PlayVS" title="Logout">
    <AuthView
      bannerImageSrc={bannerImageSrc}
      Logo={logo}
      logoPosition={logoPosition}
      title="You‘re logged out!"
    >
      <Logout />
    </AuthView>
  </PublicPage>
)
