import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'

export interface StyleProps {
  subtitleUrl?: string
  subtitleColor?: string
  removeSubtitleLinkUnderline?: boolean
}

const useStyles = makeStyles({
  subtitle: ({
    subtitleUrl,
    subtitleColor,
    removeSubtitleLinkUnderline,
  }: StyleProps): CreateCSSProperties => ({
    cursor: subtitleUrl ? 'pointer' : 'default',
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    color: subtitleColor || 'inherit',
    '&:hover': {
      textDecoration:
        subtitleUrl && !removeSubtitleLinkUnderline ? 'underline' : 'none',
    },
  }),
  subtitleWithoutLink: {
    whiteSpace: 'break-spaces',
    cursor: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    color: ({ subtitleColor }: StyleProps): string =>
      subtitleColor || 'inherit',
  },
  subtitleLinkNoUnderline: {
    '&.MuiLink-root': {
      textDecoration: 'none',
    },
  },
})

export interface ClickableBoxProps {
  to: string
  className?: string
}

export interface UserClusterSubtitleProps {
  subtitleUrl?: string
  removeSubtitleLinkUnderline?: boolean
  subtitle?: string | null
  isCaptain?: boolean | null
  subtitleNode?: React.ReactNode
  subtitleColor?: string
}

const ClickableBox: React.FC<ClickableBoxProps> = ({
  to,
  children,
  className,
}) => {
  const navigate = useNavigate()
  return (
    <Box className={className} onClick={(): void => navigate(to)} width="100%">
      {children}
    </Box>
  )
}

export const UserClusterSubtitle: React.FC<UserClusterSubtitleProps> = ({
  subtitle,
  subtitleNode,
  subtitleUrl,
  removeSubtitleLinkUnderline,
  isCaptain,
  subtitleColor,
}) => {
  const classes = useStyles({
    subtitleUrl,
    subtitleColor,
    removeSubtitleLinkUnderline,
  })

  const subtitleClassName = subtitleUrl
    ? classes.subtitle
    : classes.subtitleWithoutLink
  const boxClassName = removeSubtitleLinkUnderline
    ? classes.subtitleLinkNoUnderline
    : ''

  const handleClick = subtitleUrl
    ? undefined
    : (e: React.SyntheticEvent): void => {
        e.stopPropagation()
        e.preventDefault()
      }

  const subtitleRoleSeparator = subtitle && isCaptain ? ' · ' : ''
  const subtitleRole = isCaptain ? 'Team Captain' : ''

  const renderedSubtitle = subtitle ? (
    <NxTypography
      className={subtitleClassName}
      onClick={handleClick}
      variant="body2"
    >
      {subtitle}
      {subtitleRoleSeparator}
      {subtitleRole}
    </NxTypography>
  ) : (
    subtitleNode
  )

  return (
    <>
      {subtitleUrl ? (
        <ClickableBox className={boxClassName} to={subtitleUrl}>
          {renderedSubtitle}
        </ClickableBox>
      ) : (
        renderedSubtitle
      )}
    </>
  )
}
