/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */

import { Maybe, User, UserEmail } from '@plvs/graphql'
import { getKey } from './initIntercom'
import { makeSafeFunction } from '../utils'

export const intercom = makeSafeFunction(['Intercom'])

// REFERENCE
// https://developers.intercom.com/installing-intercom/docs/basic-javascript

export const bootIntercom = ({
  emails,
  id,
  hmac,
}: Pick<User, 'id' | 'hmac'> & {
  emails: Maybe<Array<Maybe<Pick<UserEmail, 'email' | 'isSchoolEmail'>>>>
}): void => {
  intercom('boot', {
    app_id: getKey(),
    emails,
    user_id: id,
    user_hash: hmac,
  })
}

export const openIntercom = (): void => {
  // Log intercom open for non-prod envs.
  if (!window?.location?.host?.includes('.playvs.com/')) {
    // eslint-disable-next-line no-console
    console.log('Intercom: show')
  }

  if ((window as any).adblockEnabled) {
    window.open('https://help.playvs.com/en/', '_blank')
  } else {
    intercom('show')
  }
}

/**
 * Show intercom with a reference to Article number.
 *
 * Testing Notes: Since Article ID #'s are unique per environment,
 * production articles will not exist in Staging/Test environments.
 *
 * To verify, you'll want to look for the log message below, then
 * compare the ID number to intercom articles list,
 * https://app.intercom.com/a/apps/ke5hq8kf/articles/articles/list/all
 */
export const showIntercomArticle = (articleNum: number | string): void => {
  let articleId
  if (typeof articleNum === 'number') {
    articleId = articleNum
  } else {
    articleId = Number((articleNum as string).match(/^\d+/)?.[0])
  }

  if (!articleId) {
    throw new Error('Invalid Article Number or Ref')
  }

  // Log intercom open for non-prod envs.
  if (!window?.location?.host?.includes('.playvs.com/')) {
    // eslint-disable-next-line no-console
    console.log('Intercom: showArticle', articleNum)
  }

  if ((window as any).adblockEnabled) {
    window.open(`https://help.playvs.com/en/articles/${articleNum}`, '_blank')
  } else {
    intercom('showArticle', articleNum)
  }
}
