import {
  useGetResourceImagesByResourceIdsQuery,
  ResourceImage,
  ResourceType,
} from '@plvs/graphql/generated'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Resource, getResourceImage } from './ResourceImageHelpers'

interface ResourceImageProviderProps {
  resources?: Resource[]
}

interface ResourceImageContext {
  resourceImages: ResourceImage[]
  getResourceImageOfSize: (input: {
    size: NxAvatarSize
    resource: { id?: string | null; type: ResourceType }
  }) => ResourceImage | undefined
  updateResources: (input: {
    resources: { id?: string | null; type: ResourceType }[]
  }) => void
  updateResourceImages: () => void
  loading: boolean
}

// Create a context for the resource image provider
const ResourceImageProviderContext = createContext<ResourceImageContext>({
  resourceImages: [],
  getResourceImageOfSize: () => undefined,
  updateResources: () => undefined,
  updateResourceImages: () => undefined,
  loading: false,
})

// Custom hook to access the resource image provider context
export const useResourceImageProvider = (): ResourceImageContext =>
  useContext(ResourceImageProviderContext)

// ResourceImageProvider component
export const ResourceImageProvider: React.FC<ResourceImageProviderProps> = ({
  children,
  resources = [],
}) => {
  const [resourceImages, setResourceImages] = useState<ResourceImage[]>([])
  const [resourceData, setResourceData] = useState<Resource[]>(resources)
  const { refetch, loading } = useGetResourceImagesByResourceIdsQuery({
    variables: { resources: resourceData },
  })

  const updateResourceImages = (): void => {
    refetch?.().then((result) => {
      if (result.data?.getResourceImagesByResourceIds) {
        setResourceImages(result.data.getResourceImagesByResourceIds)
      }
    })
  }

  useEffect(() => {
    if (resourceData.length) {
      updateResourceImages()
    }
  }, [resourceData])

  const updateResources = (input: {
    resources: { id?: string | null; type: ResourceType }[]
  }): void => {
    const filteredResources = input.resources.filter(
      (resource) => !!resource.id
    ) as Resource[]
    if (!filteredResources.length) return
    // Combine the input resources and the resourceData array with unique values
    const combinedResources = Array.from(
      new Map(
        [...resourceData, ...filteredResources].map((item) => [
          item.id + item.type,
          item,
        ])
      ).values()
    )
    if (combinedResources.length !== resourceData.length) {
      setResourceData(combinedResources)
    }
  }

  const getResourceImageOfSize = (input: {
    size: NxAvatarSize
    resource?: { id?: string | null; type: ResourceType }
  }): ResourceImage | undefined => {
    const { size, resource } = input
    if (!resource?.id) {
      return undefined
    }
    return getResourceImage(resourceImages, resource as Resource, size)
  }

  return (
    <ResourceImageProviderContext.Provider
      value={{
        resourceImages,
        getResourceImageOfSize,
        updateResources,
        updateResourceImages,
        loading,
      }}
    >
      {children}
    </ResourceImageProviderContext.Provider>
  )
}
