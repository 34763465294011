import React, { useEffect } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { generatePath, useNavigate, useLocation } from 'react-router'
import { Route, Navigate } from 'react-router-dom'
import {
  useEsportFavicon,
  hasRoleForSomeResourceIds,
  hasRoleNameForSomeResourceIds,
  useTitle,
} from '@plvs/utils'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  CompetitionGroup,
  MatchStatus,
  PhaseFormat,
  UserRoleName,
  useGetOnMatchQueueRedirectDataQuery,
  useGetTopLevelMatchDataQuery,
} from '@plvs/graphql/generated'
import { Box } from '@material-ui/core'
import {
  useMatchLobbyRenderControllerContext,
  useMatchLobbyRenderControllerState,
} from '@plvs/respawn/renderController'
import { MatchLobbyPageProps } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import {
  normalizeMatchForHero,
  determineTeamToBeRedirected,
  shouldDetermineMatchResultsForQueue,
} from '@plvs/respawn/features/match-lobby/utils/Lobby.helpers'
import { ControlPanel } from '@plvs/respawn/features/match-lobby/ControlPanel'
import { MatchStatsProvider } from '@plvs/respawn/features/match-lobby/MatchStatsContainer'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { UserOnlineStatusProvider } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'

import { useSkipMatchAssistant } from '@plvs/respawn/features/match-assistant'
import { Path } from '@plvs/const'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { MatchRostersContainer } from '../components/matchRostersContainer/MatchRostersContainer'
import { MatchStats } from '../MatchStats'
import { ActionAndInfoSectionWrapper } from '../components/actionAndInfoSection/ActionAndInfoSectionWrapper'
import { MissionControl } from '../MissionControl'
// This will be moved to respawn in a follow up ticket.
// https://playvs.atlassian.net/browse/MATCH-7067
// Remove this.

import { LobbySkeleton } from './LobbySkeleton'
import { MatchHero } from './MatchHero'

const HOURS_FOR_MATCH_BANNER = 4

export const Lobby: React.FC<MatchLobbyPageProps> = ({ matchId }) => {
  const location = useLocation()
  const isMobile = useBreakpointSm()
  const path = location.pathname
  const navigate = useNavigate()

  const flags = useFlags()
  const ldClient = useLDClient()
  const thisEsportHasAMatchAssistant = flags.matchAssistant
  const { userRoles } = useUserIdentityFn()
  const {
    hasSkippedMatchAssistant,
    skipMatchAssistant,
  } = useSkipMatchAssistant(matchId)

  const { data, loading, refetch } = useGetTopLevelMatchDataQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const refetchTopLevelMatchData = async (): Promise<void> => {
    await refetch()
  }
  const heroMatch = React.useMemo(() => normalizeMatchForHero(data?.match), [
    data?.match,
  ])
  const esportSlug = data?.match?.esport?.slug ?? null
  useEsportFavicon({
    esportSlug,
  })
  const esportRating = data?.match?.esport?.rating
  const competitionGroup = data?.match?.team1?.competitionGroup
  // Non scholastic product does not currently support queues.
  // Once the queue system is implemented for stadium, this logic will move
  // to the backend.
  const isNotSmartScheduledMatchScholastic =
    data?.match?.slot?.phase?.format !== PhaseFormat.SmartSchedule ||
    competitionGroup === CompetitionGroup.Stadium
  const {
    data: queueRedirectData,
    loading: queueRedirectLoading,
  } = useGetOnMatchQueueRedirectDataQuery({
    variables: {
      matchId,
    },
    skip: isNotSmartScheduledMatchScholastic,
  })
  const { team1, team2, status } = heroMatch

  const { hasApiIntegration, NexusIcon } = useGeneralEsportAdapter(esportSlug)
  const { lobby, chat, match } = useMatchLobbyRenderControllerContext()
  const {
    setMatchLobbyRenderControllerStateFn,
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const isMatchAssistantEnabled = Boolean(
    thisEsportHasAMatchAssistant &&
      data?.match?.matchAssistantEnabled &&
      !hasSkippedMatchAssistant[matchId]
  )
  const sharedRenderControllerProps = {
    userRoles,
    team1,
    team2,
    competitionGroup,
    isMatchAssistantEnabled,
    status,
  }
  const lobbyComponents = lobby.getLobbyComponentsToRender(
    sharedRenderControllerProps
  )

  const matchActions = match.getMatchAssistantActions(
    sharedRenderControllerProps
  )
  const matchComponents = match.getMatchLobbyComponentsToRender({
    ...sharedRenderControllerProps,
    isOnMissionControl: location.pathname.includes('mission-control'),
  })

  const matchTeamsDisplayDetails = lobby.getHomeAndAwayTeamDisplayDetails({
    competitionGroup,
    team1,
    team2,
    esportRating,
  })

  const chatRender = chat.getChatComponentsToRender({
    userRoles,
    competitionGroup,
    team1: {
      id: heroMatch.team1.id,
      schoolId: data?.match?.team2?.school?.id,
    },
    team2: {
      id: heroMatch.team2.id,
      schoolId: data?.match?.team2?.school?.id,
    },
  })

  const now = Date.now()
  const timeSinceStart =
    (now - new Date(data?.match?.scheduledStartsAt ?? '').getTime()) /
    (60 * 60 * 1000) // time diff in hours

  // Consolidate this into the render controller and the backend.
  // Consolidate showMatchQueueBanner, canManageQueueBannerActions, canQueue
  // and solidify pattern of logic.
  // https://playvs.atlassian.net/browse/MATCH-7141
  const showMatchQueueBanner =
    (competitionGroup !== CompetitionGroup.Stadium &&
      data?.match?.status === MatchStatus.Completed) ||
    (data?.match?.status === MatchStatus.Forfeited &&
      hasRoleForSomeResourceIds(userRoles, [team1.schoolId, team2.schoolId]) &&
      timeSinceStart < HOURS_FOR_MATCH_BANNER)

  // This logic doesn't work if the user is apart of both teams, ex.
  // the user is a coach at the school and the teams are from the same school.
  // TODO: consolidate myTeam and myTeamIds defined here and in the render controller.
  // https://playvs.atlassian.net/browse/MATCH-7138
  const myTeamId = data?.match?.team1?.isMyTeam ? team1.id : team2.id
  const teamId = myTeamId ?? team1.id

  // In the format `My Team vs Opp Team` or `Home Team vs Away Team` if the user
  // is not associated with either team.
  const title = hasRoleForSomeResourceIds(userRoles, [
    team1.id,
    team2.id,
    team1.schoolId,
    team2.schoolId,
  ])
    ? `(Match) ${teamId === team1.id ? team1.name : team2.name} vs. ${
        teamId === team1.id ? team2.name : team1.name
      }`
    : `(Match) ${team1.name} vs ${team2.name}`
  useTitle(title)

  useEffect(() => {
    if (loading) {
      return
    }
    const currentState = getMatchLobbyRenderControllerState()
    setMatchLobbyRenderControllerStateFn({
      ...currentState,
      chat: {
        ...currentState.chat,
        ...chatRender,
      },
      match: {
        ...currentState.match,
        ...matchActions,
        ...matchComponents,
      },
      lobby: {
        ...currentState.lobby,
        ...lobbyComponents,
        ...matchTeamsDisplayDetails,
      },
    })
  }, [
    loading,
    competitionGroup,
    team1,
    team2,
    isMatchAssistantEnabled,
    esportRating,
    userRoles,
    status,
    hasSkippedMatchAssistant[matchId],
    location.pathname,
  ])

  useEffect(
    function identifyLaunchDarkly() {
      if (!esportSlug) {
        return
      }

      ldClient?.identify({ kind: 'esport', key: esportSlug })
    },
    [ldClient, esportSlug]
  )

  // The below logic tracks https://playvs.atlassian.net/browse/MATCH-6996
  // This useEffect will either be completely deleted or
  // greatly reduced in logic once this business logic moves to the backend.
  useEffect(() => {
    const canEnterTheQueue = hasRoleNameForSomeResourceIds(
      userRoles,
      UserRoleName.Coach,
      [team1.id, team2.id, team1.schoolId, team2.schoolId]
    )
    const canMoveForwardWithQueueRedirect = shouldDetermineMatchResultsForQueue(
      {
        status,
        hasQueueData: Boolean(queueRedirectData),
        canEnterTheQueue,
      }
    )
    if (canMoveForwardWithQueueRedirect) {
      const { teamToBeRedirectedId, canRedirect } = determineTeamToBeRedirected(
        {
          queueRedirectData,
          teamId,
          team1Id: team1.id,
          team2Id: team2.id,
        }
      )

      if (canRedirect) {
        const queueLobbyPath = generatePath(Path.QueueLobby, {
          slotId: data?.match?.slot?.id ?? '',
          teamId: teamToBeRedirectedId,
        })
        navigate(queueLobbyPath)
      }
    }
  }, [Boolean(queueRedirectData), queueRedirectLoading, status, teamId])

  if (loading) {
    return <LobbySkeleton />
  }

  return (
    <Esport slug={esportSlug}>
      {isMobile && lobbyComponents.showActionAndInfoSection && (
        <ActionAndInfoSectionWrapper
          competitionGroup={competitionGroup}
          esportSlug={esportSlug}
          matchId={matchId}
        />
      )}
      <MatchHero
        esportSlug={esportSlug}
        isScrimmage={Boolean(data?.match?.isScrimmage)}
        match={heroMatch}
      />
      <MatchStatsProvider
        hasApiIntegration={hasApiIntegration}
        matchId={matchId}
      >
        <ControlPanel
          ActionAndInfoSectionComponent={ActionAndInfoSectionWrapper}
          competitionGroup={competitionGroup}
          esportSlug={esportSlug}
          matchId={matchId}
          path={path}
          // The below logic tracks https://playvs.atlassian.net/browse/MATCH-6996
          showMatchQueueBanner={showMatchQueueBanner}
        />

        <Box mb={6} mx={2}>
          <UserOnlineStatusProvider>
            <ApmRoutes>
              <Route
                element={
                  <MissionControl
                    components={data?.match?.league?.sponsorshipComponents}
                    esportSlug={esportSlug ?? null}
                    matchId={matchId}
                    refetchTopLevelMatchData={refetchTopLevelMatchData}
                    skipMatchAssistant={skipMatchAssistant}
                    team1={team1}
                    team2={team2}
                    teamId={teamId}
                  />
                }
                path="mission-control"
              />

              <Route
                element={
                  <MatchRostersContainer
                    esportSlug={esportSlug}
                    matchId={matchId}
                  />
                }
                path="teams"
              />

              <Route
                element={
                  <MatchStats
                    esportLogo={NexusIcon}
                    matchId={matchId}
                    team1Id={team1.id}
                    team1Name={team1.name}
                    team2Id={team2.id}
                    team2Name={team2.name}
                  />
                }
                path="match-stats"
              />
              <Route
                element={<Navigate to={`${path}/mission-control`} />}
                path="/"
              />
            </ApmRoutes>
            {esportSlug && <EsrbRating esportSlug={esportSlug} mt={5} />}
          </UserOnlineStatusProvider>
        </Box>
      </MatchStatsProvider>
    </Esport>
  )
}
