import React from 'react'
import { AppPage, PublicPage } from '@plvs/rally/pages/page'
import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { useLocation } from 'react-router-dom'
import { Box, FullHeightBox, Hidden } from '@plvs/respawn/features/layout'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import { ExploreLeagues } from '@plvs/rally/features/explore/ExploreLeagues'

export const CompetitionsPage: React.FC = () => {
  const location = useLocation()

  const isPublic = !location.pathname.includes('/app')

  return (
    <>
      {isPublic ? (
        <PublicPage description="Public Leage Page" title="Competitions">
          <Box display="flex" justifyContent="center">
            <Hidden smDown>
              <RallyAppDrawer isPublic />
            </Hidden>
            <FullHeightBox maxWidth="1042px">
              <MySchoolLeagues>
                <Box
                  display="flex"
                  height="100%"
                  style={{ margin: 'auto' }}
                  width="100%"
                >
                  Not Implemented yet
                </Box>
              </MySchoolLeagues>
            </FullHeightBox>
          </Box>
        </PublicPage>
      ) : (
        <AppPage title="Competitions">
          <ExploreLeagues />
        </AppPage>
      )}
    </>
  )
}
