import { Notifications } from '@playvs-inc/nexus-icons'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  refetchGetNotificationCenterQuery,
  useGetNotificationCenterSummaryQuery,
} from '@plvs/graphql/generated'
import * as analytics from '@plvs/respawn/features/analytics'
import { mutationEventTarget } from '@plvs/rally/libs/events/mutation-events/MutationEventTarget'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { IconToggle, IconDescription } from '../IconToggle'
import { useAnimationStyles } from './animationStyles'

// How long between user last fetched before we
// attempt to fetch the unread count again when
// a user navigates to a different page within
// our app.
const REFETCH_TIMING_THRESHOLD_SECONDS = 10

// How often we poll to update the unread count.
const POLLING_INTERVAL_MS = 10 * 60 * 1000

// A delay we add before issuing the requery after a mutation
// has been fired.  This is to allow time for the mutation
// to complete before we update the taskbar.  This is because
// tasks and notifications are process asynchronously on the
// backend after the mutation has completed.
const ON_MUTATION_REQUERY_DELAY_MS = 3 * 1000

type NotificationDrawerComponent = React.FC<{
  close?: () => void
  uncompletedTaskCount?: number | null | undefined
}>

interface AppDrawerNotificationsToggleProps {
  NotificationDrawer: NotificationDrawerComponent
  isInAppBar?: boolean
}

export const AppDrawerNotificationsToggle: React.FC<AppDrawerNotificationsToggleProps> = ({
  NotificationDrawer,
  isInAppBar,
}) => {
  const { userId } = useUserIdentityFn()
  const location = useLocation()
  const classes = useAnimationStyles()

  const lastFetchDateRef = useRef(dayjs())

  const [isOpen, setIsOpen] = React.useState(false)

  const {
    data: notificationSummaryData,
    loading,
    refetch,
  } = useGetNotificationCenterSummaryQuery({
    variables: {
      skipTasks: false,
    },
    pollInterval: POLLING_INTERVAL_MS,
    onCompleted: (data) => {
      // If we receive a positive change in unread count while the
      // notification drawer is open, fetch the latest notifications.
      if (isOpen && data.userNotifications.unreadCount > 0) {
        refetchGetNotificationCenterQuery({
          // Limit our fetch to notifications that are unread.
          // The +1 is there so we can get the cursor of previous
          // result so the automatic merge() function will correctly
          // merge in any past results.
          first: data.userNotifications.unreadCount + 1,
        })
      }
    },
  })

  const unreadCount: number | null | undefined =
    notificationSummaryData?.userNotifications.unreadCount
  const uncompletedTaskCount: number | null | undefined =
    notificationSummaryData?.getIncompleteNotificationTaskCount

  const open = (): void => {
    // Clear pending badge for notifications by calling mutation to set updated at
    analytics.userNotificationsDrawerOpened({
      userId: userId ?? '',
      timeStamp: new Date().toDateString(),
      notificationsCleared: unreadCount ?? 0,
    })
    setIsOpen(!isOpen)
  }

  const close = (): void => {
    analytics.userNotificationsDrawerClosed({
      userId: userId ?? '',
      timeStamp: new Date().toDateString(),
    })
    setIsOpen(false)
  }

  // Subscribe to mutations and requery for new notifictions and task
  // updates everytime we perform a mutation.
  useEffect(() => {
    const unsubscribeHandler = mutationEventTarget.subscribe(() => {
      setTimeout(async () => {
        const oldCounts = {
          unreadCount,
          uncompletedTaskCount,
        }
        const result = await refetch?.({
          skipTasks: false,
        })

        // If our counts don't update, do a 2nd refetch later just in case
        // the server is running slower due to processing extra concurrent
        // requests from other users.
        if (
          result &&
          result?.data?.getIncompleteNotificationTaskCount ===
            oldCounts?.uncompletedTaskCount &&
          result?.data?.userNotifications?.unreadCount ===
            oldCounts?.unreadCount
        ) {
          setTimeout(() => {
            refetch?.({
              skipTasks: false,
            })
          }, ON_MUTATION_REQUERY_DELAY_MS)
        }
      }, ON_MUTATION_REQUERY_DELAY_MS)
    })

    return (): void => {
      unsubscribeHandler()
    }
  })

  // When user navigates to another page, refresh the notification count.
  useEffect(() => {
    if (
      !loading &&
      // Use a Ref to track our last refetch to avoid over refetching.
      dayjs().diff(lastFetchDateRef.current, 'seconds') >
        REFETCH_TIMING_THRESHOLD_SECONDS
    ) {
      refetch()
      lastFetchDateRef.current = dayjs()
    }
  }, [location.pathname, loading])

  let badgeCount = 0
  let isDotDisplayed = false

  if (unreadCount && unreadCount > 0) {
    badgeCount = unreadCount
  } else if (uncompletedTaskCount && uncompletedTaskCount > 0) {
    // Need to still update number or dot will not show.
    badgeCount = uncompletedTaskCount
    isDotDisplayed = true
  }

  const hasBadgeCount = badgeCount > 0

  return (
    <IconToggle
      badgeClassName={hasBadgeCount ? classes.badgePopup : undefined}
      badgeCount={badgeCount}
      childDrawer={
        <NotificationDrawer
          close={close}
          uncompletedTaskCount={uncompletedTaskCount}
        />
      }
      Icon={Notifications}
      iconClassName={hasBadgeCount ? classes.rotateIcon : undefined}
      iconDescription={IconDescription.Notifications}
      isInAppBar={isInAppBar}
      isOpen={isOpen}
      isRedDotDisplayed={isDotDisplayed}
      onClose={close}
      onOpen={open}
    />
  )
}
