import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { QuestionOutlined } from '@playvs-inc/nexus-icons'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'

import { IntercomArticleMappings } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0, 0, 0, 0.5),
  },
  learnMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const PLAY_VS_SCORE_TOOLTIP =
  'PlayVS Score is based on when a team wins their matches and the strength of their schedule.'

export const PlayVsScoreHeader: React.FC = () => {
  const classes = useStyles()

  return (
    <Box alignItems="center" display="flex">
      <NxTypography variant="subtitle1"> PlayVS Score</NxTypography>
      <NxTooltip
        arrow
        interactive
        leaveDelay={2000}
        placement="top-start"
        title={
          <>
            {PLAY_VS_SCORE_TOOLTIP}{' '}
            <NxTypography
              className={classes.learnMore}
              onClick={(): void =>
                showIntercomArticle(IntercomArticleMappings.playVsScore)
              }
              variant="body5"
            >
              Learn More
            </NxTypography>
          </>
        }
      >
        <IconButton className={classes.iconButton}>
          <QuestionOutlined />
        </IconButton>
      </NxTooltip>
    </Box>
  )
}
