import React from 'react'
import { FormControl, Select, InputLabel, useTheme } from '@material-ui/core'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'

import { equals, isNil } from 'ramda'
import {
  DateOption,
  TimeOption,
} from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'

export const RescheduleProposedTimesStep: React.FC<{
  availableDates: DateOption[]
  proposedDateIndices: number[]
  availableTimes: TimeOption[]
  proposedTimeIndices: number[]
  onDayChange(e: any): void
  onTimeChange(e: any): void
  isSameSchool?: boolean
  onSubmit(e: React.FormEvent): void
  onPrev(): void
  isSubmitting: boolean
}> = ({
  onDayChange,
  onTimeChange,
  availableDates,
  proposedDateIndices,
  availableTimes,
  proposedTimeIndices,
  isSameSchool = false,
  onSubmit,
  onPrev,
  isSubmitting,
}) => {
  const proposedPairingsMap: Record<number, boolean> = {}
  const theme = useTheme()
  const dayOptions = availableDates.map(
    (dayOption: DateOption, index: number) => (
      <option key={dayOption.formattedDate} value={index}>
        {dayOption.formattedDate}
      </option>
    )
  )

  const timeOptions = availableTimes.map(
    (timeOption: TimeOption, index: number) => (
      <option key={timeOption.formattedTime} value={index}>
        {timeOption.formattedTime}
      </option>
    )
  )

  const optionsArray = isSameSchool ? [0] : [0, 1, 2]

  const proposedOptionsIndices = [
    [proposedDateIndices[0], proposedTimeIndices[0]],
    [proposedDateIndices[1], proposedTimeIndices[1]],
    [proposedDateIndices[2], proposedTimeIndices[2]],
  ]

  proposedOptionsIndices.reduce((a, b, index): number[] => {
    const isMatchingPair = equals(a, b)
    if (!proposedPairingsMap[index]) {
      proposedPairingsMap[index] = isMatchingPair
    }
    return b
  })

  const hasChosenSameDateAndTimeOptions = Object.values(
    proposedPairingsMap
  ).some((value) => Boolean(value))

  const submitDisabled =
    (isNil(proposedDateIndices[2]) && !isNil(proposedTimeIndices[2])) ||
    (!isNil(proposedDateIndices[2]) && isNil(proposedTimeIndices[2])) ||
    hasChosenSameDateAndTimeOptions

  const renderOptions = optionsArray.map((index) => {
    return (
      <Box key={index}>
        {!isSameSchool && (
          <Box alignItems="center" display="flex">
            <NxTypography variant="body2">Option {index + 1}</NxTypography>
            {index + 1 > 2 && (
              <Box pl={0.5}>
                <NxTypography variant="body1">(optional)</NxTypography>
              </Box>
            )}
          </Box>
        )}

        <Box display="flex" mb={2} mt={1}>
          <Box mr={-5} width="100%">
            <FormControl variant="filled">
              <InputLabel htmlFor={`date${index}`}>Date</InputLabel>
              <Select
                fullWidth
                inputProps={{
                  name: `date${index}`,
                  id: `date${index}`,
                  'data-id': index,
                }}
                label="Date"
                native
                onChange={onDayChange}
                value={proposedDateIndices[index] ?? ''}
              >
                {index > 1 && <option>&nbsp;</option>}
                {dayOptions}
              </Select>
            </FormControl>
          </Box>
          <Box width="100%">
            <FormControl variant="filled">
              <InputLabel htmlFor={`time${index}`}>Time</InputLabel>
              <Select
                fullWidth
                inputProps={{
                  name: `time${index}`,
                  id: `time${index}`,
                  'data-id': index,
                }}
                label="Time"
                native
                onChange={onTimeChange}
                value={proposedTimeIndices[index] ?? ''}
              >
                {index > 1 && <option>&nbsp;</option>}
                {timeOptions}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    )
  })

  return (
    <form noValidate onSubmit={onSubmit}>
      {renderOptions}
      <Box
        display="flex"
        gridGap={theme.spacing(2)}
        justifyContent="flex-end"
        pt={2}
      >
        {!isSameSchool && (
          <NxButton label="Back" onClick={onPrev} variant="text" />
        )}
        <NxButton
          disabled={submitDisabled || isSubmitting}
          label={isSameSchool ? 'Next' : 'Send Request'}
          onSubmit={onSubmit}
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}
