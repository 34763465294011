import { makeVar, ReactiveVar } from '@apollo/client'

export enum ProductType {
  Scholastic = 'scholastic',
  Stadium = 'stadium',
}

export const productTypeMapToDisplayString: Record<ProductType, string> = {
  [ProductType.Scholastic]: 'Scholastic',
  [ProductType.Stadium]: 'Stadium',
}

export type ProductTypeVar = {
  productType: ProductType
  nameRequestUrl: string
}

export const useProductTypeVar: ReactiveVar<ProductType> = makeVar<ProductType>(
  ProductType.Scholastic
)
