import React, { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { withCookies } from 'react-cookie'
import { rallyEnv } from '@plvs/rally/env'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Param } from '@plvs/const'
import { cleanGraphQLError, CookieProps, useSafeQuery } from '@plvs/utils'
import { Link } from '@material-ui/core'
import {
  useUnlinkUserProviderAccountMutation,
  EsportSlug,
  Platform,
  Provider,
  useGetEsportBySlugQuery,
  useGetUserEsportPlatformsQuery,
  useSetUserEsportPlatformMutation,
  useRemoveUserEsportPlatformMutation,
  useGetPlayerLinkedAccountPlatformsQuery,
  refetchGetUserProviderAccountsQuery,
} from '@plvs/graphql'
import { useDebouncedCallback } from 'use-debounce'

import { LoadingSpinner } from '@playvs-inc/nexus-components'
import {
  RouteComponentProps,
  withRouter,
} from '@plvs/respawn/features/route/WithRouter'
import ConnectionCard from '../ConnectionCard'
import { AccountProviderComponentProps, createConnectAccount } from '../utils'
import { EpicGamesEditComponent } from '../EpicGamesEditComponent'

interface EpicGamesConnectionProps
  extends AccountProviderComponentProps,
    RouteComponentProps {}

const EpicGamesConnection: React.FC<EpicGamesConnectionProps & CookieProps> = ({
  providerDetails,
  apolloError,
  cookies,
  checkpoint,
  userId,
  refetchUserProviderAccounts,
  isParent,
}) => {
  // Data
  const providerId = providerDetails?.id ?? ''
  const epicAccountId = providerDetails?.providerAccountId ?? ''
  const providerDisplayName = providerDetails?.providerDisplayName ?? ''
  const lastUpdated =
    providerDetails?.updatedAt ?? providerDetails?.createdAt ?? ''

  // Query Params State
  const [success] = useQueryParam(Param.Success)
  const [authError] = useQueryParam(Param.AuthError)
  const [provider] = useQueryParam(Param.Provider)

  const isEpicProvider = provider === Provider.EpicGames

  // Errors State
  const [error, setError] = useState<Error | ApolloError | undefined>()

  // Platform State
  const [rocketLeagueId, setRocketLeagueId] = useState<string>('')
  const [userPlatform, setUserPlatform] = useState<Platform | '' | 'unknown'>(
    'unknown'
  )
  const [platformRefreshLoading, setPlatformRefreshLoading] = useState(false)

  // User State
  const [epicDisplayName, setEpicDisplayName] = useState<string>(
    providerDisplayName
  )
  const [isConnected, setIsConnected] = useState(!!epicAccountId)

  // Queries
  const { data: esportData } = useGetEsportBySlugQuery({
    variables: { slug: EsportSlug.RocketLeague },
  })
  const {
    data: platformData,
    loading: loadingPlatform,
    refetchWithSameVariables: refetchGetUserEsportPlatforms,
  } = useSafeQuery(useGetUserEsportPlatformsQuery, {
    variables: { userId },
    skip: !userId,
  })

  const {
    data: getPlayerLinkedAccountPlatformData,
    loading: getPlayerLinkedAccountPlatformLoading,
    refetchWithSameVariables: refetchGetPlayerLinkedAccountPlatforms,
  } = useSafeQuery(useGetPlayerLinkedAccountPlatformsQuery, {
    variables: {
      epicAccountId: `Epic|${epicAccountId}`,
    },
    skip: !epicAccountId,
  })

  // Mutations
  const [remove] = useUnlinkUserProviderAccountMutation({
    onError: (err): void => setError(err),
    refetchQueries: [refetchGetUserProviderAccountsQuery()],
  })
  const [setUserEsportPlatform] = useSetUserEsportPlatformMutation({
    onError: (err): void => setError(err),
  })
  const [removeUserEsportPlatform] = useRemoveUserEsportPlatformMutation({
    onError: (err): void => setError(err),
  })

  // Effects
  useEffect(() => {
    setRocketLeagueId(esportData?.esport?.id || '')
  }, [esportData])

  useEffect(() => {
    if (providerDisplayName) {
      setEpicDisplayName(providerDisplayName)
    }
    if (epicAccountId) {
      setIsConnected(true)
    }
  }, [providerDetails])

  useEffect(() => {
    if (rocketLeagueId && platformData) {
      setUserPlatform(
        platformData?.userEsportPlatforms?.find(
          (esportPlatform) => esportPlatform.esportId === rocketLeagueId
        )?.platform || ''
      )
    }
  }, [platformData, rocketLeagueId])

  useEffect(() => {
    if (apolloError) {
      setError(apolloError)
    }
  }, [apolloError])

  useEffect(() => {
    if (success === 'false' && authError) {
      setError(new Error(authError))
    }
    if (success === 'false' && !authError) {
      setError(
        new Error(
          'Account could not be successfully connected, please try again or connect a different account.'
        )
      )
    }
  }, [authError, success])

  // Child Props
  const updatePlatform = async (selectedPlatform: Platform): Promise<void> => {
    setUserPlatform(selectedPlatform)

    try {
      if (userId) {
        setUserEsportPlatform({
          variables: {
            input: {
              userId,
              platform: selectedPlatform,
              platformUsername:
                getPlayerLinkedAccountPlatformData?.getPlayerLinkedAccountPlatforms?.playerLinkedAccounts?.find(
                  (esportPlatform) =>
                    esportPlatform?.platform === selectedPlatform &&
                    esportPlatform?.username
                )?.username ?? '',
              esportId: rocketLeagueId,
            },
          },
        }).then(() => {
          refetchGetUserEsportPlatforms()
        })
      }
    } catch (setUserEsportPlatformError: any) {
      setError(setUserEsportPlatformError)
    }
  }

  const disconnectAccount = async (): Promise<void> => {
    setError(undefined)
    setIsConnected(false)

    await remove({
      variables: {
        providerId,
      },
    })

    if (userId && rocketLeagueId) {
      await removeUserEsportPlatform({
        variables: {
          userId,
          esportId: rocketLeagueId,
        },
      })
    }

    await refetchUserProviderAccounts()
  }

  const connectAccount = createConnectAccount(
    rallyEnv.EPICGAMES_AUTH_URI || 'unknown',
    cookies,
    setError
  )

  const debounceRefresh = useDebouncedCallback(async () => {
    const result = await refetchGetPlayerLinkedAccountPlatforms()
    if (result) {
      setPlatformRefreshLoading(result.loading)
    }
  }, 1000)

  const onRefreshClick = (): void => {
    setError(undefined)
    setPlatformRefreshLoading(true)

    debounceRefresh.callback()
  }

  const renderError = (): string => {
    if (isEpicProvider && error?.message) {
      return cleanGraphQLError(error.message)
    }
    return ''
  }

  const loadingPlatformSelect =
    loadingPlatform || getPlayerLinkedAccountPlatformLoading

  let EditComponent: React.ReactNode = <LoadingSpinner size="medium" />
  if (!isConnected) {
    EditComponent = <></>
  } else if (
    isConnected &&
    !loadingPlatformSelect &&
    !getPlayerLinkedAccountPlatformLoading &&
    userPlatform !== 'unknown'
  ) {
    EditComponent = (
      <EpicGamesEditComponent
        connectedPlatforms={
          getPlayerLinkedAccountPlatformData?.getPlayerLinkedAccountPlatforms
            ?.playerLinkedAccounts || []
        }
        onRefreshClick={onRefreshClick}
        platformRefreshLoading={platformRefreshLoading}
        updatePlatform={updatePlatform}
        userPlatform={userPlatform}
      />
    )
  }

  return (
    <ConnectionCard
      buttonMessage="Disconnect"
      checkpoint={checkpoint}
      connectedText={epicDisplayName ? `Connected to ${epicDisplayName}` : ''}
      disabledText=""
      EditComponent={EditComponent}
      errorText={renderError()}
      isConnected={isConnected}
      isParent={isParent}
      lastUpdated={lastUpdated}
      onConnectClick={connectAccount}
      onUpdateClick={disconnectAccount}
      providerName="EpicGames"
      subtitle={
        <>
          Connect your Epic Games account to participate in Rocket League
          matches.
          {isConnected && (
            <>
              <br />
              Rocket League players and spectators must activate a platform at{' '}
              <Link
                href="https://www.rocketleague.com/activate/"
                target="_blank"
              >
                rocketleague.com/activate/
              </Link>
              .
            </>
          )}
        </>
      }
      title="Epic Games"
      userId={userId}
    />
  )
}
export default withRouter(withCookies(EpicGamesConnection))
