import { ResourceImage, ResourceType, Team } from '@plvs/graphql/generated'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'

export interface Resource {
  id: string
  type: ResourceType
}

export function getResourceImage(
  resourceImages: ResourceImage[],
  resource: Resource,
  size: number
): ResourceImage | undefined {
  if (resourceImages.length === 0) {
    return undefined
  }
  const filteredImages = resourceImages
    .filter(
      (image) =>
        ((image.size && image.size >= size) || !image.size) &&
        image.resourceId === resource.id &&
        image.resourceType === resource.type
    )

    .sort((a, b) => {
      if (a.size === null && b.size !== null) {
        return 1
      }
      if (a.size !== null && b.size === null) {
        return -1
      }
      if (a.size === null && b.size === null) {
        return 0
      }
      return a.size! - b.size!
    })
  return filteredImages[0]
}

export function getTeamImageOfSize(input: {
  team?: Pick<Team, 'id' | 'schoolId'> | null
  size: NxAvatarSize
  getResourceImageOfSize: (input: {
    size: NxAvatarSize
    resource: { id?: string; type: ResourceType }
  }) => ResourceImage | undefined
}): ResourceImage | undefined {
  const { team, size, getResourceImageOfSize } = input
  if (!team) {
    return undefined
  }
  const { id: teamId, schoolId } = team
  const resource = schoolId
    ? { id: schoolId, type: ResourceType.Organization }
    : { id: teamId, type: ResourceType.Team }

  return getResourceImageOfSize({ size, resource })
}
