import { yupResolver } from '@hookform/resolvers'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import {
  refetchGetMySchoolDetailsQuery,
  useGetMySchoolDetailsQuery,
  useUpdateSchoolDetailMutation,
} from '@plvs/graphql/generated/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import { useWithSinglePendingRequest } from '@plvs/rally/libs/request-utils/useWithSinglePendingRequest'
import { assert, recordDiffRight } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { equals, pick } from 'ramda'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  NxTypography,
  NxSelect,
  NxTextInput,
  NxSelectOption,
  NxCheckbox,
} from '@playvs-inc/nexus-components'

interface ItSchoolComputerSurveyFormInputs {
  hasDedicatedLab: boolean
  numStudentComps: string
  publicIP: string
}

const ItSchoolComputerSurveySchema = yup.object({
  hasDedicatedLab: yup.boolean(),
  numStudentComps: yup.string(),
  publicIP: yup.string(),
})

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(3, 3, 1.5)} !important`,
  },
  checkbox: {
    marginLeft: theme.spacing(-1.25),
  },
  checkboxLabel: {
    cursor: 'pointer',
  },
}))

export const ItSchoolComputerSurveyFormPanel: FunctionComponent = (): React.ReactElement => {
  const classes = useStyles()
  // Hooks
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const withSaveNotification = useWithSaveNotification()
  const withSinglePendingRequest = useWithSinglePendingRequest()
  const formRef = useRef<HTMLFormElement>(null)

  // Queries
  const { data } = useGetMySchoolDetailsQuery()

  // Computed props
  const itDetails = data?.me?.school?.detail?.it

  const hasDedicatedLab = itDetails?.hasDedicatedLab ?? false
  const numStudentComps = itDetails?.numStudentComps ?? ''
  const publicIP = itDetails?.publicIP ?? ''

  // State
  // This is used as a mirror of hasDedicatedLab form field to trigger the
  // side effect to trigger the form onBlur handler when checkbox is checked.
  const [currentHasDedicatedLab, setCurrentHasDedicatedLab] = useState<boolean>(
    hasDedicatedLab
  )

  // Mutations
  const [mutateSchoolDetails] = useUpdateSchoolDetailMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  // Side Effects
  // trigger form submit when hasDedicatedLab changes.
  useEffect(() => {
    if (currentHasDedicatedLab !== hasDedicatedLab) {
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    }
  }, [currentHasDedicatedLab, hasDedicatedLab, formRef])

  // Form handlers
  const { errors, handleSubmit, register, getValues, setError } = useForm<
    ItSchoolComputerSurveyFormInputs
  >({
    defaultValues: {
      hasDedicatedLab,
      numStudentComps,
      publicIP,
    },
    resolver: yupResolver<ItSchoolComputerSurveyFormInputs>(
      ItSchoolComputerSurveySchema
    ),
  })

  const onBlur = useCallback(async (): Promise<void> => {
    const values = getValues()

    values.hasDedicatedLab = currentHasDedicatedLab
    const oldValues = pick(
      ['hasDedicatedLab', 'numStudentComps', 'publicIP'],
      data?.me?.school?.detail?.it ?? {}
    )

    const hasChanges = !equals(oldValues, values)

    if (!hasChanges) {
      return
    }

    try {
      await ItSchoolComputerSurveySchema.validate(values)
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const firstError = err.errors[0]
        enqueueSnackbar(firstError, {
          variant: 'error',
          transitionDuration: {
            appear: 5000,
          },
        })

        setError(err.path as keyof ItSchoolComputerSurveyFormInputs, {
          message: firstError,
          type: err.type,
          shouldFocus: true,
        })
        return
      }
      throw err // otherwise throw and let sentry error capture
    }

    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }, [data, setError, enqueueSnackbar, getValues])

  const onSubmit = useCallback(
    handleSubmit(
      async (values: ItSchoolComputerSurveyFormInputs): Promise<void> => {
        const oldValues = pick(
          ['hasDedicatedLab', 'numStudentComps', 'publicIP'],
          data?.me?.school?.detail?.it ?? {}
        )

        const changedValues = recordDiffRight(
          oldValues,
          (values as unknown) as Record<string, unknown>
        )

        // Because 'hasDedicatedLab' is handled separately from the main
        // form, we need to patch the diffed value back in.
        if (currentHasDedicatedLab !== hasDedicatedLab) {
          changedValues.hasDedicatedLab = currentHasDedicatedLab
        }

        // Check to see if any props have changed.
        if (
          Object.entries(changedValues).filter((x) => x[1] !== undefined)
            .length < 1
        ) {
          return
        }

        withSinglePendingRequest(async () => {
          withSaveNotification(
            async (): Promise<void> => {
              const schoolId = data?.me?.school?.id
              assert(schoolId)

              await mutateSchoolDetails({
                variables: {
                  schoolId,
                  detail: {
                    it: {
                      ...changedValues,
                    },
                  },
                },
              })
            }
          )
        })
      }
    ),
    [
      enqueueSnackbar,
      closeSnackbar,
      currentHasDedicatedLab,
      data,
      hasDedicatedLab,
    ]
  )

  return (
    <form ref={formRef} noValidate onBlur={onBlur} onSubmit={onSubmit}>
      <Card>
        <CardContent className={classes.container}>
          <Box mb={2}>
            <NxTypography variant="h4">School Computers</NxTypography>
          </Box>
          <NxTypography variant="body2">
            Does your school have a dedicated computer lab?
          </NxTypography>
          <Box display="flex" flexDirection="column">
            <Box alignItems="center" display="flex">
              <NxCheckbox
                checked={currentHasDedicatedLab}
                className={classes.checkbox}
                data-cy="has-lab-yes-checkbox"
                onChange={(): void => setCurrentHasDedicatedLab(true)}
              />
              <NxTypography
                className={classes.checkboxLabel}
                onClick={(): void => setCurrentHasDedicatedLab(true)}
                variant="body1"
              >
                Yes, we do
              </NxTypography>
            </Box>
            <Box alignItems="center" display="flex">
              <NxCheckbox
                checked={!currentHasDedicatedLab}
                className={classes.checkbox}
                data-cy="has-lab-no-checkbox"
                onChange={(): void => setCurrentHasDedicatedLab(false)}
              />
              <NxTypography
                className={classes.checkboxLabel}
                onClick={(): void => setCurrentHasDedicatedLab(false)}
                variant="body1"
              >
                No, we do not
              </NxTypography>
            </Box>
          </Box>
          <Box mt={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item sm={6} xs={12}>
                <NxSelect
                  ref={register}
                  fullWidth
                  label="How many computers can be used for esports?"
                  name="numStudentComps"
                  onChange={(): Promise<void> => onBlur()}
                  style={{ maxWidth: '350px' }}
                >
                  {numStudentComps ? (
                    <NxSelectOption value="1">Please select</NxSelectOption>
                  ) : (
                    <></>
                  )}
                  <NxSelectOption value="0-0">None</NxSelectOption>
                  <NxSelectOption value="1-10">1 to 10</NxSelectOption>
                  <NxSelectOption value="10-25">10 to 25</NxSelectOption>
                  <NxSelectOption value="25-50">25 to 50</NxSelectOption>
                  <NxSelectOption value="50-75">50 to 75</NxSelectOption>
                  <NxSelectOption value="75-100">75 to 100</NxSelectOption>
                  <NxSelectOption value="100+">more than 100</NxSelectOption>
                </NxSelect>
              </Grid>
              <Grid item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  defaultValue={publicIP}
                  fullWidth
                  helperText={errors.publicIP?.message}
                  label="School Public IP Address"
                  name="publicIP"
                  style={{ maxWidth: '350px' }}
                  variant={errors.publicIP ? 'error' : 'default'}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  )
}
