import { MinimalMetaseason } from '@plvs/utils'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import React from 'react'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { RosterCardRenderControllerProvider } from '@plvs/respawn/renderController/rosterCard/RosterCardRenderControllerProvider'
import { RosterCard } from './RosterCard'
import { RosterProvider } from './RosterProvider'
import { UserRole } from './RosterProvider.types'

export interface RosterContainerProps {
  teamId: string
  metaseason?: MinimalMetaseason
  promotedMetaseason?: MinimalMetaseason
  showCreateScrimmage?: boolean
  showAdditionalContainerActions?: boolean
  readOnly?: boolean
  date?: string | null
  teamColor?: string
  isPast?: boolean
  userRoles?: UserRole[]
  overrideTeamRosterViewPermissions?: boolean
  publicView?: boolean
  CustomHeader?: React.FC
  hideDefaultHeader?: boolean
  rosterCardStarterRowActions?: RosterCardMenuRowItems[]
  rosterCardSubRowActions?: RosterCardMenuRowSubItems[]
  noBoxShadow?: boolean
  borderRadius?: string | number
  parentDataLoading?: boolean
  eventId?: string
  isInMatchAssistantContainer?: boolean
}

export const RosterContainer: React.FC<RosterContainerProps> = ({
  teamId,
  metaseason,
  promotedMetaseason,
  showCreateScrimmage = true,
  showAdditionalContainerActions = true,
  date,
  teamColor,
  userRoles,
  isPast = false,
  readOnly = false,
  noBoxShadow = false,
  overrideTeamRosterViewPermissions,
  publicView,
  CustomHeader,
  hideDefaultHeader,
  rosterCardStarterRowActions,
  rosterCardSubRowActions,
  borderRadius,
  parentDataLoading,
  eventId,
  isInMatchAssistantContainer = false,
}) => {
  return (
    <PenaltyBox>
      <RosterProvider
        date={date}
        isPast={isPast}
        metaseason={metaseason}
        overrideTeamRosterViewPermissions={overrideTeamRosterViewPermissions}
        promotedMetaseason={promotedMetaseason}
        publicView={publicView}
        teamColor={teamColor}
        teamId={teamId}
        userRoles={userRoles}
      >
        <RosterCardRenderControllerProvider>
          <RosterCard
            borderRadius={borderRadius}
            CustomHeader={CustomHeader}
            eventId={eventId}
            hideDefaultHeader={hideDefaultHeader}
            isInMatchAssistantContainer={isInMatchAssistantContainer}
            noBoxShadow={noBoxShadow}
            parentDataLoading={parentDataLoading}
            readOnly={readOnly}
            rosterCardStarterRowActions={rosterCardStarterRowActions}
            rosterCardSubRowActions={rosterCardSubRowActions}
            showAdditionalContainerActions={showAdditionalContainerActions}
            showCreateScrimmage={showCreateScrimmage}
          />
        </RosterCardRenderControllerProvider>
      </RosterProvider>
    </PenaltyBox>
  )
}
