import React, { useState, useEffect } from 'react'
import { Tabs, Tab, makeStyles, IconButton } from '@material-ui/core'
import { useBreakpointXs, Box } from '@plvs/respawn/features/layout'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Collapse from '@material-ui/core/Collapse'

import { NxTypography, NxEsportAvatar } from '@playvs-inc/nexus-components'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { MatchCardFooter } from '@plvs/respawn/features/match/MatchCardFooter'

interface AccordionCardTabProps {
  children: React.ReactNode
  tab: string
}

export const AccordionCardTab: React.FC<AccordionCardTabProps> = ({
  children,
  tab: _tab,
}) => {
  return <>{children}</>
}

export interface AccordionCardProps {
  children?: React.ReactNode
  className?: string
  footer?: React.ReactNode
  title?: React.ReactNode
  titleDetail?: React.ReactNode
  sideContent?: React.ReactNode
  topContent?: React.ReactNode
  tabHeight?: number | string
  tabVariant?: 'standard' | 'scrollable' | 'fullWidth'
  wrapperClassName?: string
  esportSlug: string | null
  hideIconButton?: boolean
  hideTabIfSingle?: boolean
  icon?: React.ReactElement
  initialToggle?: boolean
  badge?: React.ReactElement
}

interface StyleProps {
  toggle?: boolean
  tabs: AccordionCardTabProps[]
  tabHeight?: number | string
}

const getTabs = (children: React.ReactNode): AccordionCardTabProps[] =>
  React.Children.toArray(children)
    .filter(React.isValidElement)
    .filter((child) => {
      const props = (child.props || {}) as AccordionCardTabProps
      return props.tab
    })
    .map((child) => {
      return (child?.props ?? { tab: '' }) as AccordionCardTabProps
    })

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    height: '100%',
    width: '100%',
  },
  iconBackground: {
    backgroundColor: theme.palette.ColorBackgroundAlt2,
  },
  toggleArrow: {
    color: theme.palette.ColorIconBase,
  },
  tab: ({
    tabHeight,
  }: StyleProps): Record<string, string | number | undefined> => ({
    minHeight: tabHeight,
  }),
  rightContainer: {
    display: 'flex',
    '& > span': {
      marginRight: '10px',
    },
    '& span': {
      alignItems: 'center',
    },
  },
  titleContainer: {
    display: 'flex',
  },
  titleSvg: {
    marginLeft: 10,
    '&>path': {
      fill: theme.palette.ColorIconBase,
    },
  },
}))

export const AccordionCard: React.FC<AccordionCardProps> = ({
  className,
  footer,
  title,
  titleDetail,
  children,
  tabHeight,
  tabVariant,
  wrapperClassName,
  esportSlug,
  sideContent,
  topContent,
  hideIconButton,
  hideTabIfSingle,
  icon,
  initialToggle,
  badge,
}) => {
  const isMobile = useBreakpointXs()
  const [selectedTab, setSelectedTab] = useState(0)
  const tabs = getTabs(children)
  const showHeader = title || titleDetail || sideContent
  const [toggle, setToggle] = useState<boolean | undefined>(false)

  const classes = useStyles({ toggle, tabs, tabHeight })
  useEffect(() => {
    setToggle(initialToggle)
  }, [initialToggle])

  const Title = title ? <NxTypography variant="h3">{title}</NxTypography> : null

  return (
    <Box className={className} flexShrink={0}>
      <Box className={classes.cardContainer}>
        <Box display="flex" flex={1} flexDirection="column" minHeight="100%">
          <Box>
            {topContent ? <Box pb={3}>{topContent}</Box> : null}
            {showHeader && (
              <Box
                alignItems="center"
                display={isMobile ? 'block' : 'flex'}
                flexDirection="row"
                pb={toggle ? 2 : 0}
              >
                {title || titleDetail ? (
                  <Box display="flex" flex={1} flexDirection="row" py={0}>
                    <NxEsportAvatar
                      esport={esportSlug}
                      iconHeight="54px"
                      iconWidth="54px"
                    />
                    <Box ml={2}>
                      <Box className={classes.titleContainer}>{Title}</Box>
                      {titleDetail ? (
                        <NxTypography variant="body3">
                          {titleDetail}
                        </NxTypography>
                      ) : null}
                    </Box>
                  </Box>
                ) : null}
                {sideContent && (
                  <Box mt={isMobile ? 2 : 0} px={3}>
                    {sideContent}
                  </Box>
                )}
                {!isMobile && (
                  <Box mt={0} px={3}>
                    <Box className={classes.rightContainer}>
                      {badge}
                      {!hideIconButton ? (
                        <IconButton
                          className={classes.iconBackground}
                          onClick={(): void => setToggle(!toggle)}
                          size="small"
                        >
                          {toggle ? (
                            <KeyboardArrowUpIcon
                              className={classes.toggleArrow}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              className={classes.toggleArrow}
                            />
                          )}
                        </IconButton>
                      ) : null}
                      {icon || null}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            <Collapse in={toggle}>
              <Box py={0}>
                {tabs.length > 1 || (tabs.length && !hideTabIfSingle) ? (
                  <Tabs value={selectedTab} variant={tabVariant}>
                    {tabs.map(({ children: _, tab, ...rest }, i) => {
                      return (
                        <Tab
                          key={tab}
                          className={classes.tab}
                          label={tab}
                          onClick={(): void => setSelectedTab(i)}
                          {...rest}
                        />
                      )
                    })}
                  </Tabs>
                ) : null}
              </Box>
            </Collapse>
          </Box>

          <Collapse in={toggle}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              minHeight="100%"
              overflow="auto"
            >
              <Box
                className={wrapperClassName}
                display="flex"
                flex={1}
                flexDirection="column"
                minHeight="100%"
                minWidth="min-content"
              >
                {React.Children.map(children, (child, i) => {
                  if (!React.isValidElement(child)) {
                    return child
                  }
                  if (child.props && child.props.tab) {
                    if (
                      child.props === tabs[selectedTab] ||
                      (!selectedTab && i === 0)
                    ) {
                      return (
                        <PenaltyBox key={child.props.tab}>{child}</PenaltyBox>
                      )
                    }
                    return null
                  }
                  return (
                    <PenaltyBox key={child.key || 'no-key'}>{child}</PenaltyBox>
                  )
                })}
              </Box>
            </Box>
          </Collapse>

          {footer && (
            <PenaltyBox>
              <MatchCardFooter>{footer}</MatchCardFooter>
            </PenaltyBox>
          )}
        </Box>
      </Box>
    </Box>
  )
}
