import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxPlayVsBanner,
  NxTypography,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import { useBreakpointSm } from '@plvs/respawn/features/layout'

export const useStyles = makeStyles((theme) => ({
  bannerContent: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
    },
  },
  bannerText: {
    color: theme.palette.OverlayColorTextBase,
  },
}))

export const ParentBanner: React.FC = () => {
  const isMobileView = useBreakpointSm()
  const classes = useStyles()

  return (
    <NxPlayVsBanner
      customContent={
        <Box
          className={classes.bannerContent}
          display="flex"
          flexDirection="column"
          ml={4}
        >
          <NxTypography
            className={classes.bannerText}
            variant={isMobileView ? 'display3' : 'display'}
          >
            Parent Dashboard
          </NxTypography>
          <Box maxWidth={448} mt={2}>
            <NxTypography
              className={classes.bannerText}
              data-testid="parent-dashboard-banner-text"
              variant={isMobileView ? 'subtitle1' : 'body1'}
            >
              Manage the data & privacy of your child. You can also view their
              progress and performance here.
            </NxTypography>
          </Box>
        </Box>
      }
      hasNoTopNavBar={isMobileView}
      shouldUseDefaultBanner
      size="small"
      title=""
      variant={PersonaImageVariant.Explore}
      widget={undefined}
    />
  )
}
