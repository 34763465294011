/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import {
  SchedulePageMatch,
  SchedulePageQueueMatch,
  TeamMatch,
} from '@plvs/rally/features/match/schedule/schedule'
import { NxTypography } from '@playvs-inc/nexus-components'
import { MatchRow } from './MatchRow'
import { QueueMatchRow } from './QueueMatchRow'

const useStyles = makeStyles((theme) => ({
  matchRowContainer: {
    padding: theme.spacing(2, 0),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.BorderLight}`,
    },
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  fullMatchRow: {
    flexBasis: '100%',
  },
  mobileCardMargin: {
    margin: '0 13px',
  },
}))

type MatchTimeGroupProps = {
  time: string
  past: boolean
  matches: (TeamMatch | SchedulePageMatch | SchedulePageQueueMatch)[]
  mobile: boolean
}

export const MatchTimeGroup: React.FC<MatchTimeGroupProps> = ({
  time,
  past,
  matches,
  mobile,
}) => {
  const styles = useStyles()
  return (
    <Box
      alignItems="center"
      data-testid="match-time-group"
      display="flex"
      flexDirection={mobile ? 'column' : 'row'}
    >
      <Box
        alignItems={mobile ? 'flex-start' : ''}
        className={
          mobile ? `${styles.fullMatchRow} ${styles.mobileCardMargin}` : ''
        }
        flexGrow={1}
        fontWeight={500}
        maxWidth="170px"
      >
        <NxTypography variant="h4">{time}</NxTypography>
      </Box>
      <Box flexGrow={1}>
        {matches.map((match, index) => {
          // we combined the two arrays so we could sort them properly
          // now we need to disassemble the array per entry
          // eslint-disable-next-line no-underscore-dangle
          const isRegularMatch = match.__typename === 'Match'

          if (isRegularMatch) {
            const matchAsRegularMatch = match as SchedulePageMatch
            return (
              <Box
                key={matchAsRegularMatch.id}
                className={styles.matchRowContainer}
                pb={2}
              >
                <MatchRow
                  match={matchAsRegularMatch}
                  mobile={mobile}
                  past={past}
                />
              </Box>
            )
          }

          const matchAsQueueMatch = match as SchedulePageQueueMatch
          return (
            <Box
              key={`${matchAsQueueMatch?.teamId}-${index}`}
              className={styles.matchRowContainer}
            >
              <QueueMatchRow match={matchAsQueueMatch} mobile={mobile} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
