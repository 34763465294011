import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import { Info } from '@material-ui/icons'
import clsx from 'clsx'

import { Path } from '@plvs/const'
import { EsportRating, MatchCheckInStatus, MatchStatus } from '@plvs/graphql'
import { isCoachForResource, isMatchPreseason } from '@plvs/utils'
import { SchedulePageMatch } from '@plvs/rally/features/match/schedule/schedule'
import {
  useGeneralEsportAdapter,
  // eslint-disable-next-line import/no-restricted-paths
} from '@plvs/respawn/features/esport/creator'
// eslint-disable-next-line import/no-restricted-paths
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import {
  NxTypography,
  NxButton,
  NxDropdownButton,
  NxIconButton,
} from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { CaretRight } from '@playvs-inc/nexus-icons'
import { useProductTours } from '@plvs/rally/init/shepherd/useProductTours'

import { findMatchTourClicked } from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/rally/init/dayjs'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import { ScrimmageBadge } from '../../badge'
import { LogoMark } from '../../icon'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  containerMobile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  deletedTeam: {
    textDecoration: 'line-through',
  },
  infoIcon: {
    color: theme.palette.ColorIconBase,
    fontSize: 17,
    verticalAlign: 'text-bottom',
    marginLeft: theme.spacing(1),
  },
  titleStyle: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.ColorTextBase,
    },
  },
  title: {
    marginBottom: '2px',
    textTransform: 'capitalize',
  },
  subTitle: {
    marginBottom: '7px',
  },
  esportLogoFuture: {
    margin: 'auto 20px',
    flex: 1,
    width: 32,
    height: 32,
    color: theme.palette.ColorIconDisabled,
  },
  esportLogoPast: {
    margin: '0 13px',
    flex: 1,
  },

  linkContainer: (props?: { reverse?: boolean }): any => ({
    flexGrow: 1,
    textAlign: props?.reverse ? 'left' : 'right',
  }),
  openMatchButton: {
    alignSelf: 'center',
  },
  checkInBtn: {
    borderLeft: `1px solid ${theme.palette.BorderLight}`,
  },
  fullMatchRow: {
    flexBasis: '100%',
  },
  mobileCardMargin: {
    margin: '0 13px',
    paddingBottom: '13px',
  },
  iconButton: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    width: 24,
    border: `1px solid ${theme.palette.BorderLight}`,
  },
}))

const pastStyles = makeStyles((theme) => {
  const winLossSize = '0.8em'
  const winLossLetterSpacing = '1px'
  return {
    esportLogo: {
      margin: '0 13px',
      flex: 1,
    },
    score: {
      fontSize: '2em',
      fontWeight: 'bold',
      color: theme.palette.ColorTextBase,
    },
    win: {
      color: theme.palette.ColorTextSuccess,
      fontSize: winLossSize,
      letterSpacing: winLossLetterSpacing,
    },
    loss: {
      color: theme.palette.ColorTextError,
      fontSize: winLossSize,
      letterSpacing: winLossLetterSpacing,
    },
    tie: {
      color: theme.palette.ColorTextAlt2,
      fontSize: winLossSize,
      letterSpacing: winLossLetterSpacing,
    },
    bye: {
      color: theme.palette.ColorTextAlt2,
      fontSize: winLossSize,
      letterSpacing: winLossLetterSpacing,
    },
    winLossContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    winLossDash: {
      color: theme.palette.ColorTextAlt2,
    },
  }
})

const logoStyles = makeStyles((theme) => {
  const logoSize = '35px'
  const containerSize = '56px'
  return {
    logoContainer: {
      backgroundColor: ({
        invisible,
      }: {
        invisible: boolean
      }): string | undefined =>
        invisible ? 'transparent' : theme.palette.ColorBackgroundAlt2,
      borderRadius: '50%',
      height: containerSize,
      width: containerSize,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    logo: {
      width: logoSize,
      height: logoSize,
      color: theme.palette.common.white,
      margin: 'auto',
      fill: ({ invisible }: { invisible: boolean }): string | undefined =>
        invisible ? 'transparent' : undefined,
    },
  }
})

type MatchRowProps = {
  past?: boolean
  match: SchedulePageMatch
  mobile: boolean
  myTeamId?: string
}

type MatchRowTeamProps = {
  match: SchedulePageMatch
  esportAdapter: GeneralEsportAdapter
  hasTeamBResults: boolean
  isMyTeamHome: boolean
}

enum GAME_STATUS {
  Win = 'WIN',
  Loss = 'LOSS',
  Tie = 'TIE',
  Bye = 'BYE',
  Forfeit = 'FORFEITED',
}

const NoTeamAvatar: React.FC<{ invisible?: boolean }> = ({
  invisible = false,
}) => {
  const styles = logoStyles({ invisible })
  return (
    <Box className={styles.logoContainer}>
      <LogoMark className={styles.logo} />
    </Box>
  )
}

export const getUnrestrictedLogo = (
  rating: EsportRating,
  logoUrl?: string | null
): string | null => {
  const logo = rating === EsportRating.Restricted ? null : logoUrl
  return logo ?? null
}

const MatchRowTeamsFuture: React.FC<MatchRowTeamProps> = ({
  match,
  esportAdapter,
  hasTeamBResults,
}) => {
  const styles = useStyles()
  const rating = match?.esport?.rating
  const teamAResults = match.matchResults?.[0]
  const teamAId = teamAResults?.team?.id ?? ''
  const teamAUnrestrictedLogo = getUnrestrictedLogo(
    rating,
    teamAResults?.team?.avatarUrl
  )
  const teamBResults = match.matchResults?.[1]
  const teamBId = teamBResults?.team?.id ?? ''
  const teamBUnrestrictedLogo = getUnrestrictedLogo(
    rating,
    teamBResults?.team?.avatarUrl
  )

  const teamBAvatar = teamBResults ? (
    <Box>
      <NxUserAvatar
        avatarUrl={teamBUnrestrictedLogo}
        hashId={teamBId ?? ''}
        size={NxAvatarSize.MD}
      />
    </Box>
  ) : (
    <NoTeamAvatar />
  )

  const renderTeamBDetails = hasTeamBResults ? (
    teamBAvatar
  ) : (
    <NoTeamAvatar invisible />
  )

  const { NexusIcon } = esportAdapter

  return (
    <Box display="flex" flex={1} flexDirection="row">
      <Box display="flex" flexDirection="row" margin="auto">
        <Box>
          <NxUserAvatar
            avatarUrl={teamAUnrestrictedLogo}
            hashId={teamAId ?? ''}
            size={NxAvatarSize.MD}
          />
        </Box>
        {NexusIcon && <NexusIcon className={styles.esportLogoFuture} />}
        <Box>{renderTeamBDetails}</Box>
      </Box>
    </Box>
  )
}

export const getMatchStatus = (
  teamAScore: number,
  teamBScore: number,
  matchStatus: MatchStatus | undefined,
  hasTeamBResults: boolean,
  isMyTeamHome: boolean
): string => {
  const myScore = isMyTeamHome ? teamAScore : teamBScore
  const oppScore = isMyTeamHome ? teamBScore : teamAScore

  if (hasTeamBResults) {
    if (myScore > oppScore) return GAME_STATUS.Win
    if (myScore < oppScore) return GAME_STATUS.Loss
    return GAME_STATUS.Tie
  }

  if (matchStatus === MatchStatus.Bye) return GAME_STATUS.Bye
  if (matchStatus === MatchStatus.Forfeited) return GAME_STATUS.Forfeit
  return ''
}

const MatchRowTeamsPast: React.FC<MatchRowTeamProps> = ({
  match,
  esportAdapter,
  hasTeamBResults,
  isMyTeamHome,
}) => {
  const styles = pastStyles()
  const rating = match?.esport?.rating
  const teamAResults = match.matchResults?.[0]
  const teamAId = teamAResults?.team?.id ?? ''
  const teamAUnrestrictedLogo = getUnrestrictedLogo(
    rating,
    teamAResults?.team?.avatarUrl
  )
  const teamAScore = esportAdapter.hasSeries
    ? teamAResults?.seriesWon ?? 0
    : teamAResults?.gamesWon ?? 0
  const teamBResults = match.matchResults?.[1]
  const teamBId = teamBResults?.team?.id ?? ''
  const teamBUnrestrictedLogo = getUnrestrictedLogo(
    rating,
    teamBResults?.team?.avatarUrl
  )
  const teamBScore = esportAdapter.hasSeries
    ? teamBResults?.seriesWon ?? 0
    : teamBResults?.gamesWon ?? 0

  const getWinLossStyle = useMemo(() => {
    return (status: string): string => {
      switch (status) {
        case GAME_STATUS.Win:
          return styles.win
        case GAME_STATUS.Loss:
          return styles.loss
        default:
          return styles.tie
      }
    }
  }, [styles])

  const gameStatus = getMatchStatus(
    teamAScore,
    teamBScore,
    match?.status,
    hasTeamBResults,
    isMyTeamHome
  )

  const noTeamBDetailsDisplay = (
    <>
      <NxUserAvatar
        avatarUrl={teamAUnrestrictedLogo}
        hashId={teamAId ?? ''}
        size={NxAvatarSize.MD}
      />
      <Box display="flex" flexDirection="row" ml={2} mr={2}>
        <Box pr={2} />
        <Box className={styles.winLossContainer} ml={0.2} mr={0.2} pt={1.2}>
          <NxTypography
            className={getWinLossStyle(gameStatus)}
            data-testid="game-status"
          >
            {gameStatus}
          </NxTypography>
        </Box>
        <Box pr={2} />
      </Box>
      <Box flex={1}>
        <NoTeamAvatar invisible />
      </Box>
    </>
  )

  const { NexusIcon } = esportAdapter

  return (
    <Box alignItems="center" display="flex" flex={2} flexDirection="row">
      {NexusIcon && <NexusIcon className={styles.esportLogo} />}
      {hasTeamBResults ? (
        <>
          <NxUserAvatar
            avatarUrl={teamAUnrestrictedLogo}
            hashId={teamAId ?? ''}
            size={NxAvatarSize.MD}
          />
          <Box display="flex" flexDirection="row" ml={2} mr={2}>
            <Box className={styles.winLossContainer}>
              <NxTypography className={styles.score} data-testid="team-a-score">
                {teamAScore}
              </NxTypography>
            </Box>
            <Box className={styles.winLossContainer} ml={0.2} mr={0.2} pt={1.2}>
              <RemoveIcon className={styles.winLossDash} />
              <NxTypography
                className={getWinLossStyle(gameStatus)}
                data-testid="game-status"
              >
                {gameStatus}
              </NxTypography>
            </Box>
            <Box className={styles.winLossContainer}>
              <NxTypography className={styles.score} data-testid="team-b-score">
                {teamBScore}
              </NxTypography>
            </Box>
          </Box>

          <Box flex={1}>
            <NxUserAvatar
              avatarUrl={teamBUnrestrictedLogo}
              hashId={teamBId ?? ''}
              size={NxAvatarSize.MD}
            />
          </Box>
        </>
      ) : (
        noTeamBDetailsDisplay
      )}
    </Box>
  )
}

export const MatchRow: React.FC<MatchRowProps> = ({
  match,
  past,
  mobile,
  myTeamId,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const styles = useStyles()
  const { userId } = useUserIdentityFn()
  const teamAResults = match.matchResults?.[0]
  const teamAName = teamAResults?.team?.name ?? ''
  const isTeamADeleted = !!teamAResults?.team?.archivedAt
  const teamBResults = match.matchResults?.[1]
  const hasTeamBResults = Boolean(teamBResults)
  const { findMatchTour, initiatedTimestamp } = useProductTours()

  const teamBName = teamBResults?.team?.name ?? 'TBD'
  const isTeamBDeleted = !!teamBResults?.team?.archivedAt
  const gameSlug = match.esport?.slug

  const esportName = match.esport?.name
  const { id } = match

  const esportAdapter = useGeneralEsportAdapter(gameSlug)
  const myTeamResult = match?.matchResults?.find(
    (result) => result.team?.isMyTeam === true
  )

  const myTeamIdDervied = myTeamId ?? myTeamResult?.team?.id
  const isMyTeamHome = teamAResults?.team?.id === myTeamIdDervied

  const { loading: selectedEntityLoading, roles } = useProfileContext()
  const isCoach =
    !selectedEntityLoading &&
    roles &&
    myTeamId &&
    isCoachForResource(roles, [myTeamId])

  const checkInClosesAt = dayjs(match?.checkInClosesAt)
  const checkInOpensAt = dayjs(match?.checkInOpensAt)

  const checkInWindowOpen = useMemo(() => {
    return dayjs().isBefore(checkInClosesAt) && dayjs().isAfter(checkInOpensAt)
  }, [checkInClosesAt, checkInOpensAt])

  const hasCheckedIn = match?.matchCheckIns?.some(
    (checkIn) =>
      checkIn.teamId === myTeamId &&
      checkIn.status === MatchCheckInStatus.Completed
  )

  const showCheckIn =
    isCoach &&
    checkInWindowOpen &&
    !hasCheckedIn &&
    match.status === MatchStatus.Scheduled

  const matchRowTeams = useMemo(() => {
    if (!past)
      return (
        <MatchRowTeamsFuture
          esportAdapter={esportAdapter}
          hasTeamBResults={hasTeamBResults}
          isMyTeamHome={isMyTeamHome}
          match={match}
        />
      )
    return (
      <MatchRowTeamsPast
        esportAdapter={esportAdapter}
        hasTeamBResults={hasTeamBResults}
        isMyTeamHome={isMyTeamHome}
        match={match}
      />
    )
  }, [past, match])

  const getTeamName = useCallback(
    (teamName: string, isDeleted: boolean): React.ReactElement => {
      if (isDeleted) {
        return (
          <span
            className={styles.deletedTeam}
            data-testid="MatchRow_DeletedTeamName"
          >
            {teamName}
            <Tooltip arrow placement="bottom" title="This team was deleted">
              <Info className={styles.infoIcon} fontSize="small" />
            </Tooltip>
          </span>
        )
      }
      return <span>{teamName}</span>
    },
    [styles]
  )

  const singleTeamDisplay = (): React.ReactNode => {
    switch (match.status) {
      case MatchStatus.Bye:
        return (
          <NxTypography className={styles.title} data-cy="teamMatchRow">
            {teamAName} {`(${MatchStatus.Bye})`}
          </NxTypography>
        )
      case MatchStatus.Forfeited:
        return (
          <NxTypography className={styles.title} data-cy="teamMatchRow">
            {teamAName} {`(${MatchStatus.Forfeited})`}
          </NxTypography>
        )
      default:
        return null
    }
  }

  const onClick = (): void => {
    navigate(`${Path.Match}/${id}`)
    if (findMatchTour?.isActive()) {
      findMatchTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 3,
        clickTarget: 'Complete Button',
      })
      findMatchTour?.complete()
    }
  }

  const viewButtonLabel = match.isScrimmage ? 'View Scrimmage' : 'Open Match'

  return (
    <Box
      className={mobile ? styles.containerMobile : styles.container}
      data-testid="match-row"
    >
      <Box
        className={
          mobile ? `${styles.fullMatchRow} ${styles.mobileCardMargin}` : ''
        }
        flex={1}
        flexWrap="wrap"
        width="300px"
      >
        {hasTeamBResults ? (
          <NxTypography className={styles.title} data-cy="teamMatchRow">
            {getTeamName(teamAName, isTeamADeleted)} vs{' '}
            {getTeamName(teamBName, isTeamBDeleted)}
          </NxTypography>
        ) : (
          singleTeamDisplay()
        )}
        <NxTypography
          className={styles.subTitle}
          color="textSecondary"
          data-testid="subtitle"
          variant="body4"
        >
          {esportName}
        </NxTypography>
        {match.isScrimmage && (
          <Box mb={2}>
            <ScrimmageBadge
              data-testid="scrimmage-badge"
              isPreseason={isMatchPreseason(match)}
              size="medium"
            />
          </Box>
        )}
      </Box>
      <Box
        alignItems={mobile || showCheckIn ? 'center' : ''}
        className={mobile ? `${styles.fullMatchRow}` : ''}
        display="flex"
        flex={2}
        flexDirection="row"
        margin="auto"
      >
        {matchRowTeams}
        <Box
          alignItems="flex-end"
          display="flex"
          flex={mobile ? '' : 1}
          justifyContent="center"
          minWidth={mobile ? '' : '200px'}
          ml={3}
        >
          {mobile && (
            <NxIconButton
              className={styles.iconButton}
              data-testid="open-match-btn"
              disabled={!teamBResults}
              icon={<CaretRight height={18} width={18} />}
              onClick={onClick}
              p={0}
              variant="secondary"
            />
          )}

          {!mobile &&
            (!showCheckIn ? (
              <NxButton
                className={clsx(styles.openMatchButton, 'open-match')}
                data-testid="open-match-btn"
                disabled={!teamBResults}
                label={viewButtonLabel}
                onClick={onClick}
                variant="secondary"
              />
            ) : (
              <NxDropdownButton
                data-cy="checkInSplitButton"
                label="Match Actions"
                menuBackgroundColor={theme.palette.ColorBackgroundBase}
                menuItems={[
                  {
                    items: [
                      {
                        label: 'Check in',

                        id: 'check-in',
                        onClick: (): void => {
                          navigate(`${Path.MatchCheckIns}/${id}`)
                        },
                      },
                      {
                        label: 'Open Match',
                        id: 'open-match',
                        onClick: (): void => {
                          navigate(`${Path.Match}/${id}`)
                        },
                      },
                    ],
                  },
                ]}
                variant="secondary"
              />
            ))}
        </Box>
      </Box>
    </Box>
  )
}
