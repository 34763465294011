import dayjs from 'dayjs'
import { formatISO } from 'date-fns'

import {
  GetCancelMatchQuery,
  GetRescheduleMatchQuery,
  MatchRescheduleRequestStatus,
  MatchStatus,
  PhaseType,
  useGetMatchRescheduleDateRangeQuery,
} from '@plvs/graphql'
import {
  DOW_FORMAT,
  HOUR_FORMAT,
  LONG_DATE_FORMAT_TH,
  RESCHEDULE_ALLOWED_TIME_END,
  RESCHEDULE_ALLOWED_TIME_START,
} from '@plvs/const'
import { useAutoskipQuery, LobbyMatch } from '@plvs/utils'
import { ActionAndInfoMatch } from '../match-lobby/ActionAndInfoSection.types'

export type RescheduleMatch = ActionAndInfoMatch

export type RescheduleMatchStep =
  | 'init'
  | 'requested-reason'
  | 'requested-times'
  | 'requested-success'
  | 'requested-failure'
  | 'read-request'
  | 'update-request'
  | 'confirm-request'
  | 'delete-request'
  | 'deny-request'

export enum RescheduleAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum DisabledRescheduleReason {
  Rescheduled = 'Match has already been rescheduled',
  Expired = 'Reschedule window has closed',
  Open = 'Reschedule already open',
  NotRegularSeason = 'Match is only reschedulable during regular season',
  Other = 'Cannot reschedule at this time',
}

export type CanBeRescheduledMatch =
  | NonNullable<GetRescheduleMatchQuery['match']>
  | NonNullable<GetCancelMatchQuery['match']>
  | NonNullable<LobbyMatch>

export const canMatchBeRescheduled = (
  match: RescheduleMatch
): { canReschedule: boolean; disableReason?: DisabledRescheduleReason } => {
  if (match?.status === MatchStatus.Rescheduled) {
    return {
      canReschedule: false,
      disableReason: DisabledRescheduleReason.Rescheduled,
    }
  }

  if (
    (match?.matchRescheduleRequests ?? []).filter(
      (o) => o.status === MatchRescheduleRequestStatus.Pending
    ).length > 0
  ) {
    return {
      canReschedule: false,
      disableReason: DisabledRescheduleReason.Open,
    }
  }

  if (match.phaseType !== PhaseType.RegularSeason) {
    return {
      canReschedule: false,
      disableReason: DisabledRescheduleReason.NotRegularSeason,
    }
  }

  if (
    match.status &&
    [
      MatchStatus.Cancelled,
      MatchStatus.Completed,
      MatchStatus.Forfeited,
    ].includes(match.status)
  ) {
    return {
      canReschedule: false,
      disableReason: DisabledRescheduleReason.Expired,
    }
  }

  return {
    canReschedule: true,
  }
}

export interface DateOption {
  dateObject: Date
  formattedDate: string
}

export interface TimeOption {
  hour: number
  minute: number
  hhmm: number
  formattedTime: string
}

export const useGetLatestAvailableRescheduleDate = (
  matchId: string
): string => {
  const { data } = useAutoskipQuery(useGetMatchRescheduleDateRangeQuery, {
    variables: { id: matchId },
  })
  // Flipping the name here to reframe around the idea of a due date or an expiration date.
  // Even though the property name is 'before', the intent is to use this value as the last available date for a reschedule.

  const latestAvailableDate = data?.getMatchRescheduleDateRange
    ?.before as string

  return latestAvailableDate
}

export const calculateDateOptions = (
  earliestAvailableDate: string,
  latestAvailableDate: string
): DateOption[] => {
  const availableDates: DateOption[] = []

  const earliestDate = dayjs(earliestAvailableDate)
  const latestDate = dayjs(latestAvailableDate)

  // We want the range of [earliestDate, latestDate] i.e. inclusive range, hence the + 1
  const daysToShow =
    Math.abs(
      earliestDate.startOf('day').diff(latestDate.endOf('day'), 'days')
    ) + 1

  let currDate = earliestDate

  for (let x = 0; x < daysToShow; x += 1) {
    availableDates.push({
      dateObject: currDate.toDate(),
      formattedDate: dayjs(currDate.toISOString()).format(
        `${DOW_FORMAT}, ${LONG_DATE_FORMAT_TH}`
      ),
    })
    currDate = currDate.add(1, 'days')
  }
  return availableDates
}

// time needs to be every 30 minutes from RESCHEDULE_ALLOWED_TIME_START to RESCHEDULE_ALLOWED_TIME_END
// 14 different time slots. 200 230 300 330 400 430 500 530 600 630 700 730 800 830
export const getAvailableTimes = (): TimeOption[] => {
  const availableTimes: TimeOption[] = []

  const today = new Date()
  for (
    let currTime = RESCHEDULE_ALLOWED_TIME_START;
    currTime < RESCHEDULE_ALLOWED_TIME_END;
    currTime = currTime % 100 ? currTime + 70 : currTime + 30
  ) {
    const hour = Math.floor(currTime / 100)
    const minute = Math.floor(currTime % 100)

    today.setHours(hour)
    today.setMinutes(minute)
    const currFormatedTime: string = dayjs(today).format(HOUR_FORMAT)
    availableTimes.push({
      formattedTime: currFormatedTime,
      hour,
      minute,
      hhmm: currTime,
    })
  }

  return availableTimes
}

export const createDateWithTime = (
  dayOption: DateOption,
  timeOption: TimeOption
): Date => {
  const date = new Date(dayOption.dateObject)
  date.setHours(timeOption.hour)
  date.setMinutes(timeOption.minute)
  date.setMilliseconds(0)
  return date
}

// NOTE: choosing to use formatISO vs. dayjs, there is a slight change to the hours, minutes, and seconds

export const getProposedDateTimesFromIndices = (
  proposedDateIndices: number[],
  proposedTimeIndices: number[],
  availableDates: DateOption[],
  availableTimes: TimeOption[]
): string[] => {
  return proposedDateIndices.map((proposedDateIndex, i): string => {
    const proposedTimeIndex: number = proposedTimeIndices[i]
    return formatISO(
      createDateWithTime(
        availableDates[proposedDateIndex],
        availableTimes[proposedTimeIndex]
      )
    )
  })
}
