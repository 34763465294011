import React from 'react'

export const SmallGreenCircleCheck = (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" fill="#00DB00" opacity="0.3" r="8" />
    <path
      d="M10.9197 4.96872L10.9197 4.96871C10.6952 4.80775 10.3827 4.85926 10.2218 5.08376L6.79688 9.85885L5.72909 8.79014L5.72907 8.79013C5.60272 8.66372 5.41854 8.61432 5.2459 8.66054C5.07325 8.70675 4.93838 8.84156 4.89208 9.01418C4.84579 9.18681 4.8951 9.37101 5.02144 9.49742L5.02147 9.49745L6.50608 10.9818L6.50606 10.9818L6.50779 10.9835C6.61247 11.0842 6.75537 11.1351 6.90016 11.1233C7.04496 11.1115 7.17771 11.0381 7.26467 10.9217L7.26469 10.9217L7.2661 10.9197L11.0348 5.66672L11.0348 5.66668C11.1958 5.44217 11.1442 5.12969 10.9197 4.96872Z"
      fill="#00DB00"
      stroke="#00DB00"
      strokeWidth="0.25"
    />
  </svg>
)
