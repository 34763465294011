import React from 'react'

import { withTransaction } from '@elastic/apm-rum-react'
import { PrivatePage } from '@plvs/rally/pages/page'
import { ScrollToTop } from '@plvs/rally/pages/route/ScrollToTop'
import { MatchLobbyRenderControllerProvider } from '@plvs/respawn/renderController/matchLobby/MatchLobbyRenderControllerProvider'
import { Lobby } from '@plvs/rally/features/match/lobby/Lobby/Lobby'
import { useParams } from 'react-router-dom'

const MatchLobbyPageInternal: React.FC = () => {
  const { matchId } = useParams<{ matchId: string }>()
  return (
    <PrivatePage title="Match Lobby">
      <ScrollToTop />
      <MatchLobbyRenderControllerProvider>
        <Lobby matchId={matchId ?? ''} />
      </MatchLobbyRenderControllerProvider>
    </PrivatePage>
  )
}

export const MatchLobbyPage = withTransaction(
  'MatchLobbyPage',
  'component'
)(MatchLobbyPageInternal)
