import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0, // keeps width from exceeding parent container
  },
}))

export const Page: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} id="page">
      {children}
    </Box>
  )
}
