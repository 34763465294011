import { LocalStorageKey } from '@plvs/const'
import { ProductType, useProductTypeVar } from '../models/ProductType'

export const updateProductTypeFn = (productType: ProductType): ProductType => {
  try {
    localStorage.setItem(LocalStorageKey.ProductType, productType)
  } catch (err) {
    // Suppress
  }

  return useProductTypeVar(productType)
}
