import React from 'react'

import { Box } from '@plvs/respawn/features/layout'

export const StatRow: React.FC<{
  children: React.ReactNode
  flexDirection?: any
  marginTop?: any
}> = ({ children, flexDirection = 'row', marginTop = 40 }) => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection={flexDirection}
    justifyContent="center"
    marginTop={marginTop}
  >
    {children}
  </Box>
)
