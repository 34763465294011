import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { SessionStorageKey } from '@plvs/const'
import { useSendParentVerificationEmailMutation } from '@plvs/graphql/generated'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  text: {
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3.5),
  },
}))

interface Props {
  email: string
}

export const ParentOnboardVerification: React.FC<Props> = ({ email }) => {
  const classes = useStyles()

  const [sendParentVerificationEmail] = useSendParentVerificationEmailMutation()

  useEffect(() => {
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
    sendParentVerificationEmail()
  }, [])

  const handleContinue = (): void => {
    window.location.replace('/app/parent-dashboard')
  }

  return (
    <div className={classes.root}>
      <QuestionnaireCard childWidth={420} title="">
        <NxTypography className={classes.text} variant="display3">
          Almost there!
        </NxTypography>
        <NxTypography className={classes.text} variant="display3">
          We need to verify your identity.
        </NxTypography>
        <NxTypography
          className={classes.subtitle}
          colorToken="ColorTextAlt"
          variant="body1"
        >
          {`We just sent an email to ${email}. Please follow the instructions in the email. Once verified, you can create an account for your child on the Parent Dashboard.`}
        </NxTypography>
        <NxButton
          className={classes.button}
          fullWidth
          label="continue to Parent dashboard"
          onClick={handleContinue}
          variant="primary"
        />
      </QuestionnaireCard>
    </div>
  )
}
