import React, { useState } from 'react'
import { Dialog, Button } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { RouterLink } from '@plvs/respawn/features/route'
import { MatchImageCard } from '../match/MatchImageCard'

type noop = () => void

export interface NotificationOverlayProps {
  imageURL?: string
  primaryCTA?: string
  link: string
  title: string
  description: string
  onPrimaryClick?(): void
  onCancelClick?(): void
  isOpen?: boolean
  isCancelable?: boolean
  isExternalUrl?: boolean
}

export const NotificationOverlay: React.FC<NotificationOverlayProps> = ({
  link,
  title,
  description,
  primaryCTA = 'Learn More',
  onPrimaryClick = (): void => {},
  onCancelClick = (): void => {},
  imageURL,
  isCancelable = true,
  isExternalUrl = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const close = (fn: () => void): noop => (): void => {
    fn()
    setIsOpen(false)
  }

  const imageCardProps = {
    borderRadius: '0',
    boxShadow: 'none',
    height: 260,
    mb: 4,
    mt: -4,
    showOverlay: false,
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={close(onCancelClick)}
      open={isOpen}
    >
      {imageURL ? (
        <MatchImageCard {...imageCardProps} alt={title} src={imageURL} />
      ) : null}
      <Box display="flex" flexDirection="column" pb={5} pt={1} px={[4, 5]}>
        <Box flex="1" mb={2}>
          <NxTypography color="textPrimary" variant="h2">
            {title}
          </NxTypography>
        </Box>
        <Box flex="1">
          <NxTypography color="textPrimary" variant="body1">
            {description}
          </NxTypography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" px={[4, 5]}>
        {isCancelable ? (
          <Box flex="grow">
            <Button onClick={close(onCancelClick)}>Cancel</Button>
          </Box>
        ) : null}
        <Box flex="1" />
        <Box flex="0">
          {isExternalUrl ? (
            <Button
              color="primary"
              href={link}
              onClick={close(onPrimaryClick)}
              rel="noopener"
              target="_blank"
              variant="contained"
            >
              {primaryCTA}
            </Button>
          ) : (
            <Button
              color="primary"
              component={RouterLink}
              onClick={close(onPrimaryClick)}
              to={link}
              variant="contained"
            >
              {primaryCTA}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
