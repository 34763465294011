import React from 'react'
import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import {
  CompetitionGroup,
  EsportRating,
  EsportSlug,
  useGetLeagueTeamsQuery,
} from '@plvs/graphql'
import { isCoachForResource, uniqueById, useAutoskipQuery } from '@plvs/utils'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Box, CardContent, Card } from '@material-ui/core'
import { Path } from '@plvs/const'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { LeagueTeam } from './LeagueTeam'

interface LeagueTeamsProps {
  metaseasonId: string
  leagueId: string
  competitionGroup: CompetitionGroup | undefined | null
  esportId: string
  esportSlug: EsportSlug | undefined | null
  className: string
  isPastRegDate: boolean
}

export const LeagueTeams: React.FC<LeagueTeamsProps> = ({
  metaseasonId,
  esportId,
  esportSlug,
  competitionGroup,
  leagueId,
  className,
  isPastRegDate,
}) => {
  const { id: orgId } = useSelectedOrganizationFn()
  const { roles } = useProfileContext()
  const { teamIds, loadingTeams } = useTeamsContext()
  const hasNoTeams = !teamIds.length
  const { data, loading } = useAutoskipQuery(useGetLeagueTeamsQuery, {
    variables: {
      filters: { teamIds },
    },
    skip: hasNoTeams,
    fetchPolicy: 'network-only',
  })
  const { isCoachAtOrg } = useUserIdentityFn()

  const teams = data?.getTeams?.teams ?? []

  const teamsEnrolledInLeague = teams.filter((team) => {
    const matchEnrolledSeason = (team.enrolledSeasons ?? []).find(
      (season) => season.metaseasonId === metaseasonId
    )
    const matchLeague = (team.leagues ?? []).find(
      (league) => league.id === leagueId
    )
    const matchEsport = esportId === team.esportId

    return matchEnrolledSeason && matchLeague && matchEsport
  })

  const enrolledTeamInLeagueIds = teamsEnrolledInLeague.map((team) => team.id)
  const teamsToEnrollInLeague = teams.filter((team) => {
    const matchEsport = team.esportId === esportId
    const matchCompetitionGroup = team.competitionGroup === competitionGroup
    return matchEsport && matchCompetitionGroup && team?.school?.id === orgId
  })

  const leagueTeams = uniqueById([
    ...teamsToEnrollInLeague,
    ...teamsEnrolledInLeague,
  ])

  return (
    <WaitTillLoaded
      loading={loading || loadingTeams}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {!leagueTeams.length ? null : (
        <Box mb={3}>
          <Card data-testid="LeagueTeams_Card">
            <CardContent className={className}>
              <NxTypography variant="h3">
                My Team{leagueTeams.length === 1 ? '' : 's'}
              </NxTypography>
              {leagueTeams.map((team) => (
                <LeagueTeam
                  key={team.id}
                  isCoachAtOrg={isCoachAtOrg}
                  isEnrolled={enrolledTeamInLeagueIds.includes(team.id)}
                  isPastRegDate={isPastRegDate}
                  isYouthProgram={
                    team?.esport?.rating === EsportRating.Restricted
                  }
                  metaseasonId={metaseasonId}
                  path={
                    isCoachForResource(roles, [team.school?.id ?? ''])
                      ? `${Path.ManageTeams}?e=${esportSlug}`
                      : `${Path.Team}/${team.id}`
                  }
                  team={team}
                />
              ))}
            </CardContent>
          </Card>
        </Box>
      )}
    </WaitTillLoaded>
  )
}
