/* istanbul ignore file */
import { ApolloCache, NormalizedCacheObject } from '@apollo/client'
import { Cookie } from '@plvs/const'
import { useLogoutUserMutation } from '@plvs/graphql/generated'
import * as analytics from '@plvs/respawn/features/analytics'
import { safeRemoveCookie } from '@plvs/utils'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

type LogoutReturn = () => Promise<void>

export const useLogout = (
  cache: ApolloCache<NormalizedCacheObject>
): LogoutReturn => {
  const [logout] = useLogoutUserMutation()

  const handleLogout = async (): Promise<void> => {
    await logout()

    const token = cookies.get(Cookie.Token)
    if (token) {
      analytics.logout()

      // Using safeRemoveCookie here vs. useCookie hook used elsewhere in the
      // app due to that hook causing a refresh loop when logging out on staging
      // environments.
      safeRemoveCookie((...args) => cookies.remove(...args), Cookie.Token)
      localStorage.clear()
      sessionStorage.clear()
      cache.reset()
      window.location.reload()
    }
  }

  return handleLogout
}
