import { QueueLobby } from '@plvs/rally/features/match/lobby/QueueLobby'
import React from 'react'
import { useParams } from 'react-router'
import { AppPage } from '../page'

export const QueueLobbyPage: React.FC = () => {
  const { slotId, teamId } = useParams<{ slotId: string; teamId: string }>()

  return (
    <AppPage title="Match Lobby">
      <QueueLobby slotId={slotId ?? ''} teamId={teamId ?? ''} />
    </AppPage>
  )
}
