import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'

export const useStyles = makeStyles((theme) => ({
  baseTextColor: {
    color: theme.palette.ColorTextBase,
  },
  title: {
    fontSize: '1.375em',
    fontWeight: 700,
    lineHeight: '112%',
    marginBottom: theme.spacing(4),
  },
  subTitle: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: theme.spacing(1),
    letterSpacing: '-0.01em',
  },
  email: {
    fontSize: '1.125rem',
    marginBottom: theme.spacing(0),
    fontWeight: 600,
  },
  emailLabel: {
    marginBottom: theme.spacing(1),
  },
  yourSchoolLabel: {
    fontFamily: 'TT Commons, Whitney, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    color: theme.palette.ColorTextAlt2 ?? Colors.Grey7,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
  },
  yourSchoolInfo: {
    fontFamily: 'TT Commons, Whitney, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '133%',
    letterSpacing: '-0.01em',
    color: theme.palette.ColorTextBase ?? theme.palette.text.primary,
  },
  fontWeightBold: {
    fontWeight: 600,
  },
  fontWeightLight: {
    fontWeight: 200,
  },
  accountEditInput: {
    '& div': {
      border: `1px solid ${
        theme.palette.BorderLight ?? theme.palette.action.active
      }`,
      '& input': {
        padding: '0.5em 1em',
      },
    },
  },
  accountInfoDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  link: { color: theme.palette.ColorTextLink, textDecoration: 'none' },
  defaultSchoolLogo: {
    height: 54,
    width: 54,
    borderSize: 2,
  },
  missingSchoolEmail: {
    color: theme.palette.ColorTextAlt,
  },
  form: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  ssoButton: {
    height: theme.spacing(5.5),
  },
  submitButton: {
    height: theme.spacing(5.5),
  },
  endAdornment: {
    marginRight: theme.spacing(1.375),
  },
  passwordEditDialog: {
    minWidth: '343px',
  },
  icon: {
    color: theme.palette.ColorTextDisabled,
  },
}))
