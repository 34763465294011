import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const AddCircle = createSvgIcon(
  <>
    <path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10c5.514,0,10-4.486,10-10S17.514,2,12,2z M17,13h-4v4h-2v-4H7v-2h4V7h2v4h4V13z" />
  </>,
  'AddCircle Logo',
  { viewBox: '2 1 20 21' }
)
