import { NxButton } from '@playvs-inc/nexus-components'
import { useSnackbar } from 'notistack'
import React from 'react'

import {
  refetchMyCoachPlayerRolePendingUsersQuery,
  useVerifyPlayerMutation,
} from '@plvs/graphql'
import { evictPlayersTableCache } from './helpers'

interface Props {
  userId: string
  schoolId: string
}

const VerifyPlayerButton = ({
  userId,
  schoolId,
}: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [mutate, { loading: isMutating }] = useVerifyPlayerMutation({
    update: evictPlayersTableCache,
  })

  const onSubmit = async (): Promise<void> => {
    try {
      if (!schoolId) {
        throw new Error()
      }
      const user = (
        await mutate({
          variables: { userId, resourceId: schoolId },
          refetchQueries: [refetchMyCoachPlayerRolePendingUsersQuery()],
        })
      )?.data?.verifyPlayer
      if (!user) throw new Error()
      // TODO: Analytics on `user`.
    } catch (e: any) {
      enqueueSnackbar('Could not verify player.', { variant: 'error' })
    }
  }
  return (
    <NxButton
      disabled={isMutating}
      fullWidth
      label="Verify"
      onClick={onSubmit}
      variant="primary"
    />
  )
}
export default VerifyPlayerButton
