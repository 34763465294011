import { InMemoryCache } from '@apollo/client'
import { Box } from '@material-ui/core'
import { LocalStorageKey, Path } from '@plvs/const'
import { FilterCacheContext } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { useLogout } from '@plvs/rally/libs/auth/loginLogoutHooks'
import React, { useEffect } from 'react'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { useChatClientContext } from '@plvs/rally/containers/chat/ChatClientProvider'
import { useNavigate } from 'react-router-dom'

export const Logout: React.FC = () => {
  const cache = new InMemoryCache()
  const navigate = useNavigate()
  const logout = useLogout(cache)
  const { setEntityId, setEntityType } = React.useContext(FilterCacheContext)
  const { tearDownClient } = useChatClientContext()
  useEffect(() => {
    logout()
    tearDownClient?.()
    setEntityId('')
    setEntityType('')
    localStorage.removeItem(LocalStorageKey.Environment)
    localStorage.removeItem(LocalStorageKey.ProductType)
  }, [])

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      mt={3}
      px={2}
      textAlign="center"
    >
      <NxTypography variant="subtitle1">
        Click below to log in again.
      </NxTypography>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={391}
        mt={2}
        textAlign="center"
        width="100%"
      >
        <NxButton
          data-cy="logout-page-login-button"
          label="Login"
          onClick={(): void => navigate(Path.Login)}
          variant="primary"
        />
      </Box>
    </Box>
  )
}
