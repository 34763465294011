import React from 'react'
import { Navigate } from 'react-router'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import { Path } from '@plvs/const'
import { useGetDisputeRequestQuery } from '@plvs/graphql'
import {
  userCanViewMatchDisputeRequest,
  CanViewMatchDisputeRequest,
} from '@plvs/utils'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { PageContentGutter } from '@plvs/respawn/features/layout'
import { ReturnLink } from '@plvs/rally/components/link'
import { LoadingSpinner } from '@playvs-inc/nexus-components'
import { MatchReportNoMatchWarningBanner } from './MatchReportNoMatchWarningBanner'
import { MatchReportReturnToMatchButton } from './MatchReportReturnToMatchButton'
import { MatchReportMatchInfo } from './MatchReportMatchInfo'
import { MatchDisputeView } from './MatchDisputeView'

dayjs.extend(tz)

export const MatchDisputeViewContainer: React.FC<{
  matchId: string
}> = ({ matchId }) => {
  const { data, loading } = useGetDisputeRequestQuery({
    variables: { matchId },
  })

  const match = data?.match
  const myTeam = [data?.match?.team1, data?.match?.team2].find(
    (team) => team?.isMyTeam
  )

  const pathToMatchLobby = `${Path.Match}/${matchId}`

  return (
    <PageContentGutter>
      <ReturnLink path={pathToMatchLobby} title="Match Lobby" />
      {((): React.ReactElement => {
        if (loading) return <LoadingSpinner />
        if (match) {
          if (!myTeam) return <Navigate to={pathToMatchLobby} />

          const { canView, request } = userCanViewMatchDisputeRequest({
            isTeamCoach: !!myTeam,
            match,
          })

          switch (canView) {
            case CanViewMatchDisputeRequest.NoBecauseUserIsNotACoachOfMatchTeam:
              return <Navigate to={pathToMatchLobby} />
            case CanViewMatchDisputeRequest.NoBecausePendingDisputeDoesNotExist:
              return (
                <Banner
                  subtitle="Please return to the match lobby."
                  title="No disputes to view at this time"
                  type={BannerType.Warning}
                >
                  <MatchReportReturnToMatchButton matchId={matchId} />
                </Banner>
              )
            case CanViewMatchDisputeRequest.Yes:
            default:
              return request ? (
                <MatchCard
                  py={3}
                  sideContent={<MatchReportMatchInfo match={match} />}
                  title="Disputed results"
                  titleDetail={`Submitted by ${
                    request.requestedBy?.name ?? '???'
                  } of the ${request.team?.name ?? '???'} on ${dayjs
                    .tz(request.createdAt, dayjs.tz.guess())
                    .format('MMMM D, YYYY h:mm A z')}`}
                >
                  <MatchDisputeView request={request} />
                </MatchCard>
              ) : (
                // This will never display.
                <>Missing request information.</>
              )
          }
        }
        return <MatchReportNoMatchWarningBanner matchId={matchId} />
      })()}
    </PageContentGutter>
  )
}
