import React, { CSSProperties } from 'react'

import SelectObject from './SelectObject'

interface Option {
  id: string
  name: string
}

interface FilterButtonProps<T> {
  id: string
  option: T
  options: T[]
  setOption(input: T): void
  style?: CSSProperties
}
export function FilterButton<T extends Option>({
  option,
  options,
  id,
  setOption,
  style,
}: FilterButtonProps<T>): React.ReactElement {
  return (
    <SelectObject
      id={id}
      option={option}
      options={options}
      setOption={setOption}
      style={
        style || {
          marginTop: 8,
        }
      }
    />
  )
}

export default FilterButton
