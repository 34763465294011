import React from 'react'
import { makeStyles, Box, useTheme } from '@material-ui/core'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { TaskState } from '@plvs/graphql/generated'
import { Path } from '@plvs/const'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  VerificationDarkSmallPrimary,
  VerificationLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import { Clock } from '@playvs-inc/nexus-icons'
import { useNavigate } from 'react-router-dom'
import { TaskCtaButton } from '../components/TaskCtaButton'

const useStyles = makeStyles((theme) => ({
  pendingContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  verificationPending: {
    color: theme.palette.ColorTextAlt,
  },
  pendingIcon: {
    alignSelf: 'flex-start',
  },
  pendingHelp: {
    color: theme.palette.ColorTextAlt2,
  },
  intercomLink: {
    color: theme.palette.ColorTextLink,
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const ScholasticVerificationTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isPendingVerification: boolean
  isMobile: boolean
  index: number
}> = ({ task, isPendingVerification, isMobile, index }) => {
  const navigate = useNavigate()
  const mobile = useBreakpointXs()
  const classes = useStyles({ mobile })
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const onNavigateToDocUpload = (): void => {
    navigate(Path.ScholasticVerification)
  }

  const isCompleted = task.state === TaskState.Completed

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography
              data-cy="ensure-players-connect-accounts"
              variant="h4"
            >
              Verify Your Employment
            </NxTypography>
          </Box>
          <Box>
            <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
              Thanks for helping us keep PlayVS a safe place
            </NxTypography>
          </Box>
        </>
      }
      taskCta={
        <>
          {!isPendingVerification || isCompleted ? (
            <TaskCtaButton
              buttonText="Upload Documents"
              completed={isCompleted}
              onClick={onNavigateToDocUpload}
              task={task}
            />
          ) : (
            <Box className={classes.pendingContainer}>
              <Clock height="20" width="20" />
              <Box ml={1}>
                <NxTypography
                  className={classes.verificationPending}
                  variant="body5"
                >
                  Verification in progress
                </NxTypography>
                <NxTypography className={classes.pendingHelp} variant="body5">
                  {`It's been over 48 hours. `}
                  <NxTypography
                    className={classes.intercomLink}
                    colorToken="ColorTextLink"
                    onClick={openIntercom}
                    variant="body5"
                  >
                    Contact Us
                  </NxTypography>
                </NxTypography>
              </Box>
            </Box>
          )}
        </>
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {isDarkMode ? (
            <VerificationDarkSmallPrimary />
          ) : (
            <VerificationLightSmallPrimary />
          )}
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
