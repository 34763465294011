export const INVALID_PASSWORD_RESET_LINK_ERROR =
  'Oops! It seems like your password reset link is either invalid or has expired. Please enter your email again to receive a new link.'

export const getEmailHelperText = (
  formError: string | undefined,
  errorQueryParam: string | null
): string | undefined => {
  if (formError) {
    return formError
  }

  if (errorQueryParam) {
    return INVALID_PASSWORD_RESET_LINK_ERROR
  }

  return undefined
}
