import React from 'react'
import { noop } from 'ramda-adjunct'

import {
  Trashcan,
  Substitute,
  ArrowRight,
  Promotetoteamcaptain,
  Demoteteamcaptain,
} from '@playvs-inc/nexus-icons'

import {
  DemoteTeamCaptainMenuItem,
  PromoteToTeamCaptainMenuItem,
} from '@plvs/respawn/features/manage-teams/ManageTeamCaptains'
import { RemovePlayerFromTeam } from '@plvs/respawn/features/manage-teams/RemovePlayerFromTeam'
import { canRemoveTeamMembers } from '@plvs/utils'
import { MoveTeamDialog } from '@plvs/respawn/features/manage-teams/MoveTeamDialog'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { RepositionPlayersInTeam } from '@plvs/respawn/features/manage-teams/RespositionPlayersInTeam'
import { RosterCardMenuRowSubItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { ContentContextPermissions } from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RowMenuProps } from '../../RosterProvider.types'
import { ActionItemProps, RosterRowActions } from './RosterRowActions'
import { useRosterContext } from '../../RosterProvider'

export const RosterRowFilledBenchMenu: React.FC<RowMenuProps> = ({
  member,
  teamId,
  members,
  getPermissions,
  onMutationSuccess,
  metaseason,
  userId,
  rosterCardSubRowActions,
  hasUnlimitedPass,
  isAdmin,
}) => {
  // Checking the latest season pass
  const { teamCaptain, league, isRosterActivelyLocked, enrolledSeason } =
    useRosterContext()

  const { getUsername } = useEsportContext()

  const canRemoveMembers = canRemoveTeamMembers(
    isRosterActivelyLocked,
    enrolledSeason,
    isAdmin
  )

  const currentSeasonPass = member?.user?.seasonPasses?.filter(
    (seasonPass) =>
      metaseason?.id &&
      seasonPass.metaseasonId === metaseason?.id &&
      league?.id === seasonPass?.leagueId &&
      !!seasonPass.consumedAt
  )[0]
  const hasUsedPass = !!currentSeasonPass?.consumedAt
  const flattenedMember = {
    id: member.user?.id || '',
    name: member.user?.name || '',
    position: member.position?.index || 0,
    seasonPasses: member?.user?.seasonPasses ?? undefined,
  }
  const memberRoles =
    members?.find(({ user }) => user && user?.id === flattenedMember.id)?.user
      ?.roles ?? []
  const isSelf = flattenedMember.id === userId

  const allowedActions = getPermissions(
    memberRoles,
    canRemoveMembers
      ? ContentContextPermissions.BENCH_ITEMS_MENU
      : ContentContextPermissions.BENCH_ITEMS_MENU_DISABLED_REMOVAL,
    isSelf
  ) as RosterCardMenuRowSubItems[]
  const filteredAllowedActions = rosterCardSubRowActions?.length
    ? allowedActions.filter((action) =>
        rosterCardSubRowActions.includes(action)
      )
    : allowedActions
  const rosterRowFilledBenchMenuMap: Record<
    RosterCardMenuRowSubItems,
    ActionItemProps
  > = {
    [RosterCardMenuRowSubItems.RemoveFromTeam]: {
      label: 'Remove Player',
      icon: <Trashcan />,
      dialogProps: {
        children: (
          <RemovePlayerFromTeam
            action="remove"
            member={flattenedMember}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Remove Player',
    },
    [RosterCardMenuRowSubItems.LeaveTeam]: {
      label: 'Leave Team',
      icon: <Trashcan />,
      dialogProps: {
        children: (
          <RemovePlayerFromTeam
            action="leave"
            member={flattenedMember}
            onClose={noop}
            teamId={teamId}
          />
        ),
      },
      key: 'Leave Team',
    },
    [RosterCardMenuRowSubItems.SwapSubToTeam]: {
      label: 'Move Team',
      icon: <ArrowRight />,
      dialogProps: {
        children: (
          <MoveTeamDialog
            getUsername={getUsername}
            member={flattenedMember}
            metaseason={metaseason}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
        padding: 0,
        width: 480,
      },
      key: 'Move Team',
      ...(isRosterActivelyLocked && {
        disabled: true,
        tooltipTitle:
          'Unable to move players between teams: Rosters are currently locked',
        tooltipPlacement: 'top',
        tooltipArrow: true,
        tooltipMaxWidth: 224,
      }),
    },
    [RosterCardMenuRowSubItems.RepositionPlayer]: {
      label: 'Move to Roster',
      icon: <Substitute />,
      dialogProps: {
        children: (
          <RepositionPlayersInTeam
            getUsername={getUsername}
            hasUnlimitedPass={hasUnlimitedPass}
            hasUsedPass={hasUsedPass}
            isBench
            metaseasonId={metaseason?.id}
            onClose={noop}
            player={flattenedMember}
            teamId={teamId}
          />
        ),
      },
      key: 'Move to Roster',
    },
    [RosterCardMenuRowSubItems.DisabledSwapSubToTeam]: {
      label: 'Move Team',
      icon: <ArrowRight />,
      key: 'Disabled Move Team',
      disabled: true,
    },
    [RosterCardMenuRowSubItems.DisabledRemoveFromTeam]: {
      label: `Unable to remove player, rosters are locked.`,
      icon: <Trashcan />,
      key: 'Disabled Remove Player',
      disabled: true,
    },
    [RosterCardMenuRowSubItems.DisabledLeaveTeam]: {
      label: `Can’t leave team, rosters are locked.`,
      icon: <Trashcan />,
      key: 'Disabled Leave Team',
      disabled: true,
    },
    [RosterCardMenuRowSubItems.PromoteToTeamCaptain]: {
      label: 'Promote to Captain',
      icon: <Promotetoteamcaptain />,
      dialogProps: {
        children: (
          <PromoteToTeamCaptainMenuItem
            currentTeamCaptainName={teamCaptain?.name ?? ''}
            member={flattenedMember}
            onClose={(): void => {}}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },

      key: 'Promote to Captain',
    },
    [RosterCardMenuRowSubItems.RemoveTeamCaptain]: {
      label: 'Remove Team Captain',
      icon: <Demoteteamcaptain />,
      dialogProps: {
        children: (
          <DemoteTeamCaptainMenuItem
            member={flattenedMember}
            onClose={(): void => {}}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },

      key: 'Remove Team Captain',
    },
  }

  const menuItems = filteredAllowedActions.map((rosterMenuItem) => {
    return rosterRowFilledBenchMenuMap[rosterMenuItem]
  })

  return <RosterRowActions actionItemProps={menuItems} />
}
