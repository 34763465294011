import {
  Esport,
  GetMatchesByDateForScheduleQuery,
  GetTeamsForScheduleMatchesWithSlotsQuery,
  GetTeamMatchesForScheduleQuery,
  CompetitionGroup,
  MatchScheduleContext,
  Maybe,
  Match,
} from '@plvs/graphql'

export type ScheduleMatch = NonNullable<
  NonNullable<GetMatchesByDateForScheduleQuery['season']>['allTeamMatches']
>[0]

export type TeamSlot = NonNullable<
  NonNullable<GetTeamsForScheduleMatchesWithSlotsQuery['teamsByIds']>
>[0]

export type SchedulePageMatch = Match

export type SchedulePageQueueMatch = {
  __typename?: 'QueueMatchDetails'
  slotId: string
  esport: NonNullable<Pick<Esport, 'name' | 'rating' | 'slug'>>
  bestOf: number
  scheduledStartsAt: string
  metaseasonId?: string
  teamId: string
  teamName: string
  schoolLogoUrl: string
  schoolName: string
  competitionGroup: CompetitionGroup
  isScrimmage: boolean
  scheduleContext?: Maybe<MatchScheduleContext>
}

export type ScheduleSeason = NonNullable<
  NonNullable<TeamSlot['seasonTeams']>[0]['season']
>

export type TeamMatch = NonNullable<
  NonNullable<GetTeamMatchesForScheduleQuery['findMatchesByTeamIds']>
>[0]

export type SchedulePhase = NonNullable<
  NonNullable<ScheduleSeason>['phases']
>[0]

export enum SortFilter {
  Upcoming = 'upcoming',
  Past = 'past',
}
