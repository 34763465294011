import { GetTopLevelMatchDataQueryResult } from '@plvs/graphql/generated'
import { GetOnMatchQueueRedirectDataQuery, MatchStatus } from '@plvs/graphql'
import { HeroMatch } from '../types/Lobby.types'

type RawHeroMatch =
  | NonNullable<NonNullable<GetTopLevelMatchDataQueryResult>['data']>['match']
  | null
  | undefined
export const normalizeMatchForHero = (match: RawHeroMatch): HeroMatch => {
  return {
    id: match?.id ?? '',
    status: match?.status ?? undefined,
    team1: {
      id: match?.team1?.id ?? '',
      schoolName: match?.team1?.school?.name ?? '',
      name: match?.team1?.name ?? '',
      avatarUrl: match?.team1?.avatarUrl ?? '',
      schoolId: match?.team1?.school?.id ?? '',
    },
    team2: {
      id: match?.team2?.id ?? '',
      schoolName: match?.team2?.school?.name ?? '',
      name: match?.team2?.name ?? '',
      avatarUrl: match?.team2?.avatarUrl ?? '',
      schoolId: match?.team2?.school?.id ?? '',
    },
    bestOf: match?.bestOf ?? 0,
  }
}

export const shouldDetermineMatchResultsForQueue = ({
  status,
  hasQueueData,
  canEnterTheQueue,
}: {
  status: MatchStatus | undefined
  hasQueueData: boolean
  canEnterTheQueue: boolean
}): boolean => {
  const isForfeitedMatch = status === MatchStatus.Forfeited
  return isForfeitedMatch && hasQueueData && canEnterTheQueue
}

export const determineTeamToBeRedirected = ({
  queueRedirectData,
  teamId,
  team1Id,
  team2Id,
}: {
  queueRedirectData: GetOnMatchQueueRedirectDataQuery | undefined
  teamId: string
  team1Id: string
  team2Id: string
}): {
  teamToBeRedirectedId: string
  canRedirect: boolean
} => {
  const hasMatchResultsPlacing = (
    queueRedirectData?.match?.matchResults ?? []
  ).some((result) => result.placing !== null)

  const aTeamNeedsResults = (queueRedirectData?.match?.matchResults ?? []).some(
    (result) => result.placing === null
  )

  const losingTeam = queueRedirectData?.match?.matchResults?.find(
    (result) => result.placing === 2 || result.placing === 1
  )

  const myTeamIsNotLosingTeam = teamId !== (losingTeam?.teamId ?? '')

  // Redirect the team who won by forfeit.
  const teamToBeRedirectedId =
    losingTeam?.teamId === team1Id ? team2Id : team1Id

  if (
    hasMatchResultsPlacing &&
    teamToBeRedirectedId &&
    aTeamNeedsResults &&
    myTeamIsNotLosingTeam
  ) {
    return {
      teamToBeRedirectedId,
      canRedirect: true,
    }
  }

  return {
    teamToBeRedirectedId: '',
    canRedirect: false,
  }
}
