import React from 'react'
import { SimpleDialog, SimpleDialogProps } from '@plvs/respawn/features/dialog'
import { NxIconButton, NxTooltip } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

type DialogProps = Omit<SimpleDialogProps, 'children' | 'onClose' | 'open'> & {
  children: React.ReactElement
}

type TooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'

export interface ActionItemProps {
  dialogProps?: DialogProps
  disabled?: boolean
  label: string
  key?: string
  onClick?(e: React.SyntheticEvent): void
  tooltipTitle?: string
  tooltipPlacement?: TooltipPlacement
  tooltipArrow?: boolean
  tooltipMaxWidth?: number
  icon: React.ReactElement
  className?: string
}

interface RosterRowActionsProps {
  className?: string
  actionItemProps: ActionItemProps[]
  iconClassName?: string
}

const useStyles = makeStyles(() => ({
  actionsWrapper: {
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: 'transparent !important',
  },
}))

export const RosterRowActions: React.FC<RosterRowActionsProps> = ({
  className,
  actionItemProps,
}) => {
  const styles = useStyles()
  const [openDialogProps, setOpenDialogProps] = React.useState<{
    open: boolean
    dialogProps?: DialogProps
    hidden?: boolean
  }>({ open: false, hidden: false })

  // inject onClose prop in children, so children can close the dialog
  const children = openDialogProps?.dialogProps?.children
  const onClose = (): void =>
    setOpenDialogProps({ ...openDialogProps, open: false })
  const clonedChildren = children
    ? React.cloneElement(children, { onClose })
    : undefined
  const menuItems = actionItemProps.map(
    ({
      dialogProps,
      label,
      onClick,
      key,
      tooltipTitle = '',
      tooltipPlacement = 'bottom',
      tooltipArrow = false,
      tooltipMaxWidth,
      icon,
      disabled,
      ...rest
    }): React.ReactElement | null => {
      return (
        <NxTooltip
          key={typeof label === 'string' ? label : key}
          arrow={tooltipArrow}
          maxWidth={tooltipMaxWidth}
          placement={tooltipPlacement}
          title={tooltipTitle || label}
        >
          <div>
            <NxIconButton
              className={disabled ? styles.iconButton : ''}
              data-cy={key}
              data-testid={key}
              disabled={disabled}
              icon={icon}
              onClick={(e): void => {
                if (onClick) onClick(e)
                if (dialogProps) setOpenDialogProps({ open: true, dialogProps })
              }}
              p="0.25em"
              variant="text"
              {...rest}
            />
          </div>
        </NxTooltip>
      )
    }
  )

  return menuItems.length ? (
    <div
      className={clsx(className, styles.actionsWrapper)}
      data-testid="rosterRowActions"
    >
      {menuItems}

      <SimpleDialog
        hidden={openDialogProps?.hidden}
        onClose={onClose}
        open={!!openDialogProps?.open}
        {...openDialogProps?.dialogProps}
      >
        {clonedChildren}
      </SimpleDialog>
    </div>
  ) : (
    <></>
  )
}
