import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
  enrolledCheck: {
    fill: Colors.Sapphire,
    marginLeft: theme.spacing(1),
  },
  unenrolledCheck: {
    fill: Colors.Grey12,
    marginLeft: theme.spacing(1),
  },
  enrolledTypography: {
    color: Colors.Sapphire,
  },
  unenrolledTypography: {
    color: Colors.Grey12,
  },
  cta: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  arrow: {
    height: '12px',
    width: theme.spacing(3),
    color: Colors.Black,
  },
  menuItemIcon: {
    marginRight: theme.spacing(1),
  },
}))
