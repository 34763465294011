import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import React from 'react'
import { CreateCSSProperties } from '@material-ui/styles'
import clsx from 'clsx'
import { RouterNavLink } from '@plvs/respawn/features/route'
import { useLocation } from 'react-router-dom'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { noop } from 'ramda-adjunct'
import { LinkObj } from '@plvs/respawn/features/app-drawer/types'

const useStyles = makeStyles((theme) => ({
  container: ({
    isExpanded,
  }: {
    isExpanded: boolean
  }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '94vh',
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    width: isExpanded ? theme.spacing(35) : theme.spacing(12),
    margin: theme.spacing(4, 3, 4, 3),
    padding: 0,
    borderRadius: theme.shape.borderRadius * 2.5,
    backgroundColor: 'white',
    boxShadow: theme.mixins.boxShadow.elevation1,
    transition: 'width 200ms ease',
  }),
  dashNav: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dashboardIcon: {
    height: theme.spacing(9.25),
    width: theme.spacing(9.25),
  },
  title: {
    fontSize: '3rem',
    fontWeight: 700,
    marginRight: theme.spacing(1.5),
    letterSpacing: '-1px',
  },
  list: {
    padding: 0,
    width: '100%',
  },
  linkRoot: {
    // Increasing for more rounded view of navigation items making them more prominent - Design
    borderRadius: theme.shape.borderRadius * 1.5,
    color: theme.palette.ColorTextBase,
    height: theme.spacing(6.25),
    width: theme.spacing(30),
    margin: theme.spacing(1, 2.5, 0, 2.5),
    padding: 0,
    position: 'relative',
    cursor: 'pointer',
  },
  activeContainer: {
    position: 'relative',
    transition: 'transform 200ms ease, font-weight 100ms ease',
    color: theme.palette.BorderDark,
    fontWeight: theme.typography.h1.fontWeight,
  },
  linkHover: {
    '&:hover': {
      '& svg': {
        width: theme.spacing(3),
        height: theme.spacing(3),
        opacity: 1,
      },
      backgroundColor: theme.palette.ColorBackgroundAlt,
    },
  },
  linkActive: {
    '&:before': {
      width: theme.spacing(0.5),
      height: theme.spacing(5),
      left: 0,
      top: '5px',
      backgroundColor: theme.palette.ColorIconLink,
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
      visibility: 'visible',
      position: 'absolute',
      content: `''`,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '&:hover': {
      '&:before': {
        backgroundColor: theme.palette.ColorBackgroundAlt,
      },
      backgroundColor: theme.palette.ColorBackgroundAlt,
    },
  },
  textLabel: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
    },
  },
  navHover: {
    width: '100%',
    marginLeft: theme.spacing(3.5),
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
}))

interface Props {
  isExpanded: boolean
  navigationLinks: LinkObj[]
}

export const AppDrawerLinks = ({
  isExpanded,
  navigationLinks,
}: Props): React.ReactElement => {
  const classes = useStyles({ isExpanded })
  const location = useLocation()

  return (
    <Box>
      <List className={classes.list}>
        {navigationLinks.map(
          ({
            exact,
            label,
            icon: Icon,
            activeIcon: ActiveIcon,
            to,
            color,
            handleOnClick,
            isRoutingToCurrLocation,
            secondaryIcon,
            disabled = false,
            className,
          }) => {
            const listItem = (
              <ListItem
                key={to ?? ''}
                // @ts-ignore
                activeClassName={
                  !isRoutingToCurrLocation && !disabled
                    ? classes.linkActive
                    : ''
                }
                className={clsx(classes.linkRoot, classes.linkHover, className)}
                color={color}
                component={RouterNavLink}
                data-cy={`${disabled ? 'disabled' : ''} ${label}`}
                disabled={disabled}
                exact={exact}
                onClick={disabled ? noop : handleOnClick}
                to={
                  isRoutingToCurrLocation || disabled ? location.pathname : to
                }
              >
                <Box
                  alignItems="center"
                  className={classes.navHover}
                  display="flex"
                  flexDirection="row"
                >
                  {Icon && ActiveIcon && (
                    <Box alignItems="center" display="flex" mr={1.5}>
                      {!isRoutingToCurrLocation &&
                      !disabled &&
                      to &&
                      location.pathname.includes(to) ? (
                        <ActiveIcon className={classes.icon} />
                      ) : (
                        <Icon className={classes.icon} />
                      )}
                    </Box>
                  )}
                  {isExpanded && (
                    <ListItemText
                      className="active-container"
                      primary={
                        <NxTypography
                          className={clsx(
                            classes.textLabel,
                            to && location.pathname.includes(to)
                              ? classes.activeContainer
                              : ''
                          )}
                          data-cy={label}
                        >
                          {label}
                        </NxTypography>
                      }
                    />
                  )}
                  {!disabled && secondaryIcon}
                </Box>
              </ListItem>
            )

            if (disabled) {
              return (
                <NxTooltip
                  key={to ?? ''}
                  arrow
                  placement="bottom"
                  title="Sign up to unlock this feature"
                >
                  <Box>{listItem}</Box>
                </NxTooltip>
              )
            }

            return listItem
          }
        )}
      </List>
    </Box>
  )
}
