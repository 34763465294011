import { Button } from '@material-ui/core'
import React from 'react'

import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { cleanGraphQLError } from '@plvs/utils'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'

export const MatchReportErrorBanner: React.FC<{
  subtitle?: string
  title?: string
}> = ({ title: titleFromProps, subtitle: subtitleFromProps }) => {
  const title = titleFromProps || 'Unable to report results'
  const subtitle =
    subtitleFromProps ||
    'Something went wrong. Please try submitting results again. If this problem persists, please contact support.'
  return (
    <div style={{ marginBottom: 24 }}>
      <Banner
        subtitle={cleanGraphQLError(subtitle)}
        title={title}
        type={BannerType.Error}
      >
        <Button onClick={openIntercom} variant="outlined">
          Contact Support
        </Button>
      </Banner>
    </div>
  )
}
