import { EsportSlug, GetGeneralEsportAdapterDetailsQuery } from '@plvs/graphql'

import { GeneralEsportAdapter } from '../creator/types'
import {
  allEsportAdaptersMap,
  generateEsportDetails,
  useGeneralEsportAdapter,
} from '../creator'

export const getAdapterNoHook = (
  slug: EsportSlug | null,
  esportDetailsData: GetGeneralEsportAdapterDetailsQuery | undefined,
  loading: boolean
): GeneralEsportAdapter => {
  if (!slug) return generateEsportDetails(slug, esportDetailsData, loading)
  return allEsportAdaptersMap(esportDetailsData, loading)[
    slug
  ] as GeneralEsportAdapter
}

export const useGetAdapter = (
  slug: EsportSlug | null
): GeneralEsportAdapter => {
  const adapter = useGeneralEsportAdapter(slug)

  return adapter
}
