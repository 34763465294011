import { makeStyles } from '@material-ui/core'
import React from 'react'

import {
  GetDisputeRequestQuery,
  GetMatchReportHistoryQuery,
} from '@plvs/graphql'
import { DisplayAttachedScreenshot } from '@plvs/rally/components/form'
import { NxTypography } from '@playvs-inc/nexus-components'
import { printReason } from './MatchDisputeForm'

type Dispute1 = NonNullable<
  NonNullable<GetDisputeRequestQuery['match']>['matchDisputeRequests']
>[0]
type Dispute2 = NonNullable<
  NonNullable<GetMatchReportHistoryQuery['match']>['matchDisputeRequests']
>[0]
type Dispute = Dispute1 | Dispute2

const useStyles = makeStyles({
  item: {
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },

  asset: {
    marginTop: 12,
  },
})

export const MatchDisputeView: React.FC<{
  request: Dispute
}> = ({ request }) => {
  const classes = useStyles()
  const reason = request?.reason
  const notes = request?.notes
  const asset = request?.asset

  return (
    <>
      {reason && (
        <div className={classes.item}>
          <NxTypography colorToken="ColorTextAlt" variant="body2">
            Reason for dispute
          </NxTypography>
          <NxTypography variant="body1">{printReason[reason]}</NxTypography>
        </div>
      )}
      {notes && (
        <div className={classes.item}>
          <NxTypography colorToken="ColorTextAlt" variant="body2">
            Tell us what happened
          </NxTypography>
          <NxTypography variant="body1">{notes}</NxTypography>
        </div>
      )}
      {asset && (
        <div className={classes.item}>
          <NxTypography colorToken="ColorTextAlt" variant="body2">
            Attachments
          </NxTypography>
          <div className={classes.asset}>
            <DisplayAttachedScreenshot attachment={asset} />
          </div>
        </div>
      )}
    </>
  )
}
