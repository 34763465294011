import React from 'react'
import {
  EsportRating,
  MatchSettleRequestFragment,
} from '@plvs/graphql/generated'
import { Box, makeStyles } from '@material-ui/core'

import {
  NxScoreReviewCard,
  NxTextLink,
  NxTypography,
  NxTextArea,
} from '@playvs-inc/nexus-components'

import { baseThemeTypography } from '@playvs-inc/nexus-theme'

import { DmrMatch, LobbyTeam } from '@plvs/utils'
import { ReportStepIndex } from '../dynamic.types'

export const useStyles = makeStyles((theme) => {
  const typography = baseThemeTypography(theme)

  return {
    contentTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    },
    dividerContainer: {
      margin: 'auto',
      width: '100%',
    },
    divider: {
      backgroundColor: theme.palette.ColorBackgroundAlt3,
    },
    titleLabel: {
      padding: theme.spacing(1.875),
      whiteSpace: 'nowrap',
    },
    winnerCardListContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    notesContainer: {
      marginTop: theme.spacing(4),
    },
    textContainer: {
      display: 'flex',
    },
    textarea: {
      width: 610,
      height: 54,
      minHeight: 54,

      border: `1px solid ${theme.palette.BorderMedium}`,
      borderRadius: theme.shape.borderRadius * 2.5,

      color: theme.palette.ColorTextBase,
      background: theme.palette.ColorBackgroundBase,

      padding: theme.spacing(2, 2),
      marginTop: theme.spacing(1),

      fontFamily: typography.fontFamily,
      ...typography.body3,

      '&::placeholder': {
        color: theme.palette.ColorTextAlt2,
        fontFamily: typography.fontFamily,
        ...typography.body3,
      },
    },
  }
})

interface MatchScoreReviewProps {
  matchSettleRequest: MatchSettleRequestFragment | undefined | null
  match: DmrMatch
  notes: string
  myTeam: LobbyTeam | undefined
  oppTeam: LobbyTeam | undefined
  isSeriesMatch: boolean
  setExpandedGameIndex(index: number): void
  setSelectedSeriesIndex(index: number): void
  setStepIndex(index: ReportStepIndex): void
  setNotes(notes: string): void
}

export const MatchScoreReview: React.FC<MatchScoreReviewProps> = ({
  matchSettleRequest,
  match,
  notes,
  myTeam,
  oppTeam,
  isSeriesMatch,
  setExpandedGameIndex,
  setSelectedSeriesIndex,
  setStepIndex,
  setNotes,
}) => {
  const classes = useStyles()
  const isYouthProgram = match?.esport?.rating === EsportRating.Restricted
  const myTeamId = myTeam?.id ?? ''
  const oppTeamId = oppTeam?.id ?? ''

  const getNonSeriesResults = (): React.ReactNode =>
    matchSettleRequest?.results?.seriesResults?.[0]?.gameResults.map(
      (gameResult, index) => {
        const winningTeam =
          gameResult.winningTeamId === myTeam?.id ? myTeam : oppTeam
        const title: string | undefined | null = winningTeam?.name
        const winningTeamSchoolLogo = winningTeam?.school?.logoUrl
        const winningTeamSchoolId = winningTeam?.id
        const hasAttachments = Boolean(gameResult.assets?.length)

        return (
          <Box key={`nonseries-${gameResult.ordinalNumber || index}`}>
            <NxScoreReviewCard
              screenShotsAttached={hasAttachments}
              title={`Game ${gameResult.ordinalNumber} Winner`}
              winner={{
                title: title ?? '??',
                subtitle: (
                  <NxTextLink
                    label="Edit"
                    labelVariant="body4"
                    onClick={(): void => {
                      setExpandedGameIndex(index)
                      setStepIndex(ReportStepIndex.GameSelectionIndex)
                    }}
                  />
                ),
                avatarUrl:
                  !isYouthProgram && winningTeamSchoolLogo
                    ? winningTeamSchoolLogo
                    : undefined,
                avatarHashId: winningTeamSchoolId ?? undefined,
              }}
            />
          </Box>
        )
      }
    )

  const getSeriesResults = (): React.ReactNode =>
    matchSettleRequest?.results?.seriesResults?.map((currSeriesRes, index) => {
      const currSeries = match?.series?.[index]
      const winningTeam: LobbyTeam | null | undefined =
        currSeriesRes.winningTeamId === myTeam?.id ? myTeam : oppTeam
      const mySeriesTeam =
        currSeries?.team1?.id === myTeamId
          ? currSeries?.team1
          : currSeries?.team2
      const oppSeriesTeam =
        currSeries?.team1?.id === myTeamId
          ? currSeries?.team2
          : currSeries?.team1
      const seriesWinningTeam =
        winningTeam?.id === myTeamId ? mySeriesTeam : oppSeriesTeam
      const winningTeamSchoolLogo = winningTeam?.school?.logoUrl
      const winningTeamSchoolId = winningTeam?.school?.id
      const hasAttachments = Boolean(
        currSeriesRes.gameResults.flatMap(({ assets }) => assets).length
      )

      const myTeamScore =
        currSeriesRes.gameResults.filter(
          (currGameRes) => currGameRes.winningTeamId === myTeamId
        ).length ?? 0
      const oppTeamScore =
        currSeriesRes.gameResults.filter(
          (currGameRes) => currGameRes.winningTeamId === oppTeamId
        ).length ?? 0

      return (
        <Box key={`series-${currSeries?.id || index}`}>
          <NxScoreReviewCard
            score={[myTeamScore, oppTeamScore]}
            screenShotsAttached={hasAttachments}
            title={`Series ${currSeriesRes.ordinalNumber} Winner`}
            winner={{
              title: seriesWinningTeam?.roster?.[0]?.name ?? '??',
              subtitle: (
                <NxTextLink
                  label="Edit"
                  labelVariant="body4"
                  onClick={(): void => {
                    setSelectedSeriesIndex(index)
                    setExpandedGameIndex(0)
                    setStepIndex(ReportStepIndex.GameSelectionIndex)
                  }}
                />
              ),
              avatarUrl: seriesWinningTeam?.roster?.[0]?.avatarUrl ?? undefined,
              avatarHashId: seriesWinningTeam?.roster?.[0]?.id ?? undefined,
              subAvatarUrl:
                !isYouthProgram && winningTeamSchoolLogo
                  ? winningTeamSchoolLogo
                  : undefined,
              subAvatarHashId: winningTeamSchoolId ?? undefined,
              showSubAvatar: true,
            }}
          />
        </Box>
      )
    })

  const handleNoteChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    evt.preventDefault()
    setNotes(evt.target.value)
  }

  return (
    <Box pt={2}>
      <NxTypography variant="h2">Settle the Match</NxTypography>
      <NxTypography colorToken="ColorTextAlt" variant="body3">
        You&apos;re almost done!
      </NxTypography>

      <Box className={classes.winnerCardListContainer}>
        {isSeriesMatch ? getSeriesResults() : getNonSeriesResults()}
      </Box>

      <Box className={classes.notesContainer}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="match-notes">
          <NxTypography variant="body2">
            Let us know if you have any other information you’d like to share.
          </NxTypography>
        </label>

        <Box mt={1}>
          <NxTextArea
            fullWidth
            id="match-notes"
            label=""
            name="match-notes"
            onChange={handleNoteChange}
            placeholder="Type here..."
            value={notes}
          />
        </Box>
      </Box>
    </Box>
  )
}
