import React from 'react'
import { Box, Card, makeStyles } from '@material-ui/core'

import {
  SchedulePageMatch,
  SchedulePageQueueMatch,
  TeamMatch,
} from '@plvs/rally/features/match/schedule/schedule'
import { groupBy } from 'ramda'
import { NxTypography } from '@playvs-inc/nexus-components'
import { MinimalMetaseason } from '@plvs/utils'
import { getDay } from 'date-fns'
import { MatchTimeGroup } from './MatchTimeGroup'

const useStyles = makeStyles((theme) => ({
  matchGroupContainer: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.BorderLight}`,
    },
  },
}))

type MatchDayGroupProps = {
  date: string
  matches: (TeamMatch | SchedulePageMatch | SchedulePageQueueMatch)[]
  past: boolean
  mobile: boolean
  metaseasons: MinimalMetaseason[]
}

const groupMatchesByTime = (
  matches: (TeamMatch | SchedulePageMatch | SchedulePageQueueMatch)[]
): Record<
  string,
  (TeamMatch | SchedulePageMatch | SchedulePageQueueMatch)[]
> => {
  const formatTime = ({
    scheduledStartsAt,
  }: TeamMatch | SchedulePageMatch | SchedulePageQueueMatch): string => {
    if (!scheduledStartsAt) return '?'
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(scheduledStartsAt))
  }
  return matches.reduce<
    Record<string, (TeamMatch | SchedulePageMatch | SchedulePageQueueMatch)[]>
  >((prev, match) => {
    const date = formatTime(match)
    const result = { ...prev }
    if (!(date in result)) {
      result[date] = []
    }
    result[date].push(match)
    return result
  }, {})
}

export const MatchDayGroup: React.FC<MatchDayGroupProps> = ({
  date,
  matches,
  past,
  mobile,
  metaseasons,
}) => {
  const styles = useStyles()

  // Adding logic here that will allow for matches of the same day but different metaseasons to be grouped apart,
  // but also allow for matches such as scrimmages that do not have a metaseasonId but are of the same day to be grouped together.
  const groupedByMetaseason = groupBy((match) => {
    const metaseasonId = match.metaseasonId ?? ''
    if (metaseasonId) {
      return metaseasonId
    }
    const dayToMatchOn = getDay(new Date(match.scheduledStartsAt ?? ''))
    return (
      matches.find((m) => {
        const day = getDay(new Date(m.scheduledStartsAt ?? ''))

        return day === dayToMatchOn && m.metaseasonId !== match.metaseasonId
      })?.metaseasonId ?? ''
    )
  }, matches)
  const metaseasonKeys = Object.keys(groupedByMetaseason)

  return (
    <Box
      data-testid="match-day-group"
      display="flex"
      flexDirection="column"
      mb={5}
    >
      {metaseasonKeys.map((metaseasonId) => {
        const metaseasonGroup = groupMatchesByTime(
          groupedByMetaseason[metaseasonId]
        )

        const groupedByMetaseasonTimesKeys = Object.keys(metaseasonGroup)
        const metaseason = metaseasons.find((m) => m.id === metaseasonId)
        return (
          <Box key={metaseasonId}>
            <Box mb={2}>
              <NxTypography
                data-testid="MathDayGroup_Title_General"
                variant="overline"
              >
                {date} · {metaseason?.name}
              </NxTypography>
            </Box>
            <Card>
              <Box>
                {groupedByMetaseasonTimesKeys.map((time) => (
                  <Box
                    key={time}
                    className={styles.matchGroupContainer}
                    mx={3}
                    my={3}
                  >
                    <MatchTimeGroup
                      matches={metaseasonGroup[time]}
                      mobile={mobile}
                      past={past}
                      time={time}
                    />
                  </Box>
                ))}
              </Box>
            </Card>
          </Box>
        )
      })}
    </Box>
  )
}
