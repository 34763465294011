import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CopiableField } from '../../form/CopiableField'

const useStyles = makeStyles({
  inviteLinkValue: {
    opacity: 0.5,
    fontVariant: 'body2',
    fontSize: '16px',
  },
  typography: {
    fontSize: '16px',
  },
})
export interface TeamInviteProps {
  inviteUrl: string
}

export const TeamInvite: React.FC<TeamInviteProps> = ({ inviteUrl }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <form data-cy="invite-team-copy-link-form" noValidate>
      <Box
        alignItems="center"
        bgcolor={theme.palette.ColorBackgroundAlt2}
        borderRadius="8px"
        display="flex"
        height="54px"
      >
        <Box color={theme.palette.ColorTextBase} display="flex" px={3}>
          <NxTypography
            className={classes.typography}
            color="inherit"
            variant="body2"
          >
            Share Invite
          </NxTypography>
        </Box>
        <Box display="flex" flex={1} pr={2}>
          <CopiableField
            className={classes.inviteLinkValue}
            disableUnderline
            fullWidth
            value={inviteUrl}
          />
        </Box>
      </Box>
    </form>
  )
}
