import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { OverlayPill } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Game, GameResult, Maybe } from '@plvs/graphql'
import { Path } from '@plvs/const'
import {
  NxAvatarSize,
  NxUserAvatar,
  sizeToNxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { GameResultDots } from './GameResultDots'

const useStyles = makeStyles((theme) => ({
  teamPill: {
    color: theme.palette.common.black,
  },
}))

export interface MatchFaceoffTeamAvatarProps {
  bestOf: number
  games: (Pick<Game, 'id'> & {
    gameResults: Maybe<Pick<GameResult, 'id' | 'placing' | 'teamId'>[]>
  })[]
  reverse?: boolean
  showTeamColorBadge?: boolean
  size: 'small' | 'medium' | 'large'
  team: {
    id: string
    teamName: string
    avatarUrl: string
    schoolName?: string
  }
  disableGameDots?: boolean
  isHomeTeam?: boolean
  seriesPlacings: (Maybe<number> | undefined)[]
  winningTeam: boolean
  isSmallBreakpoint: boolean
}

export const DesktopMatchFaceoffTeamAvatar: React.FC<MatchFaceoffTeamAvatarProps> = ({
  reverse,
  size,
  team,
  bestOf,
  games,
  showTeamColorBadge,
  disableGameDots,
  isHomeTeam,
  seriesPlacings,
  winningTeam,
  isSmallBreakpoint,
}) => {
  const teamTitle = `${isHomeTeam ? 'Home' : 'Away'} Team`
  const classes = useStyles()

  const gameDots = (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pl={reverse ? 0 : 4}
        pr={reverse ? 4 : 0}
      >
        {showTeamColorBadge && (
          <Box
            display="flex"
            flexDirection={reverse ? 'row-reverse' : 'row'}
            mb={2}
          >
            <OverlayPill
              className={classes.teamPill}
              data-cy="home-away"
              label={teamTitle}
              size="small"
              variant="disabled"
            />
            {winningTeam && (
              <Box data-cy="winner" ml={reverse ? 0 : 2} mr={reverse ? 2 : 0}>
                <OverlayPill label="Winner" size="small" variant="success" />
              </Box>
            )}
          </Box>
        )}
        {!disableGameDots && (
          <GameResultDots
            bestOf={bestOf}
            games={games}
            reverse={reverse}
            seriesPlacings={seriesPlacings}
            size={size}
            teamId={team.id}
          />
        )}
      </Box>
    </>
  )

  const avatarSize = isSmallBreakpoint
    ? NxAvatarSize.MD
    : sizeToNxAvatarSize[size]

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={reverse ? 'row-reverse' : 'row'}
    >
      {team.id ? (
        <Link color="inherit" to={`${Path.Team}/${team.id}`}>
          <NxUserAvatar
            avatarUrl={team.avatarUrl}
            hashId={team.id}
            size={avatarSize}
          />
        </Link>
      ) : (
        <Box>
          <NxUserAvatar
            avatarUrl={team.avatarUrl}
            hashId={team.id}
            size={avatarSize}
          />
        </Box>
      )}
      {gameDots}
    </Box>
  )
}
