import React from 'react'

export const ForwardRef = React.forwardRef(
  (
    { children, ...rest }: React.HTMLAttributes<HTMLDivElement>,
    ref: React.Ref<HTMLDivElement>
  ): React.ReactElement => (
    <div ref={ref} style={{ display: 'inline-block' }} {...rest}>
      {children}
    </div>
  )
)
