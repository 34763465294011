import { CreateCSSProperties } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'

export const useBracketStyles = makeStyles((theme) => ({
  root: ({ toggled }: { toggled: boolean }): CreateCSSProperties => ({
    '& .slick-slider': {
      height: toggled ? 775 : '100%',
      overflow: 'auto',
    },
    '& .slick-prev, & .slick-next': {
      backgroundColor: Colors.Orange,
      border: '1px solid',
      borderColor: Colors.Orange,
      borderRadius: '50%',
      color: Colors.Grey6,
      height: 24,
      width: 24,
      top: 64,
      transition: 'all 100ms ease',
      zIndex: 20,
    },
    '& .slick-prev': {
      left: 24,
    },
    '& .slick-next': {
      right: 24,
    },
    '& .slick-prev > svg': {
      position: 'relative',
      left: -2,
      top: -1.5,
    },
    '& .slick-next > svg': {
      position: 'relative',
      right: 0,
      top: -1.5,
    },
    '& .slick-prev.slick-disabled, & .slick-next.slick-disabled': {
      color: Colors.Grey1,
      backgroundColor: 'transparent',
      borderColor: Colors.Grey3,
    },
    '& .slick-prev:before, & .slick-next:before': {
      content: 'none',
    },
    '& .slick-track': {
      display: 'flex',
      overflow: 'auto',
      margin: 0,
    },
    '& .slick-slide': {
      float: 'none',
      height: 'auto',
      minHeight: 'unset',
      width: '300px !important',
    },
    '& .slick-initialized .slick-slide': {
      display: 'flex',
    },
    '& .slick-slide > div': {
      display: 'flex',
      flex: 1,
      minWidth: 1,
      outline: 'none',
    },
  }),
  showMoreText: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  downArrowButton: {
    marginLeft: theme.spacing(1.5),
    border: `1px solid ${Colors.Grey12}`,
  },
}))
