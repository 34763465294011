import { Dialog as MuiDialog } from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import React from 'react'

export type SimpleDialogProps = DialogProps & {
  onClose(): void
  width?: string | number
  padding?: string | number
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
  children,
  onClose,
  width,
  padding,
  ...rest
}) => {
  const style: Pick<SimpleDialogProps, 'width' | 'padding'> = {}
  if (width) {
    style.width = width
  }
  if (padding !== undefined) {
    style.padding = padding
  }
  return (
    <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
      // capture all keypresses in the dialog so that they don't do wierd things
      //  like, for instance, interacting with the MenuItem that opened the
      //  Dialog in the first place
      onKeyDown={(e): void => e.stopPropagation()}
      role="dialog"
    >
      <MuiDialog onClose={onClose} PaperProps={{ style }} {...rest}>
        {children}
      </MuiDialog>
    </div>
  )
}
