import React, { useState, useRef } from 'react'
import {
  Menu,
  MenuItem,
  PaperProps as MuiPaperProps,
  makeStyles,
} from '@material-ui/core'
import { SimpleDialog, SimpleDialogProps } from '@plvs/respawn/features/dialog'
import { PopoverOrigin } from '@material-ui/core/Popover/Popover'
import { NxTooltip } from '@playvs-inc/nexus-components'
import { MoreIconButton } from './MoreIconButton'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.ColorTextBase,
  },
}))

type DialogProps = Omit<SimpleDialogProps, 'children' | 'onClose' | 'open'> & {
  children: React.ReactElement
}

export interface MenuItemProps {
  dialogProps?: DialogProps
  disabled?: boolean
  label: React.ReactNode
  key?: string
  onClick?(e: React.SyntheticEvent): void
  tooltipTitle?: string
  disabledTooltipTitle?: string
  icon?: React.ReactElement
  className?: string
}

interface MenuProps {
  className?: string
  menuItemProps: MenuItemProps[]
  iconClassName?: string
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  isDisabled?: boolean
  PaperProps?: MuiPaperProps
}

export const MoreMenu: React.FC<MenuProps> = ({
  className,
  menuItemProps,
  iconClassName,
  transformOrigin,
  anchorOrigin,
  isDisabled,
  PaperProps,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const openMenu = (): void => setIsMenuOpen(true)

  const classes = useStyles()

  const closeMenu = (e: React.SyntheticEvent): void => {
    e.stopPropagation()
    e.preventDefault()
    setIsMenuOpen(false)
  }

  const onMoreClick = (e: React.SyntheticEvent): void => {
    e.stopPropagation()
    e.preventDefault()
    openMenu()
  }

  const [openDialogProps, setOpenDialogProps] = React.useState<{
    open: boolean
    dialogProps?: DialogProps
    hidden?: boolean
  }>({ open: false, hidden: false })

  // inject onClose prop in children, so children can close the dialog
  const children = openDialogProps?.dialogProps?.children
  const onClose = (): void =>
    setOpenDialogProps({ ...openDialogProps, open: false })
  const clonedChildren = children
    ? React.cloneElement(children, { onClose })
    : undefined
  const menuItems = menuItemProps.map(
    ({
      dialogProps,
      label,
      onClick,
      key,
      tooltipTitle = '',
      disabledTooltipTitle = '',
      icon,
      disabled = false,
      ...rest
    }): React.ReactElement | null => {
      const menuItem = (
        <MenuItem
          className={classes.menuItem}
          data-cy={key}
          disabled={isDisabled || disabled}
          onClick={(e): void => {
            if (onClick) onClick(e)
            if (dialogProps) setOpenDialogProps({ open: true, dialogProps })
            closeMenu(e)
          }}
          {...rest}
        >
          {icon || null}
          {label}
        </MenuItem>
      )
      const hasTooltip =
        (!disabled && !!tooltipTitle) || (disabled && !!disabledTooltipTitle)
      // See https://mui.com/material-ui/react-tooltip/#disabled-elements for explanation of why the span is needed
      const menuItemComponent = hasTooltip ? (
        <NxTooltip title={disabled ? disabledTooltipTitle : tooltipTitle}>
          <span>{menuItem}</span>
        </NxTooltip>
      ) : (
        menuItem
      )
      return (
        <div key={typeof label === 'string' ? label : key}>
          {menuItemComponent}
        </div>
      )
    }
  )

  return menuItems.length ? (
    <div ref={ref} className={className}>
      <MoreIconButton
        className={iconClassName}
        data-testid="MoreMenu_OpenMenuButton"
        onClick={onMoreClick}
      />
      <Menu
        anchorEl={ref.current}
        anchorOrigin={anchorOrigin}
        getContentAnchorEl={null}
        onClose={closeMenu}
        open={isMenuOpen}
        PaperProps={PaperProps}
        transformOrigin={transformOrigin}
      >
        {menuItems}
      </Menu>
      <SimpleDialog
        hidden={openDialogProps?.hidden}
        onClose={onClose}
        open={!!openDialogProps?.open}
        {...openDialogProps?.dialogProps}
      >
        {clonedChildren}
      </SimpleDialog>
    </div>
  ) : (
    <></>
  )
}
