import React from 'react'
import { ChatAccessTokenProvider } from './ChatAccessTokenProvider'
import { ChatClientProvider } from './ChatClientProvider'
import { ChatConversationsProvider } from './ChatConversationsProvider'

export const ChatContainer: React.FC = ({ children }) => {
  return (
    <ChatAccessTokenProvider>
      <ChatClientProvider>
        <ChatConversationsProvider>{children}</ChatConversationsProvider>
      </ChatClientProvider>
    </ChatAccessTokenProvider>
  )
}
