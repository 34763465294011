import React from 'react'
import { sentryContext } from '@plvs/respawn/features/analytics'
import { NxEmptyState } from '@playvs-inc/nexus-components'
import { WarningFilled } from '@playvs-inc/nexus-icons'

interface PenaltyBoxState {
  hasError: boolean
  eventId?: string
  traceId?: string
}

interface PenaltyBoxProps {
  errorChildren?: React.ReactNode
}

export class PenaltyBox extends React.Component<
  PenaltyBoxProps,
  PenaltyBoxState
> {
  constructor(props: PenaltyBoxProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): PenaltyBoxState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { client: Sentry } = this.context

    if (Sentry && Sentry.withScope) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Sentry.withScope((scope: any): void => {
        scope.setExtras(errorInfo)
        Sentry.captureException(error)
      })
    }
  }

  render(): React.ReactElement {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      return (
        <NxEmptyState
          spot={<WarningFilled />}
          subtitle={
            "Unfortunately, we ran into an issue processing your request. The error has been reported and we're working on a solution. Thank you for your patience and please try again later."
          }
          title="We're Fixing It"
        />
      )
    }
    return <>{children}</>
  }
}

PenaltyBox.contextType = sentryContext
