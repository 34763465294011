let snapKey: string

// because the last step of initialization requires the user's email address
export const getKey = (): string => snapKey

export default (key: string): void => {
  /* eslint-disable */
  ;(function (e, t, n) {
    // @ts-ignore
    if (e.snaptr) return
    // @ts-ignore
    const a = (e.snaptr = function () {
      // @ts-ignore
      if (a.handleRequest) {
        // @ts-ignore
        a.handleRequest.apply(a, arguments)
      } else {
        // @ts-ignore
        a.queue.push(arguments)
      }
    })
    // @ts-ignore
    a.queue = []
    const s = 'script'
    const r = t.createElement(s)
    // @ts-ignore
    r.async = !0
    // @ts-ignore
    r.src = n
    const u = t.getElementsByTagName(s)[0]
    // @ts-ignore
    u.parentNode.insertBefore(r, u)
  })(window, document, 'https://sc-static.net/scevent.min.js')
  /* eslint-enable */

  snapKey = key
}
