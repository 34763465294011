import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const MatchesIcon = createSvgIcon(
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.99994 5.30872L4.26511 6.59799C1.34324 7.97544 1.17489 12.0692 3.9738 13.6819L9.99991 17.1541L16.026 13.6819C18.8249 12.0692 18.6566 7.97544 15.7347 6.59799L12.9999 5.30874V7.51985L14.8819 8.40704C16.3428 9.09577 16.427 11.1427 15.0275 11.949L9.99991 14.8459L4.97229 11.949C3.57284 11.1427 3.65701 9.09577 5.11795 8.40704L6.99994 7.51982V5.30872Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11 5.73244C11.5978 5.38663 12 4.74028 12 4C12 2.89543 11.1046 2 10 2C8.89543 2 8 2.89543 8 4C8 4.74028 8.4022 5.38663 9 5.73244V10H11V5.73244Z"
      fill="black"
      fillRule="evenodd"
    />
    <circle cx="7" cy="10" fill="black" r="1" />
  </svg>,
  'MatchesIcon',
  { viewBox: '0 0 20 20' }
)
