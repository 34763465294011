import { map } from 'ramda'
import React, { useEffect } from 'react'

import {
  Column,
  Table,
  TableColumn,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import {
  QuerySortOrder,
  useMyDeniedPlayersQuery,
  User,
  UserRoleName,
} from '@plvs/graphql'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@plvs/const'
import { betterCompact } from '@plvs/utils'
import { getSchoolEmail, MinimalEmailEntry } from './helpers'

interface Props {
  filters: { name: string }
  filtersDebouncing: boolean
  setTotalCount: (count: number) => void
}

const columns = [
  Column.UserCluster,
  Column.SchoolEmailCopiable,
  Column.UserGradYear,
  Column.UserJoinDate,
  Column.UserDeniedPlayerMore,
]

const DeniedPlayersTable: React.FC<Props> = ({
  filters,
  filtersDebouncing,
  setTotalCount,
}) => {
  const { page, getNumPages, setPage, offset } = usePagination({
    itemsPerPage: DEFAULT_PAGINATION_PAGE_LIMIT,
  })
  const { selectedEntityId } = useProfileContext()

  const { data, loading } = useMyDeniedPlayersQuery({
    variables: {
      input: {
        name: filters.name,
        roleName: UserRoleName.Player,
        schoolId: selectedEntityId,
        options: {
          limit: DEFAULT_PAGINATION_PAGE_LIMIT,
          offset,
          orderby: [{ field: 'createdAt', order: QuerySortOrder.Asc }],
        },
      },
    },
    skip: !selectedEntityId || filtersDebouncing,
  })

  const players = map(
    (player) => ({
      ...player,
      schoolId: selectedEntityId,
      email: getSchoolEmail(
        selectedEntityId,
        player.emails as MinimalEmailEntry[] | null
      ),
    }),
    data?.findDeniedUsers?.users ?? []
  )

  const playersToShow = betterCompact(players)

  const totalCount = data?.findDeniedUsers?.totalCount ?? 0
  const numPages = getNumPages(totalCount)

  const columnsToUse =
    numPages > 1
      ? columns.map(
          (column) => ({ ...column, sortable: false } as TableColumn<unknown>)
        )
      : columns

  useEffect(() => {
    setTotalCount(totalCount)
  }, [data])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <LoadingSpinner size="large" />
      </Box>
    )
  }

  return totalCount > 0 ? (
    <Box data-cy="denied-players-table">
      {/* Table and pagination */}
      <Box>
        <Table<
          Pick<
            User,
            'id' | 'avatarUrl' | 'createdAt' | 'email' | 'gradYear' | 'name'
          > & { schoolId: string }
        >
          columns={columnsToUse}
          data={playersToShow}
        />

        <Box my={2}>
          <TablePagination
            numPages={numPages}
            page={page}
            setPage={setPage}
            totalCount={players.length}
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box p={3}>
      <NxTypography variant="body1">
        You do not have any denied players.
      </NxTypography>
    </Box>
  )
}

export default DeniedPlayersTable
