import { NxButton } from '@playvs-inc/nexus-components'
import { useSnackbar } from 'notistack'
import React from 'react'

import {
  refetchGetCoachesAtMySchoolQuery,
  useVerifyCoachMutation,
} from '@plvs/graphql'

interface Props {
  userId: string
  schoolId: string
}

const VerifyCoachButton = ({ userId, schoolId }: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [verifyCoach, { loading: verifyingCoach }] = useVerifyCoachMutation()

  const onVerifyCoach = async (): Promise<void> => {
    try {
      await verifyCoach({
        refetchQueries: [refetchGetCoachesAtMySchoolQuery()],
        awaitRefetchQueries: true,
        variables: {
          userId,
          resourceId: schoolId,
        },
      })
    } catch (err: unknown) {
      enqueueSnackbar('Could not verify coach.', { variant: 'error' })
    }
  }

  return (
    <NxButton
      disabled={verifyingCoach}
      fullWidth
      label="Approve"
      onClick={onVerifyCoach}
      variant="primary"
    />
  )
}
export default VerifyCoachButton
