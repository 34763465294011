// listed in step order
export enum ChildOnboardPath {
  ChildDataUsage = `child-data-usage`,
  ChildBirthday = `child-birthday`,
  ChildEmail = `child-email`,
  ChildInfo = `child-info`,
  ChildEsportInterests = `child-esport-interests`,
  ChildSchool = `child-school`,
  ChildReview = `child-review`,
  ChildOver13 = `child-over-13`,
}
