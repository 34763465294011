import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Colors, alpha } from '@plvs/rally/themes'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { UserCluster } from '../../cluster'

interface PillButtonProps {
  avatarUrl: string | null | undefined
  title: string | null | undefined
  subtitle: string // usually the stat name
  value: string
  ringColor?: Colors
  selected?: boolean
  onClick?: () => void
}

const useStyles = makeStyles((theme) => ({
  pill: ({
    ringColor,
  }: Pick<PillButtonProps, 'ringColor'>): CreateCSSProperties => ({
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: theme.palette.ColorBackgroundAlt2,
    color: theme.palette.ColorTextBase,
    borderRadius: '44px',
    boxShadow: theme.shadows[0],
    transition: [
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    ].join(', '),
    '&:active': {
      backgroundColor: ringColor
        ? alpha(ringColor, 0.1)
        : theme.palette.ColorBackgroundInfo,
      boxShadow: theme.shadows[3],
    },
  }),
  pillSelected: ({
    ringColor,
  }: Pick<PillButtonProps, 'ringColor'>): CreateCSSProperties => ({
    backgroundColor: ringColor
      ? alpha(ringColor, 0.1)
      : theme.palette.ColorBackgroundInfo,
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0],
    },
  }),
}))

export const PillButton: React.FC<PillButtonProps> = ({
  avatarUrl,
  title,
  subtitle,
  ringColor,
  value,
  selected,
  onClick,
}) => {
  const { pill, pillSelected } = useStyles({ ringColor })
  return (
    <Box
      className={clsx(pill, {
        [pillSelected]: selected,
      })}
      display="flex"
      flexDirection="row"
      onClick={onClick}
      pl={2}
      tabIndex={0}
    >
      <Box flex={1}>
        <UserCluster
          avatarUrl={avatarUrl}
          id=""
          ringColor={ringColor}
          subtitle={subtitle}
          title={title}
        />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flex={0}
        flexDirection="row"
        minWidth={104}
      >
        <Box flex={1} pl={2}>
          <NxTypography variant="h2">{value}</NxTypography>
        </Box>
      </Box>
    </Box>
  )
}
