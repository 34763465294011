import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const HamburgerIcon = createSvgIcon(
  <svg
    fill="none"
    height="10"
    viewBox="0 0 14 10"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.75009C0 9.16426 0.335744 9.5 0.749906 9.5H13.2501C13.6643 9.5 14 9.16426 14 8.75009C14 8.33593 13.6643 8.00019 13.2501 8.00019H0.749905C0.335744 8.00019 0 8.33593 0 8.75009ZM0.749906 0.5C0.335744 0.5 0 0.835744 0 1.24991C0 1.66407 0.335745 1.99981 0.749906 1.99981H13.2501C13.6643 1.99981 14 1.66407 14 1.24991C14 0.835744 13.6643 0.5 13.2501 0.5H0.749906ZM0 5.00017C0 5.4278 0.346664 5.77447 0.774296 5.77447H13.2257C13.6533 5.77447 14 5.4278 14 5.00017C14 4.57254 13.6533 4.22587 13.2257 4.22587H0.774297C0.346665 4.22587 0 4.57254 0 5.00017Z"
      fill="#8A9599"
    />
  </svg>,
  'FortniteEsportIcon',
  { viewBox: '0 0 14 10' }
)
