import { makeStyles } from '@material-ui/core'
import { RewindGradients } from '../Rewind.helpers'

export const useStyles = makeStyles((theme) => ({
  joystick: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2000,
    marginTop: -theme.spacing(6),
    marginRight: -theme.spacing(5),
    height: 237,
    width: 'auto',

    [theme.breakpoints.down('xs')]: {
      marginTop: '30%',
      marginRight: 0,
      width: 104.49,
      height: 118.22,
    },
  },

  text: {
    fontWeight: 700,
    fontSize: 125,
    letterSpacing: '-0.02em',
    lineHeight: '78%',

    [theme.breakpoints.down('xs')]: {
      fontSize: 90,
    },
  },

  textContainer: {
    background: RewindGradients.Text,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  checker: {
    marginBottom: -theme.spacing(2.75),
    marginLeft: -theme.spacing(3),
  },

  scribble: {
    position: 'absolute',
    left: 0,
    top: 0,
    marginTop: -theme.spacing(1),
  },

  spark: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    marginBottom: theme.spacing(7),
    marginRight: -theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      marginRight: -theme.spacing(3),
    },
  },
}))
