import { ApolloCache } from '@apollo/client'
import { UserEmail } from '@plvs/graphql'

export type MinimalEmailEntry = Pick<UserEmail, 'email' | 'isSchoolEmail'>

export interface MinimalCoachEntry {
  email: string
  id: string
  avatarUrl: string
  name: string
  createdAt: string
  schoolId: string
}

export const getSchoolEmail = (
  schoolId: string,
  emails: (MinimalEmailEntry | null)[] | null
): string => {
  return (
    (emails || []).find((emailEntry) => emailEntry?.isSchoolEmail)?.email ||
    'N/A'
  )
}

/**
 * Evicts billing related cache to enrollment changes will purge the
 * billing summary cache.
 */
// Not feisable to unit test apollo cache.
/* istanbul ignore next */
export const evictPlayersTableCache = (cache: ApolloCache<unknown>): void => {
  cache.evict({
    fieldName: 'findUsers',
  })
  cache.evict({
    fieldName: 'findDeniedUsers',
  })
}
