import copyToClipboard from 'copy-to-clipboard'
import { useState, useEffect, useCallback } from 'react'

const TIMEOUT = 1500

interface UseClipboardReturn {
  copy: () => void
  wasRecentlyCopied: boolean
}

export const useClipboard = (text: string): UseClipboardReturn => {
  const [wasRecentlyCopied, setWasRecentlyCopied] = useState(false)

  const copy = useCallback(
    () => setWasRecentlyCopied(copyToClipboard(text)),
    [text]
  )

  useEffect(() => {
    let timer: number
    if (wasRecentlyCopied) {
      timer = window.setTimeout(() => setWasRecentlyCopied(false), TIMEOUT)
    }

    return (): void => clearTimeout(timer)
  }, [wasRecentlyCopied])

  return {
    copy,
    wasRecentlyCopied,
  }
}
