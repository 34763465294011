import { head, map } from 'ramda'
import React from 'react'
import { useQuery } from '@apollo/client'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  GetTopPerformersStatsByTeamIdQuery,
  GetTopPerformersStatsByTeamIdDocument,
  GetTopPerformersStatsByTeamIdQueryVariables,
  Phase,
} from '@plvs/graphql'
import { PhaseTopPerformers } from './PhaseTopPerformers'

export const PlayerPhaseTopPerformers: React.FC<{
  teamId: string
  phase?: Pick<Phase, 'id' | 'endsAt'>
  metaseasonId: string
}> = ({ teamId, phase, metaseasonId }) => {
  // TODO Public need to update permissioning
  // message: "Permission Error: User cannot perform operation ViewField on specified resource of type Team at paths: members."
  const { data, loading } = useQuery<
    GetTopPerformersStatsByTeamIdQuery,
    GetTopPerformersStatsByTeamIdQueryVariables
  >(GetTopPerformersStatsByTeamIdDocument, {
    variables: {
      id: teamId,
      phaseId: phase?.id ?? '',
      date: phase?.endsAt ?? '',
      metaseasonId,
    },
  })

  const { phaseTopPerformerCategories } = useEsportContext()

  const members = data?.team?.members ?? []
  const membersForTableData = members.filter(({ phaseStats }) => phaseStats)
  const categories = phaseTopPerformerCategories ?? []
  const tableData = map(({ id, avatarUrl, name, phaseStats, school }) => {
    return {
      player: {
        id: id ?? '',
        avatarUrl,
        name: name || 'N/A',
      },
      logoUrl: school?.logoUrl,
      stats: phaseStats ? head(phaseStats)?.stats ?? null : null,
    }
  }, membersForTableData)

  return (
    <PhaseTopPerformers
      categories={categories}
      data={tableData}
      loading={loading}
    />
  )
}
