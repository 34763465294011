/* istanbul ignore file */
// This is a top level routing file and context provider. Pages will be unit tested individually.

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  LocalStorageKey,
  NASEF_QUERY_PARAM,
  NASEF_SLUG,
  Param,
  Path,
  SessionStorageKey,
} from '@plvs/const'
import {
  useGetMyAccountDetailsQuery,
  UserRoleName,
} from '@plvs/graphql/generated/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { usePromptOnWindowUnload } from '@plvs/utils'
import React, { useEffect } from 'react'
import { Navigate, Route, useNavigate, useLocation } from 'react-router'
import { useQueryParams } from '@plvs/rally/components/filter'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { OnboardAdditionalContact } from './OnboardAdditionalContact'
import { OnboardFalcultyRole } from './OnboardFalcultyRole'
import { OnboardHaveCoachInMind } from './OnboardHaveCoachInMind'
import {
  OnboardingContext,
  useOnboardingContextProvider,
} from './OnboardingContext'
import { OnboardingInviteCoach } from './OnboardingInviteCoach'
import { OnboardingInvitePlayer } from './OnboardingInvitePlayer'
import { OnboardPlanningToCoach } from './OnboardPlanningToCoach'
import { OnboardScheduleFacultyConsult } from './OnboardScheduleFacultyConsult'
import { OnboardSchoolSelect } from './OnboardSchoolSelect/index'
import { OnboardSheerId } from './OnboardSheerId'
import { OnboardEsportInterests } from './OnboardEsportInterests'
import { OnboardUserName } from './OnboardUserName'
import { OnboardTellUsMoreWrapper } from './OnboardTellUsMore/OnboardTellUsMoreWrapper'

/**
 * Determines the path we exit the onboarding flow to when they get
 * directed to the /finish route.
 * @param contextValues
 */
const getFinalRedirectRoute = (
  contextValues: Record<string, unknown>
): string => {
  const teamPath = `${Path.App}/team/${contextValues.teamId}/manage`
  let redirectPath: string = Path.Dashboard

  // Routes users to the team page if they accepted an invited
  if (contextValues.teamId) {
    redirectPath = teamPath
    return redirectPath
  }

  return redirectPath
}

export const OnboardV2: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { orgId } = useUserIdentityFn()
  const [params] = useQueryParams([Param.UTM])

  // Query Hooks
  const {
    data: loggedInUserData,
    loading: loggedInUserDataLoading,
    refetch: refetchAccountDetails,
  } = useGetMyAccountDetailsQuery()

  const { assign, data: contextValues } = useOnboardingContextProvider()

  // Computed Props
  const userHasFullyCompletedOnboarding = !!contextValues.onboardingComplete
  const showNASEFUx = contextValues?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  // Check local storage for org invite object
  const orgInviteRaw = sessionStorage.getItem(SessionStorageKey.OrgInvite)
  if (orgInviteRaw) {
    sessionStorage.removeItem(SessionStorageKey.OrgInvite)
  }

  let teamId: string | null = null
  let schoolId: string | null = null
  let role: string | null = null

  // Check local storage for reference ID if a user was invited to a team.
  const teamInviteDataRaw = localStorage.getItem(
    LocalStorageKey.InvitedTeamData
  )
  if (teamInviteDataRaw) {
    teamId = JSON.parse(teamInviteDataRaw).resourceId
  }

  if (orgInviteRaw && !contextValues.schoolId && !contextValues.roleName) {
    schoolId = JSON.parse(orgInviteRaw).resourceId
    role =
      JSON.parse(orgInviteRaw).roleName === UserRoleName.Player ||
      JSON.parse(orgInviteRaw).roleName === UserRoleName.Student
        ? UserRoleName.Student
        : UserRoleName.Fac

    if (schoolId && JSON.parse(orgInviteRaw).roleName) {
      assign({
        schoolId,
        role,
      })
    }
  }

  const redirectPath: string = getFinalRedirectRoute(contextValues)
  if (teamId && !contextValues.teamId) {
    assign({ teamId })
  }

  if (loggedInUserData?.me?.dateOfBirth && !contextValues.dobExists) {
    assign({ dobExists: !!loggedInUserData?.me?.dateOfBirth })
  }

  // Side Effects

  // Prompt user if they abruptly exit onboarding by closing the browser.
  // exclude final pages in the onboarding flow.
  // adding condition for schools not in CIF to go back to normal logo without prompt
  const doPrompt =
    !pathname.includes('/invite-') &&
    !pathname.includes('/finish') &&
    !pathname.includes('contact') &&
    !pathname.includes('/student-school') &&
    !userHasFullyCompletedOnboarding
  usePromptOnWindowUnload(doPrompt)

  // Init name and DOB data in context to handle situation when user re-enters
  // the onboarding flow via "Add School" redirect.
  useEffect(() => {
    if (loggedInUserData?.me) {
      // Using raw date since dayjs parse appears to fail on Safari
      const dobParts = loggedInUserData.me.dateOfBirth?.split('-') || []
      const month = Number(dobParts[0]) - 1 // JS month starts with 0
      const day = Number(dobParts[1])
      const year = Number(dobParts[2])

      assign({
        userId: loggedInUserData.me.id,
        firstName: loggedInUserData.me.firstName,
        lastName: loggedInUserData.me.lastName,
        dateOfBirth: loggedInUserData.me.dateOfBirth
          ? new Date(year, month, day)
          : undefined,
      })
    }
  }, [loggedInUserData])

  // Handle Finish route.
  // Handle redirects when we route to finish
  useEffect(() => {
    if (pathname.includes('finish')) {
      if (!redirectPath.startsWith(Path.SpawnPoint)) {
        // If we are redirecting out of onboarding, we want to reload the app
        // so apollo and auth related hooks will use the updated auth token.
        window.location.replace(redirectPath)

        // Clear out the session storage when we done onboarding to avoid
        sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
      } else {
        // If we're redirecting just inside the onboarding, we don't use
        // window.location to avoid the refresh loop.
        navigate(`${redirectPath}${showNASEFUx}`, { replace: true })
      }
    }
  }, [pathname, redirectPath])

  // Refresh account details as we go through the flow in the event user forcefully
  // navigates off the flow.  This will ensure non-onboarding pages will receive the
  // user with most up to date account props.
  useEffect(() => {
    refetchAccountDetails()
  }, [])

  // Redirect to dashboard if onboarding context is clear and
  // user has an orgId.  This means user has already onboarded
  // onto a school
  useEffect(() => {
    if (
      (orgId?.length ?? 0) > 0 &&
      pathname !== Path.DocUpload &&
      pathname !== Path.SpawnPoint
    ) {
      navigate(Path.Dashboard, { replace: true })
    }
  }, [orgId])

  // Checks for NASEF signup
  useEffect(() => {
    // Only assign if the variable does not exist
    if (params.utm_source === NASEF_SLUG && !contextValues.isNasefSignUp) {
      assign({
        isNasefSignUp: true,
      })
    }
  }, [params, contextValues.isNasefSignUp])

  // Using this prompt till more support for react-router v6 is available
  window.onpopstate = (): void => {
    if (!!contextValues.onboardingComplete && !pathname.includes('invite-')) {
      // eslint-disable-next-line
      const result = window.confirm(
        'Your account has been created so you will be returning to the signup screen. Are you sure you want to go back?'
      )
      if (result) {
        navigate(Path.Registration)
      }
    }
  }

  return (
    <PenaltyBox>
      <WaitTillLoaded loading={loggedInUserDataLoading}>
        <OnboardingContext.Provider value={{ assign, data: contextValues }}>
          <ApmRoutes>
            {/* See https://whimsical.com/onboarding-flow-AnmTRuiAoG1x9bLBd7ykhU
          for details on the expected onboarding flow. (ask #identity for pw) */}

            {/* Initial steps - Common to both Student and Faculty */}
            <Route element={<Navigate to={`about${showNASEFUx}`} />} path="/" />

            <Route element={<OnboardUserName />} path="about" />

            <Route element={<OnboardTellUsMoreWrapper />} path="tell-us-more" />

            {/* Student route */}
            <Route
              element={<OnboardEsportInterests />}
              path={`${Path.StudentEsportInterests}`}
            />
            <Route element={<OnboardSchoolSelect />} path="student-school" />

            <Route
              element={<OnboardAdditionalContact />}
              path="student-contact"
            />

            <Route
              element={<OnboardingInviteCoach />}
              path="student-invite-coach"
            />

            <Route
              element={<OnboardingInvitePlayer />}
              path="student-invite-player"
            />

            {/* Faculty Route */}
            <Route element={<OnboardSchoolSelect />} path="faculty-school" />

            <Route element={<OnboardFalcultyRole />} path="faculty" />

            <Route
              element={<OnboardPlanningToCoach />}
              path="faculty/planning-to-coach"
            />

            <Route
              element={<OnboardHaveCoachInMind />}
              path="faculty/know-a-coach"
            />

            <Route
              element={<OnboardingInviteCoach />}
              path="faculty/faculty-invite-coach"
            />
            <Route
              element={<OnboardingInvitePlayer />}
              path="faculty/faculty-invite-player"
            />
            <Route
              element={<OnboardAdditionalContact />}
              path="faculty/faculty-contact"
            />
            <Route
              element={<OnboardAdditionalContact />}
              path="faculty/verify-employment"
            />
            <Route
              element={<OnboardSheerId />}
              path="faculty/scholastic-verification"
            />
            <Route element={<OnboardSheerId />} path="verification-success" />
            <Route element={<OnboardSheerId />} path="doc-upload" />
            <Route element={<OnboardSheerId />} path="verification-pending" />
            <Route
              element={<OnboardScheduleFacultyConsult />}
              path="schedule-consult"
            />

            {/* Following are fallbacks for existing links to the old URLs of these pages. */}
            <Route element={<OnboardingInvitePlayer />} path="invite-player" />
            <Route element={<OnboardingInviteCoach />} path="invite-coach" />

            {/* Onboarding should be complete by this point. These are pages to invite
            coaches and players once the join school is finalized (additional-contact page). 
            These are shared between both student and Faculty, but not OTHER */}

            {/* Empty route to allow user to park at the URL before being redirected to /app
            This is handled by the useEffect() */}
            <Route path="finish" />
          </ApmRoutes>
        </OnboardingContext.Provider>
      </WaitTillLoaded>
    </PenaltyBox>
  )
}
