/* istanbul ignore file */
// Low value unit testing this component.

import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { PublicPage } from './page'

/**
 * This is a page to present when a user if email verification fails.
 */
export const EmailVerificationErrorPage: React.FC = () => {
  return (
    <PublicPage
      description="Email Verification Failed"
      title="This verification link is no longer valid"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gridGap="1em"
        justifyContent="center"
        margin="auto"
        px={2}
      >
        <NxTypography align="center" colorToken="ColorTextBase" variant="h3">
          This verification link is no longer valid
        </NxTypography>
        <NxTypography align="center" colorToken="ColorTextBase" variant="body1">
          Please try sending a new verification link. If that doesn&apos;t work,
          please contact support at support@playvs.com if you need further
          assistance.
        </NxTypography>
      </Box>
    </PublicPage>
  )
}
