import React from 'react'
import { WatchLater } from '@material-ui/icons'
import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug, MatchRescheduleRequestStatus } from '@plvs/graphql'
import { LobbyMatch } from '@plvs/utils'
import { head } from 'ramda'
import { useTheme } from '@material-ui/core'
import { RescheduleMatchButton } from './RescheduleMatchButton'
import { DarkAlert } from '../../alert/DarkAlert'

const getAlert = ({
  isMyRequest,
  isResubmitted,
  match,
  teamId,
}: {
  isMyRequest: boolean
  isResubmitted?: boolean
  match: {
    matchRescheduleRequests: {
      status: MatchRescheduleRequestStatus | null
      teamId: string
      rescheduleNotes: string | null
    }[]
    id: string
    esportSlug: EsportSlug | null
  }
  teamId: string
}): {
  actionButtonType: string
  cancelButton?: React.ReactElement
  subtitle: string
  title: string
} => {
  return isMyRequest
    ? {
        actionButtonType: 'read',
        title: isResubmitted
          ? 'Counter Proposal Sent'
          : 'Reschedule Request Sent',
        subtitle: isResubmitted
          ? 'We sent your alternative time suggestions to your opponent'
          : 'You requested that your match be rescheduled. This request is currently pending',
        cancelButton: (
          <RescheduleMatchButton
            esportSlug={match?.esportSlug}
            matchId={match?.id ?? ''}
            teamId={teamId}
            type="delete"
          />
        ),
      }
    : {
        title: 'Reschedule Request',
        subtitle: 'Your opponent is requesting to reschedule this match',
        actionButtonType: 'update',
      }
}

export const RescheduleMatchAlert: React.FC<{
  match: {
    matchRescheduleRequests: {
      status: MatchRescheduleRequestStatus | null
      teamId: string
      rescheduleNotes: string | null
    }[]
    id: string
    esportSlug: EsportSlug | null
  }
  teamId: string
}> = ({ match, teamId }) => {
  const theme = useTheme()
  if (!match) return null
  const request = head(
    (match?.matchRescheduleRequests ?? []).filter(
      (o) => o.status === MatchRescheduleRequestStatus.Pending
    )
  )

  const isMyRequest = request ? request.teamId === teamId : false
  const isResubmitted = request && request.rescheduleNotes === 'resubmitted'

  const { title, subtitle, actionButtonType, cancelButton } = getAlert({
    isMyRequest,
    isResubmitted,
    match,
    teamId,
  })

  return (
    <Box mb={3}>
      <DarkAlert
        endContent={
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <RescheduleMatchButton
                esportSlug={match.esportSlug}
                matchId={match.id}
                teamId={teamId}
                type={actionButtonType}
              />
            </Box>
            {cancelButton}
          </Box>
        }
        iconColor={theme.palette.ColorIconWarn}
        IconComponent={WatchLater}
        subtitle={subtitle}
        title={title}
      />
    </Box>
  )
}
