import React, { useState } from 'react'
import { Box } from '@material-ui/core'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  PastScrimmagesEmptySubtitle,
  ScrimmagesCTA,
} from '@plvs/rally/components/scrimmage'
import {
  Column,
  Table,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import {
  useGetSchoolScrimmagesQuery,
  EsportSlug,
  ScrimmageRequestStatus,
  ScrimmageRequestTimeFilter,
} from '@plvs/graphql'
import { ScrimmageTableLimits } from '@plvs/const'
import { getNow } from '@plvs/utils'
import { sortScrimmagesByAscendingTime } from '../../components/scrimmage/scrimmageHelpers'
import { ScrimmagesEmpty } from './ScrimmagesEmpty'

// Max number of days to display past scrimmages
const MAX_DISPLAY_DAYS = 90

export const PastScrimmages: React.FC<{
  schoolId: string
  selectedEsportSlug?: EsportSlug
}> = ({ schoolId, selectedEsportSlug }) => {
  const { page, setPage, getNumPages, offset } = usePagination({
    itemsPerPage: ScrimmageTableLimits.MyScrimmages,
  })

  const [now] = useState(getNow({ minuteDifference: -120 }))

  const {
    data: pastScrimmageData,
    loading: loadingPastScrimmages,
  } = useGetSchoolScrimmagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: schoolId,
      filter: {
        esportSlugs: selectedEsportSlug ? [selectedEsportSlug] : null,
        statuses: [
          ScrimmageRequestStatus.Completed,
          ScrimmageRequestStatus.Scheduled,
        ],
        time: ScrimmageRequestTimeFilter.All,
        startsAt: {
          before: now,
        },
        maxDisplayDays: MAX_DISPLAY_DAYS,
      },
      limit: ScrimmageTableLimits.MyScrimmages,
      offset,
    },
    skip: !schoolId,
  })

  const pastScrimmages = (
    pastScrimmageData?.school?.myScrimmageRequests?.result ?? []
  )
    .slice()
    .sort(sortScrimmagesByAscendingTime)
  const totalPastScrimmages =
    pastScrimmageData?.school?.myScrimmageRequests?.total ?? 0

  const numPages = getNumPages(totalPastScrimmages)

  return (
    <>
      <WaitTillLoaded
        loading={loadingPastScrimmages}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {pastScrimmages.length ? (
          <>
            <Table
              columns={[
                // ContainerColumn.ScrimmageEsportIcon,
                Column.ScrimmageTeams,
                Column.ScrimmageStatus,
                Column.ScrimmageFormattedDateAndTime,
                Column.EndCap,
              ]}
              data={pastScrimmages}
            />
            <Box mt={2} />
            {numPages > 1 && (
              <TablePagination
                numPages={numPages}
                page={page}
                setPage={setPage}
              />
            )}
          </>
        ) : (
          <ScrimmagesEmpty
            subtitle={`${PastScrimmagesEmptySubtitle} ${ScrimmagesCTA}`}
          />
        )}
      </WaitTillLoaded>
    </>
  )
}
