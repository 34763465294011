import React from 'react'
import { Discord, Twitch, Youtube, Linkout } from '@playvs-inc/nexus-icons'
import { Box } from '@plvs/respawn/features/layout/Box'
import { NxTypography } from '@playvs-inc/nexus-components'
import {
  SchoolProvider,
  SchoolProviderAccount as SchoolProviderAccountType,
} from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'

const getSchoolProviderLink = (
  provider: string,
  displayName: string
): string => {
  if (provider === SchoolProvider.TwitchSchool) {
    return `https://twitch.tv/${displayName}`
  }

  if (provider === SchoolProvider.YoutubeSchool) {
    return `https://youtube.com/${displayName}`
  }

  return ''
}

export const useStyles = makeStyles((theme) => ({
  schoolProviderName: {
    marginLeft: theme.spacing(0.75),
  },
  link: {
    textDecoration: 'none',
  },
  outlink: {
    color: theme.palette.OverlayColorIconBase,
  },
  youtube: {
    '& path:first-child': {
      fill: 'transparent',
    },
  },
}))

interface Props {
  providerAccount: Pick<
    SchoolProviderAccountType,
    'id' | 'providerName' | 'providerDisplayName'
  >
}

export const SchoolProviderAccount: React.FC<Props> = ({ providerAccount }) => {
  const isDiscord =
    providerAccount.providerName === SchoolProvider.DiscordSchool

  const classes = useStyles()

  const SCHOOL_PROVIDER_ICON_MAP: Record<string, React.ReactElement> = {
    [SchoolProvider.TwitchSchool]: <Twitch color="white" />,
    [SchoolProvider.DiscordSchool]: <Discord color="white" />,
    [SchoolProvider.YoutubeSchool]: (
      <Youtube className={classes.youtube} color="white" />
    ),
  }

  const displayName = (
    <NxTypography
      className={classes.schoolProviderName}
      colorToken="OverlayColorTextBase"
      variant="overline"
    >
      {providerAccount.providerDisplayName}
    </NxTypography>
  )

  return (
    <Box alignItems="center" display="flex" mr={2.5}>
      {SCHOOL_PROVIDER_ICON_MAP[providerAccount?.providerName]}
      {isDiscord ? (
        <>{displayName}</>
      ) : (
        <a
          className={classes.link}
          data-testid="provider-link"
          href={getSchoolProviderLink(
            providerAccount.providerName,
            providerAccount.providerDisplayName
          )}
          rel="noreferrer"
          target="_blank"
        >
          <Box alignItems="center" display="flex">
            {displayName}
            <Linkout className={classes.outlink} />
          </Box>
        </a>
      )}
    </Box>
  )
}
