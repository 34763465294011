import React, { useContext, useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  EsportSlug,
  useGetAllEligibleMetaseasonsQuery,
  useGetUsersEsportQuery,
  useGetGeneralEsportAdapterDetailsQuery,
} from '@plvs/graphql'
import { useAutoskipQuery, findCurrentPastFutureItemId } from '@plvs/utils'
import { Box, makeStyles } from '@material-ui/core'
import { getAdapterNoHook } from './adapters'
import { MockGeneralAdapter } from './creator'
import { GeneralEsportAdapter } from './creator/types'

const useStyles = makeStyles(() => ({
  appContainer: {
    // This ensures the notifications drawer and chat drawer render over other components.
    zIndex: 1,
  },
  flex: {
    flex: 1,
  },
}))

export const context =
  React.createContext<GeneralEsportAdapter>(MockGeneralAdapter)
const { Provider } = context

export const Esport: React.FC<{
  children: React.ReactNode
  slug?: EsportSlug | null
  width?: string
}> = ({ slug, children, width }) => {
  const flags = useFlags()
  const classes = useStyles()
  const { data: metaseasonData } = useAutoskipQuery(
    useGetAllEligibleMetaseasonsQuery,
    {
      skip: !!slug, // if we have a slug already, we can skip this query
    }
  )
  const { data, loading } = useAutoskipQuery(
    useGetGeneralEsportAdapterDetailsQuery,
    {}
  )

  const allMetaseasons = metaseasonData?.eligibleMetaseasons ?? []
  const metaseasonId = findCurrentPastFutureItemId(allMetaseasons) ?? ''

  const { data: esportData } = useAutoskipQuery(useGetUsersEsportQuery, {
    variables: { metaseasonId },
    // 1. if we have a slug already, we can skip this query
    // 2. metaseasonId could be '' and that's a no-no
    skip: !!slug || !metaseasonId,
  })

  const esportSlug = slug ?? esportData?.me?.league?.esport.slug ?? null

  const esportAdapter = getAdapterNoHook(esportSlug, data, loading)

  const value = useMemo(
    () => esportAdapter,
    [esportSlug, loading]
  ) as GeneralEsportAdapter

  return (
    <Provider value={value}>
      <Box
        className={
          flags.activateFullBleedBanners ? classes.flex : classes.appContainer
        }
        width={width}
      >
        {children}
      </Box>
    </Provider>
  )
}

export const useEsportContext = (): GeneralEsportAdapter => useContext(context)
