import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import CIFBanner from '@plvs/rally/assets/sponsorships/cif-banner.svg'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { useUseLeaguesQuery } from '@plvs/graphql'

const leagueGroupNameAssetMap: Record<string, any> = {
  California: CIFBanner,
}

export const SponsorshipBanner: React.FC = () => {
  const { metaseason } = useSchoolLeagueInfoContext()
  const metaseasonId = metaseason?.id ?? ''
  const { data, loading } = useUseLeaguesQuery({
    variables: { metaseasonId },
    skip: !metaseasonId,
  })
  const leagueGroups = data?.me?.school?.leagueGroups ?? []
  const selectedGroups = leagueGroups.map((leagueGroup) => {
    const leagueGroupName = leagueGroup?.name ?? ''
    const sponsorShipAsset = leagueGroupNameAssetMap[leagueGroupName]

    if (!sponsorShipAsset || loading) {
      return null
    }

    return (
      <Box
        key={leagueGroup.id}
        display="flex"
        flex={0}
        flexDirection="column"
        mt={[1, 2]}
        mx={[1, 2]}
      >
        <MatchCard px={3} py={2}>
          <Box alignItems="center" display="flex">
            <img alt="sponsorship" height={36} src={sponsorShipAsset} />
          </Box>
        </MatchCard>
      </Box>
    )
  })

  return <>{selectedGroups}</>
}
