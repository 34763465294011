import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { AvatarCircle, AvatarCircleSizes } from './AvatarCircle'
import { LogoMark } from '../icon'

const useStyles = makeStyles((theme) => {
  const marginLeft = 40
  return {
    logosContainer: {
      display: 'flex',
    },
    logoContainer: {
      padding: '3px',
      backgroundColor: theme.palette.ColorBackgroundBase,
      borderRadius: '50%',
      transition: 'all 0.7s cubic-bezier(0.395, -0.570, 0.000, 1.285)',
      zIndex: 1,
      '&:not(:first-child)': {
        marginLeft: `-${marginLeft}px`,
      },
    },
    scoreText: {
      fontWeight: 600,
      fontSize: '0.6em',
      color: theme.palette.common.white,
      margin: '0 auto',
    },
    blankAvatar: {
      backgroundColor: theme.palette.ColorBackgroundBase,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }
})

export const MatchAvatarGroup: React.FC<{
  logoUrls: string[]
  displayText?: string
  size?: string
}> = ({ logoUrls = [], displayText = '', size = 'medium', ...props }) => {
  const styles = useStyles()
  const items = useMemo(() => {
    const avatars = logoUrls.map((url) => {
      return (
        <AvatarCircle
          key={url}
          avatarUrl={url}
          className={styles.logoContainer}
          data-testid="avatar_group_display_circle_url"
          size={size as AvatarCircleSizes}
        />
      )
    })
    new Array(Math.max(2 - avatars.length, 0)).fill(0).forEach(() => {
      avatars.push(
        <AvatarCircle
          key={`avatar_group_display_circle_empty_${
            Date.now() * Math.random()
          }`}
          avatarUrl=""
          className={styles.logoContainer}
          data-testid="avatar_group_display_circle_empty"
          defaultIcon={<LogoMark />}
          size={size as AvatarCircleSizes}
        />
      )
    })
    if (displayText) {
      avatars.push(
        <AvatarCircle
          key="avatar_group_display_circle_text"
          avatarUrl=""
          className={styles.logoContainer}
          data-testid="avatar_group_display_circle_text"
          defaultIcon={
            <div className={styles.blankAvatar}>
              <span
                className={styles.scoreText}
                data-testid="avatar_group_text"
              >
                {displayText}
              </span>
            </div>
          }
          size={size as AvatarCircleSizes}
        />
      )
    }
    return avatars
  }, [logoUrls, displayText])
  return (
    <div {...props} className={styles.logosContainer}>
      {items}
    </div>
  )
}
