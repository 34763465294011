import React from 'react'
import SelectObject from './SelectObject'

interface FilterPhase {
  id: string
  name: string
}

interface Props {
  className?: string
  phase?: FilterPhase
  phases: FilterPhase[]
  setPhase: (id: string) => void
  buttonClassName?: string
}

export const PhaseFilter: React.FC<Props> = ({
  className,
  phase,
  phases,
  setPhase,
  buttonClassName,
}) => {
  const selectedPhase = phase ?? { name: 'Period', id: '' }

  return (
    <SelectObject<typeof selectedPhase>
      buttonClassName={buttonClassName}
      className={className}
      data-testid="LeagueFilter__PhaseSelect"
      option={selectedPhase}
      options={phases}
      renderValue={(p): string => p?.name ?? 'n/a'}
      setOption={(p): void => setPhase(p?.id ?? '')}
    />
  )
}
