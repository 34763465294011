import React from 'react'
import {
  EsportSlug,
  useGetLeagueGroupsForSchoolQuery,
  IntervalTense,
} from '@plvs/graphql'
import { RelativeTiming, MinimalMetaseason } from '@plvs/utils'
import { ManageTeamsEnrollmentCard } from '@plvs/rally/components/manageTeams/ManageTeamsEnrollmentCard'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

interface ManageTeamsEnrollmentCardContainerProps {
  path: string
  title: string
  esportSlug: EsportSlug | null
  metaseason: MinimalMetaseason | undefined
}
export const ManageTeamsEnrollmentCardContainer: React.FC<ManageTeamsEnrollmentCardContainerProps> = ({
  path,
  esportSlug,
  title,
  metaseason,
}) => {
  const { id: schoolId } = useSelectedOrganizationFn()

  const { enrolledTeamIds, competitionInterval } = useManageTeamsContext()
  const metaseasonId = metaseason?.id ?? ''
  const { data, loading } = useGetLeagueGroupsForSchoolQuery({
    variables: { id: schoolId ?? '', metaseasonId },
    skip: !metaseasonId || !schoolId,
  })
  const leaguesForEsport = (data?.getLeaguesBySchoolId ?? []).filter(
    (league) => {
      return (
        league?.esport.slug === esportSlug &&
        league?.seasons?.some((season) => season.metaseasonId === metaseasonId)
      )
    }
  )

  const isNotCurrentSeason = competitionInterval
    ? competitionInterval.tense !== IntervalTense.Current
    : metaseason?.timing !== RelativeTiming.Present
  const futureTense =
    competitionInterval?.tense === IntervalTense.Future &&
    metaseason?.timing === RelativeTiming.Future
  const hideEnrollmentCard = isNotCurrentSeason && !futureTense
  return hideEnrollmentCard ? (
    <></>
  ) : (
    <ManageTeamsEnrollmentCard
      esportSlug={esportSlug}
      hasAnEnrolledTeam={enrolledTeamIds.length > 0}
      leagues={leaguesForEsport}
      loading={loading}
      metaseasonId={metaseasonId}
      path={path}
      title={title}
    />
  )
}
