import { Box } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { SchoolSearchResult } from '@plvs/graphql/generated/graphql'
import { Avatar } from '@plvs/rally/components/avatar'
import React from 'react'
import { ADD_SCHOOL_PLACEHOLDER } from './OnboardSchoolSelect.types'

export const SearchResultItemRenderer = (
  option: SchoolSearchResult
): React.ReactNode => {
  if (option?.schoolId === ADD_SCHOOL_PLACEHOLDER.schoolId) {
    return (
      <Box pl={6.5}>
        <NxTypography
          color="textPrimary"
          data-testid="OnboardSchoolSelect_SchoolSearch_AddSchoolOption"
          variant="body1"
        >
          Can’t find your school?{' '}
          <NxTypography color="primary" display="inline">
            Contact us
          </NxTypography>{' '}
          and request to have it added to PlayVS.
        </NxTypography>
      </Box>
    )
  }
  return (
    <Box alignItems="flex-start" display="flex" justifyContent="space-around">
      <Box pt={1}>
        <Avatar size="smedium" src={option?.logoUrl} />
      </Box>
      <Box ml={1.5}>
        <NxTypography
          color="textPrimary"
          style={{
            fontWeight: 600,
          }}
          variant="body1"
        >
          {option?.name}
        </NxTypography>
        <NxTypography color="textSecondary" variant="subtitle1">
          {option?.address}
        </NxTypography>
        <NxTypography color="textSecondary" variant="subtitle1">
          {option?.city}, {option?.state} {option?.zip}
        </NxTypography>
      </Box>
    </Box>
  )
}
