import { mapObjIndexed } from 'ramda'
import React from 'react'
import { UseFormMethods, UseFormOptions, useForm } from 'react-hook-form'

import { formErrorToString } from '@plvs/utils'
import {
  UseMutationProps,
  UseMutationReturn2,
  useDefaultMutation,
} from './useDefaultMutation'

interface UseMutationFormProps<
  FormInput extends Record<string, unknown>,
  Mutation,
  MutationVariables
> extends UseMutationProps<FormInput, Mutation, MutationVariables> {
  useFormProps: UseFormOptions<FormInput>
  variables(input: FormInput): MutationVariables
}

interface UseMutationFormReturn<FormInput> extends UseMutationReturn2 {
  formErrorStrings: Partial<Record<keyof FormInput, string>>
  rawSubmit(input: FormInput): Promise<void>
  onSubmit(e: React.SyntheticEvent<Element, Event>): Promise<void>
}

export function useDefaultForm<
  FormInput extends Record<string, unknown>,
  Mutation,
  MutationVariables
>({
  useFormProps,
  ...restIn
}: UseMutationFormProps<
  FormInput,
  Mutation,
  MutationVariables
>): UseMutationFormReturn<FormInput> & {
  formErrors: UseFormMethods<FormInput>['errors']
} & Omit<UseFormMethods<FormInput>, 'errors' | 'handleSubmit'> {
  const [submit, restOfMutation] = useDefaultMutation<
    FormInput,
    Mutation,
    MutationVariables
  >(restIn)
  // form
  const { errors, handleSubmit, ...restOfForm } = useForm<FormInput>(
    useFormProps
  )

  // submit
  const onSubmit = handleSubmit(submit as any)
  return {
    formErrors: errors,
    // NOTE: This isn't set up for field arrays.
    formErrorStrings: mapObjIndexed(formErrorToString, errors) as Partial<
      Record<keyof FormInput, string>
    >,
    onSubmit,
    rawSubmit: submit,
    ...restOfForm,
    ...restOfMutation,
  }
}
