import React, { useEffect, useState } from 'react'
import { makeStyles, Grid, useTheme } from '@material-ui/core'
import {
  Navigate,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import tz from 'dayjs/plugin/timezone'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  PhaseStatus,
  PhaseType,
  EsportRating,
  useGetLeagueByPublicSlugQuery,
  SeasonStatus,
  useGetLeagueSeasonDataQuery,
} from '@plvs/graphql'
import {
  DateFormats,
  findNextCompSlot,
  formatInTz,
  sortByStartsAt,
} from '@plvs/utils'
import { isNil, last } from 'ramda'
import { NxTabs, NxTab, NxEmptyState } from '@playvs-inc/nexus-components'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import dayjs from '@plvs/rally/init/dayjs'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'

import { HighAlertLightLargeSecondary } from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { StringParam, useQueryParams } from 'use-query-params'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { LeagueTeams } from './LeagueTeams'
import {
  getCurrentPhase,
  getLeagueSponsorLogos,
  getSeasonEnrollmentStatus,
} from './ExploreHelpers'

import { SeasonSchedule } from './SeasonSchedule/SeasonSchedule'
import { LeagueOverviewCard } from './LeagueOverviewCard'
import { KeyDatesCard } from './KeyDatesCard'
import { CreateTeamCard } from './CreateTeamCard'
import { LeagueDetailsTeamsTable } from './LeagueDetailsTeamTable/LeagueDetailsTeamsTable'
import { LeagueStandings } from './LeagueStandings/LeagueStandings'
import {
  LeagueDetailsTab,
  PhaseFilterType,
  SlotFilterType,
} from './LeagueDetails.types'
import { LeagueDetailsFilters } from './LeagueDetailsFilters'
import { LeagueMatches } from './LeagueMatches/LeagueMatches'
import { ResourcesCard } from './ResourcesCard/ResourcesCard'
import { ReferCard } from './refer/ReferCard'
import { LeagueDetailsHero } from './LeagueDetailsHero'

dayjs.extend(tz)

export const useStyles = makeStyles((theme) => ({
  exploreContainer: {
    cursor: 'pointer',
  },
  leagueSideInfoContainer: {
    padding: theme.spacing(3),
  },
  cta: {
    marginRight: theme.spacing(2),
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  createButton: {
    width: '100%',
    padding: theme.spacing(1.75, 8.75),
    marginBottom: theme.spacing(4),
  },
  seasonSchedule: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  privateResourceCard: {
    marginTop: theme.spacing(3),
  },
}))

interface Props {
  isPublic: boolean
}

export const LeagueDetails: React.FC<Props> = ({ isPublic }) => {
  const classes = useStyles()

  const { publicSlug } = useParams<{
    publicSlug: string
  }>()

  // state
  const [tabValue, setTabValue] = useState<LeagueDetailsTab>(
    LeagueDetailsTab.Overview
  )

  const [phase, setPhase] = useState<PhaseFilterType | undefined>()
  const [slotFilter, setSlotFilter] = useState<SlotFilterType | undefined>()
  const [team, setTeam] = useState<SelectedTeam | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const tabs: {
    label: string
    path: string
    id?: string
    value: LeagueDetailsTab
  }[] = []
  tabs.push({
    label: 'Overview',
    path: `overview`,
    id: 'league-tabs-overview',
    value: LeagueDetailsTab.Overview,
  })
  tabs.push({
    label: 'Standings',
    path: `standings`,
    id: 'league-tabs-standings',
    value: LeagueDetailsTab.Standings,
  })
  tabs.push({
    label: 'Matches',
    path: `matches`,
    id: 'league-tabs-matches',
    value: LeagueDetailsTab.Matches,
  })
  tabs.push({
    label: 'Teams List',
    path: `teams`,
    id: 'league-tabs-teams',
    value: LeagueDetailsTab.Teams,
  })
  const handleTabChange = (_: any, newValue: LeagueDetailsTab): void => {
    setTabValue(newValue)
    const tabData = tabs.find((x) => x.value === newValue)
    if (tabData && tabData.path) {
      navigate(tabData.path)
    }
  }
  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname === tab.path)
    if (currentTab) {
      setTabValue(currentTab.value)
    }
  }, [tabs])

  // custom hooks
  const {
    loading: schoolLeagueInfoContextLoading,
    metaseason,
    setLeague,
  } = useSchoolLeagueInfoContext()
  const [queryFilters] = useQueryParams({
    queryPhaseId: StringParam,
  })
  const { queryPhaseId } = queryFilters

  const { isCoachAtOrg, orgId } = useUserIdentityFn()

  const metaseasonId = metaseason?.id ?? ''
  const metaseasonName = metaseason?.name ?? ''

  const { data, loading } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  useEffect(() => {
    if (leagueId) {
      setLeague(leagueId)
    }
  }, [leagueId])

  useEffect(() => {
    return () => {
      /* istanbul ignore next */
      setLeague('')
    }
  }, [])

  const {
    data: leagueSeasonData,
    loading: leagueSeasonDataLoading,
  } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId || schoolLeagueInfoContextLoading,
    variables: { leagueId, metaseasonId },
  })

  const selectedLeague = data?.getLeagueByPublicSlug
  const competitionGroup = selectedLeague?.competitionGroup
  const hasActiveSeasons = selectedLeague?.seasons?.some(
    (s) => s.status === SeasonStatus.Active
  )

  // Derived data
  const selectedLeagueName =
    selectedLeague?.displayName || selectedLeague?.name || ''
  const esportId = selectedLeague?.esport?.id || ''
  const esportSlug = selectedLeague?.esport?.slug
  const esportRating = selectedLeague?.esport?.rating ?? EsportRating.General

  const season = leagueSeasonData?.league?.seasonByMetaseasonId

  const seasonStartsAt = season?.startsAt
  const seasonEndsAt = season?.endsAt
  const teamRegistrationEndsAt = season?.teamRegistrationEndsAt
  const suggestedRegistrationEndsAt = season?.suggestedRegistrationEndsAt

  const seasonPhases = sortByStartsAt(season?.phases ?? [])
  const lastPhase = last(seasonPhases)

  const seasonPhase =
    seasonPhases.find((p) => p.type === PhaseType.RegularSeason) ||
    lastPhase ||
    null

  const nextCompSlot = findNextCompSlot(seasonPhase)
  const competitionTime = nextCompSlot?.startsAt
  const formattedTime = competitionTime
    ? formatInTz({
        at: competitionTime,
        format: DateFormats.LeagueCompetitionTimeFormat,
        timezone: dayjs.tz.guess(),
      })
    : 'TBD'
  const enrollmentStatus = getSeasonEnrollmentStatus({
    teamRegistrationEndsAt: season?.teamRegistrationEndsAt ?? undefined,
    registrationStartsAt: season?.registrationStartsAt,
  })
  const activePhases = seasonPhases.filter(
    (p) => p.status === PhaseStatus.Active
  )

  const esportName = selectedLeague?.esport.name || ''

  useEffect(() => {
    if (metaseasonId && !loading) {
      const currentPhase =
        activePhases.find((x) => x.id === queryPhaseId) ||
        activePhases.find(getCurrentPhase) ||
        lastPhase

      setPhase(currentPhase as PhaseFilterType)
    }
  }, [metaseasonId, loading, activePhases.toString(), phase?.id])

  const {
    bannerLogo,
    rightSideLogo,
    mobileRightSideLogo,
  } = getLeagueSponsorLogos({
    images: season?.sponsorshipImages ?? [],
    isDarkMode,
  })

  return (
    <WaitTillLoaded
      loading={
        loading || schoolLeagueInfoContextLoading || leagueSeasonDataLoading
      }
      showSpinnerWhileLoading
    >
      {isNil(selectedLeague) || selectedLeague.isHidden || !hasActiveSeasons ? (
        <Box mt={2}>
          <PageContentGutter>
            <NxEmptyState
              spot={<HighAlertLightLargeSecondary height={200} width={200} />}
              subtitle="The league you are trying to find does not exist or may have moved. Please double check the URL."
              title="League Not Found"
            />
          </PageContentGutter>
        </Box>
      ) : (
        <>
          <HeroGutter mb={2} mt={0} mx={[1, 2]}>
            <LeagueDetailsHero
              competitionGroup={competitionGroup}
              enrollmentStatusLabel={enrollmentStatus.label}
              enrollmentStatusVariant={enrollmentStatus.variant}
              esportName={esportName}
              esportRating={esportRating}
              esportSlug={esportSlug}
              isPublic={isPublic}
              metaseasonName={metaseasonName}
              mobileRightSideSponsorLogo={mobileRightSideLogo}
              rightSideLogo={rightSideLogo}
              selectedLeagueName={selectedLeagueName}
              sponsorAvatar={bannerLogo}
            />
          </HeroGutter>
          <PageContentGutter>
            <Box alignItems="center" display="flex" mb={2}>
              <NxTabs onChange={handleTabChange} size="large" value={tabValue}>
                {tabs.map((tab) => (
                  <NxTab
                    key={tab.id}
                    data-path={tab.path}
                    id={tab.id}
                    label={tab.label}
                    selected={tabValue === tab.value}
                    value={tab.value}
                  />
                ))}
              </NxTabs>
            </Box>
            <Box alignItems="center" display="flex" mb={2}>
              <LeagueDetailsFilters
                leagueId={leagueId}
                phase={phase}
                phases={activePhases as PhaseFilterType[]}
                setPhase={setPhase}
                setSlotFilter={setSlotFilter}
                slotFilter={slotFilter}
                tab={tabValue}
                team={team}
              />
            </Box>
            <ApmRoutes>
              <Route
                element={
                  <Grid container spacing={2}>
                    <Grid item md={isPublic ? 12 : 8} sm={12} xs={12}>
                      {!isPublic && (
                        <LeagueOverviewCard
                          className={classes.card}
                          formattedTime={formattedTime}
                          teamFormat={selectedLeague?.teamFormat ?? 0}
                          teamMaxSize={selectedLeague?.teamMaxSize ?? 0}
                        />
                      )}
                      <KeyDatesCard
                        className={classes.card}
                        esportName={esportName}
                        esportSlug={esportSlug}
                        isPublic={isPublic}
                        metaseasonName={metaseasonName}
                        rostersLockAt={season?.rostersLockAt}
                        seasonEndsAt={seasonEndsAt}
                        seasonStartsAt={seasonStartsAt}
                        suggestedRegistrationEndsAt={
                          suggestedRegistrationEndsAt
                        }
                        teamRegistrationEndsAt={teamRegistrationEndsAt}
                      />
                      <Grid
                        className={classes.seasonSchedule}
                        container
                        spacing={isPublic ? 2 : 0}
                      >
                        <Grid item sm={isPublic ? 7 : 12} xs={12}>
                          {activePhases.length > 0 && (
                            <SeasonSchedule
                              formattedTime={formattedTime}
                              phases={activePhases}
                            />
                          )}
                        </Grid>
                        {isPublic && esportSlug && (
                          <Grid item sm={5} xs={12}>
                            <ResourcesCard
                              esportSlug={esportSlug}
                              isPublic={isPublic}
                              rulebookUrl={
                                selectedLeague?.esport?.details?.rulebookUrl
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {!isPublic && (
                      <Grid item md={4} sm={12} xs={12}>
                        <LeagueTeams
                          className={classes.leagueSideInfoContainer}
                          competitionGroup={competitionGroup}
                          esportId={esportId}
                          esportSlug={esportSlug}
                          isPastRegDate={dayjs().isSameOrAfter(
                            teamRegistrationEndsAt
                          )}
                          leagueId={leagueId}
                          metaseasonId={metaseasonId}
                        />
                        <CreateTeamCard
                          esportSlug={esportSlug}
                          isCoachAtOrg={isCoachAtOrg}
                        />
                        {esportSlug && (
                          <ResourcesCard
                            className={classes.privateResourceCard}
                            esportSlug={esportSlug}
                            isPublic={isPublic}
                            rulebookUrl={
                              selectedLeague?.esport?.details?.rulebookUrl
                            }
                          />
                        )}
                        {isCoachAtOrg && <ReferCard schoolId={orgId || ''} />}
                      </Grid>
                    )}
                  </Grid>
                }
                path="overview"
              />
              <Route
                element={
                  <LeagueStandings
                    esportSlug={esportSlug}
                    phase={phase}
                    seasonId={season?.id ?? ''}
                  />
                }
                path="standings"
              />
              <Route
                element={
                  <LeagueMatches
                    leagueId={leagueId}
                    metaseasonId={metaseasonId}
                    phase={phase}
                    seasonIds={undefined}
                    setTeam={setTeam}
                    slotFilter={slotFilter}
                    team={team}
                  />
                }
                path="matches"
              />
              <Route
                element={
                  esportSlug ? (
                    <LeagueDetailsTeamsTable
                      esportRating={esportRating}
                      esportSlug={esportSlug}
                      leagueId={leagueId}
                      metaseasonId={metaseasonId}
                    />
                  ) : (
                    <></>
                  )
                }
                path="teams/*"
              />
              <Route element={<Navigate to="overview" />} path="/" />
            </ApmRoutes>
            {esportSlug && <EsrbRating esportSlug={esportSlug} mt={5} />}
          </PageContentGutter>
        </>
      )}
    </WaitTillLoaded>
  )
}
