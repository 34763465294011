import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

import { useBreakpointXs } from '@plvs/respawn/features/layout'
import * as analytics from '@plvs/respawn/features/analytics'

export const MOVE_CURSOR_DEBOUNCE_IN_MS = 10 * 1000
export const SLIDE_CHANGE_IN_MS = 7 * 1000
export const PROGRESS_CHANGE_IN_MS = 100
export const PROGRESS_INCREMENT = 1.7

function useQuery(): URLSearchParams {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

interface SlideshowControlsProps {
  slideCount?: number
  userId?: string
}

interface SlideshowControls {
  handleClose(): void
  handleNext(): void
  handleBack(): void
  onMouseEnter(): void
  onMouseLeave(): void
  onShare(): void
  handleOpen(): void
  isOpen: boolean
  shouldShowPagers: boolean
  hasNextPage: boolean
  hasPreviousPage: boolean
  progress: number
  slideIndex: number
}

export function useSlideshowControls({
  slideCount = 0,
  userId = '',
}: SlideshowControlsProps): SlideshowControls {
  const query = useQuery()
  const isRewind = query.get('rewind') !== null

  const [isOpen, setIsOpen] = useState(isRewind)
  const [slideIndex, setSlideIndex] = useState(0)

  const [progress, setProgress] = useState(0)
  const [hasMovedCursor, setHasMovedCursor] = useState(false)
  const [pagerHasFocus, setPagerHasFocus] = useState(false)

  const hasNextPage = slideIndex < slideCount - 1
  const hasPreviousPage = slideIndex > 0

  const isMobile = useBreakpointXs()
  const shouldShowPagers = isMobile || pagerHasFocus || hasMovedCursor

  const handleClose = (): void => {
    setIsOpen(false)
    setSlideIndex(0)
  }

  const handleOpen = (): void => {
    analytics.rewindOpenClicked({
      timeStamp: new Date().toDateString(),
      userId: userId ?? '',
    })
    setIsOpen(true)
  }

  const handleNext = (): void => {
    if (!hasNextPage) {
      return
    }

    setSlideIndex(slideIndex + 1)
  }

  const handleBack = (): void => {
    if (!hasPreviousPage) {
      return
    }

    setSlideIndex(slideIndex - 1)
  }

  const onMouseEnter = (): void => {
    setPagerHasFocus(true)
  }

  const onMouseLeave = (): void => {
    setPagerHasFocus(false)
  }

  const onShare = (): void => {
    analytics.rewindShareClicked({
      timeStamp: new Date().toDateString(),
      userId: userId ?? '',
    })
  }

  // Updates the progress stepper
  useEffect(
    function startProgressInterval() {
      let interval: NodeJS.Timeout

      if (hasNextPage && isOpen) {
        interval = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + PROGRESS_INCREMENT
          )
        }, PROGRESS_CHANGE_IN_MS)
      }

      return (): void => {
        setProgress(0)
        clearTimeout(interval)
      }
    },
    [slideIndex, slideCount, isOpen]
  )

  // Goes to the next slide every n seconds
  useEffect(
    function startTimer() {
      let timeout: NodeJS.Timeout

      if (isOpen && hasNextPage) {
        timeout = setTimeout(() => {
          handleNext()
        }, SLIDE_CHANGE_IN_MS)
      }

      return (): void => {
        clearTimeout(timeout)
      }
    },
    [slideIndex, slideCount, isOpen, hasNextPage]
  )

  // Shows pagination buttons on mousemove
  useEffect(
    function setMouseMoveListener() {
      let timeout: NodeJS.Timeout

      const updateHasMovedCursor = (): void => {
        clearTimeout(timeout)

        setHasMovedCursor(true)

        timeout = setTimeout((): void => {
          setHasMovedCursor(false)
        }, MOVE_CURSOR_DEBOUNCE_IN_MS)
      }

      if (isOpen) {
        window.addEventListener('mousemove', updateHasMovedCursor)
      }

      return (): void => {
        window.removeEventListener('mousemove', updateHasMovedCursor)
      }
    },
    [isOpen]
  )

  // Allows keyboard navigation via arrow keys
  useEffect(
    function setKeybindListener() {
      const onKeyPress = ({ key }: { key: string }): void => {
        if (key === 'ArrowLeft') {
          handleBack()
        }

        if (key === 'ArrowRight') {
          handleNext()
        }
      }

      if (isOpen) {
        window.addEventListener('keydown', onKeyPress)
      }

      return (): void => {
        window.removeEventListener('keydown', onKeyPress)
      }
    },
    [slideIndex, slideCount, isOpen]
  )

  return {
    isOpen,
    handleClose,
    handleNext,
    handleBack,
    onMouseEnter,
    onMouseLeave,
    onShare,
    shouldShowPagers,
    progress,
    slideIndex,
    handleOpen,
    hasNextPage,
    hasPreviousPage,
  }
}
