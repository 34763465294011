import { UserRoleName } from '@plvs/graphql'
import { UserType } from '@plvs/utils'
import { getKey } from './initFacebook'
import { makeSafeFunction } from '../utils'

const facebook = makeSafeFunction(['fbq'])

// REFERENCE
// https://developers.facebook.com/docs/facebook-pixel/implementation

// CHROME EXTENSION
// https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en

export const register = ({
  email,
  userId,
  userType,
}: {
  email: string
  userId: string
  userType?: UserType
}): void => {
  facebook('init', getKey(), { uid: userId })
  facebook('setUserProperties', getKey(), {
    $email: email,
    $user_type: userType,
  })
  facebook('track', 'PageView')
  facebook('track', 'CompleteRegistration')
  if (userType === UserRoleName.Coach) {
    // for coach ad optimization
    facebook('trackCustom', 'CoachCompleteRegistration')
  }
  if (userType === UserRoleName.Player) {
    // for player ad optimization
    facebook('trackCustom', 'PlayerCompleteRegistration')
  }
}
