import React from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { appendClasses, arrayOfN } from '@plvs/utils'
import { useStyles as notificationDrawerStyles } from './NotificationDrawer.styles'
import { useStyles } from '../AppDrawerToggle.styles'

export const LoadingSkeleton: React.FC = () => {
  const isMobile = useBreakpointSm()
  const classes = notificationDrawerStyles({ hasUnreadNotifications: false })
  const { container } = useStyles({ isMobile })
  return (
    <Box
      className={appendClasses(container)}
      data-testid="NotificationDrawer__LoadingSkeleton"
      paddingTop="8em"
    >
      {arrayOfN(5).map((elementNum) => (
        <Box key={elementNum} mx={2} py={1}>
          <Skeleton
            className={classes.fetchMoreNotifications}
            component="div"
            height="5em"
            variant="rect"
            width="100%"
          />
        </Box>
      ))}
    </Box>
  )
}
