import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { noop } from 'ramda-adjunct'

import {
  ContentFeature,
  GetUserAttrsQuery,
  GetUserAttrsQueryResult,
  Maybe,
  LegacyNotification,
  SchoolType,
  LegacyUserNotification,
} from '@plvs/graphql'

export interface NotificationState {
  pending: boolean
  items: (Pick<LegacyUserNotification, 'id' | 'status'> & {
    notification: Pick<
      LegacyNotification,
      | 'id'
      | 'name'
      | 'description'
      | 'title'
      | 'type'
      | 'buttonText'
      | 'href'
      | 'imageUrl'
    >
  })[]
  acknowledge: (id: string) => Promise<void>
}

export interface UserContentState {
  features: Maybe<Pick<ContentFeature, 'slug' | 'variant'>[]>
  loading: boolean
  error?: ApolloError
  refetch: () => void
}
export interface OnboardingState {
  schoolType: Maybe<SchoolType>
}

export type RefetchUserAttrsFn = Promise<ApolloQueryResult<GetUserAttrsQuery>>

export type UserAttrsState = Pick<
  GetUserAttrsQueryResult,
  'error' | 'loading'
> & {
  attributes?: GetUserAttrsQuery['userAttributes']
  refetch?(): RefetchUserAttrsFn
}

export interface AppState {
  initialized: boolean
  notifications: NotificationState
  onboarding: OnboardingState
  userAttributes: UserAttrsState
  userContent: UserContentState
}

export const initialState: AppState = {
  initialized: false,
  notifications: {
    pending: true,
    items: [],
    acknowledge: async (): Promise<void> => {},
  },
  onboarding: {
    schoolType: null,
  },
  userAttributes: {
    loading: true,
  },
  userContent: {
    features: null,
    loading: true,
    refetch: noop,
  },
}
