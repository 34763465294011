import { intersection, isNil } from 'ramda'
import React from 'react'
import {
  Navigate,
  Outlet,
  Route,
  RouteProps,
  useLocation,
  useOutlet,
  useOutletContext,
} from 'react-router-dom'
import { userRolesToUserRoleNames } from '@plvs/utils'
import { UserRoleName, useGetMyIdentityQuery } from '@plvs/graphql'
import { LoadingSpinner } from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { Path } from '@plvs/const'
import { ReplaceWithLogin } from './ReplaceWithLogin'
import { RouteComponentProps, withRouter } from './WithRouter'

const ALLOWED_APP_REDIRECTS = [
  `${Path.App}/explore/leagues/`,
  `${Path.App}${Path.Profile}`,
]

interface Props
  extends Omit<RouteComponentProps, 'id'>,
    Omit<RouteProps, 'location'> {
  allowedRoles?: UserRoleName[]
}

// Need to fix this.
export const AuthorizedRoute = withRouter(
  ({ allowedRoles, ...rest }: Props): React.ReactElement => {
    const { data, loading } = useGetMyIdentityQuery()

    const { pathname } = useLocation()

    if (loading)
      return (
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          justifyContent="center"
        >
          <LoadingSpinner />
        </Box>
      )

    // redirects users to url without '/app' in it
    if (
      isNil(data?.me) &&
      ALLOWED_APP_REDIRECTS.some(
        (allowedPath) => pathname.indexOf(allowedPath) > -1
      )
    ) {
      // need to preserve query string params for explore league detail page
      let stripApp = `${pathname.replace('/app', '')}?`
      const params = new URLSearchParams(window.location.search)
      // eslint-disable-next-line no-return-assign
      params.forEach((param, key) => (stripApp += `${key}=${param}&`))
      return <Navigate replace to={stripApp} />
    }

    if (data && data?.me) {
      const userRoleNames = userRolesToUserRoleNames(data.me.roles || [])

      if (!allowedRoles || intersection(userRoleNames, allowedRoles).length)
        return <Outlet context={rest} />

      // otherwise, the user does not have the correct roles to access this page
      return <div>Incorrect roles...</div>
    }

    // if we're not logged in, redirect to the /login page
    return <ReplaceWithLogin />
  }
)

export const AuthorizedContainer = ({
  component,
  allowedRoles = [],
}: {
  component: JSX.Element
  allowedRoles?: UserRoleName[]
}): JSX.Element => {
  const { data, loading } = useGetMyIdentityQuery()
  const { pathname } = useLocation()

  if (loading)
    return (
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
      >
        <LoadingSpinner />
      </Box>
    )

  // redirects users to url without '/app' in it
  if (
    isNil(data?.me) &&
    ALLOWED_APP_REDIRECTS.some(
      (allowedPath) => pathname.indexOf(allowedPath) > -1
    )
  ) {
    // need to preserve query string params for explore league detail page
    let stripApp = `${pathname.replace('/app', '')}?`
    const params = new URLSearchParams(window.location.search)
    // eslint-disable-next-line no-return-assign
    params.forEach((param, key) => (stripApp += `${key}=${param}&`))
    return <Navigate replace to={stripApp} />
  }

  if (data && data?.me) {
    const userRoleNames = userRolesToUserRoleNames(data.me.roles || [])

    if (
      allowedRoles &&
      (intersection(userRoleNames, allowedRoles).length || !allowedRoles.length)
    ) {
      return <>{component}</>
    }
    // otherwise, the user does not have the correct roles to access this page
    return <div>Incorrect roles...</div>
  }

  // if we're not logged in, redirect to the /login page
  return <ReplaceWithLogin />
}
