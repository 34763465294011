import React from 'react'
import { GetUserAttrs } from './fetchers/GetUserAttrs'
import { GetNotifications } from './fetchers/GetNotifications'
import { GetUserContent } from './fetchers/GetUserContent'

export const InitAppState: React.FC = () => (
  <>
    <GetNotifications />
    <GetUserAttrs />
    <GetUserContent />
  </>
)
