import React, { ReactElement, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ClassNameMap } from '@material-ui/styles'
import clsx from 'clsx'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'
import { EsportRating, Match } from '@plvs/graphql'
import {
  isTeamOwnerOfLobbyTeam,
  appendClasses,
  getIsMatchCheckedIn,
} from '@plvs/utils'
import dayjs from '@plvs/rally/init/dayjs'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { MatchAvatarGroup } from '@plvs/rally/components/avatar/MatchAvatarGroup'
import { useBreakpointMd } from '@plvs/respawn/features/layout'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  generateEsportDetails,
  useGeneralEsportAdapter,
} from '@plvs/respawn/features/esport/creator'
import { SchedulePageMatch } from '@plvs/rally/features/match/schedule/schedule'

import { VerifiedBackground } from '@plvs/rally/assets'
import { Verified } from '@playvs-inc/nexus-icons'
import { Tooltip } from '@plvs/rally/components/tooltip'

const useStyles = makeStyles((theme) => ({
  container: {
    scrollSnapAlign: 'start',
    minWidth: 'min-content',
  },
  matchCard: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
    width: '16em',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    fontFamily: '"TT Commons", Whitney',
    fontSize: '1.25rem',
    lineHeight: '133%',
    boxShadow: theme.shadows[1],
  },
  vsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  vsAvatarRow: {
    position: 'relative',
    alignContent: 'center',
    display: 'flex',
    flexBasis: 10,
    justifyContent: 'center',
  },
  checkInContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '1.4rem',
    width: '1.3rem',
  },
  tooltip: {
    padding: '4px 10px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
  checkedInIcon: {
    position: 'absolute',
    right: 0,
    bottom: '3px',
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  checkedInIconBackground: {
    position: 'absolute',
    right: 0,
    bottom: '2px',
    backgroundColor: theme.palette.ColorBackgroundBase,
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  vsText: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexBasis: 5,
    justifyContent: 'center',
    color: theme.palette.ColorTextAlt,
    fontSize: '1.3em',
    fontWeight: 700,
  },
  matchDetailsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  ctaButton: {
    textTransform: 'none',
  },
  ctaButtonCheckinUnavailable: {
    color: theme.palette.BorderDark,
    border: `solid 1px ${theme.palette.BorderDark}`,
  },
  gameNameLoading: {
    marginTop: '-1em',
    marginBottom: '-1em',
  },
  matchName: {
    maxWidth: '130px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
  },
  singleTeamName: {
    textTransform: 'capitalize',
    color: theme.palette.ColorTextBase,
  },
  vsSpacer: {
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  success: {
    color: theme.palette.ColorIconSuccess,
  },
  disabled: {
    color: theme.palette.ColorIconDisabled,
  },
  esportIcon: {
    marginRight: theme.spacing(1),
  },
}))

const getCheckinStatusIcon = (
  classes: ClassNameMap<string>,
  checkInStatus?: boolean | '' | undefined
): ReactElement => {
  return (
    <Box className={classes.checkInContainer}>
      {checkInStatus === true ? (
        <Tooltip
          arrow
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          title="Checked in"
        >
          <Box>
            <img
              alt="VerifiedBackground"
              className={classes.checkedInIconBackground}
              src={VerifiedBackground}
            />
            <Verified
              className={clsx(classes.checkedInIcon, classes.success)}
            />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          title="Not checked in"
        >
          <Box>
            <img
              alt="VerifiedBackground"
              className={classes.checkedInIconBackground}
              src={VerifiedBackground}
            />
            <Verified
              className={clsx(classes.checkedInIcon, classes.disabled)}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

export const getAvatarGroup = (
  match: Pick<Match, 'matchResults'>,
  displayText: string,
  isMobile?: boolean
): JSX.Element | null => {
  if (!match || !match.matchResults) return null
  const urls: string[] = match.matchResults
    .map((matchResult) => matchResult?.team?.school?.logoUrl || '')
    .filter((url) => url)
  return (
    <MatchAvatarGroup
      data-testid="match_avatar_group"
      displayText={displayText}
      logoUrls={urls.slice(1, 3)}
      size={isMobile ? 'large' : 'xlarge'}
    />
  )
}

export const DashboardMatchCard: React.FC<{
  match?: SchedulePageMatch
  isMobile: boolean
  isCoach?: boolean
}> = ({ match, isMobile, isCoach }) => {
  const { userRoles } = useUserIdentityFn()

  const isMobilePad = useBreakpointMd()
  const classes = useStyles()

  const { NexusIcon } = useGeneralEsportAdapter(match?.esport.slug)

  // Computed Props
  const dateFormat = dayjs(match?.scheduledStartsAt).isAfter(
    dayjs().add(6, 'd')
  )
    ? 'MMM Do @ h:mm A'
    : 'dddd @ h:mm A'

  const formatTime = match?.scheduledStartsAt
    ? dayjs(match?.scheduledStartsAt).format(dateFormat)
    : undefined

  const isTeamVsTeamFormat = !generateEsportDetails(
    match?.esport?.slug,
    undefined,
    false
  ).isFortnite

  const myTeamId = match?.matchResults?.find((result) => result?.team?.isMyTeam)
    ?.team?.id
  const opponentTeamId = match?.matchResults?.find(
    (result) => !result?.team?.isMyTeam
  )?.team?.id
  const team1Results = match?.matchResults?.[0]
  const team2Results = match?.matchResults?.[1]

  const team1Logo = team1Results?.team?.school?.logoUrl ?? ''
  const team2Logo = team2Results?.team?.school?.logoUrl ?? ''

  const team1Name = team1Results?.team?.name ?? ''
  const team2Name = team2Results?.team?.name ?? 'TBD'

  const isTeamOwner =
    userRoles && myTeamId && isTeamOwnerOfLobbyTeam(userRoles, myTeamId)

  const canCheckIn =
    dayjs().isBetween(
      dayjs(match?.checkInOpensAt),
      dayjs(match?.checkInClosesAt)
    ) &&
    (isTeamOwner || isCoach)

  const isMyTeamCheckedIn =
    myTeamId && match && getIsMatchCheckedIn([myTeamId], match)

  const isOpponentTeamCheckedIn =
    opponentTeamId && match && getIsMatchCheckedIn([opponentTeamId], match)

  const avatarGroup = useMemo(
    () =>
      match &&
      getAvatarGroup(
        match,
        `+ ${match?.matchResults?.length}`,
        isMobile || isMobilePad
      ),
    [match, isMobile, isMobilePad]
  )

  const opponent = !isTeamVsTeamFormat ? (
    avatarGroup
  ) : (
    <NxUserAvatar
      avatarUrl={
        match?.esport?.rating === EsportRating.Restricted ? null : team2Logo
      }
      hashId={opponentTeamId ?? ''}
      size={NxAvatarSize.LG}
    />
  )

  const singleTeamDisplay = (
    <NxTypography
      className={classes.singleTeamName}
      data-testid="DashboardMatchCard_SingleTeamDisplay"
      variant="body1"
    >
      {team1Name} ({match?.status})
    </NxTypography>
  )

  const defaultTeamDisplay = (
    <>
      <NxTypography
        className={classes.matchName}
        color="textPrimary"
        data-cy="team1MatchCard"
        variant="body1"
      >
        {team1Name}
      </NxTypography>
      <NxTypography
        className={classes.vsSpacer}
        color="textPrimary"
        variant="body1"
      >
        {' '}
        vs{' '}
      </NxTypography>
      <NxTypography
        className={classes.matchName}
        color="textPrimary"
        data-cy="team2MatchCard"
        variant="body1"
      >
        {isTeamVsTeamFormat
          ? team2Name
          : `${match?.matchResults?.length} teams`}
      </NxTypography>
    </>
  )

  // Secondary is for scrimmage matches as they do no have a name assigned to the match
  const matchName = match?.name ? (
    <NxTypography color="textPrimary" variant="body1">
      {match?.name}
    </NxTypography>
  ) : (
    <Box display="flex">
      {team2Results ? defaultTeamDisplay : singleTeamDisplay}
    </Box>
  )

  return (
    <Box
      className={appendClasses(
        classes.container,
        'MatchCard' /* This is used as a locator for '<UpcomingMatches />' component. */
      )}
      data-testid="DashboardMatchCard_MatchCard"
      mr={3}
    >
      <Box className={classes.matchCard} data-cy="matchCard">
        <Box display="flex" maxHeight={20}>
          <NxTypography variant="h4">
            {formatTime ?? (
              <Skeleton
                height="2em"
                style={{ marginTop: '-0.5em' }}
                variant="text"
                width="9em"
              />
            )}
          </NxTypography>
        </Box>
        {/* // Vs. Box */}
        <Box pb={2} pt={4}>
          <Box className={classes.vsBox}>
            <Box className={classes.vsAvatarRow}>
              {match ? (
                <NxUserAvatar
                  avatarUrl={
                    match?.esport?.rating === EsportRating.Restricted
                      ? null
                      : team1Logo
                  }
                  hashId={myTeamId ?? ''}
                  size={NxAvatarSize.LG}
                />
              ) : (
                <Skeleton height="4em" variant="circle" width="4em" />
              )}
              {canCheckIn && getCheckinStatusIcon(classes, isMyTeamCheckedIn)}
            </Box>
            <Box className={classes.vsText}>vs</Box>
            <Box className={classes.vsAvatarRow}>
              {match ? (
                opponent
              ) : (
                <Skeleton height="4em" variant="circle" width="4em" />
              )}
              {canCheckIn &&
                getCheckinStatusIcon(classes, isOpponentTeamCheckedIn)}
            </Box>
          </Box>
        </Box>

        {/* // Match Name */}
        <Box mb={3.75} mt={2.25}>
          <Box>
            {(match && matchName) ?? (
              <Skeleton height="2em" variant="text" width="10em" />
            )}
          </Box>
          {/* // Match Details */}
          <Box className={classes.matchDetailsContainer} mt={1}>
            {NexusIcon && (
              <NexusIcon
                className={classes.esportIcon}
                height={20}
                width={20}
              />
            )}
            <NxTypography
              color="textSecondary"
              colorToken="ColorTextAlt"
              variant="body3"
            >
              {match?.esport.name ?? (
                <Skeleton
                  className={classes.gameNameLoading}
                  variant="text"
                  width="15em"
                />
              )}
            </NxTypography>
          </Box>
        </Box>

        {/* // CTA button */}
        <Tooltip
          arrow
          placement="bottom"
          title={
            match?.id
              ? ''
              : 'Lobby is not available until all teams are set for this match'
          }
        >
          <Box mt="auto">
            {!match && (
              <Skeleton
                height="1.71em"
                style={{ marginTop: '-1em' }}
                variant="rect"
                width="100%"
              />
            )}

            {match &&
              (canCheckIn && !isMyTeamCheckedIn ? (
                <NxButton
                  className={classes.ctaButton}
                  data-cy="checkInButton"
                  disabled={!match?.id || !team2Results}
                  fullWidth
                  href={`${Path.MatchCheckIns}/${match?.id}`}
                  label="Check In"
                  variant="primary"
                />
              ) : (
                <NxButton
                  className={appendClasses(
                    classes.ctaButton,
                    classes.ctaButtonCheckinUnavailable
                  )}
                  data-cy="viewMatchLobbyButton"
                  data-testid="DashboardMatchCard_Button"
                  disabled={!match?.id || !team2Results}
                  fullWidth
                  href={
                    match?.id
                      ? `${Path.Match}/${match.id}`
                      : `${Path.Dashboard}`
                  }
                  label="Open Match"
                  variant="secondary"
                />
              ))}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}
