import { Box, Card, CardContent, makeStyles } from '@material-ui/core'
import {
  CompetitionGroup,
  refetchGetMySchoolDetailsQuery,
  useGetMySchoolDetailsQuery,
  useUpdateAllowCaptainEnrollmentMutation,
  useUpdateAllowPlayerLedTeamsMutation,
  useUpdateShowCoachEmailsMutation,
} from '@plvs/graphql/generated'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import React, { FunctionComponent, useEffect } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { SchoolPermissions } from './SchoolPermissions'

const useStyles = makeStyles((theme) => ({
  cardContentContainer: {
    padding: theme.spacing(3),
  },
}))

export const SchoolPermissionsFormPanel: FunctionComponent = () => {
  const withSaveNotification = useWithSaveNotification()
  const classes = useStyles()

  // Queries
  // Grab school data from cache.
  const { data } = useGetMySchoolDetailsQuery()

  // Computed props
  const schoolId = data?.me?.school?.id ?? ''

  const currentAllowPlayerLeadTeams =
    data?.me?.school?.allowPlayerLedTeams ?? false
  const currentAllowCaptainEnrollment =
    data?.me?.school?.allowCaptainEnrollment ?? false
  const currentShowCoachEmails = data?.me?.school?.showCoachEmails ?? false

  const competitionGroup =
    data?.me?.school?.competitionGroup ?? CompetitionGroup.HighSchool

  // State vars
  const [allowPlayerLedTeams, setAllowPlayerLedTeams] = React.useState<boolean>(
    currentAllowPlayerLeadTeams
  )
  const [allowCaptainEnrollment, setAllowCaptainEnrollment] = React.useState<
    boolean
  >(currentAllowCaptainEnrollment)
  const [showCoachEmails, setShowCoachEmails] = React.useState<boolean>(
    currentShowCoachEmails
  )

  // Mutations
  const [mutateAllowPlayerLedTeams] = useUpdateAllowPlayerLedTeamsMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })
  const [
    mutateAllowCaptainEnrollment,
  ] = useUpdateAllowCaptainEnrollmentMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  const [mutateShowCoachEmails] = useUpdateShowCoachEmailsMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  // Side Effects

  // Tracks allow player led teams.
  useEffect(() => {
    if (allowPlayerLedTeams === currentAllowPlayerLeadTeams) {
      return
    }

    withSaveNotification(
      async (): Promise<void> => {
        await mutateAllowPlayerLedTeams({
          variables: {
            allowPlayerLedTeams,
            schoolId,
          },
        })
      }
    )
  }, [allowPlayerLedTeams, schoolId])

  // Tracks allow captain enrollment
  useEffect(() => {
    if (allowCaptainEnrollment === currentAllowCaptainEnrollment) {
      return
    }

    withSaveNotification(
      async (): Promise<void> => {
        await mutateAllowCaptainEnrollment({
          variables: {
            allowCaptainEnrollment,
            schoolId,
          },
        })
      }
    )
  }, [allowCaptainEnrollment, schoolId])

  // Tracks show coach emails
  useEffect(() => {
    if (showCoachEmails === currentShowCoachEmails) {
      return
    }

    withSaveNotification(
      async (): Promise<void> => {
        await mutateShowCoachEmails({
          variables: {
            showCoachEmails,
            schoolId,
          },
        })
      }
    )
  }, [showCoachEmails, schoolId])

  return (
    <form>
      <Card>
        <CardContent className={classes.cardContentContainer}>
          <Box mb={2}>
            <NxTypography variant="h4">Permissions</NxTypography>
          </Box>
          <SchoolPermissions
            allowCaptainEnrollment={allowCaptainEnrollment}
            allowPlayerLedTeams={allowPlayerLedTeams}
            competitionGroup={competitionGroup}
            setAllowCaptainEnrollment={setAllowCaptainEnrollment}
            setAllowPlayerLedTeams={setAllowPlayerLedTeams}
            setShowCoachEmails={setShowCoachEmails}
            showCoachEmails={showCoachEmails}
          />
        </CardContent>
      </Card>
    </form>
  )
}
