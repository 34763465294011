import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'
import { Grid, makeStyles, Paper, useTheme } from '@material-ui/core'
import { NxTypography, getEsportColorValue } from '@playvs-inc/nexus-components'
import { Linkout } from '@playvs-inc/nexus-icons'
import Handbook from '@plvs/rally/assets/coachResources/handbook.png'
import HandbookMobile from '@plvs/rally/assets/coachResources/handbook-mobile.jpg'
import ResourceHub from '@plvs/rally/assets/coachResources/resource-hub.png'
import ResourceHubMobile from '@plvs/rally/assets/coachResources/resource-hub-mobile.jpg'
import { CreateCSSProperties } from '@material-ui/styles'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'

import { IntercomArticleMappings } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { RulebookImg } from '../rulebook/RulebookImg'

export const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    height: 160,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  rulebookOverlay: ({
    overlayColor,
  }: {
    overlayColor: string
  }): CreateCSSProperties => ({
    display: 'flex',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: overlayColor,
      opacity: 0.7,
      [theme.breakpoints.down('xs')]: {
        borderRadius: theme.shape.borderRadius * 2,
      },
    },
  }),
  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    width: 70,
    height: 70,
  },
  paper: {
    padding: theme.spacing(3),
    boxShadow: theme.mixins.boxShadow.elevation1,
  },
  resourceText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    textAlign: 'left',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  link: {
    cursor: 'pointer',
  },
}))

const RESOURCE_LINK = 'https://help.playvs.com/en/'

interface Props {
  esportSlug?: EsportSlug
  rulebookUrl?: string
}

export const CoachResources: React.FC<Props> = ({
  esportSlug,
  rulebookUrl,
}) => {
  const theme = useTheme()

  const overlayColor = getEsportColorValue(theme, esportSlug, '6')

  const classes = useStyles({
    overlayColor: overlayColor || theme.palette.common.white,
  })

  const isXs = useBreakpointXs()

  return (
    <Paper className={classes.paper}>
      <NxTypography className={classes.title} variant="h3">
        Coach Resources
      </NxTypography>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={6}>
          <Box
            className={classes.link}
            onClick={(): void =>
              showIntercomArticle(IntercomArticleMappings.coachHandbook)
            }
          >
            <img
              alt="handbook"
              className={classes.img}
              src={isXs ? HandbookMobile : Handbook}
            />
          </Box>
          <NxTypography className={classes.resourceText} variant="subtitle1">
            PlayVS Coach Handbook
          </NxTypography>
        </Grid>
        {rulebookUrl && (
          <Grid item sm={12} xs={6}>
            <RulebookImg
              esportSlug={esportSlug}
              isPublic={false}
              rulebookUrl={rulebookUrl}
            />
          </Grid>
        )}
        <Grid item sm={12} xs={6}>
          <a href={RESOURCE_LINK} rel="noreferrer" target="_blank">
            <img
              alt="resource-hub"
              className={classes.img}
              src={isXs ? ResourceHubMobile : ResourceHub}
            />
          </a>
          <NxTypography className={classes.resourceText} variant="subtitle1">
            PlayVS Resource Hub <Linkout />
          </NxTypography>
        </Grid>
      </Grid>
    </Paper>
  )
}
