import { useReactiveVar } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import {
  activeGlobalConversationsVar,
  ActiveGlobalConveresationsVarPayload,
} from '../models/GlobalChatConversations'

export const useActiveGlobalChatConversationsVar = (): ActiveGlobalConveresationsVarPayload => {
  const activeConvos = useReactiveVar(activeGlobalConversationsVar)
  const activeConversations = activeConvos?.activeConversations

  const setVar = useCallback(() => {
    activeGlobalConversationsVar({
      activeConversations,
    })
  }, [JSON.stringify(activeConversations)])

  useEffect(() => {
    setVar()
  }, [JSON.stringify(activeConversations)])

  return activeConvos
}
