import React from 'react'
import * as yup from 'yup'
import tz from 'dayjs/plugin/timezone'

import {
  EsportSlug,
  refetchGetSchoolScrimmagesQuery,
  refetchGetSchoolAvailableScrimmagesQuery,
  GetSchoolRecommendedScrimmagesQuery,
  ScrimmageRequestTimeFilter,
  CompetitionGroup,
} from '@plvs/graphql'
import {
  yupEsportRequired,
  yupTeamIdRequired,
  yupTimeRequired,
  yupDateRequired,
  betterCompact,
  yupRatingRequired,
} from '@plvs/utils'
import { ScrimmageTableLimits } from '@plvs/const'
import { RecommendedScrimmage } from '@plvs/rally/components/scrimmage'
import { AllEsportsData } from '@plvs/respawn/features/esport/creator/types'
import dayjs from '@plvs/rally/init/dayjs'

dayjs.extend(tz)

export const JoinScrimmageValidationSchema = yup.object().shape({
  opposingTeamId: yup.string().required('Please select a team'),
})

export enum CreateScrimmageStep {
  Initialize = 'init',
  RecommendedScrimmages = 'recommended-scrimmages',
  ChooseOpponent = 'choose-opponent',
}

export enum ScrimmageOpponentType {
  Private = 'private',
  Public = 'public',
}

export interface CreateScrimmageFormInput {
  allowAutoSchedule?: boolean
  requestingTeamId?: string
  date?: string
  time?: string
  esport?: EsportSlug
  opponentType?: ScrimmageOpponentType
  bestOf?: string
  rating: string
}

export const CreateScrimmageFormSchema = yup.object().shape({
  allowAutoSchedule: yup.boolean(),
  date: yupDateRequired,
  esport: yupEsportRequired,
  rating: yupRatingRequired,
  requestingTeamId: yupTeamIdRequired,
  time: yupTimeRequired,
})

export const getScrimmageEsportSlugs = (
  filteredEsportSlugs: EsportSlug[],
  useAllEsportAdapters: AllEsportsData
): EsportSlug[] => {
  return betterCompact(
    useAllEsportAdapters
      .filter(
        (esportAdapter) =>
          esportAdapter.slug &&
          esportAdapter.isScrimmageEnabled &&
          esportAdapter.isEsportEnabled &&
          filteredEsportSlugs?.includes(esportAdapter.slug)
      )
      .map((esportAdapter) => esportAdapter.slug)
  )
}

export const getEsportsSlugFilterConfig = ({
  filteredEsportSlugs,
  useAllEsportAdapters,
}: {
  filteredEsportSlugs?: EsportSlug[]
  useAllEsportAdapters: AllEsportsData
}): {
  esportSlugs?: EsportSlug[]
  showEmptyOption?: boolean
  useQueryParam?: boolean
} => {
  return {
    esportSlugs: getScrimmageEsportSlugs(
      filteredEsportSlugs ?? [],
      useAllEsportAdapters
    ),
    showEmptyOption: true,
  }
}

export const ScrimmagePageRefreshContext = React.createContext<{
  isRefreshed: boolean
  toggleRefresh(isRefreshed: boolean): void
}>({
  isRefreshed: false,
  toggleRefresh: () => {},
})

type ScrimmageRequestWithDate = Pick<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<GetSchoolRecommendedScrimmagesQuery>['school']
      >['availableScrimmageRequests']
    >['result']
  >[0],
  'startsAt'
>

export const sortScrimmagesByDescendingTime = (
  scrimmageA: ScrimmageRequestWithDate,
  scrimmageB: ScrimmageRequestWithDate
): number =>
  dayjs(scrimmageA.startsAt).unix() - dayjs(scrimmageB.startsAt).unix()

export const sortScrimmagesByAscendingTime = (
  scrimmageA: ScrimmageRequestWithDate,
  scrimmageB: ScrimmageRequestWithDate
): number =>
  dayjs(scrimmageB.startsAt).unix() - dayjs(scrimmageA.startsAt).unix()

export const getRefetchScrimmageQueries = (schoolId: string | null): any[] =>
  schoolId
    ? [
        refetchGetSchoolScrimmagesQuery({
          id: schoolId,
          filter: {
            time: ScrimmageRequestTimeFilter.Future,
          },
          limit: ScrimmageTableLimits.MyScrimmages,
          offset: 0,
        }),
        refetchGetSchoolScrimmagesQuery({
          id: schoolId,
          filter: {
            time: ScrimmageRequestTimeFilter.Past,
          },
          limit: ScrimmageTableLimits.MyScrimmages,
          offset: 0,
        }),
        refetchGetSchoolAvailableScrimmagesQuery({
          id: schoolId,
          limit: ScrimmageTableLimits.AvailableScrimmages,
          offset: 0,
        }),
      ]
    : []

// Filter all available scrimmages in -1, 0, +1 hours to startsAt
// Filter time filtered scrimmages by recommendation score (min 80)
// If no time + recommendation score filtered scrimmages, return time filtered
export const findTopThreeScrimmages = ({
  startsAt,
  availableScrimmagesData,
  esportSlug,
  teamId,
  schoolCompetitionGroup,
}: {
  startsAt: string | null
  availableScrimmagesData?: GetSchoolRecommendedScrimmagesQuery
  esportSlug: EsportSlug | null
  teamId: string
  schoolCompetitionGroup: CompetitionGroup | null
}): RecommendedScrimmage[] => {
  if (!startsAt || !availableScrimmagesData) return []

  const allAvailableScrimmages =
    availableScrimmagesData?.school?.availableScrimmageRequests?.result ?? []
  const availableScrimmages = esportSlug
    ? allAvailableScrimmages.filter(
        (scrimmage) =>
          scrimmage?.esport?.slug === esportSlug &&
          scrimmage?.requestingTeamId !== teamId
      )
    : allAvailableScrimmages

  const userInputTime = dayjs(startsAt)
  const dayAfter = userInputTime.add(1, 'day')
  const dayBefore = userInputTime.subtract(1, 'day')

  const scrimmagesWithinRange = availableScrimmages
    .filter((scrimmage) =>
      // Inclusive of start and end dates
      dayjs(scrimmage.startsAt).isBetween(dayAfter, dayBefore, 'second', '[]')
    )
    .sort((scrimmageA, scrimmageB) =>
      dayjs(scrimmageA.startsAt).diff(dayjs(scrimmageB.startsAt))
    )

  return scrimmagesWithinRange.filter((scrimmage) =>
    schoolCompetitionGroup
      ? scrimmage?.requestingTeam?.competitionGroup === schoolCompetitionGroup
      : true
  )
}
