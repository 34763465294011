import { Season } from '@plvs/graphql/generated'
import dayjs from 'dayjs'

export const getHoursLeft = ({
  seasons,
  isLateEnrollment,
}: {
  seasons: Pick<
    Season,
    'teamRegistrationEndsAt' | 'suggestedRegistrationEndsAt'
  >[]
  isLateEnrollment: boolean
}): number => {
  const now = dayjs()

  const sortedSeasonDates = isLateEnrollment
    ? seasons
        .sort((a, b) => {
          const dateA = a?.teamRegistrationEndsAt ?? ''
          const dateB = b?.teamRegistrationEndsAt ?? ''

          return dayjs(dateA).isAfter(dateB) ? 1 : -1
        })
        .map((s) => s?.teamRegistrationEndsAt)
    : seasons
        .sort((a, b) => {
          const dateA = a?.suggestedRegistrationEndsAt ?? ''
          const dateB = b?.suggestedRegistrationEndsAt ?? ''

          return dayjs(dateA).isAfter(dateB) ? 1 : -1
        })
        .map((s) => s?.suggestedRegistrationEndsAt)

  let hoursLeft = 0

  const sortedSeasonDatesLength = sortedSeasonDates.length
  for (let i = 0; i < sortedSeasonDatesLength; i += 1) {
    const diff = dayjs(sortedSeasonDates[i]).diff(now, 'hours')

    if (diff > 0) {
      hoursLeft = diff
      break
    }
  }
  return hoursLeft
}
