import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  titleClass: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '250px',
    },
  },
  contentClass: {
    padding: 0,
    marginTop: 0,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  matchInfo: {
    borderRadius: theme.shape.borderRadius * 2.5,
    backgroundColor: theme.palette.ColorBackgroundAlt,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vsText: {
    color: theme.palette.ColorTextAlt2,
  },
  avatar: {
    height: 26,
    width: 26,
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  vsContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(0.5),
    fontWeight: 700,
  },
  ratingContainer: {
    gridGap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  commentContainer: {
    display: 'flex',
    gridGap: theme.spacing(2),
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(0.5),
  },
  commentHeaderText: {
    color: theme.palette.ColorTextAlt,
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    gridGap: theme.spacing(1),
  },
  infoInputTitle: {
    marginBottom: theme.spacing(1),
  },
  firstSurveyContainer: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(35vh - 140px)',
    },
  },
}))
