import {
  Round,
  PercentWithOneDecimalPlace,
  RoundToOneDecimalPlace,
} from '@plvs/utils'

export const Score = {
  accessor: 'stats.score',
  Header: 'Score',
  HeaderTooltip: 'Score',
  format: Round,
}

export const ShootingPercentage = {
  accessor: 'stats.shootingPercentage',
  Header: 'Shot %',
  HeaderTooltip: 'Shooting Percentage',
  format: PercentWithOneDecimalPlace,
}

export const Goals = {
  accessor: 'stats.goals',
  Header: 'Goals',
  HeaderTooltip: 'Goals',
  format: RoundToOneDecimalPlace,
}

export const Saves = {
  accessor: 'stats.saves',
  Header: 'Saves',
  HeaderTooltip: 'Saves',
  format: RoundToOneDecimalPlace,
}

export const RocketLeagueAssists = {
  accessor: 'stats.assists',
  Header: 'Assists',
  HeaderTooltip: 'Assists',
  format: RoundToOneDecimalPlace,
}

export const Shots = {
  accessor: 'stats.shots',
  Header: 'Shots',
  HeaderTooltip: 'Shots',
  format: RoundToOneDecimalPlace,
}

export const AvgShootingPercentage = {
  accessor: 'stats.shootingPercentage',
  Header: 'S%',
  HeaderTooltip: 'Avg. Shooting Percentage',
  format: PercentWithOneDecimalPlace,
}

export const AvgGoals = {
  accessor: 'stats.averageGoals',
  Header: 'G',
  HeaderTooltip: 'Avg. Goals',
  format: RoundToOneDecimalPlace,
}

export const AvgSaves = {
  accessor: 'stats.averageSaves',
  Header: 'S',
  HeaderTooltip: 'Avg. Saves',
  format: RoundToOneDecimalPlace,
}

export const AvgRocketLeagueAssistsSaves = {
  accessor: 'stats.averageAssists',
  Header: 'A',
  HeaderTooltip: 'Avg. Assists',
  format: RoundToOneDecimalPlace,
}

export const AvgShots = {
  accessor: 'stats.averageShots',
  Header: 'SH',
  HeaderTooltip: 'Avg. Shots',
  format: RoundToOneDecimalPlace,
}
