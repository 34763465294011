import { Link, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { RouterLink } from '@plvs/respawn/features/route'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  titleColor: { color: theme.palette.ColorTextBase },
  subtitleColor: { color: theme.palette.ColorTextAlt2 },
  loss: {
    color: theme.palette.ColorTextError,
  },
  win: {
    color: theme.palette.ColorTextSuccess,
  },
  vs: {
    textOverflow: 'ellipsis',
  },
}))

export const GameCluster: React.FC<{
  gameId?: string
  matchId: string | null
  opponent?: string | string[]
  outcome?: string
  opposing?: boolean
  subtitle?: string | null
  title?: string | null
}> = ({ gameId, matchId, opponent, outcome, opposing, subtitle, title }) => {
  const styles = useStyles()

  return (
    <Link
      key={gameId}
      component={RouterLink}
      to={
        gameId
          ? `/app/match/${matchId}/game/${gameId}`
          : `/app/match/${matchId}`
      }
      underline="none"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection={opposing ? 'row-reverse' : 'row'}
        justifyContent="flex-start"
        py={3}
      >
        <div style={{ marginRight: '30px' }}>
          <NxTypography colorToken="ColorTextBase" variant="h3">
            {title}
          </NxTypography>
          <NxTypography colorToken="ColorTextAlt2" variant="subtitle2">
            {subtitle}
          </NxTypography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginRight: '30px',
            width: '24px',
          }}
        >
          <span className={outcome === 'L' ? styles.loss : styles.win}>
            {outcome}
          </span>
        </div>
        <div>
          <NxTypography
            className={styles.vs}
            colorToken="ColorTextAlt2"
            variant="h4"
          >
            {opponent && `vs ${opponent}`}
          </NxTypography>
        </div>
      </Box>
    </Link>
  )
}
