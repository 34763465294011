import React, { useEffect } from 'react'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql/types'
import { makeStyles } from '@material-ui/core'
import { LogoWordMark } from '@plvs/rally/components/icon'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NxErrorState } from '@playvs-inc/nexus-components'
import { PrivatePage } from '../../page'

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%',
    '& div': {
      display: 'flex',
    },
    width: '100%',
    margin: 'auto',
    color: theme.palette.common.white,
  },
  desktopLogo: {
    width: '95px',
    height: '26px',
  },
  imgStyle: {
    objectFit: 'cover',
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    minHeight: '100vh',
    background: theme.palette.common.black,
  },
  overlay: {
    background:
      'linear-gradient(360deg, #000000 36.73%, rgba(24, 26, 27, 0.35) 97.95%)',
    minHeight: '100vh',
  },
}))

interface QueuePageWrapperProps {
  loading?: boolean
  pageDataTestId: string
  pageTitle: string
  esportSlug: EsportSlug
  backgroundImageSrc: string
}

export const QueuePageWrapper: React.FC<QueuePageWrapperProps> = ({
  loading,
  pageDataTestId,
  pageTitle,
  esportSlug,
  backgroundImageSrc,
  children,
}) => {
  const flags = useFlags()
  const { boxWrapper, desktopLogo, imgStyle, overlay } = useStyles()

  useEffect(() => {
    document.body.style.height = '100%'
    document.documentElement.style.height = '100%'
    return () => {
      document.body.style.height = ''
      document.documentElement.style.height = ''
    }
  }, [])

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      <PrivatePage data-testid={pageDataTestId} title={pageTitle}>
        <PageContentGutter>
          <Box
            bottom="0"
            left="0"
            overflow="hidden"
            position="absolute"
            right="0"
            top="0"
            zIndex="1"
          >
            <img
              alt={esportSlug}
              className={imgStyle}
              src={backgroundImageSrc}
            />
          </Box>
          <Box
            bottom="0"
            className={overlay}
            left="0"
            overflow="hidden"
            position="absolute"
            right="0"
            top="0"
            zIndex="1"
          />
          <Box className={boxWrapper} position="relative" zIndex="3">
            <Box display="flex" flexGrow="0">
              <LogoWordMark className={desktopLogo} />
            </Box>
            {flags.errorStateMatchQueue ? (
              <NxErrorState
                additionalInfo="Please return to the match lobby."
                componentDescription="match queue"
                isStandalone
              />
            ) : (
              <>{children}</>
            )}
          </Box>
        </PageContentGutter>
      </PrivatePage>
    </WaitTillLoaded>
  )
}
