import React from 'react'
import { useTheme } from '@material-ui/core'

import { NxButton, NxSkeletonLoader } from '@playvs-inc/nexus-components'

import { MatchCard, MatchCardTab } from '@plvs/respawn/features/match/MatchCard'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql'
import { Path } from '@plvs/const'

import { PastScrimmages } from './PastScrimmages'
import { UpcomingScrimmages } from './UpcomingScrimmages'
import { UnplayedScrimmages } from './UnplayedScrimmages'

interface MyScrimmagesProps {
  schoolId: string
  esportSlugFilter?: React.ReactElement
  selectedEsportSlug?: EsportSlug
}

export const MyScrimmages: React.FC<MyScrimmagesProps> = ({
  schoolId,
  esportSlugFilter,
  selectedEsportSlug,
}) => {
  const theme = useTheme()

  return (
    <WaitTillLoaded
      loading={!schoolId}
      LoadingComponent={(): React.ReactElement => (
        <NxSkeletonLoader height="276px" variant="rect" width="100%" />
      )}
      showSpinnerWhileLoading
    >
      <MatchCard
        sideContent={
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gridGap={theme.spacing(1)}
            justifyContent="flex-end"
          >
            {esportSlugFilter}
            <NxButton
              href={Path.CreateScrimmage}
              label="Create Scrimmage"
              variant="primary"
            />
          </Box>
        }
        title="My Scrimmages"
        titleTypographyVariant="h4"
      >
        <MatchCardTab tab="Upcoming">
          <UpcomingScrimmages
            schoolId={schoolId}
            selectedEsportSlug={selectedEsportSlug}
          />
        </MatchCardTab>
        <MatchCardTab tab="Past Results">
          <PastScrimmages
            schoolId={schoolId}
            selectedEsportSlug={selectedEsportSlug}
          />
        </MatchCardTab>
        <MatchCardTab data-cy="notPlayedTab" tab="Not Played">
          <UnplayedScrimmages
            schoolId={schoolId}
            selectedEsportSlug={selectedEsportSlug}
          />
        </MatchCardTab>
      </MatchCard>
    </WaitTillLoaded>
  )
}
