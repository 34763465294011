import React from 'react'
import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core'
import { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'

import { ForwardRef } from './ForwardRef'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.ColorBackgroundInvert,
    color: theme.palette.ColorTextInvert,
    ...theme.typography.body1,
  },
  arrow: {
    color: theme.palette.ColorBackgroundInvert,
  },
}))

export const Tooltip: React.FC<
  Omit<MuiTooltipProps, 'title'> & {
    breakForwardRef?: boolean
    title: React.ReactNode | undefined
  }
> = ({
  arrow = true,
  breakForwardRef = true,
  children,
  enterTouchDelay = 0,
  title,
  style,
  classes,
  ...rest
}) => {
  const styles = useStyles()

  return (
    <MuiTooltip
      arrow={arrow}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow, ...classes }}
      enterTouchDelay={enterTouchDelay}
      style={style}
      title={title ?? ''}
      {...rest}
    >
      {breakForwardRef ? (
        <ForwardRef
        // wrapping children with ForwardRef will prevent the tooltip from disabling
        //  when children (ex. children is a button) is disabled
        >
          {children}
        </ForwardRef>
      ) : (
        children
      )}
    </MuiTooltip>
  )
}
