import React from 'react'
import { makeStyles } from '@material-ui/core'

import { User, Maybe, CompetitionGroup, UserEmail } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import {
  ContactCluster,
  HideContactItemOption,
} from '@plvs/rally/components/cluster'
import { NxTypography } from '@playvs-inc/nexus-components'

export interface Contact
  extends Pick<User, 'id' | 'name' | 'avatarUrl' | 'lastSeen' | 'phone'> {
  emails: Maybe<Array<Maybe<Pick<UserEmail, 'email' | 'isSchoolEmail'>>>>
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.BorderLight,
    },
  },
}))

export const Contact: React.FC<{
  contacts: Contact[]
  competitionGroup: CompetitionGroup | null | undefined
  hideClustersArray?: HideContactItemOption[]
  isCheckpoint?: boolean
}> = ({ contacts, competitionGroup, hideClustersArray, isCheckpoint }) => {
  const classes = useStyles()

  return contacts.length ? (
    <Box display="flex" flexDirection="column">
      {contacts.map((contact) => {
        return (
          <div key={contact.id} className={classes.root}>
            <ContactCluster
              avatarUrl={contact.avatarUrl}
              email={
                contact?.emails?.find((e) => e?.isSchoolEmail)?.email ?? null
              }
              hideClustersArray={hideClustersArray}
              isCheckpoint={isCheckpoint}
              phone={
                competitionGroup === CompetitionGroup.Youth
                  ? null
                  : contact.phone
              }
              subtitle="coach"
              title={contact.name || 'No name'}
              userId={contact.id}
            />
          </div>
        )
      })}
    </Box>
  ) : (
    <Box p={3}>
      <NxTypography variant="h4">No contact information</NxTypography>
    </Box>
  )
}
