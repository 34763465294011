import React, { useCallback } from 'react'

import { ChatRole } from '@plvs/graphql/generated'
import { ChatRenderController } from './chat/types/chatRenderController.types'
import { determineChatRender } from './chat/utils/chatRenderController.helpers'
import {
  determineMatchAssistantActions,
  determineMatchLobbyComponentsRender,
} from './match/utils/matchRenderController.helpers'
import { MatchRenderController } from './match/types/matchRenderController.types'
import { MatchLobbyRenderControllerState } from './MatchLobbyRenderController.types'
import { LobbyRenderController } from './lobby/types/lobbyRenderController.types'
import {
  determineLobbyComponentsRender,
  determineHomeAndAwayTeamDisplayDetails,
} from './lobby/utils/lobbyRenderController.helpers'

type UseMatchLobbyRenderControllerContextReturn = {
  match: MatchRenderController
  chat: ChatRenderController
  lobby: LobbyRenderController
}

type MatchLobbyRenderControllerContext =
  UseMatchLobbyRenderControllerContextReturn & {
    getMatchLobbyRenderControllerState: () => MatchLobbyRenderControllerState
    setMatchLobbyRenderControllerStateFn: (
      updatedStateValues: Partial<MatchLobbyRenderControllerState>
    ) => void
  }

export const useMatchLobbyRenderControllerContext =
  (): UseMatchLobbyRenderControllerContextReturn => {
    return {
      chat: {
        getChatComponentsToRender: determineChatRender,
      },
      match: {
        getMatchLobbyComponentsToRender: determineMatchLobbyComponentsRender,
        getMatchAssistantActions: determineMatchAssistantActions,
      },
      lobby: {
        getLobbyComponentsToRender: determineLobbyComponentsRender,
        getHomeAndAwayTeamDisplayDetails:
          determineHomeAndAwayTeamDisplayDetails,
      },
    }
  }

export const matchLobbyRenderControllerContext =
  React.createContext<MatchLobbyRenderControllerContext>({
    match: {
      getMatchLobbyComponentsToRender: () => ({
        shouldRenderMatchReporting: false,
        shouldRenderMatchAssistant: false,
        shouldRenderMatchAssistantForBothTeams: false,
        shouldShowMatchLobbyTour: false,
      }),
      getMatchAssistantActions: () => ({
        canReport: false,
        canParticipate: false,
        canSpectate: false,
        canDisputeMatch: false,
        canManageMatch: false,
        canManageRoster: false,
        canQueue: false,
        rolesThatCanManageRosterCopy: [],
      }),
    },
    chat: {
      getChatComponentsToRender: () => ({
        shouldRenderChat: false,
        shouldRenderCoachChat: false,
        // Player is the lowest level of permissions in chat.
        chatRole: ChatRole.Player,
      }),
    },
    lobby: {
      getLobbyComponentsToRender: () => ({
        showManageMatchSection: false,
        showMatchLobbyTour: false,
        myTeamIds: [],
        opposingTeamId: '',
        isMemberOfBothTeams: false,
        showActionAndInfoSection: false,
        canManageQueueBannerActions: false,

        showRescheduleMatchAlert: false,
        enableScoutingToTeamsPage: false,
      }),
      getHomeAndAwayTeamDisplayDetails: () => ({
        homeTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
        awayTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
      }),
    },
    getMatchLobbyRenderControllerState: () => ({
      match: {
        shouldRenderMatchReporting: false,
        shouldRenderMatchAssistant: false,
        shouldRenderMatchAssistantForBothTeams: false,
        shouldShowMatchLobbyTour: false,
        canManageMatch: false,
        canReport: false,
        canParticipate: false,
        canSpectate: false,
        canDisputeMatch: false,
        canManageRoster: false,
        canQueue: false,
        rolesThatCanManageRosterCopy: [],
      },
      chat: {
        shouldRenderChat: false,
        shouldRenderCoachChat: false,
        chatRole: ChatRole.Player,
      },
      lobby: {
        showManageMatchSection: false,
        showMatchLobbyTour: false,
        showActionAndInfoSection: false,
        canManageQueueBannerActions: false,
        showRescheduleMatchAlert: false,
        showLobbyTab: false,
        myTeamIds: [],
        opposingTeamId: '',
        isMemberOfBothTeams: false,
        enableScoutingToTeamsPage: false,
        homeTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
        awayTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
      },
    }),
    setMatchLobbyRenderControllerStateFn: () => {},
  })

export const MatchLobbyRenderControllerProvider: React.FC = ({ children }) => {
  const [matchLobbyRenderControllerState, setMatchLobbyRenderControllerState] =
    React.useState<MatchLobbyRenderControllerState>({
      match: {
        shouldRenderMatchReporting: false,
        shouldRenderMatchAssistant: false,
        shouldRenderMatchAssistantForBothTeams: false,
        shouldShowMatchLobbyTour: false,
        canReport: false,
        canManageMatch: false,
        canParticipate: false,
        canSpectate: false,
        canDisputeMatch: false,
        canManageRoster: false,
        canQueue: false,
        rolesThatCanManageRosterCopy: [],
      },
      chat: {
        shouldRenderChat: false,
        shouldRenderCoachChat: false,
        chatRole: ChatRole.Player,
      },
      lobby: {
        showManageMatchSection: false,
        showMatchLobbyTour: false,
        myTeamIds: [],
        opposingTeamId: '',
        isMemberOfBothTeams: false,
        showActionAndInfoSection: false,
        canManageQueueBannerActions: false,
        showRescheduleMatchAlert: false,

        enableScoutingToTeamsPage: false,
        homeTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
        awayTeam: {
          id: '',
          teamName: '',
          avatarUrl: '',
        },
      },
    })

  const getMatchLobbyRenderControllerState =
    useCallback((): MatchLobbyRenderControllerState => {
      return matchLobbyRenderControllerState
    }, [matchLobbyRenderControllerState])

  const setMatchLobbyRenderControllerStateFn = useCallback(
    (newState: Partial<MatchLobbyRenderControllerState>): void => {
      setMatchLobbyRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useMatchLobbyRenderControllerContext()

  return (
    <matchLobbyRenderControllerContext.Provider
      value={{
        ...values,
        getMatchLobbyRenderControllerState,
        setMatchLobbyRenderControllerStateFn,
      }}
    >
      {children}
    </matchLobbyRenderControllerContext.Provider>
  )
}

export const useMatchLobbyRenderControllerState = (): {
  getMatchLobbyRenderControllerState: () => MatchLobbyRenderControllerState
  setMatchLobbyRenderControllerStateFn: (
    updatedStateValues: Partial<MatchLobbyRenderControllerState>
  ) => void
} => {
  const {
    getMatchLobbyRenderControllerState,
    setMatchLobbyRenderControllerStateFn,
  } = React.useContext(matchLobbyRenderControllerContext)

  return {
    getMatchLobbyRenderControllerState,
    setMatchLobbyRenderControllerStateFn,
  }
}
