import { Theme } from '@material-ui/core'

import { rup } from '@plvs/utils'
import { ttCommons400, ttCommons600, ttCommons700 } from './ttCommonsFont'
import * as fonts from './fonts'
import { Colors } from '../colors'

export * from './Button'
export * from './Card'
export * from './Link'
export * from './MuiToolbar'

export const MuiAvatar = {
  colorDefault: {
    backgroundColor: Colors.Grey4,
  },
}

export const MuiCssBaseline = (theme: Theme): Record<string, unknown> => ({
  '@global': {
    '@font-face': [
      ttCommons400,
      ttCommons600,
      ttCommons700,
      fonts.whitney500,
      fonts.whitney600,
      fonts.whitney700,
      fonts.bebasNeue400,
    ],
    body: {
      backgroundColor: theme.palette.background.default,
      fontSize: 18,

      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
  },
})

export const MuiExpansionPanel = {
  root: {
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
    background: 'none',
    boxShadow: 'none',
  },
}

export const MuiExpansionPanelSummary = {
  content: {
    '&$expanded': {
      marginBottom: '12px',
      marginTop: '12px',
    },
  },
  root: {
    '&$expanded': {
      minHeight: 48,
    },
  },
}

export const MuiPaper = {
  rounded: {
    borderRadius: 12,
    color: 'inherit',
  },
}

export const MuiSnackbarContent = {
  action: {
    paddingLeft: 12,
  },
  message: {
    flex: 1,
  },
}

export const MuiTabs = {
  indicator: {
    height: 3,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    backgroundColor: Colors.Orange,
  },
}

export const MuiTab = (theme: Theme): Record<string, unknown> => ({
  root: {
    ...theme.typography.button,
    color: theme.palette.ColorTextBase || `${Colors.Black} !important`,
    minHeight: '80px',
    ...rup(theme as any, {
      minWidth: [80, 80, 80],
    }),
  },
})

export const MuiFilledInput = (theme: Theme): Record<string, unknown> => ({
  root: {
    backgroundColor:
      theme.palette.ColorBackgroundAlt || theme.palette.background.default,
    borderRadius: 4,
    border: `0px solid ${theme.palette.BorderLight}`,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.BorderLight,
    },
  },
  underline: {
    '&.Mui-disabled:before': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:hover:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
  },
})

export const MuiFormLabel = (theme: Theme): Record<string, unknown> => ({
  root: {
    color: theme.palette.ColorTextBase,
    '&$focused': {
      color: theme.palette.ColorTextAlt,
    },
  },
})

export const MuiInput = {
  underline: {
    '&:after': {
      borderBottomColor: Colors.Black,
    },
  },
}
