import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxCheckbox } from '@playvs-inc/nexus-components'
import { EsportPreferenceItem } from './types'

const useStyles = makeStyles((theme) => ({
  checkedItem: {
    color: theme.palette.ColorTextBase,
    textDecoration: 'none',
  },
  item: {
    color: theme.palette.ColorTextBase,
    textDecoration: 'none',
  },
}))

interface EsportPreferenceModalContentProps {
  onChange(id: string): void
  entries: EsportPreferenceItem[]
}

export const EsportPreferenceModalContent: React.FC<EsportPreferenceModalContentProps> = ({
  onChange,
  entries,
}) => {
  const { checkedItem, item } = useStyles()
  return (
    <Box mt={2} pb={2}>
      {entries.map(({ isChecked, label, id }) => {
        return (
          <Box key={id} display="flex" mb={2}>
            <Box display="flex" flex={1} flexDirection="row">
              <label
                className={isChecked ? checkedItem : item}
                htmlFor={`checkbox-${id}`}
              >
                {label}
              </label>
            </Box>
            <Box display="flex" flex={0} flexDirection="row">
              <div>
                <NxCheckbox
                  checked={isChecked}
                  name={`checkbox-${id}`}
                  onChange={(): void => {
                    onChange(id)
                  }}
                />
              </div>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
