import {
  AddPlayerToTeamMutationVariables,
  useAddPlayerToTeamMutation,
  useResurrectDuplicateTeamMutation,
} from '@plvs/graphql'
import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'

type AddPlayerToTeamResponse = {
  success: boolean
  teamId?: string
}

type AddPlayerToTeamFnType = (
  options: AddPlayerToTeamMutationVariables,
  currentMemberIds: string[]
) => Promise<AddPlayerToTeamResponse>

/**
 * Error response is being done this way for now. The backend plans to
 * create a standardized reponse structure so that in the future we have
 * standard error codes instead of relying on string parsing.
 *
 * @param e
 */
const isFixedRosterTeamError = (e: ApolloError): boolean => {
  return e.message.includes(
    'Esport does not allow multiple teams to have the exact same players'
  )
}

/**
 * This hook encapsulates the unique logic we need for when a player
 * deletes a "fixedRosterTeam" and tries to create a new team and
 * add the same player. In this situation, we want to "resurrect"
 * the "deleted" team and delete the team they just created.
 * Because of this, this hook was created to handle that logic
 * without duplicating it in different places.
 *
 * @param baseOptions - Any additional actions you want to take
 */
export const useAddPlayerToTeamHook = (baseOptions?: {
  onCompleted?: () => void
}): [AddPlayerToTeamFnType, { loading: boolean; error: any }] => {
  const [
    addPlayerToTeamMutation,
    { loading: addPlayerLoading, error: addError },
  ] = useAddPlayerToTeamMutation(baseOptions)
  const [
    resurrectTeamMutation,
    { loading: resurrectLoading, error: resurrectError },
  ] = useResurrectDuplicateTeamMutation(baseOptions)
  const addPlayerToTeam = useCallback<AddPlayerToTeamFnType>(
    async (
      options: AddPlayerToTeamMutationVariables,
      currentMemberIds
    ): Promise<AddPlayerToTeamResponse> => {
      try {
        const resp = await addPlayerToTeamMutation({ variables: options })
        return {
          success: !!resp?.data?.addPlayerToTeam,
          teamId: options.teamId,
        }
      } catch (e: any) {
        if (isFixedRosterTeamError(e)) {
          const memberIds = [...currentMemberIds, options.userId].filter(
            (id) => id
          )
          if (memberIds) {
            const resp = await resurrectTeamMutation({
              variables: {
                teamToReplaceId: options.teamId,
                archivedTeamMemberIds: memberIds,
              },
            })
            const teamId = resp.data?.resurrectDuplicateArchivedTeam.team?.id
            return { success: !!teamId, teamId }
          }
        }
        return { success: false, teamId: undefined }
      }
    },
    []
  )
  return [
    addPlayerToTeam,
    {
      loading: addPlayerLoading || resurrectLoading,
      error: addError || resurrectError,
    },
  ]
}
