import { has } from 'ramda'
import dayjs from 'dayjs'
import { GetUpcomingSeasonInfoQuery } from '@plvs/graphql'

export type UpcomingSeason = NonNullable<
  NonNullable<NonNullable<GetUpcomingSeasonInfoQuery>['metaseason']>['seasons']
>[0]

export type UpcomingSeasonLeague = NonNullable<UpcomingSeason>['league']

/**
 * Group leagues by their enrollment or late enrollment date
 */
export const groupLeaguesByEnrollmentDate = (
  accum: Record<string, UpcomingSeasonLeague[]>,
  season: UpcomingSeason
): Record<string, UpcomingSeasonLeague[]> => {
  const object = accum

  const now = dayjs()

  // registration deadline
  const suggestedRegistrationEndsAt = dayjs(
    season?.suggestedRegistrationEndsAt ?? ''
  )

  // late registration deadline
  const teamRegistrationEndsAt = dayjs(season?.teamRegistrationEndsAt ?? '')

  const date = now.isBefore(suggestedRegistrationEndsAt)
    ? suggestedRegistrationEndsAt
    : teamRegistrationEndsAt

  const formattedDate = date.toISOString()

  const league = season?.league

  if (has(formattedDate, accum)) {
    const entry = object[formattedDate]
    entry.push(league)
  } else {
    object[formattedDate] = [league]
  }

  return object
}

export const sortByEsportName = (
  seasonA: UpcomingSeason,
  seasonB: UpcomingSeason
): number => {
  const esportA = seasonA?.league?.esport?.slug ?? ''
  const esportB = seasonB?.league?.esport?.slug ?? ''

  return esportA.localeCompare(esportB)
}

export const sortByDate = (d1: string, d2: string): number => {
  return dayjs(d1).isAfter(dayjs(d2)) ? 1 : -1
}

export const LATE_ENROLLMENT_TOOLTIP =
  'Late enrollment may cause your team to receive an automatic loss for matches missed.'
