import React, { useRef } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import {
  refetchGetChildAvatarUrlQuery,
  useSetUserAvatarImageMutation,
} from '@plvs/graphql'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { useResourceImageProvider } from '../resources/ResourceImageProvider'

const MAX_FILE_SIZE_IN_BYTES = 1000000 // 1MB

const useStyles = makeStyles({
  hiddenInput: {
    display: 'none',
  },
})

export const SetUserAvatar = ({
  userId,
}: {
  userId: string
}): React.ReactElement => {
  const { hiddenInput } = useStyles()
  const [isBusy, setIsBusy] = React.useState(false)
  const [error, setError] = React.useState<Error>()

  const inputRef = useRef<HTMLInputElement>(null)

  const [mutate] = useSetUserAvatarImageMutation()
  const { updateResourceImages } = useResourceImageProvider()
  const handleOnClick = (): void => {
    inputRef.current?.click()
  }

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    try {
      const file = event?.target?.files?.[0]
      if (file) {
        const { type } = file
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          throw new Error('File size must be less than 1MB')
        }
        if (type === 'image/jpeg' || type === 'image/png') {
          setIsBusy(true)
          await mutate({
            awaitRefetchQueries: true,
            refetchQueries: [refetchGetChildAvatarUrlQuery({ userId })],
            variables: { file, userId },
          })
          updateResourceImages()
        } else {
          throw new Error('File must be jpeg or png')
        }
      } else {
        // User will get error if they hit cancel from file upload window. Do we want this?
        // throw new Error('No file selected')
      }
    } catch (err: unknown) {
      setError(err as Error)
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <Box style={{ backgroundColor: 'none' }}>
      <label htmlFor="contained-button-file">
        <NxButton
          data-testid="upload-avatar-button"
          label={isBusy ? 'Uploading...' : 'Upload Avatar'}
          onClick={handleOnClick}
          variant="secondary"
        />
        <input
          ref={inputRef}
          accept="image/*"
          className={hiddenInput}
          data-testid="upload-avatar-input"
          id="contained-button-file"
          onChange={handleOnChange}
          type="file"
        />
      </label>
      {error && (
        <Box data-testid="upload-avatar-error" mt={1}>
          <NxTypography colorToken="ColorTextError">
            {error.message}
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
