import React from 'react'

import {
  GetPlayerPhaseStatsByTeamQuery,
  Phase,
  useGetPlayerPhaseStatsByTeamQuery,
} from '@plvs/graphql'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import { getTableColumns } from '@plvs/rally/containers/table'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { PhaseStats } from './PhaseStats'

type Member = NonNullable<
  NonNullable<GetPlayerPhaseStatsByTeamQuery['team']>['members']
>[0]

export interface PhaseStatRow {
  player: {
    avatarUrl: string | undefined
    name: string
    id: string
    username: string | undefined
  }
  position: string
  stats: any
}

export const mapMembersToTableFormat = (
  getUsername: GeneralEsportAdapter['getUsername'],
  getPositionName: GeneralEsportAdapter['getPositionName']
) => (member: Member): PhaseStatRow => {
  const { id, name, avatarUrl, position, phaseStats } = member
  return {
    player: {
      name: name || 'N/A',
      avatarUrl: avatarUrl || '',
      id,
      username: getUsername(member),
    },
    position: getPositionName(position ?? null),
    stats: phaseStats?.[0]?.stats,
  }
}

export interface PlayerPhaseStatsProps {
  teamId: string
  metaseasonId: string
  phase?: Pick<Phase, 'id' | 'endsAt'>
}

export const PlayerPhaseStats: React.FC<PlayerPhaseStatsProps> = ({
  teamId,
  metaseasonId,
  phase,
}) => {
  // TODO Public need to update permissioning for this data
  // message: "Permission Error: User cannot perform operation ViewField on specified resource of type Team at paths: members."
  const { data, loading } = useGetPlayerPhaseStatsByTeamQuery({
    variables: {
      id: teamId,
      metaseasonId,
      phaseId: phase?.id ?? '',
      date: phase?.endsAt ?? '',
    },
    skip: !phase?.id || !phase?.endsAt,
  })
  const {
    getUsername,
    getPositionShortName,
    phaseStatColumns,
    rankingInfoCopy,
  } = useEsportContext()
  const columns = getTableColumns(phaseStatColumns ?? [], rankingInfoCopy)

  return (
    <PhaseStats
      columns={columns}
      data={(data?.team?.members ?? [])
        .filter(({ phaseStats }) => phaseStats)
        .map(mapMembersToTableFormat(getUsername, getPositionShortName))}
      loading={loading}
    />
  )
}
