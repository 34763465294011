import React from 'react'
import {
  Box,
  useBreakpointSm,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import {
  MatchCheckInMatchStatus,
  MatchCheckInTeam,
  MatchCheckInMatch,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { Button, makeStyles } from '@material-ui/core'
import { EsportRating } from '@plvs/graphql'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

import { Link } from 'react-router-dom'
import { Path } from '@plvs/const'
import { RouterLink } from '@plvs/respawn/features/route'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles({
  avatarCircle: {
    height: '81px',
    width: '81px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const MatchCheckInCheckedInStep: React.FC<{
  match: MatchCheckInMatch
  status: MatchCheckInMatchStatus
  opponent: MatchCheckInTeam | null | undefined
  opponentIsCheckedIn: boolean
  onNext(): void
  hasNextMatch: boolean
  loading: boolean
}> = ({
  match,
  status,
  opponent,
  opponentIsCheckedIn,
  onNext,
  hasNextMatch,
  loading,
}) => {
  const { avatarCircle } = useStyles()
  const isMobile = useBreakpointSm()
  const esportRating = match?.esport?.rating
  const checkedInAndOpponentCheckedInString =
    'Your opponent has checked in!\n\nGet ready for the upcoming match.'
  const checkedInAndOpponentNotCheckedInString =
    'Your opponent has not checked in.\n\nIf they don’t check in, they still may attend the match. However, if they don’t attend the match, they will forfeit.'
  const forfeitString =
    'You have forfeited this match. Check in for other upcoming matches or return to the schedule.'
  const rescheduleRequestedString =
    'Your team has requested a reschedule for this match. Check in for other upcoming matches or return to the schedule.'
  const rescheduledString =
    'This match has been rescheduled. Check in for other upcoming matches or return to the schedule.'
  const opponentForfeitedString =
    'Your opponent has forfeited this match! Check in for other upcoming matches or return to the schedule.'
  const missedCheckInString =
    'You missed the check-in window, but you can still participate in the game. Check-ins will be mandatory for all teams starting 2022.'

  let bodyText = ''
  switch (status) {
    case MatchCheckInMatchStatus.CheckedIn:
      bodyText = opponentIsCheckedIn
        ? checkedInAndOpponentCheckedInString
        : checkedInAndOpponentNotCheckedInString
      break
    case MatchCheckInMatchStatus.Forfeited:
      bodyText = forfeitString
      break
    case MatchCheckInMatchStatus.OpponentForfeited:
      bodyText = opponentForfeitedString
      break
    case MatchCheckInMatchStatus.RescheduleRequested:
      bodyText = rescheduleRequestedString
      break
    case MatchCheckInMatchStatus.Closed:
      bodyText = missedCheckInString
      break
    case MatchCheckInMatchStatus.Rescheduled:
      bodyText = rescheduledString
      break
    case MatchCheckInMatchStatus.Available:
    default:
      break
  }

  return (
    <Box mb={5}>
      <Box mb={2}>
        <NxTypography variant="h3">What&apos;s next?</NxTypography>
      </Box>
      <Box mb={2}>
        <NxTypography id="whats-next-body" variant="body1">
          {bodyText}
        </NxTypography>
      </Box>
      <Box
        alignItems={isMobile ? 'flex-start' : 'center'}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Box display="flex" mb={2}>
          <Box className={avatarCircle} mr={1}>
            <WaitTillLoaded
              loading={loading}
              loadingSpinnerProps={{ size: 'medium' }}
              showSpinnerWhileLoading
            >
              <RouterLink to={`/app/team/${opponent?.id}`}>
                <NxUserAvatar
                  avatarUrl={
                    esportRating === EsportRating.Restricted
                      ? null
                      : opponent?.school?.logoUrl
                  }
                  hashId={opponent?.id ?? ''}
                  size={NxAvatarSize.MD}
                />
              </RouterLink>
            </WaitTillLoaded>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <NxTypography variant="h3">{opponent?.name ?? ''}</NxTypography>
            <NxTypography variant="subtitle2">
              {opponent?.school?.name ?? ''}
            </NxTypography>
          </Box>
        </Box>
        <Box>
          {hasNextMatch && (
            <>
              <Button component={Link} to={Path.Schedule}>
                {isMobile ? 'To Schedule' : 'Return to schedule'}
              </Button>
              <Box component="span" pr={2} />
              <Button color="primary" onClick={onNext} variant="contained">
                {isMobile ? 'Next Match' : 'Check in for next match'}
              </Button>
            </>
          )}
          {!hasNextMatch && (
            <Button
              color="primary"
              component={Link}
              to={Path.Schedule}
              variant="contained"
            >
              Return to schedule
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
