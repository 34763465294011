import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { RewindAssets } from './Rewind.helpers'

interface CSSProps {
  shouldShowPagers: boolean
}

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    background: 'rgba(0, 0, 0, 91%)',
  },
  button: ({ shouldShowPagers }: CSSProps): CreateCSSProperties => ({
    opacity: shouldShowPagers ? '100%' : '0%',
    transition: 'opacity linear 0.2s',
    borderRadius: '100%',
    height: 44,
    width: 44,
    borderColor: theme.palette.OverlayBorderLight,

    '&:hover': {
      background: theme.palette.OverlayBorderLight,
    },

    '&:active': {
      background: theme.palette.ColorBackgroundAccent,
    },

    '&:disabled': {
      background: 'transparent !important',
    },
  }),
  background: {
    background: `url(${RewindAssets.Background})`,
    backgroundSize: 'cover',
    backgroundColor: '#FF8100',

    width: 480,
    maxWidth: '90vw',
    minHeight: 480,
    maxHeight: '100vh',

    position: 'relative',
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
    },
  },
  slide: {
    position: 'relative',
    /* Arbitrary z-index higher than the dialog z-index so that content isn't faded from
      the background overlay */
    zIndex: 2000,
    height: 'auto',
  },
  shareButton: {
    width: 232,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gridGap: theme.spacing(3),
    },
  },
  ctaButton: {
    // Compensates for the alert/notification buttons
    marginTop: theme.spacing(3),
    background: theme.palette.common.white,
    border: 0,
    padding: 0,
    borderRadius: 10,
    position: 'relative',
    height: 168,
    width: 300,
  },
  cta: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'translate 0.2s',
    cursor: 'pointer',
    height: 168,
    width: 300,

    '&:hover': {
      translate: theme.spacing(1, -1),
    },
  },
}))
