import { useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { SHORT_DATE_FORMAT } from '@plvs/const'
import {
  GameStatus,
  Phase,
  useGetTeamPhaseRecentGamesQuery,
} from '@plvs/graphql'
import { ExpandableCard } from '@plvs/rally/components/card'
import { EmptyPage } from '@plvs/rally/components/empty'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { Table } from '@plvs/rally/components/table'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { getTableColumns } from '@plvs/rally/containers/table'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import React, { FunctionComponent } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { RelativeTiming } from '@plvs/utils'

dayjs.extend(tz)

type RecentGameProps = {
  phase: Pick<Phase, 'id' | 'endsAt'>
  teamId: string
}

export const RecentGames: FunctionComponent<RecentGameProps> = ({
  phase,
  teamId,
}): React.ReactElement => {
  const NUM_GAMES_TO_PREVIEW = 3
  const { metaseason, esportSlug } = useSchoolLeagueInfoContext()
  const mobile = useBreakpointXs()
  const theme = useTheme()
  const {
    teamRecentGamesColumns,
    rankingInfoCopy,
    isGamelessType,
  } = useGeneralEsportAdapter(esportSlug ?? null)
  const columns = getTableColumns(teamRecentGamesColumns ?? [], rankingInfoCopy)

  const {
    data: recentGamesData,
    loading: recentGamesLoading,
  } = useGetTeamPhaseRecentGamesQuery({
    variables: {
      teamId,
      phaseId: phase.id,
    },
    skip: !teamId || !phase || !columns || columns.length < 1,
    // The match data on the backend is very dirty.  There are few teams whos data
    // will cause some resolvers to fail.  Instead of failing, we'll try to render
    // what data we do get back.
    errorPolicy: 'all',
  })

  // If not table columns are defined for this game, then this game has not been
  // configured yet, so we can exit here.  To configure new games, see:
  // `packages/rally/src/containers/esport/creator/esportComponents.tsx`
  if (!columns || columns.length < 1) {
    return <></>
  }

  const normalGames = recentGamesData?.team?.teamPhaseSummary?.recentGames || []
  // Note: Fortnite is a battle royal game that does not have distinct game stats.
  // So this is a special case we need to handle separately.
  const fnGames =
    recentGamesData?.team?.teamPhaseSummary?.recentFortniteMatches || []

  // We are flattening the game specific fields and merging it into `stats`
  // so it can be accessed by the existing Columns adapters used by our tables.
  const displayGamesNormal = normalGames.map((game) => {
    // TODO: Computed props - Dev Note: these should be moved into the backend.
    // However they are here in the meantime to get this feature working.

    // calculate opponent by checking if team not equal to current teamId
    const opponent =
      game.winningTeam?.id === teamId
        ? game.losingTeam?.name
        : game.winningTeam?.name
    const thisTeam =
      game.winningTeam?.id === teamId
        ? game.winningTeam?.name
        : game.losingTeam?.name

    // Outcome
    const outcome = game.winningTeam?.id === teamId ? 'W' : 'L'

    // Settled
    const isSettled = game.status === GameStatus.Settled
    let settledBy = ''
    if (isSettled) {
      settledBy = 'PlayVS'
    }

    // Forfeits
    const isForfeit = game.status === GameStatus.Forfeited
    let forfeitBy = ''
    if (isForfeit) {
      forfeitBy = game.placing === 2 ? thisTeam ?? '' : opponent ?? ''
    }
    // end of computed props

    const stats = {
      // Items that are expected in the stats field that are game agnostic
      isForfeit,
      forfeitBy,
      isSettled,
      settledBy,

      // Add each game we want to support
      ...game.leagueOfLegendsTeamStats,
      ...game.rocketLeagueTeamStats,
      ...game.smiteTeamStats,
    }

    return {
      gameId: game.id,
      ordinal: game.ordinalNumber,
      week: game.match?.week,
      matchId: game.match?.id,
      matchName: game.match?.name,
      opponent,
      outcome,
      stats,
      isGamelessType,
    }
  })

  // Processing for fortnight games.
  const displayGamesFn = fnGames.map((fnMatch) => {
    // Ideally this should be a specific type, but the type here is very complciated
    // so using 'any' to keep this simple.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentTeamStats = fnMatch.teamMatchStats?.find(
      (x) => x.teamId === teamId
    )

    const numOpponent = fnMatch.teamMatchStats
      ? fnMatch.teamMatchStats.length - 1
      : '?'

    const matchName: string =
      fnMatch.name ??
      `Match on ${dayjs(fnMatch.scheduledStartsAt as string)
        .tz()
        .format(SHORT_DATE_FORMAT)}`

    return {
      // gameID and ordinal does not exist on Fortnite, this is a hack
      // to allow reusing the GameCluster component.
      gameId: fnMatch.id,
      ordinal: '',

      week: fnMatch.week,
      matchId: fnMatch.id,
      matchName,
      opponent: `${numOpponent} other teams`,
      // Flatten the stats object structure so it can be used with our Table c
      // Column adapters.
      stats: {
        ...currentTeamStats,
        ...currentTeamStats?.fortniteStats,
      },
      isGamelessType,
    }
  })

  const allGames = [...displayGamesNormal, ...displayGamesFn]
  const previewGames = allGames.slice(0, NUM_GAMES_TO_PREVIEW)
  const expandedGames = allGames.slice(NUM_GAMES_TO_PREVIEW)
  const hasExpandedContent = expandedGames.length > 0
  const defaultEmptyCellValue = 'N/A'

  let previewContent = (
    <Box padding={theme.spacing(2)}>
      <Box>
        <NxTypography variant="h3">
          {metaseason?.timing === RelativeTiming.Present ? 'Recent ' : ''}
          Official Games
        </NxTypography>
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" />
      </Box>
    </Box>
  )

  // Note: Because I'm using error policy all, I want to check if it is critical
  // failure by seeing if the recentGames field is completely null.  Otherwise
  // just continue to the best of our ability.
  if (
    !recentGamesLoading &&
    (!!recentGamesData?.team?.teamPhaseSummary?.recentGames ||
      !!recentGamesData?.team?.teamPhaseSummary?.recentFortniteMatches)
  ) {
    previewContent = (
      <>
        <Box padding={theme.spacing(2)}>
          <NxTypography variant="h3">
            {metaseason?.timing === RelativeTiming.Present ? 'Recent ' : ''}
            Official Games
          </NxTypography>
        </Box>
        {previewGames.length > 0 ? (
          <Table
            columns={columns}
            data={previewGames}
            defaultEmptyCellValue={defaultEmptyCellValue}
            resizable={false}
            sortable={false}
          />
        ) : (
          <EmptyPage
            mb={6}
            mt={0}
            showIcon
            subtitle={
              <>This team hasn’t played any PlayVS games this season.</>
            }
            title="No games yet"
          />
        )}
      </>
    )
  } else if (!recentGamesLoading) {
    previewContent = (
      <>
        <Box padding={theme.spacing(2)}>
          <NxTypography variant="h3">
            {metaseason?.timing === RelativeTiming.Present ? 'Recent ' : ''}
            Official Games
          </NxTypography>
        </Box>
        <Table
          columns={columns}
          data={[{ matchName: 'Error loading games.', stats: {} }]}
          defaultEmptyCellValue={defaultEmptyCellValue}
          resizable={false}
          sortable={false}
        />
      </>
    )
  }

  const expandedContent = hasExpandedContent ? (
    <Table
      columns={columns}
      data={expandedGames}
      defaultEmptyCellValue={defaultEmptyCellValue}
      hideHeader
    />
  ) : (
    false
  )

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <div
        style={{
          minWidth: 'min-content',
        }}
      >
        <ExpandableCard
          buttonPosition={mobile ? 'flex-start' : 'flex-end'}
          expandedContent={expandedContent}
          expandText="Show More"
          hideButton={!hasExpandedContent}
          previewContent={previewContent}
        />
      </div>
    </div>
  )
}
