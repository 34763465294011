import {
  School,
  SchoolSearchResult,
  SchoolType,
} from '@plvs/graphql/generated/graphql'

export type SchoolId = Pick<School, 'id'>
export type SchoolName = Pick<School, 'name'>

// Placeholder to add 'Add a school' option to the bottom of the search results.
export const ADD_SCHOOL_PLACEHOLDER: SchoolSearchResult = {
  schoolId: 'ADD_SCHOOL',
  city: '',
  // Hack to assign name as a non-string
  name: '',
  searchScore: 0,
  address: '',
  state: '',
  zip: '',
  type: SchoolType.HighSchool,
  logoUrl: '',
}
