import { logger } from '@plvs/rally/logging'

export function waitFor(milliseconds: number): Promise<void> {
  return new Promise((resolve) => {
    return setTimeout(resolve, milliseconds)
  })
}

export function retry({
  executedPromise,
  maxRetries,
  setCanRetry,
}: {
  executedPromise?: () => Promise<boolean>
  maxRetries: number
  setCanRetry: (stop: boolean) => void
}): Promise<boolean> {
  async function retryWithBackoff(retries: number): Promise<boolean> {
    if (retries === maxRetries) {
      setCanRetry(false)
      logger.debug('No longer retrying')
      throw new Error(
        'Unable to connect to the Twilio Client. Max retries were executed.'
      )
    }

    if (retries > 0) {
      const timeToWait = 2 ** retries * 1000
      logger.debug(`Retrying in ${timeToWait}ms`)
      await waitFor(timeToWait)

      const response = await executedPromise?.()

      // Access token received so stop retrying
      if (response) {
        return Promise.resolve(Boolean(response))
      }

      return retryWithBackoff(retries + 1)
    }

    return Promise.resolve(false)
  }

  // Calling retryWithBackoff starting at one retry. If this function is called, the FE has already tried to connect once.
  return retryWithBackoff(1)
}
