import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useStyles } from '../../../MatchAssistant.styles'

import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'

interface GamePreviewProps {
  onNext?(): void
}

export const GamePreview: React.FC<GamePreviewProps> = ({ onNext }) => {
  const styles = useStyles()
  const theme = useTheme()
  const {
    currentStep,
    homeTeam,
    awayTeam,
    gameOrdinal = 0,
    gameMode,
    selectedStage,
    charactersByUserId,
  } = useMatchAssistantContext()

  const {
    match: { canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const handleNext = (): void => {
    onNext?.()
  }

  const isNextDisabled = canSpectate || !onNext
  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  const header = gameMode ? (
    <>
      <NxTypography variant="overline">{gameMode}</NxTypography>
      <NxTypography variant="h4">
        {selectedStage?.selectionOption?.displayName}
      </NxTypography>
    </>
  ) : (
    <NxTypography variant="h1">
      Prepare for Game {currentStep?.step?.gameOrdinal}
    </NxTypography>
  )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          borderBottom={`1px solid ${theme.palette.BorderLight}`}
          display="flex"
          flex={1}
          flexDirection="column"
          gridGap={theme.spacing(0.5)}
          justifyContent="center"
          mb={3}
          pb={3}
          textAlign="center"
          width="100%"
        >
          {header}
        </Box>

        <Box
          alignItems="center"
          display="flex"
          gridGap={theme.spacing(3)}
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            justifyContent="flex-end"
            textAlign="right"
          >
            <NxTypography variant="overline">{homeTeam?.name}</NxTypography>

            {homeTeam?.starters?.map((starter) => (
              <NxUserCluster
                key={starter.id}
                alignment="right"
                avatarHashId={starter.id}
                avatarUrl={starter.avatarUrl}
                subHeader={
                  charactersByUserId[starter.id]?.[gameOrdinal]?.selectionOption
                    ?.displayName
                }
                subtitles={[{ title: starter.publisherAccountName }]}
                title={starter.name}
              />
            ))}
          </Box>

          <NxTypography variant="h4">vs</NxTypography>

          <Box
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            justifyContent="flex-end"
          >
            <NxTypography variant="overline">{awayTeam?.name}</NxTypography>

            {awayTeam?.starters?.map((starter) => (
              <NxUserCluster
                key={starter.id}
                avatarHashId={starter.id}
                avatarUrl={starter.avatarUrl}
                subHeader={
                  charactersByUserId[starter.id]?.[gameOrdinal]?.selectionOption
                    ?.displayName
                }
                subtitles={[{ title: starter.publisherAccountName }]}
                title={starter.name}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={`Go play game ${gameOrdinal} and come back to report the results,`}
        />

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={stepAlertsData.actionLoading}
            fullWidth
            label="Change Roster"
            loading={stepAlertsData.actionLoading}
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            variant="secondary"
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Report Results"
            onClick={handleNext}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
