import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'
import {
  BracketMatch,
  NxBracket,
  getRoundName,
} from '@plvs/checkpoint/components/bracket/Bracket'
import {
  BracketTeamMatchStatus,
  EsportRating,
  useGetPhaseBracketQuery,
} from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { generatePath } from 'react-router-dom'
import { useAppLocationFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models/AppLocation'
import { Draggable } from '@plvs/checkpoint/components/draggable/Draggable'
import { EmptyPageWithSpot } from '@plvs/rally/components/empty/EmptyPageWithSpot'
import StandingSecondaryLargeDark from '@plvs/rally/assets/icons/StandingSecondaryLargeDark.svg'
import StandingSecondaryLarge from '@plvs/rally/assets/icons/StandingSecondaryLarge.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    maxWidth: '1267px',
    width: '100%',
    '&::-webkit-scrollbar': {
      // overrides default scrollbar height of 0 in ssr.tsx
      height: '0.5em !important',
      background: theme.palette.ColorBackgroundAlt,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.ColorBackgroundInactive,
    },
  },
}))

export function getLinks(
  location: Location,
  isLoggedIn: boolean,
  schoolSlug?: string,
  schoolId?: string,
  teamId?: string,
  matchId?: string
): { schoolLink: string; teamLink: string; matchLink: string } {
  let schoolLink: string = ''
  let matchLink: string = ''
  let teamLink: string = ''
  if (location === Location.Rally) {
    if (schoolSlug) {
      const path = isLoggedIn ? '/app/school/:slug' : '/org/:slug'
      schoolLink = generatePath(path, {
        slug: schoolSlug,
      })
    }
    if (teamId) {
      const path = isLoggedIn ? '/app/team/:id' : '/team/:id'
      teamLink = generatePath(path, {
        id: teamId,
      })
    }
    if (matchId) {
      const path = '/app/match/:id'
      matchLink = generatePath(path, {
        id: matchId,
      })
    }
  } else if (location === Location.Checkpoint) {
    if (schoolId) {
      schoolLink = generatePath('/school/:id/info', {
        id: schoolId,
      })
    }
    if (teamId) {
      teamLink = generatePath('/team/:id', {
        id: teamId,
      })
    }
    if (matchId) {
      matchLink = generatePath('/match/:id', {
        id: matchId,
      })
    }
  }
  return { schoolLink, teamLink, matchLink }
}

export interface BracketContainerProps {
  phaseId: string
  esportSlug: EsportSlug
  isLoggedIn: boolean
}

export function BracketContainer({
  phaseId,
  esportSlug,
  isLoggedIn,
}: BracketContainerProps): React.ReactElement {
  const classes = useStyles()
  const { data: bracketData, loading } = useGetPhaseBracketQuery({
    variables: { phaseId },
  })
  const { hasSeries, rating, isYouthProgram } = useGeneralEsportAdapter(
    esportSlug
  )
  const appLocation = useAppLocationFn()
  const flattened: BracketMatch[] = []
  const rounds = bracketData?.getBracketByPhaseId?.results ?? []
  rounds.forEach((round, roundIndex) => {
    round.forEach((match, matchIndex) => {
      flattened.push({
        id: match.bracketIndex ?? '',
        matchId: match.matchId ?? '',
        name: `${getRoundName(roundIndex + 1, rounds.length)} - Match ${
          matchIndex + 1
        }`,
        nextMatchId: match.nextBracketIndex,
        tournamentRoundText: `${roundIndex + 1}`,
        startTime: match.match?.scheduledStartsAt ?? '',
        participants: match.bracketTeams?.map((t) => {
          const result = match.match?.matchResults?.find(
            (x) => x.teamId === t.teamId
          )
          const score = hasSeries ? result?.seriesWon : result?.gamesWon
          const { schoolLink, teamLink, matchLink } = getLinks(
            appLocation,
            isLoggedIn,
            t.team?.school?.slug ?? undefined,
            t.schoolId ?? undefined,
            t.teamId ?? undefined,
            match.matchId ?? undefined
          )
          if (t) {
            return {
              id: t.teamId ?? '',
              seed: t.seed,
              status: t.matchStatus,
              displayName:
                t.matchStatus === BracketTeamMatchStatus.Bye
                  ? 'Bye'
                  : t.team?.name ?? '',
              picture: t.team?.avatarUrl ?? '',
              schoolName: t.team?.school?.name ?? '',
              score: score ?? null,
              schoolId: t.team?.school?.id,
              isDeleted: t.isDeleted,
              hideSchoolDetails:
                isYouthProgram ||
                rating === EsportRating.Restricted ||
                t.matchStatus === BracketTeamMatchStatus.Bye ||
                t.matchStatus === BracketTeamMatchStatus.Tbd,
              schoolLink,
              teamLink,
              matchLink,
            }
          }
          return null
        }),
      })
    })
  })
  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      {flattened && flattened.length ? (
        <Draggable includeArrows>
          <Box className={classes.container}>
            <NxBracket esportSlug={esportSlug} matches={flattened} />
          </Box>
        </Draggable>
      ) : (
        <EmptyPageWithSpot
          spot={StandingSecondaryLarge}
          spotCaption="No standings"
          spotDark={StandingSecondaryLargeDark}
          subtitle="An updated bracket will be available after the first round is played."
          title="Bracket not available"
        />
      )}
    </WaitTillLoaded>
  )
}
