/* istanbul ignore file */
// Cannot effectively unit test.
import { relayStylePagination } from '@apollo/client/utilities'

/**
 * Rules below define qualifications for the uniqueness of returned data,
 * preventing Apollo from caching objects.
 *
 * NOTE: everytime these types are requested through our API, we must also ask for these properties regardless of
 * using them in the UI, so that apollo can cache the responses correctly.
 *
 * https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
 */

export const typePolicies = {
  Query: {
    fields: {
      getIncompleteNotificationTasks: relayStylePagination(),
    },
  },
  TeamMember: {
    keyFields: ['id', 'teamId', 'effectiveAt'],
  },
  UserRole: {
    keyFields: ['userId', 'resourceId', 'roleName'],
  },
  SeriesTeam: {
    keyFields: ['id', 'seriesId'],
  },
  GameTeam: {
    keyFields: ['id', 'gameId'],
  },
  EsportDetails: {
    keyFields: ['esportId', 'abbreviation'],
  },
  TeamPlayer: {
    keyFields: ['user', ['id']],
  },
  LeagueGroupScope: {
    keyFields: ['leagueGroup', ['id']],
  },
  UserNotifications: {
    fields: {
      notifications: relayStylePagination(),
    },
  },
  GameAssistantStatePayload: {
    keyFields: ['step', ['matchId', 'teamId']],
  },
  School: {
    fields: {
      coaches: {
        keyFields: ['user', ['id']],
        merge(existing = [], incoming: any[]): unknown[] {
          if (existing === incoming) {
            return [...existing]
          }
          return [...incoming]
        },
      },
      deniedCoaches: {
        keyFields: ['user', ['id']],
        merge(existing = [], incoming: any[]): unknown[] {
          if (existing === incoming) {
            return [...existing]
          }
          return [...incoming]
        },
      },
      coachRolePendingUsers: {
        keyFields: ['user', ['id']],
        merge(existing = [], incoming: any[]): unknown[] {
          if (existing === incoming) {
            return [...existing]
          }
          return [...incoming]
        },
      },
    },
  },
}
