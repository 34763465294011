import React from 'react'
import { useRemoveTeamCoachMutation } from '@plvs/graphql'
import {
  NxModal,
  NxTypography,
  NxButton,
  NxAlert,
} from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { TeamCoach } from '../roster/roster.types'

export interface RemoveCoachModalProps {
  onClose(): void
  coach: TeamCoach
  onMutationSuccess(): void
  teamId: string
}

export const RemoveCoachModal: React.FC<RemoveCoachModalProps> = ({
  onClose,
  coach,
  onMutationSuccess,
  teamId,
}) => {
  const [removeTeamCoach, { error, loading }] = useRemoveTeamCoachMutation({
    onCompleted: async () => {
      onClose()
      onMutationSuccess?.()
    },
  })

  const removeCoachFromTeam = async (): Promise<void> => {
    await removeTeamCoach({
      variables: {
        teamId,
        coachUserId: coach.id,
      },
    })
  }
  const actionButtons = (
    <>
      <NxButton label="Cancel" onClick={onClose} variant="text" />
      <NxButton
        data-testid="RemoveCoachModal_Button"
        disabled={loading}
        label="Yes, Remove Coach"
        onClick={removeCoachFromTeam}
        variant="primary"
      />
    </>
  )

  return (
    <NxModal
      actions={actionButtons}
      onClose={onClose}
      open
      showTopRightClose={false}
      title={`Remove ${coach.name}?`}
    >
      {error && (
        <Box py={2}>
          <NxAlert
            canClose
            content={
              <NxTypography
                colorToken="ColorTextAlt2"
                data-testid="Banner_Subtitle"
                variant="body1"
              >
                {error.message}
              </NxTypography>
            }
            title={
              <NxTypography data-testid="Banner_Title" variant="h4">
                Unable to remove coach
              </NxTypography>
            }
            variant="error"
          />
        </Box>
      )}
      <NxTypography>{`Are you sure you want to remove ${coach.name}?`}</NxTypography>
    </NxModal>
  )
}
