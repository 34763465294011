import {
  ResourceType,
  Role,
  RoleStatus,
  UserRole,
  UserRoleName,
  UserRoleStatusName,
} from '@plvs/graphql'
import { join, map, uniq } from 'ramda'

// types

export type PickUserRoleName = Pick<UserRole, 'roleName'>
export type PickUserRole = Pick<UserRole, 'roleName' | 'resourceType'>

// Deprecated. Do not use.
export type UserType =
  | UserRoleName.Admin
  | UserRoleName.Coach
  | UserRoleName.Player
  | UserRoleName.Student

// consts

export const userRoleNameToLongUserRoleNameMap = {
  [UserRoleName.Admin]: 'System Administrator',
  [UserRoleName.Coach]: 'Coach',
  [UserRoleName.Fac]: 'Faculty',
  [UserRoleName.Player]: 'Player',
  [UserRoleName.Student]: 'Student',
}

// utils

export const userRolesToUserRoleNames = (
  userRoles: PickUserRoleName[]
): UserRoleName[] => userRoles.map((r) => r.roleName as UserRoleName)

/** @deprecated */
export const userRoleNamesToUserType = (
  userRoleNames: UserRoleName[]
): UserType => {
  if (userRoleNames.includes(UserRoleName.Admin)) return UserRoleName.Admin
  if (userRoleNames.includes(UserRoleName.Fac)) return UserRoleName.Coach
  if (userRoleNames.includes(UserRoleName.Student)) return UserRoleName.Player
  return UserRoleName.Player
}

/** @deprecated */
export const userRolesToUserType = (userRoles: PickUserRoleName[]): UserType =>
  userRoleNamesToUserType(userRolesToUserRoleNames(userRoles))

export const hasRoleForResourceType = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  roleName: UserRoleName,
  resourceType: ResourceType
): boolean =>
  userRoles.some(
    (role) => role.roleName === roleName && resourceType === role.resourceType
  )

export const hasRoleForResourceId = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  roleName: UserRoleName,
  resourceId: string
): boolean =>
  userRoles.some(
    (role) => role.roleName === roleName && resourceId === role.resourceId
  )

export const hasRoleForSomeResourceIds = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceIds: Array<string>
): boolean => userRoles.some((role) => resourceIds.includes(role.resourceId))

export const hasRoleNameForSomeResourceIds = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  roleName: UserRoleName,
  resourceIds: Array<string>
): boolean =>
  userRoles.some(
    (role) =>
      resourceIds.includes(role.resourceId) && role.roleName === roleName
  )

export const hasRoleForResource = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceId: string
): boolean => userRoles.some((role) => resourceId === role.resourceId)

export const userRolesByResourceToSingularString = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[]
): string => {
  if (
    hasRoleForResourceType(userRoles, UserRoleName.Admin, ResourceType.System)
  )
    return userRoleNameToLongUserRoleNameMap[UserRoleName.Admin]
  if (
    hasRoleForResourceType(
      userRoles,
      UserRoleName.Coach,
      ResourceType.Organization
    )
  )
    return userRoleNameToLongUserRoleNameMap[UserRoleName.Coach]
  if (
    hasRoleForResourceType(
      userRoles,
      UserRoleName.Fac,
      ResourceType.Organization
    )
  )
    return userRoleNameToLongUserRoleNameMap[UserRoleName.Fac]
  if (
    hasRoleForResourceType(
      userRoles,
      UserRoleName.Player,
      ResourceType.Organization
    )
  )
    return userRoleNameToLongUserRoleNameMap[UserRoleName.Player]
  if (
    hasRoleForResourceType(
      userRoles,
      UserRoleName.Student,
      ResourceType.Organization
    )
  )
    return userRoleNameToLongUserRoleNameMap[UserRoleName.Student]
  return 'Other'
}

export const isMemberOfOrganization = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  organizationIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Member &&
      organizationIds.includes(role.resourceId)
  )

export const isStudentForOrganization = (
  userRoles: Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName'>[],
  organizationIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Student &&
      organizationIds.includes(role.resourceId)
  )

export const isPlayerForOrganization = (
  userRoles: Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName'>[],
  organizationIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Player &&
      organizationIds.includes(role.resourceId)
  )

export const isFacultyForOrganization = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  organizationIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Fac &&
      organizationIds.includes(role.resourceId)
  )

export const isCoachForResource = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Coach &&
      resourceIds.includes(role.resourceId)
  )

export const isCaptainForResource = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Captain &&
      resourceIds.includes(role.resourceId)
  )

export const isTeamOwnerForResource = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Owner &&
      resourceIds.includes(role.resourceId)
  )

export const isPlayerForResource = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  resourceIds: string[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Player &&
      resourceIds.includes(role.resourceId)
  )

export const isAdminForSystem = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Admin &&
      role.resourceType === ResourceType.System
  )

export const isCxForSystem = (
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[]
): boolean =>
  userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Cx &&
      role.resourceType === ResourceType.System
  )

export const isAcceptedUserRoleForResource = (
  userSchoolRoleStatus: (Pick<RoleStatus, 'status'> & {
    role: Pick<Role, 'roleName' | 'resourceId' | 'resourceType' | 'userId'>
  })[],
  roleName: UserRoleName,
  resourceId: string
): boolean =>
  userSchoolRoleStatus.some((roleStatus) => {
    return (
      roleStatus.role.roleName === roleName &&
      roleStatus.role.resourceId === resourceId &&
      roleStatus.status === UserRoleStatusName.Approved
    )
  })

export const userRolesNamesByResourceType = (
  userRoles: PickUserRole[],
  resourceType: ResourceType
): UserRoleName[] =>
  userRoles
    .filter((r) => r?.resourceType === resourceType)
    .map((r) => r.roleName as UserRoleName)

export const getCommaSeparatedRoleNames = (
  user: {
    roles: PickUserRoleName[] | null
  } | null
): string => user?.roles?.map(({ roleName }) => roleName).join(', ') ?? ''

export const getCommaSeparatedRoleNamesUniq = (
  roles: PickUserRoleName[]
): string => join(', ', uniq(map((role) => role?.roleName ?? '', roles)))

export const hasValidParentRole = (
  role: Pick<UserRole, 'roleName' | 'resourceType' | 'status'>
): boolean => {
  return (
    role.roleName === UserRoleName.Parent &&
    role.resourceType === ResourceType.User &&
    role.status === UserRoleStatusName.Approved
  )
}
