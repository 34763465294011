import { Provider } from '@plvs/graphql/generated'
import {
  Leagueoflegends,
  Twitch,
  Battlenet,
  Epicgames,
  Xbox,
  Playstation,
} from '@playvs-inc/nexus-icons'

export const providerIconMap: Record<
  string,
  ({ ...props }) => React.ReactElement
> = {
  [Provider.BattleDotNet]: Battlenet,
  [Provider.EpicGames]: Epicgames,
  [Provider.Twitch]: Twitch,
  [Provider.Riot]: Leagueoflegends,
  [Provider.XBoxLive]: Xbox,
  [Provider.PlayStationNetwork]: Playstation,
}

export const getProviderExternalLink = (
  providerDisplayName: string,
  providerName?: Provider
): string | null => {
  switch (providerName) {
    case Provider.Riot: {
      return `https://na.op.gg/summoners/na/${providerDisplayName}`
    }
    case Provider.EpicGames: {
      return `https://rocketleague.tracker.network/rocket-league/profile/epic/${providerDisplayName}/overview`
    }
    case Provider.BattleDotNet: {
      return `https://playoverwatch.com/en-us/search/?q=${providerDisplayName}`
    }
    case Provider.Twitch: {
      return `https://www.twitch.tv/${providerDisplayName}`
    }
    default: {
      return null
    }
  }
}
