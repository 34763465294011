import React from 'react'

import { SignUpBenefits } from '../features/login/SignUpBenefits'
import { SignUpParentBenefits } from '../features/login/SignUpParentBenefits'
import { SignUpReferralBenefits } from '../features/login/SignUpReferralBenefits'
import { SignUpVesl } from '../features/login/SignUpVesl'

export function getSidebarContent(
  inviteCode: string | null,
  isParentSignup: boolean,
  isVeslSignup: boolean
): React.ReactNode {
  if (inviteCode) {
    return <SignUpReferralBenefits />
  }

  if (isVeslSignup) {
    return <SignUpVesl />
  }

  if (isParentSignup) {
    return <SignUpParentBenefits />
  }

  return <SignUpBenefits />
}
