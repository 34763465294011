import { EsportRating } from '@plvs/graphql/generated'
import { scrubSchoolIfYouthProgram, MatchAssistantTeam } from '@plvs/utils'

export const getDisplayForTeamsInMatchAssistant = ({
  team,
  esportRating,
}: {
  team: MatchAssistantTeam | null
  esportRating: EsportRating | null | undefined
}): MatchAssistantTeam => {
  if (team?.school) {
    // @ts-ignore Clean up types
    return scrubSchoolIfYouthProgram(team, esportRating) ?? null
  }

  return team
}
