// This component is a wrapper around a component hosted by Plasmic.
// Cannot be effectively unit tested.
/* istanbul ignore file */
import React from 'react'

import { PlasmicComponent } from '@plasmicapp/loader-react'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings } from '@plvs/const'

interface QueueLobbyFAQProps {
  isPreseason: boolean
}

export const QueueLobbyFAQ: React.FC<QueueLobbyFAQProps> = ({
  isPreseason = false,
}) => {
  return (
    <PlasmicComponent
      component={isPreseason ? 'PreseasonQueueLobbyFaq' : 'QueueLobbyFaq'}
      componentProps={{
        smartSchedulerOverview: {
          onClick: (): void =>
            showIntercomArticle(IntercomArticleMappings.smartSchedulerOverview),
        },
      }}
    />
  )
}
