import React from 'react'
import { AppPage } from '@plvs/rally/pages/page'
import { ExploreLeaguesTable } from '@plvs/rally/features/explore/ExploreLeaguesTable'

export const ExploreLeaguesTablePage: React.FC = () => {
  return (
    <AppPage title="Competitions">
      <ExploreLeaguesTable />
    </AppPage>
  )
}
