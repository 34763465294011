import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { MatchImageCard } from '../match/MatchImageCard'

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  navButtons: {
    diplay: 'flex',
  },
  actionButton: {
    whiteSpace: 'nowrap',
  },
}))
export interface OverlayStepInterface {
  step?: number
  children?: React.ReactNode
  image?: {
    src: string // url
    alt: string
  } | null
  icon?: JSX.Element
  nextCTA?: string
  onNextClick?: () => void
  title: string
  video?: {
    src: string // url
  } | null
}

export const OverlayStep: React.FC<OverlayStepInterface> = () => null

interface OverlayStepperInterface {
  children: React.ReactElement<OverlayStepInterface>[]
  open: boolean
  cancelable?: boolean
  onClose: () => void
  hideNumberOfSteps?: boolean
  cancelableButtonLabel?: string
  hideNextButton?: boolean
  actionButton?: {
    label: string
    onClick: () => void
  }
}

const getSteps = (
  children: OverlayStepperInterface['children']
): OverlayStepInterface[] =>
  React.Children.toArray(children)
    .filter(React.isValidElement)
    .map(({ props }) => props as OverlayStepInterface)

export const OverlayStepper: React.FC<OverlayStepperInterface> = ({
  children,
  open,
  onClose,
  cancelable = false,
  hideNumberOfSteps = false,
  cancelableButtonLabel,
  hideNextButton = false,
  actionButton,
}) => {
  const classes = useStyles()
  const steps = useMemo(() => getSteps(children), [children])
  const [currentStep, setCurrentStep] = useState<OverlayStepInterface>(steps[0])
  const currentIndex = steps.findIndex((step) => step.step === currentStep.step)

  useEffect(() => {
    setCurrentStep(steps[0])
  }, [steps])

  const switchStep = useCallback(
    (increment: number) => {
      const nextIndex = currentIndex + increment
      const step = steps[nextIndex]
      if (step) {
        setCurrentStep(step)
      } else {
        onClose()
      }
    },
    [currentStep, currentIndex, setCurrentStep, steps]
  )

  const onNext = useCallback(() => switchStep(1), [switchStep, currentIndex])
  const onPrev = useCallback(() => switchStep(-1), [switchStep, currentIndex])

  // TODO: be able to make sure there is a current step if step are avaliable

  useEffect(() => {
    if (!currentStep) {
      // eslint-disable-next-line
      console.warn('No current step found')
    }
  }, [currentStep])

  if (!currentStep) {
    return <></>
  }

  const {
    children: stepChildren,
    image,
    nextCTA,
    onNextClick,
    title,
    video,
    icon,
  } = currentStep

  const imageCardProps = {
    borderRadius: '0',
    boxShadow: 'none',
    height: 346,
    mb: 4,
    mt: -4,
    showOverlay: false,
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      {video?.src ? (
        <MatchImageCard {...imageCardProps} src={video.src} video />
      ) : null}
      {image?.src ? (
        <MatchImageCard {...imageCardProps} alt={image.alt} src={image.src} />
      ) : null}
      {icon ? <Box className={classes.iconWrapper}>{icon}</Box> : null}
      <Box display="flex" flexDirection="row" px={[4, 5]}>
        <Box flex="1" mb={2}>
          <NxTypography colorToken="ColorTextBase" variant="h2">
            {title}
          </NxTypography>
        </Box>
        {!hideNumberOfSteps ? (
          <Box flex="0" ml={1} whiteSpace="nowrap">
            <NxTypography colorToken="ColorTextAlt2" variant="body1">
              Step {currentIndex + 1}/{steps.length}
            </NxTypography>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="column" pb={[4, 5]} px={[4, 5]}>
        {stepChildren}
      </Box>
      <Box display="flex" flexDirection="row" px={[4, 5]}>
        {cancelable ? (
          <Box flex="grow" pr={1}>
            <NxButton
              label={cancelableButtonLabel || 'Cancel'}
              onClick={onClose}
              variant="secondary"
            />
          </Box>
        ) : null}
        {actionButton && (
          <Box className={classes.actionButton} flex="0">
            <NxButton
              label={actionButton.label}
              onClick={actionButton.onClick}
              variant="secondary"
            />
          </Box>
        )}
        <Box flex="1" />
        {currentIndex !== 0 && (
          <Box flex="0" pr={1}>
            <NxButton label="Previous" onClick={onPrev} variant="text" />
          </Box>
        )}
        {!hideNextButton ? (
          <Box flex="0">
            <NxButton
              label={nextCTA || 'Next'}
              onClick={onNextClick || onNext}
              variant="primary"
            />
          </Box>
        ) : null}
      </Box>
    </Dialog>
  )
}
