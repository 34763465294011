import React from 'react'
import SelectObject from './SelectObject'

interface FilterMetaseason {
  id: string
  name: string
}

interface Props {
  className?: string
  metaseason?: FilterMetaseason | null
  metaseasons: FilterMetaseason[]
  setMetaseason: (id: string) => void
  buttonClassName?: string
}

export const MetaseasonFilter: React.FC<Props> = ({
  className,
  metaseason,
  metaseasons,
  setMetaseason,
  buttonClassName,
}) => {
  const selectedMetaseason = metaseason ?? { name: 'Season', id: '' }

  return (
    <SelectObject<typeof selectedMetaseason>
      buttonClassName={buttonClassName}
      className={className}
      data-testid="LeagueFilter__MetaseasonSelect"
      option={selectedMetaseason}
      options={metaseasons}
      renderValue={(m): string => m?.name ?? 'n/a'}
      setOption={(m): void => setMetaseason(m?.id ?? '')}
    />
  )
}
