import {
  NxTypography,
  NxWeeklyAwardAchievementCard,
  NxCardPaginationButtons,
} from '@playvs-inc/nexus-components'
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { usePlayerAwardsProviderContext } from '@plvs/rally/features/profile/awards/PlayerAwardsProvider'
import {
  LeagueDarkLargePrimary,
  LeagueLightLargePrimary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { HeroGutter } from '@plvs/rally/components/hero'

import {
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'

import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { AssignedSeasonalAwardsCard } from './AssignedSeasonalAwardCard'

interface StyleProps {
  currentIndex: number
}

export const useStyles = makeStyles((theme) => ({
  teamRowContainerOverflow: {
    overflowX: 'hidden',
  },
  teamRowContainer: {
    height: 137,
    width: '100%',
    display: 'flex',
  },
  awardCard: ({ currentIndex }: StyleProps): CreateCSSProperties => ({
    marginRight: theme.spacing(2),
    transition: 'all 250ms linear',
    transform: `translateX(-${currentIndex * 105}%)`,
    minWidth: 298,
  }),
}))

export const PlayerAwards: React.FC = () => {
  const {
    assignedWeeklyAwards,
    assignedSeasonalAwards,
    loading,
  } = usePlayerAwardsProviderContext()

  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const combinedLength =
    assignedWeeklyAwards.length + assignedSeasonalAwards.length

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const handleNext = (): void => {
    if (currentIndex < combinedLength - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }
  const handlePrev = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }
  const classes = useStyles({ currentIndex })

  const hasAwards = combinedLength > 0

  return hasAwards ? (
    <Box>
      <HeroGutter mb={3} mt={4}>
        <Grid container direction="row" justifyContent="space-between">
          <NxTypography variant="h3">Achievements</NxTypography>
          <NxCardPaginationButtons
            enableLeft={currentIndex > 0}
            enableRight={currentIndex < combinedLength - 1}
            onLeft={handlePrev}
            onRight={handleNext}
          />
        </Grid>
      </HeroGutter>
      <PageContentGutter style={{ marginTop: 0 }}>
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          <Box className={classes.teamRowContainerOverflow}>
            <Box className={classes.teamRowContainer}>
              {assignedSeasonalAwards.length > 0 && (
                <>
                  {assignedSeasonalAwards.map((award) => (
                    <AssignedSeasonalAwardsCard
                      key={award.id}
                      award={award}
                      className={classes.awardCard}
                    />
                  ))}
                </>
              )}
              {assignedWeeklyAwards.length > 0 && (
                <>
                  {assignedWeeklyAwards.map((award) => (
                    <NxWeeklyAwardAchievementCard
                      key={award.id}
                      achievementRecord={award}
                      className={classes.awardCard}
                      spot={
                        isDarkMode ? (
                          <LeagueDarkLargePrimary height={80} width={80} />
                        ) : (
                          <LeagueLightLargePrimary height={80} width={80} />
                        )
                      }
                    />
                  ))}
                </>
              )}
            </Box>
          </Box>
        </WaitTillLoaded>
      </PageContentGutter>
    </Box>
  ) : null
}
