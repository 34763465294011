import { find, join, map } from 'ramda'
import { useCallback, useEffect, useState } from 'react'

import { Param } from '@plvs/const'

import { Option } from './filterHelpers'
import { useQueryParam } from './useQuery'

export type FilterOption = { id: string | number }

// The `fallbackOption` helps prevent errors while data is loading.
const fallbackOption = {
  id: 'fallback',
  name: 'N/A',
  values: [],
}

export function useOption<T extends FilterOption>({
  allOptions,
  defaultOption = fallbackOption,
  queryParam,
}: {
  allOptions: Option<T>[]
  defaultOption?: Option<T>
  queryParam?: Param
}): {
  option: Option<T>
  options: Option<T>[]
  setOption(input: Option<T>): void
} {
  const [param, setParam] = useQueryParam(queryParam)
  const [option, _setOption] = useState<Option<T>>(defaultOption)
  const setOption = useCallback(
    (o) => {
      // when setOption is called, set the paramValue to the option name
      //  and update the stored option using our private API, _setOption
      setParam(o.name)
      _setOption(o)
    },
    [setParam]
  )

  useEffect(() => {
    // if the list of options updates and there is a name among the options
    //  that matches the param, set that option; if no match, set the defaultOption
    const matchingOption = find(({ name }) => name === param, allOptions)
    _setOption(matchingOption || defaultOption)
  }, [
    join(
      '-',
      map((o) => o.id, allOptions)
    ),
  ])

  useEffect(() => {
    // if the param changes and is not equal to the selected option name,
    //  try to match the param with an existing option name and set that option
    if (option.name !== param) {
      const matchingOption = find(({ name }) => name === param, allOptions)
      if (matchingOption) _setOption(matchingOption)
    }
  }, [param])

  return {
    option,
    options: allOptions,
    setOption,
  }
}
