import { UserNotification } from '@plvs/graphql/generated/graphql'
import dayjs from 'dayjs'
import React from 'react'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'
import { NotificationSpotWrapper } from '../../NotificationSpotWrapper'

export const SchoolMessagePostedAdapter: UserNotificationContentAdapter = {
  notificationSource: 'School Message Posted',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SchoolSystemMessage
        IconComponent={<NotificationSpotWrapper />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        receivedAt={dayjs(notification.createdAt).toDate()}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
