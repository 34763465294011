import React from 'react'
import {
  Maybe,
  PlayerProfileSnapshot,
  School,
  UserProviderAccount,
} from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

import { SnapshotBox } from './SnapshotBox'
import { ProviderAccountLink } from './ProviderAccountLink'
import { RewindSlideshow } from '../rewind'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'black',
    marginBottom: theme.spacing(5),
  },
  heroWrapper: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  avatar: {
    width: 96,
    height: 96,
    minWidth: 64,
    minHeight: 64,
    '& svg': {
      minWidth: 64,
      minHeight: 64,
      width: 96,
      height: 96,
    },
    [theme.breakpoints.down('xs')]: {
      width: 64,
      height: 64,
      '& svg': {
        width: 64,
        height: 64,
      },
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  stat: {
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  avatarUrl: Maybe<string> | undefined
  gradYear: Maybe<number> | undefined
  id: string | undefined
  isProfileValid: boolean
  name: Maybe<string> | undefined
  playerProfileSnapshot:
    | Maybe<
        Pick<
          PlayerProfileSnapshot,
          'matchesPlayed' | 'seasonsPlayed' | 'titlesPlayed'
        >
      >
    | undefined
  school: Maybe<Pick<School, 'id' | 'name' | 'city' | 'state'>> | undefined
  userProviderAccounts: Pick<
    UserProviderAccount,
    'providerDisplayName' | 'providerName' | 'id'
  >[]
}

export const ProfileHero: React.FC<Props> = ({
  avatarUrl,
  gradYear,
  id,
  isProfileValid,
  name,
  playerProfileSnapshot,
  school,
  userProviderAccounts,
}) => {
  const classes = useStyles()

  const subtitle = `Class of ${gradYear ?? 'N/A'} | ${
    school?.name ?? 'No School'
  } · ${school?.city ?? 'No City'}, ${school?.state ?? 'No State'}`

  const matchesPlayed = playerProfileSnapshot?.matchesPlayed ?? 0
  const seasonsPlayed = playerProfileSnapshot?.seasonsPlayed ?? 0
  const titlesPlayed = playerProfileSnapshot?.titlesPlayed ?? 0

  const playerStats = (
    <Box
      alignItems="center"
      display={['none', 'flex']}
      marginLeft="auto"
      mb={2}
    >
      {!!matchesPlayed && (
        <SnapshotBox
          className={classes.stat}
          stat={matchesPlayed}
          subtitle="Matches"
        />
      )}
      {!!seasonsPlayed && (
        <SnapshotBox
          className={classes.stat}
          stat={seasonsPlayed}
          subtitle="Seasons"
        />
      )}
      {!!titlesPlayed && <SnapshotBox stat={titlesPlayed} subtitle="Titles" />}
    </Box>
  )

  return (
    <div className={classes.heroWrapper}>
      <NxPlayVsBanner
        additionalContent={
          isProfileValid ? (
            <>
              <Box alignItems="center" display="flex" flexWrap="wrap">
                {userProviderAccounts.map((pa) => (
                  <ProviderAccountLink key={pa?.id} userProviderAccount={pa} />
                ))}
              </Box>
              {playerStats}
            </>
          ) : (
            <></>
          )
        }
        avatar={
          isProfileValid ? (
            <NxUserAvatar
              avatarUrl={avatarUrl}
              className={classes.avatar}
              hashId={id ?? ''}
              size={NxAvatarSize.LG}
            />
          ) : (
            <></>
          )
        }
        rightContent={isProfileValid ? <RewindSlideshow userId={id} /> : <></>}
        shouldUseDefaultBanner
        size="small"
        subtitle={isProfileValid ? subtitle : ''}
        title={isProfileValid ? name || '' : ''}
        variant={PersonaImageVariant.MySchool}
      />
    </div>
  )
}
