import React, { useEffect, useMemo } from 'react'

import { NxTab, NxTabs, NxSkeletonLoader } from '@playvs-inc/nexus-components'

import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useGetCoachAdditionalInfoQuery } from '@plvs/graphql/generated'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'

import { isAdminForSystem, isCoachForResource } from '@plvs/utils'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import {
  useRosterCardControllerState,
  useRosterCardRenderControllerContext,
} from '@plvs/respawn/renderController/rosterCard/RosterCardRenderControllerProvider'
import { matchPath, useLocation } from 'react-router-dom'
import { useRosterCardStyles } from './RosterCard.styles'

import { useRosterContext } from './RosterProvider'
import { ScrimmageReady } from './ScrimmageReady'
import { RosterRowList } from './rosterChildren/RosterRowList'
import { RosterRowCoachesList } from './rosterChildren/coaches/RosterRowCoachesList'
import { RosterRowBenchListWrapper } from './rosterChildren/RosterRowBenchListWrapper'
import { RosterMatchHistory } from './rosterChildren/RosterMatchHistory'
import {
  BenchRosterPlayer,
  RosterCardTabs,
  StarterRosterPlayer,
} from './roster.types'
import { RosterDefaultHeader } from './RosterDefaultHeader'
import { RosterRivalsList } from './rivals/RosterRivalsList'
import { InvitePlayerButton } from './modal/InvitePlayerButton'

export interface RosterCardProps {
  showAdditionalContainerActions: boolean
  showCreateScrimmage: boolean
  readOnly: boolean
  hideDefaultHeader?: boolean
  CustomHeader?: React.FC
  rosterCardStarterRowActions?: RosterCardMenuRowItems[]
  rosterCardSubRowActions?: RosterCardMenuRowSubItems[]
  noBoxShadow?: boolean
  borderRadius?: string | number
  parentDataLoading?: boolean
  eventId?: string
  isInMatchAssistantContainer?: boolean
}

export const RosterCard: React.FC<RosterCardProps> = ({
  showAdditionalContainerActions,
  showCreateScrimmage,
  readOnly,
  hideDefaultHeader = false,
  CustomHeader,
  rosterCardStarterRowActions,
  rosterCardSubRowActions,
  noBoxShadow = false,
  borderRadius,
  parentDataLoading = false,
  eventId,
  isInMatchAssistantContainer = false,
}) => {
  const productType = useProductTypeFn()
  const {
    isTeamAssociatedWithOrg,
    teamCoaches,
    team,
    teamId,
    starters,
    substitutes,
    loading,
    teamFormat,
    onMutationSuccessInRosterFlow,
    addToTeamMutationSuccess,
    userRoles,
    esport,
    metaseason,
    isPast,
    isBenchFull,
    getRosterCardPermissions,
    userId,
    schoolId,
    league,
    isEnrolled,
    isTeamOwnerForResource,
    publicView,
    competitionGroup,
  } = useRosterContext()
  const { pathname } = useLocation()
  const { rosterCard } = useRosterCardRenderControllerContext()
  const { setRenderControllerStateFn, getRenderControllerState } =
    useRosterCardControllerState()
  const rosterCardComponents = rosterCard.getRosterCardComponentsToRender({
    productType,
    isInMatchAssistantContainer,
    isTeamOwnerForResource,
  })
  useEffect(() => {
    const currentState = getRenderControllerState()
    setRenderControllerStateFn({
      ...currentState,
      rosterCard: {
        ...currentState.rosterCard,
        ...rosterCardComponents,
      },
    })
  }, [])

  const {
    shouldShowInvitePlayer,
    shouldRenderTeamAvatar,
    isContactsTabEnabled,
    enableScoutingToTeamsPage,
  } = rosterCardComponents

  const [tabValue, setTabValue] = React.useState<RosterCardTabs>(
    RosterCardTabs.Roster
  )
  const coachMangeTeamsRouteMatch = matchPath(
    {
      path: Path.ManageTeams,
      caseSensitive: true,
      end: true,
    },
    pathname
  )

  const { isCoachAtOrg } = useUserIdentityFn()

  const classes: Record<string, string> = useRosterCardStyles()

  const { data: additionalInfoData } = useGetCoachAdditionalInfoQuery({
    variables: {
      teamId,
    },
    skip: !isCoachAtOrg || !teamId || loading,
    // There is an edge case where a user is a coach, but is viewing outside
    // of their league group.  This field is only viewable
    // for coaches at the same league group.
    errorPolicy: 'ignore',
  })

  const teamCoachesWithAdditionalInfo = useMemo(() => {
    return teamCoaches?.map((teamCoach) => ({
      ...teamCoach,
      phone: teamCoach?.isPhoneNumberVisible ? teamCoach.phone : null,
      additionalInfo: additionalInfoData?.team?.coaches?.find(
        (coach) => coach.id === teamCoach.id
      )?.additionalInfo,
    }))
  }, [teamCoaches, additionalInfoData])

  const esportAdapter = useGeneralEsportAdapter(esport.slug)

  // computed props
  const isOnCoachManageTeamsPage = !!coachMangeTeamsRouteMatch

  const startersListWithUserName = starters.map((starter) => {
    if (!starter?.userName) {
      return {
        ...starter,
        userName: starter?.user ? esportAdapter?.getUsername(starter.user) : '',
      }
    }
    return starter
  })

  const substitutesWithUserName = substitutes.map((sub) => {
    if (!sub?.userName) {
      return {
        ...sub,
        userName: sub?.user ? esportAdapter?.getUsername(sub.user) : '',
      }
    }

    return sub
  })

  const createScrimmageReady =
    showCreateScrimmage &&
    isTeamAssociatedWithOrg &&
    esportAdapter?.isScrimmageEnabled &&
    starters.length >= teamFormat

  const handleTabChange = (_: any, newValue: RosterCardTabs): void => {
    setTabValue(newValue)
  }

  const isCoachForThisOrg = isCoachForResource(userRoles, [schoolId])
  const isAdmin = isAdminForSystem(userRoles)
  const enableBenchTab =
    isAdmin || isCoachForThisOrg || Boolean(substitutesWithUserName.length)
  const enableRivalsTab = isEnrolled

  return (
    <WaitTillLoaded
      loading={loading || parentDataLoading}
      LoadingComponent={(): React.ReactElement => (
        <NxSkeletonLoader height="519px" variant="rect" width="100%" />
      )}
      showSpinnerWhileLoading
    >
      <MatchCard
        borderRadius={borderRadius}
        className={classes.card}
        noBoxShadow={noBoxShadow}
        px={0}
        title={
          <>
            {CustomHeader && <CustomHeader />}
            {!hideDefaultHeader && !CustomHeader && (
              <RosterDefaultHeader
                enableScoutingToTeamsPage={enableScoutingToTeamsPage}
                shouldRenderTeamAvatar={shouldRenderTeamAvatar}
                showAdditionalContainerActions={showAdditionalContainerActions}
              />
            )}
          </>
        }
        titleContainerClassName={classes.rootTitleContainer}
      >
        <Box pl={4}>
          <NxTabs onChange={handleTabChange} size="small" value={tabValue}>
            <NxTab label="Roster" value={RosterCardTabs.Roster} />
            {esportAdapter?.hasSubstitutesEnabled && (
              <NxTab
                disabled={!enableBenchTab}
                label="Bench"
                value={RosterCardTabs.Bench}
              />
            )}
            {isContactsTabEnabled && (
              <NxTab
                label={isOnCoachManageTeamsPage ? 'Coaches' : 'Contacts'}
                value={RosterCardTabs.Contacts}
              />
            )}
            {!isOnCoachManageTeamsPage && (
              <NxTab
                data-cy="Match History"
                label="History"
                value={RosterCardTabs.MatchHistory}
              />
            )}

            {enableRivalsTab && (
              <NxTab
                data-cy={RosterCardTabs.Rivals}
                label={RosterCardTabs.Rivals}
                value={RosterCardTabs.Rivals}
              />
            )}
          </NxTabs>
        </Box>

        {tabValue === RosterCardTabs.Roster && (
          <Box display="flex" flexDirection="column">
            <RosterRowList
              addToTeamMutationSuccess={addToTeamMutationSuccess}
              competitionGroup={competitionGroup}
              esportSlug={esport.slug}
              getPermissions={getRosterCardPermissions}
              isAdmin={isAdmin}
              isPast={isPast}
              isTeamAssociatedWithOrg={isTeamAssociatedWithOrg}
              leagueId={league?.id ?? ''}
              loading={loading}
              members={startersListWithUserName as StarterRosterPlayer[]}
              metaseason={metaseason}
              publicView={publicView}
              readOnly={readOnly}
              rosterCardStarterRowActions={rosterCardStarterRowActions}
              teamId={teamId}
              teamName={team?.name ?? ''}
              userId={userId}
              userRoles={userRoles}
            />
          </Box>
        )}

        {tabValue === RosterCardTabs.Bench && (
          <Box display="flex" flexDirection="column">
            <RosterRowBenchListWrapper
              competitionGroup={competitionGroup}
              esportSlug={esport.slug}
              getPermissions={getRosterCardPermissions}
              isAdmin={isAdmin}
              isBenchFull={isBenchFull}
              isCoachForTeamsOrg={isCoachForThisOrg}
              isPast={isPast}
              members={substitutesWithUserName as BenchRosterPlayer[]}
              metaseason={metaseason}
              readOnly={readOnly}
              rosterCardSubRowActions={rosterCardSubRowActions}
              teamId={teamId}
              userId={userId}
            />
          </Box>
        )}

        {tabValue === RosterCardTabs.Contacts && (
          <Box display="flex" flexDirection="column">
            <RosterRowCoachesList
              coaches={teamCoachesWithAdditionalInfo ?? []}
              disableAction={isPast || readOnly}
              getPermissions={getRosterCardPermissions}
              isCoachForThisOrg={isCoachForThisOrg}
              onMutationSuccess={onMutationSuccessInRosterFlow}
              schoolId={schoolId}
              teamId={teamId}
              userId={userId}
              userRoles={userRoles}
            />
          </Box>
        )}

        {tabValue === RosterCardTabs.MatchHistory && (
          <Box display="flex" flexDirection="column">
            <RosterMatchHistory
              esportRating={esport.rating}
              esportSlug={esport.slug}
              teamId={teamId}
            />
          </Box>
        )}

        {tabValue === RosterCardTabs.Rivals && (
          <Box display="flex" flexDirection="column">
            <RosterRivalsList />
          </Box>
        )}

        {createScrimmageReady && esport.slug && (
          <ScrimmageReady
            esportSlug={esport.slug}
            teamId={teamId}
            userRoles={userRoles}
          />
        )}
        {shouldShowInvitePlayer && (
          <InvitePlayerButton eventId={eventId || ''} teamId={teamId} />
        )}
      </MatchCard>
    </WaitTillLoaded>
  )
}
