import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { HighAlertLightSmallPrimary } from '@playvs-inc/nexus-spots'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'

const UpdateSchoolITPhoneNumberAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  return (
    <SchoolSystemMessage
      IconComponent={<HighAlertLightSmallPrimary height="4rem" />}
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const UpdateSchoolITPhoneNumberAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Update School It Phone Number',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <UpdateSchoolITPhoneNumberAdapterContainer notification={notification} />
    )
  },
}
