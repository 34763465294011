import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const DropdownIcon = createSvgIcon(
  <>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 8 16"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99054 16C3.76998 16.0003 3.55999 15.9057 3.41423 15.7405L0.144044 12.0308C-0.070923 11.7751 -0.0420575 11.395 0.209057 11.1745C0.460172 10.9541 0.841685 10.9741 1.06834 11.2195L3.92788 14.4635C3.9437 14.4815 3.96654 14.4918 3.99054 14.4918C4.01454 14.4918 4.03738 14.4815 4.0532 14.4635L6.91274 11.2195C7.05632 11.0487 7.28131 10.969 7.50065 11.0111C7.71999 11.0532 7.89927 11.2106 7.96911 11.4223C8.03896 11.634 7.98841 11.8669 7.83703 12.0308L4.56785 15.7392C4.42184 15.9048 4.21156 15.9998 3.99054 16Z"
        fill="#8A939C"
      />
      <path
        d="M3.99054 4.76837e-07C3.76998 -0.000271797 3.55999 0.0942712 3.41423 0.259468L0.144044 3.96918C-0.070923 4.22485 -0.0420575 4.60503 0.209057 4.82546C0.460172 5.04588 0.841685 5.02593 1.06834 4.78051L3.92788 1.53651C3.9437 1.5185 3.96654 1.50817 3.99054 1.50817C4.01454 1.50817 4.03738 1.5185 4.0532 1.53651L6.91274 4.78051C7.05632 4.95128 7.28131 5.03103 7.50065 4.98892C7.71999 4.94681 7.89927 4.78944 7.96911 4.57771C8.03896 4.36597 7.98841 4.13309 7.83703 3.96918L4.56785 0.260798C4.42184 0.0952015 4.21156 0.000209808 3.99054 4.76837e-07Z"
        fill="#8A939C"
      />
    </svg>
  </>,
  'Dropdown Icon',
  { viewBox: '-4 -8 40 40' }
)
