import React from 'react'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { Path } from '@plvs/const'
import { RelativeTiming } from '@plvs/utils'
import { CoachEnrollmentCard } from '@plvs/rally/containers/manageTeams/CoachEnrollmentCardContainer'

export const CoachEnrollmentWidget: React.FC = () => {
  const { promotedMetaseason } = useSchoolLeagueInfoContext()
  const metaseasonId = promotedMetaseason?.id ?? ''
  const metaseasonName = promotedMetaseason?.name ?? ''
  const isPastMetaseason = promotedMetaseason?.timing === RelativeTiming.Past

  if (isPastMetaseason) {
    return null
  }

  return (
    <CoachEnrollmentCard
      metaseason={promotedMetaseason}
      path={`${Path.Enrollment}/${metaseasonId}`}
      title={`Join the ${metaseasonName} Season!`}
    />
  )
}
