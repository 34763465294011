import React, { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box } from '@plvs/respawn/features/layout'
import { TextField } from '@material-ui/core'
import { cleanGraphQLError, CookieProps, formErrorToString } from '@plvs/utils'
import {
  refetchGetUserProviderAccountsQuery,
  useLinkNintendoAccountMutation,
  LinkNintendoAccountInput,
  useUnlinkUserProviderAccountMutation,
} from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'

import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import ConnectionCard from './ConnectionCard'
import { AccountProviderComponentProps } from './utils'

interface ProviderConnectionProps
  extends AccountProviderComponentProps,
    RouteComponentProps {}

export const NintendoConnection: React.FC<
  ProviderConnectionProps & CookieProps
> = ({ name: providerName, providerDetails, checkpoint, userId, isParent }) => {
  // Hooks
  const [error, setError] = useState<ApolloError | undefined>()
  const [nintendoUsername, setName] = useState<string>(
    providerDetails?.providerDisplayName ?? ''
  )
  const [providerId, setProviderId] = useState<string | undefined>(
    providerDetails?.id
  )

  // Data
  const lastUpdated =
    providerDetails?.updatedAt ?? providerDetails?.createdAt ?? ''

  // form
  const { errors, handleSubmit, register } = useForm<LinkNintendoAccountInput>({
    defaultValues: { displayName: nintendoUsername },
    resolver: yupResolver<LinkNintendoAccountInput>(
      yup.object().shape({
        displayName: yup.string().required('Enter a Nintendo friend code'),
      })
    ),
  })

  // Mutations
  const [
    remove,
    { loading: disconnectMutationLoading },
  ] = useUnlinkUserProviderAccountMutation({
    onError: (err: ApolloError): void => setError(err),
  })

  const [
    connectNintendoAccount,
    { loading: connectMutationLoading },
  ] = useLinkNintendoAccountMutation({
    onError: (err: ApolloError): void => setError(err),
  })

  const disconnectNintendoAccount = async (): Promise<void> => {
    if (!providerId) {
      return
    }
    if (!disconnectMutationLoading) {
      await remove({
        awaitRefetchQueries: true,
        refetchQueries: [refetchGetUserProviderAccountsQuery()],
        variables: {
          providerId,
        },
      })
      setName('')
      setProviderId(undefined)
      setError(undefined)
    }
  }

  useEffect(() => {
    if (disconnectMutationLoading || connectMutationLoading) {
      return
    }
    if (providerDetails?.providerDisplayName !== nintendoUsername) {
      setName(providerDetails?.providerDisplayName ?? '')
    }
    if (providerDetails?.id !== providerId) {
      setProviderId(providerDetails?.id)
    }
  }, [providerDetails])

  const onConnect = handleSubmit(
    async (input: { displayName: string }): Promise<void> => {
      if (!connectMutationLoading) {
        const result = await connectNintendoAccount({
          refetchQueries: [refetchGetUserProviderAccountsQuery()],
          variables: {
            userId: userId ?? '',
            attributes: { displayName: input.displayName },
          },
        })
        setProviderId(result.data?.linkNintendoAccount?.id ?? '')
        setName(result.data?.linkNintendoAccount.providerDisplayName ?? '')
      }
    }
  )

  const renderError = (): string =>
    error?.message ? cleanGraphQLError(error.message) : ''

  const EditComponent = !nintendoUsername ? (
    <form key={nintendoUsername} noValidate onSubmit={onConnect}>
      <Box maxWidth={400}>
        <TextField
          autoComplete="off"
          defaultValue={nintendoUsername}
          error={!!errors.displayName}
          fullWidth
          helperText={formErrorToString(errors.displayName)}
          InputProps={{ readOnly: !!nintendoUsername }}
          inputRef={register}
          label="Friend Code"
          name="displayName"
          size="small"
          variant="outlined"
        />
      </Box>
    </form>
  ) : undefined

  return (
    <ConnectionCard
      checkpoint={checkpoint}
      connectedText={nintendoUsername ? `${nintendoUsername} saved` : ''}
      disabled={connectMutationLoading || disconnectMutationLoading}
      EditComponent={!nintendoUsername ? EditComponent : undefined}
      errorText={renderError()}
      isConnected={!!nintendoUsername}
      isParent={isParent}
      lastUpdated={lastUpdated}
      onConnectClick={onConnect}
      onUpdateClick={disconnectNintendoAccount}
      providerName={providerName}
      subtitle="Add your Nintendo friend code. (Ex: SW-0000-0000-0000)"
      title="Nintendo"
      userId={userId}
    />
  )
}
