import { Button, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { Tooltip } from '@plvs/rally/components/tooltip'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.ColorTextBase,
    padding: theme.spacing(1.5, 0, 0),
  },
  selection: {
    color: theme.palette.ColorTextBase,
    paddingRight: theme.spacing(0.5),
  },
  button: {
    textTransform: 'capitalize',
    color: theme.palette.ColorTextLink,
  },
}))

export interface SelectedWeekProps {
  onClick(): void
  isPastDeadline: boolean
  selectedWeekInterval: string
}
export const SelectedWeek: React.FC<SelectedWeekProps> = ({
  onClick,
  isPastDeadline,
  selectedWeekInterval,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex">
        <NxTypography className={classes.selection} variant="body2">
          {selectedWeekInterval}
        </NxTypography>
        <Tooltip
          disableFocusListener={!isPastDeadline}
          disableHoverListener={!isPastDeadline}
          disableTouchListener={!isPastDeadline}
          placement="bottom-start"
          title="This can‘t be changed after the break week has started"
        >
          <Button
            className={classes.button}
            data-testid="SelectedWeek_Button"
            disabled={isPastDeadline}
            onClick={onClick}
            variant="text"
          >
            Change Week
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )
}
