import { makeStyles } from '@material-ui/core'

export interface StyleProps {
  active: boolean
}

export const useRosterCardStyles = makeStyles(() => ({
  card: {
    height: 'auto',
    maxWidth: '100%',
  },
  rootTitleContainer: {
    padding: 0,
    width: '100%',
  },
}))
