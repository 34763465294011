import React from 'react'

import { Path } from '@plvs/const'
import { useParams } from 'react-router-dom'
import { MatchDispute } from '@plvs/rally/features/match/dispute'
import { PageContentGutter } from '@plvs/respawn/features/layout'
import { ReturnLink } from '@plvs/rally/components/link'
import { AppPage } from '../../page'

export const MatchDisputePage: React.FC = () => {
  const params = useParams<{ matchId: string }>()
  return (
    <AppPage title="Dispute Match Results">
      <PageContentGutter>
        <ReturnLink
          path={`${Path.Match}/${params.matchId}/mission-control`}
          title="Match Lobby"
        />
        <MatchDispute matchId={params.matchId!} />
      </PageContentGutter>
    </AppPage>
  )
}
