import { head, reduce } from 'ramda'
import React, { useState } from 'react'

import { School, Team, User } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Table, TableColumn } from '../../table/Table'

type StatRowBase = {
  stats: Record<string, any> | null
}

export type PlayerStatRow = StatRowBase & {
  player: Pick<User, 'id' | 'avatarUrl' | 'name'> | null
}

export type TeamStatRow = StatRowBase & {
  team:
    | (Pick<Team, 'name' | 'id'> & {
        school: Pick<School, 'name' | 'logoUrl'> | null
      })
    | null
}

export interface CategoryInterface {
  header: string
  accessor: string
  format?: (stat: number) => string
}

interface CategoryStats<T> {
  [category: string]: T
}

function createCategoryMap<T>(
  categories: CategoryInterface[],
  fn: (category: CategoryInterface) => T | undefined
): CategoryStats<T> {
  return reduce<CategoryInterface, CategoryStats<T>>(
    (accum, category): CategoryStats<T> => {
      const stat = fn(category)

      return stat
        ? {
            ...accum,
            [category.accessor]: stat,
          }
        : accum
    },
    {},
    categories
  )
}

export const TopPerformers = <T extends StatRowBase>({
  categories,
  data,
  entityColumn,
  PillButtonComponent,
}: {
  categories: CategoryInterface[] // this is the categories for an esport
  data: T[] // this would be a row of stats
  entityColumn: TableColumn<T>
  PillButtonComponent: React.FC<{
    onClick(): void
    row: T
    selected?: boolean
    subtitle: string
    value: string
  }>
}): React.ReactElement => {
  const [selectedCategory, setSelectedCategory] = useState<
    CategoryInterface | undefined
  >(head(categories))

  if (!data.length || !selectedCategory) return <></>

  // small amout of aggregation
  const statsByCategory = createCategoryMap<T[]>(categories, ({ accessor }) =>
    [...data].sort((prev, next): number => {
      const nextValue = parseFloat(next.stats?.[accessor] ?? 0)
      const prevValue = parseFloat(prev.stats?.[accessor] ?? 0)
      return nextValue - prevValue
    })
  )

  const topPerformersMap = createCategoryMap<T>(categories, ({ accessor }) =>
    head(statsByCategory[accessor])
  )

  return (
    <Box display="flex" flexDirection="row">
      <Box flex={1}>
        {categories.map(({ header, accessor, format }, i) => {
          const topPerformer = topPerformersMap[accessor]
          const value = topPerformer.stats?.[accessor]
          return (
            <Box key={header} pb={categories.length !== i + 1 ? 2 : 0}>
              <PillButtonComponent
                onClick={(): void =>
                  setSelectedCategory({ header, accessor, format })
                }
                row={topPerformer}
                selected={selectedCategory.accessor === accessor}
                subtitle={header}
                value={format ? format(value) : value}
              />
            </Box>
          )
        })}
      </Box>
      <Box flex={0} pl={3} />
      <Box flex={1} mt={-2}>
        <Table
          columns={[
            {
              Header: 'Rank',
              Cell: ({ row }): React.ReactElement => (
                // eslint-disable-next-line no-underscore-dangle
                <>{row._index + 1}</>
              ),
              width: 60,
            },
            entityColumn,
            {
              Header: 'Total',
              width: 100,
              Cell: ({ stats }): React.ReactElement => {
                const value = stats?.[selectedCategory.accessor]
                return (
                  <NxTypography variant="h4">
                    {selectedCategory.format
                      ? selectedCategory.format(value)
                      : value}
                  </NxTypography>
                )
              },
            },
          ]}
          data={statsByCategory[selectedCategory.accessor].slice(0, 5)}
          resizable={false}
          sortable={false}
        />
      </Box>
    </Box>
  )
}
