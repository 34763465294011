import React from 'react'

import { useTheme } from '@material-ui/core'
import { Alert, AlertProps } from '@plvs/respawn/features/alert/Alert'

export const DarkAlert: React.FC<
  Omit<AlertProps, 'backgroundColor' | 'color' | 'subtitleColor'>
> = (props) => {
  const theme = useTheme()

  return (
    <Alert
      backgroundColor={theme.palette.common.black}
      color={theme.palette.common.white}
      subtitleColor={theme.palette.grey[100]}
      {...props}
    />
  )
}
