import { FALLBACK_TIMEZONE } from '@plvs/const'
import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(dayjsUtc)
dayjs.extend(tz)

// enums

export enum DateFormats {
  LeagueCompetitionTimeFormat = 'dddd h:mmA z',
  PrettyPrintFormat = 'MMM D, YYYY ● h:mm A z',
}

// utils

export function getDateFormatMonthDateYear(date: Date): string {
  const weekdayMonthDay = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date)
  return weekdayMonthDay
}

export const prettyPrintISOString = ({
  isostring,
  timezone,
}: {
  isostring: string
  timezone: string
}): string => dayjs(isostring).tz(timezone).format('ddd MMM D h:mma Z')

export const formatInTz = ({
  at,
  format,
  timezone,
}: {
  at: string | null | undefined
  format: string
  timezone: string | null | undefined
}): string =>
  dayjs(at)
    .tz(timezone ?? FALLBACK_TIMEZONE)
    .format(format)
