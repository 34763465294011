export const MuiToolbar = {
  regular: {
    minHeight: 72,
    paddingLeft: '24px',
    paddingRight: '24px',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.04), 0px 2px 16px rgba(0, 0, 0, 0.04);',
    '@media (min-width: 600px)': {
      minHeight: 92,
      paddingLeft: '16px',
      paddingRight: '20px',
    },
  },
}
