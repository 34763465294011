import React, { useEffect, useState } from 'react'

export enum SheerIdOrigin {
  Path = 'https://services.sheerid.com',
}
export interface IframeProps {
  verificationId: string
  programId: string
}

export const Iframe: React.FC<IframeProps> = ({
  verificationId,
  programId,
}) => {
  const [height, setHeight] = useState<string>('100%')

  useEffect(() => {
    const listener = ({
      data,
      origin,
    }: {
      data: string | Record<string, string>
      origin: string
    }): void => {
      if (origin === SheerIdOrigin.Path && typeof data === 'string') {
        try {
          const parsed = JSON.parse(data)
          if (parsed.action === 'updateHeight' && parsed.height) {
            setHeight(`${parsed.height}px`)
          }
        } catch (e: any) {
          // Incase the JSON object is malformed
        }
      }
    }
    window.addEventListener('message', listener)
    return (): void => window.removeEventListener('message', listener)
  }, [height])

  return (
    <iframe
      allow="fullscreen"
      frameBorder={0}
      height={height}
      src={`https://services.sheerid.com/verify/${programId}/?verificationId=${verificationId}`}
      title="Confirm Eligibility"
      width="100%"
    />
  )
}
