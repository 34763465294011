import React from 'react'
import { debounce, makeStyles, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete } from '@material-ui/lab'
import {
  TeamFiltersInput,
  useGetTeamsSearchLazyQuery,
} from '@plvs/graphql/generated'
import { DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { SelectedTeam } from './types'
import { TeamSearchOption } from './TeamSearchOption'

const useStyles = makeStyles((theme) => ({
  listBox: {
    maxHeight: 200,
  },
  popupIndicatorOpen: {
    transform: 'rotate(0deg)',
  },
  textField: {
    backgroundColor: theme.palette.ColorBackgroundBase,
  },
}))

interface Props {
  className?: string
  limit?: number
  onChange: (newTeam: SelectedTeam | null) => void
  size?: 'medium' | 'small'
  teamFilters?: TeamFiltersInput
  value: SelectedTeam | null
}

export const TeamSearch: React.FC<Props> = ({
  className,
  limit = 10,
  onChange,
  size = 'small',
  teamFilters,
  value,
}) => {
  const classes = useStyles()

  const [getTeams, { data: teamsData, loading }] = useGetTeamsSearchLazyQuery()

  const fetchTeamsOnOpen = (): void => {
    getTeams({
      variables: {
        limit,
        filters: teamFilters,
      },
    })
  }
  const fetchTeamsOnInputChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string,
    _reason: string
  ): void => {
    getTeams({
      variables: {
        limit,
        filters: { keyword: newValue, ...teamFilters },
      },
    })
  }

  const teams = teamsData?.getTeams?.teams ?? []

  return (
    <Autocomplete
      classes={{
        listbox: classes.listBox,
        popupIndicatorOpen: classes.popupIndicatorOpen,
      }}
      className={className}
      getOptionLabel={(option): string => option.name ?? ''}
      getOptionSelected={(option, newValue): boolean =>
        option.id === newValue.id
      }
      loading={loading}
      onChange={(e, data): void => onChange(data)}
      onInputChange={debounce(fetchTeamsOnInputChange, DEFAULT_DEBOUNCE_TIME)}
      onOpen={fetchTeamsOnOpen}
      options={teams}
      popupIcon={<SearchIcon />}
      renderInput={(params): React.ReactElement => (
        <TextField
          {...params}
          className={classes.textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <WaitTillLoaded
                    loading={loading}
                    loadingSpinnerProps={{ size: 'small' }}
                    showSpinnerWhileLoading
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Search team name"
          variant="outlined"
        />
      )}
      renderOption={(option): React.ReactElement => (
        <TeamSearchOption team={option} />
      )}
      size={size}
      value={value}
    />
  )
}
