import { SchoolTeamFragment, UserRole } from '@plvs/graphql/generated'
import { isCoachForResource, isCaptainForResource } from '@plvs/utils'

export function getTeamsForScrimmage(
  teams: SchoolTeamFragment[] = [],
  schoolId: string,
  userRoles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[]
): SchoolTeamFragment[] {
  const unhiddenTeams = teams.filter((team) => team.isHidden === false)
  return isCoachForResource(userRoles, [schoolId])
    ? unhiddenTeams
    : unhiddenTeams.filter((team) => isCaptainForResource(userRoles, [team.id]))
}
