import React from 'react'
import { values, uniqBy } from 'ramda'
import { SelectObject } from '@plvs/rally/components/filter/SelectObject'
import { Box } from '@plvs/respawn/features/layout'
import { CompetitionModel } from '@plvs/graphql'
import { competitionModelToDisplay, MinimalMetaseason } from '@plvs/utils'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { MinimalLeague } from '@plvs/respawn/features/filters/types'
import { useLeagueConfig } from '@plvs/respawn/containers/filter/league/hooks'
import { useFilterStyles } from './styles'

type LeagueFiltersProps = {
  showMetaseasons?: boolean
  showLeagues?: boolean
  showUniqueLeaguesByEsport?: boolean
  showTeams?: boolean
  showPhases?: boolean
  spacing?: number
  /**
   * Display a league by it's display displayname/name instead of by it's
   * esport.  'East Rocket League 5' vs. 'Rocket League'
   */
  displayLeagueNames?: boolean
  showCompetitionModel?: boolean
  metaseasonOptions?: MinimalMetaseason[]
}

export const LeagueFilters: React.FC<LeagueFiltersProps> = ({
  showMetaseasons = true,
  showLeagues = true,
  showTeams = true,
  showPhases = false,
  showUniqueLeaguesByEsport = true,
  spacing = 2,
  showCompetitionModel = false,
  displayLeagueNames,
  metaseasonOptions,
}) => {
  const {
    metaseasons,
    metaseason,
    setMetaseason,
    leagues,
    league,
    setLeague,
    teams,
    team,
    setTeam,
    phase,
    phases,
    setPhase,
    competitionModel,
    setCompetitionModel,
  } = useLeagueConfig()

  const { competitionGroup } = useSelectedOrganizationFn()

  const selectedMetaseason = metaseason ?? { name: 'Season', id: '' }
  const selectedTeam = team ?? { name: 'Team', id: '' }
  const selectedLeague = league ?? {
    name: 'League',
    id: '',
    esport: { id: '', name: 'Esport' },
  }
  const selectedPhase = phase ?? { name: 'Period', id: '' }

  const competitionModels = values(CompetitionModel).map((c) => ({
    name: competitionModelToDisplay({ competitionModel: c, competitionGroup }),
    id: c,
  }))

  const selectedCompetitionModel = competitionModel
    ? {
        name: competitionModelToDisplay({ competitionModel, competitionGroup }),
        id: competitionModel,
      }
    : {
        name: competitionModelToDisplay({
          competitionModel: CompetitionModel.Rec,
          competitionGroup,
        }),
        id: CompetitionModel.Rec,
      }

  const { itemsParent } = useFilterStyles({ pr: spacing })

  const filteredLeagues = showUniqueLeaguesByEsport
    ? uniqBy((leagueData) => leagueData?.esport?.slug, leagues)
    : leagues

  filteredLeagues.sort((a, b) => {
    const leagueAEsportName = a?.esport?.name || ''
    const leagueBEsportName = b?.esport?.name || ''
    return leagueAEsportName > leagueBEsportName ? 1 : -1
  })

  return (
    <Box className={itemsParent} display="flex" flexDirection="row">
      {showCompetitionModel && (
        <Box className="item">
          <SelectObject<typeof selectedCompetitionModel>
            data-testid="LeagueFilter__CompetitionModelSelect"
            option={selectedCompetitionModel}
            options={competitionModels}
            renderValue={(m): string => m?.name ?? 'n/a'}
            setOption={(m): void => setCompetitionModel(m?.id ?? '')}
          />
        </Box>
      )}
      {showMetaseasons && (
        <Box className="item">
          <SelectObject<typeof selectedMetaseason>
            data-testid="LeagueFilter__MetaseasonSelect"
            option={selectedMetaseason}
            options={metaseasonOptions || metaseasons}
            renderValue={(m): string => m?.name ?? 'n/a'}
            setOption={(m): void => setMetaseason(m?.id ?? '')}
          />
        </Box>
      )}
      {showPhases && (
        <Box className="item">
          <SelectObject<typeof selectedPhase>
            data-testid="LeagueFilter__PhaseSelect"
            option={selectedPhase}
            options={phases}
            renderValue={(p): string => p?.name ?? 'n/a'}
            setOption={(p): void => setPhase(p?.id ?? '')}
          />
        </Box>
      )}
      {showLeagues && (
        <Box className="item" data-cy="filter">
          <SelectObject<typeof selectedLeague>
            data-cy="leagueSelect"
            data-testid="LeagueFilter__LeagueSelect"
            option={selectedLeague}
            options={filteredLeagues}
            renderValue={(renderedLeague): string => {
              let displayVal = 'n/a'

              if (displayLeagueNames) {
                displayVal =
                  (renderedLeague as MinimalLeague & { displayName: string })
                    .displayName || renderedLeague.name
              } else if (renderedLeague.esport && renderedLeague.esport.name) {
                displayVal = renderedLeague.esport.name
              }
              return displayVal
            }}
            setOption={(l): void => setLeague(l?.id ?? '')}
          />
        </Box>
      )}
      {showTeams && (
        <Box className="item">
          <SelectObject<typeof selectedTeam>
            data-testid="LeagueFilter__TeamSelect"
            option={selectedTeam}
            options={teams}
            renderValue={(t): string => t?.name ?? 'n/a'}
            setOption={(t): void => setTeam(t?.id ?? '')}
          />
        </Box>
      )}
    </Box>
  )
}
