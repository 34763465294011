import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'

import { NxHeaderCard } from '@playvs-inc/nexus-components'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { StepType, useTabNotification } from '@plvs/utils'
import { useMatchAssistantContext } from './useMatchAssistant'
import { MatchAssistantIntro } from './steps/general/intro/MatchAssistantIntro'
import { useMatchAssistantAdapterSteps } from './matchAssistantAdapterSteps'
import { useMatchAssistantStepAlertsContext } from './hooks/useMatchAssistantStepAlerts'
import { useMatchAssistantHelper } from './MatchAssistantHelper'

export const MatchAssistant: React.FC = () => {
  const {
    currentStepLoading,
    step,
    shouldRenderIntro,
    shouldRenderMatchAssistant,
    esportSlug,
    currentStep,
    stepIndex,
    isMyTurn,
  } = useMatchAssistantContext()

  const { assistantSteps } = useMatchAssistantAdapterSteps(esportSlug)
  const {
    renderStepAlert,
    getStepAlerts,
  } = useMatchAssistantStepAlertsContext()
  const {
    openHelper,
    showHelper,
    alertData: helperAlertData,
  } = useMatchAssistantHelper()

  const [setTabNotification, clearTabNotification] = useTabNotification()

  useEffect(
    function addListeners() {
      const changeTabTitle = (): void => {
        // Flash tab title when the current step isn't submitted.
        // Ignore the Report step though because they'll be
        // playing their game.
        if (
          document.hidden &&
          isMyTurn &&
          currentStep?.step?.submitted === false &&
          step !== StepType.Report
        ) {
          setTabNotification('(Match) Your turn!')
        } else {
          clearTabNotification()
        }
      }

      window.addEventListener('visibilitychange', changeTabTitle)

      return (): void => {
        window.removeEventListener('visibilitychange', changeTabTitle)
      }
    },
    [step, currentStep?.step?.submitted === false, isMyTurn]
  )

  if (!shouldRenderMatchAssistant) {
    return <></>
  }

  const content = assistantSteps[step]
  const stepData = content?.[stepIndex]
  const stepAlertsData = getStepAlerts()

  // Fallback for invalid/unsupported steps.
  if (shouldRenderIntro || (step && (!content || !stepData))) {
    return (
      <NxHeaderCard
        actions={stepData?.actions}
        alertData={showHelper ? helperAlertData : stepAlertsData}
        showAlert={showHelper || renderStepAlert}
        sideContentAction={openHelper}
        subtitle={stepData?.subtitle}
        title={stepData?.title ?? ''}
      >
        <MatchAssistantIntro />
      </NxHeaderCard>
    )
  }

  return (
    <Box className="shepherd-match-assistant" flex={1} minWidth="50%">
      <WaitTillLoaded
        loading={currentStepLoading || !currentStep}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        <NxHeaderCard
          actions={stepData?.actions}
          alertData={showHelper ? helperAlertData : stepAlertsData}
          showAlert={showHelper || renderStepAlert}
          sideContentAction={openHelper}
          subtitle={stepData?.subtitle}
          title={stepData?.title ?? ''}
        >
          <Box py={1}>{stepData?.step}</Box>
        </NxHeaderCard>
      </WaitTillLoaded>
    </Box>
  )
}
