import { ProductType } from '@plvs/client-data/models/ProductType'
import { Path } from '@plvs/const'
import {
  ScheduleComponentDetailsProps,
  ScheduleComponentDetailsReturn,
} from '../types/scheduleController.types'

export const determineScheduleRender = ({
  productType,
}: ScheduleComponentDetailsProps): ScheduleComponentDetailsReturn => {
  const isInStadium = productType === ProductType.Stadium

  if (isInStadium) {
    return {
      emptyMatchesButtonTitle: 'Explore Events',
      emptyMatchesButtonPath: Path.Dashboard,
      emptyMatchesSubtitleCopyPartial: 'You are not a part of any events',
    }
  }

  return {
    emptyMatchesButtonTitle: 'Explore Leagues',
    emptyMatchesButtonPath: Path.Explore,
    emptyMatchesSubtitleCopyPartial: 'You are not a part of any leagues',
  }
}
