import { Link, makeStyles } from '@material-ui/core'
import React from 'react'

import { AvatarCircle } from '@plvs/rally/components/avatar'
import { Attachment } from './attachHelpers'

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  filename: {
    marginRight: 10,
  },
})

export const DisplayAttachedScreenshot: React.FC<{
  attachment: Attachment
}> = ({ attachment }) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.wrapper}
      href={attachment.signedUrl ?? '#'}
      target="_blank"
    >
      {attachment.signedUrl && (
        <div style={{ marginRight: 24 }}>
          <AvatarCircle
            avatarUrl={attachment.signedUrl}
            shape="square"
            size="medium"
          />
        </div>
      )}
      <div className={classes.filename}>{attachment.fileName}</div>
    </Link>
  )
}
