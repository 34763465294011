import { Metaseason, CompetitionGroup, Season } from '@plvs/graphql'
import { head, last } from 'ramda'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { RelativeTiming, sortByStartsAt } from '../timeUtils'

dayjs.extend(isBetween)

export type MetaseasonArg = Pick<
  Metaseason,
  'id' | 'startsAt' | 'endsAt' | 'name' | 'isPromoted'
> & { isInitialMetaseason?: boolean }

export type PromotedMetaseason = Pick<Metaseason, 'isPromoted'>

export interface NameRequired {
  name: string
}

export type MinimalMetaseason = Pick<
  Metaseason,
  'id' | 'name' | 'startsAt' | 'endsAt' | 'isPromoted'
> & { timing: RelativeTiming } & NameRequired & {
    competitionGroup?: CompetitionGroup | null
  } & { seasons?: Pick<Season, 'leagueId' | 'status'>[] } & {
    isInitialMetaseason?: boolean
  }

// utils

export function sortMetaseasonTimelineBySuccession(
  timeline: Metaseason[]
): Metaseason[] {
  if (!timeline) return []
  let result: Metaseason[] = []
  const metasasonLookup: Record<string, Metaseason> = timeline.reduce(
    (accumulator, current) => ({ ...accumulator, [current.id]: current }),
    {}
  )
  const dedupedMetaseasons: Metaseason[] = Object.values(metasasonLookup)
  const firstMetaseason = dedupedMetaseasons.find(
    (metaseason) =>
      metaseason.previousMetaseasonId &&
      !metasasonLookup[metaseason.previousMetaseasonId]
  )
  let currentMetaseason = firstMetaseason
  while (currentMetaseason) {
    result = result.concat([currentMetaseason])
    if (!currentMetaseason.nextMetaseasonId) break
    currentMetaseason = metasasonLookup[currentMetaseason.nextMetaseasonId]
    if (result.length > dedupedMetaseasons.length) {
      throw new Error('Circular timeline.')
    }
  }
  return result
}

export function generateNameFromFirstAndLastMetaseason(
  metaseasons: Metaseason[]
): string {
  if (!metaseasons.length) return ''
  if (metaseasons.length === 1) return head(metaseasons)?.name || ''
  // return only spring if that is the only season available
  if (head(metaseasons)?.name?.includes('Spring'))
    return head(metaseasons)?.name || ''
  // return fall and spring if fall is also available
  return `${head(metaseasons)?.name} - ${last(metaseasons)?.name}`
}

export const getCurrentMetaseasonId = (
  metaseasons: Pick<Metaseason, 'id' | 'startsAt' | 'endsAt' | 'isPromoted'>[]
): string | undefined => {
  const now = dayjs()

  const currentMetaseason = metaseasons
    .filter((metaseason) => {
      const isCurrentSeason = dayjs(now).isBetween(
        dayjs(metaseason?.startsAt),
        dayjs(metaseason?.endsAt)
      )
      return isCurrentSeason
    })
    .pop()

  const promotedMetaseason = metaseasons
    .filter((metaseason) => metaseason?.isPromoted)
    .pop()

  const latestMetaseason = sortByStartsAt(
    (metaseasons as { startsAt: string; id: string }[]) ?? []
  ).pop()

  return currentMetaseason?.id || promotedMetaseason?.id || latestMetaseason?.id
}

export const mapMetaseasonTimingToRelative = (
  metaseason: MetaseasonArg
): MinimalMetaseason => {
  const now = dayjs()

  let timing = RelativeTiming.Present
  if (now.isAfter(dayjs(metaseason.endsAt ?? ''))) {
    timing = RelativeTiming.Past
  }
  if (now.isBefore(dayjs(metaseason.startsAt ?? ''))) {
    timing = RelativeTiming.Future
  }
  return {
    ...metaseason,
    name: metaseason.name ?? 'n/a',
    timing,
  }
}

export const mapMetaseasonsForFilters = <T extends MetaseasonArg>({
  metaseasons,
}: {
  metaseasons: T[]
}): MinimalMetaseason[] => {
  return metaseasons
    .map(mapMetaseasonTimingToRelative)
    .sort((a, b) => dayjs(a?.startsAt).unix() - dayjs(b?.startsAt).unix())
}

export const findPromotedMetaseason = <T extends PromotedMetaseason>({
  metaseasons,
}: {
  metaseasons: T[]
}): T | undefined => metaseasons.find((metaseason) => !!metaseason?.isPromoted)
