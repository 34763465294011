import React, { useState } from 'react'
import { Box, Button, makeStyles, useTheme } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import { Close } from '@playvs-inc/nexus-icons'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  ArenaLightLargePrimary,
  ArenaDarkLargePrimary,
} from '@playvs-inc/nexus-spots'

import * as analytics from '@plvs/respawn/features/analytics'
import { EsportSlug } from '@plvs/graphql/types'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Path } from '@plvs/const'
import { Alert } from '@plvs/respawn/features/alert/Alert'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    minWidth: '0px',
    padding: '0px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '12px',
    right: '16px',
  },
  container: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: mobile ? 'column' : 'row',
    alignItems: mobile ? 'center' : 'normal',
  }),
  iconWrapper: {
    marginRight: theme.spacing(2),
    alignSelf: 'center',
  },
  textBody: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    width: mobile ? '100%' : '75%',
  }),
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  textContainer: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    marginBottom: mobile ? theme.spacing(1) : '0',
    textAlign: mobile ? 'center' : 'initial',
  }),
  nowrap: {
    whiteSpace: 'nowrap',
  },
  '@global': {
    '.MuiButtonBase-root > p': {
      whiteSpace: 'nowrap',
    },
  },
}))

interface MatchQueueBannerProps {
  esportSlug: EsportSlug | null
  teamId: string
  title: string
  showButton: boolean
  subtitle: string
  isPreseason: boolean
  slotId?: string
}

export const MatchQueueBanner: React.FC<MatchQueueBannerProps> = ({
  esportSlug,
  teamId,
  title,
  showButton,
  subtitle,
  isPreseason,
}) => {
  const { userId } = useUserIdentityFn()
  const navigate = useNavigate()

  const theme = useTheme()
  const mobile = useBreakpointSm()
  const {
    iconWrapper,
    titleWrapper,
    closeButton,
    container,
    textBody,
    textContainer,
    nowrap,
  } = useStyles({ mobile })

  const [isHidden, setHidden] = useState(false)

  const handleClick = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.stopPropagation()
    analytics.tryMatchQueueButtonClicked({
      timeStamp: new Date().toDateString(),
      userId: userId ?? '',
      teamId,
    })

    if (isPreseason) {
      navigate(Path.Schedule)
    } else {
      navigate(`/queue/${teamId}/${esportSlug}`)
    }
  }

  const isLightMode = theme.palette.type === MUIThemeModeEnum.Light

  return isHidden ? (
    <></>
  ) : (
    <Alert>
      <>
        <Box className={container}>
          <Box className={iconWrapper}>
            {isLightMode ? (
              <ArenaLightLargePrimary height="56px" width="56px" />
            ) : (
              <ArenaDarkLargePrimary height="56px" width="56px" />
            )}
          </Box>
          <Box className={textContainer} flexGrow="1">
            <NxTypography className={titleWrapper} variant="h4">
              {title}
            </NxTypography>
            <NxTypography className={textBody} variant="body1">
              {subtitle}
            </NxTypography>
          </Box>
          <Box alignItems="center" display="flex">
            {showButton && (
              <NxButton
                className={nowrap}
                data-cy="findMatch"
                label="Find a Match"
                onClick={handleClick}
                variant="primary"
              />
            )}
          </Box>
        </Box>
        <Button
          className={closeButton}
          onClick={(): void => {
            setHidden(true)
          }}
        >
          <Close color={theme.palette.ColorIconBase} />
        </Button>
      </>
    </Alert>
  )
}
