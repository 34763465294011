import { ApolloQueryResult, makeVar, ReactiveVar } from '@apollo/client'
import {
  CompetitionGroup,
  SelectedOrganizationQueryVariables,
  SelectedOrganizationQuery,
} from '@plvs/graphql'

export type SelectedOrganizationInterface = {
  refetch?: (
    variables?: Partial<SelectedOrganizationQueryVariables>
  ) => Promise<ApolloQueryResult<SelectedOrganizationQuery>>
  id: string
  competitionGroup: CompetitionGroup | undefined | null
  country: string | undefined | null
  loading: boolean
  error: Error | undefined
}

export const selectedOrganizationVar: ReactiveVar<SelectedOrganizationInterface> = makeVar<
  SelectedOrganizationInterface
>({
  id: '',
  competitionGroup: null,
  country: '',
  error: undefined,
  loading: false,
  refetch: undefined,
})

export const useSelectedOrganizationIdVar: ReactiveVar<
  string | undefined
> = makeVar<string | undefined>(undefined)
