// This component is just a canvas HTMl element
// Cannot be effectively unit tested.
/* istanbul ignore file */

import React, { useEffect, memo } from 'react'
import AwardTemplate from '@plvs/rally/assets/awards/award-template.jpg'

const getFont = (nameSize: number): string => {
  if (nameSize < 22) {
    return '74px Bebas Neue'
  }

  if (nameSize >= 22 && nameSize <= 33) {
    return '52px Bebas Neue'
  }

  return '40px Bebas Neue'
}

const CANVAS_WIDTH = 1200
const CANVAS_HEIGHT = 675

interface Props {
  name: string
  schoolName: string
  weekString: string
}

/**
 * To view the canvas for editing purposes, comment out display: 'none'
 */

export const AwardsCanvas: React.FC<Props> = memo(
  ({ name, schoolName, weekString }) => {
    useEffect(() => {
      const canvas = document.getElementById('awardCanvas') as HTMLCanvasElement
      const ctx = canvas?.getContext('2d') as any

      const awardImg = new Image()
      awardImg.crossOrigin = 'anonymous'
      awardImg.src = AwardTemplate

      awardImg.onload = (): void => {
        if (ctx) {
          ctx.drawImage(awardImg, 0, 0)

          // Award Name
          ctx.font = getFont(name.length)
          ctx.fillStyle = 'white'
          ctx.textAlign = 'center'
          ctx.letterSpacing = '0.065em'
          const uppercaseName = name.toUpperCase()
          ctx.fillText(
            `${uppercaseName}`,
            CANVAS_WIDTH / 2,
            CANVAS_HEIGHT / 1.35
          )

          ctx.font = 'bold 20px TT Commons'
          ctx.fillStyle = 'white'
          ctx.letterSpacing = '0.2em'
          const uppercaseSchoolName = schoolName.toUpperCase()
          ctx.fillText(
            `${uppercaseSchoolName}`,
            CANVAS_WIDTH / 2,
            CANVAS_HEIGHT / 1.2
          )

          // Award Week
          ctx.font = 'bold 20px TT Commons'
          ctx.fillStyle = '#3D4143'
          ctx.letterSpacing = '0.155em'
          ctx.fillText(`${weekString}`, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4.3)
        }
      }
    }, [])

    return (
      <canvas
        height={`${CANVAS_HEIGHT}px`}
        id="awardCanvas"
        style={{ display: 'none' }}
        width={`${CANVAS_WIDTH}px`}
      />
    )
  }
)
