import React from 'react'
import { Box } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { cleanGraphQLError, formErrorToString } from '@plvs/utils'

interface ErrorMessageProps {
  error: string | undefined
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <Box mt={2} textAlign="center">
      <NxTypography
        colorToken="ColorTextError"
        data-testid="registrationErrorMessage"
        variant="body3"
      >
        {cleanGraphQLError(formErrorToString(error) || '')}
      </NxTypography>
    </Box>
  )
}
