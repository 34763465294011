import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'

import {
  MatchAssistantAlertType,
  PlayerSelection,
  createPlayerObject,
} from '@plvs/utils'
import { GameAssistantSelection } from '@plvs/graphql'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { useStyles } from '../../../MatchAssistant.styles'
import {
  mapTeamRoster,
  useMatchAssistantContext,
} from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'
import { PlayersMatchUp } from '../../../PlayersMatchUp'

interface MatchPreviewProps {
  onNext?(): void
  children?: React.ReactNode
  homeTeamPick: GameAssistantSelection | undefined
  awayTeamPick: GameAssistantSelection | undefined
  customCenter?: React.ReactNode
  showOverlayImage?: boolean
}

export const MatchPreview: React.FC<MatchPreviewProps> = ({
  onNext = (): void => {},
  children,
  homeTeamPick,
  awayTeamPick,
  customCenter,
  showOverlayImage,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const {
    homeTeam,
    awayTeam,
    gameOrdinal,
    esportSlug,
  } = useMatchAssistantContext()

  const {
    match: { canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const isNextDisabled = canSpectate
  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  const homePlayerObj: PlayerSelection = createPlayerObject({
    team: { name: homeTeam?.name, logoUrl: homeTeam?.avatarUrl },
    selectionOption: homeTeamPick?.selectionOption,
  })

  const awayPlayerObj: PlayerSelection = createPlayerObject({
    team: { name: awayTeam?.name, logoUrl: awayTeam?.avatarUrl },
    selectionOption: awayTeamPick?.selectionOption,
  })

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        justifyContent="center"
        width="100%"
      >
        <NxTypography variant="h1">Match Preview</NxTypography>

        <NxTypography variant="overline">Teams</NxTypography>

        <SelectedCharacters
          awayPlayer={awayPlayerObj}
          customCenter={customCenter}
          esportSlug={esportSlug}
          homePlayer={homePlayerObj}
          showOverlayImage={showOverlayImage}
        />

        {children}

        <NxTypography variant="overline">Players</NxTypography>

        <PlayersMatchUp
          currentPlayersAwayTeam={mapTeamRoster({
            teamRoster: awayTeam?.starters ?? [],
            teamName: awayTeam?.name ?? '',
          })}
          currentPlayersHomeTeam={mapTeamRoster({
            teamName: homeTeam?.name ?? '',
            teamRoster: homeTeam?.starters ?? [],
          })}
        />
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={`Go play game ${gameOrdinal} and come back to report the results.`}
        />

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={stepAlertsData.actionLoading}
            fullWidth
            label="Change Roster"
            loading={stepAlertsData.actionLoading}
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            variant="secondary"
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Report Results"
            onClick={onNext}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
