import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
} from '@playvs-inc/nexus-components'

import {
  MatchAssistantUserFragment,
  useGetCurrentSeriesPlayerSelectionsByMatchIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import { FirebobMessage, PlayerSelection } from '@plvs/utils'
import { logger } from '@plvs/rally/logging'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { useStyles } from './PlayerSelect.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'

export const PlayerSelect: React.FC = () => {
  const {
    currentStep,
    teamId: myTeamId = '',
    match,
    isMyTeamHome,
    isMyTurn,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    opposingTeamId,
    opposingTeam,
    myTeam,
    gameOrdinal,
    seriesOrdinal,
    esportSlug,
  } = useMatchAssistantContext()
  const { id: stepId = '' } = currentStep?.step ?? {}

  const {
    match: { canParticipate, canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const styles = useStyles()

  const {
    data: playerData,
    loading: playerDataLoading,
    refetch: refetchGetCurrentSeriesPlayerSelectionsByMatchId,
  } = useGetCurrentSeriesPlayerSelectionsByMatchIdQuery({
    variables: {
      matchId: match?.id ?? '',
      teamId: myTeamId,
    },
    skip: !match?.id || !myTeamId,
  })

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions =
    playerData?.currentSeriesPlayerSelectionsByMatchId.players

  const [selectedPlayer, setSelectedPlayer] = useState<
    MatchAssistantUserFragment
  >()

  const isNextDisabled = !selectedPlayer || canSpectate || updateLoading

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    value: MatchAssistantUserFragment | null
  ): void => {
    setSelectedPlayer(value || undefined)
  }

  const handleNext = async (): Promise<void> => {
    if (isNextDisabled) {
      return
    }

    setActionsTakenInSteps({
      ...actionsTakenInSteps,
      [stepId]: new Date().toISOString(),
    })

    logger.debug('[PlayerSelect | handleNext]', selectedPlayer)

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId,
            selectionOptions: [
              {
                userId: selectedPlayer.id ?? '',
              },
            ],
          },
        },
      })
    } catch (err) {
      logger.error(err)
    }
  }

  useEffect(
    function clearSelection() {
      setSelectedPlayer(undefined)
      refetchGetCurrentSeriesPlayerSelectionsByMatchId()
    },
    [currentStep?.step?.id]
  )

  const isWaitingForOpponent =
    canParticipate && (currentStep?.step?.submitted || !isMyTurn)
  const isSelectDisabled =
    canSpectate ||
    updateLoading ||
    isWaitingForOpponent ||
    currentStep?.step?.submitted

  let firebobMessage = isWaitingForOpponent
    ? 'Waiting on opponent'
    : `It's your turn to select a player`

  if (canSpectate) {
    firebobMessage = FirebobMessage.Spectator
  }

  const myPlayerPick = currentStep?.playerPicks?.find(
    (pick) => pick.gameAssistantStep?.teamId === myTeamId
  )
  const opposingPlayerPick = currentStep?.playerPicks?.find(
    (pick) => pick.gameAssistantStep?.teamId === opposingTeamId
  )
  const myPlayer = myTeam?.roster?.find(
    (user) => user.id === myPlayerPick?.userId
  )
  const opposingPlayer = opposingTeam?.roster?.find(
    (user) => user.id === opposingPlayerPick?.userId
  )
  const opposingPlayerObj: PlayerSelection | undefined = opposingPlayer
    ? {
        id: opposingPlayer.id ?? '',
        name: opposingPlayer.name ?? '',
        avatarUrl: opposingPlayer.avatarUrl ?? '',
        selection: undefined,
        useAvatar: true,
      }
    : undefined
  const selectedPlayerObj: PlayerSelection = myPlayer
    ? {
        id: myPlayer.id ?? '',
        name: myPlayer.name ?? '',
        avatarUrl: myPlayer.avatarUrl ?? '',
        selection: undefined,
        useAvatar: true,
      }
    : {
        id: selectedPlayer?.id ?? '',
        name: selectedPlayer?.name ?? '',
        avatarUrl: selectedPlayer?.avatarUrl ?? '',
        selection: undefined,
        useAvatar: true,
      }

  const isFirstGame =
    gameOrdinal === 1 && (!seriesOrdinal || seriesOrdinal === 1)

  return (
    <WaitTillLoaded
      loading={playerDataLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Autocomplete
        className={styles.autocomplete}
        data-cy="player-select"
        disabled={isSelectDisabled}
        getOptionLabel={(option): string => option.name ?? ''}
        onChange={handleChange}
        options={selectionOptions ?? []}
        renderInput={(params): React.ReactElement => (
          <TextField
            {...params}
            label={isFirstGame ? 'Who will go first?' : 'Who is next?'}
            variant="outlined"
          />
        )}
        renderOption={(params): React.ReactElement => {
          return (
            <NxTypography variant="body1">{params.name ?? ''}</NxTypography>
          )
        }}
      />

      <SelectedCharacters
        awayPlayer={!isMyTeamHome ? selectedPlayerObj : opposingPlayerObj}
        esportSlug={esportSlug}
        homePlayer={isMyTeamHome ? selectedPlayerObj : opposingPlayerObj}
      />

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {canParticipate && (
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Confirm Your Player"
            loading={isWaitingForOpponent || updateLoading}
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </WaitTillLoaded>
  )
}
