import React from 'react'

import {
  BracketResult,
  BracketType,
} from '@plvs/rally/features/standings/standingsHelpers'
import { BracketResultPairSingle } from './BracketResultPairSingle'
import { BracketResultPairDouble } from './BracketResultPairDouble'

export const BracketResultPairs: React.FC<{
  slotIndex: number
  bracketResults: BracketResult[]
  bracketType: BracketType
  toMatch?(matchId: string): string
  slotsLength?: number
}> = ({ slotIndex, bracketResults, bracketType, toMatch, slotsLength }) => {
  const numBracketResults = bracketResults.length
  const isFirstSlot = slotIndex === 0
  const nodes: React.ReactElement[] = []

  if (numBracketResults === 0) {
    // do nothing
  } else if (numBracketResults === 1) {
    nodes.push(
      <BracketResultPairSingle
        key={`slot-${slotIndex}-team-${0}`}
        bracketResults={bracketResults}
        isFirstSlot={isFirstSlot}
        slotIndex={slotIndex}
        slotsLength={slotsLength}
      />
    )
  } else {
    for (let teamIndex = 0; teamIndex < numBracketResults; teamIndex += 2) {
      nodes.push(
        <BracketResultPairDouble
          key={`slot-${slotIndex}-pair-${teamIndex / 2}`}
          bracketResults={bracketResults}
          bracketType={bracketType}
          isFirstSlot={isFirstSlot}
          numBracketResults={numBracketResults}
          podIndex={teamIndex / 2}
          slotIndex={slotIndex}
          slotsLength={slotsLength}
          teamIndex={teamIndex}
          toMatch={toMatch}
        />
      )
    }
  }

  return <>{nodes}</>
}
