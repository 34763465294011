import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Param, Path } from '@plvs/const'

export const ReplaceWithLogin = (): React.ReactElement => {
  const location = useLocation()
  // tell the app where to send the user after login, with a `next` param and
  //  take care to maintain the existing params

  const urlSearchParams = new URLSearchParams(location.search)
  if (location.pathname !== Path.Login) {
    // ex.
    // START = /settings?utm_source=facebook
    // END   = /login?utm_source=facebook&next=/settings
    urlSearchParams.append(Param.Next, location.pathname)
  }

  return (
    <Navigate
      replace
      to={{
        pathname: Path.Login,
        search: urlSearchParams.toString(),
      }}
    />
  )
}
