import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import { NxIconButton, NxTooltip } from '@playvs-inc/nexus-components'
import { ScrimmageOutlined } from '@playvs-inc/nexus-icons'

import { MatchCardFooter } from '@plvs/respawn/features/match/MatchCardFooter'
import { isCoachForResource } from '@plvs/utils'
import { EsportSlug } from '@plvs/graphql/types'
import { Path } from '@plvs/const'
import { UserRole } from './RosterProvider.types'

const useStyles = makeStyles(() => ({
  tooltip: {
    width: '100%',
  },
}))

export interface ScrimmageReadyProps {
  userRoles: UserRole[]
  teamId: string
  esportSlug: EsportSlug
}

export const ScrimmageReady: React.FC<ScrimmageReadyProps> = ({
  userRoles,
  teamId,
  esportSlug,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isCoachForTheTeam = isCoachForResource(userRoles, [teamId])
  const disableScrimmageButton = !isCoachForTheTeam
  const disabledScrimmageButtonMessage = disableScrimmageButton
    ? 'Only coaches can create scrimmages.'
    : ''

  const handleReroute = (): void => {
    navigate(`${Path.CreateScrimmage}?e=${esportSlug}&t=${teamId}`)
  }

  return (
    <MatchCardFooter data-testid="ScrimmageReady_MatchCardFooter">
      <NxTooltip
        className={classes.tooltip}
        title={disabledScrimmageButtonMessage}
      >
        <div>
          <NxIconButton
            data-testid="ScrimmageReady_Button"
            disabled={disableScrimmageButton}
            fullWidth
            icon={<ScrimmageOutlined />}
            label="Create Scrimmage"
            onClick={handleReroute}
            variant="secondary"
          />
        </div>
      </NxTooltip>
    </MatchCardFooter>
  )
}
