import React from 'react'
import { Profile } from '@plvs/rally/features/profile/Profile'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { PublicPage, AppPage } from '@plvs/rally/pages/page'
import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { Box, FullHeightBox, Hidden } from '@plvs/respawn/features/layout'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

export const ProfilePage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const { pathname: path } = useLocation()

  const isPublic = !path.includes('/app')

  const { userId: userIdFromHook, loading } = useUserIdentityFn()

  // if logged in already, redirect users back to /app url
  if (isPublic && userIdFromHook && !loading) {
    const prependApp = `${Path.App}${path}`

    return <Navigate to={prependApp} />
  }

  return (
    <>
      {isPublic ? (
        <PublicPage description="Public Profile Page" title="Profile">
          <Box display="flex" justifyContent="center">
            <Hidden smDown>
              <RallyAppDrawer isPublic />
            </Hidden>
            <FullHeightBox maxWidth="1042px">
              <MySchoolLeagues shouldUseEligibleMetaseasons={false}>
                <Profile userId={userId ?? ''} />
              </MySchoolLeagues>
            </FullHeightBox>
          </Box>
        </PublicPage>
      ) : (
        <AppPage title="Profile">
          <Profile userId={userId ?? ''} />
        </AppPage>
      )}
    </>
  )
}
