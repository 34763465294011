import React, { useState } from 'react'

import { NxButton, NxHeaderCardAlertProps } from '@playvs-inc/nexus-components'

import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { matchAssistantHelperClicked } from '@plvs/respawn/features/analytics/segment/segment'
import { useMatchAssistantContext } from './useMatchAssistant'

interface MatchAssistantHelper {
  showHelper: boolean
  openHelper(): void
  closeHelper(): void
  alertData: NxHeaderCardAlertProps
}

export const useMatchAssistantHelper = (): MatchAssistantHelper => {
  const {
    esportSlug,
    matchId,
    gameOrdinal = 1,
    seriesOrdinal,
    stepOrdinal = 1,
  } = useMatchAssistantContext()
  const {
    name,
    rulebookArticleId,
    matchAssistantVideoSrc,
  } = useGeneralEsportAdapter(esportSlug)

  const [showHelper, setShowHelper] = useState(false)

  const openHelper = (): void => {
    matchAssistantHelperClicked({
      matchId,
      gameOrdinal,
      seriesOrdinal,
      stepOrdinal,
    })
    setShowHelper(true)
  }

  const closeHelper = (): void => {
    setShowHelper(false)
  }

  const openRulebook = (): void => {
    showIntercomArticle(rulebookArticleId)
  }

  const helperAlertData: NxHeaderCardAlertProps = {
    title: 'Match Assistant Tutorial',
    onClose: closeHelper,
    content: (
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        frameBorder="0"
        height="315"
        src={matchAssistantVideoSrc}
        title="Match Assistant Tutorial"
        width="100%"
      />
    ),
    actions: (
      <>
        <NxButton
          label={`${name} Rulebook`}
          onClick={openRulebook}
          variant="text"
        />
        <NxButton label="Close" onClick={closeHelper} variant="primary" />
      </>
    ),
  }

  return {
    alertData: helperAlertData,
    closeHelper,
    showHelper,
    openHelper,
  }
}
