import { Button, DialogContent, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import React, { useState, useRef } from 'react'
import { cleanGraphQLError, useAutoskipQuery } from '@plvs/utils'
import { FlexSpacer, WaitTillLoaded, Box } from '@plvs/respawn/features/layout'
import {
  Metaseason,
  useDeactivateTeamFromLeagueMutation,
  useGetTeamUnenrollInfoQuery,
} from '@plvs/graphql'
import { NxAlert, NxTypography } from '@playvs-inc/nexus-components'
import { head } from 'ramda'
import { ApolloError } from '@apollo/client'
import { DialogHeader } from '../dialog/DialogHeader'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.ColorTextAlt2,
  },
  cancelButton: {
    '&.MuiButton-root': {
      color: theme.palette.ColorTextAlt2,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  unenrollButton: {
    backgroundColor: theme.palette.ColorTextError,
    color: theme.palette.common.white,
  },
}))

interface UnenrollTeamProps {
  onClose(): void
  id: string
  onSuccess?(effectiveAtDate?: string): Promise<void>
  metaseason: Pick<Metaseason, 'id' | 'name'> | undefined
}

export const UnenrollTeam: React.FC<UnenrollTeamProps> = ({
  onClose,
  id,
  onSuccess,
  metaseason,
}) => {
  const timeRef = useRef(dayjs().toISOString())
  const metaseasonId = metaseason?.id ?? ''
  const metaseasonName = metaseason?.name
  const classes = useStyles()
  const [error, setError] = useState<ApolloError | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const { data, loading } = useAutoskipQuery(useGetTeamUnenrollInfoQuery, {
    variables: { id, metaseasonId },
  })

  const teamName = data?.team?.name
  const leagueId = head(data?.team?.leagues ?? [])?.id
  const currentTeamSeason = data?.team?.enrolledSeasons?.filter(
    (season) => season.metaseasonId === metaseasonId
  )
  const teamRegistrationEndsAt = currentTeamSeason
    ? head(currentTeamSeason)?.teamRegistrationEndsAt
    : ''

  // mutate
  const [mutate, { loading: isMutating }] = useDeactivateTeamFromLeagueMutation(
    {
      onCompleted: async (): Promise<void> => {
        await onSuccess?.(timeRef.current)
      },
    }
  )

  const onClick = async (): Promise<void> => {
    try {
      if (!leagueId || !metaseasonId) {
        throw new ApolloError({
          errorMessage: 'Unable to gather team information',
        })
      }
      const result = await mutate({
        variables: {
          teamId: id,
          leagueId,
          metaseasonId,
        },
      })

      const success = result?.data?.removeTeamFromLeague?.success
      if (success) {
        onClose()
        enqueueSnackbar(
          `${teamName ?? 'The team'} is unenrolled for ${
            metaseasonName ?? 'the season'
          }`,
          {
            variant: 'success',
          }
        )
      } else {
        throw new ApolloError({
          errorMessage: 'Unable to unenroll team',
        })
      }
    } catch (e: any) {
      setError(e)
    }
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box maxWidth={480}>
        <Box pb={errorMessage ? 0 : 2}>
          <DialogHeader
            subtitle={`Unenrolling this team will remove them from being scheduled for matches. If you unenroll this team, you will have until ${
              teamRegistrationEndsAt === ''
                ? dayjs(teamRegistrationEndsAt).format('MMMM Do YYYY, hh:mmA')
                : `the registration deadline`
            } to re-enroll them.`}
            subtitleClassName={classes.subtitle}
            title={`Unenroll ${teamName ?? 'this team'} for ${
              metaseasonName ?? 'the season'
            }?`}
          />
        </Box>
        <DialogContent>
          {errorMessage && (
            <Box pb={3}>
              <NxAlert
                canClose
                content={
                  <NxTypography
                    colorToken="ColorTextAlt2"
                    data-testid="Banner_Subtitle"
                    variant="body1"
                  >
                    {errorMessage}
                  </NxTypography>
                }
                title={
                  <NxTypography data-testid="Banner_Title" variant="h4">
                    Unable to change team name
                  </NxTypography>
                }
                variant="error"
              />
            </Box>
          )}
          <Box display="flex">
            <Button className={classes.cancelButton} onClick={onClose}>
              Cancel
            </Button>
            <FlexSpacer />
            <Button
              className={classes.unenrollButton}
              disabled={isMutating}
              onClick={onClick}
              variant="contained"
            >
              Unenroll Team
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </WaitTillLoaded>
  )
}
