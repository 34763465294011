import { ProductType } from '@plvs/client-data/models/ProductType'
import {
  isCaptainForResource,
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  isTeamOwnerForResource as isTeamOwnerForResourceFn,
} from '@plvs/utils'
import {
  RosterCardComponentProps,
  DeleteTeamType,
  RosterCardComponentReturn,
  RosterRowItemRenderProps,
  RosterRowItemRenderReturn,
} from '../types/rosterCardController.types'

export const determineRosterCardRender = ({
  productType,
  isInMatchAssistantContainer,
  isTeamOwnerForResource,
}: RosterCardComponentProps): RosterCardComponentReturn => {
  const isInStadium = productType === ProductType.Stadium

  if (isInStadium) {
    const shouldShowInvitePlayer =
      !isInMatchAssistantContainer && isTeamOwnerForResource
    return {
      shouldRenderTeamAvatar: true,
      shouldShowInvitePlayer,
      shouldShowDeleteTeamDisclaimer: false,
      deleteTeamSubtitle: 'This team will be removed from the event.',
      isContactsTabEnabled: false,
      deleteTeamMutation: DeleteTeamType.deleteStadium,
      enableScoutingToTeamsPage: false,
    }
  }

  return {
    shouldRenderTeamAvatar: false,
    shouldShowInvitePlayer: false,
    shouldShowDeleteTeamDisclaimer: true,
    deleteTeamSubtitle: 'This team will be removed from my teams.',
    isContactsTabEnabled: true,
    deleteTeamMutation: DeleteTeamType.deleteScholastic,
    enableScoutingToTeamsPage: true,
  }
}

export const determineRosterRowItemRender = ({
  userRoles,
  competitionGroup,
  teamId,
}: RosterRowItemRenderProps): RosterRowItemRenderReturn => {
  if (!competitionGroup) {
    return {
      shouldRenderCaptainIcon: false,
    }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const isCaptainForTeam = isCaptainForResource(userRoles, [teamId])
    return {
      shouldRenderCaptainIcon: isCaptainForTeam,
    }
  }
  const isTeamOwner = isTeamOwnerForResourceFn(userRoles, [teamId])
  return {
    shouldRenderCaptainIcon: isTeamOwner,
  }
}
