import { Box, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import React from 'react'
import { DrawerMatchList } from './matches'
import { PracticeDayList } from './practiceDays/PracticeDayList'

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.ColorTextAlt,
    marginLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  emptyState: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    color: theme.palette.ColorTextDisabled,
  },
}))

interface DrawerAnnouncementListProps {
  canViewPracticeDay?: boolean
  isCoach?: boolean
}
export const DrawerAnnouncementList: React.FC<DrawerAnnouncementListProps> = ({
  canViewPracticeDay,
  isCoach,
}) => {
  const styles = useStyles()
  const [
    hasPracticeDayAnnouncements,
    setHasPracticeDayAnnouncements,
  ] = React.useState(false)
  const [
    loadingPracticeDayAnnouncements,
    setLoadingPracticeDayAnnouncements,
  ] = React.useState(true)
  const [
    hasUpcomingMatchAnnouncements,
    setHasUpcomingMatchnnouncements,
  ] = React.useState(false)
  const [
    loadingMatchAnnouncements,
    setLoadingMatchAnnouncements,
  ] = React.useState(true)

  const isLoadingAnnouncements =
    loadingPracticeDayAnnouncements || loadingMatchAnnouncements

  const hasAnyAnnouncements =
    hasPracticeDayAnnouncements || hasUpcomingMatchAnnouncements

  return (
    <Box pb={3}>
      <NxTypography className={styles.overline} variant="overline">
        Today
      </NxTypography>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <PracticeDayList
          canView={canViewPracticeDay}
          isCoach={isCoach}
          setHasAnnouncements={setHasPracticeDayAnnouncements}
          setLoadingPracticeDayAnnouncements={
            setLoadingPracticeDayAnnouncements
          }
        />
        <DrawerMatchList
          isCoach={isCoach}
          setHasAnnouncements={setHasUpcomingMatchnnouncements}
          setLoadingMatchAnnouncements={setLoadingMatchAnnouncements}
        />
      </Box>

      <WaitTillLoaded
        loading={isLoadingAnnouncements}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {!hasAnyAnnouncements && (
          <NxTypography className={styles.emptyState} variant="body1">
            No Upcoming Matches
          </NxTypography>
        )}
      </WaitTillLoaded>
    </Box>
  )
}
