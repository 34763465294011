export const updateSkipBillingContactFn = (
  promotedMetaseasonId: string,
  userId: string
): string => {
  try {
    localStorage?.setItem(
      `skipBillingContactVar-${userId}`,
      promotedMetaseasonId
    )
  } catch {
    // Swallow exception in event localStorage API is denied.
  }
  return promotedMetaseasonId
}
