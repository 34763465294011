import React from 'react'

import { NxEmote } from '@playvs-inc/nexus-components'

import {
  Maybe,
  PlayerReplayStatName,
  PlayerReplayStatFragment,
} from '@plvs/graphql'

export function getHasOpposingTeam(data?: PlayerReplayStatFragment): boolean {
  if (!data?.team) {
    return false
  }

  return (
    data?.statName?.toLowerCase() ===
    PlayerReplayStatName.FirstMatchWin.toLowerCase()
  )
}

export function getHasTeam(data?: PlayerReplayStatFragment): boolean {
  return Boolean(data?.team) && !getHasOpposingTeam(data)
}

export const getSlideComponent = (
  name?: Maybe<PlayerReplayStatName>
): string => {
  let casedName
  if (name) {
    casedName = name[0].toUpperCase() + name.substr(1)
  }

  switch (casedName) {
    case PlayerReplayStatName.MatchParticipation:
    case PlayerReplayStatName.NumGamesPlayed:
    case PlayerReplayStatName.HighFunRating:
    case PlayerReplayStatName.LongestWinningStreak:
    case PlayerReplayStatName.FirstMatchWin:
      return 'HorizontalSlide'

    case PlayerReplayStatName.MadePlayoffs:
    case PlayerReplayStatName.WonChampionship:
    case PlayerReplayStatName.PlayVsCupChampion:
    case PlayerReplayStatName.PlayerOfTheWeekAwards:
    case PlayerReplayStatName.EndOfSeasonAwards:
    default:
      return 'VerticalSlide'
  }
}

export function getShareUrl(): string {
  const { href } = window.location
  const url = new URL(href)
  if (!url.searchParams.has('rewind')) {
    url.searchParams.append('rewind', '')
  }
  return url.toString()
}

export function getEmote(value: Maybe<string>): React.ReactElement {
  if (!value) {
    return <></>
  }

  let numValue

  if (value?.includes('%')) {
    // Handle percentages as whole numbers
    numValue = Number(value.slice(0, value.length - 1))

    if (numValue > 85) {
      return <NxEmote emoteKey="fbshock" height={45} width={45} />
    }

    if (numValue > 33) {
      return <NxEmote emoteKey="fbcelebrate" height={45} width={45} />
    }

    if (numValue <= 33) {
      return <NxEmote emoteKey="fbrip" height={45} width={45} />
    }
  } else if (value?.includes('.')) {
    // Value is 1.0-5.0
    numValue = Number(value)

    if (numValue > 4.5) {
      return <NxEmote emoteKey="fbshock" height={45} width={45} />
    }

    if (numValue > 4) {
      return <NxEmote emoteKey="fbhighfive" height={45} width={45} />
    }

    if (numValue > 3) {
      return <NxEmote emoteKey="fblove" height={45} width={45} />
    }

    if (numValue > 2) {
      return <NxEmote emoteKey="fbfan" height={45} width={45} />
    }

    if (numValue <= 1) {
      return <NxEmote emoteKey="fbrip" height={45} width={45} />
    }
  } else {
    numValue = Number(value)

    if (numValue > 30) {
      return <NxEmote emoteKey="fbshock" height={45} width={45} />
    }

    if (numValue > 10) {
      return <NxEmote emoteKey="fbgg" height={45} width={45} />
    }

    if (numValue > 3) {
      return <NxEmote emoteKey="fbfan" height={45} width={45} />
    }

    if (numValue <= 3) {
      return <NxEmote emoteKey="fbrip" height={45} width={45} />
    }
  }

  return <NxEmote emoteKey="fbfan" height={45} width={45} />
}
