import { Cookie, Param, Path } from '@plvs/const'
import { env } from '@plvs/env'
import { Request, Response } from 'express'
import queryString, { StringifiableRecord } from 'query-string'
import { isFunction } from 'ramda-adjunct'
import { useEffect } from 'react'
import {
  Cookie as CookieProp,
  CookieGetOptions,
  CookieSetOptions,
} from 'universal-cookie'
import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import { browserOnly } from './browserUtils'

export type GetCookieFn = (name: Cookie, options?: CookieGetOptions) => string

export type SetCookieFn = (
  name: Cookie,
  value: string,
  options?: CookieSetOptions
) => void

export type RemoveCookieFn = (name: Cookie, options?: CookieSetOptions) => void

// interfaces

export interface CookieProps {
  cookies: CookieProp
}

// consts

const NINETY_DAYS_IN_SECONDS = 24 * 60 * 60 * 90

// utils

// Performs a redirect while maintaining the (ex. ?meow=true) search string on
//  on the end of the original url
export const redirectToPathWhilePreservingQuery = (
  path: string
): ((req: Request, res: Response) => void) => (
  req: Request,
  res: Response
): void => {
  const rqs: string = queryString.stringify(req.query as StringifiableRecord)
  const qs = rqs ? `?${rqs}` : ''
  const newUrl = path + qs
  res.redirect(newUrl)
}

export function getUrlParam<T>(
  location: Window['location'] | RouteComponentProps['location'],
  name: Param
): T | undefined {
  const urlSearchParams = new URLSearchParams(location.search)
  return (urlSearchParams.get(name) || undefined) as T | undefined
}

export const safeGetCookie = (
  obj: GetCookieFn | Record<string, string>,
  name: Cookie
): string => (isFunction(obj) ? obj(name) : obj[name])

export const safeSetCookie = (
  fn: SetCookieFn,
  name: Cookie,
  value: string,
  domain?: string,
  options: CookieSetOptions = {}
): void => {
  if (name === Cookie.Token) {
    fn(name, value, {
      maxAge: NINETY_DAYS_IN_SECONDS,
      path: Path.Root,
      secure: !env.IS_NODE_ENV_DEVELOPMENT,
      domain: domain ?? env.COOKIE_DOMAIN,
      ...options,
    })
  } else {
    fn(name, value, {
      path: Path.Root,
      domain: domain ?? env.COOKIE_DOMAIN,
      ...options,
    })
  }
}

export const safeRemoveCookie = (
  fn: RemoveCookieFn,
  name: Cookie,
  options: CookieSetOptions = {}
): void => {
  fn(name, {
    path: Path.Root,
    domain: env.COOKIE_DOMAIN,
    ...options,
  })
  fn(name, {
    path: Path.Root,
    domain: env.OAUTH_COOKIE_DOMAIN,
    ...options,
  })
}

export const subtractOriginFromUrl = (url: string): string => {
  const urlObj = new URL(url)
  return urlObj.pathname + urlObj.search
}

export const getLocationOrigin = (): string =>
  browserOnly(() => window.location.origin)() || ''

export const getAppScrimmageUrl = (scrimmageId: string): string =>
  `${getLocationOrigin() + Path.Scrimmage}?join=${scrimmageId}`

/**
 * Reloads current page with query params attached to end
 * example input: ['foo=bar', 'baz=biz']
 */
export const reloadWithQueryParams = (params: string[]): void => {
  window.location.href = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${params.join('&')}`
}

/**
 * Get the path to open a match reschedule notification
 */
export const getRescheduleMatchNotificationPath = (matchId: string): string =>
  `${Path.Match}/${matchId}/mission-control?${Param.OpenRescheduleNotification}=true`

/**
 *  Hook for triggering a prompt when browser window is closed.
 *
 * @param message message to be displayed
 * @param condition when true, show prompt on close.
 */
export const usePromptOnWindowUnload = (
  condition: boolean,
  _window?: Window // This 3rd param is for unit testing purpose.
): void | (() => void) => {
  /* istanbul ignore next */
  const windowObj = _window || window

  useEffect(() => {
    const shouldUnload = condition
    if (condition) {
      const unloadHandler = (evt: BeforeUnloadEvent): string | void => {
        evt.preventDefault()

        // Dev Note: Assigning the message only works on old browsers.
        // It will display the standard window exit prompt for the
        // browser.  This is due to browsers not wanting spamsters
        // to abuse this system dialog prompt.

        // eslint-disable-next-line no-param-reassign
        evt.returnValue = 'Leave site?'
        return 'Leave site?'
      }

      windowObj.onbeforeunload = unloadHandler.bind(this)
    }

    return (): void => {
      if (shouldUnload) {
        windowObj.onbeforeunload = null
      }
    }
  }, [condition])
}

export const getPagePath = (pathname: string): string => {
  const splitPathname = pathname.split('/')
  const pagePath = splitPathname[1]

  return pagePath
}
