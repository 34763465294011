export const ScrimmagesCTA = "Why don't you create one?"

export const AvailableScrimmagesEmptySubtitle =
  'There aren’t any open scrimmages.'

export const MyScrimmagesEmptySubtitle =
  'You don’t have any scrimmages scheduled.'

export const PastScrimmagesEmptySubtitle = 'You don’t have any past scrimmages.'

export const UnplayedScrimmagesEmptySubtitle =
  'You don’t have any unplayed scrimmages.'
