import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const BracketIcon = createSvgIcon(
  <svg
    fill="none"
    height="14"
    viewBox="0 0 15 14"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.452148 1.5C0.452148 0.947715 0.899864 0.5 1.45215 0.5H5.45215C6.00443 0.5 6.45215 0.947715 6.45215 1.5V3.5C6.45215 4.05228 6.00443 4.5 5.45215 4.5H1.45215C0.899864 4.5 0.452148 4.05228 0.452148 3.5V1.5ZM0.452148 10.5C0.452148 9.94771 0.899864 9.5 1.45215 9.5H5.45215C6.00443 9.5 6.45215 9.94771 6.45215 10.5V12.5C6.45215 13.0523 6.00443 13.5 5.45215 13.5H1.45215C0.899864 13.5 0.452148 13.0523 0.452148 12.5V10.5ZM9.45215 5C8.89986 5 8.45215 5.44772 8.45215 6V8C8.45215 8.55228 8.89986 9 9.45215 9H13.4521C14.0044 9 14.4521 8.55228 14.4521 8V6C14.4521 5.44772 14.0044 5 13.4521 5H9.45215Z"
      fill="#161718"
      fillRule="evenodd"
    />
  </svg>,
  'FortniteEsportIcon',
  { viewBox: '0 0 15 14' }
)
