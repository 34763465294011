import { GameAssistantStepType } from '@plvs/graphql/generated'
import { FirebobMessage, SelectionOption } from '@plvs/utils'

export function getFirebobMessage({
  canParticipate,
  isMyTurn,
  hasSubmitted,
  stepType,
  totalSelections,
  gameOrdinal = 0,
}: {
  canParticipate: boolean
  isMyTurn: boolean
  hasSubmitted: boolean
  stepType?: GameAssistantStepType
  totalSelections: number
  gameOrdinal?: number
}): string {
  if (!canParticipate) {
    return FirebobMessage.Spectator
  }

  if (isMyTurn && !hasSubmitted) {
    if (stepType === GameAssistantStepType.Pick) {
      return 'Pick 1 stage'
    }

    // Only Games 3+ have free strikes
    if (gameOrdinal < 3) {
      return `Strike ${totalSelections} ${
        totalSelections > 1 ? 'Stages' : 'Stage'
      }`
    }

    return `Strike ${totalSelections} ${
      totalSelections > 1 ? 'Stages' : 'Stage'
    } (+ any Free Strike stages)`
  }

  if (stepType === GameAssistantStepType.Pick && isMyTurn && hasSubmitted) {
    return 'Setting up your game...'
  }

  if (stepType === GameAssistantStepType.Pick) {
    return 'Opponent picks 1 stage'
  }

  // Only Games 3+ have free strikes
  if (gameOrdinal < 3) {
    return `Strike ${totalSelections} ${
      totalSelections > 1 ? 'Stages' : 'Stage'
    }`
  }

  return `Opponent strikes ${totalSelections} ${
    totalSelections > 1 ? 'Stages' : 'Stage'
  } (+ any Free Strike stages)`
}

export function getButtonLabel({
  stepType,
  selectedStageIds,
  sortedSelectionOptions,
}: {
  stepType?: GameAssistantStepType
  selectedStageIds: string[]
  sortedSelectionOptions: SelectionOption[]
}): string {
  if (stepType === GameAssistantStepType.Ban) {
    return 'Confirm Your Strikes'
  }

  if (!selectedStageIds.length) {
    return 'Select a Stage'
  }

  const stage = sortedSelectionOptions.find(
    ({ id }) => id === selectedStageIds[0]
  )
  return `Play on ${stage?.displayName}`
}
