import { NavigateFunction } from 'react-router-dom'

export const openNotification = (
  navigate: NavigateFunction,
  url?: string | null,
  helpAction?: () => void | null
): void => {
  if (url) {
    if (url.startsWith('http')) {
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  } else if (helpAction) {
    helpAction()
  }
}
