import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'

export const StatRowHeading: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box minWidth={144} textAlign="center">
    <NxTypography variant="overline">{children}</NxTypography>
  </Box>
)
