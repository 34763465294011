import React from 'react'

import { Box, makeStyles, useTheme } from '@material-ui/core'

import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { MatchSettleRequestFragment } from '@plvs/graphql/generated'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

import {
  CoachDarkSmallPrimary,
  CoachLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { DmrMatch, DmrTeam } from '@plvs/utils'

import { AttachScreenshotsButton } from '../buttons/attachScreenshotsButton/AttachScreenshotsButton'
import { GameSelection } from '../gameSelection/GameSelection'
import { MatchScoreReview } from '../matchScoreReview/MatchScoreReview'
import { MatchFinalScore } from '../matchFinalScore/MatchFinalScore'
import { OpponentForfeitButton } from '../buttons/OpponentForfeitButton'
import { DisputeResultsButton } from '../buttons/DisputeResultsButton'
import { SettleMatchButton } from '../buttons/SettleMatchButton'
import { MatchReportCardStep, ReportStepIndex } from '../dynamic.types'
import { ArchiveMatchButton } from '../buttons/ArchiveMatchButton'
import { GameSelectionValue } from '../matchReportAccordion/MatchReportAccordion.types'

const SPOT_SIZE = 64

export const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& button': {
        width: '100%',
      },
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    border: 0,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  footerSpaceBetween: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    border: 0,
  },
  settleReminder: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface useGetMatchReportCardStepsProps {
  match: DmrMatch
  matchSettleRequest: MatchSettleRequestFragment | null
  loadingFinalScore: boolean
  loadingMatchSettleRequest: boolean
  canManageMatch: boolean
  selectedSeriesIndex: number
  expandedGameIndex: number
  notes: string
  isSubmittingGameResult: boolean
  isAdminUser?: boolean
  isArchiveMatchChecked?: boolean
  isSeriesMatch: boolean
  myTeam: DmrTeam | undefined
  oppTeam: DmrTeam | undefined
  seriesMatchDoesntHaveSeriesCreated: boolean
  onSelectOption(
    gameIndex: number,
    { value, meta }: GameSelectionValue
  ): Promise<void>
  setMatchSettleRequest(matchSettleRequest: MatchSettleRequestFragment): void
  setStepIndex(index: ReportStepIndex): void
  setSelectedSeriesIndex(index: number): void
  setExpandedGameIndex(index: number): void
  setNotes(notes: string): void
  setShowForfeitSuccessBanner?: (showForfeitSuccessBanner: boolean) => void
  onClickSettleMatch(): void
  setIsArchiveMatchChecked?: (isArchiveMatchChecked: boolean) => void
}

export const useGetMatchReportCardSteps = ({
  match,
  matchSettleRequest,
  loadingFinalScore,
  loadingMatchSettleRequest,
  canManageMatch,
  selectedSeriesIndex,
  expandedGameIndex,
  notes,
  isSubmittingGameResult,
  isAdminUser = false,
  isArchiveMatchChecked = false,
  isSeriesMatch,
  myTeam,
  oppTeam,
  seriesMatchDoesntHaveSeriesCreated,
  onSelectOption,
  setSelectedSeriesIndex,
  setMatchSettleRequest,
  setStepIndex,
  setExpandedGameIndex,
  setNotes,
  setShowForfeitSuccessBanner,
  onClickSettleMatch,
  setIsArchiveMatchChecked = (): void => {},
}: useGetMatchReportCardStepsProps): MatchReportCardStep[] => {
  const theme = useTheme()
  const classes = useStyles()

  return [
    {
      showCard: false,
    },
    {
      // Game selection flow
      title: 'Scoreboard',
      MainContent: seriesMatchDoesntHaveSeriesCreated ? (
        <>
          <Banner
            subtitle="Results can not be reported at this time. Reach out to customer support for further assistance."
            title="Match series not found"
            type={BannerType.Error}
          />
        </>
      ) : (
        <GameSelection
          expandedGameIndex={expandedGameIndex}
          isSeriesMatch={isSeriesMatch}
          isSubmittingGameResult={isSubmittingGameResult}
          loadingMatchSettleRequest={loadingMatchSettleRequest}
          match={match}
          matchSettleRequest={matchSettleRequest}
          myTeam={myTeam}
          onSelectOption={onSelectOption}
          oppTeam={oppTeam}
          selectedSeriesIndex={selectedSeriesIndex}
          setExpandedGameIndex={setExpandedGameIndex}
          setSelectedSeriesIndex={setSelectedSeriesIndex}
        />
      ),
      SideContent:
        match?.id && setShowForfeitSuccessBanner ? (
          <OpponentForfeitButton
            matchId={match?.id}
            setShowForfeitSuccessBanner={setShowForfeitSuccessBanner}
            teamId={oppTeam?.id ?? ''}
          />
        ) : null,
      showCard: canManageMatch,
      Footer: matchSettleRequest?.isReadyForReview && (
        <Box className={classes.footer}>
          <NxButton
            label="Next"
            onClick={(): void =>
              setStepIndex(ReportStepIndex.MatchScoreReviewIndex)
            }
            variant="primary"
          />
        </Box>
      ),
    },
    {
      // Review and attach screenshots before submission
      MainContent: (
        <MatchScoreReview
          isSeriesMatch={isSeriesMatch}
          match={match}
          matchSettleRequest={matchSettleRequest}
          myTeam={myTeam}
          notes={notes}
          oppTeam={oppTeam}
          setExpandedGameIndex={setExpandedGameIndex}
          setNotes={setNotes}
          setSelectedSeriesIndex={setSelectedSeriesIndex}
          setStepIndex={setStepIndex}
        />
      ),
      Footer: (
        <Box display="flex" flex={1} flexDirection="column">
          <Box
            alignItems="center"
            className={classes.settleReminder}
            display="flex"
            flex={1}
            gridGap="1em"
            mb={3}
          >
            {theme.palette.type === MUIThemeModeEnum.Dark ? (
              <CoachDarkSmallPrimary height={SPOT_SIZE} />
            ) : (
              <CoachLightSmallPrimary height={SPOT_SIZE} />
            )}
            <div>
              <NxTypography variant="body2">Reminder</NxTypography>
              <NxTypography variant="body3">
                Please review your results and submit them.
              </NxTypography>
            </div>
          </Box>

          <Box
            className={
              isAdminUser ? classes.footerSpaceBetween : classes.footer
            }
            justifyContent="flex-end"
          >
            {isAdminUser && (
              <Box>
                <ArchiveMatchButton
                  isArchiveMatchChecked={isArchiveMatchChecked}
                  setIsArchiveMatchChecked={setIsArchiveMatchChecked}
                />
              </Box>
            )}
            <Box className={classes.buttonGroup}>
              <AttachScreenshotsButton
                match={match}
                matchSettleRequest={matchSettleRequest}
                setMatchSettleRequest={setMatchSettleRequest}
              />
              {matchSettleRequest?.id && (
                <SettleMatchButton onClickSettleMatch={onClickSettleMatch} />
              )}
            </Box>
          </Box>
        </Box>
      ),
      showCard: canManageMatch,
    },
    ...[
      isAdminUser
        ? {
            // Match settled banner
            title: 'Match Settled!',
            MainContent: (
              <WaitTillLoaded
                loading={loadingFinalScore}
                loadingSpinnerProps={{ size: 'medium' }}
                showSpinnerWhileLoading
              >
                <Banner
                  subtitle="Your results have successfully been submitted. Reload the page to see the updated match results."
                  title="Match results submitted"
                  type={BannerType.Success}
                />
              </WaitTillLoaded>
            ),
            showCard: true,
          }
        : {
            // Final score board
            title: 'Final Score',
            // @ts-ignore
            MainContent: <MatchFinalScore match={match} />,
            SideContent:
              match?.id && canManageMatch ? (
                <DisputeResultsButton
                  canManageMatch={canManageMatch}
                  match={match}
                />
              ) : null,
            showCard: true,
          },
    ],
  ]
}
