import React from 'react'
import { useParams } from 'react-router-dom'
import { PublicPage } from './page'
import { Login } from '../features/login/Login'
import { PasswordReset } from '../features/login/PasswordReset'

export const PasswordResetPage: React.FC = () => {
  const { token } = useParams<{ token: string }>()
  return (
    <PublicPage description="Reset your password" title="Reset Password">
      <Login title="Reset Your Password">
        <PasswordReset token={token ?? ''} />
      </Login>
    </PublicPage>
  )
}
