import {
  Slot,
  Phase,
  SlotLabel,
  PhaseFormat,
  PhaseType,
  SlotExclusionWindow,
} from '@plvs/graphql'
import dayjs from 'dayjs'
import { last, map } from 'ramda'
import { isInteger } from 'ramda-adjunct'

// types

type CompTimePhase = Pick<Phase, 'id'> & {
  slots: Pick<Slot, 'id' | 'startsAt'>[] | null
}

type PhaseBeforeNameAndPath = Pick<
  Phase,
  'id' | 'startsAt' | 'endsAt' | 'name' | 'slug' | 'type' | 'format'
>
type PhaseWithNameAndPath = PhaseBeforeNameAndPath & {
  name: string
  path: string
}

type NextCompSlot = Pick<Slot, 'id' | 'startsAt'> | null

// consts

export const printPhaseFormatMap: Record<PhaseFormat, string> = {
  [PhaseFormat.BattleRoyale]: 'Battle Royale',
  [PhaseFormat.DoubleElimination]: 'Double Elimination',
  [PhaseFormat.HybridSwiss]: 'Hybrid Swiss',
  [PhaseFormat.RoundRobin]: 'Round Robin',
  [PhaseFormat.SingleElimination]: 'Single Elimination',
  [PhaseFormat.Swiss]: 'Swiss',
  [PhaseFormat.Survival]: 'Survival',
  [PhaseFormat.SmartSchedule]: 'SmartSchedule',
}

export const printPhaseTypeMap: Record<PhaseType, string> = {
  [PhaseType.Championship]: 'Championship',
  [PhaseType.Invitational]: 'Invitational',
  [PhaseType.Playoff]: 'Playoffs',
  [PhaseType.Preseason]: 'Preseason',
  [PhaseType.Qualifier]: 'Qualifier',
  [PhaseType.RegularSeason]: 'Regular Season',
}

export const phaseTypeOrder = [
  printPhaseTypeMap[PhaseType.Preseason],
  printPhaseTypeMap[PhaseType.RegularSeason],
  printPhaseTypeMap[PhaseType.Playoff],
  printPhaseTypeMap[PhaseType.Qualifier],
  printPhaseTypeMap[PhaseType.Championship],
  printPhaseTypeMap[PhaseType.Invitational],
]

export const phaseTypeToPhaseSlugMap: Record<PhaseType, string> = {
  [PhaseType.Championship]: 'championship',
  [PhaseType.Invitational]: 'invitational',
  [PhaseType.Playoff]: 'playoffs',
  [PhaseType.Preseason]: 'preseason',
  [PhaseType.Qualifier]: 'qualifier',
  [PhaseType.RegularSeason]: 'regular-season',
}

// utils

export const findNextCompSlot = (
  phase: CompTimePhase | null,
  now?: dayjs.Dayjs
): NextCompSlot | null => {
  const slots = phase?.slots
  const lastSlot = slots ? last(slots) : undefined
  return (
    slots?.find((slot) => {
      const currentStartTime = dayjs(slot.startsAt)

      const rightNow = now ?? dayjs()

      const diff = currentStartTime.diff(rightNow, 'seconds')

      return diff > 0 ? slot : null
    }) ||
    lastSlot ||
    null
  )
}

export const getSlotName = ({
  phaseFormat,
  order,
  label,
  round,
  ordinal = null,
  name,
  totalSlots,
}: {
  label?: SlotLabel | null
  name?: string | null
  order?: number | null
  ordinal?: number | null
  phaseFormat?: PhaseFormat
  round?: number
  totalSlots?: number
}): string => {
  if (name) {
    return name
  }

  if (phaseFormat === PhaseFormat.SingleElimination) {
    if (ordinal === totalSlots) {
      return 'Finals'
    }
    return isInteger(ordinal) ? `Round ${ordinal}` : ''
  }

  if (phaseFormat === PhaseFormat.DoubleElimination) {
    return `${label} ${round} (#${ordinal})`
  }

  return isInteger(order) ? `Week ${order + 1}` : ''
}

export const phasesToPhasesWithNameAndPath = ({
  phases,
  basePath,
}): PhaseWithNameAndPath[] => {
  return map((phase) => {
    const name = phase.name || printPhaseTypeMap[phase.type]
    const slug = phase.slug || phaseTypeToPhaseSlugMap[phase.type]
    const path = `${basePath}/${slug}/${phase.id}`

    return {
      ...phase,
      name,
      path,
      slug,
    }
  }, phases)
}

export const formatIsElimination = (format: PhaseFormat): boolean =>
  format === PhaseFormat.SingleElimination ||
  format === PhaseFormat.DoubleElimination

export const findMatchingSlotExclusion = <
  T extends Pick<SlotExclusionWindow, 'startsAt' | 'endsAt'>
>(
  slotExclusionWindows: T[] | null | undefined,
  phaseStartDate: string | null | undefined,
  phaseEndDate: string | null | undefined
): T | undefined | null => {
  if (!slotExclusionWindows || !phaseStartDate || !phaseEndDate)
    return undefined
  return slotExclusionWindows.find((window) => {
    return (
      dayjs(window?.startsAt || '').isSame(dayjs(phaseStartDate)) &&
      dayjs(window?.endsAt || '').isSame(dayjs(phaseEndDate))
    )
  })
}
