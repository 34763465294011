import { useEffect } from 'react'
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk'
import { useQueryParams } from '@plvs/rally/components/filter'
import { Param } from '@plvs/const'

type UseOAuthReturn = {
  isEnabled: boolean
}

export function useOAuth(): UseOAuthReturn {
  const [params] = useQueryParams([Param.OAuth])
  const flags = useFlags()
  const ldClient = useLDClient()
  const oauthQueryParam = params.oauth

  useEffect(
    function initializeLaunchDarkly() {
      if (!ldClient) {
        return
      }

      ldClient.identify({
        kind: 'user',
        key: oauthQueryParam ?? 'none',
        custom: {
          oauthAllowed: oauthQueryParam === 'allowed',
        },
      })
    },
    [ldClient]
  )

  return { isEnabled: flags.isGoogleOAuthVisible }
}
