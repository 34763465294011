import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

type CSSProperties = {
  hasCharacterAsset: boolean
}

export const useStyles = makeStyles((theme) => ({
  outerContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  selectableContainer: {
    display: 'flex',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  subtitles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  selectableSinge: {
    width: '100%',
  },
  selectable: {
    borderRadius: 6,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  userCluster: ({ hasCharacterAsset }: CSSProperties): CreateCSSProperties => ({
    gap: hasCharacterAsset ? theme.spacing(0) : undefined,
  }),
  footer: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      width: '100%',

      '& button': {
        width: '100%',
      },
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  scoreInput: {
    width: 94,
    marginRight: theme.spacing(2),
  },

  playerGradient: {
    background: `linear-gradient(180deg, ${theme.palette.ColorEsportAccentSUBase} 0%, ${theme.palette.ColorEsportAccentSU6} 100%)`,
    height: '100%',
    width: theme.spacing(8),
    borderRadius: '6px 0 0 6px',
  },
  opponentGradient: {
    background: 'linear-gradient(180deg, #195DFF 0%, #002580 100%)',
    height: '100%',
    width: theme.spacing(8),
    borderRadius: '6px 0 0 6px',
  },
}))
