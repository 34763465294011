import React from 'react'
import { OptionalWeeksCache } from '@plvs/respawn/containers/enrollment/types'
import { SlotExclusionWindowResourceType } from '@plvs/graphql'
import { OptionalWeeksMenu } from './OptionalWeeksMenu'
import { SelectedWeek } from './SelectedWeek'

export interface OptionalWeekContentProps {
  optionalWeeksCache: OptionalWeeksCache
  configuration: {
    maxSlotExclusions: number
    defaultsAppliedAt: string
    suggestions: Array<{
      startsAt: string
      endsAt: string
      hash: string
      isAvailable: boolean
      title: string
    }>
  }
  range: string
  seasonId?: string
  resourceType: SlotExclusionWindowResourceType
  resourceId: string
  selectLabel?: string
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  menuOpened?: boolean
  menuWidth?: string
}

export const OptionalWeekContent: React.FC<OptionalWeekContentProps> = ({
  configuration,
  range,
  optionalWeeksCache,
  setOptionalWeeksCache,
  seasonId,
  resourceId,
  resourceType,
  selectLabel,
  menuOpened = false,
  menuWidth = '40%',
}) => {
  const [showMenuForSection, setShowMenuForSection] = React.useState(menuOpened)
  if (optionalWeeksCache[range]?.selections.length) {
    return (
      <>
        {optionalWeeksCache[range]?.selections.map((cache, i) => {
          const { confirmedSelection } = cache
          if (confirmedSelection && !showMenuForSection) {
            const isPastDeadline = !confirmedSelection.isEditable

            return (
              <SelectedWeek
                key={confirmedSelection.hash}
                isPastDeadline={isPastDeadline}
                onClick={(): void => setShowMenuForSection(true)}
                selectedWeekInterval={confirmedSelection.formattedSelection!}
              />
            )
          }

          return (
            <OptionalWeeksMenu
              key={`${range}-${i.toString()}`}
              configSuggestions={configuration.suggestions}
              optionalWeeksCache={optionalWeeksCache}
              resourceId={resourceId}
              resourceType={resourceType}
              seasonId={seasonId}
              selectedSlotCache={cache}
              selectLabel={selectLabel}
              updateOptionalWeeksCache={setOptionalWeeksCache}
              width={menuWidth}
            />
          )
        })}
      </>
    )
  }
  return (
    <>
      <OptionalWeeksMenu
        key={range}
        configSuggestions={configuration.suggestions}
        optionalWeeksCache={optionalWeeksCache}
        resourceId={resourceId}
        resourceType={resourceType}
        seasonId={seasonId}
        selectedSlotCache={{
          confirmedSelection: null,
          selection: null,
        }}
        selectLabel={selectLabel}
        updateOptionalWeeksCache={setOptionalWeeksCache}
        width={menuWidth}
      />
    </>
  )
}
