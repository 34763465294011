import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { NxButton, NxIconButton, NxModal } from '@playvs-inc/nexus-components'
import { Close } from '@playvs-inc/nexus-icons'

import { TeamRivalFragment, useDenyTeamRivalMutation } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useRosterContext } from '../../RosterProvider'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: `${theme.palette.ColorIconError}!important`,
  },
}))

interface RosterRivalsDenyButtonProps {
  rival: TeamRivalFragment
  onComplete?(): void
}

export const RosterRivalsDenyButton: React.FC<RosterRivalsDenyButtonProps> = ({
  rival,
  onComplete,
}) => {
  const styles = useStyles()
  const { teamId } = useRosterContext()

  const { enqueueSnackbar } = useSnackbar()
  const [denyTeamRival, { loading: loadingDenyTeamRival }] =
    useDenyTeamRivalMutation()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (): void => {
    setIsOpen(true)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleDeny = async (): Promise<void> => {
    try {
      await denyTeamRival({
        variables: {
          input: {
            acceptingTeamId: teamId,
            teamRivalId: rival.id,
          },
        },
      })
      onComplete?.()
      enqueueSnackbar('Rival request denied', { variant: 'success' })
      setIsOpen(false)
    } catch (err) {
      enqueueSnackbar(
        'There was an issue denying this rival request. Try again or reach out to Customer Experience.',
        {
          variant: 'error',
        }
      )
    }
  }

  return (
    <>
      <NxIconButton
        icon={<Close className={styles.icon} />}
        label=""
        onClick={handleOpen}
        variant="text"
      />

      {isOpen && (
        <NxModal
          actions={
            <>
              <NxButton label="Cancel" onClick={handleClose} variant="text" />

              <NxButton
                label="Yes, Deny"
                loading={loadingDenyTeamRival}
                onClick={handleDeny}
                variant="primary"
              />
            </>
          }
          onClose={handleClose}
          open={isOpen}
          showTopRightClose
          subtitle={`Are you sure you want to deny ${rival.requestingTeam?.name}?`}
          title="Deny Rival Invitation?"
        >
          <WaitTillLoaded
            loading={loadingDenyTeamRival}
            loadingSpinnerProps={{ size: 'medium' }}
            showSpinnerWhileLoading
          />
        </NxModal>
      )}
    </>
  )
}
