import React, { useState } from 'react'
import {
  usePostMatchSurveyDetailsQuery,
  useCreateMatchSurveyResponseMutation,
  useDismissMatchSurveyMutation,
  useSaveSurveyResponseMutation,
  SurveyType,
} from '@plvs/graphql'
import { SingleNotification } from '@plvs/rally/containers/app/notifications'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { PostMatchSurveyOverlayPresentation } from '@plvs/rally/components/overlay/PostMatchSurveyOverlay'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

type PostMatchSurveyOverlayProps = {
  acknowledge(id: string): Promise<void>
  postMatchSurveyNotification: SingleNotification
  setShowPostMatchSurveySuccessStep(
    showPostMatchSurveySuccessStep: boolean
  ): void
  isFirstMatch?: boolean
}

export const PostMatchSurveyOverlay: React.FC<PostMatchSurveyOverlayProps> = ({
  acknowledge,
  postMatchSurveyNotification,
  setShowPostMatchSurveySuccessStep,
  isFirstMatch,
}) => {
  const [surveyHasError, setSurveyHasError] = useState<boolean>(false)
  const [surveySubmitSuccess, setSurveySubmitSuccess] = useState<boolean>(false)

  const { id: surveyModalId, surveyMatchId } = postMatchSurveyNotification
  const matchId = surveyMatchId ?? ''
  const {
    data: matchData,
    loading: matchLoading,
  } = usePostMatchSurveyDetailsQuery({
    variables: { matchId },
  })
  const { userId } = useUserIdentityFn()

  const [
    submitSurvey,
    { loading: submitLoading },
  ] = useCreateMatchSurveyResponseMutation()

  const [
    saveFirstSurvey,
    { loading: submitFirstSurveyLoading },
  ] = useSaveSurveyResponseMutation()

  const [ackPostMatchSurvey] = useDismissMatchSurveyMutation()

  const esportSlug = matchData?.match?.esport?.slug ?? null
  const esportAdapter = useGeneralEsportAdapter(esportSlug)

  return (
    <PostMatchSurveyOverlayPresentation
      key={surveyModalId}
      esportName={esportAdapter.name}
      hasSeries={esportAdapter.hasSeries}
      isFirstMatch={isFirstMatch}
      isSubmitting={submitLoading || submitFirstSurveyLoading}
      loading={matchLoading || submitLoading || submitFirstSurveyLoading}
      match={matchData?.match ?? null}
      onCancelClick={(): void => {
        ackPostMatchSurvey({
          variables: {
            matchSurveyInput: {
              matchId,
              userId,
            },
          },
        })
        acknowledge(surveyModalId)
      }}
      onPrimaryClick={async ({
        funRating,
        surveyComment,
        shouldContact,
        teamId,
        surveyQuestions,
      }): Promise<void> => {
        try {
          if (!isFirstMatch) {
            await submitSurvey({
              variables: {
                matchSurveyInput: {
                  matchId,
                  userId,
                  teamId,
                  funRating,
                  surveyComment,
                  shouldContact,
                },
              },
            })
          } else if (isFirstMatch && surveyQuestions) {
            await saveFirstSurvey({
              variables: {
                input: {
                  userId,
                  questions: surveyQuestions,
                  surveyType: SurveyType.FirstMatch,
                },
              },
            })
          }

          setSurveySubmitSuccess(true)
        } catch (e: any) {
          setSurveyHasError(true)
          setSurveySubmitSuccess(false)
        }
        await acknowledge(surveyModalId)
      }}
      setShowPostMatchSurveySuccessStep={setShowPostMatchSurveySuccessStep}
      submitError={surveyHasError}
      submitSuccess={surveySubmitSuccess}
    />
  )
}
