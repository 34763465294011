import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const StateConferenceWhite = createSvgIcon(
  <>
    <path
      d="M21.0787 11.8703L20.0503 12.5423C18.7664 13.385 17.4847 14.2298 16.205 15.0767C15.7308 15.3871 15.1703 15.6495 15.0313 16.1599C14.9004 15.8703 14.8349 15.5855 14.72 15.3199C14.6402 15.1548 14.617 14.968 14.6539 14.7884C14.6909 14.6087 14.7859 14.4463 14.9244 14.3263C15.315 13.9687 15.7372 13.6474 16.1859 13.3663C17.7524 12.3295 19.3333 11.3087 20.9094 10.2815C20.7432 9.41399 20.4505 8.57568 20.0407 7.79351C18.3608 8.88365 16.6841 9.97805 15.0106 11.0767C14.5187 11.3967 13.9263 11.6719 13.7842 12.1967C13.6293 11.8399 13.5191 11.5071 13.4137 11.1647C13.278 10.7199 13.4648 10.3647 14.0189 9.99991L19.2726 6.55991C18.7515 5.85884 18.1325 5.23647 17.4346 4.71191C15.9911 5.65485 14.5475 6.59885 13.1039 7.54391C12.6249 7.85271 12.4907 8.21111 12.6041 8.58871C12.7175 8.96631 12.8532 9.37911 12.9762 9.77431C13.1359 10.2639 13.0528 10.4143 12.4412 10.7967C10.699 11.8991 6.03137 14.8991 4.28919 15.9999C4.16432 16.0778 4.04626 16.1661 3.93628 16.2639C4.33526 17.017 4.83787 17.7102 5.42935 18.3231C7.01664 17.3119 9.55727 15.6799 10.3397 15.1887L12.775 13.6511C13.2221 13.3695 13.7331 13.1199 13.8592 12.6399C14.0564 13.0985 14.2135 13.5733 14.3287 14.0591C14.3575 14.1935 14.209 14.4063 14.0668 14.5487C13.8687 14.7181 13.6548 14.8681 13.4281 14.9967C11.2989 16.3919 9.16444 17.7866 7.02463 19.1807C6.91015 19.2518 6.80315 19.3344 6.70526 19.4271C7.50594 19.9989 8.39426 20.4361 9.3353 20.7215C10.9897 19.6527 12.6366 18.5818 14.276 17.5087C14.6417 17.2703 15.001 17.0287 15.1144 16.6671C15.2578 17.0156 15.3831 17.3712 15.4896 17.7327C15.6685 18.2943 15.6094 18.4031 14.902 18.8527L11.3617 21.0927C11.5645 21.1071 11.7689 21.1167 11.9749 21.1167C13.4554 21.1179 14.9134 20.7547 16.221 20.0591C16.138 19.7903 16.0517 19.5231 15.9687 19.2591C15.7962 18.7103 16.0406 18.2367 16.716 17.7967L20.4399 15.3599C20.8638 14.2907 21.0805 13.1504 21.0787 11.9999C21.0787 11.9567 21.0787 11.9135 21.0787 11.8703Z"
      fill="white"
    />
    <path
      d="M11.9765 24C9.60781 24 7.29227 23.2962 5.32274 21.9776C3.3532 20.6591 1.81814 18.7849 0.911665 16.5922C0.00518927 14.3995 -0.231986 11.9867 0.230131 9.65892C0.692249 7.33115 1.8329 5.19295 3.50785 3.51472C5.1828 1.83649 7.31682 0.693605 9.64004 0.230582C11.9633 -0.232441 14.3713 0.00519943 16.5598 0.913451C18.7482 1.8217 20.6187 3.35977 21.9347 5.33316C23.2507 7.30655 23.9531 9.62663 23.9531 12C23.9493 15.1814 22.6862 18.2315 20.441 20.4811C18.1958 22.7307 15.1517 23.9962 11.9765 24ZM11.9765 1.6864C9.94069 1.6864 7.95056 2.29129 6.25782 3.42456C4.56507 4.55783 3.24573 6.1686 2.46665 8.05316C1.68756 9.93772 1.48372 12.0114 1.88089 14.0121C2.27807 16.0127 3.25842 17.8504 4.69799 19.2928C6.13755 20.7352 7.97166 21.7175 9.96839 22.1154C11.9651 22.5134 14.0348 22.3091 15.9157 21.5285C17.7965 20.7479 19.4042 19.426 20.5352 17.7299C21.6663 16.0339 22.27 14.0398 22.27 12C22.2666 9.26571 21.181 6.64437 19.2514 4.71093C17.3217 2.77749 14.7055 1.68979 11.9765 1.6864Z"
      fill="white"
    />
    <path
      d="M9.34338 8.39553L10.6991 5.40033L7.6491 6.63713V5.37793C7.05331 5.37761 6.47081 5.55436 5.97529 5.88582C5.47977 6.21728 5.09351 6.68855 4.86536 7.24C4.63722 7.79146 4.57744 8.39833 4.6936 8.98384C4.80975 9.56935 5.09662 10.1072 5.51791 10.5293C5.9392 10.9514 6.47598 11.2388 7.06034 11.3552C7.6447 11.4716 8.25039 11.4117 8.80077 11.1831C9.35115 10.9545 9.8215 10.5675 10.1523 10.071C10.4831 9.57453 10.6595 8.99089 10.6592 8.39393L9.34338 8.39553Z"
      fill="white"
    />
  </>,
  'StateConference-White',
  { viewBox: '0 0 24 24' }
)
