import { useTheme } from '@material-ui/core'
import { map } from 'ramda'
import React, { Dispatch, SetStateAction } from 'react'

import {
  FnTeamPhaseStandingsQuery,
  FnTeamMatchStandingsQuery,
  Provider,
} from '@plvs/graphql'
import { Colors } from '@plvs/rally/themes'
import {
  TableColumn,
  Table,
  TablePagination,
} from '@plvs/rally/components/table'
import { getAccountProviderUsername } from '@plvs/utils'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'

import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { UserCluster } from '../cluster'

// Team Phase Stats
type Phase = NonNullable<FnTeamPhaseStandingsQuery['phase']>
type TeamPhaseStats = NonNullable<Phase['fortniteTeamPhaseStats']>
type LeaderboardPhaseTeam = NonNullable<
  NonNullable<TeamPhaseStats['leaderboard']>[0]
>

// Team Match Stats for Phase
type TeamMatchStats = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<FnTeamMatchStandingsQuery['phase']>['slots']
    >[0]['matches']
  >[0]['fortniteTeamMatchStats']
>
type LeaderboardMatchTeam = NonNullable<
  NonNullable<TeamMatchStats['leaderboard']>[0]
>

// this should be enough to cover getFortniteTeamMatchStats and other permutations as well
export type Team = LeaderboardMatchTeam | LeaderboardPhaseTeam

// NOTE: There is still not type safety with this component when it comes to
//  what columns are being pumped in. Those columns are typed as
//  TableColumn<any>[].
export const MatchLeaderboard: React.FC<{
  buttons: React.ReactNode
  columns: TableColumn<Team>[]
  data: Team[]
  numPages: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  title: string
  titleDetail: string
}> = ({
  buttons,
  columns,
  data,
  numPages,
  page,
  setPage,
  title,
  titleDetail,
}) => {
  const theme = useTheme()

  return (
    <MatchCard
      footer={
        <TablePagination numPages={numPages} page={page} setPage={setPage} />
      }
      px={0}
      py={0}
      sideContent={buttons}
      title={title}
      titleDetail={titleDetail}
    >
      <Table
        columns={columns}
        data={data}
        getTrProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ): { className: string; onClick(): void } => {
          const { expanded } = state
          const path = rowInfo?.nestingPath[0]
          const isRowExpanded = expanded[path]

          return {
            className: isRowExpanded ? 'rt-expanded' : '',
            onClick: (): void =>
              instance.setState({
                expanded: {
                  [path]: !isRowExpanded,
                },
              }),
          }
        }}
        sortable={false}
        SubComponent={({ row }): React.ReactElement | null => {
          // eslint-disable-next-line no-underscore-dangle
          const members = (row?._original as Team).team?.members ?? []

          return members ? (
            <div
              style={{
                backgroundColor: Colors.Grey6,
                padding: theme.spacing(1.5, 3, 1),
              }}
            >
              <NxTypography variant="overline">Player</NxTypography>
              {map((member): React.ReactElement => {
                const { id, isCaptain, avatarUrl, name } = member
                return (
                  <UserCluster
                    key={id}
                    avatarSize={NxAvatarSize.SM}
                    avatarUrl={avatarUrl || ''}
                    id={id}
                    isCaptain={isCaptain}
                    subtitle={
                      getAccountProviderUsername(member, Provider.EpicGames) ??
                      'N/A'
                    }
                    title={name || 'N/A'}
                  />
                )
              }, members)}
            </div>
          ) : null
        }}
      />
    </MatchCard>
  )
}
