import React from 'react'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import {
  IconButton,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core'
import { ArrowBack, Check } from '@material-ui/icons'
import { Colors } from '@plvs/rally/themes'
import { CompetitionGroup, CompetitionModel } from '@plvs/graphql'
import { competitionModelToDisplay } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles({
  cta: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  stepper: {
    backgroundColor: 'inherit',
    padding: 0,
    width: '50%',
  },
  check: {
    color: Colors.TEMPNewOrange,
  },
})

interface EnrollmentAppBarProps {
  onBack: () => void
  activeStep: number
  steps: CompetitionModel[]
  competitionGroup: CompetitionGroup | undefined | null
  showStepper: boolean
}

export const EnrollmentAppBar: React.FC<EnrollmentAppBarProps> = ({
  onBack,
  steps,
  activeStep,
  competitionGroup,
  showStepper,
}) => {
  const { cta, iconWrapper, stepper, check } = useStyles()
  const isMobile = useBreakpointXs()

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      pl={isMobile ? 2 : 8}
      py={3}
    >
      <IconButton className={cta} onClick={onBack} size="small">
        <Box className={iconWrapper}>
          <ArrowBack />
        </Box>
      </IconButton>
      <Box pl={1}>
        <NxTypography colorToken="ColorTextBase" variant="button">
          Go Back
        </NxTypography>
      </Box>
      {showStepper && (
        <Box display="flex" flex={1} justifyContent="center">
          <Stepper activeStep={activeStep} className={stepper}>
            {steps.map((step, i) => {
              const label = competitionModelToDisplay({
                competitionModel: step,
                competitionGroup,
              })
              return (
                <Step key={label}>
                  <StepLabel
                    icon={i === activeStep ? <Check className={check} /> : null}
                  >
                    <NxTypography variant="button">{label}</NxTypography>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
      )}
    </Box>
  )
}
