import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout/Box'
import { CompetitionGroup, League } from '@plvs/graphql/generated'
import { EsportSlug, EsportRating } from '@plvs/graphql'
import { NxEsportCard, NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  esportCard: {
    cursor: 'pointer',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  esportCardContainer: {
    marginBottom: theme.spacing(3),
  },
  leagueTitle: {
    marginBottom: theme.spacing(1),
  },
  leagueSubtitle: {
    color: theme.palette.ColorTextAlt2,
    marginBottom: theme.spacing(1),
  },
  esportGrid: {
    display: 'grid',
    gap: '1em',
    margin: theme.spacing(3, 0),
    gridAutoRows: 'minmax(100px, auto)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}))

type ExploreLeagueType =
  | (Pick<League, 'id' | 'competitionGroup'> & {
      esport: {
        id: string
        slug?: EsportSlug
        name?: string | null
        rating?: EsportRating
      }
    })
  | null

interface Props {
  competitionGroup: CompetitionGroup
  leagues: ExploreLeagueType[]
  subtitle: string
  title: string
}

export const ExploreLeaguesSection: React.FC<Props> = ({
  competitionGroup,
  leagues,
  subtitle,
  title,
}) => {
  const classes = useStyles()

  const navigate = useNavigate()

  const anyRestricted = leagues.some(
    (l) => l?.esport.rating === EsportRating.Restricted
  )

  return (
    <Box className={classes.esportCardContainer}>
      <NxTypography className={classes.leagueTitle} variant="h3">
        {title}
      </NxTypography>
      <NxTypography className={classes.leagueSubtitle} variant="overline">
        {subtitle}
      </NxTypography>
      <Box className={classes.esportGrid}>
        {leagues.map((league) => {
          const esport = league?.esport?.slug
          return (
            <Fragment key={`${league?.id} - ${league?.esport?.id}`}>
              {esport && (
                <NxEsportCard
                  data-cy={esport}
                  esport={esport}
                  esportName={league?.esport?.name ?? ''}
                  onClick={(): void =>
                    navigate(
                      `/app/explore/leagues-table/${competitionGroup}/${league?.esport?.slug}`
                    )
                  }
                />
              )}
            </Fragment>
          )
        })}
      </Box>
      {anyRestricted && (
        <Box display="flex" justifyContent="center" mt={9}>
          <NxTypography variant="subtitle1">
            * These titles and leagues are unaffiliated with the NFHS Network
            and certain state associations.
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
