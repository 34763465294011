import React, { Dispatch, SetStateAction } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import {
  NxCardPaginationButtons,
  NxTypography,
} from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  totalCount: {
    marginRight: theme.spacing(2),
  },
  pageText: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
}))

export const TablePagination: React.FC<{
  className?: string
  numPages: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  totalCount?: number
  setIsPrev?: Dispatch<SetStateAction<boolean>>
}> = ({ className, numPages, page, setPage, totalCount, setIsPrev }) => {
  const classes = useStyles()

  const handleLeft = async (): Promise<void> => {
    setPage((oldPage) => oldPage - 1)
    setIsPrev?.(true)
  }

  const handleRight = async (): Promise<void> => {
    setPage((oldPage) => oldPage + 1)
    setIsPrev?.(false)
  }

  return (
    <Box
      alignItems="center"
      className={className}
      data-testid="table-pagination"
      display="flex"
      justifyContent="center"
      mb={2}
      width="100%"
    >
      {totalCount && (
        <NxTypography className={classes.totalCount} variant="body4">
          {totalCount} Results
        </NxTypography>
      )}

      <NxCardPaginationButtons
        enableLeft={page > 1}
        enableRight={page < numPages}
        onLeft={handleLeft}
        onRight={handleRight}
      >
        <NxTypography className={classes.pageText} variant="overline">
          {page} of {numPages}
        </NxTypography>
      </NxCardPaginationButtons>
    </Box>
  )
}
