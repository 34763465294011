import React from 'react'
import { PublicPage } from './page'

// when server-side rendered, this component will cue the server to return a 404
export const NotFoundPage: React.FC = () => {
  return (
    <PublicPage description="The requested page could not be found" title="404">
      <h1>Oops, nothing here!</h1>
    </PublicPage>
  )
}
