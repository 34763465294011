import { useState, useEffect, useRef } from 'react'

type UseTabNotificationReturn = [(title: string) => void, () => void]

/**
 * "Flashes" the document title.
 *
 * @param interval Interval length in miliseconds
 * @returns
 */
export function useTabNotification(interval = 1000): UseTabNotificationReturn {
  const [prevTitle] = useState(() => document.title)
  const [title, setTitle] = useState<string>()
  const notificationIntervalId = useRef<number>()

  function revertOriginalTitle(): void {
    document.title = prevTitle
  }

  function tick(newTitle: string): void {
    document.title = document.title === newTitle ? prevTitle : newTitle
  }

  function setTabNotification(newTitle: string): void {
    setTitle(newTitle)
  }

  function clearTabNotification(): void {
    revertOriginalTitle()
    setTitle(undefined)
  }

  function startNotifying(): void {
    notificationIntervalId.current = setInterval(tick, interval, title)
  }

  function stopNotifying(): void {
    clearInterval(notificationIntervalId.current)
    notificationIntervalId.current = undefined
  }

  useEffect((): void => {
    if (notificationIntervalId.current && !title) {
      stopNotifying()
    }

    if (!notificationIntervalId.current && title) {
      startNotifying()
    }
  }, [title])

  useEffect(() => {
    return (): void => {
      if (notificationIntervalId.current) {
        clearInterval(notificationIntervalId.current)
      }
    }
  }, [])

  return [setTabNotification, clearTabNotification]
}

export default useTabNotification
