import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import {
  TeamMember,
  useAssignTeamCaptainRoleMutation,
  useUnassignTeamCaptainRoleMutation,
} from '@plvs/graphql'
import {
  NxTypography,
  NxModal,
  NxButton,
  NxTextLink,
} from '@playvs-inc/nexus-components'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { useSnackbar } from 'notistack'
import { IntercomArticleMappings } from '@plvs/const'

interface Props {
  onClose(): void
  member: Pick<TeamMember, 'id' | 'name'>
  onMutationSuccess?(): void
  teamId: string
  currentTeamCaptainName?: string
}

export const PromoteToTeamCaptainMenuItem: React.FC<Props> = ({
  onClose,
  member,
  onMutationSuccess,
  teamId,
  currentTeamCaptainName,
}) => {
  const [assignTeamCaptainRole] = useAssignTeamCaptainRoleMutation()
  const { enqueueSnackbar } = useSnackbar()

  const submit = async (): Promise<void> => {
    try {
      await assignTeamCaptainRole({
        variables: { teamId, userId: member.id },
      })
      onMutationSuccess?.()
      enqueueSnackbar(`Successfully promoted to captain`, {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      enqueueSnackbar(`Unable to promote to captain`, {
        variant: 'error',
      })
    }
  }

  return (
    <NxModal
      actions={
        <>
          <NxButton
            label="Cancel"
            onClick={(): void => {
              onClose()
            }}
            variant="text"
          />
          <NxButton label="Submit" onClick={submit} variant="primary" />
        </>
      }
      onClose={(): void => {
        onClose()
      }}
      open
      showTopRightClose
      size="small"
      subtitle={`Would you like to promote ${
        member?.name ?? 'this player'
      } to a team captain?`}
      title="Promote to Captain"
    >
      <>
        {currentTeamCaptainName && (
          <Box data-testid="current-team-captain" pt={1}>
            <NxTypography variant="body1">
              This will demote <b>{currentTeamCaptainName}</b> from team
              captain.
            </NxTypography>
          </Box>
        )}
        <Box alignItems="center" display="flex" flexDirection="row" gridGap={2}>
          <NxTypography variant="body1">
            Click here to learn more about{' '}
          </NxTypography>
          <NxTextLink
            label="Team Captains"
            onClick={(e: React.SyntheticEvent): void => {
              e.stopPropagation()
              showIntercomArticle(IntercomArticleMappings.captains)
            }}
          />
        </Box>
      </>
    </NxModal>
  )
}

export const DemoteTeamCaptainMenuItem: React.FC<Props> = ({
  onClose,
  member,
  onMutationSuccess,
  teamId,
  currentTeamCaptainName,
}) => {
  const [unassignTeamCaptainRole] = useUnassignTeamCaptainRoleMutation()
  const { enqueueSnackbar } = useSnackbar()

  const submit = async (): Promise<void> => {
    try {
      await unassignTeamCaptainRole({
        variables: { teamId, userId: member.id },
      })
      onMutationSuccess?.()
      enqueueSnackbar(`Successfully removed the captain role`, {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      enqueueSnackbar(`Unable to remove the captain role`, {
        variant: 'error',
      })
    }
  }

  return (
    <NxModal
      actions={
        <>
          <NxButton
            label="Cancel"
            onClick={(): void => {
              onClose()
            }}
            variant="text"
          />
          <NxButton label="Submit" onClick={submit} variant="primary" />
        </>
      }
      onClose={(): void => {
        onClose()
      }}
      open
      showTopRightClose
      size="small"
      subtitle={`Would you like to remove the captain role from ${
        currentTeamCaptainName ?? 'this player'
      }?`}
      title="Remove Team Captain"
    >
      <>
        {currentTeamCaptainName && (
          <Box pt={1}>
            <NxTypography variant="body1">
              This will remove the team captain role from{' '}
              <b>{currentTeamCaptainName}.</b>
            </NxTypography>
          </Box>
        )}
        <Box
          alignContent="center"
          display="flex"
          flexDirection="row"
          gridGap={2}
        >
          <NxTypography variant="body1">
            Click here to learn more about{' '}
          </NxTypography>
          <NxTextLink
            label="Team Captains"
            onClick={(e: React.SyntheticEvent): void => {
              e.stopPropagation()
              showIntercomArticle(IntercomArticleMappings.captains)
            }}
          />
        </Box>
      </>
    </NxModal>
  )
}
