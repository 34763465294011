import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { useSpring, animated } from 'react-spring'
import { Colors } from '@plvs/rally/themes'

import {
  BracketResult,
  BracketType,
  LEG_WIDTH,
  TAIL_WIDTH,
  POD_HEIGHT,
  POD_GUTTER,
  BracketResultStatus,
} from '@plvs/rally/features/standings/standingsHelpers'
import { EsportRating } from '@plvs/graphql/generated'
import { BracketResultPod } from './BracketResultPod'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px 0`,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  pod: {
    position: 'relative',
    border: `1px solid ${theme.palette.BorderLight}`,
    boxShadow:
      '0px 2px 16px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
    borderRadius: theme.shape.borderRadius * 2,
    width: '100%',
  },
  leg: ({
    bottom,
    top,
  }: {
    bottom: boolean
    top: boolean
  }): CreateCSSProperties => ({
    borderRight: top || bottom ? `1px solid ${Colors.Grey12}` : '',
    borderBottom: bottom ? `1px solid ${Colors.Grey12}` : '',
    borderTop: top ? `1px solid ${Colors.Grey12}` : '',
    content: '""',
    right: -15,
    position: 'absolute',
    top: `calc(50% + ${bottom ? '1px' : '0'})`,
    transform: bottom ? 'translateY(-100%)' : '',
  }),
  tail: {
    borderTop: `1px solid ${Colors.Grey12}`,
    content: '""',
    left: -20,
    position: 'absolute',
    top: '50%',
    width: TAIL_WIDTH,
  },
}))

export const BracketResultPairDouble: React.FC<{
  bracketResults: BracketResult[]
  bracketType: BracketType
  isFirstSlot: boolean
  numBracketResults: number
  podIndex: number
  slotIndex: number
  teamIndex: number
  toMatch?(matchId: string): string
  slotsLength?: number
}> = ({
  bracketType,
  bracketResults,
  isFirstSlot,
  podIndex,
  numBracketResults,
  slotIndex,
  teamIndex,
  toMatch,
  slotsLength,
}) => {
  // styles
  const top = podIndex % 2 === 0
  const bottom = podIndex % 2 === 1
  const classes = useStyles({ bottom, top })

  // get team info for each pod
  const nextTeamIndex = teamIndex + 1
  const {
    esportRating,
    matchId: matchIdA,
    status: statusA,
    team: teamA,
    isUnfilledForBye: isUnfilledForByeA,
    matchCompleted: teamAMatchCompleted,
  } = bracketResults[teamIndex]

  const {
    matchId: matchIdB,
    status: statusB,
    team: teamB,
    isUnfilledForBye: isUnfilledForByeB,
    matchCompleted: teamBMatchCompleted,
  } = bracketResults[nextTeamIndex]

  // compute line styles
  const isEvenSlotIndex = slotIndex % 2 === 0
  const isLosersBracket = bracketType === 'losers'

  const isSingleMatch = numBracketResults === 2

  const podFullHeight = POD_HEIGHT + POD_GUTTER
  const legHeight = isLosersBracket
    ? 2 ** (slotIndex - 1) * podFullHeight
    : 2 ** slotIndex * podFullHeight
  const legStyle = useSpring({
    height:
      (isLosersBracket && isEvenSlotIndex) || isSingleMatch ? 0 : legHeight,
    width: LEG_WIDTH,
  })
  const tailStyle = useSpring({ width: isFirstSlot ? 0 : TAIL_WIDTH })

  // Seeding information
  const seedA = teamA?.seed
  const seedB = teamB?.seed
  const showSeed = slotIndex === 0 // seeds only displayed in first round
  const isLastSlot = slotIndex === slotsLength

  return (
    <animated.div
      key={`slot-${slotIndex}-pair-${teamIndex / 2}`}
      className={classes.root}
    >
      <div className={classes.pod}>
        <BracketResultPod
          key={`slot-${slotIndex}-team-${teamIndex}`}
          esportRating={esportRating || EsportRating.General}
          isUnfilledForBye={isUnfilledForByeA}
          matchId={matchIdA}
          noOpponent={!seedB}
          seed={showSeed ? seedA : null}
          status={
            teamAMatchCompleted || statusB === BracketResultStatus.Tbd
              ? statusA
              : BracketResultStatus.Tbd
          }
          team={teamA}
          toMatch={toMatch}
          top
        />
        {!isLastSlot && (
          <animated.div className={classes.leg} style={legStyle} />
        )}
        <animated.div className={classes.tail} style={tailStyle} />
        <BracketResultPod
          key={`slot-${slotIndex}-team-${nextTeamIndex}`}
          esportRating={esportRating || EsportRating.General}
          isUnfilledForBye={isUnfilledForByeB}
          matchId={matchIdB}
          noOpponent={!seedA}
          seed={showSeed ? seedB : null}
          status={
            teamBMatchCompleted || statusB === BracketResultStatus.Bye
              ? statusB
              : BracketResultStatus.Tbd
          }
          team={teamB}
          toMatch={toMatch}
        />
      </div>
    </animated.div>
  )
}
