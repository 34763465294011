import { Box, FormLabel, makeStyles, useTheme } from '@material-ui/core'
import {
  CompetitionGroup,
  ResourceType,
  School,
  SchoolType,
  SuggestedSchoolInput,
  useGenerateReferralLinkMutation,
  UserRole,
  UserRoleName,
} from '@plvs/graphql/generated/graphql'
import { CountryCode, assert } from '@plvs/utils'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import * as analytics from '@plvs/respawn/features/analytics'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { getCountryFromState } from '@plvs/rally/components/onboard/helpers'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '@plvs/rally/features/coach/coachLeadGen/CoachLeadComponent'
import { StudentDashboardCoachLeadIncentives } from '@plvs/rally/features/coach/coachLeadGen/StudentDashboardCoachLeadIncentives'

import { noop } from 'ramda-adjunct'
import React, { useEffect } from 'react'

import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { OnboardReferByEmailForm, OnboardReferByLinkForm } from '..'
import { useOnboardingContext } from './OnboardingContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coachLeadContainer: {
    // Max Width 1012px
    maxWidth: theme.spacing(126.5),
  },
  formLabel: { color: theme.palette.ColorTextBase, fontWeight: 600 },
  coachLeadTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 600,
  },
  skipButton: {
    marginTop: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.BorderDark,
  },
}))

export const OnboardingInviteCoach: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()

  // other hooks
  const { data: contextData } = useOnboardingContext()

  const [
    generateReferralLink,
    { data: referralLinkData, loading },
  ] = useGenerateReferralLinkMutation()

  const coachLink = referralLinkData?.generateReferralLink
  const {
    role,
    schoolId,
    schoolType,
    schoolProvince,
    suggestedSchool,
    schoolName,
    isNasefSignUp,
  } = contextData

  const competitionGroup: CompetitionGroup | undefined = {
    [SchoolType.HighSchool]: CompetitionGroup.HighSchool,
    [SchoolType.College]: CompetitionGroup.College,
  }[schoolType as SchoolType]

  const isUsersSchoolInUnitedStates =
    getCountryFromState(schoolProvince as string) === CountryCode.UnitedStates
  const sugesstedSchoolSelected = suggestedSchool as SuggestedSchoolInput
  const school = {
    id: schoolId,
    state: schoolProvince,
    name: sugesstedSchoolSelected?.name ?? schoolName ?? '',
  } as Pick<School, 'id' | 'name' | 'state'>
  const isFaculty = role !== UserRoleName.Student
  const showNASEFUx = isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const onContinue = (): void => {
    if (contextData.role === UserRoleName.Fac) {
      // faculty flow
      navigate(`${Path.SpawnPoint}/faculty/faculty-contact${showNASEFUx}`)
    } else {
      // Student flow
      navigate(`${Path.SpawnPoint}/student-invite-player${showNASEFUx}`)
    }
  }

  useEffect(() => {
    try {
      assert(competitionGroup, 'competition group should be valid')
      generateReferralLink({
        variables: {
          resourceId: schoolId as string,
          resourceType: schoolId
            ? ResourceType.Organization
            : ResourceType.System,
          roleName: schoolId ? UserRoleName.Coach : UserRoleName.User,
          source: 'onboarding_nomination',
          medium: 'share_link',
          competitionGroup: competitionGroup as CompetitionGroup,
        },
      })
    } catch (err: any) {
      noop(err)
    }
  }, [schoolId, competitionGroup])

  const sideView = isFaculty ? undefined : (
    <StudentDashboardCoachLeadIncentives />
  )
  const title = isFaculty ? (
    <NxTypography className={classes.coachLeadTitle} variant="h1">
      Refer a coach to your school
    </NxTypography>
  ) : (
    <Box>
      <NxTypography className={classes.coachLeadTitle} variant="h1">
        Your school needs a coach!
      </NxTypography>
      <NxTypography className={classes.coachLeadTitle} variant="h1">
        Refer a faculty member below
      </NxTypography>
    </Box>
  )

  if (isUsersSchoolInUnitedStates) {
    return (
      <Box display="flex" justifyContent="center" p={8}>
        <Box className={classes.coachLeadContainer}>
          <CoachLeadComponent
            formFooter={
              <NxButton
                className={classes.skipButton}
                data-cy="nextButton"
                label="Next"
                onClick={onContinue}
                variant="text"
              />
            }
            isBorderless
            isFaculty={isFaculty}
            school={school as SchoolPropType}
            setAvailableSchool
            sideView={sideView}
            source={
              isFaculty
                ? 'faculty-signup-coach-lead'
                : 'student-signup-coach-lead'
            }
            title={title}
          />
        </Box>
      </Box>
    )
  }

  return (
    <WaitTillLoaded
      loading={loading || !referralLinkData}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box>
        <Box className={classes.container}>
          <QuestionnaireCard
            childWidth={theme.spacing(50)}
            onContinue={onContinue}
            subtitle="Have someone in mind who would make a great coach? Use the link below to nominate a teacher, administrator or any other school employee to coach your esports team."
            title="Invite a Coach"
          >
            <Box textAlign="center" width="100%">
              <Box>
                <FormLabel className={classes.formLabel}>
                  Share your link
                </FormLabel>
              </Box>
              <OnboardReferByLinkForm
                link={coachLink}
                onClickCopy={(value): void =>
                  analytics.copyInviteLink({
                    inviteLink: value,
                    inviteeUserType: UserRoleName.Coach,
                    myUserRoles: [role as Pick<UserRole, 'roleName'>],
                  })
                }
              />
            </Box>
            <Box mt={3} width="100%">
              <Box textAlign="center">
                <FormLabel className={classes.formLabel}>
                  Invite via email
                </FormLabel>
              </Box>
              <NxTypography colorToken="ColorTextAlt2" variant="body1">
                Your nominee will receive an email from us.
              </NxTypography>
              {/* TODO: Backend currently errors, seems like account not fully
          created at this point.  Need to make api call to fully onboard
        this account before this point */}
              <OnboardReferByEmailForm
                competitionGroup={competitionGroup as CompetitionGroup}
                myUserRoles={[role as Pick<UserRole, 'roleName'>]}
                recipientUserRoleName={UserRoleName.Fac}
                schoolId={schoolId as string}
                source="onboard_nomination"
              />
            </Box>
          </QuestionnaireCard>
        </Box>
      </Box>
    </WaitTillLoaded>
  )
}
