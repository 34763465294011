export type UserNameAndDobInput = {
  firstName: string
  lastName: string
  dateOfBirth: string
}

export type FacultyDetailsInput = {
  otherEmail: string
  phone: string
}

export type StudentDetailsInput = {
  otherEmail: string
}

export enum Checked {
  Personal = 'personal',
  Work = 'work',
  School = 'school',
}
