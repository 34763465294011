import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    background: 'rgba(255, 255, 255, 0.3)',
  },
  barColorPrimary: {
    background: theme.palette.ColorBackgroundInactive,
  },
}))
