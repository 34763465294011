import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

interface CSSProperties {
  valueLength: number
}

export const useStyles = makeStyles((theme) => ({
  accentColor: {
    color: '#FFE600',
  },
  value: {
    fontSize: 64,
    letterSpacing: '-2px',
    fontWeight: 700,
  },
  verticalValue: ({ valueLength }: CSSProperties): CreateCSSProperties => ({
    fontSize: valueLength >= 20 ? theme.spacing(5) : theme.spacing(8),
    fontWeight: 700,
  }),
  avatar: {
    height: 80,
    width: 80,
  },
  playvsCup: {
    height: 99,
    width: 'auto',
  },
  playvsCupContainer: {
    top: -theme.spacing(3),
    right: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      right: 0,
    },
  },
}))
