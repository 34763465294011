import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { GameIncompleteReason } from '@plvs/graphql/generated'
import { isNil } from 'ramda'

import { useStyles } from './MatchReportAccordion.styles'
import {
  GameIncompleteMetaData,
  GameSelectionValue,
  ReportOption,
  ReportOptionValue,
} from './MatchReportAccordion.types'

type MatchReportAccordionFormProps = {
  defaultValues?: GameIncompleteMetaData
  options: ReportOption[]
  gameIndex: number
  loadingContent: boolean
  onSelectOption(gameIndex: number, newValue: GameSelectionValue): void
}

export const MatchReportAccordionForm: React.FC<MatchReportAccordionFormProps> = ({
  onSelectOption,
  options,
  defaultValues,
  gameIndex,
  loadingContent,
}) => {
  const styles = useStyles()

  // Track meta changes locally before saving and bubbling them up.
  const [reason, setReason] = useState<GameIncompleteReason | undefined>(
    defaultValues?.reason
  )
  const [faultId, setFaultId] = useState<
    ReportOptionValue.MyTeam | ReportOptionValue.OppTeam | undefined
  >(defaultValues?.faultId)

  return (
    <form
      onSubmit={(evnt): void => {
        evnt.preventDefault()
        if (!isNil(faultId) && !isNil(reason)) {
          onSelectOption(gameIndex, {
            value: ReportOptionValue.Incomplete,
            meta: { reason, faultId },
          })
        }
      }}
    >
      <div className={styles.incompleteReasons}>
        {/* @TODO Replace with NxRadio  */}
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <NxTypography variant="body2">What happened?</NxTypography>
          </FormLabel>
          <RadioGroup
            aria-label="what-happened"
            name="whatHappened"
            onChange={(_evnt, newValue): void =>
              setReason(newValue as GameIncompleteReason)
            }
            value={reason ?? ''}
          >
            <FormControlLabel
              control={<Radio />}
              disabled={loadingContent}
              label="Team No Show"
              value={GameIncompleteReason.PlayerNoShow}
            />
            <FormControlLabel
              control={<Radio />}
              disabled={loadingContent}
              label="Technical Issue"
              value={GameIncompleteReason.TechnologyIssues}
            />
          </RadioGroup>
        </FormControl>

        {/* @TODO Replace with NxRadio  */}
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <NxTypography variant="body2">What team?</NxTypography>
          </FormLabel>
          <RadioGroup
            aria-label="what-team"
            name="whatTeam"
            onChange={(_evnt, newValue): void => setFaultId(Number(newValue))}
            value={`${faultId}` ?? ''}
          >
            {options.map(({ title, value }) => (
              <FormControlLabel
                key={title}
                control={<Radio />}
                disabled={!reason}
                label={title}
                value={`${value}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <div className={styles.incompleteButtons}>
        <NxButton
          label="Cancel"
          onClick={(): void => {
            setReason(undefined)
            setFaultId(undefined)
          }}
          variant="secondary"
        />
        <NxButton
          disabled={isNil(faultId) || isNil(reason) || loadingContent}
          label="Save"
          type="submit"
          variant="primary"
        />
      </div>
    </form>
  )
}
