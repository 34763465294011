import React from 'react'
import { useTheme } from '@material-ui/core'

import { NxEmptyState } from '@playvs-inc/nexus-components'
import {
  StandingDarkLargeSecondary,
  StandingLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { ObjectivesScope } from '@plvs/respawn/features/esport/creator/types'
import { StatRow, StatRowHeading } from '@plvs/rally/components/match/'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { EsportSlug } from '@plvs/graphql'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useMatchStatsContext } from '@plvs/respawn/features/match-lobby/MatchStatsContainer'
import { LolBandana } from '../components/LolBandana'
import { LolCandy } from '../components/LolCandy'
import { Objectives } from '../components/Objectives'
import { VersusBars } from '../components/VersusBars'

interface GameStatsProps {
  gameId: string
  team1Id: string
  team2Id: string
}

export const GameStats: React.FC<GameStatsProps> = ({
  gameId = '',
  team1Id,
  team2Id,
}) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const { getObjectives, slug } = useEsportContext()

  const { statsData, isLoading } = useMatchStatsContext()
  const objectives = getObjectives(ObjectivesScope.Game)

  if (!isLoading) {
    return (
      <NxEmptyState
        isStandalone
        spot={
          isDarkMode ? (
            <StandingDarkLargeSecondary height={200} width={200} />
          ) : (
            <StandingLightLargeSecondary height={200} width={200} />
          )
        }
        subtitle="Sorry about that!"
        title="No stats found"
      />
    )
  }

  const gameStats = statsData?.match?.games?.find(({ id }) => id === gameId)
  const teamGameStats = gameStats?.teamGameStats ?? []
  const team1GameStats = teamGameStats.find(({ teamId }) => teamId === team1Id)
  const team2GameStats = teamGameStats.find(({ teamId }) => teamId === team2Id)

  const playerGameStats = gameStats?.playerGameStats ?? []
  const team1PlayerGameStats = playerGameStats.filter(
    ({ teamId }) => teamId === team1Id
  )
  const team2PlayerGameStats = playerGameStats.filter(
    ({ teamId }) => teamId === team2Id
  )

  return (
    <WaitTillLoaded
      loading={isLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        {slug === EsportSlug.LeagueOfLegends ? (
          <>
            <StatRow marginTop={0}>
              <Box flex={1}>
                <LolCandy playerGameStats={team1PlayerGameStats} />
              </Box>
              <Box flex={0}>
                <StatRowHeading>Champions</StatRowHeading>
              </Box>
              <Box flex={1}>
                <LolCandy opposing playerGameStats={team2PlayerGameStats} />
              </Box>
            </StatRow>
            <StatRow>
              <LolBandana teamGameStats={team1GameStats} />
              <StatRowHeading>Bans</StatRowHeading>
              <LolBandana opposing teamGameStats={team2GameStats} />
            </StatRow>
          </>
        ) : null}

        {objectives.length ? (
          <StatRow>
            <Objectives teamGameStats={team1GameStats} />
            <StatRowHeading>Objectives</StatRowHeading>
            <Objectives opposing teamGameStats={team2GameStats} />
          </StatRow>
        ) : (
          <></>
        )}
        <VersusBars
          teamAGameStats={team1GameStats}
          teamBGameStats={team2GameStats}
        />
      </>
    </WaitTillLoaded>
  )
}
