import { useEffect, useCallback } from 'react'
import {
  useGetLegacyNotificationsQuery,
  useAcknowledgeNotificationMutation,
} from '@plvs/graphql'
import { useAppDispatch, useAppState } from '../context'
import { notificationsFetched, SingleNotification } from '../notifications'

export const useNotifications = (): {
  notifications: SingleNotification[]
  acknowledge: (id: string) => Promise<void>
} => {
  const { notifications } = useAppState()
  return {
    notifications: notifications?.items || [],
    acknowledge: notifications.acknowledge,
  }
}

export const GetNotifications: React.FC = () => {
  // apollo query
  const { data, refetch } = useGetLegacyNotificationsQuery()

  const [acknowledgeMutation] = useAcknowledgeNotificationMutation()

  const dispatch = useAppDispatch()
  const acknowledge = useCallback(
    async (id): Promise<void> => {
      await acknowledgeMutation({ variables: { notificationId: id } })
      if (refetch) {
        await refetch()
      }
    },
    [refetch]
  )

  // When notifications are loaded
  useEffect(() => {
    if (data) {
      dispatch(notificationsFetched({ data, acknowledge }))
    }
  }, [data])

  // TODO: handle error state,
  // refetch? notification to user that notifications can't be fetched.

  return null
}
