import {
  NotificationDarkSmallPrimary,
  NotificationLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import React from 'react'
import { useTheme } from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

export const NotificationSpotWrapper: React.FC = () => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  return isDarkMode ? (
    <NotificationDarkSmallPrimary height={64} width={64} />
  ) : (
    <NotificationLightSmallPrimary height={64} width={64} />
  )
}
