import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { HighAlertLightSmallPrimary } from '@playvs-inc/nexus-spots'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'

const UpdatePersonalPhoneNumberAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  return (
    <SystemMessage
      IconComponent={<HighAlertLightSmallPrimary height="4rem" />}
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const UpdatePersonalPhoneNumberAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Update Personal Phone Number',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <UpdatePersonalPhoneNumberAdapterContainer notification={notification} />
    )
  },
}
