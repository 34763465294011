import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, useTheme, Box } from '@material-ui/core'
import { noop } from 'ramda-adjunct'

import { NxChat, NxChatMedia, NxGlobalChat } from '@playvs-inc/nexus-chat'
import { NxEmptyState, NxButton, NxTooltip } from '@playvs-inc/nexus-components'
import {
  ChatLightLargeSecondary,
  ChatDarkLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { acceptableFileTypes } from '@plvs/rally/components/form'
import { ChatRole, useCreateMatchChatMutation } from '@plvs/graphql'

import { useSnackbar } from 'notistack'

import * as analytics from '@plvs/respawn/features/analytics'
import { useChatAccessTokenProviderContext } from '@plvs/rally/containers/chat/ChatAccessTokenProvider'
import { mapMediaToNxMedia, NotFound } from './utils'
import { MatchChatProps } from './types'

const fileTypes = [...acceptableFileTypes, 'image/gif']
const MAX_MEDIA_LENGTH = 20

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
  emptyState: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}))

// Due to the refactor of removing the setting of draft, we need to remove messageError. This is a temporary solution. The onSend function checks for errors and handles those properly.
// In the refactor, messageError will be defined in Nexus and propagated to Rally. JIRA: https://playvs.atlassian.net/browse/MATCH-6555
export const MatchChat: React.FC<MatchChatProps> = ({
  matchId,
  draft,
  messages,
  isLoading,
  isSending,
  sendMessage,
  media,
  setMedia,
  errorCode,
  hasOlderMessages,
  loadMessages,
  scrollRef,
  setErrorCode,
  teamIds,
  isCheckpoint,
  chatRole,
  isGlobal,
  teamName,
  esportSlug,
  isCoachChat,
  onClose,
  triggerPulse,
  onExpanded,
  expanded,
  className,
  menuOptions,
  menuBackgroundColor,
  loadingConversationState,
  isStateClosed,
}) => {
  const isPlayer = chatRole === ChatRole.Player
  const {
    accessToken,
    loading,
    shouldDefaultConnectToTwilio,
    setShouldDefaultConnectToTwilio,
  } = useChatAccessTokenProviderContext()
  const showCreateChatButton = isCheckpoint
  const loadingChat = loadingConversationState || isLoading || loading
  const isMobile = useBreakpointSm()
  const theme = useTheme()
  const styles = useStyles({ isMobile })

  // This piece of state is so we can track the time match lobby chat started loading without sending multiple sengrid events.
  const [startedLoadingAt, setStartedLoadingAt] = useState<string>('')

  useEffect(() => {
    setStartedLoadingAt(new Date().toISOString())
  }, [])

  const [createMatchChat] = useCreateMatchChatMutation()
  const { enqueueSnackbar } = useSnackbar()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleAttachClick = (): void => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { files } = event.target
    setMedia([...media, ...Array.from(files ?? [])])

    // Resets input[type='file'] to prevent issue where onChange isn't triggered
    // when re-attaching a removed screenshot
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const handleRemoveClick = (_file: NxChatMedia, ndx: number): void => {
    const newMedia = [...media]
    newMedia.splice(ndx, 1)
    setMedia(newMedia)
  }

  const handleCreateMatchChat = async (): Promise<void> => {
    if (!matchId || !teamIds?.length) {
      return
    }

    try {
      const createdChat = await createMatchChat({
        variables: {
          input: { matchId: matchId ?? '', teamIds },
        },
      })

      if (createdChat?.data?.createMatchChat?.success) {
        setErrorCode(undefined)

        enqueueSnackbar('Match chat successfully created', {
          variant: 'success',
        })
      }
    } catch (err) {
      enqueueSnackbar('Match chat creation failed', {
        variant: 'error',
      })
    }
  }

  const isAttachDisabled = media.length > MAX_MEDIA_LENGTH || isPlayer

  const createChat = matchId && !errorCode && accessToken

  useEffect(() => {
    if (!loadingChat) {
      analytics.matchLobbyChatLoaded({
        startedLoading: startedLoadingAt,
        archivedChat: isStateClosed ?? false,
        finishedLoading: new Date().toISOString(),
        chatUnqiueName: matchId ?? '',
      })
    }
  }, [loadingChat])

  const chatComponent = isGlobal ? (
    <NxGlobalChat
      ref={scrollRef}
      // @ts-ignore the same enum will never be deemed the same between two repos
      chatRole={chatRole}
      className={className}
      data={messages ?? []}
      // @ts-ignore TODO: Fix this
      esportSlug={esportSlug || ''}
      expanded={expanded}
      global
      hasOlderMessages={hasOlderMessages}
      isAttachDisabled={isAttachDisabled}
      isCoachChat={isCoachChat}
      isLoading={isLoading || loading}
      isSendDisabled={isSending}
      loadingConversationState={loadingConversationState}
      media={mapMediaToNxMedia(media)}
      menuBackgroundColor={menuBackgroundColor}
      menuOptions={menuOptions}
      onClickAttach={handleAttachClick}
      onClickEmote={noop}
      onClickRemove={handleRemoveClick}
      onClose={onClose}
      onExpanded={onExpanded}
      onLoadMessages={loadMessages}
      onSend={sendMessage}
      stateIsClosed={isStateClosed}
      teamName={teamName}
      triggerPulse={triggerPulse}
      value={draft}
    />
  ) : (
    <NxChat
      ref={scrollRef}
      // @ts-ignore the same enum will never be deemed the same between two repos
      chatRole={chatRole}
      className={className}
      data={messages ?? []}
      global={false}
      hasOlderMessages={hasOlderMessages}
      isAttachDisabled={isAttachDisabled}
      isLoading={isLoading || loading}
      isSendDisabled={isSending}
      loadingConversationState={loadingConversationState}
      media={mapMediaToNxMedia(media)}
      onClickAttach={handleAttachClick}
      onClickEmote={noop}
      onClickRemove={handleRemoveClick}
      onLoadMessages={loadMessages}
      onSend={sendMessage}
      stateIsClosed={isStateClosed}
      value={draft}
    />
  )

  const showComponentToRequestTwilioAccessInStaging = accessToken
    ? false
    : !shouldDefaultConnectToTwilio

  return (
    <Box data-cy="chatWindow">
      <input
        ref={inputRef}
        accept={fileTypes.join(',')}
        className={styles.hidden}
        multiple
        onChange={handleFileChange}
        type="file"
      />

      {createChat && chatComponent}

      {showComponentToRequestTwilioAccessInStaging && !loading && (
        <NxEmptyState
          className={styles.emptyState}
          cta={
            <NxTooltip title="Chat will still be to created in Checkpoint first">
              <NxButton
                label="Request Access"
                onClick={(): void => {
                  setShouldDefaultConnectToTwilio?.(true)
                }}
                variant="secondary"
              />
            </NxTooltip>
          }
          spot={
            theme.palette.type === MUIThemeModeEnum.Light ? (
              <ChatLightLargeSecondary height={200} width={200} />
            ) : (
              <ChatDarkLargeSecondary height={200} width={200} />
            )
          }
          subtitle="Request access in Checkpoint before creating a chat in staging."
          title="Request Access to Create Chat"
        />
      )}

      {errorCode && (
        <NxEmptyState
          className={styles.emptyState}
          cta={
            showCreateChatButton &&
            errorCode === NotFound && (
              <NxButton
                label="Create Chat"
                onClick={handleCreateMatchChat}
                variant="secondary"
              />
            )
          }
          spot={
            theme.palette.type === MUIThemeModeEnum.Light ? (
              <ChatLightLargeSecondary height={200} width={200} />
            ) : (
              <ChatDarkLargeSecondary height={200} width={200} />
            )
          }
          subtitle="Chat may take a few minutes to load, please wait a moment and refresh."
          title="Loading Chat..."
        />
      )}
    </Box>
  )
}
