import React from 'react'
import {
  NxButton,
  NxSpotModal,
  NxRadioButtonGroup,
  NxTypography,
  Pill,
} from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots'
import { Path } from '@plvs/const'
import {
  CompetitionGroup,
  useChangeSchoolIdForUserMutation,
  useGetSchoolRolesQuery,
} from '@plvs/graphql/generated'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core'

interface Props {
  onClose(): void
  open: boolean
  orgId: string
}

const useStyles = makeStyles((theme) => ({
  schoolTypeText: {
    margin: theme.spacing(0, 0, 2, 1.5),
  },

  label: {
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
    },
  },
}))

export const SwitchSchoolModal: React.FC<Props> = ({
  onClose,
  open,
  orgId,
}) => {
  const [schoolId, setSchoolId] = React.useState(orgId)
  const [radioProps, setRadioProps] = React.useState({
    hsProps: {},
    msProps: {},
  })
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const { data, loading } = useGetSchoolRolesQuery({ skip: !open })

  const [
    changeSchool,
    { loading: changeSchoolLoading },
  ] = useChangeSchoolIdForUserMutation()

  const handleClose = (): void => {
    setRadioProps({ hsProps: {}, msProps: {} })
    onClose()
  }

  const handleContinue = async (): Promise<void> => {
    try {
      if (orgId === schoolId) {
        handleClose()
        return
      }

      const result = await changeSchool({ variables: { schoolId } })
      if (result?.data?.changeSchoolIdForUser?.success) {
        handleClose()
        window.location.replace(`${Path.Dashboard}`)
      }
    } catch {
      enqueueSnackbar('Error changing school', { variant: 'error' })
    }
  }

  const getFilteredRadioOptions = ({ schoolRoles, competitionGroup }): any => {
    return schoolRoles
      .filter((item) => item?.school?.competitionGroup === competitionGroup)
      .sort((a, b) => {
        const schoolA = a?.school?.name?.toLowerCase() ?? ''
        const schoolB = b?.school?.name?.toLowerCase() ?? ''
        return schoolA.localeCompare(schoolB)
      })
      .map((item) => ({
        label: (
          <Box
            data-testid={`test-school-${item?.school?.name}`}
            display="flex"
            gridGap="10px"
          >
            <NxTypography className={classes.label} variant="body1">
              {item?.school?.name ?? ''}
            </NxTypography>
            {item?.schoolId === orgId && (
              <Pill label="Selected" size="small" variant="info" />
            )}
          </Box>
        ),
        value: item?.schoolId ?? '',
      }))
  }

  const roleData = data?.getSchoolRoles?.roles ?? []

  const highSchoolRadioOptions = getFilteredRadioOptions({
    schoolRoles: [...roleData],
    competitionGroup: CompetitionGroup.HighSchool,
  })

  const middleSchoolRadioOptions = getFilteredRadioOptions({
    schoolRoles: [...roleData],
    competitionGroup: CompetitionGroup.MiddleSchool,
  })

  const handleMsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSchoolId((event.target as HTMLInputElement).value)
    setRadioProps({ hsProps: { checked: false }, msProps: {} })
  }

  const handleHsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSchoolId((event.target as HTMLInputElement).value)
    setRadioProps({ hsProps: {}, msProps: { checked: false } })
  }

  return (
    <NxSpotModal
      actions={
        <>
          <NxButton
            data-testid="cancel-button"
            label="Cancel"
            onClick={handleClose}
            variant="text"
          />
          <NxButton
            data-testid="continue-button"
            disabled={changeSchoolLoading || !schoolId}
            label="Continue"
            onClick={handleContinue}
            variant="primary"
          />
        </>
      }
      onClose={handleClose}
      open={open}
      spot={
        <NxSpot
          domain="School"
          height={100}
          size="large"
          variant="primary"
          width={100}
        />
      }
      subtitle="Once you choose a school, you'll be taken directly to the coach view for that school."
      title="Select a School to Manage"
    >
      <WaitTillLoaded
        loading={
          loading && (!middleSchoolRadioOptions || !highSchoolRadioOptions)
        }
        showSpinnerWhileLoading
      >
        {middleSchoolRadioOptions.length > 0 && (
          <Box mb={2}>
            <NxTypography className={classes.schoolTypeText} variant="overline">
              Middle School
            </NxTypography>
            <NxRadioButtonGroup
              {...radioProps.msProps}
              defaultValue={orgId}
              onChange={handleMsChange}
              radioOptions={middleSchoolRadioOptions}
            />
          </Box>
        )}
        {highSchoolRadioOptions.length > 0 && (
          <>
            <NxTypography className={classes.schoolTypeText} variant="overline">
              High School
            </NxTypography>
            <NxRadioButtonGroup
              {...radioProps.hsProps}
              defaultValue={orgId}
              onChange={handleHsChange}
              radioOptions={highSchoolRadioOptions}
            />
          </>
        )}
      </WaitTillLoaded>
    </NxSpotModal>
  )
}
