import { yupResolver } from '@hookform/resolvers'
import { Box, TextField, makeStyles } from '@material-ui/core'
import {
  CompetitionGroup,
  Maybe,
  ReferByEmailInput,
  ResourceType,
  useReferByEmailMutation,
  useReferParentByEmailMutation,
  UserRole,
  UserRoleName,
} from '@plvs/graphql'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  userRoleNamesToUserType,
  cleanGraphQLError,
  formErrorToString,
  yupEmailRequired,
} from '@plvs/utils'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
}))

const OnboardInviteByEmailFormSchema = yup.object().shape({
  email: yupEmailRequired,
})

interface Props {
  recipientUserRoleName:
    | UserRoleName.Student
    | UserRoleName.Fac
    | UserRoleName.User
    | UserRoleName.Parent
  myUserRoles: Pick<UserRole, 'roleName'>[] | []
  source?: string
  competitionGroup?: Maybe<CompetitionGroup>
  schoolId?: string
}

type FormInput = Pick<ReferByEmailInput, 'email'>

const userRoleMap = {
  [UserRoleName.Student]: 'player',
  [UserRoleName.Fac]: 'faculty',
  [UserRoleName.User]: 'user',
  [UserRoleName.Parent]: 'parent',
}

export const OnboardReferByEmailForm = ({
  recipientUserRoleName,
  myUserRoles,
  source,
  competitionGroup,
  schoolId,
}: Props): React.ReactElement => {
  const { errors, handleSubmit, register, reset, getValues } = useForm<
    FormInput
  >({
    resolver: yupResolver<FormInput>(OnboardInviteByEmailFormSchema),
  })
  const [isBusy, setIsBusy] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()
  const [email, setEmail] = React.useState('')

  const [mutate] = useReferByEmailMutation()
  const [referParentByEmail] = useReferParentByEmailMutation()

  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  // Form handlers
  const onFormUpdate = (): void => {
    const values = getValues()
    if (values.email && /^\S+@\S+\.\S+$/.test(values.email)) {
      setEmail(values.email)
      setIsBusy(false)
    } else {
      setIsBusy(true)
    }
  }

  const onSendInvite = async (): Promise<void> => {
    setError(undefined)
    setIsBusy(true)

    const isParent = recipientUserRoleName === UserRoleName.Parent

    try {
      if (isParent) {
        await referParentByEmail({
          variables: {
            input: {
              source,
              email,
            },
          },
        })
      } else {
        await mutate({
          variables: {
            input: {
              resourceId: schoolId,
              resourceType: schoolId
                ? ResourceType.Organization
                : ResourceType.System,
              email,
              roleName: schoolId ? recipientUserRoleName : UserRoleName.User,
              source,
              competitionGroup,
            },
          },
        })
      }

      analytics.inviteByEmail({
        inviteeEmails: [email],
        inviteeUserType: userRoleNamesToUserType([recipientUserRoleName]),
        myUserRoles,
      })
      reset()
      enqueueSnackbar('Invite sent', { variant: 'success' })
    } catch (e: any) {
      setError(e)
    } finally {
      setIsBusy(false)
    }
  }

  const onSubmit = handleSubmit(
    async (): Promise<void> => {
      return onSendInvite()
    }
  )

  return (
    <form noValidate onChange={onFormUpdate} onSubmit={onSubmit}>
      {error && cleanGraphQLError(error.message)}
      <NxTypography variant="body3">
        Invite {userRoleMap[recipientUserRoleName]} via email
      </NxTypography>
      <Box alignItems="center" display="flex" width="100%">
        <TextField
          className={classes.textField}
          error={!!(error || errors.email)}
          helperText={formErrorToString(errors.email)}
          inputRef={register}
          name="email"
          size="small"
          type="email"
          variant="outlined"
        />
        <NxButton
          className={classes.button}
          data-testid="submit"
          disabled={isBusy}
          label={`Invite ${userRoleMap[recipientUserRoleName]}`}
          onClick={onSendInvite}
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}
