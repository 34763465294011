import { makeStyles, useTheme } from '@material-ui/core'
import {
  NASEF_QUERY_PARAM,
  Path,
  collegeRolesOptions,
  highSchoolRolesOptions,
} from '@plvs/const'
import { SchoolType } from '@plvs/graphql/generated/graphql'
import { OnboardRoleDescriptionOptions } from '@plvs/rally/components/onboard/OnboardRoleDescriptionOptions'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import { cleanGraphQLError } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import React, { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from './OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
}))

export const OnboardFalcultyRole: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const { assign, data: contextData } = useOnboardingContext()

  // computed props
  const options =
    contextData.schoolType === SchoolType.HighSchool
      ? highSchoolRolesOptions
      : collegeRolesOptions

  const showNASEFUx = contextData?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  // state vars
  const [facultyRole, setFacultyRole] = useState<string | null>(
    contextData.facultyRole as string
  )

  const onSubmit = async (evt: SyntheticEvent): Promise<void> => {
    evt.preventDefault()
    try {
      assign({
        facultyRole,
      })

      navigate(`${Path.SpawnPoint}/faculty/planning-to-coach${showNASEFUx}`)
    } catch (err: unknown) {
      if (err instanceof Error) {
        enqueueSnackbar(cleanGraphQLError(err.message), { variant: 'error' })
      } else throw err
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <QuestionnaireCard
        childWidth={theme.spacing(50)}
        disableContinue={!facultyRole}
        onContinue={(): void => {}}
        subtitle="Select the role that best describes your job function at your school."
        title="What‘s your role at your school?"
      >
        <OnboardRoleDescriptionOptions
          checked={facultyRole}
          onChange={(opt): void => setFacultyRole(opt)}
          options={options}
        />
      </QuestionnaireCard>
    </form>
  )
}
