import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  mapOption: ({ isMyTurn }: { isMyTurn: boolean }): CreateCSSProperties => ({
    border: `2px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.mixins.cornerRadius['border-radius-2'],

    '&:hover': {
      borderColor: !isMyTurn
        ? theme.palette.BorderLight
        : theme.palette.BorderDark,
    },
  }),

  checkbox: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  icon: ({ isMyTurn }: { isMyTurn: boolean }): CreateCSSProperties => ({
    borderRadius: theme.mixins.cornerRadius['border-radius-4'],
    width: theme.spacing(2),
    height: theme.spacing(2),
    border: `2px solid ${theme.palette.BorderMedium}`,
    padding: 0,

    'input:hover ~ &': {
      borderColor: !isMyTurn
        ? theme.palette.BorderMedium
        : theme.palette.BorderDark,
    },

    '& ~ input': {
      padding: 0,
    },
  }),

  checkedIcon: {
    borderRadius: theme.mixins.cornerRadius['border-radius-4'],
    width: theme.spacing(2),
    height: theme.spacing(2),
    border: `2px solid ${theme.palette.BorderDark}`,
    background: theme.palette.BorderDark,
    color: theme.palette.ColorTextInvert,

    '&> svg': {
      position: 'absolute',
      top: '-10%',
      left: '-10%',
    },
  },

  selectedMapOption: {
    borderColor: theme.palette.ColorTextSuccess,

    background: theme.palette.ColorBackgroundSuccess,
  },

  disabledMapOption: {
    border: theme.palette.BorderMedium,

    '&:hover': {
      border: theme.palette.BorderMedium,
    },
  },
}))
