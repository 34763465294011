import React from 'react'
import { Colors } from '@plvs/rally/themes/colors'
import { createSvgIcon } from '../createSvgIcon'

export const EmptyAvatar = createSvgIcon(
  <svg fill="none" height="28" viewBox="0 0 28 28" width="28">
    <circle
      cx="14"
      cy="14"
      fill={Colors.Grey4}
      r="13"
      stroke={Colors.Grey6}
      strokeWidth="2"
    />
  </svg>,
  'EmptyAvatar',
  { viewBox: '0 0 28 28' }
)
