import { useProductTours } from '@plvs/rally/init/shepherd/useProductTours'
import {
  findMatchTourClicked,
  productTourClicked,
} from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/rally/init/dayjs'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Links } from '@plvs/respawn/renderController/checkpoint/appDrawer/types/appDrawerRenderController.types'
import { useNavigate } from 'react-router-dom'

const ONBOARDING_TOUR_STEP = {
  competitions: 2,
  mySchool: 3,
  manageTeams: 5,
  schedule: 8,
  standings: 11,
}

export const useLink = (link: Links): Links => {
  const navigate = useNavigate()
  const { userId } = useUserIdentityFn()

  const {
    onboardingTour,
    initiatedTimestamp,
    findMatchTour,
  } = useProductTours()

  const handleClick = (): void => {
    if (findMatchTour?.isActive()) {
      findMatchTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 2,
        clickTarget: 'Next Button',
      })
      findMatchTour?.next()
      navigate(link.to)
      return
    }

    if (onboardingTour?.isActive()) {
      productTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: link.className ? ONBOARDING_TOUR_STEP[link.className] : 0,
        clickTarget: `Navigation ${link.label} Button`,
      })
      onboardingTour?.next()
      navigate(link.to)
      return
    }

    navigate(link.to)
  }

  return {
    ...link,
    handleOnClick: handleClick,
  }
}
