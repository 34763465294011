import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { toDate, format } from 'date-fns-tz'

export const RescheduleConfirm: React.FC<{
  acceptedDate: string
}> = ({ acceptedDate }) => {
  return (
    <>
      <NxTypography colorToken="ColorTextAlt2" variant="overline">
        Rescheduled To
      </NxTypography>
      <Box display="flex" mt={1}>
        <Box mr={1} width="100%">
          <NxTypography variant="body2">
            {format(toDate(acceptedDate), "EEEE MMM do, yyyy 'at' h:mma zz")}
          </NxTypography>
        </Box>
      </Box>
    </>
  )
}
