import { Card, CardContent, makeStyles } from '@material-ui/core'
import {
  refetchGetMySchoolDetailsQuery,
  useGetMySchoolDetailsQuery,
  useUpdateSchoolDetailMutation,
} from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import { useWithSinglePendingRequest } from '@plvs/rally/libs/request-utils/useWithSinglePendingRequest'
import { assert } from '@plvs/utils'
import React, { FunctionComponent, useEffect } from 'react'
import {
  NxTypography,
  NxTextLink,
  NxCheckbox,
} from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  link: {
    color: theme.palette.ColorTextLink,
    textDecoration: 'none',
  },
  checkbox: {
    '& < .svg': {
      color: 'black !important',
      fill: 'black !important',
    },
    '& > .svg': {
      color: 'black !important',
      fill: 'black !important',
    },
    marginLeft: theme.spacing(-1.25),
  },
  checkboxLabel: {
    cursor: 'pointer',
  },
  textLink: {
    textTransform: 'lowercase',
    display: 'inline-block',
  },
}))

const ITAcknowledgementsInfo: FunctionComponent = () => {
  const classes = useStyles()

  // Queries
  const { data } = useGetMySchoolDetailsQuery()

  // Hooks
  const withSaveNotification = useWithSaveNotification()
  const withSinglePendingRequest = useWithSinglePendingRequest()

  // Derived Props
  const itDetails = data?.me?.school?.detail?.it
  const agreeToInstallOrig = itDetails?.agreeToInstallGames ?? false
  const agreeToPortsOrig = itDetails?.agreeToWhitelistPorts ?? false
  const agreeToUpdatesOrig = itDetails?.agreeToDoPatchUpdates ?? false
  const schoolId = data?.me?.school?.id

  // State
  const [agreeToInstall, toggleInstall] = React.useState<boolean>(
    agreeToInstallOrig
  )
  const [agreeToPorts, togglePorts] = React.useState<boolean>(agreeToPortsOrig)
  const [agreeToUpdates, toggleUpdates] = React.useState<boolean>(
    agreeToUpdatesOrig
  )

  // Mutations
  const [mutateSchoolDetails] = useUpdateSchoolDetailMutation({
    refetchQueries: [refetchGetMySchoolDetailsQuery()],
  })

  // Side Effects
  useEffect(() => {
    if (agreeToInstall === agreeToInstallOrig) {
      return
    }

    withSaveNotification(
      async (): Promise<void> => {
        assert(schoolId)
        await mutateSchoolDetails({
          variables: {
            schoolId,
            detail: {
              it: {
                agreeToInstallGames: agreeToInstall,
              },
            },
          },
        })
      }
    )
  }, [agreeToInstall, agreeToInstallOrig, schoolId])

  useEffect(() => {
    if (agreeToPorts === agreeToPortsOrig) {
      return
    }
    withSinglePendingRequest(async () => {
      await withSaveNotification(
        async (): Promise<void> => {
          assert(schoolId)
          await mutateSchoolDetails({
            variables: {
              schoolId,
              detail: {
                it: {
                  agreeToWhitelistPorts: agreeToPorts,
                },
              },
            },
          })
        }
      )
    })
  }, [agreeToPorts, agreeToPortsOrig, schoolId])

  useEffect(() => {
    if (agreeToUpdates === agreeToUpdatesOrig) {
      return
    }

    withSaveNotification(
      async (): Promise<void> => {
        assert(schoolId)
        await mutateSchoolDetails({
          variables: {
            schoolId,
            detail: {
              it: {
                agreeToDoPatchUpdates: agreeToUpdates,
              },
            },
          },
        })
      }
    )
  }, [agreeToUpdates, agreeToUpdatesOrig, schoolId])

  return (
    <Card>
      <CardContent className={classes.container}>
        <Box mb={2}>
          <NxTypography variant="h4">IT Acknowledgements</NxTypography>
        </Box>
        <NxTypography variant="body2">
          Certain technical steps are needed to ensure your school can compete
          on the PlayVS platform each match day.
        </NxTypography>
        <NxTypography variant="body2">
          Please confirm you understand these steps.
        </NxTypography>
        <Box display="flex" flexDirection="column" mt={2}>
          <Box alignItems="center" display="flex">
            <NxCheckbox
              checked={agreeToInstall}
              className={classes.checkbox}
              data-cy="agree-to-install-checkbox"
              onChange={(): void => toggleInstall(!agreeToInstall)}
            />
            <NxTypography
              className={classes.checkboxLabel}
              onClick={(): void => toggleInstall(!agreeToInstall)}
              variant="body1"
            >
              I agree to install the game(s) in which my team will compete.
            </NxTypography>
          </Box>
          <Box alignItems="center" display="flex">
            <NxCheckbox
              checked={agreeToPorts}
              className={classes.checkbox}
              data-cy="agree-to-ports-checkbox"
              onChange={(): void => togglePorts(!agreeToPorts)}
            />
            <NxTypography
              className={classes.checkboxLabel}
              onClick={(): void => togglePorts(!agreeToPorts)}
              variant="body1"
            >
              I agree to{' '}
              <NxTextLink
                className={classes.textLink}
                href="https://help.playvs.com/en/articles/4919231"
                label="whitelist the correct firewall ports"
                labelVariant="body1"
              />{' '}
              to ensure connectivity during matchplay.
            </NxTypography>
          </Box>
          <Box alignItems="center" display="flex">
            <NxCheckbox
              checked={agreeToUpdates}
              className={classes.checkbox}
              data-cy="agree-to-updates-checkbox"
              onChange={(): void => toggleUpdates(!agreeToUpdates)}
            />
            <NxTypography
              className={classes.checkboxLabel}
              onClick={(): void => toggleUpdates(!agreeToUpdates)}
              variant="body1"
            >
              I agree to keep the game software up-to-date with the latest
              version during the course of the season.
            </NxTypography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ITAcknowledgementsInfo
