import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { omit, head, uniqBy } from 'ramda'

import {
  useTeamLeaguesFiltersQuery,
  TeamLeaguesFiltersQueryResult,
} from '@plvs/graphql'
import {
  RelativeTiming,
  findPromotedMetaseason,
  MinimalMetaseason,
  mapMetaseasonsForFilters,
} from '@plvs/utils'
import {
  LeagueFilterContext,
  SetDefaultMetaseason,
  SetDefaultLeague,
  MinimalSeason,
  MinimalTeam,
} from '@plvs/respawn/features/filters/types'
import { Provider } from '@plvs/respawn/containers/filter/league/context'
import {
  mapLeagueToMinimalLeague,
  mapToMinimalPhase,
  defaultSetLeague,
  sortPhaseByActiveThenStartsAt,
} from './helpers'

interface TeamLeaguesProps {
  setDefaultMetaseason?: SetDefaultMetaseason<TeamLeaguesFiltersQueryResult>
  setDefaultLeague?: SetDefaultLeague<TeamLeaguesFiltersQueryResult>
  teamId: string
}

export const setDefaultMetaseasonStats: SetDefaultMetaseason<TeamLeaguesFiltersQueryResult> = (
  metaseasons
) => {
  return (
    metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Past) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Future)
  )
}

export const setDefaultMetaseasonTeam: SetDefaultMetaseason<TeamLeaguesFiltersQueryResult> = (
  metaseasons
) => {
  return (
    metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Past)
  )
}

export const TeamLeagues: React.FC<TeamLeaguesProps> = ({
  children,
  setDefaultMetaseason = setDefaultMetaseasonStats,
  setDefaultLeague = defaultSetLeague,

  teamId,
}) => {
  // Route will only render for teams
  const isCoach = false
  const [metaseasonId, setMetaseason] = useState<string | null>(null)
  const [leagueId, setLeague] = useState<string>()
  const [phaseId, setPhase] = useState<string>()

  // TODO remove ignore once phse issue around isPartial of null is resolved
  const { data: leagueFilterData, loading, error } = useTeamLeaguesFiltersQuery(
    {
      variables: {
        teamId,
        hasMetaseason: !!metaseasonId,
        metaseasonId,
      },
      errorPolicy: 'ignore',
      skip: !teamId,
    }
  )

  const data = leagueFilterData

  const value: LeagueFilterContext = {
    leagues: [],
    teams: [],
    loading: true,
    error,
    setMetaseason,
    metaseasons: [],
    setLeague,
    setTeam: (): void => {},
    phases: [],
    setCompetitionModel: () => {},
    setPhase,
    defaultPhases: [],
  }

  let derivedMetaseason: MinimalMetaseason | undefined

  if (!loading) {
    value.loading = false
  }

  if (data && !loading && !error) {
    // Filter map data to minimal structure
    // Metaseason
    const enrolledSeasons = data?.team?.enrolledSeasons ?? []
    const metaseasons = mapMetaseasonsForFilters({
      metaseasons: enrolledSeasons.map(
        (enrolledSeason) => enrolledSeason?.metaseason as MinimalMetaseason
      ),
    })

    const promotedMetaseason = findPromotedMetaseason({ metaseasons })

    derivedMetaseason =
      metaseasons.find((ms) => ms.id === metaseasonId) ||
      setDefaultMetaseason(metaseasons, data)

    // Season
    const seasons = enrolledSeasons.map((enrolledSeason) => ({
      ...enrolledSeason,
      name: enrolledSeason.name ?? '',
    }))

    // Leagues
    const eligibleLeagues = uniqBy(
      (league) => league?.id,
      enrolledSeasons.map((enrolledSeason) => enrolledSeason.league) ?? []
    ).filter((l) => l) as Array<
      NonNullable<typeof enrolledSeasons[0]['league']>
    >

    const leagues = eligibleLeagues.map(mapLeagueToMinimalLeague)
    const selectedLeague =
      leagues.find((league) => league.id === leagueId) ||
      setDefaultLeague(leagues, data, isCoach)

    const selectedSeason = head(
      seasons.filter(
        ({ metaseason }) => metaseason?.id === derivedMetaseason?.id
      )
    )

    const season = {
      ...omit(['leagues', 'phases'], selectedSeason),
      name: selectedSeason?.name ?? '',
    } as MinimalSeason
    // Phases
    const defaultPhases = selectedSeason?.phases ?? []
    const phases = defaultPhases
      .map(mapToMinimalPhase)
      .sort((a, b) => dayjs(a?.startsAt).diff(dayjs(b?.startsAt)))

    let phase = phases.find(({ id }) => id === phaseId)
    if (!phase) {
      ;[phase] = sortPhaseByActiveThenStartsAt(phases).reverse()
    }

    // Teams
    const team = data.team as MinimalTeam
    const teams = [team]

    // Assign everything to the value

    value.esportSlug = selectedLeague?.esport?.slug || team?.esport?.slug
    value.promotedMetaseason = promotedMetaseason
    value.metaseason = derivedMetaseason
    value.metaseasons = metaseasons
    value.season = season
    value.league = selectedLeague
    value.leagues = leagues
    value.teams = teams
    value.team = team
    value.defaultPhases = defaultPhases
    value.phases = phases
    value.phase = phase
    value.competitionModel = undefined // competitionModel
    value.loading = false
  }

  useEffect(() => {
    if (derivedMetaseason && !metaseasonId) {
      setMetaseason(derivedMetaseason.id)
      setPhase(undefined)
    }
  }, [derivedMetaseason, metaseasonId, data])

  return <Provider value={value}>{children}</Provider>
}
