import React from 'react'
import { makeStyles, Link } from '@material-ui/core'

import { RegionalNationalWhite } from '@plvs/rally/components/icon/logos'
import { RouterLink } from '@plvs/respawn/features/route'
import { NxTypography } from '@playvs-inc/nexus-components'

interface MatchCardBannerProps {
  subtitle: string
  path: string
}

const useStyles = makeStyles({
  stateHeader: {
    marginLeft: 8,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const MatchCardBanner: React.FC<MatchCardBannerProps> = ({
  subtitle,
  path,
}) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.link}
      color="inherit"
      component={RouterLink}
      to={path}
      underline="none"
    >
      <RegionalNationalWhite />
      <NxTypography
        className={classes.stateHeader}
        colorToken="OverlayColorTextBase"
        variant="button"
      >
        {subtitle}
      </NxTypography>
    </Link>
  )
}
