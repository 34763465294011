// Unable to effectively unit test.
/* istanbul ignore file */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

type UseScrolledIntoViewReturn = {
  isSupported: boolean
  isVisible: boolean
  ScrolledIntoViewTrigger: React.FC
}

export interface ScrolledIntoViewOptions {
  /**
   * Buffer margin around the root element.
   */
  rootMargin: number
  /**
   * Threshold margin for intersection detection.
   * [0, 0.8] => as soon as 80% of the element is visible.
   */
  threshold: [number, number]
}

/**
 * Detects when the hook element is scrolled into view.
 * 
 * Usage:
 * 
 * const {
    isVisible: loadingVisible,
    ScrolledIntoViewTrigger,
  } = useScrolledIntoView()

  ...
  
  useEffect(() => {
    console.log('scrolled into view')
  }, [isVisible])

  return <>
    ...
    <ScrolledIntoViewTrigger/>
  </>
 */
export const useScrolledIntoView = (
  options?: ScrolledIntoViewOptions
): UseScrolledIntoViewReturn => {
  const isIntersectionObserverSupported = !!window.IntersectionObserver
  const [isVisible, setIsVisible] = useState<boolean>(false)

  // This round about way is so the low level listener used inside the intersection observer
  // is able to read the most current value and not the stale value from when the useEffect was
  // run.
  const isVisibleRef = useRef<boolean>(false)
  isVisibleRef.current = isVisible

  const triggerRef = useRef<HTMLElement>(null)
  const intersectionObsRef = useRef<IntersectionObserver>()

  useEffect(() => {
    // Shortcircuit if the browser does not support intersection observer.
    if (!isIntersectionObserverSupported) {
      return
    }

    const intersectionObserverOptions: IntersectionObserverInit = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      root: document as any,
      rootMargin: options?.rootMargin ? `${options?.rootMargin}px` : '20px',
      threshold: options?.threshold ?? [0, 0.8],
    }

    intersectionObsRef.current = new IntersectionObserver(async (entries) => {
      const didIntersect = entries.reduce(
        (acc, entry) => acc || entry.isIntersecting,
        false
      )
      if (isVisibleRef.current !== didIntersect) {
        setIsVisible(didIntersect)
      }
    }, intersectionObserverOptions)
  }, [])

  return {
    isSupported: isIntersectionObserverSupported,
    isVisible,
    ScrolledIntoViewTrigger: (): React.ReactElement => {
      useLayoutEffect(() => {
        if (triggerRef.current) {
          intersectionObsRef.current?.observe(triggerRef.current)
        }

        return (): void => {
          intersectionObsRef.current?.unobserve(triggerRef.current as Element)
        }
      }, [])

      return <span ref={triggerRef} style={{ width: 1, height: 1 }} />
    },
  }
}
