import { makeStyles } from '@material-ui/core'

import { RewindAssets } from '../Rewind.helpers'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 80,
    width: 80,
  },

  nameSpiral: {
    background: `url(${RewindAssets.Spiral})`,
    height: 56,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  content: {
    position: 'relative',
    padding: theme.spacing(3),
    minHeight: 171,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
      gap: theme.spacing(2),
    },
  },
}))
