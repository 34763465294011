import React, { useState } from 'react'
import {
  useGetEligiblePlayersForTeamQuery,
  EsportSlug,
  useRepositionPlayersInTeamMutation,
  Provider,
} from '@plvs/graphql'
import { ApolloError } from '@apollo/client'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSnackbar } from 'notistack'
import { cleanGraphQLError } from '@plvs/utils'
import { head } from 'ramda'
import { Button, makeStyles, TextField } from '@material-ui/core'
import { NxTypography, NxModal } from '@playvs-inc/nexus-components'

import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { RosterRowData } from '@plvs/respawn/features/roster/modal/RosterRow'
import { useAddPlayerToTeamHook } from './useAddPlayerToTeamHook'
import { esportConnectionsPlatformMap } from '../../esport/creator/esportStaticDetails'
import {
  getEligiblePlayerDisabledReasonText,
  repositionPlayer,
} from '../helpers'
import { Banner, BannerType } from '../../banner'
import { useGetAdapter } from '../../esport/adapters'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.ColorTextBase,
  },
  content: {
    '& .MuiDialogContent-root': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
    maxHeight: '796px',
    overflow: 'auto',
  },
  confirmationText: {
    marginLeft: theme.spacing(2),
  },
}))

const createRosterRowDataList = ({
  benchList,
  verifiedStudentList,
}: {
  benchList: RosterRowData[]
  verifiedStudentList: RosterRowData[]
}): Array<{ label?: string; list?: RosterRowData[] }> => {
  const finalList: { label: string; list: RosterRowData[] }[] = []
  if (benchList.length) {
    finalList.push({ label: 'Bench', list: benchList })
  }
  if (verifiedStudentList.length) {
    finalList.push({ label: 'Verified Students', list: verifiedStudentList })
  }
  return finalList
}
export interface AddPlayerToTeamProps {
  teamId: string
  positionIndex: number | null
  onMutationSuccess?(returnedTeamId?: string): Promise<void>
  closeDialog(): void
  isDialogOpen: boolean
  esportSlug: EsportSlug
  metaseasonId: string
  leagueId: string
}

export const AddPlayerToTeam: React.FC<AddPlayerToTeamProps> = ({
  teamId,
  positionIndex,
  onMutationSuccess,
  closeDialog,
  isDialogOpen,
  esportSlug,
  metaseasonId,
  leagueId,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { getUsername } = useGetAdapter(esportSlug)
  const [userSelected, setUserSelected] = useState<{
    id: string
  }>({ id: '' })
  const [error, setError] = useState<Error>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const supportedPlatforms = new Set(esportConnectionsPlatformMap[esportSlug])

  const { data, loading } = useGetEligiblePlayersForTeamQuery({
    variables: {
      teamId,
    },
  })

  const [addPlayerToTeam, { loading: isAddMutating }] = useAddPlayerToTeamHook()
  const [repositionPlayers, { loading: isRepositioning }] =
    useRepositionPlayersInTeamMutation()

  const teamRoster = head(data?.team?.roster?.formats ?? [])

  const currentRosterMemberIds = (teamRoster?.starters ?? [])
    .map((starter) => starter?.player?.user?.id ?? '')
    .filter((id) => id)

  const benchMembers = React.useMemo(() => {
    const members = (teamRoster?.substitutes ?? []).map((benchMember) => {
      const currentSeasonPass = benchMember?.user.seasonPasses?.filter(
        (seasonPass) =>
          metaseasonId &&
          seasonPass.metaseasonId === metaseasonId &&
          seasonPass?.leagueId === leagueId
      )[0]
      const hasUsedPass = !!currentSeasonPass?.consumedAt
      return {
        id: benchMember.user.id ?? '',
        avatarSrc: benchMember.user.avatarUrl ?? '',
        title: benchMember.user.name ?? '',
        subtitle: benchMember.user.id ? getUsername(benchMember.user) : '',
        hasUsedPass,
      }
    })

    return searchTerm
      ? members.filter((member) =>
          member.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : members
  }, [searchTerm, teamRoster])

  const verifiedStudents = React.useMemo(() => {
    const members = (data?.team?.getEligiblePlayers ?? []).map(
      ({ user: student, disabled, disabledReason }) => {
        const connectedPlatforms =
          student.userProviderAccounts?.map((p) => p.providerName) ?? []
        const intersection = connectedPlatforms.filter((p) =>
          supportedPlatforms.has(p as Provider)
        )
        const isConnected = intersection.length > 0

        const hasEsportInterest = (student.esportInterests ?? [])
          .map((e: { id: string; slug: string }) => e.slug)
          .includes(esportSlug)

        const subtitle =
          getUsername(student) ||
          student?.emails?.find((email) => email?.isSchoolEmail)?.email

        return {
          id: student.id ?? '',
          avatarSrc: student.avatarUrl ?? '',
          title: student.name ?? '',
          subtitle: student.id ? subtitle : '',
          isConnected,
          hasEsportInterest,
          disabled,
          disabledTooltip: getEligiblePlayerDisabledReasonText(
            disabledReason,
            data?.team?.esport?.name
          ),
        }
      }
    )

    return searchTerm
      ? members.filter((member) =>
          member.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : members
  }, [searchTerm, teamRoster])

  const sortUsers = (
    user1: RosterRowData & { isConnected: boolean; hasEsportInterest: boolean },
    user2: RosterRowData & { isConnected: boolean; hasEsportInterest: boolean }
  ): number => {
    // Sort by connected account
    if (user1.isConnected !== user2.isConnected) {
      return user1.isConnected ? -1 : 1
    }

    // Then by esport interest
    if (user1.hasEsportInterest !== user2.hasEsportInterest) {
      return user1.hasEsportInterest ? -1 : 1
    }

    return user1.subtitle ? -1 : 1
  }

  const rosterRowDataList = React.useMemo(() => {
    return createRosterRowDataList({
      benchList: benchMembers,
      verifiedStudentList: verifiedStudents.sort(sortUsers),
    })
  }, [searchTerm, teamRoster])

  const onChange = (id: string): void => {
    if (userSelected.id === id) {
      setUserSelected({ id: '' })
    } else {
      setUserSelected({ id })
    }
  }

  const repositionPlayerOntoStartingRoster = async (
    id: string
  ): Promise<void> => {
    try {
      const finalPlayerPositions = repositionPlayer({
        selectedPosition: {
          id: 'open',
          positionId: null,
          positionIndex,
          currentUser: {
            isPlayer: true,
          },
        },
        player: {
          id,
          position: null,
        },
        playerToBeRepositionedIsSub: false,
      })
      await repositionPlayers({
        variables: {
          teamId,
          playerPositions: finalPlayerPositions,
        },
      })
      enqueueSnackbar('Success! Player has been added.', {
        variant: 'success',
      })
      closeDialog()
      await onMutationSuccess?.()
    } catch (e: any) {
      setError(e as Error)
    }
  }

  const addToTeam = async (id: string): Promise<void> => {
    try {
      const mutationVariables = {
        teamId,
        position: positionIndex,
      }
      const { success, teamId: returnedTeamId } = await addPlayerToTeam(
        {
          ...mutationVariables,
          userId: id,
        },
        currentRosterMemberIds
      )

      if (success) {
        enqueueSnackbar('Success! Player has been added.', {
          variant: 'success',
        })
      } else {
        throw new ApolloError({
          errorMessage: 'Unable to add selected player to the team.',
        })
      }
      closeDialog()
      await onMutationSuccess?.(returnedTeamId)
    } catch (e: any) {
      setError(e as Error)
    }
  }

  const addPlayer = async (isBench: boolean): Promise<void> => {
    if (!userSelected.id) {
      setError(
        new ApolloError({
          errorMessage: 'You must first select a player.',
        })
      )
    } else if (isBench) {
      await repositionPlayerOntoStartingRoster(userSelected.id)
    } else {
      await addToTeam(userSelected.id)
    }
  }

  const onCheck = async (): Promise<void> => {
    const member = benchMembers.find((member) => member.id === userSelected.id)
    const isBench = !!member
    await addPlayer(isBench)
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <NxModal
      actions={
        <>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color="primary"
            data-testid="AddPlayerToTeam_Button"
            disabled={isAddMutating || isRepositioning}
            onClick={onCheck}
            variant="contained"
          >
            Confirm
          </Button>
        </>
      }
      className={classes.content}
      fullWidth
      maxWidth="xs"
      onClose={closeDialog}
      open={isDialogOpen}
      showTopRightClose={false}
      subtitle="Select a player from the list"
      title="Add a Player"
    >
      <>
        {errorMessage && (
          <Box py={2}>
            <Banner
              subtitle={errorMessage}
              title="Unable to add player"
              type={BannerType.Error}
            />
          </Box>
        )}
        <TextField
          color="secondary"
          fullWidth
          label="Search by Name"
          name="search"
          onChange={(e): void => {
            setSearchTerm(e.target.value)
          }}
          type="text"
          variant="outlined"
        />
        <WaitTillLoaded
          loading={loading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          {rosterRowDataList.length ? (
            rosterRowDataList.map((section) => {
              return (
                <Box
                  key={section.label}
                  data-testid="RosterSection_Box"
                  pb={2}
                  pt={1}
                >
                  <NxTypography className={classes.label} variant="overline">
                    {section.label}
                  </NxTypography>
                  <RosterRowGroup
                    onChange={onChange}
                    rowEntries={section.list ?? []}
                    selectedId={userSelected.id}
                  />
                </Box>
              )
            })
          ) : (
            <Box pb={2} pt={-1}>
              <Banner
                subtitle="There are no players available for this team."
                title="Unable to find players"
                type={BannerType.Warning}
              />
            </Box>
          )}
        </WaitTillLoaded>
      </>
    </NxModal>
  )
}
