import React, { ChangeEvent, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Box } from '@plvs/respawn/features/layout'

import {
  SchoolSearchResult,
  SchoolType,
  useGetAllSchoolsForCoachLeadQuery,
} from '@plvs/graphql'
import { DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab'
import { SelectedSchoolType } from './CoachLeadComponent'
import { CoachSchoolLookUpOption } from './CoachSchoolLookUpOption'

const useStyles = makeStyles((theme) => ({
  schoolSearch: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    width: '100% !important',
    '& div': {
      backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  searchInput: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: '5px',
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
  },
}))

const DEFAULT_VALUE = {
  name: '',
  schoolId: '',
  logoUrl: '',
  city: '',
  state: '',
}

type SchoolFilterOption = Pick<
  SchoolSearchResult,
  'schoolId' | 'name' | 'logoUrl' | 'city' | 'state'
>

interface CoachSchoolLookUpProps {
  register: React.Ref<HTMLInputElement>
  setSelectedSchool: (
    school: Pick<SchoolSearchResult, 'schoolId' | 'name'> | null
  ) => void
  label?: string
  selectedSchool?: SelectedSchoolType | null
  state?: string
}

export const CoachSchoolLookUp: React.FC<CoachSchoolLookUpProps> = ({
  register,
  setSelectedSchool,
  label,
  selectedSchool,
  state,
}): React.ReactElement => {
  const classes = useStyles()
  const [query, setQuery] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [debouncedQuery] = useDebounce(query, DEFAULT_DEBOUNCE_TIME)

  const { data } = useGetAllSchoolsForCoachLeadQuery({
    variables: {
      query: debouncedQuery,
      type: SchoolType.HighSchool,
      state: state ?? '',
    },
    skip: !state,
  })

  const handleSchoolSearchChange = (
    event: ChangeEvent<unknown>,
    value: string,
    _reason: AutocompleteInputChangeReason
  ): void => {
    if (!event) {
      setQuery('')
      return
    }

    // Only update query on text type event.
    if ((event.target as HTMLInputElement).type !== 'text') {
      return
    }

    setSelectedSchool(null)
    setQuery(value)
  }

  const handleBlur = (): void => {
    if (query && !selectedSchool?.schoolId) {
      setError(true)
    } else {
      setError(false)
    }
  }

  return (
    <Box display="flex">
      <Autocomplete
        className={classes.schoolSearch}
        defaultValue={selectedSchool ?? DEFAULT_VALUE}
        filterOptions={(): SchoolFilterOption[] => [
          ...(data?.searchSchools?.results ?? []),
        ]}
        getOptionLabel={(option): string => option?.name ?? ''}
        onBlur={handleBlur}
        onChange={(_evt, value): void => {
          // User cleared input
          if (selectedSchool && value === null) {
            setQuery('')
          }

          setSelectedSchool(value)
        }}
        onInputChange={handleSchoolSearchChange}
        options={[...(data?.searchSchools?.results ?? [])]}
        renderInput={(params): React.ReactNode => {
          return (
            <TextField
              {...params}
              className={classes.searchInput}
              error={error}
              helperText={
                error && 'Please select a school from the search list.'
              }
              inputRef={register}
              label={label}
              name="schoolName"
              placeholder="School"
              variant="filled"
            />
          )
        }}
        renderOption={(option): React.ReactNode => (
          <CoachSchoolLookUpOption school={option} />
        )}
        value={{
          name: selectedSchool?.name ?? query,
          schoolId: selectedSchool?.schoolId ?? '',
        }}
      />
    </Box>
  )
}
