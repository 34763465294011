import React from 'react'
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

import { env } from '@plvs/env'

export const createSvgIcon = (
  path: React.ReactNode,
  displayName: string,
  presetProps: SvgIconProps = {}
): typeof SvgIcon => {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <SvgIcon
        // @ts-ignore
        ref={ref}
        component="svg"
        data-mui-test={`${displayName}Icon`}
        {...presetProps}
        {...props}
      >
        {path}
      </SvgIcon>
    ))
  )

  if (!env.IS_NODE_ENV_DEVELOPMENT) {
    Component.displayName = `${displayName}Icon`
  }

  // @ts-ignore
  Component.muiName = SvgIcon.muiName

  // @ts-ignore
  return Component
}
