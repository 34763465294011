/**
 * This contains a list of Articles that exist in Intecom.
 *
 * You can find the articles defined via logging in to Intercom,
 * https://app.intercom.com/a/apps/ke5hq8kf/articles/articles/list/all
 */
export const IntercomArticleMappings: Record<string, number> = {
  administrationApproval: 5946617,
  allArticles: 6418133,
  billingGuide: 4919223,
  coachExpectations: 5854925,
  coachHandbook: 5482657,
  connectionsGuide: 4919251,
  faq: 4919252,
  highSchoolCoachHandbook: 5482657,
  highSchoolEnrollmentSteps: 4919261,
  howToStartAnEsportsProgram: 4919238,
  itDept: 4919231,
  matchAssistant: 6961146,
  matchAssistantBeta: 8510746,
  parentsGuide: 4919246,
  playoffInfo: 8546188,
  playVsScore: 6437166,
  pricingFaq: 8464156,
  programCapacity: 4919152,
  ruleBooks: 5464981,
  youthRuleBooks: 4919158,
  middleSchoolRuleBooks: 9006171,
  smartSchedulerOverview: 6493689,
  captains: 8510746,
}
