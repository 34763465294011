import React, { useEffect, useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import {
  NxTypography,
  NxTextLink,
  NxButton,
} from '@playvs-inc/nexus-components'

import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useStyles } from '@plvs/rally/pages/app/match/slotQueue'
import { useTheme } from '@material-ui/core'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { QueueTimer } from './QueueTimer'

dayjs.extend(duration)

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface PreseasonQueueWaitingRowProps {
  team?: Team | null
  hasError: boolean
  numTeamsInQueue: number
  nextPairing?: string | null
  inQueue: boolean
  onRejoinQueue(): void
  isLoadingRejoinQueue: boolean
}

export const PreseasonQueueWaitingRow: React.FC<PreseasonQueueWaitingRowProps> = ({
  team,
  hasError,
  numTeamsInQueue,
  nextPairing,
  inQueue = true,
  onRejoinQueue,
  isLoadingRejoinQueue = false,
}) => {
  const theme = useTheme()
  const mobile = useBreakpointSm()
  const [title, setTitle] = useState(() => {
    if (hasError) {
      return "There's been an issue"
    }

    if (inQueue) {
      return 'Looking for a Team'
    }

    return 'No Match Found'
  })
  const {
    teamsContainer,
    mobileTeamNameContainer,
    mobileTeamContainer,
    mobileHeader,
    subtitleWrapper,
    teamLabel,
    schoolName,
  } = useStyles({ mobile })

  useEffect(() => {
    if (hasError) {
      setTitle("There's been an issue")
    }
  }, [title, hasError])

  const isYouthProgram = team?.esport?.rating === EsportRating.Restricted

  let subtitle: string | React.ReactElement = inQueue ? (
    <>
      <NxTypography color="inherit" variant="body1">
        This is a <b>preseason</b> match queue. We encourage each team to play
        at least 1 preseason match; however, you many queue for more as well!
      </NxTypography>

      <NxTypography color="inherit" variant="body1">
        Pairing will happen when the <b>Estimated Time</b> reaches <b>0:00</b>{' '}
        or the <b>Teams in Queue</b> reaches <b>50</b>. Please stay in queue and
        remain on this page.
      </NxTypography>
    </>
  ) : (
    <>
      <NxTypography color="inherit" variant="body1">
        We could not find a team to match you against. This happens when there
        is an odd number of teams in the queue. If you’d like to try again,
        click <b>Rejoin Queue</b>.
      </NxTypography>

      <NxButton
        label="Rejoin Queue"
        loading={isLoadingRejoinQueue}
        onClick={onRejoinQueue}
        variant="primary"
      />
    </>
  )

  if (hasError) {
    subtitle = (
      <NxTypography className={subtitleWrapper} color="inherit" variant="body1">
        There has been an issue redirecting you to your match.{' '}
        <NxTextLink label="Click here" onClick={openIntercom} /> to reach out to
        support.
      </NxTypography>
    )
  }

  return (
    <Box className={teamsContainer} data-testid="SlotQueueWaitingRow_Wrapper">
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(3)}
          >
            <NxTypography color="inherit" variant="h2">
              {title}
            </NxTypography>

            <Box
              className={subtitleWrapper}
              display="flex"
              flexDirection="column"
              gridGap={theme.spacing(2)}
            >
              {subtitle}
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />

              <Box
                className={mobileTeamNameContainer}
                color={theme.palette.OverlayColorTextBase}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography color="inherit" variant="h1">
                  {team?.name}
                </NxTypography>
                {!isYouthProgram && (
                  <NxTypography
                    className={schoolName}
                    color="inherit"
                    variant="subtitle2"
                  >
                    {team?.school?.name}
                  </NxTypography>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxUserAvatar
              avatarUrl={team?.school?.logoUrl}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.XXL}
            />
          </Box>
          <NxTypography className={teamLabel} color="inherit" variant="h1">
            {team?.name}
          </NxTypography>
          {!isYouthProgram && (
            <NxTypography
              className={schoolName}
              color="inherit"
              variant="subtitle2"
            >
              {team?.school?.name}
            </NxTypography>
          )}
          <Box
            alignItems="center"
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            justifyContent="space-between"
            pt={2}
          >
            <NxTypography color="inherit" variant="h2">
              {title}
            </NxTypography>

            <Box
              className={subtitleWrapper}
              display="flex"
              flexDirection="column"
              gridGap={theme.spacing(2)}
            >
              {subtitle}
            </Box>
          </Box>
        </>
      )}

      {inQueue && (
        <Box display="flex" gridGap={theme.spacing(8)} mt={3}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(0.5)}
          >
            <NxTypography color="inherit" variant="overline">
              Teams In Queue
            </NxTypography>
            <NxTypography color="inherit" variant="display">
              {numTeamsInQueue}
            </NxTypography>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(0.5)}
          >
            <NxTypography color="inherit" variant="overline">
              Estimated Time
            </NxTypography>
            <QueueTimer
              countdownTime={dayjs(nextPairing)}
              onCountdownComplete={async (): Promise<void> => {}}
              onTenSecondsLeft={async (): Promise<void> => {}}
              variant="display"
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
