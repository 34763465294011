import React, { useEffect, useMemo } from 'react'
import { useGetSchoolTeamsQuery } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { useNavigate, useParams } from 'react-router'
import { makeStyles, useTheme } from '@material-ui/core'
import { OptionalWeeksCard } from '@plvs/rally/features/enrollment/optionalWeeks/OptionalWeeksCard'
import {
  NxTypography,
  NxButton,
  NxEmptyState,
} from '@playvs-inc/nexus-components'

import { CreateteamLightLargeSecondary } from '@playvs-inc/nexus-spots'
import { Path } from '@plvs/const'
import { Esport } from '@plvs/respawn/features/esport/Esport'

import {
  EnrolledPlayerOnTeam,
  EnrollmentLeagueEsport,
  EnrollmentLeagues,
  EnrollmentRangeData,
  OptionalWeeksCache,
  SeasonEnrollmentRangeData,
  TeamLeagueSelectFn,
} from '@plvs/respawn/containers/enrollment/types'
import { EnrollmentEsportCard } from './EnrollmentEsportCard'

const useStyles = makeStyles((theme) => ({
  emptyTeam: {
    borderRadius: theme.shape.borderRadius,
  },
  spot: {
    width: 190,
    height: 190,
  },
  subtitle: {
    margin: theme.spacing(1, 0, 3, 0),
  },
}))

interface SeasonEnrollmentProps {
  onPlayersSelected: TeamLeagueSelectFn
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  leagues: EnrollmentLeagues
  leagueEsports: EnrollmentLeagueEsport[]
  schoolId: string
  isAdmin: boolean
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  optionalWeeksCache: OptionalWeeksCache
  slotExclusionRangeData: EnrollmentRangeData
  setOptionalSeasonWeeksCache(input: OptionalWeeksCache): void
  optionalSeasonWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  addEnrollmentSeasonIds(seasonIds: string[]): void
  removeEnrollmentSeasonId(seasonId: string): void
}

export const SeasonEnrollment: React.FC<SeasonEnrollmentProps> = ({
  onPlayersSelected,
  enrolledPlayersOnTeam,
  schoolId,
  leagues,
  leagueEsports,
  isAdmin,
  optionalWeeksCache,
  setOptionalWeeksCache,
  slotExclusionRangeData,
  seasonSlotExclusionRangeData,
  optionalSeasonWeeksCache,
  setOptionalSeasonWeeksCache,
  addEnrollmentSeasonIds,
  removeEnrollmentSeasonId,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { id: metaseasonId } = useParams<{ id: string }>()
  const { data } = useGetSchoolTeamsQuery({
    variables: { schoolId: schoolId ?? '' },
    skip: !schoolId,
  })
  useMemo(() => {
    if (!data?.school?.teams?.length) {
      return
    }
    const enrolledSeasonIds = data.school.teams
      .flatMap((teamData) => {
        return teamData.enrolledSeasons?.map((season) => season.id)
      })
      .filter(Boolean) as string[]

    addEnrollmentSeasonIds(enrolledSeasonIds)
  }, [data])

  const [hasBreakWeek, setHasBreakWeek] = React.useState(
    !!slotExclusionRangeData.length || !!seasonSlotExclusionRangeData.length
  )
  useEffect(() => {
    setHasBreakWeek(
      !!slotExclusionRangeData.length || !!seasonSlotExclusionRangeData.length
    )
  }, [slotExclusionRangeData, seasonSlotExclusionRangeData])

  const teamEnrollmentText = 'Step 1: Select Leagues to Enroll'

  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" pb={18.75}>
      <Box mb={2}>
        <NxTypography variant="h1">{teamEnrollmentText}</NxTypography>
      </Box>
      {leagueEsports.map((esport) => {
        const esportSlug = esport.slug
        const esportId = esport.id

        return (
          <Esport key={esportId} slug={esportSlug}>
            <EnrollmentEsportCard
              addEnrollmentSeasonIds={addEnrollmentSeasonIds}
              backgroundColor={theme.palette.ColorBackgroundBase}
              enrolledPlayersOnTeam={enrolledPlayersOnTeam}
              esportId={esportId}
              esportSlug={esportSlug}
              isAdmin={isAdmin}
              leagues={leagues}
              metaseasonId={metaseasonId!}
              onPlayersSelected={onPlayersSelected}
              removeEnrollmentSeasonId={removeEnrollmentSeasonId}
              schoolId={schoolId}
              seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
            />
          </Esport>
        )
      })}
      {!data?.school?.teams?.length && (
        <Box className={classes.emptyTeam} mb={5} mx={6}>
          <NxEmptyState
            cta={
              <NxButton
                label="Create A Team"
                onClick={(): void => {
                  navigate(Path.CreateTeam)
                }}
                variant="secondary"
              />
            }
            spot={<CreateteamLightLargeSecondary className={classes.spot} />}
            subtitle="Your school currently has no teams. Why don't you create one?"
            title="No Teams Available"
          />
        </Box>
      )}
      {hasBreakWeek && (
        <Box>
          <Box mb={2}>
            <NxTypography variant="h1">Step 2: Select Break Week</NxTypography>
          </Box>
          <OptionalWeeksCard
            optionalSeasonWeeksCache={optionalSeasonWeeksCache}
            optionalWeeksCache={optionalWeeksCache}
            rangeData={slotExclusionRangeData}
            seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
            setOptionalSeasonWeeksCache={setOptionalSeasonWeeksCache}
            setOptionalWeeksCache={setOptionalWeeksCache}
          />
        </Box>
      )}
    </Box>
  )
}
