import React from 'react'
import { Box, useTheme } from '@material-ui/core'

import {
  NxButton,
  NxMatchDetailsCluster,
  NxTextLink,
  NxTypography,
} from '@playvs-inc/nexus-components'
import {
  CheckboxLightPrimaryLarge,
  CheckboxDarkPrimaryLarge,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { logger } from '@plvs/rally/logging'
import { useSubmitGameAssistantStepSelectionsMutation } from '@plvs/graphql'
import { IntercomArticleMappings } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles as useMatchAssistantStyles } from '../../../MatchAssistant.styles'

// 2 hours in miliseconds
const TWO_HOURS_EARLY = 1000 * 60 * 60 * 2

// 15 minutes in miliseconds
const FIFTEEN_MINS_EARLY = 1000 * 60 * 15

export const ReadyUp: React.FC = () => {
  // This is not the correct pattern, rendering logic should be created by the render controller.
  // However, intercom is being hidden for v1, so this is a temporary solution.
  const productType = useProductTypeFn()
  const isStadium = productType === ProductType.Stadium
  const theme = useTheme()
  const maStyles = useMatchAssistantStyles()
  const {
    currentStep,
    myTeam,
    opposingTeam,
    match,
    esportSlug,
  } = useMatchAssistantContext()

  const {
    match: { canManageMatch, canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const isLightMode = theme.palette.type === MUIThemeModeEnum.Light
  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const { name: esportName } = useGeneralEsportAdapter(esportSlug)

  const canReadyUp =
    Date.parse(match?.scheduledStartsAt ?? '') - Date.now() <=
    (canManageMatch ? TWO_HOURS_EARLY : FIFTEEN_MINS_EARLY)
  const isWaitingForOpponent = currentStep?.step?.submitted
  const isNextDisabled = canSpectate || isWaitingForOpponent || !canReadyUp

  const handleLearnMore = (): void => {
    showIntercomArticle(IntercomArticleMappings.matchAssistant)
  }

  const handleNext = async (): Promise<void> => {
    logger.debug('[ReadyUp | handleNext]')

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
          },
        },
      })
    } catch (err) {
      logger.error('[ReadyUp | handleNext]', err)
    }
  }

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(4)}
          justifyContent="center"
          mb={8}
          mt={3}
        >
          <NxTypography variant="h4">Is your team ready to play?</NxTypography>

          <Box alignItems="center" display="flex" gridGap={theme.spacing(2)}>
            {isLightMode ? (
              <CheckboxLightPrimaryLarge height={100} width={100} />
            ) : (
              <CheckboxDarkPrimaryLarge height={100} width={100} />
            )}

            <NxMatchDetailsCluster
              bestOf={match?.bestOf ?? undefined}
              esport={esportSlug ?? ''}
              esportName={esportName}
              showAvatar={false}
              teamAName={myTeam?.name ?? ''}
              teamBName={opposingTeam?.name ?? ''}
              timestamp={match?.scheduledStartsAt ?? ''}
            />
          </Box>
        </Box>
      </Box>

      <MatchAssistantFooter>
        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <NxButton
          className={maStyles.cta}
          disabled={isNextDisabled}
          fullWidth
          label="Ready Up"
          loading={isWaitingForOpponent || updateLoading}
          onClick={handleNext}
          variant="primary"
        />

        {isStadium ? null : (
          <NxTextLink
            label="What is Match Assistant?"
            onClick={handleLearnMore}
          />
        )}
      </MatchAssistantFooter>
    </>
  )
}
