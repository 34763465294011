import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import React from 'react'

interface Props {
  theKey?: string | number
}

const NotificationCloseButton = ({ theKey }: Props): React.ReactElement => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={(): void => closeSnackbar(theKey)}
    >
      <Close />
    </IconButton>
  )
}

export default NotificationCloseButton
