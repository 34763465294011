import React from 'react'
import { Box } from '@material-ui/core'

import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'

import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useLocation } from 'react-router-dom'

interface LoginLinkProps {
  isParentSignup: boolean
  isNasefSignUp: boolean
  hasBirthday: boolean
  shouldRenderParentFields: boolean
}

export const LoginLink: React.FC<LoginLinkProps> = ({
  isParentSignup,
  isNasefSignUp,
  hasBirthday,
  shouldRenderParentFields,
}) => {
  const location = useLocation()
  const signInCta = isParentSignup
    ? 'Already have a parent account?'
    : 'Already have an account?'

  const loginPath = isNasefSignUp
    ? `${Path.Login}${NASEF_QUERY_PARAM}`
    : `${Path.Login}${location.search}`

  const signupPath = isNasefSignUp
    ? `${Path.Registration}${NASEF_QUERY_PARAM}`
    : `${Path.Registration}${location.search}`

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent={['center', 'space-between']}
      mt={3}
    >
      <NxTypography variant="body1">
        {signInCta}&nbsp;
        <NxTextLink
          data-testid="sign-in"
          href={loginPath}
          label="Sign in"
          labelVariant="body1"
        />
      </NxTypography>

      {hasBirthday && isParentSignup && (
        <Box mt={1}>
          <NxTypography variant="body1">
            Looking to sign up as a coach or player?&nbsp;
            <NxTextLink
              data-testid="user-sign-in"
              href={signupPath}
              label="Click here"
              labelVariant="body1"
            />
          </NxTypography>
        </Box>
      )}

      {shouldRenderParentFields && (
        <Box mt={1}>
          <NxTypography variant="body1">
            Looking to sign up as a parent?&nbsp;
            <NxTextLink
              data-testid="parent-sign-up"
              href={`${Path.ParentSignUp}${location.search}`}
              label="Click here"
              labelVariant="body1"
            />
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
