import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxTypography,
  NxIconButton,
  NxButton,
  NxModal,
} from '@playvs-inc/nexus-components'
import { CaretRight } from '@playvs-inc/nexus-icons'
import { useSnackbar } from 'notistack'
import {
  useDeleteUserMutation,
  refetchGetUserChildrenQuery,
} from '@plvs/graphql/generated'
import { Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { ChildSettingsCard } from './ChildSettingsCard'

const useStyles = makeStyles(() => ({
  deleteModalOpen: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const ChildOtherSettings: React.FC<{
  childName?: string | null
  userId: string
}> = ({ childName, userId }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [deleteUserMutation, loading] = useDeleteUserMutation()
  const [showDeleteChildModal, setShowDeleteChildModal] = React.useState(false)

  const deleteUser = async (): Promise<void> => {
    try {
      await deleteUserMutation({
        awaitRefetchQueries: true,
        variables: {
          userId,
          reason: 'Parent deletion of child account',
        },
        refetchQueries: [refetchGetUserChildrenQuery()],
      })
      setShowDeleteChildModal(false)
      navigate(Path.ParentDashboard, { replace: true })
    } catch {
      enqueueSnackbar('Something went wrong. Please contact support.', {
        variant: 'error',
        autoHideDuration: 2000,
      })
      setShowDeleteChildModal(false)
    }
  }

  const closeDeleteModal = (): void => {
    setShowDeleteChildModal(false)
  }

  const openDeleteModal = (): void => {
    setShowDeleteChildModal(true)
  }

  const deleteChildCluster = (
    <Box
      className={classes.deleteModalOpen}
      data-testid="delete-child"
      onClick={openDeleteModal}
    >
      <Box display="flex" flexDirection="row">
        <NxTypography colorToken="ColorTextError" variant="body1">
          Delete Child Account
        </NxTypography>
      </Box>
      <NxIconButton
        data-testid="delete-button"
        disabled={!loading}
        icon={<CaretRight />}
        variant="text"
      />
    </Box>
  )

  return (
    <Box mt={3}>
      <ChildSettingsCard
        customContent={[{ id: 'delete-child', content: deleteChildCluster }]}
        title="Other Settings"
      />
      <NxModal
        actions={
          <>
            <NxButton
              data-testid="cancel-delete"
              label="Cancel"
              onClick={closeDeleteModal}
              variant="text"
            />
            <NxButton
              data-testid="confirm-delete"
              label="Delete"
              onClick={deleteUser}
              variant="error"
            />
          </>
        }
        currentStep={1}
        onClose={closeDeleteModal}
        open={showDeleteChildModal}
        showTopRightClose
        size="small"
        subtitle={`Are you sure you want to delete ${childName}'s account? This action cannot be undone.`}
        title="Delete Child Account"
      />
    </Box>
  )
}
