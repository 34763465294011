import { yupResolver } from '@hookform/resolvers'

import { Box, Grid, useTheme } from '@material-ui/core'
import {
  Maybe,
  refetchGetMyAccountDetailsQuery,
  SetAccountSettingsInput,
  User,
  UserEmail,
  useSetMyAccountSettingsMutation,
} from '@plvs/graphql'
import {
  formErrorToString,
  isValidPhoneNumber,
  yupFirstNameRequired,
  yupGradYearRequired,
  yupLastNameRequired,
  yupPhoneNumber,
} from '@plvs/utils'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  NxTypography,
  NxTextInput,
  NxTextArea,
  NxButton,
} from '@playvs-inc/nexus-components'

import { ApolloError } from '@apollo/client'
import ProfanityFilter from 'bad-words'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useAccountRenderControllerState } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { UsernameInput } from '@plvs/rally/features/auth'

const profanityFilter = new ProfanityFilter()

export const CoachDefaultAccountSchema = yup.object().shape({
  /* istanbul ignore next */
  additionalInfo: yup.string().test({
    name: 'additionalInfo',
    message: 'Please check the content of this field and try again.',
    /* istanbul ignore next */
    test: (value: string): boolean => {
      return !profanityFilter.isProfane(value)
    },
  }),
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
  /* istanbul ignore next */
  phone: yupPhoneNumber.test({
    name: 'phone',
    message: 'Please enter a valid phone number',
    /* istanbul ignore next */
    test: isValidPhoneNumber,
  }),
  /* istanbul ignore next */
  phoneExt: yup.string().test({
    name: 'phoneExt',
    message: 'Please enter a valid extension',
    /* istanbul ignore next */
    test: (value: string): boolean => {
      return /^\d*$/.test(value)
    },
  }),
})

export function getAccountSchema({
  shouldRenderFirstLastName,
  shouldRenderUsername,
  isStudentAtOrg,
  isFacultyAtOrg,
}): yup.ObjectSchema<Record<string, unknown>> {
  if (isFacultyAtOrg) {
    return CoachDefaultAccountSchema
  }

  return yup.object().shape({
    firstName: shouldRenderFirstLastName
      ? yupFirstNameRequired
      : yup.string().notRequired(),
    gradYear: isStudentAtOrg ? yupGradYearRequired : yup.string().notRequired(),
    lastName: shouldRenderFirstLastName
      ? yupLastNameRequired
      : yup.string().notRequired(),
    username: shouldRenderUsername
      ? yup.string().required()
      : yup.string().notRequired(),
  })
}

interface AccountSettingsInput {
  firstName?: string
  lastName?: string
  username?: string
  email?: string
  gradYear?: number | null
  phone?: string
  phoneExt?: string
  workEmail?: string
  isNotCoaching?: string
  dateOfBirth?: string
  additionalInfo?: string
}

const AccountSettingsForm: React.FC<{
  me: Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'gradYear'
    | 'phone'
    | 'phoneExt'
    | 'dateOfBirth'
    | 'additionalInfo'
    | 'username'
  > & {
    emails: Maybe<Array<Maybe<Pick<UserEmail, 'email' | 'isSchoolEmail'>>>>
  }
}> = ({ me }) => {
  const { isFacultyAtOrg, isStudentAtOrg } = useUserIdentityFn()

  const { getAccountRenderControllerState } = useAccountRenderControllerState()
  const {
    settings: { shouldRenderFirstLastName, shouldRenderUsername },
  } = getAccountRenderControllerState()

  const {
    firstName,
    lastName,
    gradYear,
    phone,
    phoneExt,
    dateOfBirth,
    additionalInfo,
    username,
  } = me
  const theme = useTheme()

  const currentAccountSchema = useMemo(
    () =>
      getAccountSchema({
        shouldRenderFirstLastName,
        shouldRenderUsername,
        isFacultyAtOrg,
        isStudentAtOrg,
      }),
    [
      shouldRenderFirstLastName,
      shouldRenderUsername,
      isStudentAtOrg,
      isFacultyAtOrg,
    ]
  )

  const { errors, handleSubmit, register, setError: setFormError } = useForm<
    AccountSettingsInput
  >({
    defaultValues: {
      additionalInfo: additionalInfo ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      username: username ?? '',
      phone: phone ?? '',
      phoneExt: phoneExt ?? '',
      gradYear: gradYear ?? null,
    },
    resolver: yupResolver<AccountSettingsInput>(currentAccountSchema),
  })
  const fieldErrors = errors as FieldErrors

  const [error, setError] = useState<Error | string>()
  const [isSuccess, setSuccess] = useState<boolean>(false)

  const [mutate] = useSetMyAccountSettingsMutation()

  const onSubmit = handleSubmit(
    async (values: SetAccountSettingsInput): Promise<void> => {
      setError(undefined)
      try {
        const response = await mutate({
          refetchQueries: [refetchGetMyAccountDetailsQuery()],
          variables: {
            input: {
              ...values,
              gradYear: Number(values.gradYear),
              phoneExt: values.phoneExt?.toString(),
              phone: values.phone?.replace(/\D/g, ''),
            },
          },
        })

        const setMyAccountSettings = response?.data?.setMyAccountSettings

        if (setMyAccountSettings) {
          setSuccess(true)
        }
      } catch (err: unknown) {
        const graphQLErrors = (err as ApolloError)?.graphQLErrors ?? []

        if (
          graphQLErrors.some((gqlErr) => gqlErr.message.includes('Profanity'))
        ) {
          setFormError('additionalInfo', {
            shouldFocus: true,
            message: 'Please check the content of this field and try again.',
          })
          setError(
            'Could not save profile changes. Please remove any inappropriate content and try again.'
          )
          return
        }

        setError(err as Error)
      }
    }
  )

  const handleChange = (): void => {
    setSuccess(false)
  }

  return (
    <form
      data-cy="account-settings-form"
      noValidate
      onChange={handleChange}
      onSubmit={(e): void => {
        onSubmit(e)
      }}
    >
      {error instanceof Error && (
        <NxTypography colorToken="ColorTextError">
          Could not save profile changes. Please check that all fields are
          filled correctly.
        </NxTypography>
      )}
      {typeof error === 'string' && (
        <NxTypography colorToken="ColorTextError">{error}</NxTypography>
      )}
      <Box mt={2}>
        <Grid container spacing={1}>
          {shouldRenderFirstLastName && (
            <>
              <Grid item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  data-testid="first-name"
                  defaultValue={firstName ?? ''}
                  fullWidth
                  helperText={formErrorToString(errors.firstName)}
                  label="First Name"
                  name="firstName"
                  type="text"
                  variant={errors.firstName ? 'error' : 'default'}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <NxTextInput
                  ref={register}
                  data-testid="last-name"
                  defaultValue={lastName ?? ''}
                  fullWidth
                  helperText={formErrorToString(errors.lastName)}
                  label="Last Name"
                  name="lastName"
                  type="text"
                  variant={errors.lastName ? 'error' : 'default'}
                />
              </Grid>
            </>
          )}

          {shouldRenderUsername && (
            <Grid item sm={12}>
              <UsernameInput ref={register} error={fieldErrors.username} />{' '}
            </Grid>
          )}

          {isFacultyAtOrg && (
            <Grid item sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gridGap={`${theme.spacing(1)}px`}
              >
                <Box flexBasis="10em" flexGrow={3}>
                  {/* // This is a controlled input, because we want to display formatted phone number. */}
                  <NxTextInput
                    ref={register}
                    fullWidth
                    helperText={formErrorToString(errors.phone)}
                    label="Phone Number"
                    name="phone"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    type="tel"
                    variant={errors.phone ? 'error' : 'default'}
                  />
                </Box>
                <Box flexBasis="8em" flexGrow={1}>
                  <NxTextInput
                    ref={register}
                    defaultValue={phoneExt || ''}
                    fullWidth
                    helperText={formErrorToString(errors.phoneExt)}
                    label="Phone Ext"
                    name="phoneExt"
                    type="text"
                    variant={errors.phoneExt ? 'error' : 'default'}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          {isStudentAtOrg ? (
            <Grid item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                defaultValue={gradYear || ''}
                fullWidth
                helperText={formErrorToString(errors.gradYear)}
                label="Graduation Year"
                name="gradYear"
                type="text"
                variant={errors.gradYear ? 'error' : 'default'}
              />
            </Grid>
          ) : null}
          <Grid item sm={6} xs={12}>
            <NxTextInput
              defaultValue={dateOfBirth || ''}
              disabled
              fullWidth
              helperText={formErrorToString(errors.dateOfBirth)}
              label="Date of Birth"
              type="text"
              variant="default"
            />
          </Grid>
          {isFacultyAtOrg && (
            <Grid item sm={6} xs={12}>
              <NxTextArea
                ref={register}
                defaultValue={additionalInfo ?? ''}
                fullWidth
                helperText={
                  errors.additionalInfo
                    ? formErrorToString(errors.additionalInfo)
                    : 'This info will display to other coaches in the Match Lobby & Team Profile pages.'
                }
                label="Additional Info"
                maxLength={300}
                name="additionalInfo"
                placeholder="(Preferred contact method, best time to contact, etc.)"
                rows={5}
                type="text"
                variant={errors.additionalInfo ? 'error' : 'default'}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <NxButton
          disabled={isSuccess}
          label={isSuccess ? 'Changes Saved' : 'Save Changes'}
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}

export default AccountSettingsForm
