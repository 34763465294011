import { makeStyles } from '@material-ui/core'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.ColorTextAlt2,
    marginLeft: 16,
  },
}))

export const FieldCue: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <NxTypography className={classes.root} variant="body2">
      {children}
    </NxTypography>
  )
}
