import React, { useState } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { useGetEligiblePlayersForTeamQuery } from '@plvs/graphql/generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { cleanGraphQLError, useAutoskipQuery } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxModal } from '@playvs-inc/nexus-components'
import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { getEligiblePlayerDisabledReasonText } from '@plvs/respawn/features/manage-teams/helpers'
import { useAddPlayerToTeamHook } from '@plvs/respawn/features/manage-teams/AddPlayerToTeam/useAddPlayerToTeamHook'
import { useEsportContext } from '../esport/Esport'

const useStyle = makeStyles((theme) => ({
  content: {
    '& .MuiDialogContent-root': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
    maxHeight: '796px',
    overflow: 'auto',
  },
}))

export interface AddSubToBenchProps {
  onClose(): void
  onMutationSuccess?(): Promise<void>
  teamId: string
  metaseasonId?: string
  isDialogOpen: boolean
  defaultSearchTerm?: string
}

export const AddSubToBenchDialog: React.FC<AddSubToBenchProps> = ({
  onClose,
  onMutationSuccess,
  teamId,
  metaseasonId,
  isDialogOpen,
  defaultSearchTerm,
}) => {
  const classes = useStyle()
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()
  const { getUsername } = useEsportContext()
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm ?? '')

  const {
    data: queryData,
    loading: loadingPlayers,
    refetch,
  } = useAutoskipQuery(useGetEligiblePlayersForTeamQuery, {
    variables: { teamId },
    skip: !teamId,
  })

  const [addPlayerToTeam, { loading: isAdding, error }] =
    useAddPlayerToTeamHook({
      onCompleted: () => {
        refetch()
        onMutationSuccess?.()
        onClose?.()
        enqueueSnackbar('Player added successfully', {
          variant: 'success',
        })
      },
    })

  const eligiblePlayers =
    queryData?.team?.getEligiblePlayers?.map(
      ({ user: currentMember, disabled, disabledReason }) => {
        return {
          id: currentMember.id,
          title: currentMember.name || '',
          subtitle:
            getUsername(currentMember) ||
            currentMember?.emails?.find((email) => email?.isSchoolEmail)?.email,
          avatarSrc: currentMember.avatarUrl || '',
          position: '',
          disabled,
          disabledTooltip: getEligiblePlayerDisabledReasonText(
            disabledReason,
            queryData?.team?.esport?.name
          ),
        }
      }
    ) || []

  const filteredPlayers = searchTerm
    ? eligiblePlayers.filter((member) =>
        member.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : eligiblePlayers

  const confirmHandler = (): void => {
    addPlayerToTeam(
      {
        metaseasonId,
        teamId,
        userId: selectedPlayerId || '',
        options: { isSubstitute: true },
      },
      queryData?.team?.roster?.players?.map(({ user: { id } }) => id) ?? []
    )
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <NxModal
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            data-testid="AddSubToBenchDialog_Confirm"
            disabled={isAdding || !selectedPlayerId || !!errorMessage}
            onClick={confirmHandler}
            variant="contained"
          >
            Confirm
          </Button>
        </>
      }
      className={classes.content}
      data-testid="AddSubToBenchDialog"
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={isDialogOpen}
      subtitle="Select a player from the list"
      title="Add a Substitute"
    >
      <>
        {errorMessage && (
          <Box py={2}>
            <Banner
              subtitle={errorMessage}
              title="Unable to add player"
              type={BannerType.Error}
            />
          </Box>
        )}
        <TextField
          color="secondary"
          fullWidth
          label="Search by Name"
          name="search"
          onChange={(e): void => {
            setSearchTerm(e.target.value)
          }}
          type="text"
          variant="outlined"
        />
        <WaitTillLoaded
          loading={loadingPlayers}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          {filteredPlayers.length ? (
            <RosterRowGroup
              onChange={setSelectedPlayerId}
              rowEntries={filteredPlayers}
              selectedId={selectedPlayerId}
            />
          ) : (
            <Box py={3}>
              <Banner
                data-testid="AddSubToBenchDialog_EmptyState"
                subtitle="There are no available players to add as substitutes"
                title="Unable to Add Substitutes"
                type={BannerType.Warning}
              />
            </Box>
          )}
        </WaitTillLoaded>
      </>
    </NxModal>
  )
}
