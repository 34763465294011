import { Box } from '@material-ui/core'
import React from 'react'
import { Banner, BannerType } from '@plvs/respawn/features/banner'

interface Props {
  errorMessage: string | null
}

export const OnboardErrorBanner: React.FC<Props> = ({ errorMessage }) => {
  if (!errorMessage) {
    return null
  }

  return (
    <Box maxWidth={400} pb={3} width="100%">
      <Banner
        subtitle={errorMessage}
        title="Unable to update account details"
        type={BannerType.Error}
      />
    </Box>
  )
}
