import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const StateConference = createSvgIcon(
  <>
    <path
      d="M35.2 19.784l-1.717 1.12a1985.625 1985.625 0 00-6.421 4.224c-.793.517-1.729.955-1.96 1.805-.22-.482-.329-.957-.52-1.4a1.39 1.39 0 01.34-1.656 14.602 14.602 0 012.107-1.6c2.616-1.728 5.256-3.429 7.888-5.14a15.011 15.011 0 00-1.45-4.148c-2.805 1.817-5.605 3.641-8.4 5.472-.822.534-1.81.992-2.048 1.867a15.971 15.971 0 01-.619-1.72c-.226-.741.085-1.333 1.01-1.941l8.774-5.734a15.2 15.2 0 00-3.07-3.08c-2.41 1.572-4.82 3.145-7.231 4.72-.8.515-1.024 1.112-.835 1.742.19.63.416 1.317.622 1.976.266.816.127 1.066-.894 1.704-2.91 1.837-10.704 6.837-13.613 8.672-.209.13-.406.277-.59.44a15.172 15.172 0 002.494 3.432c2.65-1.686 6.893-4.406 8.2-5.224l4.067-2.563c.746-.47 1.6-.885 1.81-1.685.33.764.592 1.555.784 2.365.048.224-.2.579-.437.816-.331.282-.688.532-1.067.747-3.555 2.325-7.12 4.65-10.693 6.973-.191.119-.37.256-.534.41a15.112 15.112 0 004.393 2.158c2.762-1.781 5.512-3.566 8.25-5.355.61-.397 1.21-.8 1.4-1.402.24.58.449 1.173.627 1.776.299.936.2 1.117-.982 1.866l-5.912 3.734c.34.024.68.04 1.024.04a15.097 15.097 0 007.091-1.763c-.139-.448-.283-.893-.421-1.333-.288-.915.12-1.704 1.248-2.438l6.218-4.06A15.107 15.107 0 0035.2 20v-.217z"
      fill="#0AF"
    />
    <path
      d="M20 40a20 20 0 1120-20 20.024 20.024 0 01-20 20zm0-37.19A17.19 17.19 0 1037.19 20 17.21 17.21 0 0020 2.81z"
      fill="#0D57A4"
    />
    <path
      d="M15.602 13.992L17.866 9l-5.093 2.061V8.963a5.03 5.03 0 105.027 5.026l-2.198.003z"
      fill="#0D57A4"
    />
  </>,
  'StateConference Logo',
  { viewBox: '0 0 40 40' }
)
