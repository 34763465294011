import React from 'react'
import { includes } from 'ramda'
import { Link, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug, UserRoleName } from '@plvs/graphql'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { NxTypography } from '@playvs-inc/nexus-components'

const links: {
  esportSlug?: EsportSlug
  link: string
  subtitle: string
  title: string
  userRoles: UserRoleName[]
}[] = [
  {
    link: 'https://help.playvs.com/en/articles/4919246',
    subtitle: '',
    title: 'Parents Guide',
    userRoles: [UserRoleName.Player, UserRoleName.Fac],
  },
  {
    link: 'https://help.playvs.com/en/?q=High+School+Rulebooks',
    subtitle: '',
    title: 'Rulebooks',
    userRoles: [UserRoleName.Player, UserRoleName.Fac],
  },
  {
    link: 'https://help.playvs.com/en/articles/4919252',
    subtitle: '',
    title: 'FAQ',
    userRoles: [UserRoleName.Player, UserRoleName.Fac],
  },
  {
    link: 'https://help.playvs.com/en/',
    subtitle: '',
    title: 'All Articles',
    userRoles: [UserRoleName.Player, UserRoleName.Fac],
  },
]

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.ColorTextLink,
  },
}))

export const LeagueResources: React.FC<{
  esportSlug: EsportSlug | undefined
  userRoles: UserRoleName[]
}> = ({ esportSlug, userRoles }) => {
  const linksComponentMap: React.ReactElement[] = []
  const classes = useStyles()

  links.forEach((linkDetail) => {
    const hasRightRoles = linkDetail.userRoles.reduce(
      (bool, roleName) => bool || includes(roleName, userRoles),
      false
    )
    const hasEsportSlug =
      !linkDetail.esportSlug || esportSlug === linkDetail.esportSlug
    const showLink = hasRightRoles && hasEsportSlug

    if (showLink) {
      linksComponentMap.push(
        <Link
          key={linkDetail.title}
          className={classes.link}
          color="inherit"
          href={linkDetail.link}
          rel="noopener"
          target="_blank"
          underline="none"
        >
          <Box key={linkDetail.title} pb={3}>
            <NxTypography color="inherit" variant="body1">
              {linkDetail.title}
            </NxTypography>
            <NxTypography color="textSecondary" variant="body2">
              {linkDetail.subtitle}
            </NxTypography>
          </Box>
        </Link>
      )
    }
  })

  return <MatchCard title="Resources">{linksComponentMap}</MatchCard>
}
