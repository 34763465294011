/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

import { toPairs } from 'ramda'

export { IntercomArticleMappings } from './intercom-article-mappings'
export { SponsorshipImageName } from './sponsorship-names'
export { SponsorshipComponentName } from './sponsorship-names'

export enum PAFStep {
  AddOrCreateTeam = 'addOrCreateTeam',
  ActivateTeam = 'activateTeam',
}

export enum Cookie {
  ReferralToken = 'referral_token',
  Token = 'token',
  StateToken = 'stateToken',
  EditToken = 'editToken',
}

export enum LocalStorageKey {
  FilterCacheMap = 'filterCacheMap',
  InvitedTeamData = 'invitedTeamData',
  PlayVsTheme = 'playVsTheme',
  StandingsFilters = 'standingsFilters',
  TwilioAccessToken = ' twilioAccessToken',
  ActiveMatchChatTab = 'activeMatchChatTab',
  MatchAssistantStepIndex = 'matchAssistantStepIndex',
  MatchAssistantStep = 'matchAssistantStep',
  IsUnderage = 'isUnderage',
  Environment = 'environment',
  ProductType = 'productType',
}

export enum SessionStorageKey {
  /**
   * Stores onboarding context
   */
  OnboardingContext = 'onboardingContext',
  OrgInvite = 'orgInvite',
}

export enum Param {
  Error = 'error',
  Next = 'next',
  Provider = 'provider',
  ReferralToken = 'referralToken',
  Type = 'type',
  Esport = 'e',
  Season = 's',
  Slot = 'slot',
  CompetitionGroup = 'cg',
  Team = 't',
  League = 'l',
  AuthError = 'authError',
  Success = 'success',
  MatchLeaderboard = 'matchLeaderboard',
  PhaseType = 'phaseType',
  Join = 'join',
  Scrimmage = 'scrimmage',
  ForceBucket = 'f_bucket',
  Invoice = 'invoice',
  Redirect = 'redirect',
  Text = 'text',
  EpicFetch = 'epicFetch',
  OpenRescheduleNotification = 'openRescheduleNotification',
  /**
   * Url search param for SchoolMessage.id
   */
  Message = 'message',
  UTM = 'utm_source',
  inviteCode = 'icd',
  OAuth = 'oauth',
  Parent = 'parent',
  eventId = 'eventId',
  teamId = 'teamId',
  stadiumInviteCode = 'inviteCode',
}

export const TOSUpdatedAt = '2021-01-28T17:00:00.000Z'

export enum Path {
  App = '/app',
  Login = '/login',
  Logout = '/logout',
  ForgotPassword = '/forgot-password',
  MySchool = '/app/my-school',
  MySchoolActivity = '/app/my-school/activity',
  MySchoolBilling = '/app/my-school/billing',
  Manage = '/app/manage',
  ManagePlayers = '/app/my-school/manage/players',
  ManageCoaches = '/app/my-school/manage/coaches',
  ManageTeams = '/app/manage/teams',
  Match = '/app/match',
  Matches = '/matches',
  MatchLobby = '/app/match/:matchId',
  MatchLobbyRoster = '/app/match/:matchId/teams',
  MatchLobbyChat = '/app/match/:matchId/chat',
  MissionControl = '/app/match/:matchId/mission-control',
  MatchStats = '/app/match/:matchId/match-stats',
  MatchInfo = '/app/match/:matchId/match-info',
  MatchCheckIns = '/app/check-in',
  MatchDispute = '/app/match/:matchId/dispute',
  NewMatch = '/matches/new',
  NewScrimmage = '/matches/scrimmage/new',
  Registration = '/signup',
  ParentSignUpRequest = '/signup/parent',
  ParentSignUp = '/signup-parent',
  Root = '/',
  Settings = '/app/account/settings',
  ConnectionSettings = '/app/account/connections',
  NotificationSettings = '/app/account/notifications',
  Schedule = '/app/schedule',
  Standings = '/app/standings',
  Scrimmage = '/app/scrimmage',
  CreateScrimmage = '/app/scrimmage/create',
  Team = '/app/team',
  TeamScouting = '/app/team/:teamId',
  TeamManagement = 'app/team/manage',
  EnrollmentPlanSelect = '/app/manage/enrollment/:metaseasonId/select',
  AdminTeam = '/team',
  GettingStarted = '/app/articles',
  Dashboard = '/app/dashboard',
  SpawnPoint = '/spawn-point',
  ChildSpawnPoint = '/child/spawn-point',
  ParentSpawnPoint = '/parent/spawn-point',
  About = '/spawn-point/about',
  ScholasticVerification = '/spawn-point/scholastic-verification',
  JoinSchool = '/spawn-point/tell-us-more',
  DocUpload = '/spawn-point/doc-upload',
  VerificationSuccess = '/spawn-point/verification-success',
  VerificationPending = '/spawn-point/verification-pending',
  Enrollment = '/app/manage/enrollment',
  Player = '/app/player',
  SchoolPage = '/app/school/:slug',
  OrgPublicPage = '/org/:slug',
  SchoolPublicPage = '/org',
  League = '/league',
  LeagueLoggedIn = '/app/league',
  Forbidden = '/forbidden',
  Banned = '/banned',
  Explore = '/app/explore',
  PublicExplore = '/explore',
  ExploreEsportDetails = '/app/explore/leagues-table/:competitionGroup/:esportSlug',
  MoreDetails = '/app/more-details',
  PublicTeam = '/team',
  CreateTeam = '/app/create-team',
  Metaseasons = '/metaseasons',
  CreateSeasons = '/metaseasons/create-seasons',
  EditSeasons = '/metaseasons/edit-seasons',
  GlobalRefer = '/app/refer-a-friend',
  MetaseasonsLeagues = '/metaseasons/leagues',
  EmailVerificationError = '/email-verification-error',
  MatchQueue = '/queue/:teamId/:slug',
  PracticeDayQueue = '/queue/practice-day',
  SlotMatchQueue = '/slot-queue/:slotId/:teamId/:esportSlug',
  PreseasonMatchQueue = '/preseason-queue/:slotId/:teamId/:esportSlug',
  QueueLobby = '/app/queue-lobby/:slotId/:teamId',
  LeagueDetails = '/explore/leagues/:publicSlug',
  ExploreLeagues = '/explore/leagues',
  Profile = '/profile',
  PlayerProfile = '/app/profile/:userId',
  Resources = '/app/resources',
  StudentEsportInterests = '/student-esport-interests',
  ParentDashboard = '/app/parent-dashboard',
  ChildDetails = '/app/parent-dashboard/child',
  Event = '/app/event',
}

export enum Polling {
  Fast = 1000 * 1, // 1 second
  Medium = 1000 * 3, // 3 seconds
  Normal = 1000 * 60, // 1 min
  Slow = 1000 * 180, // 3 min
}

export enum NoEntityId {
  Team = 'noTeamId',
}

export enum ScrimmageTableLimits {
  MyScrimmages = 10,
  AvailableScrimmages = 10,
}

const DEFAULT_TABLE_LIMIT = 25

export enum CheckpointTableLimits {
  ExportAllCSV = 500,
  AllMatches = DEFAULT_TABLE_LIMIT,
  UsersTable = DEFAULT_TABLE_LIMIT,
  VarsityAssociations = DEFAULT_TABLE_LIMIT,
  LeagueTeams = DEFAULT_TABLE_LIMIT,
  VarsityAssociationLeagueGroups = DEFAULT_TABLE_LIMIT,
  SchoolsOnVarsityAssociation = DEFAULT_TABLE_LIMIT,
}

export const FALLBACK_TIMEZONE = 'UTC' as const

export const SUPPORT_EMAIL = 'support@playvs.com'

export const DEFAULT_COMPONENT_WIDTH = 600

export const provinceAbbr = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
] as const

export const stateAbbrs = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'DC', // NOTE: DC is included here!
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
] as const

export type StateAbbr = typeof stateAbbrs[number] | typeof provinceAbbr[number]

export const stateNames: Record<StateAbbr, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',

  DC: 'Washington DC', // NOTE: DC is included here!

  // Canadian Territories
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
}

export const highSchoolFacultyRoles = {
  Teacher: 'teacher',
  Coach: 'Coach',
  Principal: 'principal',
  IT: 'it',
  Counselor: 'counselor',
  'Athletic Director': 'athletic_director',
  'Activities Director': 'activities_director',
  Other: 'other',
}

export const collegeFacultyRoles = {
  'Team Manager': 'team_manager',
  'Team Coach': 'team_coach',
  'Head Coach': 'head_coach',
  'Esports Director': 'esports_director',
  'Esports Coordinator': 'esports_coordinator',
  'Community Manager': 'community_manager',
  'Faculty Advisor': 'faculty_advisor',
  Other: 'other',
}

export interface SelectOption {
  value: string
  label: string
}

const convertMapToArrayOfLabelValueObjects = ([label, value]: [
  string,
  string
]): SelectOption => ({
  label,
  value,
})

export const highSchoolRolesOptions: SelectOption[] = toPairs(
  highSchoolFacultyRoles
).map(convertMapToArrayOfLabelValueObjects)

export const collegeRolesOptions: SelectOption[] = toPairs(
  collegeFacultyRoles
).map(convertMapToArrayOfLabelValueObjects)

// Timeframe to allow scheduling: 2pm - 9pm. Formatted: HHmm
export const RESCHEDULE_ALLOWED_TIME_START = 1400
export const RESCHEDULE_ALLOWED_TIME_END = 2100

// NOTE: Below are to be used with moment.js, dateFNS will throw an error logged in sentry re 'YYYY' vs. 'yyyy'.
// NOTE: dateFNS is a deprecated package

// Long Format: (April 9, 2019)
export const LONG_DATE_FORMAT = 'MMMM D, YYYY'

// Short Format: (Apr 9, 19)
export const SHORT_DATE_FORMAT = 'MMM D, YYYY'

// Format: (April 9th, 2019)
export const LONG_DATE_FORMAT_TH = 'MMMM Do, YYYY'

// FormatL (Apr 09 2022)
export const INVOICE_DATE_FORMAT = 'MMM DD YYYY'

// Hour w/ Timezone Format: ( 3:00 PM PDT)
export const HOUR_TZ_FORMAT = 'h:mm A z'

// Hour w/out Timezone Format: ( 3:00 PM )
export const HOUR_FORMAT = 'h:mm A'

// Day of Week: (Fri)
export const DOW_FORMAT = `ddd`

// Long Format: (Fri Apr 13, 2020)
export const SHORT_DOW_DATE_FORMAT = `${DOW_FORMAT} ${SHORT_DATE_FORMAT}`

// Timezone: (PST)
export const TZ_FORMAT = `z`

// Long Format with Timezone: (Fri April 13, 2020 9:00 PM PST)
export const SHORT_DOW_DATE_FORMAT_AND_HOUR_TZ_FORMAT = `${SHORT_DOW_DATE_FORMAT} ${HOUR_TZ_FORMAT}`

// Short Format with Timezone: (08/04/1961 4:05pm PST)
export const MOMENT_SHORT_DATE_AND_TIME = 'MM/DD/YYYY h:mma z'

// Short Format : (08/04/61)
export const MOMENT_SHORT_DATE_TWO_DIGIT_YEAR = 'MM/DD/YY'

// Short Format with Timezone: (08/04/1961)
export const MOMENT_DASHED_DATE = 'MM/DD/YYYY'

export const MOMENT_DASHED_DATE_AND_TIME = 'MM/DD/YYYY h:mma'

// November 19, 2021 3:45 PM EST
export const MOMENT_LONG_DATE_AND_TIME_TZ = 'LLL z'

// Wed 12:00 PM PDT 09/08/2021
export const DOW_AND_TIME_AND_DASHED_DATE_FORMAT = `${DOW_FORMAT} ${HOUR_TZ_FORMAT} ${MOMENT_DASHED_DATE}`

// Mar 13th
export const MOMENT_SHORT_MONTH_AND_ORDINAL = 'MMM Do'

// Feb 10th 2022 04:14 PST
export const MOMENT_SHORT_MONTH_DATE_AND_TIME_TZ = 'MMM Do, YYYY hh:mm z'

// Sep 16th, 2022
export const MOMENT_SHORT_MONTH_AND_ORDINAL_AND_YEAR = 'MMM Do, YYYY'

// April 14 @ 3PM PDT
export const LONG_DATE_AND_DAY_AND_TZ = 'MMMM D @ hA z'

// Sep 17, Tue
export const SHORT_MONTH_AND_DAY_AND_DOW = 'MMM D, ddd'

// 2024-01-20 12:00am
export const MOMENT_LONG_DATE_AND_TIME_CONDENSED = 'YYYY-MM-DD h:mma'

export const DEFAULT_DEBOUNCE_TIME = 500

export const DEFAULT_TIMEZONE = 'UTC'

export const PLAYVS_HELP_ARTICLE_REGEX = /\/articles\/(?<id>\d+)/

export enum MatchActions {
  RESCHEDULE = 'reschedule',
  FORFEIT = 'forfeit',
  CANCEL = 'cancel',
  START_NEW_MATCH = 'start new match',
  CANCEL_SCRIMMAGE = 'cancel scrimmage',
}

export const MB_TO_BYTES = 1048576

export const MBToBytesMap = {
  '1MB': MB_TO_BYTES * 1,
  '2MB': MB_TO_BYTES * 2,
  '3MB': MB_TO_BYTES * 3,
}

export const READABLE_ROLE_NAMES = {
  admin: 'Admin',
  cx: 'CX',
  coach: 'Coach',
  fac: 'Faculty',
  player: 'Player',
  student: 'Student',
  user: 'User',
  captain: 'Captain',
  public: 'public',
  owner: 'owner',
  member: 'member',
  primary_coach: 'Primary Coach',
}

export enum UrlQueryParam {
  // Purposely shortened the query param in order to ofuscate the purpose to our end users.
  phaseId = 'phid',
}

export const NON_SERIES_UUID = 'non-series-uuid'

// Domain script source for cookie consent/privacy policy
export enum PrivacyScript {
  PROD = '7d06c0ac-5bcd-4e45-b5bc-09e89169b0a7',
  STAGING = '7d06c0ac-5bcd-4e45-b5bc-09e89169b0a7-test',
}

// Survey types by id
export const USER_NOTIFICATIONS = {
  firstMatchSurveyNotificationId: '8deae6dd-b547-48f2-858b-114412a957c1',
  matchSurveyNotificationId: '610c0f1f-210a-49f0-8559-a9e9f7d40649',
}

export const DEFAULT_PAGINATION_PAGE_LIMIT = 25

// Link to Spin page
export enum SpinLink {
  development = 'https://espin-git-develop-ceh.vercel.app',
  staging = 'https://espin-git-staging-ceh.vercel.app',
  production = 'https://www.staypluggedin.gg',
}

// Identifier for NASEF onboarding
export const NASEF_QUERY_PARAM = '?utm_source=nasef'
export const CA_CIF_SLUG = 'high_school_ca_cif'
export const NASEF_SLUG = 'nasef'

// Identifier for Vesl onboarding
export const VESL_SLUG = 'vesl'
export const VESL_VA_SLUG = 'high_school_nc_vesl'

export const MONTHS = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}

export const DAYS_OF_MONTH = Array.from({ length: 31 }, (_, index) => index + 1)

const MIN_YEAR = 1900
export const ONBOARD_YEARS = Array.from(
  { length: new Date().getFullYear() - MIN_YEAR + 1 },
  (_, index) => 1900 + index
).reverse()
