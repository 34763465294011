import React from 'react'
import { Pill } from '@playvs-inc/nexus-components'

import { Tooltip } from '@plvs/rally/components/tooltip'

type RecommendationLevel = 'high' | 'medium' | 'low'

const statusColorMap: Record<
  RecommendationLevel,
  | 'disabled'
  | 'inactive'
  | 'error'
  | 'success'
  | 'info'
  | 'misc'
  | 'warn'
  | 'brand'
> = {
  high: 'success',
  medium: 'disabled',
  low: 'brand',
}

const scoreToLevel = (score: number): RecommendationLevel => {
  if (score >= 70) return 'high'
  if (score <= 40) return 'low'
  return 'medium'
}

export const ScrimmageRecommendationBadge = ({
  recommendationPercent,
}: {
  recommendationPercent: number
}): React.ReactElement => {
  const truncatedPercentage = Math.trunc((recommendationPercent ?? 0) * 100)
  const adjustedPercentage =
    truncatedPercentage >= 100 ? 99 : truncatedPercentage

  return (
    <Tooltip title="This score indicates how evenly matched you are with an opponent.">
      <Pill
        label={`${adjustedPercentage}% match`}
        size="large"
        variant={statusColorMap[scoreToLevel(adjustedPercentage)]}
      />
    </Tooltip>
  )
}
