/* eslint-disable consistent-return */
import React, { ReactElement, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import dayjs from 'dayjs'

import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  useGetTeamOverviewQuery,
  useGetSlotQuery,
  useLeavePreseasonQueueMutation,
  useJoinPreseasonQueueMutation,
  useGetMatchDetailsQuery,
  EsportSlug,
  useCheckPlaceInPreseasonQueueQuery,
  refetchCheckPlaceInPreseasonQueueQuery,
} from '@plvs/graphql'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchImageLoader } from '@plvs/respawn/features/esport/creator/utils'
import { SlotQueueOpponentRow } from '@plvs/rally/features/match/queue/SlotQueueOpponentRow'
import {
  leaveQueueButtonClicked,
  pageRedirectFromMatchQueueToMatchLobby,
} from '@plvs/respawn/features/analytics'
import { Path } from '@plvs/const'
import { PreseasonQueueWaitingRow } from '@plvs/rally/features/match/queue/PreseasonQueueWaitingRow'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useStyles as useQueueStyles } from './slotQueue'
import { QueuePageWrapper } from './QueuePageWrapper'
import { initializePushMessaging } from './slotQueuePushHelper'

const SECONDS_UNTIL_REDIRECT = 90

export const PreseasonQueuePage: React.FC = () => {
  const { slotId, teamId, esportSlug } = useParams<{
    slotId: string
    teamId: string
    esportSlug: EsportSlug
  }>()
  const theme = useTheme()
  const mobile = useBreakpointSm()
  const {
    barTextColor,
    gameSubheader,
    pageWrapper,
    leaveQueueButton,
  } = useQueueStyles({ mobile })

  const { userId } = useUserIdentityFn()
  const navigate = useNavigate()

  const {
    data: teamOverviewData,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({
    variables: { teamId: teamId ?? '' },
    skip: !teamId,
  })
  const myTeam = teamOverviewData?.team

  const {
    name: esportName,
    bestOf,
    matchOverviewFormat,
    loading: esportLoading,
    hasSeries,
    seriesBestOf,
  } = useGeneralEsportAdapter(esportSlug ?? null)

  const { data: slotData } = useGetSlotQuery({
    variables: {
      id: slotId ?? '',
    },
    skip: !slotId,
  })

  const { imageSrc } = useMatchImageLoader({
    esportSlug: esportSlug as EsportSlug,
  })

  const [
    joinPreseasonQueueMutation,
    { loading: isLoadingJoinQueue, called },
  ] = useJoinPreseasonQueueMutation()
  const [leavePreseasonQueueMutation] = useLeavePreseasonQueueMutation()

  const {
    data,
    startPolling,
    stopPolling,
    loading: isLoadingCheckPlace,
  } = useCheckPlaceInPreseasonQueueQuery({
    variables: {
      teamId: teamId ?? '',
    },
    skip: !called || !teamId,
  })
  const queueData = data?.checkPlaceInPreseasonQueue
  const matchId = queueData?.matchId || ''

  const { data: matchData } = useGetMatchDetailsQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()

  useEffect(function stopPollingOnUnmount() {
    return (): void => {
      clearTimeout(timeoutId)
      stopPolling()
    }
  }, [])

  const lobbyUrl = matchId
    ? generatePath(Path.MatchLobby, {
        matchId,
      })
    : ''

  const opponentTeam =
    matchData?.match?.team1?.id === teamId
      ? matchData?.match?.team2
      : matchData?.match?.team1

  const leaveQueueMutate = async (): Promise<void> => {
    leaveQueueButtonClicked({
      timeStamp: new Date().toISOString(),
      userId,
      teamId: teamId ?? '',
      slotId: slotId ?? '',
      matchId,
    })

    try {
      await leavePreseasonQueueMutation({
        variables: {
          teamId: teamId ?? '',
        },
      })
    } catch {
      // suppress
    }

    clearTimeout(timeoutId)
    stopPolling()

    navigate(-1)
  }

  const navigateToMatch = async (): Promise<void> => {
    pageRedirectFromMatchQueueToMatchLobby({
      timeStamp: new Date().toISOString(),
      userId,
      teamId: teamId ?? '',
      slotId: slotId ?? '',
      matchId,
    })
    navigate(lobbyUrl)
  }

  useEffect(() => {
    if (queueData?.inQueue === false) {
      stopPolling()
    }
  }, [queueData?.inQueue])

  useEffect(
    function pollCheckPlaceInQueue(): void {
      if (!queueData?.nextScheduledPairing) {
        return
      }

      const nextScheduledPairingInMs = dayjs(
        queueData?.nextScheduledPairing
      ).diff(dayjs())

      stopPolling()

      // Poll every 60 seconds until we receive a match ID
      if (!matchId || matchId === 'pending') {
        startPolling(1000 * 60)
      }

      clearTimeout(timeoutId)

      // Poll every 10 second once we've hit the next scheduled pairing time
      const id = setTimeout(() => {
        stopPolling()
        if (!matchId || matchId === 'pending') {
          startPolling(1000 * 10)
        }
      }, nextScheduledPairingInMs + 1000 / 2)

      setTimeoutId(id)
    },
    [queueData?.nextScheduledPairing]
  )

  const joinQueue = (): void => {
    joinPreseasonQueueMutation({
      variables: {
        teamId: teamId ?? '',
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchCheckPlaceInPreseasonQueueQuery({ teamId: teamId ?? '' }),
      ],
    })
  }

  useEffect(() => {
    joinQueue()
    initializePushMessaging()
  }, [])

  useEffect(() => {
    if (matchId && matchId !== 'pending') {
      stopPolling()

      setTimeout(function redirectToMatch() {
        navigateToMatch()
      }, SECONDS_UNTIL_REDIRECT * 1000)
    }
  }, [matchId])

  useEffect(() => {
    if (matchId && matchId !== 'pending') {
      clearTimeout(timeoutId)
      stopPolling()
    }
  }, [matchId])

  const slotBestOf = hasSeries
    ? slotData?.slot?.seriesBestOf
    : slotData?.slot?.bestOf
  const esportBestOf = hasSeries ? seriesBestOf : bestOf

  const renderTeamRow = (): ReactElement => {
    if (matchId) {
      const timeUntilRedirect = dayjs(matchData?.match?.scheduledStartsAt).add(
        SECONDS_UNTIL_REDIRECT,
        'seconds'
      )

      return (
        <SlotQueueOpponentRow
          countdownTime={timeUntilRedirect}
          lobbyUrl={lobbyUrl}
          onCountdownComplete={async (): Promise<void> => {}}
          opponentTeam={opponentTeam}
          team={myTeam}
        />
      )
    }

    return (
      <PreseasonQueueWaitingRow
        hasError={false}
        inQueue={isLoadingCheckPlace || queueData?.inQueue !== false}
        isLoadingRejoinQueue={isLoadingJoinQueue}
        nextPairing={queueData?.nextScheduledPairing}
        numTeamsInQueue={queueData?.numTeamsInQueue ?? 1}
        onRejoinQueue={joinQueue}
        team={myTeam}
      />
    )
  }

  return (
    <QueuePageWrapper
      backgroundImageSrc={imageSrc}
      esportSlug={esportSlug!}
      loading={teamOverviewLoading || esportLoading}
      pageDataTestId="PreseasonQueuePage_Page"
      pageTitle="Match Queue"
    >
      <Box
        alignItems="center"
        className={pageWrapper}
        color={theme.palette.OverlayColorTextBase}
        flexDirection="column"
        flexGrow="0"
      >
        <Box display="flex" flexGrow="0" mt={3}>
          <NxTypography color="inherit" variant="display">
            Find a Match
          </NxTypography>
        </Box>

        {mobile ? (
          <></>
        ) : (
          <Box
            className={gameSubheader}
            color={theme.palette.OverlayColorTextBase}
            flexGrow="0"
            mt={2}
          >
            <NxTypography color="inherit" variant="overline">
              best of {slotBestOf ?? esportBestOf}
            </NxTypography>
            <NxTypography className={barTextColor} variant="overline">
              |
            </NxTypography>
            <NxTypography color="inherit" variant="overline">
              {esportName}
            </NxTypography>
            <NxTypography className={barTextColor} variant="overline">
              |
            </NxTypography>
            <NxTypography color="inherit" variant="overline">
              {matchOverviewFormat}
            </NxTypography>
          </Box>
        )}

        {renderTeamRow()}

        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          flexGrow="0"
          gridGap={theme.spacing(1)}
          mt={-10}
        >
          <NxButton
            className={!matchId ? undefined : leaveQueueButton}
            data-cy="leaveQueueButton"
            data-testid="PreseasonQueuePage_LeaveButton"
            label="Leave Queue"
            onClick={(): Promise<void> => leaveQueueMutate()}
            shouldUseOverlayColors
            variant="secondary"
          />

          <NxTypography colorToken="OverlayColorTextBase" variant="body1">
            Please stay on this page to remain in the queue
          </NxTypography>
        </Box>
      </Box>
    </QueuePageWrapper>
  )
}
