import { pick } from 'ramda'
import { isArray } from 'ramda-adjunct'
import React, { useMemo } from 'react'
import { Box as MuiBox } from '@material-ui/core'
import { BoxProps as MUIBoxProps } from '@material-ui/core/Box'

/*
  Box<override>
  ------------
  This allows use to still use box for all the things that it helps with
  but forego using it for it when it causes performance issues.

  Till the performance issues are resolved use this implementation.
  issue: https://github.com/mui-org/material-ui/pull/16858
  TODO: migrate back after merge

  please link directly to this box via
  import { Box } from '../core/components/Box'

  all documentation found here should work
  https://material-ui.com/components/box/


  Unsupported props
  -------
  These prop are based on the theme.
*/

const UNSUPPORT_PROPS = [
  'clone',
  'borderColor',
  'borderRadius',
  'color',
  'bgcolor',
  'zIndex',
  'boxShadow',
  'm',
  'mt',
  'mr',
  'ml',
  'mb',
  'mx',
  'my',
  'p',
  'pt',
  'pr',
  'pl',
  'pb',
  'px',
  'py',
  'fontFamily',
  'fontSize',
  'fontWeight',
]

/*
  Supported props
  -------
  These props that we can throw into styles object.
*/

const SUPPORT_PROPS = [
  // border
  'border',
  'borderTop',
  'borderLeft',
  'borderRight',
  'borderBottom',
  // display
  'display',
  'overflow',
  'textOverflow',
  'visibility',
  'whiteSpace',
  // flex
  'flex',
  'flexDirection',
  'flexWrap',
  'justifyContent',
  'alignItems',
  'alignContent',
  'order',
  'flexGrow',
  'flexShrink',
  'alignSelf',
  'gridGap',
  // margin
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  // padding
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  // positions
  'position',
  'top',
  'right',
  'left',
  'bottom',
  // sizing
  'width',
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  // typography
  'letterSpacing',
  'lineHeight',
  'textAlign',
]

const getProps = (
  props: BoxProps,
  propKeys: string[],
  isUnsupported: boolean
): BoxProps => {
  if (isUnsupported) return props
  const { style } = props
  const propValues = Object.values(props)
  const propStyles: BoxProps = {}
  const otherProps: BoxProps = {}
  propKeys.forEach((key, i) => {
    const isSupported = SUPPORT_PROPS.includes(key)
    const value = propValues[i]
    const target = isSupported ? propStyles : otherProps
    if (key !== 'style') {
      Object.defineProperty(target, key, {
        enumerable: true,
        value,
      })
    }
  })
  const styleObj = Object.assign(propStyles, style)
  return {
    style: styleObj,
    ...otherProps,
  }
}

export type BoxProps = MUIBoxProps

export const Box = React.forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLElement>): React.ReactElement => {
    const propKeys = Object.keys(props)
    const propValues = Object.values(pick(SUPPORT_PROPS, props))
    const isUnsupported =
      propKeys.some((key) => UNSUPPORT_PROPS.includes(key)) ||
      propValues.some(isArray)
    const overrideProps = useMemo(
      () => getProps(props, propKeys, isUnsupported),
      [props, propKeys, isUnsupported]
    )
    if (isUnsupported) {
      // has unsupported props, use MUI box.
      // @ts-ignore - because the types for the original box does not seem to have ref
      return <MuiBox ref={ref} {...overrideProps} />
    }
    const { component = 'div', children } = props
    const Component = component
    return (
      <Component ref={ref} {...overrideProps}>
        {children}
      </Component>
    )
  }
)

Box.displayName = 'Box<override>'
