import React from 'react'
import { Box, useTheme } from '@material-ui/core'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
  NxScoreboardRows,
} from '@playvs-inc/nexus-components'

import { FirebobMessage } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { useStyles } from '../../../MatchAssistant.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'

interface MatchPreviewProps {
  onNext?(): void
}

export const MatchPreview: React.FC<MatchPreviewProps> = ({ onNext }) => {
  const theme = useTheme()
  const styles = useStyles()
  const {
    currentPlayerOptions,
    currentPlayers,
    homeCharacter,
    awayCharacter,
    selectedStage,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    currentStep,
    gameOrdinal,
    esportSlug,
  } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const currentPlayerOptionsDisplay = Array({
    title: 'Players',
    showScore: false,
    leftSide: {
      ...currentPlayerOptions[0],
      subtitle: currentPlayerOptions[0]?.userProviderName,
    },
    rightSide: {
      ...currentPlayerOptions[1],
      subtitle: currentPlayerOptions[1]?.userProviderName,
    },
    customMiddle: (
      <Box className={styles.desktopOnly} px={2}>
        <NxTypography variant="h4">vs</NxTypography>
      </Box>
    ),
  })

  const handleNext = (): void => {
    if (currentStep?.step?.id) {
      setActionsTakenInSteps({
        ...actionsTakenInSteps,
        [currentStep.step.id]: new Date().toISOString(),
      })
    }
    onNext?.()
  }

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <NxTypography variant="h1">Match Preview</NxTypography>

        <Box py={2}>
          <NxTypography variant="overline">Characters</NxTypography>
        </Box>
      </Box>

      <SelectedCharacters
        awayPlayer={{
          id: currentPlayers[1]?.id,
          name: currentPlayers[1]?.id,
          avatarUrl: currentPlayers[1]?.id,
          selection: awayCharacter?.selectionOption,
          useAvatar: !awayCharacter?.selectionOption?.assetUri,
        }}
        esportSlug={esportSlug}
        homePlayer={{
          id: currentPlayers[0]?.id,
          name: currentPlayers[0]?.id,
          avatarUrl: currentPlayers[0]?.id,
          selection: homeCharacter?.selectionOption,
          useAvatar: !homeCharacter?.selectionOption?.assetUri,
        }}
      />

      <Box
        borderBottom={`1px solid ${theme.palette.BorderLight}`}
        mb={4}
        mx={-3}
      />

      <Box alignItems="center" display="flex" flexDirection="column">
        <NxScoreboardRows data={currentPlayerOptionsDisplay} requireTitle />

        <Box pb={0.5} pt={1}>
          <NxTypography variant="overline">Stage</NxTypography>
        </Box>

        <NxTypography variant="body1">
          {selectedStage?.selectionOption?.displayName}
        </NxTypography>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={
            canParticipate
              ? `Go play game ${gameOrdinal} and come back to report the results`
              : FirebobMessage.Spectator
          }
        />

        {canParticipate && (
          <NxButton
            className={styles.cta}
            fullWidth
            label="Report Results"
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </>
  )
}
