/* istanbul ignore file */
// This takes an unusally large amount of low level mocking in order to test.
// At this point it would be more test code written against the specific
// implementation to be worth the effort/return from such a test.

import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { Cookie } from '@plvs/const'
import { IS_BROWSER, safeRemoveCookie } from '@plvs/utils'
import { GraphQLError } from 'graphql'
import { path } from 'ramda'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const errorLink = (): ApolloLink =>
  onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message }) => {
        switch (extensions?.code) {
          case 'UNAUTHENTICATED':
            // catch any cases where we have an invalid JWT
            if (
              message.match(/invalid signature/gi) ||
              message.match(/invalid token/gi) ||
              message.match(/not a valid user account/gi) ||
              message.match(/Your token has expired/gi)
            ) {
              // delete the cookie containing the JWT
              safeRemoveCookie(cookies.remove.bind(cookies), Cookie.Token)
              // and refresh because if we don't, apollo will keep sending its cached (invalid) JWT
              window.location.reload()
            }
            break
          default:
        }
      })
    }

    if (networkError) {
      const errors = path(['result', 'errors'], networkError) as GraphQLError[]
      if (!IS_BROWSER) {
        // eslint-disable-next-line no-console
        if (errors) console.error('GraphQL Network Error', errors)
      }
    }
  })
