import {
  HasCoach,
  OnboardKnowACoachOptions,
} from '@plvs/rally/components/onboard/OnboardKnowACoachOptions'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import React, { useState } from 'react'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from './OnboardingContext'

export const OnboardHaveCoachInMind: React.FC = () => {
  const navigate = useNavigate()
  const { assign, data: onboardContext } = useOnboardingContext()

  const [hasCoachOption, setHasCoachOption] = useState<HasCoach | null>(
    (onboardContext.hasCoachInMind as HasCoach) ?? null
  )

  const showNASEFUx = onboardContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const onContinue = (): void => {
    assign({
      hasCoachInMind: hasCoachOption,
    })

    if (hasCoachOption === HasCoach.True) {
      navigate(`${Path.SpawnPoint}/faculty/faculty-invite-coach${showNASEFUx}`)
      return
    }

    navigate(`${Path.SpawnPoint}/faculty/faculty-contact${showNASEFUx}`)
  }

  return (
    <QuestionnaireCard
      buttonOpacity={!hasCoachOption}
      childWidth={544}
      onContinue={onContinue}
      subtitle="A coach can be any faculty member at your school. They will serve as the main point of contact for your esports program."
      title="Do you have a coach in mind?"
    >
      <OnboardKnowACoachOptions
        onChange={setHasCoachOption}
        selected={hasCoachOption}
      />
    </QuestionnaireCard>
  )
}
