import React, { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { ChildSettingsCard } from './ChildSettingsCard'

type ChildInfoProps = {
  name: string | null | undefined
  dateOfBirth: string | null | undefined
  graduationYear: number | null | undefined
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    minWidth: '195px',
    marginRight: theme.spacing(3),
  },
}))

export const ChildInfoSettings: React.FC<ChildInfoProps> = ({
  name,
  dateOfBirth,
  graduationYear,
}) => {
  const classes = useStyles()

  const formattedDateOfBirth = useMemo(() => {
    return dateOfBirth ? dayjs(dateOfBirth).format('MMMM D, YYYY') : null
  }, [dateOfBirth])

  const nameCluster = (
    <Box data-testid="child-name" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Name
      </NxTypography>
      <NxTypography variant="body1">{name}</NxTypography>
    </Box>
  )

  const dateOfBirthCluster = (
    <Box data-testid="child-name" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Date of Birth
      </NxTypography>
      <NxTypography variant="body1">{formattedDateOfBirth}</NxTypography>
    </Box>
  )

  const graduationYearCluster = (
    <Box data-testid="child-name" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Middle School Graduation Year
      </NxTypography>
      <NxTypography variant="body1">{graduationYear}</NxTypography>
    </Box>
  )

  const titleCta = (
    <Link to="edit-info">
      <NxButton
        data-testid="edit-details-button"
        label="Edit"
        variant="secondary"
      />
    </Link>
  )

  return (
    <Box mt={3}>
      <ChildSettingsCard
        customContent={[
          { id: 'name', content: nameCluster },
          { id: 'dateOfBirth', content: dateOfBirthCluster },
          { id: 'graduationYear', content: graduationYearCluster },
        ]}
        title="Child Information"
        titleCta={titleCta}
      />
    </Box>
  )
}
