import React, { useEffect, useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography, LoadingSpinner } from '@playvs-inc/nexus-components'
import { makeStyles, Avatar as MuiAvatar, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

const useStyles = makeStyles((theme) => ({
  schoolName: {
    color: theme.palette.common.white,
  },
  fireBobAvatar: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    backgroundColor: theme.palette.common.white,
    width: mobile ? '80px' : '150px',
    height: mobile ? '80px' : '150px',
  }),
  teamsContainer: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    alignItems: 'center',
    flexGrow: mobile ? 1 : 2,
    marginBottom: mobile ? '0' : '250px',
    width: mobile ? '100%' : '50%',
    flexDirection: mobile ? 'column' : 'row',
  }),
  searchMarginHack: {
    visibility: 'hidden',
  },
  teamLabel: {
    marginTop: theme.spacing(3),
  },
  mobileTeamNameContainer: {
    marginLeft: theme.spacing(3),
  },
  mobileTeamContainer: {
    marginBottom: theme.spacing(2),
  },
  mobileHeader: {
    marginBottom: theme.spacing(10),
  },
}))

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface MatchQueueWaitingRowProps {
  team?: Team | null
}

export const MatchQueueWaitingRow: React.FC<MatchQueueWaitingRowProps> = ({
  team,
}) => {
  const mobile = useBreakpointSm()
  const {
    schoolName,
    fireBobAvatar,
    teamsContainer,
    searchMarginHack,
    teamLabel,
    mobileTeamNameContainer,
    mobileTeamContainer,
    mobileHeader,
  } = useStyles({ mobile })
  const theme = useTheme()

  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timerInterval = setInterval(() => {
      const nextSecond = seconds + 1
      if (nextSecond === 60) {
        setMinutes(minutes + 1)
        setSeconds(0)
      } else {
        setSeconds(nextSecond)
      }
    }, 1000)
    return (): void => {
      clearInterval(timerInterval)
    }
  }, [minutes, seconds])

  const [searchText, setSearchText] = useState('Searching')
  useEffect(() => {
    const searchInterval = setInterval(() => {
      switch (searchText) {
        case 'Searching':
          setSearchText('Searching.')
          break
        case 'Searching.':
          setSearchText('Searching..')
          break
        case 'Searching..':
          setSearchText('Searching...')
          break
        case 'Searching...':
          setSearchText('Searching')
          break
        default:
          setSearchText('Searching')
      }
    }, 1000)
    return (): void => {
      clearInterval(searchInterval)
    }
  }, [searchText])

  const secondsText = seconds < 10 ? `0${seconds}` : seconds
  const isYouthProgram = team?.esport?.rating === EsportRating.Restricted
  const teamLogo = isYouthProgram ? undefined : team?.school?.logoUrl

  return (
    <Box className={teamsContainer} data-testid="MatchQueueWaitingRow">
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            color={theme.palette.OverlayColorTextBase}
            data-testid="MatchQueueWaitingRow_Mobile"
            display="flex"
            flexDirection="column"
          >
            <NxTypography color="inherit" variant="h3">
              Looking for a Team
            </NxTypography>
            <NxTypography color="inherit" variant="h3">
              {minutes}:{secondsText}
            </NxTypography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={teamLogo}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                color={theme.palette.OverlayColorTextBase}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography color="inherit" variant="h1">
                  {team?.name}
                </NxTypography>
                {!isYouthProgram && (
                  <NxTypography
                    className={schoolName}
                    color="inherit"
                    variant="subtitle2"
                  >
                    {team?.school?.name}
                  </NxTypography>
                )}
              </Box>
            </Box>
            <Box display="flex" flex="1" flexDirection="row" marginRight="auto">
              <Box flex="1" flexDirection="column">
                <Box display="flex" flex="1" flexDirection="row">
                  <MuiAvatar className={fireBobAvatar}>
                    <LoadingSpinner />
                  </MuiAvatar>
                  <NxTypography
                    className={mobileTeamNameContainer}
                    colorToken="OverlayColorTextBase"
                    variant="h1"
                  >
                    {searchText}
                  </NxTypography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            marginRight="auto"
          >
            <NxUserAvatar
              avatarUrl={teamLogo}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.XXL}
            />
            <NxTypography className={teamLabel} color="inherit" variant="h1">
              {team?.name}
            </NxTypography>
            {!isYouthProgram && (
              <NxTypography
                className={schoolName}
                color="inherit"
                variant="subtitle2"
              >
                {team?.school?.name}
              </NxTypography>
            )}
          </Box>
          <Box
            alignItems="center"
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxTypography color="inherit" variant="h2">
              Looking for a Team
            </NxTypography>
            <NxTypography color="inherit" variant="display">
              {minutes}:{secondsText}
            </NxTypography>
          </Box>
          <Box
            alignItems="flex-end"
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            marginLeft="auto"
          >
            <Box flex="1" flexDirection="column">
              <MuiAvatar className={fireBobAvatar}>
                <LoadingSpinner />
              </MuiAvatar>
              <NxTypography className={teamLabel} color="inherit" variant="h1">
                {searchText}
              </NxTypography>
              <NxTypography
                className={searchMarginHack}
                color="inherit"
                variant="subtitle2"
              >
                &nbsp;
                {/* Hack to make sure the right side "searching" container has same height as home team */}
              </NxTypography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
