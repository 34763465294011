import { formatISO } from 'date-fns'
import React from 'react'
import { head } from 'ramda'

import { formErrorToString } from '@plvs/utils'
import {
  MatchRescheduleRequestReason,
  MatchRescheduleRequestStatus,
  RequestMatchRescheduleInput,
  RequestMatchRescheduleWithinSchoolInput,
  CancelMatchRescheduleInput,
  ResponseMatchRescheduleInput,
  MatchRescheduleResponse,
  useRequestMatchRescheduleWithinSchoolMutation,
  useRequestMatchRescheduleMutation,
  useRespondToMatchRescheduleRequestMutation,
  useCancelMatchRescheduleMutation,
  EsportSlug,
} from '@plvs/graphql'
import dayjs from 'dayjs'
import {
  NxButton,
  NxDialogProps,
  NxModal,
  NxTextArea,
} from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import {
  RescheduleMatch,
  RescheduleMatchStep,
  getAvailableTimes,
  DateOption,
  TimeOption,
  createDateWithTime,
  getProposedDateTimesFromIndices,
  useGetLatestAvailableRescheduleDate,
  calculateDateOptions,
} from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { RescheduleReasonStep } from './RescheduleReasonStep'
import { RescheduleProposedTimesStep } from './RescheduleProposedTimesStep'
import { RescheduleSuccessStep } from './RescheduleSuccessStep'
import { RescheduleConfirm } from './RescheduleConfirm'
import { RescheduleUpdate } from './RescheduleUpdate'

const useStyles = makeStyles({
  contentClassName: {
    marginBottom: 0,
  },
})
/**
 * Reschedule Windows
 *
 * C = Current time and day
 * SA = Time of Slot with status 'awaiting'
 * SX = Time of Slot with status 'matchesScheduled'
 * S = Time of Slot with or without status 'awaiting'
 * P = End of regular phase
 * . = Reschedule window
 *
 * All reschedule windows are capped at two weeks max.
 *
 * 1 -- All Non-New Mexico Leagues (Default Behavior)
 *
 * If match phase has slots with status 'awaiting':
 * C----------SX------SA------SA------SA-----P-------------
 *            ^ I want to reschedule this match
 * ....................
 *
 * If match phase does NOT have slots with status 'awaiting':
 * C-----SX-----------SX----------------P------------------
 *       ^ I want to reschedule this match
 * ......................................
 *
 * 2 -- New Mexico High School Leagues
 *
 * If match phase has slots:
 * C----------S------S------S------S-----P-----------------
 *            ^ I want to reschedule this match
 *            ......................
 *
 * If I want to reschedule a match in the future:
 * C----------S------S------S------S-----P-----------------
 *                   ^ I want to reschedule this match
 *                   ...............
 *
 * If match phase does NOT have slots:
 * C-------------------------------------P-----------------
 * .......................................
 *
 */
interface RescheduleMatchDialogProps {
  closeDialog(): void
  onReschedule?(): void
  onRespond?(response: MatchRescheduleResponse): void
  isDialogOpen: boolean
  isReadOnly?: boolean
  isSameSchool?: boolean
  match: RescheduleMatch
  step: RescheduleMatchStep
  setStep(step: RescheduleMatchStep): void
  teamId: string
  esportSlug: EsportSlug | null | undefined
}

export const RescheduleMatchDialog: React.FC<RescheduleMatchDialogProps> = ({
  closeDialog,
  match,
  isDialogOpen,
  isSameSchool = false,
  step,
  setStep,
  teamId,
  onReschedule = (): void => {},
  onRespond = (): void => {},
  esportSlug,
}) => {
  const classes = useStyles()
  const latestAvailableDate = useGetLatestAvailableRescheduleDate(match.id)
  const availableDates: DateOption[] = calculateDateOptions(
    dayjs().toISOString(),
    latestAvailableDate
  )

  const availableTimes: TimeOption[] = getAvailableTimes()

  // uses indices of availableDates for ease of updating
  const [proposedDateIndices, setProposedDateIndices] = React.useState<
    number[]
  >([0, 0])
  const [proposedTimeIndices, setProposedTimeIndices] = React.useState<
    number[]
  >([0, 1])

  const [reason, setReason] = React.useState<string>()
  const [reasonDetails, setReasonDetails] = React.useState<string | null>(null)
  const [acceptedDate, setAcceptedDate] = React.useState<string>()
  const [formError, setFormError] = React.useState()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onClose = (): void => {
    closeDialog()
    setStep('init')
    setReason('')
    setAcceptedDate('')
  }

  // mutations
  const [createMutation, { data: createData }] =
    useRequestMatchRescheduleMutation()
  const createProposedTimes = createData?.requestMatchReschedule?.proposedTimes

  const [createWithinSchoolMutation, { data: createWithinSchoolData }] =
    useRequestMatchRescheduleWithinSchoolMutation()
  const createWithinSchoolProposedTimes =
    createWithinSchoolData?.requestMatchRescheduleWithinSchool?.proposedTimes

  const [updateMutation, { data: updateData }] =
    useRespondToMatchRescheduleRequestMutation()
  const updateProposedTimes =
    updateData?.respondToMatchRescheduleRequest?.proposedTimes

  const [deleteMutation] = useCancelMatchRescheduleMutation()

  // grab the proposed times from whichever mutation runs for the success step
  const proposedTimes =
    createProposedTimes ||
    createWithinSchoolProposedTimes ||
    updateProposedTimes ||
    []

  const onCreate = async (
    input: RequestMatchRescheduleInput
  ): Promise<void> => {
    try {
      const created = await createMutation({
        variables: { input },
      })

      const successfullyCreated = !created?.errors?.length
      if (successfullyCreated) {
        onReschedule()
        setStep('requested-success')
      }
    } catch (e: any) {
      setFormError(e)
      setStep('requested-failure')
    }
  }

  const onCreateWithinSchool = async (
    input: RequestMatchRescheduleWithinSchoolInput
  ): Promise<void> => {
    try {
      const created = await createWithinSchoolMutation({
        variables: { input },
      })
      const successfullyCreated = !created?.errors?.length
      if (successfullyCreated) {
        onReschedule()
        setStep('requested-success')
      }
    } catch (e: any) {
      setFormError(e)
      setStep('requested-failure')
    }
  }

  const onUpdate = async (
    input: ResponseMatchRescheduleInput
  ): Promise<void> => {
    try {
      await updateMutation({
        variables: { input },
      })
      onRespond(input.response)
      setStep('requested-success')
    } catch (e: any) {
      setFormError(e)
      setStep('requested-failure')
    }
  }

  const onDelete = async (input: CancelMatchRescheduleInput): Promise<void> => {
    try {
      await deleteMutation({
        variables: { input },
      })
      onClose()
    } catch (e: any) {
      setFormError(e)
      setStep('requested-failure')
    }
  }

  const onSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    setIsSubmitting(true)
    if (isSameSchool) {
      const proposedDate: Date = createDateWithTime(
        availableDates[proposedDateIndices[0]],
        availableTimes[proposedTimeIndices[0]]
      )

      setAcceptedDate(formatISO(proposedDate))

      setStep('confirm-request')
      setIsSubmitting(false)
      return
    }

    const proposedDateTimes: string[] = getProposedDateTimesFromIndices(
      proposedDateIndices,
      proposedTimeIndices,
      availableDates,
      availableTimes
    )

    if (acceptedDate === 'counter') {
      const arr = (match?.matchRescheduleRequests ?? []).filter(
        (o) => o?.status === MatchRescheduleRequestStatus.Pending
      )
      const id = head(arr)?.id
      if (id) {
        await onUpdate({
          denyNotes: null,
          matchRescheduleRequestId: id,
          teamId,
          proposedTimes: proposedDateTimes,
          response: MatchRescheduleResponse.Resubmitted,
          selectedProposedTime: null,
        })
      }
    } else {
      await onCreate({
        matchId: match.id,
        teamId,
        rescheduleReason: reason as MatchRescheduleRequestReason,
        rescheduleNotes: reasonDetails,
        proposedTimes: proposedDateTimes,
      })
    }
    setIsSubmitting(false)
  }

  const onConfirm = async (): Promise<void> => {
    const arr = (match?.matchRescheduleRequests ?? []).filter(
      (o) => o?.status === MatchRescheduleRequestStatus.Pending
    )
    const id = head(arr)?.id
    if (id) {
      await onDelete({
        matchRescheduleRequestId: id,
        teamId,
      })
    }
  }

  const dialogProps: Record<RescheduleMatchStep, NxDialogProps> = {
    init: {
      onClose,
      open: isDialogOpen,
    },
    'requested-reason': {
      onClose,
      open: isDialogOpen,

      children: (
        <RescheduleReasonStep
          esportSlug={esportSlug ?? null}
          match={match}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            e.persist()
            setReason(e.target.value)
            setReasonDetails('')
          }}
          reason={reason}
          reasonDetails={reasonDetails}
          setReasonDetails={setReasonDetails}
        />
      ),
      actions: (
        <NxButton
          disabled={
            !reason ||
            (reason === MatchRescheduleRequestReason.Other &&
              (!reasonDetails || reasonDetails.length < 30))
          }
          label="Next"
          onClick={(): void => {
            setStep('requested-times')
          }}
          variant="primary"
        />
      ),
      subtitle: 'Please provide a few details.',
      title: 'Reschedule Match',
    },
    'requested-times': {
      onClose,
      open: isDialogOpen,
      subtitle:
        'Please use the match lobby chat to coordinate updated times with the opposing coach first prior to sending out a reschedule request. You may not submit the same date and time option twice.',
      contentClassName: classes.contentClassName,
      children: (
        <RescheduleProposedTimesStep
          availableDates={availableDates}
          availableTimes={availableTimes}
          isSameSchool={isSameSchool}
          isSubmitting={isSubmitting}
          onDayChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const availableDateIndex = Number(event.target.value)
            const proposedDateIndex: number = parseInt(
              event.target.name.charAt(event.target.name.length - 1),
              10
            )

            const selectedDate: DateOption = availableDates[availableDateIndex]
            if (selectedDate) {
              const newProposedDateIndices: number[] = [...proposedDateIndices]
              newProposedDateIndices[proposedDateIndex] = availableDateIndex
              setProposedDateIndices(newProposedDateIndices)
            }
          }}
          onPrev={(): void => {
            if (acceptedDate === 'counter') {
              setStep('update-request')
            } else {
              setStep('requested-reason')
            }
          }}
          onSubmit={onSubmit}
          onTimeChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const availableTimeIndex = Number(event.target.value)
            const proposedTimeIndex = parseInt(
              event.target.name.charAt(event.target.name.length - 1),
              10
            )

            const selectedTime = availableTimes[availableTimeIndex]
            if (selectedTime) {
              const newProposedTimeIndices = [...proposedTimeIndices]
              newProposedTimeIndices[proposedTimeIndex] = availableTimeIndex
              setProposedTimeIndices(newProposedTimeIndices)
            }
          }}
          proposedDateIndices={proposedDateIndices}
          proposedTimeIndices={proposedTimeIndices}
        />
      ),
      title: 'Reschedule Match',
    },
    'requested-success': {
      onClose,
      open: isDialogOpen,
      children: (
        <RescheduleSuccessStep
          acceptedDate={acceptedDate}
          match={match}
          proposedTimes={proposedTimes}
        />
      ),
      actions: <NxButton label="Done" onClick={onClose} variant="primary" />,
      subtitle: isSameSchool
        ? 'Your match has been updated!'
        : 'We’ve sent your opponent your suggested match times.',
      title: isSameSchool ? 'Match Rescheduled' : 'Reschedule Request Sent',
    },
    'requested-failure': {
      onClose,
      open: isDialogOpen,
      subtitle: `${formErrorToString(formError)}`,
      title: 'Reschedule Request Failed',
    },
    'read-request': {
      onClose,
      open: isDialogOpen,
      children: <RescheduleSuccessStep match={match} />,
      title: 'View Request',
    },
    'update-request': {
      actions: (
        <>
          <NxButton
            data-cy="denyRequestButton"
            disabled={!!acceptedDate}
            label="Deny Request"
            onClick={(): void => {
              setStep('deny-request')
            }}
            variant="text"
          />

          <NxButton
            data-cy="nextButton"
            disabled={!acceptedDate}
            label="Next"
            onClick={(): void => {
              if (acceptedDate === 'counter') {
                setStep('requested-times')
              } else {
                setStep('confirm-request')
              }
            }}
            variant="primary"
          />
        </>
      ),
      onClose,
      open: isDialogOpen,
      children: (
        <RescheduleUpdate
          acceptedDate={acceptedDate}
          match={match}
          onChange={setAcceptedDate}
        />
      ),
      subtitle:
        'Please review and select a new match time suggested by your opponent.',
      title: 'Reschedule Match',
    },
    'confirm-request': {
      onClose,
      open: isDialogOpen,
      actions: (
        <>
          <NxButton
            label="Previous"
            onClick={(): void => {
              if (isSameSchool) {
                setStep('requested-times')
              } else {
                setStep('update-request')
              }
            }}
            variant="text"
          />
          <NxButton
            label="Confirm"
            onClick={async (): Promise<void> => {
              if (acceptedDate) {
                if (isSameSchool) {
                  await onCreateWithinSchool({
                    matchId: match.id,
                    teamId,
                    updatedStartsAt: acceptedDate,
                  })
                } else {
                  const arr = (match?.matchRescheduleRequests ?? []).filter(
                    (o) => o?.status === MatchRescheduleRequestStatus.Pending
                  )
                  const id = head(arr)?.id
                  if (id) {
                    await onUpdate({
                      denyNotes: null,
                      matchRescheduleRequestId: id,
                      proposedTimes: null,
                      teamId,
                      selectedProposedTime: acceptedDate,
                      response: MatchRescheduleResponse.Approved,
                    })
                  }
                }
              }
            }}
            variant="primary"
          />
        </>
      ),
      subtitle:
        'Are you sure you want to reschedule for this time? Once rescheduled your team must play.',
      children: acceptedDate ? (
        <RescheduleConfirm acceptedDate={acceptedDate} />
      ) : null,
      title: 'Are you sure?',
    },
    'deny-request': {
      onClose,
      open: isDialogOpen,
      actions: (
        <>
          <NxButton
            label="Previous"
            onClick={(): void => {
              setStep('update-request')
            }}
            variant="text"
          />
          <NxButton
            label="Confirm"
            onClick={async (): Promise<void> => {
              const arr = (match?.matchRescheduleRequests ?? []).filter(
                (o) => o?.status === MatchRescheduleRequestStatus.Pending
              )
              const id = head(arr)?.id
              if (id) {
                await onUpdate({
                  matchRescheduleRequestId: id,
                  proposedTimes: null,
                  teamId,
                  response: MatchRescheduleResponse.Denied,
                  denyNotes: reasonDetails,
                  selectedProposedTime: null,
                })
                onClose()
              }
            }}
            variant="primary"
          />
        </>
      ),
      children: (
        <NxTextArea
          fullWidth
          label="Message"
          name="reasonDetail"
          onChange={(e): void => {
            setReasonDetails(e.target.value)
          }}
          placeholder="Message cannot be more than 300 characters."
          rows={6}
          value={reasonDetails ?? ''}
          variant="default"
        />
      ),
      subtitle:
        "Please let us know why you are denying your opponent's request for rescheduling.",
      title: 'Deny Request',
    },
    'delete-request': {
      onClose,
      open: isDialogOpen,
      subtitle:
        'Are you sure you want to cancel your reschedule request? You may request another if you change your mind.',
      actions: (
        <NxButton label="Confirm" onClick={onConfirm} variant="primary" />
      ),
      title: 'Are you sure?',
    },
  }

  return <>{step === 'init' ? null : <NxModal {...dialogProps[step]} />}</>
}
