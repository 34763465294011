import {
  EligiblePlayerDisabledReason,
  IntervalTense,
  PlayerPosition,
  TeamMember,
} from '@plvs/graphql/generated'
import { ConnectedAccountProviders } from '@plvs/utils'
import { RosterRowData } from '@plvs/respawn/features/roster/modal/RosterRow'
import { head } from 'ramda'
import { isNull, isNumber } from 'ramda-adjunct'
import * as uuid from 'uuid'
import {
  Position,
  CompetitionIntervalWithMetaseasonsForOrg,
  openSubPosition,
  Starter,
  Substitute,
  TeamPlayer,
} from '@plvs/respawn/features/manage-teams/types'

const animals = [
  'Alligator',
  'Ant',
  'Bear',
  'Bee',
  'Bird',
  'Camel',
  'Cat',
  'Cheetah',
  'Chicken',
  'Cow',
  'Crocodile',
  'Deer',
  'Dog',
  'Dolphin',
  'Duck',
  'Eagle',
  'Elephant',
  'Fish',
  'Fly',
  'Fox',
  'Frog',
  'Giraffe',
  'Goat',
  'Goldfish',
  'Hamster',
  'Hippopotamus',
  'Horse',
  'Kangaroo',
  'Kitten',
  'Lion',
  'Lobster',
  'Octopus',
  'Owl',
  'Panda',
  'Pig',
  'Puppy',
  'Rabbit',
  'Scorpion',
  'Seal',
  'Shark',
  'Sheep',
  'Snail',
  'Snake',
  'Spider',
  'Squirrel',
  'Tiger',
  'Turtle',
  'Wolf',
  'Zebra',
]

const colors = [
  'White',
  'Yellow',
  'Blue',
  'Red',
  'Green',
  'Black',
  'Brown',
  'Azure',
  'Ivory',
  'Teal',
  'Silver',
  'Purple',
  'Navy Blue',
  'Pea Green',
  'Gray',
  'Orange',
  'Maroon',
  'Charcoal',
  'Aquamarine',
  'Coral',
  'Fuchsia',
  'Wheat',
  'Lime',
  'Crimson',
  'Khaki',
  'Hot Pink',
  'Magenta',
  'Olden',
  'Plum',
  'Olive',
  'Cyan',
]

const randomN = (n: number): number => Math.floor(Math.random() * (n - 1))

export const generateName = (): string => {
  const truncatedUuid = head(uuid.v4().split('-'))

  return `${colors[randomN(colors.length)]} ${
    animals[randomN(animals.length)]
  } ${truncatedUuid}`
}

export const repositionPlayer = ({
  selectedPosition,
  player,
  playerToBeRepositionedIsSub,
}: {
  selectedPosition: Position
  player: {
    id: string
    position: number | null
  }
  playerToBeRepositionedIsSub: boolean
}): PlayerPosition[] => {
  const isSelectedPositionSubstitute = !isNumber(selectedPosition.positionIndex)

  const newPositions = [
    {
      id: player.id,
      positionId: selectedPosition.positionId,
      ...(isSelectedPositionSubstitute
        ? {
            isSubstitute: true,
          }
        : { position: selectedPosition.positionIndex }),
    },
  ]

  if (!selectedPosition.id.match(/open/gi)) {
    newPositions.push({
      id: selectedPosition.id,
      // NOTE: not passing positionId until we can update type of player to
      // include positionId
      positionId: null,
      ...(playerToBeRepositionedIsSub
        ? {
            isSubstitute: true,
          }
        : { position: player.position }),
    })
  }

  return newPositions
}

export const formatRosterRowDataFromUser = ({
  user,
  getUsername,
}: {
  user: TeamPlayer | undefined
  getUsername: (user: ConnectedAccountProviders) => string
}): Omit<RosterRowData, 'position' | 'positionId'> => ({
  title: user?.name ?? '',
  subtitle: user?.userProviderAccounts ? getUsername(user) : '',
  avatarSrc: user?.avatarUrl ?? '',
  id: user?.id ?? '',
})

export const createRosterRowDataForStarters = ({
  list,
  getUsername,
  player,
}: {
  list: Starter[]
  getUsername: (user: ConnectedAccountProviders) => string
  player: Pick<TeamMember, 'id'>
}): RosterRowData[] => {
  const filteredRosterRowStarters = list.filter(
    (starter) => starter.player?.user.id !== player.id
  )
  const rosterRowStarters = filteredRosterRowStarters.map((starter, i) => {
    const isPositionAvailable = isNull(starter.player)
    const user = starter.player?.user

    if (isPositionAvailable) {
      return {
        title: 'Position Available',
        position: starter.position.abbreviation ?? '',
        id: `open-starter${i}`,
        positionId: starter?.position?.id,
        positionIndex: starter?.position?.index,
        hasConsumedPass: undefined,
      }
    }
    return {
      position: starter?.position?.abbreviation ?? '',
      positionId: starter?.position?.id,
      positionIndex: starter?.position?.index,
      ...formatRosterRowDataFromUser({ user, getUsername }),
    }
  })
  return rosterRowStarters
}

export const createRosterRowDataForBench = ({
  list,
  getUsername,
  player,
  metaseasonId,
  leagueId,
  maxNumberOfSubs = 0,
}: {
  list: Substitute[]
  getUsername: (user: ConnectedAccountProviders) => string
  player: Pick<TeamMember, 'id'>
  metaseasonId: string | undefined
  leagueId: string | undefined
  maxNumberOfSubs: number | undefined
}): RosterRowData[] => {
  const filteredRosterRowBench = list.filter(
    (bench) => bench.user.id !== player.id
  )

  const numberOfOpenBenchPositions =
    maxNumberOfSubs - filteredRosterRowBench.length

  const numberOfOpenBenchPositionsList = new Array(
    numberOfOpenBenchPositions
  ).fill(undefined)
  const rosterRowBench: RosterRowData[] = filteredRosterRowBench.map(
    (bench) => {
      const user = bench?.user

      const currentSeasonPass = user?.seasonPasses?.find(
        (seasonPass) =>
          metaseasonId &&
          seasonPass.metaseasonId === metaseasonId &&
          leagueId === seasonPass?.leagueId &&
          !!seasonPass.consumedAt
      )
      return {
        position: 'Sub',
        positionId: null,
        positionIndex: undefined,
        ...formatRosterRowDataFromUser({ user, getUsername }),
        hasConsumedPass: !!currentSeasonPass?.consumedAt,
      }
    }
  )

  if (numberOfOpenBenchPositions > 0) {
    numberOfOpenBenchPositionsList.forEach((_, i) => {
      rosterRowBench.push({
        id: `open-sub${i}`,
        ...openSubPosition,
        hasConsumedPass: undefined,
      })
    })
  }

  return rosterRowBench
}

export const getLatestDateFn = (
  dates: (Date | undefined)[]
): Date | undefined => {
  if (!dates?.length) {
    return undefined
  }
  return dates.reduce((date1, date2) => {
    if (!date1) {
      return date2
    }
    if (!date2) {
      return date1
    }
    return date1 > date2 ? date1 : date2
  })
}

export function getCompetedEndDateFromCompetitionInterval(
  competitionInterval?: CompetitionIntervalWithMetaseasonsForOrg
): Date | null {
  if (!competitionInterval) {
    return null
  }
  const enrolledSeasons = competitionInterval?.interval.enrolledSeasons
  if (enrolledSeasons?.length) {
    const lastEndsAtDates = enrolledSeasons.map((season) => {
      if (season) {
        return new Date(season.endsAt)
      }
      return undefined
    })

    const lastEndsAtDate = getLatestDateFn(lastEndsAtDates)

    if (lastEndsAtDate) {
      return lastEndsAtDate
    }
  }
  return null
}

function getIntervalOfType(
  competitionInterval: CompetitionIntervalWithMetaseasonsForOrg[],
  intervalTense: IntervalTense
): CompetitionIntervalWithMetaseasonsForOrg | undefined {
  return competitionInterval.find(
    (interval) => interval.interval.tense === intervalTense
  )
}

export function getDefaultCompetitionInterval(input: {
  intervals: CompetitionIntervalWithMetaseasonsForOrg[]
  currentRegEndDate: Date | undefined
  participatingInCurrentMetaseason: boolean
}): CompetitionIntervalWithMetaseasonsForOrg | undefined {
  const { intervals, currentRegEndDate, participatingInCurrentMetaseason } =
    input
  const now = new Date()
  const currentInterval = getIntervalOfType(intervals, IntervalTense.Current)
  const futureInterval = getIntervalOfType(intervals, IntervalTense.Future)
  const pastInterval = getIntervalOfType(intervals, IntervalTense.Past)
  const currentEnrollmentOngoing: boolean = currentRegEndDate
    ? currentRegEndDate > now
    : false
  const currentIntervalEndsAt = getLatestDateFn(
    currentInterval?.metaseasonsForOrganization.map(({ endsAt }) =>
      endsAt ? new Date(endsAt) : undefined
    ) ?? []
  )
  if (
    currentEnrollmentOngoing ||
    participatingInCurrentMetaseason ||
    (!futureInterval?.metaseasonsForOrganization.length &&
      currentInterval &&
      currentIntervalEndsAt &&
      currentIntervalEndsAt > now)
  ) {
    return currentInterval
  }

  if (!participatingInCurrentMetaseason && !futureInterval && pastInterval) {
    return pastInterval
  }

  return futureInterval
}

export function getEligiblePlayerDisabledReasonText(
  reason: EligiblePlayerDisabledReason | null | undefined,
  esportName: string | null | undefined
): string | undefined {
  const esportNameText = esportName
    ? `${esportName} competitions`
    : 'this esport'

  switch (reason) {
    case EligiblePlayerDisabledReason.Underage:
      return `Players under the age of 13 cannot compete in ${esportNameText}. Please select a player over the age of 13.`
    default:
      return undefined
  }
}
