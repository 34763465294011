import { CompetitionGroup, CompetitionModel, SchoolType } from '@plvs/graphql'
import {
  competitionGroupConfig,
  CompetitionModelsConfig,
} from '../organization'

export const CompetitionModelMap: Record<CompetitionModel, string> = {
  [CompetitionModel.Rec]: 'Rec',
}

export const getDefaultCompetitionModel = (): CompetitionModel => {
  return CompetitionModel.Rec
}

export const competitionModelsConfigByEntity = ({
  competitionGroup,
}: {
  competitionGroup: CompetitionGroup | null | undefined
}): CompetitionModelsConfig =>
  competitionGroupConfig.competitionGroupConfigs.find(
    (config) => config.competitionGroup === competitionGroup
  )?.competitionModelConfigs ?? []

export const getFilteredCompetitionModel = ({
  competitionModelsConfig,
  competitionModel,
}: {
  competitionModelsConfig: CompetitionModelsConfig
  competitionModel: CompetitionModel | null
}): CompetitionModel | undefined => {
  const shouldFilterLeaguesByCompetitionModel =
    competitionModelsConfig.length > 0
  const selectedCompetitionModel = shouldFilterLeaguesByCompetitionModel
    ? competitionModel || getDefaultCompetitionModel()
    : undefined
  return selectedCompetitionModel
}

export const competitionModelToDisplay = ({
  competitionModel,
  competitionGroup,
}: {
  competitionModel: CompetitionModel | null | undefined
  competitionGroup: CompetitionGroup | undefined | null
}): string => {
  const competitionModelDefault = competitionModel || CompetitionModel.Rec
  switch (competitionModelDefault) {
    case CompetitionModel.Rec:
      switch (competitionGroup) {
        case CompetitionGroup.College:
          return 'National'
        case CompetitionGroup.HighSchool:
        case CompetitionGroup.MiddleSchool:
          return 'Regional'
        default:
          return ''
      }
    default:
      return ''
  }
}

export const SCHOOL_TYPE_COMPETITION_GROUP_MAP = {
  [SchoolType.HighSchool]: CompetitionGroup.HighSchool,
  [SchoolType.MiddleSchool]: CompetitionGroup.MiddleSchool,
  [SchoolType.College]: CompetitionGroup.College,
}
