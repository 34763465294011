import { Box, useTheme } from '@material-ui/core'
import { TaskState } from '@plvs/graphql/generated'
import React, { useState } from 'react'
import { Path } from '@plvs/const'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import {
  CheckboxDarkPrimarySmall,
  CheckboxLightPrimarySmall,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { useNavigate } from 'react-router-dom'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const CompleteChecklistTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  index: number
}> = ({ task, isMobile, index }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const [completedState] = useState<boolean>(task.state === TaskState.Completed)

  const handleResourcesRedirect = async (): Promise<void> => {
    navigate(Path.Resources)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Complete Esports Program Checklist
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Complete &rsquo;How to Start Your Esports Program&lsquo;
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="View on Resources"
          completed={completedState}
          onClick={handleResourcesRedirect}
          task={task}
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {isDarkMode ? (
            <CheckboxDarkPrimarySmall />
          ) : (
            <CheckboxLightPrimarySmall />
          )}
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
