import { yupResolver } from '@hookform/resolvers'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import {
  NxSelect,
  NxSelectOption,
  NxTextInput,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { stateNames } from '@plvs/const'
import {
  refetchGetSchoolBillingContactQuery,
  useGetSchoolBillingContactQuery,
  useSetSchoolBillingContactMutation,
} from '@plvs/graphql/generated/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { useWithSaveNotification } from '@plvs/rally/libs/notifications/useWithSaveNotification'
import { useWithSinglePendingRequest } from '@plvs/rally/libs/request-utils/useWithSinglePendingRequest'
import { formErrorToString, useAutoskipQuery } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { equals, values } from 'ramda'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  BillingInfoSchema,
  SchoolBasicInfoInput,
} from './SchoolBillingFormHelpers'

const useStyles = makeStyles((theme) => ({
  subHeader: {
    color: `${theme.palette.ColorTextAlt2} !important`,
  },
  planPriceDescription: {
    textTransform: 'uppercase',
  },
  planNamePill: {
    borderRadius: '1em',
    padding: '0.5em',
    marginRight: '1em',
    lineHeight: '3em',
    '& span': {
      textTransform: 'uppercase',
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
  },
  billingAddressGrid: {
    display: 'flex',
  },
  topBilling: {
    marginBottom: theme.spacing(2),
  },
  topBillingContent: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: `${theme.spacing(2.5, 3, 1.5)} !important`,
  },
  inputContainer: {
    maxWidth: '350px',
    paddingBottom: `${theme.spacing(0.5)}px !important`,
    paddingTop: `0px !important`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  dropdownContainer: {
    maxWidth: '350px',
    paddingTop: `0px !important`,
    paddingBottom: `${theme.spacing(2)}px !important`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  planCoverageDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textSecondary: {
    color: `${theme.palette.ColorTextAlt2} !important`,
  },
}))

/**
 * This is the panel in the school settings page that handles the basic info
 * such as address and mascot name.
 */
export const SchoolBillingFormPanel: React.FC<{ cpSchoolId?: string }> = ({
  cpSchoolId,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  // Queries
  // Grab school data from cache.

  const { id: orgId } = useSelectedOrganizationFn()
  const schoolId = cpSchoolId ?? orgId ?? ''

  const { data: schoolData } = useAutoskipQuery(
    useGetSchoolBillingContactQuery,
    {
      variables: {
        schoolId,
      },
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'standby',
    }
  )

  const billingContact = schoolData?.school?.billingContact

  // init billing form values
  const initialValues = {
    // If billing info does not exist, we try to fallback to the associated
    // user or the school.
    firstName:
      billingContact?.firstName ?? billingContact?.user?.firstName ?? '',
    lastName: billingContact?.lastName ?? billingContact?.user?.lastName ?? '',
    phone: billingContact?.phone ?? billingContact?.user?.phone ?? '',
    phoneExt: billingContact?.phoneExt ?? billingContact?.user?.phoneExt ?? '',
    email: billingContact?.email ?? billingContact?.user?.email ?? '',
    address: billingContact?.address ?? schoolData?.school?.address ?? '',
    city: billingContact?.city ?? schoolData?.school?.city ?? '',
    state: billingContact?.state ?? schoolData?.school?.state ?? '',
    zip: billingContact?.zip ?? schoolData?.school?.zip ?? '',
  }
  // Note: this is technically not safe, but works in this case because I pre
  // queried the data in a parent component, and we can pull directly off the
  // cache.
  const [savedInitValues, setSavedInitValues] = useState(initialValues)
  // end init billing form values

  // Hooks
  const formRef = useRef<HTMLFormElement>(null)
  const withSaveNotification = useWithSaveNotification()
  const withSinglePendingRequest = useWithSinglePendingRequest()

  const {
    errors,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
  } = useForm<SchoolBasicInfoInput>({
    resolver: yupResolver(BillingInfoSchema),
    defaultValues: savedInitValues,
    shouldFocusError: false,
  })

  // Mutations
  const [mutateBillingContact] = useSetSchoolBillingContactMutation()

  // Side effects

  // Form handlers
  const onSubmit = useCallback(
    handleSubmit((input: SchoolBasicInfoInput): void => {
      withSinglePendingRequest(async () => {
        await withSaveNotification(async () => {
          await mutateBillingContact({
            variables: {
              schoolId,
              input: {
                email: input.email,
                firstName: input.firstName,
                lastName: input.lastName,
                organizationRole: 'n/a',
                phone: input.phone,
                phoneExt: input.phoneExt,
                address: input.address,
                city: input.city,
                state: input.state,
                zip: input.zip,
                userId: null,
              },
            },
            refetchQueries: [refetchGetSchoolBillingContactQuery({ schoolId })],
          })
        })

        setSavedInitValues({
          ...input,
        })
      })
    }),
    [
      withSinglePendingRequest,
      withSaveNotification,
      mutateBillingContact,
      schoolId,
    ]
  )

  const onBlur = useCallback(async (): Promise<void> => {
    const oldValues = savedInitValues
    const newValues = getValues()

    const hasChanges = !equals(oldValues, newValues)
    if (!hasChanges) {
      return
    }
    try {
      await BillingInfoSchema.validate(values)
    } catch (err: unknown) {
      if (err instanceof yup.ValidationError) {
        const firstError = err.errors[0]
        enqueueSnackbar(firstError, {
          variant: 'error',
          transitionDuration: {
            appear: 5000,
          },
        })

        setError(err.path as keyof SchoolBasicInfoInput, {
          message: firstError,
          type: err.type,
        })
        return
      }
      throw err // otherwise throw and let sentry error capture
    }
    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }, [onSubmit, savedInitValues, getValues, enqueueSnackbar, formRef])

  return (
    <Box>
      <Box mb={2}>
        <NxTypography variant="h2">Billing Account</NxTypography>
        <NxTypography className={classes.subHeader} variant="body1">
          Edit your schools billing contact information
        </NxTypography>
      </Box>
      <form ref={formRef} noValidate onBlur={onBlur} onSubmit={onSubmit}>
        <Card className={classes.topBilling}>
          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="column">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <NxTypography variant="h4">Billing Contact</NxTypography>
                  <NxTypography
                    className={classes.textSecondary}
                    variant="body1"
                  >
                    Please provide the contact info for the person who processes
                    payments, typically in Accounts Payable.
                  </NxTypography>
                </Grid>
                <Grid className={classes.inputContainer} item sm={6} xs={12}>
                  <NxTextInput
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {...((register('firstName') as unknown) as Object)}
                    defaultValue={getValues().firstName}
                    fullWidth
                    helperText={formErrorToString(errors.firstName)}
                    label="First Name"
                    onChange={(e): void =>
                      setValue('firstName', e.currentTarget.value)
                    }
                    tabIndex={0}
                    variant={errors.firstName ? 'error' : 'default'}
                  />
                </Grid>
                <Grid className={classes.inputContainer} item sm={6} xs={12}>
                  <NxTextInput
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {...((register('lastName') as unknown) as Object)}
                    defaultValue={getValues().lastName}
                    fullWidth
                    helperText={formErrorToString(errors.firstName)}
                    label="Last Name"
                    onChange={(e): void =>
                      setValue('lastName', e.currentTarget.value)
                    }
                    tabIndex={0}
                    variant={errors.lastName ? 'error' : 'default'}
                  />
                </Grid>
                <Grid className={classes.inputContainer} item sm={6} xs={12}>
                  <NxTextInput
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {...((register('phone') as unknown) as Object)}
                    defaultValue={getValues().phone}
                    fullWidth
                    helperText={formErrorToString(errors.phone)}
                    label="Phone"
                    onChange={(e): void =>
                      setValue('phone', e.currentTarget.value)
                    }
                    tabIndex={0}
                    type="number"
                    variant={errors.phone ? 'error' : 'default'}
                  />
                </Grid>
                <Grid className={classes.inputContainer} item sm={6} xs={12}>
                  <NxTextInput
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {...((register('phoneExt') as unknown) as Object)}
                    defaultValue={getValues().phoneExt}
                    fullWidth
                    helperText={formErrorToString(errors.phoneExt)}
                    label="Ext"
                    onChange={(e): void =>
                      setValue('phoneExt', e.currentTarget.value)
                    }
                    tabIndex={0}
                    variant={errors.phoneExt ? 'error' : 'default'}
                  />
                </Grid>
                <Grid className={classes.inputContainer} item sm={6} xs={12}>
                  <NxTextInput
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {...((register('email') as unknown) as Object)}
                    defaultValue={getValues().email}
                    fullWidth
                    helperText={formErrorToString(errors.email)}
                    label="School Email"
                    onChange={(e): void =>
                      setValue('email', e.currentTarget.value)
                    }
                    tabIndex={0}
                    variant={errors.email ? 'error' : 'default'}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Card>
          <CardContent className={classes.content}>
            <Grid className={classes.billingAddressGrid} container spacing={2}>
              <Grid item xs={12}>
                <NxTypography variant="h4">Billing Address</NxTypography>
                <NxTypography className={classes.textSecondary} variant="body1">
                  Please provide the address of the department that processes
                  payments, typically in Accounts Payable.
                </NxTypography>
              </Grid>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <NxTextInput
                  // eslint-disable-next-line @typescript-eslint/ban-types
                  {...((register('address') as unknown) as Object)}
                  defaultValue={getValues().address}
                  fullWidth
                  helperText={formErrorToString(errors.address)}
                  label="Street Address"
                  onChange={(e): void =>
                    setValue('address', e.currentTarget.value)
                  }
                  tabIndex={0}
                  variant={errors.address ? 'error' : 'default'}
                />
              </Grid>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <></>
              </Grid>
              <Grid className={classes.inputContainer} item sm={6} xs={12}>
                <NxTextInput
                  style={{ height: '56px' }}
                  // eslint-disable-next-line @typescript-eslint/ban-types
                  {...((register('city') as unknown) as Object)}
                  defaultValue={getValues().city}
                  fullWidth
                  helperText={formErrorToString(errors.city)}
                  label="City"
                  onChange={(e): void =>
                    setValue('city', e.currentTarget.value)
                  }
                  tabIndex={0}
                  variant={errors.city ? 'error' : 'default'}
                />
              </Grid>
              <Grid className={classes.dropdownContainer} item sm={3} xs={12}>
                {/* // In development mode you might see a warning message
                  //    Warning: Use the `defaultValue` or `value` props on <select>
                  //    </select>instead of setting `selected` on <option>.
                  // Ignore this as is being generated by react-hook-form trying to hook into
                  // this control's fields to control the form. */}
                <NxSelect
                  // eslint-disable-next-line @typescript-eslint/ban-types
                  {...((register('state') as unknown) as Object)}
                  error={Boolean(errors.state)}
                  fullWidth
                  helperText={formErrorToString(errors.state)}
                  label="State / Province"
                  name="state"
                  // Blur events don't propgate to the form for select fields,
                  // so we'll call the blur handler on change here.
                  onChange={(e): void => {
                    setValue('state', e.currentTarget.value as string)
                    onBlur()
                  }}
                >
                  {Object.entries(stateNames).map(([abbr, name]) => (
                    <NxSelectOption
                      key={abbr}
                      {...{ selected: abbr === savedInitValues.state }}
                      value={abbr}
                    >
                      {name}
                    </NxSelectOption>
                  ))}
                </NxSelect>
              </Grid>
              <Grid className={classes.inputContainer} item sm={3} xs={12}>
                <NxTextInput
                  style={{ height: '56px' }}
                  // eslint-disable-next-line @typescript-eslint/ban-types
                  {...((register('zip') as unknown) as Object)}
                  defaultValue={getValues().zip}
                  fullWidth
                  helperText={formErrorToString(errors.zip)}
                  label="Zip code"
                  onChange={(e): void => setValue('zip', e.currentTarget.value)}
                  tabIndex={0}
                  variant={errors.zip ? 'error' : 'default'}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Box>
  )
}
