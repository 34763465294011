import {
  ResourceType,
  UserRole,
  UserRoleName,
  RoleRequest,
  UserRoleStatusName,
} from '@plvs/graphql'
import { uniq } from 'ramda'
import { ProfileType } from './types'

type MinimalUserRole = Pick<
  UserRole,
  'resourceType' | 'resourceId' | 'roleName'
>

export const getEntityIdsFromRoles = (
  roles: MinimalUserRole[],
  resourceType: ResourceType
): string[] => {
  const resourceIds = roles
    .filter((role) => role?.resourceType === resourceType)
    .map((role) => role?.resourceId)

  return uniq(resourceIds)
}

export const getEntityIdsFromVerifiedRoles = (
  roles: MinimalUserRole[],
  resourceType: ResourceType
): string[] => {
  const acceptedRoleNames = [
    UserRoleName.Player,
    UserRoleName.Member,
    UserRoleName.Coach,
  ]
  const resourceIds = roles
    .filter(
      (role) =>
        role?.resourceType === resourceType &&
        acceptedRoleNames.includes(role.roleName)
    )
    .map((role) => role?.resourceId)
  return uniq(resourceIds)
}

export const getUserEntityIdsFromRoles = (
  roles: MinimalUserRole[],
  resourceType: ResourceType
): string[] => {
  const acceptedRoleNames = [
    UserRoleName.Player,
    UserRoleName.Member,
    UserRoleName.Owner,
    UserRoleName.Coach,
  ]
  const resourceIds = roles
    .filter(
      (role) =>
        role?.resourceType === resourceType &&
        acceptedRoleNames.includes(role.roleName)
    )
    .map((role) => role?.resourceId)
  return uniq(resourceIds)
}

export const getAllProfileEntities = (
  roles: MinimalUserRole[]
): ProfileType[] => {
  const allUniqProfileIds = uniq(roles.map((role) => role?.resourceId))
  const allProfiles = roles
    .map((role) => ({
      resourceType: role?.resourceType,
      id: role?.resourceId,
    }))
    .filter((role) => role?.resourceType !== ResourceType.System)

  return allUniqProfileIds
    .map((id) => allProfiles.find((prof) => prof?.id === id))
    .filter((prof) => prof !== undefined) as ProfileType[]
}

export const getOnlyOrganizationProfiles = (
  roles: MinimalUserRole[],
  roleRequests:
    | ({ __typename?: 'RoleRequest' } & Pick<
        RoleRequest,
        'resourceType' | 'resourceId' | 'roleName' | 'status'
      >)[]
    | null
    | undefined
): ProfileType[] => {
  // TODO: ID-1836
  // This is a temporary fix.  Ideally this should be addressed by
  // backend endpoint.  This frontend fix has too much business logic
  // that should be calculated on the backend.

  // create a map of which
  const orgPermissions: {
    resourceType: ResourceType
    id: string
    approved: boolean
  }[] = []

  const pendingOrgIds: string[] = []

  // Step 1 - Use approved scholastic roles user already have to determine if they are approved.
  const scholasticRoles = roles.filter((role) => {
    return role.resourceType === ResourceType.Organization
  })
  scholasticRoles.forEach((role) => {
    if (role.resourceType !== ResourceType.Organization) {
      return
    }

    if (
      role.roleName === UserRoleName.Player ||
      role.roleName === UserRoleName.Coach
    ) {
      orgPermissions.push({
        resourceType: ResourceType.Organization,
        id: role.resourceId,
        approved: true,
      })
    } else if (
      role.roleName === UserRoleName.Fac ||
      role.roleName === UserRoleName.Student
    ) {
      // build a list of pending orgs that we are awaiting approval for.
      pendingOrgIds.push(role.resourceId)
    }
  })

  // Step 2 - Use Pending and Denied to figure out orgs that user is associated with but not
  // approved yet.
  const alreadyApprovedResourceIds = orgPermissions.map((p) => p.id)

  const scholasticRoleRequests = (roleRequests ?? [])
    .filter(
      (roleRequest) => roleRequest.resourceType === ResourceType.Organization
    )
    .filter((roleRequest) => roleRequest.status !== UserRoleStatusName.Approved)
    // Due to data integrity issues in roleRequests endpoint, returning roleRequests that are
    // no longer applicable, we do an additional fileter.
    // a. filter out orgs that we are already approved.  (this is likely a stray approval)
    .filter(
      (roleRequest) =>
        !alreadyApprovedResourceIds.includes(roleRequest.resourceId)
    )
    // b. Only include role requests that are linked to a pending org.
    .filter((roleRequest) => {
      return pendingOrgIds.includes(roleRequest.resourceId)
    })

  const pendingApprovalPermissions = scholasticRoleRequests.map((roleReq) => ({
    resourceType: roleReq.resourceType,
    id: roleReq.resourceId,
    approved: false,
  }))

  return [...orgPermissions, ...pendingApprovalPermissions]
}
