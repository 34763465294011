import React from 'react'
import { useTheme } from '@material-ui/core'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  NxEmptyState,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import {
  ArenaDarkSmallSecondary,
  ArenaLightSmallSecondary,
} from '@playvs-inc/nexus-spots'

import { TeamRivalFragment, useGetTeamRivalsQuery } from '@plvs/graphql'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { isCxForSystem } from '@plvs/utils'
import { useRosterContext } from '../RosterProvider'
import { RosterRivalsAutocomplete } from './RosterRivalsAutocomplete'
import {
  RosterRivalsAcceptButton,
  RosterRivalsCancelButton,
  RosterRivalsDenyButton,
  RosterRivalsRemoveButton,
} from './actions'

enum RivalActions {
  Accept = 'accept',
  Deny = 'deny',
  Remove = 'remove',
  Cancel = 'cancel',
}

function getTeamRivals({
  teamId,
  pendingTeamRivals,
  requestedTeamRivals,
  currentTeamRival,
}: {
  teamId: string
  pendingTeamRivals: TeamRivalFragment[]
  requestedTeamRivals: TeamRivalFragment[]
  currentTeamRival?: TeamRivalFragment | null
}): Record<string, TeamRivalFragment | null> {
  const pendingTeams =
    pendingTeamRivals?.reduce((acc, rival) => {
      acc[rival.requestingTeamId] = rival
      return acc
    }, {}) ?? []
  const requestingTeams =
    requestedTeamRivals?.reduce((acc, rival) => {
      acc[rival.acceptingTeamId] = rival
      return acc
    }, {}) ?? []
  let currentTeam = {}
  if (currentTeamRival) {
    currentTeam =
      currentTeamRival?.acceptingTeamId === teamId
        ? { [currentTeamRival?.requestingTeamId]: currentTeamRival }
        : { [currentTeamRival?.acceptingTeamId ?? '']: currentTeamRival }
  }

  return {
    ...pendingTeams,
    ...requestingTeams,
    ...currentTeam,
  }
}

export const RosterRivalsList: React.FC = () => {
  const theme = useTheme()
  const { teamId, isAssociatedWithTeam, enrolledSeason, userRoles, isCoach } =
    useRosterContext()

  const {
    data: rivalsData,
    loading: rivalsLoading,
    refetch: refetchGetTeamRivals,
  } = useGetTeamRivalsQuery({
    variables: {
      teamId,
      seasonId: enrolledSeason?.id ?? '',
    },
    skip: !enrolledSeason?.id || !teamId,
  })

  const {
    currentTeamRival,
    pendingTeamRivals = [],
    requestedTeamRivals = [],
  } = rivalsData?.team ?? {}
  const rivalTeams = getTeamRivals({
    teamId,
    currentTeamRival,
    pendingTeamRivals: pendingTeamRivals ?? [],
    requestedTeamRivals: requestedTeamRivals ?? [],
  })

  const isLoading = rivalsLoading

  const isCx = isCxForSystem(userRoles)

  const refetchRivals = (): void => {
    refetchGetTeamRivals({
      teamId,
      seasonId: enrolledSeason?.id ?? '',
    })
  }

  const getAction = (
    action: RivalActions,
    rival: TeamRivalFragment
  ): React.ReactNode => {
    switch (action) {
      case RivalActions.Accept:
        return (
          <RosterRivalsAcceptButton onComplete={refetchRivals} rival={rival} />
        )
      case RivalActions.Deny:
        return (
          <RosterRivalsDenyButton onComplete={refetchRivals} rival={rival} />
        )
      case RivalActions.Cancel:
        return (
          <RosterRivalsCancelButton onComplete={refetchRivals} rival={rival} />
        )
      case RivalActions.Remove:
      default:
        return (
          <RosterRivalsRemoveButton onComplete={refetchRivals} rival={rival} />
        )
    }
  }

  const renderRivals = ({
    title,
    rivals,
    actions = [],
    teamKey = 'acceptingTeam',
  }: {
    title: string
    rivals: TeamRivalFragment[]
    actions: RivalActions[]
    teamKey: 'acceptingTeam' | 'requestingTeam'
  }): React.ReactNode => {
    return rivals.length ? (
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <NxTypography variant="overline">{title}</NxTypography>

        {rivals.map((rival) => {
          const rivalData = rivalTeams[rival[`${teamKey}Id`]]

          if (!rivalData) {
            return undefined
          }

          return (
            <Box
              key={rivalData.id}
              alignItems="center"
              display="flex"
              gridGap={theme.spacing(2)}
              justifyContent="space-between"
            >
              <NxUserCluster
                avatarHashId={rivalData.id}
                avatarUrl={rivalData[teamKey]?.school?.logoUrl ?? ''}
                subtitles={[{ title: rivalData[teamKey]?.school?.name ?? '' }]}
                title={rivalData[teamKey]?.name || ''}
              />

              <Box
                alignItems="center"
                display="flex"
                gridGap={theme.spacing(1)}
              >
                {actions.map((action) => getAction(action, rivalData))}
              </Box>
            </Box>
          )
        })}
      </Box>
    ) : undefined
  }

  const isSeasonCurrent = Date.parse(enrolledSeason?.endsAt ?? '') > Date.now()
  const canRequestRival = !currentTeamRival && isCoach && isSeasonCurrent

  return (
    <WaitTillLoaded
      loading={isLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
        p={3}
      >
        {canRequestRival && (
          <Box>
            <RosterRivalsAutocomplete onComplete={refetchRivals} />

            <NxTypography colorToken="ColorTextAlt2" variant="body4">
              Select a team that you would like to compete against this season.
              You can only request a rivalry between enrolled teams without a
              rival.
            </NxTypography>
          </Box>
        )}

        {renderRivals({
          title: 'Rival Teams',
          rivals: currentTeamRival ? [currentTeamRival] : [],
          actions: isCx ? [RivalActions.Remove] : [],
          teamKey:
            currentTeamRival?.acceptingTeamId === teamId
              ? 'requestingTeam'
              : 'acceptingTeam',
        })}

        {renderRivals({
          title: 'Requested Rivals',
          rivals: pendingTeamRivals ?? [],
          actions:
            isCoach || isCx ? [RivalActions.Accept, RivalActions.Deny] : [],
          teamKey: 'requestingTeam',
        })}

        {renderRivals({
          title: 'Pending Rival Invitations',
          rivals: requestedTeamRivals ?? [],
          actions: isCoach || isCx ? [RivalActions.Cancel] : [],
          teamKey: 'acceptingTeam',
        })}

        {!Object.keys(rivalTeams).filter((val) => val).length && (
          <NxEmptyState
            isStandalone
            spot={
              theme.palette.type === MUIThemeModeEnum.Dark ? (
                <ArenaDarkSmallSecondary height={100} width={100} />
              ) : (
                <ArenaLightSmallSecondary height={100} width={100} />
              )
            }
            subtitle={
              isAssociatedWithTeam
                ? 'Rival schools will show here.'
                : 'This team has not selected Rivals yet for this season'
            }
            title="No Rivals Yet"
          />
        )}
      </Box>
    </WaitTillLoaded>
  )
}
