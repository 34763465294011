import React, { useState } from 'react'
import { Navigate } from 'react-router'

import { Path } from '@plvs/const'
import { useCoachSettleMatchInfoQuery } from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { CanDisputeMatchResults, userCanDisputeMatchResults } from '@plvs/utils'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchDisputeForm } from './MatchDisputeForm'
import { MatchReportMatchInfo } from './MatchReportMatchInfo'
import { MatchReportNoMatchWarningBanner } from './MatchReportNoMatchWarningBanner'
import { MatchReportReturnToMatchButton } from './MatchReportReturnToMatchButton'

export const MatchDispute: React.FC<{
  matchId: string
}> = ({ matchId }) => {
  const [success, setSuccess] = useState(false)

  const { data, loading } = useCoachSettleMatchInfoQuery({
    variables: { matchId },
  })

  const match = data?.match
  const myTeam = [data?.match?.team1, data?.match?.team2].find(
    (team) => team?.isMyTeam
  )

  const returnToMatch = <MatchReportReturnToMatchButton matchId={matchId} />

  if (loading)
    return <WaitTillLoaded loading={loading} showSpinnerWhileLoading />
  if (match) {
    const canDispute = success
      ? { result: true, message: '', enum: CanDisputeMatchResults.Yes }
      : userCanDisputeMatchResults({
          canManageMatch: !!myTeam,
          match,
        })

    switch (canDispute?.enum) {
      case CanDisputeMatchResults.NoBecauseUserIsNotACoachOfMatchTeam:
        return <Navigate to={`${Path.Match}/${matchId}/mission-control`} />
      case CanDisputeMatchResults.NoBecauseMatchStatusIsNotDisputeable:
        return (
          <Banner
            data-testid="NoBecauseMatchStatusIsNotDisputeable"
            subtitle="Only completed or forfeited matches can be disputed."
            title="Match cannot be disputed"
            type={BannerType.Warning}
          >
            {returnToMatch}
          </Banner>
        )
      case CanDisputeMatchResults.NoBecauseTheTimeframeToDisputeHasClosed:
        return (
          <Banner
            data-testid="NoBecauseTheTimeframeToDisputeHasClosed"
            subtitle="Matches must be disputed within 14 days of the scheduled date of the match."
            title="Match cannot be disputed"
            type={BannerType.Warning}
          >
            {returnToMatch}
          </Banner>
        )
      case CanDisputeMatchResults.Yes:
      default:
        return myTeam ? (
          <MatchCard
            py={3}
            sideContent={<MatchReportMatchInfo match={match} />}
            title="Dispute results"
            titleDetail="Disagree with the result? We’ll review the match. Provide more details below."
          >
            <MatchDisputeForm
              match={match}
              myTeamId={myTeam.id}
              setSuccess={setSuccess}
              success={success}
            />
          </MatchCard>
        ) : (
          // This will never display.
          <>Missing my team information.</>
        )
    }
  }

  return <MatchReportNoMatchWarningBanner matchId={matchId} />
}
