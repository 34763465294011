import { toDate, format } from 'date-fns-tz'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'

import { MatchRescheduleRequestStatus } from '@plvs/graphql'
import { head } from 'ramda'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { RescheduleMatch } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'

const useStyles = makeStyles({
  timeOptions: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
})

export const RescheduleSuccessStep: React.FC<{
  acceptedDate?: string
  match: RescheduleMatch
  proposedTimes?: string[]
}> = ({ match, acceptedDate, proposedTimes = [] }) => {
  const rescheduleRequests =
    match?.matchRescheduleRequests?.filter(
      (o) => o.status === MatchRescheduleRequestStatus.Pending
    ) ?? []

  const matchProposedTimes = head(rescheduleRequests)?.proposedTimes ?? []
  const classes = useStyles()
  /**
   * Note: The proposed times from the GetRescheduleMatchQuery are
   * not updated when a reschedule mutation fires while in the modal.
   * As a work around, we are using the proposed times returned from the mutation vs.
   * the proposed times in the match query.
   */
  const proposedTimesOptions = proposedTimes.length
    ? proposedTimes
    : matchProposedTimes

  const renderOptions = proposedTimesOptions.map((datetime, index) => {
    return (
      <Box key={datetime} className={classes.timeOptions} pb={2}>
        <NxTypography colorToken="ColorTextAlt2" variant="overline">
          Option {index + 1}
        </NxTypography>
        <Box display="flex" pt={1}>
          <Box mr={1} width="100%">
            <NxTypography variant="body1">
              {format(toDate(datetime), "EEEE, MMM do, yyyy 'at' h:mma zz")}
            </NxTypography>
          </Box>
        </Box>
      </Box>
    )
  })

  const renderAcceptedDate = acceptedDate && acceptedDate !== 'counter' && (
    <Box display="flex">
      <NxTypography colorToken="ColorTextAlt2" variant="overline">
        Accepted
      </NxTypography>
      <Box mr={1} pt={1} width="100%">
        <NxTypography variant="body2">
          {format(toDate(acceptedDate), "EEEE, MMM do, yyyy 'at' h:mma zz")}
        </NxTypography>
      </Box>
    </Box>
  )

  return (
    <>
      {renderOptions}
      {renderAcceptedDate}
    </>
  )
}
