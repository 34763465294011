import React from 'react'
import {
  OverlayStep,
  OverlayStepper,
} from '@plvs/rally/components/overlay/OverlayStepper'
import { useUserAttrsContext } from '@plvs/rally/containers/app'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  EnrollmentLoadingSpinner,
  NxTypography,
} from '@playvs-inc/nexus-components'
import {
  EnrollmentLoaderDarkLargePrimary,
  EnrollmentLoaderLightLargePrimary,
} from '@playvs-inc/nexus-spots'
import { useTheme } from '@material-ui/core'

export interface EnrollmentLoadingModalProps {
  isModalOpen: boolean
}
export const EnrollmentLoadingModal: React.FC<EnrollmentLoadingModalProps> = ({
  isModalOpen,
}) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const { attributes } = useUserAttrsContext()
  const isParticipatingInMetaseason = attributes?.isParticipatingInMetaseason
  return (
    <OverlayStepper
      hideNextButton
      hideNumberOfSteps
      onClose={(): void => {}}
      open={isModalOpen}
    >
      {[
        <OverlayStep
          key="loading"
          icon={
            <EnrollmentLoadingSpinner
              spot={
                isDarkMode ? (
                  <EnrollmentLoaderDarkLargePrimary />
                ) : (
                  <EnrollmentLoaderLightLargePrimary />
                )
              }
            />
          }
          title={
            isParticipatingInMetaseason
              ? 'We are updating your enrollment!'
              : 'We are enrolling your program'
          }
        >
          <NxTypography variant="body1">
            Please do not refresh the browser. We will automatically redirect
            you upon success.
          </NxTypography>
        </OverlayStep>,
      ]}
    </OverlayStepper>
  )
}
