import React from 'react'
import { Provider, UserProviderAccount } from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Linkout } from '@playvs-inc/nexus-icons'
import { getProviderExternalLink, providerIconMap } from './profileHeroHelpers'

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    width: 20,
    height: 20,
    color: theme.palette.OverlayColorIconBase,
  },
  link: {
    textDecoration: 'none',
  },
  linkOut: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing(1),
    color: theme.palette.OverlayColorIconBase,
  },
}))

interface Props {
  userProviderAccount: Pick<
    UserProviderAccount,
    'providerDisplayName' | 'providerName' | 'id'
  >
}

export const ProviderAccountLink: React.FC<Props> = ({
  userProviderAccount,
}) => {
  const classes = useStyles()

  const providerName = userProviderAccount?.providerName ?? ''
  const providerDisplayName = userProviderAccount?.providerDisplayName ?? ''

  const Icon = providerIconMap[providerName]

  if (!Icon) {
    return null
  }

  const providerExternalLink = getProviderExternalLink(
    providerDisplayName,
    providerName as Provider
  )

  return (
    <Box alignItems="center" display="flex" mb={0.5} mr={3}>
      <Icon className={classes.icon} />
      {providerExternalLink ? (
        <>
          <a
            className={classes.link}
            href={providerExternalLink}
            rel="noreferrer"
            target="_blank"
          >
            <NxTypography colorToken="OverlayColorTextBase" variant="body3">
              {providerDisplayName}
            </NxTypography>
          </a>
          <Linkout className={classes.linkOut} />
        </>
      ) : (
        <NxTypography colorToken="OverlayColorTextBase" variant="body3">
          {providerDisplayName}
        </NxTypography>
      )}
    </Box>
  )
}
