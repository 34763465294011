import {
  AllottedSeasonPass,
  CompetitionGroup,
  CompetitionModel,
  DateComponents,
  Esport,
  League,
  LeagueGroup,
  LeagueGroupScope,
  Maybe,
  ProductType,
  RankedAnnualPassProduct,
  Season,
  Team,
  UserSchoolSubmission,
} from '@plvs/graphql/generated'
import dayjs from 'dayjs'
import { includes } from 'ramda'

export type CompetitionGroupConfigMap = {
  competitionGroupConfigs: Array<{
    competitionGroup: CompetitionGroup
    competitionModelConfigs: CompetitionModelsConfig
  }>
}

export type SchoolScopedLeagues = Array<
  Pick<LeagueGroupScope, 'status'> & {
    leagueGroup: Pick<LeagueGroup, 'id'> & {
      leagues: EligibleLeagues
    }
  }
>

export type EnrollmentProduct = {
  id: string
  name: string
  description: string
  priceUsd: number
  type: ProductType
  slug: string | null
}

export type MinimalLeague = Pick<
  League,
  'id' | 'name' | 'competitionModel' | 'displayName'
> & {
  esport: Pick<Esport, 'id' | 'slug' | 'name' | 'esportGroupId' | 'rating'>
} & {
  name: string
}

export type SchoolTeams = Array<
  Pick<Team, 'id' | 'name'> & {
    esport: Pick<Esport, 'id' | 'name' | 'slug'>
  }
>

export type EligibleLeagues = Maybe<
  Array<
    Pick<League, 'id' | 'name' | 'competitionModel' | 'displayName'> & {
      esport: Pick<Esport, 'id' | 'name' | 'slug' | 'esportGroupId' | 'rating'>
    }
  >
>

export type EligibleLeaguesReturn = Array<
  Pick<League, 'id' | 'name' | 'competitionModel' | 'displayName'> & {
    esport: Pick<Esport, 'id' | 'name' | 'slug' | 'esportGroupId' | 'rating'>
  }
>

export type CompetitionModelsConfig = Array<{
  competitionModel: CompetitionModel
  displayName?: string
}>

// consts

export const ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS: CompetitionGroup[] = [
  CompetitionGroup.HighSchool,
  CompetitionGroup.College,
  CompetitionGroup.MiddleSchool,
]

export const competitionGroupConfig: CompetitionGroupConfigMap = {
  competitionGroupConfigs: [
    {
      competitionGroup: CompetitionGroup.HighSchool,
      competitionModelConfigs: [
        {
          competitionModel: CompetitionModel.Rec,
        },
      ],
    },
    {
      competitionGroup: CompetitionGroup.College,
      competitionModelConfigs: [
        {
          competitionModel: CompetitionModel.Rec,
          displayName: 'National',
        },
      ],
    },
    {
      competitionGroup: CompetitionGroup.MiddleSchool,
      competitionModelConfigs: [
        {
          competitionModel: CompetitionModel.Rec,
        },
      ],
    },
  ],
}

export const CompetitionGroupMap: Record<CompetitionGroup, string> = {
  [CompetitionGroup.HighSchool]: 'High School',
  [CompetitionGroup.College]: 'College',
  [CompetitionGroup.Youth]: 'Youth',
  [CompetitionGroup.Open]: 'Open',
  [CompetitionGroup.MiddleSchool]: 'Middle School',
  [CompetitionGroup.Stadium]: 'Stadium',
}
// utils

export const isAssociatedToOrganization = (
  competitionGroup: CompetitionGroup | undefined | null
): boolean =>
  includes(competitionGroup, ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS)

export const getCompetitionIntervalName = ({
  firstDay,
  lastDay,
}: {
  firstDay: DateComponents
  lastDay: DateComponents
}): string => {
  const startMonth = dayjs()
    .month(firstDay.month - 1)
    .format('MMMM')
  const startYear = dayjs().year(firstDay.year).format('YYYY')
  const endMonth = dayjs()
    .month(lastDay.month - 1)
    .format('MMMM')
  const endYear = dayjs().year(lastDay.year).format('YYYY')
  return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
}

/**
 *
 * @param schoolScopedLeagues are the leagues within the scoped league group
 * @param seasonLeagues are leagues that came from the selected metaseason season
 * @param metaseason is the selected metaseason
 * @param seasons are the seleected metaseason seasons
 */
export const getAllEligibleLeaguesForSchool = ({
  seasonLeagues,
  seasons,
}: {
  seasonLeagues: EligibleLeaguesReturn
  seasons: Array<Pick<Season, 'id'> & { league: Pick<League, 'id'> | null }>
}): EligibleLeaguesReturn => {
  const allLeagues = seasonLeagues.filter((league) => {
    return seasons.some((season) => season.league?.id === league?.id)
  })
  return allLeagues
}

export const getFilteredTeamsForSchool = ({
  teams,
  league,
}: {
  teams: SchoolTeams
  league: MinimalLeague | undefined
}): SchoolTeams => teams.filter((team) => team.esport.id === league?.esport.id)

export const getLatestSubmitDate = (
  submissions: Pick<UserSchoolSubmission, 'createdAt'>[]
): string | undefined => submissions[submissions.length - 1]?.createdAt

export const createEnrollmentProduct = (
  eligibleProducts: Array<
    Pick<
      RankedAnnualPassProduct,
      'id' | 'name' | 'description' | 'priceUsd' | 'type' | 'slug'
    > & {
      seasonPassesAllotted: Maybe<Array<Pick<AllottedSeasonPass, 'id'>>>
    }
  >
): EnrollmentProduct[] =>
  eligibleProducts.map((product) => {
    const { ...rest } = product
    return {
      ...rest,
    }
  })
