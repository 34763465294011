import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { NxTypography, NxTooltip } from '@playvs-inc/nexus-components'
import dayjs from '@plvs/rally/init/dayjs'
import clsx from 'clsx'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import {
  LONG_DATE_AND_DAY_AND_TZ,
  Path,
  SHORT_MONTH_AND_DAY_AND_DOW,
} from '@plvs/const'
import { includes } from 'ramda'
import tz from 'dayjs/plugin/timezone'
import { formatInTz } from '@plvs/utils'

import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'
import { LeagueMatchRow } from './types'
import { LiveIndicator } from '../../app/drawer/matches/LiveIndicator'
import { MatchResults } from './MatchResults'
import { LeagueMatchTeamCluster } from './LeagueMatchTeamCluster'
import {
  getInvalidMatchResults,
  INVALID_RESULTS_MATCH_STATUSES,
  getTooltip,
} from './leagueMatchesHelpers'

dayjs.extend(tz)

const { FirebobDisabled } = NxMiscAssets

export const useStyles = makeStyles((theme) => ({
  root: ({ isMatchLive }: { isMatchLive: boolean }): CreateCSSProperties => ({
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    padding: theme.spacing(2, 3),
    borderLeft: isMatchLive
      ? `8px solid ${theme.palette.OverlayColorBackgroundError}`
      : 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1.5, 2),
    },
  }),
  liveText: {
    marginLeft: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  matchDateSection: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  matchDate: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  avatarLeft: {
    marginLeft: theme.spacing(2),
  },
  avatarRight: {
    marginRight: theme.spacing(2),
  },
  leftTeam: {
    textAlign: 'right',
  },
  matchResults: {
    textAlign: 'center',
  },
  opaqueTeam: {
    opacity: '30%',
  },
  vsText: {
    margin: theme.spacing(0, 1),
    textAlign: 'center',
  },
  pill: {
    margin: theme.spacing(0, 1),
  },
  teamNameMobile: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  vsAvatarText: {
    margin: theme.spacing(0, 5),
  },
}))

interface Props {
  className?: string
  match: LeagueMatchRow
}

export const LeagueMatchScheduleRow: React.FC<Props> = ({
  className,
  match,
}) => {
  const now = dayjs()

  const navigate = useNavigate()

  const isXs = useBreakpointXs()

  const isMatchLive = match?.status === MatchStatus.Live

  const classes = useStyles({ isMatchLive })

  const matchDate = dayjs(match?.scheduledStartsAt).format(
    SHORT_MONTH_AND_DAY_AND_DOW
  )
  const matchTime = dayjs(match?.scheduledStartsAt).format('h:mm A')

  const matchResults = match?.matchResults ?? []

  const team1 = matchResults?.[0]?.team
  const team2 = matchResults?.[1]?.team

  const isPastMatch =
    !isMatchLive && dayjs(match?.scheduledStartsAt).isBefore(now)

  const hasValidResults =
    matchResults.length >= 2 &&
    !includes(match?.status, INVALID_RESULTS_MATCH_STATUSES)

  const team1Lost =
    hasValidResults && isPastMatch && matchResults[0]?.placing === 2
  const team2Lost =
    hasValidResults && isPastMatch && matchResults[1]?.placing === 2

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  return (
    <>
      {isXs ? (
        <Box
          alignItems="center"
          className={clsx(classes.root, className)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <NxTypography
            className={classes.matchDate}
            colorToken="ColorTextAlt2"
            data-testid="matchDate"
            variant="overline"
          >
            {formatInTz({
              at: match?.scheduledStartsAt,
              format: LONG_DATE_AND_DAY_AND_TZ,
              timezone: dayjs.tz.guess(),
            })}
          </NxTypography>
          <Box alignItems="center" display="flex" mb={1} width="100%">
            <Box className={classes.teamNameMobile} flex="1">
              <NxTypography className={classes.leftTeam} variant="body2">
                {team1?.name ?? 'N/A'}
              </NxTypography>
            </Box>
            <NxTypography className={classes.vsText} variant="body3">
              VS
            </NxTypography>
            <Box className={classes.teamNameMobile} flex="1">
              <NxTypography variant="body2">
                {team2?.name ?? 'N/A'}
              </NxTypography>
            </Box>
          </Box>
          <Box alignItems="center" display="flex" justifyContent="center">
            <NxUserAvatar
              avatarUrl={isRestricted ? undefined : team1?.school?.logoUrl}
              className={clsx(
                classes.avatarLeft,
                team1Lost && classes.opaqueTeam
              )}
              hashId={team1?.id ?? ''}
              size={NxAvatarSize.SM}
            />
            {isMatchLive ? (
              <Box mx={2.5}>
                <LiveIndicator
                  isLive={isMatchLive}
                  typographyClassName={classes.liveText}
                  typographyVariant="body2"
                />
              </Box>
            ) : (
              <>
                {isPastMatch ? (
                  <Box alignItems="center" display="flex" mx={1}>
                    {hasValidResults ? (
                      <MatchResults matchResults={matchResults} />
                    ) : (
                      <>{getInvalidMatchResults(match?.status)}</>
                    )}
                  </Box>
                ) : (
                  <NxTypography
                    className={classes.vsAvatarText}
                    variant="subtitle2"
                  >
                    VS
                  </NxTypography>
                )}
              </>
            )}
            {hasValidResults ? (
              <NxUserAvatar
                avatarUrl={isRestricted ? undefined : team2?.school?.logoUrl}
                className={clsx(
                  classes.avatarRight,
                  team2Lost && classes.opaqueTeam
                )}
                hashId={team2?.id ?? ''}
                size={NxAvatarSize.SM}
              />
            ) : (
              <NxTooltip
                arrow
                placement="right"
                title={getTooltip(match?.status)}
              >
                <div>
                  <FirebobDisabled height={40} width={40} />
                </div>
              </NxTooltip>
            )}
          </Box>
        </Box>
      ) : (
        <Grid
          alignItems="center"
          className={clsx(classes.root, className)}
          container
          direction="row"
          onClick={(): void => navigate(`${Path.Match}/${match.id}`)}
        >
          <Grid item xs={1}>
            {isMatchLive ? (
              <LiveIndicator
                isLive={isMatchLive}
                typographyClassName={classes.liveText}
                typographyVariant="body2"
              />
            ) : (
              <>
                <NxTypography
                  className={clsx(classes.matchDate, classes.matchDateSection)}
                  data-testid="matchDate"
                  variant="body2"
                >
                  {matchDate}
                </NxTypography>
                <NxTypography
                  className={classes.matchDateSection}
                  variant="body2"
                >
                  {matchTime}
                </NxTypography>
              </>
            )}
          </Grid>
          <Grid item xs>
            <LeagueMatchTeamCluster
              didTeamLose={team1Lost}
              hasValidResults={hasValidResults}
              isLeftSide
              isRestrictedEsport={isRestricted}
              matchStatus={match?.status}
              team={team1}
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              alignItems="center"
              className={classes.matchResults}
              display="flex"
              justifyContent="center"
            >
              {isPastMatch ? (
                <>
                  {hasValidResults ? (
                    <MatchResults matchResults={matchResults} />
                  ) : (
                    <>{getInvalidMatchResults(match?.status)}</>
                  )}
                </>
              ) : (
                <NxTypography variant="subtitle1">VS</NxTypography>
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <LeagueMatchTeamCluster
              didTeamLose={team2Lost}
              hasValidResults={hasValidResults}
              isLeftSide={false}
              isRestrictedEsport={isRestricted}
              matchStatus={match?.status}
              team={team2}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      )}
    </>
  )
}
