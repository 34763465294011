import React from 'react'
import { Box, BoxProps } from './Box'

type OnboardBoxProps = Omit<
  BoxProps,
  'alignItems' | 'flex' | 'display' | 'flexDirection' | 'justifyContent'
>

export const OnboardBox: React.FC<OnboardBoxProps> = ({
  py,
  ...otherProps
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      py={py}
      {...otherProps}
    />
  )
}
