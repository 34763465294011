import { TextField, makeStyles } from '@material-ui/core'

import React from 'react'
import { Box } from '@plvs/respawn/features/layout'

export interface LargeTextFieldProps {
  charMinLength?: number
  charMaxLength?: number
  label?: string
  rows?: string
  value: string
  onChange?(event?: any): void
}

const useStyles = makeStyles((theme) => ({
  text: ({ error }: { error: boolean }): { color?: string } => ({
    color: error ? theme.palette.ColorTextInfo : theme.palette.ColorTextSuccess,
  }),
  textarea: ({
    error,
  }: {
    error: boolean
  }): Record<string, string | undefined> => ({
    border: '1px solid',
    borderColor: error
      ? theme.palette.ColorTextInfo
      : theme.palette.ColorTextSuccess,
    borderRadius: '4px',
  }),
}))

export const LargeTextField: React.FC<LargeTextFieldProps> = ({
  onChange,
  value,
  label,
  charMinLength = 30,
  charMaxLength = 150,
  rows = '6',
}) => {
  const chars = (value && value.length) || 0
  const classes = useStyles({
    error: chars < charMinLength || chars > charMaxLength,
  })

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <TextField
        className={classes.textarea}
        fullWidth
        label={label}
        multiline
        name="reasonDetail"
        onChange={onChange}
        rows={rows}
        value={value}
        variant="filled"
      />
      <span className={classes.text}>
        {chars} {chars === 1 ? 'character' : 'characters'}{' '}
        {chars > charMaxLength
          ? `(maximum ${charMaxLength} characters)`
          : `(minimum ${charMinLength}
        characters)`}
      </span>
    </Box>
  )
}
