import { clone } from 'ramda'

import { EsportSlug, EsportRating, Esport } from '@plvs/graphql'

import { EsportSlug as NxEsportSlug } from '@playvs-inc/nexus-components'
import { EsportRecord } from '@plvs/respawn/features/esport/creator/esportDesignAssets'

export function getSafeGeneralEsportAdapterDetails<T>(
  slug: EsportSlug | null | undefined,
  data: EsportRecord<T>
): T | null {
  if (slug && data?.[slug]) return data[slug] ?? null
  return null
}

export function getIsYouthProgram(esportRating?: EsportRating | null): boolean {
  return esportRating === EsportRating.Restricted
}

type UtilTeam =
  | {
      school:
        | {
            id: string
            name: string | null
          }
        | null
        | undefined
      avatarUrl: string | null
    }
  | null
  | undefined

export function scrubYouthProgramSchool(team: UtilTeam): UtilTeam {
  const scrubbedTeam = clone(team)

  if (scrubbedTeam) {
    scrubbedTeam.avatarUrl = ''
  }

  if (scrubbedTeam?.school) {
    scrubbedTeam.school.name = ''
  }

  return scrubbedTeam
}

export function scrubSchoolIfYouthProgram(
  team: UtilTeam,
  esportRating?: EsportRating | null
): UtilTeam {
  if (getIsYouthProgram(esportRating)) {
    return scrubYouthProgramSchool(team)
  }

  return team
}

export const ESPORTS_WITH_STATS = [
  EsportSlug.RocketLeague,
  EsportSlug.Splatoon,
  EsportSlug.Madden,
  EsportSlug.Madden21PS4,
  EsportSlug.Madden21SolosXboxOne,
  EsportSlug.Madden21XboxOne,
  EsportSlug.NBA2K,
]

const UNUSED_NX_MADDEN_SLUGS = [
  NxEsportSlug.Madden21PS4,
  NxEsportSlug.Madden21XboxOne,
  NxEsportSlug.Madden21SolosXboxOne,
]
export const NX_ESPORT_SLUGS_DISPLAY = Object.values(NxEsportSlug).filter(
  (slug) => !UNUSED_NX_MADDEN_SLUGS.includes(slug)
)

export const getOnboardEsportSlugs = (
  esports: Pick<Esport, 'id' | 'slug'>[]
): EsportSlug[] =>
  esports
    .map((esport) => esport?.slug)
    .filter((slug) => Boolean(slug))
    .filter((slug) => NX_ESPORT_SLUGS_DISPLAY.includes(slug))
