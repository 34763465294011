import React from 'react'
import { PrivatePage } from './page'

const AuthorizedPage: React.FC = () => (
  <PrivatePage title="Authorized">
    <div>You‘re logged in!</div>
  </PrivatePage>
)

export default AuthorizedPage
