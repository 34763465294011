import React from 'react'
import { Box, Radio, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxTextLink, NxUserCluster } from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'
import { EsportRating, Maybe, School, Team } from '@plvs/graphql'
import { useNavigate } from 'react-router-dom'

export type ScrimmageTeam = Pick<Team, 'id' | 'name'> & {
  school?: Maybe<Pick<School, 'id' | 'name' | 'logoUrl' | 'slug'>>
}

interface StyleProps {
  addPadding?: boolean
  selected?: boolean
}

const useStyles = makeStyles((theme) => ({
  teamContainer: (props?: StyleProps): CreateCSSProperties => ({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: props?.addPadding ? theme.spacing(1) : 0,
    paddingBottom: props?.addPadding ? theme.spacing(1) : 0,
  }),
  title: {
    color: theme.palette.ColorTextAlt2,
    '&:hover': {
      color: theme.palette.ColorTextLink,
    },
  },
  selectableTitle: {
    color: theme.palette.ColorTextBase,
    '&:hover': {
      color: theme.palette.ColorTextLink,
    },
  },
  radioButton: ({ selected }: StyleProps): CreateCSSProperties => ({
    marginRight: theme.spacing(3),
    marginTop: '-26px',
    color: selected ? theme.palette.ColorTextBase : theme.palette.ColorTextAlt2,
  }),
  textColor: ({ selected }: StyleProps): CreateCSSProperties => ({
    color: selected ? theme.palette.ColorTextBase : theme.palette.ColorTextAlt2,
  }),
}))

export const TeamAndSchoolInfo: React.FC<{
  addPadding?: boolean
  useCheckpointUrl?: boolean
  team: ScrimmageTeam
  esportRating?: EsportRating
  selectable?: true
  selected?: boolean
}> = ({
  addPadding,
  team,
  useCheckpointUrl,
  esportRating,
  selectable,
  selected = false,
}) => {
  const navigate = useNavigate()
  const classes = useStyles({ addPadding, selected })
  const schoolUrl = `school/${team.school?.slug}`
  const teamUrl = useCheckpointUrl
    ? `team/${team.id}`
    : `${Path.Team}/${team.id}`
  const isYouthProgram = esportRating === EsportRating.Restricted

  const redirectToTeam = (): void => {
    navigate(teamUrl)
  }

  const cluster = (
    <NxUserCluster
      avatarHashId={team.id}
      avatarUrl={
        esportRating === EsportRating.Restricted
          ? undefined
          : team.school?.logoUrl ?? undefined
      }
      onClickAvatar={redirectToTeam}
      subtitles={
        isYouthProgram
          ? []
          : [
              {
                title: (
                  <NxTextLink
                    className={classes.title}
                    href={team.school?.slug ? schoolUrl : '#'}
                    label={team.school?.name ?? 'Unknown'}
                    labelVariant="body3"
                  />
                ),
              },
            ]
      }
      title={
        <NxTextLink
          className={classes.selectableTitle}
          data-cy="teamName"
          href={teamUrl ?? '#'}
          label={team.name ?? ''}
          labelVariant="body2"
        />
      }
    />
  )

  return selectable ? (
    <Box className={classes.teamContainer}>
      <Radio checked={selected} className={classes.radioButton} size="small" />
      {cluster}
    </Box>
  ) : (
    <Box className={classes.teamContainer}>{cluster}</Box>
  )
}
