/* eslint-disable no-nested-ternary */
import React from 'react'
import { HeroGutter, TitleHero } from '@plvs/rally/components/hero'
import { EmptyPage } from '@plvs/rally/components/empty'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { UserRoleName } from '@plvs/graphql'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

import { MobileHero } from '@plvs/rally/components/hero/MobileHero'
import { ReferButton } from '../onboard/ReferButton'

interface UnverifiedSchoolProps {
  showHero?: boolean
  subtitle?: string
  title?: string
  showReferButton?: boolean
}

export const UnverifiedSchool: React.FC<UnverifiedSchoolProps> = ({
  showHero = false,
  subtitle = '',
  title = '',
  showReferButton = true,
}) => {
  const flags = useFlags()
  const isMobile = useBreakpointSm()
  return (
    <>
      {showHero && (
        <HeroGutter>
          {flags.activateFullBleedBanners && !isMobile ? (
            <NxPlayVsBanner
              size="small"
              subtitle={subtitle}
              title={title}
              variant={PersonaImageVariant.Standings}
            />
          ) : !isMobile ? (
            <MatchImageCard>
              <TitleHero subtitle={subtitle} title={title} />
            </MatchImageCard>
          ) : (
            <MobileHero subtitle={subtitle} title={title} />
          )}
        </HeroGutter>
      )}
      <EmptyPage
        mb={3}
        showIcon
        subtitle={`In order to be placed on team, your school needs somebody to coach! But there's good news. You can recruit one yourself!`}
        title="Your school needs a coach!"
      />
      <Box display="flex" justifyContent="center" mt={3}>
        {showReferButton ? (
          <ReferButton
            label="LEARN MORE"
            refer={UserRoleName.Fac}
            source="unverified_school"
            variant="primary"
          />
        ) : null}
      </Box>
    </>
  )
}
