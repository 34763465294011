import React from 'react'
import { generatePath } from 'react-router'
import { makeStyles } from '@material-ui/core'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Path } from '@plvs/const'
import { EsportSlug } from '@plvs/graphql/types'
import * as analytics from '@plvs/respawn/features/analytics'
import { NxButton } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  matchQueueButton: {
    marginRight: theme.spacing(2),
    textTransform: 'uppercase',
  },
}))

interface TryMatchQueueButtonProps {
  teamId: string
  esportSlug: EsportSlug
}

export const TryMatchQueueButton: React.FC<TryMatchQueueButtonProps> = ({
  teamId,
  esportSlug,
}) => {
  const { userId } = useUserIdentityFn()

  const styles = useStyles()

  const pathToMatchQueue = generatePath(Path.MatchQueue, {
    teamId,
    slug: esportSlug,
  })

  const handleClick = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.stopPropagation()
    analytics.tryMatchQueueButtonClicked({
      timeStamp: new Date().toDateString(),
      userId: userId ?? '',
      teamId,
    })
  }

  return (
    <NxButton
      className={styles.matchQueueButton}
      href={pathToMatchQueue}
      label="Try Match Queue"
      onClick={handleClick}
      variant="primary"
    />
  )
}
