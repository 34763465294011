import React, { useState } from 'react'
import dayjs from '@plvs/rally/init/dayjs'
import {
  NxTypography,
  NxButton,
  NxEmptyState,
} from '@playvs-inc/nexus-components'
import { MatchStatus, useGetAwardMatchesQuery } from '@plvs/graphql/generated'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Grid, makeStyles, useTheme } from '@material-ui/core'

import {
  StadiumDarkLargeSecondary,
  StadiumLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { AwardMatchCard } from './AwardMatchCard'

export const useStyles = makeStyles((theme) => ({
  selectPlayer: {
    marginBottom: theme.spacing(5.625),
  },
  viewAllButton: {
    marginLeft: 'auto',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const MATCHES_LIMIT = 25

interface Props {
  beginningOfWeekTime?: string
  schoolId: string
}

export const WeekCompletedMatches: React.FC<Props> = ({
  beginningOfWeekTime,
  schoolId,
}) => {
  const classes = useStyles()

  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const [numberOfMatches, setNumberOfMatches] = useState<number>(6)

  const nextSunday = dayjs(beginningOfWeekTime).add(7, 'day')

  const { data, loading } = useGetAwardMatchesQuery({
    variables: {
      filters: {
        startsAt: {
          before: nextSunday.toISOString(),
          after: beginningOfWeekTime,
        },
        schoolId,
        status: [MatchStatus.Completed],
        isScrimmage: false,
      },
      sortFields: [
        {
          fieldName: 'scheduled_starts_at',
          fieldSortDir: 'asc',
        },
      ],
      limit: MATCHES_LIMIT,
    },
    skip: !schoolId || !beginningOfWeekTime,
  })

  const matches = data?.getMatches?.matches ?? []

  const displayMatches = matches.slice(0, numberOfMatches)

  const viewAllClicked = numberOfMatches === MATCHES_LIMIT

  const handleViewAllClick = (): void => {
    if (!viewAllClicked) {
      setNumberOfMatches(MATCHES_LIMIT)
    } else {
      setNumberOfMatches(6)
    }
  }

  return (
    <>
      <Box alignItems="center" display="flex" mb={2} mt={7}>
        <div>
          <NxTypography className={classes.title} variant="h3">
            Completed Matches
          </NxTypography>
          <NxTypography colorToken="ColorTextAlt" variant="body1">
            To view a different week, use the filter above to select a new week
          </NxTypography>
        </div>
        {matches.length > 6 && (
          <NxButton
            className={classes.viewAllButton}
            disabled={matches.length <= 6}
            label={viewAllClicked ? 'View Less' : 'View All'}
            onClick={handleViewAllClick}
            variant="text"
          />
        )}
      </Box>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        {matches.length > 0 ? (
          <Grid container spacing={2}>
            {displayMatches.map((match) => (
              <Grid key={match.id} item sm={4} xs={12}>
                <AwardMatchCard match={match} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NxEmptyState
            spot={
              isDarkMode ? (
                <StadiumDarkLargeSecondary height={100} width={100} />
              ) : (
                <StadiumLightLargeSecondary height={100} width={100} />
              )
            }
            subtitle="No matches were found for this school."
            title="No Completed Matches"
          />
        )}
      </WaitTillLoaded>
    </>
  )
}
