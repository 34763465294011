// TODO: GP-2090 Move elevation mixins to Nexus once new Nexus package is in place.
export const BoxShadow = {
  elevation1:
    '0px 2px 16px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
  elevation2:
    '0px 2px 8px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)',
  elevation3:
    '0px 8px 16px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.04)',
  elevation4:
    '0px 8px 24px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.24)',
}

export const Shape = {
  borderRadius: {
    card: '10px',
    pill: '100px',
  },
}

export const BoldedText = {
  fontFamily: 'Whitney, sans-serif',
  fontWeight: 600,
  letterSpacing: '0.06px',
}
