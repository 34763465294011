import React from 'react'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { EsportSlug } from '@plvs/graphql/types'
import { makeStyles } from '@material-ui/core'
import { TryMatchQueueButton } from '../../queue/TryMatchQueueButton'

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
  },
}))

interface ForfeitSuccessBanner {
  esportSlug: EsportSlug
  myTeamId: string
}

export const ForfeitSuccessBanner: React.FC<ForfeitSuccessBanner> = ({
  esportSlug,
  myTeamId,
}) => {
  const styles = useStyles()
  const successText = (
    <>
      The opposing team will be notified. Please wait up to one minute for
      reported results to be received.
      <br />
      <br />
      Still want to play more? With Match Queue, you can now queue up for a
      scrimmage with a brand new opponent and continue playing today!
    </>
  )

  return (
    <Banner
      subtitle={successText}
      title="Game results submitted"
      type={BannerType.Success}
    >
      <div className={styles.flex}>
        <TryMatchQueueButton esportSlug={esportSlug} teamId={myTeamId} />
      </div>
    </Banner>
  )
}
