import React, { useMemo, useState } from 'react'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { head } from 'ramda'
import {
  NxTypography,
  NxEmptyState,
  NxSeasonalAwardsTable,
  NxSeasonalAwardsTableData,
} from '@playvs-inc/nexus-components'

import {
  StadiumDarkLargeSecondary,
  StadiumLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  useGenerateSeasonalPlayerAwardCertificateMutation,
  useGenerateSeasonalPlayerAwardSocialMediaImageMutation,
  useGetSchoolTeamsAndMembersEffectiveAtRangeQuery,
} from '@plvs/graphql'

import { MetaseasonFilter } from '@plvs/rally/components/filter/MetaseasonFilter'
import dayjs from '@plvs/rally/init/dayjs'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { AssignSeasonalAwardModal } from './AssignSeasonalAwardModal'
import { DeleteSeasonalAwardModal } from './DeleteSeasonalAwardModal'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.boxShadow.elevation1,
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(2, 0),
  },
  profileNotFound: {
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const SPRING_2023_CUTOFF_DATE = '2023-01-08T00:00:00.000Z'

export const SeasonalAwards: React.FC = () => {
  const classes = useStyles()
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState<
    NxSeasonalAwardsTableData
  >()
  const theme = useTheme()
  const lessThanMinWidth = useMediaQuery('(max-width:600px)')
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const {
    selectedEntityId,
    loading: profileLoading,
    userId,
  } = useProfileContext()
  const {
    metaseason,
    metaseasons,
    setMetaseason,
  } = useSchoolLeagueInfoContext()

  const {
    data: playerData,
    loading: loadingPlayers2,
    refetch,
  } = useGetSchoolTeamsAndMembersEffectiveAtRangeQuery({
    variables: {
      id: selectedEntityId,
      metaseasonId: metaseason?.id || '',
      effectiveAtRange: {
        startsAt: metaseason?.startsAt || '',
        endsAt: metaseason?.endsAt || '',
      },
      teamFilters: { includeDeleted: true },
    },
    notifyOnNetworkStatusChange: true,
    skip: profileLoading || !metaseason || !selectedEntityId,
  })
  const [
    generateCertificateMutation,
  ] = useGenerateSeasonalPlayerAwardCertificateMutation()
  const [
    generateSocialMediaMutation,
  ] = useGenerateSeasonalPlayerAwardSocialMediaImageMutation()
  const tableData: NxSeasonalAwardsTableData[] = []
  const teamUserIds = new Set()
  playerData?.school?.teams?.forEach((team) => {
    if (team?.leagues?.length) {
      const esportSlug = team?.leagues?.[0].esport.slug
      team.members?.forEach((player) => {
        if (!teamUserIds.has(team.id + player.id)) {
          const award = player.assignedSeasonalAwards?.find(({ teamId }) => {
            return teamId === team.id
          })
          tableData.push({
            generatedId: `${team.id}-${player.id}`,
            team: {
              id: team.id,
              name: team.name || '',
              esportSlug,
            },
            player: {
              id: player.id,
              name: player.name || '',
              avatarUrl: player.avatarUrl || '',
            },
            assignedAward: award
              ? {
                  id: award.id,
                  awardType: {
                    id: award.awardType?.id || '',
                    title: award.awardType?.title || '',
                  },
                }
              : undefined,
          })
          teamUserIds.add(team.id + player.id)
        }
      })
    }
  })

  const generatedIdMapping = useMemo<
    Record<string, NxSeasonalAwardsTableData>
  >(() => {
    return tableData.reduce((accum, userData) => {
      return {
        ...accum,
        [userData.generatedId]: userData,
      }
    }, {})
  }, [tableData])
  const awardIdMapping = useMemo<
    Record<string, NxSeasonalAwardsTableData>
  >(() => {
    return tableData.reduce((accum, userData) => {
      if (!userData.assignedAward) {
        return accum
      }
      return {
        ...accum,
        [userData.assignedAward.id]: userData,
      }
    }, {})
  }, [tableData])
  const editHandler = (awardId: string): void => {
    const userData = awardIdMapping[awardId]
    setSelectedUserData(userData)
    setShowAssignModal(true)
  }
  const deleteHandler = (awardId: string): void => {
    const userData = awardIdMapping[awardId]
    setSelectedUserData(userData)
    setShowDeleteModal(true)
  }
  const assignHandler = (generatedId: string): void => {
    const userData = generatedIdMapping[generatedId]
    setSelectedUserData(userData)
    setShowAssignModal(true)
  }
  const generateImageHandler = async (awardId: string): Promise<void> => {
    const result = await generateSocialMediaMutation({
      variables: {
        input: { id: awardId },
      },
    })

    const socialMediaUrl =
      result?.data?.generateSeasonalPlayerAwardSocialMediaImage?.signedUrl

    if (socialMediaUrl) {
      window.open(socialMediaUrl, '_blank')
    } else {
      throw head(result?.errors ?? []) || new Error('Invalid response.')
    }
  }
  const generatePdfHandler = async (awardId: string): Promise<void> => {
    const result = await generateCertificateMutation({
      variables: {
        input: { id: awardId },
      },
    })

    const certificateUrl =
      result?.data?.generateSeasonalPlayerAwardCertificate?.signedUrl

    if (certificateUrl) {
      window.open(certificateUrl, '_blank')
    } else {
      throw head(result?.errors ?? []) || new Error('Invalid response.')
    }
  }
  const modalCloseHandler = (hasChanges?: boolean): void => {
    if (hasChanges) {
      refetch()
    }
    setShowAssignModal(false)
    setShowDeleteModal(false)
  }

  const filteredMetaseasons = metaseasons.filter((m) =>
    dayjs(m?.startsAt).isSameOrAfter(dayjs(SPRING_2023_CUTOFF_DATE))
  )

  return (
    <Box mt={theme.spacing(0.75)}>
      <Box
        display={lessThanMinWidth ? 'block' : 'flex'}
        justifyContent="space-between"
        mb={2}
      >
        <Box mb={lessThanMinWidth ? theme.spacing(0.25) : 0}>
          <NxTypography className={classes.title} variant="h3">
            {`${metaseason?.name || ''} Player Recognition`}
          </NxTypography>
          <NxTypography colorToken="ColorTextAlt" variant="body1">
            Celebrate your players&apos; achievements during this season
          </NxTypography>
        </Box>
        <MetaseasonFilter
          metaseason={metaseason}
          metaseasons={filteredMetaseasons}
          setMetaseason={setMetaseason}
        />
      </Box>
      <WaitTillLoaded
        loading={profileLoading || loadingPlayers2}
        showSpinnerWhileLoading
      >
        <Box className={classes.root}>
          <>
            {tableData.length ? (
              <NxSeasonalAwardsTable
                assignAwardHandler={assignHandler}
                data={tableData}
                deleteAwardHandler={deleteHandler}
                editAwardHandler={editHandler}
                generateImage={generateImageHandler}
                generatePdf={generatePdfHandler}
              />
            ) : (
              <NxEmptyState
                spot={
                  isDarkMode ? (
                    <StadiumDarkLargeSecondary height={100} width={100} />
                  ) : (
                    <StadiumLightLargeSecondary height={100} width={100} />
                  )
                }
                subtitle="The  award for this season is currently unavailable. Please try adjusting the filter to view awards from other seasons."
                title="Seasonal Award Unavailable"
              />
            )}
          </>
        </Box>
      </WaitTillLoaded>
      <DeleteSeasonalAwardModal
        onClose={modalCloseHandler}
        open={showDeleteModal}
        userData={selectedUserData}
      />
      <AssignSeasonalAwardModal
        coachId={userId}
        metaseasonId={metaseason?.id || ''}
        onClose={modalCloseHandler}
        open={showAssignModal}
        userData={selectedUserData}
      />
    </Box>
  )
}
