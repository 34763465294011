import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { CreateCSSProperties } from '@material-ui/styles'

import { EsportSlug } from '@plvs/graphql'
import { useMatchImageLoader } from '@plvs/respawn/features/esport/creator/utils'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  composePersonaGradient,
  getPersonaImageVariantByPath,
  PersonaImageVariant,
  NxSkeletonLoader,
} from '@playvs-inc/nexus-components'
import { BoxShadow } from '@plvs/rally/themes'

import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { useLocation } from 'react-router-dom'
import { Colors, alpha } from '../../themes/colors'

export interface MatchCardProps extends BoxProps {
  alt?: string
  backgroundColor?: Colors
  borderRadius?: string
  boxShadow?: string
  color?: Colors
  esportSlug?: EsportSlug | null
  showOverlay?: boolean
  src?: string
  video?: boolean
  topBanner?: React.ReactNode
  position?: boolean
  topBannerColor?: Colors
  bottomBanner?: React.ReactNode
  bottomBannerColor?: Colors
  disableFallbackAvatar?: boolean
  loading?: boolean
}

interface MatchCardStyleProps {
  isLoaded: boolean
  borderRadius: string
  backgroundColor: Colors
  color: Colors
  boxShadow?: string
  height?: string
  position: boolean
  esportSlug?: EsportSlug
  variant?: PersonaImageVariant
}

const useStyles = makeStyles((theme) => ({
  imgStyle: {
    objectFit: 'cover',
    objectPosition: ({ position }: MatchCardStyleProps): string =>
      position ? '0 0' : '',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  imgActor: ({ isLoaded }: MatchCardStyleProps): CreateCSSProperties => ({
    transition: 'all 1.2s ease-in-out',
    opacity: isLoaded ? 1 : 0,
    transform: `scale(${isLoaded ? 1.01 : 1})`,
    transformOrigin: 'center top',
  }),
  card: ({
    backgroundColor,
    borderRadius,
    color,
    boxShadow,
    height,
  }: MatchCardStyleProps): CreateCSSProperties => ({
    backgroundColor,
    borderRadius,
    color,
    boxShadow,
    height,
    willChange: 'transform',
    overflow: 'hidden',
  }),
  overlay: ({
    esportSlug,
    variant,
  }: MatchCardStyleProps): CreateCSSProperties => {
    const { start, end } = composePersonaGradient(
      esportSlug ?? '',
      variant,
      theme
    )
    const legacyFallback = `linear-gradient(0deg, ${alpha(
      theme.palette.common.black,
      0.2
    )}, ${alpha(
      theme.palette.common.black,
      0.2
    )}), linear-gradient(180deg, ${alpha(
      theme.palette.BorderLight ?? '',
      0
    )} 0%, ${alpha(theme.palette.BorderLight ?? '', 0.75)} 100%)`

    return {
      background:
        variant && start && end
          ? `linear-gradient(89.44deg, ${start} 12.98%, rgba(0, 57, 77, 0) 51.89%, ${end} 85.92%)`
          : legacyFallback,
    }
  },
}))

const defaultBoxShadow = BoxShadow.elevation1

export const MatchImageCard: React.FC<MatchCardProps> = ({
  borderRadius = '24px',
  backgroundColor = Colors.Grey1,
  className,
  color = Colors.White,
  src,
  children,
  showOverlay = true,
  alt,
  boxShadow = defaultBoxShadow,
  video,
  topBanner,
  position = false,
  esportSlug,
  topBannerColor = Colors.Grey1,
  bottomBanner,
  bottomBannerColor = Colors.Grey1,
  disableFallbackAvatar = false,
  loading,
  ...otherProps
}) => {
  const location = useLocation()
  const { slug } = useEsportContext()
  const variant = getPersonaImageVariantByPath(location.pathname ?? '')
  const { isLoaded, imageSrc } = useMatchImageLoader({
    src,
    esportSlug: disableFallbackAvatar ? null : esportSlug ?? slug,
    variant,
    disableFallback: disableFallbackAvatar,
  })

  const { card, imgStyle, imgActor, overlay } = useStyles({
    backgroundColor,
    borderRadius,
    isLoaded,
    color,
    boxShadow,
    position,
    esportSlug: esportSlug ?? slug ?? undefined,
    variant,
  })

  return (
    <PenaltyBox>
      <WaitTillLoaded
        loading={loading}
        LoadingComponent={(): React.ReactElement => (
          <NxSkeletonLoader height="313px" variant="rect" width="100%" />
        )}
        showSpinnerWhileLoading
      >
        <Box
          className={clsx(card, className)}
          data-testid="MatchImageCard-Card"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          overflow="hidden"
          position="relative"
          {...otherProps}
        >
          {topBanner && (
            <Box
              alignItems="center"
              bgcolor={topBannerColor}
              display="flex"
              justifyContent="center"
              position="absolute"
              py={1}
              width="100%"
              zIndex={10}
            >
              {topBanner}
            </Box>
          )}
          {bottomBanner && (
            <Box
              alignItems="center"
              bgcolor={bottomBannerColor}
              bottom="0"
              display="flex"
              justifyContent="center"
              position="absolute"
              py={2}
              width="100%"
              zIndex={10}
            >
              {bottomBanner}
            </Box>
          )}
          <Box
            bottom="0"
            className={imgActor}
            left="0"
            overflow="hidden"
            position="absolute"
            right="0"
            top="0"
            zIndex="1"
          >
            {isLoaded && imageSrc && (
              <img
                alt={alt ?? slug ?? ''}
                className={imgStyle}
                src={imageSrc}
              />
            )}
            {video && (
              <video
                key={src} // without this, the same video will show for each step
                autoPlay
                className={imgStyle}
                loop
                muted
                width="260"
              >
                <source src={src} type="video/mp4" />
                Sorry, your browser doesn‘t support embedded videos.
              </video>
            )}
          </Box>
          {showOverlay ? (
            <Box
              bottom="0"
              className={overlay}
              left="0"
              overflow="hidden"
              position="absolute"
              right="0"
              top="0"
              zIndex="1"
            />
          ) : null}
          <Box
            display="flex"
            flex="1"
            mb={bottomBanner ? 7 : undefined}
            mt={topBanner ? 4 : undefined}
            position="relative"
            zIndex="3"
          >
            {children}
          </Box>
        </Box>
      </WaitTillLoaded>
    </PenaltyBox>
  )
}
