import { Theme, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { EsportSlug } from '@plvs/graphql'

type BackgroundStyles = {
  homeBoxShadow: string | undefined
  awayBoxShadow: string | undefined
  homeGradientBackground: string | undefined
  awayGradientBackground: string | undefined
}

const getBackgroundStylesByEsport = ({
  esportSlug,
}: {
  theme: Theme
  esportSlug: EsportSlug
}): BackgroundStyles => {
  switch (esportSlug) {
    case EsportSlug.SmashBrosUltimate:
    case EsportSlug.SuperSmashBrosUltimateSolos:
    case EsportSlug.SuperSmashBrosUltimateCrew:
      return {
        homeBoxShadow: '#000',
        awayBoxShadow: '#000',
        homeGradientBackground:
          'linear-gradient(180deg, #272A2B 0%, #181A1B 100%)',
        awayGradientBackground:
          'linear-gradient(180deg, #272A2B 0%, #181A1B 100%)',
      }

    case EsportSlug.Madden:
    case EsportSlug.Madden21PS4:
    case EsportSlug.Madden21SolosXboxOne:
    case EsportSlug.Madden21XboxOne:
      return {
        homeBoxShadow: '#E30101',
        awayBoxShadow: '#3B16E3',
        homeGradientBackground:
          'linear-gradient(180deg, #11042F 0%, #2A0977 100%, #190547 100%)',
        awayGradientBackground:
          'linear-gradient(180deg, #11042F 0%, #2A0977 100%, #190547 100%)',
      }

    case EsportSlug.NBA2K:
      return {
        homeBoxShadow: '#E30101',
        awayBoxShadow: '#3B16E3',
        homeGradientBackground:
          'linear-gradient(180deg, #191919 0%, #262626 100%)',
        awayGradientBackground:
          'linear-gradient(180deg, #191919 0%, #262626 100%)',
      }

    default:
      return {
        homeBoxShadow: '#E30101',
        awayBoxShadow: '#3B16E3',
        homeGradientBackground:
          'linear-gradient(180deg, #11042F 0%, #2A0977 100%, #190547 100%)',
        awayGradientBackground:
          'linear-gradient(180deg, #11042F 0%, #2A0977 100%, #190547 100%)',
      }
  }
}

type CSSProperties = {
  esportSlug: EsportSlug
  isHomeTeam?: boolean
}

export const useStyles = makeStyles((theme) => ({
  cutout: {
    background: theme.palette.ColorBackgroundBase,
    height: 66,
    width: 66,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      height: 32,
      width: 32,
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
  },

  characterContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    padding: theme.spacing(2, 2),
    height: theme.spacing(25),
    borderRadius: 8,

    boxShadow: `${theme.spacing(0.5, 0.5, 0, 0)} ${theme.palette.common.black}`,

    [theme.breakpoints.down('sm')]: {
      height: 'auto',

      boxShadow: `${theme.spacing(0.25, 0.25, 0, 0)} ${
        theme.palette.common.black
      }`,
    },
  },

  container: ({ isHomeTeam }: CSSProperties): CreateCSSProperties => ({
    background: isHomeTeam
      ? 'url(https://s3.amazonaws.com/assets.playvs.com/match-assistant/nba2k-court-left.png) left center / cover'
      : 'url(https://s3.amazonaws.com/assets.playvs.com/match-assistant/nba2k-court-right.png) right center / cover',
    position: 'absolute',
    zIndex: 1,
    bottom: 4,
    left: 48,
  }),

  centerImage: {
    background:
      'url(https://s3.amazonaws.com/assets.playvs.com/match-assistant/basketball.png) center center',
    position: 'absolute',
    zIndex: 2,
  },

  gradient: ({
    esportSlug,
    isHomeTeam,
  }: CSSProperties): CreateCSSProperties => {
    const gradients = getBackgroundStylesByEsport({ theme, esportSlug })
    return {
      background: isHomeTeam
        ? gradients.homeGradientBackground
        : gradients.awayGradientBackground,
      position: 'relative',
    }
  },
}))

// The above makeStyles doesn't like when we spread `theme.typography.display3`
// AND when we have a function property, so break it out into its own hook.
export const useVsStyles = makeStyles((theme) => ({
  vs: {
    // @ts-ignore
    ...theme.typography.display3,

    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body2,
    },
  },
}))
