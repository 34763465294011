/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-this-in-sfc */
import React from 'react'
import { NavigateFunction } from 'react-router-dom'
import { renderToString } from 'react-dom/server'
import { ShepherdSlide } from '@plvs/rally/components/shepherd/ShepherdSlide'
import { NxEmote } from '@playvs-inc/nexus-components'
import Step from 'shepherd.js/src/types/step'
import { Path } from '@plvs/const'
import { TourSpotWrapper } from '@plvs/rally/components/shepherd/TourSpotWrapper'
import { productTourClicked } from '@plvs/respawn/features/analytics'
import { pollForItem } from './shepherdUtils'
import dayjs from '../dayjs'

const FINAL_SUBTITLE = `That’s all! Wishing you the very best of luck, and remember, we're here for you every step of the way. Should you need any further assistance or guidance, don't hesitate to reach out. To revisit the tour at any time, simply click on your account name and then select "Product Tour”. Game on!`

export const createOnboardTourSteps = ({
  userId,
  navigate,
}: {
  userId: string
  navigate: NavigateFunction
}): Step.StepOptions[] => {
  const now = dayjs().toISOString()

  const steps: Step.StepOptions[] = [
    {
      id: 'onboarding-intro',
      text: renderToString(
        <ShepherdSlide
          spot={<TourSpotWrapper />}
          subtitle="Get ready to take on the competition! Take the first step by clicking the tour button below, and we’ll show you around the PlayVS esports platform."
          title="Welcome to PlayVS"
        />
      ),
      classes: 'shepherd-modal large-slide',
      cancelIcon: {
        enabled: false,
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Maybe Later',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Maybe Later Button',
            })
            return this.show('maybe-later')
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Take Tour',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'competitions-link',
      attachTo: { element: '.competitions', on: 'right-start' },
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={2}
          subtitle="Click here to view competitions and leagues that are available for your school."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 2,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
      ],
    },
    {
      id: 'my-school-link',
      attachTo: { element: '.mySchool', on: 'right-start' },
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={3}
          subtitle="Click here to view and manage your school information, players, coaches, and more."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 3,
              clickTarget: 'Back Button',
            })
            if (window.location.pathname.includes(Path.MySchool)) {
              navigate(Path.Explore)
            }

            return this.back()
          },
        },
      ],
    },
    {
      id: 'manage-players-tab',
      attachTo: { element: '.shepherd-players', on: 'bottom-start' },
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      classes: 'shepherd-modal players-tab',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={4}
          subtitle="Players who sign up to your school will show in this tab. You’ll need to verify that each student who signs up attends your school"
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 4,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 4,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      modalOverlayOpeningPadding: 8,
      beforeShowPromise: pollForItem({ selector: '.shepherd-players' }),
    },
    {
      id: 'manage-teams-link',
      attachTo: { element: '.manageTeams', on: 'right-start' },
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={5}
          subtitle="Click here to view and manage your team rosters."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 5,
              clickTarget: 'Back Button',
            })
            if (window.location.pathname.includes(Path.Manage)) {
              navigate(Path.MySchool)
            }
            return this.back()
          },
        },
      ],
    },
    {
      id: 'manage-teams-page',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={6}
          subtitle="You can create teams and add players to team rosters on this page. Note that only verified players can be added to teams."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 6,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 6,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'manage-enrollments-widget',
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      classes: 'shepherd-modal enrollment-widget',
      text: renderToString(
        <ShepherdSlide
          rightArrowStyles={{
            top: -48,
            right: -23,
            transform: 'rotate(-30deg)',
          }}
          step={7}
          subtitle="When you’re ready, you’ll enroll your teams using this widget"
          totalSteps={17}
        />
      ),
      attachTo: {
        element: '.shepherd-manage-enrollments-widget',
        on: 'bottom-start',
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 7,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 7,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      beforeShowPromise: pollForItem({
        selector: '.shepherd-manage-enrollments-widget',
      }),
    },
    {
      id: 'schedule-link',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={8}
          subtitle="Click here to access your teams schedule."
          totalSteps={17}
        />
      ),
      attachTo: {
        element: '.schedule',
        on: 'right-start',
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 8,
              clickTarget: 'Back Button',
            })
            if (window.location.pathname.includes(Path.Schedule)) {
              navigate(Path.ManageTeams)
            }
            return this.back()
          },
        },
      ],
    },
    {
      id: 'schedule-page',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={9}
          subtitle="Your school’s upcoming matches will show here. On game day, you’ll come here to find and open your match. If your opponent says “TBD”, simply join the match queue and we’ll pair you with another school."
          totalSteps={17}
        />
      ),

      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 9,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 9,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'match-chat-button',
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      classes: 'shepherd-modal match-chat-button',
      text: renderToString(
        <ShepherdSlide
          showBottomLeftHighlight
          step={10}
          subtitle="Match chats with other teams will show here. Use this to chat with other teams on game day."
          totalSteps={17}
        />
      ),
      attachTo: {
        element: '.shepherd-chat-button',
        on: 'bottom-start',
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 10,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 10,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      modalOverlayOpeningPadding: 12,
    },
    {
      id: 'standings-link',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={11}
          subtitle="Click here to view league standings."
          totalSteps={17}
        />
      ),
      attachTo: {
        element: '.standings',
        on: 'right-start',
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 11,
              clickTarget: 'Back Button',
            })

            if (window.location.pathname.includes(Path.Standings)) {
              navigate(Path.Schedule)
            }
            return this.back()
          },
        },
      ],
    },
    {
      id: 'standings-page',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={12}
          subtitle="Once you have some matches under your belt, come here to see how your teams stack up against the competition."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 12,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 12,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'scrimmage-link',
      cancelIcon: {
        enabled: true,
      },
      attachTo: {
        element: '.scrimmage',
        on: 'right-start',
      },
      canClickTarget: false,
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={13}
          subtitle="You’ll find open scrimmages here. You can also challenge specific teams."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 13,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 13,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'resources-link',
      cancelIcon: {
        enabled: true,
      },
      attachTo: {
        element: '.resourcesv2',
        on: 'right-start',
      },
      classes: 'shepherd-modal app-link',
      canClickTarget: false,
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={14}
          subtitle="In the resources section, you'll discover rulebooks, helpful articles, instructional videos, and other valuable information."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 14,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 14,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
    },
    {
      id: 'profile-link',
      cancelIcon: {
        enabled: true,
      },
      attachTo: {
        element: '.shepherd-profile',
        on: 'top-end',
      },
      modalOverlayOpeningPadding: -16,
      classes: 'shepherd-modal profile-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 40, left: -80, transform: 'rotate(-40deg)' }}
          step={15}
          subtitle="Click your name to open your user menu."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 15,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
      ],
    },
    {
      id: 'account-settings-link',
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      attachTo: {
        element: '.shepherd-account-settings',
        on: 'right',
      },
      classes: 'shepherd-modal',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={16}
          subtitle="You can update your personal info, notification settings, and more here."
          totalSteps={17}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 16,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 16,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      beforeShowPromise: pollForItem({
        selector: '.shepherd-account-settings',
      }),
    },
    {
      id: 'final-step-gg',
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal large-slide',
      text: renderToString(
        <ShepherdSlide
          spot={<NxEmote emoteKey="fbgg" height={112} width={112} />}
          subtitle={FINAL_SUBTITLE}
          title="Get ready for an exciting season!"
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 17,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Done',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 17,
              clickTarget: 'Complete Button',
            })
            return this.complete()
          },
        },
      ],
    },
    {
      id: 'maybe-later',
      classes: 'shepherd-modal maybe-later',
      attachTo: { element: '.shepherd-profile', on: 'right' },
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 48, left: -80, transform: 'rotate(-40deg)' }}
          subtitle='To revisit the tour at any time, simply click on your account name and then select "Product Tour”.'
        />
      ),
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      buttons: [
        {
          classes: 'shepherd-primary-button',
          text: 'Got it',
          action(): void {
            productTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Next Time Button',
            })
            return this.complete()
          },
        },
      ],
      modalOverlayOpeningPadding: -16,
    },
  ]

  return steps
}
