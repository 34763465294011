import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useStyles } from '@plvs/rally/pages/app/match/slotQueue'

import { generatePath, useNavigate } from 'react-router-dom'
import { Path } from '@plvs/const'
import dayjs from 'dayjs'
import { useTheme } from '@material-ui/core'
import { QueueTimer } from './QueueTimer'

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface SlotQueueByeRowProps {
  team?: Team | null
  esportSlug: string
  matchId: string
  onCountdownComplete(): Promise<void>
}

export const SlotQueueByeRow: React.FC<SlotQueueByeRowProps> = ({
  team,
  esportSlug,
  matchId,
  onCountdownComplete,
}) => {
  const mobile = useBreakpointSm()
  const navigate = useNavigate()
  const theme = useTheme()
  const {
    joinButtonContainer,
    teamsContainer,
    mobileTeamContainer,
    mobileHeader,
    subtitleWrapper,
    mobileTeamNameContainer,
    byeMatchButton,
  } = useStyles({ mobile })
  const title = 'Queue Closed'
  const subtitle =
    'We were not able to find you an opponent. ' +
    'For this week you will recieve a Bye (win). If you would ' +
    'still like to play; you can join our Scrimmage Queue to verse ' +
    'teams from different leagues for fun!'

  const navigateToScrimmageQueue = async (): Promise<void> => {
    const queuePath = generatePath(Path.MatchQueue, {
      teamId: team?.id ?? '',
      slug: esportSlug,
    })
    navigate(queuePath)
  }

  const navigateToMatch = async (): Promise<void> => {
    const lobbyPath = generatePath(Path.MatchLobby, {
      matchId,
    })
    navigate(lobbyPath)
  }

  return (
    <Box className={teamsContainer}>
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flexDirection="column"
          >
            <NxTypography
              color="inherit"
              colorToken="OverlayColorTextBase"
              variant="h2"
            >
              {title}
            </NxTypography>
            <QueueTimer
              countdownTime={dayjs()}
              onCountdownComplete={onCountdownComplete}
              variant="h2"
            />
            <NxTypography
              className={subtitleWrapper}
              color="inherit"
              variant="subtitle1"
            >
              {subtitle}
            </NxTypography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                color={theme.palette.OverlayColorTextBase}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography color="inherit" variant="h1">
                  {team?.name}
                </NxTypography>
                <NxTypography color="inherit" variant="body1">
                  {team?.school?.name}
                </NxTypography>
              </Box>
            </Box>
            <Box
              className={joinButtonContainer}
              display="flex"
              flex="1"
              flexDirection="column"
            >
              <NxButton
                label="Join Scrimmage Queue"
                onClick={async (): Promise<void> => navigateToScrimmageQueue()}
                shouldUseOverlayColors
                variant="primary"
              />
              <NxButton
                className={byeMatchButton}
                label="View My Bye Match"
                onClick={async (): Promise<void> => navigateToMatch()}
                shouldUseOverlayColors
                variant="secondary"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxUserAvatar
              avatarUrl={team?.school?.logoUrl}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.XXL}
            />
          </Box>
          <Box
            alignItems="center"
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="space-between"
          >
            <NxTypography color="inherit" variant="h2">
              {title}
            </NxTypography>
            <NxTypography
              className={subtitleWrapper}
              color="inherit"
              variant="subtitle1"
            >
              {subtitle}
            </NxTypography>
            <QueueTimer
              countdownTime={dayjs()}
              onCountdownComplete={onCountdownComplete}
              variant="display"
            />
          </Box>
          <Box
            className={joinButtonContainer}
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <NxButton
              label="Join Scrimmage Queue"
              onClick={async (): Promise<void> => navigateToScrimmageQueue()}
              shouldUseOverlayColors
              variant="primary"
            />
            <NxButton
              className={byeMatchButton}
              label="View My Bye Match"
              onClick={async (): Promise<void> => navigateToMatch()}
              shouldUseOverlayColors
              variant="secondary"
            />
          </Box>
        </>
      )}
    </Box>
  )
}
