// interfaces

import {
  Maybe,
  Provider,
  TeamRosterFormat,
  UserProviderAccount,
} from '@plvs/graphql'

export interface ConnectedAccountProviders {
  userProviderAccounts: Maybe<
    Maybe<
      Omit<
        UserProviderAccount,
        | 'esport'
        | 'createdAt'
        | 'updatedAt'
        | 'providerOnboardUrl'
        | 'id'
        | 'providerAccountId'
        | 'providerIntegrationType'
      >
    >[]
  >
}

export interface ConnectedAccountProviderNames {
  userProviderAccounts: Maybe<
    Maybe<Pick<UserProviderAccount, 'providerName'>>[]
  >
}

export const isAccountProviderLinked = (
  user: ConnectedAccountProviderNames,
  providerName: Provider
): boolean => {
  const providerAccounts = user?.userProviderAccounts ?? []
  return !!providerAccounts.some((acct) => acct?.providerName === providerName)
}

export const getAccountProviderUsername = (
  user: ConnectedAccountProviders | null,
  providerName: Provider
): string => {
  if (!user) return ''
  const providerAccounts = user?.userProviderAccounts ?? []
  return (
    providerAccounts.find((acct) => acct?.providerName === providerName)
      ?.providerDisplayName ?? ''
  )
}

export const getHasAccountProvider = (
  user: ConnectedAccountProviders | null,
  providerName: Provider
): boolean => {
  if (!user) {
    return false
  }

  const providerAccounts = user?.userProviderAccounts ?? []

  return providerAccounts.some((acct) => acct?.providerName === providerName)
}

export const getHasAccountProvidersForPlayers = (
  teamRosterFormat: TeamRosterFormat | undefined,
  providerName: Provider
): boolean => {
  const starters =
    teamRosterFormat?.starters?.map((starter) => starter.player?.user) ?? []
  const substitutes =
    teamRosterFormat?.substitutes?.map((substitute) => substitute.user) ?? []
  const players = [
    ...(starters as ConnectedAccountProviders[]),
    ...(substitutes as ConnectedAccountProviders[]),
  ].filter((starter) => Boolean(starter))
  const isMissingProvider = players.some(
    (user) => !getHasAccountProvider(user, providerName)
  )
  return !isMissingProvider
}
