import { useState, Dispatch, SetStateAction } from 'react'

interface UsePaginationReturn {
  page: number
  offset: number
  setPage: Dispatch<SetStateAction<number>>
  getNumPages: (itemsCount: number) => number
}

export const usePagination = ({
  itemsPerPage,
}: {
  itemsPerPage: number
}): UsePaginationReturn => {
  const [page, setPage] = useState(1)
  const offset = (page - 1) * itemsPerPage
  const getNumPages = (itemsCount: number): number =>
    Math.ceil(itemsCount / itemsPerPage)

  return {
    page,
    offset,
    setPage,
    getNumPages,
  }
}
