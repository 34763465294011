import React, { memo } from 'react'
import { ErrorCode } from '@plvs/graphql/generated'
import { Box, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { NotVerifiedLightSmallPrimary } from '@playvs-inc/nexus-spots'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.ColorBackgroundAlt2,
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(2, 3),
    maxWidth: 390,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 16px 0px rgba(0, 0, 0, 0.04)',
  },
  icon: {
    marginRight: theme.spacing(2),
    width: 64,
    height: 64,
  },
  textContainer: {
    maxWidth: 262,
  },
}))

export const CreateAccountCallout: React.FC = memo(() => {
  const classes = useStyles()
  const query = new URLSearchParams(window.location.search)
  const errorQueryParam = query.get('errorCode')
  const hasGoogleEmailNotRegisteredError =
    errorQueryParam === ErrorCode.EmailNotRegistered

  if (!hasGoogleEmailNotRegisteredError) {
    return null
  }

  return (
    <Box px={2}>
      <Box className={classes.root}>
        <NotVerifiedLightSmallPrimary className={classes.icon} />
        <div className={classes.textContainer}>
          <NxTypography variant="h4">Create a PlayVS Account</NxTypography>
          <NxTypography variant="body1">
            In order to enable Google sign in, you need to create a PlayVS
            account first.
          </NxTypography>
        </div>
      </Box>
    </Box>
  )
})
