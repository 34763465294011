import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { env } from '@plvs/env'
import { possibleTypes } from '@plvs/graphql'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { typePolicies } from './typePolicies'
import { errorLink } from './middleware/errorLink'
import { forceLogoutLink } from './middleware/forceLogoutLink'
import { mutationWatcherLink } from './middleware/mutationWatcherLink'
import { splitLink } from './middleware/splitWsLink'

export const initApolloClient = ({
  jwt,
  restore,
  name,
  version,
  requestUrl,
}: {
  jwt?: string
  restore?: NormalizedCacheObject
  name?: string
  version?: string
  requestUrl?: string
}): ApolloClient<Record<string, unknown>> => {
  const cache = restore
    ? new InMemoryCache({
        possibleTypes,
        typePolicies,
      }).restore(restore)
    : new InMemoryCache({
        possibleTypes,
        typePolicies,
      })

  const client = new ApolloClient({
    cache,
    connectToDevTools: !env.IS_NODE_ENV_PRODUCTION,
    link: ApolloLink.from([
      forceLogoutLink(cache),
      mutationWatcherLink,
      errorLink(),
      (createPersistedQueryLink() as unknown) as ApolloLink,
      splitLink(jwt, requestUrl),
    ]),
    resolvers: {},
    ssrMode: false,
    name: name ?? 'undefined-client',
    version: version ?? 'undefined-version',
  })

  return client
}
