import { Arrow } from '@plvs/rally/features/standings/Brackets'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { CreateCSSProperties } from '@material-ui/styles'
import { useBreakpointSm } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  arrow: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    position: 'absolute',
    top: theme.spacing(4),
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    '& svg': {
      top: '-1.5px',
      left: isMobile ? '-6px' : '-8px',
      position: 'relative',
    },
    border: '1px solid',
  }),
  arrowEnabled: {
    backgroundColor: theme.palette.OverlayColorBackgroundAccent,
    color: theme.palette.OverlayColorTextBase,
    borderColor: theme.palette.OverlayColorBackgroundAccent,
    cursor: 'pointer',
  },
  arrowDisabled: {
    backgroundColor: theme.palette.OverlayColorBackgroundAlt3,
    color: theme.palette.ColorTextDisabled,
    borderColor: theme.palette.OverlayBorderMedium,
    cursor: 'not-allowed',
  },
  leftArrow: {
    left: theme.spacing(3),
  },
  rightArrow: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    right: theme.spacing(3),
    '& svg': {
      left: isMobile ? '-5px' : '-6px',
    },
  }),
}))

export const Draggable = ({
  includeArrows = false,
  scrollDistance = 415,
  children,
}): React.ReactElement => {
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })

  const ourRef = useRef<HTMLDivElement>() as React.MutableRefObject<
    HTMLInputElement
  >

  const [isMouseDown, setIsMouseDown] = useState(false)
  const [leftDisabled, setLeftDisabled] = useState(true)
  const [rightDisabled, setRightDisabled] = useState(true)
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  })

  const checkDisabled = (): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[includeArrows ? 2 : 0] as HTMLElement
    const maxScrollLeft = slider.scrollWidth - slider.clientWidth
    setLeftDisabled(slider.scrollLeft === 0)
    setRightDisabled(slider.scrollLeft === maxScrollLeft)
  }

  useEffect(() => {
    checkDisabled()
  }, [ourRef])

  const handleDragStart = (e): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[includeArrows ? 2 : 0] as HTMLElement
    const startX = e.pageX - slider.offsetLeft
    const startY = e.pageY - slider.offsetTop
    const { scrollLeft } = slider
    const { scrollTop } = slider
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true)
    document.body.style.cursor = 'grabbing'
  }

  const handleDragEnd = (): void => {
    setIsMouseDown(false)
    if (!ourRef.current) return
    document.body.style.cursor = 'default'
    checkDisabled()
  }

  const handleDrag = (e): void => {
    if (!isMouseDown || !ourRef.current) return
    e.preventDefault()
    const slider = ourRef.current.children[includeArrows ? 2 : 0] as HTMLElement
    const x = e.pageX - slider.offsetLeft
    const y = e.pageY - slider.offsetTop
    const walkX = (x - mouseCoords.current.startX) * 1.5
    const walkY = (y - mouseCoords.current.startY) * 1.5
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
    slider.scrollTop = mouseCoords.current.scrollTop - walkY
  }

  const clickMove = (left: boolean): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[2] as HTMLElement
    const scrollSize = left ? -scrollDistance : scrollDistance
    slider.scroll({
      left: slider.scrollLeft + scrollSize,
      behavior: 'smooth',
    })
    setTimeout(() => {
      checkDisabled()
    }, 200)
  }

  return (
    <div
      ref={ourRef}
      className={classes.container}
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      role="presentation"
    >
      {includeArrows ? (
        <>
          <Arrow
            className={clsx(
              classes.leftArrow,
              classes.arrow,
              leftDisabled ? classes.arrowDisabled : classes.arrowEnabled
            )}
            onClick={(): void => clickMove(true)}
            prev
          />
          <Arrow
            className={clsx(
              classes.rightArrow,
              classes.arrow,
              rightDisabled ? classes.arrowDisabled : classes.arrowEnabled
            )}
            onClick={(): void => clickMove(false)}
          />
        </>
      ) : (
        <></>
      )}
      {children}
    </div>
  )
}
