import { forEachObjIndexed, map, zipObj } from 'ramda'
import { noop } from 'ramda-adjunct'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Param } from '@plvs/const'
import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'

export const useQuery = (
  location: RouteComponentProps['location']
): URLSearchParams => {
  try {
    return new URLSearchParams(location.search)
  } catch (e: any) {
    // NOTE: we only use get and set cast to unknown so we can cast
    // back into URLSearchParams... this only happens on IE11
    return ({ get: () => null, set: () => {} } as unknown) as URLSearchParams
  }
}

type QueryParamReturn = [string | null, (value: string) => void]

export const useQueryParam = (key?: Param): QueryParamReturn => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery(location)

  const value = key ? query.get(key) : ''
  const set = useCallback(
    key
      ? (nextValue: string): void => {
          if (nextValue) {
            query.set(key, nextValue)
          } else {
            query.delete(key)
          }
          navigate(
            {
              pathname: location.pathname,
              search: `?${query.toString()}`,
            },
            { replace: true }
          )
        }
      : noop,
    [navigate, location]
  )

  return [value, set]
}

// betterZipObj maintains the type of the key where zipObj would cast them to type string;
//  betterZipObj is useful where the key is an enum
function betterZipObj<T extends string, U>(
  keys: T[],
  values: U[]
): Record<T, U> {
  return zipObj(keys, values) as Record<T, U>
}

// use this hook when needing to update multiple params at the same time
export function useQueryParams<T extends Param>(
  keys: T[]
): [Record<T, string | null>, (newObj: Record<T, string>) => void] {
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery(location)

  const values = map((key) => query.get(key), keys)
  const obj = betterZipObj(keys, values)
  const set = useCallback(
    (newObj: Record<T, string>) => {
      forEachObjIndexed(
        (value, key) => (value ? query.set(key, value) : query.delete(key)),
        newObj
      )
      navigate(
        {
          pathname: location.pathname,
          search: `?${query.toString()}`,
        },
        { replace: true }
      )
    },
    [navigate, location]
  )

  return [obj, set]
}
