import { Avatar, Box } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

import React from 'react'

export type AvatarCircleSizes = 'small' | 'medium' | 'large' | 'xlarge'

const borderRadii = {
  xlarge: 6,
  large: 6,
  medium: 6,
  small: 6,
}

const sizes = {
  xlarge: {
    height: 80,
    width: 80,
  },
  large: {
    height: 64,
    width: 64,
  },
  medium: {
    height: 56,
    width: 56,
  },
  small: {
    height: 32,
    width: 32,
  },
}

export interface AvatarCircleProps {
  avatarUrl?: string | null | undefined
  className?: string
  defaultIcon?: React.ReactNode
  shape?: 'circle' | 'square'
  size: AvatarCircleSizes
}

export const AvatarCircle: React.FC<AvatarCircleProps> = ({
  avatarUrl,
  className,
  defaultIcon,
  shape = 'circle',
  size,
  ...props
}) => (
  <div
    className={className}
    style={typeof size === 'string' ? sizes[size] : size}
  >
    <Avatar
      alt="avatar"
      {...props}
      src={avatarUrl || undefined}
      style={{
        height: 'inherit',
        width: 'inherit',
        ...(shape === 'square' ? { borderRadius: borderRadii[size] } : {}),
      }}
    >
      {defaultIcon || (
        <Box>
          <AccountCircle style={{ height: 'inherit', width: 'inherit' }} />
        </Box>
      )}
    </Avatar>
  </div>
)
