import { isCoachForResource } from '@plvs/utils'
import React from 'react'
import { MatchChat } from '@plvs/rally/features/chat'
import {
  ChatRole,
  ResourceType,
  UserRoleName,
  useGetMatchTeamDetailsQuery,
} from '@plvs/graphql'

import {
  useGlobalUnreadMessagesVar,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { head } from 'ramda'
import { ActiveGlobalConversations } from '@plvs/client-data/models/GlobalChatConversations'
import { updateActiveGlobalChatConversationsVar } from '@plvs/client-data/mutations/updateActiveGlobalChatConversationsVar'
import { makeStyles, useTheme } from '@material-ui/core'
import {
  MatchOutlined,
  Notes,
  TeamsOutlined,
  Books,
} from '@playvs-inc/nexus-icons'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { MenuGroup } from '@playvs-inc/nexus-components'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { IntercomArticleMappings, Path } from '@plvs/const'
import * as analytics from '@plvs/respawn/features/analytics'
import { getMatchIdFromCoachChat } from '@plvs/rally/features/chat/utils'
import { useMatchChat } from '@plvs/rally/features/chat/hooks/useMatchChat'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    [theme.breakpoints.down('sm')]: {
      height: ({ expanded }: { expanded: boolean }): string => {
        return expanded ? 'calc(100vh - 100px)' : '56px'
      },
    },
  },
}))

export const GlobalChatWindow: React.FC<{
  uniqueName: string
  onClose(): void
  activeConversations: ActiveGlobalConversations
}> = ({ uniqueName, onClose, activeConversations }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const expanded = activeConversations[uniqueName]?.expanded

  const styles = useStyles({ expanded })
  const isCoachChat = uniqueName.includes('coach')
  const matchId = isCoachChat
    ? getMatchIdFromCoachChat({ uniqueName })
    : uniqueName

  const onClickToMatchLobby = (): void => {
    navigate(`${Path.Match}/${matchId}`)
  }

  const onClickToManageTeam = (): void => {
    navigate(`${Path.Match}/${matchId}/teams`)
  }

  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()
  const triggerPulse = unreadMessagesByConversation[uniqueName] > 0
  const numberOfMessagesUnreadToRead = unreadMessagesByConversation[uniqueName]

  const { userRoles, loading: loadingUserRoles } = useUserIdentityFn()

  const { data, loading } = useGetMatchTeamDetailsQuery({
    variables: {
      id: matchId,
    },
    skip: !matchId,
  })

  const team1 = data?.match?.team1
  const team1Id = team1?.id ?? ''
  const team2 = data?.match?.team2
  const team2Id = team2?.id ?? ''
  const esportSlug = data?.match?.esport?.slug

  const {
    rulebookArticleId,
    matchInstructionsArticleId,
  } = useGeneralEsportAdapter(esportSlug)

  const myTeamRoles = userRoles.filter(
    (role) => role.resourceId === team1?.id || role.resourceId === team2?.id
  )

  const myTeam = head(myTeamRoles)?.resourceId === team1?.id ? team1 : team2

  const isCoachOfTeam = isCoachForResource(userRoles, [team1Id, team2Id])
  const isAdmin = userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Admin &&
      role.resourceType === ResourceType.System
  )
  const chatRole = isCoachOfTeam ? ChatRole.Coach : ChatRole.Player
  const chatData = useMatchChat({
    chatUniqueName: uniqueName,
    active: true,
    chatRole,
    isGlobal: true,
    loadingConversationState: loading || loadingUserRoles,
  })

  const onClickHeader = (): void => {
    if (triggerPulse) {
      analytics.userReadUnreadMessageViaGlobal({
        chatUniqueName: uniqueName,
        numberOfUnreadMessagesRead: numberOfMessagesUnreadToRead,
        location: 'Global Chat Window',
      })
    }
    chatData?.conversation?.setAllMessagesRead()
    updateActiveGlobalChatConversationsVar({
      ...activeConversations,
      [uniqueName]: {
        active: activeConversations[uniqueName]?.active,
        expanded: !expanded,
      },
    })
  }

  const onCloseExtended = (): void => {
    chatData?.conversation?.setAllMessagesRead()
    onClose()
  }

  const menuOptions: MenuGroup[] = [
    {
      items: [
        {
          label: 'Go to Match Lobby',
          id: 'route-to-match-lobby',
          Icon: MatchOutlined,
          onClick: onClickToMatchLobby,
        },
        {
          label: 'Manage Team',
          id: 'manage-team',
          Icon: TeamsOutlined,
          onClick: onClickToManageTeam,
        },
        {
          label: 'Rulebook',
          id: 'manage-match-rulebook',
          Icon: Books,
          onClick: (): void => showIntercomArticle(rulebookArticleId),
        },
        {
          label: 'Match Instructions',
          id: 'manage-match-match-instructions',
          Icon: Notes,
          onClick: (): void =>
            showIntercomArticle(
              matchInstructionsArticleId ?? IntercomArticleMappings.allArticles
            ),
          disabled: !matchInstructionsArticleId,
        },
      ],
    },
  ]

  return (
    // @ts-ignore TODO: Fix this
    <MatchChat
      className={styles.fullHeight}
      {...chatData}
      chatRole={isAdmin ? ChatRole.Admin : chatRole}
      esportSlug={esportSlug}
      expanded={expanded}
      isCoachChat={isCoachChat}
      isGlobal
      // Passing loading from useQuery and identity reactive var versus wrapping this is a "wait till loaded" component because we want the chat window to open right away.
      // The loading state within the chat window will render while it waits for the data.
      isLoading={loading || loadingUserRoles || chatData.isLoading}
      matchId={matchId}
      menuBackgroundColor={theme.palette.ColorBackgroundBase}
      menuOptions={menuOptions}
      onClose={onCloseExtended}
      onExpanded={onClickHeader}
      teamName={myTeam?.name ?? ''}
      triggerPulse={triggerPulse}
    />
  )
}
