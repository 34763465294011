// utils

import { Game, GameStatus } from '@plvs/graphql/generated'

export const doesGameHaveStats = (game: Pick<Game, 'status'>): boolean => {
  return (
    game.status === GameStatus.Quarantined ||
    game.status === GameStatus.Reviewed
  )
}

export const isGameActive = (game: Pick<Game, 'status'>): boolean => {
  return game.status === GameStatus.Open || game.status === GameStatus.Live
}

export const shouldShowGame = (game: Pick<Game, 'status'>): boolean => {
  return game.status !== GameStatus.Cancelled
}
