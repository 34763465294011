import { SnackbarKey, useSnackbar } from 'notistack'
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions'
import { Fade } from '@material-ui/core'
import { FunctionComponent, useRef } from 'react'
import { assert, cleanGraphQLError } from '@plvs/utils'

export type WithSaveNotificationOptions = {
  progressMessage?: string
  successMessage?: string
  /* Use %s as error.message token */
  errorMessage?: string
}

const DEFAULT_WITH_SAVE_NOTIFICATION_OPTIONS: WithSaveNotificationOptions = {
  progressMessage: 'Saving in progress.',
  successMessage: 'Your changes have been saved.',
  errorMessage: 'An error has occurred: %s',
}

const MESSAGE_DISPLAY_DURATION = 5000
const SAVING_PROGRESS_MESSAGE_DELAY = 1000

/**
 * Creates a wrapper to that can be used to add 'Saving in progress' and
 * 'Update Successful' notification messages around a given mutation action.
 *
 * usage:
 *
 * @returns withSaveNotification mutation action wrapper
 */
export const useWithSaveNotification = (
  options?: WithSaveNotificationOptions
): CallableFunction => {
  const lastSnackbarRef = useRef<SnackbarKey>()
  const { progressMessage, successMessage, errorMessage } = Object.assign(
    DEFAULT_WITH_SAVE_NOTIFICATION_OPTIONS,
    options
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  /**
   * Wraps a given mutaiton action with a 'Saving in progress' and
   * 'Saving
   * Usage Example:
   *  withAutoSaveNotification(async (): Promise<void> => {
   *    await mutateMyAccount({
   *      variables: {
   *        email: updatedEmail,
   *      }
   *    })
   *  })
   */
  return async function withSaveNotification(
    mutationAction: () => Promise<void>
  ): Promise<void> {
    let savingSnackbarKey: SnackbarKey | null = null

    if (lastSnackbarRef.current) {
      closeSnackbar(lastSnackbarRef.current)
      lastSnackbarRef.current = undefined
    }

    // Show the Saving in process on a delay so this will only show
    // if the changes are not saved instantaneously.
    const continueTimeout = setTimeout(() => {
      savingSnackbarKey = enqueueSnackbar(progressMessage, {
        variant: 'info',
        persist: true,
        TransitionComponent: Fade as FunctionComponent<TransitionProps>,
        transitionDuration: {
          exit: 0,
        },
        preventDuplicate: true,
      })
      lastSnackbarRef.current = savingSnackbarKey
    }, SAVING_PROGRESS_MESSAGE_DELAY)

    try {
      await mutationAction()

      clearTimeout(continueTimeout)

      if (savingSnackbarKey) {
        closeSnackbar(savingSnackbarKey)
      }

      // Enqueing the saved message on a slight delay to allow the 'Saving in progress'
      // message to clear out before pushing the next message in.
      setTimeout(() => {
        lastSnackbarRef.current = enqueueSnackbar(successMessage, {
          variant: 'success',
          transitionDuration: {
            appear: MESSAGE_DISPLAY_DURATION,
            enter: 100,
            exit: 500,
          },
          TransitionComponent: Fade as React.ComponentType<TransitionProps>,
          preventDuplicate: true,
        })
      }, 300)
    } catch (err: any) {
      clearTimeout(continueTimeout)

      if (savingSnackbarKey) {
        closeSnackbar(savingSnackbarKey)
      }

      setTimeout(() => {
        assert(errorMessage)
        lastSnackbarRef.current = enqueueSnackbar(
          errorMessage.replace(
            '%s',
            cleanGraphQLError(err.message ?? err ?? 'Unknown error')
          ),
          {
            variant: 'error',
            transitionDuration: {
              appear: MESSAGE_DISPLAY_DURATION,
              enter: 100,
              exit: 500,
            },
            TransitionComponent: Fade as React.ComponentType<TransitionProps>,
            preventDuplicate: true,
          }
        )
      }, 300)
    }
  }
}
