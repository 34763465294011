import dayjs from 'dayjs'
import { groupBy } from 'ramda'

import { MatchStatus } from '@plvs/graphql'
import { MinimumMatchData } from './DrawerMatchCard'

const LIVE = 'A'
const NOT_LIVE = 'B'

export const groupByLive = groupBy((match: MinimumMatchData) =>
  match.status === MatchStatus.Live || match.status === MatchStatus.Open
    ? LIVE
    : NOT_LIVE
)

export const sortMatches = (
  matches: MinimumMatchData[]
): MinimumMatchData[] => {
  const groupedMatches: { [index: string]: MinimumMatchData[] } = groupByLive(
    matches
  )

  const sortedGroups = [
    groupedMatches[LIVE] || [],
    groupedMatches[NOT_LIVE] || [],
  ].map((group) =>
    group.sort(
      (matchA, matchB) =>
        dayjs(matchA?.scheduledStartsAt).unix() -
        dayjs(matchB?.scheduledStartsAt).unix()
    )
  )

  return sortedGroups.flat()
}
