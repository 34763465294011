import { ApolloProvider } from '@apollo/client'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { updateProductTypeFn } from '@plvs/client-data/mutations'
import { Cookie, LocalStorageKey } from '@plvs/const'
import { rallyEnv } from '@plvs/rally/env'
import { initApolloClient } from '@plvs/rally/init/apollo'
import { noop } from 'ramda-adjunct'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

type UseApolloProviderWrapperReturn = {
  changeProductAPI: ({
    productTypeChangeArg,
    environment,
  }: {
    productTypeChangeArg: ProductType
    environment?: string
  }) => void
  defaultStagingDevelopURL: string
}
type ApolloProviderWrapperContext = UseApolloProviderWrapperReturn

export const apolloProviderWrapperContext = React.createContext<
  ApolloProviderWrapperContext
>({
  changeProductAPI: noop,
  defaultStagingDevelopURL: '',
})

const productionAPIs = [
  {
    productType: ProductType.Stadium,
    url: 'https://sapi.playvs.com/graphql',
  },
  {
    productType: ProductType.Scholastic,
    url: 'https://api.playvs.com/graphql',
  },
]

export const useApolloProviderWrapperContext = (): {
  changeProductAPI: ({
    productTypeChangeArg,
    environment,
  }: {
    productTypeChangeArg: ProductType
    environment?: string
  }) => void
  defaultStagingDevelopURL: string
} => {
  const { changeProductAPI, defaultStagingDevelopURL } = React.useContext(
    apolloProviderWrapperContext
  )

  return {
    changeProductAPI,
    defaultStagingDevelopURL,
  }
}

interface ApolloProviderWrapperProps {
  name: string
  defaultEnvironmentURI?: string
}

export const ApolloProviderWrapper: React.FC<ApolloProviderWrapperProps> = ({
  name,
  children,
  defaultEnvironmentURI = rallyEnv.GRAPHQL_URI,
}) => {
  const defaultProductType = useProductTypeFn()
  const localStoredURI = localStorage.getItem(LocalStorageKey.Environment)
  const sessionStoredProductType = localStorage.getItem(
    LocalStorageKey.ProductType
  ) as ProductType
  const [productUrl, setProductUrl] = useState<string>(
    localStoredURI || defaultEnvironmentURI
  )

  const [productType, setProductType] = useState<ProductType>(
    sessionStoredProductType || defaultProductType
  )
  const [cookies] = useCookies([Cookie.Token])

  const getProductionAPI = (productTypeArg: ProductType): void => {
    const propApi = productionAPIs.find(
      (product) => product.productType === productTypeArg
    )?.url
    if (propApi) {
      setProductUrl(propApi)
      localStorage.setItem(LocalStorageKey.Environment, propApi)
    }
  }
  const getStagingAPI = (environment?: string): void => {
    let defaultEnv: string = productUrl
    if (environment) {
      defaultEnv = environment
    }

    setProductUrl(defaultEnv)
    localStorage.setItem(LocalStorageKey.Environment, defaultEnv)
  }

  const changeProductAPI = ({
    productTypeChangeArg,
    environment,
  }: {
    productTypeChangeArg: ProductType
    environment?: string
  }): void => {
    const isProduction = rallyEnv.API_ENV === 'production'

    if (isProduction) {
      getProductionAPI(productTypeChangeArg)
    } else {
      getStagingAPI(environment)
    }
    setProductType(productTypeChangeArg)
    localStorage.setItem(LocalStorageKey.ProductType, productTypeChangeArg)
  }

  const client = useMemo(() => {
    return initApolloClient({
      jwt: cookies[Cookie.Token],
      restore: window.__APOLLO_STATE__, // eslint-disable-line no-underscore-dangle
      name,
      version: '1.0',
      requestUrl: productUrl,
    })
  }, [productUrl])

  useEffect(() => {
    updateProductTypeFn(productType)
  }, [productType])

  return (
    <apolloProviderWrapperContext.Provider
      value={{
        changeProductAPI,
        defaultStagingDevelopURL: productUrl,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </apolloProviderWrapperContext.Provider>
  )
}
