import React, { useState, useEffect } from 'react'
import { isNil } from 'ramda'
import { Box, useTheme } from '@material-ui/core'

import {
  NxTypography,
  NxButton,
  NxFirebobMessage,
  NxSelectableSingle,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import {
  MatchSettleRequestFragment,
  MatchSettleRequestStatus,
  useAdminSaveGameResultLiveMutation,
  useFetchMatchSettleRequestQuery,
  useMatchSettleRequestSubmittedSubscription,
  useMatchSettleRequestUpdatedSubscription,
  useSaveGameResultLiveMutation,
} from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchAssistantContext } from '../../useMatchAssistant'
import { useStyles } from './Reporting.styles'
import {
  FirebobMessage,
  submitGameResults,
  TeamSide,
  updateStateOnMatchSettleRequestChange,
} from './Reporting.helpers'

import { MatchAssistantFooter } from '../../MatchAssistantFooter'
import { TriggerMatchAssistantAlertsButton } from '../../../dynamic/buttons/TriggerMatchAssistantAlertsButton'
import { useMatchAssistantStepAlertsContext } from '../../hooks/useMatchAssistantStepAlerts'

export const Reporting: React.FC = () => {
  const theme = useTheme()
  const { userId } = useUserIdentityFn()

  const {
    currentSeries,
    currentStep,
    refetchCurrentGameAssistantStepByMatchId,
    selectedStage,
    homeCharacter,
    awayCharacter,
    homeTeamId,
    awayTeamId,
    match,
    teamId: myTeamId = '',
    isInCheckpoint,
    setSubmittedUserIdForNPS,
  } = useMatchAssistantContext()

  const homePlayer =
    currentSeries?.team1?.id === homeTeamId
      ? currentSeries?.team1?.roster?.[0]
      : currentSeries?.team2?.roster?.[0]
  const awayPlayer =
    currentSeries?.team1?.id === homeTeamId
      ? currentSeries?.team2?.roster?.[0]
      : currentSeries?.team1?.roster?.[0]
  const homePlayerCharacter = homeCharacter?.selectionOption
  const awayPlayerCharacter = awayCharacter?.selectionOption

  const classes = useStyles({
    hasCharacterAsset: Boolean(homePlayerCharacter?.assetUri),
  })

  /// ///////
  // State //
  /// ///////
  const [selectedTeamSide, setSelectedTeamSide] = useState<TeamSide | -1>(-1)
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)
  const [firebobMessage, setFirebobMessage] = useState<FirebobMessage>(
    FirebobMessage.Hmm
  )
  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)

  /// /////////
  // QUERIES //
  /// /////////
  const { data: incomingMatchSettleRequest } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId: match?.id,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !(match?.id || userId),
    fetchPolicy: 'network-only',
  })

  /// ///////////
  // MUTATIONS //
  /// ///////////
  const [saveGameResultLive] = useSaveGameResultLiveMutation()
  const [adminSaveGameResultLive] = useAdminSaveGameResultLiveMutation()

  const saveGameResult = isInCheckpoint
    ? adminSaveGameResultLive
    : saveGameResultLive

  /// ///////////////
  // SUBSCRIPTIONS //
  /// ///////////////
  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId: match?.id ?? '',
      },
    },
    skip: !match?.id,
  })

  useMatchSettleRequestSubmittedSubscription({
    variables: {
      input: {
        matchSettleRequestId: matchSettleRequest?.id ?? '',
      },
    },
    skip: !matchSettleRequest?.id,
    fetchPolicy: 'network-only',
  })

  /// /////////
  // EFFECTS //
  /// /////////

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]

    if (returnedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: returnedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled: false,
        gameAssistantStepHasBeenUpdated: false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(returnedMatchSettleRequest)
    } else {
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest

    if (updatedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: updatedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.matchHasBeenAutoSettled ?? false,
        gameAssistantStepHasBeenUpdated:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.gameAssistantStepHasBeenUpdated ?? false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
    }
  }, [matchSettleRequestUpdated])

  const { getStepAlerts, openStepAlert } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  return (
    <>
      <Box className={classes.container}>
        <NxTypography variant="h1">
          Who won Game {currentStep?.step?.gameOrdinal}?
        </NxTypography>

        {selectedStage && (
          <Box className={classes.subtitles}>
            <NxTypography variant="overline">Stage</NxTypography>

            <NxTypography variant="body1">
              {selectedStage?.selectionOption?.displayName}
            </NxTypography>
          </Box>
        )}

        <NxSelectableSingle
          height={`${theme.spacing(11)}px`}
          hideCheckmark
          onChange={(_, newValue): void => {
            setSelectedTeamSide(newValue)
          }}
          outerContainerClassName={classes.outerContainer}
          selectableContainerClassName={classes.selectableContainer}
          value={selectedTeamSide}
          width="100%"
        >
          <Box key="home-team" className={classes.selectable}>
            <NxUserCluster
              avatarHashId={homePlayer?.id ?? ''}
              avatarUrl={
                homePlayerCharacter?.assetUri ??
                homePlayer?.avatarUrl ??
                undefined
              }
              className={classes.userCluster}
              compact={!homePlayerCharacter?.assetUri}
              subtitles={[{ title: homePlayerCharacter?.displayName ?? '' }]}
              title={homePlayer?.name ?? '??'}
            />
          </Box>

          <Box key="away-team" className={classes.selectable}>
            <NxUserCluster
              avatarHashId={awayPlayer?.id ?? ''}
              avatarUrl={
                awayPlayerCharacter?.assetUri ??
                awayPlayer?.avatarUrl ??
                undefined
              }
              className={classes.userCluster}
              compact={!awayPlayerCharacter?.assetUri}
              subtitles={[{ title: awayPlayerCharacter?.displayName ?? '' }]}
              title={awayPlayer?.name ?? '??'}
            />
          </Box>
        </NxSelectableSingle>

        <MatchAssistantFooter>
          <NxFirebobMessage message={firebobMessage} />

          <Box
            display="flex"
            gridGap={theme.spacing(2)}
            justifyContent="space-between"
            width="100%"
          >
            <TriggerMatchAssistantAlertsButton
              disabled={stepAlertsData.actionLoading}
              label="Reset Game"
              onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            />

            <NxButton
              disabled={selectedTeamSide === -1}
              fullWidth
              label={
                firebobMessage === FirebobMessage.RejectedSubmission
                  ? 'Resubmit'
                  : 'Submit'
              }
              loading={
                loadingMatchSettleRequest ||
                firebobMessage === FirebobMessage.WaitingOnOpp
              }
              onClick={async (): Promise<void> => {
                setSubmittedUserIdForNPS(userId)
                if (!isNil(selectedTeamSide) && currentStep && match) {
                  await submitGameResults({
                    selectedTeamSide,
                    homeTeamId,
                    awayTeamId,
                    homePlayerId: homePlayer?.id ?? '',
                    awayPlayerId: awayPlayer?.id ?? '',
                    userId,
                    matchId: match?.id ?? '',
                    seriesId: currentStep?.step?.seriesId ?? '',
                    seriesOrdinal: currentStep?.step?.seriesOrdinal ?? 0,
                    gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
                    myTeamId,
                    saveGameResultLive: saveGameResult,
                    setIsLoadingMatchSettleRequest,
                    isAdmin: isInCheckpoint,
                  })
                }
              }}
              variant="primary"
            />
          </Box>
        </MatchAssistantFooter>
      </Box>
    </>
  )
}
