import React, { useState } from 'react'
import {
  useGenerateReferralLinkMutation,
  useGetOwnerToAddToTeamQuery,
  ResourceType,
  UserRoleName,
} from '@plvs/graphql'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSnackbar } from 'notistack'
import { cleanGraphQLError, useAutoskipQuery } from '@plvs/utils'
import { noop } from 'ramda-adjunct'
import { Button, makeStyles } from '@material-ui/core'
import { NxModal, NxTypography } from '@playvs-inc/nexus-components'
import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { TeamInvite } from '@plvs/respawn/features/coach/teams/TeamInvite'
import { Banner, BannerType } from '../../banner'
import { useAddPlayerToTeamHook } from './useAddPlayerToTeamHook'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.ColorTextBase,
  },
  content: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
  },
  row: {
    paddingLeft: theme.spacing(4),
  },
}))

export interface AddPlayerToTeamDTCProps {
  teamId: string
  userId: string
  positionIndex: number | null
  onMutationSuccess?(returnedTeamId?: string): Promise<void>
  closeDialog(): void
  metaseasonId?: string
  isDialogOpen: boolean
}

export const AddPlayerToTeamDTC: React.FC<AddPlayerToTeamDTCProps> = ({
  teamId,
  positionIndex,
  onMutationSuccess,
  closeDialog,
  isDialogOpen,
  userId,
  metaseasonId,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState<Error>()
  const [referralLink, setReferralLink] = React.useState<string>('')

  const { data, loading } = useAutoskipQuery(useGetOwnerToAddToTeamQuery, {
    variables: { id: teamId },
  })

  const [addPlayerToTeam, { loading: isAddMutating }] = useAddPlayerToTeamHook()
  const [generateReferralLink, { loading: referralLoading }] =
    useGenerateReferralLinkMutation()

  const team = data?.team
  const teamOwner =
    team?.owners
      ?.filter((owner) => owner.id === userId)
      .map((to) => ({
        avatarSrc: to.avatarUrl ?? '',
        title: to.name ?? '',
        id: to.id ?? '',
      })) ?? []

  const rosterMembers = team?.roster?.players ?? []

  const isTeamOwnerAMemberOfTeam = rosterMembers.some(
    (player) => player.user.id === userId
  )

  const inviteToken = referralLink.split('/su/')?.[1]?.split('?')?.[0]
  const inviteUrl = `${team?.inviteUrl}/${inviteToken}`
  const rosterMemberIds = rosterMembers.map((member) => member.user.id)

  const generateReferralLinkFunc =
    React.useCallback(async (): Promise<void> => {
      try {
        // TODO: generateRerralLink should be moved to the backend
        // and added to the service that generates the inviteUrl
        const referralLinkData = await generateReferralLink({
          variables: {
            resourceType: ResourceType.System,
            roleName: UserRoleName.User,
            source: 'manage_team_invite',
            medium: 'share_link',
          },
        })
        const newReferralLink =
          referralLinkData?.data?.generateReferralLink ?? ''
        setReferralLink(newReferralLink)
      } catch (err) {
        noop(err)
      }
    }, [referralLink])

  const addPlayer = async (): Promise<void> => {
    try {
      const mutationVariables = {
        teamId,
        position: positionIndex,
      }
      const { success, teamId: returnedTeamId } = await addPlayerToTeam(
        {
          ...mutationVariables,
          metaseasonId,
          userId,
        },
        rosterMemberIds
      )
      if (success) {
        enqueueSnackbar('Success! You have been added.', {
          variant: 'success',
        })
      } else {
        throw new Error('Unable to add selected player to the team.')
      }

      closeDialog()
      await onMutationSuccess?.(returnedTeamId)
    } catch (e) {
      // @ts-ignore
      setError(e)
    }
  }

  React.useEffect(() => {
    if (!referralLink) {
      generateReferralLinkFunc()
    }
  }, [referralLink])

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <NxModal
      actions={
        <>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color="primary"
            data-testid="AddPlayerToTeamDTC_Button"
            disabled={isAddMutating || isTeamOwnerAMemberOfTeam}
            onClick={addPlayer}
            variant="contained"
          >
            Confirm
          </Button>
        </>
      }
      fullWidth
      maxWidth="xs"
      onClose={closeDialog}
      open={isDialogOpen}
      subtitle="Select a player from the list"
      title="Add a Player"
    >
      <>
        {errorMessage && (
          <Box py={2}>
            <Banner
              subtitle={errorMessage}
              title="Unable to add you to the team"
              type={BannerType.Error}
            />
          </Box>
        )}
        <WaitTillLoaded
          loading={loading || referralLoading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <Box pb={4} pt={1}>
            <NxTypography variant="body1">
              Share this link with anyone aged 13-18 to join your team
            </NxTypography>
            <Box pt={2}>
              <TeamInvite inviteUrl={inviteUrl} />
            </Box>
          </Box>
          {isTeamOwnerAMemberOfTeam ? (
            <Box pb={2} pt={1}>
              <Banner
                data-testid="AddPlayerToTeamDTC_Banner"
                subtitle="You are already a member of the team"
                title="Unable to add you to the team"
                type={BannerType.Warning}
              />
            </Box>
          ) : (
            <>
              <Box pb={2}>
                <NxTypography variant="body1">
                  Or, choose from available players
                </NxTypography>
              </Box>
              <Box className={classes.content}>
                <Box className={classes.row}>
                  <RosterRowGroup
                    onChange={noop}
                    rowEntries={teamOwner}
                    selectedId={userId}
                  />
                </Box>
              </Box>
            </>
          )}
        </WaitTillLoaded>
      </>
    </NxModal>
  )
}
