import React, { useEffect, useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { makeStyles, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { pushMessage } from '@plvs/rally/pages/app/match/slotQueuePushHelper'

const useStyles = makeStyles((theme) => ({
  subHeader: {
    textAlign: 'center',
  },
  teamsContainer: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    alignItems: 'center',
    flexGrow: mobile ? 1 : 2,
    marginBottom: mobile ? '0' : '250px',
    width: mobile ? '100%' : '50%',
    flexDirection: mobile ? 'column' : 'row',
  }),
  teamLabel: {
    marginTop: theme.spacing(3),
  },
  mobileTeamNameContainer: {
    marginLeft: theme.spacing(3),
  },
  mobileTeamContainer: {
    marginBottom: theme.spacing(2),
  },
  mobileHeader: {
    marginBottom: theme.spacing(5),
  },
  mobileButtonContainer: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
  },
  acceptButton: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    width: '211px',
  },
  declineButton: {
    width: '160px',
    height: '36px',
  },
  acceptedOverlay: {
    position: 'absolute',
    left: '-4px',
    top: '-4px',
  },
}))

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface MatchQueueOpponentRowProps {
  team?: Team | null
  teamAccepted: boolean
  opponentTeam?: Team | null
  opponentAccepted: boolean
  onAccept(): Promise<void>
  onDecline(): Promise<void>
  onReady(): Promise<void>
  onExpire(): Promise<void>
}

export const MatchQueueOpponentRow: React.FC<MatchQueueOpponentRowProps> = ({
  team,
  teamAccepted,
  opponentTeam,
  opponentAccepted,
  onAccept,
  onDecline,
  onReady,
  onExpire,
}) => {
  const mobile = useBreakpointSm()
  const theme = useTheme()
  const {
    subHeader,
    teamsContainer,
    teamLabel,
    mobileTeamNameContainer,
    mobileTeamContainer,
    mobileHeader,
    mobileButtonContainer,
    buttonContainer,
    acceptButton,
    declineButton,
    acceptedOverlay,
  } = useStyles({ mobile })

  let subheaderText = 'Ready Up!'
  if (teamAccepted && opponentAccepted) {
    subheaderText = 'Match Starting shortly...!'
  } else if (teamAccepted) {
    subheaderText = 'Waiting for Opponent to Accept'
  } else if (opponentAccepted) {
    subheaderText = 'Opponent’s ready! Please accept to get started.'
  }

  const [expireCountdown, setExpireCountdown] = useState(15)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (!(teamAccepted && opponentAccepted)) {
        const nextCountdown = expireCountdown - 1
        if (nextCountdown <= 0) {
          onExpire()
        }
        if (nextCountdown >= 0) {
          setExpireCountdown(nextCountdown)
        }
      } else {
        clearInterval(timerInterval)
      }
    }, 1000)
    return (): void => {
      clearInterval(timerInterval)
    }
  }, [expireCountdown])

  const [moveToMatchCountdown, setMoveToMatchCountdown] = useState(60)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (teamAccepted && opponentAccepted) {
      const timerInterval = setInterval(() => {
        const nextCountdown = moveToMatchCountdown - 1
        if (nextCountdown <= 0) {
          onReady()
        }
        if (nextCountdown >= 0) {
          setMoveToMatchCountdown(nextCountdown)
        }
      }, 1000)
      return (): void => {
        clearInterval(timerInterval)
      }
    }
  }, [moveToMatchCountdown, teamAccepted, opponentAccepted])

  useEffect(() => {
    if (opponentTeam) {
      pushMessage('Match Ready!', {
        body: `Your match with ${opponentTeam.name} is ready!`,
      })
    }
  }, [opponentTeam])

  const isYouthProgram = team?.esport?.rating === EsportRating.Restricted

  return (
    <Box className={teamsContainer} data-testid="MatchQueueOpponentRow">
      {mobile ? (
        <>
          <Box
            alignItems="center"
            className={mobileHeader}
            color={theme.palette.OverlayColorTextBase}
            data-testid="MatchQueueOpponentRow_Mobile"
            display="flex"
            flexDirection="column"
          >
            <NxTypography color="inherit" variant="h2">
              Team Found
            </NxTypography>
            <NxTypography
              className={subHeader}
              color="inherit"
              variant="subtitle1"
            >
              {subheaderText}
            </NxTypography>
            <NxTypography color="inherit" variant="display">
              {teamAccepted && opponentAccepted
                ? moveToMatchCountdown
                : expireCountdown}
            </NxTypography>
          </Box>
          <Box display="flex" flexDirection="column" position="relative">
            {teamAccepted ? (
              <svg
                className={acceptedOverlay}
                fill="none"
                height="88"
                viewBox="0 0 40 40"
                width="88"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" fill="#00ff00" r="20" />
              </svg>
            ) : (
              <></>
            )}
            <Box
              className={mobileTeamContainer}
              display="flex"
              flex="1"
              flexDirection="row"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={isYouthProgram ? undefined : team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <Box
                className={mobileTeamNameContainer}
                color={theme.palette.OverlayColorTextBase}
                display="flex"
                flex="1"
                flexDirection="column"
              >
                <NxTypography color="inherit" variant="h1">
                  {team?.name}
                </NxTypography>
                {!isYouthProgram && (
                  <NxTypography color="inherit" variant="subtitle2">
                    {team?.school?.name}
                  </NxTypography>
                )}
              </Box>
            </Box>
            <Box display="flex" flex="1" flexDirection="row" marginRight="auto">
              <Box flex="1" flexDirection="column" position="relative">
                {opponentAccepted ? (
                  <svg
                    className={acceptedOverlay}
                    fill="none"
                    height="88"
                    viewBox="0 0 40 40"
                    width="88"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="20" cy="20" fill="#00ff00" r="20" />
                  </svg>
                ) : (
                  <></>
                )}
                <NxUserAvatar
                  avatarUrl={
                    isYouthProgram ? undefined : opponentTeam?.school?.logoUrl
                  }
                  hashId={team?.id ?? ''}
                  size={NxAvatarSize.LG}
                />
                <Box
                  className={mobileTeamNameContainer}
                  color={theme.palette.OverlayColorTextBase}
                  display="flex"
                  flex="1"
                  flexDirection="column"
                >
                  <NxTypography color="inherit" variant="h1">
                    {opponentTeam?.name}
                  </NxTypography>
                  {!isYouthProgram && (
                    <NxTypography color="inherit" variant="subtitle2">
                      {opponentTeam?.school?.name}
                    </NxTypography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            alignItems="center"
            className={mobileButtonContainer}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <NxButton
              className={acceptButton}
              disabled={teamAccepted}
              label="Accept"
              onClick={async (): Promise<void> => onAccept()}
              shouldUseOverlayColors
              variant="primary"
            />
            <NxButton
              className={declineButton}
              disabled={teamAccepted}
              label="Decline"
              onClick={async (): Promise<void> => onDecline()}
              shouldUseOverlayColors
              variant="secondary"
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            marginRight="auto"
            position="relative"
          >
            {teamAccepted ? (
              <svg
                className={acceptedOverlay}
                fill="none"
                height="158"
                viewBox="0 0 40 40"
                width="158"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" fill="#00ff00" r="20" />
              </svg>
            ) : (
              <></>
            )}
            <NxUserAvatar
              avatarUrl={isYouthProgram ? undefined : team?.school?.logoUrl}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.XXL}
            />
            <NxTypography className={teamLabel} color="inherit" variant="h1">
              {team?.name}
            </NxTypography>
            {!isYouthProgram && (
              <NxTypography color="inherit" variant="subtitle2">
                {team?.school?.name}
              </NxTypography>
            )}
          </Box>
          <Box
            alignItems="center"
            className={buttonContainer}
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
          >
            <NxTypography color="inherit" variant="h2">
              Team Found
            </NxTypography>
            <NxTypography
              className={subHeader}
              color="inherit"
              variant="subtitle1"
            >
              {subheaderText}
            </NxTypography>
            <NxTypography color="inherit" variant="display">
              {teamAccepted && opponentAccepted
                ? moveToMatchCountdown
                : expireCountdown}
            </NxTypography>
            <NxButton
              className={acceptButton}
              data-cy="acceptOppenentQueueButton"
              disabled={teamAccepted}
              label="Accept"
              onClick={async (): Promise<void> => onAccept()}
              shouldUseOverlayColors
              variant="primary"
            />
            <NxButton
              className={declineButton}
              data-cy="declineOppenentQueueButton"
              disabled={teamAccepted}
              label="Decline"
              onClick={async (): Promise<void> => onDecline()}
              shouldUseOverlayColors
              variant="secondary"
            />
          </Box>
          <Box
            alignItems="flex-end"
            display="flex"
            flex="1"
            flexDirection="column"
            marginLeft="auto"
          >
            <Box
              color={theme.palette.OverlayColorTextBase}
              flex="1"
              flexDirection="column"
              position="relative"
            >
              {opponentAccepted ? (
                <svg
                  className={acceptedOverlay}
                  fill="none"
                  height="158"
                  viewBox="0 0 40 40"
                  width="158"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" fill="#00ff00" r="20" />
                </svg>
              ) : (
                <></>
              )}
              <NxUserAvatar
                avatarUrl={
                  isYouthProgram ? undefined : opponentTeam?.school?.logoUrl
                }
                hashId={team?.id ?? ''}
                size={NxAvatarSize.XXL}
              />
              <NxTypography className={teamLabel} color="inherit" variant="h1">
                {opponentTeam?.name}
              </NxTypography>
              {!isYouthProgram && (
                <NxTypography color="inherit" variant="subtitle2">
                  {opponentTeam?.school?.name}
                </NxTypography>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
