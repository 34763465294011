import React, { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box } from '@plvs/respawn/features/layout'
import { TextField } from '@material-ui/core'
import { cleanGraphQLError, CookieProps, formErrorToString } from '@plvs/utils'
import {
  refetchGetUserProviderAccountsQuery,
  useLinkPlayStationNetworkAccountMutation,
  LinkPlayStationNetworkAccountInput,
  useUnlinkUserProviderAccountMutation,
} from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'

import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import ConnectionCard from './ConnectionCard'
import { AccountProviderComponentProps } from './utils'

interface ProviderConnectionProps
  extends AccountProviderComponentProps,
    RouteComponentProps {}

export const PlayStationNetworkConnection: React.FC<
  ProviderConnectionProps & CookieProps
> = ({ name: providerName, providerDetails, checkpoint, userId, isParent }) => {
  // Hooks
  const [psnUsername, setName] = useState<string>(
    providerDetails?.providerDisplayName ?? ''
  )
  const [error, setError] = useState<ApolloError | undefined>()
  const [providerId, setProviderId] = useState<string | undefined>(
    providerDetails?.id
  )

  // Data
  const lastUpdated =
    providerDetails?.updatedAt ?? providerDetails?.createdAt ?? ''

  // form
  const { errors, handleSubmit, register } = useForm<
    LinkPlayStationNetworkAccountInput
  >({
    defaultValues: { psnUsername, userId },
    resolver: yupResolver<LinkPlayStationNetworkAccountInput>(
      yup.object().shape({
        psnUsername: yup
          .string()
          .required('Enter a PlayStation Network username'),
      })
    ),
  })

  // Mutations
  // TODO should be updated with PSN
  const [
    remove,
    { loading: disconnectMutationLoading },
  ] = useUnlinkUserProviderAccountMutation({
    onError: (err: ApolloError): void => setError(err),
  })
  const [
    connectPsnAccount,
    { loading: connectMutationLoading },
  ] = useLinkPlayStationNetworkAccountMutation({
    onError: (err: ApolloError): void => setError(err),
  })

  useEffect(() => {
    if (disconnectMutationLoading || connectMutationLoading) {
      return
    }
    if (providerDetails?.providerDisplayName !== psnUsername) {
      setName(providerDetails?.providerDisplayName ?? '')
    }
    if (providerDetails?.id !== providerId) {
      setProviderId(providerDetails?.id)
    }
  }, [providerDetails])

  const onConnect = handleSubmit(
    async (input: { psnUsername: string }): Promise<void> => {
      const linkPlayStationNetworkAccountInput = {
        psnUsername: input.psnUsername,
        userId: userId ?? '',
      }
      if (!connectMutationLoading) {
        const result = await connectPsnAccount({
          awaitRefetchQueries: true,
          refetchQueries: [refetchGetUserProviderAccountsQuery()],
          variables: { input: linkPlayStationNetworkAccountInput },
        })
        setProviderId(result.data?.linkPlayStationNetworkAccount?.id ?? '')
        setName(
          result.data?.linkPlayStationNetworkAccount.providerDisplayName ?? ''
        )
      }
    }
  )

  const disconnectPsnAccount = async (): Promise<void> => {
    if (!providerId) {
      return
    }
    if (!disconnectMutationLoading) {
      await remove({
        awaitRefetchQueries: true,
        refetchQueries: [refetchGetUserProviderAccountsQuery()],
        variables: {
          providerId,
        },
      })
      setName('')
      setProviderId(undefined)
      setError(undefined)
    }
  }

  const renderError = (): string =>
    error?.message ? cleanGraphQLError(error.message) : ''

  const EditComponent = !psnUsername ? (
    <form key={psnUsername} noValidate onSubmit={onConnect}>
      <Box maxWidth={400}>
        <TextField
          autoComplete="off"
          defaultValue={psnUsername}
          disabled={connectMutationLoading}
          error={!!errors.psnUsername}
          fullWidth
          helperText={formErrorToString(errors.psnUsername)}
          InputProps={{ readOnly: !!psnUsername }}
          inputRef={register}
          label="Username"
          name="psnUsername"
          size="small"
          variant="outlined"
        />
      </Box>
    </form>
  ) : undefined

  return (
    <ConnectionCard
      checkpoint={checkpoint}
      connectedText={psnUsername ? `Connected to ${psnUsername}` : ''}
      disabled={connectMutationLoading || disconnectMutationLoading}
      EditComponent={!psnUsername ? EditComponent : undefined}
      errorText={renderError()}
      isConnected={!!psnUsername}
      isParent={isParent}
      lastUpdated={lastUpdated}
      onConnectClick={onConnect}
      onUpdateClick={disconnectPsnAccount}
      providerName={providerName}
      subtitle="Connect your PlayStation Network account"
      title="PlayStation Network"
      userId={userId}
    />
  )
}
