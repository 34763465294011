import {
  Playstation,
  Leagueoflegends,
  Battlenet,
  Xbox,
  Nintendoswitch,
  Notifications,
  Epicgames,
  Steam,
} from '@playvs-inc/nexus-icons'
import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { Provider } from '@plvs/graphql/generated'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'
import { PillVariants } from '../../NotificationPill'

const PUBLISHER_ICONS: any = {
  [Provider.PlayStationNetwork]: Playstation,
  [Provider.Riot]: Leagueoflegends,
  [Provider.BattleDotNet]: Battlenet,
  [Provider.XBoxLive]: Xbox,
  [Provider.Nintendo]: Nintendoswitch,
  [Provider.EpicGames]: Epicgames,
  [Provider.Steam]: Steam,
}

export const ConnectPublisherAccountAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Connect Provider Account',

  createNotification(notification: UserNotification): React.ReactElement {
    const iconName = notification?.contentData?.glyph ?? ''
    const IconComponent = PUBLISHER_ICONS[iconName] ?? Notifications

    return (
      <SystemMessage
        IconComponent={<IconComponent size="4rem" />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        pillText="Critical Match Issue"
        pillVariant={PillVariants.Warning}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
