import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Avatar } from '../avatar'

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.ColorTextAlt2,
  },
}))
export interface OnboardCustomerExperienceProps {
  name: string
  subtitle?: string
  title?: string
}

export const OnboardCustomerExperience: React.FC<OnboardCustomerExperienceProps> = ({
  name,
  subtitle,
  title,
}) => {
  const { overline } = useStyles()
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="row">
        <Avatar
          backgroundColor={theme.palette.ColorBackgroundAccent}
          size="xsmall"
        />
        <Box alignItems="flex-end" display="flex" flexDirection="row">
          <Box pl={2} pr={1}>
            <NxTypography variant="h4">{name}</NxTypography>
          </Box>
          {title && (
            <NxTypography className={overline} variant="overline">
              {title}
            </NxTypography>
          )}
        </Box>
      </Box>
      <Box pl={5}>
        <NxTypography variant="body1">{subtitle}</NxTypography>
      </Box>
    </Box>
  )
}
