import { InMemoryCache } from '@apollo/client'
import { FilterCacheContext } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { useLogout } from '@plvs/rally/libs/auth/loginLogoutHooks'
import React, { useEffect } from 'react'

/**
 * React-router route component that enforces the user is logged out.
 * If the user is currently logged in, it will force a logout and
 * refresh the page.
 * @param props
 */
export const LoggedOutWrapper = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const cache = new InMemoryCache()
  const logout = useLogout(cache)
  const { setEntityId, setEntityType } = React.useContext(FilterCacheContext)
  useEffect(() => {
    logout()
    setEntityId('')
    setEntityType('')
  }, [])

  return children
}
