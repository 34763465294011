import React from 'react'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistant } from '@plvs/rally/features/match/assistant/MatchAssistant'
import { MatchAssistantStepAlertsProvider } from '@plvs/rally/features/match/assistant/hooks'
import { MatchAssistantProvider } from '@plvs/rally/features/match/assistant/useMatchAssistant'
import { MatchTeamForRenderController } from '@plvs/respawn/renderController/matchLobby/match/types/matchRenderController.types'
import { Box, useTheme } from '@material-ui/core'

interface MatchAssistantWrapperProps {
  matchId: string
  teamId: string
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  skipMatchAssistant(): Promise<void>
}

export const MatchAssistantWrapper: React.FC<MatchAssistantWrapperProps> = ({
  team1,
  team2,
  matchId,
  teamId,
  skipMatchAssistant,
}) => {
  const theme = useTheme()

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const {
    shouldRenderMatchAssistant,
    shouldRenderMatchAssistantForBothTeams,
  } = getMatchLobbyRenderControllerState().match

  if (shouldRenderMatchAssistantForBothTeams) {
    return (
      <Box
        data-testid="match-assistant-container"
        display="flex"
        flexWrap="wrap"
        gridGap={theme.spacing(3)}
        justifyContent="space-between"
        width="100%"
      >
        <MatchAssistantProvider
          matchId={matchId}
          myTeamId={team1.id}
          skipMatchAssistant={skipMatchAssistant}
        >
          <MatchAssistantStepAlertsProvider matchId={matchId} teamId={team1.id}>
            <MatchAssistant />
          </MatchAssistantStepAlertsProvider>
        </MatchAssistantProvider>

        <MatchAssistantProvider
          matchId={matchId}
          myTeamId={team2.id}
          skipMatchAssistant={skipMatchAssistant}
        >
          <MatchAssistantStepAlertsProvider matchId={matchId} teamId={team2.id}>
            <MatchAssistant />
          </MatchAssistantStepAlertsProvider>
        </MatchAssistantProvider>
      </Box>
    )
  }

  if (shouldRenderMatchAssistant) {
    return (
      <MatchAssistantProvider
        matchId={matchId}
        myTeamId={teamId}
        skipMatchAssistant={skipMatchAssistant}
      >
        <MatchAssistantStepAlertsProvider matchId={matchId} teamId={teamId}>
          <MatchAssistant />
        </MatchAssistantStepAlertsProvider>
      </MatchAssistantProvider>
    )
  }

  return null
}
