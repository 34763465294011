import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTabs, NxTab } from '@playvs-inc/nexus-components'

import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

export const MatchTabs: React.FC = () => {
  const { handleTabChange, tab, metaseasons } = useManageTeamsContext()

  const metaseasonTabs = metaseasons.map((ms, index) => ({
    label: ms?.name ?? '',
    id: ms?.id ?? '',
    value: index,
    'data-testid': 'MatchHeader_Tab',
  }))

  return (
    <>
      <Box ml={2} py={1.5}>
        <NxTabs
          data-testid="MatchHeader_TabGroup"
          onChange={handleTabChange}
          value={tab}
        >
          {metaseasonTabs.map(({ id, label, value }) => {
            return (
              <NxTab
                key={id}
                data-testid="MatchHeader_Tab"
                id={id}
                label={label}
                size="small"
                value={value}
              />
            )
          })}
        </NxTabs>
      </Box>
    </>
  )
}
