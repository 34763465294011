import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import React from 'react'

import { UserCluster } from '../../cluster'
import { PillButton } from './PillButton'
import { CategoryInterface, TeamStatRow, TopPerformers } from './TopPerformers'

const TeamPillButton: React.FC<{
  onClick(): void
  row: TeamStatRow
  selected?: boolean
  subtitle: string
  value: string
}> = ({ onClick, row, selected, subtitle, value }) => (
  <PillButton
    avatarUrl={row.team?.school?.logoUrl}
    onClick={onClick}
    selected={selected}
    subtitle={subtitle}
    title={row.team?.name}
    value={value}
  />
)

export const TeamTopPerformers: React.FC<{
  categories: CategoryInterface[] // this is the categories for an esport
  data: TeamStatRow[] // this would be a row of stats
}> = ({ categories, data }) => {
  return (
    <TopPerformers
      categories={categories}
      data={data}
      entityColumn={{
        Header: 'Team',
        Cell: ({ team }): React.ReactElement => (
          <UserCluster
            avatarSize={NxAvatarSize.SM}
            avatarUrl={team?.school?.logoUrl}
            id={team?.id}
            pb={1.5}
            pt={1.5}
            subtitle={team?.school?.name}
            title={team?.name}
          />
        ),
        minWidth: 266,
      }}
      PillButtonComponent={TeamPillButton}
    />
  )
}
