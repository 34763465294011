import React from 'react'
import { NxUserCluster } from '@playvs-inc/nexus-components'
import { Column } from 'react-table-v6'

export type TableRowProps = {
  row: { _index: number }
}

export type FormatFn = (input: any) => string

export interface TableColumn<T> extends Column<T> {
  minWidth?: number
  maxWidth?: number
  accessor: string
  Cell?(props: T & TableRowProps): React.ReactElement | null
  format?: FormatFn
  Header: React.ReactNode | string | null
  HeaderTooltip?: string
}

export interface UserClusterColumnProps {
  player: {
    id: string
    name: string | null
    username: string | null
    avatarUrl: string | null
    ringColor: string | null | undefined
  }
}

export const UserClusterColumn: TableColumn<UserClusterColumnProps> = {
  minWidth: 226,
  maxWidth: 300,
  accessor: 'name',
  Cell: ({ player }) => {
    const { id, name, username, avatarUrl } = player
    return (
      <NxUserCluster
        avatarHashId={id}
        avatarUrl={avatarUrl ?? ''}
        subtitles={[{ title: username }]}
        title={name ?? ''}
      />
    )
  },
  Header: 'Name',
}
