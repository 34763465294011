import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { Epicgames } from '@playvs-inc/nexus-icons'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'
import { PillVariants } from '../../NotificationPill'

export const SelectEpicGamesPlatformAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Select Platform for Account',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SystemMessage
        IconComponent={<Epicgames height={64} width={64} />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        pillText="Critical Match Issue"
        pillVariant={PillVariants.Warning}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
