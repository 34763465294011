import { Dispatch, SetStateAction, useState } from 'react'

import { EsportSlug } from '@plvs/graphql/types'
import {
  CancelMatchFirstStep,
  CancelMatchStep,
} from '@plvs/rally/containers/match'

import {
  CompetitionGroup,
  useGetMatchDataForActionAndInfoSectionQuery,
} from '@plvs/graphql/generated'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { ActionAndInfoMatch } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.types'
import { normalizeActionAndInfoMatch } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.helpers'
import {
  DisabledRescheduleReason,
  canMatchBeRescheduled,
} from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'

interface UseActionAndInfoSectionReturn {
  match: ActionAndInfoMatch
  rulebookArticleId: string
  matchInstructionsArticleId: string
  showRescheduleItem: boolean
  showViewRescheduleItem: boolean
  isRescheduleDialogOpen: boolean
  setIsRescheduleDialogOpen: Dispatch<SetStateAction<boolean>>
  step: string
  setStep: Dispatch<SetStateAction<string>>
  isForfeitDialogOpen: boolean
  setIsForfeitDialogOpen: Dispatch<SetStateAction<boolean>>
  forfeitStep: CancelMatchStep
  setForfeitStep: Dispatch<SetStateAction<CancelMatchStep>>
  competitionGroup: CompetitionGroup
  esportSlug: EsportSlug
  matchId: string
  loading: boolean
}

export function useActionAndInfoSection({
  esportSlug,
  matchId,
  competitionGroup,
}): UseActionAndInfoSectionReturn {
  const [isRescheduleDialogOpen, setIsRescheduleDialogOpen] = useState(false)
  const [step, setStep] = useState<string>('requested-reason')
  const [isForfeitDialogOpen, setIsForfeitDialogOpen] = useState(false)
  const [forfeitStep, setForfeitStep] = useState<CancelMatchStep>(
    CancelMatchFirstStep
  )

  const { data, loading } = useGetMatchDataForActionAndInfoSectionQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const match = normalizeActionAndInfoMatch(data?.match)

  const { canReschedule, disableReason } = canMatchBeRescheduled(match)
  const showViewRescheduleItem = disableReason === DisabledRescheduleReason.Open
  const showRescheduleItem = canReschedule

  const {
    rulebookArticleId,
    matchInstructionsArticleId,
  } = useGeneralEsportAdapter(esportSlug)

  return {
    rulebookArticleId,
    matchInstructionsArticleId,
    showRescheduleItem,
    showViewRescheduleItem,
    isRescheduleDialogOpen,
    setIsRescheduleDialogOpen,
    step,
    setStep,
    isForfeitDialogOpen,
    setIsForfeitDialogOpen,
    forfeitStep,
    setForfeitStep,
    competitionGroup,
    esportSlug,
    matchId,
    loading,
    match,
  }
}
