import React from 'react'
import { useParams } from 'react-router-dom'
import AccountChildSettings from '@plvs/rally/features/account/AccountChildSettings'
import { NxTextLink } from '@playvs-inc/nexus-components'
import { ParentBanner } from '@plvs/rally/features/home/parentDashboard/ParentBanner'
import { Box, PageContentGutter } from '@plvs/respawn/features/layout'
import { ChildPlatformConnections } from '@plvs/rally/features/account/childPlatformConnections/ChildPlatformConnections'
import { AppPage } from '../page'

export const ChildDetailsPage: React.FC = () => {
  const params = useParams<{ userId: string }>()

  const { userId } = params

  return (
    <AppPage title="Child Details">
      <ParentBanner />
      <PageContentGutter>
        <AccountChildSettings userId={userId ?? ''} />
        <ChildPlatformConnections userId={userId ?? ''} />
        <Box ml={2} mt={2}>
          <NxTextLink
            href="https://playvs.com/privacy"
            label="See how your child’s data is used by viewing our privacy policy."
            labelVariant="body1"
            target="_blank"
          />
        </Box>
      </PageContentGutter>
    </AppPage>
  )
}
