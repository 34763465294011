import React from 'react'
import { makeStyles, Grid, Box, Hidden } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { BoxProps } from '@plvs/respawn/features/layout'
import { LoginSideBanner } from './LoginSideBanner'
import { CreateAccountCallout } from './CreateAccountCallout'

interface CreateAccountProps {
  children: React.ReactNode
  title: string
  childrenContainerProps?: BoxProps
  SidebarContent: React.ReactNode
  Logo: React.ReactNode
  bannerImageSrc: string
  // Defaults to 'corner'
  logoPosition?: 'corner' | 'center'
}

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  title: {
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 600,
    height: '100%',
  },
  content: {
    height: 'calc(100vh - 240px)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      height: 584,
      paddingTop: theme.spacing(9),
    },
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'max-content',
      padding: theme.spacing(5, 0, 2),
    },
  },
}))

export const CreateAccount = ({
  children,
  title,
  childrenContainerProps,
  SidebarContent,
  Logo,
  bannerImageSrc,
  logoPosition = 'corner',
}: CreateAccountProps): React.ReactElement => {
  const classes = useStyles()

  const sideBar = (
    <LoginSideBanner height="100%" src={bannerImageSrc}>
      <Box className={classes.benefitsContainer}>{SidebarContent}</Box>
    </LoginSideBanner>
  )

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Grid className={classes.gridContainer} container>
        <Hidden smDown>
          <Grid item md={6}>
            {sideBar}
          </Grid>
        </Hidden>
        <Grid className={classes.formContainer} item md={6} xs={12}>
          {logoPosition === 'corner' && Logo}

          <Box className={classes.content}>
            {logoPosition === 'center' && (
              <Box alignSelf="center" mb={7}>
                {Logo}
              </Box>
            )}
            <CreateAccountCallout />
            <NxTypography className={classes.title} variant="display2">
              {title}
            </NxTypography>
            <Box {...childrenContainerProps}>{children}</Box>
          </Box>
        </Grid>
      </Grid>
      <Hidden mdUp>{sideBar}</Hidden>
    </Box>
  )
}
