import React from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { Path } from '@plvs/const'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import {
  ConnectedAccountsDarkLargePrimary,
  ConnectedAccountsLightLargePrimary,
} from '@playvs-inc/nexus-spots'
import { useNavigate } from 'react-router'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    background: theme.palette.ColorBackgroundBase,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(3),
    maxHeight: '132px',
    maxWidth: '1042px',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      maxHeight: '293px',
      maxWidth: '100%',
    },
    boxShadow: theme.mixins.boxShadow.elevation1,
  },
  textContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '8px',
    height: '56px',
    justifyContent: 'stretch',
    maxWidth: '678px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      height: '77px',
      maxWidth: '310px',
    },
  },
  subtitle: {
    height: '21px',
    maxWidth: '559px',
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  iconContainer: {
    display: 'flex',
    height: '100px',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    marginBottom: '0px',
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      marginBottom: theme.spacing(3),
    },
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '36px',
    justifyContent: 'center',
    marginTop: '0px',
    marginLeft: theme.spacing(3),
    maxWidth: '175px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginLeft: '0px',
    },
  },
}))

export const MissingEsportsConnectedAccount: React.FC = () => {
  const navigate = useNavigate()
  const onGoToConnect = async (): Promise<void> => {
    navigate(Path.ConnectionSettings)
  }
  const theme = useTheme()
  const classes = useStyles()

  const icon =
    theme.palette.type !== 'dark' ? (
      <ConnectedAccountsLightLargePrimary />
    ) : (
      <ConnectedAccountsDarkLargePrimary />
    )

  const title = (
    <NxTypography
      data-testid="missing-esports-connected-account_title"
      variant="h4"
    >
      Connect your game accounts
    </NxTypography>
  )
  const subTitle = (
    <NxTypography
      className={classes.subtitle}
      data-testid="missing-esports-connected-account_subtitle"
      variant="body1"
    >
      Get connected to play! Go over to the settings to connect your game
      accounts to PlayVS
    </NxTypography>
  )

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.textContainer}>
        {title}
        {subTitle}
      </Box>
      <Box className={classes.buttonContainer}>
        <NxButton
          data-testid="missing-esports-connected-account_button"
          label="Connect Accounts"
          onClick={onGoToConnect}
          variant="primary"
        />
      </Box>
    </Box>
  )
}
