import { head, map } from 'ramda'

import { Param } from '@plvs/const'
import { Metaseason } from '@plvs/graphql'
import { sortByMaybeEndsAt, getTime } from '@plvs/utils'
import { useOption } from './useOption'
import { Option } from './filterHelpers'

export enum SeasonTiming {
  Placeholder = 'placeholder',
  Past = 'past',
  Future = 'future',
  Current = 'current',
}

export type FilterMetaseason = Pick<
  Metaseason,
  'id' | 'name' | 'startsAt' | 'endsAt' | 'isInitialMetaseason'
>

export function getSeasonTimingLabel({
  startsAt,
  endsAt,
}: {
  startsAt?: string | null
  endsAt?: string | null
}): SeasonTiming {
  const today = getTime()
  const startsBeforeNow = getTime(startsAt) < today
  const endsAfterNow = getTime(endsAt) > today
  if (startsBeforeNow && endsAfterNow) return SeasonTiming.Current
  if (!startsBeforeNow && endsAfterNow) return SeasonTiming.Future
  return SeasonTiming.Past
}

export function createMetaseasonOptions<T extends FilterMetaseason>(
  metaseasons: T[]
): {
  defaultOption?: Option<T>
  allOptions: Option<T>[]
} {
  const sortedMetaseasons = sortByMaybeEndsAt(metaseasons).reverse()
  const nextSeasons: Option<T>[] = []
  const currentSeasons: Option<T>[] = []
  const pastSeasons: Option<T>[] = []
  const allOptions = map((metaseason) => {
    const option = {
      id: metaseason.id,
      name: metaseason.name || 'N/A',
      values: [metaseason],
    }

    if (getSeasonTimingLabel(metaseason) === SeasonTiming.Future) {
      nextSeasons.push(option)
    }

    if (getSeasonTimingLabel(metaseason) === SeasonTiming.Current) {
      currentSeasons.push(option)
    }

    if (getSeasonTimingLabel(metaseason) === SeasonTiming.Past) {
      pastSeasons.push(option)
    }

    return option
  }, sortedMetaseasons)

  const defaultOption =
    head(currentSeasons) || head(nextSeasons) || head(pastSeasons)

  return {
    defaultOption,
    allOptions,
  }
}

export function useMetaseasonOption<T extends FilterMetaseason>(
  metaseasons: T[]
): {
  metaseasonOption: Option<T>
  metaseasonOptions: Option<T>[]
  setMetaseasonOption(input: Option<T>): void
} {
  const { defaultOption, allOptions } = createMetaseasonOptions(metaseasons)
  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption,
    queryParam: Param.Season,
  })

  return {
    metaseasonOption: option,
    metaseasonOptions: options,
    setMetaseasonOption: setOption,
  }
}
