import React, { memo } from 'react'
import { Box, useBreakpointMd } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { CreateCSSProperties } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { Player } from './helper'

const useStyles = makeStyles({
  player: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  playerIcon: ({
    isPlayerProfileEnabled,
  }: {
    isPlayerProfileEnabled: boolean
  }): CreateCSSProperties => ({
    cursor: isPlayerProfileEnabled ? 'pointer' : 'auto',
  }),
})

interface Props {
  isPlayerProfileEnabled: boolean
  player: Player
}

export const PlayerRow: React.FC<Props> = memo(
  ({ isPlayerProfileEnabled, player }) => {
    const classes = useStyles({ isPlayerProfileEnabled })

    const navigate = useNavigate()

    const isMobile = useBreakpointMd()

    return (
      <Box
        className={classes.player}
        data-testid="SchoolRoster_Players_PlayerEntry"
      >
        <Box
          data-testid="avatar-wrapper"
          minHeight={40}
          minWidth={40}
          onClick={
            isPlayerProfileEnabled
              ? (): void => navigate(`${Path.App}${Path.Profile}/${player.id}`)
              : undefined
          }
        >
          <NxUserAvatar
            avatarUrl={player?.avatarUrl}
            className={classes.playerIcon}
            hashId={player?.id ?? ''}
            size={NxAvatarSize.SM}
          />
        </Box>
        <Box pl={2}>
          <NxTypography variant={isMobile ? 'subtitle1' : 'body2'}>
            {player?.name}
          </NxTypography>
        </Box>
      </Box>
    )
  }
)
