import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { CSSProperties } from 'react'

import { rup } from '@plvs/utils'

const useStyles = makeStyles((theme) => ({
  fillHeight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
  },
  xSize: {
    ...rup(theme as any, {
      marginLeft: [16, 16],
      marginRight: [16, 16],
    }),
  },
  ySizeSmall: {
    ...rup(theme as any, {
      marginBottom: [8, 16],
      marginTop: [8, 8],
    }),
  },
  ySizeMedium: {
    ...rup(theme as any, {
      marginBottom: [16, 24],
      marginTop: [16, 16],
    }),
  },
  ySizeLarge: {
    ...rup(theme as any, {
      marginBottom: [16, 32],
      marginTop: [16, 16],
    }),
  },
}))

type Size = 'small' | 'medium' | 'large'

interface Props {
  children: React.ReactNode
  ySize?: Size
  style?: CSSProperties
  className?: string
}

export const PageContentGutter = ({
  children,
  ySize = 'large',
  style,
  className,
}: Props): React.ReactElement => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.fillHeight,
        classes.xSize,
        {
          [classes.ySizeSmall]: ySize === 'small',
          [classes.ySizeMedium]: ySize === 'medium',
          [classes.ySizeLarge]: ySize === 'large',
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}
