import { Box, makeStyles, useTheme } from '@material-ui/core'
import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'
import React from 'react'
import { PlayersMatchUpForMatchAssistant } from './MatchAssistant.types'

const useStyles = makeStyles({
  content: {
    '&:last-child': {
      paddingBottom: 1,
    },
  },
})

interface PlayersMatchUpProps {
  currentPlayersHomeTeam: PlayersMatchUpForMatchAssistant[]
  homeTeamName?: string
  currentPlayersAwayTeam: PlayersMatchUpForMatchAssistant[]
  awayTeamName?: string
}
export const PlayersMatchUp: React.FC<PlayersMatchUpProps> = ({
  currentPlayersHomeTeam,
  currentPlayersAwayTeam,
  homeTeamName,
  awayTeamName,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gridGap={theme.spacing(3)}
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column">
        {homeTeamName && (
          <Box display="flex" justifyContent="right" pb={3} width="100%">
            <NxTypography variant="overline">{homeTeamName}</NxTypography>
          </Box>
        )}
        {currentPlayersHomeTeam.map((player) => {
          return (
            <Box
              key={player.avatarHashId}
              className={classes.content}
              display="flex"
              flexDirection="column"
              pb={3}
            >
              <NxUserCluster
                alignment="right"
                avatarHashId={player.avatarHashId}
                avatarUrl={player.avatarUrl}
                subHeader={player.subHeader}
                subtitles={player.subtitles}
                title={player.title}
              />
            </Box>
          )
        })}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <NxTypography variant="h4">vs</NxTypography>
      </Box>
      <Box display="flex" flexDirection="column">
        {awayTeamName && (
          <Box justifyContent="right" mr="-24px" pb={3} width="100%">
            <NxTypography variant="overline">{awayTeamName}</NxTypography>
          </Box>
        )}
        {currentPlayersAwayTeam.map((player) => {
          return (
            <Box
              key={player.avatarHashId}
              className={classes.content}
              display="flex"
              flexDirection="column"
              pb={3}
            >
              <NxUserCluster
                avatarHashId={player.avatarHashId}
                avatarUrl={player.avatarUrl}
                subHeader={player.subHeader}
                subtitles={player.subtitles}
                title={player.title}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
