import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import {
  ResourcesDarkLargePrimary,
  ResourcesLightLargePrimary,
} from '@playvs-inc/nexus-spots'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { IntercomArticleMappings } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    padding: theme.spacing(2, 0),
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(1),
    boxShadow: theme.shadows[1],
    width: '100%',
    minHeight: '112px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    height: '70px',
  },
  iconContainer: {
    margin: theme.spacing(0, 2),
    height: '70px',
    width: '70px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  text: {
    flexDirection: 'column',
  },
  icon: {
    height: '70px',
    width: '70px',
  },
  header: {
    height: '27px',
  },
  button: {
    height: '36px',
  },
}))

export const CoachHandbook: React.FC = () => {
  const theme = useTheme()

  const showArticle = (): void => {
    showIntercomArticle(IntercomArticleMappings.highSchoolCoachHandbook)
  }

  const classes = useStyles()
  return (
    <Grid className={classes.wrapper} container>
      <Grid className={classes.item} item lg={8} xs={12}>
        <Box className={classes.iconContainer}>
          {theme.palette.type === MUIThemeModeEnum.Light ? (
            <ResourcesLightLargePrimary className={classes.icon} />
          ) : (
            <ResourcesDarkLargePrimary className={classes.icon} />
          )}
        </Box>
        <Box alignItems="flex-start" className={classes.text} display="flex">
          <NxTypography className={classes.header} variant="h4">
            Coach Handbook
          </NxTypography>
          <NxTypography variant="body1">
            A quick guide for new and seasoned coaches.
          </NxTypography>
        </Box>
      </Grid>
      <Grid item lg={3} xs={12}>
        <NxButton
          className={classes.button}
          fullWidth
          label="View"
          onClick={showArticle}
          variant="secondary"
        />
      </Grid>
    </Grid>
  )
}
