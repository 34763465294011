import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { NxTypography } from '@playvs-inc/nexus-components'
import { arrayOfN } from '@plvs/utils'

const useStyles = makeStyles((theme) => ({
  coach: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  details: {
    color: theme.palette.ColorTextDisabled,
  },
}))

const RowSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.coach} data-testid="row-skeleton">
      <Skeleton height={32} variant="circle" width={32} />
      <Box pl={2}>
        <NxTypography variant="h3">
          <Skeleton variant="text" width="8em" />
        </NxTypography>
        <NxTypography className={classes.details} variant="body1">
          <Skeleton variant="text" width="10em" />
        </NxTypography>
      </Box>
    </Box>
  )
}

interface Props {
  numberOfRows: number
}

export const RowsLoading: React.FC<Props> = ({ numberOfRows }) => {
  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {arrayOfN(numberOfRows).map((i: number) => (
        <RowSkeleton key={i} />
      ))}
    </Box>
  )
}
