import {
  CompetitionGroup,
  SeasonStatus,
  SeasonTeamMatchSort,
  useGetNonLoggedInMetaseasonsQuery,
  useGetUserAwardsQuery,
} from '@plvs/graphql'
import React from 'react'

export type AssignedWeeklyAwards = {
  id: string
  startsAt: string
  endsAt: string
  seasonName: string
}

export type AssignedSeasonalAward = {
  id: string
  title: string
  metaseasonName: string
  description: string
}

type PlayerAwardsProviderContextType = {
  assignedWeeklyAwards: AssignedWeeklyAwards[]
  assignedSeasonalAwards: AssignedSeasonalAward[]
  loading: boolean
}

const defaultContextValue = {
  assignedWeeklyAwards: [],
  loading: false,
  assignedSeasonalAwards: [],
}

export const PlayerAwardsProviderContext = React.createContext<
  PlayerAwardsProviderContextType
>(defaultContextValue)

export const usePlayerAwardsProviderContext = (): PlayerAwardsProviderContextType => {
  return React.useContext(PlayerAwardsProviderContext)
}

interface Props {
  userId?: string
}

export const PlayerAwardsProvider: React.FC<Props> = ({ children, userId }) => {
  const {
    data: metaseasonsData,
    loading: loadingMetaseasons,
  } = useGetNonLoggedInMetaseasonsQuery({
    variables: {
      options: {
        filters: {
          competitionGroups: [CompetitionGroup.HighSchool],
          status: SeasonStatus.Active,
        },
        sort: SeasonTeamMatchSort.Asc,
      },
    },
  })
  const metaseasons = metaseasonsData?.metaseasons.map((m) => {
    return {
      id: m.id,
      name: m.name,
      startsAt: new Date(m.startsAt || ''),
      endsAt: new Date(m.endsAt || ''),
    }
  })
  const {
    data: userAwardsData,
    loading: loadingEligibleMetaseasonData,
  } = useGetUserAwardsQuery({
    variables: { userId: userId || '' },
    skip: !userId,
  })
  const assignedWeeklyAwards = (
    userAwardsData?.user?.assignedWeeklyAwards ?? []
  ).map((award) => {
    const awardStartDate = new Date(award?.awardWeekOption.startDate || '')
    const metaseason = metaseasons?.find(
      (m) => awardStartDate >= m.startsAt && awardStartDate < m.endsAt
    )
    return {
      id: award?.id || '',
      startsAt: award?.awardWeekOption.startDate || '',
      endsAt: award?.awardWeekOption.endDate || '',
      seasonName: metaseason?.name || '',
    }
  })

  const seasonalAwards = userAwardsData?.user?.assignedSeasonalAwards ?? []
  const assignedSeasonalAwards = seasonalAwards.map((award) => {
    return {
      id: award?.id ?? '',
      title: award?.awardType?.title ?? '',
      metaseasonName:
        metaseasons?.find((m) => m?.id === award?.metaseasonId)?.name ?? '',
      description: award?.awardType?.description ?? '',
    }
  })

  return (
    <PlayerAwardsProviderContext.Provider
      value={{
        loading: loadingEligibleMetaseasonData || loadingMetaseasons,
        assignedSeasonalAwards,
        assignedWeeklyAwards,
      }}
    >
      {children}
    </PlayerAwardsProviderContext.Provider>
  )
}
