import React, { useState } from 'react'

import { Opponentforfeit } from '@playvs-inc/nexus-icons'
import {
  NxIconButton,
  NxButton,
  NxModal,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { ForfeitReason, useForfeitMatchMutation } from '@plvs/graphql/generated'
import { makeStyles, useTheme } from '@material-ui/core'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { getForfeitText } from '@plvs/utils'

const useStyles = makeStyles(() => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

interface OpponentForfeitButtonProps {
  matchId: string
  teamId: string
  setShowForfeitSuccessBanner(showForfeitSuccessBanner: boolean): void
}

export const OpponentForfeitButton: React.FC<OpponentForfeitButtonProps> = ({
  matchId,
  teamId,
  setShowForfeitSuccessBanner,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const [showForfeit, setShowForfeit] = useState(false)
  const [forfeitMatchMutation] = useForfeitMatchMutation()

  const openForfeitDialog = (): void => {
    setShowForfeit(true)
  }

  const closeForfeitDialog = (): void => {
    setShowForfeit(false)
  }

  const onClickSubmitForfeit = async (): Promise<void> => {
    setShowForfeitSuccessBanner(true)
    setShowForfeit(false)
    await forfeitMatchMutation({
      variables: {
        matchId,
        teamId,
        reason: ForfeitReason.NoShow,
      },
    })
  }

  const {
    match: { canQueue },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  return (
    <>
      <NxIconButton
        className={styles.nowrap}
        data-cy="opponentForfeitButton"
        icon={<Opponentforfeit color={theme.palette.ColorTextAlt} />}
        label="Opponent Forfeit"
        onClick={openForfeitDialog}
        variant="text"
      />
      <NxModal
        actions={
          <>
            <NxButton
              label="Cancel"
              onClick={closeForfeitDialog}
              variant="text"
            />
            <NxButton
              label="Submit"
              onClick={onClickSubmitForfeit}
              variant="primary"
            />
          </>
        }
        onClose={closeForfeitDialog}
        open={showForfeit}
        showTopRightClose
        size="small"
        title="Opponent Forfeit"
      >
        <NxTypography variant="body1">{getForfeitText(canQueue)}</NxTypography>
      </NxModal>
    </>
  )
}
