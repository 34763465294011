import React from 'react'

import { PublicPage } from './page'

const HealthCheckPage: React.FC = () => (
  <PublicPage description="HealthCheck" title="HealthCheck">
    <p>success</p>
  </PublicPage>
)

export default HealthCheckPage
