import { getKey } from './initSnapchat'
import { makeSafeFunction } from '../utils'

const snapchat = makeSafeFunction(['snaptr'])

// REFERENCE
// https://businesshelp.snapchat.com/en-US/article/snap-pixel-about

// CHROME EXTENSION
// https://chrome.google.com/webstore/detail/snap-pixel-helper/hnlbfcoodjpconffdbddfglilhkhpdnf?hl=en

// NOTE
// 1. user data can ONLY be passed on the init call
// 2. PAGE_VIEW must be fired right after the init call in order to "activate" tracking

export const register = ({ email }: { email: string }): void => {
  snapchat('init', getKey(), { user_email: email })
  snapchat('track', 'PAGE_VIEW')
  snapchat('track', 'SIGN_UP')
}
