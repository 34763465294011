import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const LogoWordMark = createSvgIcon(
  <g data-name="Logo">
    <path d="M354.73 58.17h62.87c33.36 0 57.74 24.38 57.74 55.17S451 168.51 417.6 168.51h-25.66v51.32h-37.21zm62.87 74.42c11.55 0 20.53-9 20.53-19.25s-9-19.25-20.53-19.25h-25.66v38.5zM489.24 58.17h34.64v161.66h-34.64zM630.19 208.28a56.85 56.85 0 01-9 6.42 49.22 49.22 0 01-24.37 6.41c-29.51 0-55.17-25.4-55.17-60.3s25.66-60.3 55.17-60.3a52 52 0 0124.37 6.41 56.85 56.85 0 019 6.42l5.13-11.55H661v118h-25.68zM602 189c14.37 0 25.66-11.55 25.66-28.23s-11.33-28.18-25.66-28.18-25.66 11.54-25.66 28.22S587.59 189 602 189zM691.94 231.38c14.38 0 20.53-6.42 24.38-16.68l-44.9-112.91h37.21l25.66 73.14 25.65-73.14h37.21l-47.47 118c-12.06 29.51-24.38 42.34-46.19 42.34a70 70 0 01-7.7-.51l-3.85-.77zM794.5 58.17H833l35.93 111.62 35.91-111.62h38.5l-55.18 161.66h-38.49zM981.15 165.94c0 14.63 7.7 21.82 23.09 21.82 14.37 0 21.81-7.7 21.81-16.69 0-24.37-79.54-10.26-79.54-68 0-24.37 21.81-46.18 57.73-46.18 34.65 0 56.46 21.81 56.46 51.32l-35.93 2.56c0-13.08-7.44-20.52-20.53-20.52-11.55 0-19.24 7.44-19.24 15.39 0 23.09 79.55 9 79.55 68 0 25.66-21.81 47.47-60.31 47.47-37.21 0-59-21.81-59-52.6z" />
    <path
      d="M217.28 139.13L280 1.28 139.13 58l.1-58A139 139 0 00.14 145.31C3.29 216.52 61 274.42 132.19 277.82A139 139 0 00278 139.23zM146 192.93h-19.23V166h-19.18v-26.9H146zm-56.74 0H70V166H50.85v-26.9h38.37z"
      fill="#ff8100"
    />
  </g>,
  'PlayVS logo',
  { viewBox: '0 0 1064.55 277.98' }
)
