import { useEffect } from 'react'

/**
 * Changes the document favicon and restores previous favicon on unmount.
 *
 * @param faviconUrl Source for the favicon
 */
export function useFavicon(faviconUrl?: string): void {
  function changeFavicon(href?: string): void {
    if (!href) {
      return
    }

    let link: HTMLLinkElement | null = document.querySelector(
      "link[rel~='icon']"
    )
    if (!link) {
      link = document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'icon'
      document.head.appendChild(link)
    }

    link.href = href
  }

  useEffect(
    function updateFavicon() {
      changeFavicon(faviconUrl)
    },
    [faviconUrl]
  )
}
