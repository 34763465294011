import dayjs from 'dayjs'
import { head } from 'ramda'
import {
  League,
  Esport,
  Team,
  Phase,
  MyLeagueFiltersQueryResult,
  CompetitionModel,
  TeamLeaguesFiltersQueryResult,
  PhaseStatus,
  SchoolLeaguesFiltersQueryResult,
} from '@plvs/graphql'
import {
  RelativeTiming,
  MinimalLeague,
  mapMetaseasonTimingToRelative,
  MinimalMetaseason,
  MetaseasonArg,
} from '@plvs/utils'
import {
  MinimalTeam,
  MinimalPhase,
  SetDefaultLeague,
} from '@plvs/respawn/features/filters/types'
import { LeagueMetadata, SeasonMetadata } from './myLeaguesTypes'

export type LeagueArg = Pick<
  League,
  'id' | 'name' | 'competitionModel' | 'displayName'
> & {
  esport: Pick<Esport, 'id' | 'slug' | 'name' | 'esportGroupId' | 'rating'>
}

export type TeamArg = Pick<Team, 'id' | 'name'> & {
  esport: Pick<Esport, 'id' | 'slug' | 'name'>
}

export type PhaseArg = Pick<
  Phase,
  | 'id'
  | 'startsAt'
  | 'endsAt'
  | 'name'
  | 'type'
  | 'seasonId'
  | 'status'
  | 'format'
  | 'survivalAdvancementCount'
  | 'survivalAdvancementPercentage'
>

export const getDefaultSetMetaseason = (
  metaseasons: MinimalMetaseason[]
): MinimalMetaseason | undefined =>
  metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Past)

export const mapMetaseasonTiming = (
  metaseasons: MetaseasonArg[]
): MinimalMetaseason[] =>
  metaseasons
    .map(mapMetaseasonTimingToRelative)
    .sort((a, b) => dayjs(a?.startsAt).unix() - dayjs(b?.startsAt).unix())

export const mapLeagueToMinimalLeague = (league: LeagueArg): MinimalLeague => {
  return {
    ...league,
    name: league.name ?? 'n/a',
  }
}

export const mapTeamToMinimalTeam = (team: TeamArg): MinimalTeam => {
  return {
    ...team,
    name: team.name ?? 'n/a',
  }
}

export const mapToMinimalPhase = (phase: PhaseArg): MinimalPhase => {
  return {
    ...phase,
    name: phase.name ?? 'n/a',
  }
}

export const defaultSetLeague: SetDefaultLeague<
  | MyLeagueFiltersQueryResult
  | TeamLeaguesFiltersQueryResult
  | SchoolLeaguesFiltersQueryResult
> = (leagues, data, isCoach) =>
  isCoach
    ? head(leagues)
    : leagues.find(
        (league) => league.competitionModel === CompetitionModel.Rec
      ) ?? head(leagues)

const getPhaseStatusValue = (phase: MinimalPhase): number => {
  return phase.status === PhaseStatus.Active ? 1 : -1
}

export const sortPhaseByActiveThenStartsAt = (
  phases: MinimalPhase[]
): MinimalPhase[] => {
  return phases.sort((a: MinimalPhase, b: MinimalPhase) => {
    if (a.status === b.status) {
      return dayjs(b.startsAt).diff(b.startsAt, 'millisecond')
    }

    return getPhaseStatusValue(b) - getPhaseStatusValue(a)
  })
}

/**
 * Gets a user's default season by:
 * 1. Season ID stored in reducer state
 * 2. Metaseason user selects in dropdown
 * 3. Current season school is competing in
 * 4. Past season school competed in
 * 5. Current season by date
 */
export const getDefaultSeason = ({
  league,
  seasons,
  seasonId,
  metaseasonId,
}: {
  league: LeagueMetadata
  seasons: SeasonMetadata[]
  seasonId?: string
  metaseasonId?: string
}): SeasonMetadata | undefined => {
  const now = dayjs()

  // 1
  const selectedSeason = league?.seasons?.find((s) => s.id === seasonId)
  if (selectedSeason) {
    return selectedSeason
  }

  // 2
  const seletedMetaseasonSeason = league?.seasons?.find(
    (season) => season?.metaseason?.id === metaseasonId
  )
  if (seletedMetaseasonSeason) {
    return seletedMetaseasonSeason
  }

  // 3
  const activeSeasonPhaseHasMatches = seasons.find(
    (s) => dayjs(s.startsAt).isAfter(now) && s.phases?.find((p) => p.hasMatches)
  )
  if (activeSeasonPhaseHasMatches) {
    return activeSeasonPhaseHasMatches
  }

  // 4
  const nonActiveSeasons = seasons.filter((s) =>
    dayjs(s.startsAt).isBefore(now)
  )
  const nonActiveSeasonPhaseHasMatches = nonActiveSeasons.find((s) => {
    const phases = s?.phases ?? []
    return phases?.find((p) => p.hasMatches)
  })
  if (nonActiveSeasonPhaseHasMatches) {
    return nonActiveSeasonPhaseHasMatches
  }

  // 5
  return seasons.find((s) => dayjs(s.startsAt).isAfter(now))
}
