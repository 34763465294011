import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { DialogContentProps as MuiDialogContentProps } from '@material-ui/core/DialogContent'
import React from 'react'

import { FlexSpacer } from '@plvs/respawn/features/layout'
import { DialogStepCountProps } from './DialogStepCount'
import { DialogHeader } from './DialogHeader'

type Props = DialogProps &
  DialogStepCountProps & {
    banner?: React.ReactElement
    buttons?: React.ReactNode
    DialogContentProps?: MuiDialogContentProps
    image?: React.ReactElement
    onClose(): void
    showCancelButton?: boolean
    showCloseButton?: boolean
    showDoneButton?: boolean
    subtitle?: React.ReactChild
    title?: string
    width?: string | number
  }

export const Dialog: React.FC<Props> = ({
  banner,
  buttons,
  children,
  currentStepIndex,
  DialogContentProps,
  image,
  onClose,
  showCancelButton,
  showCloseButton,
  showDoneButton,
  stepCount,
  subtitle,
  title,
  width,
  ...rest
}) => {
  return (
    <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
      // capture all keypresses in the dialog so that they don't do wierd things
      //  like, for instance, interacting with the MenuItem that opened the
      //  Dialog in the first place
      onKeyDown={(e): void => e.stopPropagation()}
      role="dialog"
    >
      <MuiDialog
        onClose={onClose}
        PaperProps={{ style: width ? { width } : {} }}
        {...rest}
      >
        {image}
        {/* Banners get displayed at the top of the dialog. */}
        {banner && <DialogTitle disableTypography>{banner}</DialogTitle>}
        <DialogHeader
          currentStepIndex={currentStepIndex}
          stepCount={stepCount}
          subtitle={subtitle}
          title={title}
        />
        <DialogContent {...DialogContentProps}>{children}</DialogContent>
        {(showCancelButton || showCloseButton || showDoneButton || buttons) && (
          <DialogActions>
            {showCancelButton && (
              <Button aria-label="cancel" onClick={onClose} variant="contained">
                Cancel
              </Button>
            )}
            {showCloseButton && (
              <Button aria-label="close" onClick={onClose} variant="contained">
                Close
              </Button>
            )}
            {(showCancelButton || showCloseButton || showDoneButton) && (
              <FlexSpacer />
            )}
            {showDoneButton && (
              <Button
                aria-label="done"
                color="primary"
                onClick={onClose}
                variant="contained"
              >
                Done
              </Button>
            )}
            {buttons}
          </DialogActions>
        )}
      </MuiDialog>
    </div>
  )
}
