import React from 'react'
import { GameHighlight, Match, MatchTeam, Maybe } from '@plvs/graphql/generated'
import { sanitizeString, yupString } from '@plvs/utils'
import {
  VideoDarkPrimaryLarge,
  VideoDarkSecondaryLarge,
  VideoLightPrimaryLarge,
  VideoLightSecondaryLarge,
} from '@playvs-inc/nexus-spots'
import * as yup from 'yup'

export type HighlightType = Maybe<
  Pick<
    GameHighlight,
    | 'id'
    | 'title'
    | 'videoUrl'
    | 'userId'
    | 'matchId'
    | 'source'
    | 'createdAt'
    | 'thumbnailUrl'
  > & {
    match: Maybe<
      Pick<Match, 'id' | 'scheduledStartsAt'> & {
        team1: Maybe<Pick<MatchTeam, 'id' | 'name'>>
        team2: Maybe<Pick<MatchTeam, 'id' | 'name'>>
      }
    >
  }
>

export const VIDEO_HEIGHT = 144
export const VIDEO_WIDTH = 256

export const getEmptyStateProps = (
  isMyProfile: boolean,
  isDarkMode: boolean
): { title: string; subtitle: string; spot: React.ReactNode } => {
  if (isMyProfile) {
    return {
      title: 'Showcase your Thrilling Esports Moments',
      subtitle:
        'Elevate your esports experience by sharing your top moments from competitions.',
      spot: isDarkMode ? (
        <VideoDarkPrimaryLarge height={200} width={200} />
      ) : (
        <VideoLightPrimaryLarge height={200} width={200} />
      ),
    }
  }

  return {
    title: 'No Player Highlights Available',
    subtitle:
      "No highlights are currently available for this player. To view more highlights, you can check out other players' profiles.",
    spot: isDarkMode ? (
      <VideoDarkSecondaryLarge height={200} width={200} />
    ) : (
      <VideoLightSecondaryLarge height={200} width={200} />
    ),
  }
}

export interface SubmitVideoInput {
  videoUrl: string
  title: string
  matchId: string
}

export const getSubmitVideoSchema = (isEditMode: boolean): any =>
  yup.object().shape({
    matchId: yupString,
    title: yupString
      .required('Required')
      .test(
        'validTitle',
        'The title contains inappropriate language.',
        (value: string): boolean => {
          return !sanitizeString(value).isProfane
        }
      ),
    ...(!isEditMode && {
      videoUrl: yupString
        .required('Required')
        .test(
          'validTwirchOrYoutubeUrl',
          'Please check the content of this field and try again.',
          (value: string): boolean => {
            return (
              value.includes('twitch.tv') ||
              value.includes('youtube') ||
              value.includes('youtu.be')
            )
          }
        ),
    }),
  })

export const getCreateVideoError = (error: string | undefined): string => {
  if (!error) {
    return ''
  }

  if (error.includes('player has not connected to twitch in playvs')) {
    return 'The video url must be created by or uploaded to one of your connected accounts.'
  }

  if (error.includes('match not found for player')) {
    return 'match not found for player'
  }

  return error
}
