import React from 'react'
import { MenuItem, NxDropdownButton } from '@playvs-inc/nexus-components'
import { Opponentforfeit, WarningOutlined } from '@playvs-inc/nexus-icons'
import { EsportSlug } from '@plvs/graphql/types'
import { MatchAssistantAlertType, StepType } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useMatchAssistantContext } from './useMatchAssistant'
import { ResetOption, useResetMatchAssistant } from './hooks'
import { useOpponentForfeit } from '../dynamic/buttons/useOpponentForfeit'
import { useMatchAssistantStepAlertsContext } from './hooks/useMatchAssistantStepAlerts'

export const MatchAssistantActions: React.FC = () => {
  const {
    isInCheckpoint,
    gameOrdinal,
    seriesOrdinal,
    refetchCurrentGameAssistantStepByMatchId,
    matchId,
    step,
    currentStep,
    esportSlug,
    opposingTeamId,
  } = useMatchAssistantContext()

  const {
    match: { canManageMatch, canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const isMatchComplete = currentStep?.matchDetails?.isComplete ?? false
  const { setResetOption, ResetMatchAssistantModal } = useResetMatchAssistant({
    matchId,
    gameOrdinal,
    seriesOrdinal,
    refetchCurrentGameAssistantStepByMatchId,
  })
  const { ReactForfeitModal, openForfeitDialog } = useOpponentForfeit({
    matchId,
    teamId: opposingTeamId,
  })

  const { openStepAlert } = useMatchAssistantStepAlertsContext()

  const menuItems: MenuItem[] = []

  if (canManageMatch && !isInCheckpoint) {
    menuItems.push({
      id: 'opponent-forfeit',
      label: 'Opponent Forfeit',
      onClick: openForfeitDialog,
      Icon: Opponentforfeit,
    })
    menuItems.push({
      id: 'reset-game-coach',
      label:
        esportSlug === EsportSlug.SmashBrosUltimate
          ? 'Reset Game'
          : 'Reset Game / Change Roster',
      onClick: (): void => openStepAlert(MatchAssistantAlertType.Reset),
      Icon: WarningOutlined,
      disabled:
        step === StepType.RosterSelect ||
        step === StepType.ReadyUp ||
        isMatchComplete,
    })
    menuItems.push({
      id: 'skip-assistant-coach',
      label: 'Skip Assistant',
      onClick: (): void => openStepAlert(MatchAssistantAlertType.Skip),
      Icon: WarningOutlined,
      disabled: isMatchComplete,
    })
  }

  if (isInCheckpoint) {
    menuItems.push({
      id: 'reset-series',
      label: 'Reset Current Series',
      onClick: (): void => setResetOption(ResetOption.Series),
    })

    menuItems.push({
      id: 'reset-match',
      label: 'Reset Match',
      onClick: (): void => setResetOption(ResetOption.Match),
    })

    menuItems.push({
      id: 'reset-game',
      label: 'Reset Current Game',
      onClick: (): void => setResetOption(ResetOption.Game),
    })
    menuItems.push({
      id: 'skip-assistant-coach',
      label: 'Skip Assistant',
      onClick: (): void => openStepAlert(MatchAssistantAlertType.Skip),
      Icon: WarningOutlined,
      disabled: isMatchComplete,
    })
  }

  if (canParticipate && !canManageMatch) {
    menuItems.push({
      id: 'reset-game-player',
      label:
        esportSlug === EsportSlug.SmashBrosUltimate
          ? 'Reset Game'
          : 'Reset Game / Change Roster',
      onClick: (): void => openStepAlert(MatchAssistantAlertType.Reset),
      Icon: WarningOutlined,
      disabled:
        step === StepType.RosterSelect ||
        step === StepType.ReadyUp ||
        isMatchComplete,
    })
    menuItems.push({
      id: 'skip-assistant-player',
      label: 'Skip Assistant',
      onClick: (): void => openStepAlert(MatchAssistantAlertType.Skip),
      Icon: WarningOutlined,
      disabled: isMatchComplete,
    })
  }

  return (
    <>
      <NxDropdownButton
        label="Game Settings"
        menuItems={[{ items: menuItems }]}
        variant="text"
      />

      {ResetMatchAssistantModal}
      {ReactForfeitModal}
    </>
  )
}
