import React from 'react'
import { useCookies } from 'react-cookie'

import dayjs from '@plvs/rally/init/dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  RegisterUserInput,
  RegisterUserMutation,
  useRegisterUserMutation,
} from '@plvs/graphql'

import { rallyEnv } from '@plvs/rally/env'
import { RegistrationForm } from '@plvs/rally/features/auth/registration/RegistrationForm'
import {
  getOnboardingBasePath,
  postRegistration,
} from '@plvs/rally/features/auth/utils/helpers'

dayjs.extend(customParseFormat)

export interface RegistrationContainerProps {
  resourceId?: string | null
  roleName?: string
  inviteCode?: string
  isNasefSignUp?: boolean
  isParentSignup?: boolean
}

export const RegistrationFormContainer: React.FC<RegistrationContainerProps> = ({
  resourceId,
  roleName,
  inviteCode,
  isParentSignup = false,
  isNasefSignUp = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies()

  const [registerUserMutation] = useRegisterUserMutation()

  const registerUser = async (
    input: RegisterUserInput,
    referralToken: string,
    birthday?: string
  ): Promise<RegisterUserMutation | undefined | null> => {
    const { data: registerUserResponse } = await registerUserMutation({
      variables: {
        input: {
          email: input.email,
          password: input.password,
          dateOfBirth: birthday ?? '',
          isParent: isParentSignup,
        },
        options: { referralToken, inviteCode },
      },
    })

    if (!registerUserResponse) {
      throw new Error('Empty response received from server.')
    }

    const onboardingBasePath = getOnboardingBasePath({
      isNasefSignUp: isNasefSignUp || false,
      isParentSignup,
    })

    postRegistration(
      setCookie,
      onboardingBasePath,
      rallyEnv.COOKIE_DOMAIN,
      {
        ...registerUserResponse?.registerUser,
        email: input.email,
        userId: registerUserResponse.registerUser?.user?.id ?? '',
      },
      referralToken
    )

    return registerUserResponse
  }

  return (
    <RegistrationForm<RegisterUserInput, RegisterUserMutation>
      isNasefSignUp={isNasefSignUp}
      isParentSignup={isParentSignup}
      oAuthRedirect={rallyEnv.GOOGLE_AUTH_SIGNUP_URI}
      onSubmit={registerUser}
      resourceId={resourceId}
      roleName={roleName}
    />
  )
}
