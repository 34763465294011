import React from 'react'
import { Button, IconButton, Menu, MenuItem, Box } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'

import { MoreVert } from '@material-ui/icons'
import { useMutation } from '@apollo/client'

import { cleanGraphQLError, getAppScrimmageUrl } from '@plvs/utils'
import { CopyButton } from '@plvs/rally/components/button'
import { Dialog } from '@plvs/respawn/features/dialog'
import { FlexSpacer } from '@plvs/respawn/features/layout'
import { Column, TableColumn } from '@plvs/rally/components/table'
import {
  JoinScrimmageDocument,
  JoinScrimmageMutationVariables,
  JoinScrimmageMutation,
  Esport,
  Maybe,
  School,
  ScrimmageRequest,
  ScrimmageRequestStatus,
  Team,
} from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { getRefetchScrimmageQueries } from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import {
  NxMatchDetailsCluster,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { getRefetchMatchLobbyQueries } from '../match'
import { CancelScrimmageButton } from './CancelScrimmageButton'

type ButtonComponentProps = Pick<
  ButtonProps,
  'children' | 'disabled' | 'ref' | 'style'
>

type ScrimmageTeam = Maybe<
  Pick<Team, 'id' | 'name'> & { school?: Maybe<Pick<School, 'id'>> }
>

type AcceptScrimmageButtonProps = {
  buttonProps?: ButtonComponentProps
  matchIdForRefetchingMatchLobbyQueries?: string
  schoolId?: string
  schoolIdForRefetchingScrimmageQueries?: string
  scrimmageRequest: Pick<ScrimmageRequest, 'id' | 'bestOf' | 'startsAt'> & {
    esport?: Maybe<Pick<Esport, 'id' | 'slug' | 'name'>>
    requestingTeam?: ScrimmageTeam
    opposingTeam?: ScrimmageTeam
    status?: ScrimmageRequestStatus
  }
}

export const AcceptScrimmageButton = React.forwardRef(
  (
    {
      buttonProps,
      matchIdForRefetchingMatchLobbyQueries,
      schoolId,
      schoolIdForRefetchingScrimmageQueries,
      scrimmageRequest,
    }: AcceptScrimmageButtonProps,
    ref: React.Ref<any>
  ): React.ReactElement => {
    const [error, setError] = React.useState<Error>()
    const [isSuccess, setSuccess] = React.useState<boolean>(false)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const openDialog = (): void => setIsDialogOpen(true)
    const closeDialog = (): void => setIsDialogOpen(false)

    const [submit, { loading: isSubmitting }] = useMutation<
      JoinScrimmageMutation,
      JoinScrimmageMutationVariables
    >(JoinScrimmageDocument, {
      onCompleted: (data: JoinScrimmageMutation): void =>
        setSuccess(data?.joinScrimmage.success ?? false),
    })

    const opposingTeamId = scrimmageRequest?.opposingTeam?.id ?? ''

    const submitAcceptScrimmageInfo = async (): Promise<void> => {
      try {
        await submit({
          awaitRefetchQueries: true,
          refetchQueries: [
            ...getRefetchMatchLobbyQueries(
              matchIdForRefetchingMatchLobbyQueries ?? null
            ),
            ...getRefetchScrimmageQueries(
              schoolIdForRefetchingScrimmageQueries ?? null
            ),
          ],
          variables: {
            input: {
              id: scrimmageRequest.id,
              opposingTeamId,
            },
          },
        })
      } catch (e: any) {
        setError(e)
        setSuccess(false)
      }
    }

    const message = isSuccess ? (
      <>
        <NxTypography variant="body1">
          Your scrimmage was accepted successfully.
        </NxTypography>
        <div style={{ display: 'flex', marginTop: 40 }}>
          <FlexSpacer />
          <Button color="primary" onClick={closeDialog} variant="contained">
            Done
          </Button>
        </div>
      </>
    ) : (
      <>
        <NxTypography style={{ marginBottom: 32 }} variant="body1">
          Are you sure you want to accept?
        </NxTypography>
        <NxMatchDetailsCluster
          esport={scrimmageRequest.esport?.slug ?? ''}
          esportName={scrimmageRequest.esport?.name ?? ''}
          showAvatar={false}
          teamAName={scrimmageRequest.requestingTeam?.name ?? ''}
          teamBName={scrimmageRequest.opposingTeam?.name ?? ''}
          timestamp={scrimmageRequest.startsAt}
        />
        <div style={{ display: 'flex', marginTop: 40 }}>
          <Button onClick={closeDialog}>Close</Button>
          <FlexSpacer />
          <Button
            color="primary"
            data-cy="acceptScrimmageButtonDialog"
            disabled={isSubmitting}
            onClick={submitAcceptScrimmageInfo}
            variant="contained"
          >
            Accept
          </Button>
        </div>
      </>
    )

    return scrimmageRequest.status === ScrimmageRequestStatus.Requested &&
      schoolId === scrimmageRequest.opposingTeam?.school?.id ? (
      <>
        <Button
          ref={ref}
          color="primary"
          data-cy="acceptScrimmageButton"
          onClick={openDialog}
          variant="contained"
          {...buttonProps}
        >
          {buttonProps?.children ?? `Accept`}
        </Button>
        <Dialog
          onClose={closeDialog}
          open={isDialogOpen}
          title={isSuccess ? 'Scrimmage accepted' : `Accept scrimmage`}
          width={400}
        >
          {error && (
            <div style={{ marginBottom: 40 }}>
              <Banner
                subtitle={cleanGraphQLError(error.message)}
                title="Unable to accept scrimmage."
                type={BannerType.Error}
              />
            </div>
          )}

          {message}
        </Dialog>
      </>
    ) : (
      <></>
    )
  }
)

export const getScrimmageAcceptColumn = ({
  schoolId,
}: {
  schoolId: string
}): TableColumn<Column.ScrimmageRow> => ({
  id: 'acceptButtons',
  Cell: (scrimmageRequest): React.ReactElement => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const openMenu = (): void => setIsMenuOpen(true)
    const closeMenu = (): void => setIsMenuOpen(false)
    const ref = React.useRef() as any

    const menu = (
      <Box id="cancelLink">
        <Box ml={-1} p={0.75}>
          <IconButton
            ref={ref}
            data-cy="cancelScrimmageButton"
            onClick={openMenu}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <Menu anchorEl={ref.current} onClose={closeMenu} open={isMenuOpen}>
          <CancelScrimmageButton
            ButtonComponent={MenuItem}
            schoolId={schoolId}
            schoolIdForRefetchingScrimmageQueries={schoolId}
            // @ts-ignore TODO
            scrimmageRequest={scrimmageRequest}
          />
        </Menu>
      </Box>
    )

    const acceptOrShare =
      scrimmageRequest?.status === ScrimmageRequestStatus.NeedsOpponent ? (
        <CopyButton
          buttonTextAfterCopying="Link copied!"
          buttonTextBeforeCopying="Share"
          textToCopy={getAppScrimmageUrl(scrimmageRequest?.id)}
        />
      ) : (
        <AcceptScrimmageButton
          schoolId={schoolId}
          schoolIdForRefetchingScrimmageQueries={schoolId}
          // @ts-ignore TODO
          scrimmageRequest={scrimmageRequest}
        />
      )

    return (
      <Box alignItems="center" display="flex" gridGap={16}>
        {acceptOrShare}
        {menu}
      </Box>
    )
  },
  Header: '',
  sortable: false,
  width: 200,
})
