import React, { useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import {
  useGetPossibleTeamRivalsQuery,
  PossibleTeamRivalFragment,
  useRequestTeamRivalMutation,
} from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { useRosterContext } from '../RosterProvider'

interface RosterRivalsAutocompleteProps {
  onComplete?(): void
}

export const RosterRivalsAutocomplete: React.FC<
  RosterRivalsAutocompleteProps
> = ({ onComplete }) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { teamId, enrolledSeason, isCoach } = useRosterContext()

  const [selectedTeam, setSelectedTeam] = useState<PossibleTeamRivalFragment>()

  const [requestTeamRival, { loading: requestLoading }] =
    useRequestTeamRivalMutation()

  const { data: searchData, loading: searchLoading } =
    useGetPossibleTeamRivalsQuery({
      variables: {
        input: {
          teamId: teamId ?? '',
          seasonId: enrolledSeason?.id ?? '',
        },
      },
      skip: !teamId || !enrolledSeason?.id,
    })
  const possibleRivals = searchData?.getPossibleTeamRivals?.results ?? []

  const handleSubmit = async (): Promise<void> => {
    if (!selectedTeam) {
      return
    }

    try {
      await requestTeamRival({
        variables: {
          input: {
            acceptingTeamId: selectedTeam?.teamId ?? '',
            requestingTeamId: teamId ?? '',
            seasonId: enrolledSeason?.id ?? '',
          },
        },
      })

      enqueueSnackbar('Rival request sent', { variant: 'success' })
      setSelectedTeam(undefined)
      onComplete?.()
    } catch (err) {
      enqueueSnackbar(
        'There was an error sending your request. Try again or reach out to Customer Experience',
        {
          variant: 'error',
        }
      )
    }
  }

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    value: PossibleTeamRivalFragment | null
  ): void => {
    setSelectedTeam(value || undefined)
  }

  return !isCoach ? (
    <></>
  ) : (
    <Box display="flex" gridGap={theme.spacing(2)} mb={1}>
      <Box flex={1}>
        <Autocomplete
          data-cy="player-select"
          disabled={searchLoading}
          getOptionDisabled={(option): boolean => option.hasActiveRivalInSeason}
          getOptionLabel={(option): string =>
            `${option.team?.name} (${option.school?.name})`
          }
          loading={searchLoading}
          onChange={handleChange}
          options={possibleRivals}
          renderInput={(params): React.ReactElement => (
            <TextField
              {...params}
              label="Type Team or School Name"
              variant="outlined"
            />
          )}
          // Autocomplete is only considered `controlled` if the initial value
          // at render is not undefined.
          renderOption={(params): React.ReactElement => {
            const label = `${params.team?.name} (${params.school?.name})`
            return <NxTypography variant="body1">{label}</NxTypography>
          }}
          value={selectedTeam || null}
        />
      </Box>

      <NxButton
        disabled={!selectedTeam}
        label="Submit"
        loading={requestLoading}
        onClick={handleSubmit}
        variant="primary"
      />
    </Box>
  )
}
