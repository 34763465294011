import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  ChatDarkLargeSecondary,
  ChatLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { NxTypography } from '@playvs-inc/nexus-components'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { useStyles } from '../AppDrawerToggle.styles'

export const EmptyChats: React.FC = () => {
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      {theme.palette.type === MUIThemeModeEnum.Dark ? (
        <ChatDarkLargeSecondary height="100px" width="100px" />
      ) : (
        <ChatLightLargeSecondary height="100px" width="100px" />
      )}
      <NxTypography className={classes.emptyTitle} variant="h3">
        No Messages Yet
      </NxTypography>
      <NxTypography className={classes.emptyBody} variant="body1">
        Check back for messages on gameday
      </NxTypography>
    </Box>
  )
}
