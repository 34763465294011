import {
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  hasRoleForResource,
  hasRoleForSomeResourceIds,
  isCaptainForResource,
  isCoachForResource,
  isMemberOfOrganization,
  isTeamOwnerForResource,
  isAdminForSystem,
} from '@plvs/utils'
import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import {
  HomeAndAwayTeamDisplayDetailsProps,
  HomeAndAwayTeamDisplayDetailsReturn,
  LobbyComponentProps,
  LobbyComponentReturn,
} from '../types/lobbyRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'
import { MatchTeamForRenderController } from '../../match/types/matchRenderController.types'

export const determineTeamIds = ({
  team1,
  team2,
  userRoles,
  teamId,
}: {
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  userRoles: UserRolesForMatchLobbyRenderController
  teamId?: string
}): {
  myTeamIds: Array<string>
  opposingTeamId: string
  isMemberOfBothTeams: boolean
} => {
  const isAdmin = isAdminForSystem(userRoles ?? [])
  const isMemberOfBothTeams =
    hasRoleForResource(userRoles, team1.id) &&
    hasRoleForResource(userRoles, team2.id)

  if (isMemberOfBothTeams || isAdmin) {
    return {
      myTeamIds: [team1.id, team2.id],
      opposingTeamId: '',
      isMemberOfBothTeams: true,
    }
  }

  if (hasRoleForResource(userRoles, team1.id) || team1.id === teamId) {
    return {
      myTeamIds: [team1.id],
      opposingTeamId: team2.id,
      isMemberOfBothTeams: false,
    }
  }

  if (hasRoleForResource(userRoles, team2.id) || team2.id === teamId) {
    return {
      myTeamIds: [team2.id],
      opposingTeamId: team1.id,
      isMemberOfBothTeams: false,
    }
  }

  if (isAdmin) {
    return {
      myTeamIds: [team1.id],
      opposingTeamId: team2.id,
      isMemberOfBothTeams: false,
    }
  }

  return {
    // Default to home vs away
    myTeamIds: [team1.id],
    opposingTeamId: team2.id,
    isMemberOfBothTeams: false,
  }
}

export const determineLobbyComponentsRenderForOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean

  showRescheduleMatchAlert: boolean
} => {
  const isMemberOfEitherSchoolInMatch = isMemberOfOrganization(userRoles, [
    team1.schoolId ?? '',
    team2.schoolId ?? '',
  ])

  const userIsCoachForEitherTeamOrSchool = isCoachForResource(userRoles, [
    team1.id,
    team2.id,
    team1.schoolId ?? '',
    team2.schoolId ?? '',
  ])

  const userIsCaptainForEitherTeam = isCaptainForResource(userRoles, [
    team1.id,
    team2.id,
    team1.schoolId ?? '',
    team2.schoolId ?? '',
  ])
  return {
    showManageMatchSection: userIsCoachForEitherTeamOrSchool,
    showMatchLobbyTour:
      isMemberOfEitherSchoolInMatch && shouldRenderMatchAssistant,
    showActionAndInfoSection: isMemberOfEitherSchoolInMatch,
    canManageQueueBannerActions: userIsCoachForEitherTeamOrSchool,

    showRescheduleMatchAlert:
      userIsCaptainForEitherTeam || userIsCoachForEitherTeamOrSchool,
  }
}

export const determineLobbyComponentsRenderForNonOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean

  showRescheduleMatchAlert: boolean
} => {
  const isParticipantInMatch = hasRoleForSomeResourceIds(userRoles, [
    team1.id,
    team2.id,
  ])

  const isTeamOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [
    team1.id,
    team2.id,
  ])

  return {
    showManageMatchSection: isTeamOwnerOfEitherTeam,
    showMatchLobbyTour: isParticipantInMatch && shouldRenderMatchAssistant,
    showActionAndInfoSection: isParticipantInMatch,
    canManageQueueBannerActions: isTeamOwnerOfEitherTeam,

    showRescheduleMatchAlert: isTeamOwnerOfEitherTeam,
  }
}

export const determineLobbyComponentsRender = ({
  status,
  userRoles,
  team1,
  team2,
  isMatchAssistantEnabled,
  competitionGroup,
}: LobbyComponentProps): LobbyComponentReturn => {
  if (!team1 || !team2) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds: [],
      opposingTeamId: '',
      isMemberOfBothTeams: false,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,

      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  const matchIsNotQuarantined = status !== MatchStatus.Quarantined
  const shouldRenderMA = isMatchAssistantEnabled && matchIsNotQuarantined
  const { myTeamIds, opposingTeamId, isMemberOfBothTeams } = determineTeamIds({
    team1,
    team2,
    userRoles,
  })
  const renderedOpposingTeamId = isMemberOfBothTeams
    ? myTeamIds[1]
    : opposingTeamId

  if (!competitionGroup) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds,
      opposingTeamId: renderedOpposingTeamId,
      isMemberOfBothTeams,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,

      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const {
      showManageMatchSection,
      showMatchLobbyTour,
      showActionAndInfoSection,
      canManageQueueBannerActions,

      showRescheduleMatchAlert,
    } = determineLobbyComponentsRenderForOrgBasedMatch({
      userRoles,
      team1,
      team2,
      shouldRenderMatchAssistant: shouldRenderMA,
    })
    return {
      showManageMatchSection,
      showMatchLobbyTour,
      myTeamIds,
      opposingTeamId,
      isMemberOfBothTeams,
      showActionAndInfoSection,
      canManageQueueBannerActions,

      showRescheduleMatchAlert,
      enableScoutingToTeamsPage: true,
    }
  }

  const {
    showManageMatchSection,
    showMatchLobbyTour,
    showActionAndInfoSection,

    canManageQueueBannerActions,
    showRescheduleMatchAlert,
  } = determineLobbyComponentsRenderForNonOrgBasedMatch({
    userRoles,
    team1,
    team2,
    shouldRenderMatchAssistant: shouldRenderMA,
  })
  return {
    showManageMatchSection,
    showMatchLobbyTour,
    myTeamIds,
    opposingTeamId,
    isMemberOfBothTeams,
    showActionAndInfoSection,
    canManageQueueBannerActions,

    showRescheduleMatchAlert,
    enableScoutingToTeamsPage: false,
  }
}

export const determineHomeAndAwayTeamDisplayDetails = ({
  team1,
  team2,
  competitionGroup,
  esportRating,
}: HomeAndAwayTeamDisplayDetailsProps): HomeAndAwayTeamDisplayDetailsReturn => {
  if (!competitionGroup) {
    return {
      homeTeam: {
        id: team1?.id ?? '',
        teamName: team1?.name ?? '',
        avatarUrl: team1?.avatarUrl ?? '',
      },

      awayTeam: {
        id: team2?.id ?? '',
        teamName: team2?.name ?? '',
        avatarUrl: team2?.avatarUrl ?? '',
      },
    }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    if (esportRating === EsportRating.Restricted) {
      return {
        homeTeam: {
          id: team1?.id ?? '',

          teamName: team1?.name ?? '',
          avatarUrl: '',
          schoolSlug: '',
          schoolName: '',
        },
        awayTeam: {
          id: team2?.id ?? '',
          teamName: team2?.name ?? '',
          avatarUrl: '',
          schoolSlug: '',
          schoolName: '',
        },
      }
    }
    return {
      homeTeam: {
        id: team1?.id ?? '',
        teamName: team1?.name ?? '',
        avatarUrl: team1?.avatarUrl ?? '',
        schoolSlug: team1?.schoolSlug ?? '',
        schoolName: team1?.schoolName ?? '',
      },
      awayTeam: {
        id: team2?.id ?? '',
        teamName: team2?.name ?? '',
        avatarUrl: team2?.avatarUrl ?? '',
        schoolSlug: team2?.schoolSlug ?? '',
        schoolName: team2?.schoolName ?? '',
      },
    }
  }
  return {
    homeTeam: {
      id: team1?.id ?? '',
      teamName: team1?.name ?? '',
      avatarUrl: team1?.avatarUrl ?? '',
    },
    awayTeam: {
      id: team2?.id ?? '',
      teamName: team2?.name ?? '',
      avatarUrl: team2?.avatarUrl ?? '',
    },
  }
}
