import React, { useState } from 'react'

import { NxButton } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { ScheduleConsultationModal } from './ScheduleConsultationModal'

const useStyles = makeStyles((theme) => ({
  consultButton: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 2),
    border: `1px solid transparent`,
    '&:disabled': {
      color: theme.palette.ColorTextDisabled,
      backgroundColor: theme.palette.ColorBackgroundAlt3,
      borderColor: theme.palette.BorderMedium,
    },
    borderColor: theme.palette.BorderDark,
    color: theme.palette.ColorTextAlt,
    '&:hover, &:active': {
      backgroundColor: theme.palette.ColorBackgroundAlt3,
      color: theme.palette.ColorTextBase,
    },
  },
}))

export const ScheduleConsultationButton: React.FC<{
  link: string
}> = ({ link }) => {
  const classes = useStyles()

  const [isConsultationOpen, setIsConsultationOpen] = useState(false)

  const handleOnOpenConsultation = (): void => {
    setIsConsultationOpen(true)
  }
  const handleOnCloseConsultation = (): void => {
    setIsConsultationOpen(false)
  }

  return (
    <>
      <NxButton
        className={classes.consultButton}
        data-cy="scheduleConsultationButton"
        label="Schedule a Consultation"
        onClick={handleOnOpenConsultation}
        variant="secondary"
      />
      <ScheduleConsultationModal
        handleClose={handleOnCloseConsultation}
        link={link}
        open={isConsultationOpen}
      />
    </>
  )
}
