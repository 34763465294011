import React from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { Box } from '@plvs/respawn/features/layout'
import { Checkbox } from '@plvs/rally/components/checkbox/Checkbox'
import { OnboardTextField } from '@plvs/rally/components/form/OnboardTextfield'
import { Callout } from '@plvs/rally/components/callout'
import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'
import { Checked, StudentDetailsInput } from './types'

export interface FieldProps {
  register: React.Ref<HTMLInputElement>
  errors?: DeepMap<StudentDetailsInput, FieldError>
  email: string | undefined | null
  isPersonal: boolean
  isSchool: boolean
  setSelected(input: Checked): void
  selected: Checked | null
}

export const OnboardStudentEmailConfirmation: React.FC<FieldProps> = ({
  errors,
  register,
  email,
  isPersonal,
  setSelected,
  isSchool,
  selected,
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box py={1}>
        <NxTypography variant="body1">
          Is {(email && <b>{email}</b>) || 'this'} your personal or school email
          address?
        </NxTypography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" py={1}>
        <Box pr={2}>
          <Checkbox
            ariaDescribedBy="personal-email"
            ariaLabelledBy="personal-email"
            checked={isPersonal}
            onChange={(): void => {
              setSelected(Checked.Personal)
            }}
          />
        </Box>
        <NxTypography variant="body1">Personal</NxTypography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" pb={4} pt={1}>
        <Box pr={2}>
          <Checkbox
            ariaDescribedBy="school-email"
            ariaLabelledBy="school-email"
            checked={isSchool}
            onChange={(): void => {
              setSelected(Checked.School)
            }}
          />
        </Box>
        <NxTypography variant="body1">School</NxTypography>
      </Box>
      {selected && (
        <>
          <Box pb={1}>
            <NxTypography variant="body1">
              {selected === Checked.Personal
                ? 'Provide Your School Email Address'
                : 'Provide Your Personal Email Address'}
            </NxTypography>
          </Box>
          <OnboardTextField
            defaultValue=""
            error={errors?.otherEmail}
            label={
              selected === Checked.Personal
                ? 'School Email Address'
                : 'Personal Email Address'
            }
            name="otherEmail"
            register={register}
          />
          <Box mt={1}>
            <NxTypography component="span" display="inline" variant="body4">
              PlayVS will never sell or use your data in inappropriate ways. To
              read more please review our&nbsp;
              <NxTextLink
                href="https://playvs.com/privacy"
                label="Privacy Policy."
                labelVariant="body4"
                target="_blank"
              />
            </NxTypography>
          </Box>
        </>
      )}
      <Callout>
        We use your personal email to create your PlayVS account, while a
        separate school email is required to participate in scholastic
        competitions.
      </Callout>
    </Box>
  )
}
