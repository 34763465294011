import React from 'react'

import { Param } from '@plvs/const'
import { FilterButton } from './FilterButton'
import { Option } from './filterHelpers'
import { useOption } from './useOption'

export type LeaderboardFilter = 'default' | 'myTeams' | 'schoolTeams'

type LeaderboardOption = Option<{ id: LeaderboardFilter }>

const defaultOption: LeaderboardOption = {
  id: 'defaultTeams',
  name: 'All Teams',
  values: [{ id: 'default' }],
}

const myTeamsOption: LeaderboardOption = {
  id: 'myTeams',
  name: 'My Teams',
  values: [{ id: 'myTeams' }],
}

const schoolTeamsOption: LeaderboardOption = {
  id: 'schoolTeams',
  name: 'School Teams',
  values: [{ id: 'schoolTeams' }],
}

const loadingOptions = [defaultOption]
const noTeamsOptions = [defaultOption, schoolTeamsOption]
const teamsOptions = [defaultOption, myTeamsOption, schoolTeamsOption]

export function useLeaderboardFilter({
  hasTeams,
  loading,
}: {
  hasTeams?: boolean
  loading?: boolean
}): {
  selectedLeaderboardFilter: LeaderboardFilter
  leaderboardFilter: React.ReactElement
} {
  // eslint-disable-next-line no-nested-ternary
  const allOptions = loading
    ? loadingOptions
    : hasTeams
    ? teamsOptions
    : noTeamsOptions
  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption: allOptions[0],
    queryParam: Param.MatchLeaderboard,
  })

  return {
    selectedLeaderboardFilter: option.values[0].id,
    leaderboardFilter: (
      <FilterButton<LeaderboardOption>
        id="leaderboard-filter"
        option={option}
        options={options}
        setOption={setOption}
        style={{}}
      />
    ),
  }
}

export function getTeamsByFilter<T, U, V>({
  filter,
  allTeams,
  myTeams,
  schoolTeams,
}: {
  filter: LeaderboardFilter
  allTeams: T
  myTeams: U
  schoolTeams: V
}): T | U | V {
  switch (filter) {
    case 'myTeams':
      return myTeams
    case 'schoolTeams':
      return schoolTeams
    case 'default':
    default:
      return allTeams
  }
}
