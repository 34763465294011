import React from 'react'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { Objective } from '@plvs/rally/components/match'
import { Box } from '@plvs/respawn/features/layout'
import { ObjectivesScope } from '@plvs/respawn/features/esport/creator/types'
import { TeamGameStats, TeamMatchStats } from '@plvs/graphql'

export const GUTTER = 4

export interface Props {
  opposing?: boolean
  teamGameStats?: Pick<TeamGameStats, '__typename'> & {
    stats: unknown | null
  }
  teamMatchStats?: Pick<TeamMatchStats, '__typename'> & {
    stats: unknown | null
  }
  type?: ObjectivesScope
}

export const Objectives: React.FC<Props> = ({
  opposing,
  teamGameStats,
  teamMatchStats,
  type = ObjectivesScope.Game,
}) => {
  const { getObjectives } = useEsportContext()
  const objectives = getObjectives(type) ?? []

  return (
    <Box
      display="flex"
      flexDirection={opposing ? 'row' : 'row-reverse'}
      marginLeft={opposing && -GUTTER}
      marginRight={opposing || -GUTTER}
    >
      {objectives.map(({ accessor, ...rest }) => {
        // @ts-ignore
        const value = (type === 'game' ? teamGameStats : teamMatchStats)
          ?.stats?.[accessor] as number | string | null | undefined

        return (
          <Box key={accessor} marginLeft={GUTTER} marginRight={GUTTER}>
            <Objective value={value} {...rest} />
          </Box>
        )
      })}
    </Box>
  )
}
