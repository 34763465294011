import { Grid, makeStyles, useTheme } from '@material-ui/core'
import React from 'react'
import { EsportRating, GetSchoolTeamsQuery } from '@plvs/graphql'
import { isPlayerForOrganization } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import {
  MegaphoneLightSecondaryLarge,
  MegaphoneDarkSecondaryLarge,
} from '@playvs-inc/nexus-spots'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import {
  NxTypography,
  NxButton,
  NxEmptyState,
} from '@playvs-inc/nexus-components'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { Path } from '@plvs/const'
import { CreateCSSProperties } from '@material-ui/styles'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'

const useStyles = makeStyles((theme) => ({
  h4: {
    marginTop: theme.spacing(2),
    color: theme.palette.ColorTextAlt2,
  },
  rosterContainer: ({
    multipleTeams,
  }: {
    multipleTeams: boolean
  }): CreateCSSProperties => ({
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing(3),
      verticalAlign: 'top',
      width: multipleTeams ? 449 : '100%',
    },
    gridTemplateColumns: '50% 50%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      gridTemplateColumns: '100% 100%',
      '& > div': {
        marginRight: 0,
      },
    },
  }),
}))

export interface AllTeamsProps {
  allTeams: NonNullable<NonNullable<GetSchoolTeamsQuery['school']>['teams']>
}

export const AllTeams: React.FC<AllTeamsProps> = ({ allTeams }) => {
  const theme = useTheme()
  const {
    metaseason,
    promotedMetaseason,
    organizationId,
  } = useManageTeamsContext()
  const { league } = useSchoolLeagueInfoContext()
  const { roles } = useProfileContext()
  const isPlayerForOrg = isPlayerForOrganization(roles, [organizationId])

  const selectedEsportName = league?.esport?.name ?? 'this esport'
  const teams = (allTeams ?? [])
    .sort(({ enrolledSeasons }) => {
      const active = !!enrolledSeasons?.find(
        (enrolledSeason) => enrolledSeason?.metaseason?.id === metaseason?.id
      )
      return active ? -1 : 1
    })
    .map(({ id, isHidden, isPlayerLed }) => ({ id, isHidden, isPlayerLed }))
    // Only Admins are allowed to see hidden teams.
    .filter(({ isHidden }) => !isHidden)

  const coachLedTeams = teams.filter(({ isPlayerLed }) => !isPlayerLed)
  const hasCoachLedTeams = !!coachLedTeams.length
  const playerLedTeams = teams.filter(({ isPlayerLed }) => isPlayerLed)
  const hasPlayerLedTeams = !!playerLedTeams.length

  const renderTeams = ({ id }: { id: string }): React.ReactElement => (
    <Box
      key={id}
      display="flex"
      flex={1}
      flexDirection="column"
      maxWidth="100%"
      minHeight="fit-content"
      mt={2}
    >
      <RosterContainer
        metaseason={metaseason}
        promotedMetaseason={promotedMetaseason}
        teamId={id}
      />
    </Box>
  )
  const classes = useStyles({
    multipleTeams: teams.length > 1,
  })

  const coachLedTeamsRender = hasCoachLedTeams && (
    <>
      <Grid className={classes.rosterContainer} container>
        {coachLedTeams.map(renderTeams)}
      </Grid>
    </>
  )

  const playerLedTeamsRender = hasPlayerLedTeams && (
    <> {playerLedTeams.map(renderTeams)} </>
  )
  const disclaimerText =
    league?.esport?.rating === EsportRating.General
      ? ''
      : `${league?.esport.name} is unaffiliated with the NFHS Network and certain state associations.`
  return (
    <>
      {teams?.length ? (
        <>
          <NxTypography variant="h3">{selectedEsportName} Teams</NxTypography>
          <>
            {disclaimerText && (
              <Box mt={2}>
                <NxTypography variant="body1">{disclaimerText}</NxTypography>
              </Box>
            )}
            {isPlayerForOrg && playerLedTeamsRender}
            {coachLedTeamsRender}
            {!isPlayerForOrg && playerLedTeamsRender}
          </>
        </>
      ) : (
        <NxEmptyState
          cta={
            <NxButton
              href={Path.CreateTeam}
              label="Create Team"
              mt={theme.spacing(2)}
              variant="secondary"
            />
          }
          data-testid="AllTeams_EmptyPage"
          spot={
            theme.palette.type === MUIThemeModeEnum.Light ? (
              <MegaphoneLightSecondaryLarge height={136} width={136} />
            ) : (
              <MegaphoneDarkSecondaryLarge height={136} width={136} />
            )
          }
          subtitle="Use the create team button to create a new team!"
          title={`You don‘t have any teams for ${selectedEsportName}`}
        />
      )}
    </>
  )
}
