import React from 'react'
import { LeagueOverview } from '@plvs/rally/features/league/Overview'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { useLocation, useParams } from 'react-router-dom'
import { AppPage, PublicPage } from '../page'

export const LeaguePage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { pathname: path } = useLocation()

  const isPublic = !path.includes('/app/')

  if (!slug) return <PenaltyBox />

  return isPublic ? (
    <PublicPage description={slug} title="Esport Leagues">
      <LeagueOverview isPublicPage={isPublic} slug={slug} />
    </PublicPage>
  ) : (
    <AppPage title="League">
      <LeagueOverview isPublicPage={isPublic} slug={slug} />
    </AppPage>
  )
}
