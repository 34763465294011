import { useReactiveVar } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import { useSelectedOrganizationQuery } from '@plvs/graphql'
import { useSelectedOrganizationIdFn } from './useSelectedOrganizationIdFn'
import { useUserIdentityFn } from './useUserIdentityFn'
import {
  SelectedOrganizationInterface,
  selectedOrganizationVar,
} from '../models/SelectedOrganization'

export const useSelectedOrganizationFn = (): SelectedOrganizationInterface => {
  const userIdentity = useUserIdentityFn()
  const orgId = useSelectedOrganizationIdFn() || userIdentity?.orgId || ''
  const { data, loading, error, refetch } = useSelectedOrganizationQuery({
    variables: { id: orgId },
    skip: !orgId,
  })

  const competitionGroup = data?.school?.competitionGroup
  const country = data?.school?.country

  const setVar = useCallback(() => {
    selectedOrganizationVar({
      refetch,
      id: data?.school?.id ?? '',
      competitionGroup,
      country,
      loading,
      error,
    })
  }, [orgId, loading])

  useEffect(() => {
    if (!loading && selectedOrganizationVar().id !== orgId) {
      setVar()
    }
  }, [orgId, loading])

  return useReactiveVar(selectedOrganizationVar)
}
