import React, { useEffect } from 'react'
import { Box, useTheme } from '@material-ui/core'

import { NxScoreboardRows, ScoreboardRow } from '@playvs-inc/nexus-components'

import { GameAssistantSelection } from '@plvs/graphql'
import { MatchAssistantMatch, getMaddenPicks } from '@plvs/utils'
import { mapNonSeriesData } from '@plvs/rally/features/match/dynamic/matchFinalScore/MatchFinalScore.helpers'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

interface ScoreboardProps {
  match?: MatchAssistantMatch
  isFinal?: boolean
}

export const Scoreboard: React.FC<ScoreboardProps> = ({
  match,
  isFinal = false,
}) => {
  const theme = useTheme()
  const {
    currentStep,
    homeTeamId,
    awayTeamId,
    refetchCurrentGameAssistantStepByMatchId,
    refetch,
  } = useMatchAssistantContext()

  useEffect(function getUpdatedScores() {
    refetch()
    refetchCurrentGameAssistantStepByMatchId()
  }, [])

  const homeMatchResults =
    match?.matchResults?.find(
      (matchResult) => matchResult?.team?.id === homeTeamId
    ) ?? {}

  const awayMatchResults =
    match?.matchResults?.find(
      (matchResult) => matchResult?.team?.id === awayTeamId
    ) ?? {}

  const data: ScoreboardRow[] = mapNonSeriesData(
    match,
    homeMatchResults,
    awayMatchResults,
    false
  )

  const currentGame = data[0]

  const gamesData = data.slice(1).map((row, ndx) => {
    const { team: homeTeamPick } = getMaddenPicks({
      options: (currentStep?.characterPicks ?? []) as GameAssistantSelection[],
      teamId: homeTeamId,
      gameOrdinal: ndx + 1,
    })
    const { team: awayTeamPick } = getMaddenPicks({
      options: (currentStep?.characterPicks ?? []) as GameAssistantSelection[],
      teamId: awayTeamId,
      gameOrdinal: ndx + 1,
    })

    return {
      ...row,
      leftSide: {
        ...row.leftSide,
        Avatar: homeTeamPick?.selectionOption?.assetUri ? (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <img
              alt={homeTeamPick?.selectionOption?.displayName}
              height={60}
              src={homeTeamPick?.selectionOption?.assetUri}
            />
          </Box>
        ) : undefined,
        title: homeTeamPick?.selectionOption?.displayName ?? row.leftSide.title,
      },

      rightSide: {
        ...row.rightSide,
        Avatar: awayTeamPick?.selectionOption?.assetUri ? (
          <img
            alt={awayTeamPick?.selectionOption?.displayName}
            height={60}
            src={awayTeamPick?.selectionOption?.assetUri}
          />
        ) : undefined,
        title:
          awayTeamPick?.selectionOption?.displayName ?? row.rightSide.title,
      },
    }
  })

  return (
    <Box pt={3}>
      {isFinal && <NxScoreboardRows data={[currentGame]} />}

      {Boolean(gamesData.length) && (
        <>
          {isFinal && (
            <Box
              borderTop={`1px solid ${theme.palette.BorderLight}`}
              mt={3}
              mx={-3}
              pt={3}
            />
          )}

          <Box pb={3} width="100%">
            <NxScoreboardRows data={gamesData} gridGap={theme.spacing(1)} />
          </Box>
        </>
      )}
    </Box>
  )
}
