import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import ITAcknowledgementsInfo from './ITAcknowledgementsInfo'
import { ItContactInfoFormPanel } from './ItContactInfoFormPanel'
import { ItSchoolComputerSurveyFormPanel } from './ItSchoolComputerSurveyFormPanel'

export const ITInfoForm: React.FC = () => {
  return (
    <>
      <ItContactInfoFormPanel />

      <Box mt={5}>
        <ItSchoolComputerSurveyFormPanel />
      </Box>

      <Box mt={5}>
        <ITAcknowledgementsInfo />
      </Box>
    </>
  )
}
