import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes'
import React from 'react'
import FireBobs from '@plvs/rally/assets/maintenance/fireBobs.png'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { LogoWordMark } from '../icon'

const useStyles = makeStyles((theme) => ({
  desktopLogo: {
    width: '111px',
    height: '29px',
  },
  topNavContainer: {
    backgroundColor: Colors.White,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: `0px 1px 0px rgba(83, 93, 102, 0.08)`,
  },
}))

export const MaintenanceErrorState: React.FC = () => {
  const classes = useStyles()

  return (
    <PenaltyBox>
      <Box data-testid="maintenance" display="flex" minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Box className={classes.topNavContainer}>
            <Box my={0.5} width={240}>
              <LogoWordMark className={classes.desktopLogo} />
            </Box>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <Box
              bgcolor={Colors.Violet1}
              borderRadius="12px"
              display="flex"
              justifyContent="center"
              maxHeight="259px"
              maxWidth="418px"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                px={6}
                py={6}
              >
                <NxTypography variant="h1">We‘ll be back soon</NxTypography>
                <Box py={3}>
                  <NxTypography colorToken="ColorTextAlt" variant="subtitle1">
                    PlayVS is currently down for scheduled maintenance.
                  </NxTypography>
                </Box>
              </Box>
            </Box>
            <Box ml={-3}>
              <img alt="fire-bobs" src={FireBobs} />
            </Box>
          </Box>
        </Box>
      </Box>
    </PenaltyBox>
  )
}
