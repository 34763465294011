import { makeStyles } from '@material-ui/core'
import React from 'react'

import { TeamMember } from '@plvs/graphql'
import { Colors } from '@plvs/rally/themes/colors'
import { NxTypography } from '@playvs-inc/nexus-components'

interface Member extends Pick<TeamMember, 'id' | 'avatarUrl' | 'name'> {
  username?: string
}
interface PlayerToolTipProps {
  username?: string
  name?: string | undefined | null
  members: Member[]
}

const useStyles = makeStyles({
  subtitle: {
    color: Colors.TeamRed,
  },
})

export const PlayerToolTip = ({
  username,
  name,
  members,
}: PlayerToolTipProps): any => {
  const { subtitle } = useStyles({})

  if (username) {
    return (
      <>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {name}
        </NxTypography>
        {username ? (
          <NxTypography colorToken="OverlayColorTextBase" variant="body2">
            {username}
          </NxTypography>
        ) : (
          <NxTypography
            className={subtitle}
            colorToken="OverlayColorTextBase"
            variant="body2"
          >
            Not Connected
          </NxTypography>
        )}
      </>
    )
  }

  if (name) {
    return name
  }

  return members.length > 0 ? 'Available Slot' : ''
}
