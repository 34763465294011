import React, { useState } from 'react'

import { NxButton, NxModal, NxTypography } from '@playvs-inc/nexus-components'

import { ForfeitReason, useForfeitMatchMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { getForfeitText } from '@plvs/utils'

interface OpponentForfeitProps {
  matchId: string
  teamId?: string
}

interface OpponentForfeit {
  forfeitMatch(): Promise<void>
  openForfeitDialog(): void
  ReactForfeitModal: React.ReactElement
}

export const useOpponentForfeit = ({
  matchId,
  teamId = '',
}: OpponentForfeitProps): OpponentForfeit => {
  const { enqueueSnackbar } = useSnackbar()

  const [showForfeit, setShowForfeit] = useState(false)
  const [forfeitMatchMutation] = useForfeitMatchMutation()

  const openForfeitDialog = (): void => {
    setShowForfeit(true)
  }

  const closeForfeitDialog = (): void => {
    setShowForfeit(false)
  }

  const forfeitMatch = async (): Promise<void> => {
    setShowForfeit(false)
    try {
      await forfeitMatchMutation({
        variables: {
          matchId,
          teamId,
          reason: ForfeitReason.NoShow,
        },
      })

      enqueueSnackbar(`Submitted forfeit request`, {
        variant: 'success',
      })
    } catch (err) {
      // swallow
    }
  }

  const {
    match: { canQueue },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  return {
    forfeitMatch,
    openForfeitDialog,
    ReactForfeitModal: (
      <NxModal
        actions={
          <>
            <NxButton
              label="Cancel"
              onClick={closeForfeitDialog}
              variant="text"
            />
            <NxButton label="Submit" onClick={forfeitMatch} variant="primary" />
          </>
        }
        onClose={closeForfeitDialog}
        open={showForfeit}
        showTopRightClose
        size="small"
        title="Opponent Forfeit"
      >
        <NxTypography variant="body1">{getForfeitText(canQueue)}</NxTypography>
      </NxModal>
    ),
  }
}
