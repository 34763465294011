import React from 'react'
import tz from 'dayjs/plugin/timezone'
import dayjs from '@plvs/rally/init/dayjs'
import { isNumber } from 'ramda-adjunct'
import { AccessTime } from '@material-ui/icons'
import { Box } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes'
import { HeroGutter } from '@plvs/rally/components/hero'
import { SvgIcon, Link, makeStyles } from '@material-ui/core'
import { RouterLink } from '@plvs/respawn/features/route'
import { EsportRating, EsportSlug, TeamMatchesFragment } from '@plvs/graphql'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { MOMENT_SHORT_DATE_AND_TIME, Path } from '@plvs/const'
import {
  NxTypography,
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },
  currentTeamContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  opponentContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.Grey1,
  },
  teamLogoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  banner: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    alignItems: 'center',
    backgroundColor: Colors.Sapphire,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stateHeader: {
    marginLeft: theme.spacing(1),
  },
  teamAvatar: {
    paddingLeft: '32px',
  },
  gameIcon: {
    margin: '4px 16px 0',
  },
  matchTime: {
    marginRight: '8px',
  },
  heroIcon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1.5),
  },
}))

interface TeamHeroProps {
  avatarUrl?: string
  nextMatch?: TeamMatchesFragment | null | undefined
  rank?: number | null
  record?: {
    won?: number
    lost?: number
  }
  subtitle?: string
  link?: React.ReactNode
  title: string
  esportSlug?: EsportSlug | null
  esportRating?: EsportRating
  teamId: string
  isPublic: boolean
}

export const TeamHero = ({
  avatarUrl,
  rank,
  record,
  subtitle,
  link,
  title,
  esportSlug,
  esportRating = EsportRating.General,
  teamId,
  isPublic,
  nextMatch,
}: TeamHeroProps): React.ReactElement => {
  const nextOpponent = nextMatch?.matchResults?.find(
    (matchResult) => matchResult.team?.id !== teamId
  )?.team

  const classes = useStyles()
  const publicTeamUrl = `${Path.PublicTeam}/${teamId}`
  const publicOpponentUrl = isPublic
    ? `${Path.PublicTeam}/${nextOpponent?.id}`
    : `${Path.Team}/${nextOpponent?.id}`

  let formattedDateTime = '--'
  if (nextMatch) {
    formattedDateTime = dayjs(nextMatch.scheduledStartsAt).format(
      MOMENT_SHORT_DATE_AND_TIME
    )
  }
  const { blackAndWhiteSvgIcon, NexusIcon } = useEsportContext()

  const showRecord =
    esportSlug !== EsportSlug.Fortnite &&
    esportSlug !== EsportSlug.FortniteSolos &&
    record &&
    !!record.won &&
    !!record.lost
  const flags = useFlags()
  const rankingElement = showRecord ? (
    <NxTypography colorToken="OverlayColorTextBase" variant="h2">
      {record?.won} - {record?.lost}{' '}
      {isNumber(rank) ? `· #${rank} in Standings` : `Record`}
    </NxTypography>
  ) : (
    <></>
  )

  return (
    <HeroGutter>
      {flags.activateFullBleedBanners ? (
        <NxPlayVsBanner
          additionalContent={isPublic ? undefined : rankingElement}
          avatar={
            <Box>
              <NxUserAvatar
                avatarUrl={
                  esportRating === EsportRating.Restricted ? null : avatarUrl
                }
                hashId={teamId}
                size={NxAvatarSize.LG}
              />
            </Box>
          }
          shouldUseDefaultBanner={isPublic}
          size="small"
          subtitle={subtitle}
          title={
            <Box alignItems="center" display="flex">
              {title}
              {NexusIcon && (
                <NexusIcon
                  className={classes.heroIcon}
                  height={32}
                  width={32}
                />
              )}
            </Box>
          }
          variant={PersonaImageVariant.Explore}
        />
      ) : (
        <MatchImageCard
          topBanner={
            !isPublic && (
              <Box className={classes.banner} py={1}>
                <Link
                  className={classes.link}
                  color="inherit"
                  component={RouterLink}
                  to={publicTeamUrl}
                  underline="none"
                >
                  <NxTypography
                    className={classes.stateHeader}
                    color="inherit"
                    variant="button"
                  >
                    View Public Page
                  </NxTypography>
                </Link>
              </Box>
            )
          }
        >
          <Box flexDirection="column" flexGrow={1}>
            <Box className={classes.container}>
              <div className={classes.teamAvatar}>
                <NxUserAvatar
                  avatarUrl={
                    esportRating === EsportRating.Restricted ? null : avatarUrl
                  }
                  hashId={teamId}
                  ringColor={Colors.Blue}
                  size={NxAvatarSize.LG}
                />
              </div>
              <Box className={classes.currentTeamContainer} px={3} py={[2, 12]}>
                <Box className={classes.teamLogoContainer} mb={1}>
                  {typeof title === 'string' ? (
                    <NxTypography
                      colorToken="OverlayColorTextBase"
                      variant="display"
                    >
                      {title}
                    </NxTypography>
                  ) : (
                    title
                  )}
                  <SvgIcon
                    fontSize="large"
                    htmlColor={Colors.Grey5}
                    {...blackAndWhiteSvgIcon}
                    className={classes.gameIcon}
                  />
                </Box>
                {link && (
                  <Box mb={3}>
                    <NxTypography
                      colorToken="OverlayColorTextBase"
                      data-testid="TeamHero_subtitle"
                      variant="subtitle2"
                    >
                      {link}
                    </NxTypography>
                  </Box>
                )}
                {showRecord && <Box>{rankingElement}</Box>}
              </Box>
            </Box>
            {nextOpponent && nextOpponent.name && (
              <Box className={classes.opponentContainer} p={4}>
                <AccessTime className={classes.matchTime} />
                <span>
                  <NxTypography colorToken="OverlayColorTextBase" variant="h3">
                    vs{' '}
                    <Link
                      key={nextOpponent.id}
                      component={RouterLink}
                      style={{ color: Colors.White }}
                      to={publicOpponentUrl}
                      underline="hover"
                    >
                      {nextOpponent.name}
                    </Link>{' '}
                    on {formattedDateTime}
                  </NxTypography>
                </span>
              </Box>
            )}
          </Box>
        </MatchImageCard>
      )}
    </HeroGutter>
  )
}
