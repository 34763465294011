import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Path } from '@plvs/const'
import { CreateAccount } from '@plvs/rally/features/login/CreateAccount'
import createAnAccount from '@plvs/rally/assets/backgrounds/create-an-account.png'
import { Box } from '@plvs/respawn/features/layout/Box'
import { AuthLogo } from '@plvs/rally/features/login/AuthLogo'
import { RegistrationFormContainer } from '@plvs/rally/features/auth/registration/RegistrationFormContainer'
import { useNavigate } from 'react-router-dom'
import { PublicPage } from '../page'
import { getSidebarContent } from '../RegistrationPage.helpers'

export const ParentSignupPage: React.FC = () => {
  const flags = useFlags()
  const navigate = useNavigate()

  const query = new URLSearchParams(window.location.search)
  const inviteCode = query.get('inviteCode') || ''

  const isCoppaEnabled = flags.coppaSupport

  useEffect(() => {
    if (isCoppaEnabled === false) {
      navigate(Path.Registration)
    }
  }, [isCoppaEnabled])

  return (
    <PublicPage description="Join the PlayVS Community" title="Signup">
      <CreateAccount
        bannerImageSrc={createAnAccount}
        childrenContainerProps={{ display: 'flex', justifyContent: 'center' }}
        Logo={<AuthLogo />}
        SidebarContent={getSidebarContent('', true, false)}
        title="Parent Signup"
      >
        <Box maxWidth="421px">
          <RegistrationFormContainer
            inviteCode={inviteCode}
            isNasefSignUp={false}
            isParentSignup
          />
        </Box>
      </CreateAccount>
    </PublicPage>
  )
}
