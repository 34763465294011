import React, { useEffect, useMemo } from 'react'
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk'

declare global {
  interface Window {
    enableCoppaSupport: () => void
    disableCoppaSupport: () => void
  }
}

interface Props {
  children: React.ReactNode
  launchDarklyClientId?: string
  userId?: string
  userName?: string
}

const COPPA_SUPPORT_KEY = 'coppaSupport'

const LaunchDarklyProviderInner: React.FC<Props> = ({
  userId,
  userName,
  children,
}) => {
  const ldClient = useLDClient()
  const query = new URLSearchParams(window.location.search)
  const oauthQueryParam = query.get('oauth')

  ldClient?.waitUntilReady()

  const coppaSupportEnabled = useMemo(
    () => window.localStorage.getItem(COPPA_SUPPORT_KEY) === 'true',
    []
  )

  window.enableCoppaSupport = (): void => {
    window.localStorage.setItem(COPPA_SUPPORT_KEY, 'true')
    window.location.reload()
  }

  window.disableCoppaSupport = (): void => {
    window.localStorage.removeItem(COPPA_SUPPORT_KEY)
    window.location.reload()
  }

  useEffect(() => {
    // Removing LaunchDarkly errors from sentry
    ldClient?.on('error', () => {})

    const custom = {
      coppaSupportEnabled,
      oauthAllowed: oauthQueryParam === 'allowed',
    }
    const context = userId
      ? {
          kind: 'user',
          key: userId,
          name: userName,
          custom,
        }
      : {
          kind: 'user',
          anonymous: true,
          custom,
        }
    ldClient?.identify(context)
  }, [ldClient, userId, userName, coppaSupportEnabled, oauthQueryParam])

  return <>{children}</>
}

export const LaunchDarklyProvider: React.FC<Props> = ({
  children,
  launchDarklyClientId,
  userId,
  userName,
}) => {
  if (!launchDarklyClientId) {
    return <>{children}</>
  }

  return (
    <LDProvider clientSideID={launchDarklyClientId}>
      <LaunchDarklyProviderInner userId={userId} userName={userName}>
        {children}
      </LaunchDarklyProviderInner>
    </LDProvider>
  )
}
