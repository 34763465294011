import React from 'react'
import { useTheme } from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import {
  TrophyLightPrimaryLarge,
  TrophyDarkPrimaryLarge,
} from '@playvs-inc/nexus-spots'

const iconProps = {
  width: 100,
  height: 100,
}
export const useGetAssignAwardIcon = (
  award: string
): React.ReactElement | undefined => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const map: Record<string, JSX.Element> = {
    'Rookie MVP': isDarkMode ? (
      <TrophyDarkPrimaryLarge {...iconProps} />
    ) : (
      <TrophyLightPrimaryLarge {...iconProps} />
    ),
  }
  return map[award]
}
