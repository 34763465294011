// types

type Nullop = () => null

// consts

const nullop: Nullop = () => null

// utils

const isBrowser = (): boolean => {
  try {
    return typeof window !== 'undefined'
  } catch (_) {
    return false
  }
}

export const IS_BROWSER = isBrowser()

export const browserOnly = <T extends () => void>(fn: T): T | Nullop =>
  IS_BROWSER ? fn : nullop

// Polyfill for unsupported browsers
/* eslint-disable no-extend-native */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(
    targetLength: number,
    padString: string
  ): string {
    targetLength >>= 0 // floor if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (this.length > targetLength) {
      return String(this)
    }
    targetLength -= this.length
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
    }
    return padString.slice(0, targetLength) + String(this)
  }
}
