/**
 * Provides a context that is used as a data store for gathered
 * information that is shared between pages during the onboarding
 * flow.
 */
import { SessionStorageKey } from '@plvs/const'
import { createContext, useContext, useState } from 'react'

export type OnboardingContextValues = Record<string, unknown>

export type OnboardingContextItems = {
  assign: (values: Record<string, unknown>) => void
  data: OnboardingContextValues
}

export const OnboardingContext = createContext<OnboardingContextItems>({
  assign: (_values: Record<string, unknown>) => {},
  data: {},
})

export const useOnboardingContext = (): OnboardingContextItems => {
  return useContext(OnboardingContext)
}

/**
 * Used by OnboardV2 to setup the Onboarding Context values to inject
 * into the provider.
 */
export const useOnboardingContextProvider = (): OnboardingContextItems => {
  // Load session data in the event user has hit the refresh key during
  // onboarding flow.
  let previouslyStoredSession
  try {
    previouslyStoredSession = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.OnboardingContext) ?? '{}'
    )
  } catch {
    previouslyStoredSession = {}
  }
  const [contextValues, setContextValues] = useState<OnboardingContextValues>(
    previouslyStoredSession
  )

  /**
   * Assign updated values to onboarding context data
   * @param input { key: value, ... }
   */
  const assign = (input: OnboardingContextValues): void => {
    setContextValues((old) => {
      const updatedValues = { ...old, ...input }

      // save to session storage in the event user refreshes browser.
      sessionStorage.setItem(
        SessionStorageKey.OnboardingContext,
        JSON.stringify(updatedValues)
      )

      return updatedValues
    })

    Object.assign(contextValues, input)
  }

  return {
    assign,
    data: contextValues,
  }
}
