import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  statsAvatar: {
    height: 32,
    width: 32,
    background: theme.palette.ColorBackgroundAlt2,
    padding: theme.spacing(0.5),
  },

  smallStatsAvatar: {
    height: 20,
    width: 20,
    background: theme.palette.ColorBackgroundAlt2,
    padding: theme.spacing(0.5),
  },

  stageIcon: {
    // Compensates for svg viewbox
    marginTop: 5,
  },
}))
