import { Box, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { School, Team, User } from '@plvs/graphql'
import { UserCluster } from '@plvs/rally/components/cluster'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { Table, TableRowProps } from '@plvs/rally/components/table'
import { Colors } from '@plvs/rally/themes'
import React, { FunctionComponent } from 'react'
import { Path } from '@plvs/const'
import { generatePath } from 'react-router'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'

const NUM_ROWS_DISPLAYED = 5

export interface Stat {
  value: number
  rank: {
    league: number
    state: number
    overall: number
  }
}

export interface StatRow {
  user?: Pick<User, 'id' | 'avatarUrl' | 'name'>
  team?: Pick<Team, 'id' | 'name'>
  school?: Pick<School, 'name' | 'logoUrl' | 'slug'>
  kda?: Stat
  gold?: Stat
  goalsPerShot?: Stat
  scorePerGame?: Stat
  eliminationsPerGame?: Stat
  rankPerGame?: Stat
}

const useStyle = makeStyles((theme) => ({
  firstPlaceContainer: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.ColorBackgroundBase,
  },
  rank: {
    display: 'flex',
    flex: '60 0 auto',
    width: '60px',
    maxWidth: '60px',
    alignItems: 'center',
  },
  cluster: {
    display: 'flex',
    width: '240px',
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    flex: '240 0 auto',
  },
  value: {
    display: 'flex',
    flex: '100 0 auto',
    width: '100px',
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 50px`,
    color: theme.palette.common.white,
  },
}))

type SupportedLookUpKey =
  | 'kda'
  | 'gold'
  | 'goalsPerShot'
  | 'scorePerGame'
  | 'eliminationsPerGame'
  | 'rankPerGame'

type PlayerStandingsProps = {
  title: string
  lookup: SupportedLookUpKey
  rows: StatRow[]
  loading: boolean
  isFloat?: boolean
  isPercent?: boolean
}

const getRank = (stat: StatRow, lookup: SupportedLookUpKey): number =>
  (stat?.[lookup]?.rank?.league as number) ?? 0

const useGetUserRank = (
  standings: StatRow[],
  lookup: SupportedLookUpKey
): number => {
  const { userId } = useUserIdentityFn()

  if (userId === null) {
    return -1
  }

  const RosterRow = standings.find((x) => x.user?.id === userId)
  if (!RosterRow) {
    return 0
  }

  return getRank(RosterRow, lookup)
}

const FirstPlace: FunctionComponent<{
  stat: StatRow
  lookup: SupportedLookUpKey
  isFloat: boolean
  isPercent: boolean
}> = ({ stat, lookup, isFloat, isPercent }) => {
  const classes = useStyle()

  const avatarUrl = stat?.user?.avatarUrl || stat?.school?.logoUrl
  const topScore = (stat && stat[lookup]?.value) ?? 0
  const schoolSlug = stat?.school?.slug || ''

  return (
    <MatchImageCard p={3}>
      <Box className={classes.firstPlaceContainer}>
        <div className={classes.rank}>1</div>
        <Box className={classes.cluster}>
          <UserCluster
            avatarSize={NxAvatarSize.MD}
            avatarUrl={avatarUrl}
            id={stat?.user?.id}
            pb={1.5}
            pt={1.5}
            ringColor={avatarUrl ? Colors.White : undefined}
            subtitle={`${stat?.school?.name}`}
            subtitleColor={Colors.White}
            subtitleUrl={
              schoolSlug
                ? generatePath(Path.SchoolPage, { slug: schoolSlug })
                : ``
            }
            title={stat?.user?.name}
            titleColor={Colors.White}
          />
        </Box>

        <NxTypography className={classes.value} variant="h2">
          {isFloat ? topScore.toFixed(2) : topScore}
          {isPercent ? '%' : ''}
        </NxTypography>
      </Box>
    </MatchImageCard>
  )
}

export const PlayerStandings: React.FC<PlayerStandingsProps> = ({
  title,
  lookup,
  rows,
  loading,
  isFloat = false,
  isPercent = false,
}) => {
  // computed props
  rows?.sort((a, b) => getRank(a, lookup) - getRank(b, lookup))

  const hasData = rows.length > 0
  const first = hasData ? rows[0] : ({} as StatRow)

  const myRank = useGetUserRank(rows, lookup)
  const outOf = rows.length

  // Rendering

  // rendering loading place holders
  if (loading) {
    const placeholderRanks = Array.from(Array(NUM_ROWS_DISPLAYED - 1)).map(
      (_item, index) => ({
        id: index,
      })
    )

    return (
      <MatchCard
        data-testid="PlayerStanding_MatchCard-loading"
        px={3}
        py={3}
        title={title}
      >
        <Skeleton height={140} variant="rect" />
        <Table
          columns={[
            {
              Header: 'Rank',
              Cell: ({ row }: TableRowProps): React.ReactElement => (
                // eslint-disable-next-line no-underscore-dangle
                <>{row._index + 2}</>
              ),
              width: 60,
            },
            {
              Header: 'Player',
              Cell: (): React.ReactElement => (
                <Skeleton height={78} variant="text" width={240} />
              ),
              minWidth: 240,
            },
            {
              Header: title,
              minWidth: 100,
              Cell: (): React.ReactElement => (
                <Skeleton height={39} variant="text" width={100} />
              ),
            },
          ]}
          data={placeholderRanks}
          hideHeader
          resizable={false}
          sortable={false}
        />
      </MatchCard>
    )
  }

  const remainingRows = rows.slice(1, NUM_ROWS_DISPLAYED)

  return (
    <MatchCard
      data-testid="PlayerStanding_MatchCard"
      px={3}
      py={3}
      title={title}
    >
      <FirstPlace
        isFloat={isFloat}
        isPercent={isPercent}
        lookup={lookup}
        stat={first}
      />
      <Table
        columns={[
          {
            Header: 'Rank',
            Cell: (props: TableRowProps | StatRow): React.ReactElement => {
              const rank = getRank(props as StatRow, lookup)

              return (
                <span data-testid="PlayerStandings_MyRanking_Rank">{rank}</span>
              )
            },
            width: 60,
          },
          {
            Header: 'Player',
            Cell: ({ user, school }): React.ReactElement => (
              <UserCluster
                avatarSize={NxAvatarSize.MD}
                avatarUrl={user?.avatarUrl || school?.logoUrl}
                id={user?.id}
                pb={1.5}
                pt={1.5}
                subtitle={school?.name}
                subtitleUrl={
                  school?.slug
                    ? generatePath(Path.SchoolPage, {
                        slug: school.slug ?? '',
                      })
                    : ``
                }
                title={user?.name}
              />
            ),
            minWidth: 240,
          },
          {
            Header: title,
            minWidth: 100,
            Cell: (row: StatRow): React.ReactElement => {
              // eslint-disable-next-line react/destructuring-assignment
              const value = row[lookup]?.value ?? 0
              return (
                <NxTypography variant="h2">
                  {isFloat ? value.toFixed(2) : value}
                  {isPercent ? '%' : ''}
                </NxTypography>
              )
            },
          },
        ]}
        data={remainingRows}
        hideHeader
        resizable={false}
        sortable={false}
      />
      {myRank > 0 && (
        <Box
          data-testid="PlayerStandings_MyRanking"
          flexDirection="row"
          justifyContent="center"
          paddingTop={2}
        >
          <NxTypography
            align="center"
            colorToken="ColorTextAlt"
            variant="body2"
          >
            You are ranked {myRank} out of {outOf}
          </NxTypography>
        </Box>
      )}
    </MatchCard>
  )
}
