import { ProductType, CompetitionModel, CompetitionGroup } from '@plvs/graphql'
import { competitionModelToDisplay } from '@plvs/utils'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { RegionalNational } from '@plvs/respawn/assets/icon'

export enum EnrollmentStep {
  SelectTeam = 'selectTeam',
  SelectPlan = 'selectPlan',
  EnrollmentConfirmation = 'enrollmentConfirmation',
}

export enum ComponentType {
  SeasonEnrollment = 'SeasonEnrollment',
  SelectPlan = 'SelectPlan',
}

export type EnrollmentStepDetails = {
  title: string
  subtitle: React.ReactNode
  components: ComponentTypeProps[]
  centerToPage?: boolean
  sidebar?: Record<string, string>
  icon: React.FC<SvgIconProps>
}

export type PlanFeatureDetails = {
  features: Array<string>
  description: React.ReactNode
}
export interface EnrollmentProps {
  [key: string]: EnrollmentStepDetails
}

export interface PlanFeatureProps {
  isUSHighSchool: boolean
  isPremium: boolean
}
export interface ComponentTypeProps {
  componentType: string
  name?: string
  title?: string
  type?: string
}

export interface EnrollmentDetailsProps {
  seasonName?: string
  competitionModel?: CompetitionModel
  competitionGroup?: CompetitionGroup
  isLastCompetitionModel?: boolean
  nextCompetitionModel?: CompetitionModel
}

export const getEnrollmentDetails = ({
  seasonName = '',
  competitionModel,
  competitionGroup,
  isLastCompetitionModel,
  nextCompetitionModel,
}: EnrollmentDetailsProps): EnrollmentProps => {
  const isCollege = competitionGroup === CompetitionGroup.College
  const collegeSubtitle = `Select which of your national teams & subsitute players you’d like to enroll into the ${seasonName} season. You may change your enrollment through the third week of the regular season.`
  const highschoolSubtitle = `Select which of your regional teams & subsitute players you’d like to enroll into the ${seasonName} season. You may change your enrollment through the third week of the regular season.`
  const nextStepOfEnrollment = competitionModelToDisplay({
    competitionModel: nextCompetitionModel,
    competitionGroup,
  })

  return {
    [EnrollmentStep.SelectTeam]: {
      title: `${
        competitionModel
          ? competitionModelToDisplay({ competitionModel, competitionGroup })
          : ''
      } ${seasonName} Season Enrollment`,
      subtitle: isCollege ? collegeSubtitle : highschoolSubtitle,
      components: [{ componentType: ComponentType.SeasonEnrollment }],
      icon: RegionalNational,
      sidebar: {
        cardTitle: 'Enrollment Summary',
        buttonTitle: isLastCompetitionModel
          ? `Complete Enrollment`
          : `Continue to ${nextStepOfEnrollment} Enrollment`,
      },
    },
    [EnrollmentStep.SelectPlan]: {
      title: 'One All-Access Pass',
      subtitle: (
        <Box maxWidth="500px">
          <NxTypography align="center" variant="body1">
            Scales to the needs of your school. <br />
          </NxTypography>
        </Box>
      ),
      centerToPage: true,
      components: [
        {
          componentType: ComponentType.SelectPlan,
          type: ProductType.AnnualPass,
        },
      ],
      icon: RegionalNational,
      sidebar: {
        cardTitle: 'Final Invoice Date Details',
        buttonTitle: 'Complete',
      },
    },
  }
}

export const knownProductSlugs = {
  Ignite: 'ignite',
  IgniteSixteenManual: 'ignite-16x1-manual-overage',
  IgniteSixteenManualPremiumOnly:
    'ignite-16x1-manual-overages-high-school-premium-only',
  IgniteEightManual: 'ignite-8x1-manual-overage',
  IgniteEightManualPremiumOnly:
    'ignite-8x1-manual-overages-high-school-premium-only',
  Free: 'free',
  IgniteZero: 'ignite-zero',
  IgnitePremium: 'ignite-premium-fall-2021-16x2',
} as const

export const getFeaturesByProductSlug = (
  slug: string | null
): PlanFeatureDetails => {
  switch (slug) {
    case knownProductSlugs.Free:
      return {
        features: [
          'Access to free competitions',
          'Basic support',
          'Match lobby chat',
          'Unlimited scrimmages',
        ],
        description: 'No billing necessary',
      }
    case knownProductSlugs.IgnitePremium:
    case knownProductSlugs.Ignite:
    case knownProductSlugs.IgniteSixteenManual:
    case knownProductSlugs.IgniteSixteenManualPremiumOnly:
    case knownProductSlugs.IgniteEightManualPremiumOnly:
      return {
        features: [
          'Access to all school year competitions',
          'Compete in all games',
          'Tech support',
          'Match lobby chat',
          'Unlimited scrimmages',
          'All League of Legends Champions unlocked',
        ],
        description: (
          <>
            <NxTypography variant="h3">
              Starting with 8 season passes
            </NxTypography>
          </>
        ),
      }
    case knownProductSlugs.IgniteZero:
      return {
        features: [
          'Access to all school year competitions',
          'Compete in all games',
          'Tech support',
          'Match lobby chat',
          'Unlimited scrimmages',
          'All League of Legends Champions unlocked',
        ],
        description: 'No billing necessary',
      }
    default:
      return {
        features: ['TBD'],
        description: 'TBD',
      }
  }
}
