import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { SelectorInput } from '@plvs/rally/components/selectorInput'
import { UserRoleName } from '@plvs/graphql'

export interface Props {
  selected: UserRoleName | null
  onChange(input: UserRoleName): void
}
export const OnboardIsCoachOptions: React.FC<Props> = ({
  selected,
  onChange,
}) => {
  return (
    <Box width="100%">
      <Box pb={2}>
        <SelectorInput
          ariaDescribedBy="is-coaching"
          ariaLabelledBy="planning-to-coach"
          checked={selected === UserRoleName.Coach}
          inActive={!!selected && selected !== UserRoleName.Coach}
          name="planning-to-coach"
          onChange={(): void => {
            onChange(UserRoleName.Coach)
          }}
          title="Yes, I will be coaching"
        />
      </Box>
      <Box pb={2}>
        <SelectorInput
          ariaDescribedBy="not-coaching"
          ariaLabelledBy="not-planning-to-coach"
          checked={selected === UserRoleName.Fac}
          inActive={!!selected && selected !== UserRoleName.Fac}
          name="not-planning-to-coach"
          onChange={(): void => {
            onChange(UserRoleName.Fac)
          }}
          title="No, I will be helping with other responsibilities"
        />
      </Box>
    </Box>
  )
}
