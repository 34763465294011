import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { SelectorInput } from '@plvs/rally/components/selectorInput'
import { OnboardCustomerExperience } from './OnboardCustomerExperience'

export enum HasCoach {
  True = 'true',
  False = 'false',
}
export interface Props {
  selected: HasCoach | null
  onChange(input: HasCoach): void
}

export const OnboardKnowACoachOptions: React.FC<Props> = ({
  selected,
  onChange,
}) => {
  return (
    <Box width="100%">
      <Box py={2}>
        <SelectorInput
          ariaDescribedBy="no-coach"
          ariaLabelledBy="does-not-have-coach"
          checked={selected === HasCoach.False}
          inActive={!!selected && selected !== HasCoach.False}
          name="no-coach"
          onChange={(): void => {
            onChange(HasCoach.False)
          }}
          title="I haven‘t found one"
        />
      </Box>
      {selected === HasCoach.False && (
        <Box pb={2}>
          <OnboardCustomerExperience
            name="Firebob"
            subtitle="No worries! We can help you recruit a coach at your school."
          />
        </Box>
      )}
      <Box py={2}>
        <SelectorInput
          ariaDescribedBy="has-a-coach"
          ariaLabelledBy="does-have-coach"
          checked={selected === HasCoach.True}
          inActive={!!selected && selected !== HasCoach.True}
          name="has-a-coach"
          onChange={(): void => {
            onChange(HasCoach.True)
          }}
          title="Yes, I want to invite a coach"
        />
      </Box>
    </Box>
  )
}
