/* istanbul ignore file */
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom'
import { Enrollment } from '@plvs/rally/containers/enrollment/Enrollment'
import {
  Box,
  WaitTillLoaded,
  useBreakpointSm,
} from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { SuccessfulEnrollment } from '@plvs/rally/features/enrollment/SuccessfulEnrollment'
import { EnrollmentAppBar } from '@plvs/rally/features/enrollment/EnrollmentAppBar/EnrollmentAppBar'
import { EnrollmentLoadingModal } from '@plvs/rally/features/enrollment/EnrollmentLoadingModal'
import { Path } from '@plvs/const'
import {
  AnnualPassType,
  CompetitionModel,
  useEstimateSchoolSeasonPassUsageQuery,
  useAcceptTosPrivacyPolicyMutation,
  useSendEnrollmentConfirmationMutation,
} from '@plvs/graphql'
import { competitionModelToDisplay, isAdminForSystem } from '@plvs/utils'
import { Location } from '@plvs/client-data/models'
import {
  useAppLocationFn,
  useUserIdentityFn,
  useSelectedOrganizationFn,
} from '@plvs/client-data/hooks'
import { updateSkipBillingContactFn } from '@plvs/client-data/mutations'
import { useEnrollment } from '@plvs/respawn/containers/enrollment/useEnrollment'
import {
  DisabledEnrollmentCTAStatus,
  EnrollmentStatus,
} from '@plvs/respawn/containers/enrollment/types'
import { EnrollmentStep } from '@plvs/respawn/containers/enrollment/enrollmentDetailsData'

import {
  getFinalSlotExclusionsSelections,
  getPl,
  getPr,
  getStep,
} from '@plvs/respawn/containers/enrollment/enrollmentHelpers'
import { EnrollmentPlanSelection } from '@plvs/rally/features/enrollment/EnrollmentPlanSelection/EnrollmentPlanSelection'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { GetUserAttrs } from '../app'

import { EnrollmentSidebar } from './EnrollmentSidebar'
import { useSlotExclusionsData } from './useSlotExclusions'

const useStyles = makeStyles((theme) => ({
  enrollmentTopContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

export const EnrollmentRoute: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const flags = useFlags()
  const classes = useStyles()
  const [isPurchasing, setPurchasing] = useState(false)
  const { id: metaseasonId } = params
  const {
    userRoles,
    loading: userIdentityLoading,
    userId,
  } = useUserIdentityFn()
  const {
    id: orgId,
    competitionGroup: orgCompetitionGroup,
    loading: userSelectedOrganizationLoading,
  } = useSelectedOrganizationFn()
  const appLocation = useAppLocationFn()
  const isAdmin = isAdminForSystem(userRoles ?? [])
  const [acceptTosPrivacyPolicy] = useAcceptTosPrivacyPolicyMutation()

  const isRally = appLocation === Location.Rally

  const {
    product,
    products,
    enrolledPlayersOnTeam,
    onPlayersSelected,
    leagues,
    schoolLogo,
    schoolName,
    season,
    loading,
    changeEnrollment,
    status,
    error,
    hasTeamUpdates,
    onProductSelected,
    hasAnnualPassInstance,
    currentAnnualPassInstance,
    purchaseProduct,
    competitionModel,
    competitionModels,
    changeCompetitionModel,
    leagueEsports,
    hasEnrolledTeams,
    presubmitTeamsToEnroll,
  } = useEnrollment({
    isCoach: true,
    metaseasonId: metaseasonId ?? '',
    schoolId: orgId,
    includeOrganizationEnrollment: true,
  })

  const {
    setOptionalWeeksCache,
    optionalWeeksCache,
    slotExclusionRangeData,
    seasonSlotExclusionRangeData,
    addEnrollmentSeasonIds,
    removeEnrollmentSeasonId,
    optionalSeasonWeeksCache,
    setOptionalSeasonWeeksCache,
    updateSlotExclusionSelection,
  } = useSlotExclusionsData({
    metaseasonId: metaseasonId ?? '',
    schoolId: orgId,
  })

  const {
    data: estimatedPassUsageData,
  } = useEstimateSchoolSeasonPassUsageQuery({
    variables: {
      metaseasonId: metaseasonId ?? '',
      enrollTeamIds: presubmitTeamsToEnroll,
      schoolId: orgId,
    },
    skip: !metaseasonId || !orgId,
    // It is more important for us to be accurate than
    // be efficient since we are displaying billing
    // information.
    fetchPolicy: 'network-only',
  })

  const [sendEnrollmentConfirmation] = useSendEnrollmentConfirmationMutation()
  const isMobile = useBreakpointSm()

  const presubmitNumberOfTeams = presubmitTeamsToEnroll.length

  const step = getStep(pathname)

  const competitionModelIndex = competitionModel
    ? competitionModels.indexOf(competitionModel)
    : -1

  const nextModelIndex = competitionModelIndex + 1

  const isLastCompetitionModel =
    competitionModelIndex === (competitionModels?.length ?? 0) - 1
  const isEnrolling = pathname.includes('teams')

  const shouldRedirectToPlanSelection =
    !flags.freeCompetition &&
    !loading &&
    status === EnrollmentStatus.NotEnrolled &&
    !hasAnnualPassInstance
  const shouldShowPlanSelection = pathname.includes('select')
  const shouldShowBackButton =
    !pathname.includes('enrollment-updated') &&
    !pathname.includes('youre-enrolled')

  const isModalOpen = status === EnrollmentStatus.Enrolling

  const onContinue = async (): Promise<void> => {
    const initialStatus = status
    // Stop continue if EnrollmentLoadingModal
    if (initialStatus === EnrollmentStatus.Enrolling) {
      return
    }
    if (!isLastCompetitionModel) {
      const nextCompetitionModel =
        competitionModels[competitionModelIndex + 1] ?? ''
      changeCompetitionModel(nextCompetitionModel)
      return
    }

    // Enroll
    const updateSuccess = await updateSlotExclusionSelection()
    const success = await changeEnrollment()
    if (flags.enrollmentPageTosAndPrivacyPolicyLinks) {
      await acceptTosPrivacyPolicy({
        variables: { metaseasonId: metaseasonId ?? '', schoolId: orgId },
      })
    }

    if (success && updateSuccess) {
      await sendEnrollmentConfirmation({
        variables: { metaseasonId: metaseasonId ?? '', schoolId: orgId },
      })

      navigate(
        initialStatus === EnrollmentStatus.NotEnrolled
          ? `youre-enrolled`
          : `enrollment-updated`
      )
    }
  }

  const onPlanContinue = useCallback(async (): Promise<void> => {
    try {
      updateSkipBillingContactFn(JSON.stringify(metaseasonId), userId)
      setPurchasing(true)
      const isProductPurchased = await purchaseProduct()
      if (isProductPurchased) {
        if (isRally) {
          // Add a short delay to allow backend to catchup, so when
          // we reload the /teams route, it will have updated enrollment
          // data.
          await new Promise((r) => setTimeout(r, 1000))
          navigate(`teams/regional`, { replace: true })
        } else {
          navigate(`/school/${orgId}`, { replace: true })
        }
      }
    } finally {
      setPurchasing(false)
    }
  }, [
    purchaseProduct,
    setPurchasing,
    updateSkipBillingContactFn,
    userId,
    isRally,
  ])

  const isButtonDisabled = (): DisabledEnrollmentCTAStatus => {
    const {
      flattenedSlotExclusionSelections,
    } = getFinalSlotExclusionsSelections({
      cache: optionalWeeksCache,
    })
    const hasConfirmedSelection = flattenedSlotExclusionSelections.some(
      (s) => !!s.confirmedSelection
    )
    const hasUpdatedSelection = flattenedSlotExclusionSelections.some(
      (s) => s.selection && s.selection.hash !== s.confirmedSelection?.hash
    )

    const {
      flattenedSlotExclusionSelections: flattenedSeasonSlotExclusionSelections,
    } = getFinalSlotExclusionsSelections({
      cache: optionalSeasonWeeksCache,
    })

    const confirmedSeasonSelections = flattenedSeasonSlotExclusionSelections.filter(
      (s) => !!s.confirmedSelection
    )

    const updatedSeasonSelections = flattenedSeasonSlotExclusionSelections.filter(
      (s) => s.selection && s.selection.hash !== s.confirmedSelection?.hash
    )

    const totalSeasonSelections =
      confirmedSeasonSelections.length + updatedSeasonSelections.length

    switch (step) {
      case EnrollmentStep.SelectTeam:
        if (
          slotExclusionRangeData.length &&
          !hasUpdatedSelection &&
          !hasConfirmedSelection
        ) {
          return { value: true, message: 'Please select a school break week' }
        }
        if (
          seasonSlotExclusionRangeData.length &&
          totalSeasonSelections < seasonSlotExclusionRangeData.length
        ) {
          return { value: true, message: 'Please select custom break week(s)' }
        }
        if (updatedSeasonSelections.length) {
          return { value: false }
        }
        if (
          !hasTeamUpdates &&
          hasUpdatedSelection &&
          totalSeasonSelections >= seasonSlotExclusionRangeData.length
        ) {
          return { value: false }
        }
        return { value: !hasTeamUpdates }
      case EnrollmentStep.SelectPlan:
        if ((!product || isPurchasing) && flags.freeCompetition) {
          return { value: true }
        }
        return { value: false }
      default:
        return { value: false }
    }
  }

  const onBack = (): void => {
    if (step === EnrollmentStep.SelectPlan && isRally) {
      navigate(Path.ManageTeams)
      return
    }
    if (competitionModelIndex !== 0) {
      const priorCompetition =
        competitionModels[competitionModelIndex - 1] ?? ''
      changeCompetitionModel(priorCompetition)
      return
    }

    if (!hasAnnualPassInstance && isRally && !flags.freeCompetition) {
      navigate(`select`, { replace: true })
      return
    }

    if (isRally) {
      navigate(Path.ManageTeams)
    } else {
      navigate(`/school/${orgId}`)
    }
  }

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          position: { horizontal: 'left', vertical: 'bottom' },
        },
      })
    }
  }, [])

  if (loading || userIdentityLoading || userSelectedOrganizationLoading) {
    return null
  }

  return (
    <>
      <GetUserAttrs />
      <Box className={classes.enrollmentTopContainer}>
        <Box display="flex" flex="1" flexDirection="column">
          {shouldShowBackButton && (
            <EnrollmentAppBar
              activeStep={competitionModelIndex}
              competitionGroup={orgCompetitionGroup}
              onBack={onBack}
              showStepper={
                competitionModels.length > 1 &&
                step === EnrollmentStep.SelectTeam
              }
              steps={competitionModels}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            pl={getPl(step, isMobile)}
            pr={getPr(step, isMobile)}
          >
            {shouldShowPlanSelection && (
              <EnrollmentPlanSelection
                onPlanContinue={onPlanContinue}
                onProductSelected={onProductSelected}
                products={products}
                selectedProduct={product ?? null}
              />
            )}
            <ApmRoutes>
              {/* 
                // Redirect to plan selection if user enters team enrollment page
                // without a plan. 
              */}
              <Route
                element={
                  <Navigate
                    to={
                      hasAnnualPassInstance ||
                      flags.freeCompetition ||
                      shouldRedirectToPlanSelection
                        ? `teams/${competitionModelToDisplay({
                            competitionModel:
                              competitionModel ?? CompetitionModel.Rec,
                            competitionGroup: orgCompetitionGroup,
                          }).toLowerCase()}`
                        : `select`
                    }
                  />
                }
                path="/"
              />
              <Route path="teams">
                {(competitionModels ?? []).map((model) => (
                  <Route
                    key={model}
                    element={
                      <Enrollment
                        addEnrollmentSeasonIds={addEnrollmentSeasonIds}
                        competitionModel={model}
                        enrolledPlayersOnTeam={enrolledPlayersOnTeam}
                        isAdmin={isAdmin}
                        leagueEsports={leagueEsports}
                        leagues={leagues ?? []}
                        metaseasonId={metaseasonId ?? ''}
                        onPlayersSelected={onPlayersSelected}
                        optionalSeasonWeeksCache={optionalSeasonWeeksCache}
                        optionalWeeksCache={optionalWeeksCache}
                        removeEnrollmentSeasonId={removeEnrollmentSeasonId}
                        schoolId={orgId}
                        seasonName={season?.name}
                        seasonSlotExclusionRangeData={
                          seasonSlotExclusionRangeData
                        }
                        setOptionalSeasonWeeksCache={
                          setOptionalSeasonWeeksCache
                        }
                        setOptionalWeeksCache={setOptionalWeeksCache}
                        slotExclusionRangeData={slotExclusionRangeData}
                      />
                    }
                    path={`${competitionModelToDisplay({
                      competitionModel: model,
                      competitionGroup: orgCompetitionGroup,
                    }).toLowerCase()}`}
                  />
                ))}
              </Route>
              <Route
                element={
                  <WaitTillLoaded loading={loading || !estimatedPassUsageData}>
                    <SuccessfulEnrollment
                      pathname={pathname}
                      seasonName={season?.name || ''}
                    />
                  </WaitTillLoaded>
                }
                path="youre-enrolled"
              />

              <Route
                element={
                  <WaitTillLoaded loading={loading || !estimatedPassUsageData}>
                    <SuccessfulEnrollment
                      pathname={pathname}
                      seasonName={season?.name || ''}
                    />
                  </WaitTillLoaded>
                }
                path="enrollment-updated"
              />
            </ApmRoutes>
            {isModalOpen && (
              <EnrollmentLoadingModal isModalOpen={isModalOpen} />
            )}
          </Box>
        </Box>
        {isEnrolling && (
          <EnrollmentSidebar
            competitionGroup={orgCompetitionGroup}
            currentAnnualPassInstance={currentAnnualPassInstance}
            disableButton={isButtonDisabled()}
            error={error}
            hasEnrolledTeams={hasEnrolledTeams}
            isLastCompetitionModel={isLastCompetitionModel}
            isUnlimited={
              estimatedPassUsageData?.estimateSchoolSeasonPassUsage
                .annualPassType === AnnualPassType.Unlimited
            }
            loading={status === EnrollmentStatus.Enrolling}
            logo={schoolLogo}
            nextCompetitionModel={competitionModels[nextModelIndex]}
            numberOfTeams={presubmitNumberOfTeams}
            onContinue={onContinue}
            optionalSeasonWeeksCache={optionalSeasonWeeksCache}
            optionalWeeksCache={optionalWeeksCache}
            passesNeeded={
              estimatedPassUsageData?.estimateSchoolSeasonPassUsage
                .totalSeasonPassesNeeded ?? 0
            }
            product={product}
            season={season}
            seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
            title={schoolName}
          />
        )}
      </Box>
    </>
  )
}
