/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  PhaseType,
  useGetUserTeamsStatsQuery,
  useGetLatestMetaseasonWithMatchQuery,
} from '@plvs/graphql'
import {
  NxTypography,
  NxCardPaginationButtons,
  NxEmptyState,
  NxTeamHistoryRecord,
  TeamHistoryRecord,
} from '@playvs-inc/nexus-components'

import {
  CreateteamDarkLargeSecondary,
  CreateteamLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { useNonLoggedInUserProviderContext } from '@plvs/rally/features/profile/NonLoggedInUserProvider'
import { NonLoggedInUserFilters } from '@plvs/rally/features/profile/NonLoggedInUserFilters'
import { head } from 'ramda'

import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

interface StyleProps {
  currentIndex: number
}
export const useStyles = makeStyles((theme) => ({
  teamRowContainerOverflow: {
    overflowX: 'hidden',
  },
  teamRowContainer: {
    height: 314,
    width: '100%',
    display: 'flex',
  },
  teamContainer: ({ currentIndex }: StyleProps): CreateCSSProperties => ({
    marginRight: theme.spacing(2),
    transition: 'all 250ms linear',
    transform: `translateX(-${currentIndex * 100}%)`,
    minWidth: 260,
  }),
}))

const shortenMetaseasonName = (metaseasonName: string | undefined): string => {
  if (!metaseasonName) return ''
  const parts = metaseasonName.split(' ')
  return `${parts[0]} '${parts[1].slice(2)}`
}

export const TeamHistory: React.FC<{ userId: string }> = ({ userId }) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const {
    data: latestMetaseasonData,
    loading: latestMetaseasonLoading,
  } = useGetLatestMetaseasonWithMatchQuery({
    variables: {
      playerId: userId,
    },
  })

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const {
    metaseason,
    loading,
    setMetaseasonId,
  } = useNonLoggedInUserProviderContext()

  const latestMetaseasonId =
    latestMetaseasonData?.getLatestMatchPlayedByPlayerId?.slot?.phase?.season
      ?.metaseasonId ?? ''
  useEffect(() => {
    if (latestMetaseasonId && !loading) {
      setMetaseasonId(latestMetaseasonId)
    }
  }, [latestMetaseasonId, loading])

  const metaseasonId = metaseason?.id
  const { data: teamsData, loading: loadingTeams } = useGetUserTeamsStatsQuery({
    variables: {
      userId,
      metaseasonId: metaseasonId || '',
      phaseType: PhaseType.RegularSeason,
    },
    skip: !metaseasonId || !userId,
  })
  const metaseasonName = shortenMetaseasonName(metaseason?.name)
  const allTeams = teamsData?.user?.enrolledTeamsByMetaseasonId || []
  const teamData = allTeams.map<TeamHistoryRecord>(
    (team): TeamHistoryRecord => {
      const league = head(team.leagues || [])
      const slug = league?.esport.slug
      return {
        teamName: team.name || '',
        leagueName: league?.displayName || '',
        seasonName: metaseasonName,
        wins:
          team?.teamPhaseSummaryByMetaseasonAndPhaseType?.standings
            ?.matchesWon || 0,
        losses:
          team?.teamPhaseSummaryByMetaseasonAndPhaseType?.standings
            ?.matchesLost || 0,
        esportSlug: slug ?? '',
      }
    }
  )

  const handleNext = (): void => {
    if (currentIndex < teamData.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const classes = useStyles({ currentIndex })
  return (
    <Box>
      <HeroGutter mb={3}>
        <NxTypography variant="h3">Team History</NxTypography>
      </HeroGutter>
      <HeroGutter mb={[2, 3]}>
        <Grid container direction="row" justifyContent="space-between">
          <NonLoggedInUserFilters />
          {teamData.length > 3 && (
            <Box>
              <NxCardPaginationButtons
                enableLeft={currentIndex > 0}
                enableRight={currentIndex < teamData.length - 1}
                onLeft={handlePrev}
                onRight={handleNext}
              />
            </Box>
          )}
        </Grid>
      </HeroGutter>
      <PageContentGutter style={{ marginTop: 0 }}>
        <WaitTillLoaded
          loading={loadingTeams || loading || latestMetaseasonLoading}
          showSpinnerWhileLoading
        >
          <>
            {teamData.length ? (
              <Box className={classes.teamRowContainerOverflow}>
                <Box className={classes.teamRowContainer}>
                  {teamData.map((team, index) => {
                    return (
                      <NxTeamHistoryRecord
                        key={`${team.teamName}-${index}`}
                        className={classes.teamContainer}
                        teamHistoryRecord={team}
                      />
                    )
                  })}
                </Box>
              </Box>
            ) : (
              <NxEmptyState
                spot={
                  isDarkMode ? (
                    <CreateteamDarkLargeSecondary height={200} width={200} />
                  ) : (
                    <CreateteamLightLargeSecondary height={200} width={200} />
                  )
                }
                subtitle="Try adjusting filter to find this user’s team history."
                title="No Team History Found"
              />
            )}
          </>
        </WaitTillLoaded>
      </PageContentGutter>
    </Box>
  )
}
