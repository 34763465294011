import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { Skeleton } from '@material-ui/lab'
import { useStyles } from '../CreateTeam.styles'

export const EsportsLoading: React.FC = () => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="row">
      <Box mr={3}>
        <Skeleton
          animation="pulse"
          className={classes.esportCardSkeleton}
          id="esportCardSkeleton"
          variant="rect"
        />
      </Box>
      <Box mr={3}>
        <Skeleton
          animation="pulse"
          className={classes.esportCardSkeleton}
          id="esportCardSkeleton"
          variant="rect"
        />
      </Box>
      <Box mr={3}>
        <Skeleton
          animation="pulse"
          className={classes.esportCardSkeleton}
          id="esportCardSkeleton"
          variant="rect"
        />
      </Box>

      <Skeleton
        animation="pulse"
        className={classes.esportCardSkeleton}
        id="esportCardSkeleton"
        variant="rect"
      />
    </Box>
  )
}
