import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const IgniteAnnualPass = createSvgIcon(
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" fill="#F78823" r="20" />
    <path
      d="M25.1261 9.74414H21.0235V13.9072C22.5302 14.0734 23.9271 14.6016 25.1261 15.4036V9.74414ZM18.9722 9.74414H14.8696V15.4036C16.0686 14.6016 17.4655 14.0734 18.9722 13.9072V9.74414ZM19.9978 30.257C23.963 30.257 27.1773 27.0426 27.1773 23.0775C27.1773 19.1123 23.963 15.898 19.9978 15.898C16.0327 15.898 12.8184 19.1123 12.8184 23.0775C12.8184 27.0426 16.0327 30.257 19.9978 30.257ZM18.7414 21.5195L19.9978 18.9749L21.2543 21.5195L24.0625 21.9267L22.0307 23.9082L22.5107 26.7052L19.9978 25.3852L17.4861 26.7052L17.9661 23.9082L15.9343 21.9267L18.7414 21.5195Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear"
        x1="19.9978"
        x2="19.9978"
        y1="9.74414"
        y2="30.257"
      >
        <stop stopColor="white" />
        <stop offset="0.994792" stopColor="white" stopOpacity="0.64" />
      </linearGradient>
    </defs>
  </svg>,
  'Ignite-Anuual-Pass',
  { viewBox: '0 0 40 40' }
)
