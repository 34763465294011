import { AppState, initialState } from './state'
import { AllActions, ActionTypes } from './actions'
import { reduceNotifications, NotificationActionTypes } from './notifications'
import { reduceOnboarding, OnboardingActionTypes } from './onboarding'
import { reduceUserAttrs, UserAttrsActionTypes } from './user-attributes'
import { UserContentActionTypes, reduceUserContent } from './user-content'

export type AppReducer = (state: AppState, action: AllActions) => AppState

export const reducer: AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.Initialize:
      return {
        ...state,
        initialized: true,
      }
    case NotificationActionTypes.Complete:
    case NotificationActionTypes.Acknowledged:
      return reduceNotifications(state, action)
    case OnboardingActionTypes.SchoolTypeSet:
      return reduceOnboarding(state, action)
    case UserAttrsActionTypes.Complete:
      return reduceUserAttrs(state, action)
    case UserContentActionTypes.Complete:
      return reduceUserContent(state, action)
    default:
      return state
  }
}
