import { makeVar, ReactiveVar } from '@apollo/client'
import { Provider } from '@plvs/graphql'

export type PlayerAccountStatus = {
  isAcctConnected: boolean
  isPlatformSelected: boolean
  isFullyConnected: boolean
}

export type AccountStatus = {
  isFullyConnected: boolean
  areAllAccountsConnected: boolean
  areAllPlatformsSelected: boolean
}

export type PlayerStatus = Record<string, PlayerAccountStatus>

export type TeamStatus = AccountStatus & {
  players: PlayerStatus
  isMissingPlayers: boolean
  isEnrolled: boolean
  esportProviderName: string
}

export type AllTeamStatusInterface = AccountStatus & {
  teams: Record<string, TeamStatus>
}

export type EsportData = {
  provider: Provider
  minTeamSize: number
}

export const allTeamsAccountStatusVar: ReactiveVar<
  AllTeamStatusInterface | undefined
> = makeVar<AllTeamStatusInterface | undefined>(undefined)

export type GetTeamAccountStatusFn = (teamId: string) => TeamStatus | undefined

export type AllTeamsAccountStatusFns = AccountStatus & {
  getTeamAccountStatus: GetTeamAccountStatusFn
  getPlayerAccountStatus: (
    teamId: string,
    playerId: string
  ) => PlayerAccountStatus | undefined
  getTeamEsportProviderName: (teamId: string) => string | undefined
}

export type UseAllTeamsAccountStatusFnsReturn = {
  allTeamsAccountStatusFns: AllTeamsAccountStatusFns
  loading: boolean
  error?: Error
}
