import { useState, useEffect } from 'react'
import { useQuery, ApolloError } from '@apollo/client'

import {
  UserContentDocument,
  UserContentQuery,
  ContentFeature,
} from '@plvs/graphql'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Param } from '@plvs/const'
import { useAppDispatch, useAppState } from '../context'
import { UserContentState } from '../state'
import { userContentFetched } from '../user-content'

export enum Feature {
  GettingStartedHighSchool = 'getting-started-high-school',
  GettingStartedCollege = 'getting-started-college',
  SpawnPoint = 'spawn_point',
}

export enum OnboardingVariant {
  Control = 'control',
  SpawnPoint = 'sp_onboarding',
}

export interface UseUserContentState {
  features: Pick<ContentFeature, 'slug' | 'variant'>[] | null
  loading: boolean
  error?: ApolloError
  refetch: () => void
  showGettingStarted: boolean
  onboardingVariant: OnboardingVariant | null
}

export const getOnboardingVariantFromUserContent = (
  userContent: Pick<UserContentState, 'features'>
): OnboardingVariant | null => {
  return userContent.features?.find(
    (feature) => feature.slug === Feature.SpawnPoint
  )?.variant as OnboardingVariant
}

export const useUserContent = (): UseUserContentState => {
  const { userContent } = useAppState()
  const showGettingStarted = !!userContent.features
    ?.map((feature) => feature?.slug)
    .some(
      (slug) =>
        slug === Feature.GettingStartedCollege ||
        slug === Feature.GettingStartedHighSchool
    )

  const onboardingVariant = getOnboardingVariantFromUserContent(userContent)

  return {
    ...userContent,
    showGettingStarted,
    onboardingVariant,
  }
}

interface Buckets {
  [slug: string]: string
}

interface BucketResp {
  content: {
    features: {
      slug: string
      variant: string | null
    }[]
  }
}

export const overwriteBuckets = <T extends BucketResp>(
  data: T,
  buckets?: Buckets
): T => {
  if (!buckets) return data
  const mappedData = { ...data }
  const { features } = mappedData.content
  if (features) {
    features.forEach((feature, i) => {
      const { slug } = feature
      if (slug && buckets[slug]) {
        mappedData.content.features[i].variant = buckets[slug]
      }
    })
  }
  return mappedData
}

export const tryParseBuckets = (buckets: string): Buckets | undefined => {
  try {
    const parsedBuckets = JSON.parse(decodeURIComponent(buckets)) as Buckets
    return parsedBuckets
  } catch (e: any) {
    return undefined
  }
}

export const GetUserContent: React.FC = () => {
  const { data, loading, refetch, error } = useQuery<UserContentQuery>(
    UserContentDocument
  )

  // NOTE: cache bucket will be { slug: variant } and is cache to avoid pulling
  // straight query param, and using cached version
  const [cachedBuckets, setCachedBuckets] = useState<Buckets | undefined>()
  const dispatch = useAppDispatch()
  const [forceBucket] = useQueryParam(Param.ForceBucket)

  useEffect(() => {
    if (forceBucket) {
      setCachedBuckets(tryParseBuckets(forceBucket))
    }
  }, [forceBucket])

  useEffect(() => {
    const me = data?.me
    const forceBucketedData =
      me && overwriteBuckets<typeof me>(me, cachedBuckets)

    dispatch(
      userContentFetched({
        data: { me: forceBucketedData ?? null },
        refetch,
        error,
        loading,
      })
    )
  }, [data, error, loading, cachedBuckets])
  return null
}
