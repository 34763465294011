import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const Eye = createSvgIcon(
  <svg fill="none" height="12" viewBox="0 0 16 12" width="16">
    <path
      d="M9.59118 6.00015C8.71988 6.00015 7.99977 5.28004 7.99977 4.40874C7.99977 4.12706 8.08173 3.86528 8.21302 3.63452C8.1414 3.62895 8.07297 3.61304 7.99977 3.61304C6.69322 3.61304 5.61266 4.6944 5.61266 6.00015C5.61266 7.30669 6.69322 8.38726 7.99977 8.38726C9.30552 8.38726 10.3869 7.30669 10.3869 6.00015C10.3869 5.92694 10.371 5.85851 10.3654 5.7869C10.1346 5.91819 9.87285 6.00015 9.59118 6.00015Z"
      fill="#2F3B43"
    />
    <path
      d="M8 0.430176C1.9264 0.430176 0.101054 5.69534 0.0843446 5.74866L0 6.0001L0.0835489 6.25154C0.101054 6.30485 1.9264 11.57 8 11.57C14.0736 11.57 15.8989 6.30485 15.9157 6.25154L16 6.0001L15.9164 5.74866C15.8989 5.69534 14.0736 0.430176 8 0.430176ZM8 9.97861C3.74219 9.97861 2.0927 6.91834 1.69326 6.0001C2.09429 5.07867 3.74458 2.02158 8 2.02158C12.2578 2.02158 13.9073 5.08186 14.3067 6.0001C13.9057 6.92152 12.2554 9.97861 8 9.97861Z"
      fill="#2F3B43"
    />
  </svg>,
  'Eye',
  { viewBox: '0 0 16 12' }
)
