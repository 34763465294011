/* istanbul ignore file */
// Provider component is not tested.  Components using it are tested instead

import React from 'react'
import { uniqBy } from 'ramda'
import {
  useGetNonLoggedInMetaseasonsQuery,
  SeasonTeamMatchSort,
  SeasonStatus,
} from '@plvs/graphql'
import {
  RelativeTiming,
  mapMetaseasonsForFilters,
  MinimalMetaseason,
} from '@plvs/utils'
import { FilterCacheContext } from '@plvs/respawn/containers/filter/FilterCacheProvider'

import { LeagueFilterContext } from '@plvs/respawn/features/filters/types'
import { Provider } from '@plvs/respawn/containers/filter/league/context'

interface AllMetaseasonsProps {
  setDefaultMetaseason?: (
    metaseasons: MinimalMetaseason[]
  ) => MinimalMetaseason | undefined
}

const defaultSetMetaseason = (
  metaseasons: MinimalMetaseason[]
): MinimalMetaseason | undefined =>
  metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Past)

export const AllMetaseasons: React.FC<AllMetaseasonsProps> = ({
  children,
  setDefaultMetaseason = defaultSetMetaseason,
}) => {
  const {
    metaseasonId,
    setCompetitionModel,
    setLeagueId,
    setMetaseasonId,
    setTeamId,
    setPhaseId,
  } = React.useContext(FilterCacheContext)

  const {
    data: nonLoggedInMetaseasonData,
    loading: loadingNonLoggedInUserMetaseasons,
    error,
  } = useGetNonLoggedInMetaseasonsQuery({
    variables: {
      options: {
        filters: {
          status: SeasonStatus.Active,
        },
        sort: SeasonTeamMatchSort.Asc,
      },
    },
  })

  const value: LeagueFilterContext = {
    leagues: [],
    metaseasons: [],
    teams: [],
    loading: loadingNonLoggedInUserMetaseasons,
    error,
    setMetaseason: setMetaseasonId,
    setLeague: setLeagueId,
    setTeam: setTeamId,
    phases: [],
    setCompetitionModel,
    setPhase: setPhaseId,
    defaultPhases: [],
  }
  if (!loadingNonLoggedInUserMetaseasons) {
    const metaseasons = uniqBy(
      (m) => m.id,
      mapMetaseasonsForFilters({
        metaseasons: nonLoggedInMetaseasonData?.metaseasons ?? [],
      })
    )
    const metaseason =
      metaseasons.find((ms) => ms.id === metaseasonId) ||
      setDefaultMetaseason(metaseasons)

    value.metaseasons = metaseasons
    value.metaseason = metaseason
  }

  return <Provider value={value}>{children}</Provider>
}
