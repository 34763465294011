import React from 'react'

import { NxIconButton } from '@playvs-inc/nexus-components'
import { Adduser } from '@playvs-inc/nexus-icons'

import { EsportSlug } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { AddPlayerToTeamWrapper } from './AddPlayerToTeamWrapper'

interface Props {
  disabled?: boolean
  positionIndex: number | null
  teamId: string
  onMutationSuccess?(returnedTeamId?: string): Promise<void>
  isAssociatedToOrg?: boolean
  userId: string
  esportSlug: EsportSlug | undefined
  loading?: boolean
  metaseasonId: string
  leagueId: string
}

export const AddPlayerToTeamButton = ({
  disabled,
  positionIndex,
  teamId,
  onMutationSuccess,
  isAssociatedToOrg,
  loading,
  userId,
  esportSlug,
  metaseasonId,
  leagueId,
}: Props): React.ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const openDialog = (): void => setIsDialogOpen(true)
  const closeDialog = (): void => setIsDialogOpen(false)

  return (
    <>
      <NxIconButton
        data-testid="AddPlayerToTeam_Button"
        disabled={disabled}
        icon={<Adduser />}
        label="Add Player"
        onClick={openDialog}
        variant="secondary"
      />
      {isDialogOpen && (
        <WaitTillLoaded loading={loading}>
          <AddPlayerToTeamWrapper
            closeDialog={closeDialog}
            esportSlug={esportSlug}
            isAssociatedToOrg={isAssociatedToOrg}
            isDialogOpen={isDialogOpen}
            leagueId={leagueId}
            loading={loading}
            metaseasonId={metaseasonId}
            onMutationSuccess={onMutationSuccess}
            positionIndex={positionIndex}
            teamId={teamId}
            userId={userId}
          />
        </WaitTillLoaded>
      )}
    </>
  )
}
