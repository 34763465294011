import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import React from 'react'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'

interface LiveIndicatorProps {
  isLive: boolean
  typographyClassName?: string
  typographyVariant?: NxTypographyVariant
}

const useStyles = makeStyles((theme) => ({
  liveText: {
    color: theme.palette.ColorTextError,
  },
  liveWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const CircleIcon: React.FC = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" fill="#DB4500" opacity="0.3" r="8">
        <animate
          attributeName="r"
          begin="0s"
          dur="1s"
          from="3"
          repeatCount="indefinite"
          to="8"
        />
      </circle>
      <circle cx="8" cy="8" fill="#DB4500" opacity="1" r="6">
        <animate
          attributeName="r"
          begin="0s"
          dur="1s"
          from="6"
          repeatCount="indefinite"
          to="3"
        />
      </circle>
    </svg>
  )
}

export const LiveIndicator: React.FC<LiveIndicatorProps> = ({
  isLive = true,
  typographyClassName,
  typographyVariant = 'overline',
}) => {
  const styles = useStyles()

  return isLive ? (
    <div className={styles.liveWrapper}>
      <CircleIcon />
      <NxTypography
        className={`${styles.liveText} ${typographyClassName}`}
        variant={typographyVariant}
      >
        Live
      </NxTypography>
    </div>
  ) : null
}
