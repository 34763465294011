import React from 'react'
import { SelectOption } from '@plvs/const'
import { SelectorInput } from '@plvs/rally/components/selectorInput'
import { Box } from '@plvs/respawn/features/layout'

export interface Props {
  options: SelectOption[]
  checked: string | null
  onChange(input: string): void
}
export const OnboardRoleDescriptionOptions: React.FC<Props> = ({
  options,
  checked,
  onChange,
}) => {
  return (
    <Box width="100%">
      {options.map((option) => {
        const key = option.value
        return (
          <Box key={key} pb={2}>
            <SelectorInput
              ariaDescribedBy={option.value}
              ariaLabelledBy={`${option.value}-role-description`}
              checked={checked === option.value}
              data-testid="OnboardRoleDescriptionsOptions_Option"
              inActive={!!checked && checked !== option.value}
              name={option.value}
              onChange={(): void => {
                onChange(option.value)
              }}
              title={option.label}
            />
          </Box>
        )
      })}
    </Box>
  )
}
