import { ChatRole } from '@plvs/graphql/generated'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'
import { ConversationsProviderMetadata } from '@plvs/rally/containers/chat/Providers.types'
import { MatchChatTabs } from '@plvs/rally/features/chat/MatchChatTabs'
import { getDerivedChatUniqueName } from '@plvs/rally/features/chat/utils'
import { useUserOnlineStatusContext } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { Participant } from '@twilio/conversations'
import React, { useEffect, useMemo } from 'react'

interface MatchChatContainerProps {
  matchId: string
}

const shouldRenderCoachChatHelpers = ({
  shouldRenderCoachChat,
  chatRole,
  matchId,
  usersActiveConversationsMap,
}: {
  shouldRenderCoachChat: boolean
  chatRole: ChatRole
  matchId: string
  usersActiveConversationsMap: Record<string, ConversationsProviderMetadata>
}): {
  coachParticipantsForChat: Participant[]
  coachChat: ConversationsProviderMetadata | undefined
} => {
  if (shouldRenderCoachChat) {
    const derivedMatchId = getDerivedChatUniqueName({
      matchId,
      chatRole,
    })
    const coachChat = usersActiveConversationsMap[derivedMatchId]
    const coachParticipantsForChat = coachChat?.participants ?? []

    return {
      coachParticipantsForChat,
      coachChat,
    }
  }
  return {
    coachParticipantsForChat: [],
    coachChat: undefined,
  }
}

const MatchChatContainer: React.FC<MatchChatContainerProps> = ({ matchId }) => {
  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const {
    shouldRenderCoachChat,
    chatRole,
  } = getMatchLobbyRenderControllerState().chat
  const {
    usersActiveConversationsMap,
    setRefetchGetConversations,
    loading: conversationsLoading,
  } = useChatConversationsContext()

  const {
    setCurrentUserIdsMap,
    currentUserIdsMap,
  } = useUserOnlineStatusContext()

  const allChat = usersActiveConversationsMap[matchId]

  const allParticipantsForChat = allChat?.participants ?? []

  const allChatParticipantIds = useMemo((): Array<string> => {
    return allParticipantsForChat
      .filter((par) => par.identity)
      .map((par) => par.identity) as Array<string>
  }, [allParticipantsForChat])

  const { coachChat, coachParticipantsForChat } = shouldRenderCoachChatHelpers({
    shouldRenderCoachChat,
    chatRole,
    matchId,
    usersActiveConversationsMap,
  })

  const coachChatParticipantIds = useMemo((): Array<string> => {
    return coachParticipantsForChat
      .filter((par) => par.identity)
      .map((par) => par.identity) as Array<string>
  }, [coachParticipantsForChat])

  useEffect(() => {
    // Only checking all chat because all users have access to all chat
    if (!allChat?.conversation?.uniqueName && !conversationsLoading) {
      setRefetchGetConversations(true)
    }
  }, [conversationsLoading])

  useEffect(() => {
    if (allChat?.conversation?.uniqueName) {
      setCurrentUserIdsMap?.({
        ...currentUserIdsMap,
        [allChat.conversation.uniqueName]: allChatParticipantIds,
      })
    }
    if (coachChat?.conversation?.uniqueName) {
      setCurrentUserIdsMap?.({
        ...currentUserIdsMap,
        [coachChat.conversation.uniqueName]: coachChatParticipantIds,
      })
    }
  }, [allChatParticipantIds.toString(), coachChatParticipantIds.toString()])

  return (
    <MatchChatTabs
      allParticipants={allParticipantsForChat}
      chatRole={chatRole}
      coachParticipants={coachParticipantsForChat}
      matchId={matchId}
    />
  )
}

export default MatchChatContainer
