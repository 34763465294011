import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isPlainObj, noop } from 'ramda-adjunct'

import { LocalStorageKey, Path } from '@plvs/const'
import { CompetitionModel, ResourceType } from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

type FilterId = Path

// Please DO NOT add optional keys here! 👹
interface Filter {
  competitionModel: CompetitionModel | null
  leagueId: string
  metaseasonId: string
  phaseId: string
  teamId: string
  entityType: '' | ResourceType | null
  entityId: string
}

// Please DO add default values here! 🤠
const defaultFilterValues: Filter = {
  competitionModel: null,
  leagueId: '',
  metaseasonId: '',
  phaseId: '',
  teamId: '',
  entityId: '',
  entityType: '',
}

// And don't forget to add your set filter functions!
interface SetFilterFns {
  setCompetitionModel(value: CompetitionModel | null): void
  setLeagueId(value: string): void
  setMetaseasonId(value: string): void
  setPhaseId(value: string): void
  setTeamId(value: string): void
  setEntityId(value: string): void
  setEntityType(value: '' | ResourceType | null): void
}

const defaultSetFilterFns: SetFilterFns = {
  setCompetitionModel: noop,
  setMetaseasonId: noop,
  setLeagueId: noop,
  setPhaseId: noop,
  setTeamId: noop,
  setEntityId: noop,
  setEntityType: noop,
}

type FilterMap = Partial<Record<FilterId, Filter>>

type SetFilterCacheFn = (values: Partial<Filter>) => void

export const FilterCacheContext = createContext<Filter & SetFilterFns>({
  ...defaultFilterValues,
  ...defaultSetFilterFns,
})

const getStorageKey = (userId: string | undefined): string =>
  `${LocalStorageKey.FilterCacheMap}.${userId || 'default'}`

const getFilterMapFromLocalStorage = (
  userId: string | undefined
): FilterMap => {
  const storageKey = getStorageKey(userId)

  // Safely parse the filter map from localStorage
  try {
    const filterMapString =
      (localStorage && localStorage.getItem(storageKey)) ?? '{}'

    const filterMap = JSON.parse(filterMapString) as FilterMap
    return isPlainObj(filterMap) ? filterMap : {}
  } catch (err: any) {
    // Defensively clear out the cache in case entries are corrupted.
    localStorage.removeItem(storageKey)
  }

  return {}
}

export const getFilterFromLocalStorage = (
  userId: string | undefined,
  id: FilterId
): Filter => {
  const filterMap = getFilterMapFromLocalStorage(userId)
  const filter = filterMap[id]

  // Don't let any properties go `undefined`
  return {
    ...defaultFilterValues,
    ...filter,
  }
}

const setFilterMapInLocalStorage = (
  userId: string | undefined,
  map: FilterMap
): void => {
  try {
    localStorage.setItem(getStorageKey(userId), JSON.stringify(map))
  } catch {
    // swallow exception in event localStorage API is denied.
  }
}

const setFilterInLocalStorage = (
  userId: string | undefined,
  id: FilterId,
  values: Filter
): void => {
  const filterMap = getFilterMapFromLocalStorage(userId)
  const updatedFilterValues: Filter = {
    ...defaultFilterValues,
    ...filterMap[id],
    ...values,
  }
  setFilterMapInLocalStorage(userId, {
    ...filterMap,
    [id]: updatedFilterValues,
  })
}

export const FilterCacheProvider: React.FC<{
  id: FilterId
}> = ({ children, id }) => {
  const { userId } = useUserIdentityFn()

  // A. Filter Cache
  const [filter, setFilter] = useState(getFilterFromLocalStorage(userId, id))

  const setFilterCache = useCallback<SetFilterCacheFn>(
    (values) =>
      setFilterInLocalStorage(userId, id, {
        ...defaultFilterValues,
        ...values,
      }),
    [id]
  )

  useEffect(() => setFilterCache(filter), [filter])

  // B. Individual Setter Functions
  const setCompetitionModel = useCallback(
    (value: CompetitionModel | null): void =>
      setFilter((current) => ({
        ...current,
        competitionModel: value,
      })),
    [setFilter]
  )
  const setLeagueId = useCallback(
    (value: string): void =>
      setFilter((current) => ({
        ...current,
        leagueId: value,
      })),
    [setFilter]
  )
  const setMetaseasonId = useCallback(
    (value: string): void =>
      setFilter((current) => ({
        ...current,
        metaseasonId: value,
      })),
    [setFilter]
  )
  const setPhaseId = useCallback(
    (value: string): void =>
      setFilter((current) => ({
        ...current,
        phaseId: value,
      })),
    [setFilter]
  )
  const setTeamId = useCallback(
    (value: string): void =>
      setFilter((current) => ({
        ...current,
        teamId: value,
      })),
    [setFilter]
  )
  const setEntityId = useCallback(
    (value: string): void =>
      setFilter((current) => ({
        ...current,
        entityId: value,
      })),
    [setFilter]
  )
  const setEntityType = useCallback(
    (value: '' | ResourceType | null): void =>
      setFilter((current) => ({
        ...current,
        entityType: value,
      })),
    [setFilter]
  )

  const values = useMemo(
    () => ({
      ...filter,
      setCompetitionModel,
      setLeagueId,
      setMetaseasonId,
      setPhaseId,
      setTeamId,
      setEntityId,
      setEntityType,
    }),
    [
      filter,
      setCompetitionModel,
      setLeagueId,
      setMetaseasonId,
      setPhaseId,
      setTeamId,
      setEntityId,
      setEntityType,
    ]
  )

  return (
    <FilterCacheContext.Provider value={values}>
      {children}
    </FilterCacheContext.Provider>
  )
}
