import { Path } from '@plvs/const'
import React from 'react'
import { Route } from 'react-router-dom'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { AppNav } from './AppNav'

export const AppNavigation = (): React.ReactElement => (
  <ApmRoutes>
    {/* Hide App Navigation on below routes */}
    <Route path="/high-school-coach-signup" />
    <Route path="/high-school-player-signup" />
    <Route path="/college-coach-signup" />
    <Route path="/college-player-signup" />
    <Route path="/esports-for-everyone" />
    <Route path="/fall2019" />
    <Route path="/california" />
    <Route path="/arizona" />
    <Route path="/virginia" />
    <Route path="/fall2019season" />
    <Route path="/signup" />
    <Route path="/login" />
    <Route path="/logout" />
    <Route path="/forgot-password" />
    <Route path="/password-reset" />
    <Route path={Path.MatchQueue} />
    <Route path={Path.PracticeDayQueue} />
    <Route path={Path.SlotMatchQueue} />
    <Route path={Path.PreseasonMatchQueue} />
    <Route path={Path.Profile} />
    <Route path={Path.ParentSignUp} />

    {/* Main component */}
    <Route element={<AppNav />} path="*" />
  </ApmRoutes>
)
