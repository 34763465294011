import React from 'react'

import { Colors } from '@plvs/rally/themes'
import { Link } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { RouterLink } from '@plvs/respawn/features/route'
import { NxTypography } from '@playvs-inc/nexus-components'

export const ReturnLink = ({
  path,
  title,
}: {
  path: string
  title: string
}): React.ReactElement => (
  <Link component={RouterLink} to={path} underline="none">
    <div
      style={{
        marginBottom: '24px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <KeyboardArrowLeft
        style={{
          backgroundColor: `${Colors.Grey6}`,
          borderRadius: '50%',
          height: 40,
          width: 40,
          display: 'flex',
          border: `2px solid ${Colors.Grey4}`,
          color: Colors.Black,
          marginRight: '12px',
        }}
      />
      <NxTypography colorToken="ColorTextAlt2" variant="subtitle1">
        {title}
      </NxTypography>
    </div>
  </Link>
)
