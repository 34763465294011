import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const RegionalNational = createSvgIcon(
  <>
    <path
      d="M20 40a20 20 0 1120-20 20.024 20.024 0 01-20 20zm0-37.19A17.19 17.19 0 1037.19 20 17.21 17.21 0 0020 2.81z"
      fill="#FCA028"
    />
    <path
      d="M8.901 18.368a15.577 15.577 0 01-.688-1.923 1.598 1.598 0 01-.067-.296c-.024-.152.134-.288.336-.288h1.675c-.035-.33-.07-.666-.099-1.013 0-.139-.024-.267-.034-.4H6.666c-.285 0-.509.184-.485.4.035.411.094.82.176 1.224.504 2.133 1.333 4.173 3.021 5.867a7.279 7.279 0 002.582 1.696 21.6 21.6 0 01-.734-2.134c-1.109-.848-1.76-1.968-2.325-3.133zM33.65 14.448h-3.175c-.043.47-.088.939-.144 1.413h1.498c.206 0 .363.136.339.288-.014.1-.036.2-.067.296a15.562 15.562 0 01-.688 1.923c-.533 1.088-1.13 2.133-2.109 2.963-.217.761-.475 1.51-.77 2.245a7.294 7.294 0 002.4-1.624c1.687-1.707 2.514-3.733 3.018-5.867.084-.404.144-.812.179-1.224.029-.229-.195-.413-.48-.413zM28.6 10.323a18.935 18.935 0 00-3.286-1.24c-4.498-1.083-9.162-.998-13.418 1.24-.65.344-.939.877-.894 1.738.054.992.147 1.768.224 2.688.384 4.55 1.752 11.126 5.6 13.662.686.442 1.44.762 2.174 1.144.2 1.133-.63 3.2-3.29 4.714a15.2 15.2 0 008.892 0c-2.896-1.168-3.309-3.56-3.13-4.717.232-.117.504-.213.733-.315a7.936 7.936 0 003.376-2.65c2.982-4.096 3.57-9.494 3.902-14.518.053-.842-.206-1.445-.883-1.746zm-3.838 7.762a4.549 4.549 0 11-4.549-4.533v1.899l4.605-1.867-2.042 4.501h1.986z"
      fill="#FFB938"
    />
  </>,
  'RegionalNational Logo',
  { viewBox: '0 0 40 40' }
)
