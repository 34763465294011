import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import {
  MatchCheckInMatch,
  MatchCheckInMatchStatus,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import { DateFormats } from '@plvs/utils'
import {
  ScheduleOutlined,
  Verified,
  Forfeit,
  Reschedule,
} from '@playvs-inc/nexus-icons'
import dayjs from 'dayjs'
import { NxTypography } from '@playvs-inc/nexus-components'

export interface MatchCheckInSidebarItemProps {
  selected: boolean
  match: MatchCheckInMatch
  matchStatus: MatchCheckInMatchStatus
  onSelectMatch(matchId: string): void
}

export const useStyles = makeStyles({
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  sidebarItem: {
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
  sidebarItemSelected: {
    background: 'rgba(0, 0, 0, 0.1)',
  },
  sidebarItemHeader: {
    display: 'flex',
    alignItems: 'center',
  },
})

function getMatchStatusIcon(
  status: MatchCheckInMatchStatus,
  theme: Theme
): React.ReactElement {
  switch (status) {
    case MatchCheckInMatchStatus.Closed:
      return <ScheduleOutlined color={theme.palette.ColorTextError} />
    case MatchCheckInMatchStatus.CheckedIn:
      return <Verified color={theme.palette.ColorTextSuccess} />
    case MatchCheckInMatchStatus.Forfeited:
      return <Forfeit color={theme.palette.ColorTextError} />
    case MatchCheckInMatchStatus.OpponentForfeited:
      return <Forfeit color={theme.palette.ColorTextSuccess} />
    case MatchCheckInMatchStatus.RescheduleRequested:
    case MatchCheckInMatchStatus.Rescheduled:
      return <Reschedule color={theme.palette.ColorTextWarn} />
    case MatchCheckInMatchStatus.Available:
    default:
      return <></>
  }
}

export const MatchCheckInSidebarItem = ({
  selected,
  match,
  onSelectMatch,
  matchStatus,
}: MatchCheckInSidebarItemProps): React.ReactElement => {
  const theme = useTheme()
  const {
    ellipsis,
    sidebarItem,
    sidebarItemSelected,
    sidebarItemHeader,
  } = useStyles()
  const matchResults = match.matchResults ?? []
  const team0Name = matchResults[0]?.team?.name ?? 'Team 1'
  const team1Name = matchResults[1]?.team?.name ?? 'Team 2'

  const matchName = `${team0Name} vs ${team1Name}`

  const className = [sidebarItem, selected ? sidebarItemSelected : ''].join(' ')

  const onClick = (): void => onSelectMatch(match.id)

  return (
    <Box className={className} onClick={onClick}>
      <Box className={sidebarItemHeader}>
        {matchStatus !== MatchCheckInMatchStatus.Available && (
          <>
            {getMatchStatusIcon(matchStatus, theme)}
            <Box pr="2px" />
          </>
        )}
        <NxTypography className={ellipsis} variant="h3">
          {matchName}
        </NxTypography>
      </Box>
      <NxTypography variant="subtitle2">{match.esport.name}</NxTypography>
      <NxTypography>
        {dayjs(match?.scheduledStartsAt).format(DateFormats.PrettyPrintFormat)}
      </NxTypography>
    </Box>
  )
}
