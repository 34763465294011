import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  CompetitionModel,
  useAdjustAnnualPassMutation,
  useUpdateCurrentAnnualPassBySchoolIdMutation,
  useEnrollTeamInSeasonMutation,
  useGetAnnualPassProductByIdQuery,
  useGetMySchoolEnrollmentQuery,
  usePurchasePlanMutation,
  useTransferTeamToSeasonMutation,
  useUnenrollTeamFromSeasonMutation,
  refetchGetMySchoolEnrollmentQuery,
} from '@plvs/graphql'
import * as analytics from '@plvs/respawn/features/analytics'
import { useLocation } from 'react-router-dom'
import {
  asyncSeries,
  filterType,
  uniqueById,
  useAutoskipQuery,
  createEnrollmentProduct,
  EnrollmentProduct,
} from '@plvs/utils'
import { chain, clone, head, pick, uniq, uniqBy } from 'ramda'
import { compact } from 'ramda-adjunct'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Path } from '@plvs/const'
import {
  EnrolledPlayer,
  EnrolledPlayerNotOnTeam,
  EnrolledPlayerOnTeam,
  EnrollmentLeagueEsport,
  EnrollmentStatus,
  EnrollmentTeam,
  TeamEnrollmentUpdate,
  TeamLeagueSelectFn,
  UseEnrollmentOptions,
  UseEnrollmentReturn,
} from '@plvs/respawn/containers/enrollment/types'
import { evictBillingCache, getEnrolledTeams } from './enrollmentHelpers'

const getEnrolledPlayersOnTeam = (
  enrolledPlayers: EnrolledPlayer[]
): EnrolledPlayerOnTeam[] =>
  filterType<EnrolledPlayerOnTeam, EnrolledPlayerNotOnTeam>(
    (player) => ('teamId' in player && player.teamId ? player : null),
    enrolledPlayers
  )

const getEnrolledPlayersNotOnTeam = (
  enrolledPlayers: EnrolledPlayer[]
): EnrolledPlayerNotOnTeam[] =>
  filterType<EnrolledPlayerNotOnTeam, EnrolledPlayerOnTeam>(
    (player) => ('teamId' in player && player.teamId ? null : player),
    enrolledPlayers
  )

const createKey = (str1?: string, str2?: string): string => `${str1}-${str2}`

const getFinalEnrollmentUpdates = (
  updates: TeamEnrollmentUpdate[]
): {
  teamsToEnroll: TeamEnrollmentUpdate[]
  teamsToUnEnroll: TeamEnrollmentUpdate[]
  teamsToTransfer: TeamEnrollmentUpdate[]
} => {
  const usedTeams = new Set<string>()
  const teamsToEnroll: TeamEnrollmentUpdate[] = []
  const teamsToUnEnroll: TeamEnrollmentUpdate[] = []
  const teamsToTransfer: TeamEnrollmentUpdate[] = []
  for (let i = updates.length - 1; i >= 0; i -= 1) {
    const update = updates[i]
    if (!usedTeams.has(update.teamId)) {
      if (update.fromSeasonId !== update.toSeasonId) {
        if (update.fromSeasonId && update.toSeasonId) {
          teamsToTransfer.push(update)
        } else if (update.toSeasonId) {
          teamsToEnroll.push(update)
        } else {
          teamsToUnEnroll.push(update)
        }
      }
      usedTeams.add(update.teamId)
    }
  }
  return { teamsToEnroll, teamsToUnEnroll, teamsToTransfer }
}

export const useEnrollment = ({
  isCoach,
  metaseasonId,
  schoolId,
  includeOrganizationEnrollment = false,
}: UseEnrollmentOptions): UseEnrollmentReturn => {
  const { pathname } = useLocation()
  const flags = useFlags()
  const [initialPlayersSelected, setInitialPlayersSelected] = useState<
    EnrolledPlayer[] | null
  >(null)

  const [cachedMetaseasonId, setCachedMetaseasonId] =
    useState<string>(metaseasonId)

  const [teamEnrollmentUpdates, setTeamEnrollmentUpdates] = useState<
    TeamEnrollmentUpdate[]
  >([])

  const [enrolledPlayers, setEnrolledPlayers] = useState<EnrolledPlayer[]>([])
  const enrolledPlayersOnTeam = getEnrolledPlayersOnTeam(enrolledPlayers)
  const enrolledPlayersNotOnTeam = getEnrolledPlayersNotOnTeam(enrolledPlayers)

  const isCachedMetaseasonCurrent = cachedMetaseasonId === metaseasonId

  const numberOfPlayersOnTeams = enrolledPlayersOnTeam.length
  const subCount = enrolledPlayersNotOnTeam.length

  const numberOfPassesUsed = numberOfPlayersOnTeams + subCount

  const [enrollmentStatus, setEnrollmentStatus] = useState<EnrollmentStatus>(
    EnrollmentStatus.NotInitialized
  )
  const [error, setError] = useState<Error>()
  const [productId, setSelectedProduct] = useState<string | null>(null)
  const [competitionModelSelected, changeCompetitionModel] =
    useState<CompetitionModel>()

  const skipQuery = !(cachedMetaseasonId.length && schoolId)

  const [updateCurrentAnnualPassBySchoolId] =
    useUpdateCurrentAnnualPassBySchoolIdMutation()

  const {
    data,
    loading,
    error: queryError,
    refetch,
  } = useAutoskipQuery(useGetMySchoolEnrollmentQuery, {
    variables: {
      metaseasonId: cachedMetaseasonId,
      schoolId,
      isCoach,
      input: {
        metaseasonId: cachedMetaseasonId,
        organizationId: schoolId,
      },
      includeOrganizationEnrollment: includeOrganizationEnrollment && isCoach,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: skipQuery,
  })

  const teams: EnrollmentTeam[] = data?.school?.teams ?? []
  const hasEnrolledTeams = teams.some((team) => team.leagues?.length)
  const enrolledSeasonIds = new Set<string>(
    teams
      .map(
        (team) =>
          team.enrolledSeasons?.filter(
            (season) => season.metaseason?.id === metaseasonId
          )[0]?.id || ''
      )
      .filter((id) => id)
  )
  const seasonEnrollmentCount = useMemo(() => {
    return teams.reduce<Record<string, number>>((accum, team) => {
      const result = { ...accum }
      team.enrolledSeasons?.find((season) => {
        if (season?.metaseason?.id === metaseasonId) {
          if (!accum[season.id]) {
            result[season.id] = 0
          }
          result[season.id] += 1
          return true
        }
        return false
      })
      return result
    }, {})
  }, [teams])
  const enrolledTeamIds = getEnrolledTeams(metaseasonId, teams ?? []).map(
    ({ teamId }) => teamId
  )
  const enrolledTeamsCount = enrolledTeamIds.length

  const [adjustPlan] = useAdjustAnnualPassMutation({
    update: evictBillingCache as any,
  })
  const [purchasePlan] = usePurchasePlanMutation({
    update: evictBillingCache as any,
  })
  const [enrollTeamInSeason] = useEnrollTeamInSeasonMutation({
    update: evictBillingCache as any,
  })
  const [unenrollTeamFromSeason] = useUnenrollTeamFromSeasonMutation({
    update: evictBillingCache as any,
  })
  const [transferTeamToSeason] = useTransferTeamToSeasonMutation({
    update: evictBillingCache as any,
  })

  const allEsports = uniqBy(
    (slug) => slug,
    (data?.getLeaguesBySchoolId ?? [])
      .map((league) => league?.esport.slug)
      .filter((esport) => esport)
  )

  const allLeagues = data?.getLeaguesBySchoolId ?? []

  const competitionModels = uniq(
    compact(allLeagues.map(({ competitionModel }) => competitionModel))
  )

  const competitionModel = competitionModelSelected ?? head(competitionModels)
  // This filters for leagues of the curently selected competition model and for the current metaseason.
  const leagues = uniqueById<(typeof allLeagues)[0]>(
    allLeagues
      .filter((league) => {
        return competitionModel
          ? league?.competitionModel === competitionModel
          : true
      })
      .filter((league) =>
        (league?.seasons ?? []).some(
          (leagueSeason) => leagueSeason?.metaseasonId === cachedMetaseasonId
        )
      )
  )

  const leagueEsports = useMemo(() => {
    return leagues.reduce<Record<string, EnrollmentLeagueEsport>>(
      (accum, league) => {
        return { ...accum, [league.esport.id]: league.esport }
      },
      {}
    )
  }, [leagues])

  const seasons = chain((league) => league.seasons ?? [], leagues).filter(
    (leagueSeason) => leagueSeason.metaseasonId === cachedMetaseasonId
  )

  // This season should be used for display logic only. The context of season should be derived at the league level.

  const season = seasons.find((s) => s.metaseasonId === cachedMetaseasonId)

  const players = data?.school?.players ?? []

  const products: EnrollmentProduct[] = createEnrollmentProduct(
    data?.school?.annualPassProductsEligible ?? []
  )

  const hasAnnualPassInstance = !!(data?.school?.annualPassInstances ?? [])
    .length
  const currentAnnualPassInstance = head(
    data?.school?.annualPassInstances ?? []
  )
  const annualPassInstanceMetaseasonId =
    currentAnnualPassInstance?.productInstanceInfo?.initialMetaseasonId
  const currentProductId = currentAnnualPassInstance?.productId ?? ''
  const annualPassMetaseasonsId = useMemo(() => {
    return currentAnnualPassInstance?.seasonPassBundles?.map(
      (seasonPass) => seasonPass?.metaseason?.id ?? ''
    )
  }, [currentAnnualPassInstance])

  // With the introduction of Ignite8Seat, school's can have a product that isn't included in their eligible products.
  const {
    data: currentProductData,
    loading: currentProductLoading,
    called: currentProductLookupCalled,
    error: currentProductLookupError,
  } = useGetAnnualPassProductByIdQuery({
    variables: { id: currentProductId },
    skip: !currentProductId,
  })

  // Since above is done as a seprate query, there is a possibility there is a lag
  // time between we receive the currentProductId and the query to lookup the current
  // product data request is sent.
  // If there is an error in the API call, we'll just give the user benefit of the doubt
  // to continue, and let the next step pick up the error situation.
  const hasPendingProductLookup =
    !!currentAnnualPassInstance?.productId &&
    !currentProductLookupError &&
    (!currentProductLookupCalled || currentProductLoading)

  const currentAnnualPassProduct =
    (currentProductData?.annualPassProduct && [
      {
        ...currentProductData?.annualPassProduct,
        position: 2,
      },
    ]) ??
    []

  const currentAnnualPassEnrollmentProduct = head(
    createEnrollmentProduct(currentAnnualPassProduct)
  )

  const currentProduct =
    products.find(({ id }) => id === (currentProductId ?? productId)) ||
    currentAnnualPassEnrollmentProduct

  const product = products.find(({ id }) => id === productId) || currentProduct

  const ineligibleLeagues = allLeagues.filter(
    (league) =>
      league?.competitionModel === competitionModel &&
      !leagues.some((lg) => lg.id === league?.id)
  )

  const schoolName = data?.school?.name ?? ''
  const schoolLogo = data?.school?.logoUrl ?? ''

  const onProductSelected = useCallback(
    (selectedProduct: EnrollmentProduct | null): void => {
      setSelectedProduct((id) => {
        if (id === selectedProduct?.id) {
          return null
        }
        return selectedProduct?.id ?? null
      })
    },
    [setSelectedProduct]
  )

  const onPlayersSelected: TeamLeagueSelectFn = (selectedData) => {
    const isChecked = !!selectedData.toSeasonId
    const priorUpdates = teamEnrollmentUpdates.filter(
      (update) => update.teamId !== selectedData.teamId
    )
    const priorTeamUpdate = teamEnrollmentUpdates.find(
      (update) => update.teamId === selectedData.teamId
    )
    if (selectedData.fromSeasonId) {
      if (
        !priorTeamUpdate ||
        priorTeamUpdate.fromSeasonId === priorTeamUpdate.toSeasonId ||
        (selectedData.fromSeasonId !== priorTeamUpdate.fromSeasonId &&
          priorTeamUpdate.fromSeasonId !== selectedData.toSeasonId)
      ) {
        seasonEnrollmentCount[selectedData.fromSeasonId] -= 1
      }
    }
    if (selectedData.toSeasonId && selectedData.fromSeasonId) {
      if (
        priorTeamUpdate &&
        (priorTeamUpdate.fromSeasonId !== selectedData.fromSeasonId ||
          priorTeamUpdate.fromSeasonId === selectedData.toSeasonId)
      ) {
        seasonEnrollmentCount[selectedData.toSeasonId] += 1
      }
    }
    if (selectedData.toSeasonId && !selectedData.fromSeasonId) {
      if (priorTeamUpdate && priorTeamUpdate.toSeasonId) {
        seasonEnrollmentCount[priorTeamUpdate.toSeasonId] -= 1
      }
      if (seasonEnrollmentCount[selectedData.toSeasonId] === undefined) {
        seasonEnrollmentCount[selectedData.toSeasonId] = 0
      }
      seasonEnrollmentCount[selectedData.toSeasonId] += 1
    }
    // when users deselect from prior selections
    if (
      !selectedData.toSeasonId &&
      !selectedData.fromSeasonId &&
      priorTeamUpdate?.toSeasonId
    ) {
      seasonEnrollmentCount[priorTeamUpdate.toSeasonId] -= 1
    }
    setTeamEnrollmentUpdates([
      ...priorUpdates,
      pick(['teamId', 'fromSeasonId', 'toSeasonId'], selectedData),
    ])
    const { fromLeagueId, esportSlug } = selectedData
    const teamPlayerKeys = new Set(
      selectedData.members.map(({ id }) => createKey(id, fromLeagueId))
    )
    if (isChecked) {
      let result: EnrolledPlayer[]
      if (selectedData.fromLeagueId) {
        result = enrolledPlayers.filter(({ userId, leagueId }) => {
          return !teamPlayerKeys.has(createKey(userId, leagueId))
        })
      } else {
        result = [...enrolledPlayers]
      }
      selectedData.members.forEach((member) => {
        result.push({
          userId: member.id,
          esportSlug,
          leagueId: selectedData.toLeagueId || '',
          metaseasonId: cachedMetaseasonId,
          teamId: selectedData.teamId,
          teamFormat: selectedData.teamFormat,
          position: member?.position,
        })
      })
      setEnrolledPlayers(result)
    } else {
      const enrolledPlayersUpdated = enrolledPlayers.filter(
        ({ userId, leagueId }) => {
          return !teamPlayerKeys.has(createKey(userId, leagueId))
        }
      )
      setEnrolledPlayers(enrolledPlayersUpdated)
    }
  }

  const refreshPlayers = useCallback(async (): Promise<void> => {
    if (!skipQuery) {
      await refetch({
        metaseasonId: cachedMetaseasonId,
        schoolId,
        isCoach,
      })
    }
    setInitialPlayersSelected(null)
  }, [setInitialPlayersSelected])

  const purchaseProduct = async (): Promise<boolean> => {
    if (product?.id === currentProduct?.id) {
      return true
    }

    try {
      if (product?.id && !hasAnnualPassInstance) {
        const purchased = await purchasePlan({
          variables: {
            productId: product?.id || '',
            schoolId: schoolId || '',
            metaseasonId: cachedMetaseasonId,
          },
        })
        if (!purchased) {
          throw new Error('Unable to purchase plan.')
        }
      } else if (product?.id && hasAnnualPassInstance) {
        const adjusted = await adjustPlan({
          variables: {
            desiredProductId: product?.id || '',
            schoolId: schoolId || '',
            annualPassInstanceId: currentAnnualPassInstance?.id ?? '',
          },
        })
        if (!adjusted?.data?.adjustAnnualPass?.id) {
          throw new Error('Unable to modify current plan.')
        }
      }
      analytics.productPlanSelected({
        productId: product?.id ?? '',
        schoolId: schoolId ?? '',
      })
      // Add a slight delay to allow the backend to catch up.  We want to refetch
      // after everything has finalized on the serverside.
      await new Promise((r) => setTimeout(r, 500))

      await refreshPlayers()
      return true
    } catch (e: unknown) {
      setEnrollmentStatus(EnrollmentStatus.EnrollmentError)
      setError(e as Error)
      return false
    }
  }

  const presubmitTeamsToEnroll = useMemo(() => {
    let teamsToBeEnrolledIds = enrolledTeamIds

    const { teamsToEnroll, teamsToUnEnroll } = getFinalEnrollmentUpdates(
      teamEnrollmentUpdates
    )

    teamsToUnEnroll.forEach((teamUpdate) => {
      teamsToBeEnrolledIds = teamsToBeEnrolledIds.filter(
        (teamId) => teamId !== teamUpdate.teamId
      )
    })
    teamsToEnroll.forEach((teamUpdate) => {
      teamsToBeEnrolledIds.push(teamUpdate.teamId)
    })

    return teamsToBeEnrolledIds
  }, [teamEnrollmentUpdates, enrolledTeamIds])

  const hasTeamUpdates = useMemo(() => {
    const { teamsToEnroll, teamsToUnEnroll, teamsToTransfer } =
      getFinalEnrollmentUpdates(teamEnrollmentUpdates)
    return (
      !!teamsToEnroll.length ||
      !!teamsToUnEnroll.length ||
      !!teamsToTransfer.length
    )
  }, [teamEnrollmentUpdates])

  const changeEnrollment = useCallback(async (): Promise<boolean> => {
    setEnrollmentStatus(EnrollmentStatus.Enrolling)
    setError(undefined)
    const { teamsToEnroll, teamsToUnEnroll, teamsToTransfer } =
      getFinalEnrollmentUpdates(teamEnrollmentUpdates)

    try {
      if (teamsToEnroll.length) {
        const updateResults = await asyncSeries<boolean, TeamEnrollmentUpdate>(
          async ({ teamId, toSeasonId }) => {
            const result = await enrollTeamInSeason({
              variables: { teamId, seasonId: toSeasonId || '' },
            })
            return result.data?.enrollTeamInSeason.success ?? false
          },
          teamsToEnroll,
          false
        )
        if (updateResults.some((success) => !success)) {
          throw new Error('Unable to enroll teams.')
        }
      }

      if (teamsToUnEnroll.length) {
        const updateResults = await asyncSeries<boolean, TeamEnrollmentUpdate>(
          async ({ teamId, fromSeasonId }) => {
            const result = await unenrollTeamFromSeason({
              variables: { teamId, seasonId: fromSeasonId || '' },
            })
            return result.data?.unenrollTeamFromSeason.success ?? false
          },
          teamsToUnEnroll,
          false
        )
        if (updateResults.some((success) => !success)) {
          throw new Error('Unable to unenroll teams.')
        }
      }

      if (teamsToTransfer.length) {
        const updateResults = await asyncSeries<boolean, TeamEnrollmentUpdate>(
          async ({ teamId, toSeasonId, fromSeasonId }) => {
            const result = await transferTeamToSeason({
              variables: {
                teamId,
                originSeasonId: fromSeasonId || '',
                destinationSeasonId: toSeasonId || '',
              },
            })
            return result.data?.transferTeamToSeason.success ?? false
          },
          teamsToTransfer,
          false
        )
        if (updateResults.some((success) => !success)) {
          throw new Error('Unable to unenroll teams.')
        }
      }
      await refreshPlayers()
      // This is a best guess, this is not necessarily correct.
      // The useEffect below will correct it if it isn't.  We just
      // need the EnrollmentStatus state to be not `Enrolling`
      // so the UI can exit any interstitial modals.
      if (teamsToEnroll.length > 0) {
        setEnrollmentStatus(EnrollmentStatus.Enrolled)
      } else {
        setEnrollmentStatus(EnrollmentStatus.NotEnrolled)
      }
      return true
    } catch (e: any) {
      await refreshPlayers()
      setEnrollmentStatus(EnrollmentStatus.EnrollmentError)
      setError(e)
      return false
    }
  }, [cachedMetaseasonId, schoolId, enrolledPlayers, initialPlayersSelected])

  useEffect(() => {
    if (!initialPlayersSelected && players.length) {
      const playersEnrolledFiltered = players.filter(
        (player) => player?.leagues?.length
      )

      const playersEnrolled = chain(({ id, leagues: playerLeagues }) => {
        return (playerLeagues ?? []).map((league) => {
          const esportSlug = league.esport.slug

          const enrolledTeam = teams.find((team) => {
            return (
              team?.esport?.slug === esportSlug &&
              (team?.members ?? []).some((member) => member.id === id) &&
              team?.leagues?.length
            )
          })

          const teamId = enrolledTeam?.id

          return {
            esportSlug,
            leagueId: league?.id,
            metaseasonId: cachedMetaseasonId,
            ...(teamId ? { teamId } : {}),
            userId: id,
          }
        })
      }, playersEnrolledFiltered).filter((player) => player.leagueId)

      if (enrolledTeamIds.length > 0) {
        setEnrollmentStatus(EnrollmentStatus.Enrolled)
      } else {
        setEnrollmentStatus(EnrollmentStatus.NotEnrolled)
      }

      setInitialPlayersSelected(clone(playersEnrolled))
      setEnrolledPlayers(playersEnrolled)
    }
  }, [initialPlayersSelected, players, enrolledTeamIds])

  // Since we are supporting two concurrent metaseasons, enrolledPlayers needs to be updated when the metaseasonId changes.
  // By calling refreshPlayers() based on the metaseasonId, initialPlayersSelected is set to null, that way playersEnrolled will be
  // up to date.

  useEffect(() => {
    if (cachedMetaseasonId !== metaseasonId) {
      setCachedMetaseasonId(metaseasonId)
      refreshPlayers()
    }
  }, [metaseasonId])

  useEffect(() => {
    // Used to sync salesforce data
    if (loading) {
      return
    }
    if (pathname.includes(Path.Enrollment) && schoolId) {
      const defaultMetaseason = metaseasonId
      const availableAnnualPass = [...(annualPassMetaseasonsId ?? [])]
      const metaseasonsToUpdate = (
        defaultMetaseason
          ? [defaultMetaseason, ...availableAnnualPass]
          : [...availableAnnualPass]
      ).filter((metaseason) => metaseason)
      const callMutate = metaseasonsToUpdate.length

      const refetchQueries = skipQuery
        ? []
        : [
            refetchGetMySchoolEnrollmentQuery({
              metaseasonId: cachedMetaseasonId,
              schoolId,
              isCoach,
            }),
          ]
      if (callMutate) {
        if (schoolId && !hasAnnualPassInstance && flags.freeCompetition) {
          purchasePlan({
            variables: {
              schoolId: schoolId || '',
              metaseasonId: cachedMetaseasonId,
            },
            awaitRefetchQueries: true,
            refetchQueries,
            onError: (err): void => setError(err),
          })
        } else {
          updateCurrentAnnualPassBySchoolId({
            variables: {
              schoolId,
              metaseasonIds: metaseasonsToUpdate,
            },
            awaitRefetchQueries: true,
            refetchQueries,
            onError: (err): void => setError(err),
          })
        }
      }
    }
  }, [currentAnnualPassInstance?.productId, metaseasonId, pathname])

  useEffect(() => {
    if (queryError) {
      setError(queryError)
    }
  }, [queryError])

  return {
    changeCompetitionModel,
    changeEnrollment,
    annualPassInstanceMetaseasonId,
    hasTeamUpdates,
    competitionModel,
    competitionModels,
    enrolledPlayers,
    enrolledPlayersOnTeam,
    enrolledPlayersNotOnTeam,
    numberOfPassesUsed,
    enrolledTeamIds,
    enrolledTeamsCount,
    enrolledSeasonIds,
    error,
    esports: allEsports,
    leagueEsports: Object.values(leagueEsports),
    hasAnnualPassInstance,
    currentAnnualPassInstance,
    ineligibleLeagues,
    leagues,
    loading: loading || hasPendingProductLookup || !isCachedMetaseasonCurrent,
    onProductSelected,
    onPlayersSelected,
    products,
    product,
    purchaseProduct,
    refreshPlayers,
    season,
    schoolId,
    schoolName,
    schoolLogo,
    status: enrollmentStatus,
    hasEnrolledTeams,
    presubmitTeamsToEnroll,
  }
}
