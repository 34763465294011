/* istanbul ignore file */
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  menu: {
    maxHeight: '330px',
  },
  filterButton: ({
    isMenuOpen,
    shouldUseOverlayColors,
  }: {
    isMenuOpen: boolean
    shouldUseOverlayColors?: boolean
  }): CreateCSSProperties => ({
    alignItems: 'center',
    background: shouldUseOverlayColors ? 'rgba(0, 0, 0, 0.7)' : 'inherit',
    color: shouldUseOverlayColors
      ? theme.palette.OverlayColorOpWhite1
      : theme.palette.ColorTextBase,
    '& > div': {
      alignItems: 'center',
    },
    '& svg': {
      color: shouldUseOverlayColors
        ? `${theme.palette.OverlayColorOpWhite1} !important`
        : `${theme.palette.ColorBackgroundAccent} !important`,
    },
    '&:hover': {
      background: theme.palette.ColorBackgroundInvert,
      color: theme.palette.ColorTextInvert,

      '& svg': {
        color: `${theme.palette.ColorTextInvert} !important`,
      },
    },
    '&[disabled]': {
      border: 'unset',
      '& svg': {
        color: 'unset !important',
      },
    },
    ...(isMenuOpen && {
      background: theme.palette.ColorBackgroundInvert,
      color: theme.palette.ColorTextInvert,
      '& svg': {
        color: `${theme.palette.ColorTextInvert} !important`,
      },
    }),
  }),
}))
