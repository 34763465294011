import React, { ReactNode, lazy, Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import { NxSkeletonLoader } from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

const Box = lazy(() =>
  import('@material-ui/core').then((m) => ({ default: m.Box }))
)
const Fade = lazy(() =>
  import('@material-ui/core').then((m) => ({ default: m.Fade }))
)

const AppSignupButton = lazy(() => import('./AppSignupButton'))
const AppDrawerMobileToggle = lazy(() =>
  import('./drawer').then((m) => ({ default: m.AppDrawerMobileToggle }))
)
const RallyAppDrawer = lazy(() =>
  import('./drawer/RallyAppDrawer').then((m) => ({ default: m.RallyAppDrawer }))
)
const AppBar = lazy(() =>
  import('@plvs/rally/components/app/AppBar').then((m) => ({
    default: m.AppBar,
  }))
)
const GetSupport = lazy(() =>
  import('@plvs/rally/components/programEducation/GetSupport').then((m) => ({
    default: m.GetSupport,
  }))
)

const SCHEDULE_CONSULT_LINK = 'https://playvs022936.typeform.com/to/H3WFfKFI'

const IN_APP_PATHS = [
  Path.PublicTeam,
  Path.ExploreLeagues,
  Path.SchoolPublicPage,
  Path.ParentSignUpRequest,
]

export const AppNav: React.FC = () => {
  // Using identity query to figure out if we're authentidated
  const isXsScreen = useBreakpointXs()
  const location = useLocation()
  const { userId, loading, error } = useUserIdentityFn()

  const isAuthenticated = !!userId

  const loginStateKnown = !loading || !!error

  const publicExclusions =
    location && IN_APP_PATHS.some((path) => location.pathname.includes(path))

  const isInApp =
    location &&
    ((location.pathname.includes(Path.App) &&
      !location.pathname.includes(Path.MoreDetails) &&
      !location.pathname.includes(Path.Forbidden)) ||
      publicExclusions)

  const showSignup =
    !isAuthenticated && location && location.pathname.indexOf(Path.League) === 0

  const rightContent = (): ReactNode => {
    if (!loginStateKnown) {
      return <></>
    }

    if (isInApp) {
      return (
        <Suspense fallback={<NxSkeletonLoader />}>
          <Fade>
            <AppSignupButton />
          </Fade>
        </Suspense>
      )
    }

    const supportLink = showSignup ? SCHEDULE_CONSULT_LINK : 'intercom'
    const supportLinkTitle = showSignup
      ? 'Schedule a consultation'
      : 'Get Support'

    let title: string | undefined = showSignup
      ? 'Schedule a consultation'
      : 'Need help?'
    if (isXsScreen) {
      title = undefined
    }

    return (
      <Suspense fallback={<NxSkeletonLoader />}>
        <Fade>
          <>
            <GetSupport
              link={supportLink}
              linkTitle={supportLinkTitle}
              showSignup={showSignup}
              title={title}
            />
            <Box pl={2}>
              <AppSignupButton showLogout />
            </Box>
          </>
        </Fade>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<NxSkeletonLoader />}>
      <AppBar
        isInApp={isInApp}
        leftContent={
          isInApp ? <AppDrawerMobileToggle AppDrawer={RallyAppDrawer} /> : <></>
        }
        rightContent={rightContent()}
      />
    </Suspense>
  )
}
