import { MatchSettleRequestFragment } from '@plvs/graphql/generated'
import { DmrSeries, DmrSeriesTeam, DmrTeam } from '@plvs/utils'
import { MinimumSeries } from '../matchReportSeriesSidebar/MatchReportSeriesSidebar'
import {
  ReportOption,
  ReportOptionValue,
} from '../matchReportAccordion/MatchReportAccordion.types'

export const mapSeriesToMinimumSeries = (args: {
  series: DmrSeries[]
  myTeamId: string
  matchSettleRequest: MatchSettleRequestFragment | null
}): MinimumSeries[] => {
  const { series, myTeamId, matchSettleRequest } = args
  return series.map((currSeries) => {
    const mySeriesTeam: DmrSeriesTeam | null | undefined =
      currSeries.team1?.id === myTeamId ? currSeries.team1 : currSeries.team2
    const oppSeriesTeam: DmrSeriesTeam | null | undefined =
      currSeries.team1?.id === myTeamId ? currSeries.team2 : currSeries.team1
    const winningTeamId:
      | string
      | null
      | undefined = matchSettleRequest?.results?.seriesResults?.find(
      (currSeriesRes) => currSeriesRes.id === currSeries.id
    )?.winningTeamId

    const winningTeam: DmrSeriesTeam | null | undefined =
      mySeriesTeam?.id === winningTeamId ? mySeriesTeam : oppSeriesTeam

    return {
      players: [mySeriesTeam?.roster?.[0], oppSeriesTeam?.roster?.[0]].map(
        (rosterPlayer) => ({
          name: rosterPlayer?.name ?? '??',
          avatarUrl: rosterPlayer?.avatarUrl ?? undefined,
        })
      ),
      winner: winningTeamId
        ? {
            name: winningTeam?.roster?.[0]?.name ?? '??',
            avatarUrl: winningTeam?.roster?.[0]?.avatarUrl ?? undefined,
          }
        : undefined,
    }
  })
}

export const mapTeamToReportOption = (args: {
  team: DmrTeam | undefined
  isMyTeam: boolean
  isYouthProgram: boolean
}): ReportOption => {
  const { team, isMyTeam, isYouthProgram } = args
  const schoolName = team?.school?.name
  const schoolLogo = team?.school?.logoUrl
  return {
    value: isMyTeam ? ReportOptionValue.MyTeam : ReportOptionValue.OppTeam,
    title: team?.name ?? '??',
    subtitle: !isYouthProgram && schoolName ? schoolName : '',
    imgSrc: !isYouthProgram && schoolLogo ? schoolLogo : undefined,
    hashId: team?.id ?? undefined,
  }
}

export const mapSeriesToReportOptionArray = (args: {
  series: DmrSeries[]
  myTeamId: string
  isYouthProgram: boolean
  myTeam?: DmrTeam
  oppTeam?: DmrTeam
}): ReportOption[][] => {
  const { series, myTeamId, isYouthProgram, myTeam, oppTeam } = args
  return series.map((currSeries) => {
    const mySeriesTeam: DmrSeriesTeam | null | undefined =
      currSeries.team1?.id === myTeamId ? currSeries.team1 : currSeries.team2
    const oppSeriesTeam: DmrSeriesTeam | null | undefined =
      currSeries.team1?.id === myTeamId ? currSeries.team2 : currSeries.team1
    const mySchoolName = myTeam?.school?.name
    const oppSchoolName = oppTeam?.school?.name

    return [
      {
        value: ReportOptionValue.MyTeam,
        title: mySeriesTeam?.roster?.[0]?.name ?? '??',
        subtitle: !isYouthProgram && mySchoolName ? mySchoolName : '',
        imgSrc: mySeriesTeam?.roster?.[0]?.avatarUrl ?? undefined,
        hashId: mySeriesTeam?.roster?.[0]?.id,
      },
      {
        value: ReportOptionValue.OppTeam,
        title: oppSeriesTeam?.roster?.[0]?.name ?? '??',
        subtitle: !isYouthProgram && oppSchoolName ? oppSchoolName : '',
        imgSrc: oppSeriesTeam?.roster?.[0]?.avatarUrl ?? undefined,
        hashId: oppSeriesTeam?.roster?.[0]?.id,
      },
    ]
  })
}
