import { Provider } from '@plvs/graphql'
import { PlayStationNetworkConnection } from './PlaystationNetworkConnection'
import { EpicGamesConnection } from './EpicGamesConnection'
import { NintendoConnection } from './NintendoConnection'
import { ProviderConnection } from './ProviderConnection'

export const gameConnectionComponentMap: ComponentMap[] = [
  { Component: ProviderConnection, name: Provider.Riot },
]

export const platformConnectionComponentMap: ComponentMap[] = [
  { Component: ProviderConnection, name: Provider.Vesl },
  { Component: ProviderConnection, name: Provider.BattleDotNet },
  {
    Component: EpicGamesConnection,
    name: Provider.EpicGames,
  },
  {
    Component: ProviderConnection,
    name: Provider.Discord,
  },
  {
    Component: ProviderConnection,
    name: Provider.Spin,
  },
  {
    Component: NintendoConnection,
    name: Provider.Nintendo,
  },
  { Component: ProviderConnection, name: Provider.XBoxLive },
  {
    Component: PlayStationNetworkConnection,
    name: Provider.PlayStationNetwork,
  },
  {
    Component: ProviderConnection,
    name: Provider.Twitch,
  },
  {
    Component: ProviderConnection,
    name: Provider.Youtube,
  },
]

export interface ComponentMap {
  Component: any
  name: string
}
