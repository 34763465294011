import {
  GlobalUnreadMessageCount,
  globalUnreadMessagesVar,
  GlobalUnreadMessagesVarPayload,
} from '../models/GlobalChatConversations'

export const updateGlobalUnreadMessages = (
  updatedGlobalUnreadMessages: GlobalUnreadMessageCount
): GlobalUnreadMessagesVarPayload => {
  return globalUnreadMessagesVar({
    unreadMessagesByConversation: updatedGlobalUnreadMessages,
  })
}
