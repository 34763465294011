import { useState, useEffect } from 'react'

import { formatDuration } from '@plvs/utils'

export const getMsDifferenceFromNow = (
  date?: string | null | undefined
): number => (date ? +new Date(date) - +new Date() : 0)

interface UseCountdownReturn {
  ended: boolean
  formattedTimeLeft: string
  msLeft: number
}

export const useCountdown = ({
  date,
  refetchMatchStatus,
}: {
  date: string | null | undefined
  refetchMatchStatus(): Promise<void>
}): UseCountdownReturn => {
  const differenceFromNow = getMsDifferenceFromNow(date)
  const [msLeft, setMSLeft] = useState(differenceFromNow)
  const ended = msLeft <= 0

  useEffect(() => setMSLeft(differenceFromNow), [date])
  useEffect(() => {
    let ts: number
    if (!ended) {
      const newDifferenceFromNow = getMsDifferenceFromNow(date)
      ts = window.setTimeout(() => setMSLeft(newDifferenceFromNow), 1000)
    }
    return (): void => {
      clearTimeout(ts)
    }
  }, [msLeft])

  useEffect(() => {
    if (ended) {
      refetchMatchStatus()
    }
  }, [ended])

  return {
    msLeft,
    formattedTimeLeft: formatDuration({ ms: msLeft }),
    ended,
  }
}
