import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { makeStyles, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

const useStyles = makeStyles((theme) => ({
  schoolName: {
    color: theme.palette.common.white,
  },
  startContainer: ({ mobile }: { mobile: boolean }): CreateCSSProperties => ({
    alignItems: 'center',
    flexGrow: mobile ? 1 : 2,
    marginTop: theme.spacing(20),
    marginBottom: mobile ? '0' : '250px',
    width: mobile ? '100%' : '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  teamLabel: {
    marginTop: theme.spacing(3),
  },
  startButtonContainer: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
    '& button': {
      height: '36px',
      width: '211px',
    },
  },
}))

interface School {
  logoUrl?: string | null
  name?: string | null
}

interface Team {
  id: string
  name?: string | null
  school?: School | null
  esport?: {
    id: string
    rating: EsportRating
  } | null
}

interface MatchQueueStartRowProps {
  team?: Team | null
  onEnterQueue(): Promise<void>
}

export const MatchQueueStartRow: React.FC<MatchQueueStartRowProps> = ({
  team,
  onEnterQueue,
}) => {
  const mobile = useBreakpointSm()
  const {
    schoolName,
    teamLabel,

    startContainer,
    startButtonContainer,
  } = useStyles({ mobile })
  const theme = useTheme()
  const isYouthProgram = team?.esport?.rating === EsportRating.Restricted

  return (
    <Box
      className={startContainer}
      color={theme.palette.OverlayColorTextBase}
      data-testid="MatchQueueStartRow"
    >
      <Box alignItems="center" display="flex" flex="1" flexDirection="column">
        <NxUserAvatar
          avatarUrl={isYouthProgram ? undefined : team?.school?.logoUrl}
          hashId={team?.id ?? ''}
          size={NxAvatarSize.XXL}
        />
        <NxTypography className={teamLabel} color="inherit" variant="h1">
          {team?.name}
        </NxTypography>
        {!isYouthProgram && (
          <NxTypography
            className={schoolName}
            color="inherit"
            variant="subtitle2"
          >
            {team?.school?.name}
          </NxTypography>
        )}
      </Box>
      <Box className={startButtonContainer} display="flex" flex="1">
        <NxButton
          data-testid="MatchQueuePage_EnterQueueButton"
          label="Start Queue"
          onClick={async (): Promise<void> => onEnterQueue()}
          variant="primary"
        />
      </Box>
    </Box>
  )
}
