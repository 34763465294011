import React from 'react'

import { NxTextInput } from '@playvs-inc/nexus-components'

import { formErrorToString } from '@plvs/utils'
import type { InputProps } from './InputProps.type'

export const DateOfBirthInput: React.FC<InputProps> = React.forwardRef(
  ({ error }, ref) => {
    return (
      <NxTextInput
        ref={ref}
        data-testid="dateOfBirth"
        fullWidth
        helperText={formErrorToString(error)}
        label="Date of Birth MM/DD/YY"
        name="dateOfBirth"
        // Setting style inline because className override base style
        style={{ height: '54px' }}
        type="text"
        variant={error ? 'error' : 'default'}
      />
    )
  }
)
