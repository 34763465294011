import React from 'react'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { Table } from '@plvs/rally/components/table'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

export const PhaseStats: React.FC<{
  columns: any[]
  data: any[]
  loading: boolean
}> = ({ columns, data, loading }) => (
  <MatchCard px={0} py={4}>
    <WaitTillLoaded loading={loading}>
      <Table columns={columns} data={data} />
    </WaitTillLoaded>
  </MatchCard>
)
