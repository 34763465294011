import { SchoolType } from '@plvs/graphql'
import { AppState } from './state'

export enum OnboardingActionTypes {
  SchoolTypeSet = 'school-type-set',
}

export interface SchoolTypeSet {
  type: OnboardingActionTypes.SchoolTypeSet
  payload: {
    schoolType: SchoolType | null
  }
}

export type OnboardingActions = SchoolTypeSet

export const reduceOnboarding = (
  state: AppState,
  { type, payload }: OnboardingActions
): AppState => {
  switch (type) {
    case OnboardingActionTypes.SchoolTypeSet:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
        },
      }

    default:
      return state
  }
}
