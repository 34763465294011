import React from 'react'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { PillButton } from './PillButton'
import {
  CategoryInterface,
  PlayerStatRow,
  TopPerformers,
} from './TopPerformers'
import { UserCluster } from '../../cluster'
import { TableColumn } from '../../table'

export const PlayerPillButton: React.FC<{
  onClick(): void
  row: PlayerStatRow
  selected?: boolean
  subtitle: string
  value: string
}> = ({ onClick, row, selected, subtitle, value }) => (
  <PillButton
    avatarUrl={row.player?.avatarUrl}
    onClick={onClick}
    selected={selected}
    subtitle={subtitle}
    title={row.player?.name}
    value={value}
  />
)

export const playerColumn: TableColumn<PlayerStatRow> = {
  Header: 'Player',
  Cell: ({ player }) => (
    <UserCluster
      avatarSize={NxAvatarSize.SM}
      avatarUrl={player?.avatarUrl}
      id={player?.id}
      pb={1.5}
      pt={1.5}
      title={player?.name}
    />
  ),
  minWidth: 266,
}

export const PlayerTopPerformers: React.FC<{
  categories: CategoryInterface[] // this is the categories for an esport
  data: PlayerStatRow[] // this would be a row of stats
}> = ({ categories, data }) => {
  return (
    <TopPerformers
      categories={categories}
      data={data}
      entityColumn={playerColumn}
      PillButtonComponent={PlayerPillButton}
    />
  )
}
