import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  NotificationLightMediumDarkSecondary,
  NotificationLightMediumLightSecondary,
} from '@playvs-inc/nexus-spots'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useStyles } from '../AppDrawerToggle.styles'

export const EmptyNotifications: React.FC<{ onClose?: () => void }> = ({
  onClose,
}) => {
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })

  return (
    <Box
      alignItems="center"
      data-testid="EmptyNotifications_EmptyNotificationsContainer"
      display="flex"
      flexDirection="column"
      onClick={onClose}
    >
      {theme.palette.type === 'dark' ? (
        <NotificationLightMediumDarkSecondary />
      ) : (
        <NotificationLightMediumLightSecondary />
      )}
      <NxTypography className={classes.emptyTitle} variant="h3">
        No Notifications Yet
      </NxTypography>
      <NxTypography className={classes.emptyBody} variant="body1">
        Check back for updates on upcoming matches and tasks that need to be
        completed
      </NxTypography>
    </Box>
  )
}
