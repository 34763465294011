import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { isNil } from 'ramda'
import { Erroralt, LeagueOutlined } from '@playvs-inc/nexus-icons'
import { ReportAccordionItem } from './ReportAccordionItem'
import { useStyles } from './MatchReportAccordion.styles'
import {
  GameSelectionValue,
  ReportOption,
  ReportOptionValue,
} from './MatchReportAccordion.types'
import { MatchReportAccordionExpandedContent } from './MatchReportAccordionExpandedContent'

type MatchReportAccordionProps = {
  /**
   * Index of expandedGameIndex item. Defaults to 0.
   * Use -1 for no expansion.
   */
  expandedGameIndex?: number
  seriesIndex: number
  numberOfGames: number
  options: ReportOption[]
  values: GameSelectionValue[]
  loadingContent: boolean

  /** Event handlers */
  onSelectOption: (index: number, newValue: GameSelectionValue) => void
  onClick?: (index: number) => void
  onEdit?: (index: number) => void

  /**
   * Values should be an ordered list.
   */
}

export const MatchReportAccordion: React.FC<MatchReportAccordionProps> = ({
  expandedGameIndex,
  seriesIndex,
  numberOfGames = 3,
  options,
  values,
  loadingContent,
  onSelectOption,
  onClick,
  onEdit,
}) => {
  const styles = useStyles()

  const handleClick = (index: number): void => {
    onClick?.(index)
  }

  const handleEdit = (index: number): void => {
    onEdit?.(index)
  }

  return (
    <>
      {new Array(numberOfGames).fill(0).map((_item, index) => {
        return (
          <ReportAccordionItem
            // eslint-disable-next-line react/no-array-index-key
            key={`report-accordion-item-${index}`}
            content={
              <div className={styles.accordionUnexpanded}>
                <NxTypography colorToken="ColorTextAlt" variant="body2">
                  Game {index + 1}
                </NxTypography>

                {!isNil(values[index]?.value) && (
                  <NxTypography
                    className={styles.accordionValue}
                    data-cy="gameWinner"
                    variant="body4"
                  >
                    {values[index].value === ReportOptionValue.Incomplete ? (
                      <>
                        <Erroralt />
                        Incomplete Game
                      </>
                    ) : (
                      <>
                        <LeagueOutlined />
                        {options[values[index].value].title}
                      </>
                    )}
                    <button
                      className={styles.editButton}
                      onClick={(): void => handleEdit(index)}
                      type="button"
                    >
                      <NxTypography color="inherit" variant="body4">
                        Edit
                      </NxTypography>
                    </button>
                  </NxTypography>
                )}
              </div>
            }
            expandedContent={
              <MatchReportAccordionExpandedContent
                gameIndex={index}
                loadingContent={loadingContent}
                onSelectOption={onSelectOption}
                options={options}
                seriesIndex={seriesIndex}
                value={values[index]}
              />
            }
            isComplete={!isNil(values[index]?.value)}
            isExpanded={expandedGameIndex === index}
            onClick={(): void => handleClick(index)}
          />
        )
      })}
    </>
  )
}
