import { ChatRole } from '@plvs/graphql/generated'
import { MenuGroup } from '@playvs-inc/nexus-components'
import { UseMatchChatReturn } from './hooks/hooks.types'

export interface ChatWrapperProps {
  matchId?: string
  teamIds?: string[]
  isCheckpoint?: boolean
  chatRole: ChatRole
}

export enum ChatTab {
  All = 'all',
  Coach = 'coach',
}

export type ConditionalChatProps =
  | {
      isGlobal: true
      teamName: string
      esportSlug: string | null
      isCoachChat: boolean
      onClose(): void
      triggerPulse: boolean
      onExpanded(): void
      expanded: boolean
      className?: string
      menuOptions: MenuGroup[]
      menuBackgroundColor?: string
    }
  | {
      isGlobal: false
      teamName?: string
      esportSlug?: string
      isCoachChat?: boolean
      onClose?(): void
      triggerPulse?: boolean
      onExpanded?(): void
      expanded?: boolean
      className?: string
      menuOptions?: MenuGroup[]
      menuBackgroundColor?: string
    }

type ChatProps = Omit<UseMatchChatReturn, 'participants'> & {
  matchId?: string
  teamIds?: string[]
  isCheckpoint?: boolean
  chatRole: ChatRole
  loadingConversationState?: boolean
  isStateClosed?: boolean
}

export type MatchChatProps = ChatProps & ConditionalChatProps
