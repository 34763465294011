/* istanbul ignore file */
// Contains temporary hard coded assumptions.

import { Box, Container, makeStyles, useTheme } from '@material-ui/core'
import { EnrollmentProduct, assert } from '@plvs/utils'
import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'
import { Page } from '@plvs/rally/pages/page/Page'

import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'

import { IntercomArticleMappings } from '@plvs/const'
import { PlasmicComponent } from '@plasmicapp/loader-react'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { useGetHelpLinksQuery } from '@plvs/graphql/generated'
import { EnrollmentPlanSelectionPassInfoModal } from './EnrollmentPlanSelectionPassInfoModal'
import CheckerSquareLight from '../../../assets/decorations/checker-square-slanted-light.svg'
import CheckerSquareDark from '../../../assets/decorations/checker-square-slanted-dark.svg'
import StarSquareLight from '../../../assets/decorations/star-square-light.svg'
import StarSquareDark from '../../../assets/decorations/star-square-dark.svg'
import { isEarlyBird22 } from './plan-cards/helpers'

const YOUTUBE_VID_URL = 'https://www.youtube.com/embed/tAviW2I_NoM'
const YOUTUBE_VID_MAX_HEIGHT = '516px'
const YOUTUBE_VID_MAX_WIDTH = '910px'
const YOUTUBE_VID_ASPECT_RATIO = '1.77734375/1'
export const PER_SEAT_SLUG = 's2023-pay-by-player-single'

const useStyles = makeStyles((theme) => ({
  inlineLink: {
    display: 'inline-flex',
  },
  passModalLink: {
    display: 'inline-flex',
    flexDirection: 'row',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: `${theme.palette.ColorTextLink} !important`,
  },

  bold: {
    fontWeight: 600,
  },
  inline: {
    display: 'inline',
  },
  responsiveVideoContainer: {
    position: 'relative',
    resize: 'horizontal',
    aspectRatio: YOUTUBE_VID_ASPECT_RATIO,
    maxHeight: YOUTUBE_VID_MAX_HEIGHT,
    maxWidth: YOUTUBE_VID_MAX_WIDTH,
    width: '100%',
    height: 'auto',
    zIndex: 10,
  },
  responsiveVideoFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  bgDecorLeft: {
    position: 'absolute',
    zIndex: 0,
    left: '-8%',
    top: '-20%',
  },
  bgDecorLeftMobile: {
    position: 'absolute',
    zIndex: 0,
    left: '-8%',
    top: '-35%',
  },
  bgDecorRight: {
    position: 'absolute',
    zIndex: 0,
    right: '-3%',
    top: '-16%',
  },
  bgDecorRightMobile: {
    position: 'absolute',
    zIndex: 0,
    right: '5%',
    bottom: '-6%',
  },
  benefitsCardPaper: {
    display: 'flex',
    flexBasis: `${theme.spacing(42)}px`,
    flexGrow: 1,
    flexShrink: 1,
  },
  benefitsIcon: {
    '& svg': {
      transform: 'scale(1.6)',
      marginTop: '30%',
    },
  },
  container: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}))

type EnrollmentPlanSelectionProps = {
  onPlanContinue: () => void
  products?: EnrollmentProduct[]
  onProductSelected: (plan: EnrollmentProduct) => void
  selectedProduct: EnrollmentProduct | null
}

export const EnrollmentPlanSelection: React.FC<EnrollmentPlanSelectionProps> = ({
  onPlanContinue,
  products = [],
  onProductSelected,
  selectedProduct,
}) => {
  const { data } = useGetHelpLinksQuery()
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useBreakpointXs()
  const [initialProduct] = useState<EnrollmentProduct | null>(selectedProduct)

  const { enqueueSnackbar } = useSnackbar()
  const [purchaseInProgress, setPurchaseInProgress] = useState<boolean>(false)
  const [showPassInfoModal, setShowPassInfoModal] = useState<boolean>(false)

  const isEarlyBird = isEarlyBird22()
  const pricingFaqLink =
    data?.getLinks?.find((link) => link.slug === 'pricing-faqs')?.url ??
    IntercomArticleMappings.pricingFaq
  const planMap = useMemo(() => {
    if (products.length === 0) {
      return {
        perPlayer: null,
        unlimited: null,
      }
    }

    // Hard coded slug mappings.  These should match up exactly with how they
    // are entered on the backend via `createAnnualPassProduct`
    // mutation.
    return {
      perPlayer: products.find((product) => product.slug === PER_SEAT_SLUG),
      unlimited: products.find((product) => product.slug === 's2023-unlimited'),
    }
  }, [products])

  // Since our updated page doesn't have a 2 step select product then hit Continue,
  // a useEffect() is added to automatically continue after the useEnrollment hook
  // finishes processing the selection change.
  useEffect(() => {
    if (!selectedProduct || purchaseInProgress) {
      return
    }

    if (selectedProduct.id === initialProduct?.id) {
      return
    }

    setPurchaseInProgress(true)
    onPlanContinue()
  }, [selectedProduct, purchaseInProgress])

  const handleSelectUnlimited = useCallback(() => {
    assert(planMap.unlimited)
    const product = planMap.unlimited
    try {
      onProductSelected(product)
      onPlanContinue()
    } catch (err: unknown) {
      enqueueSnackbar('Unable to complete your selection.', {
        variant: 'error',
      })
    }
  }, [planMap])

  const handleSelectPayBySeat = useCallback(async () => {
    assert(planMap.perPlayer)
    const product = planMap.perPlayer
    try {
      onProductSelected(product)
      onPlanContinue()
    } catch (err: unknown) {
      enqueueSnackbar('Unable to complete your selection.', {
        variant: 'error',
      })
    }
  }, [planMap])

  // Display props
  const isDark = theme.palette.type === 'dark'

  return (
    <>
      <Page data-testid="EnrollmentPlanSelection__Page">
        <Box
          alignItems="center"
          bgcolor="ColorBackgroundBody"
          data-testid="EnrollmentPlanSelection__Container"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mt={2}
          width="100%"
        >
          <Container className={classes.container} maxWidth="md">
            <PlasmicComponent
              component="EnrollmentPlanSelection"
              componentProps={{
                selectPlayerPlanPass: {
                  onClick: handleSelectPayBySeat,
                },
                selectUnlimitedPlan: {
                  onClick: handleSelectUnlimited,
                },
                infoIcon: {
                  onClick: setShowPassInfoModal,
                },
              }}
            />
          </Container>
          <Box display="flex" mt={5}>
            <NxTypography
              color="textPrimary"
              component="span"
              style={{ textAlign: 'center' }}
              variant="h3"
            >
              Want to know more about your player passes?
            </NxTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={1}>
            <NxTypography
              color="textPrimary"
              component="span"
              style={{ textAlign: 'center' }}
              variant="body1"
            >
              You can read{' '}
              <NxTextLink
                className={classes.inlineLink}
                color="primary"
                data-testid="EnrollmentPlanSelection__PassModalLink"
                label="how player passes work"
                onClick={(): void => {
                  setShowPassInfoModal(true)
                }}
                variant="body1"
              />{' '}
              or if you have further pricing questions you can view our{' '}
              <NxTextLink
                className={classes.inlineLink}
                display="inline"
                label="Pricing FAQ's"
                onClick={(): void => {
                  showIntercomArticle(pricingFaqLink)
                }}
              />
            </NxTypography>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            mb={5}
            mt={2}
          >
            {isEarlyBird && (
              <NxTypography
                color="textPrimary"
                style={{ textAlign: 'center' }}
                variant="body1"
              >
                Early Enrollment Pricing applies only if you sign your PlayVS
                contract before July 31st, 2022.
              </NxTypography>
            )}
            <NxTypography
              color="textPrimary"
              style={{ textAlign: 'center' }}
              variant="body4"
            >
              Your school will not be charged until after you sign your PlayVS
              contract.
            </NxTypography>
          </Box>
        </Box>
        {/* // Lower Section */}
        <Box
          alignItems="center"
          bgcolor="ColorBackgroundAlt2"
          display="flex"
          flexDirection="column"
          pb={10}
          position="relative"
          pt={isMobile ? 5 : 7}
          px={2}
          width="100%"
        >
          <Box my={1}>
            <NxTypography
              color="textPrimary"
              style={{ zIndex: 10, textAlign: 'center' }}
              variant="h1"
            >
              Experience the PlayVS Advantage
            </NxTypography>
          </Box>

          {/* // Video */}
          <Box
            className={classes.responsiveVideoContainer}
            flexShrink={1}
            mt={4}
            mx={2}
            position="relative"
          >
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={classes.responsiveVideoFrame}
              frameBorder={0}
              height={YOUTUBE_VID_MAX_HEIGHT}
              src={YOUTUBE_VID_URL}
              title="YouTube video player"
              width={YOUTUBE_VID_MAX_WIDTH}
            />
            {isMobile && (
              <img
                alt="background decoration"
                className={classes.bgDecorLeftMobile}
                src={isDark ? CheckerSquareDark : CheckerSquareLight}
              />
            )}
            {!isMobile && (
              <img
                alt="background decoration"
                className={classes.bgDecorLeft}
                src={isDark ? CheckerSquareDark : CheckerSquareLight}
              />
            )}
            {!isMobile && (
              <img
                alt="background decoration"
                className={classes.bgDecorRight}
                src={isDark ? StarSquareDark : StarSquareLight}
              />
            )}
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            maxWidth={YOUTUBE_VID_MAX_WIDTH}
            mt={1}
            position="relative"
            width="100%"
          >
            {/* Edit component at: https://studio.plasmic.app/projects/wtykeqQGhNwNVuoCkadKJS */}
            <Box zIndex={10}>
              <PlasmicComponent
                component="EnrollmentPlanSelectionBenefits"
                css={{ padding: 0 }}
              />
            </Box>
            {isMobile && (
              <img
                alt="background decoration"
                className={classes.bgDecorRightMobile}
                src={isDark ? StarSquareDark : StarSquareLight}
              />
            )}
          </Box>
        </Box>
      </Page>
      {showPassInfoModal && (
        <EnrollmentPlanSelectionPassInfoModal
          onClose={(): void => setShowPassInfoModal(false)}
        />
      )}
    </>
  )
}
