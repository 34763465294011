import { Box, makeStyles } from '@material-ui/core'
import { stateNames } from '@plvs/const'
import {
  SchoolType,
  useAddChildToSchoolMutation,
} from '@plvs/graphql/generated/graphql'
import { noop } from 'ramda-adjunct'
import { OnboardSelectField } from '@plvs/rally/components/onboard/OnboardSelectField'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import React, { ChangeEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NxTypography } from '@playvs-inc/nexus-components'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { SchoolSearchForm } from '@plvs/rally/containers/onboard/v2/OnboardSchoolSelect/OnboardSchoolSelect.SchoolSearchForm'
import { SchoolPreview } from '@plvs/rally/containers/onboard/v2/OnboardSchoolSelect/OnboardSchoolSelect.SchoolPreview'
import { CoachCallout } from '@plvs/rally/containers/onboard/v2/OnboardSchoolSelect/CoachCallout'
import { SchoolId } from '@plvs/rally/containers/onboard/v2/OnboardSchoolSelect/OnboardSchoolSelect.types'
import { useSnackbar } from 'notistack'
import { useOnboardingContext } from '@plvs/rally/containers/onboard/v2/OnboardingContext'
import { ChildOnboardPath } from './childOnboardConst'
import { getNextPath } from './helpers'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  schoolName: {
    marginTop: theme.spacing(1),
  },
  schoolLocation: {
    marginTop: theme.spacing(0.5),
  },
}))

export const OnboardChildSchool: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams<{ childId: string | undefined }>()

  const { data: onboardData } = useOnboardingContext()

  const [province, setProvince] = useState<string | ''>('')

  const [selectedSchoolId, setSelectedSchoolId] = useState<SchoolId | null>()

  const [numCoaches, setNumCoaches] = useState(0)

  const [
    addChildToSchool,
    { loading: isMutating },
  ] = useAddChildToSchoolMutation()

  const requiredFieldsComplete = selectedSchoolId

  // Form Handlers
  const onSubmit = async (evt: React.SyntheticEvent): Promise<void> => {
    evt.preventDefault()

    const childId = onboardData.childId || params.childId
    const gradYear = +(onboardData.graduationYear as number)
    try {
      await addChildToSchool({
        variables: {
          input: {
            gradYear,
            schoolId: `${selectedSchoolId}`,
            userId: childId as string,
          },
        },
      })

      navigate(
        getNextPath({
          childId: params.childId,
          path: ChildOnboardPath.ChildReview,
        })
      )
    } catch (e) {
      enqueueSnackbar('Error adding user to school', { variant: 'error' })
    }
  }

  const setSelectedSchool = (schoolId: SchoolId): void => {
    setSelectedSchoolId(schoolId)
  }

  const onSchoolClear = (): void => {
    setSelectedSchoolId(null)
  }

  return (
    <WaitTillLoaded>
      <form className={classes.form} noValidate onSubmit={onSubmit}>
        <QuestionnaireCard
          childWidth={400}
          disableContinue={!requiredFieldsComplete}
          isContinuing={isMutating}
          mobileHeightOverride="100%"
          onContinue={noop}
          subtitle="We support middle schools across all of the United States and Canada."
          title="Find your Child’s School"
        >
          <Box py={1} width="100%">
            <NxTypography variant="body2">Enter State/Province</NxTypography>
          </Box>
          <OnboardSelectField
            defaultValue={province}
            name="state"
            onChange={(
              e: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ): void => {
              const value = e?.target?.value ? (e.target.value as string) : ''
              setProvince(value)
            }}
            prompt="State/Province"
          >
            {Object.entries(stateNames).map(([abbr, name]) => (
              <option key={abbr} value={abbr}>
                {name}
              </option>
            ))}
          </OnboardSelectField>

          <SchoolSearchForm
            onClear={onSchoolClear}
            onSchoolSelected={setSelectedSchool}
            onSuggestSchool={noop}
            province={province}
            schoolType={SchoolType.MiddleSchool}
            titleTypographyVariant="body2"
          />

          {!!selectedSchoolId && (
            <>
              <SchoolPreview
                onNumCoaches={setNumCoaches}
                schoolId={selectedSchoolId}
                setIsCifSchool={noop}
              />
              <CoachCallout numCoaches={numCoaches} />
            </>
          )}
        </QuestionnaireCard>
      </form>
    </WaitTillLoaded>
  )
}
