import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { isFunction } from 'ramda-adjunct'
import { useState } from 'react'

import { cleanGraphQLError } from '@plvs/utils'

export interface UseMutationProps<Input, Mutation, MutationVariables> {
  MutationDocument: any
  onSuccess?(result: FetchResult<Mutation>): void
  refetchQueries?: { query: any }[]
  snackError?: string | ((message: string) => string)
  snackSuccess?: string
  snackSuccessWarning?: string
  variables(input: Input): MutationVariables
}

export interface UseMutationReturn2 {
  error: Error | undefined
  errorString: string
  loading: boolean
  resetError(): void
}

type UseMutationReturn<Input> = [
  (input: Input) => Promise<void>,
  UseMutationReturn2
]

export function useDefaultMutation<Input, Mutation, MutationVariables>({
  MutationDocument,
  onSuccess,
  refetchQueries = [],
  snackError,
  snackSuccess,
  snackSuccessWarning,
  variables,
}: UseMutationProps<Input, Mutation, MutationVariables>): UseMutationReturn<
  Input
> {
  // mutate
  const [mutate, { loading }] = useMutation<Mutation, MutationVariables>(
    MutationDocument
  )

  // error
  const [error, setError] = useState<Error>()
  const resetError = (): void => setError(undefined)

  // notifications
  const { enqueueSnackbar } = useSnackbar()

  // submit
  const submit = async (input: Input): Promise<void> => {
    if (!loading) {
      setError(undefined)
      try {
        const result = await mutate({
          awaitRefetchQueries: true,
          refetchQueries,
          variables: variables(input),
        })
        const mutationErrors = result?.errors ?? []

        // Here, we enforce stricter success checking by taking a peek at the
        //  property that the mutation result comes in on.
        // ex. gql`
        //   mutation myMutationName($id: ID!) {
        //     actualMutationName(id: $id)
        //   }
        // `
        // We're doing `result.data.actualMutationName`.
        // @ts-ignore
        const accessor: string =
          MutationDocument.definitions[0].selectionSet.selections[0].name.value
        const firstMutationData = (result?.data as any)?.[accessor]
        if (!firstMutationData || mutationErrors.length) {
          throw mutationErrors[0] || new Error('Error: Invalid response.')
        } else {
          if (onSuccess) onSuccess(result)
          if (snackSuccess)
            enqueueSnackbar(snackSuccess, { variant: 'success' })
          if (snackSuccessWarning)
            enqueueSnackbar(snackSuccessWarning, { variant: 'warning' })
        }
      } catch (e: any) {
        setError(e)
        if (isFunction(snackError)) {
          enqueueSnackbar(snackError(cleanGraphQLError(e.message)), {
            variant: 'error',
          })
        } else if (snackError) {
          enqueueSnackbar(snackError, { variant: 'error' })
        }
      }
    }
  }

  return [
    submit,
    {
      error,
      errorString:
        error && error.message ? cleanGraphQLError(error.message) : '',
      loading,
      resetError,
    },
  ]
}
