import {
  GetCheckInMatchesQuery,
  GetMetaseasonByIdQuery,
  TeamQuery,
} from '@plvs/graphql'

export type MatchCheckInMatch = NonNullable<
  NonNullable<GetCheckInMatchesQuery['getMatches']>['matches']
>[0]

export type MatchCheckInStatusMap = {
  [key: string]: MatchCheckInMatchStatus
}

export type MatchCheckInMetaSeason = NonNullable<
  GetMetaseasonByIdQuery['metaseason']
>

export type MatchCheckInTeam = NonNullable<TeamQuery['team']>

export enum MatchCheckInMatchStatus {
  Available = 'available',
  CheckedIn = 'checked-in',
  Forfeited = 'forfeited',
  OpponentForfeited = 'opponent-forfeited',
  RescheduleRequested = 'reschedule-requested',
  Rescheduled = 'rescheduled',
  Closed = 'closed',
}
