import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'
import { Colors, darken } from '../colors'

export const MuiButton = (theme: Theme): Record<string, CSSProperties> => ({
  contained: {
    backgroundColor: Colors.Grey5,
    boxShadow: theme.shadows[0],
    '&$focusVisible': {
      boxShadow: theme.shadows[1],
    },
    '&:active': {
      boxShadow: theme.shadows[3],
    },
    whiteSpace: 'nowrap',
  },
  containedPrimary: {
    color: Colors.White,
    backgroundColor: Colors.Orange,
    '&:hover': {
      backgroundColor: darken(Colors.Orange, 0.1),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: Colors.Orange,
      },
    },
  },
  /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
  containedSecondary: {
    color: Colors.Grey1,
    backgroundColor: Colors.Grey5,
    '&:hover': {
      backgroundColor: darken(Colors.Grey5, 0.05),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: Colors.Grey5,
      },
    },
  },
  outlined: {},
  root: {
    borderRadius: 8,
    padding: '7px 16px',
    textAlign: 'center',
  },
  sizeSmall: {
    height: 32,
    padding: '0px 12px',
  },
  text: {
    padding: 8,
  },
  startIcon: {
    marginLeft: -2,
  },
})
