import { useEffect } from 'react'
import axios from 'axios'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AppVersion, reloadWindow } from './helpers'

dayjs.extend(relativeTime)

export const initClientVersion = async (version: string): Promise<void> => {
  try {
    const versionRequest = await axios.get('/version')
    if (version && versionRequest?.data !== AppVersion.Stable) {
      reloadWindow()
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (!err.response || err.response.status >= 400) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
}

export const InitClient: React.FC = () => {
  useEffect(() => {
    const initFunction = async (): Promise<void> =>
      // @ts-ignore
      initClientVersion(window.version ?? '')
    setInterval(initFunction, 600000)
  }, [])

  return null
}
