import {
  CompetitionInterval,
  EsportPosition,
  Metaseason,
  Season,
  User,
  UserSeasonPass,
} from '@plvs/graphql/generated'
import { ConnectedAccountProviders, MinimalMetaseason } from '@plvs/utils'

export type Position = {
  id: string
  positionId: string | null
  positionIndex: number | null
  currentUser: {
    hasConsumedPass?: boolean | undefined
    isPlayer: boolean
  }
}

export type Interval = Pick<
  CompetitionInterval,
  'name' | 'startsAt' | 'endsAt' | 'tense' | 'firstDay' | 'lastDay'
> & {
  position: number
}

export type IntervalWithEnrolledSeasons = Interval & {
  enrolledSeasons?:
    | Pick<
        Season,
        | 'endsAt'
        | 'id'
        | 'registrationStartsAt'
        | 'teamRegistrationEndsAt'
        | 'type'
      >[]
    | undefined
    | null
}

export type DefaultMetaseason = Pick<
  Metaseason,
  'id' | 'name' | 'startsAt' | 'endsAt' | 'isPromoted'
>

export const openSubPosition = {
  title: 'Position Available',
  position: 'Sub',
  positionId: null,
  positionIndex: undefined,
}

export type TeamPlayer = Pick<User, 'id' | 'name' | 'avatarUrl'> & {
  seasonPasses:
    | Pick<UserSeasonPass, 'id' | 'metaseasonId' | 'consumedAt' | 'leagueId'>[]
    | null
    | undefined
} & ConnectedAccountProviders

export type Starter = {
  player:
    | {
        user: TeamPlayer & {
          seasonPasses:
            | Pick<
                UserSeasonPass,
                'id' | 'metaseasonId' | 'consumedAt' | 'leagueId'
              >[]
            | null
            | undefined
        }
      }
    | undefined
    | null
  position: Pick<EsportPosition, 'abbreviation' | 'id' | 'index'>
}

export type Substitute = {
  user: TeamPlayer
}

export type MetaseasonWithTiming = MinimalMetaseason & {
  enrollmentEndsAt?: string
}
export type CompetitionIntervalWithMetaseasonsForOrg = {
  interval: IntervalWithEnrolledSeasons
  metaseasonsForOrganization: MetaseasonWithTiming[]
}
