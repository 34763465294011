import { UserNotification } from '@plvs/graphql/generated/graphql'
import React from 'react'
import { adapters } from './adapters'

export const createNotification = (
  notification: UserNotification,
  isDark?: boolean
): React.ReactElement | undefined => {
  const adapter = adapters[notification.type] ?? adapters['']
  return adapter(notification, !!isDark)
}
