import React, { useState } from 'react'
import { Card, makeStyles, useTheme, Theme } from '@material-ui/core'
import { isString, noop } from 'ramda-adjunct'
import { EsportRating } from '@plvs/graphql'
import {
  NxTypography,
  NxTab,
  NxTabs,
  NxTooltip,
} from '@playvs-inc/nexus-components'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'
import { useBreakpointXs, Box } from '@plvs/respawn/features/layout'
import {
  NxUserAvatar,
  sizeToNxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

import { isNil } from 'ramda'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { MatchCardFooter } from './MatchCardFooter'

export type AvatarSizes =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'smedium'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'

interface MatchCardTabProps {
  children: React.ReactNode
  tab: React.ReactNode
  disabled?: boolean
}

export const MatchCardTab: React.FC<MatchCardTabProps> = ({
  children,
  tab: _tab,
  disabled: _disabled,
}) => {
  return <>{children}</>
}

export interface MatchCardProps {
  children?: React.ReactNode
  className?: string
  footer?: React.ReactNode
  title?: React.ReactNode
  titleDetail?: string
  titleUnderline?: string
  titleTopPadding?: number
  titleBottomPadding?: number
  titleDetailContent?: React.ReactNode
  subtitleContent?: React.ReactNode
  sideContent?: React.ReactNode
  px?: number
  py?: number
  backgroundColor?: string
  color?: string
  tabHeight?: number | string
  tabVariant?: 'standard' | 'scrollable' | 'fullWidth'
  tabUnderline?: boolean
  topContent?: React.ReactNode
  outerWrapperStyle?: React.CSSProperties
  wrapperStyle?: Record<string, unknown>
  avatarSize?: AvatarSizes
  avatarSrc?: string | null
  avatarTooltip?: string | React.ReactNode
  defaultTabIndex?: number
  noBoxShadow?: boolean
  borderRadius?: number | string
  titleContainerClassName?: string
  disabled?: boolean
  titleTypographyVariant?: NxTypographyVariant
  teamId?: string
  esportRating?: EsportRating
  setTabOnParent?: (tab: string) => void
}

const getTabs = (children: React.ReactNode): MatchCardTabProps[] =>
  React.Children.toArray(children)
    .filter(React.isValidElement)
    .filter((child) => {
      const props = (child.props || {}) as MatchCardTabProps
      return props.tab
    })
    .map((child) => {
      return (child?.props ?? { tab: '' }) as MatchCardTabProps
    })

const useStyles = makeStyles((theme) => ({
  titleDetail: {
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
  },
  titleContainer: {
    flex: 1,
    padding: theme.spacing(0, 3),
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
}))

export const MatchCard: React.FC<MatchCardProps> = ({
  className,
  footer,
  title,
  titleDetail,
  titleUnderline,
  titleTopPadding = 16,
  titleBottomPadding = 8,
  subtitleContent,
  children,
  px = 3,
  py,
  sideContent,
  color,
  backgroundColor,
  tabHeight,
  tabUnderline,
  topContent,
  outerWrapperStyle,
  wrapperStyle,
  avatarSize = 'small',
  avatarSrc,
  avatarTooltip,
  defaultTabIndex = 0,
  titleDetailContent,
  noBoxShadow,
  titleContainerClassName,
  disabled,
  titleTypographyVariant = 'body2',
  teamId = '',
  esportRating = EsportRating.General,
  borderRadius,
  setTabOnParent = noop,
}) => {
  const mobile = useBreakpointXs()
  const [selectedTab, setSelectedTab] = useState(defaultTabIndex)
  const tabs = getTabs(children)
  const showHeader = title || titleDetail || sideContent
  const classes = useStyles({})
  const theme = useTheme<Theme>()

  const bgColor =
    backgroundColor ??
    (theme.palette.ColorBackgroundBase || theme.palette.background.paper)
  const textColor =
    color ?? (theme.palette.ColorTextBase || theme.palette.text.primary)

  const BaseAvatar = (
    <Box mr={2}>
      <NxUserAvatar
        avatarUrl={esportRating === EsportRating.Restricted ? null : avatarSrc}
        hashId={teamId ?? ''}
        size={sizeToNxAvatarSize[avatarSize]}
      />
    </Box>
  )

  const Avatar = !isNil(avatarTooltip) ? (
    <NxTooltip arrow placement="top-end" title={avatarTooltip}>
      {BaseAvatar}
    </NxTooltip>
  ) : (
    <>{avatarSrc && BaseAvatar} </>
  )

  const TitleDetail = titleDetail ? (
    <NxTypography className={classes.titleDetail} variant="body1">
      {titleDetail}
    </NxTypography>
  ) : null

  return (
    <div
      className={className}
      style={{
        color: textColor,
        flexShrink: 0,
      }}
    >
      <Card
        style={{
          height: '100%',
          borderRadius: borderRadius || '12px',
          backgroundColor: bgColor,
          boxShadow: noBoxShadow ? 'none' : '',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: '100%',
          }}
        >
          <div>
            {topContent ? <div>{topContent}</div> : null}
            {showHeader && (
              <div
                style={{
                  display: mobile ? 'block' : 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: titleBottomPadding,
                  paddingTop: titleTopPadding,
                  ...(titleUnderline && {
                    borderBottom: titleUnderline,
                  }),
                }}
              >
                {title || titleDetail ? (
                  <div
                    className={
                      titleContainerClassName ?? classes.titleContainer
                    }
                  >
                    {Avatar}
                    <Box width="100%">
                      {title && (
                        <>
                          {typeof title === 'string' ? (
                            <NxTypography
                              data-testid="textTitle"
                              variant={titleTypographyVariant}
                            >
                              {title}
                            </NxTypography>
                          ) : (
                            title
                          )}
                        </>
                      )}
                      {TitleDetail}
                      {titleDetailContent || null}
                    </Box>
                  </div>
                ) : null}
                {sideContent && (
                  <div
                    style={{
                      marginTop: mobile ? theme.spacing(2) : 0,
                      padding: theme.spacing(0, 3),
                    }}
                  >
                    {sideContent}
                  </div>
                )}
              </div>
            )}
            <span>{subtitleContent || null}</span>
            <div
              style={{
                padding: theme.spacing(0, 3),
                ...(tabUnderline && {
                  borderBottom: `1px solid ${theme.palette.grey[200]}`,
                }),
              }}
            >
              {tabs.length ? (
                <NxTabs onChange={noop} value={selectedTab}>
                  {tabs.map(({ children: _, tab, ...rest }, i) => {
                    return (
                      <NxTab
                        key={isString(tab) ? (tab as string) : i}
                        disabled={disabled}
                        label={tab as string}
                        onClick={(): void => {
                          setSelectedTab(i)
                          setTabOnParent(tab as string)
                        }}
                        style={{ minHeight: tabHeight }}
                        value={i}
                        {...rest}
                      />
                    )
                  })}
                </NxTabs>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              minHeight: '100%',
              overflow: 'auto',
              ...outerWrapperStyle,
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                minHeight: '100%',
                padding: `${py ? py * 8 : 0}px ${px * 8}px`,
                ...wrapperStyle,
              }}
            >
              {React.Children.map(children, (child, i) => {
                if (!React.isValidElement(child)) {
                  return child
                }
                if (child.props && child.props.tab) {
                  if (
                    child.props === tabs[selectedTab] ||
                    (!selectedTab && i === 0)
                  ) {
                    return (
                      <PenaltyBox key={child.props.tab}>{child}</PenaltyBox>
                    )
                  }
                  return null
                }
                return (
                  <PenaltyBox key={child.key || 'no-key'}>{child}</PenaltyBox>
                )
              })}
            </div>
          </div>
          {footer && (
            <PenaltyBox>
              <MatchCardFooter>{footer}</MatchCardFooter>
            </PenaltyBox>
          )}
        </div>
      </Card>
    </div>
  )
}
