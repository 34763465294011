import { SponsorshipComponentName, SponsorshipImageName } from '@plvs/const'

type SponsorshipImageConfig = {
  name: SponsorshipImageName
  display: string
  description: string
}

export type SponsorshipComponentConfig = {
  display: string
  imageConfigs: SponsorshipImageConfig[]
  fullWidth: boolean
}

export const SponsorshipComponentConfigs = {
  [SponsorshipComponentName.DashboardBanner]: {
    display: 'Dashboard Banner',
    imageConfigs: [
      {
        name: SponsorshipImageName.Banner,
        display: 'Banner Background',
        description: '',
      },
      {
        name: SponsorshipImageName.MobileBanner,
        display: 'Mobile Banner Background',
        description: '',
      },
      {
        name: SponsorshipImageName.BannerLogo,
        display: 'Banner Logo',
        description: '',
      },
      {
        name: SponsorshipImageName.MobileBannerLogo,
        display: 'Mobile Banner Logo',
        description: '',
      },
    ],
    fullWidth: true,
  },
  [SponsorshipComponentName.MatchLobbyBanner]: {
    display: 'Match Lobby Banner',
    imageConfigs: [
      {
        name: SponsorshipImageName.Banner,
        display: 'Banner Background',
        description: '',
      },
      {
        name: SponsorshipImageName.MobileBanner,
        display: 'Mobile Banner Background',
        description: '',
      },
      {
        name: SponsorshipImageName.BannerLogo,
        display: 'Banner Logo',
        description: '',
      },
      {
        name: SponsorshipImageName.MobileBannerLogo,
        display: 'Mobile Banner Logo',
        description: '',
      },
    ],
    fullWidth: true,
  },
  [SponsorshipComponentName.MatchLobbyLogo]: {
    display: 'Match Lobby Logo',
    imageConfigs: [
      {
        name: SponsorshipImageName.Banner,
        display: 'Logo',
        description: '',
      },
      {
        name: SponsorshipImageName.MobileBanner,
        display: 'Mobile Logo',
        description: '',
      },
    ],
    fullWidth: false,
  },
}
