import React from 'react'
import { Box, useTheme } from '@material-ui/core'

export const MatchAssistantFooter: React.FC = ({ children }) => {
  const theme = useTheme()

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
      mt={3}
      width="100%"
    >
      {children}
    </Box>
  )
}
