import React from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { NxTypography } from '@playvs-inc/nexus-components'

import { Esport, Match, Maybe, MatchTeam } from '@plvs/graphql'

dayjs.extend(tz)

export const MatchReportMatchInfo: React.FC<{
  match: Pick<Match, 'id' | 'bestOf' | 'scheduledStartsAt' | 'status'> & {
    esport: Pick<Esport, 'id' | 'name'>
    team1: Maybe<Pick<MatchTeam, 'id' | 'name'>>
    team2: Maybe<Pick<MatchTeam, 'id' | 'name'>>
  }
}> = ({ match }) => {
  return (
    <NxTypography
      colorToken="ColorTextAlt"
      style={{
        minWidth: 200,
        maxWidth: 400,
      }}
    >
      <span data-cy="matchReportMatchInfo" style={{ fontWeight: 'bold' }}>
        {match.team1?.name ?? '???'} vs {match.team2?.name ?? '???'}
      </span>
      <br />
      {match.esport.name}, Best of {match.bestOf}
      <br />
      {match.scheduledStartsAt &&
        dayjs(match.scheduledStartsAt)
          .tz(dayjs.tz.guess())
          .format('MMMM Do, h:mm A z')}
    </NxTypography>
  )
}
