import React, { useState } from 'react'
import { Box, Checkbox, makeStyles, useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'
import clsx from 'clsx'
import { logger } from '@plvs/rally/logging'
import {
  useGetMapSelectionOptionsByEsportIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import { Smallcheck } from '@playvs-inc/nexus-icons'
import { sortByDisplayName } from '@plvs/utils'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles as useMapStyles } from './StageSelect.styles'
import { useStyles as useMatchAssistantStyles } from '../../../MatchAssistant.styles'

const useStyles = makeStyles({
  list: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

export const StageSelect: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const maStyles = useMatchAssistantStyles()
  const {
    currentStep,
    teamId,
    gameOrdinal,
    isMyTurn,
    gameMode,
    match,
  } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const styles = useMapStyles({ isMyTurn })

  const [selectionId, setSelectionId] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState(
    currentStep?.step?.submitted ?? false
  )

  const { data: mapData, loading } = useGetMapSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id,
  })

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions = mapData?.mapSelectionOptionsByEsportId?.selectionOptions.find(
    (option) => option.gameOrdinal === gameOrdinal
  )
  const sortedSelectionOptions = sortByDisplayName(
    selectionOptions?.selectionOptions
  )

  const homeTeamPicksFirst = gameOrdinal === 1
  const isWaitingForOpponent = hasSubmitted || !isMyTurn
  const isNextDisabled =
    !isMyTurn ||
    !canParticipate ||
    isWaitingForOpponent ||
    !selectionId ||
    isSubmitting
  const isSelectDisabled =
    !isMyTurn ||
    !canParticipate ||
    updateLoading ||
    isWaitingForOpponent ||
    currentStep?.step?.submitted ||
    isSubmitting

  const handleChange = (id: string): void => {
    setSelectionId(id)
  }

  const handleNext = async (): Promise<void> => {
    setIsSubmitting(true)

    if (isNextDisabled) {
      setIsSubmitting(false)
      return
    }

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
            selectionOptions: [{ selectionOptionId: selectionId }],
          },
        },
      })

      setHasSubmitted(true)
      setIsSubmitting(false)
    } catch (err) {
      logger.error(err)
    }
  }

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <NxTypography variant="overline">Game Mode</NxTypography>
        <NxTypography variant="h1">{gameMode}</NxTypography>
      </Box>

      <Box
        className={classes.list}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
        maxHeight="267px"
        mb={3}
        mt={2}
        overflow="auto"
      >
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          {sortedSelectionOptions.map(({ id, displayName }) => {
            const isSelected = id === selectionId
            const isChecked = selectionId === id
            return (
              <Box
                key={`${teamId}-${id}`}
                alignItems="center"
                className={clsx(
                  styles.mapOption,
                  isChecked && styles.selectedMapOption,
                  isSelected && styles.disabledMapOption
                )}
                component="label"
                display="flex"
                gridGap={theme.spacing(1)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore This is a valid label prop
                htmlFor={`${teamId}-${id}`}
                px={3}
                py={2}
              >
                <Checkbox
                  checked={Boolean(isChecked && styles.selectedMapOption)}
                  checkedIcon={
                    <Box className={styles.checkedIcon}>
                      <Smallcheck
                        color={theme.palette.ColorIconInvert}
                        height={20}
                        width={20}
                      />
                    </Box>
                  }
                  className={styles.checkbox}
                  disabled={isSelectDisabled || isSelected}
                  icon={<span className={styles.icon} />}
                  id={`${teamId}-${id}`}
                  onChange={(_event): void => handleChange(id)}
                />
                <NxTypography
                  colorToken={isSelected ? 'ColorTextDisabled' : 'ColorTextAlt'}
                  variant="body2"
                >
                  {displayName}
                </NxTypography>
              </Box>
            )
          })}
        </WaitTillLoaded>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={
            homeTeamPicksFirst
              ? 'The home team picks first stage.'
              : 'Select one stage.'
          }
        />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <NxButton
          className={maStyles.cta}
          disabled={isNextDisabled}
          fullWidth
          label="Next"
          loading={isWaitingForOpponent || updateLoading}
          onClick={handleNext}
          variant="primary"
        />
      </MatchAssistantFooter>
    </>
  )
}
