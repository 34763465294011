import React from 'react'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  Phase,
  EsportSlug,
  useFetchPlayerStandingQuery,
  FetchPlayerStandingQuery,
} from '@plvs/graphql'
import { Grid } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { PlayerStandings, StatRow } from './PlayerStandings'

export type PlayerStandingsRow = NonNullable<
  NonNullable<FetchPlayerStandingQuery['fetchPlayerStanding']>
>[0]

export const StandingsByPlayer: React.FC<{
  phase: Pick<Phase, 'id'>
}> = ({ phase }) => {
  const { slug } = useEsportContext()
  const { data, loading, error } = useFetchPlayerStandingQuery({
    variables: {
      phaseId: phase.id,
    },
  })

  const results: PlayerStandingsRow[] = data?.fetchPlayerStanding ?? []

  if (!results || error) {
    return <></>
  }

  const playerStandings: {
    title: string
    lookup:
      | 'kda'
      | 'gold'
      | 'goalsPerShot'
      | 'scorePerGame'
      | 'eliminationsPerGame'
      | 'rankPerGame'
    rows: PlayerStandingsRow[]
    isFloat?: boolean
    isPercent?: boolean
  }[] = []

  switch (slug) {
    case EsportSlug.LeagueOfLegends:
    case EsportSlug.Smite:
      playerStandings.push({
        title: 'Overall KDA',
        lookup: 'kda',
        rows: results,
        isFloat: true,
      })
      playerStandings.push({
        title: 'Gold Per Minute',
        lookup: 'gold',
        rows: results,
        isFloat: true,
      })
      break
    case EsportSlug.RocketLeague:
      playerStandings.push({
        title: 'Goals Per Shot %',
        lookup: 'goalsPerShot',
        rows: results,
        isFloat: true,
        isPercent: true,
      })
      playerStandings.push({
        title: 'Score Per Game',
        lookup: 'scorePerGame',
        rows: results,
        isFloat: true,
      })
      break
    case EsportSlug.Fortnite:
      playerStandings.push({
        title: 'Eliminations Per Game',
        lookup: 'eliminationsPerGame',
        rows: results,
        isFloat: true,
      })
      playerStandings.push({
        title: 'Rank Per Game',
        lookup: 'rankPerGame',
        rows: results,
        isFloat: true,
      })
      break

    default:
      break
  }

  const playerStandingOutput = playerStandings.map((stat) => {
    const rows = stat.rows.map((row: PlayerStandingsRow) => {
      return row as StatRow
    })
    return rows.length ? (
      <Grid key={stat.title} item xs={6}>
        <Box pb={4}>
          <PlayerStandings
            key={stat.title}
            isFloat={stat.isFloat}
            isPercent={stat.isPercent}
            loading={loading}
            lookup={stat.lookup}
            rows={rows}
            title={stat.title}
          />
        </Box>
      </Grid>
    ) : null
  })

  return <Grid container>{playerStandingOutput}</Grid>
}
