import React from 'react'
import { Link, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import dayjs from '@plvs/rally/init/dayjs'
import { Avatar } from '@plvs/rally/components/avatar'
import { MoreMenu } from '@plvs/respawn/features/button/MoreMenu'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { SchoolUserMessage } from './helper'

const useStyles = makeStyles((theme) => ({
  ago: {
    display: 'flex',
    flex: '1',
  },
  avatar: {
    margin: '20px',
  },
  message: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '8px',
    wordBreak: 'break-all',
  },
  action: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.palette.ColorBackgroundAlt2,
    margin: '12px 0 8px 0',
    borderRadius: '6px',
  },
  linkDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    flex: 1,
    padding: '20px',
  },
  url: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export const Message: React.FC<{
  message: SchoolUserMessage
  isCoachOfCurrentSchool: boolean
  togglePin?(message: SchoolUserMessage): void
  onDelete?(message: SchoolUserMessage): void
}> = React.memo(
  ({ message, isCoachOfCurrentSchool = false, togglePin, onDelete }) => {
    const classes = useStyles()
    const createdAt = dayjs(message?.createdAt)
    const ago = createdAt.fromNow()
    const actions: {
      label: string
      onClick: () => void
      disabled: boolean
    }[] = []
    if (isCoachOfCurrentSchool && onDelete)
      actions.push({
        label: 'Delete Post',
        onClick: (): void => {
          onDelete(message)
        },
        disabled: false,
      })
    if (isCoachOfCurrentSchool && togglePin)
      actions.push({
        label: message?.isPinned ? 'Unpin Post' : 'Pin Post',
        onClick: (): void => {
          togglePin(message)
        },
        disabled: false,
      })

    return (
      <Box className={classes.message}>
        <Box className={classes.row}>
          <NxTypography className={classes.ago} variant="subtitle2">
            {message?.isPinned ? 'Pinned' : ago}
          </NxTypography>
          <MoreMenu menuItemProps={actions} />
        </Box>

        <NxTypography variant="h3">{message?.title}</NxTypography>
        <NxTypography variant="body1">{message?.message}</NxTypography>
        {message?.linkUrl && (
          <Link
            key={message.linkUrl}
            className={classes.link}
            color="inherit"
            href={message.linkUrl}
            rel="noopener"
            target="_blank"
            underline="none"
          >
            {message?.linkImageUrl && (
              <Avatar
                shape="rounded"
                size="xxlarge"
                src={message?.linkImageUrl}
              />
            )}
            <Box className={classes.linkDescription}>
              <NxTypography variant="body1">{message?.linkTitle}</NxTypography>
              <NxTypography className={classes.url} variant="subtitle1">
                {message?.linkUrl}
              </NxTypography>
            </Box>
          </Link>
        )}
        <Box className={classes.row}>
          <Box px={1.6} py={1.6}>
            <NxUserAvatar
              avatarUrl={message?.user?.avatarUrl}
              hashId={message?.user?.id ?? ''}
              size={NxAvatarSize.MD}
            />
          </Box>
          <Box className={classes.column}>
            <NxTypography variant="body2">
              {message?.user?.name} · {createdAt.format('MMM D @ h:mma z')}{' '}
            </NxTypography>
            <NxTypography variant="body2">{message?.school?.name}</NxTypography>
          </Box>
        </Box>
      </Box>
    )
  }
)
