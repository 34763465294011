import React from 'react'
import { Standings } from '@plvs/rally/features/standings'
import { UrlQueryParam } from '@plvs/const'
import { UnverifiedSchool } from '@plvs/rally/containers/empty'
import {
  useIsSchoolByIdVerifiedQuery,
  CompetitionGroup,
  UserRoleName,
} from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useAutoskipQuery } from '@plvs/utils'
import { MyLeaguesProvider } from '@plvs/rally/containers/filter/league/MyLeaguesProvider'

import { useLocation } from 'react-router'
import { withTransaction } from '@elastic/apm-rum-react'
import { AppPage } from '../page'

const StandingsPage: React.FC = () => {
  const { selectedEntityId, roles } = useProfileContext()
  const { data, loading } = useAutoskipQuery(useIsSchoolByIdVerifiedQuery, {
    variables: {
      id: selectedEntityId,
    },
  })
  const { search } = useLocation()
  const initialPhaseId = React.useMemo(() => {
    const parsedSearchParams = new URLSearchParams(search)
    return parsedSearchParams.get(UrlQueryParam.phaseId)
  }, [search])

  const isVerifiedSchool = !data?.school?.deletedAt
  const isFaculty = roles.some(
    (role) =>
      role.roleName === UserRoleName.Fac && role.resourceId === selectedEntityId
  )
  const isCollege = data?.school?.competitionGroup === CompetitionGroup.College

  return (
    <AppPage title="Standings">
      <MyLeaguesProvider initialPhaseId={initialPhaseId}>
        <WaitTillLoaded loading={loading}>
          {selectedEntityId && !isVerifiedSchool && !isFaculty ? (
            <UnverifiedSchool
              showHero
              showReferButton={!isCollege}
              subtitle="Top players and teams"
              title="Standings"
            />
          ) : (
            <Standings />
          )}
        </WaitTillLoaded>
      </MyLeaguesProvider>
    </AppPage>
  )
}

export default withTransaction('StandingsPage', 'component')(StandingsPage)
