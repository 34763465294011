import React from 'react'
import { SchoolOverview } from '@plvs/rally/features/school/Overview'
import { EsportSlug, useGetSchoolBySlugQuery } from '@plvs/graphql'
import {
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { useLocation, useParams } from 'react-router'
import { AppPage, PublicPage } from '../page'

export const SchoolPage: React.FC = () => {
  const { schoolId: paramSchoolId, slug } = useParams<{
    schoolId: string
    slug: string
  }>()
  const { pathname: path } = useLocation()
  const { data, loading, error } = useGetSchoolBySlugQuery({
    variables: { slug: slug as EsportSlug },
    skip: !slug,
  })
  const isPublic = path.includes('/org/')

  const schoolId = data?.getSchoolBySlug?.id || ''
  const selectedSchoolId = paramSchoolId || schoolId

  if (error || !schoolId) return <PenaltyBox />

  return isPublic ? (
    <MySchoolLeagues shouldUseEligibleMetaseasons={false}>
      <PublicPage
        description="Public School Page"
        hasDrawer
        title="School Page"
      >
        <SchoolOverview isPublicPage={isPublic} schoolId={selectedSchoolId} />
      </PublicPage>
    </MySchoolLeagues>
  ) : (
    <AppPage title="School">
      <WaitTillLoaded loading={loading}>
        <PageContentGutter>
          <SchoolOverview isPublicPage={isPublic} schoolId={selectedSchoolId} />
        </PageContentGutter>
      </WaitTillLoaded>
    </AppPage>
  )
}
