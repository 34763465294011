import { filter, isEmpty } from 'ramda'
import { isTruthy } from 'ramda-adjunct'

import { UserRole, UserRoleName } from '@plvs/graphql'
import { UserType, userRolesToUserType } from '@plvs/utils'
import * as facebook from './facebook/facebook'
import * as intercom from './intercom/intercom'
import * as segment from './segment/segment'
import * as snapchat from './snapchat/snapchat'

// //////////////
// Easy Events //
// //////////////

export const { bootIntercom } = intercom

export const {
  group,
  identify,
  login,
  setRole,
  setRoleDescription,
  setSchool,
  setSchoolSubmission,
  seasonWizardActivated,
  seasonWizardDismissed,
  seasonWizardCompleted,
  playerActivationFlowTeamSelection,
  playerActivationFlowTeamActivation,
  playerActivationFlowCloseModalAfterAbandoned,
  connectAccountCTAAcknowledged,
  referCoachModalDiscovered,
  referPlayerModalDiscovered,
  referUserModalDiscovered,
  referralModalCTAConverted,
  votedOnArticle,
  readArticle,
  inAppClickedArticleCTA,
  watchedArticleVideo,
  openedArticleResource,
  clickedArticleCTA,
  productPlanSelected,
  nonSanctionedSchoolInterestedInStateOrConference,
  notificationService,
  billingContactAtSchool,
  deniedVerifiedPlayer,
  graduateVerifiedPlayer,
  transferVerifiedPlayer,
  userEnteredNameAndDateOfBirth,
  userNotificationsDrawerClosed,
  userNotificationsDrawerOpened,
  userNotificationMessageOpened,
  userNotificationTaskOpened,
  userNotificationTaskRead,
  userNotificationMessageRead,
  userDropdownMenuItemClicked,
  addSchoolButtonClicked,
  tryMatchQueueButtonClicked,
  findOpponentButtonClicked,
  leaveQueueButtonClicked,
  pageRedirectFromMatchQueueToMatchLobby,
  continuedQueue,
  scrimmageQueueShown,
  leftNavUnreadNotificationIconClicked,
  globalChatNotificationsDrawerOpened,
  globalChatWindowClosed,
  gettingStartedTaskOpened,
  messageSentFromGlobal,
  userReadUnreadMessageViaGlobal,
  matchLobbyChatLoaded,
  matchAssistantStepTimestamps,
  globalChatDrawerOpenedAndLoaded,
  globalChatTwilioCallAndDataBuilt,
  rewindShareClicked,
  rewindOpenClicked,
  matchAssistantCompletedNPSSurvey,
  productTourViewed,
  productTourClicked,
  findMatchTourViewed,
  findMatchTourClicked,
  matchLobbyTourViewed,
  matchLobbyTourClicked,
} = segment

// //////////////
// Hard Events //
// //////////////

type AnalyticsFnNoArgs = () => void
type AnalyticsFn<Properties> = (properties: Properties) => void

const tokenRegex = /\/(\w+)(\?|$)/
const getTokenFromUrl = (url: string): string | undefined => {
  const matches = tokenRegex.exec(url)
  return matches ? matches[1] : undefined
}

export const userRolesToOnboardingFeature = (
  userRoles: Pick<UserRole, 'roleName'>[]
): segment.Feature =>
  userRolesToUserType(userRoles) === UserRoleName.Coach
    ? segment.Feature.CoachOnboarding
    : segment.Feature.PlayerOnboarding

export const copyInviteLink: AnalyticsFn<
  {
    myUserRoles: Pick<UserRole, 'roleName'>[]
  } & Omit<segment.CopyInviteLinkProperties, 'feature' | 'inviteToken'>
> = ({ inviteLink, myUserRoles, ...rest }) =>
  segment.copyInviteLink({
    feature: userRolesToOnboardingFeature(myUserRoles),
    inviteLink,
    inviteToken: getTokenFromUrl(inviteLink),
    ...rest,
  })

export const inviteByEmail: AnalyticsFn<
  {
    myUserRoles: Pick<UserRole, 'roleName'>[]
  } & Omit<segment.InviteByEmailProperties, 'feature'>
> = ({ inviteeEmails, inviteeUserType, myUserRoles }) => {
  // filter out blank emails
  const filteredInviteeEmails = filter(isTruthy, inviteeEmails)
  if (!isEmpty(filteredInviteeEmails)) {
    segment.inviteByEmail({
      feature: userRolesToOnboardingFeature(myUserRoles),
      inviteeEmails: filteredInviteeEmails,
      inviteeUserType,
    })
  }
}

export const logout: AnalyticsFnNoArgs = () => {
  segment.logout()
  segment.reset()
}

export const register: AnalyticsFn<{
  email: string
  userId: string
  next?: string
  provider?: string
  referralToken?: string
  userRoles: UserRoleName[]
  userType?: UserType
}> = ({
  email,
  referralToken,
  next,
  provider,
  userId,
  userRoles,
  userType,
}) => {
  segment.register({ email, next, provider, referralToken, userId, userRoles })
  facebook.register({ email, userId, userType })
  snapchat.register({ email })
}
