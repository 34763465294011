import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.ColorTextBase,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.ColorTextAccent,
    // NOTE: support for buttons
    background: 'transparent',
    border: 'none',
    padding: 0,
    '&:hover': {
      color: theme.palette.ColorTextAccent,
    },
  },
}))

interface LinkPropsInterface extends Partial<LinkProps> {
  Component?: React.ElementType
  variant?: string
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkPropsInterface>(
  (
    { children, to, Component = 'a', ...otherProps },
    ref
  ): React.ReactElement => {
    const { link } = useStyles()
    const isHyper = typeof to === 'string' && !!to.match(/:\/\//)
    const innerNode = (
      // @ts-ignore Not sure how to get component to carry through in the props type here...
      <NxTypography component="span" variant={otherProps.variant ?? 'body1'}>
        {children}
      </NxTypography>
    )
    // NOTE: this supports undefined "to"
    if (
      (isHyper && typeof to !== 'object' && typeof to !== 'function') ||
      otherProps.onClick
    ) {
      return (
        <Component
          className={link}
          data-testid="normal-link"
          href={to}
          {...otherProps}
        >
          {innerNode}
        </Component>
      )
    }
    return (
      <RouterLink
        ref={ref}
        className={link}
        data-testid="router-link"
        to={to || ''}
        {...otherProps}
      >
        {innerNode}
      </RouterLink>
    )
  }
)
