import { Box, Button } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router'
import { Path, Param } from '@plvs/const'
import { UserRoleName } from '@plvs/graphql'
import { RouterLink } from '@plvs/respawn/features/route'
import { StepState } from './onboardTypes'

interface ReferContinueButtonProps {
  refer: UserRoleName
  setCompleted?(input: StepState): void
  completed?: StepState
}

export const ReferContinueButton: React.FC<ReferContinueButtonProps> = ({
  refer,
  setCompleted,
  completed,
}) => {
  const location = useLocation()
  const next = new URLSearchParams(location.search).get(Param.Next)
  const buttonProps =
    setCompleted && completed
      ? {
          onClick: (): void => {
            if (refer === UserRoleName.Student) {
              setCompleted({ ...completed, student: true })
            }
            if (refer === UserRoleName.Fac) {
              setCompleted({ ...completed, fac: true })
            }
          },
        }
      : {
          component: RouterLink,
          to: next || Path.App,
        }

  return (
    <Box mt={2}>
      <Button
        color="primary"
        data-cy="o-refer-user-button"
        variant="contained"
        {...buttonProps}
      >
        Continue
      </Button>
    </Box>
  )
}
