// This file is just styles and doesn't need testing.
/* istanbul ignore file */
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  navContainer: {
    maxWidth: 256,
  },

  accordionContainer: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 768,
    },
  },

  clickable: {
    cursor: 'pointer',
  },

  overline: {
    whiteSpace: 'nowrap',
    color: theme.palette.ColorTextAlt2,
    textTransform: 'uppercase',
  },

  teamItem: {
    color: theme.palette.ColorTextBase,
    width: '100%',
    cursor: 'pointer',
    padding: theme.spacing(2, 3),
  },

  selectables: {
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3),
    },
  },

  accordionContent: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  accordionGame: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  accordionUnexpanded: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    gap: '1em',
    padding: theme.spacing(2),
  },

  accordionValue: {
    display: 'flex',
    gap: '0.5em',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  editButton: {
    padding: 0,
    color: theme.palette.ColorTextLink,
    border: 0,
    cursor: 'pointer',
    background: 'none',

    '&:hover': {
      color: theme.palette.ColorBackgroundAccentAlt2,
      background: 'none',
    },
  },

  incompleteIcon: {
    background: theme.palette.ColorBackgroundAlt3,
    padding: theme.spacing(1),
    borderRadius: 100,
    display: 'flex',
  },

  incompleteReasons: {
    margin: theme.spacing(0, 0, 4, 8),
    display: 'flex',
    gap: '3em',
  },

  accordionContentTop: {
    padding: theme.spacing(2, 3, 2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },

  incompleteButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.75em',
    marginBottom: theme.spacing(3),
  },

  '@global': {
    '[data-testid="NxSingleSelect__OuterContainer"]': {
      flexWrap: 'wrap',
      height: 'auto',
      minHeight: 64,
    },
  },
}))
