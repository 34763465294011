import React from 'react'
import { NxTypography, NxButton, NxModal } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout/Box'

import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { makeStyles } from '@material-ui/core'
import { User } from '@plvs/graphql/generated/graphql'

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  name: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  beginningOfWeek:
    | {
        id: string
        name: string
      }
    | undefined
  handleClose(): void
  loading: boolean
  handleAssignAward(): Promise<void>
  open: boolean
  selectedUser: Pick<User, 'id' | 'name' | 'avatarUrl'>
}

export const NominatePlayerModal: React.FC<Props> = ({
  beginningOfWeek,
  handleClose,
  loading,
  handleAssignAward,
  open,
  selectedUser,
}) => {
  const classes = useStyles()

  return (
    <NxModal
      actions={
        <>
          <NxButton label="Cancel" onClick={handleClose} variant="text" />
          <NxButton
            disabled={loading}
            label="Confirm"
            onClick={handleAssignAward}
            variant="primary"
          />
        </>
      }
      onClose={handleClose}
      open={open}
      showTopRightClose
      size="small"
      subtitle="Please confirm the Player of the Week. This cannot be changed once confirmed. "
      title="Nominate Player of the Week"
    >
      <NxTypography className={classes.title} variant="body2">
        Player of The Week ({beginningOfWeek?.name ?? ''})
      </NxTypography>
      <Box alignItems="center" display="flex">
        <NxUserAvatar
          avatarUrl={selectedUser?.avatarUrl ?? ''}
          hashId={selectedUser.id}
          size={NxAvatarSize.XS}
        />
        <NxTypography
          className={classes.name}
          colorToken="ColorTextAlt"
          variant="body3"
        >
          {selectedUser.name}
        </NxTypography>
      </Box>
    </NxModal>
  )
}
