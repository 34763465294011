import React from 'react'
import { ApolloError } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { Button } from '@material-ui/core'
import { useJoinTeamMutation } from '@plvs/graphql'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

interface Props {
  disabled?: boolean
  positionIndex: number | null
  teamId: string
  onMutationSuccess?(): void
}

export const JoinTeamButton = ({
  disabled,
  positionIndex,
  teamId,
  onMutationSuccess,
}: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const { refetchEnrollment } = useManageTeamsContext()

  const [joinTeamMutation, { loading: isJoinMutating }] = useJoinTeamMutation()
  const joinTeam = async (): Promise<void> => {
    try {
      const mutationVariables = {
        teamId,
        position: positionIndex,
      }
      const response = await joinTeamMutation({
        variables: mutationVariables,
      })
      const success = !!response?.data?.joinTeam

      if (success) {
        enqueueSnackbar("Success! You've joined the team.", {
          variant: 'success',
        })
        onMutationSuccess?.()
      } else {
        throw new ApolloError({
          errorMessage: 'Unable to join team.',
        })
      }
    } catch (e: any) {
      enqueueSnackbar('Unable to join team.', { variant: 'error' })
    } finally {
      refetchEnrollment()
    }
  }

  return (
    <Button
      color="secondary"
      disabled={isJoinMutating || disabled}
      onClick={joinTeam}
      variant="contained"
    >
      Join Team
    </Button>
  )
}
