import React, { useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import {
  AssignTeamCoachDocument,
  AssignTeamCoachMutation,
  AssignTeamCoachMutationVariables,
  useGetSchoolCoachesBySchoolIdQuery,
  User,
} from '@plvs/graphql'
import { useAutoskipQuery } from '@plvs/utils'
import { NxModal, NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { filterOutCurrentCoaches } from '@plvs/respawn/features/manage-teams/SwapCoachModal'
import { useDefaultMutation } from '../apollo/useDefaultMutation'
import { RosterTeamCoaches } from '../roster/roster.types'

export interface AddCoachToTeamProps {
  teamId: string
  onMutationSuccess?(): Promise<void>
  onClose(): void
  isDialogOpen: boolean
  users?: User[] | RosterTeamCoaches | null
  schoolId: string
}

export const AddCoachToTeamDialog: React.FC<AddCoachToTeamProps> = ({
  onClose,
  onMutationSuccess,
  isDialogOpen,
  schoolId,
  users,
  teamId,
}) => {
  const [coachSelected, setCoachSelected] = useState<{
    id: string
  }>({ id: '' })
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { data, loading: loadingCoaches } = useAutoskipQuery(
    useGetSchoolCoachesBySchoolIdQuery,
    {
      variables: {
        schoolId,
      },
    }
  )

  const [assignCoach, { errorString, loading: assignCoachLoading }] =
    useDefaultMutation<
      { id: string },
      AssignTeamCoachMutation,
      AssignTeamCoachMutationVariables
    >({
      MutationDocument: AssignTeamCoachDocument,
      onSuccess: () => {
        onClose()
        if (onMutationSuccess) {
          onMutationSuccess()
        }
      },
      snackError: 'Failed to replace coach.',
      snackSuccess: 'Coach replaced!',
      variables: (newMember) => ({
        teamId,
        coachUserId: newMember.id,
      }),
    })

  const availableCoaches = filterOutCurrentCoaches(
    data?.school?.coaches || [],
    users ?? []
  )?.map((ac) => ({
    title: ac.name ?? '',
    subtitle: ac.email ?? '',
    avatarSrc: ac.avatarUrl ?? '',
    id: ac.id ?? '',
  }))

  const filteredCoaches = searchTerm
    ? availableCoaches.filter((member) =>
        member.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : availableCoaches

  const onChange = (id: string): void => {
    if (coachSelected.id === id) {
      setCoachSelected({ id: '' })
    } else {
      setCoachSelected({ id })
    }
  }

  const assignCoachToTeam = async (): Promise<void> => {
    await assignCoach({ id: coachSelected.id })
  }

  const ActionButtons = (
    <>
      <NxButton label="Cancel" onClick={onClose} variant="text" />
      <NxButton
        disabled={assignCoachLoading}
        label="Submit"
        onClick={assignCoachToTeam}
        variant="primary"
      />
    </>
  )
  return (
    <NxModal
      actions={ActionButtons}
      onClose={onClose}
      open={isDialogOpen}
      showTopRightClose
      size="small"
      subtitle="Select a coach from the list"
      title="Add a Coach"
    >
      <Box pt={1}>
        <TextField
          color="secondary"
          fullWidth
          label="Search by Name"
          name="search"
          onChange={(e): void => {
            setSearchTerm(e.target.value)
          }}
          type="text"
          variant="outlined"
        />
      </Box>
      <WaitTillLoaded
        loading={loadingCoaches}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        <Box mt={3}>
          {errorString && (
            <Box py={2}>
              <Banner
                subtitle={errorString}
                title="Unable to add coach to team"
                type={BannerType.Error}
              />
            </Box>
          )}
          <NxTypography variant="overline">Verified Coaches</NxTypography>
          {filteredCoaches.length ? (
            <RosterRowGroup
              onChange={onChange}
              rowEntries={filteredCoaches}
              selectedId={coachSelected.id}
            />
          ) : (
            <NxTypography align="center" variant="body1">
              No coaches found
            </NxTypography>
          )}
        </Box>
      </WaitTillLoaded>
    </NxModal>
  )
}
