import { Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { RouterLink } from '@plvs/respawn/features/route'
import { Colors } from '@plvs/rally/themes'
import { Path } from '@plvs/const'
import { VerifiedLogo } from '@plvs/rally/assets'
import { generatePath } from 'react-router'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  checkInText: {
    letterSpacing: '0.06em',
    textTransform: 'capitalize',
    padding: '0px 5px',
  },
  schoolName: {
    color: theme.palette.common.white,
  },
}))

export interface MatchFaceoffTeamNameProps {
  reverse?: boolean
  schoolName?: string
  schoolSlug?: string
  teamName?: string
  teamId?: string | null
  teamHasCheckedIn?: boolean
}

export const DesktopMatchFaceoffTeamName: React.FC<MatchFaceoffTeamNameProps> = ({
  reverse,
  schoolName,
  schoolSlug,
  teamName,
  teamId,
  teamHasCheckedIn,
}) => {
  const classes = useStyles()
  const teamNameOutput = teamId ? (
    <Link
      key={teamId}
      component={RouterLink}
      data-cy={teamName}
      style={{ color: Colors.White, textDecorationColor: Colors.Orange }}
      to={`/app/team/${teamId}`}
      underline="hover"
    >
      {teamName}
    </Link>
  ) : (
    teamName
  )

  return (
    <Box textAlign={reverse ? 'right' : 'left'}>
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent={reverse ? 'flex-end' : ''}
        pb={1}
      >
        {reverse && teamHasCheckedIn && (
          <Box display="flex" paddingLeft={5}>
            <img alt="Verified" src={VerifiedLogo} />
            <NxTypography
              className={classes.checkInText}
              colorToken="OverlayColorTextBase"
              data-testid="DesktopMatchFaceoffAwayTeam_CheckedIn"
              variant="body2"
            >
              Checked In
            </NxTypography>
          </Box>
        )}
        <NxTypography
          colorToken="OverlayColorTextBase"
          component="span"
          variant="h3"
        >
          {teamNameOutput}
        </NxTypography>
        {!reverse && teamHasCheckedIn && (
          <Box display="flex" paddingRight={5}>
            <img alt="Verified" src={VerifiedLogo} />
            <NxTypography
              className={classes.checkInText}
              colorToken="OverlayColorTextBase"
              data-testid="DesktopMatchFaceoffHomeTeam_CheckedIn"
              variant="body2"
            >
              Checked In
            </NxTypography>
          </Box>
        )}
      </Box>
      {schoolSlug ? (
        <Link
          color="inherit"
          component={RouterLink}
          to={generatePath(Path.SchoolPage, { slug: schoolSlug })}
        >
          <NxTypography
            className={classes.schoolName}
            component="span"
            data-testid="DesktopMatchFaceoffTeamName_SchoolName_Slug"
            variant="subtitle2"
          >
            {schoolName}
          </NxTypography>
        </Link>
      ) : (
        <NxTypography
          className={classes.schoolName}
          component="span"
          data-testid="DesktopMatchFaceoffTeamName_SchoolName_NoSlug"
          variant="subtitle2"
        >
          {schoolName}
        </NxTypography>
      )}
    </Box>
  )
}
