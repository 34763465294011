/* istanbul ignore file */
// This takes an unusally large amount of low level mocking in order to test.
// At this point it would be more test code written against the specific
// implementation to be worth the effort/return from such a test.

import { ApolloLink } from '@apollo/client'
import { mutationEventTarget } from '../../../libs/events/mutation-events/MutationEventTarget'

export const mutationWatcherLink = new ApolloLink((operation, forward) => {
  let hasMutation = false
  if (
    operation.query.definitions.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (def) => ((def as unknown) as any).operation === 'mutation'
    )
  ) {
    hasMutation = true
  }

  let hasRan = false
  return forward(operation).map((data) => {
    // We wait for the data to return without errors before emitting the event.
    if (!hasRan && hasMutation) {
      mutationEventTarget.emit(operation.operationName)
    }
    hasRan = true
    return data
  })
})
