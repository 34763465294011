// This init is for a 3rd Party app that is injected at runtime
// Cannot be effectively unit tested.
/* istanbul ignore file */

import { breakpoints } from '@playvs-inc/nexus-core'

let intercomKey: string

// because booting requires the app key, again
export const getKey = (): string => intercomKey

export default (key: string): void => {
  /* eslint-disable */
  ;(function () {
    const w = window

    const windowHeight = w.visualViewport?.height ?? 0

    const toggleVisibility = (): void => {
      const button = w.document.querySelector('.intercom-launcher')
      const breakpointNumber = Number(
        breakpoints.desktopSmall.slice(0, breakpoints.desktopSmall.length - 2)
      )
      const isSmallScreen = w.innerWidth <= breakpointNumber
      const isMatchChat = w.location.pathname.includes('/chat')

      // Hide when keyboard opens:
      // assume if our height changes, it's
      // because the keyboard popped up
      // also giving a slight buffer to account for scrollbars
      if (
        isSmallScreen &&
        ((w.visualViewport?.height ?? 0) + 150 < windowHeight || isMatchChat)
      ) {
        button?.setAttribute('style', 'display:none')
      } else {
        button?.setAttribute('style', '')
      }
    }
    toggleVisibility()

    // @ts-ignore
    const ic = w.Intercom
    // no way to listen for "keyboard" event so instead
    // we listen for resize
    w.visualViewport?.addEventListener('resize', toggleVisibility, false)
    if (typeof ic === 'function') {
      ic('reattach_activator')
      // @ts-ignore
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function () {
        // @ts-ignore
        i.c(arguments)
      }
      // @ts-ignore
      i.q = []
      // @ts-ignore
      i.c = function (args) {
        // @ts-ignore
        i.q.push(args)
      }
      // @ts-ignore
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + key
        const x = d.getElementsByTagName('script')[0]
        // @ts-ignore
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
        // @ts-ignore
      } else if (w.attachEvent) {
        // @ts-ignore
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
  /* eslint-enable */

  intercomKey = key
}
