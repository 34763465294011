import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import { User } from '@plvs/graphql'
import { ConnectedAccountProviders } from '@plvs/utils'

interface RawStats {
  player:
    | (Pick<User, 'id' | 'name' | 'avatarUrl'> & ConnectedAccountProviders)
    | null
}

export const decoratePlayerRow = <T extends RawStats>({
  getUsername,
}: {
  getUsername: GeneralEsportAdapter['getUsername']
}) => (
  row: T
): T & {
  player: {
    name: string
    username: string | null
  }
} => {
  const { player } = row

  return {
    ...row,
    player: {
      ...player,
      name: player?.name ?? 'N/A',
      username: player && getUsername(player),
    },
  }
}
