import React from 'react'
import {
  EsportStatus,
  EsportRating,
  GameStatus,
  MatchStatus,
  PhaseStatus,
  ScrimmageRequestStatus,
  SeasonStatus,
  SlotStatus,
  SeasonEnrollmentStatus,
} from '@plvs/graphql'
import { makeStyles } from '@material-ui/core'
import { Pill, PillProps } from '@playvs-inc/nexus-components'

const useStyles = makeStyles({
  pill: {
    width: '100%',
  },
})

type Variants =
  | 'success'
  | 'info'
  | 'brand'
  | 'error'
  | 'disabled'
  | 'misc'
  | 'warn'
  | 'inactive'

export enum OtherStatus {
  NoGame = 'noGame',
  NoMatch = 'noMatch',
  NoScrimmageRequest = 'noScrimmageRequest',
  Archived = 'archived',
}

export type MegaStatus =
  | GameStatus
  | MatchStatus
  | PhaseStatus
  | ScrimmageRequestStatus
  | SeasonStatus
  | SlotStatus
  | OtherStatus
  | EsportStatus
  | EsportRating
  | SeasonEnrollmentStatus

export const statusPrettyPrintMap: Record<MegaStatus, string> = {
  [GameStatus.Scheduled]: 'Scheduled',
  [GameStatus.Open]: 'Open',
  [GameStatus.Live]: 'Live',
  [GameStatus.Completed]: 'Completed',
  [GameStatus.Reviewed]: 'Reviewed',
  [GameStatus.Quarantined]: 'Quarantined',
  [GameStatus.Forfeited]: 'Forfeited',
  [GameStatus.Cancelled]: 'Cancelled',
  [GameStatus.Bye]: 'Bye',
  [GameStatus.Settled]: 'Settled',
  [MatchStatus.Rescheduling]: 'Rescheduling',
  [MatchStatus.Rescheduled]: 'Rescheduled',
  [ScrimmageRequestStatus.NeedsOpponent]: 'Needs Opponent',
  [ScrimmageRequestStatus.Canceled]: 'Canceled',
  [ScrimmageRequestStatus.Requested]: 'Invite Sent',
  [ScrimmageRequestStatus.Processed]: 'Processing',
  [ScrimmageRequestStatus.Declined]: 'Invite Declined',
  [ScrimmageRequestStatus.NoResponse]: 'No Response',
  [SeasonStatus.Active]: 'Active',
  [SeasonStatus.Draft]: 'Draft',
  [SlotStatus.AwaitingSchedule]: 'Awaiting',
  [SlotStatus.MatchesScheduled]: 'Scheduled',
  [OtherStatus.NoGame]: 'No Game',
  [OtherStatus.NoMatch]: 'No Match',
  [OtherStatus.NoScrimmageRequest]: 'No Scrimmage Request',
  [OtherStatus.Archived]: 'Archived',
  [EsportStatus.Enabled]: 'Active',
  [EsportStatus.Disabled]: 'Draft',
  [EsportStatus.All]: 'All',
  [EsportRating.General]: 'General',
  [EsportRating.Restricted]: 'Restricted',
  [SeasonEnrollmentStatus.Inactive]: 'Inactive',
  [SeasonEnrollmentStatus.Pending]: 'Pending',
  [SeasonEnrollmentStatus.Ready]: 'Ready',
}

export const statusColorMap: Record<MegaStatus, Variants> = {
  [GameStatus.Scheduled]: 'success',
  [GameStatus.Open]: 'info',
  [GameStatus.Live]: 'success',
  [GameStatus.Completed]: 'info',
  [GameStatus.Reviewed]: 'info',
  [GameStatus.Quarantined]: 'brand',
  [GameStatus.Forfeited]: 'misc',
  [GameStatus.Cancelled]: 'error',
  [GameStatus.Bye]: 'info',
  [GameStatus.Settled]: 'info',
  [MatchStatus.Rescheduling]: 'disabled',
  [MatchStatus.Rescheduled]: 'error',
  [ScrimmageRequestStatus.NeedsOpponent]: 'brand',
  [ScrimmageRequestStatus.Requested]: 'brand',
  [ScrimmageRequestStatus.Canceled]: 'error',
  [ScrimmageRequestStatus.Processed]: 'disabled',
  [ScrimmageRequestStatus.Declined]: 'error',
  [ScrimmageRequestStatus.NoResponse]: 'error',
  [SeasonStatus.Active]: 'success',
  [SeasonStatus.Draft]: 'brand',
  [SlotStatus.AwaitingSchedule]: 'brand',
  [SlotStatus.MatchesScheduled]: 'success',
  [OtherStatus.NoGame]: 'disabled',
  [OtherStatus.NoMatch]: 'disabled',
  [OtherStatus.NoScrimmageRequest]: 'disabled',
  [OtherStatus.Archived]: 'disabled',
  [EsportStatus.Enabled]: 'success',
  [EsportStatus.Disabled]: 'brand',
  [EsportStatus.All]: 'info',
  [EsportRating.General]: 'success',
  [EsportRating.Restricted]: 'error',
  [SeasonEnrollmentStatus.Inactive]: 'disabled',
  [SeasonEnrollmentStatus.Pending]: 'brand',
  [SeasonEnrollmentStatus.Ready]: 'success',
}

export const StatusBadge = ({
  size = 'large',
  status,
  text,
  ...rest
}: {
  status: MegaStatus
  text?: string
  textVariant?: string
} & Partial<PillProps>): React.ReactElement => {
  const { pill } = useStyles()
  return (
    <Pill
      className={pill}
      label={text || statusPrettyPrintMap[status]}
      size={size}
      variant={statusColorMap[status]}
      {...rest}
    />
  )
}
