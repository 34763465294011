import React from 'react'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { useTheme } from '@material-ui/core'
import {
  ProductTourDarkPrimaryLarge,
  ProductTourLightPrimaryLarge,
} from '@playvs-inc/nexus-spots'

export const TourSpotWrapper: React.FC = () => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  if (isDarkMode) {
    return <ProductTourDarkPrimaryLarge />
  }

  return <ProductTourLightPrimaryLarge />
}
