export default (key: string): void => {
  /* eslint-disable */
  // @ts-ignore
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  // @ts-ignore
  window._linkedin_data_partner_ids.push(key)
  ;(function () {
    const s = document.getElementsByTagName('script')[0]
    const b = document.createElement('script')
    b.type = 'text/javascript'
    b.async = true
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
    // @ts-ignore
    s.parentNode.insertBefore(b, s)
  })()
  /* eslint-enable */
}
