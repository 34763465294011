import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'

import { TableColumn } from '@plvs/rally/components/table'
import { ScrimmageRow } from '@plvs/rally/components/table/column'

const useStyles = makeStyles({
  iconStyling: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export const ScrimmageEsportIcon: TableColumn<ScrimmageRow> = {
  accessor: 'esport.slug',
  Cell: ({ esport }) => {
    const { iconStyling } = useStyles()
    const theme = useTheme()
    const esportSlug = esport?.slug ?? null
    const { NexusIcon } = useGeneralEsportAdapter(esportSlug)
    return NexusIcon ? (
      <NexusIcon
        className={iconStyling}
        color={theme.palette.ColorIconAlt2}
        height="32"
        width="32"
      />
    ) : (
      <></>
    )
  },
  Header: 'Esport',
  sortable: false,
  width: 80,
}
