import { List } from '@material-ui/core'
import React from 'react'
import { CompetitionGroup, Metaseason } from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql/types'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RosterCardMenuRowSubItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { AddSubToBenchDialog } from '@plvs/respawn/features/manage-teams/AddSubToBench'
import { BenchRosterPlayer, UserRole } from '../RosterProvider.types'
import { RosterListAddItem } from './RosterListAddItem'
import { RosterRowFilled } from './RosterRowFilled'
import { RosterRowFilledBenchMenu } from './menus/RosterRowFilledBenchMenu'

export interface RosterRowBenchListWrapperProps {
  members: BenchRosterPlayer[]
  teamId: string
  onMutationSuccess?(): Promise<void>
  isPast: boolean
  isBenchFull: boolean
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  readOnly: boolean
  metaseason?: Pick<Metaseason, 'id' | 'endsAt'>
  userId: string
  rosterCardSubRowActions?: RosterCardMenuRowSubItems[]
  esportSlug: EsportSlug | undefined
  hasUnlimitedPass?: boolean
  isCoachForTeamsOrg: boolean
  isAdmin: boolean
  competitionGroup: CompetitionGroup | undefined
}

export const RosterRowBenchListWrapper: React.FC<
  RosterRowBenchListWrapperProps
> = ({
  onMutationSuccess,
  teamId,
  members,
  isPast,
  isBenchFull,
  isCoachForTeamsOrg,
  getPermissions,
  metaseason,
  readOnly,
  userId,
  rosterCardSubRowActions,
  esportSlug,
  hasUnlimitedPass,
  isAdmin,
  competitionGroup,
}) => {
  return (
    <List>
      {(isCoachForTeamsOrg || isAdmin) && !readOnly && (
        <RosterListAddItem
          DialogElement={AddSubToBenchDialog}
          disabled={isBenchFull}
          disabledTooltipTitle="The team bench is full"
          onMutationSuccess={onMutationSuccess}
          schoolId=""
          teamId={teamId}
          title="Add a Substitute"
        />
      )}
      {members.map(
        (member, i) =>
          member?.user?.id && (
            <RosterRowFilled
              key={member.user.id}
              competitionGroup={competitionGroup}
              disableAction={isPast || readOnly}
              esportSlug={esportSlug}
              getPermissions={getPermissions}
              hasUnlimitedPass={hasUnlimitedPass}
              isAdmin={isAdmin}
              isLastNode={i === members.length - 1}
              member={member}
              members={members}
              metaseason={metaseason}
              onMutationSuccess={onMutationSuccess}
              rosterCardSubRowActions={rosterCardSubRowActions}
              RowMenu={RosterRowFilledBenchMenu}
              teamId={teamId}
              userId={userId}
            />
          )
      )}
    </List>
  )
}
