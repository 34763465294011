// This component is a wrapper around a component hosted by Plasmic.
// Cannot be effectively unit tested.
/* istanbul ignore file */

import { PlasmicComponent } from '@plasmicapp/loader-react'
import React from 'react'

export const GlobalSchoolBountyPlasmicComponent: React.FC = () => {
  return <PlasmicComponent component="GlobalSchoolBountyCalloutComponent" />
}
