import { makeVar, ReactiveVar } from '@apollo/client'

export interface UserProfileInterface {
  teamIds: string[]
  loading: boolean
  error: Error | undefined
}

export const userProfileVar: ReactiveVar<UserProfileInterface> = makeVar<
  UserProfileInterface
>({
  teamIds: [],
  loading: false,
  error: undefined,
})
