import React from 'react'
import {
  NxTypography,
  EsportSlug,
  esportLogoMap,
} from '@playvs-inc/nexus-components'
import { ListSubheader, makeStyles, MenuItem } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { FilterLeague, getLabel } from './leagueFilterHelpers'

export const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1.5),
  },
}))

interface Props {
  handleClose(): void
  selectedLeague: FilterLeague
  leagues: FilterLeague[]
  setLeague: (id: string) => void
  /* Use the league's esport name instead of display name */
  showEsportName: boolean
  /* Use a league's name instead of display name */
  showLeagueName: boolean
  /* Truncate the league name text up to 40chars */
  shouldTruncateName: boolean
  title: string
}

export const LeagueFilterSubgroup: React.FC<Props> = ({
  handleClose,
  leagues,
  selectedLeague,
  setLeague,
  showEsportName,
  shouldTruncateName,
  showLeagueName,
  title,
}) => {
  const classes = useStyles()

  return (
    <div>
      <ListSubheader className={classes.title}>
        <NxTypography colorToken="ColorTextAlt2" variant="overline">
          {title}
        </NxTypography>
      </ListSubheader>
      {leagues.map(
        (l, i): React.ReactElement => {
          const label = getLabel({
            league: l,
            showEsportName,
            shouldTruncateName,
            showLeagueName,
          })

          const esportSlug = l?.esport?.slug ?? EsportSlug.LeagueOfLegends

          // todo MATCH-6784 fix
          // @ts-ignore No types for this but they're not really necessary
          const Icon: React.FC<{ className: string }> | null = esportSlug
            ? esportLogoMap[esportSlug]
            : null

          return (
            <MenuItem
              key={l.id}
              className={classes.menuItem}
              data-cy={label}
              onClick={(): void => {
                setLeague(l.id)
                handleClose()
              }}
              selected={l.id === selectedLeague.id}
              value={i}
            >
              <Box alignItems="center" display="flex">
                {Icon && <Icon className={classes.icon} />}
                <NxTypography variant="body3">{label}</NxTypography>
              </Box>
            </MenuItem>
          )
        }
      )}
    </div>
  )
}
