import { makeStyles } from '@material-ui/core'
import { CheckCircle, Error, Info, Warning } from '@material-ui/icons'
import { SnackbarProvider } from 'notistack'
import React from 'react'

import { Colors } from '@plvs/rally/themes'
import NotificationCloseButton from './NotificationCloseButton'

interface ChildrenProps {
  children: React.ReactChild | React.ReactChild[]
}

const IconWrapper = ({ children }: ChildrenProps): React.ReactElement => (
  <div style={{ alignItems: 'center', display: 'flex', marginRight: 8 }}>
    {children}
  </div>
)

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    color: Colors.Black,
  },
}))

export const NotificationProvider = ({
  children,
}: ChildrenProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      action={(key): React.ReactNode => [
        <NotificationCloseButton key={`close-${key}`} theKey={key} />,
      ]}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={3333}
      classes={{
        variantError: classes.error,
        variantWarning: classes.warning,
      }}
      iconVariant={{
        error: (
          <IconWrapper>
            <Error />
          </IconWrapper>
        ),
        info: (
          <IconWrapper>
            <Info />
          </IconWrapper>
        ),
        success: (
          <IconWrapper>
            <CheckCircle />
          </IconWrapper>
        ),
        warning: (
          <IconWrapper>
            <Warning />
          </IconWrapper>
        ),
      }}
      maxSnack={5}
    >
      <>{children}</>
    </SnackbarProvider>
  )
}
