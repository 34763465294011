import React, { useEffect, useState } from 'react'
import { Param } from '@plvs/const'
import {
  EsportSlug,
  useGetSchoolTeamsQuery,
  useUpdateCurrentAnnualPassBySchoolIdMutation,
} from '@plvs/graphql'
import { TeamsFilterBar } from '@plvs/rally/containers/team'
import { useQueryParams } from '@plvs/rally/components/filter'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import {
  PageContentGutter,
  Box,
  useBreakpointSm,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { HeroGutter } from '@plvs/rally/components/hero'
import { AllTeams } from '@plvs/rally/containers/manageTeams/AllTeams'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { MatchHeader } from '@plvs/rally/containers/match/MatchHeader'
import {
  useLeagueConfig,
  useSchoolLeagueInfoContext,
} from '@plvs/respawn/containers/filter/league/hooks'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { chain } from 'ramda'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { withTransaction } from '@elastic/apm-rum-react'
import { HeroTabsGutter } from '@plvs/respawn/features/layout/HeroTabsGutter'
import { AppPage } from '../../page'

export const ManageTeamsPageInternal: React.FC = () => {
  const flags = useFlags()
  const isMobile = useBreakpointSm()
  const [params] = useQueryParams([Param.Esport])
  const qParamEsportSlug = params[Param.Esport] ?? undefined

  const [esportSlug, setEsportSlug] = useState<EsportSlug | undefined>(():
    | EsportSlug
    | undefined => {
    if (
      qParamEsportSlug &&
      Object.values(EsportSlug).includes(qParamEsportSlug as EsportSlug)
    ) {
      return qParamEsportSlug as EsportSlug
    }
    return undefined
  })
  const [isInitialLeagueSet, setIsInitialLeagueSet] = useState(false)

  const { loading, organizationId } = useManageTeamsContext()
  const { league, leagues } = useSchoolLeagueInfoContext()
  const [mutate] = useUpdateCurrentAnnualPassBySchoolIdMutation()

  const { setLeague } = useLeagueConfig()

  useEffect(() => {
    if (qParamEsportSlug && !isInitialLeagueSet) {
      const initialLeague = leagues.find((currLeague) => {
        return currLeague?.esport?.slug === qParamEsportSlug
      })

      if (initialLeague?.id) {
        setLeague(initialLeague.id)
        setIsInitialLeagueSet(true)
      }
    }
  }, [isInitialLeagueSet, leagues, qParamEsportSlug])

  const esportId = league?.esport.id ?? ''
  const { data, loading: allTeamsLoading, refetch } = useGetSchoolTeamsQuery({
    variables: { schoolId: organizationId },
    skip: !organizationId,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.school?.teams?.some((team) => team?.isHidden)) {
      refetch({ schoolId: organizationId })
    }
  }, [data])

  const allTeams = data?.school?.teams ?? []

  const teamsByEsportAndCompetitionModel = allTeams.filter(
    (team) => team.esport.id === esportId
  )
  const isEnrolledForPromotedMetaseason = !!chain(
    (team) => team.enrolledSeasons ?? [],
    allTeams
  ).find((enrolledSeason) => !!enrolledSeason.metaseason?.isPromoted)

  const metaseasonIdsToUpdate = [
    chain((team) => team.enrolledSeasons ?? [], allTeams).find(
      (enrolledSeason) => !!enrolledSeason.metaseason?.isPromoted
    )?.metaseason?.id ?? '',
  ].filter((metaseason) => metaseason)

  const callMutate = metaseasonIdsToUpdate.length

  useEffect(() => {
    if (callMutate) {
      mutate({
        variables: {
          schoolId: organizationId,
          metaseasonIds: metaseasonIdsToUpdate,
        },
      })
    }
  }, [callMutate])

  const loadingData = loading || allTeamsLoading
  const desktopPadding = flags.activateFullBleedBanners ? 0 : 3

  const manageTeamsReturn = (
    <Box display="flex" flex={1} flexDirection={isMobile ? 'column' : 'row'}>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        pr={isMobile ? 0 : desktopPadding}
      >
        <AllTeams allTeams={teamsByEsportAndCompetitionModel} />
      </Box>
    </Box>
  )

  return (
    <WaitTillLoaded loading={loadingData} showSpinnerWhileLoading>
      <AppPage title="Manage Teams">
        {!isMobile && (
          <HeroGutter mb={1.25}>
            <PenaltyBox>
              <Esport slug={esportSlug}>
                <MatchHeader isEnrolled={isEnrolledForPromotedMetaseason} />
              </Esport>
            </PenaltyBox>
          </HeroGutter>
        )}
        <PenaltyBox>
          <HeroTabsGutter>
            <Box mt={flags.activateFullBleedBanners && !isMobile ? 3 : 0}>
              <TeamsFilterBar setEsportSlug={setEsportSlug} />
            </Box>
          </HeroTabsGutter>
          <PageContentGutter style={{ marginTop: 0 }}>
            {manageTeamsReturn}
          </PageContentGutter>
        </PenaltyBox>
      </AppPage>
    </WaitTillLoaded>
  )
}

export const ManageTeamsPage = withTransaction(
  'ManageTeamsPage',
  'page'
)(ManageTeamsPageInternal)
