import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { Box, FlexSpacer } from '@plvs/respawn/features/layout'

import { RouterNavLink } from '@plvs/respawn/features/route'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { TabInterface } from './hero'

interface ButtonTabProps {
  end?: React.ReactNode
  tabs: TabInterface[]
}

const useStyles = makeStyles((theme) => ({
  tabButton: {
    backgroundColor: 'transparent',
    borderRadius: 24,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 2),
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
    '&:hover': {
      backgroundColor:
        theme.palette.ColorBackgroundBody || theme.palette.background.default,
    },
    '&[aria-disabled="true"]': {
      backgroundColor: 'transparent',
      color: theme.palette.ColorTextDisabled,
    },
  },
  tabButtonActive: {
    backgroundColor:
      theme.palette.ColorBackgroundAccent || theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor:
        theme.palette.ColorBackgroundAccentAlt || theme.palette.primary.dark,
    },
  },
}))

export const ButtonTabs: React.FC<ButtonTabProps> = ({ end, tabs }) => {
  const { pathname } = useLocation()
  const classes = useStyles()
  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="row"
      flexWrap="wrap"
    >
      {tabs.map(({ label, to, disabled, dataTestid }) => {
        return (
          <Box key={to} flex={0}>
            <Button
              className={clsx(
                pathname.toLowerCase().includes(to)
                  ? classes.tabButtonActive
                  : '',
                classes.tabButton
              )}
              component={RouterNavLink}
              data-testid={dataTestid}
              disabled={disabled}
              to={to}
              variant="contained"
            >
              {label}
            </Button>
          </Box>
        )
      })}
      {end && <FlexSpacer />}
      {end}
    </Box>
  )
}
