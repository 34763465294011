import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'

interface TriggerMatchAssistantAlertsButtonProps {
  label: string
  onClick(): void
  disabled?: boolean
}

export const TriggerMatchAssistantAlertsButton: React.FC<TriggerMatchAssistantAlertsButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <Box width="60%">
      <NxButton
        disabled={disabled}
        fullWidth
        label={label}
        onClick={onClick}
        variant="secondary"
      />
    </Box>
  )
}
