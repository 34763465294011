import React from 'react'
import { useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import {
  ScrimmageRequestFilter,
  CompetitionGroup,
  useGetSchoolAvailableScrimmagesQuery,
} from '@plvs/graphql'
import { useStateAbbr, useWeekday } from '@plvs/rally/components/filter'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import {
  Column,
  Table,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import { ScrimmageTableLimits } from '@plvs/const'
import {
  ScrimmagePageRefreshContext,
  sortScrimmagesByDescendingTime,
} from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { useScrimmageEsportFilter } from '@plvs/rally/containers/scrimmage/ScrimmageFilters'
import { ContainerColumn } from '@plvs/rally/containers/table'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import {
  AvailableScrimmageRow,
  AvailableScrimmageRowWithDetails,
} from '@plvs/rally/components/table/column/scrimmage'
import { useRating } from '@plvs/rally/components/filter/useRating'
import { NxSkeletonLoader } from '@playvs-inc/nexus-components'
import {
  AvailableScrimmagesEmptySubtitle,
  ScrimmagesCTA,
} from '@plvs/rally/components/scrimmage'
import { ScrimmagesEmpty } from '../ScrimmagesEmpty'

dayjs.extend(tz)
interface AvailableScrimmagesProps {
  schoolId: string
  schoolCompetitionGroup?: CompetitionGroup | null
}

const addEsportDetailsToScrimmages = ({
  allEsportAdapters,
  scrimmageRequests,
}: {
  allEsportAdapters: GeneralEsportAdapter[]
  scrimmageRequests: AvailableScrimmageRow[]
}): AvailableScrimmageRowWithDetails[] => {
  return scrimmageRequests
    .map((scrimmageRequest) => {
      const esportDetails =
        allEsportAdapters.find(
          (adapter) => adapter.slug === scrimmageRequest?.esport?.slug
        ) ?? null
      return {
        ...scrimmageRequest,
        platformType: esportDetails?.platformType ?? null,
        platformIcon: esportDetails?.platformIcon ?? null,
        teamMinStarterSize: esportDetails?.teamMinStarterSize ?? 0,
        hasSeries: esportDetails?.hasSeries ?? false,
      }
    })
    .sort(sortScrimmagesByDescendingTime)
}

export const AvailableScrimmages: React.FC<AvailableScrimmagesProps> = ({
  schoolId,
  schoolCompetitionGroup,
}) => {
  const theme = useTheme()

  const { stateAbbrFilter, selectedStateAbbr } = useStateAbbr()
  const { esportSlugFilter, selectedEsportSlug } = useScrimmageEsportFilter()
  const { weekdayFilter, selectedWeekday } = useWeekday()
  const { ratingFilter, selectedRating } = useRating()
  const allEsportAdapters = useAllEsportAdapters()

  const { page, setPage, getNumPages, offset } = usePagination({
    itemsPerPage: ScrimmageTableLimits.AvailableScrimmages,
  })
  const { isRefreshed } = React.useContext(ScrimmagePageRefreshContext) ?? {}

  const dayOfWeek = selectedWeekday
    ? { day: selectedWeekday, timezone: dayjs.tz.guess() }
    : null

  const scrimmageRequestFilter: ScrimmageRequestFilter = {
    ratings: selectedRating ? [selectedRating] : null,
    states: selectedStateAbbr ? [selectedStateAbbr] : null,
    esportSlugs: selectedEsportSlug ? [selectedEsportSlug] : null,
    dayOfWeek,
    competitionGroups: schoolCompetitionGroup
      ? [schoolCompetitionGroup]
      : [CompetitionGroup.College, CompetitionGroup.HighSchool],
  }

  const {
    data: scrimmageData,
    loading: loadingScrimmages,
  } = useGetSchoolAvailableScrimmagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: schoolId,
      offset,
      limit: ScrimmageTableLimits.AvailableScrimmages,
      filter: scrimmageRequestFilter,
      // @ts-ignore cache bust
      isRefreshed,
    },
    skip: !schoolId,
  })

  // extend with platform + team size

  const scrimmages = addEsportDetailsToScrimmages({
    allEsportAdapters,
    scrimmageRequests:
      scrimmageData?.school?.availableScrimmageRequests?.result ?? [],
  })
  const totalAvailableScrimmages =
    scrimmageData?.school?.availableScrimmageRequests?.total ?? 0

  const numPages = getNumPages(totalAvailableScrimmages)

  return (
    <WaitTillLoaded
      loading={loadingScrimmages || !schoolId || !schoolCompetitionGroup}
      LoadingComponent={(): React.ReactElement => (
        <NxSkeletonLoader height="426px" variant="rect" width="100%" />
      )}
      showSpinnerWhileLoading
    >
      <MatchCard
        px={0}
        sideContent={
          <Box
            data-cy="availableScrimmagesFilter"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gridGap={theme.spacing(1)}
            justifyContent="flex-end"
          >
            {ratingFilter}
            {weekdayFilter}
            {esportSlugFilter}
            {stateAbbrFilter}
          </Box>
        }
        title="Available Scrimmages"
        titleTypographyVariant="h4"
      >
        {scrimmages.length ? (
          <>
            <Table
              columns={[
                ContainerColumn.ScrimmageEsportIcon,
                Column.ScrimmageRequestingTeam,
                Column.ScrimmageRating,
                Column.ScrimmageFormattedDateAndTime,
                Column.ScrimmageJoin,
                Column.EndCap,
              ]}
              data={scrimmages}
            />
            <Box mt={2} />
            {numPages > 1 && (
              <TablePagination
                numPages={numPages}
                page={page}
                setPage={setPage}
              />
            )}
          </>
        ) : (
          <ScrimmagesEmpty
            subtitle={`${AvailableScrimmagesEmptySubtitle} ${ScrimmagesCTA}`}
          />
        )}
      </MatchCard>
    </WaitTillLoaded>
  )
}
