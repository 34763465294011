import React from 'react'
import { Box, useTheme, Avatar } from '@material-ui/core'

import { Stats } from '@playvs-inc/nexus-icons'

import { RocketLeaguePlayerSeasonStatsFragment } from '@plvs/graphql/generated'
import { isEmpty } from 'ramda'

import { useRosterContext } from '../../RosterProvider'
import { StatsAvatarWrapper } from './RosterRowFilledStats.helpers'
import { useStyles } from './RosterRowFilledStats.styles'

export interface RosterRowFilledStatsRocketLeagueProps {
  memberId: string
}

export const RosterRowFilledStatsRocketLeague: React.FC<
  RosterRowFilledStatsRocketLeagueProps
> = ({ memberId }) => {
  const theme = useTheme()
  const styles = useStyles()

  const { statsByPlayer } = useRosterContext()

  const rocketLeagueStats = statsByPlayer[memberId]
  const showRocketLeagueStats =
    rocketLeagueStats?.rocketLeagueStats &&
    !isEmpty(rocketLeagueStats?.rocketLeagueStats)

  const rocketLeagueStatsAvatar = (
    {
      averageShots,
      averageGoals,
      averageAssists,
      averageSaves,
    }: RocketLeaguePlayerSeasonStatsFragment,
    options: { avatar: React.ReactElement; title: string }
  ): React.ReactNode => {
    return (
      <StatsAvatarWrapper
        tooltipTitle={
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(1)}
          >
            <Box>
              <Box>{options.title}</Box>
              <Box>Goals: {Math.round(averageGoals)}</Box>
              <Box>Assists: {Math.round(averageAssists)}</Box>
              <Box>Saves: {Math.round(averageSaves)}</Box>
              <Box>Shots: {Math.round(averageShots)}</Box>
            </Box>
          </Box>
        }
      >
        <Avatar className={styles.statsAvatar}>{options.avatar}</Avatar>
      </StatsAvatarWrapper>
    )
  }

  return showRocketLeagueStats ? (
    <Box
      alignItems="center"
      alignSelf="flex-start"
      display="flex"
      gridGap={theme.spacing(0.5)}
    >
      {rocketLeagueStatsAvatar(
        rocketLeagueStats.rocketLeagueStats as RocketLeaguePlayerSeasonStatsFragment,
        {
          avatar: <Stats />,
          title: 'Avg. Game Performance',
        }
      )}
    </Box>
  ) : (
    <></>
  )
}
