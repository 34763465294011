import React from 'react'
import { NxTypography, NxTooltip } from '@playvs-inc/nexus-components'
import clsx from 'clsx'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Box, useBreakpointMd } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'
import { MatchStatus, MatchTeam, Maybe, School } from '@plvs/graphql/generated'

import { getTooltip, truncate } from './leagueMatchesHelpers'

const { FirebobDisabled } = NxMiscAssets

const TEAM_NAME_LIMIT = 13
const SCHOOL_NAME_LIMIT = 17

interface StyleProps {
  isLeftSide: boolean
}

export const useStyles = makeStyles((theme) => ({
  avatarLeft: {
    marginLeft: theme.spacing(2),
  },
  avatarRight: {
    marginRight: theme.spacing(2),
  },
  teamSection: ({ isLeftSide }: StyleProps): CreateCSSProperties => ({
    textAlign: isLeftSide ? 'right' : 'left',
  }),
  opaqueTeam: {
    opacity: '30%',
  },
}))

interface Props {
  didTeamLose: boolean
  isLeftSide: boolean
  isRestrictedEsport: boolean
  team: Maybe<
    Pick<MatchTeam, 'id' | 'name'> & {
      school: Maybe<Pick<School, 'id' | 'name' | 'logoUrl'>>
    }
  >
  matchStatus: MatchStatus | undefined
  hasValidResults: boolean
}

export const LeagueMatchTeamCluster: React.FC<Props> = ({
  didTeamLose,
  isLeftSide,
  isRestrictedEsport,
  team,
  matchStatus,
  hasValidResults,
}) => {
  const classes = useStyles({ isLeftSide })

  const isMd = useBreakpointMd()

  const shouldTooltipAvatar = !isLeftSide && !hasValidResults

  const TeamsSection = (
    <div className={classes.teamSection}>
      <NxTypography variant="body2">
        {isMd ? truncate(team?.name ?? '', TEAM_NAME_LIMIT) : team?.name}
      </NxTypography>
      {!isRestrictedEsport && (
        <NxTypography data-testid="school-name" variant="body4">
          {isMd
            ? truncate(team?.school?.name ?? '', SCHOOL_NAME_LIMIT)
            : team?.school?.name}
        </NxTypography>
      )}
    </div>
  )

  const tooltip = getTooltip(matchStatus)

  const AvatarCluster = shouldTooltipAvatar ? (
    <>
      {tooltip ? (
        <NxTooltip arrow placement="right" title={tooltip}>
          <Box alignItems="center" display="flex">
            <FirebobDisabled />
          </Box>
        </NxTooltip>
      ) : (
        <FirebobDisabled />
      )}
      <Box display="flex" flexDirection="column" ml={2}>
        <NxTypography variant="body2">N/A</NxTypography>
        <NxTypography variant="body4">N/A</NxTypography>
      </Box>
    </>
  ) : (
    <>
      {isLeftSide && TeamsSection}
      <NxUserAvatar
        avatarUrl={isRestrictedEsport ? undefined : team?.school?.logoUrl}
        className={isLeftSide ? classes.avatarLeft : classes.avatarRight}
        hashId={team?.id ?? ''}
        size={NxAvatarSize.MD}
      />
      {!isLeftSide && TeamsSection}
    </>
  )

  return (
    <Box
      alignItems="center"
      className={clsx(didTeamLose && classes.opaqueTeam)}
      display="flex"
      justifyContent={isLeftSide ? 'flex-end' : 'start'}
    >
      {AvatarCluster}
    </Box>
  )
}
