import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'
import {
  OptionalWeeksCache,
  Selection,
} from '@plvs/respawn/containers/enrollment/types'
import React, { useState } from 'react'
import { NxButton, NxModal, NxTypography } from '@playvs-inc/nexus-components'
import { SlotExclusionWindowResourceType } from '@plvs/graphql'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { NO_BREAK_WEEK_HASH } from '@plvs/respawn/containers/enrollment/enrollmentHelpers'

const useStyles = makeStyles((theme) => ({
  select: ({
    isMobile,
    width,
  }: {
    isMobile: boolean
    width: string
  }): CreateCSSProperties => ({
    width: isMobile ? '100%' : width,
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingTop: 15,
    marginTop: 10,
    marginBottom: theme.spacing(1),
    '& .MuiSelect-select:focus, div': {
      backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    },
  }),
  selectLabel: {
    position: 'relative',
    top: '38px',
    left: '11px',
    zIndex: 1,
  },
  option: {
    color: theme.palette.ColorTextBase,
  },
  formControl: {
    marginTop: theme.spacing(-1.5),
  },
}))

enum DefaultOptionId {
  Default = 'default',
}

export interface OptionalWeeksMenuProps {
  configSuggestions: Array<{
    startsAt: string
    endsAt: string
    hash: string
    isAvailable: boolean
    title: string
  }>
  updateOptionalWeeksCache(input: OptionalWeeksCache): void
  optionalWeeksCache: OptionalWeeksCache
  selectedSlotCache: {
    confirmedSelection: Selection | null
    selection: Selection | null
  }
  resourceType: SlotExclusionWindowResourceType
  resourceId: string
  seasonId?: string
  selectLabel?: string
  width?: string
}

export const OptionalWeeksMenu: React.FC<OptionalWeeksMenuProps> = ({
  configSuggestions,
  optionalWeeksCache,
  updateOptionalWeeksCache,
  selectedSlotCache,
  seasonId,
  resourceId,
  resourceType,
  selectLabel,
  width = '40%',
}) => {
  const isMobile = useBreakpointXs()
  const classes = useStyles({ isMobile, width })
  const [showModal, setShowModal] = useState(false)

  const formattedOptions = [
    { value: 'default', label: 'Select a Break Week', disabled: true },
    ...configSuggestions.map((suggestion) => {
      return {
        value: suggestion.hash,
        label: suggestion.title,
        disabled: !suggestion.isAvailable,
      }
    }),
  ]

  const selectedOptionId =
    formattedOptions.find(
      (option) => option.value === selectedSlotCache.selection?.hash
    )?.value ?? 'default'

  const changeSelection = (
    selectedHash: string,
    hasConfirmed: boolean
  ): void => {
    if (selectedHash === NO_BREAK_WEEK_HASH && !hasConfirmed) {
      setShowModal(true)
      return
    }
    const selectedSuggestion = configSuggestions.find(
      (suggestion) => suggestion.hash === selectedHash
    )
    const formattedSelectedSuggestion = {
      hash: selectedSuggestion?.hash ?? '',
      startsAt: selectedSuggestion?.startsAt ?? '',
      endsAt: selectedSuggestion?.endsAt ?? '',
      formattedSelection: selectedSuggestion?.title ?? 'TBD',
      isEditable: true,
    }
    if (seasonId) {
      updateOptionalWeeksCache({
        ...optionalWeeksCache,
        [seasonId]: {
          selections: [
            {
              selection: formattedSelectedSuggestion,
              confirmedSelection: selectedSlotCache.confirmedSelection,
            },
          ],
          resourceId,
          resourceType,
          seasonId,
        },
      })
    } else {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      selectedSlotCache.selection = formattedSelectedSuggestion
      updateOptionalWeeksCache({ ...optionalWeeksCache })
    }
  }

  const confirmNoBreakWeek = (): void => {
    setShowModal(false)
    changeSelection(NO_BREAK_WEEK_HASH, true)
  }

  const onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const selectedHash = event.target.value as string
    changeSelection(selectedHash, false)
  }

  return (
    <>
      <FormControl className={classes.formControl} fullWidth size="small">
        <InputLabel
          className={classes.selectLabel}
          id="break-week-select-label"
        >
          {selectLabel || 'General Break Week'}
        </InputLabel>
        <Select
          className={classes.select}
          data-testid="OptionalWeeksMenu_Select"
          disableUnderline
          id="break-week-select"
          label="Break Week"
          labelId="break-week-select-label"
          onChange={onChange}
          value={selectedOptionId || DefaultOptionId.Default}
        >
          {formattedOptions.map((option) => {
            return (
              <MenuItem
                key={option.value}
                data-testid="OptionalWeeksMenu_MenuItem"
                disabled={option.disabled}
                value={option.value}
              >
                <NxTypography className={classes.option}>
                  {option.label}
                </NxTypography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <NxModal
        actions={
          <Box display="flex">
            <NxButton
              label="Cancel"
              onClick={(): void => setShowModal(false)}
              variant="text"
            />
            <NxButton
              label="Confirm"
              onClick={confirmNoBreakWeek}
              variant="primary"
            />
          </Box>
        }
        onClose={(): void => setShowModal(false)}
        open={showModal}
        showTopRightClose
        size="small"
        subtitle="I understand that by choosing “No Break Week”, my team(s) will continuously play throughout the season without a BYE week."
        title="Confirm “No Break Week” Selection"
      />
    </>
  )
}
