import React from 'react'
import { Card, CardContent, makeStyles, useTheme } from '@material-ui/core'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { LeagueFilters } from '@plvs/rally/containers/filter/league/LeagueFilters'
import { useGetPurchasedProductsForSchoolQuery } from '@plvs/graphql'
import { WaitTillLoaded, Box, FlexSpacer } from '@plvs/respawn/features/layout'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { PaymentPlan as Presentational } from '@plvs/checkpoint/components/school/enrollment/PaymentPlan'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  body1: {
    color: theme.palette.ColorTextAlt2,
  },
  cardAction: {
    padding: 0,
    margin: 0,
  },
}))
export interface PaymentPlanProps {
  id: string
}

export const PaymentPlan: React.FC<PaymentPlanProps> = ({ id }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { metaseason } = useSchoolLeagueInfoContext()
  const metaseasonId = metaseason?.id ?? ''
  const metaseasonName = metaseason?.name ?? ''

  const { data, loading } = useGetPurchasedProductsForSchoolQuery({
    variables: { id, metaseasonId },
    skip: !metaseasonId,
  })

  const annualPassInstances = data?.school?.annualPassInstances

  const productPurchased = annualPassInstances?.find(
    (instance) => instance?.product?.id === instance?.productId
  )

  const hasPaymentPlan = !!productPurchased

  return (
    <Card>
      <CardContent className={classes.cardAction}>
        <WaitTillLoaded
          loading={loading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <Box
            boxShadow={`0px 1px 0px ${theme.palette.ColorBackgroundInactive}`}
            px={5}
            py={5}
          >
            <Box display="flex" flex={1} flexDirection="row">
              <Box display="flex" flexDirection="column">
                <NxTypography variant="body2">Payment Plan</NxTypography>
                <Box pt={1}>
                  <NxTypography className={classes.body1} variant="body1">
                    All actions performed on a school’s account are recorded in
                    event history.
                  </NxTypography>
                </Box>
              </Box>
              <FlexSpacer />
              <LeagueFilters
                showLeagues={false}
                showPhases={false}
                showTeams={false}
              />
            </Box>
          </Box>
          {id && metaseasonId ? (
            <Presentational
              hasPaymentPlan={hasPaymentPlan}
              id={id}
              metaseasonName={metaseasonName}
              productPurchased={productPurchased?.product}
            />
          ) : (
            <Box
              data-testid="PaymentPlan_InfoMissingContainer"
              maxWidth="50%"
              pb={1}
              pt={4}
              px={5}
            >
              <Banner
                subtitle="Unable to find school and/or season info, please refresh and try again."
                title="Unable to find school and season info"
                type={BannerType.Error}
              />
            </Box>
          )}
        </WaitTillLoaded>
      </CardContent>
    </Card>
  )
}
