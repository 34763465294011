import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { makeStyles, IconButton } from '@material-ui/core'
import { head, last } from 'ramda'
import { isNumber, isUndefined } from 'ramda-adjunct'
import { Tooltip } from '@plvs/rally/components/tooltip'
import { CaretLeft, CaretRight } from '@playvs-inc/nexus-icons'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { CreateCSSProperties } from '@material-ui/styles'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

export const useStyles = makeStyles((theme) => ({
  container: ({ bannerV2 }: { bannerV2: boolean }): CreateCSSProperties => ({
    position: bannerV2 ? 'absolute' : 'inherit',
    top: bannerV2 ? theme.spacing(4) : 0,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      position: 'inherit',
    },
  }),
  caretIcon: {
    fill: 'inherit',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.black,
    },
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fill: theme.palette.common.white,
    '&.MuiIconButton-root.Mui-disabled': {
      fill: theme.palette.ColorIconDisabled,
    },
    [theme.breakpoints.down('sm')]: {
      fill: theme.palette.common.black,
    },
  },
  typography: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.black,
    },
  },
  currentYearButton: {
    minWidth: theme.spacing(24),
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    border: `1px solid ${theme.palette.common.white}`,
  },
}))

export const CompetitionInterval: React.FC = () => {
  const flags = useFlags()
  const isMobile = useBreakpointSm()
  const classes = useStyles({ bannerV2: flags.activateFullBleedBanners })
  const {
    competitionIntervals,
    competitionInterval,
    setDefaultCompetitionInterval,
    currentCompetitionIntervalPosition,
    setCompetitionIntervalPosition,
  } = useManageTeamsContext()

  const isFirstCompetitionInterval =
    currentCompetitionIntervalPosition === head(competitionIntervals)?.position
  const isLastCompetitionInterval =
    currentCompetitionIntervalPosition === last(competitionIntervals)?.position
  const intervalPositionNotInitialized = isUndefined(
    currentCompetitionIntervalPosition
  )
  const disableRight =
    isLastCompetitionInterval || intervalPositionNotInitialized
  const disableLeft =
    isFirstCompetitionInterval || intervalPositionNotInitialized

  const deprecatedLeftPadding = isMobile ? 2 : 7
  const deprecatedTopPadding = isMobile ? 2 : 5

  const leftTooltipTitle = disableLeft
    ? 'Your school did not have an account active for the previous school year'
    : ''
  const rightTooltipTitle = disableRight
    ? 'Competitions for this next school year are not yet available'
    : ''

  return (
    <Box
      alignItems="center"
      className={classes.container}
      display="flex"
      flexDirection="row"
      pl={flags.activateFullBleedBanners ? 0 : deprecatedLeftPadding}
      pt={flags.activateFullBleedBanners ? 0 : deprecatedTopPadding}
    >
      {isMobile ? (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          pl={isMobile ? 0 : 2}
        >
          <IconButton
            className={classes.iconButton}
            data-testid="CompetitionInterval_CaretLeft"
            disabled={disableLeft}
            onClick={(): void => {
              if (isNumber(currentCompetitionIntervalPosition))
                setCompetitionIntervalPosition(
                  currentCompetitionIntervalPosition - 1
                )
            }}
          >
            <CaretLeft className={classes.caretIcon} />
          </IconButton>

          <Box width="100%">
            <NxTypography
              className={classes.typography}
              data-testid="CompetitionInterval_Typography"
              variant="body3"
            >
              {competitionInterval?.name}
            </NxTypography>
          </Box>
          <IconButton
            className={classes.iconButton}
            data-testid="CompetitionInterval_CaretRight"
            disabled={disableRight}
            onClick={(): void => {
              if (isNumber(currentCompetitionIntervalPosition))
                setCompetitionIntervalPosition(
                  currentCompetitionIntervalPosition + 1
                )
            }}
          >
            <CaretRight className={classes.caretIcon} />
          </IconButton>
        </Box>
      ) : (
        <>
          <NxButton
            className={classes.currentYearButton}
            data-testid="CompetitionInterval_NexusButton"
            label={isMobile ? 'Current Year' : 'Current School Year'}
            onClick={setDefaultCompetitionInterval}
            variant="secondary"
          />

          <Box display="flex" flexDirection="row" pl={2}>
            <Tooltip
              data-testid="CompetitionInterval_LeftToolTip"
              title={leftTooltipTitle}
            >
              <IconButton
                className={classes.iconButton}
                data-testid="CompetitionInterval_CaretLeft"
                disabled={disableLeft}
                onClick={(): void => {
                  if (isNumber(currentCompetitionIntervalPosition))
                    setCompetitionIntervalPosition(
                      currentCompetitionIntervalPosition - 1
                    )
                }}
              >
                <CaretLeft className={classes.caretIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              data-testid="CompetitionInterval_RightToolTip"
              title={rightTooltipTitle}
            >
              <IconButton
                className={classes.iconButton}
                data-testid="CompetitionInterval_CaretRight"
                disabled={disableRight}
                onClick={(): void => {
                  if (isNumber(currentCompetitionIntervalPosition))
                    setCompetitionIntervalPosition(
                      currentCompetitionIntervalPosition + 1
                    )
                }}
              >
                <CaretRight className={classes.caretIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box pl={2} width="100%">
            <NxTypography
              className={classes.typography}
              data-testid="CompetitionInterval_Typography"
              variant="body1"
            >
              {competitionInterval?.name}
            </NxTypography>
          </Box>
        </>
      )}
    </Box>
  )
}
