import {
  isValidPhoneNumber,
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPhoneExtension,
  yupPhoneNumber,
} from '@plvs/utils'
import * as yup from 'yup'

export const BillingInfoSchema = yup.object().shape({
  address: yup.string(),
  city: yup.string(),
  email: yupEmail,
  firstName: yupFirstName,
  lastName: yupLastName,
  phone: yupPhoneNumber.test({
    name: 'phone',
    message: 'Please provide a valid phone number',
    /* istanbul ignore next */
    test: isValidPhoneNumber,
  }),
  phoneExt: yupPhoneExtension,
  state: yup.string(),
  zip: yup
    .string()
    .test(
      'length',
      'Must be at least 5 digits',
      (val) => val.length === 0 || val.length >= 5
    ),
})

export interface SchoolBasicInfoInput {
  address: string
  city: string
  email: string
  firstName: string
  lastName: string
  phone: string
  phoneExt: string
  state: string
  zip: string
}
