import React from 'react'
import clsx from 'clsx'

import { AddCircle } from '@plvs/rally/components/icon'
import { MenuItem } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { Path, SessionStorageKey } from '@plvs/const'
import { NxTypography } from '@playvs-inc/nexus-components'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useStyles } from '../Menu.styles'

export const handleJoinSchool = (navigate: NavigateFunction): void => {
  sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
  navigate(Path.JoinSchool)
}

export const AddSchoolMenuItem: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <MenuItem
      className={classes.defaultMenuItem}
      id="addMenuItem"
      onClick={(): void => handleJoinSchool(navigate)}
    >
      <Box className={classes.iconContainer}>
        <AddCircle className={classes.blueAdd} />
      </Box>
      <Box className={classes.textContainer}>
        <NxTypography className={classes.text} variant="body2">
          Join your school
        </NxTypography>
        <NxTypography
          className={clsx([classes.text, classes.subText])}
          variant="body4"
        >
          Represent your school in esports
        </NxTypography>
      </Box>
    </MenuItem>
  )
}
