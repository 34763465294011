import React from 'react'
import {
  CompetitionGroup,
  School,
  useFindSchoolsByIdsQuery,
} from '@plvs/graphql'
import {
  useAutoskipQuery,
  CompetitionModelsConfig,
  competitionModelsConfigByEntity,
} from '@plvs/utils'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { head } from 'ramda'

type OrganizationsContext = {
  organizationIds: string[] | undefined
  organizations: Pick<School, 'id' | 'name' | 'competitionGroup' | 'logoUrl'>[]
  organization:
    | Pick<School, 'id' | 'name' | 'competitionGroup' | 'logoUrl'>
    | undefined
  loading: boolean
  competitionModels: CompetitionModelsConfig
  competitionGroup: CompetitionGroup | null | undefined
}

interface OrganizationProviderProps {
  organizationIds?: string[]
  organizations?: Pick<School, 'id' | 'name' | 'competitionGroup' | 'logoUrl'>[]
}

export const organizationsContext = React.createContext<OrganizationsContext>({
  organizationIds: [],
  organizations: [],
  organization: undefined,
  loading: false,
  competitionModels: [],
  competitionGroup: undefined,
})

export const useOrganizationsContext = (): OrganizationsContext => {
  return React.useContext(organizationsContext)
}

export const OrganizationsProvider: React.FC<OrganizationProviderProps> = ({
  organizationIds,
  organizations: organizationProps,
  children,
}) => {
  const {
    organizationIds: defaultOrganizationIds,
    selectedEntityId,
  } = useProfileContext()
  const orgIdsToQuery = organizationIds ?? defaultOrganizationIds

  const { data, loading } = useAutoskipQuery(useFindSchoolsByIdsQuery, {
    variables: {
      ids: orgIdsToQuery,
    },
    skip: !orgIdsToQuery?.length,
  })

  const organizations = organizationProps ?? data?.findSchoolsByIds ?? []

  const organization = organizations.find((org) => org.id === selectedEntityId)

  const competitionGroup =
    organization?.competitionGroup ?? head(organizations)?.competitionGroup

  const competitionModelsConfig = competitionModelsConfigByEntity({
    competitionGroup,
  })

  return (
    <organizationsContext.Provider
      value={{
        organizationIds,
        organizations,
        organization,
        competitionModels: competitionModelsConfig,
        loading,
        competitionGroup,
      }}
    >
      {children}
    </organizationsContext.Provider>
  )
}
