import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Hide, Unhide } from '@playvs-inc/nexus-icons'
import {
  NxButton,
  NxTextInput,
  NxTypography,
} from '@playvs-inc/nexus-components'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useSnackbar } from 'notistack'
import {
  useChangeChildPasswordMutation,
  useCreateUserEmailMutation,
} from '@plvs/graphql'
import {
  passwordRequirementsValidation,
  yupEmail,
  yupEmailRequired,
} from '@plvs/utils'
import { useNavigate } from 'react-router'
import { handleChildAccountError } from './helpers'

const useStyles = makeStyles((theme) => ({
  inputTitles: {
    marginBottom: theme.spacing(3),
  },
  inputFieldContainer: {
    maxWidth: '360px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  passwordInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginBottom: theme.spacing(5),
  },
  endAdornment: {
    marginRight: theme.spacing(1.375),
  },
}))

const CONFIRM_PASSSWORD_ERROR = 'Passwords must match'
const PASSWORD_LENGTH_ERROR = 'Password must be between 8-64 characters long.'

const ChangeLoginSettingsFormSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  newSchoolEmail: yupEmailRequired,
  password: passwordRequirementsValidation,
})

interface ChangeLoginSettingsFormInput {
  confirmPassword: string
  newSchoolEmail: string
  password: string
}

export const ChildEmailPasswordForm: React.FC<{
  userId: string
}> = ({ userId }) => {
  const classes = useStyles()

  const navigate = useNavigate()

  const [canSubmitEmail, setCanSubmitEmail] = React.useState(false)
  const [canSubmitPassword, setSubmitPassword] = React.useState(false)

  const [showPassword, setShowPassword] = React.useState(false)
  const ShowPasswordIcon = showPassword ? Unhide : Hide
  const [passwordFormError, setPasswordFormError] = React.useState('')

  const [createUserEmail] = useCreateUserEmailMutation()
  const [changeChildPassword] = useChangeChildPasswordMutation()

  const { enqueueSnackbar } = useSnackbar()

  const { register, watch, reset } = useForm<ChangeLoginSettingsFormInput>({
    resolver: yupResolver<ChangeLoginSettingsFormInput>(
      ChangeLoginSettingsFormSchema
    ),
  })

  const watchAllFields = watch()

  const onFormUpdate = async (): Promise<void> => {
    const { newSchoolEmail, password, confirmPassword } = watchAllFields

    try {
      if (newSchoolEmail.length) {
        await yupEmail.validate(newSchoolEmail)
        setCanSubmitEmail(true)
      }
      if (password && confirmPassword) {
        if (password !== confirmPassword) {
          setSubmitPassword(false)
          throw new Error(CONFIRM_PASSSWORD_ERROR)
        }
        if (password === confirmPassword) {
          await passwordRequirementsValidation.validate(password)
          setSubmitPassword(true)
          setPasswordFormError('')
        }
      }
    } catch (e: any) {
      if (e.message === 'Invalid email address') {
        setCanSubmitEmail(false)
      }
      if (e.message === CONFIRM_PASSSWORD_ERROR) {
        setPasswordFormError(CONFIRM_PASSSWORD_ERROR)
      }
      if (e.message === PASSWORD_LENGTH_ERROR) {
        setPasswordFormError(PASSWORD_LENGTH_ERROR)
        setSubmitPassword(false)
      }
    }
  }

  const submitEmail = async (): Promise<void> => {
    const { newSchoolEmail } = watchAllFields

    try {
      await createUserEmail({
        variables: {
          userId,
          attributes: {
            email: newSchoolEmail,
            isSchoolEmail: true,
          },
          options: {
            overwriteExisting: true,
          },
        },
      })
      enqueueSnackbar('Email address has been successfully updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      })
      reset({ newSchoolEmail: '' })
    } catch (e: any) {
      handleChildAccountError({ enqueueSnackbar, navigate, userId })
    }
  }

  const submitPasswordChange = async (): Promise<void> => {
    const { password } = watchAllFields
    try {
      await changeChildPassword({
        variables: {
          input: {
            userId,
            newPassword: password,
          },
        },
      })
      enqueueSnackbar('password has been successfully updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      })
      reset({ password: '', confirmPassword: '' })
    } catch (e: any) {
      handleChildAccountError({ enqueueSnackbar, navigate, userId })
    }
  }

  return (
    <Box>
      <form onChange={onFormUpdate}>
        <NxTypography className={classes.inputTitles} variant="h2">
          Edit Email
        </NxTypography>
        <Box className={classes.inputFieldContainer}>
          <NxTextInput
            ref={register}
            data-testid="password"
            fullWidth
            label="School Email"
            name="newSchoolEmail"
            type="email"
          />
          <NxButton
            className={classes.submitButton}
            disabled={!canSubmitEmail}
            fullWidth
            label="Save"
            onClick={submitEmail}
            variant="primary"
          />
        </Box>

        <NxTypography className={classes.inputTitles} variant="h2">
          Edit Password
        </NxTypography>
        <Box className={classes.inputFieldContainer}>
          <Box className={classes.passwordInputContainer}>
            <NxTextInput
              ref={register}
              data-testid="password"
              endAdornment={
                <ShowPasswordIcon
                  className={classes.endAdornment}
                  data-cy="showPasswordIcon"
                  onClick={(): void => setShowPassword(!showPassword)}
                />
              }
              fullWidth
              helperText="Password must be between 8-64 characters long"
              label="New Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
            />
            <NxTextInput
              ref={register}
              data-testid="confirm-password"
              endAdornment={
                <ShowPasswordIcon
                  className={classes.endAdornment}
                  data-cy="showPasswordIcon"
                  onClick={(): void => setShowPassword(!showPassword)}
                />
              }
              fullWidth
              helperText="Password must be between 8-64 characters long"
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
            />
          </Box>
          {passwordFormError && (
            <NxTypography colorToken="ColorTextError">
              {passwordFormError}
            </NxTypography>
          )}
          <NxButton
            className={classes.submitButton}
            disabled={!canSubmitPassword}
            fullWidth
            label="Save"
            onClick={submitPasswordChange}
            variant="primary"
          />
        </Box>
      </form>
    </Box>
  )
}
