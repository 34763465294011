import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

interface StyleProps {
  readonly?: boolean
}

export const useStyles = makeStyles((theme) => ({
  autocomplete: ({ readonly }: StyleProps): CreateCSSProperties => ({
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
    visibility: readonly ? 'hidden' : 'visible',
  }),

  footer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& button': {
        width: '100%',
      },
    },
  },
}))
