import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

interface StyleProps {
  isMobile?: boolean
}

export const useStyles = makeStyles((theme) => ({
  container: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    height: '100%',
    width: isMobile ? '100vw' : '26rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: theme.palette.ColorBackgroundBase,
  }),
  emptyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
  },
  emptyStateCloseButtonIcon: ({
    isMobile,
  }: StyleProps): CreateCSSProperties => ({
    position: 'absolute',
    top: '1.5rem',
    right: isMobile ? 'unset' : '1.5rem',
    left: isMobile ? '1.5rem' : 'unset',
    cursor: 'pointer',
  }),
  closeButton: {
    marginLeft: 'auto',
    marginRight: '1.5rem',
    cursor: 'pointer',
  },
  backButton: {
    alignItems: 'flex-end',
    marginLeft: '1.3rem',
    height: '2.75rem',
    cursor: 'pointer',
  },
  emptyTitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.ColorTextAlt2,
    lineHeight: '130%',
  },
  emptyBody: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.ColorTextAlt2,
    lineHeight: '130%',
  },
  backText: {
    marginLeft: '.5rem',
    fontFamily: 'Whitney, sans-serif',
    fontWeight: 600,
    letterSpacing: '0.06px',
  },
  header: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    minWidth: isMobile ? '100%' : '26rem',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    right: 0,
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.boxShadow.elevation1,
  }),
  titleContainer: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    paddingLeft: '1rem',
    height: '7rem',
    width: '100%',
    paddingTop: isMobile ? theme.spacing(6) : 0,
  }),
  headerTitle: {
    marginLeft: '.25rem',
    fontSize: '1.75rem',
  },
  drawerSectionTitle: {
    fontSize: '1.13rem',
    marginBottom: '.5rem',
    color: theme.palette.ColorTextBase,
  },
  cardContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backdrop: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    marginTop: isMobile ? theme.spacing(19.5) : theme.spacing(14),
    position: 'relative',
    backgroundColor: theme.palette.ColorBackgroundBody,
  }),
  notificationTasks: {
    width: '100%',
  },
}))
