import React, { useEffect } from 'react'
import { ResourceType } from '@plvs/graphql'
import { rallyEnv } from '@plvs/rally/env'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { useLocation } from 'react-router-dom'
import { AppDrawer, UserMinimalUser } from './AppDrawer'

export enum HiddenRoutes {
  ManageEnrollment = 'manage/enrollment',
}

type DrawerFeatureFlag =
  | 'hasResources'
  | 'isCoachAtOrg'
  | 'hasManageTeams'
  | 'isFilteredToOrganization'

export type DrawerFeatureFlagRecord = Record<DrawerFeatureFlag, boolean>

interface Props {
  isPublic?: boolean
}

export const RallyAppDrawer: React.FC<Props> = ({ isPublic = false }) => {
  const flags = useFlags()
  const { pathname } = useLocation()

  // Query Hooks
  const {
    userId,
    userName,
    isCoachAtOrg,
    isPlayerAtOrg,
    isCaptainAtOrg,
    isParent,
  } = useUserIdentityFn()
  const { getResourceImageOfSize } = useResourceImageProvider()
  const avatarUrl =
    getResourceImageOfSize({
      resource: {
        id: userId,
        type: ResourceType.User,
      },
      size: NxAvatarSize.XXL,
    })?.url ?? 'Unknown'

  // Custom Hooks
  const { id: orgId } = useSelectedOrganizationFn()
  const { setEntity, selectedEntityId } = useProfileContext()

  const userNameAndAvatar = {
    name: `${userName}`,
    avatarUrl,
  }

  useEffect(() => {
    if (selectedEntityId !== orgId) {
      setEntity(ResourceType.Organization, orgId)
    }
  }, [orgId])

  const isHiddenRoute = Object.values(HiddenRoutes).some((route) => {
    return pathname.includes(route)
  })

  if (isHiddenRoute) {
    return null
  }

  return (
    <AppDrawer
      apiEnv={rallyEnv.API_ENV}
      isCaptain={isCaptainAtOrg}
      isCoach={isCoachAtOrg}
      isParent={flags?.coppaSupport && isParent}
      isPlayer={isPlayerAtOrg}
      isPublic={isPublic}
      userNameAndAvatar={userNameAndAvatar as UserMinimalUser}
    />
  )
}
