import React from 'react'

import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { MatchReportReturnToMatchButton } from './MatchReportReturnToMatchButton'

export const MatchReportNoMatchWarningBanner: React.FC<{ matchId: string }> = ({
  matchId,
}) => (
  <Banner
    data-testid="no-match-found"
    subtitle="A match with this id does not exist in our records."
    title="No match found"
    type={BannerType.Warning}
  >
    <MatchReportReturnToMatchButton matchId={matchId} />
  </Banner>
)
