import { useEffect, useState } from 'react'

/**
 * Changes the document title and restores previous title on unmount.
 *
 * @param title Document title as shown in the browser tab
 */
export function useTitle(title: string): void {
  const [prevTitle] = useState(document.title)

  useEffect(() => {
    document.title = title

    return (): void => {
      document.title = prevTitle
    }
  }, [title])
}
