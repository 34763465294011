import React from 'react'
import { Box } from '@material-ui/core'

import {
  NxButton,
  NxFirebobMessage,
  NxTooltip,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { logger } from '@plvs/rally/logging'
import { useSubmitGameAssistantStepSelectionsMutation } from '@plvs/graphql'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { TeamStatsRosterHeader } from '@plvs/respawn/features/match-lobby/TeamStatsRosterHeader'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles as useMatchAssistantStyles } from '../../../MatchAssistant.styles'

const getFirebobMessage = ({
  isWaitingForOpponent,
  canManageRoster,
  canManageMatch,
  rolesThatCanManageRosterCopy,
}: {
  isWaitingForOpponent: boolean
  canManageRoster: boolean
  canManageMatch: boolean
  rolesThatCanManageRosterCopy: string[]
}): string => {
  if (isWaitingForOpponent) {
    return 'Opponent is confirming their roster'
  }

  if (canManageMatch || canManageRoster) {
    return 'Make any roster changes'
  }

  return `Players may confirm the roster, only ${rolesThatCanManageRosterCopy.join(
    ' and '
  )} can make changes.`
}

interface RosterSelectProps {
  teamId?: string
}

export const RosterSelect: React.FC<RosterSelectProps> = ({ teamId = '' }) => {
  const maStyles = useMatchAssistantStyles()
  const {
    currentStep,
    teamId: myTeamId,
    myTeam,
    match,
    esportSlug,
    opposingTeam,
  } = useMatchAssistantContext()
  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const {
    enableScoutingToTeamsPage,
  } = getMatchLobbyRenderControllerState().lobby

  const {
    match: {
      canParticipate,
      canManageRoster,
      canManageMatch,
      rolesThatCanManageRosterCopy,
    },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const isWaitingForOpponent = currentStep?.step?.submitted ?? false
  const hasValidRoster =
    myTeam?.starters?.filter((starter) => starter.id)?.length ===
    myTeam?.rosterFormat?.teamSize

  const isNextDisabled =
    !canParticipate || isWaitingForOpponent || !hasValidRoster

  const handleNext = async (): Promise<void> => {
    logger.debug('[RosterSelect | handleNext]')

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
          },
        },
      })
    } catch (err) {
      logger.error(err)
    }
  }

  const isMyTeam = myTeamId === teamId
  const team = isMyTeam ? myTeam : opposingTeam

  const firebobMessage = isMyTeam
    ? getFirebobMessage({
        isWaitingForOpponent,
        canManageRoster,
        canManageMatch,
        rolesThatCanManageRosterCopy,
      })
    : 'Review your opponent’s roster, click Confirm to continue'
  const buttonLabel = isMyTeam ? 'Confirm Roster' : 'Confirm'

  const button = (
    <NxButton
      className={maStyles.cta}
      disabled={isNextDisabled}
      fullWidth
      label={buttonLabel}
      loading={isWaitingForOpponent || updateLoading}
      onClick={handleNext}
      variant="primary"
    />
  )

  return (
    <>
      <Box margin={-3}>
        <RosterContainer
          borderRadius="0px"
          CustomHeader={(): React.ReactElement => (
            <Box marginTop={-2}>
              <TeamStatsRosterHeader
                enableScoutingToTeamsPage={enableScoutingToTeamsPage}
                esportSlug={esportSlug ?? null}
                hasClinchedPlayoffs={
                  !!team?.regularSeasonTeamPhaseStandings?.hasClinchedPlayoffs
                }
                isTeamDeleted={!!team?.archivedAt}
                phaseId={match?.slot?.phase?.id}
                ranking={team?.regularSeasonTeamPhaseStandings?.ranking}
                teamId={teamId}
                teamName={team?.name}
                winLossRecord={{
                  matchesWon:
                    team?.regularSeasonTeamPhaseStandings?.winLossRecord
                      ?.matchesWon ?? 0,
                  matchesLost:
                    team?.regularSeasonTeamPhaseStandings?.winLossRecord
                      ?.matchesLost ?? 0,
                }}
              />
            </Box>
          )}
          isInMatchAssistantContainer
          noBoxShadow
          overrideTeamRosterViewPermissions
          readOnly={isWaitingForOpponent || !isMyTeam}
          rosterCardStarterRowActions={
            isMyTeam
              ? [
                  RosterCardMenuRowItems.RepositionPlayer,
                  RosterCardMenuRowItems.PromoteToTeamCaptain,
                  RosterCardMenuRowItems.RemoveTeamCaptain,
                ]
              : []
          }
          rosterCardSubRowActions={
            isMyTeam
              ? [
                  RosterCardMenuRowSubItems.RepositionPlayer,
                  RosterCardMenuRowSubItems.PromoteToTeamCaptain,
                  RosterCardMenuRowSubItems.RemoveTeamCaptain,
                ]
              : []
          }
          showAdditionalContainerActions={false}
          showCreateScrimmage={false}
          teamId={teamId}
        />
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {hasValidRoster ? (
          button
        ) : (
          <NxTooltip title="Ensure your roster is full!">
            <Box>{button}</Box>
          </NxTooltip>
        )}
      </MatchAssistantFooter>
    </>
  )
}
