import dayjs from 'dayjs'
import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'
import { NotificationSpotWrapper } from '../../NotificationSpotWrapper'

export const PlayerDeniedAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Player Denied',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SystemMessage
        IconComponent={<NotificationSpotWrapper />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        receivedAt={dayjs(notification.createdAt).toDate()}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
