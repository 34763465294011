import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import {
  NxButton,
  NxFirebobMessage,
  NxSelect,
  NxSelectOption,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import { logger } from '@plvs/rally/logging'
import {
  useGetEpicPlatformSelectionOptionsByUserIdsQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles as useMatchAssistantStyles } from '../../../MatchAssistant.styles'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
  },
}))

const getFirebobMessage = ({
  allPlatformsSelected,
  isWaitingForOpponent,
}: {
  allPlatformsSelected: boolean
  isWaitingForOpponent: boolean
}): string => {
  if (allPlatformsSelected && !isWaitingForOpponent) {
    return 'Nice selections!'
  }

  if (isWaitingForOpponent) {
    return 'Both teams need to be ready.'
  }

  return 'What platforms are we running?'
}

export const PlatformCheck: React.FC = () => {
  const styles = useStyles()
  const maStyles = useMatchAssistantStyles()
  const { currentStep, myTeam, currentStepLoading } = useMatchAssistantContext()

  const {
    match: { canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const [selections, setSelections] = useState<Record<string, string>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState(
    currentStep?.step?.submitted ?? false
  )

  const {
    data: userPlatformOptions,
    loading,
  } = useGetEpicPlatformSelectionOptionsByUserIdsQuery({
    variables: {
      userIds: myTeam?.starters?.map(({ id }) => id) ?? [],
    },
  })

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const allPlatformsSelected =
    Object.entries(selections).length === myTeam?.starters?.length

  const isWaitingForOpponent = hasSubmitted
  const isNextDisabled =
    canSpectate || isWaitingForOpponent || !allPlatformsSelected

  const handleChange = (
    userId: string,
    evnt: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const { value } = evnt.target
    setSelections({ ...selections, [userId]: value as string })
  }

  const handleNext = async (): Promise<void> => {
    setIsSubmitting(true)
    if (isNextDisabled) {
      setIsSubmitting(false)
      return
    }

    logger.debug('[PlatformCheck | handleNext]', selections)

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
            selectionOptions: Object.entries(selections).map(
              ([userId, platform]) => ({
                userId,
                selectionOptionId: platform,
              })
            ),
          },
        },
      })

      setHasSubmitted(true)
      setIsSubmitting(false)
    } catch (err) {
      logger.error(err)
    }
  }

  const firebobMessage = getFirebobMessage({
    allPlatformsSelected,
    isWaitingForOpponent,
  })

  const submittedSelections = currentStep?.step?.selections ?? []

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <NxTypography variant="h1">Match Prep</NxTypography>

        <Box className={styles.grid} mt={3}>
          <WaitTillLoaded
            loading={currentStepLoading || loading}
            loadingSpinnerProps={{ size: 'medium' }}
            showSpinnerWhileLoading
          >
            {myTeam?.starters?.map(
              ({ id, avatarUrl, publisherAccountName, name }) => {
                const platformOptions = userPlatformOptions?.epicPlatformSelectionOptionsByUserIds?.selectionOptions?.find(
                  ({ userId }) => userId === id
                )?.selectionOptions

                const defaultValue = submittedSelections?.find(
                  (option) => option?.userId === id
                )?.selectionOption?.id

                return (
                  <>
                    <NxUserCluster
                      alignment="right"
                      avatarHashId={id}
                      avatarUrl={avatarUrl ?? ''}
                      subtitles={[{ title: publisherAccountName }]}
                      title={name ?? ''}
                    />

                    {platformOptions && platformOptions.length ? (
                      <NxSelect
                        defaultValue={defaultValue}
                        disabled={isWaitingForOpponent || isSubmitting}
                        fullWidth
                        label="Confirm Platform"
                        name={`platform-${id}`}
                        onChange={(evnt): void => handleChange(id, evnt)}
                      >
                        <NxSelectOption value="">
                          Choose a platform
                        </NxSelectOption>
                        {platformOptions?.map((selectionOption) => {
                          const {
                            displayName,
                            id: platformId,
                          } = selectionOption

                          return (
                            <NxSelectOption key={platformId} value={platformId}>
                              {displayName}
                            </NxSelectOption>
                          )
                        })}
                      </NxSelect>
                    ) : (
                      <NxTypography colorToken="ColorTextError" variant="body1">
                        This user must connect their Epic account from their own
                        Account Settings Page.
                      </NxTypography>
                    )}
                  </>
                )
              }
            )}
          </WaitTillLoaded>
        </Box>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <NxButton
          className={maStyles.cta}
          disabled={isNextDisabled}
          fullWidth
          label="Confirm Platforms"
          loading={isWaitingForOpponent || updateLoading}
          onClick={handleNext}
          variant="primary"
        />
      </MatchAssistantFooter>
    </>
  )
}
