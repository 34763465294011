export const RewindAssets = {
  PlayVS: 'https://s3.amazonaws.com/assets.playvs.com/replay/RewindPlayVS.png',
  Joystick:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/JoyStick.png',
  Scribble:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/RewindScribble.png',
  Blast:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/RewindBlast.png',
  Checker:
    'https://s3.amazonaws.com/assets.playvs.com/replay/RewindCheckerboard.png',
  Spiral:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/RewindNameSpiral.png',
  Logo:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/RewindLogo.png',
  Swish:
    'https://s3.amazonaws.com/assets.playvs.com/replay/fall-2023/RewindSwish.png',
  Finale:
    'https://s3.amazonaws.com/assets.playvs.com/replay/SeeyounextSpring.png',
  PlayVSCup: 'https://s3.amazonaws.com/assets.playvs.com/RewindPlayVSCup.png',
  CTA: 'https://s3.amazonaws.com/assets.playvs.com/replay/Rewind+Small.png',
  Background:
    'https://s3.amazonaws.com/assets.playvs.com/replay/composedbg.jpg',
  Joysticks: [
    'https://s3.amazonaws.com/assets.playvs.com/replay/JoyStick-0.png',
    'https://s3.amazonaws.com/assets.playvs.com/replay/JoyStick-1.png',
    'https://s3.amazonaws.com/assets.playvs.com/replay/JoyStick-2.png',
    'https://s3.amazonaws.com/assets.playvs.com/replay/JoyStick-3.png',
    'https://s3.amazonaws.com/assets.playvs.com/replay/JoyStick-4.png',
  ],
}

export function getCurrentSeason(): string {
  const date = new Date()
  const month = date.getMonth()
  if (month < 6) {
    return 'Spring'
  }

  return 'Fall'
}

export function getNextSeason(): string {
  return getCurrentSeason() === 'Spring' ? 'Fall' : 'Spring'
}

export const RewindData = {
  Year: new Date().getFullYear(),
  CurrentSeason: getCurrentSeason(),
  NextSeason: getNextSeason(),
}

export const RewindGradients = {
  Spring2023: 'linear-gradient(39.68deg, #E017CE 22.54%, #FFA301 92.91%)',
  Fall2023:
    'linear-gradient(137.75deg, #0099BA 14.78%, #0A00D5 57.62%, #FF5000 98.02%)',
  Text: 'linear-gradient(180deg, #FFFFFF 0%, #CEFFF9 100%)',
}
