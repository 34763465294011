import React from 'react'

import { TableColumn } from '../Table'

// Because react-table applies different padding to the last column, this component
//  can be useful to absorb the weirdness
export const EndCap: TableColumn<any> = {
  Cell: () => <></>,
  Header: '',
  id: 'endCap',
  minWidth: 64, // should match the amount of padding that the column gets
}
