import React from 'react'
import { betterCompact } from '@plvs/utils'
import { useEsportSlug } from '@plvs/rally/components/filter'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { useMySchoolTeamEsportsQuery, EsportSlug } from '@plvs/graphql'
import { getEsportsSlugFilterConfig } from '@plvs/rally/components/scrimmage/scrimmageHelpers'

export const useScrimmageEsportFilter = (): {
  selectedEsportSlug?: EsportSlug
  esportSlugFilter: React.ReactElement
} => {
  const { data } = useMySchoolTeamEsportsQuery()
  const teamEsportSlugs: EsportSlug[] = betterCompact(
    (data?.me?.school?.teams ?? []).map((team) => team?.esport?.slug)
  )
  const allEsportAdapterData = useAllEsportAdapters()
  const allEsportSlugsAndNames = betterCompact(
    allEsportAdapterData.map((esportAdapter) =>
      esportAdapter.slug
        ? { slug: esportAdapter.slug, name: esportAdapter.name }
        : null
    )
  )

  allEsportSlugsAndNames.sort((a, b) => {
    const leagueAEsportName = a?.name || ''
    const leagueBEsportName = b?.name || ''
    return leagueAEsportName > leagueBEsportName ? 1 : -1
  })

  return useEsportSlug({
    ...getEsportsSlugFilterConfig({
      filteredEsportSlugs: teamEsportSlugs,
      useAllEsportAdapters: allEsportAdapterData,
    }),
    allEsportSlugsAndNames,
  })
}
