import React from 'react'
import clsx from 'clsx'
import { Card, CardContent, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout/Box'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
    overflowX: 'scroll',
  },
  section: {
    display: 'flex',
    flexShrink: 0,
    width: 184,
    flexDirection: 'column',
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderRadius: theme.shape.borderRadius * 2.5,
    color: theme.palette.ColorTextAlt,
    padding: theme.spacing(2, 3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  extraPaddingTop: {
    paddingTop: theme.spacing(3.25),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: 'auto',
  },
}))

interface Props {
  className?: string
  formattedTime: string
  teamFormat: number
  teamMaxSize: number
}

export const LeagueOverviewCard: React.FC<Props> = ({
  className,
  formattedTime,
  teamFormat,
  teamMaxSize,
}) => {
  const classes = useStyles()

  return (
    <Card className={className}>
      <CardContent className={classes.cardContent}>
        <Box display="flex">
          <div className={classes.section}>
            <NxTypography
              className={classes.title}
              data-cy="competionTime"
              variant="h4"
            >
              {formattedTime}
            </NxTypography>
            <NxTypography className={classes.subtitle} variant="body3">
              Competition Time
            </NxTypography>
          </div>
          <div className={clsx(classes.section, classes.extraPaddingTop)}>
            <NxTypography
              className={classes.title}
              data-cy="requiredPlayers"
              variant="h2"
            >
              {teamFormat}
            </NxTypography>
            <NxTypography className={classes.subtitle} variant="body3">
              Required Players
            </NxTypography>
          </div>
          <div className={clsx(classes.section, classes.extraPaddingTop)}>
            <NxTypography
              className={classes.title}
              data-cy="benchSlots"
              variant="h2"
            >
              {teamMaxSize - teamFormat}
            </NxTypography>
            <NxTypography className={classes.subtitle} variant="body3">
              Bench Slot Available
            </NxTypography>
          </div>
        </Box>
      </CardContent>
    </Card>
  )
}
