import { Box } from '@material-ui/core'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useIsPasswordResetValidQuery } from '@plvs/graphql/generated/graphql'
import { Path } from '@plvs/const'
import { PasswordResetForm } from './PasswordResetForm'

interface Props {
  token: string
}

export const PasswordReset = ({ token }: Props): React.ReactElement => {
  const { data, error, loading } = useIsPasswordResetValidQuery({
    variables: { input: { email: '', vcode: token } },
  })
  if (loading)
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        Checking your password reset token...
      </Box>
    )

  if (data?.isPasswordResetValid?.isValid && token) {
    return <PasswordResetForm token={token} />
  }

  if (error) {
    return <Navigate to={`${Path.ForgotPassword}?error=true`} />
  }

  return <Navigate to={`${Path.ForgotPassword}?error=true`} />
}
