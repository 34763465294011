import React from 'react'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { useDebounce } from 'use-debounce'
import clsx from 'clsx'
import { NxTypography } from '@playvs-inc/nexus-components'
import { NxRandomFirebobHover } from '@playvs-inc/nexus-misc-assets'
// import { useOnboardingTour } from '@plvs/rally/init/shepherd/useOnboardingTour'
import { useProductTours } from '@plvs/rally/init/shepherd/useProductTours'
import { Path, SponsorshipImageName } from '@plvs/const'
import * as analytics from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/rally/init/dayjs'
import { useNavigate } from 'react-router-dom'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

interface StyleProps {
  isHovered?: boolean
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2.8rem',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '67px',
    color: theme.palette.ColorTextBase,
  },
  dashNav: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dashboardIconContainer: {
    width: 68,
    height: 68,
  },
  playVsLogo: ({ isHovered }: StyleProps): CreateCSSProperties => ({
    transition: 'transform 0.40s ease-in-out',
    transform: isHovered ? 'translateX(5px)' : 'translateX(0px)',
  }),
  campaignLogo: {
    maxHeight: 105,
  },
}))

export const AppDrawerLogo: React.FC = () => {
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const [isHovered, setIsHovered] = React.useState(false)
  const [debouncedIsHovered] = useDebounce(isHovered, 100)
  const navigate = useNavigate()
  const classes = useStyles({ isHovered: debouncedIsHovered })

  const { userId, activeCampaign } = useUserIdentityFn()
  const { onboardingTour, initiatedTimestamp } = useProductTours()

  const navigateToDashboard = (): void => {
    if (onboardingTour?.isActive()) {
      analytics.productTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 19,
        clickTarget: 'Dashboard Navigation',
      })
      onboardingTour?.next()
    }
    navigate(Path.Dashboard)
  }

  const lightLogo = activeCampaign?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.BannerLogo
  )
  const darkLogo = activeCampaign?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.DarkModeBannerLogo
  )
  const logo = isDarkMode && darkLogo ? darkLogo : lightLogo
  const imgTag = logo ? (
    <img
      alt="campaign-logo"
      className={classes.campaignLogo}
      data-testid="campaign-logo"
      src={logo.url}
    />
  ) : null

  const renderLogo = (): React.ReactNode => {
    return (
      <Box display="flex" flexDirection="column">
        {imgTag ? (
          <Box alignItems="center" display="flex" flexDirection="column">
            {imgTag}
          </Box>
        ) : (
          <Box display="flex" flexDirection="row">
            <Box className={classes.dashboardIconContainer}>
              <NxRandomFirebobHover
                height={68}
                isHovered={debouncedIsHovered}
                overrideHover
                width={68}
              />
            </Box>
            <Box className={classes.playVsLogo} data-testid="standard-logo">
              <NxTypography className={classes.title}>PlayVS</NxTypography>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box
      className={clsx(classes.dashNav, 'shepherd-playvs-logo')}
      ml={-2}
      mt={imgTag ? 2 : 6}
      onClick={navigateToDashboard}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={(): void => setIsHovered(false)}
    >
      {renderLogo()}
    </Box>
  )
}
