/* istanbul ignore file */
// This is a provider component.  Components using this provider will be tested instead.
import React from 'react'
import { Esport, Maybe, School, Team, useFindTeamsQuery } from '@plvs/graphql'
import { useAutoskipQuery } from '@plvs/utils'
import { Coach } from '@plvs/rally/features/school/helper'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'

export type TeamsContext = {
  teamIds: string[]
  teams: Array<
    Pick<Team, 'id' | 'name' | 'competitionGroup' | 'esportId'> & {
      esport: Pick<Esport, 'slug'>
      school: Maybe<Pick<School, 'logoUrl'>>
      coaches: Maybe<Array<Pick<Coach, 'id' | 'name'>>>
    }
  >
  loadingTeams: boolean
  refetchTeamProvider(): Promise<void>
  /* Team IDs for selected school entity ID */
  schoolTeamIds: string[]
}

interface TeamsProviderProps {
  children: React.ReactNode
}

export const teamsContext = React.createContext<TeamsContext>({
  teamIds: [],
  teams: [],
  loadingTeams: false,
  refetchTeamProvider: async (): Promise<void> => {},
  schoolTeamIds: [],
})

export const useTeamsContext = (): TeamsContext => {
  return React.useContext(teamsContext)
}

export const TeamsProvider: React.FC<TeamsProviderProps> = ({ children }) => {
  const { teamIds: profileTeamIds, selectedEntityId } = useProfileContext()
  const teamIdsArg = profileTeamIds
  const noTeamIds = !teamIdsArg.length
  const orgIdArg = selectedEntityId
  const schoolIdArg = orgIdArg ? { schoolId: orgIdArg } : {}

  const { data, loading, refetch } = useAutoskipQuery(useFindTeamsQuery, {
    variables: {
      ids: teamIdsArg,
      ...schoolIdArg,
    },
    skip: noTeamIds,
  })

  const teams = data?.teamsByIdsSchoolId ?? []
  const schoolTeamIds = teams.map((team) => team.id)

  const refetchTeamProvider = async (): Promise<void> => {
    await refetch({ ids: teamIdsArg, schoolId: orgIdArg })
  }

  return (
    <teamsContext.Provider
      value={{
        teamIds: teamIdsArg,
        teams,
        loadingTeams: loading,
        refetchTeamProvider,
        schoolTeamIds,
      }}
    >
      {children}
    </teamsContext.Provider>
  )
}
