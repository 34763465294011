import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core'

import {
  StandingDarkLargeSecondary,
  StandingLightLargeSecondary,
} from '@playvs-inc/nexus-spots'
import { NxEmptyState } from '@playvs-inc/nexus-components'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { useAutoskipQuery } from '@plvs/utils'
import { ObjectivesScope } from '@plvs/respawn/features/esport/creator/types'
import { StatRow, StatRowHeading } from '@plvs/rally/components/match/'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { useMatchStatsQuery } from '@plvs/graphql'
import { Polling } from '@plvs/const'
import { Objectives } from '@plvs/rally/features/match/lobby/components/Objectives'
import { VersusBars } from '@plvs/rally/features/match/lobby/components/VersusBars'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

interface OverviewStatsProps {
  matchId: string
  team1Id: string
  team2Id: string
}
export const OverviewStats: React.FC<OverviewStatsProps> = ({
  matchId,
  team1Id,
  team2Id,
}) => {
  const theme = useTheme()

  const { getObjectives } = useEsportContext()
  const {
    data: statsData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useAutoskipQuery(useMatchStatsQuery, {
    variables: { matchId },
    skip: !matchId,
  })

  const status = statsData?.match?.status ?? null

  useEffect(() => {
    startPolling(Polling.Slow)
    return (): void => stopPolling()
  }, [status])

  if (error) {
    // allow error boundry to catch
    throw error
  }

  if (!statsData && !loading) {
    const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
    return (
      <NxEmptyState
        isStandalone
        spot={
          isDarkMode ? (
            <StandingDarkLargeSecondary height={200} width={200} />
          ) : (
            <StandingLightLargeSecondary height={200} width={200} />
          )
        }
        subtitle="Sorry about that!"
        title="No stats found"
      />
    )
  }

  const teamMatchStats = statsData?.match?.teamMatchStats ?? []
  const team1MatchStats = teamMatchStats.find(
    ({ teamId }) => teamId === team1Id
  )
  const team2MatchStats = teamMatchStats.find(
    ({ teamId }) => teamId === team2Id
  )

  const objectives = getObjectives(ObjectivesScope.Match)

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        {objectives.length ? (
          <StatRow marginTop={0}>
            <Objectives
              teamMatchStats={team1MatchStats}
              type={ObjectivesScope.Match}
            />
            <StatRowHeading>Objectives</StatRowHeading>
            <Objectives
              opposing
              teamMatchStats={team2MatchStats}
              type={ObjectivesScope.Match}
            />
          </StatRow>
        ) : (
          <></>
        )}

        <VersusBars
          teamAMatchStats={team1MatchStats}
          teamBMatchStats={team2MatchStats}
          type="match"
        />
      </>
    </WaitTillLoaded>
  )
}
