import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  // TODO: This will be updated when NxTypography is refactored to not pass variant styles via inline styles.
  // Priority one is to merge this PR given scope.
  // https://playvs.atlassian.net/browse/NEX-124
  forcedDisplay: {
    fontSize: '2.566rem !important',
    letterSpacing: '-0.02rem !important',
    lineHeight: '108% !important',
    fontWeight: 700,
  },
})

export interface MatchFaceoffScoreProps {
  awayTeamScore?: number
  homeTeamScore?: number
}

export const MatchFaceoffScore: React.FC<MatchFaceoffScoreProps> = ({
  awayTeamScore,
  homeTeamScore,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" px={3}>
      <NxTypography
        className={classes.forcedDisplay}
        colorToken="OverlayColorTextBase"
        data-cy="teamScore"
      >
        {homeTeamScore}
      </NxTypography>
      <Box px={3}>
        <NxTypography
          className={classes.forcedDisplay}
          colorToken="OverlayColorTextBase"
        >
          -
        </NxTypography>
      </Box>
      <NxTypography
        className={classes.forcedDisplay}
        colorToken="OverlayColorTextBase"
        data-cy="opponentScore"
      >
        {awayTeamScore}
      </NxTypography>
    </Box>
  )
}
