import React from 'react'
import { Helmet } from 'react-helmet'

import { Page } from './Page'

export const PrivatePage: React.FC<{
  children: React.ReactNode
  title: string
}> = ({ children, title }) => (
  <Page>
    <Helmet>
      <title>{title}</title>
      <meta content="noindex" name="robots" />
    </Helmet>
    {children}
  </Page>
)
