import React from 'react'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  className?: string
  subtitle?: string
  title: string
}

export const MobileHero: React.FC<Props> = ({ className, subtitle, title }) => {
  const classes = useStyles()

  return (
    <div className={className}>
      <NxTypography className={classes.title} variant="display2">
        {title}
      </NxTypography>
      {subtitle && (
        <NxTypography colorToken="ColorTextAlt2" variant="body2">
          {subtitle}
        </NxTypography>
      )}
    </div>
  )
}
