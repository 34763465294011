import { DialogTitle } from '@material-ui/core'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core/styles'
import { DialogStepCount, DialogStepCountProps } from './DialogStepCount'

type Props = DialogStepCountProps & {
  subtitle?: React.ReactChild
  title?: string
  titleClassName?: string
  subtitleClassName?: string
  subtitleContainerClassName?: string
  headerClassname?: string
}

const useStyle = makeStyles((theme) => ({
  subtitleContainer: {
    paddingTop: theme.spacing(1.5),
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
}))

export const DialogHeader: React.FC<Props> = ({
  currentStepIndex,
  stepCount,
  subtitle,
  title,
  titleClassName,
  subtitleClassName,
  subtitleContainerClassName,
  headerClassname,
}) => {
  const styles = useStyle()
  return title || subtitle ? (
    <DialogTitle className={headerClassname} disableTypography>
      {title && (
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <NxTypography
            className={titleClassName}
            data-testid="DialogHeader_Title"
            variant="h2"
          >
            {title}
          </NxTypography>
          <DialogStepCount
            currentStepIndex={currentStepIndex}
            stepCount={stepCount}
          />
        </div>
      )}
      {subtitle && (
        <div
          className={`${styles.subtitleContainer} ${subtitleContainerClassName}`}
        >
          <NxTypography
            className={subtitleClassName}
            data-testid="DialogHeader_Subtitle"
            variant="body1"
          >
            {subtitle}
          </NxTypography>
        </div>
      )}
    </DialogTitle>
  ) : null
}
