import React, { useState } from 'react'
import { Box, useTheme } from '@material-ui/core'
import {
  NxTypography,
  LoadingSpinner,
  NxSelectableSingle,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import { WarningOutlined } from '@playvs-inc/nexus-icons'

import { useStyles } from './MatchReportAccordion.styles'
import { MatchReportAccordionForm } from './MatchReportAccordionForm'
import {
  GameSelectionValue,
  ReportOption,
  ReportOptionValue,
} from './MatchReportAccordion.types'

interface MatchReportAccordionExpandedContentProps {
  gameIndex: number
  seriesIndex: number
  value: GameSelectionValue
  options: ReportOption[]
  loadingContent: boolean
  onSelectOption(gameIndex: number, newValue: GameSelectionValue): void
}

export const MatchReportAccordionExpandedContent: React.FC<MatchReportAccordionExpandedContentProps> = ({
  gameIndex,
  seriesIndex = 1,
  value,
  options,
  loadingContent,
  onSelectOption,
}) => {
  const styles = useStyles()
  const theme = useTheme()

  const [incompleteFormExpanded, setIncompleteFormExpanded] = useState(
    value?.value === ReportOptionValue.Incomplete
  )

  const selectOptions = [
    ...options.map(({ title, subtitle, imgSrc, hashId }) => (
      <Box
        key={title}
        alignSelf="flex-start"
        className={styles.teamItem}
        display="flex"
        flexDirection="row"
        gridGap="0.5em"
        textAlign="left"
      >
        <NxUserCluster
          avatarHashId={hashId ?? ''}
          avatarUrl={imgSrc}
          compact
          subtitles={[{ title: subtitle }]}
          title={title}
        />
      </Box>
    )),

    <Box
      key="incomplete"
      alignItems="center"
      alignSelf="flex-start"
      className={styles.teamItem}
      display="flex"
      flexDirection="row"
      gridGap="0.5em"
    >
      <div className={styles.incompleteIcon}>
        <WarningOutlined color={theme.palette.ColorIconDisabled} />
      </div>
      <NxTypography noWrap variant="button">
        Incomplete Game
      </NxTypography>
    </Box>,
  ]

  let selectedValue
  if (value?.value === ReportOptionValue.Incomplete) {
    selectedValue = value.value
  } else if (incompleteFormExpanded) {
    selectedValue = ReportOptionValue.Incomplete
  } else if (
    value?.value === ReportOptionValue.MyTeam ||
    value?.value === ReportOptionValue.OppTeam
  ) {
    selectedValue = value.value
  } else {
    selectedValue = -1
  }

  return (
    <>
      <Box data-cy="game" px={3} py={2}>
        <NxTypography variant="overline">Series {seriesIndex + 1}</NxTypography>

        <Box
          className={styles.accordionContent}
          display="flex"
          gridGap={theme.spacing(4)}
        >
          <Box
            className={styles.accordionGame}
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(1)}
            mt={2}
          >
            <NxTypography data-cy="gameIndex" variant="body2">
              Game {gameIndex + 1}
            </NxTypography>

            <NxTypography className={styles.overline} variant="overline">
              Who won?
            </NxTypography>
          </Box>

          <Box
            className={styles.selectables}
            display="flex"
            flex="1"
            flexDirection="column"
            gridGap={theme.spacing(1)}
            justifyContent="center"
            py={3}
          >
            {loadingContent ? (
              <LoadingSpinner size="medium" />
            ) : (
              <NxSelectableSingle
                flexDirection="column"
                height={`${theme.spacing(8)}px`}
                onChange={(_evnt, newValue): void => {
                  if (newValue === ReportOptionValue.Incomplete) {
                    setIncompleteFormExpanded(true)
                  } else {
                    onSelectOption(gameIndex, {
                      value: newValue,
                      meta: undefined,
                    })
                  }
                }}
                padding={1}
                value={selectedValue}
                width="100%"
              >
                {selectOptions}
              </NxSelectableSingle>
            )}
          </Box>
        </Box>
      </Box>

      {incompleteFormExpanded && (
        <MatchReportAccordionForm
          defaultValues={value?.meta}
          gameIndex={gameIndex}
          loadingContent={loadingContent}
          onSelectOption={onSelectOption}
          options={options}
        />
      )}
    </>
  )
}
