import React from 'react'
import { makeStyles } from '@material-ui/core'
import { appendClasses } from '@plvs/utils'
import { Pill } from '@playvs-inc/nexus-components'

export enum PillVariants {
  Pending = 'pending',
  Warning = 'warning',
  Denied = 'denied',
  Processed = 'processed',
}

const useStyles = makeStyles((theme) => ({
  pill: {
    height: '1.37rem',
    fontSize: '.7rem',
    letterSpacing: '0.12rem',
    fontWeight: 700,
    '&:active': {
      boxShadow: 'unset',
    },
    '& .MuiChip-label': {
      padding: '0px 10px',
    },
  },
  pending: {
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundWarn,
      borderColor: theme.palette.ColorBackgroundWarn,
      color: theme.palette.ColorTextWarn,
    },
  },
  warning: {
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundError,
      borderColor: theme.palette.ColorBackgroundError,
      color: theme.palette.ColorTextError,
    },
  },
  denied: {
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundAlt2,
      borderColor: theme.palette.ColorBackgroundAlt2,
      color: theme.palette.ColorTextAlt,
    },
  },
  processed: {
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundInfo,
      borderColor: theme.palette.ColorBackgroundInfo,
      color: theme.palette.ColorTextInfo,
    },
  },
}))

const PILL_MAP: Record<PillVariants, 'disabled' | 'error' | 'info' | 'warn'> = {
  [PillVariants.Pending]: 'warn',
  [PillVariants.Warning]: 'error',
  [PillVariants.Denied]: 'disabled',
  [PillVariants.Processed]: 'info',
}

export const NotificationPill: React.FC<{
  variant: PillVariants
  pillText: string
}> = ({ variant, pillText }) => {
  const classes = useStyles()

  return (
    <Pill
      className={appendClasses(classes.pill, classes[variant])}
      data-cy={pillText}
      label={pillText ?? ''}
      size="small"
      variant={PILL_MAP[variant]}
    />
  )
}
