import React from 'react'

import { MatchStatus } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'

import { MatchFaceoffHeader } from './MatchFaceoffHeader'

export const MatchFaceoff: React.FC<{
  countdown?: string
  liveLabel: string
  mobile: boolean
  size: 'small' | 'medium' | 'large'
  bestOf: number
  status: MatchStatus | null | undefined
  matchId: string
  refetchMatchStatus(): Promise<void>
}> = ({
  children,
  countdown,
  liveLabel,
  mobile,
  size,
  bestOf,
  refetchMatchStatus,
  status,
  matchId,
}) => (
  <Box pb={mobile ? 3 : 5} pt={mobile ? 0 : 3}>
    {size !== 'small' && (
      <MatchFaceoffHeader
        bestOf={bestOf}
        countdown={countdown}
        liveLabel={liveLabel}
        matchId={matchId}
        mobile={mobile}
        refetchMatchStatus={refetchMatchStatus}
        status={status}
      />
    )}
    {children}
  </Box>
)
