import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { MatchStatus, useGetLeagueMatchesQuery } from '@plvs/graphql/generated'
import dayjs from '@plvs/rally/init/dayjs'
import { useBreakpointXs, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { TeamSearch } from '@plvs/rally/components/search/TeamSearch/TeamSearch'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { makeStyles } from '@material-ui/core'
import { NxEmptyState } from '@playvs-inc/nexus-components'
import { StadiumLightLargeSecondary } from '@playvs-inc/nexus-spots'
import { includes } from 'ramda'
import { PhaseFilterType, SlotFilterType } from '../LeagueDetails.types'
import { LeagueMatchesSection } from './LeagueMatchesSection'

const EXCLUDED_MATCH_STATUSES = [MatchStatus.Rescheduled, MatchStatus.Cancelled]

export const useStyles = makeStyles((theme) => ({
  teamSearch: {
    marginBottom: theme.spacing(3),
    width: 360,
  },
}))

interface LeagueMatchesProps {
  phase: PhaseFilterType | undefined
  leagueId: string | undefined
  seasonIds: string[] | undefined
  metaseasonId: string | undefined
  slotFilter: SlotFilterType | undefined
  team: SelectedTeam | null
  setTeam: Dispatch<SetStateAction<SelectedTeam | null>>
}

export const LeagueMatches: React.FC<LeagueMatchesProps> = ({
  leagueId,
  metaseasonId,
  phase,
  slotFilter,
  team,
  setTeam,
  seasonIds,
}) => {
  const phaseId = phase?.id

  const slotId = slotFilter?.id

  const now = useMemo(() => dayjs(), [])

  const classes = useStyles()

  const isXs = useBreakpointXs()

  const hasTeam = !!team

  const shouldUseSlotFilter = !hasTeam && slotId

  const { data, loading } = useGetLeagueMatchesQuery({
    variables: {
      filters: {
        leagueIds: leagueId ? [leagueId] : [],
        seasonIds,
        metaseasonId,
        ...(phaseId && { phaseIds: [phaseId] }),
        ...(shouldUseSlotFilter && { slotId }),
        ...(hasTeam && { teamIds: [team?.id ?? ''] }),
      },
      limit: 150,
      sortFields: [
        {
          fieldName: 'scheduled_starts_at',
          fieldSortDir: 'asc',
        },
      ],
    },
  })

  const matches = (data?.getMatches?.matches ?? []).filter(
    (match) => !includes(match?.status, EXCLUDED_MATCH_STATUSES)
  )
  const liveMatches = matches.filter(
    (match) => match?.status === MatchStatus.Live
  )

  const upcomingMatches = matches.filter(
    (match) =>
      match?.status !== MatchStatus.Live &&
      dayjs(match?.scheduledStartsAt).isAfter(now)
  )

  const pastMatches = matches.filter(
    (match) =>
      match?.status !== MatchStatus.Live &&
      dayjs(match?.scheduledStartsAt).isBefore(now)
  )
  const pastMatchesOnly = matches.length === pastMatches.length
  const defaultNumberOfPastMatches = pastMatchesOnly ? 15 : 3

  return (
    <>
      <TeamSearch
        className={classes.teamSearch}
        onChange={(newTeam): void => setTeam(newTeam)}
        teamFilters={{ leagueId, metaseasonId }}
        value={team}
      />
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        {matches.length > 0 ? (
          <>
            {liveMatches.length > 0 && (
              <LeagueMatchesSection
                matches={liveMatches}
                title="Live Matches"
              />
            )}
            {upcomingMatches.length > 0 && (
              <LeagueMatchesSection
                matches={upcomingMatches}
                title="Upcoming Matches"
              />
            )}
            {pastMatches.length > 0 && (
              <LeagueMatchesSection
                defaultLimit={defaultNumberOfPastMatches}
                matches={pastMatches}
                title="Past Matches"
              />
            )}
          </>
        ) : (
          <NxEmptyState
            spot={
              <StadiumLightLargeSecondary
                height={isXs ? 100 : 200}
                width={isXs ? 100 : 200}
              />
            }
            subtitle="Try adjusting your search or filter to find the matches you’re looking for."
            title="No Matches Found"
          />
        )}
      </WaitTillLoaded>
    </>
  )
}
