import React, { useEffect } from 'react'

interface ScrollToTopProps {
  basedOn?: boolean
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ basedOn = true }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [basedOn])

  return null
}
