import { makeStyles } from '@material-ui/core'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    letterSpacing: '0.2px',
  },
  iconContainer: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
  icon: {
    width: '20px',
    height: '20px',
    stroke: theme.palette.ColorIconAlt2,
  },
  redLabel: {
    color: theme.palette.ColorTextError,
  },
}))

type Props = {
  labelText: string
  labelIcon: React.ReactElement
  isRed?: boolean
  className?: string
}

export const RosterMenuLabel: React.FC<Props> = ({
  labelText,
  labelIcon,
  isRed = false,
  className,
}) => {
  const styles = useStyles()
  return (
    <Box className={clsx(styles.textContainer, className)}>
      <NxTypography className={styles.iconContainer}>{labelIcon}</NxTypography>
      <NxTypography className={isRed ? styles.redLabel : ''}>
        {labelText}
      </NxTypography>
    </Box>
  )
}
