import React, { useState } from 'react'
import {
  SchoolType,
  useEsportsByCompetitionGroupQuery,
  useSetMyEsportInterestsMutation,
} from '@plvs/graphql/generated'
import {
  NxButton,
  NxEsportMultiSelectable,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { Box, Container, makeStyles } from '@material-ui/core'
import { EsportsLoading } from '@plvs/rally/features/createTeam/esports/Esports.loading'
import { useSnackbar } from 'notistack'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import {
  getOnboardEsportSlugs,
  SCHOOL_TYPE_COMPETITION_GROUP_MAP,
} from '@plvs/utils'
import { useOnboardingContext } from './OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(6),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  esports: {
    justifyContent: 'flex-start',
    maxWidth: 750,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft: 'unset',
    },
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    width: 'calc(100% - 32px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: 'calc(100% - 48px)',
    },
  },
}))

export const OnboardEsportInterests: React.FC = () => {
  const classes = useStyles()

  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const { data: onboardContext } = useOnboardingContext()

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])

  const { data, loading } = useEsportsByCompetitionGroupQuery({
    variables: {
      competitionGroup:
        SCHOOL_TYPE_COMPETITION_GROUP_MAP[
          (onboardContext?.schoolType as SchoolType) ?? SchoolType.HighSchool
        ],
    },
  })

  const [
    setMyEsportInterests,
    { loading: esportInterestsLoading },
  ] = useSetMyEsportInterestsMutation()

  const esports = data?.esportsByCompetitionGroup ?? []

  const slugs = getOnboardEsportSlugs(data?.esportsByCompetitionGroup ?? [])

  const showNASEFUx = onboardContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    try {
      if (!selectedIndexes.length) {
        navigate(`${Path.SpawnPoint}/student-school${showNASEFUx}`)
        return
      }

      const selectedEsportIds = selectedIndexes.map(
        (index) => esports[index].id
      )
      await setMyEsportInterests({
        variables: { input: { esportIds: selectedEsportIds } },
      })

      navigate(`${Path.SpawnPoint}/student-school${showNASEFUx}`)
    } catch (error) {
      enqueueSnackbar(
        'Error setting esport interests at this time, moving on',
        { variant: 'error' }
      )
      navigate(`${Path.SpawnPoint}/student-school${showNASEFUx}`)
    }
  }

  return (
    <Container maxWidth="md">
      <form className={classes.form} onSubmit={handleSubmit}>
        <NxTypography className={classes.title} variant="display3">
          What esports are you interested in?
        </NxTypography>
        {loading ? (
          <EsportsLoading />
        ) : (
          <>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <NxEsportMultiSelectable
                className={classes.esports}
                esports={slugs}
                onChange={setSelectedIndexes}
                values={selectedIndexes}
              />
              <NxButton
                className={classes.button}
                disabled={esportInterestsLoading}
                label="Next"
                type="submit"
                variant="primary"
              />
            </Box>
          </>
        )}
      </form>
    </Container>
  )
}
