import dayjsOriginal from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

function initDayJS(): any {
  const dayjsPlugins = [
    updateLocale,
    relativeTime,
    utc,
    duration,
    isBetween,
    isSameOrAfter,
    isSameOrBefore,
    advancedFormat,
    localizedFormat,
  ]
  dayjsPlugins.forEach((plugin) => dayjsOriginal.extend(plugin))
  dayjsOriginal.updateLocale('en', {
    relativeTime: {
      past: '%s ago',
      s: '1 min',
      ss: '1 min',
      m: '1 min',
      mm: '%d min',
      h: '1 hr',
      hh: '%d hr',
      future: 'in %s',
      d: 'a day',
      dd: '%d days',
      w: '%d week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  })

  return dayjsOriginal
}

export const dayjs = initDayJS()

export default dayjs
