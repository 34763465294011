import React from 'react'
import { Pill } from '@playvs-inc/nexus-components'
import { MatchStatus } from '@plvs/graphql/generated'

export const getInvalidMatchResults = (
  matchStatus: MatchStatus | undefined
): React.ReactElement => {
  switch (matchStatus) {
    case MatchStatus.Forfeited:
      return <Pill label="Forfeited" size="small" variant="error" />
    case MatchStatus.Bye:
      return <Pill label="Bye" size="small" variant="misc" />
    default:
      return <Pill label="pending" size="small" variant="warn" />
  }
}

export const getTooltip = (matchStatus: MatchStatus | undefined): string => {
  switch (matchStatus) {
    case MatchStatus.Bye:
      return 'We were unable to find this team an opponent.  This team will receive a Bye (win) for this week.'
    case MatchStatus.Forfeited:
      return 'This match was forfeited.'
    default:
      return ''
  }
}

export const INVALID_RESULTS_MATCH_STATUSES = [
  MatchStatus.Quarantined,
  MatchStatus.Rescheduled,
]

export const truncate = (input: string, numberOfCharacters: number): string =>
  input.length > numberOfCharacters
    ? `${input.substring(0, numberOfCharacters)}...`
    : input
