import { makeStyles, Box, Divider, Paper, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { CreateCSSProperties } from '@material-ui/styles'
import { NodeEnv } from '@plvs/env'
import { Hidden, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ApiEnvBadge } from '@plvs/rally/components/badge'
import { LinkObj } from '@plvs/respawn/features/app-drawer/types'
import { AppDrawerLinks } from '@plvs/respawn/features/app-drawer/AppDrawerLinks'
import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import { DrawerAnnouncementList } from './DrawerAnnouncementList'
import { ProfileDropdown } from './ProfileDropdown'
import { JoinPlayVs } from './JoinPlayVs'
import { useGetNavigationLinks } from './Links'
import { AppDrawerLogo } from './AppDrawerLogo'

interface StyleProps {
  isExpanded?: boolean
  isFullBleedBanner?: boolean
  isPublic: boolean
}

const useStyles = makeStyles((theme) => ({
  appDrawer: ({ isFullBleedBanner }: StyleProps): CreateCSSProperties => ({
    minWidth: theme.spacing(isFullBleedBanner ? 40 : 43.75),
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(35),
    },
    '&>div': {
      overflow: 'hidden',
    },
  }),
  apiBadge: {
    top: theme.spacing(8),
    right: theme.spacing(7.125),
    position: 'absolute',
  },
  container: ({ isExpanded }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'space-between',
    height: 'calc(100vh - 64px)',
    zIndex: 2,
    maxHeight: theme.spacing(125),
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    width: isExpanded ? theme.spacing(35) : theme.spacing(12),
    margin: theme.spacing(4, 3),
    padding: 0,
    transition: 'width 200ms ease',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100dvh - 93px)',
      borderRadius: 0,
      top: 93,
      margin: 0,
      justifyContent: 'normal',
    },
  }),
  appDrawerLinks: ({ isPublic }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isPublic ? 'normal' : 'space-between',
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.ColorBackgroundAlt2,
    },
  }),
  avatarSkeleton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  mobileHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  topShadow: {
    position: 'relative',
    height: theme.spacing(2),
    top: `-${theme.spacing(2)}px`,
    boxShadow: theme.mixins.boxShadow.elevation3,
  },
  profile: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: 280,
    },
  },
}))

export interface UserMinimalUser {
  id: string
  name: string
  avatarUrl?: string | null
}

interface Props extends RouteComponentProps {
  apiEnv?: NodeEnv
  isCoach?: boolean
  isCaptain?: boolean
  isPlayer?: boolean
  isParent?: boolean
  isPublic: boolean
  userNameAndAvatar?: UserMinimalUser | null
  links?: LinkObj[]
  showUpcomingMatches?: boolean
  Logo?: React.ReactElement
}

export const AppDrawer = ({
  apiEnv,
  isCoach,
  isPlayer,
  isCaptain,
  isParent,
  isPublic,
  userNameAndAvatar,
  links: defaultLinks,
  showUpcomingMatches = true,
  Logo,
}: Props): React.ReactElement => {
  const isMobile = useBreakpointSm()
  const flags = useFlags()
  const theme = useTheme()

  const [isExpanded, setIsExpanded] = React.useState(true)

  const classes = useStyles({
    isExpanded,
    isFullBleedBanner: flags.activateFullBleedBanners,
    isPublic,
  })

  useEffect(() => {
    setIsExpanded(true)
  }, [])

  const links = useGetNavigationLinks({
    isCoachAtOrg: isCoach ?? false,
    isPlayerAtOrg: isPlayer ?? false,
    isCaptainAtOrg: isCaptain ?? false,
    isParent: isParent ?? false,
    isMobile,
    isPublic,
    hasResourcesFlag: flags.playerResourcesPage,
  })
  const navLinks = defaultLinks ?? links

  const showApiEnvBadge = apiEnv !== 'production' && Boolean(apiEnv)
  const shouldRenderAnnouncementList = showUpcomingMatches && !isParent

  return (
    <Box className={classes.appDrawer}>
      <Paper className={classes.container} elevation={1}>
        <Box>
          <Hidden mdUp>
            <div className={classes.topShadow} />
          </Hidden>
          {Logo ?? <AppDrawerLogo />}
          <Box mt={2} />
          <Box
            className={classes.appDrawerLinks}
            data-testid="AppDrawer__AppDrawerLinks"
            height={`calc(100vh - ${isPublic ? 225 : 292}px)`}
            maxHeight={isPublic ? '100%' : 772}
            overflow="auto"
          >
            <AppDrawerLinks
              isExpanded={isExpanded}
              navigationLinks={navLinks}
            />

            {showApiEnvBadge && (
              <Box data-testid="api-badge" pl={theme.spacing(0.7)}>
                <ApiEnvBadge apiEnv={apiEnv} />
              </Box>
            )}

            {isPublic ? (
              <JoinPlayVs />
            ) : (
              shouldRenderAnnouncementList && (
                <Box className={classes.mobileHidden} ml={1.875} mt={6}>
                  <DrawerAnnouncementList
                    canViewPracticeDay={isCoach || isCaptain}
                  />
                </Box>
              )
            )}
          </Box>
        </Box>

        {/* User profile nav */}
        {!isPublic && (
          <Box className={classes.profile}>
            <Divider
              className={classes.mobileHidden}
              light
              variant="fullWidth"
            />
            <ProfileDropdown userNameAndAvatar={userNameAndAvatar} />
          </Box>
        )}
      </Paper>
    </Box>
  )
}
