import React from 'react'

import { r } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

interface TitleHeroProps {
  subtitle?: React.ReactNode
  subtitle2?: React.ReactNode
  title?: React.ReactNode
  icon?: React.ReactNode
  overTitle?: string
}

const useStyles = makeStyles({
  titleHeader: {
    marginRight: 8,
  },
})

export const TitleHero: React.FC<TitleHeroProps> = ({
  subtitle,
  subtitle2,
  title,
  icon,
  overTitle,
}) => {
  const { titleHeader } = useStyles({})
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="100%"
      px={3}
      py={r([2, 15])}
    >
      {overTitle && (
        <Box marginBottom={8}>
          <NxTypography colorToken="OverlayColorTextBase" variant="overline">
            {overTitle}
          </NxTypography>
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        marginBottom={8}
      >
        {typeof title === 'string' ? (
          <NxTypography
            className={titleHeader}
            colorToken="OverlayColorTextBase"
            variant="display"
          >
            {title}
          </NxTypography>
        ) : (
          title
        )}
        {icon ? <Box flex="0">{icon}</Box> : null}
      </Box>
      {subtitle && typeof subtitle === 'string' ? (
        <Box>
          <NxTypography color="inherit" variant="body1">
            {subtitle}
          </NxTypography>
        </Box>
      ) : (
        subtitle
      )}
      {subtitle2 && typeof subtitle2 === 'string' ? (
        <Box>
          <NxTypography color="inherit" variant="body2">
            {subtitle2}
          </NxTypography>
        </Box>
      ) : (
        subtitle2
      )}
    </Box>
  )
}
