import React, { useEffect, useState } from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'

import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import {
  MatchSettleRequestStatus,
  useFetchMatchSettleRequestQuery,
  MatchSettleRequestFragment,
  useSubmitMatchSettleRequestLiveMutation,
  useSaveGameResultLiveMutation,
  useMatchSettleRequestSubmittedSubscription,
  useMatchSettleRequestAssetsUpdatedSubscription,
  useMatchSettleRequestUpdatedSubscription,
} from '@plvs/graphql/generated'
import { DmrMatch, DmrTeam } from '@plvs/utils'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { ForfeitSuccessBanner } from '../forfeitSuccessBanner/ForfeitSuccessBanner'
import { useGetMatchReportCardSteps } from './MatchReportCardSteps'
import { ReportStepIndex } from '../dynamic.types'
import { GameSelectionValue } from '../matchReportAccordion/MatchReportAccordion.types'
import {
  createOnSelectOptionFunction,
  updateSelectedStepSeriesAndGame,
} from './MatchReportCard.helpers'

export const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
  footer: {
    width: '100%',
    display: 'flex',
    border: 0,
    gridGap: theme.spacing(1),
    alignItems: 'center',

    '&> button': {
      height: 'fit-content',
    },
  },
  forfeitSuccessBannerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

interface MatchReportCardProps {
  match: DmrMatch
  isSeriesMatch: boolean
  myTeamId: string
  canManageMatch: boolean
  refetchCallback(): void
  matchAssistantEnabled: boolean | null | undefined
}

export const MatchReportCard: React.FC<MatchReportCardProps> = ({
  match: incomingMatch,
  canManageMatch,
  myTeamId,
  refetchCallback,
  isSeriesMatch,
  matchAssistantEnabled,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { userId } = useUserIdentityFn()
  const isMyTeamHome = myTeamId === incomingMatch?.team1?.id

  /// ///////
  // State //
  /// ///////
  const [match, setMatch] = useState<DmrMatch>(incomingMatch)
  const [stepIndex, setStepIndex] = useState<ReportStepIndex>(
    ReportStepIndex.DefaultIndex
  )
  const [expandedGameIndex, setExpandedGameIndex] = useState<number>(0)
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState<number>(0)
  const [notes, setNotes] = useState('')
  const [showForfeitSuccessBanner, setShowForfeitSuccessBanner] = useState<
    boolean
  >(false)
  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)
  // manually handling loading state since the data loading and setting of state is synchronous.
  // loading should be false only when values are actually updated in state.
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)
  const [loadingIncomingMatch, setIsLoadingIncomingMatch] = useState<boolean>(
    !incomingMatch
  )
  const [isSubmittingGameResult, setIsSubmittingGameResult] = useState<boolean>(
    false
  )

  /// ///////////
  // VARIABLES //
  /// ///////////
  const myTeam: DmrTeam | null =
    (isMyTeamHome ? match?.team1 : match?.team2) ?? null
  const oppTeam: DmrTeam | null =
    (isMyTeamHome ? match?.team2 : match?.team1) ?? null

  const hasSeriesCreated = !!match?.series?.length
  const seriesMatchDoesntHaveSeriesCreated = isSeriesMatch && !hasSeriesCreated

  /// /////////
  // QUERIES //
  /// /////////
  const {
    data: incomingMatchSettleRequest,
    refetch: refetchMatchSettleRequest,
  } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId: match?.id,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !match?.id || !userId,
    fetchPolicy: 'network-only',
  })

  /// ///////////
  // MUTATIONS //
  /// ///////////
  const [saveGameResultLive] = useSaveGameResultLiveMutation()
  const [
    submitMatchSettleRequestLiveMutation,
    { loading: isSubmittingFinalScore },
  ] = useSubmitMatchSettleRequestLiveMutation()

  /// ///////////////
  // SUBSCRIPTIONS //
  /// ///////////////
  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId: match?.id ?? '',
      },
    },
    skip:
      !match?.id ||
      !(
        stepIndex === ReportStepIndex.GameSelectionIndex ||
        stepIndex === ReportStepIndex.MatchScoreReviewIndex
      ),
  })

  useMatchSettleRequestSubmittedSubscription({
    variables: {
      input: {
        matchSettleRequestId: matchSettleRequest?.id ?? '',
      },
    },
    skip:
      !matchSettleRequest?.id ||
      stepIndex === ReportStepIndex.MatchFinalScoreIndex,
    fetchPolicy: 'network-only',
    onSubscriptionData: refetchCallback,
  })

  const MatchSettleRequestAssetsUpdated = useMatchSettleRequestAssetsUpdatedSubscription(
    {
      variables: {
        input: {
          matchSettleRequestId: matchSettleRequest?.id ?? '',
        },
      },
      skip:
        !matchSettleRequest?.id ||
        stepIndex !== ReportStepIndex.MatchScoreReviewIndex,
      fetchPolicy: 'network-only',
    }
  )

  /// /////////
  // EFFECTS //
  /// /////////
  useEffect(() => {
    setMatch(incomingMatch)
    setIsLoadingIncomingMatch(false)
  }, [incomingMatch])

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]
    if (incomingMatchSettleRequest) {
      setMatchSettleRequest(returnedMatchSettleRequest ?? null)
    }

    if (incomingMatchSettleRequest?.fetchMatchSettleRequest?.success) {
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest
    if (updatedMatchSettleRequest) {
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
      setIsLoadingMatchSettleRequest(false)
    }
  }, [matchSettleRequestUpdated])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      MatchSettleRequestAssetsUpdated?.data?.matchSettleRequestAssetsUpdated
        ?.matchSettleRequest
    if (updatedMatchSettleRequest) {
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
      setIsLoadingMatchSettleRequest(false)
    }
  }, [MatchSettleRequestAssetsUpdated])

  useEffect(() => {
    updateSelectedStepSeriesAndGame({
      match,
      isSubmittingFinalScore,
      setStepIndex,
      matchSettleRequest,
      loadingMatchSettleRequest,
      loadingIncomingMatch,
      isSubmittingGameResult,
      isSeriesMatch,
      setSelectedSeriesIndex,
      setExpandedGameIndex,
      matchAssistantEnabled,
    })
  }, [
    match?.status,
    matchSettleRequest,
    loadingMatchSettleRequest,
    loadingIncomingMatch,
    isSubmittingGameResult,
    isSubmittingFinalScore,
  ])

  /// /////////
  // HELPERS //
  /// /////////
  const onSelectOption: (
    gameIndex: number,
    { value, meta }: GameSelectionValue
  ) => Promise<void> = createOnSelectOptionFunction({
    match,
    userId,
    selectedSeriesIndex,
    isSeriesMatch,
    myTeam,
    oppTeam,
    setIsSubmittingGameResult,
    saveGameResultLive,
    setMatchSettleRequest,
    refetchMatchSettleRequest,
  })

  const onClickSettleMatch = async (): Promise<void> => {
    setStepIndex(ReportStepIndex.MatchFinalScoreIndex)
    await submitMatchSettleRequestLiveMutation({
      variables: {
        input: {
          matchSettleRequestId: matchSettleRequest?.id ?? '',
          teamId: myTeamId,
          notes,
        },
      },
    })
  }

  const reportSteps = useGetMatchReportCardSteps({
    match,
    matchSettleRequest,
    loadingMatchSettleRequest,
    myTeam,
    oppTeam,
    canManageMatch,
    selectedSeriesIndex,
    expandedGameIndex,
    notes,
    loadingFinalScore: loadingIncomingMatch || isSubmittingFinalScore,
    isSubmittingGameResult,
    isSeriesMatch,
    seriesMatchDoesntHaveSeriesCreated,
    onSelectOption,
    setMatchSettleRequest,
    setStepIndex,
    setSelectedSeriesIndex,
    setExpandedGameIndex,
    setNotes,
    setShowForfeitSuccessBanner,
    onClickSettleMatch,
  })

  const children: React.ReactElement[] = []

  if (showForfeitSuccessBanner && match?.esport?.slug) {
    children.push(
      <Box className={classes.forfeitSuccessBannerContainer}>
        <ForfeitSuccessBanner
          esportSlug={match?.esport?.slug}
          myTeamId={myTeamId}
        />
      </Box>
    )
  }

  if (!showForfeitSuccessBanner && reportSteps[stepIndex].showCard) {
    children.push(
      <Box data-cy="scoreBoard" flex={1} maxWidth="100vw" mb={4} minWidth={344}>
        <MatchCard
          footer={
            reportSteps[stepIndex]?.Footer ? (
              <Box className={classes.footer}>
                {reportSteps[stepIndex].Footer}
              </Box>
            ) : null
          }
          sideContent={reportSteps[stepIndex].SideContent}
          title={reportSteps[stepIndex].title}
          titleBottomPadding={theme.spacing(2)}
          titleDetail={reportSteps[stepIndex].titleDetail}
        >
          <Box data-cy="scoreCard">{reportSteps[stepIndex].MainContent}</Box>
        </MatchCard>
      </Box>
    )
  }

  return children.length ? <>{children}</> : null
}
