import { isNumber } from 'ramda-adjunct'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

export interface DialogStepCountProps {
  currentStepIndex?: number
  stepCount?: number
}

export const DialogStepCount: React.FC<DialogStepCountProps> = ({
  currentStepIndex,
  stepCount,
}) =>
  isNumber(currentStepIndex) && isNumber(stepCount) ? (
    <div
      style={{
        marginLeft: 8,
        whiteSpace: 'nowrap',
      }}
    >
      <NxTypography colorToken="ColorTextAlt2" variant="body1">
        Step {currentStepIndex + 1}/{stepCount}
      </NxTypography>
    </div>
  ) : (
    <></>
  )
