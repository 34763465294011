import React from 'react'
import { SponsorshipBanner } from '@plvs/rally/features/home/SponsorshipBanner'
import { Home } from '@plvs/rally/features/home/Home'
import { withTransaction } from '@elastic/apm-rum-react'
import { ParentHome } from '@plvs/rally/features/home/ParentHome'
import { MySchoolLeagues } from '../../containers/filter/league/MySchoolLeagues'
import { AppPage } from '../page'

const HomePageInternal: React.FC<{ isParent: boolean }> = ({ isParent }) => {
  if (isParent) {
    return (
      <AppPage title="Parent Dashboard">
        <ParentHome />
      </AppPage>
    )
  }
  return (
    <AppPage title="Dashboard">
      <MySchoolLeagues>
        <SponsorshipBanner />
        <Home />
      </MySchoolLeagues>
    </AppPage>
  )
}

// Note: renaming this for APM
export const HomePage = withTransaction(
  'HomePage',
  'component'
)(HomePageInternal)
