import React, { useState } from 'react'
import { useGetUpcomingSeasonInfoQuery } from '@plvs/graphql'
import { MinimalMetaseason } from '@plvs/utils'
import dayjs from 'dayjs'
import { useBreakpointXs, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { NxWidget, NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { EnrollmentModal } from '../EnrollmentModal/EnrollmentModal'
import { getHoursLeft } from './coachEnrollmentCardHelpers'

export const useStyles = makeStyles((theme) => ({
  enrollmentText: {
    marginBottom: theme.spacing(1),
  },
  modalText: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface Props {
  path: string
  metaseason: MinimalMetaseason | undefined
  title: string
}
export const CoachEnrollmentCard: React.FC<Props> = ({
  path,
  metaseason,
  title,
}) => {
  const isMobile = useBreakpointXs()
  const [open, setOpen] = useState<boolean>(false)
  const handleCloseModal = (): void => setOpen(false)

  const navigate = useNavigate()
  const classes = useStyles()

  const { leagues } = useSchoolLeagueInfoContext()
  const metaseasonId = metaseason?.id ?? ''

  const { data, loading } = useGetUpcomingSeasonInfoQuery({
    variables: { metaseasonId },
    skip: !metaseasonId,
  })

  const leagueIds = leagues.reduce((prev, league) => {
    prev.add(league.id)
    return prev
  }, new Set())
  const seasons = (data?.metaseason?.seasons ?? []).filter((season) =>
    leagueIds.has(season.leagueId)
  )

  const now = dayjs()
  const isLateEnrollment = seasons.every((season) =>
    now.isAfter(season?.suggestedRegistrationEndsAt)
  )

  const hoursLeft = getHoursLeft({ seasons, isLateEnrollment })

  const daysLeft = Math.floor(hoursLeft / 24)

  // Less than 24 hours, use hours remaining.  Else use how many days left
  const enrollmentEndsInTitle =
    hoursLeft < 24 ? `${hoursLeft} hours` : `${daysLeft} days`

  const metaseasonName = metaseason?.name ?? ''

  return (
    <WaitTillLoaded loading={loading}>
      {hoursLeft > 0 && (
        <>
          <NxWidget
            actions={
              <>
                <NxButton
                  fullWidth
                  label="Manage Enrollment"
                  mb="16px"
                  onClick={(): void => navigate(path)}
                  variant="primary"
                />
                <NxButton
                  fullWidth
                  label="View Enrollment Schedule"
                  onClick={(): void => setOpen(true)}
                  variant="secondary"
                />
              </>
            }
            title={title}
            width={isMobile ? '100%' : '300px'}
          >
            <NxTypography
              className={classes.enrollmentText}
              variant="subtitle1"
            >
              {isLateEnrollment ? 'Late ' : ''} Enrollment ends in{' '}
              <NxTypography component="span" variant="h4">
                {enrollmentEndsInTitle}
              </NxTypography>
            </NxTypography>
            <NxTypography className={classes.modalText} variant="body3">
              Enrollment deadlines may differ for some of your school&apos;s
              leagues.
            </NxTypography>
          </NxWidget>
          <EnrollmentModal
            handleCloseModal={handleCloseModal}
            open={open}
            path={path}
            seasons={seasons}
            title={`${metaseasonName} Enrollment Deadlines`}
          />
        </>
      )}
    </WaitTillLoaded>
  )
}
