import { Hidden, SwipeableDrawer, makeStyles } from '@material-ui/core'
import { NxIconButton } from '@playvs-inc/nexus-components'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { MobileMenuIcon } from './MobileMenuIcon'

const useStyles = makeStyles({
  swipeableDrawer: {
    '& .MuiDrawer-paper ': {
      backgroundColor: 'unset !important',
    },
    '& .MuiPaper-elevation16': {
      boxShadow: 'unset',
    },
    '& .MuiBackdrop-root': {
      top: '92px',
    },
  },
  button: {
    padding: 0,
  },
})

export const AppDrawerMobileToggle: React.FC<{
  AppDrawer: React.FC<{ close?(): void; isPublic?: boolean }>
}> = ({ AppDrawer }) => {
  const location = useLocation()
  const isPublic = !location.pathname.includes('/app')

  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(false)
  const open = (): void => setIsOpen(!isOpen)
  const close = (): void => setIsOpen(false)

  return (
    <>
      <NxIconButton
        className={classes.button}
        icon={<MobileMenuIcon />}
        onClick={open}
        variant="text"
      />

      <Hidden
        // we don't want this to render on the server, right?--it's not visible
        mdUp
      >
        <SwipeableDrawer
          anchor="left"
          className={classes.swipeableDrawer}
          onClose={close}
          onOpen={open}
          open={isOpen}
        >
          <AppDrawer close={close} isPublic={isPublic} />
        </SwipeableDrawer>
      </Hidden>
    </>
  )
}
