import { List } from '@material-ui/core'
import React from 'react'
import { CompetitionGroup, EsportSlug, Metaseason } from '@plvs/graphql'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RosterCardMenuRowItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { StarterRosterPlayer, UserRole } from '../RosterProvider.types'
import { RosterRowEmpty } from './RosterRowEmpty'
import { RosterRowFilled } from './RosterRowFilled'
import { RosterRowFilledMenu } from './menus/RosterRowFilledMenu'

export interface RosterRowListProps {
  members: StarterRosterPlayer[]
  userId: string
  isPast: boolean
  teamId: string
  addToTeamMutationSuccess?(input?: string): Promise<void>
  isTeamAssociatedWithOrg: boolean
  loading?: boolean
  esportSlug: EsportSlug | undefined
  onMutationSuccess?(): Promise<void>
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  userRoles: UserRole[]
  readOnly: boolean
  teamName: string
  rosterCardStarterRowActions?: RosterCardMenuRowItems[]
  isAdmin: boolean
  metaseason?: Pick<Metaseason, 'id' | 'endsAt'>
  leagueId: string
  publicView?: boolean
  competitionGroup: CompetitionGroup | undefined
}

export const RosterRowList: React.FC<RosterRowListProps> = ({
  addToTeamMutationSuccess,
  esportSlug,
  getPermissions,
  isAdmin,
  isPast,
  isTeamAssociatedWithOrg,
  leagueId,
  loading,
  members,
  metaseason,
  onMutationSuccess,
  readOnly,
  rosterCardStarterRowActions,
  teamId,
  teamName,
  userId,
  userRoles,
  publicView,
  competitionGroup,
}) => {
  return (
    <List data-cy="rosterRowList">
      {members.map((member, i) =>
        member?.user?.id ? (
          <RosterRowFilled
            key={member.user.id}
            competitionGroup={competitionGroup}
            disableAction={isPast || readOnly}
            esportSlug={esportSlug}
            getPermissions={getPermissions}
            isAdmin={isAdmin}
            isLastNode={i === members.length - 1}
            member={member}
            members={members}
            metaseason={metaseason}
            onMutationSuccess={onMutationSuccess}
            publicView={publicView}
            rosterCardStarterRowActions={rosterCardStarterRowActions}
            RowMenu={RosterRowFilledMenu}
            teamId={teamId}
            userId={userId}
          />
        ) : (
          <RosterRowEmpty
            key={`emptyRow-${teamName}-${i.toString()}`}
            addToTeamMutationSuccess={addToTeamMutationSuccess}
            disableAction={
              isPast || readOnly || (!isTeamAssociatedWithOrg && isAdmin)
            }
            esportSlug={esportSlug}
            getPermissions={getPermissions}
            isLastNode={i === members.length - 1}
            isTeamAssociatedWithOrg={isTeamAssociatedWithOrg}
            leagueId={leagueId}
            loading={loading}
            metaseasonId={metaseason?.id ?? ''}
            position={member?.position}
            teamId={teamId}
            userId={userId}
            userRoles={userRoles}
          />
        )
      )}
    </List>
  )
}
