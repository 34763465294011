import React, { useEffect, useState } from 'react'
import {
  NxSpotModal,
  NxTypography,
  NxButton,
  NxSeasonalAwardsTableData,
} from '@playvs-inc/nexus-components'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'

import {
  useAssignSeasonalPlayerAwardMutation,
  useAvailableSeasonalPlayerAwardTypesQuery,
  useUpdateSeasonalPlayerAwardMutation,
} from '@plvs/graphql/generated'

import { useSnackbar } from 'notistack'
import { useGetAssignAwardIcon } from './assignSeasonalAwardModalHelpers'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.ColorTextAlt,
  },
  desc: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  onClose(hasChanges?: boolean): void
  open: boolean
  metaseasonId: string
  coachId: string
  userData?: NxSeasonalAwardsTableData
}

export const AssignSeasonalAwardModal: React.FC<Props> = ({
  onClose,
  open,
  metaseasonId,
  userData,
  coachId,
}) => {
  const classes = useStyles()
  const isUpdating = !!userData?.assignedAward
  const { enqueueSnackbar } = useSnackbar()
  const [awardId, setAwardId] = useState<string>('')
  const { data } = useAvailableSeasonalPlayerAwardTypesQuery()
  const awards =
    data?.availableSeasonalPlayerAwardTypes?.availablePlayerAwardTypes ?? []
  const [
    assignAward,
    { loading: isAssigningAward },
  ] = useAssignSeasonalPlayerAwardMutation()
  const [
    updateAward,
    { loading: isUpdatingAward },
  ] = useUpdateSeasonalPlayerAwardMutation()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setAwardId(event.target.value as string)
  }

  const selectedAward = awards.find((a) => a?.id === awardId)

  const Icon = useGetAssignAwardIcon('Rookie MVP')

  useEffect(() => {
    setAwardId(userData?.assignedAward?.awardType.id || '')
  }, [userData])
  const assignHandler = async (): Promise<void> => {
    if (isUpdating) {
      const result = await updateAward({
        variables: {
          input: {
            id: userData?.assignedAward?.id || '',
            attributes: {
              awardTypeId: awardId,
            },
          },
        },
      })
      if (result.errors?.length) {
        enqueueSnackbar('Failed to updated award', { variant: 'error' })
      } else {
        enqueueSnackbar('Successfully updated award', { variant: 'success' })
      }
    } else {
      const result = await assignAward({
        variables: {
          input: {
            coachId,
            metaseasonId,
            userId: userData?.player.id || '',
            teamId: userData?.team.id || '',
            awardTypeId: awardId,
          },
        },
      })
      if (result.errors?.length) {
        enqueueSnackbar('Failed to assign award', { variant: 'error' })
      } else {
        enqueueSnackbar('Successfully assigned award', { variant: 'success' })
      }
    }
    onClose(true)
  }
  const username = userData?.player.name
  return (
    <NxSpotModal
      actions={
        <>
          <NxButton
            label="Cancel"
            onClick={(): void => onClose(false)}
            variant="text"
          />
          <NxButton
            disabled={isAssigningAward || isUpdatingAward || !awardId}
            label={isUpdating ? 'Update' : 'Assign'}
            onClick={assignHandler}
            variant="primary"
          />
        </>
      }
      onClose={(): void => onClose(false)}
      open={open}
      showTopRightClose
      size="large"
      spot={Icon && <>{Icon}</>}
      subtitle={`What made ${username} stand out this season?`}
      title={`Nominate ${username} For an Award`}
    >
      <FormControl fullWidth variant="outlined">
        <InputLabel>Award</InputLabel>
        <Select
          fullWidth
          label="Award"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          onChange={handleChange}
          value={awardId}
        >
          {awards.map((a) => (
            <MenuItem
              key={a?.id ?? ''}
              className={classes.menuItem}
              value={a?.id ?? ''}
            >
              {a?.title ?? ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <NxTypography className={classes.desc} variant="body3">
        {selectedAward?.description ??
          "Don't forget to recognize your players' accomplishments during the season!"}
      </NxTypography>
    </NxSpotModal>
  )
}
