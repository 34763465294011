import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.mixins.boxShadow.elevation1,
    borderRadius: theme.shape.borderRadius * 2.5,
    maxWidth: 686,
    overflow: 'hidden',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderTopRightRadius: theme.shape.borderRadius * 2.5,
    borderTopLeftRadius: theme.shape.borderRadius * 2.5,
  },
  item: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.divider.bottom,
    '&:last-child': {
      boxShadow: 'unset',
      paddingBottom: theme.spacing(4),
    },
  },
}))

interface Props {
  title: string | React.ReactElement
  titleCta?: any
  customContent: {
    id: string
    content: any
  }[]
  emptyState?: React.ReactNode
}

export const ChildSettingsCard: React.FC<Props> = ({
  title,
  titleCta,
  customContent,
  emptyState,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.title} p={3}>
        <NxTypography variant="h4">{title}</NxTypography>
        {titleCta ?? null}
      </Box>
      {emptyState ? (
        <>{emptyState}</>
      ) : (
        <>
          {customContent.map((content) => (
            <Box
              key={content.id}
              alignItems="center"
              className={classes.item}
              display="flex"
              p={3}
            >
              {content.content}
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}
