import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { Tooltip } from '@plvs/rally/components/tooltip'

interface Props {
  disabled?: boolean
  children: React.ReactElement
  tooltipTitle?: string
}

export const RowContainerV2: React.FC<Props> = ({
  disabled,
  children,
  tooltipTitle,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column" mt={2}>
      <Tooltip
        arrow
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
        disableTouchListener={!disabled}
        title={tooltipTitle}
      >
        {children}
      </Tooltip>
    </Box>
  )
}
