import { useState, useEffect, useRef } from 'react'
import { Conversation } from '@twilio/conversations'
import { logger } from '@plvs/rally/logging'
import {
  useActiveGlobalChatConversationsVar,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { useChatClientContext } from '@plvs/rally/containers/chat/ChatClientProvider'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'

interface GetUnreadMessageCountProps {
  chatUniqueName: string
  setListeners?: (conversation: Conversation) => Conversation
}

interface GetUnreadMessageCountReturn {
  unreadMessageCounts: Record<string, number>
  unreadMessageCount: number
}

export function useGetUnreadMessageCount({
  chatUniqueName,
}: GetUnreadMessageCountProps): GetUnreadMessageCountReturn {
  const { isReady } = useChatClientContext()
  const { userId, loading: identityLoading } = useUserIdentityFn()
  const [unreadMessageCounts, setUnreadMessageCounts] = useState<
    Record<string, number>
  >({})
  const { usersActiveConversationsMap, loading } = useChatConversationsContext()
  const [conversation, setConversation] = useState<Conversation>()
  const { activeConversations } = useActiveGlobalChatConversationsVar()

  const chatIsExpanded = activeConversations[chatUniqueName]?.expanded
  const [chatIsExpandedState, setChatIsExpandedState] = useState(chatIsExpanded)
  const chatIsExpandedRef = useRef(chatIsExpandedState)

  const chatUniqueNameIncludedInConversationsMetadata =
    usersActiveConversationsMap[chatUniqueName]

  useEffect(() => {
    if (
      chatIsExpanded !== chatIsExpandedRef.current &&
      chatIsExpanded !== chatIsExpandedState
    ) {
      chatIsExpandedRef.current = chatIsExpanded
      setChatIsExpandedState(chatIsExpanded)
    }
  }, [chatIsExpanded, chatIsExpandedRef.current, chatIsExpandedState])

  useEffect(
    function getConversation(): void {
      async function getConversationAsync(uniqueName: string): Promise<void> {
        if (
          !isReady ||
          !chatUniqueName ||
          !chatUniqueNameIncludedInConversationsMetadata ||
          identityLoading ||
          loading
        ) {
          return
        }

        try {
          const convo = usersActiveConversationsMap[chatUniqueName].conversation
          let count = await convo?.getUnreadMessagesCount()

          if (count === null) {
            count = await convo?.getMessagesCount()
          }

          setUnreadMessageCounts({
            ...unreadMessageCounts,
            [uniqueName]: count ?? 0,
          })

          if (convo) {
            convo.on('messageAdded', async function updateUnreadMessageCount(
              message
            ) {
              const currentStateConvo =
                usersActiveConversationsMap[chatUniqueName]
              if (currentStateConvo?.messages) {
                currentStateConvo.messages.items = usersActiveConversationsMap[
                  chatUniqueName
                ]?.messages.items.concat(message)
              }

              const newCount = await convo?.getUnreadMessagesCount()

              if (message.author && userId && !loading) {
                if (userId === message.author) {
                  return
                }

                if (chatIsExpandedRef.current) {
                  convo?.updateLastReadMessageIndex(message.index)
                } else {
                  setUnreadMessageCounts({
                    ...unreadMessageCounts,
                    [uniqueName]: newCount ?? unreadMessageCounts[uniqueName],
                  })
                }
              }
            })

            convo.on('updated', async function onConversationUpdated(data) {
              if (
                data.updateReasons.some(
                  (reason) => reason === 'lastReadMessageIndex'
                )
              ) {
                setUnreadMessageCounts({
                  ...unreadMessageCounts,
                  [uniqueName]: 0,
                })
              }
            })
          }
          setConversation(convo)
        } catch (e) {
          logger.error('[useGetUnreadMessageCount]', e)
        }
      }

      getConversationAsync(chatUniqueName)
    },
    [
      chatUniqueName,
      isReady,
      loading,
      chatUniqueNameIncludedInConversationsMetadata,
      identityLoading,
    ]
  )

  useEffect(function removeListenersOnDismount() {
    return (): void => {
      conversation?.removeAllListeners()
    }
  }, [])

  return {
    unreadMessageCounts,
    unreadMessageCount: unreadMessageCounts[chatUniqueName],
  }
}
