import { IconButton, makeStyles } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'

import { AvatarCircle } from '@plvs/rally/components/avatar'
import { Colors } from '@plvs/rally/themes'
import { Tooltip } from '@plvs/rally/components/tooltip'
import {
  Attachment,
  AttachScreenshotAPI,
  DeleteMutationHook,
  removeAttachment,
} from './attachHelpers'

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 12,
  },
  filename: {
    marginRight: 10,
  },
})

export const RemoveAttachedScreenshot: React.FC<
  AttachScreenshotAPI & {
    attachment: Attachment
    deleteAssetMutation: DeleteMutationHook
  }
> = ({ attachment, attachments, deleteAssetMutation, setAttachments }) => {
  const classes = useStyles()
  const [mutate] = deleteAssetMutation()

  const onClick = async (): Promise<void> => {
    try {
      removeAttachment({
        attachments,
        attachmentIdToRemove: attachment.id,
        setAttachments,
      })

      await mutate({
        awaitRefetchQueries: true,
        variables: { assetId: attachment.id },
      })
    } catch (err: any) {
      // Could display errors here; could also implement loading state
    }
  }

  return (
    <div className={classes.wrapper}>
      {attachment.signedUrl && (
        <div style={{ marginRight: 24 }}>
          <AvatarCircle
            avatarUrl={attachment.signedUrl}
            shape="square"
            size="medium"
          />
        </div>
      )}
      <div className={classes.filename}>{attachment.fileName}</div>
      <Tooltip title="Remove Screenshot">
        <div style={{ color: Colors.Grey4 }}>
          <IconButton color="inherit" onClick={onClick}>
            <Cancel />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )
}
