import {
  MatchSettleRequestFragment,
  MatchStatus,
  SaveGameResultInput,
} from '@plvs/graphql/generated'
import { DmrMatch, DmrTeam, LobbyTeam } from '@plvs/utils'
import { ReportStepIndex, SaveGameResultLiveFunction } from '../dynamic.types'
import {
  GameSelectionValue,
  ReportOptionValue,
} from '../matchReportAccordion/MatchReportAccordion.types'

export const updateSelectedStepSeriesAndGame = (args: {
  match: DmrMatch | undefined
  isSubmittingFinalScore: boolean
  matchSettleRequest: MatchSettleRequestFragment | null
  loadingMatchSettleRequest: boolean
  loadingIncomingMatch: boolean
  isSubmittingGameResult: boolean
  isSeriesMatch: boolean
  setStepIndex(stepIndex: ReportStepIndex): void
  setSelectedSeriesIndex(selectedSeriesIndex: number): void
  setExpandedGameIndex(selectedSeriesIndex: number): void
  matchAssistantEnabled?: boolean | null
}): void => {
  const {
    match,
    isSubmittingFinalScore,
    setStepIndex,
    matchSettleRequest,
    loadingMatchSettleRequest,
    loadingIncomingMatch,
    isSubmittingGameResult,
    isSeriesMatch,
    setSelectedSeriesIndex,
    setExpandedGameIndex,
    matchAssistantEnabled,
  } = args
  const matchStatusIndicatedResults =
    match?.status === MatchStatus.Completed ||
    match?.status === MatchStatus.Forfeited

  const matchStatusIndicatedResultsCanBeSubmitted =
    match?.status === MatchStatus.Scheduled ||
    match?.status === MatchStatus.Live ||
    match?.status === MatchStatus.Open ||
    match?.status === MatchStatus.Quarantined

  if (matchStatusIndicatedResults || isSubmittingFinalScore) {
    setStepIndex(ReportStepIndex.MatchFinalScoreIndex)
  } else if (
    matchStatusIndicatedResultsCanBeSubmitted &&
    matchSettleRequest?.isReadyForReview &&
    !loadingMatchSettleRequest &&
    !loadingIncomingMatch &&
    !isSubmittingGameResult
  ) {
    setStepIndex(ReportStepIndex.MatchScoreReviewIndex)
  } else if (
    matchStatusIndicatedResultsCanBeSubmitted &&
    !loadingMatchSettleRequest &&
    !loadingIncomingMatch &&
    !isSubmittingGameResult
  ) {
    setStepIndex(ReportStepIndex.GameSelectionIndex)
    let nextSeriesIndex = 0
    if (isSeriesMatch) {
      matchSettleRequest?.results?.seriesResults?.forEach(
        (currSeries, index) => {
          if (currSeries.isReadyForReview) {
            nextSeriesIndex = index + 1
          }
        }
      )
    }
    const nextGameOrdinalNumberToSumbit: number =
      matchSettleRequest?.results?.seriesResults?.[nextSeriesIndex]?.gameResults
        .length ?? 0

    setSelectedSeriesIndex(nextSeriesIndex)
    setExpandedGameIndex(nextGameOrdinalNumberToSumbit)
  } else if (matchAssistantEnabled === false) {
    setStepIndex(ReportStepIndex.GameSelectionIndex)
  }
}

export const createOnSelectOptionFunction = (args: {
  match: DmrMatch | undefined
  userId: string
  selectedSeriesIndex: number
  isSeriesMatch: boolean
  myTeam: DmrTeam | undefined
  oppTeam: DmrTeam | undefined
  setIsSubmittingGameResult(isSubmittingGameResult: boolean): void
  saveGameResultLive: SaveGameResultLiveFunction
  setMatchSettleRequest(matchSettleRequest: MatchSettleRequestFragment): void
  refetchMatchSettleRequest(): void
}): ((
  gameIndex: number,
  { value, meta }: GameSelectionValue
) => Promise<void>) => {
  const {
    match,
    userId,
    selectedSeriesIndex,
    isSeriesMatch,
    myTeam,
    oppTeam,
    setIsSubmittingGameResult,
    saveGameResultLive,
    setMatchSettleRequest,
    refetchMatchSettleRequest,
  } = args
  return async (
    gameIndex: number,
    { value, meta }: GameSelectionValue
  ): Promise<void> => {
    setIsSubmittingGameResult(true)
    const series = match?.series ?? []
    const activeSeries = series?.[selectedSeriesIndex]

    const saveGameResultInput: SaveGameResultInput = {
      matchId: match?.id ?? '',
      requestedById: userId,
      seriesOrdinalNumber: selectedSeriesIndex + 1,
      teamId: myTeam?.id ?? '',
      gameOrdinalNumber: gameIndex + 1,
      winningTeamId: '',
      losingTeamId: '',
      winningPlayerIds: [],
      losingPlayerIds: [],
      isIncomplete: false,
      incompleteReason: undefined,
      seriesId: isSeriesMatch ? series?.[selectedSeriesIndex].id : undefined,
    }

    let winningTeam: LobbyTeam | undefined
    let losingTeam: LobbyTeam | undefined

    if (value === ReportOptionValue.Incomplete) {
      winningTeam =
        meta?.faultId === ReportOptionValue.MyTeam ? oppTeam : myTeam
      losingTeam = meta?.faultId === ReportOptionValue.MyTeam ? myTeam : oppTeam
      saveGameResultInput.isIncomplete = true
      saveGameResultInput.incompleteReason = meta?.reason
    } else {
      winningTeam = value === ReportOptionValue.MyTeam ? myTeam : oppTeam
      losingTeam = value === ReportOptionValue.MyTeam ? oppTeam : myTeam
    }

    saveGameResultInput.winningTeamId = winningTeam?.id ?? ''
    saveGameResultInput.losingTeamId = losingTeam?.id ?? ''
    saveGameResultInput.winningPlayerIds =
      winningTeam?.roster?.map((member) => member.id) ?? []
    saveGameResultInput.losingPlayerIds =
      losingTeam?.roster?.map((member) => member.id) ?? []

    if (isSeriesMatch) {
      const winningRoster =
        activeSeries.team1?.id === winningTeam?.id
          ? activeSeries.team1?.roster
          : activeSeries.team2?.roster
      const losingRoster =
        activeSeries.team1?.id === losingTeam?.id
          ? activeSeries.team1?.roster
          : activeSeries.team2?.roster

      saveGameResultInput.winningPlayerIds = winningRoster?.map(({ id }) => id)
      saveGameResultInput.losingPlayerIds = losingRoster?.map(({ id }) => id)
    } else {
      saveGameResultInput.winningPlayerIds =
        winningTeam?.roster?.map((member) => member.id) ?? []
      saveGameResultInput.losingPlayerIds =
        losingTeam?.roster?.map((member) => member.id) ?? []
    }

    const updatedMatchSettleRequest = await saveGameResultLive({
      variables: {
        input: saveGameResultInput,
      },
    })
    if (updatedMatchSettleRequest.data?.saveGameResultLive?.errors?.length) {
      refetchMatchSettleRequest()
      setIsSubmittingGameResult(false)
    } else if (
      updatedMatchSettleRequest.data?.saveGameResultLive.matchSettleRequest
    ) {
      setMatchSettleRequest(
        updatedMatchSettleRequest.data?.saveGameResultLive.matchSettleRequest
      )
      setIsSubmittingGameResult(false)
    }
  }
}
