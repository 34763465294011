import React from 'react'
import { Box, useTheme, Dialog } from '@material-ui/core'

import { NxIconButton, NxIconButtonProps } from '@playvs-inc/nexus-components'
import { CaretLeft, CaretRight, Share } from '@playvs-inc/nexus-icons'

import { useGetReplayStatsByUserIdQuery } from '@plvs/graphql'
import { CopyIconButton } from '@plvs/respawn/features/button'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { getShareUrl } from '@plvs/utils'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { RewindSlide, IntroSlide, FinalSlide } from './slides'
import { useSlideshowControls } from './hooks'
import { useStyles } from './RewindSlideshow.styles'
import { RewindAssets, RewindData } from './Rewind.helpers'
import { RewindProgressBar } from './components'

interface RewindSlideshowProps {
  userId?: string
}

type CommonPagerProps = Pick<
  NxIconButtonProps,
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'shouldUseOverlayColors'
  | 'variant'
  | 'className'
  | 'loading'
>

export const RewindSlideshow: React.FC<RewindSlideshowProps> = ({ userId }) => {
  const { userId: viewerId } = useUserIdentityFn()
  const isLoggedInUser = viewerId === userId
  const isMobile = useBreakpointXs()

  const { data, loading: isLoading } = useGetReplayStatsByUserIdQuery({
    variables: {
      input: {
        userId: userId ?? '',
      },
    },
    skip: !userId,
  })

  const generalSlides = ['IntroSlide', 'FinalSlide']
  const slideCount =
    (data?.getReplayStatsByUserId.slidesData?.length ?? 1) +
    generalSlides.length

  const {
    isOpen,
    hasNextPage,
    hasPreviousPage,
    shouldShowPagers,
    handleNext,
    handleBack,
    handleClose,
    slideIndex,
    handleOpen,
    onMouseEnter,
    onMouseLeave,
    progress,
    onShare,
  } = useSlideshowControls({ slideCount, userId })

  const styles = useStyles({ shouldShowPagers })
  const theme = useTheme()

  if (!data?.getReplayStatsByUserId.slidesData?.length) {
    return null
  }

  const cta = (
    <button className={styles.ctaButton} onClick={handleOpen} type="button">
      <img
        alt={`Your ${RewindData.CurrentSeason} ${RewindData.Year} Rewind is here`}
        className={styles.cta}
        src={RewindAssets.CTA}
      />
    </button>
  )

  const pagerProps: CommonPagerProps = {
    onMouseEnter,
    onMouseLeave,
    shouldUseOverlayColors: true,
    variant: 'secondary',
    className: styles.button,
    loading: isLoading,
  }

  const prevPager = (
    <NxIconButton
      disabled={!hasPreviousPage}
      icon={<CaretLeft color="OverlayColorTextBase" />}
      onClick={handleBack}
      {...pagerProps}
    />
  )

  const nextPager = (
    <NxIconButton
      disabled={!hasNextPage}
      icon={<CaretRight color="OverlayColorTextBase" />}
      onClick={handleNext}
      {...pagerProps}
    />
  )

  const slide = (
    <Box className={styles.background} display="flex">
      <Box className={styles.slide} flex={1}>
        {slideIndex === 0 && <IntroSlide isLoggedInUser={isLoggedInUser} />}

        {slideIndex === slideCount - 1 && <FinalSlide />}

        {slideIndex > 0 && slideIndex < slideCount - 1 && (
          <RewindSlide
            data={data?.getReplayStatsByUserId?.slidesData?.[slideIndex - 1]}
            user={data?.getReplayStatsByUserId?.user}
          />
        )}
      </Box>
    </Box>
  )

  return (
    <>
      {cta}

      <Dialog
        BackdropProps={{
          classes: {
            root: styles.backdrop,
          },
        }}
        classes={{
          root: styles.dialog,
          paper: styles.paper,
        }}
        fullScreen
        maxWidth={false}
        onClose={handleClose}
        open={isOpen}
      >
        <Box>
          <Box className={styles.container} mb={5}>
            {!isMobile && prevPager}

            <Box>
              <RewindProgressBar
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                progress={progress}
                slideCount={slideCount}
                slideIndex={slideIndex}
              />

              {slide}
            </Box>

            {!isMobile && nextPager}
          </Box>

          <Box
            alignItems="center"
            display="flex"
            gridGap={theme.spacing(2)}
            justifyContent="center"
          >
            {isMobile && prevPager}

            <CopyIconButton
              className={styles.shareButton}
              icon={<Share />}
              label="Copy Link"
              onClickCopyAdditionalAction={onShare}
              textToCopy={getShareUrl()}
              variant="primary"
            />

            {isMobile && nextPager}
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
