import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { getUrlParam } from '@plvs/utils'
import { EsportSlug, useGetRescheduleMatchQuery } from '@plvs/graphql'
import { Param } from '@plvs/const'

import { NxButton, NxTooltip } from '@playvs-inc/nexus-components'
import { normalizeActionAndInfoMatch } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.helpers'
import {
  RescheduleMatchStep,
  canMatchBeRescheduled,
} from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { RescheduleMatchDialog } from './RescheduleMatchDialog'

interface RescheduleMatchButtonProps {
  matchId: string
  teamId: string
  type?: string
  onClose?(): void
  onReschedule?(): void
  esportSlug: EsportSlug | null
}

export const RescheduleMatchButton: React.FC<RescheduleMatchButtonProps> = ({
  matchId,
  teamId,
  type,
  onClose,
  onReschedule,
  esportSlug,
}) => {
  const { data } = useGetRescheduleMatchQuery({
    variables: { matchId },
    errorPolicy: 'all',
  })
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [step, setStep] = React.useState<RescheduleMatchStep>('init')

  useEffect(() => {
    const openRescheduleNotification = getUrlParam(
      window.location,
      Param.OpenRescheduleNotification
    )

    // trigger reschedule notification dialog via url param
    if (openRescheduleNotification && type === 'update') {
      setIsDialogOpen(true)
    }
  }, [])

  const openDialog = (): void => setIsDialogOpen(true)
  const closeDialog = (): void => {
    if (onClose) onClose()
    setIsDialogOpen(false)
  }

  const match = data?.match
  if (!match) return <></>

  const team1SchoolId = match.team1?.school?.id
  const team2SchoolId = match.team2?.school?.id
  const isSameSchool = !!(
    team1SchoolId &&
    team2SchoolId &&
    team1SchoolId === team2SchoolId
  )

  const normalizedMatch = normalizeActionAndInfoMatch(match)
  const { canReschedule, disableReason } =
    canMatchBeRescheduled(normalizedMatch)

  const renderedDisabledReason = disableReason ?? ''

  let button = <></>
  let initStep: RescheduleMatchStep
  switch (type) {
    case 'read':
      button = (
        <Button
          color="inherit"
          data-cy="rescheduleButton"
          onClick={openDialog}
          variant="outlined"
        >
          View Your Request
        </Button>
      )
      initStep = step === 'init' ? 'read-request' : step
      break

    case 'update':
      button = (
        <Button
          color="inherit"
          data-cy="rescheduleButton"
          onClick={openDialog}
          variant="outlined"
        >
          View Request
        </Button>
      )
      initStep = step === 'init' ? 'update-request' : step
      break

    case 'delete':
      button = (
        <Button color="inherit" onClick={openDialog} variant="outlined">
          Cancel
        </Button>
      )
      initStep = step === 'init' ? 'delete-request' : step
      break

    default:
      button = (
        <NxTooltip title={!canReschedule ? renderedDisabledReason : ''}>
          {onClose ? (
            <NxButton
              data-cy="rescheduleMatchButton"
              disabled={!canReschedule}
              label="Reschedule"
              onClick={openDialog}
              variant="primary"
            />
          ) : (
            <NxButton
              data-cy="rescheduleMatchButton"
              disabled={!canReschedule}
              label="Reschedule Match"
              onClick={openDialog}
              variant="primary"
            />
          )}
        </NxTooltip>
      )
      initStep = step === 'init' ? 'requested-reason' : step
      if (step === 'init' && isSameSchool) {
        initStep = 'requested-times'
      }
  }

  return (
    <>
      {button}
      <RescheduleMatchDialog
        closeDialog={closeDialog}
        esportSlug={esportSlug}
        isDialogOpen={!!isDialogOpen}
        isReadOnly
        isSameSchool={isSameSchool}
        match={normalizedMatch}
        onReschedule={onReschedule}
        setStep={setStep}
        step={initStep}
        teamId={teamId}
      />
    </>
  )
}
