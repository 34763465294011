/* istanbul ignore file */
// Provider component is not tested.  Components using it are tested instead

import React from 'react'
import { omit, head, uniqBy } from 'ramda'
import {
  useMyLeagueFiltersQuery,
  MyLeagueFiltersQueryResult,
  PhaseType,
  UserRoleName,
  useSchoolLeaguesQuery,
  useGetSeasonForLeagueFilterQuery,
  useGetNonLoggedInMetaseasonsQuery,
  CompetitionGroup,
  SeasonTeamMatchSort,
  SeasonStatus,
  useGetLeagueMetaseasonsByLeagueIdQuery,
} from '@plvs/graphql'
import {
  RelativeTiming,
  getFilteredCompetitionModel,
  getFilteredTeamsForSchool,
  findPromotedMetaseason,
  mapMetaseasonsForFilters,
  MetaseasonArg,
} from '@plvs/utils'
import { FilterCacheContext } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'

import {
  LeagueFilterContext,
  SetDefaultMetaseason,
  SetDefaultLeague,
  SetDefaultTeam,
  MinimalSeason,
} from '@plvs/respawn/features/filters/types'
import { Provider } from '@plvs/respawn/containers/filter/league/context'
import {
  mapLeagueToMinimalLeague,
  mapTeamToMinimalTeam,
  mapToMinimalPhase,
  defaultSetLeague,
} from './helpers'
import { useOrganizationsContext } from '../../organization/OrganizationsProvider'

interface MySchoolLeaguesProps {
  setDefaultMetaseason?: SetDefaultMetaseason<MyLeagueFiltersQueryResult>
  setDefaultLeague?: SetDefaultLeague<MyLeagueFiltersQueryResult>
  setDefaultTeam?: SetDefaultTeam<MyLeagueFiltersQueryResult>
  defaultPhaseType?: PhaseType
  shouldUseEligibleMetaseasons?: boolean
}

// TODO: persistance per page, or instance of MyLeagues
// maybe allow for some minor abstraction hook to access these values.

const defaultSetMetaseason: SetDefaultMetaseason<MyLeagueFiltersQueryResult> = (
  metaseasons
) =>
  metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Past)

const defaultSetTeam: SetDefaultTeam<MyLeagueFiltersQueryResult> = (leagues) =>
  head(leagues)

export const MySchoolLeagues: React.FC<MySchoolLeaguesProps> = ({
  children,
  setDefaultMetaseason = defaultSetMetaseason,
  setDefaultLeague = defaultSetLeague,
  setDefaultTeam = defaultSetTeam,
  defaultPhaseType,
  shouldUseEligibleMetaseasons = true,
}) => {
  const {
    competitionModel,
    leagueId,
    metaseasonId,
    phaseId,
    setCompetitionModel,
    setLeagueId,
    setMetaseasonId,
    setTeamId,
    setPhaseId,
    teamId,
  } = React.useContext(FilterCacheContext)

  const {
    selectedEntityId,
    roles,
    organizationIds,
    loading: profileLoading,
  } = useProfileContext()

  const { isLoggedIn } = useUserIdentityFn()

  const {
    competitionModels: competitionModelsConfig,
  } = useOrganizationsContext()
  const selectedCompetitionModel = getFilteredCompetitionModel({
    competitionModel,
    competitionModelsConfig,
  })

  const schoolId = (selectedEntityId || head(organizationIds)) ?? ''
  const isCoach = roles.some(
    (role) =>
      role.resourceId === selectedEntityId &&
      role.roleName === UserRoleName.Coach
  )

  const {
    data,
    loading: loadingMetaseasons,
    error: errorLoadingMetaseasons,
  } = useMyLeagueFiltersQuery({
    variables: { schoolId },
    skip: profileLoading || !isLoggedIn || !schoolId,
  })

  const {
    data: leagueMetaseasonsData,
    loading: loadingLeagueMetaseasons,
  } = useGetLeagueMetaseasonsByLeagueIdQuery({
    variables: { leagueId },
    skip: !leagueId,
  })

  const leagueMetaseasons = (leagueMetaseasonsData?.league?.seasons ?? [])
    .map((s) => s.metaseason)
    .filter((m) => m?.status === SeasonStatus.Active)

  const {
    data: nonLoggedInMetaseasonData,
    loading: loadingNonLoggedInUserMetaseasons,
  } = useGetNonLoggedInMetaseasonsQuery({
    variables: {
      options: {
        filters: {
          competitionGroups: [CompetitionGroup.HighSchool],
          status: SeasonStatus.Active,
        },
        sort: SeasonTeamMatchSort.Asc,
      },
    },
    skip: shouldUseEligibleMetaseasons,
  })

  const value: LeagueFilterContext = {
    leagues: [],
    metaseasons: [],
    teams: [],
    loading: loadingMetaseasons,
    error: errorLoadingMetaseasons,
    setMetaseason: setMetaseasonId,
    setLeague: setLeagueId,
    setTeam: setTeamId,
    phases: [],
    setCompetitionModel,
    setPhase: setPhaseId,
    defaultPhases: [],
  }
  let selectedMetaseasonId = metaseasonId
  let selectedLeagueId = leagueId

  if (
    !loadingMetaseasons &&
    !errorLoadingMetaseasons &&
    !loadingNonLoggedInUserMetaseasons &&
    !loadingLeagueMetaseasons
  ) {
    const combinedMetaseasons = [
      ...(shouldUseEligibleMetaseasons ? data?.eligibleMetaseasons ?? [] : []),
      ...(nonLoggedInMetaseasonData?.metaseasons ?? []),
      ...(leagueMetaseasons as MetaseasonArg[]),
    ]

    const metaseasons = uniqBy(
      (m) => m.id,
      mapMetaseasonsForFilters({
        metaseasons: combinedMetaseasons,
      })
    )
    const promotedMetaseason = findPromotedMetaseason({ metaseasons })
    const metaseason =
      metaseasons.find((ms) => ms.id === metaseasonId) ||
      setDefaultMetaseason(metaseasons, data)

    selectedMetaseasonId = metaseason ? metaseason.id : selectedMetaseasonId

    value.promotedMetaseason = promotedMetaseason
    value.metaseasons = metaseasons
    value.metaseason = metaseason
    value.competitionModel = selectedCompetitionModel
  }

  const {
    data: scopedLeaguesData,
    loading: loadingScopedData,
    error: errorLoadingLeagues,
  } = useSchoolLeaguesQuery({
    variables: {
      schoolId,
    },
    skip: !schoolId || !selectedMetaseasonId,
  })

  const allLeagues = (scopedLeaguesData?.getLeaguesBySchoolId || []).map(
    mapLeagueToMinimalLeague
  )
  const leagues = allLeagues.filter((league) =>
    selectedCompetitionModel
      ? league.competitionModel === selectedCompetitionModel
      : true
  )
  const selectedLeague =
    leagues.find((league) => league.id === leagueId) ||
    setDefaultLeague(leagues, data, isCoach)

  selectedLeagueId = selectedLeague ? selectedLeague.id : selectedLeagueId

  const schoolTeams = getFilteredTeamsForSchool({
    teams: data?.school?.teams ?? [],
    league: selectedLeague,
  }).map(mapTeamToMinimalTeam)

  const dataIsLoaded =
    !loadingScopedData &&
    !loadingMetaseasons &&
    !loadingNonLoggedInUserMetaseasons

  const {
    data: leagueSeasonData,
    loading: loadingSeasons,
    error: errorLoadingSeason,
  } = useGetSeasonForLeagueFilterQuery({
    variables: {
      leagueId: selectedLeagueId || '',
      metaseasonId: selectedMetaseasonId || '',
    },
    skip: !selectedLeagueId || !selectedMetaseasonId || !dataIsLoaded,
  })

  const team =
    schoolTeams.find((t) => t.id === teamId) ??
    setDefaultTeam(schoolTeams, data)

  value.teams = schoolTeams
  value.team = team
  value.leagues = leagues
  value.league = selectedLeague
  value.esportSlug = selectedLeague?.esport.slug

  const selectedSeason = leagueSeasonData?.league?.seasonByMetaseasonId
  const season = {
    ...omit(['leagues', 'phases'], selectedSeason),
    name: selectedSeason?.name ?? '',
  } as MinimalSeason

  const defaultPhases = selectedSeason?.phases ?? []
  const phases = defaultPhases.map(mapToMinimalPhase)
  const phase =
    phases.find(({ id }) => id === phaseId) ||
    phases.find(({ type }) =>
      defaultPhaseType ? type === defaultPhaseType : PhaseType.RegularSeason
    ) ||
    head(phases)
  value.season = season
  value.defaultPhases = defaultPhases
  value.phases = phases
  value.phase = phase
  value.loading =
    loadingScopedData ||
    loadingMetaseasons ||
    loadingSeasons ||
    loadingLeagueMetaseasons

  value.error =
    errorLoadingMetaseasons || errorLoadingSeason || errorLoadingLeagues

  return <Provider value={value}>{children}</Provider>
}
