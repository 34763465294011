import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const Lock = createSvgIcon(
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="white"
      height="31"
      rx="15.5"
      stroke="#D9DCDF"
      width="31"
      x="0.5"
      y="0.5"
    />
    <path
      d="M20.6 16C20.6 15.3382 20.0618 14.8 19.4 14.8H18.8V13C18.8 11.3458 17.4542 10 15.8 10C14.1458 10 12.8 11.3458 12.8 13V14.8H12.2C11.5382 14.8 11 15.3382 11 16V20.8C11 21.4618 11.5382 22 12.2 22H19.4C20.0618 22 20.6 21.4618 20.6 20.8V16ZM14 13C14 12.0076 14.8076 11.2 15.8 11.2C16.7924 11.2 17.6 12.0076 17.6 13V14.8H14V13Z"
      fill="#2F3B43"
    />
  </svg>,
  'Lock',
  { viewBox: '0 0 32 32' }
)
