import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as H from 'history'

export type RouteComponentProps = {
  match?: any
  location?: H.Location
  history?: H.History
  staticContext?: {
    statusCode: number
  }
  id?: string
  matchId?: string
}

export const withRouter = (Component): ((props: any) => JSX.Element) => {
  const ComponentWithRouterProp = (props): JSX.Element => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}
