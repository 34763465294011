import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(8, 4),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  search: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  textfield: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))
