import React from 'react'

import { NxTooltip } from '@playvs-inc/nexus-components'

export const StatsAvatarWrapper: React.FC<{
  tooltipTitle: NonNullable<React.ReactNode>
  children: React.ReactElement
}> = ({ tooltipTitle, children }) => {
  return (
    <NxTooltip arrow placement="top-start" title={tooltipTitle}>
      {children}
    </NxTooltip>
  )
}
