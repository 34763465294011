// As a primer to understanding this file, read the brown bag on useSafeQuery & useAutoskipQuery
// https://www.notion.so/playvs/useSafeQuery-useAutoskipQuery-cef6a2ee370e46dc81646eb13363c5db

import { QueryHookOptions } from '@apollo/client'
import {
  isBoolean,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNull,
  isUndefined,
  noop,
} from 'ramda-adjunct'
import { useCallback } from 'react'

// types

type SafeQueryFn = <
  Query extends Record<string, unknown>,
  QueryVariables extends Record<string, unknown>,
  Options extends QueryHookOptions<Query, QueryVariables>,
  Fn extends (options: Options) => any // (1)
>(
  useQuery: Fn,
  options: Options
) => ReturnType<Fn> & {
  refetch: ReturnType<Fn>['refetch'] | (() => Promise<void>)
  refetchWithSameVariables:
    | (() => ReturnType<ReturnType<Fn>['refetch']>)
    | (() => Promise<void>)
}

// consts

const asyncNoop = async (): Promise<void> => {
  noop()
}

// utils

const getIsSkippable = (value: unknown): boolean =>
  isUndefined(value) ||
  isNull(value) ||
  isEmptyString(value) ||
  isEmptyArray(value)

export const getDefaultSkip = (
  variables: Record<string, unknown> | undefined
): boolean =>
  isUndefined(variables)
    ? false
    : Object.entries(variables).some(
        ([_, value]) =>
          getIsSkippable(value) ||
          (isArray(value) && value.some(getIsSkippable))
      )

/** 
 * 
 * # A Note on Unsafe Typing (1)
 * 
 * There is some unsafe typing inside this function (see (1)), namely the use 
 *  of `any` for the Fn return type. The reason for this--and I could be wrong
 *  but--I'm fairly sure that one would need to pass the graphql document, eg. 
 *  GetMyRolesDocument, into the function to get full typing inside
 *  useSafeQuery.
 *
 * I chose to not make `document` an argument since that would create extra
 *  work for developers using this function for no tangible return.
 *
 * # Example Usage:
 * 
 * ```
const { data, refetchWithSameVariables } = useSafeQuery(
  useGetRescheduleRequestsQuery,
  {
    variables: { matchId },
  }
)
```
 * 
 */
export const useSafeQuery: SafeQueryFn = (useQuery, options) => {
  const { pollInterval: pollIntervalOption, skip, variables } = options
  // I. Override useQuery arguments

  //
  // A: pollInterval is set to 0 when skip is true because apollo ignores skip
  //  when polling.
  // https://github.com/apollographql/react-apollo/issues/3921
  //
  const pollInterval = skip ? 0 : pollIntervalOption

  const { refetch: refetchBase, ...restQueryHookResult } = useQuery({
    ...options,
    pollInterval, // A
  })

  // II. Override useQuery return

  //
  // B: Make refetch function noop when skip is true because apollo ignores
  //  skip when refetching.
  // https://github.com/apollographql/react-apollo/issues/2127
  //
  const refetch = skip ? asyncNoop : refetchBase
  //
  // WARNING: If you are going to take advantage of the memoization of
  //  refetchWithSameVariables, you must memoize the `variables` object passed
  //  to useSafeQuery, etc.
  //
  const refetchWithSameVariablesBase = useCallback(
    (): Promise<unknown> => refetchBase(variables),
    [refetchBase, variables]
  )
  //
  // C: Add refetchWithSameVariables convenience function to cover the common
  //  use case of needing to refetch with the same variables.
  //
  const refetchWithSameVariables = skip
    ? asyncNoop
    : refetchWithSameVariablesBase

  return {
    ...restQueryHookResult,
    refetch, // B
    refetchWithSameVariables, // C
  }
}

/**
 *
 * # What
 * This function will automatically set skip to true if any of option.variables
 *  are falsy-ish--see Why for more.
 *
 * If you require a different behavior, you have two options. You can:
 *
 * 1. passing a boolean value for `skip`, thereby overriding the autoskip
 *     behavior
 * 2. use useSafeQuery, which has no autoskip behavior
 *
 * # Why
 * Most queries should be skipped when a variable is null, undefined, an empty
 *  string, an empty array, or an array containing null, undefined, or an empty
 *  string.
 *
 * ## Example 1: `$id: ID!`
 * In Example 1, if we provide null or undefined as the id, the typescript
 *  compiler will detect this and produce a compile error. If we ignore the
 *  typescript compiler, the query will still fail when executed--the GraphQL
 *  API will reject it for violating the type constraint.
 *
 * Furthermore, if we provide an empty string as the id, the database layer
 *  will fail when performing a SQL query, because an empty string is an
 *  invalid UUID.
 *
 * ## Example 2: `$ids: [ID!]`
 * In Example 2, the same concerns apply to each item in the ids array.
 *
 * Furthermore, it is more often the case that we prefer to skip when the array
 *  is empty, because the query will have nothing to return. Also, if we're
 *  going to reject `[undefined]`, we should reject `[]` as well--for sanity's
 *  sake.
 *
 */
export const useAutoskipQuery: SafeQueryFn = (useQuery, options) => {
  const { skip: skipOption, variables } = options
  //
  // A: Default the skip value if a boolean is not already provided; this is
  //  somewhat unsafe, but then again, so is writing code. 👩‍💻👹
  //
  const skip = isBoolean(skipOption) ? skipOption : getDefaultSkip(variables)

  // @ts-ignore I'm not sure how to get the types to line up here for useQuery.
  return useSafeQuery(useQuery, {
    ...options,
    skip, // A
  })
}
