import React from 'react'

import { Login } from '@plvs/rally/features/login/Login'
import { LoginForm } from '@plvs/rally/features/login/LoginForm'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NASEF_SLUG, Param } from '@plvs/const'
import { NxErrorState } from '@playvs-inc/nexus-components'
import { PublicPage } from './page'
import { useQueryParams } from '../components/filter'
import { rallyEnv } from '../env'

export const LoginPage: React.FC = () => {
  const flags = useFlags()
  const [params] = useQueryParams([Param.UTM])

  const isNASEFLogin = flags.nasefUx && params.utm_source === NASEF_SLUG

  return (
    <PublicPage description="Login to PlayVS" title="Login">
      <Login title={flags.errorStateLogin ? '' : 'Welcome Back'}>
        {flags.errorStateLogin ? (
          <NxErrorState
            additionalInfo="Please check back."
            componentDescription="login feature"
            data-testid="error-state-login"
            isStandalone
          />
        ) : (
          <LoginForm
            cookieDomain={rallyEnv.COOKIE_DOMAIN}
            data-testid="login-form"
            isNASEFLogin={isNASEFLogin}
            oAuthRedirect={rallyEnv.GOOGLE_AUTH_URI}
          />
        )}
      </Login>
    </PublicPage>
  )
}
