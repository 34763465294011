import React from 'react'
import { HeroGutter, TitleHero } from '@plvs/rally/components/hero'
import { PageContentGutter } from '@plvs/respawn/features/layout'
import { ManagePlayers } from '@plvs/rally/features/coach/players/ManagePlayers'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { AppPage } from '../../page'

export const ManagePlayersPage: React.FC = () => {
  return (
    <AppPage title="Manage Players">
      <HeroGutter>
        <MatchImageCard>
          <TitleHero
            subtitle="Verify new players and assign esport preferences"
            title="Players"
          />
        </MatchImageCard>
      </HeroGutter>
      <PageContentGutter>
        <ManagePlayers />
      </PageContentGutter>
    </AppPage>
  )
}
