/*

  Notifications
  -----

  This is a top level container that exposes notification functionality to
  all the children in the app react tree. This should allow for notifications
  to be consumed and acknowledged anywhere in the application.

  To access the notifications.

  const { notifications } = useAppState()

  // find specific notification
  const myNotification = (notifications?..items ?? [])
    .find(notif => notif.notification.name === 'NOTIFICATION_NAME')

  if (myNotification) {
    // acknowledge notification
    notifications.acknowledge(myNotification.id)
  }

*/

import {
  GetLegacyNotificationsQueryResult,
  LegacyUserNotification,
  LegacyNotification,
} from '@plvs/graphql'
import { AppState } from './state'

export enum NotificationActionTypes {
  Complete = 'notifications-fetch-complete',
  Acknowledged = 'notification-acknowledged',
}

export enum NotificationNames {
  RescheduleMatch = 'request_match_reschedule_to_receiving_coach',
  Scrimmage = 'direct_scrimmage_request_to_receiving_coach',
}

export interface SingleNotification
  extends Pick<LegacyUserNotification, 'id' | 'status'> {
  surveyMatchId?: string
  total?: number
  rescheduleMatchId?: string
  notification: Pick<
    LegacyNotification,
    | 'id'
    | 'name'
    | 'description'
    | 'title'
    | 'type'
    | 'buttonText'
    | 'href'
    | 'imageUrl'
  >
}

export interface NotificationCompleteAction {
  type: NotificationActionTypes.Complete
  items: SingleNotification[]
  acknowledge: (id: string) => Promise<void>
}

export interface NotificationAcknowledgedAction {
  type: NotificationActionTypes.Acknowledged
  id: string
}

export type NotificationActions =
  | NotificationCompleteAction
  | NotificationAcknowledgedAction

interface NotificationFetchedParams {
  data: GetLegacyNotificationsQueryResult['data']
  acknowledge: (id: string) => Promise<void>
}

export const notificationsFetched = ({
  data,
  acknowledge,
}: NotificationFetchedParams): NotificationCompleteAction => {
  const items = (data?.getAllPendingLegacyNotifications ?? []).filter(
    (x) => x
  ) as SingleNotification[]
  return {
    type: NotificationActionTypes.Complete,
    items,
    acknowledge,
  }
}

export const reduceNotifications = (
  state: AppState,
  action: NotificationActions
): AppState => {
  switch (action.type) {
    case NotificationActionTypes.Complete:
      return {
        ...state,
        notifications: {
          pending: false,
          items: action.items,
          acknowledge: action.acknowledge,
        },
      }

    // TODO: Notifications should be removed from list
    case NotificationActionTypes.Acknowledged:
    default:
      return state
  }
}
