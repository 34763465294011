import { CompetitionGroup } from '@plvs/graphql/generated'

export const CompetitionPillLabel = {
  [CompetitionGroup.HighSchool]: 'HIGH SCHOOL',
  [CompetitionGroup.MiddleSchool]: 'MIDDLE SCHOOL',
}

export const CompetitionPillVariant = {
  [CompetitionGroup.HighSchool]: 'info',
  [CompetitionGroup.MiddleSchool]: 'misc',
}
