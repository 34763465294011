/*

  Attributes
  -----

  This is a top level container that exposes user attributes functionality to
  all the children in the app react tree. This should allow for specific attributes about a user
  to be consumed and completed anywhere in the application.

*/
import { GetUserAttrsQueryResult } from '@plvs/graphql'

import { AppState, RefetchUserAttrsFn, UserAttrsState } from './state'

export enum UserAttrsActionTypes {
  Complete = 'user-attr-complete',
}

export interface UserAttrsCompleteAction {
  type: UserAttrsActionTypes.Complete
  payload: UserAttrsState
}

export type UserAttrsActions = UserAttrsCompleteAction

export const userAttrsFetched = ({
  data,
  error,
  loading,
  refetch,
}: Pick<GetUserAttrsQueryResult, 'data' | 'error' | 'loading'> & {
  refetch?(): RefetchUserAttrsFn
}): UserAttrsCompleteAction => ({
  type: UserAttrsActionTypes.Complete,
  payload: {
    attributes: data?.userAttributes,
    error,
    loading,
    refetch,
  },
})

export const reduceUserAttrs = (
  state: AppState,
  { type, payload }: UserAttrsActions
): AppState => {
  switch (type) {
    case UserAttrsActionTypes.Complete:
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          ...payload,
        },
      }
    default:
      return state
  }
}
