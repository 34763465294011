import React from 'react'
import { intersection, values } from 'ramda'

import { NxSelectOption } from '@playvs-inc/nexus-components'

import { CompetitionGroup, EsportSlug } from '@plvs/graphql'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'

interface EsportOptionsProps {
  competitionGroups?: CompetitionGroup[]
  selectedEsportSlug: EsportSlug | undefined
}

export function useScrimmageEsportOptions({
  competitionGroups = values(CompetitionGroup),
  selectedEsportSlug,
}: EsportOptionsProps): React.ReactElement[] {
  const esportAdapters = useAllEsportAdapters()
  const esportOptions = esportAdapters
    .filter(
      (esportAdapter) =>
        esportAdapter.isScrimmageEnabled && esportAdapter.isEsportEnabled
    )
    .map((esportAdapter) => ({
      competitionGroups:
        esportAdapter.competitionGroups ?? values(CompetitionGroup),
      name: esportAdapter.name,
      slug: esportAdapter.slug,
    }))

  const optionsFilteredByCompetitionGroups = esportOptions.filter(
    (option) =>
      !!intersection(option.competitionGroups, competitionGroups).length
  )

  const options: React.ReactElement[] = optionsFilteredByCompetitionGroups.map(
    (esport) => (
      <NxSelectOption
        key={esport.slug}
        selected={esport.slug === selectedEsportSlug}
        value={esport.slug ?? ''}
      >
        {esport.name}
      </NxSelectOption>
    )
  )

  return [
    <NxSelectOption key="placeholder" value="">
      Select an Esport
    </NxSelectOption>,
    ...options,
  ]
}
