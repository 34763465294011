import { ResourcesWrapper } from '@plvs/rally/features/resources/ResourcesWrapper'
import React from 'react'
import { withTransaction } from '@elastic/apm-rum-react'
import { AppPage } from '../page'

const ResourcesPageInternal: React.FC = () => {
  return (
    <AppPage title="Resources Page">
      <ResourcesWrapper />
    </AppPage>
  )
}

export const ResourcesPage = withTransaction(
  'ResourcesPage',
  'component'
)(ResourcesPageInternal)
