import React from 'react'

import { ListItem, makeStyles, useTheme } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { EsportRating, MatchStatus } from '@plvs/graphql'
import {
  NxTooltip,
  Pill,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

interface StyleProps {
  placing: number
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    overflow: 'hidden',
    padding: theme.spacing(1.5, 3),
  },
  placing: ({ placing }: StyleProps): CreateCSSProperties => ({
    color:
      placing === 2 ? theme.palette.error.main : theme.palette.success.main,
  }),
  wonLost: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    textTransform: 'capitalize',
    color: theme.palette.ColorTextAlt2,
  },
  link: {
    width: '100%',
    color: theme.palette.ColorTextBase,
  },
  linkContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gridGap: theme.spacing(1),
  },
  userClusterWrapper: {
    overflow: 'hidden',
  },
}))
interface Props {
  avatarUrl?: string
  matchId: string
  end?: React.ReactElement
  won: number
  lost: number
  placing: number
  schoolName: string
  matchDate: string
  teamName: string
  teamId?: string
  esportRating?: EsportRating
  status: string
}
const placingOutcomes: { [key: number]: string } = {
  0: 'T',
  1: 'W',
  2: 'L',
}

export const MatchHistoryItem = ({
  avatarUrl,
  schoolName,
  matchDate,
  teamName,
  matchId,
  end,
  won,
  lost,
  placing,
  teamId,
  esportRating = EsportRating.General,
  status,
}: Props): React.ReactElement => {
  const theme = useTheme()
  const classes = useStyles({ placing })
  const outcome = placingOutcomes[placing]
  const isByeMatch = status === MatchStatus.Bye
  const isForfeitMatch = status === MatchStatus.Forfeited

  const cluster = !teamName ? (
    <NxUserCluster
      avatarHashId="firebob"
      randomPersona="Firebob"
      randomPersonaIndex={5}
      subtitles={[{ title: `N/A • ${matchDate}` }]}
      title={
        <NxTooltip
          arrow
          placement="top-start"
          title={
            isByeMatch
              ? 'We were unable to find this team an opponent. This team will receive a Bye (win) for this week.'
              : `This match was ${status}.`
          }
        >
          <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
            <NxTypography variant="body2">N/A</NxTypography>
            <Pill
              label={status}
              size="small"
              variant={status === MatchStatus.Bye ? 'misc' : 'error'}
            />
          </Box>
        </NxTooltip>
      }
    />
  ) : (
    <NxUserCluster
      avatarHashId={teamId ?? ''}
      avatarUrl={esportRating === EsportRating.Restricted ? '' : avatarUrl}
      onClickAvatar={(): void => {
        window.open(`/app/match/${matchId}`, '_blank', 'noopener')
      }}
      subtitles={[
        {
          title:
            schoolName && esportRating !== EsportRating.Restricted
              ? `${schoolName} · ${matchDate}`
              : matchDate,
        },
      ]}
      title={
        isForfeitMatch ? (
          <NxTooltip
            arrow
            placement="top-start"
            title={`This match was ${status}.`}
          >
            <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
              <NxTypography variant="body2">{teamName}</NxTypography>
              <Pill label={status} size="small" variant="error" />
            </Box>
          </NxTooltip>
        ) : (
          teamName
        )
      }
    />
  )
  return (
    <ListItem className={classes.listItem} disableGutters>
      <Box key={matchId} className={classes.link}>
        <Box className={classes.linkContent}>
          <Box
            className={classes.userClusterWrapper}
            data-cy="teamMatchHistoryOpponent"
          >
            {cluster}
          </Box>
          <Box
            display="flex"
            flex={0}
            flexDirection="row"
            justifyContent="center"
          >
            <NxTypography
              className={classes.wonLost}
              data-cy="scoreMatchHistory"
              variant="body2"
            >
              {won} - {lost}
            </NxTypography>
            <Box
              display="flex"
              justifyContent="center"
              padding="0 20px"
              width="30px"
            >
              <NxTypography
                className={classes.placing}
                data-cy="resultsMatchHistory"
                variant="body2"
              >
                {outcome || ' '}
              </NxTypography>
            </Box>
          </Box>
          {end}
        </Box>
      </Box>
    </ListItem>
  )
}

export default MatchHistoryItem
