// Initializing 3rd party lib.  Unit test coverage not possible.
/* istanbul ignore file */
import { initPlasmicLoader } from '@plasmicapp/loader-react'
import { rallyEnv } from '@plvs/rally/env'

export const plasmic = initPlasmicLoader({
  projects: [
    {
      id: rallyEnv.PLASMIC_ID, // your project id
      token: rallyEnv.PLASMIC_TOKEN, // your project token
    },
  ],
  preview: rallyEnv.PLASMIC_PREVIEW,
})
