import React from 'react'

import { LargeTextField } from '@plvs/rally/components/form'
import { Box } from '@plvs/respawn/features/layout'

const charMaxLength = 250
const charMinLength = 5

export const ForfeitDetailStep: React.FC<{
  forfeitDetail: string | undefined
  onChange(event: any): void
}> = ({ forfeitDetail = '', onChange }) => (
  <Box pt={2} px={0.1}>
    <LargeTextField
      charMaxLength={charMaxLength}
      charMinLength={charMinLength}
      label="We are forfeiting the match because..."
      onChange={onChange}
      value={forfeitDetail || ''}
    />
  </Box>
)
