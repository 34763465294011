import React from 'react'
import { SelectObject } from '@plvs/rally/components/filter'
import {
  NonLoggedInUserMetaseason,
  useNonLoggedInUserProviderContext,
} from '@plvs/rally/features/profile/NonLoggedInUserProvider'
import { Box } from '@plvs/respawn/features/layout'

export const NonLoggedInUserFilters: React.FC = () => {
  const {
    metaseasons,
    metaseason,
    setMetaseasonId,
  } = useNonLoggedInUserProviderContext()
  return (
    <Box className="item">
      <SelectObject<NonLoggedInUserMetaseason>
        option={metaseason || { id: '', name: '', startsAt: '' }}
        options={metaseasons}
        renderValue={(m): string => m?.name ?? 'n/a'}
        setOption={(m): void => setMetaseasonId(m?.id ?? '')}
      />
    </Box>
  )
}
