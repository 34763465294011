import { NON_SERIES_UUID } from '@plvs/const'
import {
  MatchSettleRequestFragment,
  SeriesAssets,
} from '@plvs/graphql/generated'
import { Attachment } from '@plvs/rally/components/form/attach/attachHelpers'

// In format { [seriesId]: [{ [gameNumber]: [assets] }]}
export interface SeriesAttachments {
  [id: string]: {
    ordinalNumber: number
    assets: Attachment[]
  }[]
}

export type SetAttachmentsFn = (value: SeriesAttachments) => void

export type AttachScreenshotAPI = {
  attachments: SeriesAttachments
  setAttachments: SetAttachmentsFn
  ordinalNumber: number
  seriesId: string
}

export const addAttachment = ({
  attachments = {},
  attachmentToAdd,
  setAttachments,
  seriesId,
  ordinalNumber,
}: AttachScreenshotAPI & {
  attachmentToAdd: Attachment
}): SeriesAttachments => {
  const newAttachments = { ...attachments }

  if (newAttachments[seriesId]) {
    const gameSet = newAttachments[seriesId].find(
      (set) => set.ordinalNumber === ordinalNumber
    )

    if (gameSet) {
      gameSet.assets.push(attachmentToAdd)
    } else {
      newAttachments[seriesId].push({
        ordinalNumber,
        assets: [attachmentToAdd],
      })
    }
  } else {
    newAttachments[seriesId] = [{ ordinalNumber, assets: [attachmentToAdd] }]
  }

  setAttachments(newAttachments)
  return newAttachments
}

export const removeAttachment = ({
  attachments = {},
  attachmentToRemove,
  setAttachments,
  seriesId,
  ordinalNumber,
}: AttachScreenshotAPI & {
  attachmentToRemove: Attachment
}): SeriesAttachments => {
  const newAttachments = { ...attachments }
  const gameAttachments = newAttachments[seriesId]?.find(
    (game) => game.ordinalNumber === ordinalNumber
  ) || { assets: [] }
  gameAttachments.assets = gameAttachments.assets.filter(
    ({ id }) => id !== attachmentToRemove.id
  )

  setAttachments(newAttachments)

  return newAttachments
}

export const mapSeriesAttachmentsToPayload = (
  attachments: SeriesAttachments
): SeriesAssets[] => {
  return Object.entries(attachments).map(([seriesId, gameAssets]) => ({
    seriesId: seriesId !== NON_SERIES_UUID ? seriesId : undefined,
    gameAssets: gameAssets.map(({ ordinalNumber, assets }) => ({
      ordinalNumber,
      assetIds: assets.map(({ id }) => id),
    })),
  }))
}

export const mapRequestAttachmentsToSeries = (
  matchSettleRequest: MatchSettleRequestFragment | null
): SeriesAttachments => {
  if (!matchSettleRequest) {
    return {}
  }

  let attachments = {}
  const setAttachments = (val: SeriesAttachments): void => {
    attachments = val
  }

  matchSettleRequest.results?.seriesResults?.forEach((currSeries) => {
    currSeries.gameResults.forEach((currGame) => {
      currGame.assets?.forEach((asset) => {
        attachments = addAttachment({
          attachments,
          attachmentToAdd: asset,
          ordinalNumber: currGame?.ordinalNumber,
          setAttachments,
          seriesId: currSeries?.id ?? '',
        })
      })
    })
  })

  return attachments
}
