import React from 'react'
import { useLocation } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { EnrollmentSideBar as PresentationalSidebar } from '@plvs/rally/features/enrollment/EnrollmentSidebar/EnrollmentSidebar'
import { EnrollmentSidebarTopContent } from '@plvs/rally/features/enrollment/EnrollmentSidebar/EnrollmentSidebarTopContent'
import { CompetitionGroup, CompetitionModel, Metaseason } from '@plvs/graphql'
import {
  generateNameFromFirstAndLastMetaseason,
  sortMetaseasonTimelineBySuccession,
  EnrollmentProduct,
} from '@plvs/utils'
import {
  getEnrollmentDetails,
  EnrollmentStep,
} from '@plvs/respawn/containers/enrollment/enrollmentDetailsData'
import {
  EnrollmentSeason,
  OptionalWeeksCache,
  DisabledEnrollmentCTAStatus,
  SeasonEnrollmentRangeData,
  AnnualPassInstanceEnrollment,
} from '@plvs/respawn/containers/enrollment/types'
import { getFinalSlotExclusionsSelections } from '@plvs/respawn/containers/enrollment/enrollmentHelpers'
import { EnrollmentPassTotals } from '../../features/enrollment/EnrollmentSidebar/EnrollmentPassTotals'

interface EnrollmentSidebarProps {
  onContinue: () => void
  logo: string
  title: string
  currentAnnualPassInstance: AnnualPassInstanceEnrollment | undefined
  season?: EnrollmentSeason
  product?: EnrollmentProduct
  disableButton?: DisabledEnrollmentCTAStatus
  error?: Error
  loading?: boolean
  competitionGroup?: CompetitionGroup | null
  isLastCompetitionModel: boolean
  nextCompetitionModel: CompetitionModel
  optionalWeeksCache: OptionalWeeksCache
  optionalSeasonWeeksCache: OptionalWeeksCache
  hasEnrolledTeams: boolean
  passesNeeded: number
  isUnlimited: boolean
  numberOfTeams: number
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
}

export const EnrollmentSidebar: React.FC<EnrollmentSidebarProps> = ({
  title,
  logo,
  onContinue,
  currentAnnualPassInstance,
  season,
  product,
  disableButton,
  error,
  loading,
  competitionGroup,
  isLastCompetitionModel,
  nextCompetitionModel,
  optionalWeeksCache,
  optionalSeasonWeeksCache,
  hasEnrolledTeams,
  passesNeeded,
  numberOfTeams,
  isUnlimited,
  seasonSlotExclusionRangeData,
}) => {
  const flags = useFlags()
  const isCollege = competitionGroup === CompetitionGroup.College

  const location = useLocation()
  const step = location.pathname.match(/teams/)
    ? EnrollmentStep.SelectTeam
    : EnrollmentStep.SelectPlan
  const enrollmentDetails = getEnrollmentDetails({
    isLastCompetitionModel,
    nextCompetitionModel,
    seasonName: season?.name,
  })[step]

  const metaseasons: Metaseason[] = currentAnnualPassInstance?.seasonPassBundles
    ?.map((bundle) => bundle.metaseason)
    .filter((ms) => ms) as Metaseason[]
  const sortedMetaseasons = sortMetaseasonTimelineBySuccession(metaseasons)
  const yearName = generateNameFromFirstAndLastMetaseason(sortedMetaseasons)
  const showBreakWeekInSidebar = getFinalSlotExclusionsSelections({
    cache: optionalWeeksCache,
  }).flattenedSlotExclusionSelections.some(
    (s) => !!s.selection || !!s.confirmedSelection
  )
  const showSeasonBreakWeekInSidebar = getFinalSlotExclusionsSelections({
    cache: optionalSeasonWeeksCache,
  }).flattenedSlotExclusionSelections.some(
    (s) => !!s.selection || !!s.confirmedSelection
  )

  const paymentPlan = {
    name: product?.name || 'No Product',
    yearName,
  }
  return (
    <PresentationalSidebar
      avatarSrc={logo}
      disableButton={disableButton}
      error={error}
      footerButtonTitle={
        hasEnrolledTeams
          ? `Update Enrollment`
          : enrollmentDetails?.sidebar?.buttonTitle
      }
      isUnlimited={isUnlimited}
      loading={loading}
      numberOfTeams={numberOfTeams}
      onButtonPress={onContinue}
      passesNeeded={passesNeeded}
      title={title}
    >
      <EnrollmentSidebarTopContent
        detail={paymentPlan}
        isBoxShadow
        isCollege={isCollege}
        optionalSeasonWeeksCache={optionalSeasonWeeksCache}
        optionalWeeksCache={optionalWeeksCache}
        seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
        showBreakWeekInSidebar={showBreakWeekInSidebar}
        showSeasonBreakWeekInSidebar={showSeasonBreakWeekInSidebar}
        title="Plan Type"
      />
      {!flags.freeCompetition && (
        <EnrollmentPassTotals
          isBoxShadow
          isUnlimited={isUnlimited}
          neededPasses={passesNeeded}
          seasonName={season?.name || ''}
        />
      )}
    </PresentationalSidebar>
  )
}
