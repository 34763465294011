import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  '@global': {
    input: {
      // Prevents browser from applying its own styles when autofilled
      transition: 'background-color 0s 600000s, color 0s 600000s !important',
    },
  },
}))
