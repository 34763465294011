import { Season } from '@plvs/graphql/generated'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const isCurrentSeason = (
  season: Pick<Season, 'startsAt' | 'endsAt'>
): boolean => {
  const now = dayjs()

  return now.isBetween(dayjs(season.startsAt), dayjs(season.endsAt))
}
