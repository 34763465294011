import React, { useMemo } from 'react'
import { Box, useTheme } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'
import { LeagueOutlined } from '@playvs-inc/nexus-icons'

import {
  EsportRating,
  MatchSettleRequestFragment,
} from '@plvs/graphql/generated'
import { DmrMatch, DmrTeam } from '@plvs/utils'
import { MatchReportAccordion } from '../matchReportAccordion/MatchReportAccordion'
import { MinimumSeries } from '../matchReportSeriesSidebar/MatchReportSeriesSidebar'
import {
  GameSelectionValue,
  ReportOption,
  ReportOptionValue,
} from '../matchReportAccordion/MatchReportAccordion.types'
import {
  mapSeriesToMinimumSeries,
  mapSeriesToReportOptionArray,
  mapTeamToReportOption,
} from './GameSelection.helpers'
import { useStyles } from '../matchReportAccordion/MatchReportAccordion.styles'

interface GameSelectionProps {
  match: DmrMatch
  matchSettleRequest: MatchSettleRequestFragment | null
  expandedGameIndex: number
  loadingMatchSettleRequest: boolean
  selectedSeriesIndex: number
  isSubmittingGameResult: boolean
  myTeam: DmrTeam | undefined
  oppTeam: DmrTeam | undefined
  isSeriesMatch: boolean
  onSelectOption(
    gameIndex: number,
    { value, meta }: GameSelectionValue
  ): Promise<void>
  setSelectedSeriesIndex(index: number): void
  setExpandedGameIndex(index: number): void
}

export const GameSelection: React.FC<GameSelectionProps> = ({
  match,
  matchSettleRequest,
  expandedGameIndex,
  loadingMatchSettleRequest,
  selectedSeriesIndex,
  isSubmittingGameResult,
  myTeam,
  oppTeam,
  isSeriesMatch,
  onSelectOption,
  setExpandedGameIndex,
  setSelectedSeriesIndex,
}) => {
  const styles = useStyles()
  const theme = useTheme()

  const series = match?.series ?? []
  const isYouthProgram = match?.esport?.rating === EsportRating.Restricted

  const options: ReportOption[][] = useMemo(() => {
    return isSeriesMatch
      ? mapSeriesToReportOptionArray({
          series,
          myTeamId: myTeam?.id ?? '',
          isYouthProgram,
          myTeam,
          oppTeam,
        })
      : [
          [
            mapTeamToReportOption({
              team: myTeam,
              isMyTeam: true,
              isYouthProgram,
            }),
            mapTeamToReportOption({
              team: oppTeam,
              isMyTeam: false,
              isYouthProgram,
            }),
          ],
        ]
  }, [match?.matchResults, series])

  const minimumSeries: MinimumSeries[] = mapSeriesToMinimumSeries({
    series,
    myTeamId: myTeam?.id ?? '',
    matchSettleRequest,
  })

  const numberOfGames = (isSeriesMatch
    ? match?.series?.[0]?.bestOf
    : match?.bestOf) as number // bestOf is required

  const mapMatchSettleRequestToValues: GameSelectionValue[][] = useMemo((): GameSelectionValue[][] => {
    return (
      matchSettleRequest?.results?.seriesResults?.map((currSeries) => {
        return (
          currSeries?.gameResults?.map((gameResult) => {
            const gameWinnerValue: ReportOptionValue =
              gameResult.winningTeamId === myTeam?.id
                ? ReportOptionValue.MyTeam
                : ReportOptionValue.OppTeam
            const gameLoserValue: ReportOptionValue =
              gameResult.losingTeamId === myTeam?.id
                ? ReportOptionValue.MyTeam
                : ReportOptionValue.OppTeam

            return gameResult.incompleteReason
              ? {
                  value: ReportOptionValue.Incomplete,
                  meta: {
                    reason: gameResult.incompleteReason,
                    faultId: gameLoserValue,
                  },
                }
              : {
                  value: gameWinnerValue,
                  meta: undefined,
                }
          }) ?? []
        )
      }) ?? []
    )
  }, [matchSettleRequest])

  const onClickSeriesIndex = (seriesIndex: number): void => {
    const prevSeriesIsReady = !!matchSettleRequest?.results?.seriesResults?.[
      seriesIndex - 1
    ]?.isReadyForReview
    const wholeMatchIdReady = !!matchSettleRequest?.isReadyForReview
    const selectedSeriesIsReady = !!matchSettleRequest?.results
      ?.seriesResults?.[seriesIndex]?.isReadyForReview
    const selectedSeriesGameResults =
      matchSettleRequest?.results?.seriesResults?.[seriesIndex]?.gameResults

    // if selected series is ready then expand the first game in the series to resrict
    // user from adding more games to finished series.
    // restict users from navigating to series if the previous series in not ready.
    // if prev series is ready and selected series is not ready then show the next sequential game to submit
    if (selectedSeriesIsReady) {
      setSelectedSeriesIndex(seriesIndex)
      setExpandedGameIndex(0)
    } else if ((seriesIndex === 0 || prevSeriesIsReady) && !wholeMatchIdReady) {
      setSelectedSeriesIndex(seriesIndex)
      setExpandedGameIndex(
        selectedSeriesGameResults?.length
          ? selectedSeriesGameResults?.length
          : 0
      )
    }
  }

  const isClickable = (seriesIndex: number): boolean => {
    const prevSeriesIsReady = !!matchSettleRequest?.results?.seriesResults?.[
      seriesIndex - 1
    ]?.isReadyForReview
    const wholeMatchIdReady = !!matchSettleRequest?.isReadyForReview
    const selectedSeriesIsReady = !!matchSettleRequest?.results
      ?.seriesResults?.[seriesIndex]?.isReadyForReview

    return (
      selectedSeriesIsReady ||
      ((seriesIndex === 0 || prevSeriesIsReady) && !wholeMatchIdReady)
    )
  }

  return (
    <>
      <Box borderTop={`1px solid ${theme.palette.BorderLight}`} mx={-3}>
        {!series.length ? (
          <MatchReportAccordion
            expandedGameIndex={expandedGameIndex}
            loadingContent={isSubmittingGameResult || loadingMatchSettleRequest}
            numberOfGames={numberOfGames}
            onEdit={setExpandedGameIndex}
            onSelectOption={onSelectOption}
            options={options[selectedSeriesIndex]}
            seriesIndex={selectedSeriesIndex}
            values={mapMatchSettleRequestToValues?.[selectedSeriesIndex] ?? []}
          />
        ) : (
          minimumSeries.map(({ players, winner }, ndx) =>
            selectedSeriesIndex === ndx ? (
              <MatchReportAccordion
                // eslint-disable-next-line react/no-array-index-key
                key={`game-${ndx}`}
                expandedGameIndex={expandedGameIndex}
                loadingContent={
                  isSubmittingGameResult || loadingMatchSettleRequest
                }
                numberOfGames={numberOfGames}
                onEdit={setExpandedGameIndex}
                onSelectOption={onSelectOption}
                options={options[selectedSeriesIndex]}
                seriesIndex={selectedSeriesIndex}
                values={
                  mapMatchSettleRequestToValues?.[selectedSeriesIndex] ?? []
                }
              />
            ) : (
              <Box
                borderBottom={`1px solid ${theme.palette.BorderLight}`}
                className={isClickable(ndx) ? styles.clickable : undefined}
                display="flex"
                gridGap={theme.spacing(1)}
                onClick={(): void => onClickSeriesIndex(ndx)}
                px={3}
                py={2}
              >
                <NxTypography variant="overline">Series {ndx + 1}</NxTypography>

                <Box flex={1} textAlign="center">
                  {winner ? (
                    <Box
                      alignItems="center"
                      display="flex"
                      gridGap={theme.spacing(0.5)}
                      justifyContent="center"
                    >
                      <LeagueOutlined />

                      <NxTypography data-cy="gameWinner" variant="body4">
                        {winner.name}
                      </NxTypography>
                      <button
                        className={styles.editButton}
                        onClick={(): void => onClickSeriesIndex(ndx)}
                        type="button"
                      >
                        <NxTypography color="inherit" variant="body4">
                          Edit
                        </NxTypography>
                      </button>
                    </Box>
                  ) : (
                    <NxTypography variant="body4">
                      {players[0].name} vs {players[1].name}
                    </NxTypography>
                  )}
                </Box>
              </Box>
            )
          )
        )}
      </Box>
    </>
  )
}
