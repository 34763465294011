import React from 'react'
import { Control } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import { NxSelectOption } from '@playvs-inc/nexus-components'

import { DAYS_OF_MONTH, MONTHS, ONBOARD_YEARS } from '@plvs/const'
import { NxSelectController } from '@plvs/rally/components/select'

const useStyles = makeStyles((theme) => ({
  inputs: {
    alignItems: 'center',
    display: 'flex',
    gridColumnGap: '8px',
    maxWidth: 460,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gridRowGap: '8px',
    },
  },
}))

interface Props {
  className?: string
  selectClassName?: string
  control: Control
  disabled?: boolean
  showBirthInLabels?: boolean
  defaultValues?: {
    month: number
    day: number
    year: number
  }
}

export const BirthdaySelectController: React.FC<Props> = ({
  className,
  selectClassName,
  control,
  disabled,
  defaultValues,
  showBirthInLabels,
}) => {
  const classes = useStyles()

  const defaultMonthValue =
    defaultValues?.month === undefined
      ? null
      : Object.entries(MONTHS)
          .find(([_, value]) => value === defaultValues.month)?.[1]
          .toString()

  return (
    <div className={clsx(classes.inputs, className)}>
      <NxSelectController
        className={selectClassName}
        control={control}
        data-cy="signup-month"
        defaultValue={defaultMonthValue}
        disabled={disabled}
        fullWidth
        label={showBirthInLabels ? 'Birth Month' : 'Month'}
        name="month"
        required
      >
        {Object.keys(MONTHS).map((month) => (
          <NxSelectOption key={month} value={MONTHS[month]}>
            {month}
          </NxSelectOption>
        ))}
      </NxSelectController>
      <NxSelectController
        className={selectClassName}
        control={control}
        data-cy="signup-day"
        defaultValue={defaultValues?.day.toString() || null}
        disabled={disabled}
        fullWidth
        label={showBirthInLabels ? 'Birth Day' : 'Day'}
        name="day"
        required
      >
        {DAYS_OF_MONTH.map((d) => (
          <NxSelectOption key={d} value={d}>
            {d}
          </NxSelectOption>
        ))}
      </NxSelectController>
      <NxSelectController
        className={selectClassName}
        control={control}
        data-cy="signup-year"
        defaultValue={defaultValues?.year.toString() || null}
        disabled={disabled}
        fullWidth
        label={showBirthInLabels ? 'Birth Year' : 'Year'}
        name="year"
        required
      >
        {ONBOARD_YEARS.map((year) => (
          <NxSelectOption key={year} value={year}>
            {year}
          </NxSelectOption>
        ))}
      </NxSelectController>
    </div>
  )
}
