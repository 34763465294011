import React from 'react'
import { EsportSlug } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { EsportPreferenceItem } from '@plvs/rally/components/overlay/managePlayers/types'
import { NxEsportAvatar, NxTypography } from '@playvs-inc/nexus-components'

// Project Paywall-Relocation
export interface EligibleEsportPreferences {
  id: string
  name: string | null | undefined
  slug: EsportSlug
  isChecked: boolean
  avatarLogoUrl: string
  gameType: string
}

export const EsportPrefLabel: React.FC<{
  preference: EligibleEsportPreferences
}> = ({ preference }) => {
  const { name, slug, gameType } = preference

  return (
    <Box alignItems="center" display="flex">
      {slug && <NxEsportAvatar esport={slug} iconHeight={38} iconWidth={38} />}
      <Box display="flex" flexDirection="column" ml={2}>
        {name && <NxTypography variant="body2">{name}</NxTypography>}
        {gameType && (
          <NxTypography colorToken="ColorTextAlt" variant="body1">
            {gameType}
          </NxTypography>
        )}
      </Box>
    </Box>
  )
}

export const createPreferences = (
  esportArr: EligibleEsportPreferences[],
  checkedEsportItems: Array<string>
): EsportPreferenceItem[] =>
  esportArr.map((preference) => {
    const { slug, id } = preference

    const isChecked = checkedEsportItems.includes(id)
    return {
      id,
      isChecked,
      slug,
      label: <EsportPrefLabel preference={preference} />,
    }
  })
