import React from 'react'
import { EsportRating, PhaseResult } from '@plvs/graphql'
import { makeStyles } from '@material-ui/core'
import { TeamCluster as TeamClusterComponent } from '../../cluster'

const useStyles = makeStyles((theme) => ({
  teamClusterRoot: {
    padding: theme.spacing(2, 2, 2, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

type PhaseTeam = NonNullable<PhaseResult['team']>

interface Props {
  data: {
    value?: PhaseTeam & { hasClinchedPlayoffs: boolean }
    team?: PhaseTeam & { hasClinchedPlayoffs: boolean }
    row?: {
      original?: {
        record?: {
          streak?: number
        }
      }
    }
  }
}

export const TeamClusterCell: React.FC<Props> = ({ data }) => {
  const classes = useStyles()
  const team = data.value || data.team

  if (!team) {
    return <>-</>
  }
  const teamName = team?.name ?? 'N/A'
  const isRestrictedEsport = team?.esport?.rating === EsportRating.Restricted
  const schoolName = isRestrictedEsport ? '' : team?.school?.name ?? 'N/A'
  const schoolSlug = team?.school?.slug ?? ''
  const logoUrl = team?.school?.logoUrl ?? ''
  const teamId = team?.id
  const esportRating = team?.esport?.rating
  const hasClinchedPlayoffs = team?.hasClinchedPlayoffs

  return (
    <div className={classes.teamClusterRoot}>
      <TeamClusterComponent
        avatarUrl={logoUrl}
        esportRating={esportRating}
        hasClinchedPlayoffs={hasClinchedPlayoffs}
        schoolSlug={schoolSlug}
        subtitle={schoolName}
        subtitleVariant="body3"
        teamId={teamId}
        title={teamName}
        titleVariant="subtitle1"
      />
    </div>
  )
}
