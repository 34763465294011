import React from 'react'
import { Param } from '@plvs/const'
import { PhaseType } from '@plvs/graphql'
import { printPhaseTypeMap } from '@plvs/utils'
import { useOption } from './useOption'
import { Option } from './filterHelpers'
import { FilterButton } from './FilterButton'

export type FilterPhaseType = { id: PhaseType }

export const allPhasesArr: { name: string; type: PhaseType }[] = [
  { name: printPhaseTypeMap[PhaseType.Preseason], type: PhaseType.Preseason },
  {
    name: printPhaseTypeMap[PhaseType.RegularSeason],
    type: PhaseType.RegularSeason,
  },
  { name: printPhaseTypeMap[PhaseType.Playoff], type: PhaseType.Playoff },
]

export const phaseOptions: Option<FilterPhaseType>[] = allPhasesArr.map(
  ({ name, type }, i) => {
    return {
      id: `${name}-${i}`,
      name,
      values: [{ id: type }],
    }
  }
)

export function usePhases(): {
  selectedPhaseType: PhaseType
  phaseFilter: React.ReactElement
} {
  const { option, options, setOption } = useOption({
    allOptions: phaseOptions,
    defaultOption: phaseOptions[0],
    queryParam: Param.PhaseType,
  })

  return {
    selectedPhaseType: option.values[0].id,
    phaseFilter: (
      <FilterButton<Option<FilterPhaseType>>
        id="phase-type-filter"
        option={option}
        options={options}
        setOption={setOption}
      />
    ),
  }
}
