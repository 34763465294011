/* istanbul ignore file */
// Unit testing this file requires mocking out a lot of low level interactions
// to the point where it will be hard to maintain.

import React from 'react'
import { useParams } from 'react-router'
import { noop } from 'ramda-adjunct'
import copy from 'clipboard-copy'
import { useSnackbar } from 'notistack'

import {
  Edit,
  Trashcan,
  Duplicate,
  Unenrollteam,
  Emote,
  Optoutofpreseason,
} from '@playvs-inc/nexus-icons'

import { MoreMenu, MenuItemProps } from '@plvs/respawn/features/button/MoreMenu'
import { makeStyles, useTheme } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { RosterCardMenuItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RenameTeam } from '@plvs/respawn/features/manage-teams/RenameTeam'
import { UnenrollTeam } from '@plvs/respawn/features/manage-teams/UnenrollTeam'
import { DeleteTeam } from '@plvs/respawn/features/manage-teams/DeleteTeam'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { LeaveTeam } from '@plvs/respawn/features/manage-teams/LeaveTeam'
import { UserRole } from '../../RosterProvider.types'
import { RosterMenuLabel } from './RosterMenuLabel'
import { UpdateTeamAvatarModal } from '../../modal/UpdateTeamAvatarModal'

export const useStyles = makeStyles((theme) => ({
  unenrollMenuItem: {
    color: theme.palette.ColorTextError,
  },
  menuIcon: {
    color: theme.palette.ColorIconAlt2,
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  box: {
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundAlt2,
    },
  },
  label: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
}))
export interface RosterCardMenuUserProps {
  isEnrolled: boolean
  teamId: string
  isTeamUnenrollmentLocked: boolean
  onMutationSuccess(effectiveAtDate?: string): Promise<void>
  teamName: string
  userRoles: UserRole[]
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
}

export const RosterCardMenu: React.FC<RosterCardMenuUserProps> = ({
  isEnrolled,
  teamId,
  isTeamUnenrollmentLocked,
  onMutationSuccess,
  getPermissions,
  userRoles,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const additionalMenuProps = {
    scroll: 'body',
    width: 400,
  }

  const { metaseason } = useSchoolLeagueInfoContext()
  const { eventId } = useParams<{ eventId: string }>()
  const allowedActions = getPermissions(
    userRoles,
    ContentContextPermissions.ROSTER_MENU
  ) as RosterCardMenuItems[]

  const rosterMenuItemsMap: Record<RosterCardMenuItems, MenuItemProps> = {
    [RosterCardMenuItems.RenameTeam]: {
      label: (
        <RosterMenuLabel
          className={classes.label}
          labelIcon={<Edit height={20} width={20} />}
          labelText="Rename Team"
        />
      ),
      dialogProps: {
        children: <RenameTeam id={teamId} onClose={noop} />,
      },
      key: 'Rename Team',
      ...additionalMenuProps,
    },
    [RosterCardMenuItems.UnenrollTeam]: {
      disabled: isTeamUnenrollmentLocked,
      label: (
        <RosterMenuLabel
          className={classes.label}
          labelIcon={<Unenrollteam height={20} width={20} />}
          labelText="Unenroll Team"
        />
      ),
      dialogProps: {
        children: (
          <UnenrollTeam
            id={teamId}
            metaseason={metaseason}
            onClose={noop}
            onSuccess={onMutationSuccess}
          />
        ),
      },
      key: 'Unenroll Team',
      ...additionalMenuProps,
    },
    [RosterCardMenuItems.CopyTeamId]: {
      label: (
        <RosterMenuLabel
          className={classes.label}
          labelIcon={<Duplicate height={20} width={20} />}
          labelText="Copy Team ID"
        />
      ),
      onClick: (): void => {
        copy(teamId)
          .then((): void => {
            enqueueSnackbar('Copied Team ID to clipboard', {
              variant: 'info',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              autoHideDuration: 2000,
            })
          })
          .catch((): void => {
            enqueueSnackbar('Error copying to clipboard', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              persist: true,
            })
          })
      },
      key: 'Copy Team ID',
      ...additionalMenuProps,
    },
    [RosterCardMenuItems.UpdateTeamAvatar]: {
      label: (
        <RosterMenuLabel
          className={classes.label}
          labelIcon={<Emote height={20} width={20} />}
          labelText="Update Team Avatar"
        />
      ),
      dialogProps: {
        children: <UpdateTeamAvatarModal onClose={noop} teamId={teamId} />,
      },
      key: 'Update team avatar',
      ...additionalMenuProps,
    },
    [RosterCardMenuItems.DeleteTeam]: {
      label: (
        <RosterMenuLabel
          className={classes.label}
          isRed
          labelIcon={
            <Trashcan
              className={classes.unenrollMenuItem}
              height={20}
              width={20}
            />
          }
          labelText="Delete Team"
        />
      ),
      disabled: isTeamUnenrollmentLocked,
      disabledTooltipTitle:
        'This team can no longer be deleted. Please contact support for further assistance.',
      dialogProps: {
        children: (
          <DeleteTeam id={teamId} isEnrolled={isEnrolled} onClose={noop} />
        ),
      },
      key: 'Delete Team',
      ...additionalMenuProps,
    },
    [RosterCardMenuItems.LeaveTeam]: {
      label: (
        <RosterMenuLabel
          className={classes.label}
          isRed
          labelIcon={
            <Optoutofpreseason
              className={classes.unenrollMenuItem}
              height={20}
              transform="rotate(180)"
              width={20}
            />
          }
          labelText="Leave Team"
        />
      ),
      dialogProps: {
        children: (
          <LeaveTeam
            onClose={noop}
            onSuccess={onMutationSuccess}
            seasonId={eventId!}
            teamId={teamId}
          />
        ),
      },
      key: 'Leave Team',
      ...additionalMenuProps,
    },
  }

  const menuItems = allowedActions
    .filter((menuItem) => {
      if (menuItem === RosterCardMenuItems.UnenrollTeam && !isEnrolled) {
        return false
      }
      return true
    })
    .map((rosterMenuItem) => {
      return rosterMenuItemsMap[rosterMenuItem]
    })

  return (
    <>
      {!!menuItems.length && (
        <Box
          alignItems="center"
          border={`1px solid ${theme.palette.BorderMedium}`}
          borderRadius="20px"
          className={classes.box}
          data-cy="more-menu"
          display="flex"
          height="30px"
          justifyContent="center"
          width="30px"
        >
          <MoreMenu
            iconClassName={classes.menuIcon}
            menuItemProps={menuItems}
          />
        </Box>
      )}
    </>
  )
}
