import React from 'react'

import {
  EsportSlug,
  useForfeitMatchMutation,
  ForfeitReason,
} from '@plvs/graphql'
import {
  CancelMatchStep,
  getRefetchMatchLobbyQueries,
} from '@plvs/rally/containers/match'
import {
  NxButton,
  NxDialogProps,
  NxModal,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { Box, useTheme } from '@material-ui/core'

import { canMatchBeRescheduled } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { RescheduleMatchButton } from '@plvs/respawn/features/rescheduleMatch/reschedule'
import { ForfeitMatchSelectReasonStep } from './ForfeitMatchSelectReasonStep'
import { ForfeitDetailStep } from './ForfeitDetailStep'
import { CancelMatch } from './cancelMatch'

const COVIDConfirmationMessage =
  'By selecting this option, your team will—for now—record a loss. However, once your school re-opens, you will be able to contact us to reschedule the match without penalty. Are you sure you want to forfeit?'
const standardMessage =
  'Are you sure you want to forfeit? Your team will record a loss. This cannot be undone.'

const charMaxLength = 250
const charMinLength = 5

export const ForfeitMatchDialog: React.FC<{
  closeDialog(): void
  onForfeitSuccess?(): void
  isDialogOpen: boolean
  match: CancelMatch
  step: CancelMatchStep
  setStep(step: CancelMatchStep): void
  teamId: string
  showRescheduleOption?: boolean
  esportSlug: EsportSlug | undefined | null
}> = ({
  closeDialog,
  match,
  isDialogOpen,
  step,
  setStep,
  teamId,
  onForfeitSuccess = (): void => {},
  showRescheduleOption = true,
  esportSlug,
}) => {
  const theme = useTheme()

  const { canReschedule } = canMatchBeRescheduled(match)
  const rescheduleMessage =
    canReschedule && showRescheduleOption
      ? ' You still have time to reschedule.'
      : ''
  const [reason, setReason] = React.useState<ForfeitReason>()
  const [forfeitDetail, setForfeitDetail] = React.useState<string>()
  const onClose = (): void => {
    closeDialog()
    setReason(undefined)
  }
  const onHandleForfeitDetail = (event: any): void => {
    const detail = event.target.value
    setForfeitDetail(detail)
  }
  // mutation
  const [mutate, { loading: isMutating }] = useForfeitMatchMutation()

  const onSubmit = async (): Promise<void> => {
    if (!isMutating) {
      try {
        const success = (
          await mutate({
            awaitRefetchQueries: true,
            refetchQueries: getRefetchMatchLobbyQueries(match.id),
            variables: {
              matchId: match.id,
              teamId,
              reason,
              forfeitDetail,
            },
          })
        )?.data?.forfeitMatch

        // confirm success
        if (success) {
          // TODO: Analytics.
          onForfeitSuccess()
          setStep('forfeit-success')
        } else {
          throw new Error('Match could not be forfeited.')
        }
      } catch (e: any) {
        setStep('forfeit-failure')
      }
    }
  }

  const dialogProps: Record<CancelMatchStep, NxDialogProps> = {
    'forfeit-verification': {
      onClose,
      open: isDialogOpen,
      showTopRightClose: true,
      children: null,
      actions: (
        // This is a work-around as RescheduleMatchButton adds extra styles.
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          gridGap={theme.spacing(2)}
          justifyContent="flex-end"
          mr={-2}
        >
          <NxButton
            data-cy="forefeitAnywayButton"
            disabled={isMutating}
            label="Forfeit"
            onClick={(): void => {
              setStep('forfeit-reason')
            }}
            variant="text"
          />

          {canReschedule && showRescheduleOption && (
            <RescheduleMatchButton
              esportSlug={esportSlug ?? null}
              matchId={match.id}
              onClose={onClose}
              teamId={teamId}
            />
          )}
        </Box>
      ),
      subtitleNode: (
        <NxTypography variant="body1">
          Do you really want to forfeit? Your team will receive a loss.
          <b>{rescheduleMessage}</b>
        </NxTypography>
      ),
      size: 'small',
      title: 'Forfeit Match',
    },
    'forfeit-reason': {
      children: (
        <ForfeitMatchSelectReasonStep
          esportSlug={esportSlug}
          match={match}
          onClose={onClose}
          reason={reason}
          setReason={setReason}
        />
      ),
      onClose,
      open: isDialogOpen,
      showTopRightClose: true,
      size: 'large',
      title: 'Forfeit Match',
      actions: (
        <>
          <NxButton
            data-cy="forefeitAnywayButton"
            label="Cancel"
            onClick={onClose}
            variant="text"
          />
          <NxButton
            data-cy="forfeitDialogNextbutton"
            disabled={!reason}
            label="Next"
            onClick={(): void => {
              setStep('forfeit-detail')
            }}
            variant="primary"
          />
        </>
      ),
    },
    'forfeit-detail': {
      children: (
        <ForfeitDetailStep
          forfeitDetail={forfeitDetail}
          onChange={onHandleForfeitDetail}
        />
      ),
      onClose,
      open: isDialogOpen,
      size: 'large',
      subtitle:
        'We would like to learn more about why you are deciding to forfeit this match.',
      showTopRightClose: true,
      title: 'Forfeit Match',
      actions: (
        <>
          <NxButton
            label="Previous"
            onClick={(): void => {
              setStep('forfeit-reason')
            }}
            variant="text"
          />

          <NxButton
            data-cy="nextButtonForfeit"
            disabled={
              (forfeitDetail ?? '').length > charMaxLength ||
              (forfeitDetail ?? '').length < charMinLength
            }
            label="Next"
            onClick={onSubmit}
            variant="primary"
          />
        </>
      ),
    },
    'forfeit-confirmation': {
      children: null,
      onClose,
      open: isDialogOpen,
      showTopRightClose: true,
      subtitle:
        reason === ForfeitReason.CovidSchoolClosure
          ? COVIDConfirmationMessage
          : standardMessage,
      title: 'Forfeit Match',

      actions: (
        <>
          <NxButton
            label="Previous"
            onClick={(): void => setStep('forfeit-detail')}
            variant="text"
          />

          <NxButton
            color="primary"
            data-cy="forfeitButtonFinal"
            disabled={isMutating}
            label="Forfeit"
            onClick={onSubmit}
            variant="primary"
          />
        </>
      ),
    },
    'forfeit-success': {
      title: 'Match Forfeited',
      onClose,
      open: isDialogOpen,
      showTopRightClose: true,
      children: null,
      size: 'small',
      subtitle:
        'You have forfeited your match against your opponent. This will be recorded as a loss on your record.',
      actions: (
        <NxButton
          data-cy="doneButton"
          label="Close"
          onClick={onClose}
          variant="primary"
        />
      ),
    },
    'forfeit-failure': {
      actions: (
        <NxButton
          data-cy="doneButton"
          label="Close"
          onClick={onClose}
          variant="primary"
        />
      ),
      children: null,
      subtitle:
        'We‘re very sorry! An unexpected error occurred (CODE: 7892). Please try again or contact customer support.',
      title: 'Match Forfeit Failed',
      onClose,
      open: isDialogOpen,
      showTopRightClose: true,
    },
  }

  return <NxModal {...dialogProps[step]} />
}
