import React, { useState } from 'react'
import { Navigate, Route } from 'react-router-dom'

import { Path } from '@plvs/const'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { ScrimmagePageRefreshContext } from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { UserRoleName } from '@plvs/graphql'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { map, prop } from 'ramda'
import { checkIfAllowedByRoles } from '@plvs/utils'
import { CreateScrimmage, Scrimmages } from '@plvs/rally/containers/scrimmage'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { AppPage } from '../page'

export const ScrimmagePage: React.FC = () => {
  const { roles } = useProfileContext()
  const userRoles = map(prop('roleName'), roles)
  const [isRefreshed, toggleRefresh] = useState(false)

  const { isUserPendingVerificationAtOrg, loading } = useUserIdentityFn()

  if (!loading && isUserPendingVerificationAtOrg) {
    return <Navigate to={Path.Dashboard} />
  }
  const isAllowedAccess = checkIfAllowedByRoles({
    userRoles,
    requireAnyOf: [UserRoleName.Coach, UserRoleName.Captain],
  })
  return (
    <AppPage title="Scrimmage">
      <ScrimmagePageRefreshContext.Provider
        value={{ isRefreshed, toggleRefresh }}
      >
        <WaitTillLoaded loading={false}>
          <ApmRoutes>
            {isAllowedAccess && (
              <>
                <Route element={<Scrimmages />} path="/*" />
                <Route element={<CreateScrimmage />} path="create" />
              </>
            )}
          </ApmRoutes>
        </WaitTillLoaded>
      </ScrimmagePageRefreshContext.Provider>
    </AppPage>
  )
}
