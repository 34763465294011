import React, { useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { sortBy, prop } from 'ramda'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import { logger } from '@plvs/rally/logging'
import {
  GameAssistantSelectionOptionFragment,
  useGetCharacterSelectionOptionsByEsportIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles as useMatchAssistantStyles } from '../../../MatchAssistant.styles'
import { useStyles } from './LoadoutSelect.styles'

const getFirebobMessage = ({
  allLoadoutsSelected,
  isWaitingForOpponent,
}: {
  allLoadoutsSelected: boolean
  isWaitingForOpponent: boolean
}): string => {
  if (allLoadoutsSelected && !isWaitingForOpponent) {
    return 'Nice selections!'
  }

  if (isWaitingForOpponent) {
    return 'Both teams need to be ready.'
  }

  return 'What loadouts are we running?'
}

export const LoadoutSelect: React.FC = () => {
  const styles = useStyles()
  const maStyles = useMatchAssistantStyles()
  const {
    currentStep,
    myTeam,
    currentStepLoading,
    gameOrdinal,
    selectedStage,
    match,
    gameMode,
  } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const [selections, setSelections] = useState<
    Record<string, GameAssistantSelectionOptionFragment | null>
  >({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState(
    currentStep?.step?.submitted ?? false
  )

  const { data, loading } = useGetCharacterSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id,
  })
  const loadoutOptions = data?.characterSelectionOptionsByEsportId?.selectionOptions?.find(
    (option) => option.gameOrdinal === gameOrdinal
  )

  const sortByDisplayCat = sortBy(prop('displayCategory'))

  const sortedListOfLoadoutSelections = sortByDisplayCat(
    [...(loadoutOptions?.selectionOptions ?? [])].sort((a, b) =>
      (a?.displayName).localeCompare(b?.displayName)
    )
  )

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const allLoadoutsSelected =
    Object.entries(selections).length === myTeam?.starters?.length

  const isWaitingForOpponent = hasSubmitted
  const isNextDisabled =
    !canParticipate ||
    isWaitingForOpponent ||
    !allLoadoutsSelected ||
    isSubmitting
  const isSelectDisabled =
    !canParticipate ||
    updateLoading ||
    isWaitingForOpponent ||
    currentStep?.step?.submitted ||
    isSubmitting

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    value: GameAssistantSelectionOptionFragment | null,
    userId: string
  ): void => {
    setSelections({ ...selections, [userId]: value })
  }

  const handleNext = async (): Promise<void> => {
    setIsSubmitting(true)

    if (isNextDisabled) {
      setIsSubmitting(false)
      return
    }

    logger.debug('[LoadoutCheck | handleNext]', selections)

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
            selectionOptions: Object.entries(selections).map(
              ([userId, loadout]) => ({
                userId,
                selectionOptionId: loadout?.id,
              })
            ),
          },
        },
      })

      setHasSubmitted(true)
      setIsSubmitting(false)
    } catch (err) {
      logger.error(err)
    }
  }

  const firebobMessage = getFirebobMessage({
    allLoadoutsSelected,
    isWaitingForOpponent,
  })

  const submittedSelections = currentStep?.step?.selections ?? []

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <NxTypography variant="overline">{gameMode}</NxTypography>
        <NxTypography variant="h1">
          {selectedStage?.selectionOption?.displayName}
        </NxTypography>

        <Box className={styles.grid} mt={3}>
          <WaitTillLoaded
            loading={currentStepLoading || loading}
            loadingSpinnerProps={{ size: 'medium' }}
            showSpinnerWhileLoading
          >
            {myTeam?.starters?.map(
              ({ id, avatarUrl, publisherAccountName, name }) => {
                const defaultValue = submittedSelections?.find(
                  (option) => option?.userId === id
                )?.selectionOption

                return (
                  <>
                    <NxUserCluster
                      avatarHashId={id}
                      avatarUrl={avatarUrl ?? ''}
                      subtitles={[{ title: publisherAccountName }]}
                      title={name ?? ''}
                    />

                    <Autocomplete
                      className={styles.autocomplete}
                      data-cy="player-select"
                      defaultValue={defaultValue}
                      disabled={isSelectDisabled}
                      getOptionLabel={(option): string =>
                        `(${option.displayCategory}) ${option.displayName}`
                      }
                      onChange={(evnt, value): void =>
                        handleChange(evnt, value, id)
                      }
                      options={sortedListOfLoadoutSelections}
                      renderInput={(params): React.ReactElement => (
                        <TextField
                          {...params}
                          label="Search Loadouts"
                          variant="outlined"
                        />
                      )}
                      renderOption={(params): React.ReactElement => {
                        return (
                          <NxTypography variant="body1">
                            <b>({params.displayCategory})</b>{' '}
                            {params.displayName}
                          </NxTypography>
                        )
                      }}
                    />
                  </>
                )
              }
            )}
          </WaitTillLoaded>
        </Box>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <NxButton
          className={maStyles.cta}
          disabled={isNextDisabled}
          fullWidth
          label="Confirm Loadouts"
          loading={isWaitingForOpponent || updateLoading}
          onClick={handleNext}
          variant="primary"
        />
      </MatchAssistantFooter>
    </>
  )
}
