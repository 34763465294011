import { useGetEligibleMetaseasonsForSchoolQuery } from '@plvs/graphql'
import React, { useState } from 'react'
import { last, uniqBy } from 'ramda'

export type NonLoggedInUserMetaseason = {
  name: string
  id: string
  startsAt: string
}

type NonLoggedInUserProviderContextType = {
  metaseasons: NonLoggedInUserMetaseason[]
  metaseason?: NonLoggedInUserMetaseason | null
  setMetaseasonId: (id: string) => void
  loading: boolean
}

const defaultContextValue = {
  metaseasons: [],
  metaseason: null,
  setMetaseasonId: (): void => {},
  loading: false,
}

export const NonLoggedInUserProviderContext = React.createContext<
  NonLoggedInUserProviderContextType
>(defaultContextValue)

export const useNonLoggedInUserProviderContext = (): NonLoggedInUserProviderContextType => {
  return React.useContext(NonLoggedInUserProviderContext)
}

interface Props {
  schoolId?: string
}

export const NonLoggedInUserProvider: React.FC<Props> = ({
  children,
  schoolId,
}) => {
  const value: NonLoggedInUserProviderContextType = { ...defaultContextValue }
  const [metaseasonId, setMetaseasonIdInternal] = useState<string>()

  const {
    data: eligibleMetaseasonData,
    loading: loadingEligibleMetaseasonData,
  } = useGetEligibleMetaseasonsForSchoolQuery({
    variables: { schoolId: schoolId || '' },
    skip: !schoolId,
  })
  const rawMetaseasons = uniqBy(
    (m) => m.id,
    eligibleMetaseasonData?.eligibleMetaseasons.slice() || []
  )
  rawMetaseasons.sort(
    (m1, m2) =>
      new Date(m1.startsAt || '').getTime() -
      new Date(m2.startsAt || '').getTime()
  )

  const metaseasons = rawMetaseasons.map((m) => {
    return { id: m.id || '', name: m.name || '', startsAt: m.startsAt || '' }
  })

  value.metaseasons = metaseasons
  const metaseason = last(metaseasons)
  if (!metaseasonId && metaseason) {
    setMetaseasonIdInternal(metaseason.id)
  }
  value.metaseason = value.metaseasons.find((m) => m.id === metaseasonId)
  value.loading = loadingEligibleMetaseasonData

  const setMetaseasonId = (id: string): void => {
    if (metaseasons.some((m) => m.id === id)) {
      setMetaseasonIdInternal(id)
    }
  }
  value.setMetaseasonId = setMetaseasonId
  return (
    <NonLoggedInUserProviderContext.Provider value={value}>
      {children}
    </NonLoggedInUserProviderContext.Provider>
  )
}
