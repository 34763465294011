import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout'
import { LeagueMatchScheduleRow } from './LeagueMatchScheduleRow'
import { LeagueMatchRow } from './types'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  viewAll: {
    marginLeft: 'auto',
  },
  row: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  matches: LeagueMatchRow[]
  title: string
  defaultLimit?: number
}

const DEFAULT_NUMBER_OF_MATCHES = 3

export const LeagueMatchesSection: React.FC<Props> = ({
  matches,
  title,
  defaultLimit,
}) => {
  const classes = useStyles()
  const defaultNumberOfMatches = defaultLimit || DEFAULT_NUMBER_OF_MATCHES
  const [numberOfMatches, setNumberOfMatches] = useState<number>(
    defaultNumberOfMatches
  )

  useEffect(() => {
    setNumberOfMatches(defaultNumberOfMatches)
  }, [matches])

  const matchesSize = matches.length

  const handleViewAll = (): void => setNumberOfMatches(matchesSize)

  const handleViewLess = (): void => setNumberOfMatches(defaultNumberOfMatches)

  const isToggled = matchesSize === numberOfMatches

  return (
    <div className={classes.root}>
      <Box alignItems="center" display="flex" mb={2}>
        <NxTypography variant="h4">{title}</NxTypography>
        {matchesSize > 3 && (
          <NxButton
            className={classes.viewAll}
            label={isToggled ? 'View Less' : 'View All'}
            onClick={isToggled ? handleViewLess : handleViewAll}
            variant="text"
          />
        )}
      </Box>
      {matches.slice(0, numberOfMatches).map((match) => (
        <LeagueMatchScheduleRow
          key={match.id}
          className={classes.row}
          match={match}
        />
      ))}
    </div>
  )
}
