import { makeStyles, useTheme } from '@material-ui/core'
import React from 'react'

import { TeamMember } from '@plvs/graphql'
import { NxTooltip } from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Avatar, AvatarSizes } from '.'
import { PlayerToolTip } from '../tooltip/PlayerToolTip'
import { TeamCaptainBadge } from '../badge'

interface Member
  extends Pick<TeamMember, 'id' | 'avatarUrl' | 'isCaptain' | 'name'> {
  username: string | undefined
}

interface TeamAvatarGroupProps {
  avatarSize?: AvatarSizes
  teamFormat: number
  members: Member[]
  disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    transition: 'all 0.7s cubic-bezier(0.395, -0.570, 0.000, 1.285)',
    zIndex: 1,
    '&:first-child': {
      '&:hover': {
        marginRight: theme.spacing(1),
      },
    },
    '&:not(:first-child)': {
      marginLeft: theme.spacing(-1),
      '&:hover': {
        marginLeft: '0px',
        marginRight: theme.spacing(1),
      },
    },
    '&:hover': {
      zIndex: 2,
    },
    paddingLeft: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
  },
  disabledContainer: {
    zIndex: 1,
    marginLeft: theme.spacing(-1),
    '&:first-child': {
      marginLeft: '0px',
    },
    paddingLeft: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
  },
}))

export const TeamAvatarGroup: React.FC<TeamAvatarGroupProps> = ({
  avatarSize = 'small',
  members,
  teamFormat,
  disabled,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const teamFormatArr = new Array(Math.max(teamFormat, members.length))
    .fill(0)
    .map((_, index) => {
      const member = members[index]
      return member || {}
    })

  return (
    <>
      {teamFormatArr.length
        ? teamFormatArr.map(
            ({ id, avatarUrl, isCaptain, name, username }, i) => {
              return (
                <div
                  key={id || `avatar-${i}`}
                  className={
                    disabled ? classes.disabledContainer : classes.container
                  }
                >
                  <NxTooltip
                    arrow
                    title={
                      !disabled && members.length > 0 ? (
                        <PlayerToolTip
                          members={members}
                          name={name}
                          username={username}
                        />
                      ) : (
                        'Available Slot'
                      )
                    }
                  >
                    <div style={{ position: 'relative', width: 'fit-content' }}>
                      {id ? (
                        <NxUserAvatar
                          avatarUrl={avatarUrl}
                          hashId={id}
                          size={NxAvatarSize.XXS}
                        />
                      ) : (
                        <Avatar
                          backgroundColor={
                            theme.palette.ColorBackgroundInactive
                          }
                          disabled={disabled}
                          size={avatarSize}
                          src={avatarUrl || ''}
                        />
                      )}
                      {isCaptain && <TeamCaptainBadge size="tiny" />}
                    </div>
                  </NxTooltip>
                </div>
              )
            }
          )
        : null}
    </>
  )
}
