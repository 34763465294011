import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@plvs/respawn/features/layout'
import { TitleHero } from '@plvs/rally/components/hero'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { EnrollmentCard } from '@plvs/rally/containers/manageTeams/EnrollmentCard'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { CompetitionGroup } from '@plvs/graphql'
import {
  competitionModelToDisplay,
  getCompetitionIntervalName,
} from '@plvs/utils'
import { RegionalNationalWhite } from '@plvs/rally/components/icon/logos'
import { MatchCardBanner } from '@plvs/rally/components/match/MatchCardBanner'
import { Path } from '@plvs/const'
import { Colors } from '@plvs/rally/themes'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import { RouterLink } from '@plvs/respawn/features/route'
import {
  NxTypography,
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

import { useOrganizationsContext } from '../organization/OrganizationsProvider'
import { CompetitionInterval } from './CompetitionInterval'
import { MatchTabs } from './MatchTab'

export const useStyles = makeStyles({
  learnMoreButton: {
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
})
export interface MatchHeaderProps {
  isEnrolled: boolean
}

export const MatchHeader: React.FC<MatchHeaderProps> = ({ isEnrolled }) => {
  const classes = useStyles()
  const flags = useFlags()
  const theme = useTheme()

  const { promotedMetaseason, competitionInterval } = useManageTeamsContext()

  const { competitionModel } = useSchoolLeagueInfoContext()
  const { competitionModels, competitionGroup } = useOrganizationsContext()

  const shouldFilterByCompetitionModels = competitionModels.length > 1
  const isCollege = competitionGroup === CompetitionGroup.College

  const competitionModelDisplayName = competitionModelToDisplay({
    competitionModel,
    competitionGroup,
  })

  const firstDay = competitionInterval?.firstDay
  const lastDay = competitionInterval?.lastDay

  const manageTeamsMatchHeaderTitle = shouldFilterByCompetitionModels
    ? competitionModelDisplayName
    : 'Manage Teams'

  // Banners
  const showBottomBanner = !isEnrolled
  const showTopBanner = !showBottomBanner && isCollege
  const showPromotedInfo = showTopBanner

  const bottomBanner = showBottomBanner ? (
    <MatchCardBanner
      path={`${Path.Enrollment}/${promotedMetaseason?.id ?? ''}`}
      subtitle={`Enrollment for ${
        promotedMetaseason?.name ?? 'current season'
      } is Now Open`}
    />
  ) : null

  const topBanner = showTopBanner ? (
    <MatchCardBanner
      path={`${Path.GettingStarted}/national-and-conference/national-leagues-college`}
      subtitle={`Learn more about ${
        competitionModelDisplayName
          ? competitionModelDisplayName.toUpperCase()
          : 'Our'
      } Leagues`}
    />
  ) : null

  // Subtitles
  const promotedCollegeSubtitle =
    'PlayVS National Leagues are regional leagues that allow for gameplay against all schools in the US and Canada for all of our partnered titles'

  const promotedSubtitle = (
    <Box alignItems="center" display="flex" flexDirection="row" pl={5}>
      <Box pr={1}>
        <NxTypography color="inherit" variant="subtitle1">
          {promotedCollegeSubtitle}
        </NxTypography>
        <Box
          borderBottom={`1px solid ${theme.palette.ColorBackgroundAlt}`}
          display="flex"
          mb={1}
          width="fit-content"
        >
          <Button
            className={classes.learnMoreButton}
            color="inherit"
            component={RouterLink}
            to={`${Path.GettingStarted}/national-and-conference/national-leagues-college`}
            variant="text"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </Box>
  )

  const subtitle = (
    <Box pl={5}>
      <NxTypography color="inherit" variant="subtitle1">
        {firstDay && lastDay
          ? getCompetitionIntervalName({
              firstDay,
              lastDay,
            })
          : ''}
      </NxTypography>
    </Box>
  )

  const heroSubtitle = showPromotedInfo ? promotedSubtitle : subtitle

  const regionalIcon = shouldFilterByCompetitionModels ? (
    <Box lineHeight="normal" pl={2} pt={1}>
      <RegionalNationalWhite fontSize="large" />
    </Box>
  ) : null

  return (
    <>
      {flags.activateFullBleedBanners ? (
        <>
          <CompetitionInterval />
          <NxPlayVsBanner
            size="large"
            subtitle={`${
              firstDay && lastDay
                ? getCompetitionIntervalName({
                    firstDay,
                    lastDay,
                  })
                : ''
            }`}
            title="Manage Teams"
            variant={PersonaImageVariant.ManageTeams}
            widget={<EnrollmentCard />}
          />
        </>
      ) : (
        <MatchImageCard
          bottomBanner={bottomBanner}
          topBanner={topBanner}
          topBannerColor={Colors.Yellow}
        >
          <Box display="flex" flexDirection="column" maxWidth="100%">
            <CompetitionInterval />
            <TitleHero
              subtitle={heroSubtitle}
              title={
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  pl={5}
                >
                  <NxTypography
                    colorToken="OverlayColorTextBase"
                    variant="display"
                  >
                    {manageTeamsMatchHeaderTitle}
                  </NxTypography>
                  {regionalIcon}
                </Box>
              }
            />
          </Box>
          <Box
            height="auto"
            marginLeft="auto"
            marginY="auto"
            pr={3}
            width="auto"
          >
            <EnrollmentCard />
          </Box>
        </MatchImageCard>
      )}
      <MatchTabs />
    </>
  )
}
