import dayjs from 'dayjs'
import React from 'react'
import { UserNotification, EsportRating } from '@plvs/graphql/generated/graphql'
import { UserNotificationContentAdapter } from '../notification.types'
import { TeamVsTeamMessage } from '../templates/TeamVsTeamMessage'
import { PillVariants } from '../../NotificationPill'

export const MatchCanceledAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Match Canceled',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <TeamVsTeamMessage
        avatar1Url={notification?.contentData?.image1 || ''}
        avatar2Url={notification?.contentData?.image2 || ''}
        esportRating={notification?.contentData?.rating || EsportRating.General}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        pillText="Canceled"
        pillVariant={PillVariants.Warning}
        receivedAt={dayjs(notification.createdAt).toDate()}
        team1Id={notification?.contentData?.team1Id || ''}
        team2Id={notification?.contentData?.team2Id || ''}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
