import {
  GameResultFragment,
  MatchSettleRequestFragment,
} from '@plvs/graphql/generated'
import { refetchCurrentGameAssistantStepByMatchIdFunction } from '@plvs/utils'
import {
  AdminSaveGameResultLiveFunction,
  SaveGameResultLiveFunction,
} from '../../../dynamic/dynamic.types'

/// ///////
// Types //
/// ///////
export enum TeamSide {
  homeTeam,
  awayTeam,
}

export enum SubmittedStatus {
  neitherTeamHasSumbitted,
  myTeamHasSubmittedWaitingOnOpp,
  myTeamHasSubmittedOppTeamRejected,
  oppTeamSumbittedMyTeamRejected,
  oppTeamSubmittedWaitingOnMyTeam,
  bothSubmitted,
}

export enum FirebobMessage {
  GG = 'GG!',
  RejectedSubmission = 'Both teams must agree on a selection. Please resubmit.',
  WaitingOnOpp = 'Waiting on opponent selection',
  Hmm = 'Having Issues? Check Game Settings for help',
  Tied = 'Scores cannot equal a draw.',
}

/// /////////
// HELPERS //
/// /////////
export const getFireBobMessage = (
  sumbittedStatus: SubmittedStatus
): FirebobMessage => {
  switch (sumbittedStatus) {
    case SubmittedStatus.myTeamHasSubmittedWaitingOnOpp:
      return FirebobMessage.WaitingOnOpp
    case SubmittedStatus.bothSubmitted:
      return FirebobMessage.GG
    case SubmittedStatus.myTeamHasSubmittedOppTeamRejected:
    case SubmittedStatus.oppTeamSumbittedMyTeamRejected:
      return FirebobMessage.RejectedSubmission
    case SubmittedStatus.neitherTeamHasSumbitted:
    case SubmittedStatus.oppTeamSubmittedWaitingOnMyTeam:
    default:
      return FirebobMessage.Hmm
  }
}

const getCurrentGameFromMatchSettleRequest = (
  matchSettleRequest: MatchSettleRequestFragment | null,
  seriesId: string,
  gameOrdinal: number
): GameResultFragment | undefined => {
  const currentSeries =
    matchSettleRequest?.results?.seriesResults?.find(
      (seriesResult) => seriesResult.id === seriesId
    ) || matchSettleRequest?.results?.seriesResults?.[0]

  return currentSeries?.gameResults?.find(
    (gameResult) => gameResult.ordinalNumber === gameOrdinal
  )
}

export const updateStateOnMatchSettleRequestChange = (args: {
  matchSettleRequest: MatchSettleRequestFragment | null
  seriesId?: string
  gameOrdinal: number
  myTeamId: string
  homeTeamId: string
  matchHasBeenAutoSettled: boolean
  gameAssistantStepHasBeenUpdated: boolean
  setIsLoadingMatchSettleRequest(isLoadingMatchSettleRequest: boolean): void
  setSelectedTeamSide(selectedTeamSide: TeamSide): void
  setFirebobMessage(firebobMessage: FirebobMessage): void
  refetchCurrentGameAssistantStepByMatchId: refetchCurrentGameAssistantStepByMatchIdFunction
}): void => {
  const {
    matchSettleRequest,
    seriesId = '',
    gameOrdinal,
    myTeamId,
    homeTeamId,
    gameAssistantStepHasBeenUpdated,
    setIsLoadingMatchSettleRequest,
    setSelectedTeamSide,
    setFirebobMessage,
    refetchCurrentGameAssistantStepByMatchId,
  } = args

  setIsLoadingMatchSettleRequest(false)

  const currentGame = getCurrentGameFromMatchSettleRequest(
    matchSettleRequest,
    seriesId,
    gameOrdinal
  )

  // Update selected winner
  if (currentGame?.approvedByTeamIds?.includes(myTeamId)) {
    setSelectedTeamSide(
      currentGame.winningTeamId === homeTeamId
        ? TeamSide.homeTeam
        : TeamSide.awayTeam
    )
  }

  // update submission status
  let newSubmittedStatus
  if (!currentGame) {
    newSubmittedStatus = SubmittedStatus.neitherTeamHasSumbitted
  } else if (currentGame.approvedByTeamIds?.length === 2) {
    newSubmittedStatus = SubmittedStatus.bothSubmitted
  } else if (currentGame.approvedByTeamIds?.includes(myTeamId)) {
    newSubmittedStatus = currentGame.gameRejected
      ? SubmittedStatus.myTeamHasSubmittedOppTeamRejected
      : SubmittedStatus.myTeamHasSubmittedWaitingOnOpp
  } else {
    newSubmittedStatus = currentGame.gameRejected
      ? SubmittedStatus.oppTeamSumbittedMyTeamRejected
      : SubmittedStatus.oppTeamSubmittedWaitingOnMyTeam
  }

  if (gameAssistantStepHasBeenUpdated) {
    refetchCurrentGameAssistantStepByMatchId({
      input: { matchId: matchSettleRequest?.matchId ?? '', teamId: myTeamId },
    })
  }

  // update firebob message
  const newFirebobMessage = getFireBobMessage(newSubmittedStatus)
  setFirebobMessage(newFirebobMessage)
}

export const submitGameResults = async (args: {
  selectedTeamSide: TeamSide | -1
  homeTeamId: string
  awayTeamId: string
  homePlayerId?: string
  awayPlayerId?: string
  homeTeamIds?: string[]
  awayTeamIds?: string[]
  userId: string
  matchId: string
  seriesId?: string
  seriesOrdinal?: number
  gameOrdinal: number
  myTeamId: string
  assetIds?: string[]
  saveGameResultLive:
    | SaveGameResultLiveFunction
    | AdminSaveGameResultLiveFunction
  isAdmin?: boolean
  setIsLoadingMatchSettleRequest(isLoadingMatchSettleRequest: boolean): void
  homeScore?: number
  awayScore?: number
}): Promise<void> => {
  const {
    selectedTeamSide,
    homeTeamId,
    awayTeamId,
    homePlayerId,
    awayPlayerId,
    userId,
    matchId,
    seriesId,
    seriesOrdinal = 1,
    gameOrdinal = 1,
    myTeamId,
    saveGameResultLive,
    setIsLoadingMatchSettleRequest,
    assetIds,
    isAdmin,
    homeTeamIds = [],
    awayTeamIds = [],
    awayScore,
    homeScore,
  } = args

  setIsLoadingMatchSettleRequest(true)
  const homeTeamWins = selectedTeamSide === TeamSide.homeTeam
  const winningTeamId = homeTeamWins ? homeTeamId : awayTeamId
  const losingTeamId = homeTeamWins ? awayTeamId : homeTeamId
  const winningTeamScore = homeTeamWins ? homeScore : awayScore
  const losingTeamScore = homeTeamWins ? awayScore : homeScore

  let winningPlayerIds: string[] = homeTeamWins ? homeTeamIds : awayTeamIds
  let losingPlayerIds: string[] = homeTeamWins ? awayTeamIds : homeTeamIds
  if (
    homePlayerId &&
    awayPlayerId &&
    !winningPlayerIds.length &&
    !losingPlayerIds.length
  ) {
    winningPlayerIds = homeTeamWins ? [homePlayerId] : [awayPlayerId]
    losingPlayerIds = homeTeamWins ? [awayPlayerId] : [homePlayerId]
  }

  await saveGameResultLive({
    variables: {
      input: {
        matchId,
        requestedById: userId,
        seriesId,
        seriesOrdinalNumber: seriesOrdinal,
        gameOrdinalNumber: gameOrdinal,
        teamId: isAdmin ? undefined : myTeamId,
        winningTeamId,
        losingTeamId,
        winningPlayerIds,
        losingPlayerIds,
        isIncomplete: false,
        assetIds,
        losingTeamScore,
        winningTeamScore,
      },
    },
  })
}

export function getInvalidScores(
  esportHasScores: boolean,
  homeScore?: number,
  awayScore?: number
): { invalidScores: boolean; errorMessage: FirebobMessage | null } {
  if (!esportHasScores) {
    return {
      invalidScores: false,
      errorMessage: null,
    }
  }

  const scoresExist =
    typeof homeScore === 'number' && typeof awayScore === 'number'
  const areScoresTied = scoresExist && homeScore === awayScore
  const invalidScores = !scoresExist || areScoresTied

  return {
    invalidScores,
    errorMessage: invalidScores && areScoresTied ? FirebobMessage.Tied : null,
  }
}
