import { NotificationActions } from './notifications'
import { OnboardingActions } from './onboarding'
import { UserAttrsActions } from './user-attributes'
import { UserContentActions } from './user-content'

export enum ActionTypes {
  Initialize = 'INIT',
}

export interface InitializedAction {
  type: ActionTypes.Initialize
}

export type AllActions =
  | InitializedAction
  | NotificationActions
  | OnboardingActions
  | UserAttrsActions
  | UserContentActions
