import React, { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { EsportSlug } from '@plvs/graphql/types'
import { NxButton, NxDropdownButton } from '@playvs-inc/nexus-components'
import {
  Forfeit,
  LeagueOutlined,
  Reschedule,
  ResourcesOutlined,
  Unhide,
  Notes,
  Sendfeedback,
  Duplicate,
  Guide,
} from '@playvs-inc/nexus-icons'
import { isMatchCancellableByStatus } from '@plvs/utils'
import {
  CompetitionGroup,
  GameStatus,
  MatchRescheduleRequestStatus,
  MatchStatus,
  useGetEntryCodesQuery,
  useGetMatchRescheduleRequestDataForMenuQuery,
  useSetTourViewedMutation,
  Tour as TourEnum,
} from '@plvs/graphql'
// eslint-disable-next-line import/no-unresolved
import Tour from 'shepherd.js/src/types/tour'
import { generatePath, useNavigate, useLocation } from 'react-router-dom'
import { IntercomArticleMappings, Path, Polling } from '@plvs/const'
import { useSnackbar } from 'notistack'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { GameCodeCopyButton } from './GameCodeCopyButton'
import { useClipboard } from '../button'

const CompetitionLeagueRulesMap: Record<CompetitionGroup, number> = {
  [CompetitionGroup.HighSchool]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.College]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.Open]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.Youth]: IntercomArticleMappings.youthRuleBooks,
  [CompetitionGroup.MiddleSchool]:
    IntercomArticleMappings.middleSchoolRuleBooks,
  [CompetitionGroup.Stadium]: IntercomArticleMappings.ruleBooks,
}

interface ActionAndInfoSectionMenuProps {
  esportSlug: EsportSlug | null | undefined
  competitionGroup: CompetitionGroup | null | undefined
  showMatchLobbyTour: boolean
  showManageMatchSection: boolean
  myTeamId: string
  opposingTeamId: string
  matchId: string
  setIsRescheduleDialogOpen: (isOpen: boolean) => void
  setStep: (step: string) => void
  isMemberOfBothTeams: boolean
  setIsForfeitDialogOpen: (isOpen: boolean) => void
  esportRulebookArticleId: string
  esportMatchInstructionsArticleId: string
  showIntercomArticle: (articleId: number | string) => void
  matchLobbyTourClicked: (props: {
    userId: string
    initiatedTimestamp: string
    stepNumber: number
    timeStamp: string
    clickTarget: string
  }) => void
  showViewRescheduleItem: boolean
  showRescheduleItem: boolean
  matchLobbyTour: Tour | undefined
  initiatedTimestamp: string | undefined
  setInitiatedTimestamp(time: string): void
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const ActionAndInfoSectionMenu: React.FC<
  ActionAndInfoSectionMenuProps
> = ({
  esportSlug,
  competitionGroup,
  showManageMatchSection,
  showMatchLobbyTour,
  myTeamId,
  opposingTeamId,
  matchId,
  isMemberOfBothTeams,
  setIsRescheduleDialogOpen,
  setIsForfeitDialogOpen,
  setStep,
  esportRulebookArticleId,
  esportMatchInstructionsArticleId,
  showIntercomArticle,
  matchLobbyTourClicked,
  showViewRescheduleItem,
  showRescheduleItem,
  matchLobbyTour,
  initiatedTimestamp,
  setInitiatedTimestamp,
}) => {
  const productType = useProductTypeFn()
  const isScholasticProduct = productType === ProductType.Scholastic
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'), {
    noSsr: true,
  })
  const navigate = useNavigate()
  const location = useLocation()
  const isMissionControl = location.pathname.includes('mission-control')
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'), {
    noSsr: true,
  })
  const isDesktopOnMissionControl = isDesktop && isMissionControl
  const flags = useFlags()
  const { enqueueSnackbar } = useSnackbar()

  const { userId } = useUserIdentityFn()

  const esportHasGameCode = esportSlug === EsportSlug.LeagueOfLegends

  const [gameCodeCopyButtonLabel, setGameCodeCopyButtonLabel] = useState(
    'Game Code Unavailable'
  )
  const [gameCodeCopyToastMessage, setGameCodeCopyToastMessage] =
    useState('Game Code Copied')
  const [gameCodeCopyButtonDisabled, setGameCodeCopyButtonDisabled] =
    useState(true)
  const [gameCode, setGameCode] = useState('')

  const [setTourViewed] = useSetTourViewedMutation()

  const {
    data: matchPollData,
    loading: matchPollDataLoading,
    error: matchPollDataError,
    startPolling,
    stopPolling,
  } = useGetEntryCodesQuery({
    variables: { matchId },
    ssr: false,
    skip: !matchId,
  })

  const { data } = useGetMatchRescheduleRequestDataForMenuQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const matchRescheduleRequests = data?.match?.matchRescheduleRequests ?? []

  const status = matchPollData?.match?.status

  useEffect(() => {
    if (
      esportHasGameCode &&
      (status === MatchStatus.Open || status === MatchStatus.Live)
    ) {
      startPolling(Polling.Normal)
    }
    return (): void => stopPolling()
  }, [status])

  const { copy: copyGameCode } = useClipboard(gameCode || '')

  useEffect(() => {
    const openGame = matchPollData?.match?.games?.find(
      (game) =>
        game.status === GameStatus.Open || game.status === GameStatus.Live
    )
    const newGameCode = openGame?.entryCode
    const errorGettingCode = matchPollDataError || (openGame && !newGameCode)
    if (errorGettingCode || !newGameCode) {
      setGameCodeCopyButtonLabel('Game Code Unavailable')
    } else {
      setGameCodeCopyButtonLabel(`Game ${openGame?.ordinalNumber} Code`)
      setGameCodeCopyToastMessage(`Game ${openGame?.ordinalNumber} Code Copied`)
    }

    setGameCodeCopyButtonDisabled(
      Boolean(matchPollDataLoading || matchPollDataError || !newGameCode)
    )
    setGameCode(newGameCode ?? '')
  }, [matchPollData, !matchPollDataLoading])

  const renderMatchLobbyTour =
    isDesktopOnMissionControl &&
    showMatchLobbyTour &&
    !!flags.matchDayOnboardingTour
  const renderManageMatchSection = showManageMatchSection
  const showGameCodeItem = isMobile && esportHasGameCode
  const showMatchInstructionItem = isMobile

  const showForfeitItem = status && isMatchCancellableByStatus(status)
  const showDisputeItem =
    status === MatchStatus.Completed || status === MatchStatus.Forfeited

  const determinRescheduleStepOnClick = (): void => {
    const matchRescheduleRequest = matchRescheduleRequests.find((request) =>
      request.matchId === matchId &&
      request.status === MatchRescheduleRequestStatus.Pending &&
      isMemberOfBothTeams
        ? request.teamId === myTeamId || request.teamId === opposingTeamId
        : request.teamId === myTeamId
    )
    if (matchRescheduleRequest) {
      setStep('read-request')
    } else {
      setStep('update-request')
    }
    setIsRescheduleDialogOpen(true)
  }

  const leagueRulebookId = competitionGroup
    ? CompetitionLeagueRulesMap[competitionGroup]
    : ''
  const menuItems = [
    {
      label: 'RESOURCES ',
      items: [
        {
          label: 'Rulebook',
          id: 'manage-match-rulebook',
          Icon: ResourcesOutlined,
          isExternalLink: true,
          onClick: (): void => showIntercomArticle(esportRulebookArticleId),
        },
        {
          label: 'League Rules',
          id: 'manage-match-league-rules',
          Icon: LeagueOutlined,
          isExternalLink: true,
          onClick: (): void => showIntercomArticle(leagueRulebookId),
        },
        // Currently adding product logic inline versus the render controller as
        // the team's page is not scoped for stadium v1. However, is this will be fast follow up
        // and this logic will be removed. There is no need to create a new property in the render
        // controller when this will be deleted in a follow up task.
        ...(isScholasticProduct
          ? [
              {
                label: 'Scout this Opponent',
                id: 'manage-match-scout',
                Icon: Unhide,
                isExternalLink: true,
                onClick: (): void => {
                  window.open(
                    `/app/team/${opposingTeamId}`,
                    '_blank',
                    'noreferrer'
                  )
                  window?.focus()
                },
              },
            ]
          : []),
        ...(renderMatchLobbyTour
          ? [
              {
                label: 'Match Lobby Tour',
                id: 'manage-match-lobby-tour',
                Icon: Guide,
                isExternalLink: false,
                onClick: async (): Promise<void> => {
                  matchLobbyTourClicked({
                    userId,
                    initiatedTimestamp: new Date().toISOString(),
                    timeStamp: new Date().toISOString(),
                    stepNumber: 0,
                    clickTarget: 'Start Button',
                  })
                  await setTourViewed({
                    variables: { tour: TourEnum.MatchLobby },
                  })
                  setInitiatedTimestamp(new Date().toISOString())
                  matchLobbyTour?.start()
                },
                className: 'shepherd-match-lobby-tour',
              },
            ]
          : []),
      ],
    },
    ...(renderManageMatchSection
      ? [
          {
            label: 'MANAGE MATCH',
            items: [
              ...(showGameCodeItem
                ? [
                    {
                      label: gameCodeCopyButtonLabel,
                      id: 'manage-match-copy-game-code',
                      Icon: Duplicate,
                      onClick: (): void => {
                        enqueueSnackbar(gameCodeCopyToastMessage, {
                          anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                          },
                        })
                        copyGameCode()
                      },
                      disabled: gameCodeCopyButtonDisabled,
                    },
                  ]
                : []),
              ...(showMatchInstructionItem
                ? [
                    {
                      label: 'Match Instructions',
                      id: 'manage-match-match-instructions',
                      Icon: Notes,
                      onClick: (): void =>
                        showIntercomArticle(
                          esportMatchInstructionsArticleId ??
                            IntercomArticleMappings.allArticles
                        ),
                      disabled: !esportMatchInstructionsArticleId,
                    },
                  ]
                : []),
              ...(showRescheduleItem
                ? [
                    {
                      label: 'Reschedule',
                      id: 'manage-match-reschedule',
                      Icon: Reschedule,
                      onClick: (): void => {
                        setIsRescheduleDialogOpen(true)
                      },
                    },
                  ]
                : []),
              ...(showViewRescheduleItem
                ? [
                    {
                      label: 'View Reschedule Request',
                      id: 'manage-match-view-reschedule',
                      Icon: Reschedule,
                      onClick: determinRescheduleStepOnClick,
                    },
                  ]
                : []),
              ...(showForfeitItem
                ? [
                    {
                      label: 'Forfeit',
                      id: 'manage-match-forfeit',
                      Icon: Forfeit,
                      onClick: (): void => setIsForfeitDialogOpen(true),
                    },
                  ]
                : []),
              ...(showDisputeItem
                ? [
                    {
                      label: 'Dispute Match',
                      id: 'manage-match-dispute',
                      Icon: Sendfeedback,

                      onClick: (): void => {
                        if (matchId) {
                          navigate({
                            pathname: generatePath(Path.MatchDispute, {
                              matchId,
                            }),
                          })
                        }
                      },
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ]

  return (
    <Box className={classes.buttonContainer}>
      {!isMobile && (
        <>
          {esportHasGameCode && <GameCodeCopyButton matchId={matchId} />}
          <Box pr={2} />
          <NxButton
            disabled={!esportMatchInstructionsArticleId}
            id="match-instructions"
            label="Match Instructions"
            onClick={(): void =>
              showIntercomArticle(
                esportMatchInstructionsArticleId ??
                  IntercomArticleMappings.allArticles
              )
            }
            variant="secondary"
          />
          <Box pr={2} />
        </>
      )}
      <NxDropdownButton
        buttonOnClick={(): void => {
          if (matchLobbyTour?.isActive()) {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: initiatedTimestamp ?? '',
              timeStamp: new Date().toISOString(),
              stepNumber: 1,
              clickTarget: 'Next Button',
            })
            matchLobbyTour?.next()
          }
        }}
        className="shepherd-manage-match"
        data-cy="manageMatch"
        fullWidth={isMobile}
        id="manage-match"
        label="Manage Match"
        menuItems={menuItems}
        MenuPaperProps={{ className: 'shepherd-manage-match-menu' }}
        variant="primary"
      />
    </Box>
  )
}
