import React from 'react'
import {
  NxTypography,
  NxButton,
  NxSeasonalAwardsTableData,
  NxModal,
} from '@playvs-inc/nexus-components'
import { FormControl, makeStyles, useTheme } from '@material-ui/core'

import { useDeleteSeasonalPlayerAwardMutation } from '@plvs/graphql/generated'

import { useSnackbar } from 'notistack'

import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxRandomAvatar } from '@playvs-inc/nexus-random-avatars'
import { Box } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.ColorTextAlt,
  },
  desc: {
    marginTop: theme.spacing(2),
  },
  infoContainer: {
    border: '1px solid',
    borderColor: theme.palette.BorderMedium,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: 40,
    width: 40,
  },
}))

interface Props {
  onClose(hasChanges?: boolean): void
  open: boolean
  userData?: NxSeasonalAwardsTableData
}

export const DeleteSeasonalAwardModal: React.FC<Props> = ({
  onClose,
  open,
  userData,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const [
    deleteAssignedAward,
    { loading: isDeletingAward },
  ] = useDeleteSeasonalPlayerAwardMutation()

  const awardId = userData?.assignedAward?.id

  const deleteHandler = async (): Promise<void> => {
    deleteAssignedAward({
      variables: {
        input: {
          assignedSeasonalPlayerAwardId: awardId || '',
        },
      },
    })
      .then(() => {
        onClose(true)
        enqueueSnackbar('Successfully deleted award', { variant: 'success' })
      })
      .catch(() => {
        onClose(false)
        enqueueSnackbar('Failed to delete award', { variant: 'error' })
      })
  }
  const user = userData?.player
  const username = user?.name || ''
  return (
    <NxModal
      actions={
        <>
          <NxButton
            label="Cancel"
            onClick={(): void => onClose(false)}
            variant="text"
          />
          <NxButton
            disabled={isDeletingAward}
            label="Delete"
            onClick={deleteHandler}
            variant="primary"
          />
        </>
      }
      onClose={(): void => onClose(false)}
      open={open}
      showTopRightClose
      size="large"
      subtitle={`Are you sure you want to delete this award for ${username}?`}
      title="Delete Award"
    >
      <FormControl
        className={classes.infoContainer}
        fullWidth
        variant="outlined"
      >
        <NxTypography variant="body2">
          {userData?.assignedAward?.awardType.title}
        </NxTypography>
        <Box display="flex">
          {user?.avatarUrl ? (
            <NxUserAvatar
              avatarUrl={user?.avatarUrl}
              hashId={user?.id || ''}
              size={NxAvatarSize.SM}
            />
          ) : (
            <NxRandomAvatar
              hashId={user?.id}
              height={40}
              persona="Firebob"
              width={40}
            />
          )}
          <Box m="auto 0" ml={theme.spacing(0.1)}>
            <NxTypography variant="body3">{user?.name}</NxTypography>
          </Box>
        </Box>
      </FormControl>
    </NxModal>
  )
}
