import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { getMaddenPicks } from '@plvs/utils'
import { GameAssistantSelection } from '@plvs/graphql'
import { Playbook } from '../../components'
import { MatchPreview as GeneralMatchPreview } from '../../general/matchPreview/MatchPreview'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

interface MatchPreviewProps {
  onNext?(): void
}

export const MatchPreview: React.FC<MatchPreviewProps> = ({ onNext }) => {
  const theme = useTheme()
  const { currentStep, homeTeam, awayTeam } = useMatchAssistantContext()

  const {
    team: homeTeamPick,
    offense: homeOffensePick,
    defense: homeDefensePick,
  } = getMaddenPicks({
    options: currentStep?.characterPicks as GameAssistantSelection[],
    teamId: homeTeam?.id,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })

  const {
    team: awayTeamPick,
    offense: awayOffensePick,
    defense: awayDefensePick,
  } = getMaddenPicks({
    options: currentStep?.characterPicks as GameAssistantSelection[],
    teamId: awayTeam?.id,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })

  // check an arbitrary pick to see if there's any playbooks
  const hasPlaybooks = Boolean(homeOffensePick)

  return (
    <GeneralMatchPreview
      awayTeamPick={awayTeamPick}
      homeTeamPick={homeTeamPick}
      onNext={onNext}
    >
      {hasPlaybooks && (
        <>
          <NxTypography variant="overline">Playbooks</NxTypography>

          <Box
            alignItems="center"
            display="flex"
            gridGap={theme.spacing(3)}
            justifyContent="center"
          >
            <Box display="flex" justifyContent="flex-end">
              <Playbook
                src={homeOffensePick?.selectionOption?.assetUri}
                title={homeOffensePick?.selectionOption?.displayName}
              />
              <Playbook
                src={homeDefensePick?.selectionOption?.assetUri}
                title={homeDefensePick?.selectionOption?.displayName}
              />
            </Box>

            {/* To maintain consistent space with player list in GeneralMatchPreview */}
            <Box visibility="hidden">
              <NxTypography variant="h4">vs</NxTypography>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Playbook
                src={awayOffensePick?.selectionOption?.assetUri}
                title={awayOffensePick?.selectionOption?.displayName}
              />
              <Playbook
                src={awayDefensePick?.selectionOption?.assetUri}
                title={awayDefensePick?.selectionOption?.displayName}
              />
            </Box>
          </Box>
        </>
      )}
    </GeneralMatchPreview>
  )
}
