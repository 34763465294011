import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useMyCoachPlayerRolePendingUsersQuery } from '@plvs/graphql/generated/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import UnverifiedPlayerCard from './UnverifiedPlayerCard'
import { MinimalEmailEntry, getSchoolEmail } from './helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    gridColumnGap: `${theme.spacing(2)}px`,
    '&::-webkit-scrollbar': {
      // overrides default scrollbar height of 0 in ssr.tsx
      height: '0.5em !important',
    },
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
}))

const UnverifiedUsers: React.FC = () => {
  const { data } = useMyCoachPlayerRolePendingUsersQuery()
  const users = data?.me?.school?.playerRolePendingUsers ?? []
  const schoolId = data?.me?.school?.id ?? ''

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {users.length ? (
        <>
          {users.map((user) => (
            <Box key={user.id} flex="0 0 auto">
              <UnverifiedPlayerCard
                avatarUrl={user.avatarUrl ?? ''}
                email={getSchoolEmail(
                  schoolId,
                  user.emails as MinimalEmailEntry[] | null
                )}
                id={user.id}
                name={user.name ?? ''}
                schoolId={schoolId}
              />
            </Box>
          ))}
        </>
      ) : (
        <NxTypography className={classes.subtitle} variant="body1">
          You do not have any unverified players.
        </NxTypography>
      )}
    </div>
  )
}

export default UnverifiedUsers
