import React from 'react'
import { useTheme } from '@material-ui/core'

import { Tooltip } from '../../tooltip'

export type MiniBadgeProps = {
  title: React.ReactNode
  children?: React.ReactElement
  backgroundStyle: string
  borderColor?: string
  borderWidth?: string
}

export const MiniBadge: React.FC<MiniBadgeProps> = ({
  title,
  children,
  backgroundStyle,
  borderColor,
  borderWidth = '2px',
}) => {
  const theme = useTheme()

  const border = borderColor ?? theme.palette.ColorBackgroundBase

  return (
    <Tooltip
      arrow
      style={{
        bottom: 0,
        position: 'absolute',
        height: 20,
        right: 0,
        width: 20,
      }}
      title={title}
    >
      <div
        style={{
          alignItems: 'center',
          background: backgroundStyle,
          border: `${borderWidth} solid ${border}`,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}
