import { makeStyles } from '@material-ui/core'
import { Path } from '@plvs/const'
import {
  ResourceType,
  School,
  useGetPublicSchoolQuery,
  useGetScheduleConsultLinkQuery,
} from '@plvs/graphql'
import { assert, getNow, hasEnrolledTeams } from '@plvs/utils'
import SchoolGrowthCard from '@plvs/rally/features/home/dashboard/SchoolGrowthCard'
import {
  NxPlayVsBanner,
  PersonaImageVariant,
  NxTab,
  NxTabs,
} from '@playvs-inc/nexus-components'
import {
  Box,
  PageContentGutter,
  useBreakpointMd,
} from '@plvs/respawn/features/layout'

import { Billing } from '@plvs/rally/features/account/Billing/Billing'

import React, { useEffect, useState } from 'react'

import { Route, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

import { Awards } from '@plvs/rally/features/awards/Awards'

import { ManageCoaches } from '@plvs/rally/features/coach/coaches/ManageCoaches'
import { SchoolProviderAccount } from '@plvs/rally/features/school/SchoolProviderAccount'
import { ApmRoutes, withTransaction } from '@elastic/apm-rum-react'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { AppPage } from '../page'
import { Avatar } from '../../components/avatar'
import { HeroGutter } from '../../components/hero'
import { ITInfoFormWrapper } from '../../features/account/ITInfoFormWrapper'
import SchoolInfoForm from '../../features/account/SchoolInfoForm/SchoolInfoForm'
import { ManagePlayers } from '../../features/coach/players/ManagePlayers'
import { SchoolOverview } from '../../features/school/Overview'

const buttonTabs = [
  {
    label: 'Activity',
    to: '/app/my-school/activity',
  },
  {
    label: 'Players',
    to: '/app/my-school/manage/players',
    className: 'shepherd-players',
  },
  {
    label: 'Coaches',
    to: '/app/my-school/manage/coaches',
  },
  {
    label: 'Awards',
    to: '/app/my-school/manage/awards',
  },
  {
    label: 'Billing',
    to: '/app/my-school/billing',
  },
  {
    label: 'Settings',
    to: '/app/my-school/settings',
  },
]

const useStyles = makeStyles({
  gutterContainer: {
    marginTop: 0,
  },
})

const MySchoolPageInternal: React.FC = () => {
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))
  const { selectedEntityId, loading, setEntity } = useProfileContext()
  const {
    isUserPendingVerificationAtOrg,
    isFacultyAtOrg,
    isCoachAtOrg,
    userId,
  } = useUserIdentityFn()

  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useBreakpointMd()

  const query = new URLSearchParams(location.search)
  const querySchoolId = query.get('schoolId')
  const {
    getResourceImageOfSize,
    updateResources,
    loading: imageLoading,
  } = useResourceImageProvider()
  const classes = useStyles()

  const schoolId = selectedEntityId
  const { data, error } = useGetPublicSchoolQuery({
    variables: {
      id: schoolId,
    },
    skip: !schoolId,
  })

  updateResources({
    resources: [{ id: schoolId, type: ResourceType.Organization }],
  })
  const schoolLogoUrl = getResourceImageOfSize({
    resource: { id: schoolId, type: ResourceType.Organization },
    size: isMobile ? NxAvatarSize.MD : NxAvatarSize.XXL,
  })?.url
  const { data: consultData } = useGetScheduleConsultLinkQuery({
    variables: {
      schoolId,
      userId,
      effectiveAt: visibleTeamsTimeWindow,
    },
    skip: !isFacultyAtOrg || !schoolId,
    errorPolicy: 'ignore',
  })

  // Ensure a school is selected when entering this page.
  useEffect(() => {
    if (!selectedEntityId && querySchoolId) {
      setEntity(ResourceType.Organization, querySchoolId)
    }
  }, [querySchoolId, selectedEntityId])

  // No need to further process non-hook related computed props
  // if pending, loading, or error.
  if (isUserPendingVerificationAtOrg) {
    return <Navigate to={Path.Dashboard} />
  }

  if (!data || error || loading || imageLoading) {
    return <></>
  }

  const school = data?.school
  assert(school)

  const orgLocation = school.city
    ? `${school.city}, ${school.state}`
    : school.state

  const hasEnrolled = hasEnrolledTeams({
    teamEnrollments: consultData?.findTeamEnrollmentsByUser?.teamEnrollments,
  })
  const consultationLink =
    consultData?.school?.salesRep?.calendarLink ?? undefined
  const requiresConsultation =
    isFacultyAtOrg && !hasEnrolled && Boolean(consultationLink)

  const handleTabChange = (_: any, path: string): void => {
    navigate(path)
  }

  const schoolProviderAccounts = (school?.schoolProviderAccounts ?? []).slice()
  const sortedSchoolProviderAccounts = schoolProviderAccounts.sort((a, b) => {
    return (b?.providerName ?? '').localeCompare(a?.providerName ?? '')
  })

  return (
    <AppPage title="My School">
      <HeroGutter mb={4}>
        <NxPlayVsBanner
          additionalContent={
            schoolProviderAccounts.length > 0 ? (
              <Box alignItems="center" display="flex">
                {sortedSchoolProviderAccounts.map((pa) => (
                  <SchoolProviderAccount key={pa.id} providerAccount={pa} />
                ))}
              </Box>
            ) : undefined
          }
          avatar={
            <Avatar
              size={isMobile ? 'medium' : 'xxlarge'}
              src={schoolLogoUrl}
            />
          }
          size="large"
          subtitle={orgLocation ?? ''}
          title={school.name ?? ''}
          variant={PersonaImageVariant.MySchool}
          widget={
            <Box mt={isMobile ? 0 : 2}>
              <SchoolGrowthCard
                consultationLink={consultationLink}
                isFaculty={isFacultyAtOrg}
                requiresConsultation={requiresConsultation}
                school={school as School}
              />
            </Box>
          }
        />
      </HeroGutter>
      {isCoachAtOrg ? (
        <Box
          display="flex"
          flexDirection="row"
          margin={['0 16px 24px', '0 24px 24px']}
        >
          <NxTabs
            onChange={handleTabChange}
            size="large"
            value={location.pathname}
          >
            {buttonTabs.map(({ label, to, className }) => (
              <NxTab key={to} className={className} label={label} value={to} />
            ))}
          </NxTabs>
        </Box>
      ) : null}
      <PageContentGutter className={classes.gutterContainer}>
        <ApmRoutes>
          <Route element={<Navigate to="activity" />} path="/" />

          <Route
            element={
              <SchoolOverview
                hideHero
                isPublicPage={false}
                schoolId={selectedEntityId}
              />
            }
            path="activity"
          />
          {isCoachAtOrg ? (
            <>
              <Route element={<ManagePlayers />} path="manage/players" />
              <Route element={<ManageCoaches />} path="manage/coaches" />
              <Route
                element={
                  <Awards schoolId={schoolId} schoolName={school?.name ?? ''} />
                }
                path="/manage/awards"
              />
              <Route
                element={
                  <Box>
                    <Billing />
                  </Box>
                }
                path="billing"
              />
              <Route
                element={
                  <Box>
                    <SchoolInfoForm />
                    <Box mt={4}>
                      <ITInfoFormWrapper />
                    </Box>
                  </Box>
                }
                path="settings"
              />
            </>
          ) : null}
        </ApmRoutes>
      </PageContentGutter>
    </AppPage>
  )
}

export const MySchoolPage = withTransaction(
  'MySchoolPage',
  'component'
)(MySchoolPageInternal)
