import React from 'react'

export const SmallInfoIcon = (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 13 13"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50012 0C2.91602 0 0.00012207 2.9159 0.00012207 6.5C0.00012207 10.0841 2.91602 13 6.50012 13C10.0842 13 13.0001 10.0841 13.0001 6.5C13.0001 2.9159 10.0842 0 6.50012 0ZM6.50012 11.7C3.63297 11.7 1.30012 9.36715 1.30012 6.5C1.30012 3.63285 3.63297 1.3 6.50012 1.3C9.36727 1.3 11.7001 3.63285 11.7001 6.5C11.7001 9.36715 9.36727 11.7 6.50012 11.7Z"
      fill="#8A939C"
    />
    <path
      d="M5.85022 5.85H7.15022V9.75H5.85022V5.85ZM5.85022 3.25H7.15022V4.55H5.85022V3.25Z"
      fill="#8A939C"
    />
  </svg>
)
