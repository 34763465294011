import React from 'react'

import { ChatRole, useGetMatchChatByUniqueIdQuery } from '@plvs/graphql'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'
import { cleanGraphQLError } from '@plvs/utils'
import { useChatAccessTokenProviderContext } from '@plvs/rally/containers/chat/ChatAccessTokenProvider'
import { getDerivedChatUniqueName } from './utils'

import { MatchChat } from './MatchChat'
import { useMatchChat } from './hooks/useMatchChat'
import { ChatWrapperProps } from './types'

export const CoachChatWrapper: React.FC<ChatWrapperProps> = ({
  matchId,
  teamIds,
  isCheckpoint,
  chatRole = ChatRole.Player,
}) => {
  const { accessToken } = useChatAccessTokenProviderContext()
  // If the conversation is included in the user's subscribed
  // conversations then the client knows the conversation is active.
  const { usersActiveConversationsMap, loading } = useChatConversationsContext()
  const derivedMatchId = getDerivedChatUniqueName({
    matchId: matchId ?? '',
    chatRole,
  })
  const activeConversation = usersActiveConversationsMap[derivedMatchId]
  const isActiveConversation = Boolean(activeConversation)

  const { data, loading: dataLoading, error } = useGetMatchChatByUniqueIdQuery({
    variables: {
      input: {
        uniqueNameOrSid: derivedMatchId ?? '',
      },
    },
    nextFetchPolicy: 'cache-only',
    skip: !derivedMatchId || isActiveConversation || !accessToken,
  })

  const conversationSid = data?.getMatchChatByUniqueId?.chat?.sid
  const isStateClosed =
    data?.getMatchChatByUniqueId?.chat?.state === 'closed' ||
    activeConversation?.conversation?.state?.current === 'closed'

  const coachChat = useMatchChat({
    chatUniqueName: derivedMatchId,
    chatRole,
    matchId,
    shouldAddAdminToChat: isCheckpoint,
    active: true,
    conversationSid,
    isStateClosed,
  })

  return (
    <MatchChat
      {...coachChat}
      chatRole={chatRole}
      errorCode={error && 404}
      isCheckpoint={isCheckpoint}
      isGlobal={false}
      isStateClosed={isStateClosed}
      loadingConversationState={loading || dataLoading}
      matchId={matchId}
      messageError={cleanGraphQLError(error?.message ?? '')}
      teamIds={teamIds}
    />
  )
}
