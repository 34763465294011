import { makeStyles } from '@material-ui/core'

export const useSeasonScheduleStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.boxShadow.elevation1,
    borderRadius: theme.shape.borderRadius * 2.5,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  cardContent: {
    padding: theme.spacing(4),
  },
  borderRight: {
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.15)',
  },
  phaseDates: {
    marginBottom: theme.spacing(2),
  },
  phaseTitle: {
    marginBottom: theme.spacing(0.5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))
