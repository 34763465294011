// wait a certain amount of time before showing step
export const delay = ({ time }: { time: number }) => (): Promise<unknown> => {
  return new Promise<void>(function (resolve) {
    setTimeout(function () {
      resolve()
    }, time)
  })
}

// poll every 50ms for the existence of an HTML element before displaying step
export const pollForItem = ({
  timeout = 10000,
  selector,
}: {
  timeout?: number
  selector: string
}) => (): Promise<unknown> => {
  return new Promise(function (resolve) {
    const startTime = Date.now()

    const tryQuery = (): void => {
      const elem = window.document.querySelector(selector)

      if (elem) {
        resolve(elem)
      } else if (Date.now() - startTime > timeout) {
        resolve(null)
      } else {
        setTimeout(tryQuery, 50)
      }
    }
    tryQuery()
  })
}
