import React, { ReactElement } from 'react'
import {
  CompetitionGroup,
  EsportRating,
  League,
  Maybe,
  Phase,
  Slot,
  SponsorshipImage,
} from '@plvs/graphql/generated'
import { CompetitionGroupMap } from '@plvs/utils'
import dayjs from '@plvs/rally/init/dayjs'
import { EsportSlug } from '@plvs/graphql/index'
import { SponsorshipImageName } from '@plvs/const'
import { HeroAnnouncement } from './exploreHeroAnnouncements'

export type CompetitionGroupDetailsAnnoucement = {
  subText: string
} & HeroAnnouncement

type AnnouncementProps =
  | {
      overwrite?: false
      heroAnnouncement?: HeroAnnouncement
    }
  | {
      overwrite: true
      heroAnnouncement: HeroAnnouncement
    }
export type GetCompetitionGroupDetailsProps = {
  competitionGroup?: CompetitionGroup | null
} & AnnouncementProps

export const getCompetitionGroupDetails = ({
  competitionGroup,
  overwrite = false,
  heroAnnouncement,
}: GetCompetitionGroupDetailsProps): CompetitionGroupDetailsAnnoucement => {
  switch (competitionGroup) {
    case CompetitionGroup.HighSchool:
    case CompetitionGroup.MiddleSchool:
      return overwrite && heroAnnouncement
        ? {
            ...heroAnnouncement,
            subText: 'Coach required',
          }
        : {
            heroTitle: 'Youth League enrollment \nis open',
            img: '',
            heroOverlineTitle: 'Explore',
            subText: 'Coach required',
          }

    case CompetitionGroup.College:
      return overwrite && heroAnnouncement
        ? {
            ...heroAnnouncement,
            subText: 'Conference or National Leagues  ·  Coach optional',
          }
        : {
            heroTitle: 'Youth League enrollment \nis open',
            img: '',
            heroOverlineTitle: 'Explore',
            subText: 'Conference or National Leagues  ·  Coach optional',
          }
    case CompetitionGroup.Youth: {
      return overwrite && heroAnnouncement
        ? {
            ...heroAnnouncement,
            subText: 'Open to anyone aged 13-18  ·  Coach optional',
          }
        : {
            heroTitle: 'Youth League enrollment \nis open',
            img: '',
            heroOverlineTitle: 'Explore',
            subText: 'Open to anyone aged 13-18  ·  Coach optional',
          }
    }
    case CompetitionGroup.Open: {
      return {
        heroTitle: '',
        img: undefined,
        heroOverlineTitle: 'Explore',
        subText: '',
      }
    }
    default:
      return {
        heroTitle: '',
        img: undefined,
        heroOverlineTitle: 'Explore',
        subText: '',
      }
  }
}

export const getSeasonEnrollmentStatus = ({
  registrationStartsAt,
  teamRegistrationEndsAt,
}: {
  registrationStartsAt: string | undefined
  teamRegistrationEndsAt: string | undefined
}): { label: string; variant: 'success' | 'info' | 'disabled' } => {
  const now = dayjs()
  if (dayjs(teamRegistrationEndsAt).isBefore(now)) {
    return { label: 'Enrollment Closed', variant: 'disabled' }
  }
  if (dayjs(registrationStartsAt).isAfter(now)) {
    return { label: 'Enrollment Opening Soon', variant: 'info' }
  }
  return { label: 'Open Enrollment', variant: 'success' }
}

export const getSectionTitle = (competitionGroup: CompetitionGroup): string => {
  return `${CompetitionGroupMap[competitionGroup]} Leagues`
}

type LeagueType =
  | (Pick<League, 'id'> & {
      esport: {
        name: Maybe<string>
      }
    })
  | null

export const sortLeaguesByEsportName = (
  leagueA: LeagueType,
  leagueB: LeagueType
): number => {
  const leagueEsportA = leagueA?.esport?.name ?? ''
  const leagueEsportB = leagueB?.esport?.name ?? ''

  return leagueEsportA.toLowerCase().localeCompare(leagueEsportB.toLowerCase())
}

export const getHeroSubtitle = ({
  competitionGroup,
  rating,
  metaseasonName,
}: {
  competitionGroup: CompetitionGroup
  rating: EsportRating
  metaseasonName: string
}): string => {
  if (rating === EsportRating.Restricted) {
    return metaseasonName
  }

  return `${CompetitionGroupMap[competitionGroup]} · ${metaseasonName}`
}

export const getCurrentPhase = (
  phase: Pick<Phase, 'startsAt' | 'endsAt'>
): boolean => {
  const now = dayjs()
  return now.isBetween(dayjs(phase.startsAt), dayjs(phase.endsAt), '[]')
}

export const getCurrentSlot = (slot: Pick<Slot, 'startsAt'>): boolean => {
  const now = dayjs()

  const startsAt = dayjs(slot?.startsAt)
  const oneWeekAfter = startsAt.add(1, 'week')

  return now.isBetween(startsAt, oneWeekAfter, '[]')
}
const MADDEN_DESC =
  'An American football video game based on the National Football League.'

export const exploreLeaguesHeroSubtitleMap: Record<string, string> = {
  [EsportSlug.LeagueOfLegends]:
    "Two teams of five heroes battle for control of Summoner's Rift.",
  [EsportSlug.RocketLeague]:
    'Two teams of three players control cars for a game of soccer In the arena.',
  [EsportSlug.RocketLeagueGMA]:
    'Two teams of three players control cars for a game of soccer In the arena.',
  [EsportSlug.SmashBrosUltimate]:
    'Fighting game in which players attempt to launch their opponents off stage and out of bounds.',
  [EsportSlug.Overwatch]:
    'An objective-based game, where two teams of five players compete in iconic locations from around the globe.',
  [EsportSlug.Splatoon]:
    'Players control characters known as Inklings and use colored ink to attack opponents and clear goals.',
  [EsportSlug.Hearthstone]:
    'The game is a turn-based card game between two opponents, using constructed decks of 30 cards along with a selected hero with a unique power.',
  [EsportSlug.MarioKart]:
    'Players compete in go-kart races, controlling one of a selection of characters.',
  [EsportSlug.Madden]: MADDEN_DESC,
  [EsportSlug.Madden21PS4]: MADDEN_DESC,
  [EsportSlug.Madden21SolosXboxOne]: MADDEN_DESC,
  [EsportSlug.Madden21XboxOne]: MADDEN_DESC,
  [EsportSlug.Valorant]:
    'Valorant is a character-based 5v5 tactical action game set on the global stage. Players play as one of a set of Agents, characters based on several countries and cultures around the world.',
  [EsportSlug.NBA2K]:
    'An American basketball video game based on the National Basketball Association. ',
}

export const getLeagueSponsorLogos = ({
  images,
  isDarkMode = false,
}: {
  images: Pick<SponsorshipImage, 'name' | 'url'>[]
  isDarkMode?: boolean
}): {
  bannerLogo: ReactElement | undefined
  mobileBannerLogo: ReactElement | undefined
  widgetLogo: ReactElement | undefined
  mobileWidgetLogo: ReactElement | undefined
  rightSideLogo: ReactElement | undefined
  mobileRightSideLogo: ReactElement | undefined
  sectionLogo: ReactElement | undefined
  mobileSectionLogo: ReactElement | undefined
} => {
  const bannerLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeBannerLogo)
      ? image.name === SponsorshipImageName.DarkModeBannerLogo
      : image.name === SponsorshipImageName.BannerLogo
  )
  const mobileBannerLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeMobileBannerLogo)
      ? image.name === SponsorshipImageName.DarkModeMobileBannerLogo
      : image.name === SponsorshipImageName.MobileBannerLogo
  )
  const widgetLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeWidgetLogo)
      ? image.name === SponsorshipImageName.DarkModeWidgetLogo
      : image.name === SponsorshipImageName.WidgetLogo
  )
  const mobileWidgetLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeMobileWidgetLogo)
      ? image.name === SponsorshipImageName.DarkModeMobileWidgetLogo
      : image.name === SponsorshipImageName.MobileWidgetLogo
  )
  const rightSideLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeRightSideLogo)
      ? image.name === SponsorshipImageName.DarkModeRightSideLogo
      : image.name === SponsorshipImageName.RightSideLogo
  )
  const mobileRightSideLogoImage = images.find((image) =>
    isDarkMode &&
    images.some(
      (x) => x.name === SponsorshipImageName.DarkModeMobileRightSideLogo
    )
      ? image.name === SponsorshipImageName.DarkModeMobileRightSideLogo
      : image.name === SponsorshipImageName.MobileRightSideLogo
  )
  const sectionLogoImage = images.find((image) =>
    isDarkMode &&
    images.some((x) => x.name === SponsorshipImageName.DarkModeSectionLogo)
      ? image.name === SponsorshipImageName.DarkModeSectionLogo
      : image.name === SponsorshipImageName.SectionLogo
  )
  const mobileSectionLogoImage = images.find((image) =>
    isDarkMode &&
    images.some(
      (x) => x.name === SponsorshipImageName.DarkModeMobileSectionLogo
    )
      ? image.name === SponsorshipImageName.DarkModeMobileSectionLogo
      : image.name === SponsorshipImageName.MobileSectionLogo
  )
  return {
    bannerLogo:
      bannerLogoImage && bannerLogoImage.url ? (
        <img
          alt="sponsorship-banner-logo"
          data-testid="banner-logo"
          height={80}
          src={bannerLogoImage.url}
          width={80}
        />
      ) : undefined,
    mobileBannerLogo:
      mobileBannerLogoImage && mobileBannerLogoImage.url ? (
        <img
          alt="sponsorship-mobile-banner-logo"
          data-testid="mobile-banner-logo"
          src={mobileBannerLogoImage.url}
          width="100%"
        />
      ) : undefined,
    widgetLogo:
      widgetLogoImage && widgetLogoImage.url ? (
        <img
          alt="sponsorship-widget-logo"
          data-testid="widget-logo"
          src={widgetLogoImage.url}
          style={{ maxHeight: '100px', maxWidth: '200px' }}
        />
      ) : undefined,
    mobileWidgetLogo:
      mobileWidgetLogoImage && mobileWidgetLogoImage.url ? (
        <img
          alt="sponsorship-mobile-widget-logo"
          data-testid="mobile-widget-logo"
          height={40}
          src={mobileWidgetLogoImage.url}
          width={40}
        />
      ) : undefined,
    rightSideLogo:
      rightSideLogoImage && rightSideLogoImage.url ? (
        <img
          alt="sponsorship-right-side-logo"
          data-testid="right-side-logo"
          height={200}
          src={rightSideLogoImage.url}
          width={260}
        />
      ) : undefined,
    mobileRightSideLogo:
      mobileRightSideLogoImage && mobileRightSideLogoImage.url ? (
        <img
          alt="sponsorship-mobile-right-side-logo"
          data-testid="mobile-right-side-logo"
          src={mobileRightSideLogoImage.url}
          style={{ width: '100vw' }}
        />
      ) : undefined,
    sectionLogo:
      sectionLogoImage && sectionLogoImage.url ? (
        <img
          alt="sponsorship-section-logo"
          data-testid="section-logo"
          src={sectionLogoImage.url}
        />
      ) : undefined,
    mobileSectionLogo:
      mobileSectionLogoImage && mobileSectionLogoImage.url ? (
        <img
          alt="sponsorship-mobile-section-logo"
          data-testid="mobile-section-logo"
          src={mobileSectionLogoImage.url}
          width="100%"
        />
      ) : undefined,
  }
}
