import React, { useState } from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import { ObjectSchema } from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { FieldError } from 'react-hook-form'

const useStyles = makeStyles({
  ul: {
    textAlign: 'left',
    listStyleType: 'none',
    paddingLeft: '0.7em',
    fontSize: '0.75rem',
  },
  normal: {
    color: '#535D66',
  },
  invalid: {
    color: '#ff230aff',
  },
  valid: {
    color: '#008a00ff',
  },
  statusIcon: {
    marginBottom: '-4px',
    fontSize: '1rem',
  },
})

export type FormValidationMessages = {
  key: string
  message: string
}

export type InputValidationErrorViewProps = {
  defaultMessages: Array<FormValidationMessages>
  errors: Array<FieldError> | undefined
}

export function extractValidationMessagesFromSchemaForField<
  T extends Record<string, unknown>
>(schema: ObjectSchema<T>, fieldName: keyof T): Array<FormValidationMessages> {
  // We have to type this manually because the correct types do not exist in the package.
  const field = (schema?.fields?.[fieldName] as unknown) as
    | {
        tests: {
          OPTIONS: { name: string; message: string }
        }[]
      }
    | null
    | undefined

  if (field) {
    return field?.tests.map<FormValidationMessages>((test) => {
      return { key: test.OPTIONS.name, message: test.OPTIONS.message }
    })
  }
  throw new Error(`[${String(fieldName)} not found in given schema`)
}

export const InputValidationErrorView = ({
  defaultMessages,
  errors,
}: InputValidationErrorViewProps): React.ReactElement => {
  const errorKeys = new Set(errors?.map(({ type }) => type))
  const [started, setStarted] = useState<boolean>(false)
  const classes = useStyles()
  const getColorStyle = (
    isStarted: boolean,
    key: string,
    errKeys: Set<string>
  ): string => {
    if (!isStarted) return classes.normal
    return errKeys && errKeys.has(key)
      ? `${classes.invalid} invalid`
      : `${classes.valid} valid`
  }
  const getIcon = (
    isStarted: boolean,
    key: string,
    errKeys: Set<string>
  ): JSX.Element => {
    if (!isStarted) return <span />
    return errKeys && errKeys.has(key) ? (
      <ClearIcon className={classes.statusIcon} fontSize="small" />
    ) : (
      <CheckIcon className={classes.statusIcon} fontSize="small" />
    )
  }
  if (!started && errorKeys.size) {
    setStarted(true)
  }
  return (
    <ul className={classes.ul} data-test="validationView">
      {defaultMessages.map((msgObj: FormValidationMessages) => {
        return (
          <li
            key={msgObj.key}
            className={getColorStyle(started, msgObj.key, errorKeys)}
          >
            {getIcon(started, msgObj.key, errorKeys)}
            {msgObj.message}
          </li>
        )
      })}
    </ul>
  )
}
