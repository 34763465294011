import {
  CompetitionGroup,
  UserRole,
  UserRoleName,
  GetMyIdentityQueryVariables,
  GetMyIdentityQuery,
  RoleStatus,
  Maybe,
  Role,
  SponsorshipCampaign,
} from '@plvs/graphql'
import { ApolloQueryResult, makeVar, ReactiveVar } from '@apollo/client'

export type UserIdentityInterface = {
  refetch?: (
    variables?: Partial<GetMyIdentityQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<GetMyIdentityQuery>>
  userId: string
  avatarUrl: string
  orgId: string | undefined
  orgCompetitionGroup: CompetitionGroup | undefined | null
  orgName: string | undefined | null
  isCoachAtOrg: boolean
  isFacultyAtOrg: boolean
  isStudentAtOrg: boolean
  isPlayerAtOrg: boolean
  isCaptainAtOrg: boolean
  isUserPendingVerificationAtOrg: boolean
  isUserDeniedAtOrg: boolean
  isUnderage: boolean
  orgCountry: string | undefined | null
  orgProvince: string | undefined | null
  userRoleNames: UserRoleName[]
  userName: string
  userRoles: Pick<
    UserRole,
    'roleName' | 'resourceType' | 'resourceId' | 'userId' | 'status'
  >[]
  userSchoolRoleStatus:
    | Maybe<
        (Pick<RoleStatus, 'status'> & {
          role: Pick<
            Role,
            'resourceType' | 'resourceId' | 'roleName' | 'userId'
          >
        })[]
      >
    | undefined
  loading: boolean
  error: Error | undefined
  hasMultiOrgAccess: boolean | undefined
  isParent: boolean | undefined
  activeCampaign:
    | Pick<SponsorshipCampaign, 'sponsorshipComponents' | 'sponsorshipImages'>
    | null
    | undefined
  isLoggedIn: boolean
}

export const userIdentityVar: ReactiveVar<UserIdentityInterface> = makeVar<
  UserIdentityInterface
>({
  userId: '',
  orgId: undefined,
  orgCompetitionGroup: null,
  orgCountry: undefined,
  orgProvince: undefined,
  orgName: undefined,
  isCoachAtOrg: false,
  isFacultyAtOrg: false,
  isStudentAtOrg: false,
  isPlayerAtOrg: false,
  isCaptainAtOrg: false,
  isUserPendingVerificationAtOrg: false,
  isUserDeniedAtOrg: false,
  isUnderage: false,
  userRoleNames: [],
  userName: '',
  userRoles: [],
  userSchoolRoleStatus: [],
  refetch: undefined,
  loading: true,
  error: undefined,
  avatarUrl: '',
  hasMultiOrgAccess: false,
  isParent: false,
  activeCampaign: null,
  isLoggedIn: false,
})
