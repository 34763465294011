import React, { useEffect, useState } from 'react'
import {
  NxActionSpotModal,
  NxButton,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { InvitePlayersDarkLargePrimary } from '@playvs-inc/nexus-spots'
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
} from '@material-ui/core'
import { Duplicate } from '@playvs-inc/nexus-icons'
import {
  CompetitionGroup,
  ResourceType,
  UserRoleName,
  useGenerateReferralLinkMutation,
} from '@plvs/graphql/generated'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  eventId: string
  onClose(): void
  open: boolean
  teamId: string
}

export const InvitePlayerModal: React.FC<Props> = ({
  eventId,
  onClose,
  open,
  teamId,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [inviteLink, setInviteLink] = useState<string>('')

  const [generateReferralLink] = useGenerateReferralLinkMutation()

  useEffect(() => {
    const fetchInviteLink = async (): Promise<void> => {
      await generateReferralLink({
        variables: {
          resourceId: teamId,
          resourceType: ResourceType.Team,
          roleName: UserRoleName.Owner,
          source: 'event_team_creation',
          medium: 'share_link',
          competitionGroup: CompetitionGroup.Stadium,
        },
        onCompleted: (data) => {
          setInviteLink(
            `${data.generateReferralLink}&eventId=${eventId}&teamId=${teamId}`
          )
        },
      })
    }

    fetchInviteLink()
  }, [])

  const handleCopy = (): void => {
    copy(inviteLink)
    enqueueSnackbar('Copied', { variant: 'success' })
  }

  return (
    <NxActionSpotModal
      actions={
        <NxButton label="Continue" onClick={onClose} variant="primary" />
      }
      content={
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={320}
        >
          <NxTypography className={classes.text} variant="h4">
            Share invite links
          </NxTypography>
          <FormControl fullWidth>
            <Input
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy}>
                    <Duplicate />
                  </IconButton>
                </InputAdornment>
              }
              value={inviteLink}
            />
          </FormControl>
        </Box>
      }
      onClose={onClose}
      open={open}
      showTopRightClose
      size="small"
      spot={<InvitePlayersDarkLargePrimary height={150} width={150} />}
      subtitle="Ready to play? Add verified players to your team roster."
      title="Invite Teamates"
    />
  )
}
