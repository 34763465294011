import React, { useState } from 'react'

import { Login } from '@plvs/rally/features/login/Login'
import { PublicPage } from './page'
import { ForgotPasswordForm } from '../features/login/ForgotPasswordForm'

interface ForgotPasswordPageProps {
  bannerImageSrc?: string
  // Todo should this be optional?
  Logo?: React.ReactElement
  logoPosition?: 'corner' | 'center'
}

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
  Logo,
  bannerImageSrc,
  logoPosition,
}) => {
  const [wasEmailSent, setWasEmailSent] = useState<boolean>(false)

  const title = wasEmailSent ? 'Check Your Email' : 'Forgot your password?'

  return (
    <PublicPage description="Forgot your password?" title="Forgot password">
      <Login
        bannerImageSrc={bannerImageSrc}
        Logo={Logo}
        logoPosition={logoPosition}
        title={title}
      >
        <ForgotPasswordForm
          setWasEmailSent={setWasEmailSent}
          wasEmailSent={wasEmailSent}
        />
      </Login>
    </PublicPage>
  )
}
