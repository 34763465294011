import { Paper, makeStyles } from '@material-ui/core'
import { flatten, map } from 'ramda'
import React from 'react'

import {
  rejectQuoteUnquoteCancelledMatches,
  sortByMaybeStartsAt,
} from '@plvs/utils'
import { BracketResultsQuery, EsportSlug } from '@plvs/graphql'

import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { Bracket } from './Brackets'
import { computeBracketSlotResults } from './standingsHelpers'

const useStyles = makeStyles(() => ({
  paper: {
    display: 'inline-block',
    width: '100%',
    boxShadow: 'none',
  },
}))

interface VsPlayoffStandingsProps {
  bracketData?: BracketResultsQuery
  slug: EsportSlug | null
  toMatch?(matchId: string): string
}

export const VsPlayoffStandings: React.FC<VsPlayoffStandingsProps> = ({
  bracketData,
  slug,
  toMatch,
}) => {
  const classes = useStyles()

  const phase = bracketData?.phase
  const teams = phase?.teams ?? []
  const esportRating = phase?.esport?.rating
  const slots = sortByMaybeStartsAt(phase?.slots ?? [])
  const matchResults = map(
    (slot) =>
      flatten(
        map(
          (match) => match?.matchResults ?? [],
          rejectQuoteUnquoteCancelledMatches(slot?.matches ?? [])
        )
      ),
    slots
  )

  const bracketSlotResults = computeBracketSlotResults(
    teams,
    matchResults,
    esportRating
  )

  return (
    <Paper className={classes.paper}>
      <PenaltyBox>
        <Bracket
          bracketSlotResults={bracketSlotResults}
          slots={slots}
          slug={slug}
          toMatch={toMatch}
        />
      </PenaltyBox>
    </Paper>
  )
}
