import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { Phase, Slot, Maybe } from '@plvs/graphql'
import { Card, CardContent, Grid } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout/Box'
import { getSlotName, sortByMaybeStartsAt } from '@plvs/utils'
import { useSeasonScheduleStyles } from './seasonSchedule.styles'

interface Props {
  className?: string
  formattedTime: string
  phases: (Pick<
    Phase,
    'id' | 'name' | 'type' | 'status' | 'startsAt' | 'endsAt' | 'format'
  > & {
    slots: Maybe<
      Pick<
        Slot,
        | 'id'
        | 'startsAt'
        | 'name'
        | 'order'
        | 'ordinal'
        | 'label'
        | 'isStartsAtHidden'
      >[]
    >
  })[]
}

const SCHEDULE_DATE_FORMAT = 'MMM DD'

export const SeasonSchedule: React.FC<Props> = ({
  className,
  formattedTime,
  phases,
}) => {
  const classes = useSeasonScheduleStyles()

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.cardContent}>
        <NxTypography className={classes.title} variant="h3">
          Schedule
        </NxTypography>
        <NxTypography data-cy="competitionTime" variant="body3">
          Competition Time: Every {formattedTime}
        </NxTypography>
        {phases.map((phase) => {
          const sortedSlots = sortByMaybeStartsAt(phase?.slots ?? [])

          return (
            <Box key={phase.id} mt={4}>
              <NxTypography
                className={classes.phaseTitle}
                data-cy="phaseName"
                variant="subtitle1"
              >
                {phase.name}
              </NxTypography>
              <NxTypography
                className={classes.phaseDates}
                data-cy="phaseDate"
                variant="h3"
              >
                {dayjs(phase.startsAt).format(SCHEDULE_DATE_FORMAT)} -{' '}
                {dayjs(phase.endsAt).format(SCHEDULE_DATE_FORMAT)}
              </NxTypography>
              <Grid container spacing={2}>
                {sortedSlots.map((slot, index) => (
                  <Grid key={slot.id} item xs={3}>
                    <div
                      className={
                        (index + 1) % 4 !== 0 &&
                        index !== sortedSlots.length - 1
                          ? classes.borderRight
                          : ''
                      }
                    >
                      <NxTypography variant="overline">
                        {getSlotName({
                          phaseFormat: phase?.format,
                          order: index,
                          label: slot?.label,
                          ordinal: index + 1,
                          name: slot?.name,
                          totalSlots: phase?.slots?.length,
                        })}
                      </NxTypography>
                      <NxTypography data-cy="slotStartDate" variant="body2">
                        {!slot.isStartsAtHidden
                          ? dayjs(slot.startsAt).format(SCHEDULE_DATE_FORMAT)
                          : 'TBD'}
                      </NxTypography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )
        })}
      </CardContent>
    </Card>
  )
}
