import { Card, makeStyles } from '@material-ui/core'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'
import DenyPlayerButton from './DenyPlayerButton'
import VerifyPlayerButton from './VerifyPlayerButton'

const useStyles = makeStyles(() => ({
  maxHeight: {
    height: '100%',
  },
}))

interface Props {
  avatarUrl: string
  email: string
  id: string
  name: string
  schoolId: string
}

const UnverifiedPlayerCard = ({
  avatarUrl,
  email,
  id,
  name,
  schoolId,
}: Props): React.ReactElement => {
  const classes = useStyles()
  const playerBox = (
    <Box alignItems="center" display="flex" flexDirection="column">
      <NxUserAvatar avatarUrl={avatarUrl} hashId={id} size={NxAvatarSize.MD} />
      <Box marginTop={24}>
        <NxTypography variant="h3">{name}</NxTypography>
      </Box>
      <NxTypography
        colorToken="ColorTextAlt2"
        data-cy="unverified-player-card-email"
        style={{ marginTop: 8 }}
        variant="body1"
      >
        {email}
      </NxTypography>
    </Box>
  )
  return (
    <Card className={classes.maxHeight} data-cy="unverified-player-card">
      <Box className={classes.maxHeight} padding="40px 32px 32px" width={260}>
        <Box
          alignItems="center"
          className={classes.maxHeight}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {playerBox}
          <Box display="flex" marginTop={24} width="100%">
            <VerifyPlayerButton schoolId={schoolId} userId={id} />
            <DenyPlayerButton schoolId={schoolId} userId={id} />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default UnverifiedPlayerCard
