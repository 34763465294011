import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box, FlexSpacer, useBreakpointXs } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  isMyProfile: boolean
  highlightsSize: number
  UploadVideoButton: React.ReactElement
  PaginationButtons: React.ReactElement
}

export const HighlightTitle: React.FC<Props> = ({
  highlightsSize,
  isMyProfile,
  UploadVideoButton,
  PaginationButtons,
}) => {
  const classes = useStyles()

  const isXs = useBreakpointXs()

  if (highlightsSize === 0) {
    return (
      <NxTypography className={classes.title} variant="h3">
        Player Highlights
      </NxTypography>
    )
  }

  if (isMyProfile && highlightsSize > 0) {
    return (
      <>
        <NxTypography className={classes.title} variant="h3">
          Player Highlights
        </NxTypography>
        <Box alignItems="center" display="flex" mb={3}>
          {UploadVideoButton}
          <FlexSpacer />
          {isXs && highlightsSize > 1 ? (
            PaginationButtons
          ) : (
            <>{highlightsSize > 3 && PaginationButtons}</>
          )}
        </Box>
      </>
    )
  }

  if (!isMyProfile && highlightsSize > 0) {
    return (
      <>
        <Box alignItems="center" display="flex" mb={3} mt={2}>
          <NxTypography variant="h3">Player Highlights</NxTypography>
          <FlexSpacer />
          {isXs && highlightsSize > 1 ? (
            PaginationButtons
          ) : (
            <>{highlightsSize > 3 && PaginationButtons}</>
          )}
        </Box>
      </>
    )
  }

  return <div />
}
