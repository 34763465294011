/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
export const isSafari =
  typeof window !== 'undefined'
    ? /constructor/i.test((window.HTMLElement as unknown) as string) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        !(window as any).safari ||
          (typeof (window as any).safari !== 'undefined' &&
            (window as any).safari.pushNotification)
      )
    : false
