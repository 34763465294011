import React from 'react'

import { FlexSpacer } from '@plvs/respawn/features/layout'
import { User } from '@plvs/graphql'
import { CopyIconButton } from '@plvs/respawn/features/button'

export type UserEmailCopiableProps = Pick<User, 'id' | 'email'>

export const UserEmailCopiable: React.FC<UserEmailCopiableProps> = ({
  email,
}) => (
  <>
    <span
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {email}
    </span>
    <FlexSpacer />
    {email && (
      <CopyIconButton
        textToCopy={email}
        tooltipTextBeforeCopying="Copy email"
      />
    )}
  </>
)
