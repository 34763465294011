import React from 'react'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { Navigate, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface Props {
  userName: string
}

export const SchoolSignupRedirect: React.FC<Props> = ({ userName }) => {
  const flags = useFlags()
  const location = useLocation()

  const isNasefSignUp = flags.nasefUx && location.search.includes('nasef')
  const NASEFQueryParam = isNasefSignUp ? NASEF_QUERY_PARAM : ''

  if (!userName) {
    return <Navigate to={`${Path.About}${NASEFQueryParam}`} />
  }
  return <Navigate to={`${Path.JoinSchool}${NASEFQueryParam}`} />
}
