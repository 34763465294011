import React from 'react'
import { EsportSlug } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { AddPlayerToTeam } from './AddPlayerToTeam'
import { AddPlayerToTeamDTC } from './AddPlayerToTeamDTC'

interface AddPlayerToTeamWrapperProps {
  positionIndex: number | null
  teamId: string
  onMutationSuccess?(returnedTeamId?: string): Promise<void>
  isAssociatedToOrg?: boolean
  userId?: string
  esportSlug: EsportSlug | undefined
  loading?: boolean
  isDialogOpen: boolean
  closeDialog(): void
  metaseasonId: string
  leagueId: string
}

export const AddPlayerToTeamWrapper: React.FC<AddPlayerToTeamWrapperProps> = ({
  positionIndex,
  teamId,
  onMutationSuccess,
  isAssociatedToOrg,
  loading,
  userId,
  esportSlug,
  isDialogOpen,
  closeDialog,
  metaseasonId,
  leagueId,
}): React.ReactElement => {
  const addPlayerDialog =
    esportSlug &&
    (isAssociatedToOrg ? (
      <AddPlayerToTeam
        closeDialog={closeDialog}
        data-testid="AddPlayerToTeamWrapper_AddPlayerToTeamOrg"
        esportSlug={esportSlug}
        isDialogOpen={isDialogOpen}
        leagueId={leagueId}
        metaseasonId={metaseasonId}
        onMutationSuccess={onMutationSuccess}
        positionIndex={positionIndex}
        teamId={teamId}
      />
    ) : (
      <AddPlayerToTeamDTC
        closeDialog={closeDialog}
        data-testid="AddPlayerToTeamWrapper_AddPlayerToTeamDTC"
        isDialogOpen={isDialogOpen}
        metaseasonId={metaseasonId}
        onMutationSuccess={onMutationSuccess}
        positionIndex={positionIndex}
        teamId={teamId}
        userId={userId ?? ''}
      />
    ))

  return <WaitTillLoaded loading={loading}>{addPlayerDialog}</WaitTillLoaded>
}
