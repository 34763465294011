import { yupResolver } from '@hookform/resolvers'
import { makeStyles } from '@material-ui/core'
import { Cookie, Path, LocalStorageKey, NASEF_QUERY_PARAM } from '@plvs/const'
import Cookies from 'universal-cookie'
import {
  useAcceptTosMutation,
  useGetMyAccountDetailsQuery,
  useJoinTeamMutation,
  useSetMyAccountSettingsMutation,
} from '@plvs/graphql'
import * as analytics from '@plvs/respawn/features/analytics'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { Box } from '@plvs/respawn/features/layout'
import { UserNameInput } from '@plvs/rally/components/onboard/types'
import { QuestionnaireCard } from '@plvs/rally/components/questionnaireCard/QuestionnaireCard'
import { OnboardUserNameForm } from '@plvs/rally/containers/onboard/v2/components/OnboardUserNameForm'
import {
  assert,
  cleanGraphQLError,
  safeRemoveCookie,
  yupFirstNameRequired,
  yupLastNameRequired,
} from '@plvs/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from './OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}))

const UserNameDetails = yup.object().shape({
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
})

const cookies = new Cookies()

interface Props {
  isParentSignup?: boolean
}

export const OnboardUserName: React.FC<Props> = ({
  isParentSignup = false,
}) => {
  // State Vars
  const [isNotFilled, setIsNotFilled] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  // Used to fix label overlapping
  const [nameValues, setNameValues] = useState({ firstName: '', lastName: '' })

  // Hooks
  const navigate = useNavigate()
  const classes = useStyles()
  const { assign, data: onboardingContext } = useOnboardingContext()

  // Queries and mutations
  const [
    setMyAccountMutation,
    { loading: isMutating },
  ] = useSetMyAccountSettingsMutation()
  const [
    acceptTosMutation,
    { loading: isAcceptingTos },
  ] = useAcceptTosMutation()
  const [
    associateTeamMutation,
    { loading: isJoiningTeam },
  ] = useJoinTeamMutation()

  const { userId } = useUserIdentityFn()
  const { data: accountData } = useGetMyAccountDetailsQuery({
    fetchPolicy: 'network-only',
  })

  // Computed Props
  const hasCompletedName =
    !!accountData?.me?.firstName && !!accountData.me.lastName

  // Handlers
  const defaultValues = {
    firstName: accountData?.me?.firstName ?? '',
    lastName: accountData?.me?.lastName ?? '',
  }

  const showNASEFUx = onboardingContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''
  const { errors, handleSubmit, register, getValues, reset } = useForm<
    UserNameInput
  >({
    mode: 'onBlur',
    resolver: yupResolver<UserNameInput>(UserNameDetails),
    defaultValues: useMemo(() => {
      return defaultValues
    }, [onboardingContext]),
  })

  // Side effects
  useEffect(() => {
    // ID-905 we like to redirect user back to registration if they forcibly
    // try to reenter this route after their information has been submitted.
    if (hasCompletedName) {
      navigate(`${Path.Registration}${showNASEFUx}`, { replace: true })
      return
    }

    // Notify analytics upon user entering this page.
    analytics.userEnteredNameAndDateOfBirth({
      userId: userId ?? '',
    })

    // Only runs on component init.
  }, [hasCompletedName])

  useEffect(() => {
    reset(defaultValues)
    setNameValues({
      firstName: (onboardingContext?.firstName as string) ?? '',
      lastName: (onboardingContext?.lastName as string) ?? '',
    })
  }, [onboardingContext])

  // Form handlers
  const onFormUpdate = (): void => {
    const values = getValues()
    setNameValues({
      firstName: values.firstName,
      lastName: values.lastName,
    })
    const currentIsNotFilled = !values.firstName || !values.lastName

    if (currentIsNotFilled !== isNotFilled) {
      setIsNotFilled(currentIsNotFilled)
    }
  }

  const submitUserAccountDetails = handleSubmit(
    async (input: UserNameInput): Promise<void> => {
      try {
        let mutationError: Error | undefined
        try {
          await setMyAccountMutation({
            variables: {
              input: {
                firstName: input.firstName,
                lastName: input.lastName,
              },
            },
          })
        } catch (e: any) {
          if (
            e?.message !==
            "Unfortunately we're unable to complete your account update."
          ) {
            throw e
          }
          mutationError = new Error(e.message)
        }

        // Associate invited team if there are any.
        const teamInviteDataRaw = localStorage?.getItem(
          LocalStorageKey.InvitedTeamData
        )
        if (teamInviteDataRaw) {
          // Note: parsing should be reverse of how it was encoded in
          // packages/rally/src/containers/youthInvite/YouthInvite.tsx
          const teamInviteData = JSON.parse(teamInviteDataRaw)
          assert(teamInviteDataRaw)
          assign({ invitedTeamId: teamInviteData.resourceId })

          await associateTeamMutation({
            variables: {
              teamId: teamInviteData.resourceId,
              options: {
                roleInviteCode: teamInviteData.code,
              },
            },
          })

          localStorage.removeItem(LocalStorageKey.InvitedTeamData)
        }

        if (mutationError) {
          safeRemoveCookie(cookies.remove.bind(cookies), Cookie.Token)
          navigate(`${Path.Forbidden}${showNASEFUx}`, {
            state: {
              message:
                "Unfortunately we're unable to complete your account signup process at this time",
              subtext:
                'Please contact support at support@playvs.com if you have any questions.',
              title: 'PlayVS',
            },
          })
          return
        }

        await acceptTosMutation()

        assign({ ...input })

        if (isParentSignup) {
          navigate(
            `${isParentSignup ? '/parent' : ''}${
              Path.SpawnPoint
            }/parent-verification`
          )
        } else {
          navigate(`${Path.SpawnPoint}/tell-us-more${showNASEFUx}`)
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(e)
        } else {
          throw e
        }
      }
    }
  )

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  return (
    <form
      className={classes.form}
      noValidate
      onChange={onFormUpdate}
      onSubmit={submitUserAccountDetails}
    >
      <QuestionnaireCard
        buttonOpacity={isNotFilled}
        childWidth={416}
        disableContinue={isMutating || isAcceptingTos || isJoiningTeam}
        isContinuing={isMutating || isAcceptingTos || isJoiningTeam}
        onContinue={submitUserAccountDetails}
        subtitle="Tell us a bit more about yourself to set up your account."
        title="Hi there!"
      >
        {errorMessage && (
          <Box maxWidth={400} pb={3} width="100%">
            <Banner
              subtitle={errorMessage}
              title="Unable to update your account"
              type={BannerType.Error}
            />
          </Box>
        )}

        <OnboardUserNameForm
          errors={errors}
          register={register}
          values={nameValues}
        />
      </QuestionnaireCard>
    </form>
  )
}
