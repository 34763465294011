import { WatchLater } from '@material-ui/icons'
import { isString } from 'ramda-adjunct'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useTheme } from '@material-ui/core'
import { useCountdown } from './UseCountdown'

export const CountDown: React.FC<{
  after: React.ReactNode
  date: string | null | undefined
  mobile?: boolean
  refetchMatchStatus(): Promise<void>
}> = ({ after, date, mobile, refetchMatchStatus }) => {
  const { ended, formattedTimeLeft } = useCountdown({
    date,
    refetchMatchStatus,
  })
  const theme = useTheme()

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      {!ended && (
        <>
          <WatchLater htmlColor={theme.palette.grey[400]} />
          <NxTypography
            colorToken="OverlayColorTextBase"
            data-testid="formattedTimeLeft"
            style={{ paddingLeft: mobile ? 8 : 12 }}
            variant="body1"
          >
            {formattedTimeLeft}
          </NxTypography>
        </>
      )}
      {ended && after && (
        <>
          {isString(after) ? (
            <NxTypography
              colorToken="OverlayColorTextBase"
              data-testid="endedDisplay"
              variant="body2"
            >
              {after}
            </NxTypography>
          ) : (
            after
          )}
        </>
      )}
    </Box>
  )
}
