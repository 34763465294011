import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NxBreadcrumbs } from '@playvs-inc/nexus-components'
import { ParentBanner } from '@plvs/rally/features/home/parentDashboard/ParentBanner'
import { PageContentGutter } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { ChildEditInfoForm } from '@plvs/rally/features/account/childSettings/ChildEditInfoForm'
import { AppPage } from '../page'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
  settingsContainer: {
    margin: theme.spacing(3, 2, 0),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 0),
    },
  },
}))

export const ChildEditInfoPage: React.FC = () => {
  const params = useParams<{ userId: string }>()
  const navigate = useNavigate()
  const { userId } = params
  const classes = useStyles()

  return (
    <AppPage title="Edit Child Information">
      <ParentBanner />
      <PageContentGutter>
        <Box className={classes.settingsContainer} maxWidth={686} mx={1}>
          <NxBreadcrumbs
            breadcrumbs={[
              {
                label: 'My Family Group',
                onClick: (): void =>
                  navigate(Path.ParentDashboard, {
                    state: { token: undefined },
                    replace: true,
                  }),
              },
              {
                label: 'Settings for Family Group Member',
                onClick: (): void =>
                  navigate(`${Path.App}/child/${userId}`, {
                    state: { token: undefined },
                    replace: true,
                  }),
              },
              {
                label: 'Edit Child Information',
                onClick: (): void => {},
              },
            ]}
            className={classes.breadcrumbs}
          />

          <ChildEditInfoForm userId={userId ?? ''} />
        </Box>
      </PageContentGutter>
    </AppPage>
  )
}
