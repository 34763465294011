import React, { useState } from 'react'
import {
  CompetitionGroup,
  useEsportsByCompetitionGroupQuery,
  useSetUserEsportInterestsMutation,
} from '@plvs/graphql/generated'
import {
  NxButton,
  NxEsportMultiSelectable,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'
import { EsportsLoading } from '@plvs/rally/features/createTeam/esports/Esports.loading'
import { useOnboardingContext } from '@plvs/rally/containers/onboard/v2/OnboardingContext'
import { getOnboardEsportSlugs } from '@plvs/utils'
import { ChildOnboardPath } from './childOnboardConst'
import { getNextPath } from './helpers'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(6),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  esports: {
    justifyContent: 'flex-start',
    maxWidth: 750,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft: 'unset',
    },
  },
  button: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    maxWidth: 390,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 48px)',
    },
  },
  skipButton: {
    maxWidth: 390,
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 48px)',
    },
  },
}))

export const OnboardChildEsports: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams<{ childId: string | undefined }>()

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])

  const { data, loading } = useEsportsByCompetitionGroupQuery({
    variables: {
      competitionGroup: CompetitionGroup.MiddleSchool,
    },
  })

  const { data: onboardData } = useOnboardingContext()

  const [
    setUserEsportInterests,
    { loading: isMutating },
  ] = useSetUserEsportInterestsMutation()

  const esports = data?.esportsByCompetitionGroup ?? []

  const slugs = getOnboardEsportSlugs(data?.esportsByCompetitionGroup ?? [])

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    if (!selectedIndexes.length) {
      navigate(
        getNextPath({
          childId: params.childId,
          path: ChildOnboardPath.ChildSchool,
        })
      )
      return
    }

    const childId = onboardData.childId || params.childId

    const selectedEsports = selectedIndexes.map((index) => esports[index])
    const selectedEsportIds = selectedEsports.map((esport) => esport?.id)

    await setUserEsportInterests({
      variables: {
        input: {
          esportIds: selectedEsportIds,
          userId: childId as string,
        },
      },
    })

    navigate(
      getNextPath({
        childId: params.childId,
        path: ChildOnboardPath.ChildSchool,
      })
    )
  }

  const handleSkip = (): void => {
    navigate(
      getNextPath({
        childId: params.childId,
        path: ChildOnboardPath.ChildSchool,
      })
    )
  }

  return (
    <Container maxWidth="md">
      <form className={classes.form} onSubmit={handleSubmit}>
        <NxTypography className={classes.title} variant="display3">
          What esports are they interested in?
        </NxTypography>
        {loading ? (
          <EsportsLoading />
        ) : (
          <>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <NxEsportMultiSelectable
                className={classes.esports}
                esports={slugs}
                onChange={setSelectedIndexes}
                values={selectedIndexes}
              />
              <NxButton
                className={classes.button}
                data-testid="continue"
                disabled={isMutating}
                label="Continue"
                type="submit"
                variant="primary"
              />
              <NxButton
                className={classes.skipButton}
                data-testid="skip"
                label="Skip"
                onClick={handleSkip}
                type="button"
                variant="text"
              />
            </Box>
          </>
        )}
      </form>
    </Container>
  )
}
