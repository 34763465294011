import { head } from 'ramda'
import React from 'react'

import { Param } from '@plvs/const'
import { EsportSlug } from '@plvs/graphql'
import { FilterButton } from './FilterButton'
import { Option } from './filterHelpers'
import { useOption } from './useOption'

export type FilterEsportSlug = { id: EsportSlug }

const emptyOption: Option<FilterEsportSlug> = {
  id: 'default',
  name: 'Esport',
  values: [],
}

type EsportSlugsAndNames = {
  slug: EsportSlug
  name: string
}

const getEsportSlugOptions: (
  esportData: EsportSlugsAndNames[]
) => Option<FilterEsportSlug>[] = (esportData) =>
  esportData.map((esport) => ({
    id: esport.slug,
    name: esport.name,
    values: [{ id: esport.slug }],
  }))

export function useEsportSlug({
  esportSlugs,
  allEsportSlugsAndNames,
  showEmptyOption,
  useQueryParam,
}: {
  esportSlugs?: EsportSlug[]
  allEsportSlugsAndNames: EsportSlugsAndNames[]
  showEmptyOption?: boolean
  useQueryParam?: boolean
}): {
  selectedEsportSlug?: EsportSlug
  esportSlugFilter: React.ReactElement
} {
  const filteredEsportData = allEsportSlugsAndNames.filter(
    (esport) => esportSlugs?.includes(esport.slug) ?? false
  )
  const allOptions = [
    ...(showEmptyOption ? [emptyOption] : []),
    ...(esportSlugs
      ? getEsportSlugOptions(filteredEsportData)
      : getEsportSlugOptions(allEsportSlugsAndNames)),
  ]

  const { option, options, setOption } = useOption({
    allOptions,
    defaultOption: allOptions[0],
    queryParam: useQueryParam ? Param.Esport : undefined,
  })

  return {
    selectedEsportSlug: head(option.values)?.id,
    esportSlugFilter: (
      <FilterButton<Option<FilterEsportSlug>>
        id="esport-slug-filter"
        option={option}
        options={options}
        setOption={setOption}
      />
    ),
  }
}
