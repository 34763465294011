import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { GameAssistantStepType } from '@plvs/graphql'

export const useStyles = makeStyles((theme) => ({
  mapOption: {
    border: `2px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.mixins.cornerRadius['border-radius-2'],

    '&:hover': {
      borderColor: theme.palette.BorderDark,
    },
  },

  checkbox: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  icon: {
    borderRadius: theme.mixins.cornerRadius['border-radius-4'],
    width: theme.spacing(2),
    height: theme.spacing(2),
    border: `2px solid ${theme.palette.BorderMedium}`,
    padding: 0,

    'input:hover ~ &': {
      borderColor: theme.palette.BorderDark,
    },

    '& ~ input': {
      padding: 0,
    },
  },

  checkedIcon: {
    borderRadius: theme.mixins.cornerRadius['border-radius-4'],
    width: theme.spacing(2),
    height: theme.spacing(2),
    border: `2px solid ${theme.palette.BorderDark}`,
    background: theme.palette.BorderDark,
    color: theme.palette.ColorTextInvert,

    '&> svg': {
      position: 'absolute',
      top: '-10%',
      left: '-10%',
    },
  },

  selectedMapOption: ({
    stepType,
  }: {
    stepType?: GameAssistantStepType
  }): CreateCSSProperties => ({
    borderColor:
      stepType === GameAssistantStepType.Pick
        ? theme.palette.ColorTextSuccess
        : theme.palette.ColorIconError,
    background:
      stepType === GameAssistantStepType.Ban
        ? theme.palette.ColorBackgroundError
        : theme.palette.ColorBackgroundSuccess,
  }),

  disabledMapOption: {
    border: theme.palette.BorderMedium,

    '&:hover': {
      border: theme.palette.BorderMedium,
    },
  },
}))
