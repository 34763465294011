import dayjs from 'dayjs'
import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'
import { NotificationSpotWrapper } from '../../NotificationSpotWrapper'

export const CoachDeniedAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Coach Denied',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SystemMessage
        helpAction={
          notification.contentData.invoke === 'help'
            ? (): void => openIntercom()
            : undefined
        }
        IconComponent={<NotificationSpotWrapper />}
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        receivedAt={dayjs(notification.createdAt).toDate()}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
