import React, { useState } from 'react'
import { useSnackbar } from 'notistack'

import { NxButton, NxIconButton, NxModal } from '@playvs-inc/nexus-components'
import { Trashcan } from '@playvs-inc/nexus-icons'

import { TeamRivalFragment, useRemoveTeamRivalMutation } from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useRosterContext } from '../../RosterProvider'

interface RosterRivalsRemoveButtonProps {
  rival: TeamRivalFragment
  onComplete?(): void
}

export const RosterRivalsRemoveButton: React.FC<
  RosterRivalsRemoveButtonProps
> = ({ rival, onComplete }) => {
  const { teamId } = useRosterContext()

  const { enqueueSnackbar } = useSnackbar()
  const [removeTeamRival, { loading: loadingRemoveTeamRival }] =
    useRemoveTeamRivalMutation()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (): void => {
    setIsOpen(true)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleRemove = async (): Promise<void> => {
    try {
      await removeTeamRival({
        variables: {
          input: {
            teamRivalId: rival.id,
            teamId,
          },
        },
      })
      onComplete?.()
      enqueueSnackbar('Rival removed', { variant: 'success' })
      setIsOpen(false)
    } catch (err) {
      enqueueSnackbar(
        'There was an issue removing this rival. Try again or reach out to Customer Experience.',
        {
          variant: 'error',
        }
      )
    }
  }

  return (
    <>
      <NxIconButton
        icon={<Trashcan />}
        label=""
        onClick={handleOpen}
        variant="text"
      />

      {isOpen && (
        <NxModal
          actions={
            <>
              <NxButton label="Cancel" onClick={handleClose} variant="text" />

              <NxButton
                label="Yes, Remove"
                loading={loadingRemoveTeamRival}
                onClick={handleRemove}
                variant="primary"
              />
            </>
          }
          onClose={handleClose}
          open={isOpen}
          showTopRightClose
          subtitle={`Are you sure you want to remove ${rival.requestingTeam?.name}?`}
          title="Remove Rival?"
        >
          <WaitTillLoaded
            loading={loadingRemoveTeamRival}
            loadingSpinnerProps={{ size: 'medium' }}
            showSpinnerWhileLoading
          />
        </NxModal>
      )}
    </>
  )
}
