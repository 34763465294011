import {
  RawActionAndInfoMatch,
  ActionAndInfoMatch,
} from './ActionAndInfoSection.types'

export function normalizeActionAndInfoMatch(
  match: RawActionAndInfoMatch
): ActionAndInfoMatch {
  return {
    id: match?.id ?? '',
    status: match?.status ?? null,
    esportSlug: match?.esport?.slug ?? null,
    matchRescheduleRequests: (match?.matchRescheduleRequests ?? []).map(
      (request) => ({
        id: request.id,
        status: request.status,
        teamId: request.teamId,
        proposedTimes: request.proposedTimes ?? [],
      })
    ),
    phaseType: match?.slot?.phase?.type ?? null,
    scheduledStartsAt: match?.scheduledStartsAt ?? '',
    isScrimmage: match?.isScrimmage || false,
    team1: {
      id: match?.team1?.id ?? '',
      name: match?.team1?.name ?? '',
    },
    team2: {
      id: match?.team2?.id ?? '',
      name: match?.team2?.name ?? '',
    },
    bestOf: match?.bestOf ?? 0,
  }
}
