import { makeStyles } from '@material-ui/core'
import { Colors } from '@plvs/rally/themes/colors'

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: 230,
    position: 'relative',
    padding: '6px 12px',
  },
  pendingMenuItem: {
    cursor: 'unset',
  },
  defaultMenuItem: {
    width: 230,
    height: 54,
    position: 'relative',
    padding: '6px 12px',
  },
  icon: {
    width: 32,
    height: 32,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 8,
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'none',
  },
  textContainer: {
    width: 160,
  },
  greenCheckContainer: {
    position: 'absolute',
    right: 12,
  },
  greenCheck: {
    color: theme.palette.ColorTextSuccess,
    background: theme.palette.common.white,
    borderRadius: '32px',
    height: 16,
    width: 16,
    zIndex: 1,
  },
  blueAdd: {
    color: Colors.Sapphire,
    borderRadius: '50%',
    height: 32,
    width: 32,
    zIndex: 1,
  },
  subText: {
    color: theme.palette.ColorTextAlt2,
  },
}))
