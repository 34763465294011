import React from 'react'
import { createSvgIcon } from '../createSvgIcon'

export const ScrimmageReady = createSvgIcon(
  <>
    <circle cx="12" cy="12" fill="#F78823" r="12" />
    <path
      d="M11.5866 16.2207L10.7172 17.0901L7.00712 13.3797L7.87671 12.5101L11.5866 16.2207Z"
      fill="white"
    />
    <path
      d="M9.51466 13.738L6.29615 16.9556L7.12135 17.781L10.3399 14.5634L9.51466 13.738Z"
      fill="white"
    />
    <path
      d="M14.3317 11.664L17.3098 8.68633L18 6.09882L15.4809 6.85696L12.5026 9.83497L14.3317 11.664Z"
      fill="white"
    />
    <path
      d="M10.6371 15.3588L11.6124 14.3836L9.78333 12.5545L8.80808 13.5298L10.6371 15.3588Z"
      fill="white"
    />
    <path
      d="M12.4134 16.1217L13.2828 16.9913L16.9929 13.2814L16.1231 12.4114L12.4134 16.1217Z"
      fill="white"
    />
    <path
      d="M13.6603 14.4653L16.8782 17.6832L17.7036 16.8577L14.4858 13.6399L13.6603 14.4653Z"
      fill="white"
    />
    <path
      d="M8.51923 6.75814L6 6L6.69001 8.58735L13.3621 15.2598L15.1917 13.4306L8.51923 6.75814Z"
      fill="white"
    />
  </>,
  'ScrimmageReadySvg',
  { viewBox: '0 0 24 24' }
)
