import React, { useEffect, useState } from 'react'
import { Box, Checkbox, useTheme } from '@material-ui/core'
import clsx from 'clsx'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
  NxTooltip,
} from '@playvs-inc/nexus-components'

import { Smallcheck, Smallclose, Lock } from '@playvs-inc/nexus-icons'

import {
  useGetMapSelectionOptionsByEsportIdQuery,
  GameAssistantStepType,
  useSubmitGameAssistantStepSelectionsMutation,
  GameAssistantSelectionOptionFragment,
} from '@plvs/graphql'
import { sortByDisplayName } from '@plvs/utils'
import { logger } from '@plvs/rally/logging'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useStyles } from './StageSelect.styles'
import { getFirebobMessage, getButtonLabel } from './StageSelect.helpers'

export const StageSelect: React.FC = () => {
  const {
    gameOrdinal,
    isMyTurn,
    mapSelections: selections,
    currentStep,
    match,
    teamId,
    actionsTakenInSteps,
    setActionsTakenInSteps,
  } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const { type: stepType, totalSelections = 1, id: stepId = '' } =
    currentStep?.step ?? {}
  const showFreeStrikes = stepType === GameAssistantStepType.Ban && isMyTurn
  const freeStrikes = showFreeStrikes
    ? currentStep?.freeStrikeMaps?.filter(
        (map) => map.gameAssistantStep?.gameOrdinal
      ) || []
    : []

  const styles = useStyles({ stepType })
  const theme = useTheme()

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const { data: mapData } = useGetMapSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id,
  })

  const [
    submitGameAssistantStep,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions = mapData?.mapSelectionOptionsByEsportId?.selectionOptions.find(
    (option) => option.gameOrdinal === gameOrdinal
  )
  const sortedSelectionOptions = sortByDisplayName(
    selectionOptions?.selectionOptions as GameAssistantSelectionOptionFragment[]
  )

  const [selectedStageIds, setSelectedStageIds] = useState<string[]>([])
  const [selectedFreeStrikeIds, setSelectedFreeStrikeIds] = useState<string[]>(
    []
  )

  const isValid = selectedStageIds.length === totalSelections
  const isWaitingForOpponent = (canParticipate && !isMyTurn) || hasSubmitted
  const isNextDisabled = !canParticipate || !isValid || isWaitingForOpponent
  const isSelectDisabled =
    !canParticipate || updateLoading || !isMyTurn || hasSubmitted

  useEffect(() => {
    setSelectedStageIds([])
    setSelectedFreeStrikeIds([])
    setHasSubmitted(false)
  }, [currentStep?.step?.id])

  const handleChange = (id: string, checked: boolean): void => {
    logger.debug('[StageSelect | handleChange]', { id, checked })

    const isFreeStrike =
      showFreeStrikes &&
      Boolean(
        freeStrikes.find(({ selectionOption }) => selectionOption?.id === id)
      )

    if (checked) {
      if (isFreeStrike) {
        setSelectedFreeStrikeIds([...selectedFreeStrikeIds, id])
      } else if (selectedStageIds.length < totalSelections) {
        setSelectedStageIds([...selectedStageIds, id])
      } else {
        // Drop the oldest selection and replace it with the new one
        const newSelectedStageIds = [...selectedStageIds].slice(1)
        setSelectedStageIds([...newSelectedStageIds, id])
      }
    } else if (isFreeStrike) {
      setSelectedFreeStrikeIds(
        selectedFreeStrikeIds.filter((stageId) => stageId !== id)
      )
    } else {
      setSelectedStageIds(selectedStageIds.filter((stageId) => stageId !== id))
    }
  }

  const handleNext = async (): Promise<void> => {
    if (isNextDisabled) {
      return
    }

    logger.debug(
      '[StageSelect | handleNext]',
      selectedStageIds,
      selectedFreeStrikeIds
    )

    setActionsTakenInSteps({
      ...actionsTakenInSteps,
      [stepId]: new Date().toISOString(),
    })

    try {
      await submitGameAssistantStep({
        variables: {
          input: {
            stepId,
            selectionOptions: selectedStageIds
              .concat(selectedFreeStrikeIds)
              .map((id) => ({ selectionOptionId: id })),
          },
        },
      })
      setHasSubmitted(true)
    } catch (err) {
      logger.error(err)
    }
  }

  const firebobMessage = getFirebobMessage({
    canParticipate,
    isMyTurn,
    hasSubmitted,
    stepType,
    totalSelections,
    gameOrdinal,
  })

  const buttonLabel = getButtonLabel({
    stepType,
    selectedStageIds,
    sortedSelectionOptions,
  })

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
        mb={3}
      >
        {sortedSelectionOptions.map(({ id, displayName }) => {
          const isSelected = Boolean(
            selections.find(
              (selection) =>
                selection.selectionOption?.id === id &&
                selection.gameAssistantStep?.gameOrdinal === gameOrdinal
            )
          )
          const isChecked = [
            ...selectedStageIds,
            ...selectedFreeStrikeIds,
          ].find((stageId) => stageId === id)
          const isFreeStrike =
            showFreeStrikes &&
            Boolean(
              freeStrikes.find(
                ({ selectionOption }) => selectionOption?.id === id
              )
            )
          const tooltipTitle =
            stepType === GameAssistantStepType.Ban ? 'Strike' : 'Pick'
          const checkedIcon =
            stepType === GameAssistantStepType.Pick ? (
              <Smallcheck
                color={theme.palette.ColorIconInvert}
                height={20}
                width={20}
              />
            ) : (
              <Smallclose
                color={theme.palette.ColorIconInvert}
                height={20}
                width={20}
              />
            )

          return (
            <Box
              key={`${teamId}-${id}`}
              alignItems="center"
              className={clsx(
                styles.mapOption,
                isChecked && styles.selectedMapOption,
                isSelected && styles.disabledMapOption
              )}
              component="label"
              display="flex"
              gridGap={theme.spacing(1)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore This is a valid label prop
              htmlFor={`${teamId}-${id}`}
              px={3}
              py={2}
            >
              {!isSelected ? (
                <NxTooltip arrow placement="top" title={tooltipTitle}>
                  <Checkbox
                    checked={Boolean(isChecked && styles.selectedMapOption)}
                    checkedIcon={
                      <Box className={styles.checkedIcon}>{checkedIcon}</Box>
                    }
                    className={styles.checkbox}
                    disabled={isSelectDisabled || isSelected}
                    icon={<span className={styles.icon} />}
                    id={`${teamId}-${id}`}
                    onChange={(_event, checked): void =>
                      handleChange(id, checked)
                    }
                  />
                </NxTooltip>
              ) : (
                <NxTooltip arrow placement="top" title="This stage is banned">
                  <span>
                    <Lock
                      color={theme.palette.ColorIconDisabled}
                      height={20}
                      width={20}
                    />
                  </span>
                </NxTooltip>
              )}

              <NxTypography
                colorToken={isSelected ? 'ColorTextDisabled' : 'ColorTextAlt'}
                variant="body2"
              >
                {displayName}
              </NxTypography>

              {isFreeStrike && (
                <Box
                  alignItems="flex-end"
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  textAlign="right"
                >
                  <NxTypography variant="body3">Free Strike</NxTypography>
                  <NxTypography variant="body3">
                    (Previous Opponent Win)
                  </NxTypography>
                </Box>
              )}
            </Box>
          )
        })}
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {canParticipate && (
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label={buttonLabel}
            loading={isWaitingForOpponent || updateLoading}
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </>
  )
}
