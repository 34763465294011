import React from 'react'
import { useParams } from 'react-router-dom'

import { MatchDisputeViewContainer } from '@plvs/rally/features/match'
import { AppPage } from '../../page'

export const MatchDisputeViewPage: React.FC = () => {
  const params = useParams<{ matchId: string }>()

  return (
    <AppPage title="View Dispute">
      <MatchDisputeViewContainer matchId={params.matchId!} />
    </AppPage>
  )
}
