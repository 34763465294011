import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import {
  EnrollmentRangeData,
  SeasonEnrollmentRangeData,
  OptionalWeeksCache,
} from '@plvs/respawn/containers/enrollment/types'
import React, { useEffect } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { OptionalWeekContent } from './OptionalWeeksContent'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    verticalAlign: 'top',
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: '8px',
    padding: theme.spacing(3),
    width: '100%',
    background: theme.palette.ColorBackgroundBase,
  },
  title: {
    color: theme.palette.ColorTextBase,
  },
  subtitle: {
    color: theme.palette.ColorTextBase,
    padding: theme.spacing(1.5, 0, 0),
  },
}))

export interface OptionalWeeksCardProps {
  optionalWeeksCache: OptionalWeeksCache
  rangeData: EnrollmentRangeData
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  setOptionalSeasonWeeksCache(input: OptionalWeeksCache): void
  optionalSeasonWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
}

export const OptionalWeeksCard: React.FC<OptionalWeeksCardProps> = ({
  optionalWeeksCache,
  rangeData,
  setOptionalWeeksCache,
  seasonSlotExclusionRangeData,
  optionalSeasonWeeksCache,
  setOptionalSeasonWeeksCache,
}) => {
  const classes = useStyles()
  const [show, setShow] = React.useState(
    seasonSlotExclusionRangeData.length > 0
  )

  useEffect(() => {
    setShow(seasonSlotExclusionRangeData.length > 0)
  }, [seasonSlotExclusionRangeData.length])

  return (
    <Box
      alignItems="flex-start"
      className={classes.container}
      display="flex"
      flexDirection="column"
    >
      <NxTypography className={classes.title} variant="h3">
        Select Your School‘s Break Week
      </NxTypography>
      <NxTypography className={classes.subtitle} variant="body3">
        Schools are able to designate a week during their regular season
        schedule as a break to avoid unnecessary forfeitures and reschedules.
        This will apply to all teams within the school.
      </NxTypography>
      {rangeData.map((data) => {
        const { configuration, range, resourceType, resourceId } = data
        return (
          <OptionalWeekContent
            key={range}
            configuration={configuration}
            optionalWeeksCache={optionalWeeksCache}
            range={range}
            resourceId={resourceId}
            resourceType={resourceType}
            setOptionalWeeksCache={setOptionalWeeksCache}
          />
        )
      })}
      {show ? (
        <Box>
          <NxTypography className={classes.title} variant="h3">
            Leagues With Custom Break Weeks
          </NxTypography>
          <NxTypography className={classes.subtitle} variant="body3">
            Below are the leagues with custom break weeks. Each league&apos;s
            teams will share the same break week. Break weeks for custom leagues
            will not be affected by the general break week, but only by the
            break week chosen specifically for that league.
          </NxTypography>
          {seasonSlotExclusionRangeData.map((data, idx) => {
            const {
              configuration,
              seasonId,
              resourceId,
              resourceType,
              leagueName,
            } = data
            const key = `${seasonId}-${idx}`
            return (
              <OptionalWeekContent
                key={key}
                configuration={configuration}
                optionalWeeksCache={optionalSeasonWeeksCache}
                range={seasonId}
                resourceId={resourceId}
                resourceType={resourceType}
                seasonId={seasonId}
                selectLabel={leagueName}
                setOptionalWeeksCache={setOptionalSeasonWeeksCache}
              />
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}
