import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import {
  NxEsportBanner,
  NxTypography,
  NxButton,
  PersonaImageVariant,
  NxTooltip,
} from '@playvs-inc/nexus-components'

import { UnknownAvatar } from '@playvs-inc/nexus-icons'

import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { format } from 'date-fns-tz'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { EsportSlug } from '@plvs/graphql/types'
import { QueueTimer } from '../../queue/QueueTimer'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  detailsWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '250px',
    '& div': {
      display: 'flex',
    },
  },
  details: {
    justifyContent: 'space-around',
  },
  schoolName: {
    textTransform: 'uppercase',
  },
  searchMarginHack: {
    visibility: 'hidden',
  },
  textWrapper: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  teamName: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  barTextColor: {
    color: theme.palette.grey[600],
  },
}))

export interface DesktopQueueLobbyHeaderProps {
  slot?: {
    bestOf: number | null
    startsAt: string | null
    seriesBestOf: number | null
  } | null
  team?: {
    id: string
    name: string | null
    school: {
      name: string | null
      logoUrl: string | null
    } | null
  } | null
  hasSeries: boolean
  statusTitle: string
  statusSubtitle: string
  showButton: boolean
  showCountdown: boolean
  showStatusInfo: boolean
  countdown: Dayjs
  onAccept(): Promise<void>
  onCountdownComplete(): Promise<void>
  slotLobbyQueueDate: string
  esportSlug: EsportSlug | undefined
  time?: string
  disableButton: boolean
  disableMessage?: string
}

export const DesktopQueueLobbyHeader: React.FC<DesktopQueueLobbyHeaderProps> = ({
  slot,
  team,
  statusTitle,
  statusSubtitle,
  showButton,
  showCountdown,
  showStatusInfo,
  countdown,
  onAccept,
  onCountdownComplete,
  slotLobbyQueueDate,
  esportSlug,
  time,
  disableButton = false,
  disableMessage,
  hasSeries,
}) => {
  const classes = useStyles()
  const flags = useFlags()

  const getBannerContent = (): React.ReactElement => {
    return (
      <Box
        className={classes.headerWrapper}
        data-testid="DesktopQueueLobbyHeader_Wrapper"
      >
        <Box className={classes.detailsWrapper} position="relative">
          <Box className={classes.details} flexGrow="0">
            <NxTypography colorToken="OverlayColorTextBase" variant="body1">
              Best of {hasSeries ? slot?.seriesBestOf : slot?.bestOf}
            </NxTypography>
            <NxTypography className={classes.barTextColor} variant="body1">
              |
            </NxTypography>
            <NxTypography colorToken="OverlayColorTextBase" variant="body1">
              {slot?.startsAt
                ? format(new Date(slot.startsAt), 'h:mm a')
                : '???'}
            </NxTypography>
            <NxTypography className={classes.barTextColor} variant="body1">
              |
            </NxTypography>
            <NxTypography colorToken="OverlayColorTextBase" variant="body1">
              {slot?.startsAt
                ? format(new Date(slot.startsAt), 'LLL d, y')
                : '???'}
            </NxTypography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignItems="center" display="flex" flex="1">
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              marginRight="auto"
            >
              <NxUserAvatar
                avatarUrl={team?.school?.logoUrl}
                hashId={team?.id ?? ''}
                size={NxAvatarSize.LG}
              />
              <NxTypography
                className={classes.teamName}
                colorToken="OverlayColorTextBase"
                data-testid="QueueLobby_TeamName"
                variant="h3"
              >
                {team?.name}
              </NxTypography>
              <NxTypography
                className={classes.schoolName}
                colorToken="OverlayColorTextBase"
                variant="body5"
              >
                {team?.school?.name}
              </NxTypography>
            </Box>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            {showStatusInfo ? (
              <>
                <NxTypography
                  className={classes.textWrapper}
                  colorToken="OverlayColorTextBase"
                  variant="h2"
                >
                  {statusTitle}
                </NxTypography>

                {showCountdown ? (
                  <Box className={classes.textWrapper}>
                    <QueueTimer
                      countdownTime={countdown}
                      onCountdownComplete={onCountdownComplete}
                      slotLobbyQueueDate={slotLobbyQueueDate}
                      variant="h1"
                    />
                  </Box>
                ) : (
                  <></>
                )}

                {time && (
                  <NxTypography color="inherit" variant="display2">
                    {time}
                  </NxTypography>
                )}

                <Box>
                  <NxTypography
                    className={classes.textWrapper}
                    colorToken="OverlayColorTextBase"
                    variant="body1"
                  >
                    {statusSubtitle}
                  </NxTypography>
                </Box>
                {showButton ? (
                  <Box mt={1} width="221px">
                    {disableMessage ? (
                      <NxTooltip title={disableMessage}>
                        <Box>
                          <NxButton
                            disabled={disableButton}
                            fullWidth
                            label="Join Queue"
                            onClick={async (): Promise<void> => onAccept()}
                            shouldUseOverlayColors
                            variant="primary"
                          />
                        </Box>
                      </NxTooltip>
                    ) : (
                      <NxButton
                        disabled={disableButton}
                        fullWidth
                        label="Join Queue"
                        onClick={async (): Promise<void> => onAccept()}
                        shouldUseOverlayColors
                        variant="primary"
                      />
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box alignItems="center" display="flex" flex="1">
            <Box
              alignItems="flex-end"
              display="flex"
              flex="1"
              flexDirection="column"
              marginRight="auto"
            >
              <UnknownAvatar height="80px" width="80px" />
              <NxTypography
                className={classes.teamName}
                colorToken="OverlayColorTextBase"
                variant="h3"
              >
                Opponent TBD
              </NxTypography>
              <NxTypography
                className={classes.searchMarginHack}
                colorToken="OverlayColorTextBase"
                variant="body5"
              >
                &nbsp;
                {/* Hack to make sure the right side "searching" container has same height as home team */}
              </NxTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {flags.activateFullBleedBanners && esportSlug ? (
        <NxEsportBanner
          additionalContent={<Box pb={2}>{getBannerContent()}</Box>}
          esportSlug={esportSlug ?? ''}
          size="large"
          useOverlayImage
          variant={PersonaImageVariant.Match}
        />
      ) : (
        <MatchImageCard esportSlug={esportSlug} mb={3}>
          {getBannerContent()}
        </MatchImageCard>
      )}
    </Box>
  )
}
