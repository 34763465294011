import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Match } from '@plvs/graphql'
import dayjs from '@plvs/rally/init/dayjs'
import {
  DEFAULT_TIMEZONE,
  DOW_FORMAT,
  HOUR_FORMAT,
  HOUR_TZ_FORMAT,
  SHORT_DATE_FORMAT,
  SHORT_DOW_DATE_FORMAT,
  TZ_FORMAT,
} from '@plvs/const'

import { TableColumn } from '../Table'
import { Tooltip } from '../../tooltip'

dayjs.extend(utc)
dayjs.extend(tz)

export type MatchWithTzStartDate = Pick<Match, 'scheduledStartsAt'> & {
  league: {
    id: string
    timezone: string | null
  } | null
}

export const getMatchDate = ({
  scheduledStartsAt,
  format,
  timezone,
}: {
  scheduledStartsAt: string | null
  format: string
  timezone: string
}): string => dayjs(scheduledStartsAt).tz(timezone).format(format)

const getMatchDates = ({
  scheduledStartsAt,
  timezone,
}: {
  scheduledStartsAt: string | null
  timezone: string
}): {
  fromNow: string
  matchDate: string
  matchDayOfWeek: string
  matchTime: string
  matchTz: string
  myDate: string
  myDayOfWeek: string
  myTime: string
  myTz: string
  utcDate: string
  utcDayOfWeek: string
  utcTime: string
  utcTz: string
} => {
  const matchScheduledStartsAtIsInThePast = dayjs().diff(scheduledStartsAt) > 0

  const matchMoment = dayjs(scheduledStartsAt).tz(timezone)
  const myTzString = dayjs.tz.guess()
  const myMoment = dayjs(scheduledStartsAt).tz(myTzString)
  const utcTz = 'UTC'
  const utcMoment = dayjs(scheduledStartsAt).tz(utcTz)

  return {
    fromNow: `match start${
      matchScheduledStartsAtIsInThePast ? 'ed' : 's'
    } ${dayjs(scheduledStartsAt).fromNow()}`,
    matchDate: matchMoment.format(SHORT_DATE_FORMAT),
    matchDayOfWeek: matchMoment.format(DOW_FORMAT),
    matchTime: matchMoment.format(HOUR_FORMAT),
    matchTz: matchMoment.format(TZ_FORMAT),
    myDate: myMoment.format(SHORT_DATE_FORMAT),
    myDayOfWeek: myMoment.format(DOW_FORMAT),
    myTime: myMoment.format(HOUR_FORMAT),
    myTz: myMoment.format(TZ_FORMAT),
    utcDate: utcMoment.format(SHORT_DATE_FORMAT),
    utcDayOfWeek: utcMoment.format(DOW_FORMAT),
    utcTime: utcMoment.format(HOUR_FORMAT),
    utcTz: utcMoment.format(TZ_FORMAT),
  }
}

export const MatchTzStartTimeAndDate: TableColumn<MatchWithTzStartDate> = {
  id: 'matchTzStartDateAndTime',
  accessor: 'scheduledStartsAt',
  Cell: ({ scheduledStartsAt, league }): React.ReactElement => {
    const timezone = league?.timezone ?? DEFAULT_TIMEZONE

    const matchDateOnly = getMatchDate({
      scheduledStartsAt,
      format: SHORT_DOW_DATE_FORMAT,
      timezone,
    })

    const matchTimeWithTz = getMatchDate({
      scheduledStartsAt,
      format: HOUR_TZ_FORMAT,
      timezone,
    })

    const {
      fromNow,
      matchDate,
      matchDayOfWeek,
      matchTime,
      matchTz,
      myDate,
      myDayOfWeek,
      myTime,
      myTz,
      utcDate,
      utcDayOfWeek,
      utcTime,
      utcTz,
    } = getMatchDates({
      scheduledStartsAt,
      timezone,
    })

    return (
      <Tooltip
        title={
          <>
            <table>
              <tbody>
                <tr>
                  <td>{matchTz}</td>
                  <td>{matchDayOfWeek}</td>
                  <td>{matchDate}</td>
                  <td>{matchTime}</td>
                </tr>
                <tr>
                  <td>{myTz}</td>
                  <td>{myDayOfWeek}</td>
                  <td>{myDate}</td>
                  <td>{myTime}</td>
                </tr>
                <tr>
                  <td>{utcTz}</td>
                  <td>{utcDayOfWeek}</td>
                  <td>{utcDate}</td>
                  <td>{utcTime}</td>
                </tr>
              </tbody>
            </table>
            {fromNow}
          </>
        }
      >
        <>
          <NxTypography variant="body1">{matchDateOnly}</NxTypography>
          <NxTypography variant="body1">{matchTimeWithTz}</NxTypography>
        </>
      </Tooltip>
    )
  },
  Header: 'Date & Time',
  width: 156,
}
