import { Path } from '@plvs/const'
import {
  SchoolType,
  SuggestedSchoolInput,
  useApplyForSchoolFacultyMutation,
  useApplyForSchoolStudentMutation,
  UserRoleName,
} from '@plvs/graphql/generated/graphql'
import { getCountryFromState } from '@plvs/rally/components/onboard/helpers'
import { GraphQLError } from 'graphql'
import { useCallback, useEffect } from 'react'
import { ErrorOption } from 'react-hook-form'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from './OnboardingContext'

export type SetFormErrorCallback = (name: string, error: ErrorOption) => void

export type OnboardAdditionalContactController = {
  onboardStudent: () => Promise<void>
  onboardFaculty: () => Promise<void>
}

export const useOnboardAdditonalContactController = (
  setFormError: SetFormErrorCallback
): OnboardAdditionalContactController => {
  const navigate = useNavigate()
  const { data, assign } = useOnboardingContext()
  const flags = useFlags()
  const { personalEmailOptional } = flags

  // query and mutations
  const [applyForSchoolFaculty] = useApplyForSchoolFacultyMutation()
  const [applyForSchoolStudent] = useApplyForSchoolStudentMutation()

  // Side Effects
  // Guard against user reentering this page once onboarding is complete.
  useEffect(() => {
    if (data.onboardingComplete) {
      navigate(Path.Registration)
    }
    // Only run once on page init.
  }, [])

  // Handlers

  const onboardStudent = useCallback(async (): Promise<void> => {
    try {
      const personalEmail = personalEmailOptional
        ? undefined
        : (data.personalEmail as string)
      const result = await applyForSchoolStudent({
        variables: {
          role: data.role as UserRoleName,
          personalEmail,
          schoolEmail: data.schoolEmail as string,
          schoolId: data.schoolId as string,
          suggestedSchool: data.suggestedSchool
            ? {
                ...(data.suggestedSchool as SuggestedSchoolInput),
                state: data.schoolProvince as string,
                country: getCountryFromState(data.schoolProvince as string),
                type: data.schoolType as SchoolType,
              }
            : undefined,
          studentInput: {
            studentGradYear: data.graduationYear as number,
          },
        },
      })

      assign({
        schoolId:
          result.data?.applyForSchool?.user?.school?.id || data.schoolId,
      })
    } catch (err: any) {
      // Highlight Error fields if possible.
      if (err.graphQLErrors) {
        err.graphQLErrors.forEach((gqlError: GraphQLError) => {
          if (gqlError.extensions?.errorType === 'UserInputError') {
            // Handle form errors
            if (gqlError.extensions.validationErrors.email) {
              setFormError('otherEmail', {
                shouldFocus: true,
                message: gqlError.extensions.validationErrors.email,
              })
              return
            }

            if (gqlError.extensions.validationErrors.phone) {
              setFormError('phone', {
                shouldFocus: true,
                message: gqlError.extensions.validationErrors.phone,
              })
            }
          }
        })
        throw err
      }

      throw new Error('Unable to save profile.')
    }
  }, [data])

  const onboardFaculty = async (): Promise<void> => {
    try {
      const personalEmail = personalEmailOptional
        ? undefined
        : (data.personalEmail as string)

      const result = await applyForSchoolFaculty({
        variables: {
          role: data.role as UserRoleName,
          personalEmail,
          schoolEmail: data.workEmail as string,
          schoolId: data.schoolId as string,
          suggestedSchool: data.suggestedSchool
            ? {
                ...(data.suggestedSchool as SuggestedSchoolInput),
                state: data.schoolProvince as string,
                country: getCountryFromState(data.schoolProvince as string),
                type: data.schoolType as SchoolType,
              }
            : undefined,
          facultyInput: {
            facultyRoleSurvey: data.facultyRole as string,
            facultyPhone: data.phone as string,
            facultyIsCoaching: data.facOrCoach === UserRoleName.Coach,
          },
        },
      })

      assign({
        schoolId:
          result.data?.applyForSchool?.user?.school?.id || data.schoolId,
      })
    } catch (err: any) {
      // Highlight Error fields if possible.
      if (err.graphQLErrors) {
        err.graphQLErrors.forEach((gqlError: GraphQLError) => {
          if (gqlError.extensions?.errorType === 'UserInputError') {
            // Handle form errors on this page's form.
            if (gqlError.extensions.validationErrors.email) {
              setFormError('otherEmail', {
                shouldFocus: true,
                message: gqlError.extensions.validationErrors.email,
              })
              return
            }

            if (gqlError.extensions.validationErrors.phone) {
              setFormError('phone', {
                shouldFocus: true,
                message: gqlError.extensions.validationErrors.phone,
              })
            }
          }
        })
        throw err
      }

      throw new Error('Unable to save your information.')
    }
  }

  return {
    onboardStudent,
    onboardFaculty,
  }
}
