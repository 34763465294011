import { NxButton } from '@playvs-inc/nexus-components'
import { useSnackbar } from 'notistack'
import React from 'react'

import {
  useDenyCoachMutation,
  refetchGetCoachesAtMySchoolQuery,
} from '@plvs/graphql'
import { useTheme } from '@material-ui/core'

interface Props {
  userId: string
  schoolId: string
}

const DenyCoachButton = ({ userId, schoolId }: Props): React.ReactElement => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const [denyCoach, { loading: denyingCoach }] = useDenyCoachMutation()

  const onDenyCoach = async (): Promise<void> => {
    try {
      await denyCoach({
        refetchQueries: [refetchGetCoachesAtMySchoolQuery()],
        awaitRefetchQueries: true,
        variables: {
          userId,
          resourceId: schoolId,
        },
      })
    } catch (err: unknown) {
      enqueueSnackbar('Could not deny coach.', { variant: 'error' })
    }
  }

  return (
    <NxButton
      disabled={denyingCoach}
      fullWidth
      label="Deny"
      onClick={onDenyCoach}
      style={{ marginRight: theme.spacing(1) }}
      variant="secondary"
    />
  )
}
export default DenyCoachButton
