import { Button, makeStyles, ButtonProps } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import React from 'react'

import { Colors } from '@plvs/rally/themes'

const useStyles = makeStyles({
  button: {
    borderRadius: 50,
    height: 32,
    minWidth: 32,
    padding: 0,
    textTransform: 'uppercase',
    '&:hover': {
      background: 'none',
      '& .circle': {
        borderColor: 'transparent',
      },
    },
  },
  circle: {
    alignItems: 'center',
    border: `1px solid ${Colors.Grey4}`,
    borderRadius: '50%',
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    transition: 'border-color 200ms',
    width: 24,
  },
  icon: ({ expanded }: { expanded: boolean }) => ({
    height: 18,
    transition: 'transform 200ms ease',
    transform: expanded ? 'rotate(180deg)' : '',
    width: 18,
  }),
  text: {
    marginRight: 8,
  },
})

export const ExpandCardButton: React.FC<
  ButtonProps & {
    collapsedText?: string
    expanded: boolean
    expandedText?: string
  }
> = ({ collapsedText, expanded, expandedText, ...rest }) => {
  const classes = useStyles({ expanded })
  const text = expanded ? expandedText : collapsedText

  return (
    <Button className={classes.button} size="small" {...rest}>
      {text && <div className={classes.text}>{text}</div>}
      <div className={classes.circle}>
        <KeyboardArrowDown className={classes.icon} />
      </div>
    </Button>
  )
}
