import React from 'react'
import { Avatar, useTheme, makeStyles } from '@material-ui/core'

import {
  PlayVsWordmarkBlack1,
  PlayVsWordmarkWhite1,
} from '@plvs/rally/assets/icons'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 85,
    height: 24,
  },
  copyText: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
}))

const AboutUs: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const logo =
    theme.palette.type === 'dark' ? PlayVsWordmarkWhite1 : PlayVsWordmarkBlack1

  return (
    <Box data-testid="AboutUs_PlayVsCopy" pb={3.35} pt={5}>
      <Avatar
        alt="PlayVs"
        className={classes.avatar}
        src={logo}
        variant="square"
      />
      <Box mb={2} mt={2}>
        <NxTypography className={classes.copyText} variant="body1">
          PlayVS is the leading amateur esports platform in the United States
          and Canada. Our mission is to provide our users with the most
          competitions and the best competitive experiences.
        </NxTypography>
      </Box>
      <Box mb={2} mt={2}>
        <NxTypography className={classes.copyText} variant="body1">
          However you play, whatever you play, we&apos;re the platform that
          powers your competition.
        </NxTypography>
      </Box>
      <Box mb={2} mt={2}>
        <NxTypography className={classes.copyText} variant="body1">
          Built in LA by gamers, for gamers.
        </NxTypography>
      </Box>
    </Box>
  )
}

export default AboutUs
