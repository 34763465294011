import React from 'react'
import { UnverifiedSchool } from '@plvs/rally/containers/empty'
import {
  useIsSchoolByIdVerifiedQuery,
  CompetitionGroup,
  UserRoleName,
} from '@plvs/graphql'
import { Path } from '@plvs/const'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MinimalTeam } from '@plvs/respawn/features/filters/types'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useAutoskipQuery } from '@plvs/utils'
import { ScheduleContainer } from '@plvs/rally/features/match/schedule/ScheduleContainer'
import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { ScheduleRenderControllerProvider } from '@plvs/respawn/renderController/schedule/ScheduleRenderControllerProvider'
import { useParams } from 'react-router-dom'
import { MySchoolLeagues } from '../../containers/filter/league/MySchoolLeagues'
import { AppPage } from '../page'

const SchedulePage: React.FC = () => {
  const params = useParams<{ matchId }>()
  const { selectedEntityId, roles } = useProfileContext()
  const { data, loading } = useAutoskipQuery(useIsSchoolByIdVerifiedQuery, {
    variables: {
      id: selectedEntityId,
    },
  })

  const isVerifiedSchool = !data?.school?.deletedAt
  const isFaculty = roles.some(
    (role) =>
      role.roleName === UserRoleName.Fac && role.resourceId === selectedEntityId
  )
  const isCollege = data?.school?.competitionGroup !== CompetitionGroup.College

  return (
    <AppPage title="Schedule">
      <FilterCacheProvider id={Path.Schedule}>
        <MySchoolLeagues
          setDefaultTeam={(): MinimalTeam => ({
            id: 'all',
            name: 'Teams',
          })}
        >
          <WaitTillLoaded loading={loading}>
            {selectedEntityId && !isVerifiedSchool && !isFaculty ? (
              <UnverifiedSchool
                showHero
                showReferButton={!isCollege}
                subtitle="Upcoming matches and past results for my teams"
                title="Team Schedule"
              />
            ) : (
              <ScheduleRenderControllerProvider>
                <ScheduleContainer {...params} path={Path.Schedule} />
              </ScheduleRenderControllerProvider>
            )}
          </WaitTillLoaded>
        </MySchoolLeagues>
      </FilterCacheProvider>
    </AppPage>
  )
}

export default SchedulePage
