import React from 'react'
import { useTheme } from '@material-ui/core'

import {
  mapMetaseasonTimingToRelative,
  RelativeTiming,
  MinimalMetaseason,
} from '@plvs/utils'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { Box } from '@plvs/respawn/features/layout'
import { EnrolledSeason } from './RosterProvider.types'

export interface RosterSeasonBannerProps {
  isRosterActivelyLocked: boolean
  enrolledSeason: EnrolledSeason | undefined
  promotedMetaseason: MinimalMetaseason | undefined
  metaseason: MinimalMetaseason | undefined
}

export const RosterSeasonBanner: React.FC<RosterSeasonBannerProps> = ({
  enrolledSeason,
  isRosterActivelyLocked,
  promotedMetaseason,
  metaseason,
}) => {
  const theme = useTheme()
  const currentEnrolledMetaseason = enrolledSeason?.metaseason
  const mappedEnrolledMetaseason =
    currentEnrolledMetaseason &&
    mapMetaseasonTimingToRelative(currentEnrolledMetaseason)

  const isEnrolledInCurrentAndUnPromotedMetaseason =
    mappedEnrolledMetaseason?.timing === RelativeTiming.Present &&
    !mappedEnrolledMetaseason.isPromoted

  const isUserViewingPromotedMetaseason =
    metaseason?.id === promotedMetaseason?.id

  const showWarningBannerForFutureRosters =
    isEnrolledInCurrentAndUnPromotedMetaseason &&
    isUserViewingPromotedMetaseason

  const warningBannerSubtitle = isRosterActivelyLocked
    ? `Unable to make roster changes for teams enrolled in ${currentEnrolledMetaseason?.name}.`
    : `We recommend updating this roster after the ${currentEnrolledMetaseason?.name} season ends.`

  const warningBannerTitle = isRosterActivelyLocked
    ? `${currentEnrolledMetaseason?.name} is locked`
    : `Roster changes for ${
        promotedMetaseason?.name ?? 'the upcoming season'
      } will affect ${currentEnrolledMetaseason?.name ?? 'the current season'}`

  return (
    <Box px={3}>
      {showWarningBannerForFutureRosters ? (
        <Box mt={2} pb={1}>
          <Banner
            color={theme.palette.common.black}
            data-testid="RosterSeasonBanner_Banner"
            subtitle={warningBannerSubtitle}
            title={warningBannerTitle}
            type={BannerType.Warning}
          />
        </Box>
      ) : null}
    </Box>
  )
}
