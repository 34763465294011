import React from 'react'

import { isGameActive, isMatchActive } from '@plvs/utils'
import { MatchFaceoff } from '@plvs/rally/components/match'
import {
  EsportSlug,
  MatchStatus,
  GameStatus,
  PhaseType,
  useGetMatchPhaseTypeAndScheduleStartsAtQuery,
  useGetMatchScoreForHeaderQuery,
} from '@plvs/graphql'
import { VsMatchFaceoff } from '@plvs/rally/components/match/faceoff/VsMatchFaceoff'
import { reject } from 'ramda'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import { Polling } from '@plvs/const'

interface VsMatchFaceoffContainerProps {
  match: HeroMatch
  countdown?: string
  esportSlug: EsportSlug | null | undefined
  mobile: boolean
  size: 'small' | 'medium' | 'large'
  isSmallBreakpoint: boolean
}

export const VsMatchFaceoffContainer: React.FC<VsMatchFaceoffContainerProps> = ({
  countdown,
  esportSlug,
  match,
  mobile,
  size,
  isSmallBreakpoint,
}) => {
  const { bestOf } = match

  const { data, loading } = useGetMatchPhaseTypeAndScheduleStartsAtQuery({
    variables: { matchId: match.id },
    skip: !match.id,
  })

  // This query will refetched inside Match Assistant when an action is
  // taken to update the score.
  // TODO: https://playvs.atlassian.net/browse/MATCH-6948
  const {
    data: matchScoreData,
    loading: matchScoreDataLoading,
    startPolling,
    stopPolling,
    refetch,
  } = useGetMatchScoreForHeaderQuery({
    variables: {
      matchId: match.id,
    },
    skip: !match.id,
  })

  const status = matchScoreData?.match?.status

  const games = data?.match?.games ?? []

  const acceptedGames = reject(
    (game) => game.status === GameStatus.Cancelled,
    games
  )

  const activeIndex = acceptedGames.findIndex((game) => isGameActive(game))

  const isPreseasonMatch =
    data?.match?.slot?.phase?.type === PhaseType.Preseason

  const isPrematch = status === MatchStatus.Scheduled
  const isScrimmage = Boolean(data?.match?.isScrimmage)

  const refetchStatus = async (): Promise<void> => {
    await refetch({ matchId: match.id })
  }

  React.useEffect(() => {
    const matchIsActive = status ? isMatchActive(status) : false

    if (matchIsActive) {
      startPolling(Polling.Slow)
    }
    if (status === MatchStatus.Completed) {
      refetch({ matchId: match.id })
    }
    return () => {
      stopPolling()
    }
  }, [status])

  return (
    <WaitTillLoaded loading={loading || matchScoreDataLoading}>
      <MatchFaceoff
        bestOf={bestOf}
        countdown={countdown}
        liveLabel={`Game ${activeIndex + 1} of ${bestOf}`}
        matchId={match.id}
        mobile={mobile}
        refetchMatchStatus={refetchStatus}
        size={size}
        status={status}
      >
        <VsMatchFaceoff
          bestOf={match.bestOf}
          esportSlug={esportSlug}
          games={acceptedGames}
          isPreseason={isPreseasonMatch}
          isScrimmage={isScrimmage}
          isSmallBreakpoint={isSmallBreakpoint}
          matchId={match.id}
          mobile={mobile}
          prematch={isPrematch}
          size={size}
          team1Score={matchScoreData?.match?.team1Score ?? 0}
          team2Score={matchScoreData?.match?.team2Score ?? 0}
          winningTeamId={matchScoreData?.match?.winningTeamId ?? ''}
        />
      </MatchFaceoff>
    </WaitTillLoaded>
  )
}
