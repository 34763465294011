import React, { useMemo } from 'react'
import { MyCoachPlayersQuery } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { useTheme, makeStyles } from '@material-ui/core'
import {
  getEsportColorValue,
  NxTooltip,
  NxTypography,
} from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  remaining: {
    marginLeft: theme.spacing(1),
  },
}))

export type UserAccountConnectedProps = Pick<
  NonNullable<
    NonNullable<
      NonNullable<NonNullable<MyCoachPlayersQuery>>['findUsers']
    >['users']
  >[0],
  'id' | 'esportInterests' | 'userProviderAccounts'
>

export const UserAccountConnected: React.FC<UserAccountConnectedProps> = ({
  esportInterests,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const allEsportsData = useAllEsportAdapters()

  const data = useMemo(
    () =>
      (esportInterests ?? [])
        .map((esport) => {
          const esportAdapter = allEsportsData.find(
            (esportData) => esportData.slug === esport.slug
          )
          const isConnected = esportAdapter?.getIsUserConnected(rest) ?? false
          const providerName = esportAdapter?.name ?? 'Unknown Esport'

          const IconComponent = esportAdapter?.NexusIcon ?? null

          const logoColor = isConnected
            ? getEsportColorValue(theme, esport.slug, '1')
            : theme.palette.ColorTextDisabled

          const circleColor = isConnected
            ? getEsportColorValue(theme, esport.slug, 'Base')
            : theme.palette.ColorBackgroundAlt2

          const title = isConnected
            ? `This user's ${providerName || ''} account is connected`
            : `This user's ${providerName || ''} account is not connected`

          return {
            ...esport,
            isConnected,
            providerName,
            IconComponent,
            logoColor,
            circleColor,
            title,
          }
        })
        .sort((a, b) => Number(b.isConnected) - Number(a.isConnected)),
    [esportInterests]
  )

  const esportInterestsSize = data.length
  const remainingEsportsSize = esportInterestsSize - 3
  const remainingEsports =
    remainingEsportsSize > 0 ? data.slice(3, esportInterestsSize) : []

  const tooltipText = remainingEsports
    .map(
      (esport) =>
        `${esport.providerName} ${
          esport.isConnected ? '(connected)' : '(not connected)'
        }`
    )
    .join(', ')

  if (!data.length) {
    return null
  }

  return (
    <Box alignItems="center" display="flex">
      {data
        .slice(0, 3)
        .map(({ title, IconComponent, circleColor, logoColor, slug }) => (
          <Box key={slug}>
            {IconComponent && (
              <Box display="flex" flexDirection="row" ml={-1}>
                <NxTooltip
                  arrow
                  placement="top-start"
                  PopperProps={{ style: { marginBottom: '-6px' } }}
                  title={title}
                >
                  <Box
                    alignItems="center"
                    bgcolor={circleColor}
                    border={`1px solid ${theme.palette.ColorBackgroundBase}`}
                    borderRadius="50%"
                    display="flex"
                    justifyContent="center"
                    p={0.5}
                  >
                    <IconComponent
                      color={logoColor}
                      height="24px"
                      width="24px"
                    />
                  </Box>
                </NxTooltip>
              </Box>
            )}
          </Box>
        ))}
      {remainingEsportsSize > 0 && (
        <NxTooltip
          arrow
          placement="top-start"
          PopperProps={{ style: { marginBottom: '-8px' } }}
          title={tooltipText}
        >
          <div>
            <NxTypography className={classes.remaining} variant="body5">
              +{remainingEsportsSize} more
            </NxTypography>
          </div>
        </NxTooltip>
      )}
    </Box>
  )
}
