import React from 'react'
import { CellInfo } from 'react-table-v6'
import { isNumber } from 'ramda-adjunct'

export const DEFAULT_WIDTH = 84

export function renderCell<T>(Cell: React.FC<T>) {
  return (data: CellInfo): React.ReactElement => (
    <Cell {...{ ...data?.original, row: data?.row }} />
  )
}

export const getMinWidth = (
  minWidth: number | undefined,
  Header: React.ReactNode | string | null
): number => {
  if (isNumber(minWidth)) {
    return minWidth
  }

  if (typeof Header === 'string') {
    const headerLength = Header?.length ?? 0
    return Math.max(DEFAULT_WIDTH, Math.floor(headerLength * 10))
  }

  return DEFAULT_WIDTH
}
