import { makeStyles } from '@material-ui/core'

export interface StyleProps {
  active: boolean
}

export const useRosterDefaultHeaderStyles = makeStyles((theme) => ({
  iconCheckmark: {
    color: ({ active }: StyleProps): string | undefined =>
      active ? theme.palette.ColorIconInfo : theme.palette.ColorIconDisabled,
  },
  icon: {
    marginBottom: -5,
    marginLeft: 5,
  },
  iconBackground: {
    backgroundColor: theme.palette.ColorBackgroundAlt2,
  },
  toggleArrow: {
    color: theme.palette.ColorIconBase,
  },
  activatedTitle: {
    color: theme.palette.ColorTextInfo,
  },
  nonActivatedTitle: {
    color: theme.palette.ColorTextAlt2,
  },
  boldText: {
    fontWeight: 600,
  },
  h4: {
    color: theme.palette.ColorTextAccent,
    paddingLeft: theme.spacing(0.5),
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 155px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  titleLink: {
    color: theme.palette.ColorTextBase,
    '&:hover': {
      color: theme.palette.ColorTextBase,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  titleDetail: {
    color: theme.palette.ColorTextAlt2,
    paddingBottom: theme.spacing(0.7),
  },
  titleDetailContent: {
    alignItems: 'center',
    display: 'flex',
  },
  titleWrapper: {
    minWidth: 300,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  avatar: { marginRight: theme.spacing(1) },
}))
