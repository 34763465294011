import React from 'react'
import { makeStyles } from '@material-ui/core'

import { NxUserCluster } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql'
import { isNumber } from 'ramda-adjunct'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { AddPlayerToTeamButton } from '@plvs/respawn/features/manage-teams/AddPlayerToTeam/AddPlayerToTeamButton'
import { RosterCardEmptyRowMenuItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { RosterRowItem } from './RosterRowItem'
import { RosterPosition, UserRole } from '../RosterProvider.types'
import { JoinTeamButton } from '../../button/JoinTeamButton'

const useStyles = makeStyles({
  container: {
    marginLeft: '12px',
  },
})
export interface RosterRowEmptyProps {
  position: RosterPosition | null
  isLastNode: boolean
  addToTeamMutationSuccess?(input?: string): Promise<void>
  disableAction: boolean
  teamId: string
  isTeamAssociatedWithOrg: boolean
  loading?: boolean
  userId: string
  esportSlug: EsportSlug | undefined
  userRoles: UserRole[]
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  leagueId: string
  metaseasonId: string
}

export const RosterRowEmpty: React.FC<RosterRowEmptyProps> = ({
  position,
  isLastNode,
  addToTeamMutationSuccess,
  disableAction,
  teamId,
  isTeamAssociatedWithOrg,
  loading,
  userId,
  esportSlug,
  userRoles,
  getPermissions,
  leagueId,
  metaseasonId,
}) => {
  const { container } = useStyles()
  const allowedAction = getPermissions(
    userRoles,
    ContentContextPermissions.ROSTER_EMPTY_ITEMS_MENU
  ) as RosterCardEmptyRowMenuItems[]

  const rosterRowEmptyMenuMap: Record<
    RosterCardEmptyRowMenuItems,
    React.ReactElement
  > = {
    [RosterCardEmptyRowMenuItems.AddPlayer]: (
      <AddPlayerToTeamButton
        key={userId}
        disabled={disableAction}
        esportSlug={esportSlug}
        isAssociatedToOrg={isTeamAssociatedWithOrg}
        leagueId={leagueId}
        loading={loading}
        metaseasonId={metaseasonId}
        onMutationSuccess={addToTeamMutationSuccess}
        positionIndex={position?.index ?? null}
        teamId={teamId}
        userId={userId}
      />
    ),
    [RosterCardEmptyRowMenuItems.JoinTeam]: (
      <JoinTeamButton
        key={teamId}
        disabled={disableAction}
        onMutationSuccess={addToTeamMutationSuccess}
        positionIndex={position?.index ?? null}
        teamId={teamId}
      />
    ),
  }

  const menuItem = allowedAction.map((rosterMenuItem) => {
    return rosterRowEmptyMenuMap[rosterMenuItem]
  })

  const positionOrdinal =
    position && isNumber(position?.index) ? position.index + 1 : ''

  return (
    <RosterRowItem
      content={
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <NxUserCluster avatarHashId="" title={`Player ${positionOrdinal}`} />
          <Box className={container}>{menuItem}</Box>
        </Box>
      }
      isLastNode={isLastNode}
    />
  )
}
