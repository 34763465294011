import { IconButton, useTheme } from '@material-ui/core'
import { IconButtonProps } from '@material-ui/core/IconButton'
import { MoreVert } from '@material-ui/icons'
import React from 'react'

export const MoreIconButton: React.FC<Omit<IconButtonProps, 'ref'>> = (
  props
) => {
  const theme = useTheme()
  return (
    <IconButton style={{ padding: theme.spacing(0.75) }} {...props}>
      <MoreVert />
    </IconButton>
  )
}
