import React from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { Firebob, HamburgerMenu } from '@playvs-inc/nexus-icons'
import { SponsorshipImageName } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

const useStyles = makeStyles((theme) => ({
  hamburgerMenu: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  fireBob: {
    height: theme.spacing(4.4),
    width: theme.spacing(4.4),
    marginLeft: theme.spacing(1.3) * -1,
  },
  campaignLogo: {
    maxWidth: 188,
    maxHeight: 40,
  },
}))

export const MobileMenuIcon: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const { activeCampaign } = useUserIdentityFn()

  const renderIcon = (): React.ReactNode => {
    const lightLogo = activeCampaign?.sponsorshipImages?.find(
      (i) => i.name === SponsorshipImageName.MobileBannerLogo
    )
    const darkLogo = activeCampaign?.sponsorshipImages?.find(
      (i) => i.name === SponsorshipImageName.DarkModeMobileBannerLogo
    )
    const logo = isDarkMode && darkLogo ? darkLogo : lightLogo
    if (logo) {
      return (
        <img
          alt="campaign-logo"
          className={classes.campaignLogo}
          data-testid="campaign-logo"
          src={logo.url}
        />
      )
    }

    return (
      <Firebob
        className={classes.fireBob}
        color={theme.palette.ColorBackgroundAccent}
        data-testid="firebob"
      />
    )
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <HamburgerMenu className={classes.hamburgerMenu} />
      {renderIcon()}
    </Box>
  )
}
