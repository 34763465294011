import { NxButton } from '@playvs-inc/nexus-components'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation } from '@apollo/client'

import {
  DenyPlayerMutation,
  DenyPlayerMutationVariables,
  DenyPlayerDocument,
  refetchMyCoachPlayerRolePendingUsersQuery,
  refetchMyDeniedPlayersQuery,
  UserRoleName,
  QuerySortOrder,
} from '@plvs/graphql'
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@plvs/const'

interface Props {
  userId: string
  schoolId: string
}

const DenyPlayerButton = ({ userId, schoolId }: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [mutate, { loading: isMutating }] = useMutation<
    DenyPlayerMutation,
    DenyPlayerMutationVariables
  >(DenyPlayerDocument)

  const onSubmit = async (): Promise<void> => {
    try {
      const user = (
        await mutate({
          awaitRefetchQueries: true,
          refetchQueries: [
            refetchMyCoachPlayerRolePendingUsersQuery(),
            refetchMyDeniedPlayersQuery({
              input: {
                roleName: UserRoleName.Player,
                schoolId,
                options: {
                  limit: DEFAULT_PAGINATION_PAGE_LIMIT,
                  orderby: [{ field: 'createdAt', order: QuerySortOrder.Asc }],
                },
              },
            }),
          ],
          variables: {
            userId,
            resourceId: schoolId,
          },
        })
      )?.data?.denyPlayer
      if (!user) throw new Error()
      // TODO: Analytics on `user`.
    } catch (e: any) {
      enqueueSnackbar('Could not deny player.', { variant: 'error' })
    }
  }
  return (
    <NxButton
      disabled={isMutating}
      fullWidth
      label="Deny"
      onClick={onSubmit}
      style={{ marginLeft: 16 }}
      variant="secondary"
    />
  )
}
export default DenyPlayerButton
