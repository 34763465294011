import React from 'react'
import dayjs from '@plvs/rally/init/dayjs'
import {
  NxModal,
  NxTypography,
  NxButton,
  NxTooltip,
} from '@playvs-inc/nexus-components'

import { IconButton, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@playvs-inc/nexus-icons'
import { Box } from '@plvs/respawn/features/layout/Box'

import { MOMENT_SHORT_MONTH_AND_ORDINAL_AND_YEAR } from '@plvs/const'

import { useNavigate } from 'react-router-dom'
import {
  groupLeaguesByEnrollmentDate,
  LATE_ENROLLMENT_TOOLTIP,
  sortByDate,
  sortByEsportName,
  UpcomingSeason,
} from './enrollmentModalHelpers'
import { LeagueRow } from './LeagueRow'

const useStyles = makeStyles((theme) => ({
  seasonDate: {
    marginBottom: theme.spacing(2),
  },
  leagueName: {
    color: theme.palette.ColorTextBase,
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(0),
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  dateSection: {
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  handleCloseModal(): void
  open: boolean
  path: string
  seasons: UpcomingSeason[]
  title: string
}

export const EnrollmentModal: React.FC<Props> = ({
  handleCloseModal,
  open,
  path,
  seasons,
  title,
}) => {
  const classes = useStyles()

  const navigate = useNavigate()

  const now = dayjs()

  const normalEnrollmentSeasons = seasons
    .filter((season) =>
      now.isBefore(dayjs(season?.suggestedRegistrationEndsAt))
    )
    .sort(sortByEsportName)
    .reduce(groupLeaguesByEnrollmentDate, {})

  const lateEnrollmentSeasons = seasons
    .filter((season) => now.isAfter(dayjs(season?.suggestedRegistrationEndsAt)))
    .sort(sortByEsportName)
    .reduce(groupLeaguesByEnrollmentDate, {})

  return (
    <NxModal
      actions={
        <>
          <NxButton label="Back" onClick={handleCloseModal} variant="text" />
          <NxButton
            label="Manage Enrollment"
            onClick={(): void => navigate(path)}
            variant="primary"
          />
        </>
      }
      height={516}
      onClose={handleCloseModal}
      open={open}
      showTopRightClose
      size="small"
      title={title}
    >
      {Object.keys(normalEnrollmentSeasons)
        .sort(sortByDate)
        .map((key) => (
          <div key={key} className={classes.dateSection}>
            <NxTypography className={classes.seasonDate} variant="h3">
              {dayjs(key).format(MOMENT_SHORT_MONTH_AND_ORDINAL_AND_YEAR)}
            </NxTypography>
            {normalEnrollmentSeasons[key].map((league) => (
              <LeagueRow key={league?.id ?? ''} league={league} />
            ))}
          </div>
        ))}
      {Object.keys(lateEnrollmentSeasons)
        .sort(sortByDate)
        .map((key) => (
          <div key={key} className={classes.dateSection}>
            <Box alignItems="center" display="flex" mb={2}>
              <NxTypography data-testid="lateEnrollmentText" variant="h3">
                {`${dayjs(key).format(
                  MOMENT_SHORT_MONTH_AND_ORDINAL_AND_YEAR
                )} (Late Enrollment)`}
              </NxTypography>
              <NxTooltip
                arrow
                placement="top-start"
                title={LATE_ENROLLMENT_TOOLTIP}
              >
                <IconButton className={classes.iconButton}>
                  <InfoOutlined className={classes.icon} />
                </IconButton>
              </NxTooltip>
            </Box>
            {lateEnrollmentSeasons[key].map((league) => (
              <LeagueRow key={league?.id ?? ''} league={league} />
            ))}
          </div>
        ))}
    </NxModal>
  )
}
