import { Path } from '@plvs/const'
import { AuthorizedContainer } from '@plvs/respawn/features/route'
import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { UserRoleName } from '@plvs/graphql/generated'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { map, prop } from 'ramda'
import { checkIfAllowedByRoles } from '@plvs/utils'
import { LogoutPage } from '@plvs/respawn/features/auth/pages/LogoutPage'
import { OnboardV2 } from '../containers/onboard/v2/OnboardV2'
import { MoreDetailsPrompt } from '../features/moreDetails/MoreDetailsPrompt'
import { AppRoute } from './app/AppRoute'
import { LeaguePage } from './app/LeaguePage'
import { SchoolPage } from './app/SchoolPage'
import { TeamPage } from './app/TeamPage'
import AuthorizedPage from './AuthorizedPage'
import { ForbiddenPage } from './ForbiddenPage'
import { EmailVerificationErrorPage } from './EmailVerificationErrorPage'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import HealthCheckPage from './HealthCheckPage'
import { LoginPage } from './LoginPage'
import { NotFoundPage } from './NotFoundPage'
import { PasswordResetPage } from './PasswordResetPage'
import { RegistrationPage } from './RegistrationPage'
import { ValuesPage } from './ValuesPage'
import { MatchQueuePage } from './app/match/MatchQueuePage'
import { PracticeDayQueuePage } from './app/match/PracticeDayQueuePage'
import { SlotQueuePage } from './app/match/SlotQueuePage'
import { LeagueDetailsPage } from './app/competitions/LeagueDetailsPage'
import { ProfilePage } from './app/profile/ProfilePage'
import { PreseasonQueuePage } from './app/match/PreseasonQueuePage'
import { ParentSignUpRequestPage } from './ParentSignupRequestPage'
import { ChildOnboard } from './app/childOnboard/ChildOnboard'
import { ParentSignupPage } from './parentSignup/ParentSignupPage'
import { ParentOnboard } from './parentOnboard/ParentOnboard'
import { ChildDetailsPage } from './app/ChildDetailsPage'
import { LoggedOutWrapper } from '../features/login/LoggedOutRoute'
import { CompetitionsRoute } from './app/competitions'

export const Pages: React.FC = () => {
  const { isParent, userRoles: roles } = useUserIdentityFn()
  const flags = useFlags()
  const isParentOn = flags?.coppaSupport && isParent
  const userRoles = map(prop('roleName'), roles)

  const canAccessPracticeQueus = checkIfAllowedByRoles({
    userRoles,
    requireAnyOf: [UserRoleName.Coach, UserRoleName.Captain],
  })

  return (
    <ApmRoutes>
      <Route
        element={
          <Navigate to={isParentOn ? Path.ParentDashboard : Path.Dashboard} />
        }
        path={Path.Root}
      />
      <Route
        element={
          <Navigate to={isParentOn ? Path.ParentDashboard : Path.Dashboard} />
        }
        path={Path.App}
      />
      {isParentOn && (
        <Route
          element={<Navigate to={Path.ParentDashboard} />}
          path={Path.Dashboard}
        />
      )}

      {/* Open to public */}
      <Route element={<ValuesPage />} path="/values" />
      <Route element={<HealthCheckPage />} path="/healthcheck" />
      <Route
        element={
          <LoggedOutWrapper>
            <LoginPage />
          </LoggedOutWrapper>
        }
        path={Path.Login}
      />
      <Route element={<LogoutPage />} path="/logout" />
      <Route
        element={
          <LoggedOutWrapper>
            <RegistrationPage />
          </LoggedOutWrapper>
        }
        path={Path.Registration}
      />
      <Route
        element={
          <LoggedOutWrapper>
            <ParentSignUpRequestPage />
          </LoggedOutWrapper>
        }
        path={Path.ParentSignUpRequest}
      />
      <Route
        element={
          <LoggedOutWrapper>
            <ParentSignupPage />
          </LoggedOutWrapper>
        }
        path={Path.ParentSignUp}
      />
      <Route element={<SchoolPage />} path="/org/:slug" />
      <Route element={<TeamPage />} path="/team/:teamId" />
      <Route element={<LeaguePage />} path="/league/:slug" />
      <Route element={<LeagueDetailsPage />} path={Path.LeagueDetails} />
      <Route element={<ProfilePage />} path={`${Path.Profile}/:userId`} />
      <Route element={<CompetitionsRoute />} path={`${Path.PublicExplore}/*`} />

      <Route
        element={
          <LoggedOutWrapper>
            <ForgotPasswordPage />
          </LoggedOutWrapper>
        }
        path="/forgot-password"
      />
      <Route element={<PasswordResetPage />} path="/password-reset/:token" />
      {/* Require authorization */}
      <Route
        element={<AuthorizedContainer component={<AuthorizedPage />} />}
        path="/authorized"
      />
      <Route
        element={<AuthorizedContainer component={<OnboardV2 />} />}
        path={`${Path.SpawnPoint}/*`}
      />
      <Route
        element={<AuthorizedContainer component={<ParentOnboard />} />}
        path={`${Path.ParentSpawnPoint}/*`}
      />
      <Route
        element={<AuthorizedContainer component={<MoreDetailsPrompt />} />}
        path={Path.MoreDetails}
      />
      <Route
        element={
          <AuthorizedContainer
            allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
            component={<MatchQueuePage />}
          />
        }
        path={`${Path.MatchQueue}/*`}
      />
      {canAccessPracticeQueus && (
        <>
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                component={<PracticeDayQueuePage />}
              />
            }
            path={`${Path.PracticeDayQueue}/*`}
          />
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                component={<SlotQueuePage />}
              />
            }
            path={`${Path.SlotMatchQueue}/*`}
          />
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                component={<PreseasonQueuePage />}
              />
            }
            path={`${Path.PreseasonMatchQueue}/*`}
          />
        </>
      )}
      {/* // Main post login route */}

      <Route
        element={<AuthorizedContainer component={<ChildOnboard />} />}
        path={`${Path.App}${Path.ChildSpawnPoint}/*`}
      />
      <Route
        element={<AuthorizedContainer component={<AppRoute />} />}
        path={`${Path.App}/*`}
      />

      <Route
        element={<AuthorizedContainer component={<ChildDetailsPage />} />}
        path={`${Path.ChildDetails}/:userId`}
      />

      {/* /* Error Pages */}
      <Route
        element={<EmailVerificationErrorPage />}
        path={Path.EmailVerificationError}
      />

      {/* 403 Forbidden */}
      <Route element={<ForbiddenPage />} path={Path.Forbidden} />
      <Route element={<ForbiddenPage />} path={Path.Banned} />
      {/* 404 */}
      <Route element={<NotFoundPage />} path="*" />
    </ApmRoutes>
  )
}
