import { betterCompact } from '@plvs/utils'
import { allEsportColumnComponentsMap } from '@plvs/rally/components/table/column'
import { EsportColumnComponentNames } from '@plvs/respawn/features/esport/creator/types'
import { TableColumn, Column } from '@plvs/rally/components/table'
import { UserClusterColumn } from '@plvs/respawn/features/esport/table-overrides/UserCluster'

type ColumnOverrides = Partial<
  Record<EsportColumnComponentNames, Partial<TableColumn<any>>>
>

export const getTableColumns = (
  columnNames: EsportColumnComponentNames[],
  rankingInfoCopy: string,
  columnOverrides?: ColumnOverrides
): TableColumn<any>[] => {
  return betterCompact(
    (columnNames ?? []).map((columnName) => {
      let column = allEsportColumnComponentsMap[columnName]
      if (!column) {
        if (columnName === EsportColumnComponentNames.UserClusterColumn)
          column = UserClusterColumn
        if (columnName === EsportColumnComponentNames.RankingPosition)
          column = Column.Position(rankingInfoCopy)
      }

      const adjustedColumn = columnOverrides?.[columnName]
        ? ({ ...column, ...columnOverrides[columnName] } as TableColumn<any>)
        : column

      return adjustedColumn
    })
  )
}
