import { makeStyles } from '@material-ui/core'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout'
import { Icon, Icons } from '../icon'

const useStyles = makeStyles((theme) => ({
  labelColor: {
    color: theme.palette.ColorTextBase,
  },
  noLabelColor: {
    color: theme.palette.ColorTextDisabled,
  },
  iconColor: {
    color: theme.palette.ColorIconBase,
  },
}))

export const ContactItem: React.FC<{
  icon: Icons
  label?: string | null
  fallbackLabel: string
}> = ({ icon, label, fallbackLabel }) => {
  const styles = useStyles()

  return (
    <Box
      alignItems="center"
      color={label ? styles.labelColor : styles.noLabelColor}
      display="flex"
      flexDirection="row"
      py={0.5}
    >
      <Box
        alignItems="center"
        color={label ? styles.noLabelColor : styles.iconColor}
        display="flex"
        pr={1}
      >
        <Icon icon={icon} />
      </Box>
      <NxTypography color="inherit" variant="body2">
        {label || fallbackLabel}
      </NxTypography>
    </Box>
  )
}
