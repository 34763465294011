import React from 'react'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useGetSchoolBillingContactQuery } from '@plvs/graphql/generated/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Box } from '@material-ui/core'
import { useAutoskipQuery } from '@plvs/utils'
import { PaymentPlan } from '@plvs/checkpoint/containers/school/enrollment/PaymentPlan'
import { SchoolBillingFormPanel } from './SchoolBillingFormPanel'
import { SchoolInvoicePanel } from './SchoolInvoicePanel'

export const Billing: React.FC<{ cpSchoolId?: string }> = ({ cpSchoolId }) => {
  const { id: rallyschoolId } = useSelectedOrganizationFn()
  const schoolId = cpSchoolId ?? rallyschoolId

  const { loading } = useAutoskipQuery(useGetSchoolBillingContactQuery, {
    variables: {
      schoolId,
    },
    fetchPolicy: 'cache-first',
  })

  const hasCpSchoolId = !!cpSchoolId

  return (
    <>
      <Box display="flex" flexDirection="column" mt={hasCpSchoolId ? 3 : 0}>
        <SchoolInvoicePanel isCheckpoint={hasCpSchoolId} />
      </Box>

      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <Box display="flex" flexDirection="column" mt={5}>
          <SchoolBillingFormPanel cpSchoolId={cpSchoolId} />
        </Box>
      </WaitTillLoaded>

      {hasCpSchoolId && (
        <Box data-testid="payment-plan" my={3}>
          <PaymentPlan id={cpSchoolId} />
        </Box>
      )}
    </>
  )
}
