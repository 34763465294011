import { refetchGetLobbyMatchQuery } from '@plvs/graphql'

export type CancelMatchStep =
  | 'forfeit-verification'
  | 'forfeit-reason'
  | 'forfeit-detail'
  | 'forfeit-confirmation'
  | 'forfeit-success'
  | 'forfeit-failure'

export const CancelMatchFirstStep = 'forfeit-verification'

export const getRefetchMatchLobbyQueries = (matchId: string | null): any[] =>
  matchId
    ? [
        refetchGetLobbyMatchQuery({
          matchId,
        }),
        // NOTE: There are some other queries for the match lobby, but they are unlikely to change, like roles and school & league affiliation
      ]
    : []
