import React, { useReducer, useContext } from 'react'

import { reducer } from './reducer'
import { AppState, initialState } from './state'
import { AllActions } from './actions'

const stateContext = React.createContext<AppState>(initialState)
const dispatchContext = React.createContext<React.Dispatch<AllActions>>(
  () => initialState
)

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <stateContext.Provider value={state}>
      <dispatchContext.Provider value={dispatch}>
        {children}
      </dispatchContext.Provider>
    </stateContext.Provider>
  )
}

export const useAppState = (): AppState => useContext(stateContext)
export const useAppDispatch = (): React.Dispatch<AllActions> =>
  useContext(dispatchContext)
