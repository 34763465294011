import React from 'react'
import {
  Button,
  ButtonGroup,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { Colors } from '@plvs/rally/themes'
import clsx from 'clsx'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Tooltip } from '../tooltip'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: 'none',
    height: 36,
    '&:hover button': {
      backgroundColor: '#DB4500',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  buttonText: {
    borderRight: 'none',
    padding: theme.spacing(1.2),
    textTransform: 'capitalize',
  },
  buttonIcon: {
    width: 33,
  },
  groupedContainedHorizontal: {
    '&:not(:last-child)': {
      borderRight: `1px solid ${Colors.White}`,
    },
  },
  list: {
    padding: 0,
  },
  menuItemText: {
    color: Colors.NexusBlack1,
    textTransform: 'capitalize',
  },
  paper: {
    padding: theme.spacing(1, 0),
  },
  tooltip: {
    alignItems: 'center',
    display: 'flex',
  },
}))

interface Props {
  buttonLabel: string
  className?: string
  menuItems: {
    name: string
    onClick(): void
    startIcon?: React.ReactNode
    disabled?: boolean
    tooltipTitle?: string
  }[]
  menuWidth?: number
}

export const DropdownButton: React.FC<Props> = ({
  buttonLabel,
  className,
  menuItems,
  menuWidth,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget.parentElement)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonGroup
        classes={{
          groupedContainedHorizontal: classes.groupedContainedHorizontal,
        }}
        className={clsx(classes.buttonGroup, className)}
        color="primary"
        variant="contained"
      >
        <Button className={classes.buttonText} onClick={handleClick}>
          {buttonLabel}
        </Button>
        <Button className={classes.buttonIcon} onClick={handleClick}>
          {anchorEl !== null ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper, list: classes.list }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            width: menuWidth,
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.name}
            disabled={menuItem.disabled}
            onClick={(): void => {
              handleClose()
              menuItem.onClick()
            }}
          >
            <Tooltip
              className={classes.tooltip}
              title={menuItem.tooltipTitle ?? ''}
            >
              <>
                {menuItem.startIcon}
                <NxTypography className={classes.menuItemText} variant="body1">
                  {menuItem.name}
                </NxTypography>
              </>
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
