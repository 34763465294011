import { Box, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { Path } from '@plvs/const'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { AppDrawerChatMessagesToggle } from './chatMessages/AppDrawerChatMessagesToggle'
import { ChatDrawer } from './chatMessages/ChatDrawer'
import { AppDrawerNotificationsToggle } from './notifications/AppDrawerNotificationsToggle'
import { NotificationDrawer } from './notifications/NotificationDrawer'

const HIDDEN_PATHS = [Path.CreateScrimmage, Path.Enrollment]

const useStyles = makeStyles((theme) => ({
  toggleContainerAppBar: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2.75),
    zIndex: 10,
  },
  toggleContainerPage: ({
    isFullBleedBanner,
    isCheckpoint,
  }: {
    isFullBleedBanner: boolean
    isCheckpoint: boolean
  }): CreateCSSProperties => {
    const marginRight = theme.spacing(isFullBleedBanner ? 2 : 7)
    const marginTop = theme.spacing(isFullBleedBanner ? 2 : 6)

    return {
      zIndex: 10,
      position: 'absolute',
      marginRight: isCheckpoint ? undefined : marginRight,
      marginTop: isCheckpoint ? theme.spacing(2) : marginTop,
      marginLeft: isCheckpoint ? theme.spacing(2) : undefined,
    }
  },
}))

interface AppDrawerTogglesProps {
  isInAppBar?: boolean
  isCheckpoint?: boolean
  isPublic?: boolean
}
export const AppDrawerToggles: React.FC<AppDrawerTogglesProps> = ({
  isInAppBar,
  isCheckpoint = false,
  isPublic = false,
}) => {
  const flags = useFlags()
  const classes = useStyles({
    isFullBleedBanner: flags.activateFullBleedBanners,
    isCheckpoint,
  })
  const location = useLocation()
  const isHiddenOnPage = HIDDEN_PATHS.some((path) =>
    location.pathname.includes(path)
  )

  const { hasMultiOrgAccess } = useUserIdentityFn()

  const showNotifications = !isCheckpoint && !hasMultiOrgAccess

  return (
    <Box
      className={
        isInAppBar ? classes.toggleContainerAppBar : classes.toggleContainerPage
      }
      display={isHiddenOnPage ? 'none' : 'flex'}
    >
      {isPublic ? null : (
        <>
          <Box pr={1.5}>
            <AppDrawerChatMessagesToggle
              ChatMessageDrawer={ChatDrawer}
              isInAppBar={isInAppBar}
            />
          </Box>
          {showNotifications && (
            <AppDrawerNotificationsToggle
              isInAppBar={isInAppBar}
              NotificationDrawer={NotificationDrawer}
            />
          )}
        </>
      )}
    </Box>
  )
}
