import { useEffect, useState, useCallback } from 'react'
import DefaultAvatar from './default-avatar.svg'

interface UseImageLoaderProps {
  src?: string | null
  fallbackSrc?: string
}

interface UseImageLoaderReturn {
  isLoaded: boolean
  imageSrc: string
}

export const useImageLoader = ({
  src,
  fallbackSrc = DefaultAvatar,
}: UseImageLoaderProps): UseImageLoaderReturn => {
  const [imageSrc, setImageSrc] = useState(src || fallbackSrc)
  const [isLoaded, setIsLoaded] = useState(false)

  const onError = useCallback(() => {
    if (imageSrc !== fallbackSrc) {
      setImageSrc(fallbackSrc)
    }
  }, [isLoaded, imageSrc])

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [isLoaded])

  useEffect(() => {
    let img: HTMLImageElement | null
    if (imageSrc) {
      setIsLoaded(false)
      img = new Image()
      img.addEventListener('load', onLoad)
      img.addEventListener('error', onError)
      img.src = imageSrc
    }
    return (): void => {
      if (img) {
        img.removeEventListener('load', onLoad)
        img.removeEventListener('error', onError)
      }
    }
  }, [imageSrc])

  useEffect(() => {
    setImageSrc(src || fallbackSrc)
  }, [src])

  return {
    isLoaded,
    imageSrc,
  }
}
