import React from 'react'

import { TOSModal } from '@plvs/rally/features/notifications/TOSModal'
import { PrivatePage } from './PrivatePage'
import { ScrollToTop } from '../route/ScrollToTop'

export const AppPage: React.FC<{
  children: React.ReactNode
  title: string
}> = ({ children, title }) => (
  <PrivatePage title={title}>
    <ScrollToTop />
    <TOSModal />
    {children}
  </PrivatePage>
)
