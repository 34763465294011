import { Esport, Maybe, SchoolLeagueEnrollmentStatus } from '@plvs/graphql'
import { truncate } from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'

export interface FilterLeague {
  id: string
  name: string
  displayName: string | null
  esport: Maybe<Pick<Esport, 'id' | 'name' | 'slug'>>
  enrollmentStatus: Maybe<SchoolLeagueEnrollmentStatus>
}

export const getLabel = ({
  league,
  showLeagueName,
  showEsportName,
  shouldTruncateName,
}: {
  league: FilterLeague
  showLeagueName: boolean
  showEsportName: boolean
  shouldTruncateName: boolean
}): string => {
  let displayVal = (league?.displayName ?? '') || (league?.name ?? '')

  if (showLeagueName) {
    displayVal = league?.name ?? ''
  } else if (showEsportName && league?.esport?.name) {
    displayVal = league.esport.name
  }

  return shouldTruncateName ? truncate(displayVal, 40) : displayVal
}
