import { makeStyles, Theme } from '@material-ui/core'

interface StyleModifiers {
  pr: number
}

export const useFilterStyles = makeStyles<Theme, StyleModifiers>(
  ({ spacing }) => ({
    itemsParent: ({ pr }): { [key: string]: React.CSSProperties } => ({
      '& > .item': {
        paddingRight: `${spacing(pr)}px`,
      },
      '& > .item:last-child': {
        paddingRight: 0,
      },
    }),
  })
)
