// Alloy `any` type because these contain generic column accessors that operate on
// any data type given to a <Table> across games.
/* eslint-disable @typescript-eslint/no-explicit-any */

import { NxTypography } from '@playvs-inc/nexus-components'
import { PhaseResult } from '@plvs/graphql'
import { EsportColumnComponentNames } from '@plvs/respawn/features/esport/creator/types'
import { PercentWithTwoDecimalPlaces, None } from '@plvs/utils'
import React from 'react'
import {
  GameCluster as GameClusterComponent,
  GameClusterWithGameStatus as GameClusterWithGameStatusComponent,
} from '../../cluster'
import { TableColumn } from '../Table'
import { MatchRecord, PlayVsScore, SeriesRecord } from './standings'
import * as Fn from './fn'
import * as LoL from './lol'
import * as RL from './rl'
import * as Shared from './shared'
import * as User from './user'
import { TeamClusterCell } from '../cell/TeamCluserCell'

export * from './fn'
export * from './lol'
export * from './match'
export * from './rl'
export * from './scrimmage'
export * from './shared'
export * from './user'

// NOTE: Needs hard type instead of `any`.
export const PlayerPosition: TableColumn<any> = {
  accessor: 'position',
  Cell: ({ position }: { position: any }): React.ReactElement => (
    <NxTypography variant="subtitle1">{position}</NxTypography>
  ),
  Header: 'Pos',
  HeaderTooltip: 'Position',
}

// NOTE: Needs hard type instead of `any`.
export const RankingPosition: TableColumn<any> = {
  accessor: 'position',
  Cell: ({
    position,
    value,
  }: {
    position: number
    value: number
  }): React.ReactElement => (
    <NxTypography variant="subtitle1">{position || value}</NxTypography>
  ),
  width: 50,
  minWidth: 50,
  Header: 'Pos',
  HeaderTooltip: 'Position',
}

// NOTE: Needs hard type instead of `any`.
export const TeamCluster: TableColumn<any> = {
  accessor: (data) => ({
    ...data.team,
    hasClinchedPlayoffs: data.hasClinchedPlayoffs,
  }),
  Cell: (data): React.ReactElement => {
    return <TeamClusterCell data={data} />
  },
  Header: 'Team',
  id: 'team',
  minWidth: 320,
}

// NOTE: Needs hard type instead of `any`.
export const WinRecord: TableColumn<any> = {
  accessor: 'record.won',
  Header: 'Won',
  id: 'won',
  minWidth: 150,
}

// NOTE: Needs hard type instead of `any`.
export const LostRecord: TableColumn<any> = {
  accessor: 'record.lost',
  Header: 'Lost',
  id: 'lost',
  minWidth: 150,
}

// NOTE: Needs hard type instead of `any`.
export const MatchesWon: TableColumn<any> = {
  accessor: 'matchesWon',
  Header: 'Won',
  id: 'matchesWon',
  minWidth: 75,
}

// NOTE: Needs hard type instead of `any`.
export const MatchesLost: TableColumn<any> = {
  accessor: 'matchesLost',
  Header: 'Lost',
  id: 'matchesLost',
  minWidth: 75,
}

type GamesWonLostData = {
  gamesWon: number
  gamesLost: number
}

// NOTE: Needs hard type instead of `any`.
export const GameRecord: TableColumn<any> = {
  Header: (): React.ReactElement => (
    <>
      <NxTypography variant="subtitle1">Game Record</NxTypography>
      <NxTypography variant="overline">WIN-LOSS</NxTypography>
    </>
  ),
  accessor: (data) => {
    return { gamesWon: data.gamesWon, gamesLost: data.gamesLost }
  },
  id: 'gamesWon',
  Cell: (
    data: { value: GamesWonLostData } & GamesWonLostData
  ): React.ReactElement => {
    const { value } = data
    const gamesData = value || data
    const { gamesWon } = gamesData
    const { gamesLost } = gamesData
    if (!!gamesWon || !!gamesLost) {
      return <NxTypography>{`${gamesWon} - ${gamesLost}`}</NxTypography>
    }
    return <NxTypography>-</NxTypography>
  },
  minWidth: 150,
}

export const GameCluster: TableColumn<{
  gameId: string
  matchId: string | null
  matchName: string | null
  opponent: string
  ordinal: number | string
  outcome: string
  week: number
}> = {
  Header: 'Game',
  id: 'gameTitle',
  Cell: ({
    gameId,
    matchId,
    matchName,
    ordinal,
    opponent,
    outcome,
    week,
  }): React.ReactElement => (
    // Note: Assume any data point can be missing, this can be cause by 3rd party
    // data outtage, MMR, or incomplete matches.  This needs to be handled here,
    // or further downstream by the display component.
    <GameClusterComponent
      gameId={gameId}
      matchId={matchId}
      opponent={opponent}
      outcome={outcome}
      subtitle={matchName || `Week ${week}`}
      // Ternary operator here used to hide the game name if there is an error
      // as denoted by missing gameId.
      title={gameId ? `Game ${ordinal || ''}` : ''}
    />
  ),
  width: 330,
}

/**
 * Same as above GameCluster with addition of game status
 * display for Forfeit and Settled
 */
export const GameClusterWithGameStatus: TableColumn<{
  gameId: string
  matchId: string | null
  matchName: string | null
  opponent: string
  ordinal: number | string
  outcome: string
  week: number
  stats?: any
  isGamelessType?: boolean
}> = {
  Header: 'Game',
  id: 'gameTitle',
  Cell: ({
    gameId,
    matchId,
    matchName,
    ordinal,
    opponent,
    outcome,
    week,
    stats,
    isGamelessType,
  }): React.ReactElement => {
    // Note: Assume any data point can be missing, this can be cause by 3rd party
    // data outtage, MMR, or incomplete matches.  This needs to be handled here,
    // or further downstream by the display component.
    return (
      <GameClusterWithGameStatusComponent
        forfeitBy={stats.forfeitBy}
        gameId={gameId}
        isForfeit={stats.isForfeit}
        isGamelessType={isGamelessType}
        isSettled={stats.isSettled}
        matchId={matchId}
        opponent={opponent}
        outcome={outcome}
        settledBy={stats.settledBy}
        // Ternary operator here used to hide the game name if there is an error
        // as denoted by missing gameId and matchId.
        subtitle={matchName || `Week ${week}`}
        title={gameId || matchId ? `Game ${ordinal || ''}` : ''}
      />
    )
  },
  width: 330,
}

// NOTE: Needs hard type instead of `any`.
export const TiebreakerPoints: TableColumn<any> = {
  accessor: 'phaseTiebreakerPoints',
  Header: 'Tiebreaker Points',
  id: 'phaseTiebreakerPoints',
  width: 150,
  minWidth: 150,
}

// NOTE: Needs hard type instead of `any`.
export const WinPercentage: TableColumn<any> = {
  accessor: 'record.winPercentage',
  Cell: ({ record }: Pick<PhaseResult, 'record'>): React.ReactElement => {
    const winPercentage = record?.winPercentage ?? 0
    if (winPercentage > 0) {
      return <>{PercentWithTwoDecimalPlaces(winPercentage)}</>
    }
    return <>-</>
  },
  Header: 'PCT',
  id: 'winPercentage',
  width: 92,
  minWidth: 92,
}

// NOTE: Needs hard type instead of `any`.
export const WinStreak: TableColumn<any> = {
  accessor: 'record.streak',
  Cell: (data: {
    record?: { streak: number }
    value?: number
  }): React.ReactElement => {
    const { value, record } = data

    const streak = record?.streak || value || -1
    return <>{streak < 1 ? '-' : streak}</>
  },
  Header: 'Win Streak',
  id: 'streak',
  width: 80,
  minWidth: 80,
}

// Adding this column to your table will hide the expand column, when working
//  with expanding rows
// NOTE: Needs hard type instead of `any`.
export const HiddenExpander: TableColumn<Record<string, unknown>> = {
  expander: true,
  Header: 'Expand',
  show: false,
}

// Columns rendering with nulls require data to be pulled from queries
// Instantiate these columns on a case-by-base basis in the code
export const allEsportColumnComponentsMap: Record<
  EsportColumnComponentNames,
  TableColumn<any> | null
> = {
  [EsportColumnComponentNames.UserClusterColumn]: null,
  [EsportColumnComponentNames.PhasePlayerCluster]: User.PhasePlayerCluster,
  [EsportColumnComponentNames.PlayerPosition]: PlayerPosition,
  [EsportColumnComponentNames.EndCap]: Shared.EndCap,
  [EsportColumnComponentNames.HiddenExpander]: HiddenExpander,
  [EsportColumnComponentNames.TeamCluster]: TeamCluster,
  [EsportColumnComponentNames.MatchesWon]: MatchesWon,
  [EsportColumnComponentNames.MatchesLost]: MatchesLost,
  [EsportColumnComponentNames.GameRecord]: GameRecord,
  [EsportColumnComponentNames.TiebreakerPoints]: TiebreakerPoints,
  [EsportColumnComponentNames.WinStreak]: WinStreak,
  [EsportColumnComponentNames.WinPercentage]: WinPercentage,
  [EsportColumnComponentNames.WinRecord]: WinRecord,
  [EsportColumnComponentNames.LostRecord]: LostRecord,
  [EsportColumnComponentNames.RankingPosition]: RankingPosition,
  [EsportColumnComponentNames.MatchRecord]: MatchRecord,
  [EsportColumnComponentNames.PlayVsScore]: PlayVsScore,
  [EsportColumnComponentNames.SeriesRecord]: SeriesRecord,

  // League of legends
  [EsportColumnComponentNames.LeagueOfLegendsChampion]: LoL.Champion,
  [EsportColumnComponentNames.LeagueOfLegendsChampions]: LoL.Champions,
  [EsportColumnComponentNames.LeagueOfLegendsBans]: LoL.Bans,
  [EsportColumnComponentNames.LeagueOfLegendsKDA]: LoL.Kda,
  [EsportColumnComponentNames.LeagueOfLegendsKDAAverage]: LoL.KdaAvg,
  [EsportColumnComponentNames.LeagueOfLegendsGPM]: LoL.Gpm,
  [EsportColumnComponentNames.LeagueOfLegendsCS]: LoL.Cs,
  [EsportColumnComponentNames.LeagueOfLegendsDamage]: LoL.Damage,
  [EsportColumnComponentNames.LeagueOfLegendsWards]: LoL.Wards,
  [EsportColumnComponentNames.LeagueOfLegendsItems]: LoL.Items,
  [EsportColumnComponentNames.LeagueOfLegendsGoldEarned]: LoL.Gold,
  [EsportColumnComponentNames.LeagueOfLegendsEliminationsAverage]:
    LoL.EliminationsAvg,
  [EsportColumnComponentNames.LeagueOfLegendsAssistsAverage]: LoL.AssistsAvg,
  [EsportColumnComponentNames.LeagueOfLegendsCSAverage]: LoL.CsAvg,
  [EsportColumnComponentNames.LeagueOfLegendsTowersDestroyedAverage]:
    LoL.TowersDestroyedAvg,
  [EsportColumnComponentNames.LeagueOfLegendsRespawnsAverage]: LoL.RespawnsAvg,
  [EsportColumnComponentNames.LeagueOfLegendsMostPickedChampion]:
    LoL.MostPickedChampion,
  [EsportColumnComponentNames.LeagueOfLegendsGameCluster]: GameCluster,

  // Rocket League
  [EsportColumnComponentNames.RocketLeagueShootingPercentage]:
    RL.ShootingPercentage,
  [EsportColumnComponentNames.RocketLeagueGoals]: RL.Goals,
  [EsportColumnComponentNames.RocketLeagueSaves]: RL.Saves,
  [EsportColumnComponentNames.RocketLeagueAssists]: RL.RocketLeagueAssists,
  [EsportColumnComponentNames.RocketLeagueShots]: RL.Shots,
  [EsportColumnComponentNames.RocketLeagueAvgShootingPercentage]:
    RL.AvgShootingPercentage,
  [EsportColumnComponentNames.RocketLeagueAvgGoals]: RL.AvgGoals,
  [EsportColumnComponentNames.RocketLeagueAvgSaves]: RL.AvgSaves,
  [EsportColumnComponentNames.RocketLeagueAvgShots]: RL.AvgShots,
  [EsportColumnComponentNames.RocketLeagueAvgAssistsSaves]:
    RL.AvgRocketLeagueAssistsSaves,
  [EsportColumnComponentNames.RocketLeagueGameCluster]: GameClusterWithGameStatus,
  [EsportColumnComponentNames.RocketLeagueScore]: RL.Score,

  // Smite
  [EsportColumnComponentNames.SmiteEliminations]: LoL.Eliminations,
  [EsportColumnComponentNames.SmiteRespawns]: LoL.Respawns,
  [EsportColumnComponentNames.SmiteAssists]: LoL.Assists,
  [EsportColumnComponentNames.SmiteGoldEarned]: LoL.StatColumn(
    'goldEarned',
    None,
    {
      Header: 'GE',
      HeaderTooltip: 'Gold earned',
    }
  ),
  [EsportColumnComponentNames.SmiteTowersDestroyed]: LoL.StatColumn(
    'towersDestroyed',
    None,
    {
      Header: 'TK',
      HeaderTooltip: 'Towers Destroyed',
    }
  ),
  [EsportColumnComponentNames.SmiteGameCluster]: GameClusterWithGameStatus,
  [EsportColumnComponentNames.SmiteKdaAverage]: LoL.KdaAvg,

  // MOBA
  [EsportColumnComponentNames.MOBAKillParticipation]: LoL.Kp,
  [EsportColumnComponentNames.MOBAGPMAverage]: LoL.GpmAvg,

  // Fortnite
  [EsportColumnComponentNames.FortniteTeamCluster]: Fn.FnTeamCluster,
  [EsportColumnComponentNames.FortniteRank]: Fn.FnRank,
  [EsportColumnComponentNames.FortnitePoints]: Fn.FnPoints,
  [EsportColumnComponentNames.FortniteAvgPoints]: Fn.FnAvgPoints,
  [EsportColumnComponentNames.FortniteWins]: Fn.FnWin,
  [EsportColumnComponentNames.FortniteWinPercentage]: Fn.FnWinPercentage,
  [EsportColumnComponentNames.FortniteAvgPlace]: Fn.FnAvgPlace,
  [EsportColumnComponentNames.FortniteAvgElims]: Fn.FnAvgElims,
  [EsportColumnComponentNames.FortniteGamesPlayed]: Fn.FnGamesPlayed,
  [EsportColumnComponentNames.FortniteTimePlayed]: Fn.FnTimePlayed,
  [EsportColumnComponentNames.FortniteState]: Fn.FnState,
  [EsportColumnComponentNames.FortniteGameCluster]: GameClusterWithGameStatus,
  [EsportColumnComponentNames.FortniteEliminations]: Fn.FnEliminationPoints,
}
