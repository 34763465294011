import { GameIncompleteReason } from '@plvs/graphql/generated'

export enum ReportOptionValue {
  MyTeam,
  OppTeam,
  Incomplete,
}

export interface GameIncompleteMetaDataDefault {
  reason: undefined
  faultId: undefined
}

export interface GameIncompleteMetaData {
  reason: GameIncompleteReason
  faultId: ReportOptionValue.MyTeam | ReportOptionValue.OppTeam
}

export type GameSelectionValue =
  | {
      value: ReportOptionValue.MyTeam | ReportOptionValue.OppTeam
      meta: undefined
    }
  | {
      value: ReportOptionValue.Incomplete
      meta: GameIncompleteMetaData
    }

export interface ReportOption {
  value: ReportOptionValue
  title: string
  subtitle?: string
  imgSrc?: string
  hashId?: string
}
