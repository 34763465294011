import React, { useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import { EsportRating } from '@plvs/graphql'
import { SchedulePageQueueMatch } from '@plvs/rally/features/match/schedule/schedule'
import {
  useGeneralEsportAdapter,
  // eslint-disable-next-line import/no-restricted-paths
} from '@plvs/respawn/features/esport/creator'
// eslint-disable-next-line import/no-restricted-paths
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { UnknownAvatar, CaretRight } from '@playvs-inc/nexus-icons'
import {
  NxTypography,
  NxButton,
  NxIconButton,
} from '@playvs-inc/nexus-components'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'

import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../themes'
import { Badge } from '../../badge'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  containerMobile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    marginBottom: '2px',
    textTransform: 'capitalize',
  },
  subTitle: {
    marginBottom: '7px',
  },
  esportLogoFuture: {
    margin: 'auto 20px',
    flex: 1,
    width: 32,
    height: 32,
    color: theme.palette.ColorIconDisabled,
  },
  iconButton: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: 24,
    border: `1px solid ${theme.palette.BorderLight}`,
    justifyContent: 'center',
    width: 24,
  },
  fullMatchRow: {
    flexBasis: '100%',
  },
  mobileCardMargin: {
    margin: '0 13px',
    paddingBottom: '13px',
  },
  openMatchButton: {
    alignSelf: 'center',
  },
}))

type QueueMatchRowProps = {
  match: SchedulePageQueueMatch
  mobile: boolean
}

type QueueMatchRowTeamProps = {
  match: SchedulePageQueueMatch
  esportAdapter: GeneralEsportAdapter
}

const QueueMatchRowTeams: React.FC<QueueMatchRowTeamProps> = ({
  match,
  esportAdapter,
}) => {
  const styles = useStyles()

  const rating = match?.esport?.rating
  const teamAId = match.teamId
  const teamASchoolLogo = match.schoolLogoUrl

  const { NexusIcon } = esportAdapter

  return (
    <Box display="flex" flex={1} flexDirection="row">
      <Box display="flex" flexDirection="row" margin="auto">
        <Box>
          <NxUserAvatar
            avatarUrl={
              rating === EsportRating.Restricted ? null : teamASchoolLogo
            }
            hashId={teamAId ?? ''}
            size={NxAvatarSize.MD}
          />
        </Box>

        {NexusIcon && <NexusIcon className={styles.esportLogoFuture} />}

        <Box>
          <UnknownAvatar height="54px" width="54px" />
        </Box>
      </Box>
    </Box>
  )
}

export const QueueMatchRow: React.FC<QueueMatchRowProps> = ({
  match,
  mobile,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const teamAName = match.teamName
  const teamBName = 'TBD'
  const gameSlug = match.esport?.slug
  const esportName = match.esport?.name

  const esportAdapter = useGeneralEsportAdapter(gameSlug)
  const matchRowTeams = useMemo(() => {
    return <QueueMatchRowTeams esportAdapter={esportAdapter} match={match} />
  }, [match])

  const lobbyUrl = `/app/queue-lobby/${match?.slotId}/${match?.teamId}`

  const handleOpenMatch = (
    evnt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (evnt.ctrlKey || evnt.metaKey) {
      window.open(lobbyUrl, '_blank', 'noopener')
    } else {
      navigate(lobbyUrl)
    }
  }

  return (
    <Box
      className={mobile ? classes.containerMobile : classes.container}
      data-testid="match-row"
    >
      <Box
        className={
          mobile ? `${classes.fullMatchRow} ${classes.mobileCardMargin}` : ''
        }
        flex={1}
        flexWrap="wrap"
        width="300px"
      >
        <NxTypography className={classes.title} variant="body1">
          {teamAName} vs {teamBName}
        </NxTypography>
        <NxTypography
          className={classes.subTitle}
          color="textSecondary"
          data-testid="subtitle"
          variant="body4"
        >
          {esportName}
        </NxTypography>
        {match.isScrimmage && (
          <Badge
            backgroundColor={Colors.TeamOrange}
            color={Colors.White}
            data-testid="scrimmage-badge"
            size="small"
            text="Scrimmage"
          />
        )}
      </Box>
      <Box
        alignItems={mobile ? 'center' : ''}
        className={mobile ? `${classes.fullMatchRow}` : ''}
        display="flex"
        flex={2}
        flexDirection="row"
        margin="auto"
      >
        {matchRowTeams}
        <Box
          alignItems="flex-end"
          display="flex"
          flex={mobile ? '' : 1}
          justifyContent="center"
          minWidth={mobile ? '' : '200px'}
          ml={3}
        >
          {mobile && (
            <NxIconButton
              className={classes.iconButton}
              data-testid="open-match-btn"
              icon={<CaretRight height={18} width={18} />}
              onClick={handleOpenMatch}
              p={0}
              variant="secondary"
            />
          )}
          {!mobile && (
            <NxButton
              className={classes.openMatchButton}
              label="Open Match"
              onClick={handleOpenMatch}
              variant="secondary"
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
