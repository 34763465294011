import React from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  MatchCheckInMetaSeason,
  MatchCheckInTeam,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { mapMetaseasonTimingToRelative } from '@plvs/utils'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'

export const MatchCheckInCheckedInContent: React.FC<{
  team: MatchCheckInTeam | null | undefined
  metaseason: MatchCheckInMetaSeason | null | undefined
  loading: boolean
}> = ({ metaseason, team, loading }) => {
  const minimalMatchMetaseason = metaseason
    ? mapMetaseasonTimingToRelative(metaseason)
    : undefined
  return (
    <WaitTillLoaded
      loading={loading || !metaseason}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box mb={10}>
        {team && minimalMatchMetaseason && (
          <RosterContainer
            metaseason={minimalMatchMetaseason}
            showAdditionalContainerActions={false}
            showCreateScrimmage={false}
            teamId={team.id}
          />
        )}
      </Box>
    </WaitTillLoaded>
  )
}
