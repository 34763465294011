import React, { useState } from 'react'
import { NxTypography, NxEmbedVideoModal } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { Morevert } from '@playvs-inc/nexus-icons'
import dayjs from '@plvs/rally/init/dayjs'
import { embedTwitchUrl, embedYoutubeUrl } from '@plvs/utils'
import {
  GameHighlightSource,
  useDeleteGameHighlightMutation,
} from '@plvs/graphql/generated'

import tz from 'dayjs/plugin/timezone'
import { MOMENT_SHORT_MONTH_DATE_AND_TIME_TZ } from '@plvs/const'
import { useSnackbar } from 'notistack'
import { isNil } from 'ramda'
import { UploadVideoModal } from './UploadVideoModal'
import { HighlightType, VIDEO_HEIGHT, VIDEO_WIDTH } from './highlightHelpers'
import { truncate } from '../../explore/LeagueMatches/leagueMatchesHelpers'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundAlt2,
    },
  },
  menuPaper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 196,
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.ColorTextAlt,
  },
  titleWrapper: {
    maxWidth: 224,
  },
  twitchImg: {
    width: '100%',
    height: '100%',
  },
  videoWrapper: {
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT,
    position: 'relative',
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
}))

interface Props {
  className?: string
  highlight: HighlightType
  isMyProfile: boolean
  userId: string
  userName: string
  refetchHighlights(): void
}

export const HighlightVideo: React.FC<Props> = ({
  className,
  highlight,
  isMyProfile,
  userId,
  userName,
  refetchHighlights,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [inlineVideoModalOpen, setInlineVideoModalOpen] = useState<boolean>(
    false
  )

  const [deleteHighlight, { loading }] = useDeleteGameHighlightMutation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const editVideo = (): void => {
    handleClose()
    setEditModalOpen(true)
  }

  const handleDelete = async (): Promise<void> => {
    try {
      const result = await deleteHighlight({
        variables: { input: { id: highlight?.id ?? '' } },
      })
      if (result?.data?.deleteGameHighlight?.deletedId) {
        enqueueSnackbar('Successfully deleted video', { variant: 'success' })
      }
    } catch (error) {
      enqueueSnackbar('Failed to delete video', { variant: 'error' })
    } finally {
      handleClose()
      refetchHighlights()
    }
  }

  const isYoutube = highlight?.source === GameHighlightSource.Youtube

  const videoSrc = isYoutube
    ? embedYoutubeUrl(highlight?.videoUrl ?? '')
    : embedTwitchUrl(highlight?.videoUrl ?? '')

  const hasMatch = !isNil(highlight?.match)

  const teamVsSubtitle = `${highlight?.match?.team1?.name} vs ${highlight?.match?.team2?.name}`

  const matchTime = highlight?.match?.scheduledStartsAt
  const matchTitleSubtitle = `Match Time: ${dayjs(matchTime).format(
    MOMENT_SHORT_MONTH_DATE_AND_TIME_TZ
  )}`

  const uploadedBySubtitle = `Uploaded by ${userName} · ${dayjs(
    highlight?.createdAt
  ).fromNow()}`

  const iframeAllow = isYoutube
    ? 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    : ''

  return (
    <>
      <div className={className}>
        <div className={classes.videoWrapper}>
          <img
            alt="video_thumbnail"
            className={classes.twitchImg}
            src={highlight?.thumbnailUrl ?? ''}
          />
          <div
            aria-hidden="true"
            className={classes.videoOverlay}
            onClick={(): void => setInlineVideoModalOpen(true)}
          />
        </div>
        <Box alignItems="center" display="flex" mb={1} mt={2}>
          <Box className={classes.titleWrapper} display="flex" flexWrap="wrap">
            <NxTypography variant="subtitle1">
              {truncate(highlight?.title ?? '', 58)}
            </NxTypography>
          </Box>

          {isMyProfile && (
            <>
              <IconButton className={classes.iconButton} onClick={handleClick}>
                <Morevert height={32} width={32} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                classes={{ paper: classes.menuPaper }}
                getContentAnchorEl={null}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem className={classes.menuItem} onClick={editVideo}>
                  Edit
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  disabled={loading}
                  onClick={handleDelete}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {hasMatch && (
          <>
            <NxTypography variant="body3">{teamVsSubtitle}</NxTypography>
            <NxTypography variant="body3">{matchTitleSubtitle}</NxTypography>
          </>
        )}
        <NxTypography variant="body3">{uploadedBySubtitle}</NxTypography>
      </div>
      {isMyProfile && (
        <UploadVideoModal
          highlight={highlight}
          onClose={(): void => setEditModalOpen(false)}
          open={isEditModalOpen}
          refetchHighlights={refetchHighlights}
          userId={userId}
        />
      )}
      {inlineVideoModalOpen && (
        <NxEmbedVideoModal
          allow={iframeAllow}
          onClose={(): void => setInlineVideoModalOpen(false)}
          open={inlineVideoModalOpen}
          showTopRightClose
          title={truncate(highlight?.title ?? '', 50)}
          videoSrc={videoSrc}
        >
          {hasMatch && (
            <>
              <NxTypography colorToken="ColorTextAlt2" variant="body1">
                {teamVsSubtitle}
              </NxTypography>
              <NxTypography colorToken="ColorTextAlt2" variant="body1">
                {matchTitleSubtitle}
              </NxTypography>
            </>
          )}
          <NxTypography colorToken="ColorTextAlt2" variant="body1">
            {uploadedBySubtitle}
          </NxTypography>
        </NxEmbedVideoModal>
      )}
    </>
  )
}
