import { path } from 'ramda'
import React, { useMemo } from 'react'

import { useAutoskipQuery } from '@plvs/utils'
import { Column } from '@plvs/rally/components/table'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  EsportSlug,
  useGetPhaseResultsQuery,
  GetPhaseResultsQuery,
} from '@plvs/graphql'
import { getTableColumns } from '@plvs/rally/containers/table'
import StandingSecondaryLargeDark from '@plvs/rally/assets/icons/StandingSecondaryLargeDark.svg'
import StandingSecondaryLarge from '@plvs/rally/assets/icons/StandingSecondaryLarge.svg'
import { EmptyPageWithSpot } from '@plvs/rally/components/empty/EmptyPageWithSpot'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { EsportStandingsTable } from './EsportStandingsTable'

const RECORD_WON_PATH = ['record', 'won']
const RL_PATH = ['record', 'compoundRankingScore']

type PhaseResultFields = NonNullable<
  NonNullable<GetPhaseResultsQuery['phase']>['phaseResults']
>[0]

export type PhaseResultData = PhaseResultFields & {
  position: number | string
  startsAt: string
}

export function addPositionAndDateToPhaseResults(
  esportSlug: EsportSlug,
  phaseResults: PhaseResultFields[],
  startsAt: string
): Array<PhaseResultData> {
  const PATH =
    esportSlug === EsportSlug.RocketLeague ? RL_PATH : RECORD_WON_PATH
  let positionTracker = 1
  return phaseResults.map((record, index) => {
    const prevRecord = phaseResults[index - 1]
    const sameWinRecord = path(PATH, prevRecord) === path(PATH, record)
    const sameRankingPoints =
      prevRecord?.rankingPoints === record?.rankingPoints
    positionTracker =
      sameWinRecord && sameRankingPoints ? positionTracker : index + 1
    return {
      ...record,
      position: positionTracker,
      startsAt,
    }
  })
}

export const VsRegularStandings: React.FC<{
  phaseId: string
}> = ({ phaseId }) => {
  const adapter = useEsportContext()
  const slug = adapter?.slug ?? EsportSlug.LeagueOfLegends

  const { data, loading } = useAutoskipQuery(useGetPhaseResultsQuery, {
    variables: { phaseId },
  })
  const phaseResults = addPositionAndDateToPhaseResults(
    slug,
    data?.phase?.phaseResults ?? [],
    data?.phase?.startsAt || ''
  )
  const { teamIds } = useProfileContext()
  const teamIdsSet = new Set(teamIds)
  const teamResults = phaseResults.reduce<PhaseResultData[]>(
    (accum, result) => {
      if (result.team?.id && teamIdsSet.has(result.team?.id)) {
        accum.push(result)
      }
      return accum
    },
    []
  )

  const columns = useMemo(() => {
    const columnComponents = getTableColumns(
      adapter?.standingsColumns ?? [],
      adapter.rankingInfoCopy
    ).filter(({ id }) => id !== Column.EndCap.id)

    return columnComponents
  }, [adapter])

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      {phaseResults.length ? (
        <EsportStandingsTable
          columns={columns}
          esportSlug={slug}
          overallData={phaseResults}
          teamData={teamResults}
        />
      ) : (
        <EmptyPageWithSpot
          spot={StandingSecondaryLarge}
          spotCaption="No standings"
          spotDark={StandingSecondaryLargeDark}
          subtitle="The final results list will be available after the last round is played."
          title="Standings not available yet"
        />
      )}
    </WaitTillLoaded>
  )
}
