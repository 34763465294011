import { createContext } from 'react'
import { noop } from 'ramda-adjunct'
import { LeagueFilterContext } from '@plvs/respawn/features/filters/types'

export const leagueContext = createContext<LeagueFilterContext>({
  leagues: [],
  teams: [],
  loading: true,
  error: null,
  setTeam: noop,
  setLeague: noop,
  setMetaseason: noop,
  phases: [],
  setCompetitionModel: noop,
  setPhase: noop,
  defaultPhases: [],
  metaseasons: [],
})
export const { Provider } = leagueContext
