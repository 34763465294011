import React from 'react'
import { CompetitionGroup, CompetitionModel, PhaseType } from '@plvs/graphql'
import { competitionModelToDisplay } from '@plvs/utils'
import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { Link, makeStyles, Box } from '@material-ui/core'
import { LONG_DATE_FORMAT } from '@plvs/const'
import dayjs from 'dayjs'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { supportLinkClicked } from '@plvs/respawn/features/analytics/segment/segment'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import {
  EnrolledPlayerOnTeam,
  EnrollmentLeagueEsport,
  EnrollmentLeagues,
  EnrollmentRangeData,
  OptionalWeeksCache,
  SeasonEnrollmentRangeData,
  TeamLeagueSelectFn,
} from '@plvs/respawn/containers/enrollment/types'
import {
  ComponentType,
  ComponentTypeProps,
  EnrollmentStep,
  getEnrollmentDetails,
} from '@plvs/respawn/containers/enrollment/enrollmentDetailsData'
import { SeasonEnrollment } from './SeasonEnrollment'

export interface EnrollmentProps {
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  leagueEsports: EnrollmentLeagueEsport[]
  onPlayersSelected: TeamLeagueSelectFn
  leagues: EnrollmentLeagues
  schoolId: string
  seasonName?: string
  competitionModel: CompetitionModel
  metaseasonId: string
  isAdmin: boolean
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  optionalWeeksCache: OptionalWeeksCache
  slotExclusionRangeData: EnrollmentRangeData
  setOptionalSeasonWeeksCache(input: OptionalWeeksCache): void
  optionalSeasonWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  addEnrollmentSeasonIds(seasonIds: string[]): void
  removeEnrollmentSeasonId(seasonId: string): void
}

export interface SelectedProps {
  [key: string]: Record<string, number>
}

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
    marginBottom: 30,
  },
  icon: {
    fontSize: '40px',
  },
}))

export const Enrollment: React.FC<EnrollmentProps> = ({
  enrolledPlayersOnTeam,
  onPlayersSelected,
  leagues,
  leagueEsports,
  schoolId,
  seasonName,
  competitionModel,
  metaseasonId,
  isAdmin,
  setOptionalWeeksCache,
  optionalWeeksCache,
  slotExclusionRangeData,
  seasonSlotExclusionRangeData,
  addEnrollmentSeasonIds,
  removeEnrollmentSeasonId,
  optionalSeasonWeeksCache,
  setOptionalSeasonWeeksCache,
}) => {
  const isMobile = useBreakpointXs()
  const { metaseasons, phases } = useSchoolLeagueInfoContext()
  const metaseason = metaseasons.find((ms) => ms?.id === metaseasonId)
  const { competitionGroup } = useSelectedOrganizationFn()

  const enrollmentDetails = getEnrollmentDetails({
    seasonName,
    competitionModel,
    competitionGroup: competitionGroup ?? undefined,
  })[EnrollmentStep.SelectTeam]
  const classes = useStyles()
  const Icon = enrollmentDetails.icon

  const handleClick = (): void => {
    supportLinkClicked({
      source: 'onboard school search',
    })
  }
  const contactInfo = (
    <Link href="mailto:support@playvs.com" onClick={handleClick}>
      support@playvs.com
    </Link>
  )
  const preseasonPhase = phases.find(
    (phase) => phase.type === PhaseType.Preseason
  )
  leagues.sort((a, b) => {
    const leagueAEsportName = a?.esport?.name || ''
    const leagueBEsportName = b?.esport?.name || ''
    return leagueAEsportName > leagueBEsportName ? 1 : -1
  })

  // Add subtitle variable due the email address needing JSX
  const extendedPreseasonText = preseasonPhase
    ? `, but you must opt-out by ${dayjs(preseasonPhase?.startsAt).format(
        LONG_DATE_FORMAT
      )}`
    : ''
  const competitionSubtitle = (
    <>
      Select which of your{' '}
      {competitionModelToDisplay({
        competitionModel,
        competitionGroup,
      }).toLowerCase()}{' '}
      teams and substitute players you&apos;d like to enroll for{' '}
      {metaseason?.name ?? 'the'} season. After the registration deadline date,
      you will no longer be able to enroll additional teams or unenroll teams or
      players, but you will be able to enroll additional substitute players and
      update team configurations.
      <Box mb={2} mt={2}>
        {`You may change or edit your enrollment through the third week of the
      regular season. Participation in the pre-season is optional${extendedPreseasonText}.`}
      </Box>
      Restrictions on team configurations vary by league — please refer to our
      league rulebooks for more details. Contact {contactInfo} if you still have
      any questions.
    </>
  )
  return (
    <Box display="flex" flex={1} justifyContent="flex-end" overflow="hidden">
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent={enrollmentDetails.centerToPage ? 'center' : ''}
        overflow="auto"
      >
        <Box alignItems="center" display="flex" pb={2}>
          {!isMobile && (
            <Box height="40px" mr={2} width="40px">
              <Icon className={classes.icon} />
            </Box>
          )}
          <NxTypography className={classes.title} variant="h1">
            {enrollmentDetails.title}
          </NxTypography>
          {competitionGroup && (
            <Box pl={2} pr={1}>
              <Pill
                label={competitionGroup.replace('_', ' ')}
                size="small"
                variant={
                  competitionGroup === CompetitionGroup.HighSchool
                    ? 'info'
                    : 'misc'
                }
              />
            </Box>
          )}
        </Box>
        <NxTypography className={classes.subtitle} variant="body1">
          {competitionSubtitle}
        </NxTypography>
        {enrollmentDetails.components.map(
          (item: ComponentTypeProps): React.ReactNode => {
            if (item.componentType === ComponentType.SeasonEnrollment) {
              return (
                <SeasonEnrollment
                  key={item.componentType}
                  addEnrollmentSeasonIds={addEnrollmentSeasonIds}
                  enrolledPlayersOnTeam={enrolledPlayersOnTeam}
                  isAdmin={isAdmin}
                  leagueEsports={leagueEsports}
                  leagues={leagues}
                  onPlayersSelected={onPlayersSelected}
                  optionalSeasonWeeksCache={optionalSeasonWeeksCache}
                  optionalWeeksCache={optionalWeeksCache}
                  removeEnrollmentSeasonId={removeEnrollmentSeasonId}
                  schoolId={schoolId}
                  seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
                  setOptionalSeasonWeeksCache={setOptionalSeasonWeeksCache}
                  setOptionalWeeksCache={setOptionalWeeksCache}
                  slotExclusionRangeData={slotExclusionRangeData}
                />
              )
            }
            return null
          }
        )}
      </Box>
    </Box>
  )
}
