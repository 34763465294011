import {
  NxButton,
  NxModal,
  NxTypography,
  Pill,
} from '@playvs-inc/nexus-components'
import React, { useState } from 'react'
import {
  SchoolDarkSmallPrimary,
  SchoolLightSmallPrimary,
} from '@playvs-inc/nexus-spots'
import {
  Box,
  Grid,
  Hidden,
  Paper,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { Regularcheck } from '@playvs-inc/nexus-icons'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { numberToPhoneFormat } from '@plvs/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CompetitionGroup } from '@plvs/graphql/generated'
import { CoachLeadComponent } from './CoachLeadComponent'
import { CompetitionPillLabel, CompetitionPillVariant } from './SchoolLeadUtils'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '295px',
    padding: theme.spacing(3),
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // For two line wrap before ellipsis overflow
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: {
      '-webkit-line-clamp': 1,
    },
  },
  subtitleContainer: {
    paddingBottom: theme.spacing(2),
    marginTop: 'auto',
  },
  location: {
    marginBottom: theme.spacing(0.5),
  },
  form: {
    marginBottom: 0,
  },
  phone: {
    height: '23px',
    color: theme.palette.ColorTextAlt2,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.ColorTextLink,
    },
  },
}))

interface Props {
  schoolId: string
  schoolName: string
  location: string
  state: string
  phoneNumber: string
  hasBeenReferred: boolean
  isFaculty: boolean
  competitionGroup?: CompetitionGroup | null
}

export const InviteSchoolCard: React.FC<Props> = ({
  schoolName,
  location,
  hasBeenReferred,
  isFaculty,
  schoolId,
  state,
  phoneNumber,
  competitionGroup,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const theme = useTheme()
  const classes = useStyles()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const flags = useFlags()
  const pillLabel = competitionGroup && CompetitionPillLabel[competitionGroup]
  const pillVariant =
    competitionGroup && CompetitionPillVariant[competitionGroup]

  const handleModalClose = (): void => setOpen(false)
  const handleModalOpen = (): void => setOpen(true)

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Paper className={classes.container}>
        {hasBeenReferred && (
          <Box
            bgcolor={theme.palette.ColorBackgroundSuccess}
            left={0}
            position="absolute"
            py={0.5}
            top={0}
            width="100%"
            zIndex={10}
          />
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          {isDarkMode ? (
            <SchoolDarkSmallPrimary height={41} width={41} />
          ) : (
            <SchoolLightSmallPrimary height={41} width={41} />
          )}
          {hasBeenReferred && (
            <Box alignItems="center" display="flex" flexDirection="row">
              <Regularcheck color={theme.palette.ColorIconSuccess} />
              <NxTypography colorToken="ColorTextSuccess" variant="body4">
                Referral Submitted
              </NxTypography>
            </Box>
          )}
        </Box>

        {pillLabel && (
          <Box mb={1}>
            <Pill label={pillLabel} size="small" variant={pillVariant} />
          </Box>
        )}
        <NxTypography className={classes.title} variant="h4">
          {schoolName}
        </NxTypography>
        <Hidden smDown>
          <Box className={classes.subtitleContainer}>
            <NxTypography
              className={classes.location}
              colorToken="ColorTextAlt2"
              variant="body1"
            >
              {location}
            </NxTypography>
            {flags.bountyPhoneNumber && (
              <NxTypography className={classes.phone} variant="body1">
                {numberToPhoneFormat(phoneNumber)}
              </NxTypography>
            )}
          </Box>
        </Hidden>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Hidden smUp>
            <Box className={classes.subtitleContainer}>
              <NxTypography
                className={classes.location}
                colorToken="ColorTextAlt2"
                variant="body1"
              >
                {location}
              </NxTypography>
              {flags.bountyPhoneNumber && (
                <NxTypography className={classes.phone} variant="body1">
                  {numberToPhoneFormat(phoneNumber)}
                </NxTypography>
              )}
            </Box>
          </Hidden>
          <Box alignSelf="flex-end" display="flex" justifyContent="flex-end">
            <NxButton
              label={hasBeenReferred ? 'Refer Again' : 'Refer'}
              onClick={handleModalOpen}
              variant="secondary"
            />
          </Box>
        </Box>
      </Paper>
      <NxModal
        onClose={handleModalClose}
        open={open}
        showTopRightClose
        subtitle="Refer a coach at another school to get them started on PlayVS."
        title="Refer a School Near You"
      >
        <CoachLeadComponent
          className={classes.form}
          gridSpacing={1}
          hideModalPadding
          isBorderless
          isFaculty={isFaculty}
          isModalView
          onBackButtonClick={handleModalClose}
          school={{ id: schoolId, state, name: schoolName }}
          setAvailableSchool
          showBackButton
          source={
            isFaculty
              ? 'faculty-global-coach-lead'
              : 'student-global-coach-lead'
          }
        />
      </NxModal>
    </Grid>
  )
}
